// @flow
import React, { Fragment } from 'react'
import { debounce } from 'lodash'
import { Moment } from 'moment'
import { withTheme } from 'emotion-theming'
import type { BrowserHistory } from 'history'
import type { Match } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import type { Theme } from '../../../../theme'
import ImageSection from './ImageSection'
import TitleSection from './TitleSection'
import DescriptionSection from './DescriptionSection'
import withDialog from '@Common/AlertModal/withAlertModal'
import TextField from '../../../Common/TextField'
import { Typography } from '@material-ui/core'
import Avatar from '@Common/Avatar'
import CarouselUnit from '@Common/CarouselList/CarouselUnit'
import { CommonIcon } from '@icons'
import Linkify from '@Common/Linkify'
import Loader from '../../../Common/Loader'

import {
  MessageSection,
  CommentSection,
  CommentBtn,
  Break,
  CommentList,
  Circle,
  CircleContainer,
  AvatarContainer,
  CommentNameDate,
  CommentBody,
} from './styles'

type Props = {
  likePost: () => void,
  unlikePost: () => void,
  type: string,
  action: string,
  theme: Theme,
  userToken: string,
  propertyId: string,
  history: BrowserHistory,
  match: Match,
  saveChanges: Function,
  showAlertModal: () => void,
  showError: (message: string) => void,
  propertyId: string,
  data: {
    threadId: string,
    endTime: string,
    startTime: string,
    title: string,
    message: string,
    image?: Object,
    moment: Moment,
    comments: Object[],
    likesBy: Object[],
    eventDuration: string,
    userFullName: string,
    userPicture: string,
    threadType: string,
    createdAt: string,
    likesOrAttending: number,
    attendeesImage: string,
    isLikedOrisAttending: boolean,
  },
  isFetching: boolean,
  hasData: boolean,
  threadId: string,
  postComment: (comment: string) => void,
  attendEvent: () => void,
  unattendEvent: () => void,
}

type State = {
  showDialog: boolean,
  comment: string,
}

class ViewEditPost extends React.Component<Props, State> {
  state = {
    showDialog: false,
    comment: '',
  }

  isEvent = this.props.type === 'events'
  isPost = this.props.type === 'posts'

  goBack = () => {
    const {
      history,
      match: { url },
      type,
    } = this.props
    history.push(`${url.split(`/${type}`)[0]}/${type}`)
  }

  handleCommentChange = (event) => {
    this.setState({ comment: event.target.value })
  }

  goToPostOrEvent = () => {
    const {
      history,
      match: { url },
      type,
      threadId,
    } = this.props
    history.push(`${url.split(`/${type}`)[0]}/${type}/${threadId}`)
  }

  goToEdit = () => {
    const {
      history,
      match: { url },
    } = this.props

    history.push(`${url}/edit`)
  }

  openDialog = () => {
    this.props.showAlertModal()
  }

  postComment = () => {
    try {
      this.props.postComment(this.state.comment)
    } finally {
      this.setState({ comment: '' })
    }
  }

  deleteComment = (key) => {
    this.props.history.push({
      search: `?comment=${key}`,
    })

    this.openDialog()
  }

  deletePost = () => {
    this.props.history.push({
      search: `?comment=`,
    })

    this.openDialog()
  }

  togglePostLike = debounce(() => {
    const {
      likePost,
      unlikePost,
      data: { isLikedOrisAttending },
    } = this.props

    isLikedOrisAttending ? unlikePost() : likePost()
  }, 200)

  render() {
    const { isFetching, hasData } = this.props
    const { comment } = this.state

    if (isFetching) return <Loader />

    if (!hasData) return null

    const {
      theme,
      type,
      action,
      data: {
        comments,
        image,
        title,
        message,
        moment,
        likesBy,
        eventDuration,
        userFullName,
        userPicture,
        threadType,
        createdAt,
        likesOrAttending,
        attendeesImage,
        isLikedOrisAttending,
      },
      attendEvent,
      unattendEvent,
      propertyId,
    } = this.props

    if (type !== threadType) return null

    return (
      <Fragment>
        {image && <ImageSection theme={theme} image={image} />}
        <TitleSection
          togglePostLike={this.togglePostLike}
          attendEvent={attendEvent}
          unattendEvent={unattendEvent}
          isLikedOrisAttending={isLikedOrisAttending}
          attendeesImage={attendeesImage}
          likesOrAttending={likesOrAttending}
          likesBy={likesBy}
          propertyId={propertyId}
          image={image}
          theme={theme}
          title={title}
          action={action}
          goBack={this.goBack}
          goToEdit={this.goToEdit}
          openDialog={this.deletePost}
          type={threadType}
          moment={moment}
          userFullName={userFullName}
          userPicture={userPicture}
        />
        <DescriptionSection
          isLikedOrisAttending={isLikedOrisAttending}
          image={image}
          createdAt={createdAt}
          userFullName={userFullName}
          type={type}
          userPicture={userPicture}
          theme={theme}
          moment={moment}
          eventDuration={eventDuration}
        />
        <MessageSection
          columnStart={1}
          columnSpan={12}
          rowStart={image ? 10 : 9}
          rowSpan={4}>
          <div>
            <Linkify tagName="pre" content={message} />
          </div>

          <CommentSection>
            <TextField
              rows={1}
              rowsMax={2}
              fullWidth
              multiline
              placeholder={'Comment'}
              value={comment}
              label={'Comment'}
              showPencil={false}
              InputProps={{
                endAdornment: (
                  <CommentBtn onClick={this.postComment}>post</CommentBtn>
                ),
              }}
              onChange={this.handleCommentChange}
            />
            {comments.length > 0 && (
              <Fragment>
                <Break />
                <Typography>
                  {comments.length} Comment{comments.length !== 1 ? 's' : ''}
                </Typography>
                <Break />
                <CommentList>
                  {comments.map(
                    ({ body, name, date, userImage, id, key }, index) => (
                      <CarouselUnit
                        key={key}
                        height={'auto'}
                        lastUnit={index === comments.length - 1}>
                        <AvatarContainer>
                          <Avatar picture={userImage} size={'3em'} />
                        </AvatarContainer>
                        <CommentBody>
                          <CommentNameDate>
                            {name} {date} {'- '}
                          </CommentNameDate>
                          {body}
                        </CommentBody>
                        <CircleContainer
                          onClick={this.deleteComment.bind(this, key)}>
                          <Circle>
                            <CommonIcon
                              name="close"
                              width="0.5em"
                              height="0.5em"
                              fill={theme.palette.primary.green.mint}
                              strokeWidth={8}
                              stroke={theme.palette.primary.green.mint}
                            />
                          </Circle>
                        </CircleContainer>
                      </CarouselUnit>
                    )
                  )}
                </CommentList>
              </Fragment>
            )}
            <Break />
          </CommentSection>
        </MessageSection>
      </Fragment>
    )
  }
}

const ViewEditPostWithDialog = withRouter(withTheme(withDialog(ViewEditPost)))

export default (props: Object) => (
  <ViewEditPostWithDialog
    alertTitle="Are you sure you wish to delete?"
    alertYesButtonText={'Yes'}
    alertNoButtonText={'No'}
    showScrollToTop
    {...props}
  />
)
