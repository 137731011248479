// @flow

import get from 'lodash/get'

export const getFirstChannel = (state: Object) => {
  return get(state, ['chat', 'channels', '0'])
}

export const getChannels = (state: Object) => {
  return get(state, ['chat', 'channels'], [])
}

export const getChannelsByType = (state: Object) => {
  return get(state, ['chat', 'byType'], [])
}

export const getTotalUnreadCount = (state: Object) => {
  const chatChannels = get(state, ['chat', 'channels'], [])
  return chatChannels
    ? chatChannels.reduce(
        (totalUnreadCount, { unreadMessageCount }) =>
          totalUnreadCount + unreadMessageCount,
        0
      )
    : 0
}
