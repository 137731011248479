import { combineReducers } from 'redux'
import withNormalizedFetch from '../../../helpers/reducers/withNormalizedFetch'
import { SECTION, SELECT_DEVICEPROFILE } from '../actions'

const byId = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const allIds = (state = [], action) => {
  switch (action.type) {
    default:
      return state
  }
}

const selectedDeviceProfile = (state = null, action) => {
  switch (action.type) {
    case SELECT_DEVICEPROFILE:
      return action.profileId
    default:
      return state
  }
}

const reducer = combineReducers({
  byId,
  allIds,
  selectedDeviceProfile
})

export default withNormalizedFetch(SECTION)(reducer)
