import { connect } from 'react-redux'
import Visitors from './Visitors'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { fetchUserVisitorsAction } from 'zego-shared/store/users/profile/actions'
import {
  getVisitors,
  getVisitorsCount
} from 'zego-shared/store/users/selectors'

const VisitorsWithFetchData = withFetchData(Visitors)
const fetchingSelector = createIsFetchingSelector(['user'])

const mapStateToProps = state => {
  return {
    isFetching: fetchingSelector(state),
    visitors: getVisitors(state),
    total: getVisitorsCount(state)
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { userId }
    }
  }
) => {
  return {
    fetchData: () => {
      dispatch(fetchUserVisitorsAction({ userId }))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VisitorsWithFetchData)
