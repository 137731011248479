import styled from '@emotion/styled'
import { Underline } from '@Common/Cards/BaseCard/styles'
import Badge from '@material-ui/core/Badge'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '../../Common/CustomCircularProgress'

import { convertHexToRGB } from '../../../utils'

// expansion panel

export const CustomBadge = styled(Badge)`
  & > span {
    position: static;
    top: 0px;
    width: 2.5em;
    height: 2.5em;
    background: ${props => props.theme.palette.grey.main}};
    font-size: ${({ theme }) => theme.custom.font.size.xs};
  }
`

export const CustomTypography = styled(Typography)`
  font-size: ${({ theme }) => theme.custom.font.size.md};
`

export const CustomExpansionPanel = styled(ExpansionPanel)`
  display: flex;
  flex-direction: column;
  margin: 0px;
  box-shadow: none;
  width: 80%;
  min-height: 48px;
  border-top: 0px;
  border-bottom: 0px;
  background-color: transparent;

  & > div {
    padding: 0px;
  }

  &:before {
    height: 0px;
  }

  ${CustomBadge} {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  ${CustomTypography} {
    flex: 10;
  }

  .summary-expanded {
    margin-bottom: 0px;
    padding: 0px;
    min-height: 20px !important;
  }
`

export const CustomExpansionPanelDetails = styled(ExpansionPanelDetails)`
  display: flex;
  flex-direction: column;
  padding: 0px;
`

export const Wrap = styled('div')`
  width: 100%;
`

export const VerticalLine = styled('div')`
  border-left: 1px solid ${({ theme }) => theme.palette.grey.main};
  height: 100%;
  position: absolute;
  left: 4.25%;
  z-index: 100000;
`

export const ButtonWrap = styled('div')`
  padding: 0em 1.5em;
`
export const CategoryItemWrapper = styled('div')`
  position: relative;
  margin-bottom: 1.5em;
`

// category item
export const GoHere = styled('a')`
  align-self: center;
  cursor: pointer;
  margin-right: 4%;
  color: ${({ theme }) => theme.palette.grey.main};

  svg {
    fill: ${({ theme }) => theme.palette.grey.main};
  }
`

export const CategoryContent = styled('div')`
  display: flex;
  padding: 0.1em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};

  & > *:first-child {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  ${GoHere} {
    opacity: 0;
  }

  &:hover ${GoHere} {
    opacity: 1;
  }

  &:hover {
    background-color: ${({ theme }) =>
      convertHexToRGB(theme.palette.neutrals.coolGray, 0.5)};
  }
`

export const Title = styled('p')`
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
`

export const Details = styled('div')`
  display: flex;
  flex: 11;
  justify-content: space-between;

  & > *:first-child {
    flex: 1;
    display: flex;
  }
  * {
    margin-right: 1%;
    color: ${({ theme }) => theme.palette.grey.main};
  }
`
export const CaptionHeading = styled(Typography)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.grey.main};
`

export const MainHeading = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
`

export const ContentWrap = styled('div')`
  width: 100%;
`

export const ProgressCircles = styled('div')`
  align-self: flex-start;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & > div {
    width: auto;
  }
`

export const FilteredData = styled('div')`
  width: 200%;
  position: absolute;
  bottom: 0;
`

export const CustomCircularProgress = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.primary.green.mint};
  align-self: flex-end;
`

export const CustomCircularWrapper = styled('div')`
  margin-left: 1em;
`

export const ContactDetails = styled('div')`
  color: ${({ theme }) => theme.palette.text.secondary};
  justify-content: flex-end;
`
export const UserList = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`
export const UnderLine = styled(Underline)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutrals.coolGray};
`
export const ViewMore = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) =>theme.palette.primary.green.mint};
  text-align: right;
  padding-top: 1em;
`
