import {
  fetchMimoChecklistOptedOutStart,
  fetchMimoChecklistOptedOutSuccess,
  fetchMimoChecklistOptedOutError

} from '../actions'

const initialState = {
  isSubmitting: false,
  isError: '',
}

const submitMimoOptedOut = (state = initialState, { type, payload, error }) => {

  switch (type) {
    case fetchMimoChecklistOptedOutStart().type:
      return {
        ...state,
        isSubmitting: true,
        isError:''
      }
    case fetchMimoChecklistOptedOutSuccess().type: {
      return {
        ...state,
        isSubmitting: false,
        isError: ''
      }
    }
    case fetchMimoChecklistOptedOutError().type:
      return {
        ...state,
        isSubmitting: false,
        isError: error
      }

    default:
      return state
  }
}
export default submitMimoOptedOut
