// some_not_humanized_string -> Some Humanized String
export const humanizeString = string =>
  typeof string === 'string'
    ? string
        .replace(/_/g, ' ')
        .replace(/(?: |\b)(\w)/g, string => string.toUpperCase())
    : string

// check whether a string ends with any of the specified postfixes
export const endsWithAny = (postfixes, str) => {
  if (typeof str !== 'string') return false
  return postfixes.some(postfix => str.endsWith(postfix))
}

// https://stackoverflow.com/questions/33076177/getting-name-initials-using-js
export const getInitials = name => {
  if (name) {
    let initials = name.match(/\b\w/g) || []
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
    return initials
  }
  return ''
}

// example -> Example
export const capitalizeFirstLetter = str => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  return str
}

export const isString = object =>
  typeof object === 'string' || object instanceof String

export const dataURItoBlob = dataURI => {
  const byteString = atob(dataURI.split(',')[1])
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: 'image/jpeg' })
}

export const formatPhoneNumber = phoneNumber => {
  if (!phoneNumber) {
    return phoneNumber
  }

  let phone = phoneNumber.replace(/[- )(]/g, '')

  if (phone.length > 6) {
    if (phone.substring(0, 2) !== '+1') {
      return `(${phone.substring(0, 3)}) ${phone.substring(
        3,
        6
      )}-${phone.substring(6)}`
    }

    phone = phoneNumber.substring(2)
    return `(${phone.substring(0, 3)}) ${phone.substring(
      3,
      6
    )}-${phone.substring(6)}`
  }

  return phone
}

export const currencyToUsd = (currency: string | number): string => {
  const number = Number(currency) || 0
  return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(number)
}
