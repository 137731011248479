import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'emotion-theming'
import moment from 'moment'

import {
  addMinutesToMilitaryTime,
  formatMilitaryTime,
  getLengthInMinutes,
  getTimeArray,
  getTimeSlotLabel,
  filterTimeSlotsToWindow,
} from "../../../utils/scheduleUtils";
import {
  ScheduleContainer,
  TimeLabel,
  TimeSlot,
  FadedTimeLine,
  NowBlock, NowBlockDashboard,
  MultiDayReservationContainer,
  MultiDayReservation,
  MultiDayBold,
  MultiDayDate,
  MultiDayDashboardReservation,
} from './style.js'

type Props = {
  startTime: ?number,
  windowDuration: ?number,
  startTime: ?number,
  availability: Object,
  reservationType: string,
  extraAreaQuantity: number,
  extraAreaName: string,
  timeZone: string,
  style: Object,
  onClickTimeSlot: ?Function,
  selectedAvailabilityTimeSlot: Object,
  forDashboard: ?boolean,
  containerDimensions: ?Object,
}

const AmenityAvailabilitySchedule = ({
  availability: { timeSlots = [], currentFacilityDate = {}, reservations = [] },
  containerDimensions,
  startTime: scheduleStartTime = 0,
  windowDuration = 1440,
  forDashboard = false,
  showNowBlock = true,
  reservationType,
  extraAreaName,
  selectedAvailabilityTimeSlot,
  onClickTimeSlot = () => { }
}: Props) => {
  const { date, time: currentTime } = currentFacilityDate;

  const isToday = timeSlots.length && timeSlots[0].startDate === date

  const isPast = (timeSlot) => {
    return !forDashboard && timeSlot.isPast
  }

  const isMultiDay = (reservationType === 'multiDay')

  // Do not filter down the list of timeslots for multiday amenities
  const adjustedTimeSlots = isMultiDay ? timeSlots
    : filterTimeSlotsToWindow(timeSlots, scheduleStartTime, windowDuration)

  if (!adjustedTimeSlots?.length) { return <></> }
  const { endTime: scheduleEndTime } = adjustedTimeSlots[adjustedTimeSlots.length - 1]
  
  const createNowBlock = () => {
    if (!showNowBlock || !isToday || currentTime < scheduleStartTime) {
      return <></>
    }
    const currentMinute = currentTime % 100
    const roundedMinutes = currentMinute >= 30 ? 30 : 0
    const time = currentTime - currentMinute + roundedMinutes
    return forDashboard
      ? <NowBlockDashboard offset={scheduleStartTime} time={time} dims={containerDimensions} />
      : <NowBlock offset={scheduleStartTime} time={time} />

  }

  const isSlotSelected = (timeSlot) => selectedAvailabilityTimeSlot?.startTime <= timeSlot.startTime &&
    selectedAvailabilityTimeSlot?.endTime >= timeSlot.endTime

  const renderTimeSlot = (timeSlot, primaryLabel, secondaryLabel) => {
    const props = {
      reservationType,
      buffer: timeSlot.buffer,
      offset: scheduleStartTime,
      status: timeSlot.status,
      startTime: timeSlot.startTime,
      endTime: timeSlot.endTime,
      isSelected: isSlotSelected(timeSlot),
      isPast: isPast(timeSlot),
      onClick: timeSlot.buffer ? () => { } : () => onClickTimeSlot(timeSlot),
    }
    return <TimeSlot {...props}>
      {primaryLabel && <div>{primaryLabel}</div>}
      {secondaryLabel && <div>{secondaryLabel}</div>}
    </TimeSlot>
  }

  const renderTimeLabel = ({ startTime: time }) => {
    if (reservationType !== 'fullFacility' && time % 100 !== 0) {
      return <></>
    }
    
    return (
      <TimeLabel time={time} offset={scheduleStartTime}>
        {formatMilitaryTime(time)}
      </TimeLabel>
    )
  }

  const renderExtras = (timeSlot) => {
    if (getLengthInMinutes(timeSlot) <= 60) {
      return <></>
    }
    const min = addMinutesToMilitaryTime(timeSlot.startTime, 30)
    const max = addMinutesToMilitaryTime(timeSlot.endTime, -30)
    const extraTimes = getTimeArray(min, max)
      .filter((t, index, arr) =>
        !(t.value % 100) && Math.abs(arr.length / 2 - index) > 1
      )

    const renderItem = ({ value: time, label: labelString }) => {
      return <>
        <TimeLabel time={time} offset={scheduleStartTime} key={time}>
          {labelString}
        </TimeLabel>
        <FadedTimeLine time={time} offset={scheduleStartTime} />
      </>
    }

    return extraTimes.map(renderItem)
  }

  const renderDashboardMultiDay = () => {
    const filteredReservations = reservations.filter((r) => {
      return r.startDate <= date && r.endDate >= date
    })

    return filteredReservations.length > 0 ?
      <MultiDayReservationContainer rows={filteredReservations.length}>
        {filteredReservations.map(element => {
          const {
            residentName,
            residentUnitName,
            startDate,
            endDate,
            startTime,
            endTime,
          } = element
          const checkInDate = moment(startDate).format("MMM D")
          const checkOutDate = moment(endDate).format("MMM D")

          let mainLabel = `Reserved by ${residentName} / ${residentUnitName}`
          let secondaryLabel = `${checkInDate} through ${checkOutDate}`

          if (endDate === date) {
            secondaryLabel = `(checking out by ${formatMilitaryTime(endTime)} today)`
          } else if (startDate === date) {
            secondaryLabel = `(checking in after ${formatMilitaryTime(startTime)} today)`
          }

          return <MultiDayDashboardReservation>
            <MultiDayBold>{mainLabel}</MultiDayBold>
            <MultiDayDate>{secondaryLabel}</MultiDayDate>
          </MultiDayDashboardReservation>
        }
        )}
      </MultiDayReservationContainer> :
      <MultiDayReservationContainer>
        <MultiDayDashboardReservation>
          <MultiDayBold>Available</MultiDayBold>
        </MultiDayDashboardReservation>
      </MultiDayReservationContainer>
  }

  const renderDetailsMultiDay = () => {
    let filteredReservations = reservations.filter((r) => {
      return r.startDate > date
    })

    const reservationsToDisplay = 5
    if (filteredReservations.length > reservationsToDisplay) {
      filteredReservations = filteredReservations.slice(0, reservationsToDisplay)
    }

    return <MultiDayReservationContainer rows={filteredReservations.length}>
      {filteredReservations.map(element => {
        const {
          residentName,
          residentUnitName,
          startDate,
          endDate,
        } = element
        const checkInDate = moment(startDate).format("MMM D")
        const checkOutDate = moment(endDate).format("MMM D")

        const mainLabel = `${residentName} / ${residentUnitName}`
        const secondaryLabel = `Check in: ${checkInDate} | Check out: ${checkOutDate}`

        return <MultiDayReservation>
          <MultiDayDate>{secondaryLabel}</MultiDayDate>
          <MultiDayBold>{mainLabel}</MultiDayBold>
        </MultiDayReservation>
      }
      )}
    </MultiDayReservationContainer>
  }

  if (reservationType === 'multiDay') {
    if (forDashboard) {
      return renderDashboardMultiDay()
    }
    return renderDetailsMultiDay()
  }

  return <ScheduleContainer timeSlots={adjustedTimeSlots}>
    <TimeLabel time={scheduleEndTime}>
      {formatMilitaryTime(scheduleEndTime)}
    </TimeLabel>
    {showNowBlock && createNowBlock()}
    {adjustedTimeSlots.map(timeSlot => {
      const { primaryLabel, secondaryLabel } = getTimeSlotLabel(timeSlot, reservationType, extraAreaName)
      return (<>
        {renderTimeLabel(timeSlot)}
        {renderTimeSlot(timeSlot, primaryLabel, secondaryLabel)}
        {renderExtras(timeSlot)}
      </>)
    }
    )}
  </ScheduleContainer>
}

AmenityAvailabilitySchedule.propTypes = {
  availability: PropTypes.object.isRequired,
  //duration: PropTypes.number.isRequired,
  increment: PropTypes.number.isRequired,
  //startTime: PropTypes.string.isRequired,
}

export default withTheme(AmenityAvailabilitySchedule)
