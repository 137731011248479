import { call, put, takeLatest } from 'redux-saga/effects'
import { showSnackbar } from '../../snackbar/actions'

import { SEND_BRANCH_SMS } from '../actions'
import api from '../../../utils/api'
import log from '../../../utils/logger'

export function* sendSMS({ appType, phoneNumber }) {
  try {
    yield call(api.sendBranchSms, appType, phoneNumber)
    yield put(showSnackbar('SMS sent successfully', 'success'))
  } catch (error) {
    yield put(showSnackbar('Failed to send', 'error'))
    log(`Failed to send. Error: ${error}`)
  }
}

function* watchSendSMS() {
  yield takeLatest(SEND_BRANCH_SMS, sendSMS)
}

export default [watchSendSMS()]
