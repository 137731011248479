export const getSmartHeaderTabs = (propertyId, type = '', scheduleEnabled) => {
  if (type === 'inactive') {
    return [
      {
        name: 'Units',
        route: `/properties/${propertyId}/smart/units`,
        query: { type: 'inactive' }
      }
    ]
  }

  const activeTabs = [
    {
      name: 'Units',
      route: `/properties/${propertyId}/smart/units`,
      query: { type: 'smart' }
    },
    {
      name: 'Battery',
      route: `/properties/${propertyId}/smart/battery`
    },
    {
      name: 'Hub',
      route: `/properties/${propertyId}/smart/hub`
    }
  ]

  const scheduleTab = {
    name: 'Vacant Schedule',
    route: `/properties/${propertyId}/smart/vacant-schedule`
  }

  return scheduleEnabled ? [...activeTabs, scheduleTab] : activeTabs
}
