import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import AppContainer from './AppContainer'
import withFetchData from 'zego-shared/views/withFetchData'
import {
  changeProperty,
  selectCompany,
  fetchPropertyInfo,
  fetchCompaniesPropertiesAndSelect
} from 'zego-shared/store/select/actions'
import { hasSendbirdToken } from 'zego-shared/store/authorization/selectors'
import { fetchAllSettings } from 'zego-shared/store/settings/actions'
import {
  getPropertyId,
  getSelectedProperty,
  getCompanyName,
  getSelectedCompany,
  getProperties
} from 'zego-shared/store/select/selectors'
import { getSelectedCompanyProperties } from 'zego-shared/store/select/companies/selectors'
import {
  getCompanies,
  checkSuperAdmin
} from 'zego-shared/store/select/companies/selectors'

const mapStateToProps = state => ({
  selectedPropertyID: getPropertyId(state),
  selectedProperty: getSelectedProperty(state),
  selectedCompany: getSelectedCompany(state),
  companyName: getCompanyName(state),
  companies: getCompanies(state),
  properties: getProperties(state),
  selectedCompanyProperties: getSelectedCompanyProperties(state),
  isSuperAdmin: checkSuperAdmin(state),
  hasSendbirdToken: hasSendbirdToken(state)
})

const mapDispatchToProps = dispatch => {
  return {
    fetchData: props => {
      dispatch(fetchAllSettings(props.selectedPropertyID))
    },
    selectProperty: propertyId => {
      dispatch(changeProperty(propertyId))
      dispatch(fetchAllSettings(propertyId))
    },
    selectCompany: companyId => {
      dispatch(selectCompany(companyId))
    },
    fetchCompaniesPropertiesAndSelect: (companyId, history) => {
      dispatch(fetchCompaniesPropertiesAndSelect(companyId, history))
    },
    getPropertyInfo: (propertyId, url, history) => {
      dispatch(fetchPropertyInfo(propertyId, url, history))
    },
    refetchData: props => {}
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withFetchData(AppContainer)))
