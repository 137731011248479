// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import Flex, { FlexItem } from '@Common/Flex'
import BaseCard from '@Common/Cards/BaseCard'
import CustomCircularProgress from '../../Common/CustomCircularProgress'
import type { Theme } from '../../../theme'
import { Description } from './styles'

type Props = {
  theme: Theme,
  total: number,
  active: number,
  percentActive: number
}

export class UserActivationCard extends React.Component<Props> {
  getContent = () => {
    const { theme, total, percentActive } = this.props
    return (
      <Flex fullWidth alignCenter justifySpaceBetween>
        <FlexItem>
          <Description>
            To view a users activity <br />
            communicate with them through <br />
            zego the user must activate <br />
            their account.
          </Description>
        </FlexItem>
        <FlexItem>
          <CustomCircularProgress
            variant="static"
            progressCaption={`of ${total} users`}
            progressText={`${percentActive}%`}
            infoText={'ACTIVATED'}
            value={percentActive}
            size={84}
            thickness={4}
            backgroundColor={({ theme }) => theme.palette.secondary.main1}
            underlayColor={theme.palette.secondary.light3}
          />
        </FlexItem>
      </Flex>
    )
  }
  render() {
    return (
      <BaseCard
      columnStart={1}
      columnSpan={2}
      rowStart={1}
      rowSpan={3}
        title="USER ACTIVATION"
        content={this.getContent}
      />
    )
  }
}

export default withTheme(UserActivationCard)
