// @flow
import React from 'react'
import ChangeSetPoint from '../../../Common/Devices/Thermostat/ChangeSetPoints'
import ChangeMode from '../../../Common/Devices/Thermostat/ChangeMode'
import type { ThermostatContentProps } from './types'

const ThermostatContent = ({
  componentMode,
  modeToChangeTo,
  heatSetpointToChangeTo,
  coolSetpointToChangeTo,
  currentThermostatMode,
  changeThermostatMode,
  changeSetPoint
}: ThermostatContentProps) => {
  if (componentMode === 'CHANGEMODE') {
    return (
      <ChangeMode
        modeToChangeTo={modeToChangeTo}
        changeThermostatMode={changeThermostatMode}
      />
    )
  }

  if (componentMode === 'CHANGESETPOINT') {
    return (
      <ChangeSetPoint
        heatSetpointToChangeTo={heatSetpointToChangeTo}
        coolSetpointToChangeTo={coolSetpointToChangeTo}
        currentThermostatMode={currentThermostatMode}
        changeSetPoint={changeSetPoint}
      />
    )
  }
  return null
}

export default ThermostatContent
