import { combineReducers } from 'redux'
import { eventsReducer as events } from 'zego-shared/store/events'
import { postsReducer as posts } from 'zego-shared/store/posts'
import { communityThreadReducer as thread } from 'zego-shared/store/communityThread'

const CommunityFeed = combineReducers({
  events,
  posts,
  thread
})

export default CommunityFeed
