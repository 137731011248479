import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuList from '@material-ui/core/MenuList'
import LogoNav from '../../../images/logo_nav.png'

export const MenuIcon = styled('span')`
  width: 45px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;

  svg {
    align-self: center;
    margin-left: 4px;

    path {
      fill: red;
      stroke: red;
    }
  }

`

export const NavItem = styled(({ color, ...other }) => (
  <MenuItem {...other} classes={{ selected: 'selected' }} />
))`
  width: 216px;
  padding: 0px;
  height: ${props => (!props.home ? '3em' : '4em')};

  &&.selected {
  background-color: ${({ home, theme }) =>
    !home && theme.palette.secondary.light};
  }
  &&.selected ${MenuIcon} {
    background-color: ${({ home, theme }) =>
      !home && theme.palette.primary.green.mint};
  }
  &:hover {
    background: ${({ home, theme }) => !home && theme.palette.primary.navy.spaceBlue};
    box-shadow: none;
  }
  &:hover ${MenuIcon} {
    background: ${({ home, theme }) => !home && theme.palette.primary.navy.midnight};
    color: ${({ home, theme }) =>
      !home && theme.palette.secondary.contrastText};
    transition: all 0.3s;
`

export const NavList = styled(MenuList)`
  height: 100%;
  overflow: hidden;
`

export const NavText = styled(ListItemText)`
  span {
    color: ${({ theme, selected }) =>
      selected ? theme.palette.primary.navy.midnight : theme.palette.common.white};
    display: flex;
    justify-content: flex-end;
  }
`

export const NavLink = styled(Link)`
  text-decoration: none;
`

export const NavMenu = styled(Drawer)`
  && .drawerpaper {
    position: fixed;
    white-space: nowrap;
    width: 216px;
    transition: width 0.3s;
    background-color: ${({ theme }) => theme.palette.primary.navy.twilight};
  }

  && .drawerpaperclose {
    overflow-x: hidden;
    transition: width 0.3s;
    width: 45px;
  }

  && .drawerpaper ${NavItem} {
    margin-left: 0px;
    transition: all 0.3s;
  }

  && .drawerpaperclose ${NavItem} {
    margin-left: -170px;
    transition: all 0.3s;
  }
`

export const IconSection = styled('div')`
  background: ${({ theme }) => theme.palette.primary.dark};
  position: absolute;
  width: 45px;
  height: 100%;
  right: 0;
`

export const BottomNav = styled('div')`
  position: absolute;
  bottom: 0;
`

export const UnreadDot = styled('div')`
  width: 0.35em;
  height: 0.35em;
  border-radius: 100%;
  position: absolute;
  right: 1.2em;
  top: 0.75em;
  background: ${({ theme }) => theme.palette.error.dark};
`

export const Logo = styled('div')`
  width: 26px;
  height: 26px;
  background: url(${LogoNav});
  background-size: 26px 26px;
  align-self: center;
  margin-right: 2px;
`
