import React, { useState, useEffect } from 'react'
import ScrollDownButton from '@Common/ScrollDownButton'
import Loader from '@Common/Loader'
import { GridItem } from '@Common/Grid'
import { FlexItem } from '@Common/Flex/FlexDirection'
import Avatar from '@Common/Avatar'
import { getDate } from 'zego-shared/utils/date'
import ReSyncPmsButton from '../../UserManagement/ReSyncPmsButton'
import { withTheme } from 'emotion-theming'
import MultiCircularProgress from '@Common/MultiCircularProgress'
import moment from 'moment'
import { PropagateLoader } from 'react-spinners'
import {
  StyledTable,
  StyledColumn,
  StyledCell,
  StyledLink,
  StyledEmailLink,
  StyledChip,
  StyledFilterText,
  StyledClearText,
  Loading,
  ShowMoreBtn,
  ResidentHappinessCard,
  ResidentHappinessContent,
  CardHeader,
  LegendRow,
  LegendLabel,
  HistoryChart,
  HistoryChartItem,
  BarChart,
  AfterTableSpacing,
  FilterChipsWrapper
} from '../styles'

const ResidentSummary = (props) => {

  const {
    residentsCount,
    residents,
    propertyId,
    theme,
    chatEnabled,
    isFetching,
    filteredResidentCount,
    residentSummary,
    fetchResidentUsersAction,
    fetchNextResidentUsersAction,
    leaseRenewalEnabled,
    residentSurveysEnabled
  } = props

  const [currentPage, setCurrentPage] = useState(1)
  const [selectedFilters, setFilters] = useState({ 'account_status': [] })
  const [showLoader, setShowLoader] = useState(false)

  const summaryFilters = { 'account_status': ['active', 'pending', 'inactive'] }

  const statuses = {
    not_applicable: 'NA',
    not_interested: 'Not interested',
    interested: 'Interested',
    renewed: 'Renewed',
    communication_sent: 'Contacted',
    blocked: 'Blocked'
  }

  const getStatusName = (id) => {
    return statuses[id]
  }

  useEffect(() => {
    if (currentPage > 1) {
      fetchNextResidentUsersAction({ propertyId, page: currentPage, params: selectedFilters })
    }
    // eslint-disable-next-line
  }, [currentPage])

  useEffect(() => {
    setShowLoader(false)
  }, [residents])

  if (isFetching) {
    return <Loader />
  }

  const renderContactLinks = (resident) => {
    const chatLink = <StyledLink to={`communication/chat/live-chat/users/${resident?.user_id}`}> Chat </StyledLink>
    const emailLink = resident?.email ? <StyledEmailLink href={`mailto:${resident?.email}`}> Email </StyledEmailLink> : ''
    return (
      <>
        {emailLink} {chatEnabled && (<span> | {chatLink}</span>)}
      </>
    )
  }

  const clearFilters = () => {
    setFilters({ 'account_status': [] })
    setCurrentPage(1)
    setShowLoader(true)
    fetchResidentUsersAction({ propertyId })
  }

  const createRows = () => {
    const formattedRows = residents.map((rowData, rowIndex) => {
      const unit = rowData.units[0]
      const formattedRow = {
        ID: (
          <FlexItem flex={0.8}>
            <StyledLink to={`/properties/${propertyId}/residents/profile/${rowData.user_id}`}>
              <Avatar size="2.2em" picture={rowData.picture} />
            </StyledLink>
          </FlexItem>
        ),
        Resident: <StyledLink to={`/properties/${propertyId}/residents/profile/${rowData.user_id}`}> {rowData.name || rowData.email} </StyledLink>,
        Unit: unit ? <StyledLink to={`/properties/${propertyId}/units/${unit.id}/user-management`}> {unit.name} </StyledLink> : '',
        Empty: "",
        Status: rowData.account_status,
        LastActive: rowData.last_active_time ? getDate(rowData.last_active_time) : '--',
        LeaseRenewalStatus: rowData.lease_interest_status ? getStatusName(rowData.lease_interest_status) : 'NA',
        Contact: renderContactLinks(rowData)
      }
      if (!leaseRenewalEnabled) {
        delete formattedRow.LeaseInterestStatus
      }
      return formattedRow

    })

    const styledRows = formattedRows.map((rowData, rowIndex) => {
      return Object.entries(rowData).map(([key, value], index) => {
        return { key: key, value: (<StyledCell index={index + 1}>{value}</StyledCell>) }
      })
    })

    return styledRows
  }

  const columns = () => {
    const columnNames = leaseRenewalEnabled ?
      ["Resident", "", "Unit", "", "Status", "Last Active", "Lease Renewal Status", "Contact"] :
      ["Resident", "", "Unit", "", "Status", "Last Active", "Contact"]

    return columnNames.map((columnName, index) => {
      return {
        value: <StyledColumn index={index + 1}> {columnName} </StyledColumn>
      }
    })
  }

  const handleChipClick = (filterType, filterValue) => {
    let selectedValues = selectedFilters[filterType]

    // toggle filters
    if (selectedValues?.indexOf(filterValue) !== -1) {
      selectedValues = selectedValues.filter(filter => filter !== filterValue)
    } else {
      selectedValues.push(filterValue)
    }

    let filters = {}
    filters[filterType] = selectedValues

    setFilters({ ...filters })
    setCurrentPage(1)
    setShowLoader(true)
    fetchResidentUsersAction({ propertyId, page: 1, params: filters })
  }

  const filterChipGroup = (summaryFilters) => {
    // map label to status
    const chips = []

    for (const [filterType, filterValues] of Object.entries(summaryFilters)) {
      const selectedValues = selectedFilters?.[filterType] || []

      if (Array.isArray(filterValues)) {
        for (const index in filterValues) {
          const value = filterValues[index]
          let color = "primary"

          if (selectedValues.includes(value)) {
            color = "secondary"
          }

          chips.push(
            <StyledChip
              theme={theme}
              variant="outlined"
              color={color}
              label={value}
              onClick={() => { handleChipClick(filterType, value) }} />
          )

        }
      } else {

        let color = "primary"
        if (filterValues === selectedValues) {
          color = "secondary"
        }

        chips.push(
          <StyledChip
            theme={theme}
            variant="outlined"
            color={color}
            label={filterValues}
            onClick={() => { handleChipClick(filterType, filterValues) }} />
        )
      }
    }

    return <FilterChipsWrapper>{chips}</FilterChipsWrapper>
  }

  const getStackChartValues = summary => {
    return ['Promoter', 'Passive', 'Detractor', 'NoResponse'].map(bucket => (summary && summary[bucket]) || 0)
  }

  const renderBarChart = month => {
    return (<HistoryChartItem>
      <BarChart value={100 * (residentSummary[month].NoResponse || 0) / totalResidents} color={theme.palette.neutrals.coolGray} />
      <BarChart value={100 * (residentSummary[month].Promoter || 0) / totalResidents} color={theme.palette.secondary.main1} />
      <BarChart value={100 * (residentSummary[month].Passive || 0) / totalResidents} color={theme.palette.alternates.brightBlue} />
      <BarChart value={100 * (residentSummary[month].Detractor || 0) / totalResidents} color={theme.palette.secondary.pink.deepRed} />
      <div>{moment.utc(month).format('MMM')}</div>
    </HistoryChartItem>)
  }

  const showMore = residents.length < filteredResidentCount
  const handleShowMore = () => {
    setCurrentPage(currentPage + 1)
    setShowLoader(true)
  }

  const months = Object.keys(residentSummary || []).sort();
  const currentSummary = residentSummary && residentSummary[months[months.length - 1]];
  const totalResidents = months.reduce((acc, month) => Math.max(acc, Object.keys(residentSummary[month]).reduce((acc, key) => acc + residentSummary[month][key], 0)), 0)

  return (
    <>
      <GridItem columnStart={1} columnSpan={12} rowStart={1} rowSpan={2}>
        {residentSurveysEnabled &&
          <ResidentHappinessCard>
            <CardHeader>Resident Review Statistics</CardHeader>
            <ResidentHappinessContent>
              <MultiCircularProgress
                variant="static"
                progressCaption="Residents"
                progressText={residentsCount}
                infoText="Overall Resident Score"
                lgFont
                underlayColor={theme.palette.neutrals.coolGray}
                size={124}
                thickness={6}
                values={getStackChartValues(currentSummary)}
                stackColors={[
                  theme.palette.secondary.pink.deepRed,
                  theme.palette.alternates.brightBlue,
                  theme.palette.secondary.main1
                ]}
              />
              <FlexItem>
                <LegendRow color={theme.palette.secondary.main1}>Review Score: <LegendLabel>Happy</LegendLabel></LegendRow>
                <LegendRow color={theme.palette.alternates.brightBlue}>Review Score: <LegendLabel>Neutral</LegendLabel></LegendRow>
                <LegendRow color={theme.palette.secondary.pink.deepRed}>Review Score: <LegendLabel>Unhappy</LegendLabel></LegendRow>
                <LegendRow color={theme.palette.neutrals.coolGray}>Have not taken a survey</LegendRow>
              </FlexItem>
              <HistoryChart>
                {months.map(month => renderBarChart(month))}
              </HistoryChart>
            </ResidentHappinessContent>
          </ResidentHappinessCard>
        }
      </GridItem>
      <GridItem columnStart={12} columnSpan={1} rowStart={5} rowSpan={1}>
        <ReSyncPmsButton />
      </GridItem>
      <GridItem columnStart={1} columnSpan={10} rowStart={5} rowSpan={1}>
        <div>
          <StyledFilterText>{filteredResidentCount} Filtered Residents </StyledFilterText>
          <StyledClearText onClick={clearFilters}> Clear Filters</StyledClearText>
        </div>
        {filterChipGroup(summaryFilters)}
      </GridItem>
      <GridItem columnStart={1} columnSpan={12} rowStart={6} rowSpan={10}>
        <StyledTable
          noDataText="No Residents"
          flex={[0.1, 1, 1.5, 1, 0.7, 0.7, 1.3]}
          columns={columns()}
          rows={createRows()}
        />
        {showLoader &&
          <Loading>
            <PropagateLoader color={theme.palette.secondary.main1} />
          </Loading>
        }
        {showMore &&
          <ShowMoreBtn
            id="showMoreBtn"
            disableRipple
            onClick={handleShowMore}>
            Show More  <ScrollDownButton />
          </ShowMoreBtn>
        }
        <AfterTableSpacing />
      </GridItem>
    </>
  )
}

export default withTheme(ResidentSummary)
