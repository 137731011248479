// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import { withRouter } from 'react-router-dom'
import type { Match } from 'react-router-dom'
import Flex from '@Common/Flex'
import CircleWithText from '@Common/Cards/CircleWithText'
import CustomCircularProgress from '../../Common/CustomCircularProgress'
import { CenteredText } from './styles'
import type { BrowserHistory } from 'history'
import type { Theme } from '../../../theme'

type Props = {
  data: Object,
  history: BrowserHistory,
  theme: Theme,
  match: Match
}

const PropertySmartCardContent = ({
  data: { smartUnitsCount, allUnitsCount, lowBatteriesCount },
  history,
  match: { params },
  theme
}: Props) => {
  return (
    <Flex fullHeight fullWidth>
      <Flex fullHeight fullWidth>
        <Flex flex={1} justifyLeft alignCenter>
          <div>
            <CustomCircularProgress
              variant="static"
              progressCaption={`of ${allUnitsCount} units`}
              progressText={`${smartUnitsCount}`}
              infoText="SMART UNITS"
              value={smartUnitsCount}
              size={87}
              thickness={4}
              backgroundColor={({ theme }) => theme.palette.neutrals.coolGray}
              underlayColor={theme.palette.secondary.main1}
              onClick={() =>
                params.propertyId
                  ? history.push(
                      `/properties/${params.propertyId}/smart/units?type=smart`
                    )
                  : undefined
              }
            />
          </div>
        </Flex>
        <Flex
          alignCenter
          fullWidth
          fullHeight
          flex={2}
          justifyCenter
          style={{
            background: theme.palette.neutrals.lightGray,
            marginRight: '-1.5em',
            marginTop: '0.1em'
          }}>
          <div>
            <CircleWithText
              dataCount={lowBatteriesCount || 0}
              backgroundColor={({ theme }) => theme.palette.primary.dark}
              textColor={({ theme }) => theme.palette.background.default}
              onClick={() =>
                params.propertyId
                  ? history.push(
                      `/properties/${params.propertyId}/smart/battery`
                    )
                  : undefined
              }
            />
            <CenteredText>LOW BATTERIES</CenteredText>
          </div>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default withRouter(withTheme(PropertySmartCardContent))
