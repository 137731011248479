import { combineReducers } from 'redux'
import { residentEngagementReducer as residentEngagement } from './residentEngagement'
import { residentActiveReducer as residentActive } from './residentActive'

const analytics = combineReducers({
  residentEngagement,
  residentActive
})

export default analytics
