// @flow

import React from 'react'
import Flex from '@Common/Flex'
import { FooterLink } from './styles'
import CommunicationChat from '../../images/communication_chat.png'
import CommunicationMessage from '../../images/communication_message.png'
import CommunicationFeed from '../../images/communication_feed.png'
import type { CommunicationCardPropsArray } from './types'
import { FooterDescription } from './styles'

const getCommunicationCardData = (
  selectedPropertyID: number,
  groupMessageTodayCount: number,
  communityFeedEnabled: boolean,
  groupMessagingEnabled: boolean,
  hasGroupMessagingPermission: boolean,
  chatEnabled: boolean,
  hasLiveChatPermission: boolean,
  upcomingEventsCount: number
): CommunicationCardPropsArray => ({
  chat: {
    columnStart: 4,
    title: 'Chat',
    description: `Stay connected with residents using our one-on-one,
    real-time chat platform. Residents receive chat messages in
    the Zego Resident app as push notifications or text messages
    if they haven’t downloaded the app.`,
    footer: chatEnabled ? (
      hasLiveChatPermission ? (
        <Flex direction="column" fullWidth justifyRight>
          <FooterLink
            to={`/properties/${selectedPropertyID}/communication/chat/live-chat`}
            elementId="liveChatBtn">
            Live Chat
          </FooterLink>
        </Flex>
      ) : (
        <FooterLink
          to="#"
          disabled
          elementId="disabledViewAllLiveChatText">
          Live Chat is not available for your role
        </FooterLink>
      )
    ) : (
      <FooterLink
        disabled to="#"
        elementId="liveChatDisabledBtn">
        Chat is currently not enabled for your property
        <FooterDescription>
          Please contact your Zego Success Manager if you would like to learn
          more.
        </FooterDescription>
      </FooterLink>
    ),
    imageSrc: CommunicationChat,
    circleText: null,
    circleCount: null
  },
  message: {
    circleText: groupMessagingEnabled ? 'sent today' : null,
    circleCount: groupMessagingEnabled ? groupMessageTodayCount : null,
    columnStart: 7,
    title: 'Group Messaging',
    description: `Manage all your group messaging here. You can send emails,
    texts, and in app notifications to custom groups, create scheduled or
    recurring messages, and save frequently used messages as
    templates.`,
    footer: groupMessagingEnabled ? (
      hasGroupMessagingPermission ? (
        <FooterLink
          to={`/properties/${selectedPropertyID}/communication/group-messaging`}
          elementId="viewAllGmBtn">
          View All
        </FooterLink>
      ) : (
        <FooterLink
          to="#"
          disabled
          elementId="disabledViewAllGmText">
          Group Messaging is not available for your role
        </FooterLink>
      )
    ) : (
      <FooterLink
        disabled to="#"
        elementId="gmDisabledTxt">
        Group Messaging is currently not enabled for your property
        <FooterDescription>
          Please contact your Zego Success Manager if you would like to learn
          more.
        </FooterDescription>
      </FooterLink>
    ),
    imageSrc: CommunicationMessage
  },
  feed: {
    circleText: communityFeedEnabled ? 'upcoming events' : null,
    circleCount: communityFeedEnabled ? upcomingEventsCount : null,
    columnStart: 10,
    title: 'Community Feed',
    description: `Cultivate a thriving and active community. Easily
    communicate events and announcements that encourage
    residents to post, comment, and engage with their community.`,
    footer: communityFeedEnabled ? (
      <FooterLink
        to={`/properties/${selectedPropertyID}/communication/community-feed`}
        elementId="viewAllFeedBtn">
        View All
      </FooterLink>
    ) : (
      <FooterLink
        disabled to="#"
        elementId="feedDisabledTxt">
        Community Feed is currently not enabled for your property
        <FooterDescription>
          Please contact your Zego Success Manager if you would like to learn
          more.
        </FooterDescription>
      </FooterLink>
    ),
    imageSrc: CommunicationFeed
  }
})

export default getCommunicationCardData
