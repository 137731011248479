import moment from 'moment'
import {
  UPDATE,
  updateThermostatActions,
  updateLockActions,
  updateDeviceNameActions,
  updateSwitchActions,
  DELETE_CODE_SUCCESS,
  EDIT_CODE_SUCCESS,
  CREATE_CODE_START,
  CREATE_CODE_SUCCESS,
  EXTEND_CODE_SUCCESS,
  CREATE_CODE_ERROR,
  CLEAR_DEVICES,
  fetchLockCodesSuccess
} from '../actions'
import { UPDATE_PREFIX, SUCCESS } from '../../helpers/actions/constants'

const byId = (state = {}, action) => {
  switch (action.type) {
    case `${UPDATE_PREFIX}${UPDATE}${SUCCESS}`:
      return state
    case updateThermostatActions.updateOptimistcallyAction().type: {
      return updateDevice(state, action)
    }
    case updateLockActions.updateOptimistcallyAction().type: {
      return updateDevice(state, action)
    }
    case fetchLockCodesSuccess().type:
      const { payload: { id, lockCodes }} = action
      const updater = transformLockCodes(lockCodes)
      return updateLockCodes(state, id, updater)

    case CREATE_CODE_START: {
      const { id, tempCodeId, codeType } = action
      const updater = addTempCode(tempCodeId, codeType)
      return updateLockCodes(state, id, updater)
    }

    case CREATE_CODE_SUCCESS: {
      const { id, tempCodeId, codeInfo } = action
      const updater = updateCode(tempCodeId, codeInfo)
      return updateLockCodes(state, id, updater)
    }
    case CREATE_CODE_ERROR: {
      const { id, tempCodeId } = action
      const updater = removeCode(tempCodeId)
      return updateLockCodes(state, id, updater)
    }
    case EDIT_CODE_SUCCESS: {
      const { id, codeId, codeInfo } = action
      const updater = updateCode(codeId, codeInfo)
      return updateLockCodes(state, id, updater)
    }
    case EXTEND_CODE_SUCCESS: {
      const { id, codeId, metaData } = action
      const updater = updateCode(codeId, metaData)
      return updateLockCodes(state, id, updater)
    }
    case DELETE_CODE_SUCCESS: {
      const { id, codeId } = action
      const updater = removeCode(codeId)
      return updateLockCodes(state, id, updater)
    }

    case `${updateDeviceNameActions.updateRevertAction().type}`:
    case `${updateDeviceNameActions.updateOptimistcallyAction().type}`: {
      const device = state[action.id]
      return updateDeviceName(device, state, action)
    }

    case `${updateSwitchActions.updateOptimistcallyAction().type}`:
    case `${updateSwitchActions.updateRevertAction().type}`: {
      return updateDevice(state, action)
    }

    case CLEAR_DEVICES:
      return {}

    default:
      return state
  }
}

const updateDevice = (state, action) => {
  const device = state[action.id]
  const device_timestamp = moment.utc().format()
  return device
    ? {
        ...state,
        [action.id]: {
          ...device,
          last_state: {
            ...device.last_state,
            device_timestamp,
            data: {
              ...device.last_state.data,
              ...action.attributes
            }
          }
        }
      }
    : state
}

const updateDeviceName = (device, state, action) => ({
  ...state,
  [action.id]: {
    ...device,
    name: action.name
  }
})

const updateLockCodes = (state, id, updater) => {
  const currentCodes = state[id].udf.lock_codes || []

  return {
    ...state,
    [id]: {
      ...state[id],
      udf: {
        ...state[id].udf,
        lock_codes: updater(currentCodes)
      }
    }
  }
}

const transformLockCodes = lockCodes => () => {
  return lockCodes.map(lockCode => ({
    verified: lockCode.confirmed,
    user_id: lockCode.user_id,
    sweep_timeout: lockCode.sweep_timeout,
    resource_code_type: lockCode.type,
    resource_code_id: lockCode.id,
    lock_code: lockCode.code,
    expiration: lockCode.expiration,
    created_at: lockCode.created_at
  }))
}

const addTempCode = (tempCodeId, codeType) => currentCodes => [
  ...currentCodes,
  {
    resource_code_id: tempCodeId,
    resource_code_type: codeType,
    lock_code: ' - - - - - -'
  }
]

const updateCode = (codeId, codeInfo) => currentCodes => {
  const {
    resource_code_id,
    resource_code_type,
    code: lock_code,
    expiration
  } = codeInfo

  return currentCodes.map(code => {
    if (code.resource_code_id !== codeId) return code

    return {
      ...code,
      lock_code,
      resource_code_id,
      resource_code_type,
      expiration
    }
  })
}

const removeCode = codeId => currentCodes =>
  currentCodes.filter(code => code.resource_code_id !== codeId)

export default byId
