import { createFetchActions } from '../../helpers/actions/creators'

export const PROPERTY_BATTERY_LEVELS_SECTION = 'PROPERTYBATTERYLEVELS'
export const UNIT_BATTERY_LEVELS_SECTION = 'UNITBATTERYLEVELS'

export const {
  fetchAction,
  fetchStartAction: fetchPropertyBatteryLevelsStart,
  fetchSuccessAction: fetchPropertyBatteryLevelsSuccess,
  fetchErrorAction: fetchPropertyBatteryLevelsError
} = createFetchActions(PROPERTY_BATTERY_LEVELS_SECTION)

export const fetchPropertyBatteryLevels = id => fetchAction({ id })

export const {
  fetchAction: fetchUnitBatteryLevelsAction,
  fetchStartAction: fetchUnitBatteryLevelsStart,
  fetchSuccessAction: fetchUnitBatteryLevelsSuccess,
  fetchErrorAction: fetchUnitBatteryLevelsError
} = createFetchActions(UNIT_BATTERY_LEVELS_SECTION)

export const fetchUnitBatteryLevels = id => fetchUnitBatteryLevelsAction({ id })
