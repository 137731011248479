import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import Flex from '@Common/Flex'
import Avatar from '@Common/Avatar'
import {
  EventWrapper,
  EventTitleWrapper,
  AttendeesWrapper,
  DateWrapper,
  EventDateWrapper,
  EventInfoWrapper,
  EventDayStyled,
  EventMonthStyled,
  EventButtonWrapper,
  Underline,
  ButtonStyled
} from './styles'

const Event = ({
  event,
  rsvpToEvent,
  authToken,
  color,
  theme,
  match: { url }
}) => {
  return (
    <Link
      to={`${url.split('/events')[0]}/events/${window.btoa(event.id)}`}
      style={{ width: '100%' }}>
      <EventWrapper>
        <EventDateWrapper color={color}>
          <EventDayStyled>{event.day}</EventDayStyled>
          <EventMonthStyled>{event.month}</EventMonthStyled>
        </EventDateWrapper>
        <EventInfoWrapper>
          <Flex alignCenter paddingBottom="0.2em">
            {event.attendessImage && (
              <Avatar
                position={'static'}
                picture={event.attendessImage}
                size="0.625em"
              />
            )}
            <AttendeesWrapper>
              {event.attendeesCount} Attending
            </AttendeesWrapper>
          </Flex>
          <Flex>
            <EventTitleWrapper>{event.title}</EventTitleWrapper>
            <EventButtonWrapper>
              {!event.isAttending && (
                <ButtonStyled onClick={() => rsvpToEvent(authToken, event.id)}>
                  attend
                </ButtonStyled>
              )}
            </EventButtonWrapper>
          </Flex>
          <Underline />
          <DateWrapper>{event.dateText}</DateWrapper>
        </EventInfoWrapper>
      </EventWrapper>
    </Link>
  )
}

export default withRouter(withTheme(Event))
