import styled from '@emotion/styled'
import MaterialTextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import { convertHexToRGB } from '../../../../utils'

const contentWidth = '65%'
const messageContentHeight = '10em'

export const HeadBackground = styled('div')`
  background: ${({ theme, editMode }) =>
    editMode ? theme.palette.common.white : theme.palette.neutrals.lightGray};
  min-height: 19em;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;

  ${({ editMode, theme }) =>
    editMode &&
    `
    border-top: 0.1px solid ${theme.palette.neutrals.mediumGray};
    box-shadow: 0 1px 10px 0 ${convertHexToRGB(
      theme.palette.neutrals.warmGray
    )};
  `};

  & > div {
    display: flex;
    padding-top: 5em;
  }

  & > div > div:first-child {
    flex: 8;
    display: flex;
    flex-direction: column;

    & > *:last-child {
      flex: 2;
    }
  }
`

export const Body = styled('div')`
  margin-top: ${({ showEditMode }) => (showEditMode ? '0em' : '-2em')};
  .deliveryMethod {
    position: relative;
    height: 9em;

    &:last-child {
      display: flex;
      padding-top: 1.5em;
    }
  }
`

export const Wrapper = styled('div')`
    padding-left: 2em;
    .recipient-row {
      & > * {
        align-self:center;
      }
    }

    .row-head {
      & > *:first-child {
        font-size: 190%;
      }
      & > *:not(*:first-child) {
        color: ${({ theme }) => theme.palette.secondary.main1};
      }
    }

    .searchIcon {
      margin-top: 0.5em;
    }

    .wrapper-flex {
      width: 100%;
      position: absolute;
      left: 0;
      margin-bottom: 4em;
    }

    ${Body} > *, ${HeadBackground} > * {
        width: ${contentWidth};
        margin: 0 auto;
    }
`

export const Recipients = styled('div')`
  color: ${({ theme }) => theme.palette.secondary.light2};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`

export const Title = styled('div')`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: ${({ theme }) => theme.custom.font.size.xxl};
`

export const MessageDetails = styled('div')`
  color: ${({ theme }) => theme.palette.secondary.light2};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 1.5em;

  & > * {
    margin-left: 3em;
  }
`

export const MessageDetail = styled('span')`
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  text-transform: capitalize;
`

export const SenderName = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  margin-top: 2.5em;
`

export const SentDateTime = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.grey.main};
`

export const Message = styled('p')`
  margin-top: 3em;
  line-height: 1.5em;
`

export const DeliveryMethodBackground = styled('div')`
  height: 9em;
  &:before {
    content: ' ';
    height: 9em;
    width: 100%;
    position: absolute;
    background: ${({ theme }) => theme.palette.primary.navy.twilight};
    left: 0;
    z-index: -1;
  }
`

export const DeliveryMethodText = styled('p')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.common.white};
  line-height: 1.7em;
`

export const DeliveryMethodTitle = styled('div')`
  color: ${({ theme }) => theme.palette.primary.green.mint};
`

export const ProgressCircles = styled('div')`
  display: flex;
  height: 100%;
  align-items: center;
  & > span {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`

export const UnderLine = styled('div')`
  content: ' ';
  height: 2px;
  margin: 0.5em 0em;
  width: 2em;
  background: ${({ theme }) => theme.palette.primary.green.mint};
`

export const CenteredText = styled('div')`
  text-align: center;
  margin-top: 0.5em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.common.white};
`

export const TextField = styled(MaterialTextField)`
  width: 50%;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.palette.neutrals.warmGray};
  padding: 0 15px;
  & input {
    color: ${({ theme }) => theme.palette.text.lightGrey};
    padding-top: 8px;
  }
`

export const Break = styled('div')`
  height: 1em;
`

export const ButtonSet = styled('div')`
  display: flex;
  margin-right: -12em;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-left: 8em;
  padding-bottom: 2em;
  margin-top: -1.5em;
`

export const EditModeContainer = styled('div')`
  .edit-mode-recipients {
    height: 1em;
    width: 100%;
    & > div {
      width: 100%;
    }
    input {
      font-size: ${({ theme }) => theme.custom.font.size.sm};
    }
  }

  .edit-mode-title {
    width: 100%;
    input {
      font-size: ${({ theme }) => theme.custom.font.size.lg};
    }
  }
`

export const Content = styled('div')`
  height: ${messageContentHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.3em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  * {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }
  background: ${({ theme }) => theme.palette.neutrals.lightGray};
  &:before {
    content: ' ';
    left: 0;
    position: absolute;
    width: 100%;
    height: ${messageContentHeight};
    background: ${({ theme }) => theme.palette.neutrals.lightGray};
    box-shadow: ${({ theme }) =>
      `inset 1px -29px 16px -33px ${convertHexToRGB(
        theme.palette.neutrals.warmGray
      )}`};
  }
  .radioGroupEditMode {
    flex-direction: row;
  }

  .template-dropdown {
    border-radius: 3em;
    display: flex;
    border: 1px solid ${({ theme }) => theme.palette.neutrals.deepGray};
    padding: 0em 1em;

    & svg {
      position: absolute;
      right: 1em;
      top: 0.5em;
    }
  }
`

export const MessageEditorContainer = styled('div')`
  padding-top: 1.5em;
  & > div {
    width: 100%;
  }
  textarea {
    margin-top: 1.5em;
  }
`

export const SendDetailsWrapper = styled('div')`
  background: ${({ theme }) => theme.palette.common.white};
  position: absolute;
  padding: 1em;
  margin-top: 5%;
  z-index: 10;
  color: ${({ theme }) => theme.palette.common.black};
  width: auto;
  box-shadow: ${({ theme }) =>
    `0px 4px 5px 0px ${convertHexToRGB(theme.palette.neutrals.coolGray)}`};

  & > div {
    display: flex;
  }

  & > div > span {
    flex: 1;
    white-space: nowrap;
    padding-right: 1em;
    line-height: 2em;
    color: ${({ theme }) => theme.palette.grey.main};
  }

  & > div > span:first-child {
    text-transform: capitalize;
  }

  & > div > span:last-child {
    text-align: right;
    padding-right: 0em;
    padding-left: 1em;
    color: ${({ theme }) => theme.palette.common.black};
  }
`

export const Line = styled('div')`
  height: 1px;
  width: 130% !important;
  margin-left: -30%;
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: ${({ theme }) => theme.palette.neutrals.coolGray};
`

export const MessageHeader = styled('div')`
  padding-top: 2em;
  padding-bottom: 1em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.neutrals.warmGray};
`

export const StyledRow = styled('div')`
  height: 4em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;

  ${({ index, theme }) =>
    (index === 5 || index === 6) &&
    `
  color: ${theme.palette.primary.green.mint};
  justify-content: flex-end;
  `};

  ${({ index, theme }) =>
    (index === 3 || index === 4) &&
    `
    text-align: center;`};
`

export const StyledColumn = styled('div')`
  height: 4em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: center;
  white-space: nowrap;

  ${({ index, theme }) =>
    index === 2 &&
    `
    font-size: ${theme.custom.font.size.lg};
    color: ${theme.palette.common.black};
  `};

  ${({ index, theme }) =>
    (index === 3 || index === 4) &&
    `
  color: ${theme.palette.primary.green.mint};
  text-align: center;
  `};

  ${({ index, theme }) =>
    index === 5 &&
    `
    display: flex;
  justify-content: flex-end;
  `};
`

export const FormControlStyled = styled(FormControl)`
  margin-right: 150px;

  .radioGroupEditModeContent {
    display: flex;
    height: 100%;
    position: relative;
    flex-direction: column;

    span {
      height: 45%;
    }
  }
`