// @flow
import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import Flex from '@Common/Flex'
import { CommonIcon } from '@icons'
import DropDown from '@Common/DropDown'
import { CustomFormHelperText, DaysIcon } from '../../CreateGroupMessage/styles'
import { EventProps } from '../../types'
import {
  OffsetLabel,
  BeforeAndAfterDropDown,
  TextFieldOffset,
  OffsetWrapper
} from './styles'
import { css } from 'emotion'

const textFieldClass = css`
  width: 50%;
  margin-top: 0px;
`

const offsets = [
  { id: 'before', name: 'before' },
  { id: 'after', name: 'after' },
]

const EventUpdate = ({
  chooseEvent,
  defaultEvent,
  handleOffsetDays,
  offsetDays,
  selectedEvent,
  offsetBeforeAfter,
  showOffsetBeforeAfterDropdown,
  handleOffsetBeforeAfter,
  events,
  theme,
  displayText
}: EventProps) => {
  return (
    <Flex flex={2}>
      <Flex direction="column" flex={1}>
        <DropDown
          width="100%"
          classes={{ root: 'template-dropdown' }}
          data={events}
          IconComponent={() => (
            <CommonIcon
              name="arrow_down"
              width="1em"
              height="1em"
              fill={theme.palette.grey.main}
            />
          )}
          DropDownLabel="recurring"
          handleChange={chooseEvent}
          selectedItem={{ id: selectedEvent }}
          defaultOption={defaultEvent}
        />
        <CustomFormHelperText>
          *If you choose to use an event to automate this message, recipients
          will be automatically reset and sent to @all
        </CustomFormHelperText>
      </Flex>
      <OffsetWrapper
        direction={'column'}
        alignItemsCenter
        flexCenter
        displayFlex>
        <OffsetLabel>offset</OffsetLabel>
        <TextFieldOffset
          value={offsetDays}
          onChange={handleOffsetDays}
          type="number"
          className={`${textFieldClass}`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <DaysIcon>days</DaysIcon>
              </InputAdornment>
            ),
          }}
        />
      </OffsetWrapper>
      <BeforeAndAfterDropDown
        width="100%"
        classes={{ root: 'template-dropdown' }}
        data={offsets}
        IconComponent={() => (
          <CommonIcon
            name="arrow_down"
            width="1em"
            height="1em"
            fill={theme.palette.grey.main}
          />
        )}
        handleChange={handleOffsetBeforeAfter}
        selectedItem={
          offsets.find((offset) => offset.id === offsetBeforeAfter) ||
          offsets[0]
        }
      />
    </Flex>
  )
}
export default EventUpdate
