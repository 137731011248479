// @flow
import { isValidChatFile } from './validationUtils'

// check if sender of current message is also the sender of last message
export const currentSenderSentLastMessage = (
  messageList: Object[],
  currentMessageIndex: number
) => {
  return (
    currentMessageIndex !== 0 &&
    messageList[currentMessageIndex].user.user_id ===
      messageList[currentMessageIndex - 1].user.user_id
  )
}

const separateChatFileAndMessage = (message = '') => {
  let msg = message.split(' ')
  let file = msg.pop()
  msg = msg.join(' ')

  if (!isValidChatFile(file)) {
    msg += ` ${file}`
    file = null
  }

  return [msg, file]
}

export const extractFile = (message: string) =>
  separateChatFileAndMessage(message)[1]

export const extractMessage = (message: string) =>
  separateChatFileAndMessage(message)[0]

