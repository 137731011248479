import {
  fetchMimoWorkOrderSubmitStart,
  fetchMimoWorkOrderSubmitError,
  fetchMimoWorkOrderSubmitSuccess,
  fetchIntegratedWorkOrderSubmitStart,
  fetchIntegratedWorkOrderSubmitError,
  fetchIntegratedWorkOrderSubmitSuccess
} from '../actions'

const initialState = {
  isSubmitting: false,
  isError: '',
  isPdfUploadAllowed: false
}
const submitMimoWorkOrders = (state = initialState, { type, payload, error }) => {

  switch (type) {
    case fetchMimoWorkOrderSubmitStart().type:
      return {
        ...state,
        isSubmitting: true
      }
    case fetchMimoWorkOrderSubmitSuccess().type: {
      return {
        ...state,
        isSubmitting: false,
        isPdfUploadAllowed: true
      }
    }
    case fetchMimoWorkOrderSubmitError().type:
      return {
        ...state,
        isSubmitting: false,
        isError: error
      }

    case fetchIntegratedWorkOrderSubmitStart().type:
      return {
        ...state,
        isSubmitting: true
      }
    case fetchIntegratedWorkOrderSubmitSuccess().type: {
      return {
        ...state,
        isSubmitting: false,
      }
    }
    case fetchIntegratedWorkOrderSubmitError().type:
      return {
        ...state,
        isSubmitting: false,
        isError: error
      }

    default:
      return state
  }
}
export default submitMimoWorkOrders
