export const UPDATE_PROPERTY = 'UPDATE_PROPERTY'
export const UPDATE_PROPERTY_OPTIMISTIC = 'UPDATE_PROPERTY_OPTIMISTIC'
export const UPDATE_PROPERTY_SUCCESS = 'UPDATE_PROPERTY_SUCCESS'
export const UPDATE_PROPERTY_ERROR = 'UPDATE_PROPERTY_ERROR'

export const GET_PROPERTY_PMS_STATUS = 'GET_PROPERTY_PMS_STATUS'
export const GET_PROPERTY_PMS_STATUS_SUCCESS = 'GET_PROPERTY_PMS_STATUS_SUCCESS'
export const GET_PROPERTY_PMS_STATUS_ERROR = 'GET_PROPERTY_PMS_STATUS_ERROR'

export const GET_PROPERTY_TIMEZONE = 'GET_PROPERTY_TIMEZONE'
export const GET_PROPERTY_TIMEZONE_SUCCESS = 'GET_PROPERTY_TIMEZONE_SUCCESS'
export const GET_PROPERTY_TIMEZONE_ERROR = 'GET_PROPERTY_TIMEZONE_ERROR'

export const updateProperty = (propertyID, attributes) => ({
  type: UPDATE_PROPERTY,
  propertyID,
  attributes
})

export const updatePropertyOptimistic = (propertyID, attributes) => ({
  type: UPDATE_PROPERTY_OPTIMISTIC,
  propertyID,
  attributes
})

export const updatePropertySuccess = attributes => ({
  type: UPDATE_PROPERTY_SUCCESS,
  attributes
})

export const updatePropertyError = (error, attributes) => ({
  type: UPDATE_PROPERTY_ERROR,
  error,
  attributes
})

export const getPropertyPmsStatus = () => ({
  type: GET_PROPERTY_PMS_STATUS
})

export const getPropertyTimezone = () => ({
  type: GET_PROPERTY_TIMEZONE
})
