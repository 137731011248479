// @flow

import React from 'react'
import OfflineChat from './OfflineChat'
import { waitForElement, runOnElementChange, getSubject } from './utils'

import type { User } from './types'

type Props = {
  user: User
}

type State = {
  isOnline: boolean
}

const sfHostname = window._env_.REACT_APP_SF_HOSTNAME || ''
const sfAgentHostname = window._env_.REACT_APP_SF_AGENT_HOSTNAME || ''
const sfOrgId = window._env_.REACT_APP_SF_ORG_ID || ''
const sfDeploymentId = window._env_.REACT_APP_SF_DEPLOYMENT_ID
const sfLiveagentUrl = window._env_.REACT_APP_SF_LIVEAGENT_URL || ''
const sfLiveagentSupportUrl = window._env_.REACT_APP_SF_LIVEAGENT_SUPPORT_URL
const sfESWName = window._env_.REACT_APP_SF_ESW_NAME
const sfESWDevName = window._env_.REACT_APP_SF_ESW_DEV_NAME
const sfButtonId = window._env_.REACT_APP_SF_BUTTON_ID
const sfRecordTypeId = window._env_.REACT_APP_SF_RECORD_TYPE_ID
// const sfLiveagentId = window._env_.REACT_APP_SF_LIVEAGENT_ID
const offlineMinimizedText = 'Contact Us!'

const updateUserInfo = (embedded_svc, user: User) => {
  const subject = getSubject(user || {})
  const prepopulatedPrechatFields = {
    Subject: subject,
    FullName: user.fullName,
    Zego_Name__c: user.fullName,
    FirstName: user.firstName,
    LastName: user.lastName,
    Email: user.email,
    Zego_Email__c: user.email
  }

  let extraPrechatFormDetails = [
    {
      label: 'Web Name',
      name: 'WebName',
      // value: user.fullName,
      displayToAgent: true,
      transcriptFields: ['Zego_Name__c']
    },
    {
      label: 'Email',
      name: 'Email',
      // value: user.email,
      displayToAgent: true,
      transcriptFields: ['Zego_Email__c']
    },
    {
      label: 'Description',
      name: 'Description',
      value: subject,
      displayToAgent: true
    },
    {
      label: 'Case Record Type',
      name: 'CaseRecordType',
      value: sfRecordTypeId,
      displayToAgent: true
    },
    {
      label: 'Origin',
      name: 'Origin',
      value: 'Admin Web',
      displayToAgent: true
    }
  ]

  let contactPrechatInfo = []

  let caseEntityFieldMaps = [
    {
      doCreate: true,
      doFind: false,
      fieldName: 'Subject',
      isExactMatch: false,
      label: 'Subject'
    },
    {
      doCreate: true,
      doFind: false,
      fieldName: 'Description',
      isExactMatch: false,
      label: 'Description'
    },
    {
      doCreate: true,
      doFind: false,
      fieldName: 'RecordTypeId',
      isExactMatch: false,
      label: 'Case Record Type'
    },
    {
      doCreate: true,
      doFind: false,
      fieldName: 'SuppliedName',
      isExactMatch: false,
      label: 'Full Name'
    },
    {
      doCreate: true,
      doFind: false,
      fieldName: 'SuppliedEmail',
      isExactMatch: false,
      label: 'Email'
    },
    {
      doCreate: true,
      doFind: false,
      fieldName: 'Origin',
      isExactMatch: false,
      label: 'Origin'
    }
  ]

  if (user.userId) {
    extraPrechatFormDetails = [
      {
        label: 'Full Name',
        name: 'FullName',
        value: user.fullName,
        displayToAgent: true,
        transcriptFields: ['Zego_Name__c']
      },
      {
        label: 'First Name',
        name: 'FirstName',
        value: user.firstName,
        displayToAgent: true
      },
      {
        label: 'Last Name',
        name: 'LastName',
        value: user.lastName,
        displayToAgent: true
      },
      {
        label: 'Subject',
        name: 'Subject',
        value: subject,
        displayToAgent: true
      },
      {
        label: 'User Id',
        name: 'UserId',
        value: user.userId,
        displayToAgent: true,
        transcriptFields: ['Zego_User_Id__c']
      },
      {
        label: 'Property Name',
        name: 'PropertyName',
        value: user.propertyName,
        displayToAgent: true,
        transcriptFields: ['Zego_Property_Name__c']
      },
      {
        label: 'Property Id',
        name: 'PropertyId',
        value: user.propertyId,
        displayToAgent: true,
        transcriptFields: ['Zego_Property_Id__c']
      },
      {
        label: 'Company Id',
        name: 'CompanyId',
        value: user.companyId,
        displayToAgent: true,
        transcriptFields: ['Zego_Company_Id__c']
      },
      {
        label: 'Company Name',
        name: 'CompanyName',
        value: user.companyName,
        displayToAgent: true,
        transcriptFields: ['Zego_Company_Name__c']
      },
      {
        label: 'PM Id',
        name: 'PMId',
        value: user.companyPmsId || 'Zego PM',
        displayToAgent: true,
        transcriptFields: ['Zego_PM_Id__c']
      },
      ...extraPrechatFormDetails
    ]

    contactPrechatInfo = [
      {
        entityName: 'Contact',
        showOnCreate: true,
        linkToEntityName: 'Case',
        linkToEntityField: 'ContactId',
        saveToTranscript: 'ContactId',
        entityFieldMaps: [
          {
            doCreate: true,
            doFind: true,
            fieldName: 'FirstName',
            isExactMatch: true,
            label: 'First Name'
          },
          {
            doCreate: true,
            doFind: true,
            fieldName: 'LastName',
            isExactMatch: true,
            label: 'Last Name'
          },
          {
            doCreate: true,
            doFind: true,
            fieldName: 'Email',
            isExactMatch: true,
            label: 'Email'
          },
          {
            doCreate: true,
            doFind: false,
            fieldName: 'Zego_PM_Id__c',
            isExactMatch: false,
            label: 'PM Id'
          }
        ]
      }
    ]

    caseEntityFieldMaps = [
      ...caseEntityFieldMaps,
      {
        doCreate: true,
        doFind: false,
        fieldName: 'Zego_User_Id__c',
        isExactMatch: false,
        label: 'User Id'
      },
      {
        doCreate: true,
        doFind: false,
        fieldName: 'Zego_Property_Name__c',
        isExactMatch: false,
        label: 'Property Name'
      },
      {
        doCreate: true,
        doFind: false,
        fieldName: 'Zego_Property_Id__c',
        isExactMatch: false,
        label: 'Property Id'
      },
      {
        doCreate: true,
        doFind: false,
        fieldName: 'Zego_Company_Id__c',
        isExactMatch: false,
        label: 'Company Id'
      },
      {
        doCreate: true,
        doFind: false,
        fieldName: 'Zego_Company_Name__c',
        isExactMatch: false,
        label: 'Company Name'
      },
      {
        doCreate: true,
        doFind: false,
        fieldName: 'Zego_PM_Id__c',
        isExactMatch: false,
        label: 'PM Id'
      }
    ]

    embedded_svc.addEventHandler('onHelpButtonClick', () => {
      // no way to hook to avoid pre-chat form
      waitForElement(
        '.embeddedServiceSidebarForm .embeddedServiceSidebarButton.startButton'
      ).then(el => {
        el.click()
      })
    })
  } else {
    embedded_svc.addEventHandler('onHelpButtonClick', () => {
      const firstNameSelector =
        '.embeddedServiceSidebarFormField .input.FirstName'
      const lastNameSelector =
        '.embeddedServiceSidebarFormField .input.LastName'
      const suppliedNameSelector =
        '.embeddedServiceSidebarFormField .input.SuppliedName'
      const subjectSelector = '.embeddedServiceSidebarFormField .input.Subject'

      if (user == null || user.userId == null) {
        runOnElementChange(suppliedNameSelector, el => {
          const updateWebName = () => {
            const firstName = document.querySelector(firstNameSelector)
            const lastName = document.querySelector(lastNameSelector)

            const suppliedName = document.querySelector(suppliedNameSelector)
            const subject = document.querySelector(subjectSelector)

            if (
              firstName instanceof HTMLInputElement &&
              lastName instanceof HTMLInputElement &&
              suppliedName instanceof HTMLInputElement &&
              subject instanceof HTMLInputElement
            ) {
              const fnameValue = firstName.value
              const lnameValue = lastName.value
              const fullName = fnameValue + ' ' + lnameValue

              suppliedName.value = fullName
              suppliedName.dispatchEvent(new Event('change'))

              const newSubjectValue = getSubject({ fullName })
              subject.value = newSubjectValue
              subject.dispatchEvent(new Event('change'))

              updateUserInfo(embedded_svc, {
                firstName: fnameValue,
                lastName: lnameValue,
                fullName: suppliedName
              })
            }
          }

          const firstName = document.querySelector(firstNameSelector)
          if (firstName) firstName.addEventListener('change', updateWebName)
          const lastName = document.querySelector(lastNameSelector)
          if (lastName) lastName.addEventListener('change', updateWebName)
        })
      }
    })
  }

  embedded_svc.settings.prepopulatedPrechatFields = prepopulatedPrechatFields
  embedded_svc.settings.extraPrechatFormDetails = extraPrechatFormDetails
  embedded_svc.settings.extraPrechatInfo = [
    ...contactPrechatInfo,
    {
      entityName: 'Case',
      showOnCreate: true,
      saveToTranscript: 'CaseId',
      entityFieldMaps: caseEntityFieldMaps
    }
  ]
}

const initESW = (gslbBaseURL, user, stateFN) => {
  const embedded_svc = window.embedded_svc
  embedded_svc.settings.displayHelpButton = true
  embedded_svc.settings.language = 'en-US'

  embedded_svc.settings.defaultMinimizedText = 'Chat with an Expert'
  embedded_svc.settings.disabledMinimizedText = 'Agent Offline'

  embedded_svc.settings.loadingText = 'Loading...'
  // embedded_svc.settings.storageDomain = 'zego.io' // (Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

  // Settings for Chat
  //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
  // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
  // Returns a valid button ID.
  //}

  updateUserInfo(embedded_svc, user)

  //embedded_svc.settings.fallbackRouting = [] //An array of button IDs, user IDs, or userId_buttonId
  embedded_svc.settings.offlineSupportMinimizedText = offlineMinimizedText

  embedded_svc.settings.enabledFeatures = ['LiveAgent']
  embedded_svc.settings.entryFeature = 'LiveAgent'

  const messageSelector =
    '.embeddedServiceHelpButton .helpButton .helpButtonEnabled .helpButtonLabel .message'

  runOnElementChange(messageSelector, el => {
    const selector = document.querySelector('.embeddedServiceHelpButton')
    if (el.innerText === offlineMinimizedText) {
      stateFN(false)
      if (selector) selector.style.display = 'none'
    } else {
      stateFN(true)
      if (selector) selector.style.display = 'block'
    }
  })

  embedded_svc.init(
    sfLiveagentUrl,
    sfLiveagentSupportUrl,
    gslbBaseURL,
    sfOrgId,
    sfESWName,
    {
      baseLiveAgentContentURL: `https://${sfHostname}.salesforceliveagent.com/content`,
      deploymentId: sfDeploymentId,
      buttonId: sfButtonId,
      baseLiveAgentURL: `https://${sfAgentHostname}.salesforceliveagent.com/chat`,
      eswLiveAgentDevName: sfESWDevName || sfESWName,
      isOfflineSupportEnabled: true
    }
  )
}

export default class LiveAgent extends React.Component<Props, State> {
  state = {
    isOnline: true
  }

  componentDidMount() {
    const { user } = this.props
    if (!user || !user.userId) return
    const liveagent = window.liveagent
    if (liveagent) {
      const s = document.createElement('script')
      s.setAttribute('src', `${sfLiveagentUrl}/embeddedservice/5.0/esw.min.js`)
      s.onload = () => {
        initESW(null, user, isOnline => {
          const { isOnline: stateIsOnline } = this.state
          if (isOnline !== stateIsOnline) {
            this.setState({ isOnline })
          }
        })
      }
      if (document.body) {
        document.body.appendChild(s)
      }
    }
  }

  componentDidUpdate() {
    const { user } = this.props
    const embedded_svc = window.embedded_svc
    if (embedded_svc) {
      updateUserInfo(embedded_svc, user)
    }
  }

  componentWillUnmount() {
    if (window.embedded_svc) {
      delete window.embedded_svc
    }
    if (window.liveagent) {
      delete window.liveagent
    }
  }

  static getDerivedStateFromError() {
    return {}
  }

  render() {
    const { isOnline } = this.state
    const { user } = this.props
    if (!user || !user.userId) return false

    return isOnline ? (
      false
    ) : (
      <OfflineChat
        offlineMinimizedText={offlineMinimizedText}
        orgId={sfOrgId}
        recordTypeId={sfRecordTypeId}
        user={user}
        apiUrl={`${sfLiveagentUrl}/servlet/servlet.WebToCase?encoding=UTF-8`}
      />
    )
  }
}
