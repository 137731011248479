//@flow
import styled from '@emotion/styled'
import { IconButton as MaterialIconButton } from '@material-ui/core'

export const IconButton = styled(MaterialIconButton)`
  padding: 0;
  margin-left: 10px;
  height: 30px;
  width: 30px;
  &:hover {
    background: none;
  }
`
export const Link = styled('a')`
  color: ${({ theme }) => theme.palette.text.primary};
`
