// @flow
import React from 'react'
import type { Node } from 'react'
import { withTheme } from 'emotion-theming'
import { withRouter } from 'react-router-dom'

//components
import BaseCard from '@Common/Cards/BaseCard'
import FooterButton from '@Common/Cards/CardButton'
import CallToActionCardContent from '@Common/Cards/CallToActionCardContent'

import type { Theme } from '../../../theme'

type Props = {
  active: boolean,
  count: number,
  countText: string,
  content?: () => Node,
  theme: Theme
}

export class SmartCard extends React.Component<Props> {
  getContent = () => {
    const { active, count = 0, countText, content } = this.props
    return active
      ? content
      : () => (
          <CallToActionCardContent
            data={countText}
            dataCount={count}
            title="Smart Apartments"
            bodyText="Lock the door, change the temperature and turn on and off lights with just a click."
          />
        )
  }

  getFooter = () => {
    const { active, theme } = this.props
    return !active &&
      <FooterButton
        text="activate"
        background={theme.palette.blue.main}
      />
  }

  render() {
    const { active } = this.props
    return (
      <BaseCard
        toggleFooterOnMouseOver={active}
        title="Zego Smart"
        columnStart={1}
        columnSpan={4}
        rowStart={9}
        rowSpan={4}
        content={this.getContent()}
        headerRight={null}
        footer={this.getFooter}
      />
    )
  }
}

export default withRouter(withTheme(SmartCard))
