import { connect } from 'react-redux'
import queryString from 'query-string'
import withFetchData from 'zego-shared/views/withFetchData'
import Chat from './Chat'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { withRouter } from 'react-router-dom'
import { fetchChatChannelsByType } from 'zego-shared/store/chat/channels/actions'
import { fetchPinnedChannels } from 'zego-shared/store/chat/pinnedChannels/actions'
import {
  shouldRefetchChannels,
  getResidentUserFromChannel
} from '../../../store/Chat/channelSelectors'
import {
  getUsersFromMessages,
  hasData
} from '../../../store/Chat/messageSelectors'
import { getUserRole } from 'zego-shared/store/authorization/selectors'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'

const nonActiveFilters = ['unread', 'pinned']
const fetchingSelector = createIsFetchingSelector(['chatchannelsbytype'])

const mapStateToProps = (state, { history, match: { params } }) => {
  const { userId } = params
  return {
    isFetching: fetchingSelector(state),
    users: getUsersFromMessages(state),
    shouldRefetchChannels: shouldRefetchChannels(state, userId),
    hasData: hasData(state),
    type: params.channelType,
    userId: getResidentUserFromChannel(state),
    history,
    propertyId: params.propertyId,
    userRole: getUserRole(state)
  }
}

const mapDispatchToProps = (
  dispatch,
  { shouldRefetchChannels, location, match: { params } }
) => {
  const { channelType, userId } = params
  return {
    fetchData: () => {
      const { filter } = queryString.parse(location.search)
      dispatch(
        fetchChatChannelsByType(
          channelType,
          userId,
          !(nonActiveFilters.includes(filter) && userId == null)
        )
      )
      dispatch(fetchPinnedChannels())
    },
    refetchData: () => {
      if (shouldRefetchChannels) {
        dispatch(fetchChatChannelsByType(channelType, userId, true))
      }
    },
    displayErrorMessage: (message) => dispatch(showSnackbar(message, 'error')),
  }
}

const ChatWithFetchData = withFetchData(Chat)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChatWithFetchData)
)
