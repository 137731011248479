import { call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken } from '../../authorization/selectors'
import {
  fetchSummary as fetchSummaryAction,
  refreshSummary as refreshSummaryAction,
  fetchSummaryStart,
  refreshSummaryStart,
  fetchSummarySuccess,
  refreshSummarySuccess,
  fetchSummaryError,
  refreshSummaryError
} from '../actions'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import { noPropertySelected } from '../../../utils/messages'
import { getPropertyId } from '../../select/selectors'

const getActions = isRefreshing => ({
  start: isRefreshing ? refreshSummaryStart : fetchSummaryStart,
  success: isRefreshing ? refreshSummarySuccess : fetchSummarySuccess,
  error: isRefreshing ? refreshSummaryError : fetchSummaryError
})

export function* fetchSummary(isRefreshing, { id }) {
  const actions = getActions(isRefreshing)

  try {
    yield put(actions.start())
    const authToken = yield select(getToken)
    const propertyID = id ? id : yield select(getPropertyId)

    if (propertyID === -1) throw new Error(noPropertySelected)

    const response = yield call(api.getPropertySummary, authToken, propertyID)
    yield put(actions.success(response))
  } catch (error) {
    yield put(actions.error(error))
    log(`Failed to fetch summary`)
  }
}

function* watchFetchSummary() {
  yield takeLatest(fetchSummaryAction().type, fetchSummary, false)
}

function* watchRefreshDevices() {
  yield takeLatest(refreshSummaryAction().type, fetchSummary, true)
}

export default [watchFetchSummary(), watchRefreshDevices()]
