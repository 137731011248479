// @flow
import React from 'react'
import PaginatedTable from '../../../Common/PaginatedTable'
import { StyledColumn } from './styles'
import { Break } from '../CreateGroupMessage/styles'

type Props = {
  items: Object[],
  currentPage: number,
  total: number,
  fetchPage: Function,
  searchField: Function,
  perPage: number
}

const RecipientsTable = ({
  items,
  currentPage,
  perPage,
  fetchPage,
  total,
  searchField
}: Props) => (
  <React.Fragment>
    <PaginatedTable
      elementId="recipientsTable"
      flex={[0.5, 2.4, 0.5, 0.5, 6, 1]}
      total={total}
      columns={[
        {
          value: <StyledColumn index={1} />
        },
        {
          value: (
            <StyledColumn
              id="recipientsCount"
              style={{ marginLeft: '-20%' }}
              index={2}>
              {total} Recipients
            </StyledColumn>
          )
        },
        {
          value: <StyledColumn index={3}>Method</StyledColumn>
        },
        { value: <StyledColumn index={4}>Group</StyledColumn> },
        {
          value: <StyledColumn index={5}>{searchField()}</StyledColumn>,
          span: 2
        }
      ]}
      rows={items}
      rowsPerPage={perPage}
      currentPage={currentPage - 1}
      changePage={fetchPage}
    />
    <Break />
    <Break />
    <Break />
    <Break />
  </React.Fragment>
)

export default RecipientsTable
