// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import OktaSignInWidget from './OktaSignInWidget'
import { OKTA_LOGIN_OPTION } from './constants'
import queryString from 'query-string'
import Loader from '@Common/Loader'
import { detect } from 'detect-browser'
import LoginSection from './LoginSection'
import PasswordRecoveryRequest from './PasswordRecoveryRequest'
import PasswordReset from './PasswordReset'
import Flex from '@Common/Flex'
import { Card, MainFlex } from './styles'
import Snackbar from '../Snackbar/SnackbarContainer'
import type { Match, RouterHistory } from 'react-router-dom'
import type { BrowserLocation } from 'history'
type ModeType = 'login' | 'passwordRecoveryRequest' | 'passwordReset'

type State = {
  mode: ModeType,
  email: string,
  password: string,
  passwordConfirm: string,
  working: boolean,
  oktaLoginFlag: boolean
}

type Props = {
  featureFlag: Object,
  isAuth: boolean,
  token: ?string,
  hasRedirect: boolean,
  match: Match,
  location: BrowserLocation,
  mode?: ModeType,
  loginEmail: (string, string) => mixed,
  loginSocial: (string, Object) => mixed,
  fetchResourcesAndRedirectAfterLogin: RouterHistory => void,
  requestResetPass: string => mixed,
  resetPass: ({ }, RouterHistory) => mixed,
  resetSuccess: Object,
  handleSocialFailure: () => void,
  error: string,
  history: RouterHistory,
  theme: any,
  loginToken: string => mixed,
  loginFailed: ?boolean
}

class Login extends React.Component<Props, State> {
  state = {
    mode: this.props.mode || 'login',
    email: '',
    password: '',
    passwordConfirm: '',
    working: false,
    oktaLoginFlag: false,
  }

  componentDidUpdate() {
    const { featureFlag } = this.props
    const { oktaLoginFlag } = this.state
    const flag = (featureFlag?.ld?.variation(OKTA_LOGIN_OPTION, false))
    if (oktaLoginFlag !== flag)
      this.setState({ oktaLoginFlag: flag })
  }

  componentDidMount() {
    const { token, loginToken } = this.props
    if (token) loginToken(token)
  }

  changeMode = (mode: ModeType) => {
    this.setState(oldState => {
      return {
        ...oldState,
        password: '',
        passwordConfirm: '',
        mode
      }
    })
  }

  handleLogin = (token) => {
    this.setWorking(true)
    const { loginEmail } = this.props
    const { email, password } = this.state
    if (token && typeof (token) == "string") {
      loginEmail("", token)
    } else {
      loginEmail(email, password)
    }
  }

  setWorking = (working: boolean) => {
    this.setState({ working })
  }

  handleSocialLogin = (provider: string, userObj: {}) => {
    const { loginSocial } = this.props
    loginSocial(provider, userObj)
  }

  handleChange = ({
    currentTarget: { id, value }
  }: SyntheticEvent<HTMLInputElement>) => {
    this.setState(oldState => {
      return {
        ...oldState,
        [id]: value
      }
    })
  }

  handlePasswordResetRequest = () => {
    this.setWorking(true)
    const { email } = this.state
    const { requestResetPass } = this.props
    requestResetPass(email)
  }

  handlePasswordReset = () => {
    this.setWorking(true)
    const { password, passwordConfirm } = this.state
    const { resetPass, location, history } = this.props
    const { reset_token: token } = queryString.parse(location.search)
    resetPass(
      {
        password,
        password_confirmation: passwordConfirm,
        token
      },
      history
    )
  }

  handleAction = () => {
    const { mode } = this.state
    switch (mode) {
      case 'passwordRecoveryRequest':
        this.handlePasswordResetRequest()
        break
      case 'passwordReset':
        this.handlePasswordReset()
        break
      default:
        this.handleLogin()
    }
  }

  getModeUI = () => {
    const { mode, email, password, passwordConfirm, working, oktaLoginFlag } = this.state
    const { handleSocialFailure, error } = this.props

    switch (mode) {
      case 'passwordRecoveryRequest':
        return (
          <PasswordRecoveryRequest
            changeMode={this.changeMode}
            handleChange={this.handleChange}
            handleResetRequest={this.handlePasswordResetRequest}
            email={email}
            error={error}
            working={working}
          />
        )

      case 'passwordReset':
        return (
          <PasswordReset
            changeMode={this.changeMode}
            handleChange={this.handleChange}
            handleReset={this.handlePasswordReset}
            password={password}
            passwordConfirm={passwordConfirm}
            error={error}
            working={working}
          />
        )

      default:
        if (oktaLoginFlag) {
          return (
            <OktaSignInWidget
              onSuccess={this.onSuccess}
              onError={error}
              changeMode={this.changeMode}
            />
          )
        }
        else {
          return (
            <LoginSection
              changeMode={this.changeMode}
              handleChange={this.handleChange}
              handleLogin={this.handleLogin}
              handleSocialLogin={this.handleSocialLogin}
              handleSocialFailure={handleSocialFailure}
              email={email}
              password={password}
              error={error}
              working={working}
            />
          )
        }
    }
  }

  detectBrowser = () => {
    const { hasRedirect } = this.props
    if (hasRedirect) return true
    const { name } = detect()
    const supportedBrowsers = ['chrome', 'firefox']
    return supportedBrowsers.includes(name)
  }

  onSuccess = (res) => {
    this.handleLogin(res.accessToken.value)
  };

  render() {
    const {
      isAuth,
      error,
      loginFailed,
      token,
      history,
      fetchResourcesAndRedirectAfterLogin
    } = this.props
    const { working } = this.state

    if (isAuth) {
      // sagas will handle redirect so just show login until the sagas handle it
      fetchResourcesAndRedirectAfterLogin(history)
    }

    if (error && working) {
      this.setWorking(false)
    }

    if (token && !loginFailed) {
      return <Loader />
    }

    return (
      <React.Fragment>
        <MainFlex
          onKeyPress={({ key }) => key === 'Enter' && this.handleAction()}
          fullWidth
          fullHeight
          alignCenter
          justifyCenter>
          <Card>
            <Flex fullHeight direction="column" alignCenter justifySpaceBetween>
              {this.getModeUI()}
            </Flex>
          </Card>
        </MainFlex>
        <Snackbar />
      </React.Fragment>
    )
  }
}

export default withTheme(Login)
