// @flow
import React, { Fragment } from 'react'
import type { Node } from 'react'
import { withTheme } from 'emotion-theming'
import {
  Head,
  Underline,
  RightPanel,
  CustomCard,
  Body,
  Footer,
  FooterNote,
  CustomCardContent,
  StyledTitle,
} from './styles'

type Props = {
  title: string,
  underlineLength: string,
  underlineStyle: string,
  imageSrc: string,
  showShadow: boolean,
  toggleFooterOnMouseOver: boolean,
  columnStart: number,
  columnSpan: number,
  rowStart: number,
  rowSpan: number,
  type: ?string,
  isSummary?: string,
  footerNote?: Node,
  header?: () => Node,
  headerRight?: () => Node,
  content?: () => Node,
  footer?: () => Node,
  onMouseLeave: (event: SyntheticEvent<HTMLButtonElement>) => void,
  onMouseEnter: (event: SyntheticEvent<HTMLButtonElement>) => void,
  leftContent: () => {},
  rightContent: () => {},
  className?: string
}

type State = {
  showFooter: boolean,
}

class BaseCard extends React.Component<Props, State> {
  static defaultProps = {
    toggleFooterOnMouseOver: false,
    imageSrc: null,
    onMouseLeave: () => {},
    onMouseEnter: () => {},
    type: null,
  }

  state = {
    showFooter: this.props.toggleFooterOnMouseOver ? false : true,
  }

  showFooter = () => {
    this.setState({ showFooter: true })
  }

  hideFooter = () => {
    this.setState({ showFooter: false })
  }

  render() {
    const {
      title,
      headerRight,
      leftContent,
      rightContent,
      footer,
      footerNote,
      imageSrc,
      columnStart,
      columnSpan,
      rowStart,
      rowSpan,
      onMouseEnter,
      onMouseLeave,
      toggleFooterOnMouseOver,
      type,
      header,
      underlineLength,
      underlineStyle,
      isSummary,
      showShadow = true,
      className
    } = this.props

    return (
      <CustomCard
        showShadow={showShadow}
        type={type}
        columnStart={columnStart}
        columnSpan={columnSpan}
        rowStart={rowStart}
        isSummary={isSummary}
        rowSpan={rowSpan}
        onMouseEnter={toggleFooterOnMouseOver ? this.showFooter : onMouseEnter}
        onMouseLeave={toggleFooterOnMouseOver ? this.hideFooter : onMouseLeave}
        className={className}>
        <CustomCardContent isSummary={isSummary} imageSrc={imageSrc}>
          <div>
            {rightContent && <Body rightContent>{rightContent()}</Body>}
          </div>
          <div>
            {
              <Fragment>
                <Head>
                  {header ? (
                    header()
                  ) : (
                    <React.Fragment>
                      <StyledTitle>{title}</StyledTitle>
                      {headerRight && <RightPanel>{headerRight()}</RightPanel>}
                    </React.Fragment>
                  )}
                </Head>
                {(title || underlineLength) && (
                  <Underline
                    underlineStyle={underlineStyle}
                    length={underlineLength}
                  />
                )}
              </Fragment>
            }
            {leftContent && <Body>{leftContent()}</Body>}
            {footer && (
              <Footer>
                {footerNote && <FooterNote>{footerNote}</FooterNote>}
                {this.state.showFooter && footer()}
              </Footer>
            )}
          </div>
        </CustomCardContent>
      </CustomCard>
    )
  }
}

export default withTheme(BaseCard)
