import { objectToFormData } from 'object-to-formdata'
import get from 'lodash/get'
import axios from 'axios'
import moment from 'moment'
import queryString from 'query-string'
import { generate as shortid } from 'shortid'
import log from '../logger'
import { buildAPIUrl, createHTTPHeader } from './utils'

let API_URL,
  LOGIN_URL,
  REFRESH_TOKEN_URL,
  BASE_PACKAGES_URL,
  BASE_UNITS_URL,
  BASE_DEVICES_URL,
  BASE_USERS_URL,
  BASE_CARDS_URL,
  BASE_EVENT_URL,
  BASE_SCHEDULE_URL,
  BASE_PROPERTIES_URL,
  BASE_SEARCH_URL,
  BASE_COMPANIES_URL,
  BASE_CHAT_URL,
  BASE_VISITORS_URL,
  APP_TYPE

const MOCHI_ENDPOINT = window?._env_?.REACT_APP_MOCHI_ENDPOINT
const MIMO_ENDPOINT = window?._env_?.REACT_APP_MIMO_ENDPOINT
const AMENITIES_API_URL = window?._env_?.REACT_APP_AMENITIES_API_URL

const NETWORK_ERR_MSG = 'Network error'
const LOCAL_ERR_MSG = 'Something went wrong'
const UNKNOWN_ERR_MSG = 'Unknown error'
const HISTORY_REQUEST_DATE = moment().subtract(1, 'month').format('YYYY-MM-DD')

const deviceTypeMap = {
  all: ['door_lock', 'thermostat', 'switch'],
  lock: 'door_lock',
  switch: 'switch',
  thermostat: 'thermostat'
}

const NON_DISPLAY_EVENT_CODES = [
  0,
  3,
  4,
  100,
  200,
  2200,
  4000,
  5001,
  5002,
  5003,
  6001,
  6003,
  20001,
  20002,
  20003
]

const getStartEndtimesPair = date => {
  date = moment.unix(date)

  return [date.startOf('day').unix(), date.endOf('day').unix()]
}

const getParamsForDateFilter = (date, threadType) => {
  if (!date) return undefined

  const [start_datetime, end_datetime] = getStartEndtimesPair(date)
  const start_key =
    threadType === 'feed' ? 'start_datetime' : 'start_event_datetime'
  const end_key = threadType === 'feed' ? 'end_datetime' : 'end_event_datetime'
  return {
    [start_key]: start_datetime,
    [end_key]: end_datetime
  }
}

const api = {
  configure: (url, appType) => {
    API_URL = url
    LOGIN_URL = `${API_URL}/login`
    REFRESH_TOKEN_URL = `${API_URL}/login/refresh`
    BASE_UNITS_URL = `${API_URL}/units`
    BASE_DEVICES_URL = `${API_URL}/devices`
    BASE_USERS_URL = `${API_URL}/users`
    BASE_CARDS_URL = `${API_URL}/cards`
    BASE_EVENT_URL = `${API_URL}/events`
    BASE_SCHEDULE_URL = `${API_URL}/schedules`
    BASE_PROPERTIES_URL = `${API_URL}/properties`
    BASE_SEARCH_URL = `${API_URL}/v2/properties`
    BASE_COMPANIES_URL = `${API_URL}/companies`
    BASE_CHAT_URL = `${API_URL}/chat`
    BASE_PACKAGES_URL = `${API_URL}/packages`
    BASE_VISITORS_URL = `${API_URL}/visitors`
    APP_TYPE = appType
  },
  sendBranchSms: (appType, phoneNumber) => {
    const url = `${API_URL}/webhooks/branch_io_invite`
    const body = {
      phone_number: phoneNumber,
      app_type: appType,
      link: window.sms_link
    }

    return api.post(url, body)
  },
  resetPassViaEmail: email => {
    const url = `${API_URL}/password-reset-request`

    const body = {
      user_or_email: email
    }

    return api.post(url, body)
  },
  resetPassword: body => {
    const url = `${API_URL}/password-reset`
    return api.post(url, body)
  },
  checkActivationToken: token => {
    const url = `${BASE_USERS_URL}/check_token/${token}`
    return api.post(url)
  },
  search: (authToken, searchTerms, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_SEARCH_URL}/${propertyId}/search/${searchTerms}`
    return api.get(url, headers)
  },
  getSearchHistory: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/search_history`
    return api.get(url, headers)
  },
  addSearchHistory: (authToken, propertyId, resourceKey, resourceValue) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const body = { search_key: resourceKey, search_value: resourceValue }
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/search_history`
    return api.put(url, body, headers)
  },
  getLegacyAmenitiesOfProperty: (authToken, propertyId, page) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/amenities?page_size=8&page=${page}`
    return api.getWithHeaders(url, headers)
  },
  searchLegacyAmenitiesOfProperty: (
    authToken,
    propertyId,
    searchTerm,
    queryParams
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/amenities/${searchTerm}?${queryString.stringify(
      queryParams
    )}`
    return api.getWithHeaders(url, headers)
  },
  getLegacyAmenity: (authToken, propertyId, amenityId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/amenities/${amenityId}`

    return api.get(url, headers)
  },
  createLegacyAmenity: (authToken, propertyId, update) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/amenities`
    return api.post(url, objectToFormData(update), headers)
  },
  updateLegacyAmenity: (authToken, propertyId, amenityId, update) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/amenities/${amenityId}`
    return api.put(url, objectToFormData(update), headers)
  },
  deleteLegacyAmenity: (authToken, propertyId, amenityId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/amenities/${amenityId}`
    return api.delete(url, headers)
  },
  searchRegisteredPetsOfProperty: (
    authToken,
    propertyId,
    searchTerm,
    queryParams
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/registration/pet/search/${searchTerm}?&page_size=8&${queryString.stringify(
      queryParams
    )}`
    return api.getWithHeaders(url, headers)
  },
  getPet: (authToken, petId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/pets/${petId}/registration/pet`
    return api.get(url, headers)
  },
  createPet: (authToken, propertyId, update) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/registration/pet`
    return api.post(url, objectToFormData(update), headers)
  },
  updatePet: (authToken, petId, update) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/pets/${petId}/registration/pet`
    return api.put(url, objectToFormData(update), headers)
  },
  getRegisteredPetsOfProperty: (authToken, propertyId, page, queryParams) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/registration/pet/summary?page=${page}&page_size=8&${queryString.stringify(
      queryParams
    )}`

    return api.getWithHeaders(url, headers)
  },
  getPetsSupportedColors: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/registration/pet/colors`
    return api.get(url, headers)
  },
  getPetsSupportedSizes: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/registration/pet/sizes`
    return api.get(url, headers)
  },
  getPetsSupportedStatuses: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/registration/pet/statuses`
    return api.get(url, headers)
  },
  getPetsSupportedTypes: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/registration/pet/types`
    return api.get(url, headers)
  },
  searchRegisteredVehiclesOfProperty: (
    authToken,
    propertyId,
    searchTerm,
    queryParams
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/registration/vehicle/search/${searchTerm}?&page_size=8&${queryString.stringify(
      queryParams
    )}`
    return api.getWithHeaders(url, headers)
  },
  getVehicle: (authToken, propertyId, vehicleId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/registration/vehicle/${vehicleId}`
    return api.get(url, headers)
  },
  createVehicle: (authToken, propertyId, update) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/registration/vehicle`
    return api.post(url, objectToFormData(update), headers)
  },
  updateVehicle: (authToken, propertyId, vehicleId, update) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/registration/vehicle/${vehicleId}`
    return api.put(url, objectToFormData(update), headers)
  },
  getRegisteredVehiclesOfProperty: (
    authToken,
    propertyId,
    page,
    queryParams
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/registration/vehicle?page=${page}&page_size=8&${queryString.stringify(
      queryParams
    )}`
    return api.getWithHeaders(url, headers)
  },
  getVehiclesSupportedColors: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/registration/vehicle/colors`
    return api.get(url, headers)
  },
  getVehiclesSupportedStates: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/registration/vehicle/states`
    return api.get(url, headers)
  },
  getVehiclesSupportedTypes: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/registration/vehicle/types`
    return api.get(url, headers)
  },
  getVehiclesCommonMakes: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/registration/vehicle/makes`
    return api.get(url, headers)
  },
  signUp: (token, data) => {
    const url = `${BASE_USERS_URL}/activate`
    const { password, firstName, lastName, email, phoneNumber } = data
    const body = {
      token,
      password,
      first_name: firstName,
      last_name: lastName,
      contact_email: email,
      phone_number: phoneNumber
    }

    return api.post(url, body)
  },
  signUpSocial: (token, data, provider) => {
    const url = `${BASE_USERS_URL}/activate`
    const body = {
      token,
      provider,
      data
    }

    return api.post(url, body)
  },
  loginEmail: (user_or_email, password, spoofUserId) => {
    let body = {};
    if (!user_or_email)
    {
      body.okta_token = password
    } else {
      body = {
        user_or_email,
        password
      }
      if (spoofUserId) {
        body.spoof_user_id = spoofUserId
      }
    }
    return api.post(LOGIN_URL, body)
  },
  loginSocial: (provider, data) => {
    let body = {
      data
    }
    return api.post(`${LOGIN_URL}/${provider}`, body)
  },
  loginToken: loginToken => {
    const body = {
      login_token: loginToken
    }
    return api.post(LOGIN_URL, body)
  },
  refreshToken: refresh_token => {
    let body = {
      refresh_token
    }
    return api.post(REFRESH_TOKEN_URL, body)
  },
  getDevices: (unitId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getDevicesUrl({ unit_id: unitId })

    return api.get(url, headers)
  },
  deleteDevice: (deviceId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = `${BASE_DEVICES_URL}/${deviceId}`
    return api.delete(url, headers)
  },
  getUnit: (unitId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getUnitsUrl(unitId)

    return api.get(url, headers)
  },
  getUnitResidentStats: (authToken, unitId) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    return api.get(`${BASE_UNITS_URL}/${unitId}/resident_stats`, headers)
  },
  getUnitResidentAnalytics: (authToken, unitId) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    return api.get(`${BASE_UNITS_URL}/${unitId}/resident_analytics`, headers)
  },
  getUser: (userId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getUserUrl({ userId, with_pms: true })

    return api.get(url, headers)
  },
  getUsers: (unitId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getUsersUrl({
      unit_id: unitId,
      roles: 'resident',
      page_size: 100
    })

    return api.get(url, headers)
  },
  getVisitorsForResident: (userId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(`${BASE_USERS_URL}/${userId}/visitors`, headers)
  },
  getVehiclesForResident: (userId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(`${BASE_USERS_URL}/${userId}/registration/vehicles`, headers)
  },
  uploadProfileImage: (userId, file, authToken) => {
    const url = `${BASE_USERS_URL}/${userId}/upload`
    let headers = createHTTPHeader(authToken, APP_TYPE)

    const data = new FormData()

    data.append('file', file, file.name)
    return api.post(url, data, headers)
  },
  getPropertyUsers: (propertyId, authToken, name) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getUsersUrl({
      property_id: propertyId,
      roles: 'property_staff,property_admin,company_admin',
      page_size: 100,
      name
    })

    return api.get(url, headers)
  },
  getPropertyResidents: (propertyId, authToken, page, name, params) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getUsersUrl({
      property_id: propertyId,
      roles: 'resident',
      status: 'current',
      page_size: 8,
      page,
      name,
      ...params
    })

    return api.getWithHeaders(url, headers)
  },
  getResidentSummary: (propertyId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    return api.get(`${BASE_PROPERTIES_URL}/${propertyId}/resident_summary`, headers)
  },
  getResidentManagementSurveys: (propertyId, authToken, page, params) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let queryParams

    if (params) {
      params.average_score = params.average_score.map(param => {
        switch (param) {
          case "unhappy":
            return "detractor"
          case "neutral":
            return "passive"
          case "happy":
            return "promoter"
        }
      })

      const npsParams = queryString.stringify({ nps_filters: params.average_score }, { arrayFormat: 'comma' })
      const completedParams = queryString.stringify({ completed_filters: params.surveys_taken }, { arrayFormat: 'comma' })
      queryParams = `${npsParams ? `&${npsParams}` : ''}${completedParams ? `&${completedParams.replace(/%20/g, "_")}` : ''}`
    }

    let url = `${BASE_PROPERTIES_URL}/${propertyId}/residents_with_survey_summary?page_size=8${page ? `&page=${page}` : ''}${queryParams ? queryParams : ''}`

    return api.getWithHeaders(url, headers)
  },
  getPropertyPmsStatus: (propertyId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    return api.get(`${BASE_PROPERTIES_URL}/${propertyId}/pms`, headers)
  },
  getPropertyBasicInfo: (propertyId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    return api.get(`${BASE_PROPERTIES_URL}/${propertyId}/info`, headers)
  },
  getPropertyResidentAnalytics: (authToken, propertyId) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    return api.get(
      `${BASE_PROPERTIES_URL}/${propertyId}/resident_analytics`,
      headers
    )
  },
  getResidentSurveys: (authToken, userId) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = `${BASE_USERS_URL}/${userId}/resident_surveys`
    return api.get(url, headers)
  },
  getResidentSurveyStats: (authToken, propertyId) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = `${BASE_PROPERTIES_URL}/${propertyId}/resident_survey_stats`
    return api.get(url, headers)
  },
  getAllVendors: (propertyId, authToken, page, name) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)

    return api.getWithHeaders(
      `${BASE_PROPERTIES_URL}/${propertyId}/vendors?page_size=8${page ? `&page=${page}` : ''}${name ? `&name=${name}` : ''}`, headers)
  },

  reSyncProperty: (propertyId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    return api.post(
      `${BASE_PROPERTIES_URL}/${propertyId}/pms/users`,
      null,
      headers
    )
  },

  createCommunityThreadComment: (authToken, propertyId, comment, threadId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/community/${threadId}/reply`

    const body = new FormData()

    body.append('body', comment)

    return api.post(url, body, headers)
  },
  deleteCommunityThreadComment: (authToken, propertyId, commentUrl) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/community/${commentUrl}`

    return api.delete(url, headers)
  },
  createCommunityThread: (authToken, propertyId, payload) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/community`

    const {
      file,
      title,
      message,
      type,
      event_end_datetime,
      event_datetime
    } = payload

    const body = new FormData()
    const threadType = type === 'events' ? 'event' : 'feed'

    body.append('body', message)
    body.append('type', threadType)
    body.append('title', title)
    body.append('event_end_datetime', event_end_datetime)
    body.append('event_datetime', event_datetime)

    if (file) {
      body.append('file', file)
    }

    return api.post(url, body, headers)
  },
  updateCommunityThread: (authToken, propertyId, payload, threadId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/community/${threadId}`

    const {
      file,
      title,
      message,
      type,
      event_end_datetime,
      event_datetime
    } = payload

    const body = new FormData()
    const threadType = type === 'events' ? 'event' : 'feed'

    body.append('body', message)
    body.append('type', threadType)
    body.append('title', title)
    body.append('event_end_datetime', event_end_datetime)
    body.append('event_datetime', event_datetime)

    if (file) {
      body.append('file', file)
    }

    return api.put(url, body, headers)
  },
  getCommunityThread: (authToken, propertyId, threadId) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/community/${threadId}`

    return api.get(url, headers)
  },
  attendEvent: (authToken, propertyId, threadId) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/community/${threadId}/rsvp`
    return api.post(url, {}, headers)
  },
  unattendEvent: (authToken, propertyId, threadId) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/community/${threadId}/rsvp`
    return api.delete(url, headers)
  },
  likePost: (authToken, propertyId, threadId) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/community/${threadId}/like`
    return api.post(url, {}, headers)
  },
  unlikePost: (authToken, propertyId, threadId) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/community/${threadId}/like`
    return api.delete(url, headers)
  },
  deleteCommunityThread: (authToken, propertyId, threadId) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/community/${threadId}`
    return api.delete(url, headers)
  },
  createUser: (authToken, payload) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = BASE_USERS_URL

    const { email, role, unitId, propertyId, companyId } = payload
    const body = {
      email,
      role,
      ...(unitId ? { units: [unitId] } : {}),
      ...(propertyId ? { properties: [propertyId] } : {}),
      ...(companyId ? { companies: [companyId] } : {})
    }

    return api.post(url, body, headers)
  },
  deleteUser: (resource, resourceId, userId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/users/${userId}`

    return api.delete(url, headers)
  },
  generateLockCode: (userId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_USERS_URL}/${userId}/lockcode`

    return api.post(url, {}, headers)
  },
  deleteLockCode: (userId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_USERS_URL}/${userId}/lockcode`

    return api.delete(url, headers)
  },
  sendLockCode: (userId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_USERS_URL}/${userId}/send_lock_code`

    return api.post(url, {}, headers)
  },
  getHistory: (authToken, unitID, deviceType, page) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getDeviceEventsUrl({ unitID, deviceType, page })

    return api.getWithHeaders(url, headers)
  },
  getWorkOrderHistory: (authToken, unitId) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getWorkOrdersUrl({ unitId })

    return api.getWithHeaders(url, headers)
  },
  updateUser: (user_id, attributes, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getUsersUrl(encodeURI(user_id))
    let body = attributes

    return axios
      .put(url, body, { headers })
      .then(response => response.data)
      .catch(handleError)
  },
  getWorkOrderCount: (unitId, authToken) => {
    const url = `${BASE_UNITS_URL}/${unitId}/work_order_count`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  getWorkOrderCategories: (unitId, authToken) => {
    const url = `${BASE_UNITS_URL}/${unitId}/work_order/categories`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  submitWorkOrder: (unitId, authToken, payload) => {
    const url = `${BASE_UNITS_URL}/${unitId}/work_order`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    const { message, categoryId, userId, appointment } = payload

    let body = {
      message,
      user_id: userId,
      category_id: categoryId,
      ...(appointment && { appointment })
    }

    return api.post(url, body, headers)
  },
  addFileToWorkOrder: (unitId, authToken, workOrderId, file) => {
    const url = `${BASE_UNITS_URL}/${unitId}/work_order/${workOrderId}/upload`
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const data = new FormData()

    data.append('file', {
      uri: file.uri,
      type: file.mime,
      name: 'image'
    })

    return api.post(url, data, headers)
  },
  getMarketplaceDeal: (marketplaceId, authToken) => {
    const url = `${BASE_CARDS_URL}/${marketplaceId}`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  getPaymentBalance: (userId, authToken) => {
    const url = `${BASE_USERS_URL}/${userId}/balance`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  getPaymentLink: authToken => {
    const url = `${BASE_USERS_URL}/payment-link`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  getPackages: (authToken, resource, resourceId, queryParams) => {
    const url = `${API_URL}/${resource}/${resourceId}/packages?${queryString.stringify(
      queryParams
    )}`

    const headers = createHTTPHeader(authToken, APP_TYPE)
    return api.getWithHeaders(url, headers)
  },
  searchPackages: (authToken, propertyId, term, queryParams) => {
    const url = `${BASE_PACKAGES_URL}/${propertyId}/admin_search/${term}?${queryString.stringify(
      queryParams
    )}`

    const headers = createHTTPHeader(authToken, APP_TYPE)
    return api.getWithHeaders(url, headers)
  },
  updatePackage: (authToken, id, data) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PACKAGES_URL}/${id}`

    const body = data
    return api.put(url, body, headers)
  },
  getMarketCards: (type, authToken) => {
    const url = `${BASE_CARDS_URL}?type=${type}`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  createMarketplaceDeal: (deal, type, propertyId, authToken) => {
    const url = `${BASE_CARDS_URL}`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    const body = {
      type,
      data: deal,
      name: deal.title,
      property_id: propertyId
    }

    return api.post(url, body, headers)
  },
  uploadMarketplaceDealImage: (image, cardId, authToken) => {
    const url = `${BASE_CARDS_URL}/${cardId}/upload_image`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    const data = new FormData()
    data.append('file', image)

    return api.post(url, data, headers)
  },
  updateMarketplaceDeal: (deal, type, authToken) => {
    const url = `${BASE_CARDS_URL}/${deal.id}`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    const body = {
      type,
      data: deal,
      name: deal.title
    }

    return api.put(url, body, headers)
  },
  getSettings: authToken => {
    const url = `${BASE_USERS_URL}/mobile_settings`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  getLeaseInterestStatus: (userId, authToken) => {
    const url = `${BASE_USERS_URL}/${userId}/lease_interest_status`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  updateLeaseInterestStatus: (userId, status_type, authToken) => {
    const url = `${BASE_USERS_URL}/${userId}/lease_interest_status/${status_type}`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.put(url, {}, headers)
  },
  updateDevice: (deviceId, name, authToken) => {
    const url = `${BASE_DEVICES_URL}/${deviceId}`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    let body = {
      name
    }

    return api.put(url, body, headers)
  },
  upgradeFirmware: (deviceId, authToken) => {
    const url = `${BASE_DEVICES_URL}/${deviceId}/fw_upgrade`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    let body = {
      type: 'core'
    }

    return api.post(url, body, headers)
  },
  addHub: (unitId, hubIdentifier, authToken) => {
    const url = `${BASE_DEVICES_URL}/hub_code`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    const body = {
      code: hubIdentifier,
      unit_id: unitId
    }

    return api.post(url, body, headers)
  },
  getDeviceProfiles: authToken => {
    const url = `${BASE_DEVICES_URL}/profiles?tested=true`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  getDocuments: ({ authToken, unitID }) => {
    const url = `${BASE_UNITS_URL}/${unitID}/documents`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  getSchedule: (id, authToken) => {
    const url = `${BASE_SCHEDULE_URL}/${id}`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  updateScheduleEnabled: (id, enabled, authToken) => {
    const url = `${BASE_SCHEDULE_URL}/${id}/${enabled}`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.put(url, {}, headers)
  },
  createUpdateSchedule: (id, body, authToken) => {
    const url = id ? `${BASE_SCHEDULE_URL}/${id}` : `${BASE_SCHEDULE_URL}`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return id ? api.put(url, body, headers) : api.post(url, body, headers)
  },
  createUnit: (authToken, propertyId, name, type) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = BASE_UNITS_URL
    const body = { name, enabled: true, property_id: propertyId, type }

    return api.post(url, body, headers)
  },
  createVisitor: (authToken, body) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = BASE_VISITORS_URL
    return api.post(url, body, headers)
  },

  updateVisitor: (authToken, body, visitorId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_VISITORS_URL}/${visitorId}`
    return api.put(url, body, headers)
  },

  getVisitor: (authToken, visitorId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_VISITORS_URL}/${visitorId}`
    return api.get(url, headers)
  },

  getVisitorsForProperty: (authToken, propertyId, page, queryParams) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/visitors?page=${page}&page_size=8&${queryString.stringify(
      queryParams
    )}`

    return api.getWithHeaders(url, headers)
  },

  searchVisitorsOnProperty: (
    authToken,
    propertyId,
    searchTerm,
    queryParams
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_VISITORS_URL}/${propertyId}/search/${searchTerm}?${queryString.stringify(
      queryParams
    )}`
    return api.getWithHeaders(url, headers)
  },

  uploadVisitorImage: (authToken, visitorId, file) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_VISITORS_URL}/${visitorId}/upload_photo`
    const data = objectToFormData({
      file,
      label: `${file.name}-${visitorId}`
    })
    return api.post(url, data, headers)
  },
  createVendorCode: (authToken, body, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/external-users`
    return api.post(url, body, headers)
  },

  updateVendorCode: (authToken, body, propertyId, codeId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/external-users/${codeId}`
    return api.put(url, body, headers)
  },

  getVendorCodes: (authToken, vendorId, propertyId, query) => {
    const { pageSize = 100, valid } = query
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = `${BASE_PROPERTIES_URL}/${propertyId}/external-users?vendor_id=${vendorId}&page_size=${pageSize}`
    if (valid) {
      url += `${url}&valid=${valid}`
    }
    return api.get(url, headers)
  },
  searchVendorCodes: (authToken, propertyId, searchTerm) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = `${BASE_PROPERTIES_URL}/${propertyId}/external-users/search/${searchTerm}`
    return api.get(url, headers)
  },
  getVendorCode: (authToken, codeId, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/external-users/${codeId}`
    return api.get(url, headers)
  },

  resendVendorCode: (authToken, codeId, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/external-users/${codeId}/send-lock-code`
    return api.post(url, {}, headers)
  },

  invalidateVendorCode: (authToken, codeId, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/external-users/${codeId}/invalidate`
    return api.put(url, {}, headers)
  },

  getVendorCodeUnits: (authToken, codeId, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/external-users/${codeId}/units-info`
    return api.get(url, headers)
  },

  getVendor: (authToken, vendorId, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/vendors/${vendorId}`
    return api.get(url, headers)
  },

  updateScheduleState: (unitID, state, authToken) => {
    const url = `${BASE_UNITS_URL}/${unitID}/schedule_state/${state}`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.put(url, {}, headers)
  },
  sendActivationEmail: (id, authToken) => {
    const url = `${BASE_USERS_URL}/${id}/send-confirmation`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.post(url, {}, headers)
  },
  getUnits: (authToken, params) => {
    const { propertyId: property_id, name } = params
    const paramsToSend = { property_id, name }
    const url = getUnitsUrl(paramsToSend)
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  getCompanies: (authToken, page) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_COMPANIES_URL}?page_size=100&enabled=true&page=${page}`
    return api.getWithHeaders(url, headers)
  },
  getCompaniesProperties: (authToken, id, page = 1) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}?company_id=${id}&page_size=100&enabled=true&page=${page}&order_by=name,asc`
    return api.getWithHeaders(url, headers)
  },
  getProperties: (authToken, page = 1) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}?enabled=true&page_size=100&page=${page}&order_by=name,asc`
    return api.getWithHeaders(url, headers)
  },
  updateProperty: (authToken, propertyId, attributes) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}`
    return api.put(url, attributes, headers)
  },
  getPropertySummary: (authToken, id) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${id}/summary`
    return api.get(url, headers)
  },
  getPropertyUsersSummary: (authToken, id) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${id}/user_summary`
    return api.get(url, headers)
  },
  getPropertyBatteryLevels: (authToken, id) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${id}/battery`
    return api.get(url, headers)
  },
  getPropertyHubs: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/hubs`

    return api.get(url, headers)
  },
  getUnitBatteryLevels: (authToken, id) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_UNITS_URL}/${id}/battery`
    return api.get(url, headers)
  },
  getLockCodePermissions: (authToken, userId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_USERS_URL}/${userId}/lock_code_permissions`
    const body = { resources: ['unit'] }

    return api.post(url, body, headers)
  },
  getPropertySettings: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/settings`
    return api.get(url, headers)
  },
  getPropertyInfo: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}`
    return api.get(url, headers)
  },
  updatePropertySettings: (authToken, propertyId, attributes) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/settings`
    return api.post(url, attributes, headers)
  },
  getUserSettings: (authToken, userId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_USERS_URL}/settings?${userId == null ? '' : `user_id=${userId}`
      }`
    return api.get(url, headers)
  },
  updateUserSettings: (authToken, attributes) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_USERS_URL}/settings`
    return api.post(url, attributes, headers)
  },
  getPinnedChannels: authToken => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_USERS_URL}/chat/tags`
    return api.get(url, headers)
  },
  getPinnedChannels: authToken => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_USERS_URL}/chat/tags`
    return api.get(url, headers)
  },
  pinChannel: (authToken, channelRef, type) => {
    type = type == null ? 'pin' : type
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_USERS_URL}/chat/${channelRef}/${type}`
    return api.post(url, {}, headers)
  },
  unpinChannel: (authToken, channelRef, type) => {
    type = type == null ? 'pin' : type
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_USERS_URL}/chat/${channelRef}/${type}`
    return api.delete(url, headers)
  },
  sendChatMessage: (
    authToken,
    sendBirdChannelUrl,
    message,
    types,
    file,
    sendbirdUserID
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_CHAT_URL}/${sendBirdChannelUrl}/${sendbirdUserID}`
    const body = new FormData()

    body.set('message', message)
    types.forEach(type => body.append('types[]', type))
    if (file) {
      body.append('file', file)
    }

    return api.post(url, body, headers)
  },
  getNewEvents: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/community/new`
    return api.get(url, headers)
  },
  getUpcomingEvents: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/community/upcoming`
    return api.get(url, headers)
  },
  getEvents: (authToken, propertyId, start, end, page) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const query = queryString.stringify({
      type: 'event',
      start_event_datetime: start,
      end_event_datetime: end,
      ...(page ? { page } : {})
    })
    const url = `${API_URL}/properties/${propertyId}/community?${query}`
    return api.getWithHeaders(url, headers)
  },
  rsvpToEvent: (authToken, propertyId, eventId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const base64EncodedId = window.btoa(eventId)
    const url = `${API_URL}/properties/${propertyId}/community/${base64EncodedId}/rsvp`
    return api.post(url, {}, headers)
  },
  getPosts: (authToken, propertyId, page) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const query = queryString.stringify({
      type: 'feed',
      sort_order: 'desc',
      ...(page ? { page } : {})
    })
    const url = `${API_URL}/properties/${propertyId}/community?${query}`
    return api.getWithHeaders(url, headers)
  },
  getFilteredThread: (
    authToken,
    propertyId,
    date,
    userId,
    threadType,
    sortBy,
    page
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const query = queryString.stringify({
      type: threadType,
      sort_order: 'desc',
      author: userId ? userId : undefined,
      sort_by: sortBy ? sortBy : undefined,
      ...getParamsForDateFilter(date, threadType),
      ...(page ? { page } : {})
    })

    const url = `${API_URL}/properties/${propertyId}/community?${query}`
    return api.getWithHeaders(url, headers)
  },
  getGroupMessagingReplacementOptions: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/replacement-options`
    return api.get(url, headers)
  },
  getGroupMessagingTemplate: (authToken, templateId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/templates/${templateId}/group-messages/template`
    return api.get(url, headers)
  },
  getGroupMessagesSentTodayCount: (authToken, resource, resourceId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/${resource}/${resourceId}/group-messages/history/sent-today`
    return api.get(url, headers)
  },
  getGroupMessagingTemplatePermission: (authToken, userId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_USERS_URL}/${userId}/group-messages/template-permissions`
    return api.get(url, headers)
  },
  updateGroupMessagingTemplate: (authToken, templateId, attributes) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/templates/${templateId}/group-messages/template`
    return api.put(url, attributes, headers)
  },
  updateGroupMessagingTemplateResource: (
    authToken,
    templateId,
    updateResource,
    updateResourceId
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/templates/${templateId}/group-messages/template/${updateResource}/${updateResourceId}`
    return api.put(url, {}, headers)
  },
  createGroupMessagingTemplate: (authToken, resourceId, resource, formData) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/${resource}/${resourceId}/group-messages/template`
    return api.post(url, formData, headers)
  },
  deleteGroupMessageTemplate: (authToken, templateId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/templates/${templateId}/group-messages/template`
    return api.delete(url, headers)
  },
  getGroupMessagingTemplates: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/template-list`
    return api.get(url, headers)
  },
  getGroupUsers: (authToken, groupId, searchTerm) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = `${API_URL}/groups/${groupId}/group-messages/group/users${searchTerm ? `/search/${searchTerm}` : ''
      }`

    return api.get(url, headers)
  },
  getGroupMessagingSingleGroup: (authToken, groupId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = `${API_URL}/groups/${groupId}/group-messages/group`
    return api.get(url, headers)
  },
  searchGroupMessagingTemplates: (
    authToken,
    propertyId,
    searchTerm,
    queryParams
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/template/search/${searchTerm}?${queryString.stringify(
      queryParams
    )}`
    return api.get(url, headers)
  },
  getGroupMessagingAutomation: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/automation`
    return api.get(url, headers)
  },
  getGroupMessagingGroupsSummary: (authToken, propertyId, page = 1) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/group-summary?page=${page}&page_size=20`
    return api.getWithHeaders(url, headers)
  },
  getGroupMessagingGroups: (authToken, propertyId, page = 1) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/group?page=${page}&page_size=20`
    return api.getWithHeaders(url, headers)
  },
  searchGroupMessagingGroups: (authToken, propertyId, term, queryParams) => {
    const headers = createHTTPHeader(authToken, term, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/group/search/${term}?type=group`
    return api.getWithHeaders(url, headers)
  },
  getDefautlGroupMessagingGroups: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/group-default-list`
    return api.getWithHeaders(url, headers)
  },
  getGroupMessagingGroupEvents: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/group-events`
    return api.get(url, headers)
  },
  getGroupMessagingHistorySummary: (authToken, propertyId, page = 1) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/history-summary?page=${page}&page_size=10`
    return api.getWithHeaders(url, headers)
  },
  searchGroupMessagingHistory: (
    authToken,
    propertyId,
    searchTerm,
    queryParams
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/history/search/${searchTerm}?${queryString.stringify(
      queryParams
    )}`
    return api.getWithHeaders(url, headers)
  },
  deleteAutomation: (authToken, groupMessageId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/automations/${groupMessageId}/group-messages/automation`
    return api.delete(url, headers)
  },
  getAutomationFutureSummary: (
    authToken,
    propertyId,
    start_time,
    stop_time
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/automation-future-summary?start_time=${start_time}&stop_time=${stop_time}`
    return api.getWithHeaders(url, headers)
  },
  getSingleGroupMessageFutureUsers: (
    authToken,
    groupMessageId,
    runTime,
    page
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/automations/${groupMessageId}/group-messages/automation-future-details-users?datetime=${runTime}${page ? `&&page=${page}` : ''
      }`
    return api.getWithHeaders(url, headers)
  },
  getAutomationFutureDetails: (authToken, groupMessageId, runTime) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/automations/${groupMessageId}/group-messages/automation-future-details?datetime=${runTime}`
    return api.get(url, headers)
  },
  getSingleGroupMessageHistory: (authToken, groupMessageId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/group-messages/${groupMessageId}/group-messages/history`
    return api.get(url, headers)
  },
  getSingleGroupMessageHistoryUsers: (authToken, groupMessageId, page) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/group-messages/${groupMessageId}/group-messages/history-summary/users${page ? `?page=${page}` : ''
      }`
    return api.getWithHeaders(url, headers)
  },
  getChatTokens: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/chat_token`
    return api.get(url, headers)
  },
  searchSingleGroupMessageUsers: (
    authToken,
    groupMessageId,
    searchTerm,
    queryParams,
    page
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/group-messages/${groupMessageId}/group-messages/history/search/${searchTerm}?${queryString.stringify(
      queryParams
    )}${page ? `&&page=${page}` : ''}`
    return api.getWithHeaders(url, headers)
  },
  sendGroupMessageNowNoTemplate: (
    authToken,
    propertyId,
    title,
    text,
    textMessage,
    templateId,
    groups,
    sendingMethods,
    files
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/send`
    const body = objectToFormData({
      title,
      text,
      text_body: textMessage,
      files: files || [],
      group_ids: groups,
      sending_methods: sendingMethods
    })
    return api.post(url, body, headers)
  },
  sendGroupMessageNowTemplate: (
    authToken,
    propertyId,
    templateId,
    groups,
    sendingMethods
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/template/${templateId}/send`
    const body = {
      group_ids: groups,
      sending_methods: sendingMethods
    }
    return api.post(url, body, headers)
  },
  sendGroupMessageDateTimeNoTemplate: (
    authToken,
    propertyId,
    cron,
    groups,
    sendingMethods,
    text,
    textMessage,
    title,
    name,
    files
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/automation-groups`
    const body = objectToFormData({
      name: shortid.generate(),
      sending_methods: sendingMethods,
      template: {
        name,
        body: text,
        text_body: textMessage,
        title,
        files: files || []
      },
      cron,
      groups
    })
    return api.post(url, body, headers)
  },
  sendGroupMessageDateTimeTemplate: (
    authToken,
    propertyId,
    templateId,
    cron,
    groups,
    sendingMethods
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/automation-groups-template`
    const body = {
      name: shortid.generate(),
      template_id: templateId,
      sending_methods: sendingMethods,
      cron,
      groups
    }
    return api.post(url, body, headers)
  },
  sendGroupMessageEventTemplate: (
    authToken,
    propertyId,
    templateId,
    cron,
    groups,
    sendingMethods,
    eventType,
    offset
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/automation-template`
    const body = {
      name: shortid.generate(),
      template_id: templateId,
      sending_methods: sendingMethods,
      cron,
      groups,
      event: {
        name: shortid.generate(),
        type: 'event',
        event: eventType,
        operator: 'eq',
        time_offset: offset
      }
    }
    return api.post(url, body, headers)
  },
  sendGroupMessageEventNoTemplate: (
    authToken,
    propertyId,
    cron,
    groups,
    sendingMethods,
    eventType,
    offset,
    text,
    textMessage,
    title,
    name,
    files
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/properties/${propertyId}/group-messages/automation`
    const body = objectToFormData({
      name: shortid.generate(),
      sending_methods: sendingMethods,
      cron,
      groups,
      template: {
        name,
        body: text,
        text_body: textMessage,
        title,
        files: files || []
      },
      event: {
        name: shortid.generate(),
        type: 'event',
        event: eventType,
        operator: 'eq',
        time_offset: offset
      }
    })
    return api.post(url, body, headers)
  },
  // groups didn't change can include event
  updateGroupMessagEditTemplate: (
    authToken,
    automationId,
    sendingMethods,
    cron,
    selectedTemplate,
    event
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url =
      selectedTemplate.id && selectedTemplate.name
        ? `${API_URL}/automations/${automationId}/group-messages/automation-and-template`
        : `${API_URL}/automations/${automationId}/group-messages/automation-template`
    const body = {}

    if (cron) {
      body.cron = cron
    }
    if (sendingMethods) {
      body.sending_methods = sendingMethods
    }
    if (event) {
      body.event = event
    }
    if (selectedTemplate.id) {
      body.template_id = selectedTemplate.id
    }
    if (
      selectedTemplate.name &&
      selectedTemplate.title &&
      selectedTemplate.message &&
      selectedTemplate.textBody
    ) {
      body.template = {
        name: selectedTemplate.name,
        title: selectedTemplate.title,
        body: selectedTemplate.message,
        text_body: selectedTemplate.textBody,
        files: []
      }
    }
    return api.put(url, body, headers)
  },
  updateGroupMessagEditTemplateGroup: (
    authToken,
    automationId,
    sendingMethods,
    cron,
    selectedTemplate,
    groups
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url =
      selectedTemplate.id && selectedTemplate.name
        ? `${API_URL}/automations/${automationId}/group-messages/automation-groups-and-template`
        : `${API_URL}/automations/${automationId}/group-messages/automation-groups-template`
    const body = {
      groups
    }
    if (cron) {
      body.cron = cron
    }
    if (sendingMethods) {
      body.sending_methods = sendingMethods
    }
    if (selectedTemplate.id) {
      body.template_id = selectedTemplate.id
    }
    if (
      selectedTemplate.name &&
      selectedTemplate.title &&
      selectedTemplate.message &&
      selectedTemplate.textBody
    ) {
      body.template = {
        name: selectedTemplate.name,
        title: selectedTemplate.title,
        body: selectedTemplate.message,
        text_body: selectedTemplate.textBody,
        files: []
      }
    }
    return api.put(url, body, headers)
  },
  post: (url, body, headers = createHTTPHeader('', APP_TYPE)) => {
    return axios
      .post(url, body, { headers })
      .then(response => response.data)
      .catch(handleError)
  },
  delete: (url, headers) => {
    return axios
      .delete(url, { headers })
      .then(response => response.data)
      .catch(handleError)
  },
  put: (url, body, headers) => {
    return axios
      .put(url, body, { headers })
      .then(response => response.data)
      .catch(handleError)
  },
  patch: (url, body, headers) => {
    return axios
      .patch(url, body, { headers })
      .then(response => response.data)
      .catch(handleError)
  },
  get: (url, headers) => {
    return axios
      .get(url, { headers })
      .then(response => response.data)
      .catch(handleError)
  },
  getWithHeaders: (url, headers) =>
    axios
      .get(url, { headers })
      .then(response => ({ data: response.data, headers: response.headers }))
      .catch(handleError),

  getUserPets: (userId, authToken) => {
    const url = `${BASE_USERS_URL}/${userId}/registration/pet/summary`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },

  getWorkOrdersByProperty: (propertyId, authToken) => {
    const url = `${MOCHI_ENDPOINT}/workorder/property/${propertyId}`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },

  submitWorkOrder: (workOrder, authToken) => {
    const url = `${MOCHI_ENDPOINT}/workorder`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.post(url, workOrder, headers)
  },

  mimoSubmitWorkOrder: (workOrder, authToken) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${MOCHI_ENDPOINT}/workorder`

    return api.post(url, workOrder, headers)
  },

  mimoWorkOrderUpdate: (workOrders, residentCheckListId, pmId, authToken) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getMimoWorkOrderUpdateUrl(residentCheckListId, pmId)

    return api.post(url, workOrders, headers)
  },

  updateWorkOrder: (workOrder, authToken) => {
    const url = `${MOCHI_ENDPOINT}/workorder/${workOrder.workOrderId}`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.put(url, workOrder, headers)
  },

  getWorkOrderDetails: (workOrderId, authToken) => {
    const url = `${MOCHI_ENDPOINT}/workorder/${workOrderId}`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },

  getStandaloneWorkOrderCategories: authToken => {
    const url = `${MOCHI_ENDPOINT}/category`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },

  getResidentMoveInMoveOut: (propertyId, authToken, page, params) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getResidentsUrl(propertyId, {
      currentPage: page,
      ...params
    })

    return api.get(url, headers)
  },

  getMimoWorkOrderSubmit: (authToken, residentCheckListId, checklistSummary) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getMimoWorkOrderSubmitUrl(residentCheckListId)

    return api.post(url, checklistSummary, headers)
  },

  submitSignature: (authToken, residentCheckListId, signature, pmId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getSignatureUrl()
    const pmeSignature = signature.replace('data:image/png;base64,', '')

    return api.put(url, { residentCheckListId, pmeSignature, pmId }, headers)
  },

  mimoIntegratedQuestions: (residentCheckListId, authToken) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getMimoIntegratedQuestionsUrl(residentCheckListId)

    return api.get(url, headers)
  },

  submitIntegratedWo: (data, unitId, authToken) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getIntegratedWoUrl(unitId)

    return api.post(url, data, headers)
  },

  submitResetCheckList: (authToken, residentId, propertyId, propertyName, unitId, unitName) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getMimoResetCheckListUrl(residentId, propertyId, propertyName, unitId, unitName)
    
    return api.delete(url, headers)
  },

  submitChecklistSettings: (checklistSettings, authToken) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getChecklistSettingsUrl()

    return api.post(url, checklistSettings, headers)
  },

  getSavedChecklistSettings: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getSavedChecklistSettingsUrl(propertyId)

    return api.get(url, headers)
  },

  submitChecklistPdf: (checklistPdf, authToken) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const residentChecklistId = checklistPdf.residentChecklistId
    const url = getChecklistPdfUrl(residentChecklistId)

    const formData = new FormData();
    formData.append('file', checklistPdf.file);
    console.log("Url", url)

    return api.put(url, formData, headers)
  },

  getResidentMoveInMoveOutFilteredCount: (propertyId, authToken, page, params) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getResidentsFilteredCountUrl({
      propertyId: propertyId,
      ...params
    })

    return api.get(url, headers)
  },

  getResidentMoveInMoveOutTotalCount: (propertyId, authToken, page, params) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getResidentsTotalCountUrl({
      propertyId: propertyId,
      ...params
    })

    return api.get(url, headers)
  },

  getChecklistSummary: (authToken, propertyId, userId, unitId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getChecklistSummaryUrl(propertyId, userId, unitId)

    return api.get(url, headers)
  },

  getChecklistSettings: (propertyId, authToken) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getChecklistSettingUrl(propertyId)

    return api.get(url, headers)
  },

  submitChecklistOptedOut: (authToken, residentCheckListId, userId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getChecklistOptedOutUrl(residentCheckListId, userId)
    return api.put(url,{}, headers)
  },

  getMarketplaceDeals: (propertyId, page, authToken) => {
    const url = `${BASE_CARDS_URL}?type=service&property_id=${propertyId}&page=${page}`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.getWithHeaders(url, headers)
  },

  deleteMarketplaceDeal: (id, authToken) => {
    const url = `${BASE_CARDS_URL}/${id}`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.delete(url, headers)
  },

  reorderMarketplaceDeal: (id, propertyId, index, authToken) => {
    const url = `${BASE_CARDS_URL}/${id}/reorder`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.post(url, { property_id: propertyId, index }, headers)
  },

  createProAmenity: (authToken, propertyId, data) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${AMENITIES_API_URL}/facility/?propertyId=${propertyId}`

    return api.post(url, data, headers)
  },

  getProAmenity: (authToken, propertyId, facilityUuid) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${AMENITIES_API_URL}/facility/${facilityUuid}/?propertyId=${propertyId}`

    return api.get(url, headers)
  },

  getProAmenities: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${AMENITIES_API_URL}/facility?propertyId=${propertyId}`

    return api.getWithHeaders(url, headers)
  },

  updateProAmenity: (authToken, propertyId, update) => {
    const { facilityUuid } = update;
    delete update.facilityUuid; // API doesn't allow uuid in update body
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${AMENITIES_API_URL}/facility/${facilityUuid}/?propertyId=${propertyId}`

    return api.patch(url, update, headers)
  },

  addAmenityPhoto: (authToken, propertyId, data) => {
    const { facilityUuid, file } = data;
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${AMENITIES_API_URL}/facility/${facilityUuid}/photos?propertyId=${propertyId}`

    return api.post(url, objectToFormData({ file }), headers)
  },

  deleteAmenityPhoto: (authToken, propertyId, data) => {
    const { facilityUuid, photoUuid } = data;
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${AMENITIES_API_URL}/facility/${facilityUuid}/photos/${photoUuid}?propertyId=${propertyId}`

    return api.delete(url, headers)
  },

  deleteProAmenity: (authToken, propertyId, facilityUuid) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${AMENITIES_API_URL}/facility/${facilityUuid}/?propertyId=${propertyId}`

    return api.delete(url, headers)
  },

  saveWaiver: (authToken, data, propertyId, facilityUuid) => {
    const { file, waiverRequired } = data;
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${AMENITIES_API_URL}/facility/${facilityUuid}/waiver/?propertyId=${propertyId}`

    return api.post(url, objectToFormData({ file, waiverRequired }), headers)
  },

  deleteWaiver: (authToken, waiverUuid, propertyId, facilityUuid) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${AMENITIES_API_URL}/facility/${facilityUuid}/waiver/${waiverUuid}/?propertyId=${propertyId}`

    return api.delete(url, headers)
  },

  getProReservations: (authToken, propertyId, facilityUuid, startDateTime) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${AMENITIES_API_URL}/reservation/?propertyId=${propertyId}&facilityUuid=${facilityUuid}&startDate=${startDateTime}`

    return api.get(url, headers)
  },

  createProAmenityReservation: (authToken, propertyId, reservation) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${AMENITIES_API_URL}/reservation/?propertyId=${propertyId}`

    return api.post(url, reservation, headers)
  },

  deleteProReservation: (authToken, propertyId, reservationUuid, cancelMessage) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const queryParams = `propertyId=${propertyId}&message=${cancelMessage}`
    const url = `${AMENITIES_API_URL}/reservation/${reservationUuid}?${queryParams}`

    return api.delete(url, headers)
  },

  getAvailability: (authToken, propertyId, facilityUuid, date, endDate) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    let url = `${AMENITIES_API_URL}/facility/${facilityUuid}/availability/?propertyId=${propertyId}`
    if(!endDate) {
      url = url + `&date=${date}`
    }
    else {
      url = url + `&startDate=${date}&endDate=${endDate}`
    }

    return api.get(url, headers)
  },

  pinAmenity: (authToken, facilityUuid, adminUuid, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${AMENITIES_API_URL}/admin/${adminUuid}/pin/facility/?propertyId=${propertyId}`

    return api.post(url, { facilityUuid }, headers)
  },

  unPinAmenity: (authToken, facilityUuid, adminUuid, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${AMENITIES_API_URL}/admin/${adminUuid}/unpin/facility/?propertyId=${propertyId}`

    return api.post(url, { facilityUuid }, headers)
  },

  getAdmin: (authToken, adminUuid, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${AMENITIES_API_URL}/admin/${adminUuid}/?propertyId=${propertyId}`

    return api.get(url, headers)
  },

  createFacilityBlock: (authToken, propertyId, facilityUuid, data) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${AMENITIES_API_URL}/facility/${facilityUuid}/block/?propertyId=${propertyId}`

    return api.post(url, data, headers)
  },

  deleteFacilityBlock: (authToken, propertyId, facilityUuid, facilityBlockUuid) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${AMENITIES_API_URL}/facility/${facilityUuid}/block/${facilityBlockUuid}/?propertyId=${propertyId}`

    return api.delete(url, headers)
  },
}

const getUnitsUrl = params => {
  return buildAPIUrl(BASE_UNITS_URL, params)
}

const getDevicesUrl = params => {
  return buildAPIUrl(BASE_DEVICES_URL, params)
}

const getUsersUrl = params => {
  return buildAPIUrl(BASE_USERS_URL, params)
}

const getResidentsUrl = (propertyId, params) => {
  return buildAPIUrl(`${MIMO_ENDPOINT}/ResidentCheckList/property/${propertyId}`, params)
}

const getMimoWorkOrderSubmitUrl = (residentCheckListId) => {
  return `${MIMO_ENDPOINT}/ResidentCheckList/${residentCheckListId}/StandaloneWOQuestions/CheckListAttributeDetails`
}

const getSignatureUrl = () => {
  return `${MIMO_ENDPOINT}/PropertyManagerSignature`
}

const getMimoIntegratedQuestionsUrl = (residentCheckListId) => {
  return `${MIMO_ENDPOINT}/ResidentIntegratedWOQuestions/${residentCheckListId}`
}

const getIntegratedWoUrl = (unitId) => {
  return `${API_URL}/v2/units/${unitId}/work_order`
}

const getMimoWorkOrderUpdateUrl = (residentCheckListId, pmId) => {
  return `${MIMO_ENDPOINT}/ResidentCheckList/${residentCheckListId}/PM/${pmId}/workorder/update`
}

const getMimoResetCheckListUrl = (residentId, propertyId, propertyName, unitId, unitName) => {
  return `${MIMO_ENDPOINT}/ResidentCheckList/ResetChecklist/${residentId}/${propertyId}/${propertyName}/${unitId}/${unitName}`
}

const getChecklistSettingsUrl = (propertyId, params) => {
  return `${MIMO_ENDPOINT}/ChecklistSettings`
}

const getSavedChecklistSettingsUrl = (propertyId) => {
  return `${MIMO_ENDPOINT}/ChecklistSettings/property/${propertyId}`
}

const getChecklistPdfUrl = (residentChecklistId) => {
  return `${MIMO_ENDPOINT}/ResidentCheckList/${residentChecklistId}/SaveCheckListSummary`
}

const getResidentsFilteredCountUrl = (params) => {
  return buildAPIUrl(`${MIMO_ENDPOINT}/ResidentCheckList/filter/count`, params)
}

const getResidentsTotalCountUrl = (params) => {
  return buildAPIUrl(`${MIMO_ENDPOINT}/ResidentCheckList/count`, params)
}

const getChecklistSummaryUrl = (propertyId, userId, unitId) => {
  return buildAPIUrl(`${MIMO_ENDPOINT}/ResidentCheckListTemplate/property/${propertyId}/${userId}/${unitId}`, '')
}

const getChecklistSettingUrl = (propertyId) => {
  return buildAPIUrl(`${MIMO_ENDPOINT}/ChecklistSettings/property/${propertyId}`, '')
}

const getChecklistOptedOutUrl = (residentCheckListId, userId) => {
  return `${MIMO_ENDPOINT}/ResidentCheckList/${residentCheckListId}/PM/${userId}/OptOut`
}

const getUserUrl = ({ userId, ...params }) => {
  return buildAPIUrl(`${BASE_USERS_URL}/${userId}`, params)
}

const getDeviceEventsUrl = ({ unitID, deviceType, page }) => {
  const type = deviceTypeMap[deviceType] || deviceTypeMap.all
  return `${BASE_EVENT_URL}?inserted_at > ${HISTORY_REQUEST_DATE}T00:00:00.000Z&exclude_event_codes=${NON_DISPLAY_EVENT_CODES}&unit_id=${unitID}&page_size=${100}&page=${page}&device_type=${type}`
}

const getWorkOrdersUrl = ({ unitId }) => {
  return `${BASE_UNITS_URL}/${unitId}/work_order`
}

const handleError = error => {
  let statusCode
  let message = ''
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    log(error.response.status, error.response.data)
    message = get(error, ['response', 'data', 'errors', 'details'])
    const errors = get(error, ['response', 'data', 'errors'])

    if (!message) {
      message = typeof errors !== 'object' ? errors : ''
    }

    if (!message) {
      message = UNKNOWN_ERR_MSG
    }

    if(message.login){
      message = error.response.data.friendly_error
    }

    statusCode = error.response.status
  } else if (error.request) {
    // The request was made but no response was received
    message = NETWORK_ERR_MSG
  } else {
    // Something happened in setting up the request that triggered an Error
    log('Error', error)
    message = LOCAL_ERR_MSG
  }

  let err = new Error(message)
  if (statusCode) {
    err.statusCode = statusCode
  }

  if (typeof error.response.data.errors === 'object') {
    err.data = error.response.data.errors
  }
  return Promise.reject(err)
}

export default api
