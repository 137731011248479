// @flow

import React from 'react'

import { connect } from 'react-redux'
import withFetchData from 'zego-shared/views/withFetchData'

import {
  fetchAmenities,
  searchAmenities
} from 'zego-shared/store/amenitiesLegacy'
import { ldInitRequest } from '../../integrations/LaunchDarkly/actions'

import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {
  hasAmenities,
  getAmenities,
  getAmenitiesPaginationInfo,
} from 'zego-shared/store/amenitiesLegacy/selectors'

import AmenityDashboardLegacy from './AmenityDashboardLegacy'

const fetchingSelector = createIsFetchingSelector(['amenities'])

const mapStateToProps = state => ({
  items: getAmenities(state),
  hasData: hasAmenities(state),
  isFetching: fetchingSelector(state),
  checkProAmenity: true,

  ...getAmenitiesPaginationInfo(state)
})

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { propertyId }
    },
  }
) => {
  return {
    fetchData: () => {
      dispatch(fetchAmenities(propertyId))
    },
    fetchPage: page => dispatch(fetchAmenities(propertyId, page)),
    searchAmenities: (searchTerm, page) => {
      if (searchTerm == null || searchTerm === '') {
        dispatch(fetchAmenities(propertyId))
      } else {
        dispatch(searchAmenities(propertyId, page, searchTerm))
      }
    },
    ldInitRequest,
  }
}

class AmenityDashboardWrapper extends React.Component<any, any> {
  componentDidMount = () => {
    const { ldInitRequest } = this.props
    ldInitRequest()
  }

  render() {
    const {
      props,
      props: {
        isFetching
      }
    } = this

    if (isFetching) {
      return null
    }

    return <AmenityDashboardLegacy {...props}/>
  }
}

const AmenityDashboardWithFetchData = withFetchData(AmenityDashboardWrapper)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AmenityDashboardWithFetchData)
