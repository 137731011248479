// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import LinearProgress from '@material-ui/core/LinearProgress'
import { FlexItem } from '@Common/Flex/FlexDirection'
import { Specs, BatteryPercentWrap, BatteryLevelText } from '../styles'
import { Props } from './types'

const LockSpecs = ({
  deviceModel,
  deviceStatus,
  InstallationDate,
  batteryPercent,
  batteryLevelPercentText,
  theme,
  toggleViewBatteryMode
}: Props) => (
  <FlexItem flex={1}>
    <Specs>
      <BatteryPercentWrap onClick={toggleViewBatteryMode}>
        <LinearProgress
          variant="determinate"
          value={batteryPercent}
          classes={{
            bar: 'battery-bar',
            root: 'battery-root'
          }}
        />
      </BatteryPercentWrap>
      <BatteryLevelText onClick={toggleViewBatteryMode}>
        {batteryLevelPercentText}
      </BatteryLevelText>
      <div>{deviceModel}</div>
      <div>Installed {InstallationDate}</div>
      <div>{deviceStatus}</div>
    </Specs>
  </FlexItem>
)

export default withTheme(LockSpecs)
