// @flow
import React from 'react'
import { Redirect } from 'react-router-dom'

class Logout extends React.Component<{ logout: Function }> {
  componentDidMount() {
    this.props.logout()
  }
  render() {
    return <Redirect to="/login" />
  }
}

export default Logout
