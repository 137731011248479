import styled from '@emotion/styled'
import MaterialTextField from '@material-ui/core/TextField'
import { convertHexToRGB } from '../../utils/commonUtils'
import Button from '@material-ui/core/Button'

export const Description = styled('div')`
  line-height: 28px;
`

export const Content = styled('p')`
  color: ${({ theme }) => `${theme.palette.common.white}`};
  line-height: 1.5em;
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  line-height: 26px;
  margin-top: 80px;
`

export const TextField = styled(MaterialTextField)`
  width: 100%;
  height: 2em;
  margin-right: 1em;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.palette.neutrals.warmGray};
  padding: 0 15px;
  & input {
    color: ${({ theme }) => theme.palette.text.lightGrey};
    padding-top: 8px;
  }
`
export const Container = styled('div')`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`

export const Layout = styled('div')`
  width: 100%;
  &:after {
    content: ' ';
    background: ${({ theme }) => `${theme.palette.neutrals.lightGray}`};
    border-top-right-radius: 5px;
    width: 70%;
    height: calc(100% - 1em);
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: -15;
    top: 2em;
  }
`

export const TableContainer = styled('div')`
  height: 84.5%;
  width: 80.5%;
  position: absolute;
  padding: 3em;
  background: ${({ theme }) => `${theme.palette.common.white}`};
  border-top-left-radius: 5px;
  right: -2em;
  bottom: -1em;
  box-shadow: ${({ theme }) =>
    `0 1px 10px 0 ${convertHexToRGB(theme.palette.neutrals.warmGray, 0.5)}`};
  th,
  td {
    padding-right: 0.5em !important;
  }
  th:last-of-type > div,
  td:last-of-type > div {
    justify-content: flex-end;
  }
  tr {
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.neutrals.deepGray}`};
  }
`

export const StyledColumn = styled('div')`
  height: 4em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  ${({ index }) => index === 1 && `justify-content: flex-start`};
  ${({ index }) => index === 6 && `justify-content: flex-end`};
  ${({ index, theme }) =>
    index === 5 &&
    `color: ${theme.palette.primary.green.mint};
    background: ${theme.palette.secondary.light3};`};
`

export const StyledRow = styled('div')`
  height: ${({ firstRow }) => (firstRow ? `7em` : `4em`)};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: ${({ firstRow }) => (firstRow ? `flex-end` : `center`)};
  white-space: nowrap;
  position: relative;
  justify-content: center;
  ${({ index }) => index === 1 && `justify-content: flex-start`};
  ${({ index }) => index === 6 && `justify-content: flex-end`};
  ${({ lockCodesMain }) => lockCodesMain && `justify-content: flex-end`};

  ${({ index, theme }) =>
    index === 5 &&
    `color: ${theme.palette.primary.green.mint};
    background: ${theme.palette.secondary.light3};`};
  ${({ firstRow }) => firstRow && `padding-bottom: 1.5em;`};
  ${({ index, theme }) =>
    (index === 1 || index === 6) &&
    `color: ${theme.palette.common.black};
    * :not(svg):not(path) {
      color: ${theme.palette.common.black};
    }`};
`

export const FabButton = styled(Button)`
  margin-top: 2.7em;
  color: ${({ theme }) => theme.palette.common.white};
  background: ${({ theme }) => `${theme.palette.blue.main}`};
  width: 45px;
  height: 45px;
`

export const FabButtonWrap = styled('div')`
  display: flex;
  justify-content: flex-end;
  height: 3.5em;
  position: absolute;
  margin-right: 2.3em;
  margin-top: 2.5em;
  right: 0;
  z-index: 999;
`

export const InputWrapper = styled('div')`
  margin-bottom: 30px;
`
