// @flow
import * as React from 'react'
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import Track from './Track'
import Tick from './Tick'
import Handle from './Handle'
import { SliderType } from './types'
import { sliderStyle, railStyle } from './styles'

const domain = [30, 90]

const ThermostatSlider = (props: SliderType) => {
  const {
    values,
    handleOnMouseDown,
    ticksCount,
    onUpdate,
    onChange,
    mode,
    percentageValues,
    vertical
  } = props
  return (
    <Slider
      mode={mode}
      step={1}
      domain={[30, 90]}
      rootStyle={sliderStyle}
      onUpdate={onUpdate}
      onChange={onChange}
      values={values}>
      <Rail>
        {({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div>
            {handles.map((handle, index, handles) => (
              <Handle
                key={handle.id}
                handle={handle}
                domain={domain}
                getHandleProps={getHandleProps}
                onMouseDown={handleOnMouseDown}
                percentValue={percentageValues && percentageValues[index]}
                vertical={vertical}
              />
            ))}
          </div>
        )}
      </Handles>
      <Tracks left={false} right={false}>
        {({ tracks }) => (
          <div>
            {tracks.map(({ id, source, target }) => (
              <Track key={id} source={source} target={target} />
            ))}
          </div>
        )}
      </Tracks>
      <Ticks count={ticksCount}>
        {({ ticks }) => (
          <div>
            {ticks.map(tick => (
              <Tick key={tick.id} tick={tick} count={ticks.length} />
            ))}
          </div>
        )}
      </Ticks>
    </Slider>
  )
}

export default ThermostatSlider
