import styled from '@emotion/styled'
import { convertHexToRGB } from '../../../../utils'

const messageWidth = '66%'

export const ImageBlock = styled('div')`
  grid-column: ${({ columnStart, columnSpan }) => `${columnStart} / span
  ${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span
  ${rowSpan}`};
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.neutrals.lightGray};
  z-index: 10;
  ${({ image, theme }) =>
    image
      ? `
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
`
      : `
border: 1px dashed ${theme.palette.neutrals.deepGray}}
`};
`

export const ImageWrapper = styled('div')`
  overflow: hidden;
  height: 100%;
  width: 100%;
`

export const TitleBlock = styled('div')`
  grid-column: ${({ columnStart, columnSpan }) => `${columnStart} / span
  ${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span
  ${rowSpan}`};
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
  padding: 2.5em 2.5em 0em 0em;
  background-color: ${({ theme }) => theme.palette.neutrals.lightGray};
  ${({ image }) =>
    !image &&
    `

  `}
`
export const AttendBlock = styled('div')`
  grid-column: ${({ columnStart, columnSpan }) => `${columnStart} / span
  ${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span
  ${rowSpan}`};
  position: relative;
`

export const ButtonSection = styled('div')`
  grid-column: ${({ columnStart, columnSpan }) => `${columnStart} / span
  ${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span
  ${rowSpan}`};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const MessageSection = styled('div')`
  grid-column: ${({ columnStart, columnSpan }) =>
    `${columnStart} / span ${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span ${rowSpan}`};
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div:first-child {
    width: ${messageWidth};
    text-align: left;
    margin: 0px;
  }

  pre {
    word-wrap: break-word;
    white-space: pre-wrap;
    font-family: inherit;
    line-height: 1.3em;
    margin-bottom: 2em;
  }
`

export const Divider = styled('div')`
  grid-column: ${({ columnStart, columnSpan }) => `${columnStart} / span
  ${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span
  ${rowSpan}`};
  box-shadow: ${({ theme }) =>
    `0px 4px 5px 0px ${convertHexToRGB(theme.palette.neutrals.coolGray)}`};
`

export const Button = styled('label')`
  width: 23%;
  padding: 0px;
  height: 2em;
  line-height: 2em;
  border-radius: 13px;
  background-color: ${({ theme }) => theme.palette.primary.green.mint};
  color: ${({ theme }) => theme.palette.common.white};
  text-align: center;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  cursor: pointer;
`

export const DescriptionBlock = styled('div')`
  grid-column: ${({ columnStart, columnSpan }) => `${columnStart} / span
${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span
${rowSpan}`};
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
  padding-left: ${({ image }) => (image ? '2em' : '0em')};
`

export const DescriptionWrapper = styled('div')`
  grid-column: 1 / span 5;
  grid-row: 2 / span 1;
  display: relative;
`

export const Title = styled('div')`
  grid-column: 2 / span 5;
  grid-row: 2 / span 1;
  font-size: ${({ theme }) => theme.custom.font.size.xxl};
  width: 90%;
  text-align: left;
`

export const Caption = styled('div')`
  display: flex;
  align-items: center;
  grid-column: 2 / span 5;
  grid-row: 1 / span 1;
  margin-top: ${({ image }) => (image ? '3em' : '1.5em')};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.primary.green.mint};
`

export const Details = styled('div')`
  & > span:last-child {
    color: ${({ theme }) => theme.palette.grey.main};
    margin-top: 0.3em;
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }
`

export const CommentSection = styled('div')`
  width: ${messageWidth};
  margin: 4em 0;
  height: auto;
  textarea {
    &::-webkit-scrollbar {
      opacity: 0;
    }
  }

  textarea:focus {
    &::-webkit-scrollbar {
      width: 3px;
      opacity: 1;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: ${({ theme }) => theme.palette.neutrals.deepGray};
    }
  }
`

export const CommentBtn = styled('a')`
  color: ${({ theme }) => theme.palette.neutrals.warmGray};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  &:hover {
    color: ${({ theme }) => theme.palette.primary.green.mint};
  }
`

export const Break = styled('div')`
  height: 2em;
`

export const CommentList = styled('div')`
  height: auto;

  pre {
    flex: 7;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1em 0em;

    margin: 0em 1em;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
`

export const Circle = styled('div')`
  border: ${({ theme }) => `1px solid ${theme.palette.primary.green.mint}`};
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CircleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`

export const AvatarContainer = styled('div')`
  padding: 1em 0em;
`

export const Attend = styled('div')`
  position: absolute;
  bottom: -33px;
`
export const CommentNameDate = styled('span')`
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
`

export const CommentBody = styled('div')`
  padding: 1em 0em;
  margin: 0em 1em;
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
`

export const LikesAttending = styled('div')`
  position: relative;
  display: inline-block;
`

export const DropdownList = styled('div')`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 270px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
  max-height: 500px;
  height: auto;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.neutrals.deepGray};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.neutrals.warmGray};
    opacity: 0.5;
    height: 1em;
  }

  ${LikesAttending}:hover & {
    display: block;
  }
`

export const List = styled('div')`
  width: 100%;
`
