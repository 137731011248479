import get from 'lodash/get'

export const getUserSettings = (state: Object, userId) => {
  userId = userId == null ? 'self' : userId
  const userSettings = get(state, ['settings', 'userSettings', userId], {})

  return {
    receivePropertyTexts: get(userSettings, ['receive_property_texts'], false),
    receivePropertyEmails: get(
      userSettings,
      ['receive_property_emails'],
      false
    ),
    receiveMaintenanceEmails: get(
      userSettings,
      ['receive_maintenance_emails'],
      false
    ),
    showStaffProfile: get(userSettings, ['show_staff_profile'], false),
    residentZegoSmartEnabled: get(
      userSettings,
      ['resident_zego_smart_enabled'],
      true
    )
  }
}

export const getAllUserSettings = state => {
  const userIds = Object.keys(get(state, ['settings', 'userSettings']))
  return userIds.reduce((settings, userId) => {
    return { ...settings, [userId]: getUserSettings(state, userId) }
  }, {})
}
