// @flow
import * as React from 'react'
import { TrackType } from './types'

const Track = ({ source, target }: TrackType) => (
  <div
    style={{
      position: 'absolute',
      height: '3em',
      zIndex: 1,
      backgroundColor: 'transparent',
      borderRadius: 7,
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`
    }}
  />
)

export default Track
