import { combineReducers } from 'redux'
import {
  AMENITY,
  AMENITIES,
  updateAmenityActions,
  RESERVATIONS,
  setAmenityViewMode,
  clearAmenityAction,
  DELETE_RESERVATION,
  GET_ADMIN,
  GET_ADMIN_SUCCESS,
  GET_AVAILABILITY,
  GET_AVAILABILITY_SUCCESS,
  CREATE_FACILITY_BLOCK_SUCCESS,
  CREATE_FACILITY_BLOCK,
} from './actions'
import withPaginatedFetch from '../helpers/reducers/withPaginatedFetch'
import withFetch from '../helpers/reducers/withFetch'

const reducer = (state = null, action) => {
  switch (action.type) {
    case DELETE_RESERVATION:
      const filteredReservation = (state && state[action.facilityUuid] &&
        state[action.facilityUuid].filter((reservation) => reservation.reservationUuid !== action.reservationUuid)) || null
      const newState = (filteredReservation && { [action.facilityUuid]: filteredReservation }) || state
      return { ...state, ...newState }
    default:
      return state
  }
}

const amenityReducer = (state = null, action) => {
  switch (action.type) {
    case updateAmenityActions.updateSuccessAction().type:
    case updateAmenityActions.updateOptimistcallyAction().type:
    case updateAmenityActions.updateRevertAction().type:
      return { ...state, ...action.update, ...action.payload }
    case clearAmenityAction().type:
      return {}
    default:
      return state
  }
}

const amenityViewReducer = (state = null, action) => {
  switch (action.type) {
    case setAmenityViewMode({}).type:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

const availabilityReducer = (state = null, action) => {
  switch (action.type) {
    case GET_AVAILABILITY_SUCCESS:
      const { payload } = action
      return { ...state, ...payload }
    default:
      return state
  }
}

const adminReducer = (state = null, action) => {
  switch (action.type) {
    case GET_ADMIN_SUCCESS:
      const { payload: { pinnedFacilityUuids } } = action
      return { ...state, pinnedFacilityUuids }
    default:
      return state
  }
}

export const amenity = withFetch(AMENITY)(amenityReducer)

export const amenities = withPaginatedFetch(AMENITIES)(reducer)

export const reservations = withFetch(RESERVATIONS)(reducer)

export const availability = withFetch(GET_AVAILABILITY)(reducer)

export const admin = withFetch(GET_ADMIN)(reducer)

export default combineReducers({
  amenity,
  amenityView: amenityViewReducer,
  amenities,
  reservations,
  admin: adminReducer,
  availability: availabilityReducer,
})
