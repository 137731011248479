import { createFetchActions, createUpdateActions } from '../../helpers/actions/creators'

export const TEMPLATE_PERMISSION = 'TEMPLATE_PERMISSION'

export const {
  fetchAction: fetchTemplatePermissionAction,
  fetchStartAction: fetchTemplatePermissionStart,
  fetchSuccessAction: fetchTemplatePermissionSuccess,
  fetchErrorAction: fetchTemplatePermissionError
} = createFetchActions(TEMPLATE_PERMISSION)

export const fetchTemplatePermission = () => fetchTemplatePermissionAction()
