// @flow

export const getPage = (state: Object, start: string) =>
  state.communityFeed.events.events.pagination[start].nextPage

export const getRSVPUser = (state: Object) => {
  const user = state.authorization.profile
  return {
    user_id: state.authorization.id,
    picture: user.picture,
    name: user.name
  }
}
