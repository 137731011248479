import React from 'react'
import Thermostat from './Thermostat'
import Switch from './SwitchDevice'
import Lock from './LockDevice'

const SmartDevice = ({
  type,
  data,
  pushLockStateCommand,
  pushThermostatCommand,
  pushSwitchStateCommand,
  viewBatteryMode,
  firstDeviceID,
  updateSwitchName
}) => {
  if (type === 'thermostat') {
    return (
      <Thermostat
        {...data}
        viewBatteryMode={viewBatteryMode}
        pushThermostatCommand={pushThermostatCommand}
      />
    )
  }
  if (type === 'door_lock') {
    return (
      <Lock
        {...data}
        firstDeviceID={firstDeviceID}
        pushLockStateCommand={pushLockStateCommand}
        viewBatteryMode={viewBatteryMode}
      />
    )
  }
  if (type === 'switch') {
    return (
      <Switch
        {...data}
        updateSwitchName={updateSwitchName}
        pushSwitchStateCommand={pushSwitchStateCommand}
      />
    )
  }
}

export default SmartDevice
