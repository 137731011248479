import compose from 'lodash/flowRight'
import { combineReducers } from 'redux'
import withFetch from '../../helpers/reducers/withFetch'
import { RESIDENT_CHAT_CHANNELS } from '../../chat/channels/actions'

const initialState = null

const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withFetch(RESIDENT_CHAT_CHANNELS)(reducer)
