// @flow
import React from 'react'
import type { BrowserHistory } from 'history'
import { withRouter } from 'react-router-dom'
import ListItemText from '@material-ui/core/ListItemText'
import InfoCard from '../../Common/InfoCard'
import { ListItem, ResidentName } from './styles'
import Flex from '@Common/Flex'

export type Props = {
  name: string,
  status: string,
  role: string,
  userId: string,
  isPmsUser: boolean,
  history: BrowserHistory,
  currentTab: string,
  match: { params: { propertyId: string } },
  showAlertModal: () => {}
}

const checkCurrentTab = currentTab => {
  const tab = currentTab ? currentTab : 'general'
  const tabCheck = tab === 'general' || tab === 'lease'

  return tabCheck
}

const getInfoHead = currentTab => {
  const tabCheck = checkCurrentTab(currentTab)
  return tabCheck ? 'USER MANAGEMENT' : 'RESIDENT'
}

const getRole = role => {
  return role === 'Resident' ? 'Current Resident' : role
}

const UserInfoCard = ({
  name,
  status,
  role,
  userId,
  history,
  match,
  isPmsUser,
  currentTab,
  showAlertModal
}: Props) => (
  <InfoCard
    infoHead={getInfoHead(currentTab)}
    title={
      role === 'Resident' ? (
        <ResidentName>{name}</ResidentName>
      ) : (
        'Property User'
      )
    }
    content={() => (
      <Flex paddingTop="3em" direction="column">
        <ListItem>
          <ListItemText primary={getRole(role)} />
        </ListItem>
        {role === 'Resident' ? (
          <ListItem>
            <ListItemText primary={`${status} User`} />
          </ListItem>
        ) : (
          ''
        )}
        {role === 'Resident' ? (
          <ListItem button>
            <ListItemText
              onClick={() =>
                role === 'Resident' &&
                history.push(
                  `/properties/${
                    match.params.propertyId
                  }/communication/chat/live-chat/users/${userId}`
                )
              }
              classes={{
                root: 'chatWithMe'
              }}
              primary={'Chat With Me'}
            />
          </ListItem>
        ) : (
          ''
        )}
        {!isPmsUser &&
          checkCurrentTab(currentTab) ? (
            <ListItem button>
              <ListItemText
                onClick={showAlertModal}
                classes={{
                  root: 'chatWithMe'
                }}
                primary={'Delete User'}
              />
            </ListItem>
          ) : ''}
      </Flex>
    )}
  />
)
// $FlowDisableNextLine
export default withRouter(UserInfoCard)
