// @flow
import { connect } from 'react-redux'
import PostsFeed from './PostsFeed'
import withFetchData from 'zego-shared/views/withFetchData'
import { fetchPosts } from 'zego-shared/store/posts'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { createIsFetchingNextSelector } from 'zego-shared/store/isFetchingNext'
import { fetchNextPosts } from 'zego-shared/store/posts/postsActions'
import {
  hasPostsData,
  getPosts,
  getShouldFetchNextPosts
} from '../../../store/CommunityFeed/communityFeedSelectors'

const fetchingSelector = createIsFetchingSelector(['posts'])
const fetchingNextSelector = createIsFetchingNextSelector(['posts'])

const mapStateToProps = (state, { match: { url } }) => ({
  posts: getPosts(state, url),
  hasData: hasPostsData(state),
  isFetching: fetchingSelector(state),
  isFetchingNext: fetchingNextSelector(state),
  shouldFetchNext: getShouldFetchNextPosts(state)
})

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { propertyId }
    }
  }
) => ({
  fetchData: () => {
    dispatch(fetchPosts(propertyId))
  },
  fetchNext: () => {
    dispatch(fetchNextPosts(propertyId))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(PostsFeed))
