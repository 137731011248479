import styled from '@emotion/styled'
import { convertHexToRGB } from '../../../utils/commonUtils'
import MaterialTextField from '../../Common/TextField'

export const Container = styled('div')`
${({ theme }) => {
  return `
  & .make-select {
    font-size: ${theme.custom.font.size.sm};
    color: ${theme.palette.neutrals.coolGray};
  }

  ${Image} {
    width: 100%;
    height: 45%;
    background: ${theme.palette.neutrals.lightGray};
    top: 40%;
    left:0;
  `
}};
`
export const Image = styled('span')`
  position: absolute;
  background-size: cover;
  background-image: url(${({ imageSrc }) => imageSrc});
`

export const Content = styled('div')`
  position: absolute;
  right:0;
  top:25%;
  width: 85%;
  height: 40%;
  box-shadow: ${({ theme }) =>
    `0 1px 10px 0 ${convertHexToRGB(theme.palette.neutrals.warmGray, 0.5)}`};
  padding: 3em 15em 3em 4em;
  background: ${({ theme }) => theme.palette.common.white}
`
export const TextField = styled(MaterialTextField)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color:  ${({ theme }) => theme.palette.neutrals.coolGray};
`

export const Dates = styled('div')`
  position absolute;
  bottom: 10%;
  right: 0;
  padding-right: 20em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color:  ${({ theme }) => theme.palette.neutrals.warmGray};
`

export const Value= styled('span')`
  color:  ${({ theme }) => theme.palette.common.black};
  padding-left: 2em;
`

export const AvatarInput = styled('input')`
  display: none;
`
