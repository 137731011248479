// @flow
import React from 'react'
import { CustomCircularProgress } from './styles'
import { ProgressText, CenteredText, TitleText } from './styles'

type Props = {
  title: string,
  progressText: string,
  progressCaption: string,
  infoText: string,
  size: string,
  underlayColor: string,
  backgroundColor: string,
  lgFont: Boolean
}

class CircularProgress extends React.Component<Props> {
  render() {
    const {
      title,
      progressText,
      progressCaption,
      infoText,
      size,
      underlayColor,
      backgroundColor,
      lgFont,
      ...rest
    } = this.props
    return (
      <div>
        <TitleText>{title}</TitleText>
        <ProgressText size={size} lgFont={lgFont}>
          <span>{progressText}</span>
          {progressCaption && <p>{progressCaption}</p>}
        </ProgressText>
        <CustomCircularProgress
          {...rest}
          size={size}
          value={100}
          underlayColor={underlayColor}
          style={{ position: 'absolute' }}
          classes={{
            circle: 'circle-custom-backdrop'
          }}
        />
        <CustomCircularProgress
          {...rest}
          size={size}
          underlayColor={backgroundColor}
          classes={{
            circle: 'circle-custom-backdrop'
          }}
        />
        <CenteredText>{infoText}</CenteredText>
      </div>
    )
  }
}

export default CircularProgress
