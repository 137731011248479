// @flow
import get from 'lodash/get'

export const getTemplatePermission = (state: Object) => {
  const templatePermission = get(state, ['groupMessaging', 'templatePermission'])
  return templatePermission
}

export const hasTemplatePermission = (state: Object) => {
  return get(state, ['groupMessaging', 'templatePermission']) !== null
}
