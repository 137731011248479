import styled from '@emotion/styled'
import theme from "../../../../../../assets/src/theme"

export const LeaseInfoContainer = styled('div')`
  height: 2.5231em;
  display: flex;
  -webkit-display: flex;
  -webkit-align-items: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: ${({ theme, lastUnit }) =>
    lastUnit ? `0px` : `1px solid ${theme.palette.neutrals.deepGray}`};

  * {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }

  a {
    color: ${({ theme }) => theme.palette.primary.green.mint};
  }

  svg {
    margin-top: 5px;
    margin-right: 15px;
  }
`
export const EmptySpace = styled('div')`
  height: 25px;
`

export const Link = styled('a')`
  font-size: 12px;
  text-decoration-line: underline;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
`
export const RedDotStyled = styled.div`
  height: 5px;
  width: 5px;
  background: ${({ theme }) => theme.palette.secondary.pink.coral};
  border-radius: 50%;
  margin: 7px 5px 0 0;
  display : flex;
  justify-content: center;
`
export const ResetLink = styled.div`
  margin-top: 13px;
  font-size: 12px;
`
export const ResetCheckListDateStyled = styled.span`
  color: ${({ theme }) => theme.palette.text.light};
  margin-left: 13px;
  font-size: 12px;
`
export const DialogMessage = styled('div')`
  text-align: center;
`
export const DialogFooterContainer = styled('div')`
  text-align: left;
  font-size: 12px;
`
export const DialogFooterMessage = styled('div')`
  float: right;
  width : 92%;
  color:${({ theme }) =>theme.palette.neutrals.deepGray};
`
export const warningIcon = {
   float:'left',
   color: theme.palette.secondary.yellow.golden
}
