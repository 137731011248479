import styled from '@emotion/styled'
import { css } from 'emotion'
import MaterialFormControl from '@material-ui/core/FormControl'
import MaterialInputLabel from '@material-ui/core/InputLabel'
import MaterialMenuItem from '@material-ui/core/MenuItem'
import Button from '../Button'

export const FormControl = styled(MaterialFormControl)`
  min-width: 100px;
  font-weight: ${({ theme, weight }) => weight || theme.custom.font.weight.bold};
  ${({ width }) =>
    width &&
    `
  width: ${width};
  `};
  *:focus {
    background: none;
  }
  & .green-dropdown {
    color: ${({ theme }) => theme.palette.primary.green.mint};
  }
`

export const menuItemFocus = css`
  &:focus {
    background-color: rgba(0, 0, 0, 0.08);
  }
`
export const InputLabel = styled(MaterialInputLabel)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`
export const MenuItem = styled(MaterialMenuItem)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`

export const ButtonStyled = styled(Button)`
  width: 100%;
  `