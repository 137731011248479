// @flow

import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import type { Match } from 'react-router-dom'
import type { BrowserHistory } from 'history'
import { withTheme } from 'emotion-theming'
import BaseCard from '@Common/Cards/BaseCard'
import Loader from '../../Common/Loader'
import { CTAButtonAdd } from '../../Common/CTAButtons'
import Grid, { GridItem } from '../../Common/Grid'
import Header from '@Common/Header'
import { Theme } from '../../../theme/'
import Flex, { FlexItem } from '@Common/Flex'
import { getDateTime, getDate } from 'zego-shared/utils/date'
import { CommonIcon, SearchIcon } from '@icons'
import Tabs from '@Common/Cards/Tabs'
import ScrollToTop from '@Common/ScrollToTop'
import QuickLinks from '../../QuickLinks/QuickLinksContainer'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import { ListItem, ListItemText } from '@material-ui/core'
import { FabButton } from '../../Common/CTAButtons/styles'
import ReactSelect from 'react-select'
import Calendar from '@Common/Calendar'
import moment, { Moment } from 'moment'
import { ClickAwayListener, InputAdornment } from '@material-ui/core';
import StyledSwitch from '../StyledSwitch'

import {
  Content,
  Layout,
  Container,
  TableContainer,
  StyledColumn,
  StyledCell,
  SelectStyle,
  FilterSelectStyle,
  CaptionHeading,
  SummaryContent,
  SummaryListNumber,
  SummaryListText,
  BackButton,
  StyledSelect,
  StyledSelectCustomStyles,
  CalendarButton,
  CalendarWrapper,
  FilterDisplay,
  StyledTable,
  SearchField
} from './styles'

type Filter = {
  Category?: string,
  Assignee?: string,
  Status?: string,
}
const statuses = ['Open', 'InProgress', 'Closed']

type Props = {
  history: BrowserHistory,
  theme: Theme,
  match: Match,
  isFetching?: boolean,
  filter?: string,
  assignees: [Object],
  workOrders: Array<Object>,
  workOrderCounts: Object,
  propertyId: number,
  unitName: ?string,
  unitId: ?number,
  updateWorkOrder(workOrder: Object): void
}

type State = {
  currentPage: number,
  showFilterOptions: boolean,
  sortDirection: number,
  sortBy: string,
  filters: Filter,
  date?: Moment,
  showCalendar: boolean,
  searchText: string
}

const Sort_Direction = {
  ASC: 1,
  DESC: -1
}

const WorkOrderStatus = {
  'Open': 'Open',
  'InProgress': 'In Progress',
  'Closed': 'Closed'
}

const sortByValues = [
  {
    label: "Submission Date - Newest to Oldest",
    value: "submittedDate",
    direction: Sort_Direction.DESC
  },
  {
    label: "Submission Date - Oldest to Newest",
    value: "submittedDate",
    direction: Sort_Direction.ASC
  }
]

const tabs = [
  {
    name: 'All',
    query: { filter: 'all' },
    key: 'All',
  },
  {
    name: 'Open',
    query: { filter: 'Open' },
    key: 'Open'
  },
  {
    name: 'In Progress',
    query: { filter: 'InProgress' },
    key: 'InProgress'
  },
  {
    name: 'Closed',
    query: { filter: 'Closed' },
    key: 'Closed'
  }
]

const columns = [
  {
    value: <StyledColumn index={1}>ID#</StyledColumn>
  },
  {
    value: (
      <StyledColumn index={2}>LOCATION / SUBMITTED BY</StyledColumn>
    )
  },
  {
    value: (
      <StyledColumn index={3}>CATEGORY</StyledColumn>
    )
  },
  {
    value: (
      <StyledColumn index={4}>ASSIGNED TO</StyledColumn>
    )
  },
  {
    value: (
      <StyledColumn index={5}>STATUS</StyledColumn>
    )
  },
  {
    value: (
      <StyledColumn index={6}>DATE / TIME</StyledColumn>
    )
  }
]

const rowsPerPage: number = 8;

class WorkOrderSummary extends React.Component<Props, State> {
  state = {
    filters: {
      Category: '',
      AssignedTo: '',
      Status: this.getFilterFromProps(this.props.filter),
    },
    date: '',
    showCalendar: false,
    showFilterOptions: false,
    sortBy: 'submittedDate',
    sortDirection: Sort_Direction.DESC,
    currentPage: 0,
    searchText: ''
  }

  getFilterFromProps(filter) {
    if (filter) {
      const tab = tabs.find(tab => tab.query.filter === filter)
      return (tab && tab.key) || 'All';
    }
    return 'All'
  }

  handleSortBy = (data) => {
    const { value, direction } = data
    this.setState({ sortBy: value, sortDirection: direction })
  }

  handleDate = (date: Moment) => {
    this.setState({ date: date, showCalendar: false })
  }

  toggleCalendar = () => {
    this.setState((prevState) => ({ showCalendar: !prevState.showCalendar }))
  }

  closeCalendar = (e) => {
    const clickArea = e.target.parentElement.id
    if (clickArea !== "calendarToggle") {
      this.setState({ showCalendar: false })
    }
  }

  handleStatusFilter = (e) => {
    const searchFilter = e.target.innerText
    this.setState((state) => ({
      filters: { ...state.filters, Status: searchFilter }
    }))
  }

  toggleAndClearFilter = () => {
    // reset tab to All
    this.props.history.push({
      search: '?filter=all'
    })

    // reset filters on state, hide filters view on table,
    this.setState((state) =>
      ({
        showFilterOptions: !state.showFilterOptions,
        sortBy: "submittedDate",
        sortDirection: Sort_Direction.DESC,
        filters: { Category: '', AssignedTo: '', Status: "All" },
        showCalendar: false,
        date: ''
      })
    )
  }

  getCategory = (category, subCategory) => {
    return category ? (category + (subCategory ? ` - ${subCategory}` : '')) : ''
  }

  getCategoriesFromWorkOrders = (workOrders) => {
    const options = workOrders
      .map(wo => {
        let categoryString = this.getCategory(wo.category, wo.subCategory)
        return { label: categoryString, value: categoryString }
      })

    let filteredOptions = [...new Set(options.map(o => o.label))]
    filteredOptions = filteredOptions.map(option => ({ label: option, value: option }))

    return filteredOptions
  }

  handleAssigneeSelect = (data) => {
    this.setState((state) =>
      ({
        filters: { ...state.filters, AssignedTo: data.label }
      })
    )
  }

  handleCategorySelect = (data) => {
    this.setState((state) =>
      ({
        filters: { ...state.filters, Category: data.label }
      })
    )
  }

  goToDetails = (event, row) => {
    if (event.target.closest("#assignedTo,#status")) {
      return
    }

    this.props.history.push(row[0].value.props.children.props.to)
  }

  createRows = (workOrders) => {
    const { filters, sortBy, sortDirection, date, searchText } = this.state
    const { assignees, propertyId } = this.props

    const getAssigneeName = (assigneeId) => {
      const assignee = assignees.find(a => a.value === assigneeId)
      return assignee && assignee.label
    }

    // sort by date, before formatting for view
    if (date) {
      workOrders = workOrders.filter(row =>
        Date.parse(row.submittedDate) >= Date.parse(getDate(date))
      )
    }

    if (sortBy) {
      workOrders = workOrders.sort((rowA, rowB) => {
        const dateA = Date.parse(rowA[sortBy])
        const dateB = Date.parse(rowB[sortBy])

        return dateA < dateB ? -sortDirection : sortDirection
      })
    }

    // transform data in something usable
    let rows = workOrders.map((rowData, rowIndex) => {
      return {
        ID: <Link to={`/properties/${propertyId}/work-orders/details/${rowData.workOrderId}`}>{rowData.workOrderNumber}</Link>,
        Location: rowData.location,
        Category: this.getCategory(rowData.category, rowData.subCategory),
        AssignedTo: (
          <ReactSelect
            id="assignedTo"
            onChange={value => this.handleSelect(value, rowIndex)}
            value={{ value: rowData.assignee, label: getAssigneeName(rowData.assignee) }}
            options={assignees}
            styles={SelectStyle}
            isDisabled={rowData.status === 'Closed'}
            components={{
              IndicatorSeparator: () => null
            }}
          />
        ),
        Status: (
          <ReactSelect
            id="status"
            isDisabled={rowData.status === 'Closed'}
            onChange={value => this.handleStatusSelect(value, rowIndex)}
            value={{ value: rowData.status, label: rowData.status === 'InProgress' ? 'In Progress' : rowData.status }}
            options={statuses.map(status => ({ value: status, label: status === 'InProgress' ? 'In Progress' : status }))}
            styles={SelectStyle}
            components={{
              IndicatorSeparator: () => null
            }}
          />
        ),
        SubmittedDate: (
          <div style={{ textAlign: "right" }}>{getDateTime(rowData.submittedDate)}</div>
        )
      }
    })

    // filter by category, assigned to, status
    for (const [key, value] of Object.entries(filters)) {
      if ((key === "AssignedTo" || key === "Status") && value && value !== 'All') {
        rows = rows.filter(row => row[key].props.value.label === value)
      }
      else if (value && value !== 'All') {
        rows = rows.filter(row => row[key] === value)
      }
    }

    if (searchText) {
      rows = rows.filter(row =>
        row['ID'].props.children.toString().includes(searchText) || row['Location'].toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
      )
    }

    let formattedRows = rows.map((rowData, rowIndex) => {
      return Object.entries(rowData).map(([key, value], index) => {
        return { key: key, value: (<StyledCell index={index + 1}>{value}</StyledCell>) }
      })
    })

    return formattedRows
  }

  handleStatusSelect = (value, index) => {
    let workOrder = this.props.workOrders[index]

    if (workOrder.status !== value.value) {
      workOrder.status = value.value

      this.props.updateWorkOrder(workOrder)
    }
  }

  handleSelect = (value, index) => {
    const workOrder = this.props.workOrders[index]

    if (workOrder.assignee !== value.value) {
      workOrder.assignee = value.value

      this.props.updateWorkOrder(workOrder)
    }
  }

  handleSearchChange = ({
    currentTarget: { value }
  }: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ searchText: value })
  }

  changePage = page => {
    this.setState({ currentPage: page - 1 })
  }

  render() {
    const {
      isFetching,
      theme,
      workOrders,
      workOrderCounts,
      assignees,
      unitName,
      propertyId,
      unitId
    } = this.props
    const {
      sortBy,
      showFilterOptions,
      date,
      showCalendar,
      filters,
      currentPage,
      searchText
    } = this.state

    const showLoader = isFetching
    const rows = this.createRows(workOrders);

    return (
      <React.Fragment>
        {unitId ?
          <GridItem columnStart={1} columnSpan={3} rowStart={1} rowSpan={7}>
            <Grid>
              <BaseCard
                columnStart={1}
                columnSpan={11}
                rowSpan={12}
                rowStart={1}
                color="black"
                header={() =>
                  <React.Fragment>
                    <CaptionHeading>WORK ORDERS</CaptionHeading>
                    <QuickLinks selected="work orders" />
                  </React.Fragment>
                }
                content={() => (
                  <SummaryContent>
                    <Header alternateFontSize midnight>Unit {unitName}</Header>
                    <Flex direction="column">
                      {Object.keys(workOrderCounts).reverse().map(status =>
                        <ListItem key={status}>
                          <ListItemText primary={
                            <Link to={`?filter=${status}`} onClick={() => this.handleStatusFilter({ target: { innerText: WorkOrderStatus[status] } })}>
                              <SummaryListNumber>{workOrderCounts[status]}</SummaryListNumber>
                              <SummaryListText>{WorkOrderStatus[status]}</SummaryListText>
                            </Link>
                          } />
                        </ListItem>
                      )}
                    </Flex>
                  </SummaryContent>
                )}
                style={{ zIndex: 10 }}
              />
            </Grid>
          </GridItem>
          :
          <GridItem columnStart={1} columnSpan={3} rowStart={1} rowSpan={12}>
            <Grid>
              <BaseCard
                columnStart={1}
                columnSpan={8}
                rowSpan={12}
                isLeftSideBG={true}
                rowStart={1}
                header={() => <Header alternateFontSize>Work Orders</Header>}
                showShadow={false}
                transparentBackground
                content={() => (
                  <Content>
                    <b>Track work orders</b> and instantly assign it
                    to a member of your staff

                    <br />
                    <br />
                    Easily view the list of work orders <br />
                    by status and sort by submission date. <br />
                    Or quickly filter the list by category, date,
                    and who is assigned to the task.
                  </Content>
                )}
              />
            </Grid>
          </GridItem>
        }
        <GridItem columnStart={1} columnSpan={12} rowStart={1} rowSpan={12}>
          <Container>
            <Layout>
              <Grid>
                {
                  unitId ?
                    <BackButton>
                      <Link to={`/properties/${propertyId}/work-orders/summary`}>
                        <FabButton variant="fab" color={theme.palette.secondary.yellow.solarFlare} darkIcon>
                          <KeyboardBackspace height="0.5em" width="0.5em" />
                        </FabButton>
                      </Link>
                    </BackButton>
                    :
                    <CTAButtonAdd
                      to={`/properties/${propertyId}/work-orders/new`}
                      color={theme.palette.common.white}
                    />
                }
                <FilterDisplay>
                  <Flex fullWidth>
                    <FlexItem flex={2}>
                      <Tabs
                        onTabClick={this.handleStatusFilter}
                        selectTextColor={theme.palette.common.black}
                        tabs={tabs}
                        customStyle={`
                          .tab-root {
                            color: ${theme.palette.grey.main};
                            font-weight: ${theme.custom.font.weight.light};
                          }
                        `}
                      />
                    </FlexItem>
                    <FlexItem flex={.35} displayFlex>
                      <SearchField
                        value={searchText}
                        onChange={this.handleSearchChange}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon
                                name="search"
                                height="18px"
                                width="16px"
                              />
                            </InputAdornment>
                          )
                        }} />
                    </FlexItem>
                    <FlexItem flex={.42} displayFlex>
                      <StyledSwitch
                        isChecked={this.state.showFilterOptions}
                        handleClick={this.toggleAndClearFilter}
                        theme={theme}
                      />
                    </FlexItem>
                  </Flex>
                </FilterDisplay>
                <GridItem
                  columnStart={3}
                  columnSpan={10}
                  rowStart={3}
                  rowSpan={10}>
                  <TableContainer fullWidth={unitId}>
                    {(showFilterOptions &&
                      <Flex direction="row">
                        <FlexItem flex={0.5} displayFlex>
                          <ReactSelect
                            id="AssignedTo"
                            value={filters.AssignedTo ?
                              { value: filters.AssignedTo, label: filters.AssignedTo }
                              : ""}
                            onChange={this.handleAssigneeSelect}
                            styles={FilterSelectStyle(theme)}
                            placeholder="Assigned To"
                            options={assignees}
                            components={{
                              IndicatorSeparator: () => null
                            }}
                          />
                        </FlexItem>
                        <FlexItem flex={0.5} displayFlex>
                          <ReactSelect
                            id="Category"
                            value={filters.Category ?
                              { value: filters.Category, label: filters.Category }
                              : ""}
                            onChange={this.handleCategorySelect}
                            styles={FilterSelectStyle(theme)}
                            placeholder="Category"
                            options={this.getCategoriesFromWorkOrders(workOrders)}
                            components={{
                              IndicatorSeparator: () => null
                            }}
                          />
                        </FlexItem>
                        <FlexItem flex={0.5} displayFlex>
                          <CalendarButton
                            id="calendarToggle"
                            onClick={this.toggleCalendar}
                            style={{ backgroundColor: 'transparent' }}
                            disableRipple >
                            {date ? getDate(date) : "Submission Date"}
                            <CommonIcon
                              name="arrow_down"
                              height={theme.custom.font.size.sm}
                              width={theme.custom.font.size.sm}
                              fill={theme.palette.neutrals.darkGray}
                              stroke={theme.palette.neutrals.darkGray}
                              strokeWidth="2"
                              style={{ marginBottom: "-2px", marginLeft: "20px" }}
                            />
                          </CalendarButton>
                          {(showCalendar &&
                            <ClickAwayListener onClickAway={this.closeCalendar}>
                              <CalendarWrapper>
                                <Calendar
                                  width={"470px"}
                                  flexDirection={'row'}
                                  flex={1}
                                  tabIndex={1}
                                  moment={date || moment()}
                                  handleChange={this.handleDate}
                                  previousDatesEnabled={true}
                                />
                              </CalendarWrapper>
                            </ClickAwayListener>
                          )}
                        </FlexItem>
                        <FlexItem flex={.3} displayFlex>
                          <StyledSelect
                            id="sortByDate"
                            onChange={this.handleSortBy}
                            value={sortBy}
                            placeholder="Sort By"
                            options={sortByValues}
                            styles={StyledSelectCustomStyles(theme)}
                            components={{
                              IndicatorSeparator: () => null
                            }}
                          />
                        </FlexItem>
                      </Flex>
                    )}
                    {showLoader ? <Loader /> : (
                      <StyledTable
                        noDataText="No Work Orders"
                        total={rows.length}
                        flex={[0.25, 1, 1, 1, 1, 1]}
                        columns={columns}
                        rows={rows.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage}
                        changePage={this.changePage}
                        rowClickHandler={this.goToDetails}
                      />
                    )}
                  </TableContainer>
                </GridItem>
              </Grid>
            </Layout>
          </Container>
        </GridItem>
        <ScrollToTop />
      </React.Fragment>
    )
  }
}

export default withRouter(withTheme(WorkOrderSummary))
