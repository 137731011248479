import { FETCH_PREFIX, FETCH_NEXT_PREFIX, SUCCESS } from '../actions/constants'

export default (section, key) => reducer => (state, action) => {
  switch (action.type) {
    case `${FETCH_PREFIX}${section}${SUCCESS}`: {
      return {
        ...state,
        data: action.payload.data,
        totalPages: parseInt(action.payload.headers['total-pages']),
        nextPage: 2,
        total: parseInt(action.payload.headers['total'])
      }
    }
    case `${FETCH_NEXT_PREFIX}${section}${SUCCESS}`: {
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
        totalPages: parseInt(action.payload.headers['total-pages']),
        nextPage: parseInt(action.payload.headers['page-number']) + 1,
        total: parseInt(action.payload.headers['total'])
      }
    }
    default:
      return reducer(state, action)
  }
}
