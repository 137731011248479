import { connect } from 'react-redux'
import { updateWorkOrder, fetchWorkOrderDetail } from 'zego-shared/store/workOrders/actions'
import { fetchUnits } from 'zego-shared/store/select/units/actions'
import { fetchPropertyUsersSummary } from 'zego-shared/store/users/propertyUsersSummary/actions'
import withFetchData from 'zego-shared/views/withFetchData'
import { getWorkOrderDetails, getWorkOrderAssignees } from 'zego-shared/store/workOrders/selectors'
import { getUnitResidents } from 'zego-shared/store/users/selectors'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { getUnits } from 'zego-shared/store/select/units/selectors'
import { getChatEnabled } from 'zego-shared/store/settings/propertySettings/selectors'
import WorkOrderNew from '../New/WorkOrderNew'

const fetchingSelector = createIsFetchingSelector(['workorderdetails'])

export const mapStateToProps = state => ({
  workOrderDetails: getWorkOrderDetails(state),
  isFetching: fetchingSelector(state),
  units: getUnits(state),
  residents: getUnitResidents(state),
  assignees: getWorkOrderAssignees(state),
  chatEnabled: getChatEnabled(state)
})

export const mapDispatchToProps = (
  dispatch,
  { match: { params: { propertyId, workOrderId } } }
) => ({
  fetchData: () => {
    dispatch(fetchWorkOrderDetail(workOrderId))
    dispatch(fetchUnits())
    dispatch(fetchPropertyUsersSummary())
  },
  updateWorkOrder: async (workOrder) => {
    await dispatch(updateWorkOrder(workOrder))
    dispatch(fetchWorkOrderDetail(workOrderId))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(WorkOrderNew))
