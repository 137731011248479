import { connect } from 'react-redux'
import queryString from 'query-string'
import ResidentUsers from './ResidentUsers'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { capitalizeFirstLetter } from 'zego-shared/utils/string'
import {
  getPropertyResidents,
  getPropertyResidentsCount,
  hasData
} from 'zego-shared/store/users/selectors'

const ResidentUsersWithFetchData = withFetchData(ResidentUsers)
const fetchingSelector = createIsFetchingSelector(['propertyUsersSummary'])

const mapStateToProps = (state, { location: { search } }) => {
  const filter =
    capitalizeFirstLetter(queryString.parse(search).status) || 'Active'

  return {
    hasData: hasData(state),
    isFetching: fetchingSelector(state),
    residents: getPropertyResidents(state, filter),
    residentsCount: getPropertyResidentsCount(state),
    filter
  }
}

export default connect(mapStateToProps)(ResidentUsersWithFetchData)
