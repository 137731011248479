import { call, put, takeLatest, select, delay } from 'redux-saga/effects'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import { showSnackbar, hideSnackbar } from '../../snackbar/actions'
import {
  genericErrorMsg,
  failedToSubmitWorkOrder,
  submitWorkOrderSuccess,
  failedToUpdateWorkOrder,
  updateWorkOrderSuccess,
  failedToLoadWorkOrders
} from '../../../utils/messages'
import { getToken } from '../../authorization/selectors'
import { getPropertyId } from 'zego-shared/store/select/selectors'
import {
  fetchWorkOrdersList,
  fetchWorkOrdersListStart,
  fetchWorkOrdersListSuccess,
  fetchWorkOrdersListError,
  fetchWorkOrderOptions,
  fetchWorkOrderOptionsStart,
  fetchWorkOrderOptionsSuccess,
  fetchWorkOrderOptionsError,
  fetchWorkOrderDetails,
  fetchWorkOrderDetailsStart,
  fetchWorkOrderDetailsSuccess,
  fetchWorkOrderDetailsError,
  SUBMIT_WORK_ORDER,
  submitWorkOrderStart,
  submitWorkOrderEnd,
  UPDATE_WORK_ORDER,
  updateWorkOrderStart,
  updateWorkOrderEnd
} from '../actions'

export function* getWorkOrders() {
  try {
    yield put(fetchWorkOrdersListStart())
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)
    const result = yield call(api.getWorkOrdersByProperty, propertyId, authToken)
    yield put(fetchWorkOrdersListSuccess(result))
  } catch (error) {
    yield put(showSnackbar(failedToLoadWorkOrders, 'error'))
    yield put(fetchWorkOrdersListError(error))
    log(error)
  }
}

export function* getWorkOrderDetails({ workOrderId }) {
  try {
    yield put(fetchWorkOrderDetailsStart())
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)
    const result = yield call(api.getWorkOrderDetails, workOrderId, authToken)
    yield put(fetchWorkOrderDetailsSuccess(result))
  } catch (error) {
    yield put(showSnackbar(failedToLoadWorkOrders, 'error'))
    yield put(fetchWorkOrderDetailsError(error))
    log(error)
  }
}

export function* getWorkOrderOptions() {
  try {
    yield put(fetchWorkOrderOptionsStart())

    const authToken = yield select(getToken)
    const response = yield call(api.getStandaloneWorkOrderCategories, authToken)
    yield put(fetchWorkOrderOptionsSuccess({ categories: response }))
  } catch (error) {
    log(error)
    yield put(showSnackbar(genericErrorMsg, 'error'))
    yield put(fetchWorkOrderOptionsError(error))
  }
}

export function* submitWorkOrder({ workOrder, history, url }) {
  try {
    yield put(submitWorkOrderStart)

    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)
    yield call(api.submitWorkOrder, { ...workOrder, propertyId }, authToken)

    yield put(submitWorkOrderEnd)
    yield history.push(url)
    yield put(showSnackbar(submitWorkOrderSuccess, 'success'))
    yield delay(500)
    yield put(hideSnackbar())
  } catch (error) {
    log(error)
    yield put(showSnackbar(failedToSubmitWorkOrder, 'error'))
    yield put(submitWorkOrderEnd)
  }
}

export function* updateWorkOrder({ workOrder }) {
  try {
    yield put(updateWorkOrderStart)

    const authToken = yield select(getToken)
    const result = yield call(api.updateWorkOrder, workOrder, authToken)

    yield put(updateWorkOrderEnd(result))
    yield put(showSnackbar(updateWorkOrderSuccess, 'success'))
    yield delay(500)
    yield put(hideSnackbar())
  } catch (error) {
    log(error)
    yield put(showSnackbar(failedToUpdateWorkOrder, 'error'))
    yield put(updateWorkOrderEnd)
  }
}

export function* watchGetWorkOrders() {
  yield takeLatest(fetchWorkOrdersList().type, getWorkOrders)
}

export function* watchFetchWorkOrderOptions() {
  yield takeLatest(fetchWorkOrderOptions().type, getWorkOrderOptions)
}

export function* watchSubmitWorkOrder() {
  yield takeLatest(SUBMIT_WORK_ORDER, submitWorkOrder)
}

export function* watchUpdateWorkOrder() {
  yield takeLatest(UPDATE_WORK_ORDER, updateWorkOrder)
}

export function* watchGetWorkOrderDetails() {
  yield takeLatest(fetchWorkOrderDetails().type, getWorkOrderDetails)
}

export default [watchGetWorkOrders(), watchFetchWorkOrderOptions(), watchSubmitWorkOrder(), watchUpdateWorkOrder(), watchGetWorkOrderDetails()]
