import { call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken } from '../../authorization/selectors'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  fetchResidentEngagementAction,
  fetchResidentEngagementStart,
  fetchResidentEngagementSuccess,
  fetchResidentEngagementError
} from './actions'

export function* fetchResidentEngagement({ propertyId, unitId }) {
  try {
    yield put(fetchResidentEngagementStart())
    const authToken = yield select(getToken)
    let response
    if (propertyId) {
      response = yield call(
        api.getPropertyResidentAnalytics,
        authToken,
        propertyId
      )
    } else {
      response = yield call(api.getUnitResidentAnalytics, authToken, unitId)
    }

    yield put(fetchResidentEngagementSuccess(response))
  } catch (error) {
    yield put(fetchResidentEngagementError(error))
    log(`Failed to fetch resident analytics data`, error)
  }
}

function* watchfetchResidentEngagement() {
  yield takeLatest(
    fetchResidentEngagementAction().type,
    fetchResidentEngagement
  )
}

export default [watchfetchResidentEngagement()]
