import { combineReducers } from 'redux'
import { byType } from 'zego-shared/store/chat/channels/reducers'
import messages from 'zego-shared/store/chat/messages/reducers'
import user from 'zego-shared/store/users/user/reducers'
import activeChannel from 'zego-shared/store/chat/activeChannel/reducers'
import userChannels from 'zego-shared/store/chat/userChannels/reducer'
import pinnedChannels from 'zego-shared/store/chat/pinnedChannels/reducers'
import { unread } from 'zego-shared/store/chat/messages/reducers'

const chat = combineReducers({
  activeChannel,
  messages,
  user,
  userChannels,
  pinnedChannels,
  byType,
  unread
})

export default chat
