import { FUTURE, searchFuture } from './actions'
import withPaginatedFetch from '../../helpers/reducers/withPaginatedFetch'

const future = (state = null, action) => {
  switch (action.type) {
    case searchFuture().type:
      if (state) {
        const data = state.data

        return {
          ...state,
          searchData: action.searchTerm
            ? data.filter(
                datum =>
                  datum.user.name
                    .toLowerCase()
                    .includes(action.searchTerm.toLowerCase()) ||
                  datum.template.title
                    .toLowerCase()
                    .includes(action.searchTerm.toLowerCase())
              )
            : data
        }
      }
    default:
      return state
  }
}

export default withPaginatedFetch(FUTURE)(future)
