// @flow
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import { CarouselUnitProps as Props } from './types'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import Avatar from '@Common/Avatar'
import { Name, LinkItem } from '../styles'

import { UnitContainer } from '../styles'

const CarouselUnit = ({
  userId,
  name,
  lastUnit,
  picture,
  match: {
    params: { propertyId }
  }
}: Props) => {
  return (
    <UnitContainer lastUnit={lastUnit}>
      <FlexDirection fullWidth>
        <FlexItem flex={0.8}>
          <Avatar size="2.2em" picture={picture} />
        </FlexItem>
        <FlexItem flex={10.2}>
          <Name>{name}</Name>
        </FlexItem>
        <FlexItem flex={1}>
          <LinkItem>
            <Link
              to={`/properties/${propertyId}/user-management/profile/${userId}`}>
              View
            </Link>
          </LinkItem>
        </FlexItem>
      </FlexDirection>
    </UnitContainer>
  )
}

export default withRouter(withTheme(CarouselUnit))
