import styled from '@emotion/styled'

export const Root = styled('div')`
  height: 100%;
  min-height: 768px;
  flex-direction: column;
  position: relative;
  min-width: ${({ theme }) => theme.screenSize.desktop};
  display: grid;
  grid-template-areas:
    'head'
    'body';
  grid-template-rows: 70px 1fr;
  ${({ leftImageSrc, rightImageSrc }) => {
    if (leftImageSrc && rightImageSrc) {
      return `background: url(${leftImageSrc}) left bottom, url(${rightImageSrc}) right;`
    } else if (leftImageSrc) {
      return `background: url(${leftImageSrc}) left bottom;`
    } else if (rightImageSrc) {
      return `background: url(${rightImageSrc}) right;`
    }
  }};
  background-position: ${({ leftImageSrc }) => leftImageSrc && '45px'};
  background-size: contain;
  background-repeat: no-repeat;
`

export const Content = styled('div')`
  margin-left: 60px;
  grid-area: body;
  padding: 1em 2em 1em 0.938em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`

export const Container = styled('div')`
  max-height: calc(100vh - 100px);
  min-height: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  grid-gap: 2.5em;
`
export const Image = styled('span')`
  position: absolute;
  pointer-events: none;
`
