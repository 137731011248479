// @flow

import React from 'react'
import moment from 'moment'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { daysInWeekShort } from '../../../utils'

import { CalendarWrapper, NavElement, DateSelect } from './styles'

const currentYear = new Date().getFullYear()
const fromMonth = new Date(currentYear - 10, 0)
const toMonth = new Date(currentYear + 10, 11)

const YearMonthForm = ({ date, localeUtils, onChange }) => {
  const months = localeUtils.getMonths()

  const years = []
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i)
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form
    onChange(new Date(year.value, month.value))
  }

  return (
    <form className="DayPicker-Caption">
      <DateSelect name="month" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {moment().month(month).format('MMM')}
          </option>
        ))}
      </DateSelect>
      <DateSelect
        name="year"
        onChange={handleChange}
        value={date.getFullYear()}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </DateSelect>
    </form>
  )
}

type NavbarProps = {
  onPreviousClick: Function,
  onNextClick: Function,
  className: string,
}

export const Navbar = ({
  onPreviousClick,
  onNextClick,
  className,
}: NavbarProps) => {
  const styleLeft = {}
  const styleRight = {}
  return (
    <div className={className}>
      <NavElement style={styleLeft} onClick={() => onPreviousClick()}>
        ←
      </NavElement>
      <NavElement style={styleRight} onClick={() => onNextClick()}>
        →
      </NavElement>
    </div>
  )
}

type Props = {
  onDayClick: Function,
  date: Object,
  width: string,
  multidayEnabled: boolean,
  ranges: Object[],
  modifiers: Object
}

type State = {
  date: Object,
}

export default class CalendarV2 extends React.Component<Props, State> {
  state = {
    date: new Date(this.props.date),
  }

  handleYearMonthChange = (date: Object) => {
    this.setState({ date })
  }

  onDayClick = (date: Object) => {
    this.setState({ date })
    this.props.onDayClick(moment(date))
  }

  render() {
    const { date } = this.state

    let { modifiers } = this.props

    return (
      <CalendarWrapper width={this.props.width}>
        <DayPicker
          month={date}
          fromMonth={fromMonth}
          toMonth={toMonth}
          weekdaysShort={daysInWeekShort}
          onDayClick={this.onDayClick}
          selectedDays={date}
          modifiers={modifiers}
          captionElement={({ date, localeUtils }) => (
            <YearMonthForm
              date={date}
              localeUtils={localeUtils}
              onChange={this.handleYearMonthChange}
            />
          )}
        />
      </CalendarWrapper>
    )
  }
}
