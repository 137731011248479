import { connect } from 'react-redux'
import CommunityFeed from './CommunityFeed'
import withFetchData from 'zego-shared/views/withFetchData'
import {
  filterPosts,
} from 'zego-shared/store/posts/postsActions'
import {
  filterEvents,
  cancelFilterEvents
} from 'zego-shared/store/events/eventsActions'
import { getPostAuthors, getThreadType } from '../../../store/CommunityFeed'
import { fetchPosts } from 'zego-shared/store/posts'
import { fetchPropertyUsersSummary } from 'zego-shared/store/users/propertyUsersSummary/actions'

const CommunityFeedWithFetchData = withFetchData(CommunityFeed)

const mapStateToProps = (
  state,
  {
    match: {
      params: { type }
    }
  }
) => {
  return {
    postAuthors: getPostAuthors(state),
    type: getThreadType(type)
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { propertyId }
    }
  }
) => {
  return {
    fetchData: () => {
      dispatch(fetchPropertyUsersSummary(propertyId))
    },
    filterPosts: (date, userId, sortBy) => {
      dispatch(filterPosts(date, userId, 'feed', sortBy, propertyId))
    },
    filterEvents: (date, userId, sortBy) => {
      dispatch(filterEvents(date, userId, 'event', sortBy, propertyId))
    },
    cancelFilterEvents: () => {
      dispatch(cancelFilterEvents())
    },
    fetchPosts: () => {
      dispatch(fetchPosts(propertyId))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityFeedWithFetchData)
