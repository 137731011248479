// @flow
import React, { useState, useEffect } from 'react'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import { withTheme } from 'emotion-theming'
import Flex from '@Common/Flex'
import Button from '@Common/Button'
import Loader from '@Common/Loader'
import AlertModalDynamic from '@Common/AlertModalDynamic/AlertModalDynamic'
import AlertModalPhoto from '@Common/AlertModalPhoto/AlertModalPhoto'
import DownloadImg from '../../../../../src/images/download.png'
import { getDateTimeSimple, getDateFullYear, getDateFullYearAmPm } from 'zego-shared/utils/date.js'
import { useHistory } from 'react-router-dom';
import {
  HeaderWrapperStyled,
  WorkOrderNeededStyled,
  MimoChecklistSummaryStyled,
  TableActionStyled,
  TableSectionNameStyled,
  ChecklistItemName,
  SwitchStyled,
  ActionTableCellStyle,
  ImageStyle,
  ImageHolder,
  TableRowStyled,
  SummaryTablesStyled,
  TableStyled,
  FooterStyled,
  TotalStyled,
  NoActionStyled,
  AlertModalClass,
  Link,
  WorkOrderText,
  DownloadButton,
  WorkOrderNumberStyle,
  NoActionStyledBlank,
  DateLabels,
  ApproverDisplay,
  ApproverName,
  DateWithTime,
  ApprovalButtonStyles,
  HeaderStyled,
  SubmittedDaysAgo,
  LeftFooterStyled,
  ResidentSignatureImageStyle,
  StyledDaysAgo,
  ChecklistSubmittedDateWithTime,
  ResidentSignature,
  ResidentFullName,
  UnApprovedHeaderStyled,
  RatingCommentsStyle,
  CommentsStyle,
  PipeStyle,
  PMSignatureText,
  RightFooterStyled,
  SignatureFooter,
  SignatureImageHolder
} from './styles'
import moment from 'moment'

const Good = 1
const Repair = 2
const Dirty = 3
const Na = 4
const NotSelected = 0

const Header = ({ workOrderNeeded, isActionEnabled, checklistSubmittedDate }: { workOrderNeeded: number, isActionEnabled: boolean, checklistSubmittedDate: string }) => {
  let today = new Date().toString()
  let daysAgoSubmitted = moment(getDateFullYear(today)).diff(getDateFullYear(checklistSubmittedDate), 'days');
  return (
    <HeaderWrapperStyled>
      <UnApprovedHeaderStyled>Move-in Checklist Summary</UnApprovedHeaderStyled>
      <SubmittedDaysAgo>Submitted : {moment.utc(checklistSubmittedDate).format('MM/DD/YYYY')} | <StyledDaysAgo>{daysAgoSubmitted} Days Ago</StyledDaysAgo></SubmittedDaysAgo>
      {isActionEnabled &&
        <WorkOrderNeededStyled>
          <b>{workOrderNeeded}</b> {workOrderNeeded > 1 ? 'Total Work Orders Needed' : 'Total Work Order Needed'}
        </WorkOrderNeededStyled>}
    </HeaderWrapperStyled>
  )
}

const WorkOrderHeader = (
  {
    workOrderCreated,
    isActionEnabled,
    isSubmittingPdf,
    checklistSummary,
    download
  }: {
    workOrderCreated: number,
    isActionEnabled: boolean,
    isSubmittingPdf: boolean,
    checklistSummary?: Object,
    download: Function
  }) => {

  return (
    <HeaderWrapperStyled>
      <HeaderStyled >
        Move-in Checklist Summary
        {isActionEnabled &&
          <span style={{ paddingTop: '10px' }}>
            <WorkOrderText>- {workOrderCreated} Total Work Orders Created</WorkOrderText>
          </span>}
      </HeaderStyled>
      {checklistSummary && (isSubmittingPdf ? <p>Downloading...</p> :
        <DownloadButton onClick={() => download()} src={DownloadImg}></DownloadButton>)
      }
    </HeaderWrapperStyled>
  )
}

const SummaryItem = ({
  roomName,
  attributeName,
  comments,
  images,
  status,
  hasData,
  updateToggle,
  updateToggleStatus,
  residentRoomCheckListInfo,
  workOrderNumber,
  checklistInfo,
  isActionEnabled
}: {
  roomName?: string,
  attributeName: string,
  comments?: string,
  images?: Object[],
  status?: number,
  hasData: boolean,
  updateToggle: Function,
  updateToggleStatus: Function,
  residentRoomCheckListInfo: Object,
  workOrderNumber?: string,
  checklistInfo?: Object,
  isActionEnabled: boolean
}) => {
  const [toggleStatus, setToggleStatus] = useState(true)
  const [showPhotoModal, setShowPhotoModal] = useState(false)
  const [defaultPhoto, setDefaultPhoto] = useState(0)

  const handleToggle = () => {
    updateToggleStatus(residentRoomCheckListInfo, toggleStatus)
    setToggleStatus(!toggleStatus)
    if (toggleStatus) {
      updateToggle(0)
    } else {
      updateToggle(1)
    }
  }

  return (
    <TableRowStyled>
      <ChecklistItemName padding="none" hasData={hasData}>
        {attributeName}
      </ChecklistItemName>
      <RatingCommentsStyle padding="none" hasData={hasData}>
        {status === Repair ? 'Repair' : (status === Dirty ? 'Dirty' : '')}
      </RatingCommentsStyle>
      <PipeStyle>{hasData ? ' | ' : ''}</PipeStyle>
      <CommentsStyle padding="none" hasData={hasData}>
        <i>{comments}</i>
      </CommentsStyle>
      <ActionTableCellStyle padding="10px" hasData={hasData}>
        {hasData ? (
          <Flex>
            <ActionTableCellStyle padding="none" hasData={hasData} >
              <ImageHolder>
                {images && images.map((object, i) => images[i]['imageUrl'] && (<ImageStyle src={images[i]['imageUrl']} key={i}
                  onClick={() => {
                    setShowPhotoModal(true)
                    setDefaultPhoto(i)
                  }
                  } />))}
              </ImageHolder>
              {showPhotoModal &&
                <AlertModalPhoto
                  id="LeavePageConfirm"
                  title={roomName}
                  subTitle={attributeName}
                  titleDesc={comments}
                  images={images}
                  photoIndex={defaultPhoto}
                  maxWidth="md"
                  message="Demo"
                  messageHighlight=""
                  emptyContentText="No images found"
                  show={showPhotoModal}
                  onClose={() => setShowPhotoModal(false)}
                  classes={{
                    root: AlertModalClass
                  }}
                />}
            </ActionTableCellStyle>
            {isActionEnabled &&
              <>
                {checklistInfo && checklistInfo.approvalStatus !== 'Approved' ? (
                  <SwitchStyled checked={toggleStatus}
                    onClick={handleToggle}
                    classes={{
                      bar: 'bar',
                      icon: 'icon',
                      switchBase: 'base',
                      checked: 'true',
                      root: 'root',
                    }}
                  />
                ) :
                  workOrderNumber == null ? (<NoActionStyled style={NoActionStyledBlank}>--</NoActionStyled>) : <Link style={WorkOrderNumberStyle}>{workOrderNumber}</Link>}
              </>}
          </Flex>
        ) :
          <NoActionStyled>--</NoActionStyled>
        }
      </ActionTableCellStyle>
    </TableRowStyled>
  )
}

const SummaryTable = ({
  tableData,
  roomName,
  workOrderNeeded,
  updateWork,
  checklistInfo,
  isActionEnabled,
  updateToggleStatus,
  workOrderCreated,
}: {
  tableData: Object[],
  roomName: string,
  workOrderNeeded: number,
  updateWork: Function,
  checklistInfo: Object,
  isActionEnabled: boolean,
  updateToggleStatus: Function,
  workOrderCreated: number,
}) => {
  const hasData = tableData.length > 0
  const [workorderCount, setWorkOrderCount] = useState(workOrderNeeded)

  const updateWorkOrderCount = (data) => {
    if (data) {
      setWorkOrderCount(workorderCount + 1)
      updateWork(workOrderNeeded - 1)
    } else {
      setWorkOrderCount(workorderCount - 1)
      updateWork(workOrderNeeded + 1)
    }
  }
  return (
    <TableStyled>
      <TableHead>
        <TableSectionNameStyled>{roomName}</TableSectionNameStyled>
        <TableCell />
        {isActionEnabled && (checklistInfo && checklistInfo.approvalStatus !== 'Approved' ? (
          <TableActionStyled>Action Required</TableActionStyled>
        ) :
          <TableActionStyled>Work Order ID</TableActionStyled>
        )}
      </TableHead>
      <TableBody>
        {tableData.map((residentRoomCheckListInfo, index) => {
          return <SummaryItem
            key={index}
            updateToggle={updateWorkOrderCount}
            isActionEnabled={isActionEnabled}
            {...residentRoomCheckListInfo}
            residentRoomCheckListInfo={residentRoomCheckListInfo}
            hasData={hasData}
            roomName={roomName}
            updateToggleStatus={updateToggleStatus}
            checklistInfo={checklistInfo}
          />
        })}
        {!hasData &&
          <SummaryItem
            attributeName="No issues reported"
            updateToggleStatus={updateToggleStatus}
            isActionEnabled={isActionEnabled}
            hasData={hasData}
            residentRoomCheckListInfo={[]}
            updateToggle={updateWorkOrderCount}
            roomName=""
            checklistInfo=""
          />
        }
      </TableBody>
    </TableStyled>
  )
}

const Total = ({
  label,
  numberOfItems,
  totalItems,
}: {
  label: string,
  numberOfItems: string,
  totalItems: number,
}) => {
  return (
    <TotalStyled>
      {label}: <b>{numberOfItems} {totalItems > 1 ? 'Items' : 'Item'}</b>
    </TotalStyled>
  )
}

const MimoChecklistSummary = ({
  checklistSummaryRemote,
  fetchChecklistSettings,
  checklistinfo,
  theme,
  profileComplete,
  getChecklistSummary,
  submitWorkOrder,
  submitIntegratedWorkOrder,
  isLoading,
  status,
  propertyId,
  savedChecklist,
  submitChecklistPdf,
  isSubmittingPdf,
  isStandaloneWoEnabled,
  isIntegratedWoEnabled,
  IsWOSubmitting,
  IsPdfUpload,
  pmId,
  showSnackbar,
  checklistSubmittedDate
}) => {
  const isActionEnabled = isStandaloneWoEnabled || isIntegratedWoEnabled
  const { user_id, units } = profileComplete
  const [showModal, setShowModal] = useState(false)
  let history = useHistory()
  const redirect = () => {
    history.push(`/properties/${propertyId}/user-management/profile/${user_id}/lease`)
  }

  useEffect(() => {
    getChecklistSummary(user_id, units[0].id)
    // eslint-disable-next-line
  }, [user_id])
  useEffect(() => {
    fetchChecklistSettings()
    // eslint-disable-next-line
  }, [propertyId])

  const [checklistSummary, setChecklistSummary] = useState([])
  const [workOrderCount, setWorkOrderCount] = useState(0)
  let tempChecklistSummary = ''
  const [totalWoNeeded, setTotalWoNeeded] = useState(0)
  const [totalWorkOrderCount, setTotalWorkOrderCount] = useState(0)
  const [signature, setSignature] = useState('')

  const checkListStatusTypes = {
    Not_Started: 'Not Started',
    In_Progress: 'In Progress',
    Opted_Out: 'Opted Out'
  }
  const errorMsg = 'Error! The move-in checklist you are trying to access does not exist.'

  useEffect(() => {
      if (checklistinfo && (checklistinfo.checklistStatus === checkListStatusTypes.Not_Started || checklistinfo.checklistStatus === checkListStatusTypes.In_Progress || checklistinfo.checklistStatus === checkListStatusTypes.Opted_Out)) {
        showSnackbar(errorMsg, 'error')
        redirect()
    }
    if(checklistinfo === null || checklistinfo === undefined){
      showSnackbar(errorMsg, 'error')
      redirect()
    }
    // eslint-disable-next-line
  }, [checklistinfo, !checklistinfo])

  useEffect(() => {
    (async function () {
      let tempNumber = 0
      try {
        if (checklistSummaryRemote !== null) {
          // eslint-disable-next-line
          tempChecklistSummary = checklistSummaryRemote
          if (tempChecklistSummary) {
            tempChecklistSummary && tempChecklistSummary.map((temp) => {
              temp.residentRoomCheckListInfo = temp.residentRoomCheckListInfo.filter(({ status }) => status !== null && status !== '' && status !== NotSelected && status !== Good && status !== Na)
              tempNumber = tempNumber + temp.residentRoomCheckListInfo.length
              temp.residentRoomCheckListInfo.forEach(({ workOrderNumber }) => {
                if (workOrderNumber) {
                  setTotalWorkOrderCount(totalWorkOrderCount + 1)
                }
              });
              return null
            });
          }
          setTotalWoNeeded(tempNumber)
          setChecklistSummary(tempChecklistSummary)
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [checklistSummaryRemote]);

  useEffect(() => {
    if (isLoading) {
      setShowModal(false)
    }
    setWorkOrderCount(tempCount)
    // eslint-disable-next-line
  }, [isLoading])
  const ValidateWoSummary = async (checklistSummaries, signature) => {
    await setSignature(signature)
    await checklistSummaries && checklistSummaries.forEach((brand) => {
      brand.residentRoomCheckListInfo = brand.residentRoomCheckListInfo.filter((atts) => atts.actionRequired)
    });

    if (isStandaloneWoEnabled) {
      await submitWorkOrder(checklistSummaries, pmId, profileComplete, savedChecklist.disclosure, signature)
      return
    }

    if (isIntegratedWoEnabled) {
      submitIntegratedWorkOrder(checklistSummaries, user_id, units[0].id, pmId, profileComplete, savedChecklist.disclosure, signature)
      return
    }
  }
  let tempCount = 0


  const updateToggleStatus = (data, status) => {
    let roomIdx = checklistSummary.findIndex(room => room.id === data.residentRoomId)
    let attIdx = checklistSummary[roomIdx].residentRoomCheckListInfo.findIndex(atts => atts.id === data.id)
    checklistSummary[roomIdx].residentRoomCheckListInfo[attIdx].actionRequired = !status
  }

  const ValidateModal = async () => {
    if (isActionEnabled) {
      setShowModal(true)
    } else {
      let checklistSummaries = checklistSummary.map(({ residentRoomCheckListInfo, ...checklistItem }) => ({
        ...checklistItem,
        residentRoomCheckListInfo: []
      }))
      await submitWorkOrder(checklistSummaries, pmId, profileComplete, savedChecklist.disclosure, '')
    }
  }
  let selectedWorkOrders = totalWoNeeded - workOrderCount

  if (isLoading) { return <Loader /> }
  return (
    <MimoChecklistSummaryStyled>
      {checklistinfo && checklistinfo.approvalStatus !== 'Approved' ? (
        <Header
          workOrderNeeded={selectedWorkOrders}
          isActionEnabled={isActionEnabled}
          checklistSubmittedDate={checklistinfo.checklistSubmittedDate} />
      ) :
        <>
          <WorkOrderHeader
            workOrderCreated={checklistinfo && checklistinfo.workordersStarted != null ? checklistinfo.workordersStarted : 0}
            isActionEnabled={isActionEnabled}
            isSubmittingPdf={isSubmittingPdf}
            checklistSummary={checklistSummaryRemote}
            download={() => submitChecklistPdf(user_id, units[0].id, profileComplete, savedChecklist.disclosure, signature)}
          />

          <DateLabels>Submitted : {checklistinfo && moment.utc(checklistinfo.checklistSubmittedDate).format('MM/DD/YYYY')} | Approved : {checklistinfo && moment.utc(checklistinfo.approvedDate).format('MM/DD/YYYY')}</DateLabels>
        </>
      }
      <SummaryTablesStyled>
        {checklistSummary && checklistSummary.map(({ roomName, residentRoomCheckListInfo }, index) => (
          <SummaryTable
            key={index}
            workOrderNeeded={workOrderCount}
            workOrderCreated={totalWorkOrderCount}
            updateWork={setWorkOrderCount}
            tableData={residentRoomCheckListInfo}
            roomName={roomName}
            isActionEnabled={isActionEnabled}
            updateToggleStatus={updateToggleStatus}
            checklistInfo={checklistinfo}
          />))}
        {showModal &&
          <AlertModalDynamic
            id="LeavePageConfirm"
            title="Complete Approval"
            maxWidth="md"
            message="By completing the Checklist approval, work orders will be created for all items marked Action Required and notification will be sent to the resident for all items dismissed."
            messageHighlight="Action Required"
            cancelButton={{
              onClick: () => setShowModal(false),
              text: "Cancel",
              width: "135px",
              height: "35px"
            }}
            woButton={{
              onClick: (signature) => ValidateWoSummary(checklistSummary, signature),
              text: "Confirm",
              color: theme.palette.primary.navy.spaceBlue,
              width: "135px",
              height: "35px"
            }}
            isLoading={IsWOSubmitting}
            data={checklistSummary}
            contentHeader="Work Orders to Create:"
            emptyContentText="No work orders to create"
            show={showModal}
            onClose={() => setShowModal(false)}
            classes={{
              root: AlertModalClass,
            }}
          />}
      </SummaryTablesStyled>
      <SignatureFooter>
        <LeftFooterStyled>
          <SignatureImageHolder><ResidentSignatureImageStyle src={checklistinfo && checklistinfo.eSignature}></ResidentSignatureImageStyle></SignatureImageHolder>
          <ResidentFullName>{profileComplete.name}</ResidentFullName>
          <ChecklistSubmittedDateWithTime>
            {checklistinfo && (!checklistinfo.checklistSubmittedDate ? '--' : getDateFullYearAmPm(checklistinfo.checklistSubmittedDate))}
          </ChecklistSubmittedDateWithTime>
          <ResidentSignature>Resident Signature</ResidentSignature>
        </LeftFooterStyled>
        <RightFooterStyled>
          {checklistinfo && checklistinfo.approvalStatus !== 'Approved' ? (
            <FooterStyled>
              {isActionEnabled && <Flex>
                <Total label="Total Work Orders Needed" numberOfItems={selectedWorkOrders + '/' + totalWoNeeded} totalItems={totalWoNeeded} />
              </Flex >}
              <Button style={ApprovalButtonStyles} onClick={() => ValidateModal()} primary> Complete Approval </Button>
            </FooterStyled >
          ) :
            <>
             <SignatureImageHolder>
              {checklistinfo && checklistinfo.pmeSignature &&
                <ResidentSignatureImageStyle src={checklistinfo.pmeSignature}></ResidentSignatureImageStyle>
              }
              </SignatureImageHolder>
              <ApproverDisplay>
                <ApproverName>{checklistinfo && (checklistinfo.pmUser && checklistinfo.pmUser.name)}</ApproverName>
                <DateWithTime>{checklistinfo && getDateTimeSimple(checklistinfo.approvedDate)}</DateWithTime>
                <PMSignatureText>PM Signature</PMSignatureText>
              </ApproverDisplay>
            </>
         }
        </RightFooterStyled>
      </SignatureFooter>
    </MimoChecklistSummaryStyled >
  )
}

export default withTheme(MimoChecklistSummary)
