// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
// $FlowDisableNextLine
import { EditorState, Modifier } from 'draft-js'
import { getReplacementOptions } from 'zego-shared/store/groupMessaging/replacementOptions/selectors'
import withClickOutside from '../withClickOutside'
import { MergeFieldsWrapper, MergeFieldsUL, MergeFieldsLI } from './styles'

type Props = {
  mergeOptions: Array<{ name: string, replaceMatch: string }>,
  setClickOutsideDomNode: (
    ref: React$ElementRef<HTMLDivElement> | null
  ) => mixed,
  modalHandler: { closeAllModals: () => void },
  editorState: any,
  onChange: any
}

type State = {
  active: boolean
}

class MergeFields extends Component<Props, State> {
  state = {
    active: false
  }

  handleClickOutside = () => {
    this.setState({ active: false })
  }

  handleClick = ({ currentTarget }: SyntheticInputEvent<HTMLInputElement>) => {
    const { modalHandler } = this.props
    modalHandler.closeAllModals()
    this.setState(state => ({ active: !state.active }))
  }

  handleClose = (e, replaceMatch: string) => {
    e.stopPropagation()
    const { editorState, onChange } = this.props
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      replaceMatch,
      editorState.getCurrentInlineStyle()
    )
    onChange(EditorState.push(editorState, contentState, 'insert-characters'))
    this.setState({ active: false })
  }

  render() {
    const { mergeOptions, setClickOutsideDomNode } = this.props
    const { active } = this.state
    return (
      <MergeFieldsWrapper
        ref={ref => setClickOutsideDomNode(ref)}
        onClick={this.handleClick}>
        <div className="rdw-option-wrapper">
          <span>[...]</span>
        </div>
        {active && (
          <MergeFieldsUL>
            {mergeOptions.map(({ name, replaceMatch }, index) => (
              <MergeFieldsLI
                key={index}
                onClick={e => this.handleClose(e, replaceMatch)}>
                {name}
              </MergeFieldsLI>
            ))}
          </MergeFieldsUL>
        )}
      </MergeFieldsWrapper>
    )
  }
}

const mapStateToProps = state => ({
  mergeOptions: getReplacementOptions(state)
})

export default connect(mapStateToProps)(withClickOutside(MergeFields))
