import React from 'react'
import { Route } from 'react-router-dom'
import SmartBatteryTabContainer from '../../views/Smart/SmartSummary/BatteryTab'
import SmartHubTabContainer from '../../views/Smart/SmartSummary/HubTab'
import SmartUnitsTabContainer from '../../views/Smart/SmartSummary/UnitsTab'
import VacantScheduleContainer from '../../views/Smart/SmartSummary/VacantScheduleTab'

const SmartSummaryTabRoutes = ({ scheduleEnabled }) => (
  <React.Fragment>
    <Route
      exact
      path="/properties/:propertyId/smart"
      render={props => (
        <SmartUnitsTabContainer {...props} scheduleEnabled={scheduleEnabled} />
      )}
    />
    <Route
      exact
      path="/properties/:propertyId/smart/battery"
      render={props => (
        <SmartBatteryTabContainer
          {...props}
          scheduleEnabled={scheduleEnabled}
        />
      )}
    />
    <Route
      exact
      path="/properties/:propertyId/smart/hub"
      render={props => (
        <SmartHubTabContainer
          {...props}
          scheduleEnabled={scheduleEnabled} />
      )}
    />
    {scheduleEnabled && (
      <Route
        exact
        path="/properties/:propertyId/smart/vacant-schedule"
        render={props => (
          <VacantScheduleContainer
            {...props}
            scheduleEnabled={scheduleEnabled}
          />
        )}
      />
    )}
    <Route
      exact
      path="/properties/:propertyId/smart/units"
      render={props => (
        <SmartUnitsTabContainer {...props} scheduleEnabled={scheduleEnabled} />
      )}
    />
  </React.Fragment>
)

export default SmartSummaryTabRoutes
