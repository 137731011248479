import styled from '@emotion/styled'
import MaterialListItem from '@material-ui/core/ListItem'
import MaterialMobileStepper from '@material-ui/core/MobileStepper'
import MaterialButton from '@material-ui/core/Button'
import MaterialTextField from '@material-ui/core/TextField'
import ReactSelect from 'react-select'

export const Title = styled('div')`
  padding-left: 25px;
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  font-size: ${({ theme }) => theme.custom.font.size.lg};
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
`

export const Subtitle = styled('div')`
  color: ${({ theme }) => theme.palette.text.secondary};
  padding: 10px 0 55px 25px;
`

export const SetupBody = styled('div')`
  display: flex;
  padding-left: 25px;
`

export const Bullet = styled('span')`
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.lg};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  margin-right: 20px;
`

export const CheckItem = styled('span')`
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
`

export const ListItem = styled(MaterialListItem)`
  min-height: 70px;
  padding: 15px 25px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutrals.coolGray};
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  cursor: initial;

  &:hover {
    background: none;
  }
`

export const Select = styled(ReactSelect)`
  flex: 1;
  & > div {
    border-radius: 19px;
    padding-left: 20px;
  }
`

export const MobileStepper = styled(MaterialMobileStepper)`
  align-items: flex-start;
  padding: 0 0px;
`

export const StepButton = styled(MaterialButton)`
  color: ${({ theme }) => theme.palette.primary.green.mint};
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  &:hover {
    background: none;
  }
`

export const SetupMessageWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.green.mint};
  color: ${({ theme }) => theme.palette.common.white};
  font-size: ${({ theme }) => theme.custom.font.size.xxl};
`

export const ButtonLight = styled(MaterialButton)`
  background-color: ${({ theme }) => theme.palette.secondary.light1};
  color: ${({ theme }) => theme.palette.common.white};
  padding: 5px 30px;
  min-width: 90px;
  border-radius: 50px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.light1};
  }
`

export const ButtonDark = styled(MaterialButton)`
  background-color: ${({ theme }) => theme.palette.primary.green.mint};
  color: ${({ theme }) => theme.palette.common.white};
  padding: 5px 30px;
  min-width: 90px;
  border-radius: 50px;
  margin-left: 20px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.green.mint};
  }
`

export const TextField = styled(MaterialTextField)`
  border: 1px solid ${({ theme }) => theme.palette.grey.light};
  border-radius: 50px;
  width: 80%;
  padding: 2px 25px;
`

export const ExitTextWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
`

export const ExitText = styled('span')`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text.primary};
`

export const ExitTextColor = styled('span')`
  color: ${({ theme }) => theme.palette.primary.green.mint};
`
