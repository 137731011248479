// @flow
import React from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ExpansionWrapper } from './styles'
import { Props, State } from './types'

class ExpansionPanels extends React.Component<Props, State> {
  state = {
    expanded: null
  }

  handleChange = (panel: string) => (
    event: SyntheticEvent<HTMLButtonElement>,
    expanded: boolean
  ) => {
    this.setState({
      expanded: expanded ? panel : false
    })
  }

  render() {
    const { expanded } = this.state
    const { panels, width } = this.props

    const isMoreThanOnePanel = panels.length > 1
    const lastPanelIndex = panels.length - 1

    return (
      <ExpansionWrapper width={width}>
        {panels.map(({ details, heading }, index) => (
          <ExpansionPanel
            key={index}
            classes={{
              root: `expansion-panel ${
                isMoreThanOnePanel && index === lastPanelIndex
                  ? 'last-panel'
                  : ''
              }`
            }}
            expanded={expanded === `panel${index}`}
            onChange={this.handleChange(`panel${index}`)}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              {heading}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>{details}</ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </ExpansionWrapper>
    )
  }
}

export default ExpansionPanels
