// @flow
import React from 'react'

import { FlexItem } from '@Common/Flex/FlexDirection'
import { Specs } from './styles'

import { SpecsType } from './types'

export const SwitchSpecs = ({
  deviceModel,
  deviceStatus,
  InstallationDate
}: SpecsType) => (
  <FlexItem flex={1}>
    <Specs>
      <div>{deviceModel}</div>
      <div>Installed {InstallationDate}</div>
      <div>{deviceStatus}</div>
    </Specs>
  </FlexItem>
)
