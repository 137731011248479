import React from 'react'
import PrivateRoute from '../../PrivateRoute'
import {
  GroupMessagingContainer,
  TemplatesContainer,
  GroupsContainer,
  CreateGroupMessage,
  SingleGroupMessageContainer,
  TemplateContainer,
  GroupsUsersContainer
} from '../../../views/Communication/GroupMessaging'

const getGroupMessagingRoutes = () => [
  <PrivateRoute
    exact
    key="groupMesssaging"
    path="/properties/:propertyId/communication/group-messaging"
    component={GroupMessagingContainer}
  />,
  <PrivateRoute
    exact
    key="createGroupMessage"
    path="/properties/:propertyId/communication/group-messaging/create"
    component={CreateGroupMessage}
  />,
  <PrivateRoute
    exact
    key="groupMessagingTemplates"
    path="/properties/:propertyId/communication/group-messaging/templates"
    component={TemplatesContainer}
  />,
  <PrivateRoute
    exact
    key="groupMessagingTemplate"
    path="/properties/:propertyId/communication/group-messaging/templates/:templateId"
    component={TemplateContainer}
  />,
  <PrivateRoute
    exact
    key="groupMessagingGroups"
    path="/properties/:propertyId/communication/group-messaging/groups"
    component={GroupsContainer}
  />,
  <PrivateRoute
    exact
    key="groupMessagingGroupUsers"
    path="/properties/:propertyId/communication/group-messaging/groups/:groupId"
    component={GroupsUsersContainer}
  />,
  <PrivateRoute
    exact
    key="groupMessagingView"
    path="/properties/:propertyId/communication/group-messaging/:status(sent|future|event)/:id"
    sent
    component={SingleGroupMessageContainer}
  />
]

export default getGroupMessagingRoutes
