// @flow
import React from 'react'
import CardBodyWrap from '../CardBodyWrap'
import CircleWithText from '../CircleWithText'

import { BodyText, BodyTitle } from './styles'

type Props = {
  dataCount: number,
  data: string,
  title: string,
  bodyText: string,
  showCircle?: boolean
}

const CallToActionCardContent = ({
  data,
  dataCount,
  title,
  bodyText,
  showCircle
}: Props) => (
  <CardBodyWrap>
    <span
      style={
        !showCircle && { justifyContent: 'flex-start', paddingTop: '1em' }
      }>
      <BodyTitle>{title}</BodyTitle>
      <BodyText>{bodyText}</BodyText>
    </span>
    <span>
      {showCircle && <CircleWithText data={data} dataCount={dataCount} />}
    </span>
  </CardBodyWrap>
)

export default CallToActionCardContent
