// @flow

import React from 'react'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import MenuItem from '@material-ui/core/MenuItem'

import Button from '@Common/Button'
import CheckBox from '@Common/CheckBox'
import Flex, { FlexItem } from '@Common/Flex'
import { menuItemFocus } from '@Common/DropDown/styles'

import {
  AmenityFormDiv,
  AmenityFormCheckBoxDiv,
  AmenityFormWaiver,
  AmenityWaiverFileName,
  AmenityFormDeleteWaiverButton,
  BackgroundDiv,
  DeleteButton,
  AmenityDetailLabel,
} from '../AmenityShared/styles'

import {GreenHeader, TextField} from './style.js'
import HorizRadioButtons from "../../Common/HorizRadioButtons";
import findIndex from 'lodash/findIndex'
import { getDefaultAmenityProperties } from "../../../utils/scheduleUtils";

const reservationTypeOptions = [
  {
    id: 'hourly',
    value: 'hourly',
    label: 'Hourly'
  },
  {
    id: 'fullFacility',
    value: 'fullFacility',
    label: 'Full Facility'
  },
  {
    id: 'multiDay',
    value: 'multiDay',
    label: 'Multi-Day/Overnight'
  },
];

const amenityTypeOptions = (disabled) => [
  {
    id: 'radio-amenity-type-space',
    label: 'Item',
    value: 'item',
    classLabel: 'radio-button-amenity-availability-label',
    disabled,
  },
  {
    id: 'radio-amenity-type-item',
    label: 'Space',
    value: 'space',
    classLabel: 'radio-button-amenity-availability-label',
    disabled,
  },
]

type Props = {
  item: Object,
  editMode: boolean,
  isError: (Object) => boolean,
  updateItem: (Object) => any,
  initiateDelete: () => any,
  initiateWaiverDelete: () => any,
  handleChange: (Object) => any,
  registerFormField: (string) => any,
  showWaiverModal: () => any,
}

export const EditField = ({
  editMode,
  label,
  value,
  children,
  switchMode,
  viewModeWrapper,
} : {
  editMode?: boolean,
  label?: any,
  value?: any,
  children: any,
  switchMode?: boolean,
  viewModeWrapper?: Function
}) => {

  const viewMode = () => (
    <>
      {(label || value) && <FlexItem flex={1}>
        {label && <AmenityDetailLabel>{label}</AmenityDetailLabel>}
        {(value || value === 0) && (
          <span>
            &nbsp;
            {value}
          </span>
        )}
      </FlexItem>}
    </>
  )

  if (switchMode) {
    const [firstChild, secondChild] = children
    return editMode ? firstChild : secondChild
  }

  if (!editMode) {
    return viewModeWrapper
      ? viewModeWrapper(viewMode())
      : viewMode()
  }

  return children
}


export default class AmenityForm extends React.Component<Props, {}> {
  handleAmenityType = (e: any) => {
    this.props.handleChange(e)

    // switch the values for totalOccupancy and then reset the
    // non-relevant one back to 0
    if (e.currentTarget.value === 'item') {
      this.props.updateItem({
        amenityType: e.currentTarget.value,
        reservationLimit: this.props.item.totalOccupancy,
        totalOccupancy: null,
      })
    }
    else if (e.currentTarget.value === 'space') {
      this.props.updateItem({
        amenityType: e.currentTarget.value,
        totalOccupancy: this.props.item.reservationLimit,
        reservationLimit: null,
      })
    }
  }

  getAmenityType(item: Object) {
    const { amenityType, reservationType } = item
    let label = ''
    const type = amenityType === 'item' ? 'Rentable Item:' : 'Reservable Space:'
    label += type
    let resType
    switch (reservationType) {
      case 'multiDay':
        resType = ' Multiday/Overnight'
        break
      case 'fullFacility':
        resType = ' Full Facility'
        break
      case 'hourly':
      default:
        resType = ' Hourly'
        break
    }
    label += resType
    return label
  }

  handleReservationTypeChange = (item: Object) => {
    const { updateItem } = this.props

    const amenityProperties = getDefaultAmenityProperties({ reservationType: item.target.value })

    updateItem({
      ...(!!amenityProperties ? amenityProperties : {})
    })
  }

  switchRsvpRequired = (_e: any, checked: boolean) => {
    const { updateItem } = this.props
    updateItem({ rsvpRequired: checked })
  }

  switchReservableArea = (_e: any, checked: boolean) => {
    const { updateItem } = this.props
    const update = {
      extraReservableArea: checked,
      ...!checked
        ? {
          extraAreaName: null,
          extraAreaQuantity: null,
          resources: {name: null, count: null}
        } : {}
    }
    updateItem(update)
  }

  switchReserveAheadRequired = (_e: any, checked: boolean) => {
    const { updateItem } = this.props
    updateItem({ reserveAheadRequired: checked })
  }

  render() {
    const {
      item,
      isError,
      handleChange,
      registerFormField,
      initiateDelete,
      initiateWaiverDelete,
      showWaiverModal,
    } = this.props

    let currentWaiver = null
    if (item.currentWaiverUuid && item.waiverDocs) {
      const foundWaiver = item.waiverDocs.filter((w) => item.currentWaiverUuid === w.waiverUuid)
      currentWaiver = (foundWaiver && foundWaiver.length === 1) ? foundWaiver[0].fileData.name : null
    }

    if ((item.placeholder && item.waiverDocs) || (!item.currentWaiverUuid && item.waiverDocs)) {
      if (typeof item.waiverDocs === Object) {
        currentWaiver = item.waiverDocs ? item.waiverDocs.name : null
      } else {
        currentWaiver = item.waiverDocs[0] ? item.waiverDocs[0].name : null
      }
    }

    // Pick hourly amenity as default.
    let reservationTypeIndex = Math.max(0, findIndex(reservationTypeOptions, { value: item.reservationType }));

    // Hide or show components based on item state conditions
    const displayExtraReservableArea = item.amenityType === 'space'
      && item.reservationType === 'hourly'
      && item.rsvpRequired

    const flexPadding = this.props.editMode ? '' : '40px'
    const wrapViewModeLabel = (label) => (
      <Flex paddingBottom={flexPadding} class="amenityDetailLabel">{label}</Flex>
    )

    const reservationRequiredLabel = 'Reservation Required'
    const requestToReserveLabel = 'Request to Reserve'
    const extraAreaLabel = "Extra Reservable Area:"
    const extraAreaNameLabel = 'Area Name'
    const extraAreaQuantityLabel = 'Quantity'
    const maxReservationDaysLabel = 'How far out can a resident reserve amenity?'

    const { editMode } = this.props

    return (
      <React.Fragment>
        <AmenityFormDiv>
            <Flex paddingBottom={flexPadding}>
              <EditField
                label={this.getAmenityType(item)}
                editMode={editMode}
                viewModeWrapper={cmp => <GreenHeader children={cmp}/>}
              >
              <FlexItem flex={1}>
                <div>
                  <HorizRadioButtons
                    handleChange={this.handleAmenityType}
                    disabled={item.published}
                    id="amenityType"
                    label="Amenity Type"
                    selectedValue={item.amenityType}
                    validationCall={registerFormField('amenityType')}
                    error={isError('amenityType')}
                    options={amenityTypeOptions(item.published)}
                    textSize="16px"
                    marginLeft="8px"
                    noUnderline
                  />
                </div>
            </FlexItem>
              </EditField>
            </Flex>
            <Flex>
            {/*  RESERVATION TYPE */}
              <EditField editMode={editMode}>
                <FlexItem flex={2}>
                  <TextField
                    addLabelPadding={true}
                    alwaysShowLabel={true}
                    disabled={item.published===true}
                    error={isError('reservationType')}
                    InputLabelProps={{ shrink: true }}
                    id="reservationType"
                    label='Reservation Type'
                    onChange={this.handleReservationTypeChange}
                    padding={null}
                    prefix={null}
                    readOnly={false}
                    select={true}
                    showPencil
                    textAlign={'left'}
                    value={reservationTypeOptions[reservationTypeIndex].label}
                    SelectProps={{
                      renderValue: value => value,
                      disableUnderline: false
                    }}>
                    {reservationTypeOptions.map(option => (
                      <MenuItem
                        style={{ display: 'flex', alignItems: 'center' }}
                        key={option.id}
                        value={option.value}
                        className={`reservation-type-item-js ${menuItemFocus}`}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FlexItem>
              </EditField>
            </Flex>
            {/* AMENITY NAME */}
            <Flex>
              <EditField editMode={editMode}>
                <FlexItem flex={2}>
                  <TextField
                    error={isError('name')}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    alwaysShowLabel={true}
                    addLabelPadding={true}
                    padding={null}
                    textAlign={'left'}
                    prefix={null}
                    label='Amenity Name'
                    id='name'
                    value={item.name}
                    showPencil
                    paddingLeft={0}
                    validationCall={registerFormField('name')}
                  />
                </FlexItem>
              </EditField>
            </Flex>
            {/* DESCRIPTION */}
            <Flex paddingBottom={flexPadding}>
              <EditField editMode={editMode} value={item.description}>
                <FlexItem flex={1}>
                  <TextField
                    error={isError('description')}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    alwaysShowLabel={true}
                    addLabelPadding={true}
                    padding={null}
                    textAlign={'left'}
                    prefix={null}
                    label='Description'
                    id='description'
                    multiline
                    rows='5'
                    value={item.description}
                    showPencil
                    validationCall={registerFormField('description')}
                  />
                </FlexItem>
              </EditField>
            </Flex>
            <Flex>
              {/* RESERVATION REQUIRED */}
              <FlexItem flex={1} paddingBottom={flexPadding}>
                <EditField
                  editMode={editMode}
                  label={reservationRequiredLabel + ':'}
                  value={item.rsvpRequired ? 'Yes' : 'No'}
                  viewModeWrapper={wrapViewModeLabel}
                >
                  <AmenityFormCheckBoxDiv>
                    <CheckBox
                      id="rsvpRequired"
                      iconName="square"
                      checkedIconName="square-checkmark"
                      checked={item.rsvpRequired}
                      onChange={this.switchRsvpRequired}
                      text={reservationRequiredLabel}
                      validationCall={registerFormField('rsvpRequired')}
                    />
                  </AmenityFormCheckBoxDiv>
                </EditField>
                {/* REQUEST TO RESERVE */}
                {item.rsvpRequired && (
                  <EditField
                    editMode={editMode}
                    label={requestToReserveLabel + ':'}
                    value={item.reserveAheadRequired ? 'Yes' : 'No'}
                    viewModeWrapper={(label) => {
                      return item.reserveAheadRequired ? wrapViewModeLabel(label) : <></>
                    }}
                  >
                    <AmenityFormCheckBoxDiv>
                      <CheckBox
                        id="reserveAheadRequired"
                        iconName="square"
                        checkedIconName="square-checkmark"
                        checked={item.reserveAheadRequired}
                        onChange={this.switchReserveAheadRequired}
                        text="Request to Reserve:"
                      />
                    </AmenityFormCheckBoxDiv>
                  </EditField>
                )}
                <div>
                  {item.amenityType === 'space' &&
                    <EditField
                      editMode={editMode}
                      label={'Total Occupancy:'}
                      value={item.totalOccupancy}
                      viewModeWrapper={wrapViewModeLabel}
                    >
                      <TextField
                        error={isError('totalOccupancy')}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        alwaysShowLabel={true}
                        addLabelPadding={true}
                        padding={null}
                        textAlign={'left'}
                        prefix={null}
                        label='Total Occupancy'
                        id='totalOccupancy'
                        value={item.totalOccupancy}
                        showPencil
                        validationCall={registerFormField('totalOccupancy')}
                      />
                    </EditField>
                  }
                  {item.amenityType === 'item' &&
                    <EditField
                      editMode={editMode}
                      label={'Total Available:'}
                      value={item.reservationLimit}
                      viewModeWrapper={wrapViewModeLabel}
                    >
                      <TextField
                        error={isError('reservationLimit')}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        alwaysShowLabel={true}
                        addLabelPadding={true}
                        padding={null}
                        textAlign={'left'}
                        prefix={null}
                        label='Total Available'
                        id='reservationLimit'
                        value={item.reservationLimit}
                        showPencil
                        validationCall={registerFormField('reservationLimit')}
                      />
                  </EditField>
                  }
                </div>
                {/* WAIVER */}
                {item.rsvpRequired && (
                  <EditField switchMode editMode={editMode}>
                    {/* WAIVER - VIEW MODE */}
                    <AmenityFormWaiver>
                      {
                        (currentWaiver) ? (
                          <React.Fragment>
                            <AmenityDetailLabel>Waiver:</AmenityDetailLabel>&nbsp;
                            <AmenityWaiverFileName id="amenity-form-waiver-value">
                              {currentWaiver}
                            </AmenityWaiverFileName>
                            <AmenityFormDeleteWaiverButton
                              id={'amenity-form-waiver-delete-button'}
                              onClick={initiateWaiverDelete}
                              title={`Delete Waiver`}
                            >
                              <HighlightOffIcon color="error" />
                            </AmenityFormDeleteWaiverButton>
                          </React.Fragment>
                        ) : (
                          <Button
                            id="btnSetupWaiver"
                            outline
                            primary
                            onClick={showWaiverModal}
                            long
                          >
                            Setup Waivers
                          </Button>
                        )
                      }
                    </AmenityFormWaiver>
                    {/* WAIVER - VIEW MODE */}
                    <Flex paddingBottom={flexPadding}>
                      <FlexItem flex={1}>
                        <AmenityDetailLabel id="amenity-detail-waiver-label">
                          Waiver:
                        </AmenityDetailLabel>
                        &nbsp;
                        <AmenityWaiverFileName id="amenity-detail-waiver-value">
                          {currentWaiver?.fileData?.name ? (
                            <a
                              href={currentWaiver?.url}
                              target="_blank"
                              rel="noopener noreferrer">
                              {currentWaiver?.fileData?.name}
                            </a>
                          ) : (
                            'None'
                          )}
                        </AmenityWaiverFileName>
                      </FlexItem>
                    </Flex>
                  </EditField>
                )}
              </FlexItem>
              {/* MAX RESERVATION DATE */}
              <FlexItem flex={1}>
                {this.props.item.rsvpRequired && (
                  <EditField
                  editMode={editMode}
                  label={maxReservationDaysLabel}
                  value={item.maxReservationDays + ' Days'}
                  viewModeWrapper={wrapViewModeLabel}
                  >
                    <TextField
                      error={isError('maxReservationDays')}
                      onChange={handleChange}
                      InputLabelProps={{shrink: true}}
                      alwaysShowLabel={true}
                      addLabelPadding={true}
                      padding={null}
                      textAlign={'left'}
                      prefix={null}
                      id='maxReservationDays'
                      label={maxReservationDaysLabel + ' (days)'}
                      value={item.maxReservationDays}
                      showPencil
                      validationCall={registerFormField('maxReservationDays')}
                    />
                  </EditField>
                )}
                {/* EXTRA AREA */}
                {displayExtraReservableArea && (
                  <EditField
                    editMode={editMode}
                    label={extraAreaLabel}
                    value={item.extraReservableArea ? 'Yes' : 'No'}
                  >
                    <AmenityFormCheckBoxDiv>
                      <CheckBox
                        id="rsvpRequired"
                        iconName="square"
                        checkedIconName="square-checkmark"
                        checked={item.extraReservableArea}
                        onChange={this.switchReservableArea}
                        text={extraAreaLabel}
                        validationCall={registerFormField('extraReservableArea')}
                      />
                    </AmenityFormCheckBoxDiv>
                  </EditField> )}
                  { item.extraReservableArea && (
                    <React.Fragment>
                      <div>
                        <EditField
                          editMode={editMode}
                          label={extraAreaNameLabel + ":"}
                          value={item.extraAreaName}
                        >
                          <TextField
                            error={isError('extraAreaName')}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            alwaysShowLabel={true}
                            textAlign={'left'}
                            addLabelPadding={true}
                            padding={null}
                            prefix={null}
                            label={extraAreaNameLabel}
                            id='extraAreaName'
                            value={item.extraAreaName}
                            showPencil
                            validationCall={registerFormField('extraAreaName')}
                          />
                        </EditField>

                      </div>
                      <div>
                        <EditField
                          editMode={editMode}
                          label={extraAreaQuantityLabel + ':'}
                          value={item.extraAreaQuantity}
                        >
                          <TextField
                            error={isError('extraAreaQuantity')}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            alwaysShowLabel={true}
                            textAlign={'left'}
                            addLabelPadding={true}
                            padding={null}
                            prefix={null}
                            label={extraAreaQuantityLabel}
                            id='extraAreaQuantity'
                            value={item.extraAreaQuantity}
                            showPencil
                            validationCall={registerFormField('extraAreaQuantity')} />
                        </EditField>
                      </div>
                    </React.Fragment>
                  )}
              </FlexItem>
            {/* DELETE AMENITY */}
            </Flex>
              <Flex justifyRight>
                <FlexItem>
                  <DeleteButton
                    id='amenity-form-delete-button'
                    onClick={initiateDelete}>
                    Delete Amenity
                  </DeleteButton>
                </FlexItem>
              </Flex>
        </AmenityFormDiv>
        <BackgroundDiv />
      </React.Fragment>
    )
  }
}
