import isPlainObject from 'lodash/isPlainObject'
import { call, select, takeLatest, put } from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import { hasChanges } from '../../utils/object'
import { getToken } from '../authorization/selectors'
import { getPropertyId } from '../select/selectors'
import api from '../../utils/api'
import log from '../../utils/logger'
import { showSnackbar, hideSnackbar } from '../snackbar/actions'
import {
  failedToUpdateAmenity,
  genericDeleteSuccess,
  genericErrorMsg
} from '../../utils/messages'
import {
  fetchAmenitiesAction,
  fetchAmenitiesStart,
  fetchAmenitiesSuccess,
  fetchAmenitiesError,
  fetchAmenityAction,
  fetchAmenityStart,
  fetchAmenitySuccess,
  fetchAmenityError,
  updateAmenityOptimistically,
  updateAmenityRevert,
  updateAmenitySuccess,
  UPDATE_AMENITY,
  DELETE_AMENITY
} from './actions'
import { getAmenity, amenityForApi } from './selectors'

export function* fetchAmenities({ propertyId, page, searchTerm, queryParams }) {
  try {
    yield put(fetchAmenitiesStart())
    const authToken = yield select(getToken)
    let response
    if (searchTerm != null) {
      response = yield call(
        api.searchLegacyAmenitiesOfProperty,
        authToken,
        propertyId,
        searchTerm,
        queryParams
      )
    } else {
      response = yield call(
        api.getLegacyAmenitiesOfProperty,
        authToken,
        propertyId,
        page
      )
    }

    yield put(fetchAmenitiesSuccess(response))
  } catch (error) {
    yield put(fetchAmenitiesError(error))
    log(`Failed to fetch amenities registration`)
  }
}

export function* fetchAmenity({ amenityId, propertyId }) {
  try {
    yield put(fetchAmenityStart())
    const authToken = yield select(getToken)
    const response = yield call(
      api.getLegacyAmenity,
      authToken,
      propertyId,
      amenityId
    )

    yield put(fetchAmenitySuccess(response))
  } catch (error) {
    yield put(fetchAmenityError(error))
    log(`Failed to fetch amenity`)
  }
}

export function* updateAmenity({ update: newAtts, history, url, amenityId }) {
  const currentState = yield select(getAmenity)
  let currentAtts = {}
  let attsToUpdate = {}
  for (let key in newAtts) {
    if (
      (key === 'files' && newAtts[key] !== currentState[key]) ||
      (!isPlainObject(newAtts[key]) && newAtts[key] !== currentState[key]) ||
      (isPlainObject(newAtts[key]) &&
        hasChanges(newAtts[key], currentState[key]))
    ) {
      attsToUpdate[key] = newAtts[key]
      currentAtts[key] = currentState[key]
    }
  }
  if (Object.keys(attsToUpdate).length > 0) {
    const authToken = yield select(getToken)
    try {
      yield put(updateAmenityOptimistically(amenityForApi(newAtts)))
      let response
      const propertyId = yield select(getPropertyId)
      if (amenityId == null) {
        const createPayload = {
          type: 'not_reservable',
          status: 'open',
          ...amenityForApi(attsToUpdate)
        }
        response = yield call(
          api.createLegacyAmenity,
          authToken,
          propertyId,
          createPayload
        )
      } else {
        response = yield call(
          api.updateLegacyAmenity,
          authToken,
          propertyId,
          currentState.id,
          amenityForApi(attsToUpdate)
        )
      }

      yield put(updateAmenitySuccess(response))
      const addMode = url.indexOf('/new') > -1
      if (addMode && response.id != null) {
        const urlPrefix = url.split('/new')[0]
        history.push(`${urlPrefix}/${response.id}`)
      }
    } catch (error) {
      log(`Failed to update amenity. Error: ${error}`)
      yield put(updateAmenityRevert(amenityForApi(currentState)))
      yield put(showSnackbar(failedToUpdateAmenity, 'error'))
    }
  }
}

export function* deleteAmenity({ id, history }) {
  try {
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)

    yield call(api.deleteLegacyAmenity, authToken, propertyId, id)
    yield put(showSnackbar(genericDeleteSuccess, 'success'))
    yield delay(500)
    yield put(hideSnackbar())

    if (!propertyId.toString().match(/\d+/)) {
      history.push('/summary')

      return null
    }

    yield history.push(`/properties/${propertyId}/amenities`)
  } catch (error) {
    log(`Failed to delete amenity. Error: ${error}`)
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

function* watchFetchAmenities() {
  yield takeLatest(fetchAmenitiesAction().type, fetchAmenities)
}
function* watchFetchAmenity() {
  yield takeLatest(fetchAmenityAction().type, fetchAmenity)
}
function* watchUpdateAmenity() {
  yield takeLatest(UPDATE_AMENITY, updateAmenity)
}
function* watchDeleteAmenity() {
  yield takeLatest(DELETE_AMENITY, deleteAmenity)
}

export default [
  watchFetchAmenities(),
  watchFetchAmenity(),
  watchUpdateAmenity(),
  watchDeleteAmenity()
]
