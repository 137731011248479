import styled from '@emotion/styled'


export const InputContainer = styled('div')`
   display: flex;
   flex-direction: row;
   height: 35px;
   width: 210px;
   border: 1px solid rgba(14, 20, 39, 0.25);
   border-radius: 50px;
   padding: 7.5px;

   & .DayPickerInput input {
       display: block;
       margin-bottom: 5px;
       border: none;
       outline: none;
   }

   svg {
       align-self: flex-end;
   }

   & .DayPickerInput .Container {
       background-color: white;
   }
`
