import styled from '@emotion/styled'

export const ReSyncPms = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  height: 100%;

  & p {
    min-height: 13px;
    min-width: 130px;
    line-height: 16px;
    text-align: right;
    font-size: ${({ theme }) => `calc(${theme.custom.font.size.sm} + 1px)`};
    color: ${({ theme }) => theme.palette.common.black};
    margin-right: 2em;
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }

  & button,
  & button:hover {
    background-color: ${({ theme }) => theme.palette.primary.navy.spaceBlue};
  }

  & button:hover ~ p {
    opacity: 1;
  }
`
