// @flow

import React, { Fragment } from 'react'
import get from 'lodash/get'
import AddIcon from '@material-ui/icons/Add'
import { Link } from 'react-router-dom'
import UsersList from './UsersList'
import { GridItem } from '../../../Common/Grid'
import { withTheme } from 'emotion-theming'
import UserItem from './UserItem'
import type themeType from '../../../../theme'
import type { Users } from './types'
import { FabButton } from './styles'

type Props = {
  users: Users,
  userSettings: Object,
  isSmartEnabled: boolean,
  match: { params: { propertyId: string, unitId: string } },
  theme: themeType,
  sendActivationEmail: () => void,
  toggleResidentZegoSmartEnabled: (string, boolean) => mixed
}

class UsersTab extends React.Component<Props, {}> {
  getUserListItems = () => {
    const {
      users,
      userSettings,
      match: { params },
      theme,
      toggleResidentZegoSmartEnabled,
      sendActivationEmail,
      isSmartEnabled
    } = this.props

    return users.map(user => (
      <UserItem
        key={user.id}
        url={`/properties/${params.propertyId}/units/${params.unitId}/users/${
          user.id
        }`}
        id={user.id}
        propertyId={params.propertyId}
        name={user.name}
        status={user.status}
        account_status={user.account_status}
        residentZegoSmartEnabled={get(
          userSettings,
          [user.id, 'residentZegoSmartEnabled'],
          true
        )}
        toggleResidentZegoSmartEnabled={toggleResidentZegoSmartEnabled}
        isSmartEnabled={isSmartEnabled}
        theme={theme}
        sendActivationEmail={sendActivationEmail}
      />
    ))
  }

  render() {
    const userListItems = this.getUserListItems()

    const {
      match: {
        params: { propertyId, unitId }
      }
    } = this.props

    return (
      <Fragment>
        <GridItem columnStart={5} columnSpan={9} rowStart={2} rowSpan={7}>
          <UsersList
            items={userListItems}
            numberOfItemsPerSlide={10}
            lastSlideNumber={Math.ceil(userListItems.length / 10)}
            transitionHeights={['0em', '2em']}
            currentSlideNo={1}
          />
        </GridItem>
        <Link
          to={{
            pathname: `/properties/${propertyId}/units/${unitId}/user-management/add-user`,
            state: { fromUnit: true }
          }}>
          <FabButton variant="fab" color="secondary">
            <AddIcon />
          </FabButton>
        </Link>
      </Fragment>
    )
  }
}

export default withTheme(UsersTab)
