// @flow

import React, { Fragment } from 'react'
import Flex, { FlexItem } from '@Common/Flex'
import { GridItem } from '../../../Common/Grid'
import ScheduleEnable from './ScheduleEnable'
import { ListItem } from './styles'
import ChangeSetPoint from '../../../Common/Devices/Thermostat/ChangeSetPoints'
import ChangeMode from '../../../Common/Devices/Thermostat/ChangeMode'
import { capitalizeFirstLetter } from '../../../../utils/stringUtils'
import { commands } from 'zego-shared/store/devices/sagas/utils/deviceCommands'
import { ScheduleContent, Title, Value, DefaultView } from './styles'

import type { ScheduleSettingsProps, ScheduleState } from './types'

type View = 'default' | 'mode' | 'setpoint'
type State = {
  view: View,
  editingScheduleState?: ScheduleState
}

const titleSuffixes = {
  mode: 'Thermostat Mode',
  setpoint: 'Temperature Setpoints',
  default: 'Settings'
}

export default class ScheduleSettings extends React.Component<
  ScheduleSettingsProps,
  State
> {
  state = {
    view: 'default',
    editingScheduleState: null
  }

  changeViewMode = (view: View, editingScheduleState: ScheduleState) => {
    this.setState({ view, editingScheduleState })
  }

  getDefaultView = (scheduleState: ScheduleState) => {
    const { name, readOnly } = this.props
    const { view, editingScheduleState } = this.state

    const hideSettings = {
      home: name === 'vacation',
      away: name === 'home-body'
    }

    if (hideSettings[scheduleState]) {
      return null
    }
    const { thermostatMode, heatSetpoint, coolSetpoint } = this.props[
      scheduleState
    ]
    const showHeatSetpoint =
      thermostatMode === commands.AUTO || thermostatMode === commands.HEAT
    const showCoolSetpoint =
      thermostatMode === commands.AUTO || thermostatMode === commands.COOL

    const rowStart = scheduleState === 'home' || hideSettings.home ? 4 : 7

    const isEditingScheduleState =
      view !== 'default' && editingScheduleState === scheduleState
    const titleSuffix = isEditingScheduleState
      ? titleSuffixes[view]
      : 'Settings'
    return (
      <DefaultView
        borderTop
        columnStart={5}
        columnSpan={6}
        rowStart={rowStart}
        rowSpan={3}>
        <Title>
          {capitalizeFirstLetter(scheduleState)} {titleSuffix}
        </Title>
        <ScheduleContent>
          {isEditingScheduleState ? (
            this.getScheduleStateModeView(editingScheduleState)
          ) : (
            <Fragment>
              <Flex>
                <FlexItem flex={1}>
                  <Value
                    readOnly={readOnly}
                    onClick={() => {
                      !readOnly && this.changeViewMode('mode', scheduleState)
                    }}>
                    {thermostatMode}
                  </Value>
                </FlexItem>
                <FlexItem flex={4}> temperature mode</FlexItem>
              </Flex>
              <Flex>
                <FlexItem flex={1}>
                  <Value
                    readOnly={readOnly || !showHeatSetpoint}
                    onClick={() => {
                      !readOnly &&
                        this.changeViewMode('setpoint', scheduleState)
                    }}>
                    {showHeatSetpoint ? (
                      <React.Fragment>
                        {heatSetpoint}
                        &deg;
                      </React.Fragment>
                    ) : (
                      'no'
                    )}
                  </Value>
                </FlexItem>
                <FlexItem flex={4}> heat settings</FlexItem>
              </Flex>
              <Flex>
                <FlexItem flex={1}>
                  <Value
                    readOnly={readOnly || !showCoolSetpoint}
                    onClick={() => {
                      !readOnly &&
                        this.changeViewMode('setpoint', scheduleState)
                    }}>
                    {showCoolSetpoint ? (
                      <React.Fragment>
                        {coolSetpoint}
                        &deg;
                      </React.Fragment>
                    ) : (
                      'no'
                    )}
                  </Value>
                </FlexItem>
                <FlexItem flex={4}> cool settings</FlexItem>
              </Flex>
            </Fragment>
          )}
        </ScheduleContent>
      </DefaultView>
    )
  }

  changeSetPoint = (values: Array<number>) => {
    const { updateSetpoints } = this.props
    const { editingScheduleState } = this.state
    const { heatSetpoint, coolSetpoint, thermostatMode } = this.props[
      editingScheduleState
    ]
    switch (thermostatMode) {
      case commands.AUTO:
        updateSetpoints(
          {
            heatSetpoint: values[0],
            coolSetpoint: values[1]
          },
          editingScheduleState
        )
        break
      case commands.HEAT:
        updateSetpoints(
          {
            heatSetpoint: values[0],
            coolSetpoint
          },
          editingScheduleState
        )
        break
      case commands.COOL:
        updateSetpoints(
          {
            heatSetpoint,
            coolSetpoint: values[0]
          },
          editingScheduleState
        )
        break
      default:
        break
    }
  }

  updateMode = (newMode: string) => {
    const { editingScheduleState } = this.state
    const { updateMode } = this.props
    updateMode(newMode, editingScheduleState)
  }

  getScheduleStateModeView = (scheduleState: ScheduleState) => {
    const { thermostatMode, heatSetpoint, coolSetpoint } = this.props[
      scheduleState
    ]
    const { view } = this.state
    return (
      <Fragment>
        <Flex justifySpaceBetween>
          <FlexItem flex={4}>
            {view === 'mode' ? (
              <ChangeMode
                modeToChangeTo={thermostatMode}
                changeThermostatMode={this.updateMode}
                hideText
              />
            ) : (
              <ChangeSetPoint
                heatSetpointToChangeTo={heatSetpoint}
                coolSetpointToChangeTo={coolSetpoint}
                currentThermostatMode={thermostatMode}
                changeSetPoint={this.changeSetPoint}
                hideThermostatValues
                hideText
              />
            )}
          </FlexItem>
          <FlexItem>
            <Value
              onClick={() => {
                this.changeViewMode('default', null)
              }}>
              Done
            </Value>
          </FlexItem>
        </Flex>
      </Fragment>
    )
  }

  render() {
    const {
      enabled,
      scheduleState,
      switchScheduleActive,
      readOnly,
      hasSchedule
    } = this.props
    if (!hasSchedule) {
      return (
        <GridItem
          columnStart={5}
          columnSpan={6}
          rowStart={2}
          rowSpan={2}
          alignCenter>
          This unit does not have schedule assigned. Please assign a new
          schedule from type section.
        </GridItem>
      )
    }
    return (
      <React.Fragment>
        <GridItem
          columnStart={5}
          columnSpan={6}
          rowStart={2}
          rowSpan={2}
          alignCenter>
          <ListItem>
            <ScheduleEnable
              readOnly={readOnly}
              enabled={enabled}
              scheduleState={scheduleState}
              switchScheduleActive={switchScheduleActive}
            />
          </ListItem>
        </GridItem>
        {this.getDefaultView('home')}
        {this.getDefaultView('away')}
      </React.Fragment>
    )
  }
}
