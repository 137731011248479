import { connect } from 'react-redux'
import SwitchDevice from './SwitchDevice'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'

export const mapDispatchToProps = dispatch => {
  return {
    showError: error => dispatch(showSnackbar(error, 'error'))
  }
}

export default connect(null, mapDispatchToProps)(SwitchDevice)