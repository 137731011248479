// @flow
import styled from '@emotion/styled'
import InputAdornment from '@material-ui/core/InputAdornment'
import MaterialTextField from '@material-ui/core/TextField'

import { convertHexToRGB } from '../../../utils'

export const ResidentSearchDiv = styled('div')`
  position: relative;
  flex: 1.5;
  .search-submit-icon:hover {
    cursor: pointer;
  }
`

export const TextField = styled(MaterialTextField)`
  width: 100%;
  border-radius: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.neutrals.mediumGray};
  padding-right: 13px;
  & input {
    margin-left: 5px;
    padding: 3px 0;
    color: ${({ theme }) => theme.palette.text.grey};
    font-size: ${({ theme }) => theme.custom.font.size.mdl};
  }
`

export const SearchInputAdornment = styled(InputAdornment)`
  margin: 0 0 0 4px;
  padding: 0;
`

export const ResultsDiv = styled('div')`
  position: absolute;
  padding: 13px 17px;
  width: 100%;
  height: auto;
  box-shadow: ${({ theme }) => `0 2px 4px 0 ${convertHexToRGB(theme.palette.grey.main, 0.25)}`};
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.common.main};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  line-height: ${({ theme }) => theme.custom.font.size.sm};
`

const ResultBaseDiv = styled('div')`
  margin: 0;
  padding: 0 13px;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  display: flex;
  -webkit-display: flex;
  align-items: center;
  -webkit-align-items: center;
`

export const LoadingDiv = styled(ResultBaseDiv)`
  font-size: ${({ theme }) => theme.custom.font.size.md};
`

export const NoResultsDiv = styled(ResultBaseDiv)`
  font-size: ${({ theme }) => theme.custom.font.size.md};
`

export const ResultItemDiv = styled(ResultBaseDiv)`
  border-bottom-width: ${({ isLastItem }) => isLastItem || isLastItem === undefined ? '0' : '1px'};
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.palette.neutrals.mediumGray};
  &:hover {
    background-color: ${({ theme }) => theme.palette.neutrals.lightGray};
    cursor: pointer;
  }
`

export const ResidentNameSpan = styled('span')`
  font-weight: bold;
  text-transform: uppercase;
`

export const BulletIconSpan = styled('span')`
  padding: 0 7px;
`
