import styled from '@emotion/styled'
import MaterialMobileStepper from '@material-ui/core/MobileStepper'
import MaterialButton from '@material-ui/core/Button'
import MaterialTextField from '@material-ui/core/TextField'
import ReactSelect from 'react-select'
import AsyncReactSelect from 'react-select/async'
import Grid from '../../Common/Grid'

export const Title = styled('div')`
  padding-left: 25px;
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  font-size: ${({ theme }) => theme.custom.font.size.xl};
`
export const Subtitle = styled('div')`
  color: ${({ theme }) => theme.palette.text.secondary};
  padding: 10px 0 55px 25px;
`

export const AddUserBody = styled('div')`
  padding-left: 25px;
`

export const SuccessMsgGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.primary.green.mint};
  color: ${({ theme }) => theme.palette.common.white};
  font-size: ${({ theme }) => theme.custom.font.size.xxl};
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Failed = styled('div')`
  padding-bottom: 1em;
`
export const Select = styled(ReactSelect)`
  & > div {
    border-radius: 19px;
    &:hover {
      border-color: #ccc;
      box-shadow: 0 0 1px #ccc;
    }
    & > div {
      padding-left: 20px;
    }
    & > options {
      &:isfocused {
        color: red;
      }
    }
  }
`

export const AsyncSelect = styled(AsyncReactSelect)`
  & > div {
    border-radius: 19px;
    & > div {
      padding-left: 20px;
    }
  }
`

export const MobileStepper = styled(MaterialMobileStepper)`
  align-items: flex-start;
  padding: 0 0px;
`

export const StepButton = styled(MaterialButton)`
  color: ${({ theme }) => theme.palette.primary.green.mint};
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  &:hover {
    background: none;
  }
`

export const Button = styled(MaterialButton)`
  background-color: ${({ theme }) => theme.palette.primary.green.mint};
  color: ${({ theme }) => theme.palette.common.white};
  padding: 5px 30px;
  min-width: 90px;
  border-radius: 50px;
  margin-left: 20px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.green.mint};
  }
`
export const TextField = styled(MaterialTextField)`
  border: 1px solid ${({ theme }) => theme.palette.grey.light};
  border-radius: 50px;
  width: 100%;
  padding: 2px 25px;
`
