import React from 'react'
import { getDateFullYear, getDateFullYearAmPm } from 'zego-shared/utils/date.js'
import { Document, Page, Text, View, Image, pdf } from '@react-pdf/renderer'
import styles from './styles'
import { PDFDocument } from 'pdf-lib'

const MimoChecklistSumaryPdf = ({
  checklistSummaryData,
  checklistinfo,
  profileComplete,
  disclosure,
  fileContent,
}) => {
  let pmName = checklistinfo.pmUser
  let issuesCount = 0
  let totalWorkOrderCount = 0
  let residentroom = ''
  const GOOD = 1
  const REPAIR = 2
  const DIRTY = 3
  const NA = 4
  checklistSummaryData &&
    checklistSummaryData.map((brand) =>
      brand.residentRoomCheckListInfo.map((attribute) => {
        if (attribute.workOrderNumber !== null) {
          totalWorkOrderCount = totalWorkOrderCount + 1
        }
        return null
      })
    )

  return (
    <Document>
      <Page
        size="Letter"
        style={styles.page}
        title="Checklist Summary"
        wrap={false}>
        <View style={styles.headerContainer}>
          <View style={styles.leftHeaderSection}>
            <Text style={styles.header}>Move-in Checklist Summary</Text>
          </View>
          <View style={styles.rightHeaderSection}>
            <Text style={styles.defaultText}>
              <Text style={styles.boldText}>Submitted:</Text>{' '}
              {!checklistinfo.checklistSubmittedDate
                ? '--'
                : getDateFullYear(checklistinfo.checklistSubmittedDate)}{' '}
              | <Text style={styles.boldText}>Approved:</Text>{' '}
              {!checklistinfo.approvedDate
                ? '--'
                : getDateFullYear(checklistinfo.approvedDate)}
            </Text>
          </View>
        </View>

        <Text style={styles.underLine}></Text>

        <View style={styles.section}>
          <Text style={styles.defaultText}>
            <Text style={styles.boldText}>Resident:</Text>{' '}
            {profileComplete && profileComplete.name} | Unit{' '}
            {profileComplete && profileComplete.units[0].name}
          </Text>
        </View>

        <View style={styles.disclousureSection}>
          <Text style={styles.defaultText}>
            {!disclosure ? null : disclosure}
          </Text>
        </View>

        <Text style={styles.underLineBreak}></Text>

        <View wrap={true}>
          {!checklistSummaryData
            ? null
            : checklistSummaryData.map((checklistSummaryDatas, i) => (
              <View style={styles.categoryHeader} wrap={false} key={i}>
                <Text style={styles.subHeader}>
                  {issuesCount > 0
                    ? (issuesCount = null)
                    : issuesCount === 0
                      ? (issuesCount = null)
                      : (issuesCount = null)}
                  {!checklistSummaryDatas.residentRoomCheckListInfo
                    ? null
                    : checklistSummaryDatas.residentRoomCheckListInfo.map(
                      (residentRoomCheckList) => {
                        if (
                          residentRoomCheckList.status === REPAIR ||
                          residentRoomCheckList.status === DIRTY
                        ) {
                          issuesCount = issuesCount + 1
                        }
                        return null
                      }
                    )}
                  {checklistSummaryDatas.roomName} :{' '}
                  <Text style={styles.defaultText}>
                    {!issuesCount ? 0 : issuesCount} total issues reported
                  </Text>
                </Text>

                <View style={styles.attributeHeader} wrap={true} break>
                  {!checklistSummaryDatas.residentRoomCheckListInfo
                    ? null
                    : checklistSummaryDatas.residentRoomCheckListInfo.map(
                      (residentRoomCheckListInfos, i) => (
                        <View style={styles.attributeSubHeader} key={i}>
                          <Text style={styles.innerDefaultText}>
                            {residentRoomCheckListInfos.attributeName} :
                            <Text
                              style={[
                                residentRoomCheckListInfos.status === REPAIR
                                  ? styles.innerDefaultTextRed
                                  : residentRoomCheckListInfos.status ===
                                    DIRTY
                                    ? styles.innerDefaultTextRed
                                    : styles.innerDefaultGreyText,
                              ]}>
                              {residentRoomCheckListInfos.status === REPAIR
                                ? ' Repair '
                                : residentRoomCheckListInfos.status ===
                                  DIRTY
                                  ? ' Dirty '
                                  : residentRoomCheckListInfos.status === NA
                                    ? ' NA '
                                    : residentRoomCheckListInfos.status === GOOD
                                      ? ' Good '
                                      : null}
                            </Text>
                            {residentRoomCheckListInfos.status === REPAIR ||
                              residentRoomCheckListInfos.status === DIRTY ? (
                              <Text style={styles.italic}>
                                - {residentRoomCheckListInfos.comments}
                              </Text>
                            ) : null}
                          </Text>
                        </View>
                      )
                    )}
                </View>
              </View>
            ))}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber }) => `Page ${pageNumber} - Summary`}
          fixed
        />
      </Page>

      <Page
        size="Letter"
        style={styles.page}
        title="Checklist Summary"
        wrap={false}>
        <View style={styles.section}>
          <Text style={styles.subHeader}>Acknowledgement :</Text>
        </View>
        <Text style={styles.defaultText}>
          This is the same text the resident sees on the E-Signature page.This
          is the same text the resident sees on the E-Signature page.This is the
          same text the resident sees on the E-Signature page.This is the same
          text the resident sees on the E-Signature page.This is the same text
          the resident sees on the E-Signature page.This is the same text the
          resident sees on the E-Signature page.This is the same text the
          resident sees on the E-Signature page.
        </Text>
        <View style={styles.headerContainer}>
          <View style={styles.leftHeaderSection}>
            <View style={styles.signatureHeader}>
              {!checklistinfo.eSignature ? (
                <View style={styles.signature}></View>
              ) : (
                <Image
                  style={styles.signature}
                  src={checklistinfo.eSignature}
                />
              )}
              <Text style={styles.defaultText}>
                {!checklistinfo.checklistSubmittedDate
                  ? '--'
                  : getDateFullYearAmPm(checklistinfo.checklistSubmittedDate)}
              </Text>
              <Text style={styles.nameText}>Resident Signature</Text>
            </View>
          </View>
          <View style={styles.leftHeaderSection}>
            <View style={styles.signatureHeader}>
              {checklistinfo.pmeSignature ? (
                <Image
                  style={styles.signature}
                  src={checklistinfo.pmeSignature}
                />
              ) : (
                <View style={styles.signature}></View>
              )}
              <Text style={styles.signatureHeaderText}>
                {!pmName ? null : pmName.name}
              </Text>
              <Text style={styles.signatureText}>
                {!checklistinfo.approvedDate
                  ? '--'
                  : getDateFullYearAmPm(checklistinfo.approvedDate)}
              </Text>
              <Text style={styles.nameText}>PM Signature</Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber }) => `Page ${pageNumber} - Signature`}
          fixed
        />
      </Page>

      <Page
        size="Letter"
        style={styles.page}
        title="Checklist Summary"
        wrap={false}>
        <View style={styles.headerContainer}>
          <View style={styles.leftHeaderSection}>
            <Text style={styles.header}>Photos</Text>
          </View>
        </View>
        <Text style={styles.underLine}></Text>
        <View style={styles.section}>
          <Text style={styles.defaultText}>
            Photos uploaded by the resident
          </Text>
        </View>

        <Text style={styles.underLineBreak}></Text>

        {!checklistSummaryData
          ? null
          : checklistSummaryData.map((checklistSummaryDatas, i) => (
            <View wrap={false} key={i}>
              <Text style={styles.subHeader}>
                {residentroom && residentroom.length > 0
                  ? (residentroom = null)
                  : residentroom}
                {!checklistSummaryDatas.residentRoomCheckListInfo
                  ? null
                  : checklistSummaryDatas.residentRoomCheckListInfo.map(
                    (residentRoomCheckListInfos) => {
                      if (
                        residentRoomCheckListInfos.status === REPAIR ||
                        residentRoomCheckListInfos.status === DIRTY
                      ) {
                        residentroom = checklistSummaryDatas.roomName
                      }
                      return null
                    }
                  )}
                {!checklistSummaryDatas.residentRoomCheckListInfo
                  ? null
                  : checklistSummaryDatas.residentRoomCheckListInfo.map(
                    (residentRoomCheckListInfos) => {
                      if (
                        residentRoomCheckListInfos.status === REPAIR ||
                        residentRoomCheckListInfos.status === DIRTY
                      ) {
                        residentroom = checklistSummaryDatas.roomName
                      }
                      return null
                    }
                  )}
                {residentroom}{' '}
                {residentroom && residentroom.length > 0 ? ':' : null}
              </Text>

              <View style={styles.attributeHeader}>
                {!checklistSummaryDatas.residentRoomCheckListInfo
                  ? null
                  : checklistSummaryDatas.residentRoomCheckListInfo.map(
                    (residentRoomCheckListInfos) =>
                      !residentRoomCheckListInfos.images
                        ? null
                        : residentRoomCheckListInfos.images.map(
                          (object, i) => (
                            <View style={styles.imageContainer} key={i}>
                              <Image
                                style={styles.pic}
                                src={
                                  residentRoomCheckListInfos.images[i][
                                  'imageUrl'
                                  ]
                                }
                                key={i}
                                source={{
                                  header: {
                                    'Access-Control-Allow-Origin': '*',
                                  },
                                }}
                              />
                            </View>
                          )
                        )
                  )}
              </View>
            </View>
          ))}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber }) => `Page ${pageNumber} - Photos`}
          fixed
        />
      </Page>

      {totalWorkOrderCount > 0 && (
        <Page
          size="Letter"
          style={styles.page}
          title="Checklist Summary"
          wrap={false}>
          <View>
            <View style={styles.headerContainer}>
              <View style={styles.leftHeaderSection}>
                <Text style={styles.header}>Work Orders</Text>
              </View>
            </View>
            <Text style={styles.underLine}></Text>
            <View style={styles.section}>
              <Text style={styles.defaultText}>New Work Orders created</Text>
            </View>

            <Text style={styles.underLineBreak}></Text>
            {!checklistSummaryData
              ? null
              : checklistSummaryData.map((checklistSummaryDatas, index) => (
                <View key={index}>
                  {residentroom && residentroom.length > 0
                    ? (residentroom = null)
                    : residentroom}
                  {!checklistSummaryDatas.residentRoomCheckListInfo
                    ? null
                    : checklistSummaryDatas.residentRoomCheckListInfo.map(
                      (residentRoomCheckListInfos, i) => {
                        if (residentRoomCheckListInfos.workOrderNumber) {
                          residentroom = checklistSummaryDatas.roomName
                        }
                        return null
                      }
                    )}
                  {!residentroom ? null : (
                    <Text style={styles.subHeader}>
                      {residentroom}{' '}
                      {residentroom && residentroom.length > 0 ? ':' : null}
                    </Text>
                  )}
                  <View>
                    {!checklistSummaryDatas.residentRoomCheckListInfo
                      ? null
                      : checklistSummaryDatas.residentRoomCheckListInfo.map(
                        (residentRoomCheckListInfos, i) =>
                          residentRoomCheckListInfos.workOrderNumber ? (
                            <Text style={styles.WorkOrderNo} key={i}>
                              {residentRoomCheckListInfos.workOrderNumber
                                ? residentRoomCheckListInfos.workOrderNumber
                                : null}
                            </Text>
                          ) : null
                      )}
                  </View>
                </View>
              ))}
          </View>

          <Text
            style={styles.pageNumber}
            render={({ pageNumber }) => `Page ${pageNumber} - Work Orders`}
            fixed
          />
        </Page>
      )}
    </Document>
  )
}

const getPdfBlob = async ({
  checklistSummary,
  checklistinfo,
  profileComplete,
  disclosure,
  fileContent,
}) => {
  const checklistSummaryBlob = await pdf(
    <MimoChecklistSumaryPdf
      checklistSummaryData={checklistSummary}
      checklistinfo={checklistinfo}
      profileComplete={profileComplete}
      disclosure={disclosure}
    />
  ).toBlob({ type: 'application/pdf' })

  if(fileContent){
    const checklistSummaryBuffer = await fetch(
      URL.createObjectURL(checklistSummaryBlob)
    ).then((res) => res.arrayBuffer())

    const checklistSettingsBuffer = await fetch(fileContent).then((res) =>
      res.arrayBuffer()
    )

    const pdfDoc = await PDFDocument.create()

    const checklistSummaryPdfDoc = await PDFDocument.load(checklistSummaryBuffer)
    const checklistSettingPdfDoc = await PDFDocument.load(checklistSettingsBuffer)
    const checklistSummaryPagesIndices = await checklistSummaryPdfDoc.getPageIndices()
    const checklistSettingPagesIndices = await checklistSettingPdfDoc.getPageIndices()
    const checklistSummaryPages = await pdfDoc.copyPages(checklistSummaryPdfDoc, checklistSummaryPagesIndices)
    const checklistSettingPages = await pdfDoc.copyPages(checklistSettingPdfDoc, checklistSettingPagesIndices)
    await [...checklistSummaryPages, ...checklistSettingPages].forEach(async summaryPage => {
      await pdfDoc.addPage(summaryPage)
    })
    const pdfBytes = await pdfDoc.save()
    const checklistSummaryPdf = await new File([pdfBytes], 'Checklist-Summary.pdf', {
      type: 'application/pdf',
    })
    return checklistSummaryPdf
  }
  else{
    const checklistSummaryPdf1 = await new File([checklistSummaryBlob], 'Checklist-Summary.pdf', {
      type: 'application/pdf',
    })
    return checklistSummaryPdf1
  }
}

export { getPdfBlob, MimoChecklistSumaryPdf }
