import { createFetchActions } from '../../../helpers/actions/creators'

export const PINNED_CHANNELS_FETCH_SECTION = 'PINNEDCHANNELS'
export const PIN_CHANNEL = 'PIN_CHANNEL'
export const UNPIN_CHANNEL = 'UNPIN_CHANNEL'

export const {
  fetchAction: fetchActionPinnedChannels,
  fetchStartAction: fetchPinnedChannelsStart,
  fetchSuccessAction: fetchPinnedChannelsSuccess,
  fetchErrorAction: fetchPinnedChannelsError
} = createFetchActions(PINNED_CHANNELS_FETCH_SECTION)

export const fetchPinnedChannels = userId =>
  fetchActionPinnedChannels({ userId })

export const pinChannel = (channelUrl, pinType) => ({
  type: PIN_CHANNEL,
  channelUrl,
  pinType
})

export const unpinChannel = (channelUrl, pinType) => ({
  type: UNPIN_CHANNEL,
  channelUrl,
  pinType
})
