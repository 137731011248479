import {
  createFetchActions,
  createUpdateActions
} from '../../../helpers/actions/creators'

export const USER_SECTION = 'USER'
export const DELETE_USER = 'DELETE_USER'

export const userRoles = {
  PROPERTY_ADMIN: 'property_admin',
  PROPERTY_STAFF: 'property_staff',
  SETUP: 'setup',
  RESIDENT: 'resident'
}

export const {
  fetchAction: fetchUserAction,
  fetchStartAction: fetchUserStart,
  fetchSuccessAction: fetchUserSuccess,
  fetchErrorAction: fetchUserError
} = createFetchActions(USER_SECTION)

export const fetchUser = id => fetchUserAction({ id })

export const deleteUser = (history, propertyId, userId) => ({
  type: DELETE_USER,
  history,
  propertyId,
  userId
})
