import { connect } from 'react-redux'
import ResidentManagementMoveInMoveOut from './ResidentManagementMoveInMoveOut'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {
  fetchResidentManagementMoveInMoveOutAction,
  fetchNextResidentManagementMoveInMoveOutAction,
  fetchResidentFilteredCountAction,
  fetchResidentTotalCountAction
} from 'zego-shared/store/moveInMoveOut/actions'
import {
  getFilteredDataCount,
  getMoveInResidentsCount,
  getResidentManagementMoveinMoveoutData,
  getResidentManagementMoveinMoveoutLoading
} from 'zego-shared/store/moveInMoveOut/selectors'
import { getPropertyId } from 'zego-shared/store/select/selectors'
import { hasPms, getPmsName } from 'zego-shared/store/summary/selectors'
import withFetchData from 'zego-shared/views/withFetchData'

const fetchingSelector = createIsFetchingSelector(['moveInMoveOut'])

export const mapStateToProps = state => ({
  hasPms: hasPms(state),
  pmsName: getPmsName(state),
  isFetching: fetchingSelector(state),
  residents: getResidentManagementMoveinMoveoutData(state),
  propertyId: getPropertyId(state),
  filteredResidentCount: getFilteredDataCount(state),
  residentsCount: getMoveInResidentsCount(state),
  isLoadingResidents: getResidentManagementMoveinMoveoutLoading(state)
})
export const mapDispatchToProps = (
  dispatch,
  { history, updateResidentValue, match: { url, params: { propertyId } } }
) => ({
  fetchData: () => {
    dispatch(fetchResidentManagementMoveInMoveOutAction({ propertyId, page: 1 }))
    dispatch(fetchResidentFilteredCountAction({ id: propertyId, page: 1 }))
    dispatch(fetchResidentTotalCountAction({ id: propertyId }))
  },
  updateResidentCount: (residentCount) => updateResidentValue(residentCount),
  fetchResidentManagementMoveInMoveOutAction: ({ propertyId, page, params }: { propertyId: string, page: number, params: Object }) => {
    dispatch(fetchResidentManagementMoveInMoveOutAction({ id: propertyId, page, params }))
    dispatch(fetchResidentFilteredCountAction({ id: propertyId, page, params }))
  },
  fetchNextResidentManagementMoveInMoveOutAction: ({ propertyId, page, params }: { propertyId: string, page: number, params: Object }) => {
    dispatch(fetchNextResidentManagementMoveInMoveOutAction({ id: propertyId, page, params }))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(ResidentManagementMoveInMoveOut))
