//@flow

import React from 'react'
import { Link } from 'react-router-dom'
import type { Node } from 'react'
import type { Match, RouterHistory } from 'react-router-dom'
import Layout from './GroupMessagingLayout'
import PaginatedTable from '../../Common/PaginatedTable'
import Tooltip from '../../Common/Tooltip'
import BackButton from './BackButton'
import Loader from '../../Common/Loader'
import {
  TemplatesRowStyled,
  TemplatesColumnStyled,
  TableContainer
} from './styles'

type Props = {
  data: Array<{ id: string, value: Node }>,
  hasData: boolean,
  isFetching: boolean,
  match: Match,
  searchTemplates: string => mixed,
  history: RouterHistory,
  userRole: string,
  propertyId: string,
  displayErrorMessage: (message: string) => void
}

const createRows = (data, { propertyId }) => {
  return data.map(({ id, ...rowData }) =>
    Object.values(rowData).map((value, index) => ({
      value: (
        <Link
          to={`/properties/${
            propertyId == null ? '' : propertyId
          }/communication/group-messaging/templates/${id}`}>
          <TemplatesRowStyled index={index}>{value}</TemplatesRowStyled>
        </Link>
      )
    }))
  )
}

const columns = [
  { value: <div index={0}>name</div> },
  { value: <div index={1}>title</div> },
  {
    value: (
      <TemplatesColumnStyled index={2}>
        type
        <Tooltip type="GroupMessagingTemplateColumn" />
      </TemplatesColumnStyled>
    )
  }
]

const getButtons = (history, propertyId) => (
  <React.Fragment>
    <BackButton
      onClick={() =>
        propertyId
          ? history.push(
              `/properties/${propertyId}/communication/group-messaging`
            )
          : history.goBack()
      }
    />
  </React.Fragment>
)

const goToCreate = (history, propertyId) => {
  if (propertyId)
    history.push(
      `/properties/${propertyId}/communication/group-messaging/templates/create`
    )
}

const Templates = ({
  data,
  hasData,
  isFetching,
  match: { params },
  history,
  searchTemplates,
  userRole,
  propertyId,
  displayErrorMessage
}: Props) => {

  if (userRole === "property_staff") {
    history.replace(`/properties/${propertyId}/communication/group-messaging`)
    setTimeout(() => {
      displayErrorMessage("Group Messaging Templates are not available for Property Staff.")
    }, 1000)

    return null
  }

  return (
    <Layout
      header="COMMUNICATION"
      title="TEMPLATES"
      onCreateClick={goToCreate.bind(this, history, params.propertyId)}
      onSearch={searchTemplates}
      buttons={getButtons(history, params.propertyId)}
      table={
        isFetching || !hasData ? (
          <React.Fragment>
            <Loader />
          </React.Fragment>
        ) : data.length > 0 ? (
          <TableContainer>
            <PaginatedTable
              total={data.length}
              columns={columns}
              rows={createRows(data, params)}
              rowsPerPage={data.length}
            />
            <br />
            <br />
          </TableContainer>
        ) : (
          <div> No Templates </div>
        )
      }
    />
  )
}

export default Templates
