import styled from '@emotion/styled'
import MaterialInput from '@material-ui/core/Input'

export const Input = styled(MaterialInput, {
  shouldForwardProp: prop => ['dark'].indexOf(prop) === -1
})`
  border: 1px solid rgba(14, 20, 39, 0.25);
  border-radius: 50px;
  margin: 7px 0;
  ${({ dark, theme }) =>
    dark && `border: 1px solid ${theme.palette.neutrals.warmGray};`};

  & input {
    padding: 9px 35px 7px 35px;
    border-radius: 50px;
  }
`
