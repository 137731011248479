// @flow
import React from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'emotion-theming'
import { withRouter } from 'react-router-dom'
import BaseCard from '@Common/Cards/BaseCard'
import CircleWithText from '@Common/Cards/CircleWithText'
import Flex from '@Common/Flex'
import CustomCircularProgress from '@Common/CustomCircularProgress'
import { CenteredText, VacantWrapper, InfoText, WidgetText, WidgetContainer, StyledVerticalLine, StyledBorderButton } from './styles'
import {
  getIssueCount,
  getNoIssueCount,
  getAwaitingApprovalCount,
  getTotalApprovalStatusCount
} from 'zego-shared/store/moveInMoveOut/selectors'

export const mapStateToProps = (state: Object) => {
  return ({
    issueCount: getIssueCount(state),
    noIssueCount: getNoIssueCount(state),
    awaitingApprovalCount: getAwaitingApprovalCount(state),
    totalApprovalStatusCount: getTotalApprovalStatusCount(state),
  })
}

const ResidentMoveinMoveoutCard = (props) => {
  const {
    theme,
    issueCount,
    noIssueCount,
    awaitingApprovalCount,
    totalApprovalStatusCount,
    setShowSettings
  } = props

  const progressTextValue = ((awaitingApprovalCount / totalApprovalStatusCount) * 100).toString();

  const getContent = () => {
    return (
      <WidgetContainer>
        <Flex fullWidth fullHeight>
          <Flex fullWidth fullHeight>
            <Flex flex={0.6} justifyRight alignCenter>
              <div>
                <CustomCircularProgress
                  variant="static"
                  progressCaption="Checklists"
                  progressText={awaitingApprovalCount}
                  xlgFont
                  value={progressTextValue}
                  size={100}
                  thickness={4}
                  underlayColor={({ theme }) => theme.palette.neutrals.coolGray}
                  backgroundColor={theme.palette.secondary.main1}
                />
                <WidgetText>
                  <InfoText>Awaiting</InfoText>
                  <InfoText>Approval</InfoText>
                </WidgetText>
              </div>
            </Flex>
            <Flex
              alignCenter
              fullWidth
              fullHeight
              flex={0.2}
              justifyRight
            >
              <StyledVerticalLine />
            </Flex>
            <Flex
              alignCenter
              fullWidth
              fullHeight
              flex={1.3}
              justifyCenter
            >
              <div>
                <CircleWithText
                  data="Checklists"
                  dataCount={issueCount}
                  backgroundColor={({ theme }) => theme.palette.primary.dark}
                  textColor={({ theme }) => theme.palette.background.default}
                  size={78}
                />

                <WidgetText>
                  <CenteredText>Reported</CenteredText>
                  <CenteredText>Issues</CenteredText>
                </WidgetText>
              </div>
              <VacantWrapper>
                <CircleWithText
                  data="Checklists"
                  dataCount={noIssueCount}
                  backgroundColor={({ theme }) => theme.palette.primary.dark}
                  textColor={({ theme }) => theme.palette.background.default}
                  size={78}
                />
                <WidgetText>
                  <CenteredText>Reported</CenteredText>
                  <CenteredText>No Issues</CenteredText>
                </WidgetText>
              </VacantWrapper>
            </Flex>
          </Flex>
        </Flex>
      </WidgetContainer>
    )
  }

  const getCheckListSettings = () => {
    return (
      <Flex>
        <StyledBorderButton
          onClick={() => setShowSettings(true)}
        >
          Checklist Settings
        </StyledBorderButton>
      </Flex>
    )
  }

  return (
    <React.Fragment>
      <BaseCard
        title="Move-in Checklist Details"
        columnStart={1}
        columnSpan={2}
        rowStart={1}
        rowSpan={3}
        content={getContent}
        textColor={({ theme }) => theme.palette.background.default}
        headerRight={getCheckListSettings}
      />
    </React.Fragment>
  )
}

export default connect(mapStateToProps)(withRouter(withTheme(ResidentMoveinMoveoutCard)))
