import styled from '@emotion/styled'
import MaterialTextField from '@material-ui/core/TextField'

export const StyledMaterialTextField = styled(MaterialTextField, {
  shouldForwardProp: prop => !['isEditor'].includes(prop)
})`
  padding: ${({ padding }) => padding ?? '10px 0 10px 10px'};

  ${({ maxwidth }) => maxwidth && `max-width: ${maxwidth};`}

  ${({ label, addlabelpadding }) =>
    label && addlabelpadding &&
    `label {
      padding: 0 0 0 10px;
    }`}

  label + div {
    margin-top: 6px;
  }

  ${({ hideCaret }) =>
    hideCaret &&
    `caret-color: transparent;`
  }

  ${({ multiline, multilinenoresize }) =>
    multiline && multilinenoresize &&
    `
      textarea {
        width: 100%;
        resize: none;
        overflow-y: hidden; 
      }
    `
  }

  ${({ multiline, isEditor }) =>
    multiline &&
    isEditor &&
    `
    textarea {
      flex-grow: 0.95;
      padding-right: 0.5em;
      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: ${({ theme }) => theme.palette.neutrals.deepGray};
      }
    }
  `}
`
export const ToolTipWrapper = styled('div')`
  position: relative;
  margin-top: -0.5em;
`
