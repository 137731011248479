// @flow

import React from 'react'
import { Heading } from './styles'
import { Underline } from '@Common/Cards/BaseCard/styles'

import type { Node } from 'react'

type Props = {
  subheader?: Node,
  header?: Node,
  noUnderline?: boolean
}

const Header = ({ subheader, header, noUnderline, ...props }: Props) => (
  <Heading {...props}>
    <div>{subheader}</div>
    <div>{header}</div>
    {noUnderline ? null : <Underline length="75px" height="25px" />}
  </Heading>
)

export default Header
