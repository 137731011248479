import { call, select, put, takeLatest, race, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { getToken, getSendbirdUserId } from '../../../authorization/selectors'
import { getFirstChannel, getChannelsByType } from '../../channels/selectors'
import { getActiveChannel } from '../../activeChannel/selectors'
import sendBird from '../../../../utils/sendbird'
import log from '../../../../utils/logger'
import api from '../../../../utils/api'
import {
  fetchChatChannelsStart,
  fetchChatChannelsSuccess,
  channelChanged
} from '../../channels/actions'
import {
  fetchChatChannelMessages as fetchChatChannelMessagesAction,
  fetchChatChannelMessagesStart,
  fetchChatChannelMessagesSuccess,
  fetchChatChannelMessagesError,
  fetchChatChannelMessagesByUrl as fetchChatChannelMessagesByUrlAction,
  sendChatMessage as sendChatMessageAction,
  sendChatMessageError,
  initSendbirdChannelHandler as initSendbirdChannelHandlerAction,
  closeSendbirdChannelHandler,
  onMessageReceived as onMessageReceivedAction,
  onChannelChanged as onChannelChangedAction,
  addMessage,
  fetchUnreadMessagesCountAction,
  fetchUnreadMessagesCountStart,
  fetchUnreadMessagesCountError,
  fetchUnreadMessagesCountSuccess
} from '../actions'

const MESSAGES_CHANNEL_ID = 'MESSAGES'

export function* fetchChatChannelMessages() {
  try {
    const activeChannel = yield select(getActiveChannel)

    yield put(fetchChatChannelMessagesStart())
    const { resource: messages } = yield call(
      sendBird.getChannelMessages,
      activeChannel
    )

    yield put(fetchChatChannelMessagesSuccess(messages.reverse()))
  } catch (error) {
    yield put(fetchChatChannelMessagesError(error))
    log(`Failed to fetch chat channel messages. Error: ${error}`)
  }
}

export function* fetchChatChannelMessagesByUrl({ channelUrl }) {
  try {
    const channels = yield select(getChannelsByType)

    const activeChannel = channels.find(channel => channel.url === channelUrl)

    yield put(fetchChatChannelMessagesStart())
    const { resource: messages } = yield call(
      sendBird.getChannelMessages,
      activeChannel
    )
    yield put(fetchChatChannelMessagesSuccess(messages.reverse()))
  } catch (error) {
    yield put(fetchChatChannelMessagesError(error))
    log(`Failed to fetch chat channel messages. Error: ${error}`)
  }
}

export function* sendChatMessage({ message, types, file }) {
  try {
    const authToken = yield select(getToken)
    const sendbirdUserID = yield select(getSendbirdUserId)
    const { url } = yield select(getActiveChannel)

    yield call(
      api.sendChatMessage,
      authToken,
      url,
      message,
      types,
      file,
      sendbirdUserID
    )
  } catch (error) {
    yield put(sendChatMessageError(error))
    log(`Failed to send chat message. Error: ${error}`)
  }
}

export function* onMessageReceived({ channel, message }) {
  const activeChannel = yield select(getActiveChannel)
  if (activeChannel && activeChannel.url === channel.url) {
    yield put(addMessage(message))
  }
}

export function* onChannelChanged({ channel }) {
  const activeChannel = yield select(getActiveChannel)
  if (activeChannel.url !== channel.url) {
    yield put(channelChanged(channel))
  }
}

export function sendBirdChannelHandler() {
  return eventChannel(emitter => {
    sendBird.initChannelHandler(MESSAGES_CHANNEL_ID, {
      onMessageReceived: (channel, message) =>
        emitter(onMessageReceivedAction(channel, message)),
      onChannelChanged: channel => emitter(onChannelChangedAction(channel))
    })

    // terminate function
    return () => {
      sendBird.removeChannelHandler(MESSAGES_CHANNEL_ID)
    }
  })
}

export function* fetchUnreadMessagesCount() {
  try {
    yield put(fetchUnreadMessagesCountStart())
    const response = yield call(sendBird.getUnreadMessageCount)
    yield put(fetchUnreadMessagesCountSuccess(response))
  } catch (error) {
    yield put(fetchUnreadMessagesCountError(error))
  }
}

export function* initSendbirdChannelHandler() {
  const channel = yield call(sendBirdChannelHandler)
  while (true) {
    const { closeAction, sendbirdMessageAction } = yield race({
      closeAction: take(closeSendbirdChannelHandler().type),
      sendbirdMessageAction: take(channel)
    })

    if (closeAction) {
      yield call(channel.close)
    } else {
      yield put(sendbirdMessageAction)
    }
  }
}

function* watchFetchChatChannelMessages() {
  yield takeLatest(
    fetchChatChannelMessagesAction().type,
    fetchChatChannelMessages
  )
}

function* watchFetchChatChannelMessagesByUrl() {
  yield takeLatest(
    fetchChatChannelMessagesByUrlAction().type,
    fetchChatChannelMessagesByUrl
  )
}

function* watchSendChatMessage() {
  yield takeLatest(sendChatMessageAction().type, sendChatMessage)
}

function* watchInitSendbirdChannelHandler() {
  yield takeLatest(
    initSendbirdChannelHandlerAction().type,
    initSendbirdChannelHandler
  )
}

function* watchOnMessageReceived() {
  yield takeLatest(onMessageReceivedAction().type, onMessageReceived)
}

function* watchOnChannelChanged() {
  yield takeLatest(onChannelChangedAction().type, onChannelChanged)
}

function* watchFetchUnreadMessagesCount() {
  yield takeLatest(
    fetchUnreadMessagesCountAction().type,
    fetchUnreadMessagesCount
  )
}

export default [
  watchFetchChatChannelMessages(),
  watchFetchChatChannelMessagesByUrl(),
  watchSendChatMessage(),
  watchInitSendbirdChannelHandler(),
  watchOnMessageReceived(),
  watchOnChannelChanged(),
  watchFetchUnreadMessagesCount()
]
