// @flow
import { connect } from 'react-redux'
import queryString from 'query-string'
import {
  fetchHistory,
  searchHistory
} from 'zego-shared/store/groupMessaging/history'
import {
  fetchFuture,
  searchFuture
} from 'zego-shared/store/groupMessaging/future'
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {
  hasHistory,
  getHistory,
  getHistoryPaginationInfo
} from 'zego-shared/store/groupMessaging/history/selectors'
import {
  hasFuture,
  getFuturePaginationInfo,
  getFuture,
  getFutureSearchResults
} from 'zego-shared/store/groupMessaging/future/selectors'
import GroupMessaging from './GroupMessaging'
import { fetchGroups } from 'zego-shared/store/groupMessaging/groups'
import { getUserRole } from 'zego-shared/store/authorization/selectors'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'

const fetchingSelectorHistory = createIsFetchingSelector(['history'])
const fetchingSelectorFuture = createIsFetchingSelector(['futuregroupmessages'])

const mapStateToProps = (state, { match: { params: { propertyId } }, location: { search } }) => {
  const isFutureTab = queryString.parse(search).status === 'future'
  const futureData = getFuture(state)

  const paginationInfo = !isFutureTab
    ? getHistoryPaginationInfo(state)
    : getFuturePaginationInfo(state)

  return {
    isFutureTab: isFutureTab,
    data: !isFutureTab ? getHistory(state) : futureData,
    searchResultsFuture: getFutureSearchResults(state),
    isFetching: !isFutureTab
      ? fetchingSelectorHistory(state)
      : fetchingSelectorFuture(state),
    hasData: !isFutureTab ? hasHistory(state) : hasFuture(state),
    ...paginationInfo,
    total:
      isFutureTab && futureData && !futureData.length ? 0 : paginationInfo.total,
    userRole: getUserRole(state),
    propertyId
  }
}

const mapDispatchToProps = (
  dispatch,
  { match: { params }, location: { search } }
) => {
  const isFutureTab = queryString.parse(search).status === 'future'
  return {
    fetchData: () => {
      dispatch(fetchGroups(params.propertyId))
      !isFutureTab
        ? dispatch(fetchHistory(params.propertyId))
        : dispatch(fetchFuture(params.propertyId))
    },
    fetchPage: page => {
      !isFutureTab
        ? dispatch(fetchHistory(params.propertyId, page))
        : dispatch(fetchFuture(params.propertyId, page))
    },
    searchHistory: searchTerm =>
      dispatch(searchHistory(params.propertyId, 1, searchTerm)),
    searchFuture: searchTerm =>
      searchTerm
        ? dispatch(searchFuture(params.propertyId, 1, searchTerm))
        : dispatch(fetchFuture(params.propertyId)),
    componentDidUpdate: (previousProps, props) => {
      if (previousProps.location.search !== props.location.search) {
        !isFutureTab
          ? dispatch(fetchHistory(params.propertyId))
          : dispatch(fetchFuture(params.propertyId))
      }
    },
    displayErrorMessage: (message) => dispatch(showSnackbar(message, 'error')),
  }
}

const GroupMessagingWithFetchData = withFetchData(GroupMessaging)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupMessagingWithFetchData)
