// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import { CommonIcon } from '@icons'
import { FabButtonWrap, FabButton } from './styles'

const Plus = ({ theme, onClick }) => (
  <FabButtonWrap>
    <FabButton onClick={onClick} variant="fab" color="secondary">
      <CommonIcon
        name="plus"
        width="6px"
        height="6px"
        fill={theme.palette.common.white}
        strokeWidth={3}
        stroke={theme.palette.common.white}
      />
    </FabButton>
  </FabButtonWrap>
)

export default withTheme(Plus)
