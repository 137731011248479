// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import moment, { Moment } from 'moment'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import DropDown from '@Common/DropDown'
import Flex from '@Common/Flex'
import FlexDirection, {
  FlexItem
} from '@Common/Flex/FlexDirection'
import { CommonIcon } from '@icons'
import {
  CustomTextField,
  DatePickerWrapper
} from '../../CreateGroupMessage/styles'
import { DateTimeProps, DateTimeState } from '../../types'
import Calendar from '@Common/Calendar'
import {
  getFullDate,
  get24HrTimeAmPm,
  capitalizeFirstLetter,
  onceAMonthDateFormat,
  onceAYearDateFormat,
  getFullDateTime
} from '../../../../../utils'

const recurringOptions = [
  { id: 0, name: 'once a year' },
  { id: 1, name: 'once a month' },
  { id: 2, name: 'non-recurring' }
]

class DateTime extends React.Component<DateTimeProps, DateTimeState> {
  state = {
    active: false,
    moment: this.props.dateTimeMoment
      ? this.props.dateTimeMoment.moment
      : moment(),
    showDatePicker: false,
    time: '',
    recurringOption: this.props.dateTimeMoment
      ? recurringOptions.find(
          option =>
            option.name === this.props.dateTimeMoment.recurringOptionName
        )
      : recurringOptions[0]
  }

  wrapperRef: Element

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  focusInput = (field: string) => {
    this.setState({ active: true })
  }

  unFocusInput = () => {
    this.setState({ active: false })
  }

  toggleFocus = () => {
    this.setState(state => ({
      active: !state.active
    }))
  }

  handleChange = (
    field: string,
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    this.setState({ [field]: event.target.value }, () =>
      this.setSelectedDateTime()
    )
  }

  handleClickOutside = (event: MouseEvent) => {
    if (
      // $FlowDisableNextLine
      this.wrapperRef &&
      // $FlowDisableNextLine
      !ReactDOM.findDOMNode(this.wrapperRef).contains(
        ((event.target: any): Node)
      )
    )
      this.setState({ showDatePicker: false })
  }

  handleCalendarChange = (moment: Moment) => {
    this.setState({ moment }, () => this.setSelectedDateTime())
  }

  handleRecurringOptionChange = (id: string) => {
    this.setState(
      {
        recurringOption: recurringOptions.find(option => option.id === id)
      },
      () => this.setSelectedDateTime()
    )
  }

  setSelectedDateTime = () => {
    const {
      moment,
      time,
      // $FlowDisableNextLine
      recurringOption: { name: recurringOptionName }
    } = this.state

    if (time) {
      const splitTime = time.split(':')
      moment.set('hour', splitTime[0])
      moment.set('minute', splitTime[1])
    }
    const text = `${capitalizeFirstLetter(recurringOptionName)} on ${
      recurringOptionName === 'once a year'
        ? onceAYearDateFormat(moment)
        : recurringOptionName === 'once a month'
        ? `the ${onceAMonthDateFormat(moment)}`
        : `${getFullDateTime(moment)}`
    }`
    this.props.handleSelectedDateTimeChange(text, moment, recurringOptionName)
  }

  toggleDatePicker = () => {
    this.setState({ showDatePicker: !this.state.showDatePicker })
  }

  setWrapperRef = (node: Element | null) => {
    // $FlowDisableNextLine
    this.wrapperRef = node
  }

  render() {
    const { isCalendarFixed, theme } = this.props
    const { active, moment, showDatePicker, recurringOption } = this.state

    return (
      <React.Fragment>
        <br />
        <FormControl fullWidth>
          <Flex>
            <FlexItem flex={2} displayFlex>
              <div
                ref={this.setWrapperRef}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%'
                }}>
                <CustomTextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={getFullDate(moment.format())}
                  active={active === 'date'}
                  label="Date"
                  onFocus={this.focusInput.bind(this, 'date')}
                  onBlur={this.unFocusInput}
                  onChange={this.handleChange.bind(this, 'date')}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <FlexDirection fullHeight direction="column">
                        <FlexItem flex={0.9} displayFlex alignItemsRight>
                          <InputAdornment position="start">
                            <CommonIcon
                              name="calendar"
                              height="14px"
                              width="12px"
                              onClick={this.toggleDatePicker}
                              fill={
                                active === 'date'
                                  ? theme.palette.secondary.main1
                                  : theme.palette.grey.main
                              }
                            />
                          </InputAdornment>
                        </FlexItem>
                      </FlexDirection>
                    ),
                    endAdornment: (
                      <FlexDirection fullHeight direction="column">
                        <FlexItem flex={0.9} displayFlex alignItemsRight>
                          <InputAdornment position="end">
                            <CommonIcon
                              name="edit"
                              height="14px"
                              width="12px"
                              onClick={this.toggleFocus}
                              fill={
                                active === 'date'
                                  ? theme.palette.secondary.main1
                                  : theme.palette.grey.main
                              }
                            />
                          </InputAdornment>
                        </FlexItem>
                      </FlexDirection>
                    )
                  }}
                />
                <DatePickerWrapper
                  isFixed={isCalendarFixed}
                  visible={showDatePicker}>
                  <Calendar
                    moment={moment}
                    handleChange={this.handleCalendarChange}
                  />
                </DatePickerWrapper>
              </div>
            </FlexItem>
            <FlexItem flex={1} displayFlex>
              <CustomTextField
                defaultValue={get24HrTimeAmPm(moment)}
                type="time"
                InputLabelProps={{
                  shrink: true
                }}
                active={active === 'time'}
                label="Time"
                onFocus={this.focusInput.bind(this, 'time')}
                onBlur={this.unFocusInput}
                onChange={this.handleChange.bind(this, 'time')}
                inputProps={{
                  step: 300, // 5 min
                  endAdornment: (
                    <FlexDirection fullHeight direction="column">
                      <FlexItem flex={0.9} displayFlex alignItemsRight>
                        <InputAdornment position="end">
                          <CommonIcon
                            name="edit"
                            height="14px"
                            width="12px"
                            onClick={this.toggleFocus}
                            fill={
                              active === 'time'
                                ? theme.palette.secondary.main1
                                : theme.palette.grey.main
                            }
                          />
                        </InputAdornment>
                      </FlexItem>
                    </FlexDirection>
                  )
                }}
              />
            </FlexItem>
            <FlexItem displayFlex style={{ marginTop: '-0.5em' }}>
              <DropDown
                label="Recurring"
                classes={{ root: 'template-dropdown' }}
                data={recurringOptions}
                IconComponent={() => (
                  <CommonIcon
                    name="arrow_down"
                    width="1em"
                    height="1em"
                    fill={theme.palette.grey.main}
                  />
                )}
                handleChange={this.handleRecurringOptionChange}
                selectedItem={recurringOption}
              />
            </FlexItem>
          </Flex>
        </FormControl>
      </React.Fragment>
    )
  }
}

export default DateTime
