// @flow

import React from 'react'
import queryString from 'query-string'
import type { RouterHistory } from 'react-router-dom'
import type { BrowserLocation } from 'history'
import {
  DefaultBackButton,
  DefaultNextButton
} from '@Common/Button'
import Flex, { FlexItem } from '@Common/Flex'
import Grid, { GridItem } from '../Common/Grid'
import InitialScreen from './InitialScreen'
import InputStep from './InputStep'
import Welcome from './Welcome'
import { MobileStepper, StepButton, ErrorMessage } from './styles'
import { Card } from './styles'
import Snackbar from '../Snackbar/SnackbarContainer'
import Loader from '../Common/Loader'
import { Redirect } from 'react-router-dom'

import { isResident } from '../../utils/roleUtils'
import { isFunction } from '../../utils/commonUtils'
import {
  validateNonEmpty,
  validatePhoneNumber,
  validateEmail,
  validatePassword,
  validateEqual
} from '../../utils/validationUtils'

import type { SignupStepType } from './types'

export const signupSteps: Array<SignupStepType> = [
  {
    component: InitialScreen,
    disableBackButton: true,
    disableNextButton: true
  },
  {
    component: InputStep,
    props: {
      placeholder: 'First Name'
    },
    disableNextButton: true,
    id: 'firstName',
    validator: validateNonEmpty
  },
  {
    component: InputStep,
    props: {
      placeholder: 'Last Name'
    },
    disableNextButton: true,
    id: 'lastName',
    validator: validateNonEmpty
  },
  {
    component: InputStep,
    props: {
      placeholder: 'Email',
      readOnly: true
    },
    disableNextButton: true,
    id: 'email',
    validator: validateEmail
  },
  {
    component: InputStep,
    props: {
      placeholder: 'Phone Number'
    },
    disableNextButton: true,
    id: 'phoneNumber',
    validator: validatePhoneNumber
  },
  {
    component: InputStep,
    props: {
      placeholder: 'Password',
      type: 'password'
    },
    disableNextButton: true,
    id: 'password',
    validator: validatePassword
  },
  {
    component: InputStep,
    props: {
      placeholder: 'Confirm Password',
      type: 'password'
    },
    disableNextButton: true,
    id: 'passwordToConfirm',
    validator: validateEqual,
    validateWith: 'password',
    validationMessage: 'Password confirmation does not match with password',
    performSignup: true
  },
  {
    component: Welcome,
    withoutGrid: true,
    displayTimer: 2000,
    onDisplayTimerEnd: () => {}
  }
]

const stepsCount = signupSteps.length

type Props = {
  location: BrowserLocation,
  validateSignUpLink: ({ token: string }) => mixed,
  signUp: (string, {}, ?string) => mixed,
  isValidSignupToken: string,
  signupSuccess: boolean,
  userRole: string,
  fetchResourcesAndRedirectAfterLogin: Object => mixed,
  history: RouterHistory
}

type State = {
  currentStep: number,
  email: string,
  password: string,
  phoneNumber: string,
  firstName: string,
  lastName: string,
  passwordToConfirm: string,
  token: string,
  enableNextButton: boolean,
  errorMessage: ?string,
  socialObj: ?Object,
  provider: ?string
}

export default class Signup extends React.Component<Props, State> {
  state = {
    currentStep: 0,
    email: '',
    password: '',
    phoneNumber: '(    )    -    ',
    firstName: '',
    lastName: '',
    passwordToConfirm: '',
    token: '',
    enableNextButton: false,
    errorMessage: null,
    socialObj: null,
    provider: null
  }

  componentDidMount() {
    const { location, validateSignUpLink } = this.props
    const {
      first_name: firstName,
      last_name: lastName,
      email,
      token
    } = queryString.parse(location.search)
    this.setState(
      oldState => ({
        ...oldState,
        firstName,
        lastName,
        email,
        token
      }),
      () => {
        validateSignUpLink({ token })
      }
    )
  }

  handleSocialSignup = (provider: string, socialObj: Object) => {
    const {
      profileObj: { email, givenName: firstName, familyName: lastName }
    } = socialObj

    this.setState(
      oldState => ({
        ...oldState,
        email: email || '',
        firstName: firstName || '',
        lastName: lastName || '',
        socialObj,
        provider: 'google'
      }),
      () => {
        const successRender = signupSteps.findIndex(
          ({ component }) => component === Welcome
        )
        this.handleNextStep(true, successRender)
      }
    )
  }

  handleBackStep = () => {
    const { currentStep } = this.state
    const { backButtonClickRender } = signupSteps[currentStep]
    const backButtonClickRenderIndex = signupSteps.findIndex(
      ({ component }) => component === backButtonClickRender
    )
    const backStep =
      backButtonClickRenderIndex === -1
        ? currentStep - 1
        : backButtonClickRenderIndex
    this.setState({ currentStep: backStep })
  }

  handleNextStep = (socialSignup: ?boolean, stepToRender: ?number) => {
    const { currentStep, token, socialObj, provider } = this.state
    const { performSignup } = signupSteps[currentStep]
    if (socialSignup === true || performSignup === true) {
      const { signUp } = this.props
      signUp(token, socialObj || this.state, provider)
    }
    this.setState(
      { currentStep: stepToRender || currentStep + 1, enableNextButton: false },
      () => {
        const { displayTimer, onDisplayTimerEnd } = signupSteps[currentStep + 1]
        if (displayTimer) {
          setTimeout(
            isFunction(onDisplayTimerEnd)
              ? onDisplayTimerEnd
              : this.handleNextStep,
            displayTimer
          )
        }
      }
    )
  }

  handleDataChange = (goToNextStep: boolean = true) => {
    const { currentStep } = this.state

    const {
      validator,
      id,
      validationMessage,
      validateWith,
      props: { placeholder }
    } = signupSteps[currentStep]

    const value = this.state[id]

    const validationResult = validator(
      this.state[id],
      validateWith && this.state[validateWith]
    )

    let errorMessage
    if (validationResult instanceof Object && !validationResult.isValid) {
      errorMessage = validationResult.message
    } else if (!validationResult) {
      errorMessage = validationMessage
        ? validationMessage
        : `Please Enter Valid Value For ${placeholder}`
    }

    this.setState(
      {
        errorMessage,
        enableNextButton: value != null && value !== '' && errorMessage == null
      },
      () => {
        errorMessage == null && goToNextStep && this.handleNextStep()
      }
    )
  }

  handleChange = ({
    currentTarget: { id, value }
  }: SyntheticEvent<HTMLInputElement>) => {
    this.setState(
      oldState => {
        return {
          ...oldState,
          [id]: value
        }
      },
      () => {
        this.handleDataChange(false)
      }
    )
  }

  render() {
    const {
      signupSuccess,
      isValidSignupToken,
      userRole,
      history,
      fetchResourcesAndRedirectAfterLogin
    } = this.props
    const { currentStep, enableNextButton, errorMessage, ...state } = this.state

    const {
      component: StepComponent,
      props,
      withoutGrid,
      disableBackButton,
      disableNextButton,
      backButton,
      nextButton,
      id
    } = signupSteps[currentStep]

    if (signupSuccess && userRole != null && !isResident(userRole)) {
      fetchResourcesAndRedirectAfterLogin(history)
    }

    if (isValidSignupToken === "NO") {
      return <Redirect to="/login" />
    }

    if (!isValidSignupToken && !signupSuccess && userRole == null) {
      return <Loader />
    }

    if (withoutGrid) {
      return <StepComponent {...props} {...state} userRole={userRole} />
    }

    const shouldEnableNextButton = () => {
      if (id === 'email') {
        return !(enableNextButton
          ? enableNextButton
          : errorMessage === undefined)
      } else {
        return !(enableNextButton ? enableNextButton : !disableNextButton)
      }
    }

    return (
      <React.Fragment>
        <Grid>
          <GridItem columnStart={1} columnSpan={12} rowStart={1} rowSpan={2}>
            <MobileStepper
              activeStep={currentStep}
              variant="progress"
              steps={stepsCount}
              position="static"
            />
            <Flex justifySpaceBetween>
              <StepButton
                onClick={this.handleBackStep}
                disabled={disableBackButton}>
                {backButton == null ? (
                  <DefaultBackButton disabled={disableBackButton} />
                ) : (
                  backButton
                )}
              </StepButton>

              <StepButton
                onClick={this.handleNextStep}
                disabled={shouldEnableNextButton()}>
                {nextButton == null ? (
                  <DefaultNextButton disabled={shouldEnableNextButton()} />
                ) : (
                  nextButton
                )}
              </StepButton>
            </Flex>
          </GridItem>
          <GridItem columnStart={1} columnSpan={12} rowStart={3} rowSpan={10}>
            <Flex fullWidth fullHeight alignCenter justifyCenter>
              <Card>
                <Flex
                  fullHeight
                  direction="column"
                  alignCenter
                  justifySpaceBetween>
                  <StepComponent
                    handleDataChange={this.handleDataChange}
                    handleNextStep={this.handleNextStep}
                    handleBackStep={this.handleBackStep}
                    onChange={this.handleChange}
                    handleSocialSignup={this.handleSocialSignup}
                    id={id}
                    value={this.state[id]}
                    error={errorMessage != null}
                    {...props}
                    {...state}
                  />
                  <FlexItem fullWidth>
                    {errorMessage && (
                      <ErrorMessage>{errorMessage}</ErrorMessage>
                    )}
                  </FlexItem>
                </Flex>
              </Card>
            </Flex>
          </GridItem>
        </Grid>
        <Snackbar />
      </React.Fragment>
    )
  }
}
