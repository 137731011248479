// @flow
import React, { useRef, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import CardButton from '../Cards/CardButton'
import { FlexItem } from '../Flex/FlexDirection'
import CloseIcon from '@material-ui/icons/Close'
import SyncIcon from '@material-ui/icons/Sync';
import Button from '@material-ui/core/Button'
import { CommonIcon } from '@icons'
import Loader from '@Common/Loader'
import SignatureCanvas from 'react-signature-canvas'
import CheckBox from '@Common/CheckBox'

import {
  styles,
  Clip,
  CheckIconContainer,
  ContentHeader,
  ContentData,
  Item,
  ItemHeader,
  MessageContainer,
  HighlightMessageContainer,
  ActionButtons,
  DialogTitleLeft,
  DialogContentLeft,
  SignatureContainer,
  CheckBoxContainer,
  ErrorMsg,
  ESignLabel,
  SignatureButtonContainer,
  LoaderContainer
} from './styles'

type Props = {
  classes: Object,
  show: boolean,
  onClose: Function,
  title: string,
  message: string,
  messageHighlight: string,
  contentHeader: string,
  data: Object,
  emptyContentText: string,
  maxWidth: string,
  woButton: { onClick: Function, color: string, text: string, height: string, width: string },
  cancelButton: { onClick: Function, color: string, text: string, height: string, width: string },
  isLoading: boolean
}

const HighlightMessage = (message, highlighted: string) => {
  // eslint-disable-next-line
  let high = message.split(highlighted)
  return (
    <MessageContainer>
      {high[0]}
      <HighlightMessageContainer>
        {highlighted}
      </HighlightMessageContainer>
      {high[1]}
    </MessageContainer>
  )
}


const AlertModalDynamic = ({
  show,
  onClose,
  title,
  message,
  messageHighlight,
  contentHeader,
  emptyContentText,
  data,
  cancelButton,
  woButton,
  maxWidth,
  classes,
  isLoading
}: Props) => {

  const [isWo, setIsWo] = useState(true)
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [error, setError] = useState('')
  const sigCanvas = useRef({})
  const clear = () => sigCanvas.current.clear()
  const submitWo = () => {
    if (sigCanvas.current.isEmpty()) {
      setError('Please E-Sign before confirming')
      return
    }
    setError('')
    const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    woButton.onClick(signature)
  }

  let count = 0
  const RenderCategoryItems = ({ category, items }) => {
    return items.map(item => {
      if (item.actionRequired) {
        count = count + 1
        return (
          <Item>
            <ItemHeader>
              {category.roomName} -
            </ItemHeader>
            {item.attributeName}
          </Item>
        )
      } else return null
    })
  }

  return (
    <React.Fragment>
      <Dialog
        classes={{
          paper: classes.root,
        }}
        PaperProps={{ className: { dialogPaper: { overflow: 'visible' } } }}
        maxWidth={maxWidth}
        open={show}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {isWo && <CheckIconContainer>
          <CommonIcon name="tick" />
        </CheckIconContainer>}
        <FlexItem>
          <Button onClick={onClose} style={styles.closeButton} >
            Dismiss
            <CloseIcon style={styles.closeIcon} />
          </Button>
        </FlexItem>
        {isWo ?
          <>
            <DialogTitle id="alert-dialog-title">
              {title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {HighlightMessage(message, messageHighlight)}
              </DialogContentText>
            </DialogContent>
            {isLoading ?
              <Clip>
                <Loader />
              </Clip> :
              <>
                <Clip>
                  <ContentHeader>
                    {contentHeader ? contentHeader : null}
                  </ContentHeader>
                  <ContentData>
                    {data && data.length &&
                      data.map((item, index) => (
                        RenderCategoryItems({
                          category: item,
                          items: item.residentRoomCheckListInfo
                        })
                      ))}
                    {!count && emptyContentText}
                  </ContentData>
                </Clip>
                <ActionButtons>
                  <React.Fragment>
                    <CardButton
                      background={cancelButton.color}
                      onClick={cancelButton.onClick}
                      outlined={true}
                      text={cancelButton.text}
                      width={cancelButton.width}
                      height={cancelButton.height}
                    />
                    <FlexItem flex={0.06} />
                    <CardButton
                      background={woButton.color}
                      onClick={() => setIsWo(false)}
                      outlined={false}
                      text="Confirm"
                      width={woButton.width}
                      height={woButton.height}
                    />
                  </React.Fragment>
                </ActionButtons>
              </>
            }
          </> :
          <>
            <DialogTitleLeft id="alert-dialog-title">
              Acknowledgement
            </DialogTitleLeft>
            <DialogTitle>
              <DialogContentLeft>
                By signing your electronic signature and selecting the "Confirm" button, 
                you are signing this Agreement electronically. You agree your electronic 
                signature is the legal equivalent of your manual signature on this Agreement. 
                By selecting "I Accept" you consent to be legally bound by this Agreement's 
                terms and conditions."
              </DialogContentLeft>
            </DialogTitle>
            {isLoading ?
              <LoaderContainer >
                <Loader />
              </LoaderContainer >
              :
              <>
                <CheckBoxContainer >
                  <CheckBox
                    iconName="square"
                    checkedIconName="square-checkmark"
                    checked={agreeTerms}
                    onChange={() => {
                      setAgreeTerms(!agreeTerms)
                      setError('')
                    }}
                    text="I have read and understand"
                  />
                </CheckBoxContainer>
                {agreeTerms &&
                  <SignatureContainer>
                    <SignatureButtonContainer>
                      <ESignLabel>E-Sign here:</ESignLabel>
                      <Button
                        onClick={() => clear()}
                        style={styles.clearButton}>
                        Clear
                        <SyncIcon style={styles.clearIcon} />
                      </Button>
                    </SignatureButtonContainer>
                    <FlexItem>
                      <SignatureCanvas
                        // $FlowDisableNextLine
                        ref={sigCanvas}
                        canvasProps={{ style: styles.signatureCanvas }} />
                    </FlexItem>
                  </SignatureContainer>}
                {error && error.length &&
                  <ErrorMsg>{error}</ErrorMsg>}
                <ActionButtons>
                  <React.Fragment >
                    <CardButton
                      background={cancelButton.color}
                      onClick={cancelButton.onClick}
                      outlined={true}
                      text={cancelButton.text}
                      width={cancelButton.width}
                      height={cancelButton.height}
                    />
                    <FlexItem flex={0.06} />
                    <CardButton
                      background={woButton.color}
                      onClick={() => submitWo()}
                      outlined={false}
                      disabled={!agreeTerms}
                      text={woButton.text}
                      width={woButton.width}
                      height={woButton.height}
                    />
                  </React.Fragment>
                </ActionButtons>
              </>
            }
          </>}
      </Dialog>
    </React.Fragment >
  )
}

export default withStyles(styles)(AlertModalDynamic)
