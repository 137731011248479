// @flow

import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { CheckboxIcon } from '@icons'
import { CheckBoxText } from './styles'

type Props = {
  checked: boolean,
  onChange: (event: SyntheticEvent<HTMLInputElement>, checked: boolean) => void,
  id?: string,
  iconName?: string,
  checkedIconName?: string,
  text?: string,
}

const getCheckboxSizes = (iconName: string) => {
  switch (iconName) {
    case 'circle':
    case 'checkmark':
      return { height: '23px', width: '24px' }

    case 'square':
    case 'square-checkmark':
      return { height: '23px', width: '22px' }

    default:
      return {}
  }
}

const CheckBox = ({
  checked,
  onChange,
  id,
  iconName = 'circle',
  checkedIconName = 'checkmark',
  text,
}: Props) => (
  <React.Fragment>
    <Checkbox
      icon={<CheckboxIcon name={iconName} {...getCheckboxSizes(iconName)} />}
      checkedIcon={
        <CheckboxIcon
          name={checkedIconName}
          {...getCheckboxSizes(checkedIconName)}
        />
      }
      checked={checked}
      onChange={onChange}
      id={id}
    />
    {text && <CheckBoxText>{text}</CheckBoxText>}
  </React.Fragment>
)

export default CheckBox
