import { connect } from 'react-redux'
import Visitor from './Visitor'
import withFetchData from 'zego-shared/views/withFetchData'
import { fetchPropertyUsersSummary } from 'zego-shared/store/users/propertyUsersSummary/actions'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import {
  fetchVisitorAction,
  uploadImage,
  updateVisitor
} from 'zego-shared/store/registration/visitors/actions'
import { getVisitor } from '../../../store/Registration/visitor/selector'
import { getAllPropertyResidents } from 'zego-shared/store/users/selectors'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'

import api from 'zego-shared/utils/api'
import { getToken } from 'zego-shared/store/authorization/selectors'

const getNames = (name = '') => {
  return name.trim().split(/\s+/)
}

const buildCreatePayload = (visitor, propertyId) => {
  const visitorNames = getNames(visitor.visitorName)
  const residentNames = getNames(visitor.visitingName)
  return {
    resident_first_name: residentNames[0],
    resident_last_name: residentNames[1],
    resident_user_id: visitor.visitingId,
    property_id: propertyId,
    unit_id: visitor.unitId,
    visitor_first_name: visitorNames[0],
    visitor_last_name: visitorNames[1],
    visitor_phone: visitor.phoneNumber,
    start_date: new Date(visitor.startDate).toISOString(),
    end_date: new Date(visitor.endDate).toISOString(),
    details: {
      description: visitor.description
    },
    photo: visitor.photo
  }
}

const buildUpdatePayload = (visitor, propertyId) => {
  const visitorNames = getNames(visitor.visitorName)
  return {
    visitor_first_name: visitorNames[0],
    visitor_last_name: visitorNames[1],
    visitor_phone: visitor.phoneNumber,
    start_date: new Date(visitor.startDate).toISOString(),
    end_date: new Date(visitor.endDate).toISOString(),
    details: {
      description: visitor.description
    }
  }
}

const createVisitor = async (visitor, propertyId, token) => {
  try {
    const data = buildCreatePayload(visitor, propertyId)
    const { visitor_id: id } = await api.createVisitor(token, data)
    const { photo } = data
    if (photo && id) {
      await api.uploadVisitorImage(token, id, photo)
    }
    return id
  } catch (error) {}
}

const getAddMode = url => {
  return url.indexOf('/new') > -1
}
const mapStateToProps = (state, { match: { url } }) => {
  const addMode = getAddMode(url)
  return {
    userToken: getToken(state),
    item: getVisitor(state, addMode),
    residents: getAllPropertyResidents(state),
    addMode,
    isFetching: createIsFetchingSelector(['visitor'])(state)
  }
}

const mapDispatchToProps = (dispatch, { match: { params, url }, history }) => ({
  saveVisitor: async (visitor, addMode, token) => {
    const { propertyId } = params

    if (addMode) {
      const id = await createVisitor(visitor, propertyId, token)
      return (
        id &&
        history.push(`/properties/${propertyId}/registration/visitors/${id}`)
      )
    }

    dispatch(updateVisitor(buildUpdatePayload(visitor, propertyId), visitor.id))
  },

  showSnackbar: (message, variant) => dispatch(showSnackbar(message, variant)),

  uploadVisitorImage: (id, data) => dispatch(uploadImage(id, data)),

  fetchData: () => {
    if (!getAddMode(url)) {
      dispatch(fetchVisitorAction({ id: params.visitorId }))
    }
    dispatch(fetchPropertyUsersSummary(params.propertyId))
  },

  componentDidUpdate: ({ match }, { addMode }) => {
    if (params.visitorId !== match.params.visitorId && !addMode) {
      dispatch(fetchVisitorAction({ id: params.visitorId }))
    }
  }
})

const VisitorWithFetchData = withFetchData(Visitor)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VisitorWithFetchData)
