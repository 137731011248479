import { CONNECT_DEVICE_SUCCESS, CLEAR_CONNECTED_DEVICE } from './actions'

const initialState = {}

const connected = (state = initialState, action) => {
  switch (action.type) {
    case CONNECT_DEVICE_SUCCESS:
      return action.device
    case CLEAR_CONNECTED_DEVICE:
      return initialState
    default:
      return state
  }
}

export default connected
