// @flow
import React from 'react'
import CarouselList from '@Common/CarouselList'
import FlexDirection from '@Common/Flex/FlexDirection'
import { HistoryContentProps } from './types'
import withScrollBehaviour from '@Common/CarouselList/withScrollBehaviour'

const HistoryContent = ({
  currentSlideNumber,
  lastSlideNumber,
  numberOfUnitsPerSlide,
  items
}: HistoryContentProps) => (
  <FlexDirection direction="column">
    <CarouselList
      numberOfItemsPerSlide={numberOfUnitsPerSlide}
      itemHeight={56}
      currentSlide={currentSlideNumber}
      lastSlide={lastSlideNumber}
      items={items}
    />
  </FlexDirection>
)

export default withScrollBehaviour(HistoryContent)
