import React from 'react'
import PrivateRoute from '../PrivateRoute'
import Communication from '../../views/Communication'
import { getChatRoutes } from './Chat'
import { getGroupMessagingRoutes } from './GroupMessaging'
import { getCommunityFeedRoutes } from './CommunityFeed'

const getCommunicationRoutes = () => [
  <PrivateRoute
    exact
    key="chat"
    path="/properties/:propertyId/communication"
    component={Communication}
  />,
  ...getChatRoutes(),
  ...getGroupMessagingRoutes(),
  ...getCommunityFeedRoutes()
]

export default getCommunicationRoutes
