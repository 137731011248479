// @flow
import React, { Fragment } from 'react'
import Btn from '@Common/Button' 
import ZegoInput from '../Common/Input'
import { FlexItem } from '@Common/Flex'
import { Google } from '../Common/Social'
import { Divider, ForgotPassword, Logo } from './styles'

import type { changeModeFn, handleChangeFn } from './types'

type Props = {
  email: string,
  password: string,
  userId?: string,
  changeMode?: changeModeFn,
  handleChange: handleChangeFn,
  handleLogin: () => mixed,
  handleSocialLogin?: (string, Object) => mixed,
  handleSocialFailure?: () => mixed,
  disableSocialLogin?: boolean,
  error: string,
  working: boolean,
  isUserSpoofing?: boolean
}

const LoginSection = ({
  email,
  password,
  userId,
  changeMode,
  handleChange,
  handleLogin,
  handleSocialLogin,
  handleSocialFailure,
  error,
  working,
  isUserSpoofing,
  disableSocialLogin
}: Props) => (
  <Fragment>
    <FlexItem>
      <Logo />
      {!isUserSpoofing && !disableSocialLogin && (
        <Google
          mode="login"
          onSuccess={handleSocialLogin}
          onFailure={handleSocialFailure}
        />
      )}
      {isUserSpoofing && (
        <ZegoInput
          fullWidth
          placeholder="user id"
          value={userId}
          onChange={handleChange}
          id="userId"
        />
      )}
      <Divider />
      <ZegoInput
        fullWidth
        placeholder="email"
        value={email}
        onChange={handleChange}
        id="email"
      />
      <ZegoInput
        fullWidth
        placeholder="password"
        type="password"
        value={password}
        onChange={handleChange}
        id="password"
      />
    </FlexItem>
    <FlexItem fullWidth>
      {!isUserSpoofing && (
        <ForgotPassword
          onClick={() => {
            changeMode && changeMode('passwordRecoveryRequest')
          }}>
          forgot your password?
        </ForgotPassword>
      )}
      <Btn
        id="loginButton"
        disableRipple
        fullWidth
        primary
        classes={{ label: 'login-label' }}
        animate={working && !error ? 'animate' : ''}
        onClick={handleLogin}>
        Login
      </Btn>
    </FlexItem>
  </Fragment>
)

export default LoginSection
