// @flow

import React, { Fragment } from 'react'
import SetupStep from './SetupStep'
import { capitalizeUnderscoreToSpace } from '../../utils/stringUtils'
import { ExitTextWrapper, ExitText, ExitTextColor } from './styles'
import withRouter from 'react-router-dom/withRouter'
import type { SetupStepProps } from './types'

const ConnectDevice = ({
  deviceProfileType,
  setupInstruction,
  hasConnectedDevice,
  match: {
    params: { propertyId, unitId }
  },
  history
}: SetupStepProps) => (
  <Fragment>
    <SetupStep
      title={`CONNECT A ${capitalizeUnderscoreToSpace(
        deviceProfileType || ''
      )}`}
      listItems={setupInstruction}
    />
    {!hasConnectedDevice && (
      <ExitTextWrapper>
        <ExitText
          onClick={() =>
            history.push(`/properties/${propertyId}/units/${unitId}/smart`)
          }>
          Having trouble connecting? <ExitTextColor>Exit</ExitTextColor> to
          delete this device and start over.
        </ExitText>
      </ExitTextWrapper>
    )}
  </Fragment>
)

export default withRouter(ConnectDevice)
