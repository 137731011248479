// @flow
import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import reject from 'lodash/reject'
import Divider from '@material-ui/core/Divider'
import { NavIcon } from '@icons'
import { STANDALONE_WORKORDER_FEATUREFLAG } from '../../WorkOrder/Constants'
import { RESIDENT_MANAGEMENT_FEATURE_FLAG } from '../../Residents/constants'
import { AMENITY_RESERVATION_FEATUREFLAG } from '../../Amenities/constants'
import type { Theme } from '../../../theme'

// styles
import {
  NavItem,
  NavLink,
  IconSection,
  NavMenu,
  MenuIcon,
  NavText,
  BottomNav,
  NavList,
  UnreadDot,
  Logo,
} from './styles'

const isTrue = 1

type State = {
  open: boolean,
  showResidentManagement: boolean,
  showWorkOrderNav: boolean,
  showProAmenities: string
}

type Props = {
  location: { pathname: string },
  propertyId: string,
  propertyAccessSettings: Object,
  theme: Theme,
  unreadStatus: number,
  featureFlag: Object,
  ldInitRequest: Function,
}

const getMenuLinks = (propertyId) => [
  {
    route: `/properties/${propertyId}/summary`,
    text: 'summary',
  },
  {
    route: `/properties/${propertyId}/smart`,
    text: 'zego smart',
    keys: ['smartResidentAccess'],
  },
  {
    route: `/properties/${propertyId}/communication`,
    text: 'communication',
    keys: ['chatResidentAccess', 'groupMessaging', 'communityFeed'],
  },
  {
    route: `/properties/${propertyId}/user-management`,
    text: 'user management',
  },
  {
    route: `/properties/${propertyId}/packages`,
    text: NAV_TEXT_PACKAGES,
    keys: ['packages'],
  },
  {
    route: `/properties/${propertyId}/lock-codes`,
    text: 'lock codes',
    keys: ['vendorCodes', 'smartResidentAccess'],
  },
  {
    route: `/properties/${propertyId}/registration`,
    text: 'registration',
    keys: ['petRegistration', 'vehicleRegistration', 'visitorRegistration'],
  },
  {
    route: `/properties/${propertyId}/amenities`,
    text: 'amenities',
    keys: ['amenities', 'amenityReservations'],
  },
  {
    route: `/properties/${propertyId}/marketplace/overview`,
    text: 'marketplace',
    keys: ['marketplace'],
  },
]

const isSelected = (route, pathname) => {
  const routeName = route.split('/')[3]
  if (pathname.split('/')[5] === routeName) return true
  return pathname.split('/')[3] === routeName
}

class NavigationMenu extends React.Component<Props, State> {
  state = {
    open: false,
    showResidentManagement: false,
    showWorkOrderNav: false,
    showProAmenities: ''
  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  }

  handleDrawerClose = () => {
    this.setState({ open: false })
  }

  getActiveMenuLinks = (propertyId, propertyAccessSettings) => {
    const {
      showWorkOrderNav,
      showResidentManagement,
      showProAmenities,
    } = this.state

    if (showProAmenities === 'Legacy')
      propertyAccessSettings.amenityReservations = false

    const menuLinks = reject(
      getMenuLinks(propertyId),
      ({ keys }) =>
        keys &&
        !keys
          .map((key) => propertyAccessSettings[key])
          .some((value) => value === true)
    )

    // show for stand alone work orders only
    if (propertyAccessSettings.standaloneWorkOrders && showWorkOrderNav) {
      menuLinks.push({
        route: `/properties/${propertyId}/work-orders/summary`,
        text: 'work orders',
      })
    }

    if (showResidentManagement) {
      // rename user management to property users, show new res link
      const resLink = {
        route: `/properties/${propertyId}/residents`,
        text: 'residents',
      }

      const propertyUserLink = {
        route: `/properties/${propertyId}/user-management/property`,
        text: 'property users',
      }

      const userMgmIndex = menuLinks.findIndex(
        (link) => link.text === 'user management'
      )

      menuLinks.splice(userMgmIndex, 1, resLink)
      menuLinks.push(propertyUserLink)
    }

    return menuLinks
  }

  componentDidMount = () => {
    const { ldInitRequest } = this.props
    ldInitRequest()
  }

  componentDidUpdate = () => {
    const { featureFlag } = this.props
    const {
      showWorkOrderNav,
      showResidentManagement,
      showProAmenities
    } = this.state

    const workOrderFlag =
      featureFlag &&
      featureFlag.ld &&
      featureFlag.ld.variation(STANDALONE_WORKORDER_FEATUREFLAG, false)
    const residentManagementFlag =
      featureFlag &&
      featureFlag.ld &&
      featureFlag.ld.variation(RESIDENT_MANAGEMENT_FEATURE_FLAG, false)
    const amenityVersionFlag =
      featureFlag &&
      featureFlag.ld &&
      featureFlag.ld.variation(AMENITY_RESERVATION_FEATUREFLAG, 'Legacy')

    const showProAmenitiesValue = amenityVersionFlag || 'Legacy'
    if (!showProAmenities)
      this.setState({ showProAmenities: showProAmenitiesValue })
    if (
      showWorkOrderNav !== workOrderFlag ||
      showResidentManagement !== residentManagementFlag
    ) {
      this.setState({
        showWorkOrderNav: workOrderFlag,
        showResidentManagement: residentManagementFlag,
      })
    }
  }

  render() {
    const {
      propertyId,
      propertyAccessSettings,
      location: { pathname },
      theme,
      unreadStatus,
    } = this.props
    return (
      <NavMenu
        id="nav-menu"
        onMouseEnter={this.handleDrawerOpen}
        onMouseLeave={this.handleDrawerClose}
        variant="permanent"
        classes={{
          paper: this.state.open
            ? 'drawerpaper'
            : 'drawerpaper drawerpaperclose',
        }}
        open={this.state.open}>
        <IconSection />
        <NavList>
          <NavItem home={isTrue}>
            <NavText primary="" />
            <MenuIcon>
              <Logo />
            </MenuIcon>
          </NavItem>
          <Divider />
          {this.getActiveMenuLinks(propertyId, propertyAccessSettings).map(
            ({ route, text }, index) => (
              <NavLink
                key={index}
                to={`${route}`}
                className="nav-link"
                id={text.replace(/\s/g, '') + 'Nav'}>
                <NavItem disableRipple selected={isSelected(route, pathname)}>
                  <NavText
                    selected={isSelected(route, pathname)}
                    primary={text}
                  />
                  <MenuIcon>
                    <NavIcon
                      fill={
                        isSelected(route, pathname)
                          ? theme.palette.primary.navy.spaceBlue
                          : theme.palette.common.white
                      }
                      name={text}
                      width="24px"
                      height="20px"
                      opacity="0.75"
                    />
                    {text === 'communication' && unreadStatus > 0 && (
                      <UnreadDot />
                    )}
                  </MenuIcon>
                </NavItem>
              </NavLink>
            )
          )}
          <BottomNav>
            <NavLink
              to={`/properties/${propertyId}/settings`}
              className="nav-link">
              <NavItem selected={pathname.includes('/settings')}>
                <NavText primary="settings" />
                <MenuIcon>
                  <NavIcon
                    fill={
                      pathname.includes('/settings')
                        ? theme.palette.primary.navy.spaceBlue
                        : theme.palette.common.white
                    }
                    name="settings"
                    width="24px"
                    height="20px"
                    opacity="0.7"
                  />
                </MenuIcon>
              </NavItem>
            </NavLink>
          </BottomNav>
        </NavList>
      </NavMenu>
    )
  }
}

export const NAV_TEXT_PACKAGES = 'packages'
export default withRouter(withTheme(NavigationMenu))
