import { call, select, put, takeLatest, fork } from 'redux-saga/effects'
import sendBird from '../../../../utils/sendbird'
import { getToken, getUserId } from '../../../authorization/selectors'
import log from '../../../../utils/logger'
import api from 'zego-shared/utils/api'
import { getChannels, getChannelsByType } from '../selectors'
import { setActiveChannel } from '../../activeChannel/actions'
import {
  fetchChatChannelsByType as fetchChatChannelsByTypeAction,
  fetchChatChannelsByTypeStart,
  fetchChatChannelsByTypeSuccess,
  fetchChatChannelsByTypeError,
  fetchUnitResidentChatChannelsAction,
  fetchUnitResidentChatChannelsStart,
  fetchUnitResidentChatChannelsSuccess,
  fetchUnitResidentChatChannelsError
} from '../actions'

export const channelTypes = {
  'live-chat': () => sendBird.getUserChannels()
}

export function* fetchRemainingChannelsByType(query) {
  while (query.hasNext) {
    const { resource: nextChannels } = yield call(
      sendBird.getNextChannels,
      query
    )
    const channels = yield select(getChannelsByType)
    const newChannels = [...channels, ...nextChannels]

    yield put(fetchChatChannelsByTypeSuccess(newChannels))
  }
}

export function* fetchChatChannelsByType({
  channelType,
  userId,
  shouldSetActiveChannel
}) {
  try {
    yield put(fetchChatChannelsByTypeStart())

    const { resource: channels, query } = yield call(channelTypes[channelType])

    yield fork(fetchRemainingChannelsByType, query)
    let activeChannel

    if (userId) {
      const { resource: userChannel } = yield call(
        sendBird.getUserChannel,
        userId
      )
      activeChannel = userChannel[0]
    } else {
      activeChannel = channels[0]
    }

    if (shouldSetActiveChannel) {
      yield put(setActiveChannel(activeChannel))
    }

    yield put(fetchChatChannelsByTypeSuccess(channels))
  } catch (error) {
    log(`Failed to fetch chat channel messages. Error: ${error}`)
  }
}

export function* fetchUnitResidentChatChannels({ id }) {
  try {
    const authToken = yield select(getToken)
    yield put(fetchUnitResidentChatChannelsStart())
    let users = yield call(api.getUsers, id, authToken)
    const usersIds = users.map(user => user.id)
    let { resource: channels, query } = yield call(
      sendBird.getUserChannels,
      usersIds
    )

    channels = channels.filter(channel => channel)
    yield put(fetchUnitResidentChatChannelsSuccess(channels))
  } catch (error) {
    yield put(fetchUnitResidentChatChannelsError(error))
    log(`Failed to fetch users chat channels. Error: ${error}`)
  }
}

function* watchFetchUnitResidentChatChannels() {
  yield takeLatest(
    fetchUnitResidentChatChannelsAction().type,
    fetchUnitResidentChatChannels
  )
}

function* watchFetchChatChannelsByType() {
  yield takeLatest(
    fetchChatChannelsByTypeAction().type,
    fetchChatChannelsByType
  )
}

export default [
  watchFetchChatChannelsByType(),
  watchFetchUnitResidentChatChannels()
]
