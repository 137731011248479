// @flow
import React from 'react'
import type { BrowserHistory } from 'history'
import toString from 'lodash/toString'
import type { Match } from 'react-router-dom'
import { Link, withRouter } from 'react-router-dom'
import Layout from './GroupMessagingLayout'
import PaginatedTable from '../../Common/PaginatedTable'
import BackButton from './BackButton'
import Loader from '../../Common/Loader'
import {
  GroupsRowStyled,
  GroupsColumnStyled as ColumnStyled,
  TableContainer
} from './styles'

type Props = {
  data: Object[],
  isFetching: boolean,
  hasData: boolean,
  currentPage: number,
  residentCount: number,
  total: number,
  perPage: number,
  fetchPage: number => mixed,
  searchGroups: string => mixed,
  match: Match,
  history: BrowserHistory,
  userRole: string,
  propertyId: string,
  displayErrorMessage: (message: string) => void
}

const createRows = (data, residentCount, url) => {
  return data.map(rowData =>
    Object.entries(rowData).map(
      ([key, value], index) =>
        key !== 'id' && {
          value:
            index === 1 ? (
              <Link to={`${url}/${rowData.id}`}>
                <GroupsRowStyled index={index}>{value}</GroupsRowStyled>
              </Link>
            ) : (
              <Link to={`${url}/${rowData.id}`}>
                <GroupsRowStyled index={index}>
                  <div>
                    {value === residentCount
                      ? 'all residents'
                      : value
                      ? toString(value)
                      : 0}
                  </div>
                </GroupsRowStyled>
              </Link>
            )
        }
    )
  )
}

const columns = [
  { value: <ColumnStyled index={0} /> },
  { value: <ColumnStyled index={1} /> },
  {
    value: <ColumnStyled index={2}>residents</ColumnStyled>
  }
]

const goBack = (history, url) => {
  history.push(`${url.split('/groups')[0]}`)
}

const getButtons = (history, url) => (
  <React.Fragment>
    <BackButton onClick={() => goBack(history, url)} />
  </React.Fragment>
)

const Groups = ({
  data,
  isFetching,
  hasData,
  currentPage,
  total,
  perPage,
  fetchPage,
  searchGroups,
  residentCount,
  match,
  history,
  userRole,
  propertyId,
  displayErrorMessage
}: Props) => {

  if (userRole === "property_staff") {
    history.replace(`/properties/${propertyId}/communication/group-messaging`)
    setTimeout(() => {
      displayErrorMessage("Group Messaging Groups are not available for Property Staff.")
    }, 1000)

    return null
  }

  return (
    <Layout
      header="COMMUNICATION"
      title="GROUPS"
      onSearch={searchGroups}
      buttons={getButtons(history, match.url)}
      table={
        isFetching || !hasData ? (
          <React.Fragment>
            <br />
            <br />
            <Loader />
          </React.Fragment>
        ) : total > 0 ? (
          <TableContainer>
            <PaginatedTable
              total={total}
              columns={columns}
              rows={createRows(data, residentCount, match.url)}
              rowsPerPage={perPage}
              currentPage={currentPage - 1}
              changePage={fetchPage}
              flex={[0.8, 1.5, 1.2]}
            />
          </TableContainer>
        ) : (
          <div>No groups exist</div>
        )
      }
    />
  )
}

export default withRouter(Groups)
