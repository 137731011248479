// @flow

import React from 'react'
import SetupStep from './SetupStep'

const checklistItems = [
  'Plug the hub into a wall outlet using the included power cord.',
  'Using the included ethernet cable, plug the hub into a wifi router or modem.',
  'Locate Install Code or 12 character MAC ID on the back of hub.'
]

const AddHub = () => <SetupStep title="CHECKLIST" listItems={checklistItems} />

export default AddHub
