import { BlockATimeModalDropdowns, DialogTitle } from '../../AmenityShared/styles'
import ReactSelect from 'react-select'
import React from 'react'
import { getTimeBlockEndTimes, getTimeBlockStartTimes } from '../../../../utils/scheduleUtils'

const maxMenuHeight = 180

export default function BlockTimeSlotModalContent({
  amenity,
  timeSlots,
  newFacilityBlock,
  setNewFacilityBlockTime
}) {
  const { startTime } = newFacilityBlock
  const startTimeSelectProps = {
    ...getTimeBlockStartTimes(amenity, timeSlots, newFacilityBlock),
    onChange: ({ value }) => setNewFacilityBlockTime({ startTime: value}),
    maxMenuHeight
  }

  const endTimeSelectProps = {
    ...getTimeBlockEndTimes(amenity, timeSlots, newFacilityBlock),
    onChange: ({ value }) => setNewFacilityBlockTime({ endTime: value}),
    isDisabled: !startTime && startTime !== 0,
    maxMenuHeight
  }
  return (
    <>
      <DialogTitle>Choose the time range you wish to block</DialogTitle>
      <BlockATimeModalDropdowns>
        <ReactSelect {...startTimeSelectProps}/> {'to'} <ReactSelect {...endTimeSelectProps}/>
      </BlockATimeModalDropdowns>
    </>
  )
}
