// @flow
import React, { Fragment } from 'react'
import isEqual from 'lodash/isEqual'
import moment, { Moment } from 'moment'
import { withTheme } from 'emotion-theming'
import type { BrowserHistory } from 'history'
import type { Match } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import TextEditor from '../../../Common/TextEditor/TextEditor'
import type { Theme } from '../../../../theme'
import { MessageSection, Divider } from './styles'
import StatusScreen, {
  StatusScreenContent
} from '../../../Common/StatusScreen/StatusScreen'
import ImageSection from './ImageSection'
import TitleSection from './TitleSection'
import DateSection from './DateSection'
import Loader from '../../../Common/Loader'

import withDialog from '@Common/AlertModal/withAlertModal'

type Props = {
  type: string,
  action: string,
  theme: Theme,
  userToken: string,
  propertyId: string,
  history: BrowserHistory,
  match: Match,
  saveChanges: Function,
  showAlertModal: () => void,
  showError: (message: string) => void,
  data: {
    threadId: string,
    endTime: string,
    startTime: string,
    title: string,
    message: string,
    image?: Object,
    moment: Moment
  },
  isFetching: boolean,
  hasData: boolean,
  threadId: string
}

type State = {
  title: string,
  image?: Object | null,
  message: string,
  showDialog: boolean,
  success: boolean,
  threadId: string,
  endTime: string,
  startTime: string,
  croppedImage?: Object | null,
  moment: Moment,
  showCropper: boolean,
  maintainState: boolean
}

class CreateEditPost extends React.Component<Props, State> {
  state = {
    title: '',
    image: null,
    message: '',
    showDialog: false,
    success: false,
    threadId: '',
    moment: moment(),
    startTime: '',
    endTime: '',
    croppedImage: null,
    showCropper: false,
    maintainState: false
  }

  isEvent = this.props.type === 'events'
  isPost = this.props.type === 'posts'

  componentDidUpdate(prevProps, prevState) {
    const { data } = this.props
    if (data && prevProps.data !== data && !prevState.maintainState) {
      this.setState({
        title: data.title,
        image: data.image,
        message: data.message,
        showDialog: false,
        success: false,
        threadId: data.threadId,
        moment: data.moment,
        startTime: data.startTime,
        endTime: data.endTime,
        maintainState: false
      })
    }
  }

  deleteImage = () => {
    const { croppedImage } = this.state

    croppedImage
      ? this.setState({ croppedImage: null })
      : this.setState({ image: null })
  }

  uploadCroppedImage = (file: any) => {
    file.url = URL.createObjectURL(file)

    this.setState({ croppedImage: file, showCropper: false })
  }

  uploadImage = async (event: SyntheticInputEvent<HTMLInputElement>) => {
    let image = event.target.files[0]
    // $FlowDisableNextLine
    image.url = URL.createObjectURL(image)
    if (image) {
      this.setState({ image, showCropper: true })
    }
  }

  handleMessageChange = message => {
    this.setState({ message })
  }

  goBack = () => {
    const { history, type, propertyId } = this.props
    history.push(
      `/properties/${propertyId}/communication/community-feed/${type}`
    )
  }

  goToPostOrEvent = () => {
    const {
      history,
      match: { url },
      type
    } = this.props
    const { threadId } = this.state
    history.push(`${url.split(`/${type}`)[0]}/${type}/${threadId}`)
  }

  openDialog = () => {
    this.props.showAlertModal()
  }

  getUTCDate = (date: Object, time: string) => {
    const dateTime = date.toDate()
    const splitTime = time.split(':')
    dateTime.setHours(splitTime[0])
    dateTime.setMinutes(splitTime[1])
    return moment.utc(dateTime).format('YYYY-MM-DDTHH:mm:ss') + 'Z'
  }

  getSpecificErrorMsgEvents = (): string => {
    const { title, message, endTime, startTime } = this.state

    if (!title && !message && !endTime && !startTime)
      return 'Title, Message, Start time and End time are required fields'
    if (!title) return 'Please provide a Title'
    if (!endTime || !startTime)
      return 'Please provide Start time and End time e.g 08:00 AM'
    if (!message) return 'Please provide a Message'
    else return 'An error occured'
  }

  savePostOrEventChanges = async () => {
    const {
      croppedImage,
      image,
      title,
      message,
      endTime,
      startTime,
      moment
    } = this.state

    const {
      type,
      userToken,
      propertyId,
      saveChanges,
      showError,
      action,
      threadId: threadIdFromProps
    } = this.props

    if (action === 'edit') {
      const data = this.props.data
      const dataValues = {
        image: data.image,
        title: data.title,
        message: data.message,
        endTime: data.endTime,
        startTime: data.startTime,
        moment: data.moment
      }

      if (
        isEqual(dataValues, {
          image,
          title,
          message,
          endTime,
          startTime,
          moment
        })
      ) {
        showError('There are no new changes!')
        return
      }
    }

    const isImageCropped =
      (this.props.data != null && this.props.data.image) ||
      (image && croppedImage)
        ? true
        : !image

    // validates required fields
    const isValid = this.isPost
      ? title && message
      : title && message && moment && endTime && startTime

    if (!isImageCropped) {
      await this.setState({maintainState: true})
      showError('Please finish cropping the image or remove it')
      return
    }

    if (isValid) {
      const utcEndDateTime = this.isEvent
        ? this.getUTCDate(moment, endTime)
        : ''
      const utcStartDateTime = this.isEvent
        ? this.getUTCDate(moment, startTime)
        : ''
      try {
        const threadId = await saveChanges(
          userToken,
          propertyId,
          croppedImage,
          title,
          message,
          type,
          utcStartDateTime,
          utcEndDateTime,
          action,
          threadIdFromProps
        )
        this.setState({ success: true, threadId })
      } catch (err) {
        showError('An error occurred! Please try again')
      }
    } else {
      this.isPost
        ? showError('Title and Message are required fields')
        : showError(this.getSpecificErrorMsgEvents())
    }
  }

  handleFieldChange = (
    type: string,
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    this.setState({ [type]: event.target.value })
  }

  handleCalendarChange = (moment: Moment) => {
    this.setState({ moment })
  }

  render() {
    const { theme, type, action, isFetching, hasData } = this.props

    if (isFetching) return <Loader />

    if (!hasData && action === 'edit') return null
    const {
      image,
      croppedImage,
      title,
      message,
      success,
      moment,
      startTime,
      endTime,
      showCropper
    } = this.state

    return (
      <Fragment>
        <ImageSection
          showCropper={showCropper}
          theme={theme}
          image={image}
          croppedImage={croppedImage}
          deleteImage={this.deleteImage}
          uploadImage={this.uploadImage}
          uploadCroppedImage={this.uploadCroppedImage}
        />
        <TitleSection
          theme={theme}
          handleTitleChange={this.handleFieldChange}
          title={title}
          action={action}
          goBack={this.goBack}
          savePostOrEventChanges={this.savePostOrEventChanges}
          openDialog={this.openDialog}
        />
        {this.isEvent && (
          <DateSection
            theme={theme}
            moment={moment}
            startTime={startTime}
            endTime={endTime}
            handleDateChange={this.handleFieldChange}
            handleCalendarChange={this.handleCalendarChange}
            handleTimeChange={this.handleFieldChange}
          />
        )}
        <Divider columnStart={1} columnSpan={12} rowStart={6} rowSpan={1} />
        <MessageSection
          columnStart={1}
          columnSpan={12}
          rowStart={10}
          rowSpan={4}>
          <TextEditor
            editMode
            alwaysShowLabel
            value={title}
            enableAttachment={false}
            enableMergeFields={false}
            width={'70%'}
            message={message}
            label="message"
            handleMessageChange={this.handleMessageChange}
          />
        </MessageSection>
        <StatusScreen
          open={success}
          type="success"
          autoHideDuration={1200}
          goTo={this.goToPostOrEvent}>
          <StatusScreenContent
            type="success"
            heading="SUCCESS!"
            subheading={`your ${type} has been ${
              action === 'edit' ? 'saved' : 'created'
            }`}
          />
        </StatusScreen>
      </Fragment>
    )
  }
}

const CreateEditPostWithDialog = withRouter(
  withTheme(withDialog(CreateEditPost))
)

export default (props: Object) => (
  <CreateEditPostWithDialog
    alertTitle="Are you sure you wish to delete?"
    alertYesButtonText={'Yes'}
    alertNoButtonText={'No'}
    {...props}
  />
)
