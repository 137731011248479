import {
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'
import { getToken } from '../../authorization/selectors'
import { getUnitId } from '../../select/selectors'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import deviceCommands, { commands } from './utils/deviceCommands'
import switchStates from './utils/deviceStates'
import {
  joinChannel,
  pushMessage,
  watchChannel,
  eventKeys
} from '../../socket/sagas'
import {
  noUnitSelected,
  failedToDeleteDevice,
  deleteDeviceSuccess
} from '../../../utils/messages'
import {
  getHubIdentifier,
  getHubId,
  getLockState,
  getSwitchState,
  getDeviceById
} from '../selectors'
import {
  PUSH_HUB_COMMAND,
  UPGRADE_FIRMWARE,
  fetchDevicesStart,
  fetchDevicesSuccess,
  fetchDevicesError,
  fetchDevices,
  refreshDevicesStart,
  refreshDevicesSuccess,
  refreshDevicesError,
  refreshDevices,
  hubCommandActions,
  updateThermostatOptimistically,
  PUSH_THERMOSTAT_COMMAND,
  PUSH_SWITCH_STATE_COMMAND,
  updateLockOptimistically,
  PUSH_LOCK_STATE_COMMAND,
  updateDeviceNameOptimistically,
  updateDeviceNameRevert,
  updateSwitchOptimistically,
  updateSwitchRevert,
  PUSH_DEVICE_NAME_COMMAND,
  DELETE_DEVICE,
  DELETE_DEVICE_START,
  DELETE_DEVICE_FINISH
} from '../actions'
import { fetchDeviceHistory } from '../../../store/deviceHistory/actions'
import { fetchUnit } from '../../../store/select/actions'
import { deviceEvent } from '../../addDevice/connected/actions'
import { getDeviceChannel, isAnEventUpdate } from './utils'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'

const getActions = isRefreshing => ({
  start: isRefreshing ? refreshDevicesStart : fetchDevicesStart,
  success: isRefreshing ? refreshDevicesSuccess : fetchDevicesSuccess,
  error: isRefreshing ? refreshDevicesError : fetchDevicesError
})

export function* getDevices({ id, isRefreshing }) {
  const actions = getActions(isRefreshing)
  try {
    yield put(actions.start())
    const authToken = yield select(getToken)
    const unitId = id ? id : yield select(getUnitId)

    if (unitId === -1) throw new Error(noUnitSelected)

    const response = yield call(api.getDevices, unitId, authToken)
    yield put(actions.success(response))
  } catch (error) {
    yield put(actions.error(error))
    log(`Failed to fetch devices. Error: ${error}`)
  }
}

export function* upgradeFirmware() {
  try {
    yield put(hubCommandActions.update.start())
    const hubId = yield select(getHubId)
    const authToken = yield select(getToken)

    const response = yield call(api.upgradeFirmware, hubId, authToken)
    yield put(hubCommandActions.update.success(response))
  } catch (error) {
    yield put(hubCommandActions.update.error())
  }
}

export function* joinHubChannel() {
  try {
    const identifier = yield select(getHubIdentifier)
    const deviceChannel = getDeviceChannel(identifier)

    const channel = yield call(joinChannel, deviceChannel)

    // start listening to only event updates (not device updates)
    yield call(
      watchChannel,
      channel,
      Object.values(eventKeys),
      deviceEvent,
      isAnEventUpdate
    )
  } catch (error) {
    log(`Failed to join hub device channel. Error: ${error}`)
  }
}

export function* deleteDevice({ id, history, url, dispatch }) {
  try {
    const authToken = yield select(getToken)
    const unitID = yield select(getUnitId)
    yield put({ type: DELETE_DEVICE_START })
    yield api.deleteDevice(id, authToken)
    yield all(
      dispatch(fetchUnit(unitID)),
      dispatch(fetchDevices(unitID)),
      dispatch(fetchDeviceHistory(unitID))
    )
    yield put(showSnackbar(deleteDeviceSuccess, 'success'))
    history.push(`${url.split('/devices')[0]}/devices`)
  } catch (error) {
    yield put(showSnackbar(failedToDeleteDevice, 'error'))
  }
  yield put({ type: DELETE_DEVICE_FINISH })
}
export function* pushHubCommand({ commandType }, loading = true) {
  try {
    const identifier = yield select(getHubIdentifier)
    const command = yield call(deviceCommands.hub[commandType], identifier)

    if (loading) {
      yield put(hubCommandActions[commandType].start())
    }

    const deviceChannel = getDeviceChannel(identifier)
    yield call(pushMessage, deviceChannel, command)

    if (loading) {
      yield delay(10000)
      yield put(hubCommandActions[commandType].success())
    }
  } catch (error) {
    log(`Failed to push hub message. Error: ${error}`)
    yield put(hubCommandActions[commandType].error())
  }
}

export function* pushThermostatCommand({ commandType, value, identifier, id }) {
  try {
    const hubIdentifier = yield select(getHubIdentifier)
    const deviceChannel = getDeviceChannel(hubIdentifier)

    const command = yield call(
      deviceCommands.thermostat[commandType],
      value,
      identifier
    )

    yield call(pushMessage, deviceChannel, command)
    yield put(updateThermostatOptimistically(id, value))
  } catch (error) {
    log(`Failed to push thermostat command message. Error: ${error}`)
  }
}

export function* pushLockStateCommand({ identifier, id }) {
  try {
    const hubIdentifier = yield select(getHubIdentifier)

    const lockState = yield select(getLockState, id)
    const commandType = lockState === 'locked' ? commands.UNLOCK : commands.LOCK

    const deviceChannel = getDeviceChannel(hubIdentifier)

    const command = yield call(deviceCommands.lock[commandType], identifier)

    yield call(pushMessage, deviceChannel, command)
    yield put(updateLockOptimistically(id, commandType === commands.LOCK))
  } catch (error) {
    yield call(log, `Failed to push lock command message. Error: ${error}`)
  }
}

export function* updateDeviceNameCommand({ id, name, previousName }) {
  try {
    const authToken = yield select(getToken)

    yield put(updateDeviceNameOptimistically(id, name))
    yield call(api.updateDevice, id, name, authToken)
  } catch (error) {
    log(`Failed to edit device name. Error: ${error}`)
    yield put(updateDeviceNameRevert(id, previousName))
  }
}

export function* pushSwitchStateCommand({ id }) {
  let switchState

  try {
    const hubIdentifier = yield select(getHubIdentifier)

    switchState = yield select(getSwitchState, id)

    const commandType =
      switchState === switchStates.OFF ? commands.ON : commands.OFF

    const nextState =
      switchState === switchStates.OFF ? switchStates.ON : switchStates.OFF

    const deviceChannel = getDeviceChannel(hubIdentifier)

    const { identifier } = yield select(getDeviceById, id)

    const command = yield call(deviceCommands.switch[commandType], identifier)

    yield call(pushMessage, deviceChannel, command)
    yield put(updateSwitchOptimistically(id, nextState))
  } catch (error) {
    log(`Failed to push switch command message. Error: `, error)
    if (switchState) {
      yield put(updateSwitchRevert(id, switchState))
    }
  }
}

function* watchFetchDevices() {
  yield takeLatest(fetchDevices().type, getDevices)
}

function* watchRefreshDevices() {
  yield takeLatest(refreshDevices().type, getDevices)
}

function* watchFetchAndRefreshDevicesSuccess() {
  yield takeEvery(
    [fetchDevicesSuccess().type, refreshDevicesSuccess().type],
    joinHubChannel
  )
}

function* watchPushHubCommand() {
  yield takeLatest(PUSH_HUB_COMMAND, pushHubCommand)
}

function* watchPushThermostatCommand() {
  yield takeLatest(PUSH_THERMOSTAT_COMMAND, pushThermostatCommand)
}

function* watchPushLockStateCommand() {
  yield takeLatest(PUSH_LOCK_STATE_COMMAND, pushLockStateCommand)
}

function* watchUpgradeFirmware() {
  yield takeLatest(UPGRADE_FIRMWARE, upgradeFirmware)
}

function* watchPushSwitchStateCommand() {
  yield takeLatest(PUSH_SWITCH_STATE_COMMAND, pushSwitchStateCommand)
}

function* watchUpdateDeviceNameCommand() {
  yield takeLatest(PUSH_DEVICE_NAME_COMMAND, updateDeviceNameCommand)
}

function* watchDeleteDevice() {
  yield takeLatest(DELETE_DEVICE, deleteDevice)
}

export default [
  watchFetchDevices(),
  watchRefreshDevices(),
  watchFetchAndRefreshDevicesSuccess(),
  watchPushHubCommand(),
  watchUpgradeFirmware(),
  watchPushThermostatCommand(),
  watchPushLockStateCommand(),
  watchPushSwitchStateCommand(),
  watchUpdateDeviceNameCommand(),
  watchDeleteDevice()
]
