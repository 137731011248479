import { combineReducers } from 'redux'
import withFetch from '../../../helpers/reducers/withFetch'
import {
  CHAT_CHANNELS,
  CHAT_CHANNELS_BY_TYPE,
  channelChanged
} from '../actions'

const initialState = null

const byTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case channelChanged().type: {
      // remove the channel that was updated and add it back at the top
      // so all the unread channels will be at the top
      const channels = state.filter(
        channel => channel.url !== action.channel.url
      )
      return [action.channel, ...channels]
    }
    default:
      return state
  }
}

export const byType = withFetch(CHAT_CHANNELS_BY_TYPE)(byTypeReducer)
