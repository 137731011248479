import SendBird from 'sendbird'

let sendBird

const CHANNEL_PAGINATION_LIMIT = 50
const LAST_MESSAGES_AMOUNT = 100

export const UNIT_CUSTOM_TYPE = 'UNIT'
export const RESIDENT_CUSTOM_TYPE = 'PROPERTY_MANAGER_RESIDENT'

const init = appId => {
  sendBird = new SendBird({ appId })
}

const connect = (userID, accessToken) => {
  return new Promise((resolve, reject) => {
    sendBird.connect(
      userID,
      accessToken,
      handleResponse(resolve, reject)
    )
  })
}

const disconnect = () => {
  if (sendBird && typeof sendBird.disconnect === 'function')
    sendBird.disconnect()
}

const getChatChannels = ({
  customTypes = [],
  userIds = [],
  name = null,
  includeEmpty = false
} = {}) => {
  const channelListQuery = sendBird.GroupChannel.createMyGroupChannelListQuery()
  channelListQuery.includeEmpty = includeEmpty
  channelListQuery.limit = CHANNEL_PAGINATION_LIMIT
  channelListQuery.channelNameContainsFilter = name
  channelListQuery.customTypesFilter = customTypes
  channelListQuery.userIdsFilter = userIds

  return new Promise((resolve, reject) => {
    channelListQuery.next(handleResponse(resolve, reject, channelListQuery))
  })
}

const getNextChannels = channelListQuery => {
  return new Promise((resolve, reject) => {
    channelListQuery.next(handleResponse(resolve, reject))
  })
}

const getChannelMessages = channel => {
  return new Promise((resolve, reject) => {
    const previousMessageQuery = channel.createPreviousMessageListQuery()
    previousMessageQuery.load(
      LAST_MESSAGES_AMOUNT,
      true,
      handleResponse(resolve, reject)
    )
  })
}

const getUnreadMessageCount = () => {
  return new Promise((resolve, reject) => {
    sendBird.getTotalUnreadChannelCount(function(count, error) {
      if (error) {
        reject(error)
      }
      resolve(count)
    })
  })
}

const getPropertyChannels = filter => {
  if (filter === 'unread') {
    return getChatChannels({ customTypes: [RESIDENT_CUSTOM_TYPE] }).then(
      ({ resource: channels, query }) => {
        return {
          resource: channels.filter(channel => channel.unreadMessageCount > 0),
          query
        }
      }
    )
  }

  if (filter === 'all') {
    return getChatChannels({
      customTypes: [RESIDENT_CUSTOM_TYPE],
      includeEmpty: true
    })
  }

  return getChatChannels({ customTypes: [RESIDENT_CUSTOM_TYPE] })
}

const getUserChannels = (userIds = []) => {
  if (userIds.length) {
    let userChannels = Promise.all(
      userIds.map(userId => {
        return getChatChannels({
          customTypes: [RESIDENT_CUSTOM_TYPE],
          includeEmpty: true,
          userIds: [userId]
        })
      })
    )

    return userChannels.then(channels => {
      return {
        resource: channels
          .filter(({ resource, query }) => resource.length > 0)
          .map(({ resource, query }) => ({
            ...resource[0],
            userId: query.userIdsFilter[0]
          }))
      }
    })
  }

  return getChatChannels({
    customTypes: [RESIDENT_CUSTOM_TYPE],
    includeEmpty: true,
    userIds
  })
}

const getUserChannel = userId => {
  return getChatChannels({
    customTypes: [RESIDENT_CUSTOM_TYPE],
    includeEmpty: true,
    userIds: [userId]
  })
}

const initChannelHandler = (id, callbacks) => {
  const channelHandler = new sendBird.ChannelHandler()
  // list of available callback functions https://docs.sendbird.com/javascript#event_handler
  Object.keys(callbacks).map(key => {
    if (channelHandler[key]) {
      channelHandler[key] = callbacks[key]
    }
  })
  sendBird.addChannelHandler(id, channelHandler)
}

const removeChannelHandler = id => {
  sendBird.removeChannelHandler(id)
}

const handleResponse = (resolve, reject, query) => (resource, error) => {
  if (error) {
    reject(error)
    return
  }
  resolve({ resource, query })
}

export default {
  init,
  connect,
  getUserChannels,
  getUnreadMessageCount,
  getPropertyChannels,
  getUserChannel,
  getNextChannels,
  getChannelMessages,
  initChannelHandler,
  removeChannelHandler,
  disconnect,
  channelTypes: {
    UNIT_CUSTOM_TYPE,
    RESIDENT_CUSTOM_TYPE
  }
}
