// @flow
import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import Grid, { GridItem } from '../Common/Grid'
import Flex, { FlexItem } from '@Common/Flex'
import BaseCard from '@Common/Cards/BaseCard'
import PaginatedTable from '../Common/PaginatedTable'
import Loader from '../Common/Loader'
import { CTAButtonAdd } from '../Common/CTAButtons'
import Avatar from '@Common/Avatar'
import Header from '@Common/Header'

import type { Theme } from '../../theme'
import type { Match } from 'react-router-dom'

import {
  Content,
  Layout,
  Container,
  TableContainer,
  StyledColumn,
  StyledRow,
} from './styles'

type AmenityItem = {
  id: number,
  amenitiesName: string,
  picture: string,
  description: string,
  reservable: string,
}

type Props = {
  items: Array<AmenityItem>,
  theme: Theme,
  match: Match,
  isFetching: boolean,
  hasData: boolean,
  currentPage: number,
  total: number,
  perPage: number,
  fetchPage: (number) => mixed,
  searchAmenities: (term: ?string, page: ?number) => mixed,
}

type State = {
  currentPage: number,
  newItems: Object[],
  showNewAmenityDialog: boolean,
}

class AmenityDashboardLegacy extends React.Component<Props, State> {
  state = {
    currentPage: 0,
    newItems: this.props.items || [],
    showNewAmenityDialog: false,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      const { items } = this.props
      this.setState({
        newItems: items,
      })
    }
  }

  createRows = () => {
    const { newItems } = this.state
    const {
      match: {
        params: { propertyId },
      },
    } = this.props

    let rows = []

    if (newItems) {
      rows = newItems.map(({ id, picture, ...rowData }, rowIndex) =>
        Object.entries(rowData).map(([key, value], index) => {
          return {
            value: (
              <StyledRow firstRow={rowIndex === 0} index={index + 1}>
                {key === 'name' ? (
                  <Link to={`/properties/${propertyId || ''}/amenities/${id}`}>
                    <Flex alignCenter>
                      <Avatar
                        showOverlay={false}
                        picture={picture}
                        size="32px"
                      />
                      <FlexItem paddingLeft="1em">{value}</FlexItem>
                    </Flex>
                  </Link>
                ) : (
                  value
                )}
              </StyledRow>
            ),
          }
        })
      )
    }

    return rows
  }

  changePage = (page) => {
    this.setState({ currentPage: page - 1 })
    const { fetchPage } = this.props
    fetchPage(page)
  }

  openNewAmenityModal = () => {
    this.setState({ showNewAmenityDialog: true })
  }

  cancelNewAmenityModal = () => {
    this.setState({ showNewAmenityDialog: false })
  }

  acceptNewAmenityModal = () => {
    this.setState({ showNewAmenityDialog: false })
  }

  render() {
    const { isFetching, hasData, total, perPage, theme } = this.props

    let {
      match: {
        params: { propertyId },
      },
    } = this.props

    const { currentPage } = this.state
    const showLoader = isFetching || !hasData
    propertyId = (propertyId === null) ? '' : propertyId

    return (
      <React.Fragment>
        <GridItem columnStart={1} columnSpan={3} rowStart={1} rowSpan={12}>
          <Grid>
            <BaseCard
              columnStart={1}
              columnSpan={8}
              rowSpan={12}
              rowStart={1}
              isLeftSideBG={true}
              header={() => <Header alternateFontSize>Amenities</Header>}
              showShadow={false}
              transparentBackground
              content={() => (
                <Content>
                  <b>Create amenity options</b> your residents can view within
                  their
                  <br />
                  resident app.
                  <br />
                  <br />
                  Allow them to reserve an amenity
                  <br />
                  or view its details.
                </Content>
              )}
            />
          </Grid>
        </GridItem>
        <GridItem columnStart={2} columnSpan={11} rowStart={1} rowSpan={12}>
          <Container>
            <Layout>
              <Grid>
                <CTAButtonAdd
                  to={`/properties/${propertyId}/amenities/new`}
                  color={theme.palette.common.white}
                />
                <GridItem
                  columnStart={3}
                  columnSpan={10}
                  rowStart={3}
                  rowSpan={10}>
                  <TableContainer>
                    {showLoader && <Loader />}
                    {!showLoader && (
                      <PaginatedTable
                        total={total || 0}
                        flex={[1, 4, 1]}
                        currentPage={currentPage}
                        rowsPerPage={perPage}
                        columns={[
                          {
                            value: <StyledColumn index={1}>NAME</StyledColumn>,
                          },
                          {
                            value: (
                              <StyledColumn index={2}>DESCRIPTION</StyledColumn>
                            ),
                          },
                          {
                            value: (
                              <StyledColumn index={3}>RESERVABLE</StyledColumn>
                            ),
                          },
                        ]}
                        rows={this.createRows()}
                        changePage={this.changePage}
                      />
                    )}
                  </TableContainer>
                </GridItem>
              </Grid>
            </Layout>
          </Container>
        </GridItem>
      </React.Fragment>
    )
  }
}

export default withRouter(withTheme(AmenityDashboardLegacy))
