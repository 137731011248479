// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import { FlexItem } from '@Common/Flex'
import { FabButton } from '@Common/Button'
import { CommonIcon } from '@icons'
import moment from 'moment'
import ReactSelect, { components } from 'react-select'
import {
  Filters,
  NewTag,
  SelectStyle,
  StyledChip,
  SurveyListAverage,
  SurveyListContent,
  SurveyListHeader,
  SurveyListItem,
  SurveyListItemHead,
  SurveyListSubHead
} from './styles'

import type { Theme } from 'emotion-theming'

type Props = {
  theme: Theme,
  profile: Object,
  chatEnabled: Boolean,
  surveys: Array<Object>,
  type: ?string,
  onClose: Function,
  lastVisit: Number,
  propertyId: number
}

type State = {
  type: ?string,
  sort: { value: string, label: string }
}

const surveyKeyNames = {
  "work_order_completion": "Work Orders",
  "lease_end" : "Pre Lease Renewal",
  "move_in": "Post Move In",
  "amenity": "Amenities" 
}

class SurveyList extends React.Component<Props, State> {
  state = {
    type: this.props.type,
    sort: { value: "date_desc", label: "Date New to Old" }
  }

  handleFilter = filter => {
    this.setState({ type: filter })
  }

  handleSort = sort => {
    this.setState({ sort })
  }

  sortCompare = (a, b) => {
    const { sort: { value: sortType } } = this.state

    switch(sortType) {
      case "date_desc":
      default:
        return Date.parse(a.completed_on) < Date.parse(b.completed_on) ? 1 : -1
      case "date_asc":
        return Date.parse(a.completed_on) > Date.parse(b.completed_on) ? 1 : -1
      case "score_desc":
        return a.survey_result < b.survey_result ? 1 : -1
      case "score_asc":
        return a.survey_result > b.survey_result ? 1 : -1
    }
  }

  render() {
    const { surveys, profile, chatEnabled, lastVisit, propertyId, theme, onClose } = this.props
    const { type, sort } = this.state

    const filteredSurveys = (type ? surveys.filter(survey => survey.type.name === type) : surveys).sort(this.sortCompare)
    const averageScore = filteredSurveys.length ? Math.round(filteredSurveys.reduce((acc, survey) => acc + survey.survey_result, 0) / filteredSurveys.length) : 0

    return <>
      <SurveyListHeader>
        <h1>{profile.name}</h1>
        <FabButton
          onClick={onClose}
          component={() => (
            <CommonIcon
              name="close"
              width="18px"
              height="18px"
              strokeWidth={3}
              stroke={theme.palette.primary.navy.midnight}
            />
          )}
          size="44px"
          background={theme.palette.secondary.yellow.solarFlare}
        />
      </SurveyListHeader>
      <SurveyListContent>
        <Filters>
          <h2>All {type && surveyKeyNames[type]} Reviews</h2>
          <FlexItem>
            {Object.keys(surveyKeyNames).map(filter => (
              <StyledChip
                key={filter}
                label={surveyKeyNames[filter]}
                variant="outlined"
                color={type === filter ? "secondary" : "primary"}
                onClick={() => this.handleFilter(filter)}
              />
            ))}
            <StyledChip label="All Reviews" variant="outlined" color={type ? "primary" : "secondary"} onClick={() => this.handleFilter(null)} />
          </FlexItem>
        </Filters>
        <SurveyListSubHead>
          <SurveyListAverage>{type && surveyKeyNames[type]} Average: <span>{averageScore}/10</span></SurveyListAverage>
          <ReactSelect
            onChange={this.handleSort}
            isSearchable={false}
            options={[
              { value: "date_desc", label: "Date New to Old" },
              { value: "date_asc", label: "Date Old to New" },
              { value: "score_desc", label: "Score High to Low" },
              { value: "score_asc", label: "Score Low to High" }
            ]}
            value={sort}
            styles={SelectStyle}
            components={{
              IndicatorSeparator: () => null,
              SingleValue: ({ children, ...props }) => {
                return (
                  <components.SingleValue {...props}>
                    {'Sort: ' + children}
                  </components.SingleValue>
                );
              },
            }}
          />
        </SurveyListSubHead>
        {filteredSurveys.map(survey => (
          <SurveyListItem key={`survey-${survey.id}`}>
            <div>
              {moment(survey.completed_on).format("LL")}
              {lastVisit && moment(survey.completed_on).valueOf() >= lastVisit && (<NewTag>(NEW)</NewTag>)}
            </div>
            <SurveyListItemHead>
              <FlexItem>
                {[...Array(10)].map((_, index) => (
                  <CommonIcon
                    key={`${survey.id}-${index}`}
                    name={index >= survey.survey_result ? "star" : "star-on"}
                    width="20px"
                    height="20px"
                  />
                ))}
              </FlexItem>
              <FlexItem>{surveyKeyNames[survey.type.name]}</FlexItem>
            </SurveyListItemHead>
            <div>{survey.feedback}</div>
            <div>
              {profile.email && (<a href={`mailto:${profile.email}`}>Email</a>)}
              {chatEnabled && (<span> | <a href={`/properties/${propertyId}/communication/chat/live-chat/users/${profile.userId}`}>Chat</a></span>)}
            </div>
          </SurveyListItem>
        ))}
      </SurveyListContent>
    </>
  }
}

export default withTheme(SurveyList)
