// @flow

import React from 'react'
import Modal from '@material-ui/core/Modal'
import TextField from '@Common/TextField'
import FlexDirection from '@Common/Flex'
import Flex from '@Common/Flex'
import FlexItem from '@Common/Flex'
import { CommonIcon } from '@icons'
import {
  validateEmail,
  validatePhoneNumber
} from '../../../utils/validationUtils'

import {
  IconButton,
  Wrapper,
  Header,
  Content,
  Select,
  CancelButton,
  SubmitButton
} from './styles'

type Props = {
  showModal: boolean,
  onClose: (boolean) => mixed
}

type State = {
  name: string,
  phone: string,
  email: string,
  productFamily: string,
  caseReason: string,
  subject: string,
  description: string,
  origin: string,
  valid: {
    name: boolean,
    phone: boolean,
    email: boolean,
    subject: boolean,
    description: boolean
  }
}

export default class SalesForceModal extends React.Component<Props, State> {
  constructor(props: Object) {
    super(props)
    this.state = {
      name: '',
      phone: '',
      email: '',
      productFamily: '',
      caseReason: '',
      subject: '',
      description: '',
      origin: 'Admin Web',
      valid: {
        name: false,
        phone: false,
        email: false,
        subject: false,
        description: false
      }
    }
  }

  clearFields = () => {
    this.setState({
      name: '',
      phone: '',
      email: '',
      productFamily: '',
      caseReason: '',
      subject: '',
      description: '',
      origin: 'Engage Admin Site',
      valid: {
        name: false,
        phone: false,
        email: false,
        subject: false,
        description: false
      }
    })
  }

  cancel = () => {
    this.clearFields()
    this.props.onClose(false)
  }

  submit = () => {
    this.submitFormToSalesForce()
    this.clearFields()
    this.props.onClose(true)
  }

  isFormValid = () => {
    const { productFamily, caseReason, valid } = this.state

    return valid.description && valid.email && valid.name && valid.phone && valid.subject &&
      productFamily.length > 0 && caseReason.length > 0
  }

  handleTextChange = ({ currentTarget: { id, value } }: SyntheticEvent<HTMLInputElement>) => {
    this.setState(oldState => {
      const { valid } = oldState
      return {
        ...oldState,
        valid: { ...valid, [id]: this.isValidFieldValue(id, value) },
        [id]: value
      }
    })
  }

  isValidFieldValue = (field: string, value: string) => {
    switch (field) {
      case 'email':
        return validateEmail(value)
      case 'phone':
        return validatePhoneNumber(value)
      default:
        return value && value.trim().length > 0
    }
  }

  handleProductChange = ({ value }: {value: string}) => {
    this.setState({ productFamily: value })
  }

  handleCaseReasonChange = ({ value }: {value: string}) => {
    this.setState({ caseReason: value })
  }

  submitFormToSalesForce = () => {
    let debugEmail = window._env_.REACT_APP_SF_CASE_DEBUG_EMAIL
    let url: string = ''
    let orgId: string = ''
    if (window._env_.REACT_APP_SF_CASE_URL) {
      url = window._env_.REACT_APP_SF_CASE_URL
    } else {
      return
    }

    if (window._env_.REACT_APP_SF_CASE_ORGID) {
      orgId = window._env_.REACT_APP_SF_CASE_ORGID
    }

    let formBody = []

    formBody.push(encodeURIComponent('orgid') + '=' + encodeURIComponent(orgId))
    formBody.push(encodeURIComponent('name') + '=' + encodeURIComponent(this.state.name))
    formBody.push(encodeURIComponent('email') + '=' + encodeURIComponent(this.state.email))
    formBody.push(encodeURIComponent('phone') + '=' + encodeURIComponent(this.state.phone))
    formBody.push(encodeURIComponent('00Nf400000TaUbf') + '=' + encodeURIComponent(this.state.productFamily))
    formBody.push(encodeURIComponent('00Nf400000TaUaw') + '=' + encodeURIComponent(this.state.caseReason))
    formBody.push(encodeURIComponent('description') + '=' + encodeURIComponent(this.state.description))
    formBody.push(encodeURIComponent('00Nf400000UWPdA') + '=' + encodeURIComponent(this.state.origin))
    formBody.push('external=1')
    if (debugEmail && debugEmail.length > 0) {
      formBody.push('debug=1')
      formBody.push(encodeURIComponent('debugEmail') + '=' + encodeURIComponent(debugEmail))
      formBody.push(encodeURIComponent('subject') + '=' + encodeURIComponent(`Engage Test - ${this.state.subject}`))
    } else {
      formBody.push(encodeURIComponent('subject') + '=' + encodeURIComponent(this.state.subject))
    }
    formBody = formBody.join('&')

    fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: formBody
    })
  }

  productOptions = [
    { value: 'Engage', label: 'Engage' },
    { value: 'Smart', label: 'Smart' }
  ]

  caseReasonOptions = [
    { value: '', label: '--None--' },
    { value: 'Account Creation', label: 'Account Creation' },
    { value: 'Accounting Software', label: 'Accounting Software' },
    { value: 'Account Inquiries and Updates', label: 'Account Inquiries and Updates' },
    { value: 'Device Malfunction', label: 'Device Malfunction' },
    { value: 'Device Question', label: 'Device Question' },
    { value: 'Faulty Installation', label: 'Faulty Installation' },
    { value: 'Feature Question', label: 'Feature Question' },
    { value: 'Feature Request', label: 'Feature Request' },
    { value: 'Features and Products', label: 'Features and Products' },
    { value: 'Installation Support', label: 'Installation Support' },
    { value: 'Integration Error', label: 'Integration Error' },
    { value: 'Integration Issues', label: 'Integration Issues' },
    { value: 'Invoices, Statements and Reports', label: 'Invoices, Statements and Reports' },
    { value: 'Monthly Billing and Statements', label: 'Monthly Billing and Statements' },
    { value: 'My Account', label: 'My Account' },
    { value: 'Payment and Fee Issue', label: 'Payment and Fee Issue' },
    { value: 'Payment or Fee Issue', label: 'Payment or Fee Issue' },
    { value: 'Privacy Policy', label: 'Privacy Policy' },
    { value: 'Refunds, Voids and Check Adjustments', label: 'Refunds, Voids and Check Adjustments' },
    { value: 'Registration', label: 'Registration' },
    { value: 'Resident Utility Charges', label: 'Resident Utility Charges' },
    { value: 'Smart Home Bug', label: 'Smart Home Bug' },
    { value: 'Software Bug', label: 'Software Bug' },
    { value: 'Submeter Reads', label: 'Submeter Reads' },
    { value: 'Submeter Repair Services', label: 'Submeter Repair Services' },
    { value: 'Submeter Reports', label: 'Submeter Reports' },
    { value: 'Submeter Usage', label: 'Submeter Usage' },
    { value: 'Transaction Research', label: 'Transaction Research' },
    { value: 'UEMOPs', label: 'UEMOPs' },
    { value: 'Utility Account Inquiries', label: 'Utility Account Inquiries' },
    { value: 'Utility Billing Charges', label: 'Utility Billing Charges' },
    { value: 'Utility Bill Submissions', label: 'Utility Bill Submissions' },
    { value: 'White Labeling', label: 'White Labeling' },
    { value: 'Accounting Software and Integrations (Engage)', label: 'Accounting Software and Integrations (Engage)' },
    { value: 'Accounting Software and Integrations (Payments)', label: 'Accounting Software and Integrations(Payments)' },
    { value: 'Accounting Software and Integrations (Smart)', label: 'Accounting Software and Integrations (Smart)' },
    { value: 'Accounting Software and Integrations (Submetering)', label: 'Accounting Software and Integrations(Submetering)' },
    { value: 'Accounting Software and Integrations (UEM)', label: 'Accounting Software and Integrations (UEM)' },
    { value: 'Accounts and Users (Billing)', label: 'Accounts and Users (Billing)' },
    { value: 'Accounts and Users (Engage)', label: 'Accounts and Users (Engage)' },
    { value: 'Accounts and Users (Payments)', label: 'Accounts and Users (Payments)' },
    { value: 'Accounts and Users (Smart)', label: 'Accounts and Users (Smart)' },
    { value: 'Accounts and Users (Submetering)', label: 'Accounts and Users (Submetering)' },
    { value: 'Accounts and Users (UEM)', label: 'Accounts and Users (UEM)' },
    { value: 'Account Updates', label: 'Account Updates' },
    { value: 'Billing Statements', label: 'Billing Statements' },
    { value: 'Client Notifications', label: 'Client Notifications' },
    { value: 'Features and Products (Payments)', label: 'Features and Products (Payments)' },
    { value: 'Hardware Support', label: 'Hardware Support' },
    { value: 'Hardware Support (Submetering)', label: 'Hardware Support (Submetering)' },
    { value: 'My Account (Billing)', label: 'My Account (Billing)' },
    { value: 'My Account (Payments)', label: 'My Account (Payments)' },
    { value: 'My Account (Smart)', label: 'My Account (Smart)' },
    { value: 'My Account (Submetering)', label: 'My Account (Submetering)' },
    { value: 'My Account (UEM)', label: 'My Account (UEM)' },
    { value: 'Payments and Bank Accounts', label: 'Payments and Bank Accounts' },
    { value: 'Payments and Bank Accounts (Billing)', label: 'Payments and Bank Accounts (Billing)' },
    { value: 'Payments and Bank Accounts (Payments)', label: 'Payments and Bank Accounts (Payments)' },
    { value: 'Payments and Bank Accounts (Smart)', label: 'Payments and Bank Accounts (Smart)' },
    { value: 'Payments and Bank Accounts (UEM)', label: 'Payments and Bank Accounts (UEM)' },
    { value: 'Report Request', label: 'Report Request' },
    { value: 'Research Request', label: 'Research Request' },
    { value: 'Resident Billing and Utility Expense Management (Billing)', label: 'Resident Billing and UtilityExpense Management (Billing)' },
    { value: 'Resident Billing and Utility Expense Management (Engage)', label: 'Resident Billing and UtilityExpense Management (Engage)' },
    { value: 'Resident Billing and Utility Expense Management (Submetering)', label: 'Resident Billing and Utility Expense Management (Submetering)' },
    { value: 'Resident Billing and Utility Expense Management (UEM)', label: 'Resident Billing and Utility Expense Management (UEM)' },
    { value: 'Utility Billing Charges / Usage', label: 'Utility Billing Charges / Usage' },
    { value: 'SRE Tickets', label: 'SRE Tickets' },
    { value: 'Resident Billing Request', label: 'Resident Billing Request' },
    { value: 'Utility Account Management', label: 'Utility Account Management' }
  ]

  render() {
    const { valid } = this.state
    return (
      <Modal onClose={this.cancel} open={this.props.showModal}>
        <Wrapper>
          <Header justifySpaceBetween alignCenter>
            <div>Submit a Case</div>
            <IconButton onClick={this.cancel}>
              <CommonIcon
                name='close'
                height='12px'
                width='12px'
                fill='#fff'
                strokeWidth={3}
                stroke='#fff'
              />
            </IconButton>
          </Header>
          <Content>
            <Flex paddingTop='10px'>Your Name</Flex>
            <TextField
              fullWidth
              textAlign='left'
              onChange={this.handleTextChange}
              error={!valid.name}
              id='name' />

            <Flex paddingTop='10px'>Email</Flex>
            <TextField
              fullWidth
              textAlign='left'
              onChange={this.handleTextChange}
              error={!valid.email}
              id='email' />

            <Flex paddingTop='10px'>Phone</Flex>
            <TextField
              fullWidth
              textAlign='left'
              onChange={this.handleTextChange}
              error={!valid.phone}
              id='phone' />

            <Flex paddingTop='10px'>Product Family Name</Flex>
            <Select
              id='productFamily'
              onChange={this.handleProductChange}
              options={this.productOptions} />

            <Flex paddingTop='10px'>Case Reason</Flex>
            <Select
              id='caseReason'
              onChange={this.handleCaseReasonChange}
              options={this.caseReasonOptions} />

            <Flex paddingTop='10px'>Subject</Flex>
            <TextField
              fullWidth
              textAlign='left'
              onChange={this.handleTextChange}
              error={!valid.subject}
              id='subject' />

            <Flex paddingTop='10px'>Description</Flex>
            <TextField
              fullWidth
              multiline
              rows={'4'}
              textAlign='left'
              id='description'
              error={!valid.description}
              onChange={this.handleTextChange} />
            <FlexDirection fullWidth>
              <FlexItem displayFlex alignItemsLeft flex={0.8}>
                <CancelButton onClick={this.cancel}>Cancel</CancelButton>
              </FlexItem>
              <FlexItem displayFlex alignItemsRight flex={0.2}>
                <SubmitButton
                  onClick={this.submit}
                  disabled={!this.isFormValid()}>Submit</SubmitButton>
              </FlexItem>
            </FlexDirection>
          </Content>
        </Wrapper>
      </Modal>
    )
  }
}
