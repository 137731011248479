import { connect } from 'react-redux'
import {
  fetchPet,
  fetchPetsColors,
  fetchPetsSizes,
  fetchPetsStatuses,
  fetchPetsTypes,
  updatePet
} from 'zego-shared/store/registration/pets'
import { fetchPropertyUsersSummary } from 'zego-shared/store/users/propertyUsersSummary/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import {
  hasPetsSupportedData,
  hasPet,
  getPet,
  getPetsColors,
  getPetsSizes,
  getPetsStatuses,
  getPetsTypes
} from 'zego-shared/store/registration/pets/selectors'
import { getAllPropertyResidents } from 'zego-shared/store/users/selectors'

import Pet from './Pet'

const fetchingSelector = createIsFetchingSelector([
  'pet',
  'petscolors',
  'petssizes',
  'petsstatuses',
  'petstypes'
])

const mapStateToProps = (state, { match: { url } }) => {
  const addMode = url.indexOf('/new') > -1
  const defaultItem = {
    color: [],
    name: '',
    weight: 0,
    breed: '',
    size: 'medium',
    type: 'others',
    status: 'pending'
  }
  return {
    item: addMode ? defaultItem : getPet(state),
    isFetching: fetchingSelector(state),
    hasData: addMode
      ? hasPetsSupportedData(state)
      : hasPet(state) && hasPetsSupportedData(state),
    residents: getAllPropertyResidents(state),
    addMode,
    petTypes: getPetsTypes(state),
    petSizes: getPetsSizes(state),
    petColors: getPetsColors(state),
    petStatuses: getPetsStatuses(state)
  }
}

const mapDispatchToProps = (dispatch, { history, match: { url, params } }) => {
  return {
    fetchData: () => {
      params.petId !== 'new' && dispatch(fetchPet(params.petId))
      dispatch(fetchPropertyUsersSummary(params.propertyId))
      dispatch(fetchPetsColors(params.propertyId))
      dispatch(fetchPetsSizes(params.propertyId))
      dispatch(fetchPetsStatuses(params.propertyId))
      dispatch(fetchPetsTypes(params.propertyId))
    },
    updatePet: update => {
      dispatch(updatePet(update, history, url, params.petId === 'new'))
    }
  }
}

const PetWithFetchData = withFetchData(Pet)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PetWithFetchData)
