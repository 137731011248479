// @flow
import React, { PureComponent } from 'react'
import { withTheme } from 'emotion-theming'
import { Link } from 'react-router-dom'
import PaginatedTable from '../../../Common/PaginatedTable'
import Avatar from '@Common/Avatar'
import {
  HeaderStyled,
  UserProfileTableStyled,
  CellStyled,
  ViewRegistrationLinkStyled
} from './styles'

type ItemId = string | number
type TableData = { name: string, imageUrl?: string, id: ItemId }
type Props = {
  keys: string[],
  headers: string[],
  tableData: TableData[],
  total: number,
  registrationUrl: string,
  rowsPerPage?: number,
  vehiclesTable: string,
  visitorsTable: string,
  columnToHighlight?: number,
  currentTab: string,
  propertyId: number
}

class UserProfileTable extends PureComponent<Props> {
  getColumns(): Object[] {
    const { visitorsTable, vehiclesTable, headers } = this.props
    return headers.map(
      (header, index): Object => {
        return {
          value: (
            <HeaderStyled
              visitorsTable={visitorsTable}
              vehiclesTable={vehiclesTable}
              isFirst={index === 0}>
              {header}
            </HeaderStyled>
          )
        }
      }
    )
  }

  getRows(): Array<Array<Object>> {
    const { tableData } = this.props
    if (Array.isArray(tableData) && tableData.length) {
      const rows = tableData.map(this.getRowItem)
      const emptyRows = this.createEmptyRows()
      return [emptyRows].concat(rows, [emptyRows])
    }
    return []
  }

  getAvatar(image: string) {
    return image ? <Avatar picture={image} size={'2em'} hideShadow /> : null
  }

  getRowItem = (rowData: Object): Object[] => {
    const { keys } = this.props

    const image = this.getAvatar(rowData.imageUrl)

    const rowItem = keys.map(
      (key: string, index: number): Object => {
        const isFirstItem = index === 0
        if (isFirstItem && image) {
          return {
            value: (
              <CellStyled isFirst={isFirstItem} hasImage={image !== null}>
                {image}
                {rowData['name']}
              </CellStyled>
            )
          }
        }
        return {
          value: (
            <CellStyled isFirst={isFirstItem}>
              {isFirstItem ? rowData['name'] : rowData[key]}
            </CellStyled>
          )
        }
      }
    )
    rowItem.push({
      value: this.getViewRegistrationLink(rowData.id)
    })
    return rowItem
  }

  createEmptyRows(): Array<Object> {
    const rows = []
    for (let index = 0; index <= this.props.headers.length; index++) {
      rows.push({ value: <CellStyled isFirst={index === 0} /> })
    }
    return rows
  }

  getViewRegistrationLink(itemId: ItemId) {
    const { currentTab, propertyId } = this.props
    return (
      <Link
        to={`/properties/${propertyId}/registration/${currentTab}/${itemId}`}>
        <ViewRegistrationLinkStyled>
          view registration
        </ViewRegistrationLinkStyled>
      </Link>
    )
  }

  render() {
    const { total, rowsPerPage = 5, columnToHighlight } = this.props
    return (
      <UserProfileTableStyled columnToHighlight={columnToHighlight}>
        <PaginatedTable
          total={total}
          columns={this.getColumns()}
          rowsPerPage={rowsPerPage}
          rows={this.getRows()}
        />
      </UserProfileTableStyled>
    )
  }
}

export default withTheme(UserProfileTable)
