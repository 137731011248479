import styled from '@emotion/styled'
import MaterialTypography from '@material-ui/core/Typography'
import MaterialButton from '@material-ui/core/Button'
import BaseFooterLink from '@Common/Cards/CardFooterLink'
import { Underline } from '@Common/Cards/BaseCard/styles'

export const Description = styled('div')`
  line-height: 28px;
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  margin-top: 80px;
`

export const Content = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.lg};
  position: absolute;
  top: 160px;
`

export const Typography = styled(MaterialTypography)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  color: ${({ theme }) => theme.palette.text.grey};
  min-height: 150px;
  margin-top: 50px;
  line-height: 24px;
`

export const FooterLink = styled(BaseFooterLink)`
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  font-size: ${({ theme }) => theme.custom.font.size.md};

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.text.black1};

    &:hover {
      color: ${({ theme }) => theme.palette.neutrals.deepGray};
    }
  }

  &:hover {
    border: ${({ theme, disabled }) =>
      !disabled && `3px solid ${theme.palette.primary.green.seeFoam}`};
  }

  ${({ disabled, theme }) =>
    !disabled &&
    `
    text-align: center;
    line-height: 30px;
    width: 95px;
    height: 35px;
    border: 3px solid ${theme.palette.secondary.main1};
    margin-bottom: 20px;
  `}

  a:hover {
    ${({ disabled }) => disabled && `cursor: default`}
    color: ${({ theme }) => theme.palette.neutrals.deepGray};
  }

  ${({ disabled, theme }) =>
    disabled &&
    `font-size: ${theme.custom.font.size.mdlg};
    text-align: right;
    span {
      font-size: ${theme.custom.font.size.sm};
    }
    `}
`

export const Container = styled('div')`
  width: 100%;
`

export const CountDescription = styled('div')`
  color: ${({ theme }) => theme.palette.grey.main};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  margin-top: 0.3em;
  white-space: nowrap;
`

export const UnderLine = styled(Underline)`
  margin-left: 10px;
`

export const Loading = styled('div')`
  grid-column: 5 / span 7;
  grid-row: 1 / span 10;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FooterDescription = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.grey.main};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
`

export const UnderlayBG = styled('div')`
  width: 1075px;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.neutrals.coolGray};
  position: absolute;
  right: -50px;
`

export const BackButtonStyled = styled(MaterialButton)`
  background-color: ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
  color: ${({ theme }) => theme.palette.primary.dark};
  width: 3em;
  height: 3em;
  box-shadow: none;

  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.secondary.yellow.solarFlare};
  }
`
