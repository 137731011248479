import { call, select, put, takeLatest } from 'redux-saga/effects'
import { getToken, getUserId } from '../../../authorization/selectors'
import {
  fetchUserProfile as fetchUserProfileAction,
  fetchUserProfileStart,
  fetchUserProfileSuccess,
  fetchUserProfileError,
  updateUserLeaseInterestStatusSuccess,
  updateUserLeaseInterestStatusFailure,
  uploadProfileImageSuccess,
  uploadProfileImageFailure,
  generateLockCodeSuccess,
  generateLockCodeFailure,
  deleteLockCodeSuccess,
  deleteLockCodeFailure,
  UPLOAD_PROFILE_IMAGE,
  GENERATE_LOCK_CODE,
  DELETE_LOCK_CODE,
  GET_USER_BALANCE,
  getUserBalanceSuccess,
  getUserBalanceFailure,
  getUserLeaseInterestStatusSuccess,
  getUserLeaseInterestStatusFailure,
  fetchUserVisitorsAction,
  fetchUserVisitorsStart,
  fetchUserVisitorsSuccess,
  fetchUserVisitorsError,
  fetchUserPetsSuccess,
  fetchUserPetsError,
  fetchUserPets,
  fetchUserPetsStart,
  fetchUserVehiclesAction,
  fetchUserVehiclesStart,
  fetchUserVehiclesSuccess,
  fetchUserVehiclesError,
  GET_USER_LEASE_INTEREST_STATUS,
  UPDATE_USER_LEASE_INTEREST_STATUS
} from '../actions'
import { showSnackbar } from '../../../snackbar/actions'

import api from '../../../../utils/api'
import log from '../../../../utils/logger'
import { failedToCreateUserLockCode } from '../../../../utils/messages'

const getUserProfileActions = isRefreshing => ({
  start: isRefreshing ? refreshUserProfileStart : fetchUserProfileStart,
  success: isRefreshing ? refreshUserProfileSuccess : fetchUserProfileSuccess,
  error: isRefreshing ? refreshUserProfileError : fetchUserProfileError
})

export function* fetchUser(isRefreshing, { userId }) {
  const actions = getUserProfileActions(isRefreshing)
  const id = userId ? userId : yield select(getUserId)

  try {
    yield put(actions.start())
    const authToken = yield select(getToken)

    yield put(fetchUserProfileStart())
    const response = yield call(api.getUser, id, authToken)
    yield put(fetchUserProfileSuccess(response))
  } catch (error) {
    yield put(fetchUserProfileError())
    log(`Failed to fetch user. Error: ${error}`)
  }
}

export function* getUserLeaseInterestStatus({ userId }) {
  try {
    const authToken = yield select(getToken)
    const response = yield call(api.getLeaseInterestStatus, userId, authToken)
    yield put(getUserLeaseInterestStatusSuccess(response))
  } catch (error) {
    yield put(getUserLeaseInterestStatusFailure())
    log(`Failed to get user lease interest status`)
  }
}

export function* updateUserLeaseInterestStatus({ userId, status }) {
  try {
    const authToken = yield select(getToken)
    const response = yield call(
      api.updateLeaseInterestStatus,
      userId,
      status,
      authToken
    )
    yield put(updateUserLeaseInterestStatusSuccess(response))
  } catch (error) {
    yield put(updateUserLeaseInterestStatusFailure())
    log(`Failed to update user lease interest status`)
  }
}

export function* getUserBalance({ userId }) {
  try {
    const authToken = yield select(getToken)
    const response = yield call(api.getPaymentBalance, userId, authToken)
    yield put(getUserBalanceSuccess(response))
  } catch (error) {
    yield put(getUserBalanceFailure())
    log(`Failed to get user balance`)
  }
}

export function* uploadProfileImage({ userId, file }) {
  try {
    const authToken = yield select(getToken)

    const response = yield call(api.uploadProfileImage, userId, file, authToken)
    yield put(uploadProfileImageSuccess(response))
  } catch (error) {
    yield put(uploadProfileImageFailure())
    log(`Failed to upload`)
  }
}

export function* generateLockCodeForUser({ userId }) {
  try {
    const authToken = yield select(getToken)
    const response = yield call(api.generateLockCode, userId, authToken)
    yield put(generateLockCodeSuccess(response))
  } catch (error) {
    yield put(showSnackbar(failedToCreateUserLockCode(error.message), 'error'))
    yield put(generateLockCodeFailure())
    log(`Failed to generate lock code`)
  }
}

export function* deleteLockCodeForUser({ userId }) {
  try {
    const authToken = yield select(getToken)

    const response = yield call(api.deleteLockCode, userId, authToken)
    yield put(deleteLockCodeSuccess(response))
  } catch (error) {
    yield put(deleteLockCodeFailure())
    log(`Failed to Delete lock code`)
  }
}

export function* getAllVisitorsForResident({ userId }) {
  try {
    yield put(fetchUserVisitorsStart())
    const authToken = yield select(getToken)

    const response = yield call(api.getVisitorsForResident, userId, authToken)
    yield put(fetchUserVisitorsSuccess(response))
  } catch (error) {
    yield put(fetchUserVisitorsError())
    log(`Failed to fetch visitors`)
  }
}

export function* getUserPets({ userId }) {
  try {
    yield put(fetchUserPetsStart())
    const authToken = yield select(getToken)
    const response = yield call(api.getUserPets, userId, authToken)
    yield put(fetchUserPetsSuccess(response))
  } catch (error) {
    yield put(fetchUserPetsError())
    log(`Failed to get user pets`)
  }
}

export function* getAllVehiclesForResident({ userId }) {
  try {
    yield put(fetchUserVehiclesStart())
    const authToken = yield select(getToken)
    const response = yield call(api.getVehiclesForResident, userId, authToken)
    yield put(fetchUserVehiclesSuccess(response))
  } catch (error) {
    yield put(fetchUserVehiclesError())
    log(`Failed to fetch visitors`)
  }
}

function* watchFetchUser() {
  yield takeLatest(fetchUserProfileAction().type, fetchUser, false)
}

function* watchGetUserBalance() {
  yield takeLatest(GET_USER_BALANCE, getUserBalance)
}

function* watchGetLeaseInterestStatus() {
  yield takeLatest(GET_USER_LEASE_INTEREST_STATUS, getUserLeaseInterestStatus)
}

function* watchUpdateLeaseInterestStatus() {
  yield takeLatest(
    UPDATE_USER_LEASE_INTEREST_STATUS,
    updateUserLeaseInterestStatus
  )
}

function* watchUploadProfileImage() {
  yield takeLatest(UPLOAD_PROFILE_IMAGE, uploadProfileImage)
}

function* watchGenerateLockCodeForUser() {
  yield takeLatest(GENERATE_LOCK_CODE, generateLockCodeForUser)
}

function* WatchDeleteLockCodeForUser() {
  yield takeLatest(DELETE_LOCK_CODE, deleteLockCodeForUser)
}

function* WatchGetAllVisitorsForResident() {
  yield takeLatest(fetchUserVisitorsAction().type, getAllVisitorsForResident)
}

function* watchGetUserPets() {
  yield takeLatest(fetchUserPets().type, getUserPets)
}

function* WatchGetAllVehiclesForResident() {
  yield takeLatest(fetchUserVehiclesAction().type, getAllVehiclesForResident)
}

export default [
  watchFetchUser(),
  watchUploadProfileImage(),
  watchGenerateLockCodeForUser(),
  WatchDeleteLockCodeForUser(),
  watchGetUserBalance(),
  WatchGetAllVisitorsForResident(),
  watchGetUserPets(),
  WatchGetAllVehiclesForResident(),
  watchGetLeaseInterestStatus(),
  watchUpdateLeaseInterestStatus()
]
