// @flow

import React from 'react'
import Flex from '@Common/Flex'
import { Logo, SocialIcon } from '@icons'
import {
  Container,
  AppName,
  DownloadMessage,
  Content,
  GoToLogin
} from './styles'

export default class WelcomeResident extends React.Component<{}> {
  render() {
    return (
      <Container
        direction="column"
        fullWidth
        fullHeight
        paddingTop="1em"
        paddingLeft="2em">
        <Logo name="logo_icon" fill="#029385" width="55px" height="55px" />
        <Content direction="column" justifyCenter alignCenter>
          <Flex justifyCenter alignCenter>
            <Logo name="logo_full" fill="#000" />
            <AppName>/ RESIDENT APP</AppName>
          </Flex>
          <DownloadMessage>
            Download the resident app to login and access Zego features like
            chatting with property <br />
            managers, securely pay rent in app, logging a maintenance work order
            and much more
          </DownloadMessage>
          <Flex justifyCenter alignCenter paddingTop="11em">
            <a href="https://play.google.com/store/apps/details?id=io.zego&hl=en_US">
              <SocialIcon name="google_play" height="72px" />
            </a>
            <a href="https://itunes.apple.com/us/app/zego-resident-app/id1313996056">
              <SocialIcon name="app_store" height="50px" />
            </a>
          </Flex>
        </Content>
        <GoToLogin justifyCenter alignCenter>
          <a href="https://my.zego.io">Go To Login</a>
        </GoToLogin>
      </Container>
    )
  }
}
