import styled from '@emotion/styled'

export const Window = styled('div')`
  flex: 1;
  display: grid;
  position: relative;
  height: 100%;
  grid-template-areas:
    'channelDetails'
    'messagelist'
    'inputbox'
    'toolbar';
  grid-template-rows: 0.36fr 2fr 0.5fr 0.25fr;

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  &:after {
    content: ' ';
    background: ${({ theme }) => theme.palette.primary.green.hintMint};
    width: 100%;
    height: 32%;
    right: 10%;
    z-index: -159;
    position: absolute;
  }

  &:before {
    content: ' ';
    top: 0%;
    background: ${({ theme }) => theme.palette.primary.green.mint};
    box-shadow: -1px -2px 2px 0 rgba(0, 0, 0, 0.15);
    width: 90%;
    height: 38%;
    right: 0;
    z-index: 1;
    position: absolute;
    display: block;
    left: -40px;
  }

  .chat-message-area {
    border: none;
    grid-area: inputbox;
    padding: 1em;
    border-top: 1px solid ${({ theme }) => theme.palette.neutrals.warmGray};
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    resize: none;

    @media only screen and (min-width: 2000px) {
      font-size: 120%;
    }

    &:focus {
      outline: none;
    }
  }
`

export const ToolBar = styled('div')`
  grid-area: toolbar;
  border-top: 1px solid ${({ theme }) => theme.palette.neutrals.warmGray};
  display: flex;
  -webkit-flex: 1;
  justify-content: space-between;
  padding-bottom: 60px;
  align-items: center;
  & > * {
    align-self: flex-end;
  }
`

export const MessagesFrame = styled('div')`
  grid-area: messagelist;
  width: 103%;
  display: grid;
  grid-template-rows: 2.5fr 0.5fr 0.25fr;
  padding: 3em;
  overflow-y: scroll;
  margin-top: 0.3em;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: -1px -2px 2px 0 rgba(0, 0, 0, 0.15);
  z-index: 1;
  & > div {
    display: flex;
    justify-content: flex-end;
  }
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${({ theme }) => theme.palette.neutrals.deepGray};
  }
`

export const Link = styled('a')`
  cursor: pointer;
  margin-right: 1em;
  color: ${({ theme, selected }) =>
    selected ? theme.palette.grey.main : theme.palette.neutrals.deepGray};
  text-transform: uppercase;
`

export const MessageContainer = styled('div')`
  display: flex;
  max-width: 50%;
  ${({ senderIsCurrentUser }) =>
    senderIsCurrentUser
      ? `justify-content: flex-end;
    margin-left: auto;
    `
      : `
    flex-direction: row-reverse;
    justify-content: flex-end;
    `};
  align-items: flex-end;
`

export const Message = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  margin-top: ${props => (props.lastSenderIsCurrentSender ? `-0.5em` : `1em`)};
  display: flex;
  flex-direction: column;

  a {
    text-decoration: underline
  }

  & > span {
    text-align: ${props => (props.senderIsCurrentUser ? `right` : `left`)};
    color: ${({ theme }) => theme.palette.grey.main};
  }

  ${({ senderIsCurrentUser }) =>
    senderIsCurrentUser
      ? `
  margin-right: 1em;
  `
      : `
  margin-left: 1em;
`};
`

export const MessageTime = styled('div')`
  margin-top: auto;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.grey.main};
`

export const MessageBubble = styled('div')`
  white-space: pre-wrap;
  margin-top: 1em;
  margin: ${props =>
    props.senderIsCurrentUser ? `1em 0em 0em 1em` : `1em 1em 0em 0em`};
  background: ${props =>
    !props.senderIsCurrentUser
      ? `${props.theme.palette.neutrals.coolGray}`
      : `${props.theme.palette.primary.green.hintMint}`};
  border-radius: ${props =>
    props.senderIsCurrentUser
      ? '32px 0px 32px 39.5px'
      : '0px 39.5px 39.5px 32px'};
  padding: 1em 2em;

  img {
    max-width: 20em;
    max-height: 20em;
    display: block;
  }
`

export const UploadIcon = styled('label')`
  margin-right: 1em;
  cursor: pointer;
  margin-top: 20px;

  svg {
    margin-bottom: -5px;
  }
`
export const UploadButton = styled('input')`
  display: none;
`

export const HrWithText = styled('div')`
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-right: 30%;
  margin-left: 30%;
  color: ${({ theme }) => theme.palette.grey.main};

  & > span {
    position: relative;
    display: inline-block;
  }

  & > span:before,
  & > span:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 200px;
    height: 1px;
    background: ${props => props.theme.palette.neutrals.deepGray};
  }

  & > span:before {
    right: 100%;
    margin-right: 15px;
  }

  & > span:after {
    left: 100%;
    margin-left: 15px;
  }
`

export const Files = styled('div')`
  width: 100%;
  position: absolute;
  bottom: 0.5em;
  left: 0;
  padding: 0.5em;
  font-size: ${({ theme }) => theme.custom.font.size.md};

  span {
    background: ${({ theme }) => theme.palette.neutrals.coolGray};
    padding: 0.5em;
    margin-right: 0.3em;
    border-radius: 2em;
  }

  svg {
    margin-right: 0.8em;
    cursor: pointer;
  }
`

export const Block = styled('div')`
  display: block;
`

export const Pin = styled('div')`
  position: absolute;
  right: -5px;
  top: 13%;
  cursor: pointer;
`
