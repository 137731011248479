// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import UserProfileTable from '../UserProfileTable'
import Loader from '../../../Common/Loader'

type Props = {
  isFetching: boolean,
  count: number,
  vehicles: Object[],
  match: { params: { propertyId: string } } 
}

export class Vehicles extends React.Component<Props, {}> {
  getHeaders = (count: number) => {
    return [`${count} Vehicles`, 'MAKE', 'MODEL', 'YEAR', 'COLOR', 'STATE']
  }

  getKeys = () => {
    return ['name', 'make', 'model', 'year', 'color', 'state']
  }

  render() {
    const { isFetching, vehicles, count, match: { params: { propertyId } } } = this.props
    if (isFetching) return <Loader />

    return (
      <React.Fragment>
        <UserProfileTable
          total={count}
          headers={this.getHeaders(count)}
          rowsPerPage={count}
          tableData={vehicles}
          keys={this.getKeys()}
          vehiclesTable={'true'}
          currentTab={'vehicles'}
          propertyId={propertyId}
        />
      </React.Fragment>
    )
  }
}

export default withTheme(Vehicles)
