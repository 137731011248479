import localForage from 'localforage'

const appPersistConfig = {
  key: 'zego_data',
  storage: localForage,
  whitelist: ['authorization', 'settings', 'select'],
  timeout: 0
}

export default appPersistConfig
