import styled from '@emotion/styled'
import MaterialListItem from '@material-ui/core/ListItem'

export const Content = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  padding: 0 0 50px 0;
  line-height: 24px;
  ${({ theme }) => {
    return `
    ${Image} {
      width: 70%;
      padding-top: 35%;
      background: ${theme.palette.neutrals.mediumGray};
      top: 25%;
      right:0;
      clip-path: circle(30% at 80%);
    `
  }};
`

export const ListItem = styled(MaterialListItem)`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  padding-left: 10px;
`
