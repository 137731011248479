// @flow

import React from 'react'
import SetupStep from './SetupStep'
import { SetupBody, TextField, ButtonDark } from './styles'

import type { handleDataChangeFunc } from './types'

type Props = {
  handleDataChange: handleDataChangeFunc
}

type State = {
  hubIdentifier: string
}

export default class EnterMac extends React.Component<Props, State> {
  state = {
    hubIdentifier: ''
  }

  handleChange = ({
    target: { value }
  }: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ hubIdentifier: value })
  }

  handleKeyPress = ({ key }: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    const { hubIdentifier } = this.state
    const { handleDataChange } = this.props
    handleDataChange({ hubIdentifier })
  }

  render() {
    return (
      <SetupStep
        title="ENTER HUB ID"
        subtitle="Please enter the Install Code from the bottom of the hub. If there is no install code present, enter the 12-digit MAC ID.">
        <SetupBody>
          <TextField
            onKeyPress={this.handleKeyPress}
            onChange={this.handleChange}
            InputProps={{
              disableUnderline: true
            }}
          />
          <ButtonDark onClick={this.handleSubmit}>SUBMIT</ButtonDark>
        </SetupBody>
      </SetupStep>
    )
  }
}
