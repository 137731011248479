import React from 'react'
import { Route } from 'react-router-dom'
import SmartDeviceContainer from '../../views/Smart/SmartUnit/SmartDeviceContainer'
import LockCodeContainer from '../../views/Smart/LockCodes'
import DeleteDevice from '../../views/Smart/SmartUnit/DeviceDeleteContainer'

const SmartDeviceRoutes = ({ id }) => (
  <React.Fragment>
    <Route
      exact
      path="/properties/:propertyId/units/:unitId/smart"
      component={props => (
        <SmartDeviceContainer {...props} firstDeviceID={id} />
      )}
    />
    <Route
      path="/properties/:propertyId/units/:unitId/smart/devices/:deviceID?/:deviceMode?"
      component={props => (
        <SmartDeviceContainer {...props} firstDeviceID={id} />
      )}
    />
    <Route
      exact
      key="lockCodeContainer"
      path="/properties/:propertyId/units/:unitId/smart/devices/:deviceID?/lock-codes"
      component={LockCodeContainer}
    />
     <Route
      exact
      key="deleteDeviceContainer"
      path="/properties/:propertyId/units/:unitId/smart/devices/:deviceID?/delete"
      component={DeleteDevice}
    />
  </React.Fragment>
)

export default SmartDeviceRoutes
