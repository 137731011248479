// @flow
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import { CarouselUnitProps as Props } from './types'
import FlexDirection, {
  FlexItem
} from '@Common/Flex/FlexDirection'
import Avatar from '@Common/Avatar'
import { CommonIcon } from '@icons'
import { Name, UnitName, LinkItem, UnitContainer } from '../styles'
import { ViewLinkItem } from './styles'

const CarouselUnit = ({
  userId,
  name,
  picture,
  lastUnit,
  unitName,
  unitId,
  theme,
  match: {
    params: { propertyId }
  }
}: Props) => {
  return (
    <UnitContainer lastUnit={lastUnit}>
      <FlexDirection fullWidth>
        <FlexItem flex={0.8}>
          <Avatar size="2.2em" picture={picture} />
        </FlexItem>
        <FlexItem flex={2} style={{ whiteSpace: 'nowrap' }}>
          <Name>{name}</Name>
        </FlexItem>
        <FlexItem
          flex={0.5}
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '0px 8px'
          }}>
          <CommonIcon
            name="bullet"
            width="4px"
            height="4px"
            fill={theme.palette.secondary.main3}
            style={{ alignSelf: 'center' }}
          />
        </FlexItem>
        <FlexItem flex={5}>
          <UnitName>{unitName}</UnitName>
        </FlexItem>
        <ViewLinkItem noRightMargin flex={1.5}>
          <LinkItem>
            <Link
              to={`/properties/${propertyId}/units/${unitId}/user-management`}>
              View Unit
            </Link>
          </LinkItem>
        </ViewLinkItem>
        <ViewLinkItem flex={1.5}>
          <LinkItem>
            <Link
              to={`/properties/${propertyId}/user-management/profile/${userId}`}>
              View User
            </Link>
          </LinkItem>
        </ViewLinkItem>
      </FlexDirection>
    </UnitContainer>
  )
}

export default withRouter(withTheme(CarouselUnit))
