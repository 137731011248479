import get from 'lodash/get'
import sum from 'lodash/sum'
import { getSelectedProperty } from 'zego-shared/store/select/selectors'
import { getAmenityContact } from 'zego-shared/store/settings/selectors'
import { formatPhoneNumber } from 'zego-shared/utils/string'

const deviceTypes = {
  door_lock: 'Lock',
  thermostat: 'Thermostat'
}

export const getSmartSummaryBatteryInfo = (state, filter) => {
  const batteryLevels = get(state, ['property', 'batteries', 'data'])

  return Object.entries(batteryLevels).reduce(
    (batteryLevelsInfo, [unitId, devices]) => {
      Object.entries(devices)
        .filter(
          ([_, device]) =>
            device.type !== 'gateway' && filter === device.category
        )
        .forEach(([deviceId, device]) => {
          const unitName = get(state, [
            'select',
            'units',
            'byId',
            unitId,
            'name'
          ])
          batteryLevelsInfo.push({
            name: isNaN(unitName) ? unitName : `UNIT ${unitName}`,
            deviceType: deviceTypes[device.type],
            deviceId,
            unitId
          })
        })
      return batteryLevelsInfo
    },
    []
  )
}

export const getSmartSummaryBatteryCount = state => {
  const batteryLevels = get(state, ['property', 'batteries', 'count'], 0)
  return sum(Object.values(batteryLevels))
}

export const getPropertyHubs = (state) => {
  const hubs = get(state, ['property', 'hubs'])

  return hubs ? hubs : { 'offline': [], 'online': [] }
}

export const getResidentData = state => {
  const users = get(state, ['property', 'users', 'data'])
  return users ? users : []
}

export const getResidentManagementSurveysData = state => {
  const users = get(state, ['property', 'users', 'resident_management_surveys'])
  return users ? users : []
}

export const getResidentManagementSurveysDataTotal = state => {
  const usersTotal = get(state, ['property', 'users', 'resident_management_surveys_total'])
  return usersTotal ? usersTotal : 0
}

export const getResidentDataCount = state => {
  return get(state, ['property', 'users', 'total'])
}

export const getResidentSummary = (state: Object, filter: string) => {
  return get(state, ['property', 'users', 'resident_summary'])
}

export const getResidentManagementMoveinMoveoutData = state => {
  return get(state, ['moveInMoveOut', 'residentMoveInMoveOut', 'residents'])
}

export const getResidentManagementMoveinMoveoutDataCount = state => {
  return get(state, ['property', 'users', 'resident_management_moveinmoveout_total'])
}

export const getGeneralInfo = state => {
  const {
    name,
    address,
    phone_number: phoneNum,
    emergency_phone_number: emergencyPhoneNum,
    office_address_city: city,
    office_address_line_1: officeAddressLine1,
    office_address_line_2: officeAddressLine2,
    office_address_state: officeAddressState,
    office_address_zip: zip,
    office_hours: officeHours,
    system_metadata: systemMetadata
  } = getSelectedProperty(state)

  const phoneNumber = formatPhoneNumber(phoneNum)
  const emergencyPhoneNumber = formatPhoneNumber(emergencyPhoneNum)

  return {
    name: name || '',
    address: address || '',
    phoneNumber: phoneNumber || '',
    emergencyPhoneNumber: emergencyPhoneNumber || '',
    email: get(systemMetadata, 'email', '') || '',
    maintenanceEmail: get(systemMetadata, 'maintenance_email', '') || '',
    officeAddressLine1: officeAddressLine1 || '',
    officeAddressLine2: officeAddressLine2 || '',
    city: city || '',
    officeAddressState: officeAddressState || '',
    zip: zip || '',
    officeHours: officeHours || '',
    amenityContact: getAmenityContact(state)
  }
}
