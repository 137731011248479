import cloneDeep from 'lodash/cloneDeep'
import {
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeLatest
} from 'redux-saga/effects'
import {
  loadAddHubStart,
  loadAddHubError,
  addHub,
  connectDeviceSuccess,
  excludeDeviceSuccess,
  CONNECT_DEVICE,
  EXCLUDE_DEVICE,
  RUN_TEST,
  SUBMIT_NAME,
  COMPLETE_ADD_DEVICE,
  DEVICE_EVENT,
  loadAddHubSuccess
} from './actions'
import { createTag } from '../../devices/sagas/utils/deviceCommands'
import { deleteDeviceSuccess, messageHubAddError } from '../../../utils/messages'
import { getUnitId } from '../../select/selectors'
import { getToken } from '../../authorization/selectors'
import { getConnectedDevice, getConnectedDeviceTestPayload } from './selectors'
import { refreshDevicesList } from '../../devices/actions'
import { showSnackbar } from '../../snackbar/actions'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  pushHubCommand,
  getDevices,
  joinHubChannel
} from '../../devices/sagas/devices'
import { pushMessage, eventCodes } from '../../socket/sagas'
import { getHubIdentifier } from '../../devices/selectors'

const DEVICE_WAITING_TIME_OUT = 61000
const DEVICE_SEARCH_RETRY_TIMES = 5

const getDeviceChannel = identifier => `device:${identifier}`

export function* addHubToUnit({ hubIdentifier }) {
  try {
    yield put(loadAddHubStart())
    const unitId = yield select(getUnitId)
    const authToken = yield select(getToken)

    yield call(api.addHub, unitId, hubIdentifier, authToken)
    yield fork(completeAddDevice)
    yield put(loadAddHubSuccess())
  } catch (error) {
    yield put(showSnackbar(messageHubAddError, 'error'))
    yield put(loadAddHubError())
  }
}

export function* connectDevice() {
  try {
    const connectedDevice = yield call(includeExcludeDevice)
    yield put(connectDeviceSuccess(connectedDevice))
  } catch (error) {
    log(`Couldn't connect to device. Error: ${error}`)
  }
}

export function* excludeDevice({ history, url }) {
  try {
    yield fork(joinHubChannel)
    const deletedDevice = yield call(includeExcludeDevice, 'exclude')
    yield put(excludeDeviceSuccess(deletedDevice))

    const unitId = yield select(getUnitId)
    yield put(refreshDevicesList(unitId))

    yield put(showSnackbar(deleteDeviceSuccess, 'success'))
    history.push(`${url.split('/devices')[0]}/devices`)
  } catch (error) {
    log(`Couldn't exclude to device. Error: ${error}`)
  }
}

export function* includeExcludeDevice(mode = 'connect') {
  const commandType = mode === 'connect' ? 'inclusion' : 'exclusion'
  for (let i = 0; i < DEVICE_SEARCH_RETRY_TIMES; i++) {
    try {
      yield call(pushHubCommand, { commandType }, false)

      const { device } = yield race({
        device: call(watchDeviceEvent),
        timeout: delay(DEVICE_WAITING_TIME_OUT)
      })

      if (device) {
        return device
      }
    } catch (err) {
      // do not call delay if the last attempt fails
      if (i < DEVICE_SEARCH_RETRY_TIMES - 1) {
        // retry after 5s
        yield delay(5000)
      }
    }
  }
  throw new Error(
    `failed to ${mode} device after ${DEVICE_SEARCH_RETRY_TIMES} attempts`
  )
}

export function* watchDeviceEvent() {
  while (true) {
    const action = yield take(DEVICE_EVENT)

    const { event } = action
    const { event_code } = event

    if (event_code === eventCodes.DEVICE_CREATED) {
      const { resource: { id, identifier, type } } = event

      return { id, identifier, type }
    } else if (event_code === eventCodes.DEVICE_EXCLUDED) {
      const { resource_id: id, meta_data: { type, identifier } } = event
      return { id, identifier, type }
    }
  }
}

export function* runTest({ selectedDeviceName }) {
  try {
    const identifier = yield select(getHubIdentifier)
    const device = yield select(getConnectedDevice)
    const command = cloneDeep(
      yield select(getConnectedDeviceTestPayload, selectedDeviceName)
    )
    delete command.type
    delete command.command
    command.target = device.identifier
    command.tag = createTag()
    const deviceChannel = getDeviceChannel(identifier)

    yield call(pushMessage, deviceChannel, command)
  } catch (error) {
    log(`Failed to run test. Error: ${error}`)
  }
}

export function* submitName({ deviceName }) {
  try {
    const authToken = yield select(getToken)
    const device = yield select(getConnectedDevice)

    const { id } = device
    yield call(api.updateDevice, id, deviceName, authToken)
    yield fork(completeAddDevice)
  } catch (error) {
    log(`Failed to submit name. Error: ${error}`)
  }
}

export function* completeAddDevice() {
  yield fork(getDevices, true)

  try {
    yield delay(500)
  } catch (error) {
    log(error)
  }
}

function* watchConnectDevice() {
  yield takeLatest(CONNECT_DEVICE, connectDevice)
}

function* watchExcludeDevice() {
  yield takeLatest(EXCLUDE_DEVICE, excludeDevice)
}

function* watchAddHub() {
  yield takeLatest(addHub().type, addHubToUnit)
}

function* watchRunTest() {
  yield takeLatest(RUN_TEST, runTest)
}

function* watchSubmitName() {
  yield takeLatest(SUBMIT_NAME, submitName)
}

function* watchCompleteAddDevice() {
  yield takeLatest(COMPLETE_ADD_DEVICE, completeAddDevice)
}

export default [
  watchAddHub(),
  watchConnectDevice(),
  watchExcludeDevice(),
  watchRunTest(),
  watchSubmitName(),
  watchCompleteAddDevice()
]
