// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import { Link, withRouter } from 'react-router-dom'
import type { Node } from 'react'
import Typography from '@material-ui/core/Typography'

import type { Theme } from '../../../theme'
//components
import { CommonIcon } from '@icons'
import { FlexItem } from '@Common/Flex/FlexDirection'
import { CarouselUnit } from '@Common/CarouselList'

export type Props = {
  match: Object,
  theme: Theme,
  data: Object
}

export const PropertySummary = ({ data, theme, match }: Props): Array<Node> => {
  return !data.length
    ? [
        <CarouselUnit key={1} lastUnit={true}>
          <FlexItem flex={1}>
            <Typography
              align="center"
              variant="display2"
              style={{ fontSize: theme.custom.font.size.mdlg }}>
              No Recent Views
            </Typography>
          </FlexItem>
        </CarouselUnit>
      ]
    : data.map((historyItem, index) => (
        <CarouselUnit
          lastUnit={index === 2}
          key={`${historyItem.name}-${index}`}>
          <FlexItem flex={0.3} />
          <FlexItem style={{ whiteSpace: 'nowrap' }}>
            {historyItem.name}
          </FlexItem>
          <FlexItem flex={0.4} style={{ textAlign: 'center' }}>
            <CommonIcon
              name="bullet"
              width="4px"
              height="4px"
              fill={theme.palette.grey.main}
              style={{ alignSelf: 'center' }}
            />
          </FlexItem>
          <FlexItem style={{ color: theme.palette.neutrals.deepGray }} flex={10}>
            {historyItem.category}
          </FlexItem>
          <FlexItem flex={0.5}>
            <Link to={`/properties/${historyItem.url}`}>view</Link>
          </FlexItem>
        </CarouselUnit>
      ))
}

export default withRouter(withTheme(PropertySummary))
