// @flow

import React from 'react'
import type { Match, Location } from 'react-router-dom'
import { setRoleTitle } from 'zego-shared/store/users/selectors'
import ChooseRole from './ChooseRole'
import ChooseUnit from './ChooseUnit'
import EnterEmail from './EnterEmail'
import Success from './Success'
import { DefaultBackButton } from '@Common/Button'
import Flex from '@Common/Flex'
import Grid, { GridItem } from '../../Common/Grid'
import { MobileStepper, StepButton } from './styles'
import Snackbar from '../../Snackbar/SnackbarContainer'
import {
  isUnitLevelRole,
  isPropertyLevelRole,
  isCompanyAdmin
} from '../../../utils/roleUtils'
import type { BrowserHistory } from 'history'
import api from 'zego-shared/utils/api'
import { RESIDENT } from '../../../constants/roleConstants'

type Props = {
  companyId: ?number,
  propertyId: ?number,
  units: Array<{}>,
  location: Location,
  getPropertyPmsStatus: () => void,
  isFetchingUnits: boolean,
  addUserError?: Object | null,
  history: BrowserHistory,
  match: Match,
  hasPms: boolean,
  userToken: string,
  userRole: string,
  fetchUnits: (name: string) => void,
  showSnackbar: Function,
  addUser: (
    email: ?string,
    selectedUserRole: ?string,
    selectedUnitId: ?number
  ) => void
}

type State = {
  currentStep: number,
  email: ?string,
  selectedUserRole: ?string,
  selectedUnitId: ?number,
  lastStep: ?number,
  addUserError?: Object,
  isCreatingUser: boolean
}

type AddUserData = {
  selectedUserRole: ?string,
  email: ?string
}

export const createUserSteps = (userRole?: string, hasPms?: boolean) => [
  {
    component: ChooseRole,
    props: { hasPms, userRole },
    backButton: null,
    withoutGrid: false,
    performAdd: false
  },
  {
    component: ChooseUnit,
    backButton: null,
    performAdd: false,
    withoutGrid: false,
    props: {}
  },
  {
    component: EnterEmail,
    performAdd: true,
    backButton: null,
    withoutGrid: false,
    props: {}
  },
  {
    component: Success,
    withoutGrid: true,
    props: { message: 'SUCCESS !' },
    backButton: null,
    performAdd: false
  }
]

const stepsCount = createUserSteps().length

const initialState = {
  currentStep: 0,
  email: null,
  selectedUserRole: null,
  selectedUnitId: null,
  lastStep: 0,
  isCreatingUser: false,
  addUserError: undefined
}

export default class AddUser extends React.Component<Props, State> {
  state = initialState
  timeout = null

  handleBackStep = () => {
    const { currentStep, selectedUserRole } = this.state
    const { state } = this.props.location

    if (currentStep === 0 || (state && state.fromUnit && currentStep === 2)) {
      this.goBackToMainComponent()
    } else {
      const newStep =
        !isUnitLevelRole(selectedUserRole) && currentStep === 2
          ? 0
          : this.state.currentStep - 1
      this.setState({ currentStep: newStep, lastStep: null })
    }
  }

  goBackToMainComponent = () => {
    const { history } = this.props
    history.goBack()
  }

  performAdd = async (nextComponentIndex: ?number) => {
    const { userToken, propertyId, companyId, showSnackbar } = this.props
    const { email, selectedUserRole, selectedUnitId, currentStep } = this.state

    try {
      await api.createUser(userToken, {
        email,
        role: selectedUserRole,
        unitId: selectedUnitId,
        // $FlowDisableNextLine
        ...(isCompanyAdmin(selectedUserRole) ? { companyId } : {}),
        ...(isPropertyLevelRole(selectedUserRole) ? { propertyId } : {})
      })
      this.updateSteps(nextComponentIndex)
      this.timeout = setTimeout(
        () => this.setState({ isCreatingUser: false }),
        200
      )
    } catch (error) {
      let message = error.message || 'Create user failed, please try again'
      if (error.data && error.data.email) {
        message = `Email ${error.data.email[0]}`
      }
      showSnackbar(message, 'error')
      this.setState({
        currentStep
      })
    }
  }

  handleNextStep = (nextComponentIndex: ?number) => {
    const { currentStep } = this.state
    const { performAdd } = createUserSteps()[currentStep]

    if (performAdd) {
      this.performAdd(nextComponentIndex)
      return
    }
    this.updateSteps(nextComponentIndex)
  }

  updateSteps = (nextComponentIndex: ?number) => {
    const { currentStep } = this.state

    const nextStep =
      nextComponentIndex == null || nextComponentIndex === -1
        ? currentStep + 1
        : nextComponentIndex
    this.setState({ currentStep: nextStep, lastStep: currentStep })
  }

  handleDataChange = (changes: AddUserData) => {
    const { selectedUserRole } = changes
    const { currentStep } = this.state

    const userCreateSteps = createUserSteps()
    const { component } = userCreateSteps[currentStep]

    let nextComponentIndex = null
    if (component === ChooseRole && !isUnitLevelRole(selectedUserRole)) {
      nextComponentIndex = userCreateSteps.findIndex(
        ({ component }) => component === EnterEmail
      )
    }

    this.setState(changes, () => {
      this.handleNextStep(nextComponentIndex)
    })
  }

  fetchUnits = (name: string) => {
    if (name.length > 0) {
      const { fetchUnits } = this.props
      fetchUnits(name)
    }
  }

  reset = () => {
    this.setState(() => initialState)
  }

  componentDidMount() {
    const { state } = this.props.location
    const {
      getPropertyPmsStatus,
      match: {
        params: { unitId }
      }
    } = this.props
    getPropertyPmsStatus()

    if (state && state.fromUnit)
      this.setState({
        selectedUserRole: RESIDENT,
        selectedUnitId: parseInt(unitId, 10),
        currentStep: this.state.currentStep + 2
      })
  }

  componentWillMount() {
    clearTimeout(this.timeout)
  }

  render() {
    const { currentStep, isCreatingUser, addUserError, ...state } = this.state
    const {
      units,
      isFetchingUnits,
      propertyId,
      hasPms,
      userRole,
      location
    } = this.props
    const userCreateSteps = createUserSteps(userRole, hasPms)
    const {
      component: StepComponent,
      props,
      withoutGrid,
      backButton
    } = userCreateSteps[currentStep]

    if (withoutGrid) {
      return (
        <StepComponent
          {...props}
          isCreatingUser={isCreatingUser}
          addUserError={addUserError}
          propertyId={propertyId}
          reset={this.reset}
          exit={this.goBackToMainComponent}
        />
      )
    }

    // $FlowDisableNextLine
    const extraProps = { ...props, ...state }

    return (
      <Grid>
        <Snackbar />
        <GridItem columnStart={1} columnSpan={12} rowStart={1} rowSpan={2}>
          <MobileStepper
            activeStep={currentStep}
            variant="progress"
            steps={location.state && location.state.fromUnit ? 2 : stepsCount}
            position="static"
          />
          <Flex justifySpaceBetween>
            <StepButton onClick={this.handleBackStep}>
              {backButton == null ? <DefaultBackButton /> : backButton}
            </StepButton>
          </Flex>
        </GridItem>
        <GridItem columnStart={4} columnSpan={6} rowStart={4} rowSpan={10}>
          <StepComponent
            fetchUnits={this.fetchUnits}
            handleDataChange={this.handleDataChange}
            handleNextStep={this.handleNextStep}
            handleBackStep={this.handleBackStep}
            units={units}
            userType={
              StepComponent.name === 'EnterEmail'
                ? setRoleTitle(state.selectedUserRole || '')
                : ''
            }
            isFetchingUnits={isFetchingUnits}
            isCreatingUser={isCreatingUser}
            addUserError={addUserError}
            {...extraProps}
          />
        </GridItem>
      </Grid>
    )
  }
}
