// @flow
import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import DropDown from '@Common/DropDown'
import { CommonIcon, RadiobuttonIcon } from '@icons'
import { TemplateEditorProps, TemplateEditorState } from '../types'
import MessageEditor from '../Template/MessageEditor'
import { css } from 'emotion'
import {
  CustomTextField,
  TextFieldContainer,
  Break,
  NewTemplateCheckBox
} from './styles'

type RefObject = {|
  current: any,
|}

class TemplateEditor extends React.Component<
  TemplateEditorProps,
  TemplateEditorState
> {
  state = {
    active: null
  }

  wrapperRef: RefObject = React.createRef()

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick)
  }

  focusInput = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ active: event.target.name })
  }

  unFocusInput = () => {
    this.setState({ active: null })
  }

  toggleFocus = () => {
    this.setState(state => ({
      active: state.active ? null : state.active
    }))
  }

  handleOutsideClick = ({ target }: Object) => {
    // $FlowDisableNextLine
    const wrapperRefExists = this.wrapperRef && this.wrapperRef.current
    // $FlowDisableNextLine
    if (wrapperRefExists && !this.wrapperRef.current.contains(target)) {
      this.unFocusInput()
    }
  }

  render() {
    const {
      templates,
      chooseTemplate,
      selectedTemplate,
      theme,
      handleMessageChange,
      handleEmailMessageChange,
      saveAsTemplate,
      handleSaveAsTemplate,
      message,
      defaultMessage,
      replacementOptions,
      handleMergeFieldChange,
      addAttachment,
      removeAttachment,
      removeFile,
      selectedDeliveryMethods,
      showChatOption
    } = this.props

    const { active } = this.state

    return (
      <React.Fragment>
        <TextFieldContainer>
          <DropDown
            width={'90%'}
            classes={{ root: 'template-dropdown' }}
            data={templates}
            IconComponent={() => (
              <CommonIcon
                name="arrow_down"
                width="1em"
                height="1em"
                fill={theme.palette.grey.main}
              />
            )}
            handleChange={chooseTemplate}
            selectedItem={selectedTemplate}
            defaultOption={defaultMessage}
          />
        </TextFieldContainer>
        <MessageEditor
          message={message}
          replacementOptions={replacementOptions}
          handleMergeFieldChange={handleMergeFieldChange}
          attachments={message.attachments}
          files={message.files}
          addAttachment={addAttachment}
          removeAttachment={removeAttachment}
          removeFile={removeFile}
          handleChange={handleMessageChange}
          handleEmailMessageChange={handleEmailMessageChange}
          subjectLineText={message.title}
          active={active}
          showChatOption={showChatOption}
          selectedDeliveryMethods={selectedDeliveryMethods}
          className={css`
            width: 90%;
          `}
          isDefault={selectedTemplate.isDefault}
          footNote={selectedTemplate.footNote}
        />
        <Break />
        <TextFieldContainer width={'90%'}>
          <NewTemplateCheckBox>
            <Checkbox
              checked={saveAsTemplate}
              onChange={handleSaveAsTemplate}
              checkedIcon={
                <RadiobuttonIcon
                  width={'1.5em'}
                  height={'1.1em'}
                  name="selected"
                />
              }
              icon={
                <RadiobuttonIcon
                  width={'1.5em'}
                  height={'1.1em'}
                  name="unselected"
                />
              }
            />
            save as a template
          </NewTemplateCheckBox>
          <CustomTextField
            value={message.templateName}
            disabled={!saveAsTemplate}
            label="Template Name"
            name="templateName"
            active={active === 'templateName'}
            onFocus={this.focusInput}
            onBlur={this.unFocusInput}
            onChange={handleMessageChange}
          />
        </TextFieldContainer>{' '}
        <Break />
      </React.Fragment>
    )
  }
}

export default TemplateEditor
