// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import BaseCard from '@Common/Cards/BaseCard/WideBaseCard'
import LockDeviceContent from './LockDeviceContent'
import { SwitchStatus } from './styles'
import { Props } from './types'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import FlexDirection, {
  FlexItem
} from '@Common/Flex/FlexDirection'
import { LockIcon, CommonIcon } from '@icons'
import Image from '../../../../images/thermostat_card.png'

import Lock from './Lock'

import { PercentageText, Superscript } from '../styles'
import { CircleWrap } from '../DevicesList/styles'

class LockDevice extends React.Component<Props, {}> {
  toggleSwitch = () => {
    const { pushLockStateCommand, id, identifier } = this.props
    pushLockStateCommand(id, identifier)
  }

  toggleViewBatteryMode = () => {
    const {
      history,
      id,
      viewBatteryMode,
      match: { params }
    } = this.props
    viewBatteryMode
      ? history.push(
          `/properties/${params.propertyId}/units/${
            params.unitId
          }/smart/devices/${id}`
        )
      : history.push(
          `/properties/${params.propertyId}/units/${
            params.unitId
          }/smart/devices/${id}/battery`
        )
  }

  showDeleteDeviceModal = () => {
    const {
      history,
      match: { url }
    } = this.props
    history.push(`${url}/delete`)
  }

  getHeaderRight = () => {
    const { isLocked, viewBatteryMode, abnormal, theme } = this.props

    if (abnormal)
      return (
        <CircleWrap large onClick={this.showDeleteDeviceModal}>
          <CommonIcon
            name="close"
            strokeWidth={5}
            width={'1em'}
            height={'1em'}
            stroke={theme.palette.secondary.pink.coral}
          />
        </CircleWrap>
      )

    return viewBatteryMode ? (
      <SwitchStatus
        onClick={this.toggleViewBatteryMode}
        style={{ cursor: 'pointer' }}>
        DONE
      </SwitchStatus>
    ) : isLocked ? (
      <SwitchStatus>LOCKED</SwitchStatus>
    ) : (
      <SwitchStatus>UNLOCKED</SwitchStatus>
    )
  }

  getRightContent = () => {
    const {
      match: { params },
      theme,
      batteryPercent,
      firstDeviceID,
      isLocked,
      viewBatteryMode,
      location: { pathname },
      abnormal
    } = this.props
    const baseRoute = pathname.replace(/(\/)$/, '')
    return (
      <FlexDirection direction="column" fullWidth fullHeight>
        <FlexItem flex={1} displayFlex flexEnd alignItemsLeft>
          {this.getHeaderRight()}
        </FlexItem>
        <FlexItem flex={5.9} displayFlex>
          <FlexItem flex={2} />
          <FlexItem flex={3} flexCenter displayFlex>
            {!viewBatteryMode ? (
              !abnormal && (
                <Lock onClick={this.toggleSwitch} isLocked={isLocked} />
              )
            ) : (
              <PercentageText>
                {batteryPercent}
                <Superscript>%</Superscript>
              </PercentageText>
            )}
          </FlexItem>
          <FlexItem
            flex={0.1}
            displayFlex
            flexEnd
            alignItemsRight
            style={{
              direction: 'column',
              height: '100%'
            }}>
            {!abnormal && (
              <Link
                to={`${
                  params.deviceId
                    ? baseRoute
                    : `${
                        baseRoute.split('/devices')[0]
                      }/devices/${firstDeviceID}`
                }/lock-codes`}>
                <LockIcon
                  name="lock_pin"
                  width="1em"
                  height="1em"
                  fill={theme.palette.primary.green.mint}
                />
              </Link>
            )}
          </FlexItem>
        </FlexItem>
      </FlexDirection>
    )
  }

  render() {
    const {
      deviceModel,
      deviceStatus,
      installationDate,
      lastUpdated,
      batteryPercent,
      lastStateText,
      batteryLevelText,
      batteryLevelPercentText,
      firstDeviceID,
      abnormal
    } = this.props

    return (
      <BaseCard
        title="LOCK"
        rowStart={1}
        rowSpan={3}
        columnStart={1}
        columnSpan={9}
        type="summary"
        imageSrc={Image}
        leftContent={() => (
          <LockDeviceContent
            abnormal={abnormal}
            firstDeviceID={firstDeviceID}
            deviceModel={deviceModel}
            deviceStatus={deviceStatus}
            installationDate={installationDate}
            lastUpdated={lastUpdated}
            batteryPercent={batteryPercent}
            lastStateText={lastStateText}
            batteryLevelText={batteryLevelText}
            batteryLevelPercentText={batteryLevelPercentText}
            toggleViewBatteryMode={this.toggleViewBatteryMode}
            toggleSwitch={this.toggleSwitch}
          />
        )}
        rightContent={this.getRightContent}
      />
    )
  }
}

export default withRouter(withTheme(LockDevice))
