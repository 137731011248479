import { normalizeArraySectionData } from '../../helpers/normalizer'
import { REFRESH_PREFIX, SUCCESS } from '../actions/constants'

export default section => reducer => (state, action) => {
  switch (action.type) {
    case `${REFRESH_PREFIX}${section}${SUCCESS}`: {
      return normalizeArraySectionData(action.payload, section, state)
    }
    default:
      return reducer(state, action)
  }
}
