// @flow

import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Link } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import SummarySideNavigation from '@Common/SummarySideNavigation'
import UsersSummaryRoutes from '../../routes/Users/UsersSummaryRoutes'
import { FabButton } from './styles'
import { State, Props } from './types'
import Loader from '../Common/Loader'
import { RESIDENT_MANAGEMENT_FEATURE_FLAG } from '../Residents/constants'

export class UserManagement extends React.Component<Props, State> {
  getSideNavigationTabs = (
    residentsCount: number,
    propertyUsersCount: number,
    propertyId: string
  ) => [
    {
      name: `${residentsCount} Resident Users`,
      route: `/properties/${propertyId}/user-management/resident`
    },
    {
      name: `${propertyUsersCount} Property Users`,
      route: `/properties/${propertyId}/user-management/property`,
      query: { status: '' }
    }
  ]

  render() {
    const {
      residentsCount,
      propertyUsersCount,
      theme,
      isFetching,
      hasData,
      featureFlag,
      match: {
        params: { propertyId }
      }
    } = this.props

    if (isFetching) return <Loader />
    if (!hasData) return null

    const tabs = this.getSideNavigationTabs(
      residentsCount,
      propertyUsersCount,
      propertyId
    )

    const hideSideNavTabs = featureFlag && featureFlag.ld && featureFlag.ld.variation(RESIDENT_MANAGEMENT_FEATURE_FLAG, false)

    return (
      <React.Fragment>
        <UsersSummaryRoutes />
        {!hideSideNavTabs && <SummarySideNavigation tabs={tabs} />}
        <Link to={`/properties/${propertyId}/user-management/add-user`}>
          <FabButton variant="fab">
            <AddIcon style={{ color: theme.palette.common.white }} />
          </FabButton>
        </Link>
      </React.Fragment>
    )
  }
}

export default withTheme(UserManagement)
