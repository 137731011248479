// @flow
import React from 'react'
import moment from 'moment'
import debounce from 'lodash/debounce'
import toString from 'lodash/toString'
import { css } from 'emotion'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import InputAdornment from '@material-ui/core/InputAdornment'
import Header from '@Common/Header'

import Grid, { GridItem } from '../Common/Grid'
import Loader from '../Common/Loader'
import BaseCard from '@Common/Cards/BaseCard'
import Tabs from '@Common/Cards/Tabs'
import Flex, { FlexItem } from '@Common/Flex/FlexDirection'
import ReminderSection from './ReminderSection'
import { SearchIcon } from '@icons'
import Column from './Column'

import PaginatedTable from '../Common/PaginatedTable'
import DropDown from '@Common/DropDown'
import { Theme } from '../../theme'
import { Moment } from 'moment'

// utils
import {
  statusData,
  getSelectedStatus,
  tabs,
  columns,
  sortFields
} from '../../utils/packagesUtils'

import {
  Content,
  Layout,
  Container,
  TextField,
  TableContainer,
  StyledRow
} from './styles'
import type { Order } from './types'
type Props = {
  packages: Object[],
  filter: string | null,
  theme: Theme,
  moment: Moment,
  isReminderPage: boolean,
  updatePackageStatus: (status: string, id: number) => void,
  fetchPackages: (term: string, sortBy: string, page: number) => void,
  isFetching: boolean,
  updatePackageReminder: (moment: Moment) => void,
  packagesReminder: Moment,
  showError: (message: string) => void,
  isPackagesEnabled: boolean,
  currentPage: number,
  total: number,
  rowsPerPage: number,
  perPage: number
}

type State = {
  currentPage: number,
  newItems: Object[],
  searchTerm: string,
  packagesReminder: Moment,
  currentSortByIndex: number,
  currentOrder: Order
}

const minSearchLength = 2

class Packages extends React.Component<Props, State> {
  state = {
    searchTerm: '',
    currentPage: this.props.currentPage - 1,
    newItems: [],
    packagesReminder: this.props.packagesReminder || null,
    currentSortByIndex: -1,
    currentOrder: ''
  }

  handleSearchChange = ({ target: { value } }) => {
    this.setState({ searchTerm: value })
    if (!value.length || value.length >= minSearchLength) this.searchPackages()
  }

  searchPackages = debounce(() => {
    this.fetchPackages()
  }, 700)

  handleKeyPress = ({ key }: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      this.searchPackages()
    }
  }

  changeStatus = async (packageId, value) => {
    const statusDetails = statusData.find(status => status.id === value)
    let status = statusDetails ? statusDetails.name.toLowerCase() : ''
    if (status === 'on hold') status = 'hold'
    this.props.updatePackageStatus(status, packageId)
  }

  handleReminderChange = ({ target: { value } }) => {
    this.setState({ packagesReminder: value })
  }

  createRows = () => {
    const { newItems = [] } = this.state

    let rows = []

    const isTrackingNumber = index => index === 4
    const isStatus = index => index === 5

    rows = newItems.map((rowData, rowIndex) =>
      Object.entries(rowData)
        .map(([key, value], index) => {
          const selectedStatus = getSelectedStatus(toString(value))
          const packageId = rowData.packageId

          return {
            packageId: key === 'packageId' ? true : false,
            value: (
              <StyledRow
                firstRow={rowIndex === 0}
                index={index + 1}
                highlight={isTrackingNumber(index)}>
                {isTrackingNumber(index) && `#`}
                {isStatus(index) ? (
                  selectedStatus ? (
                    <DropDown
                      handleChange={this.changeStatus.bind(this, packageId)}
                      selectedItem={selectedStatus}
                      data={statusData}
                    />
                  ) : (
                    'Unknown'
                  )
                ) : (
                  value
                )}
              </StyledRow>
            )
          }
        })
        .filter(item => !item.packageId)
    )

    return rows
  }

  setReminder = () => {
    const { packagesReminder } = this.state

    const momentFormat = moment(packagesReminder, 'HH:mm')
    if (packagesReminder) {
      this.props.updatePackageReminder(momentFormat)
      this.setState({ packagesReminder: momentFormat })
    } else {
      this.props.showError('Please set a valid time for reminder')
    }
  }

  changePage = page => {
    this.setState({ currentPage: page - 1 }, this.fetchPackages)
  }

  fetchPackages = () => {
    const {
      currentSortByIndex: index,
      currentOrder: order,
      searchTerm,
      currentPage
    } = this.state
    this.props.fetchPackages(
      searchTerm,
      `${sortFields[index]},${order}`,
      currentPage + 1
    )
  }

  handleColumnClick = (index: number) => (order: Order) => {
    this.setState(
      { currentSortByIndex: index, currentOrder: order, currentPage: 0 },
      this.fetchPackages
    )
  }

  getColumns = () => {
    const { currentSortByIndex, currentOrder, searchTerm } = this.state
    return columns.map((column, index) => {
      const isCurrent = currentSortByIndex === index
      return {
        value: (
          <Column
            index={index}
            column={column}
            isCurrent={isCurrent}
            onClick={this.handleColumnClick(index)}
            order={currentOrder}
            disabled={Boolean(searchTerm)}
          />
        )
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.packages !== this.props.packages) {
      const { packages } = this.props
      this.setState({
        newItems: packages
      })
    }

    if (prevProps.filter !== this.props.filter) {
      this.setState({
        searchTerm: '',
        currentSortByIndex: -1,
        currentOrder: '',
        currentPage: 0
      })
    }
  }

  render() {
    const {
      theme,
      isReminderPage,
      isFetching,
      isPackagesEnabled,
      rowsPerPage,
      total
    } = this.props
    const { searchTerm, packagesReminder, currentPage } = this.state

    if (!isPackagesEnabled) return null

    return (
      <React.Fragment>
        <GridItem columnStart={1} columnSpan={3} rowStart={1} rowSpan={12}>
          <Grid>
            <BaseCard
              columnStart={1}
              columnSpan={8}
              rowSpan={12}
              isLeftSideBG={true}
              rowStart={1}
              header={() => <Header alternateFontSize>Packages</Header>}
              showShadow={false}
              transparentBackground
              content={() => (
                <Content>
                  <b>Log package arrivals</b> and
                  <br />
                  instantly update residents of a<br />
                  recieved package.
                  <br />
                  <br />
                  Easily mark a package as
                  <br />
                  delivered when residents come in <br />
                  for pickup. Or mark it on hold to <br />
                  stop automatic daily package <br />
                  pickup reminders.
                </Content>
              )}
            />
          </Grid>
        </GridItem>
        <GridItem columnStart={2} columnSpan={11} rowStart={1} rowSpan={12}>
          <Container>
            <Layout>
              <Grid>
                <GridItem
                  columnStart={4}
                  columnSpan={9}
                  rowStart={2}
                  rowSpan={2}>
                  <Flex fullWidth>
                    <FlexItem flex={4}>
                      <Tabs
                        selectTextColor={theme.palette.text.black}
                        tabs={tabs}
                        customStyle={`
                          .tab-root {
                            color: ${theme.palette.grey.main};
                            font-weight: ${theme.custom.font.weight.light};
                          }
                        `}
                        scrollable={css`
                          .scrollable-tab {
                            padding-left: '24px;';
                          }
                        `}
                      />
                    </FlexItem>
                    <FlexItem flex={1} displayFlex flexEnd>
                      {!isReminderPage && (
                        <TextField
                          value={searchTerm}
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon
                                  name="search"
                                  height="20px"
                                  width="18px"
                                  onClick={this.searchPackages}
                                  className="searchIcon"
                                />
                              </InputAdornment>
                            ),
                          }}
                          onChange={this.handleSearchChange}
                          onKeyPress={this.handleKeyPress}
                        />
                      )}
                    </FlexItem>
                  </Flex>
                </GridItem>
                <GridItem
                  columnStart={3}
                  columnSpan={10}
                  rowStart={3}
                  rowSpan={10}>
                  <TableContainer>
                    {isReminderPage ? (
                      <ReminderSection
                        moment={packagesReminder}
                        handleReminderChange={this.handleReminderChange}
                        setReminder={this.setReminder}
                      />
                    ) : isFetching ? (
                      <Loader />
                    ) : (
                      <PaginatedTable
                        noDataText="No Packages"
                        total={total}
                        flex={[1.8, 0.7, 1, 0.5, 1, 2.3]}
                        currentPage={currentPage}
                        rowsPerPage={rowsPerPage}
                        columns={this.getColumns()}
                        rows={this.createRows()}
                        changePage={this.changePage}
                      />
                    )}
                  </TableContainer>
                </GridItem>
              </Grid>
            </Layout>
          </Container>
        </GridItem>
      </React.Fragment>
    )
  }
}

export default withRouter(withTheme(Packages))
