import { takeLatest, select } from 'redux-saga/effects'
import Raven from 'raven-js'
import { PERSIST } from 'redux-persist'
import {
  FETCH_RESOURCES_AND_REDIRECT_AFTER_LOGIN,
  DEAUTH
} from 'zego-shared/store/authorization/actions'
import { getUserData } from '../selectors'

const isProd = process.env.NODE_ENV === 'production'

function* setUserContextSaga() {
  const { isAuth, ...rest } = yield select(getUserData)
  if (isAuth && isProd) {
    yield Raven.setUserContext(rest)
  }
}

function* deleteUserContextSaga() {
  if (isProd) {
    yield Raven.setUserContext()
  }
}

function* watchPersistRehydrate() {
  yield takeLatest(
    [PERSIST, FETCH_RESOURCES_AND_REDIRECT_AFTER_LOGIN],
    setUserContextSaga
  )
}

function* watchDeAuth() {
  yield takeLatest(DEAUTH, deleteUserContextSaga)
}

export default [watchPersistRehydrate(), watchDeAuth()]
