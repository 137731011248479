import { call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken } from '../../../authorization/selectors'
import {
  fetchPropertyUsersSummary as fetchPropertyUsersSummaryAction,
  fetchPropertyUsersSummaryStart,
  fetchPropertyUsersSummarySuccess,
  fetchPropertyUsersSummaryError,
  refreshPropertyUsersSummary as refreshPropertyUsersSummaryAction,
  refreshPropertyUsersSummaryStart,
  refreshPropertyUsersSummarySuccess,
  refreshPropertyUsersSummaryError
} from '../actions'
import api from '../../../../utils/api'
import log from '../../../../utils/logger'
import { noPropertySelected } from '../../../../utils/messages'
import { getPropertyId } from '../../../select/selectors'

const getPropertyUsersSummaryActions = isRefreshing => ({
  start: isRefreshing ? refreshPropertyUsersSummaryStart : fetchPropertyUsersSummaryStart,
  success: isRefreshing ? refreshPropertyUsersSummarySuccess : fetchPropertyUsersSummarySuccess,
  error: isRefreshing ? refreshPropertyUsersSummaryError : fetchPropertyUsersSummaryError
})

export function* fetchPropertyUsersSummary(isRefreshing, { id }) {
  const actions = getPropertyUsersSummaryActions(isRefreshing)

  try {
    yield put(actions.start())
    const authToken = yield select(getToken)
    const propertyID = id ? id : yield select(getPropertyId)

    if (propertyID === -1) throw new Error(noPropertySelected)

    const response = yield call(api.getPropertyUsersSummary, authToken, propertyID)
    yield put(actions.success(response))
  } catch (error) {
    yield put(actions.error(error))
    log(`Failed to fetch Property Users Summary`)
  }
}

function* watchFetchPropertyUsersSummary() {
  yield takeLatest(fetchPropertyUsersSummaryAction().type, fetchPropertyUsersSummary, false)
}

export default [watchFetchPropertyUsersSummary()]
