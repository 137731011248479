// @flow

import React, { Fragment } from 'react'
import Divider from '@material-ui/core/Divider'
import SummaryCard from '@Common/Cards/SummaryCard'
import ScheduleSettings from './ScheduleSettings'
import ScheduleType from './ScheduleType'
import { getSmartHeaderTabs } from '../smartUtils'
import Loader from '../../../Common/Loader'

import type { VacantScheduleProps, SwitchScheduleActiveFn } from './types'

export default class VacantSchedule extends React.Component<
  VacantScheduleProps,
  {}
> {
  getRows = () => {
    const { scheduleInfo, updateMode, updateSetpoints } = this.props
    const { thermostatMode, heatSetpoint, coolSetpoint } = scheduleInfo
    return [
      <ScheduleSettings
        thermostatMode={thermostatMode}
        heatSetpoint={heatSetpoint}
        coolSetpoint={coolSetpoint}
        updateMode={updateMode}
        updateSetpoints={updateSetpoints}
      />,
      <Divider />,
      <ScheduleType thermostatMode={thermostatMode} updateMode={updateMode} />
    ]
  }
  switchScheduleActive: SwitchScheduleActiveFn = (_e, checked) => {
    const { updateScheduleEnabled } = this.props
    updateScheduleEnabled(checked)
  }
  render() {
    const {
      isFetching,
      scheduleInfo,
      type,
      propertyId,
      scheduleEnabled
    } = this.props
    if (isFetching || scheduleInfo == null) {
      return <Loader />
    }
    return (
      <Fragment>
        <SummaryCard
          captionHeading="ZEGO SMART"
          mainHeading="Vacant Schedule"
          headerTabs={getSmartHeaderTabs(propertyId, type, scheduleEnabled)}
          items={this.getRows()}
          filterNames={[]}
          countText=""
          hideSummaryCount
          autoHeight
        />
      </Fragment>
    )
  }
}
