import {
  call,
  delay,
  select,
  takeLatest,
  put,
  takeEvery,
  all
} from 'redux-saga/effects'
import moment from 'moment'
import { DateTime } from 'luxon'
import { getToken, getUserId } from '../authorization/selectors'
import { getPropertyId, getPropertyTimeZone } from '../select/selectors'
import api from '../../utils/api'
import log from '../../utils/logger'

import { showSnackbar, hideSnackbar } from '../snackbar/actions'

import {
  failedToCreateProAmenity,
  updateAmenitySuccessPrefix,
  amenitiesFetchError,
  amenitiyFetchError,
  saveAmenityDraftSuffix,
  saveAmenityPublishedSuffix,
  failedToUpdateAmenity,
  genericDeleteSuccess,
  genericErrorMsg,
  reservationCreateSuccess,
  reservationCreateError,
  reservationDeleteSuccess,
  reservationDeleteError,
  blockDaySuccess,
  blockDayError,
  blockSlotSuccess,
  blockSlotError,
  deleteBlockDaySuccess,
  deleteBlockDayError,
  deleteBlockSlotSuccess,
  deleteBlockSlotError,
} from '../../utils/messages'

import {
  clearAmenity,
  createAmenityAction,
  createReservation,
  createReservationStart,
  createReservationSuccess,
  createReservationError,
  createFacilityBlockStart,
  createFacilityBlock,
  createFacilityBlockSuccess,
  createFacilityBlockError,
  deleteReservation,
  deleteReservationStart,
  deleteReservationSuccess,
  deleteReservationError,
  fetchAmenitiesAction,
  fetchAmenitiesStart,
  fetchAmenitiesError,
  fetchAmenitiesSuccess,
  fetchAmenityAction,
  fetchAmenityStart,
  fetchAmenitySuccess,
  fetchAmenityError,
  fetchReservationsAction,
  fetchReservationsStart,
  fetchReservationsSuccess,
  fetchReservationsError,
  updateAmenityOptimistically,
  updateAmenityRevert,
  updateAmenitySuccess,
  UPDATE_AMENITY,
  DELETE_AMENITY,
  setAmenityViewMode,
  deleteWaiverStart,
  deleteWaiver,
  deleteWaiverSuccess,
  deleteWaiverError,
  pinAmenityStart,
  pinAmenity,
  pinAmenitySuccess,
  pinAmenityError,
  unPinAmenityStart,
  unPinAmenity,
  unPinAmenitySuccess,
  unPinAmenityError,
  getAdmin,
  getAdminStart,
  getAdminSuccess,
  getAdminError,
  getAvailabilityAC,
  getAvailabilitySuccess,
  getAvailabilityForAmenitiesSuccess,
  getAvailabilityStart,
  getAvailabilityError,
  UPDATE_AMENITY_PHOTO_PIN,
  updateAmenityPhotoPinSuccess,
  updateAmenityPhotoPinError,
  deleteFacilityBlockStart,
  deleteFacilityBlock,
  deleteFacilityBlockSuccess,
  deleteFacilityBlockError,
} from './actions'

import { getAmenity, amenityForApi } from './selectors'

const displayNames = {
  name: 'Name',
  description: 'Description',
  totalOccupancy: 'Total Occupancy',
  maxPeoplePerReservation: 'Max # per reservation',
  maxReservationsPerDay: 'Max # of slots resident can reserve per day',
  rsvpRequired: 'Reservation Required',
  guestsAllowed: 'Guests allowed',
  duration: 'Length of time slot',
  padding: '30 Minute Buffer',
  schedule: 'Availability',
  resources: 'Extra Reservable Araes',
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
  startTime: 'Day Start Time',
  endTime: 'Day End Time',
  count: 'Quantity',
}

function formatErrors(errors) {
  if ('string' === typeof errors) {
    return errors
  }
  const messages = errors.map((fieldError = {}) => {
    if ('string' === typeof fieldError) {
      return fieldError
    }

    if ('msg' in fieldError && 'param' in fieldError) {
      const fields = fieldError.param.split('.').map((field) => {
        return field in displayNames ? displayNames[field] : field
      })

      return [...fields, fieldError.msg].join(': ')
    }

    return fieldError
  })

  return [failedToUpdateAmenity, ...messages].join('\n')
}

export function* fetchAmenity({ amenityId, propertyId, history }) {
  try {
    yield put(fetchAmenityStart())
    const authToken = yield select(getToken)
    const response = yield call(
      api.getProAmenity,
      authToken,
      propertyId,
      amenityId
    )

    yield put(fetchAmenitySuccess(response))
  } catch (error) {
    log(`Failed to fetch amenity pro. Error: ${error}`)
    history.push(`/properties/${propertyId}/amenities`)
    yield put(showSnackbar(amenitiyFetchError, 'error'))
    yield put(fetchAmenityError(error))
  }
}

function* fetchAmenities(params) {
  try {
    yield call(getAdminSaga)

    yield put(fetchAmenitiesStart())
    const authToken = yield select(getToken)
    const response = yield call(api.getProAmenities, authToken, params.propertyId)
    const allFacilities = response.data
    const facilities = Array.isArray(allFacilities) ? allFacilities.filter(f => !f.placeholder) : []

    const { headers } = response

    const preparedResponse = { data: facilities, headers }

    yield put(fetchAmenitiesSuccess(preparedResponse))
  } catch (error) {
    log(`Failed to fetch amenities pro. Error: ${error}`)
    yield put(showSnackbar(amenitiesFetchError, 'error'))
    yield put(fetchAmenitiesError(error))
  }
}

export function* getAdminSaga() {
  const authToken = yield select(getToken)
  const propertyId = yield select(getPropertyId)
  const adminUuid = yield select(getUserId)

  try {
    yield put(getAdminStart())

    const response = yield call(api.getAdmin, authToken, adminUuid, propertyId)
    yield put(getAdminSuccess(response))
  } catch(error) {
    log(`Failed to get admin. Error: ${error}`)
    yield put(getAdminError())
  }
}

function* createAmenity(params) {
  const { history, url, amenityParams } = params
  try {
    yield put(clearAmenity())

    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)
    const timeZone = yield select(getPropertyTimeZone)

    if (!propertyId.toString().match(/\d+/)) {
      history.push('/summary')
      return null
    }
    const response = yield call(
      api.createProAmenity,
      authToken,
      propertyId,
      {
        ...{ placeholder: true, timeZone },
        ...(!!amenityParams ? amenityParams : {}),
      }
    )

    const { facilityUuid } = response

    if (!facilityUuid) {
      log('no facilityUuid was returned ' + JSON.stringify(response))
      throw new Error('no facility uuid was returned')
    }

    const newUrl = `${url}/${facilityUuid}`.replace('//', '/')
    history.push(newUrl)

    yield put(
      setAmenityViewMode({
        readMode: false,
        editMode: true,
      })
    )
  } catch (e) {
    log('failed to create new (placeholder) amenity: ' + (e.message || e))
    yield put(showSnackbar(failedToCreateProAmenity, 'error'))
  }
}

export function* updateAmenitySaga({ update }) {
  const currentState = yield select(getAmenity)
  const authToken = yield select(getToken)
  const timeZone = yield select(getPropertyTimeZone)

  try {

    const { deletedPhotos, photos, facilityUuid, waiverDocs, waiverRequired } = update

    const newAtts = {
      ...update,
      placeholder: update.placeholder ?? false,
      published: update.published ?? false,
      rsvpRequired: update.rsvpRequired ?? false,
      reserveAheadRequired: update.reserveAheadRequired ?? false,
    }
    let apiAmenity = amenityForApi(newAtts)
    yield put(updateAmenityOptimistically(apiAmenity))

    const propertyId = yield select(getPropertyId)
    for (const photoUuid of deletedPhotos) {
      yield call(
        api.deleteAmenityPhoto,
        authToken,
        propertyId,
        { facilityUuid, photoUuid }
      );
    }

    yield all(photos.map((photo) =>
      call(
        api.addAmenityPhoto,
        authToken,
        propertyId,
        { file: photo, facilityUuid }
      )
    ))

    //  assigning waiverDocs[0] to a variable and then spreading the file object causes data loss
    yield all(waiverDocs.map((waiver) =>
      call(
        api.saveWaiver,
        authToken,
        { file: waiver, waiverRequired },
        propertyId,
        facilityUuid,
      )
    ))

    const response = yield call(
      api.updateProAmenity,
      authToken,
      propertyId,
      { ...apiAmenity, timeZone }
    )

    yield put(updateAmenitySuccess(response))

    const prefix = updateAmenitySuccessPrefix
    const published = newAtts.published ?? currentState.published ?? false
    const suffix = published
      ? saveAmenityPublishedSuffix
      : saveAmenityDraftSuffix
    const variant = published ? 'success' : 'warning'

    yield put(showSnackbar(`${prefix}${suffix}`, variant))
    yield put(
      setAmenityViewMode({
        readMode: true,
        editMode: false,
      })
    )
  } catch (error) {
    log(`Failed to update amenity. Error: ${error}`, { currentState, error })
    yield put(updateAmenityRevert(currentState))

    const { data: errors = [] } = error
    yield put(showSnackbar(formatErrors(errors), 'error'))
  }
}

export function* updateAmenityPhotoPinSaga({ update, amenityId }) {
  const currentState = yield select(getAmenity)
  const authToken = yield select(getToken)
  try {
    const { pinnedPhotoUuid } = update
    const propertyId = yield select(getPropertyId)
    const apiAmenity = { pinnedPhotoUuid, facilityUuid: amenityId }

    yield call(api.updateProAmenity, authToken, propertyId, apiAmenity)
    yield put(updateAmenityPhotoPinSuccess())

  } catch (error) {
    log(`Failed to update amenity. Error: ${error}`)
    yield put(updateAmenityRevert(currentState))
    yield put(updateAmenityPhotoPinError())
    yield put(showSnackbar('Unable to pin photo. Please try again later.', 'error'))
  }
}

export function* deleteAmenity({ id, history }) {
  try {
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)

    yield call(api.deleteProAmenity, authToken, propertyId, id)
    yield put(showSnackbar(genericDeleteSuccess, 'success'))
    yield delay(500)
    yield put(hideSnackbar())

    if (!propertyId.toString().match(/\d+/)) {
      history.push('/summary')
      return null
    }

    yield history.push(`/properties/${propertyId}/amenities`)
  } catch (error) {
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

export function* pinAmenitySaga(data) {
  const authToken = yield select(getToken)
  const propertyId = yield select(getPropertyId)
  const adminUuid = yield select(getUserId)
  const { facilityUuid } = data

  try {
    yield put(pinAmenityStart())
    yield call(api.pinAmenity, authToken, facilityUuid, adminUuid, propertyId)
    yield put(pinAmenitySuccess())

    yield call(fetchAmenities, { propertyId })
  } catch (error) {
    log(`Failed to pin amenity. Error: ${error}`)
    yield put(pinAmenityError())
  }
}

export function* unPinAmenitySaga(data) {
  const authToken = yield select(getToken)
  const propertyId = yield select(getPropertyId)
  const adminUuid = yield select(getUserId)
  const { facilityUuid } = data

  try {
    yield put(unPinAmenityStart())
    yield call(api.unPinAmenity, authToken, facilityUuid, adminUuid, propertyId)
    yield put(unPinAmenitySuccess())

    yield call(fetchAmenities, { propertyId })
  } catch (error) {
    log(`Failed to unpin amenity. Error: ${error}`)
    yield put(unPinAmenityError())
  }
}

export function* deleteWaiverSaga({ waiverUuid, amenityId }) {
  const authToken = yield select(getToken)
  const propertyId = yield select(getPropertyId)

  try {
    yield put(deleteWaiverStart())
    yield call(api.deleteWaiver, authToken, waiverUuid, propertyId, amenityId)
    yield put(deleteWaiverSuccess())


    yield put(showSnackbar('The waiver file has been successfully deleted', 'success'))
    yield delay(500)
    yield put(hideSnackbar())
  } catch (error) {
    log(`Failed to delete waiver. Error: ${error}`)
    yield put(deleteWaiverError(error))
  }
}

export function* fetchReservationsSaga({ propertyId, facilityUuid, startDate, endDate }) {
  try {
    yield put(fetchReservationsStart())
    const authToken = yield select(getToken)
    let response

    response = yield call(
      api.getProReservations,
      authToken,
      propertyId,
      facilityUuid,
      startDate,
      endDate
    )

    // TODO: (later ticket) make reducer and action to allow for multiple facility reservations
    const reservations = {
      [facilityUuid]: response,
      date: startDate,
    }

    yield put(fetchReservationsSuccess(reservations))
  } catch (error) {
    yield put(fetchReservationsError(error))
    log(`Failed to fetch reservations. Error ${error}`)
  }
}

export function* createReservationSaga({ reservation, residentName }) {
  const authToken = yield select(getToken)
  const propertyId = yield select(getPropertyId)
  const { facilityUuid, startDate } = reservation

  try {
    yield put(createReservationStart())
    yield call(api.createProAmenityReservation, authToken, propertyId, reservation)
    yield put(createReservationSuccess())

    // Need to fetch reservations to get latest into the store.
    yield call(fetchReservationsSaga, { propertyId, facilityUuid, startDate })
    yield call(getAvailabilitySaga, { facilityUuid, date: startDate})

    yield put(showSnackbar(reservationCreateSuccess(residentName), 'success'))
  } catch (error) {
    log(`Failed to create reservation. Error: ${error}`)
    yield put(createReservationError())
    yield put(showSnackbar(reservationCreateError, 'error'))
  }
}

export function* deleteReservationSaga({ reservation, message, residentName }) {
  const authToken = yield select(getToken)
  const propertyId = yield select(getPropertyId)
  const { reservationUuid, facilityUuid, startDate } = reservation

  try {
    yield put(deleteReservationStart())
    yield call(api.deleteProReservation, authToken, propertyId, reservationUuid, message)
    yield put(deleteReservationSuccess())

    // Need to fetch reservations to get latest into the store.
    yield call(fetchReservationsSaga, { propertyId, facilityUuid, startDate })
    yield call(getAvailabilitySaga, { facilityUuid, date: startDate })

    yield put(showSnackbar(reservationDeleteSuccess(residentName), 'success'))
  } catch (error) {
    log(`Failed to delete reservation. Error: ${error}`)
    yield put(deleteReservationError())
    yield put(showSnackbar(reservationDeleteError, 'error'))
  }
}

export function* createFacilityBlockSaga({ body, facilityUuid }) {
  const authToken = yield select(getToken)
  const propertyId = yield select(getPropertyId)

  const { startTime, endTime, startDate } = body

  const isDay = startTime === 0 && endTime === 2400

  const successMessage = isDay ? blockDaySuccess : blockSlotSuccess
  const errorMessage = isDay ? blockDayError : blockSlotError

  try {
    yield put(createFacilityBlockStart())
    yield call(api.createFacilityBlock, authToken, propertyId, facilityUuid, body)
    yield put(createFacilityBlockSuccess())

    yield put(showSnackbar(successMessage, 'success'))
    yield delay(1000)
    yield put(hideSnackbar())
    yield getAvailabilitySaga({ facilityUuid, date: startDate })

  } catch (error) {
      log(`Failed to create a facility block. Error: ${error}`)
      yield put(createFacilityBlockError())
      yield put(showSnackbar(errorMessage, 'error'))
      yield delay(1000)
      yield put(hideSnackbar())
  }
}

export function* deleteFacilityBlockSaga({ facilityUuid, body }) {
  const authToken = yield select(getToken)
  const propertyId = yield select(getPropertyId)

  const { startDate, startTime, endTime, facilityBlockUuid } = body
  const isDay = startTime === 0 && endTime === 2400

  const successMessage = isDay ? deleteBlockDaySuccess : deleteBlockSlotSuccess
  const errorMessage = isDay ? deleteBlockDayError : deleteBlockSlotError

  try {
    yield put(deleteFacilityBlockStart())
    yield call(api.deleteFacilityBlock, authToken, propertyId, facilityUuid, facilityBlockUuid)
    yield put(deleteFacilityBlockSuccess())

    yield put(showSnackbar(successMessage, 'success'))
    yield delay(1000)
    yield put(hideSnackbar())

    yield call(fetchReservationsSaga, { propertyId, facilityUuid, startTime, endTime })
    yield call(getAvailabilitySaga, { facilityUuid, date: startDate })

  } catch (error) {
    log(`Failed to delete a facility block. Error: ${error}`)
    yield put(deleteFacilityBlockError())
    yield put(showSnackbar(errorMessage, 'error'))
    yield delay(1000)
    yield put(hideSnackbar())
  }
}

export function* getAvailabilitySaga({ facilityUuid, date, endDate }, forAmenities = false) {
  const authToken = yield select(getToken)
  const propertyId = yield select(getPropertyId)

  try {
    yield put(getAvailabilityStart())
    const result = yield call(api.getAvailability, authToken, propertyId, facilityUuid, date, endDate)
    if (forAmenities) {
      yield put(getAvailabilityForAmenitiesSuccess())
      return result
    } else {
      yield put(getAvailabilitySuccess(result))
    }
  } catch (error) {
    log(`Failed to get availability. Error: ${error}`)
    yield put(getAvailabilityError())
  }
}

function* watchCreateAmenity() {
  yield takeEvery(createAmenityAction().type, createAmenity)
}
function* watchGetAvailability() {
  yield takeLatest(getAvailabilityAC().type, getAvailabilitySaga)
}
function* watchFetchAmenities() {
  yield takeLatest(fetchAmenitiesAction().type, fetchAmenities)
}
function* watchFetchAmenity() {
  yield takeLatest(fetchAmenityAction().type, fetchAmenity)
}
function* watchUpdateAmenity() {
  yield takeLatest(UPDATE_AMENITY, updateAmenitySaga)
}
function* watchUpdateAmenityPhotoPin() {
  yield takeLatest(UPDATE_AMENITY_PHOTO_PIN, updateAmenityPhotoPinSaga)
}
function* watchDeleteAmenity() {
  yield takeLatest(DELETE_AMENITY, deleteAmenity)
}
function* watchFetchReservations() {
  yield takeLatest(fetchReservationsAction().type, fetchReservationsSaga)
}
function* watchDeleteWaiver() {
  yield takeLatest(deleteWaiver().type, deleteWaiverSaga)
}
function* watchCreateReservation() {
  yield takeLatest(createReservation().type, createReservationSaga)
}
function* watchDeleteReservation() {
  yield takeLatest(deleteReservation().type, deleteReservationSaga)
}
function* watchPinAmenity() {
  yield takeLatest(pinAmenity().type, pinAmenitySaga)
}
function* watchUnPinAmenity() {
  yield takeLatest(unPinAmenity().type, unPinAmenitySaga)
}
function* watchGetAdmin() {
  yield takeLatest(getAdmin().type, getAdminSaga)
}
function* watchCreateFacilityBlock() {
  yield takeLatest(createFacilityBlock().type, createFacilityBlockSaga)
}
function* watchDeleteFacilityBlock() {
  yield takeLatest(deleteFacilityBlock().type, deleteFacilityBlockSaga)
}

export default [
  watchCreateAmenity(),
  watchCreateFacilityBlock(),
  watchCreateReservation(),
  watchDeleteAmenity(),
  watchDeleteFacilityBlock(),
  watchDeleteReservation(),
  watchDeleteWaiver(),
  watchFetchAmenities(),
  watchFetchAmenity(),
  watchGetAvailability(),
  watchFetchReservations(),
  watchGetAdmin(),
  watchPinAmenity(),
  watchUnPinAmenity(),
  watchUpdateAmenity(),
  watchUpdateAmenityPhotoPin(),
]
