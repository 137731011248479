// @flow

import React, { Fragment } from 'react'
import Btn from '@Common/Button'
import ZegoInput from '../Common/Input'
import { FlexItem } from '@Common/Flex'
import { Title } from './styles'

import type { changeModeFn, handleChangeFn } from './types'

type Props = {
  password: string,
  passwordConfirm: string,
  changeMode: changeModeFn,
  handleChange: handleChangeFn,
  handleReset: () => mixed,
  error: string,
  working: boolean
}

const PasswordReset = ({
  password,
  passwordConfirm,
  changeMode,
  handleChange,
  handleReset,
  error,
  working
}: Props) => (
  <Fragment>
    <FlexItem alignCenter>
      <Title>Reset Your Password</Title>
      Please enter and confirm a new password.
    </FlexItem>
    <FlexItem>
      <ZegoInput
        fullWidth
        placeholder="password"
        type="password"
        value={password}
        onChange={handleChange}
        id="password"
      />
      <ZegoInput
        fullWidth
        placeholder="confirm password"
        type="password"
        value={passwordConfirm}
        onChange={handleChange}
        id="passwordConfirm"
      />
    </FlexItem>
    <FlexItem fullWidth>
      <Btn
        onClick={() => {
          changeMode('login')
        }}>
        back to login
      </Btn>
      <Btn
        fullWidth
        onClick={handleReset}
        classes={{ label: 'login-label' }}
        animate={working && !error ? 'animate' : ''}
        name="Reset Password"
        secondary
      />
    </FlexItem>
  </Fragment>
)

export default PasswordReset
