import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { HubIcon } from '@icons'
import Flex from '@Common/Flex'
import { commands } from 'zego-shared/store/devices/sagas/utils/deviceCommands'
import { withTheme } from 'emotion-theming'

const commandsName = [
  commands.INCLUSION,
  commands.EXCLUSION,
  commands.UPDATE,
  commands.RESTART
]

const HubCommandIcons = ({
  width,
  height,
  showDescription = false,
  isAnyCommandLoading,
  commandsBeingSent,
  pushHubCommand,
  updateFirmware,
  isPropertyListCommands,
  theme
}) => {
  const extraProps = showDescription ? {} : { fill: '#b0b6bd' }
  return commandsName.map(name => (
    <Flex alignCenter direction="column" key={name} style={{ padding: '10px' }}>
      {commandsBeingSent[name] ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <HubIcon
            onClick={() => !isAnyCommandLoading && pushHubCommand(name)}
            filter={isAnyCommandLoading ? 'grayscale(1)' : undefined}
            name={name}
            width={width}
            height={height}
            {...extraProps}
          />
          {showDescription && (
            <div
              style={{
                marginTop: '10px',
                fontSize: theme.custom.font.size.sm
              }}>
              {name}
            </div>
          )}
        </React.Fragment>
      )}
    </Flex>
  ))
}

export default withTheme(HubCommandIcons)
