import {
  createFetchActions,
  createRefreshActions,
  createFetchAndRefreshActions
} from '../../../helpers/actions/creators'

export const USERSSECTION = 'UNITUSERS'
export const SEND_ACTIVATION_EMAIL = 'SEND_ACTIVATION_EMAIL'
export const GET_UNIT_USERS_BALANCE = 'GET_UNIT_USERS_BALANCE'

export const {
  fetchAction: fetchUsersAction,
  fetchStartAction: fetchUsersStart,
  fetchSuccessAction: fetchUsersSuccess,
  fetchErrorAction: fetchUsersError,
  refreshAction: refreshUsers,
  refreshStartAction: refreshUsersStart,
  refreshSuccessAction: refreshUsersSuccess,
  refreshErrorAction: refreshUsersError
} = createFetchAndRefreshActions(USERSSECTION)

export const fetchUsers = id => fetchUsersAction({ id })

export const sendActivationEmail = id => ({
  type: SEND_ACTIVATION_EMAIL,
  id
})

export const getUnitUsersBalance = (response) => ({
  type: GET_UNIT_USERS_BALANCE,
  response
})
