import { call, select, takeLatest, put, all } from 'redux-saga/effects'
import { getToken } from '../../../authorization/selectors'
import {
  SEND_ACTIVATION_EMAIL,
  fetchUsers as fetchUsersAction,
  refreshUsers as refreshUsersAction,
  fetchUsersStart,
  refreshUsersStart,
  fetchUsersSuccess,
  refreshUsersSuccess,
  fetchUsersError,
  refreshUsersError,
  getUnitUsersBalance
} from '../actions'
import api from '../../../../utils/api'
import log from '../../../../utils/logger'
import { showSnackbar } from '../../../snackbar/actions'
import { fetchUserSettings } from '../../../settings/actions'
import {
  noUnitSelected,
  sendEmailError,
  sendEmailConfirmation
} from '../../../../utils/messages'
import { getUnitId } from '../../../select/selectors'

const getUsersActions = isRefreshing => ({
  start: isRefreshing ? refreshUsersStart : fetchUsersStart,
  success: isRefreshing ? refreshUsersSuccess : fetchUsersSuccess,
  error: isRefreshing ? refreshUsersError : fetchUsersError
})

export function* getUnitBalance(users, authToken) {
  try {
    const unitBalances = yield all(
      users.map(user => call(api.getPaymentBalance, user.user_id, authToken))
    )
    return unitBalances
  } catch (error) {
    console.log('Error', error)
    return null
  }
}

export function* fetchUsers(isRefreshing, { id }) {
  const actions = getUsersActions(isRefreshing)
  try {
    yield put(actions.start())
    const authToken = yield select(getToken)
    const unitID = id ? id : yield select(getUnitId)

    if (unitID === -1) throw new Error(noUnitSelected)
    const users = yield call(api.getUsers, unitID, authToken)

    const unitBalance = yield call(getUnitBalance, users, authToken)

    yield put(actions.success(users))
    yield all(users.map(user => put(fetchUserSettings(user.user_id))))
    yield put(getUnitUsersBalance(unitBalance))
  } catch (error) {
    yield put(actions.error(error))
    log(`Failed to fetch summary`)
  }
}

export function* sendActivationEmail({ id }) {
  try {
    const authToken = yield select(getToken)

    yield call(api.sendActivationEmail, id, authToken)
    yield put(showSnackbar(sendEmailConfirmation, 'success'))
  } catch (error) {
    yield put(showSnackbar(sendEmailError, 'error'))
  }
}

function* watchFetchUsers() {
  yield takeLatest(fetchUsersAction().type, fetchUsers, false)
}

function* watchActivateUser() {
  yield takeLatest(SEND_ACTIVATION_EMAIL, sendActivationEmail)
}

export default [watchFetchUsers(), watchActivateUser()]
