import { call, select, takeLatest, put } from 'redux-saga/effects'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  failedToResendVendorCode,
  failedToInvalidateVendorCode,
  resendVendorCodeSuccess,
  invalidateVendorCodeSuccess
} from '../../../utils/messages'
import { getToken } from '../../authorization/selectors'
import { showSnackbar } from '../../snackbar/actions'
import {
  fetchLockCodesVendorsAction,
  fetchLockCodesVendorsStart,
  fetchLockCodesVendorsSuccess,
  fetchLockCodesVendorsError,
  SEND_LOCKCODE,
  setSearchTerm,
  fetchVendorCodesStart,
  fetchVendorCodes as fetchVendorCodesAction,
  fetchVendorCodesError,
  fetchVendorCodesSuccess,
  fetchVendorCodeStart,
  fetchVendorCodeAction,
  fetchVendorCodeError,
  fetchVendorCodeSuccess,
  fetchVendorCodeUnitsStart,
  fetchVendorCodeUnitsSuccess,
  fetchVendorCodeUnitsAction,
  fetchVendorCodeUnitsError,
  resendCode,
  resendCodeError,
  resendCodeSuccess,
  invalidateCode,
  invalidateCodeSuccess,
  invalidateCodeError,
  fetchVendorError,
  fetchVendorStart,
  fetchVendorSuccess,
  fetchVendorAction
} from '../actions'

export function* getAllVendors({ propertyId, page, name }) {
  try {
    yield put(fetchLockCodesVendorsStart())
    const authToken = yield select(getToken)

    if(name) yield put(setSearchTerm(name))
    const response = yield call(
      api.getAllVendors,
      propertyId,
      authToken,
      page,
      name
    )
    yield put(fetchLockCodesVendorsSuccess(response))
  } catch (error) {
    yield put(fetchLockCodesVendorsError())
    log(`Failed to fetch visitors`)
  }
}

export function* sendLockCode({ userId }) {
  try {
    const authToken = yield select(getToken)
    yield call(api.sendLockCode, userId, authToken)
    yield put(showSnackbar('Lock code sent successfully', 'success'))
  } catch (error) {
    yield put(showSnackbar('Error sending lock code', 'error'))
    log(`Failed to send lock code`)
  }
}

export function* fetchVendorCodes({
  vendorId,
  propertyId,
  searchTerm,
  query = {}
}) {
  try {
    yield put(fetchVendorCodesStart())
    const authToken = yield select(getToken)
    let codes
    if (searchTerm) {
      codes = yield call(
        api.searchVendorCodes,
        authToken,
        propertyId,
        searchTerm
      )
    } else {
      codes = yield call(
        api.getVendorCodes,
        authToken,
        vendorId,
        propertyId,
        query
      )
    }
    yield put(fetchVendorCodesSuccess(codes))
  } catch (error) {
    yield put(fetchVendorCodesError())
    log(error)
  }
}

export function* fetchVendorCode({ codeId, propertyId }) {
  try {
    yield put(fetchVendorCodeStart())
    const authToken = yield select(getToken)
    const codes = yield call(api.getVendorCode, authToken, codeId, propertyId)
    yield put(fetchVendorCodeSuccess(codes))
  } catch (error) {
    yield put(fetchVendorCodeError(error))
    log(error)
  }
}

export function* fetchVendorCodeUnits({ codeId, propertyId }) {
  try {
    yield put(fetchVendorCodeUnitsStart())
    const authToken = yield select(getToken)
    const codes = yield call(
      api.getVendorCodeUnits,
      authToken,
      codeId,
      propertyId
    )
    yield put(fetchVendorCodeUnitsSuccess(codes))
  } catch (error) {
    yield put(fetchVendorCodeUnitsError(error))
    log(error)
  }
}

export function* resendVendorCode({ codeId, propertyId }) {
  try {
    const authToken = yield select(getToken)
    yield call(api.resendVendorCode, authToken, codeId, propertyId)
    yield put(resendCodeSuccess())
    yield put(showSnackbar(resendVendorCodeSuccess, 'success'))
  } catch (error) {
    yield put(resendCodeError(error))
    yield put(showSnackbar(failedToResendVendorCode, 'error'))
    log(error)
  }
}

export function* invalidateVendorCode({ codeId, propertyId, vendorId }) {
  try {
    const authToken = yield select(getToken)
    yield call(api.invalidateVendorCode, authToken, codeId, propertyId)
    yield put(invalidateCodeSuccess())
    yield put(fetchVendorCodesAction(vendorId, propertyId))
    yield put(showSnackbar(invalidateVendorCodeSuccess, 'success'))
  } catch (error) {
    yield put(invalidateCodeError(error))
    yield put(showSnackbar(failedToInvalidateVendorCode, 'error'))
    log(error)
  }
}

export function* fetchVendor({ vendorId, propertyId }) {
  try {
    yield put(fetchVendorStart())
    const authToken = yield select(getToken)
    const vendor = yield call(api.getVendor, authToken, vendorId, propertyId)
    yield put(fetchVendorSuccess(vendor))
  } catch (error) {
    yield put(fetchVendorError(error))
  }
}

function* watchFetchVendorCodes() {
  yield takeLatest(fetchVendorCodesAction().type, fetchVendorCodes)
}

function* watchFetchVendorCode() {
  yield takeLatest(fetchVendorCodeAction().type, fetchVendorCode)
}

function* watchFetchVendorCodeUnits() {
  yield takeLatest(fetchVendorCodeUnitsAction().type, fetchVendorCodeUnits)
}

function* watchResendCode() {
  yield takeLatest(resendCode().type, resendVendorCode)
}

function* watchInvalidateCode() {
  yield takeLatest(invalidateCode().type, invalidateVendorCode)
}

function* WatchGetAllVendors() {
  yield takeLatest(fetchLockCodesVendorsAction().type, getAllVendors)
}

function* WatchSendLockCode() {
  yield takeLatest(SEND_LOCKCODE, sendLockCode)
}

function* WatchFetchVendor() {
  yield takeLatest(fetchVendorAction().type, fetchVendor)
}

export default [
  watchFetchVendorCodes(),
  watchFetchVendorCode(),
  watchFetchVendorCodeUnits(),
  watchResendCode(),
  watchInvalidateCode(),
  watchFetchVendorCodes(),
  WatchGetAllVendors(),
  WatchSendLockCode(),
  WatchFetchVendor()
]
