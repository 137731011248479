// @flow
import React from 'react'
import type { Node } from 'react'

import { Container, Item } from './styles'

type GridProps = {
  children: Node
}

type GridItemProps = {
  children: Node,
  columnStart: number,
  columnSpan: number,
  rowStart?: number,
  rowSpan?: number
}

export const Grid = (props: GridProps) => {
  const { children, ...rest } = props
  return <Container {...rest}>{children}</Container>
}

export const GridItem = (props: GridItemProps) => {
  const { children, ...rest } = props
  return <Item {...rest}>{children}</Item>
}

export default Grid
