import { connect } from 'react-redux'
import ResidentSummary from './ResidentSummary'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { fetchPropertyUsersSummary } from 'zego-shared/store/users/propertyUsersSummary/actions'
import { fetchResidentUsersAction, fetchNextResidentUsersAction, fetchResidentSummary } from 'zego-shared/store/users/property/actions'
import { getResidentData, getResidentDataCount, getResidentSummary } from '../../../store/Property/selectors'
import { getPropertyId } from 'zego-shared/store/select/selectors'
import { getChatEnabled } from 'zego-shared/store/settings/propertySettings/selectors'
import { getPropertyResidentsCount } from 'zego-shared/store/users/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { getLeaseRenewalEnabled } from 'zego-shared/store/settings/selectors'

const fetchingSelector = createIsFetchingSelector(['property', 'users'])

export const mapStateToProps = state => ({
  chatEnabled: getChatEnabled(state),
  isFetching: fetchingSelector(state),
  residents: getResidentData(state),
  residentSummary: getResidentSummary(state),
  propertyId: getPropertyId(state),
  filteredResidentCount: getResidentDataCount(state),
  residentsCount: getPropertyResidentsCount(state),
  leaseRenewalEnabled: getLeaseRenewalEnabled(state)
})

export const mapDispatchToProps = (
  dispatch,
  { history, match: { url, params: { propertyId } } }
) => ({
  fetchData: () => {
    dispatch(fetchResidentUsersAction({ id: propertyId }))
    dispatch(fetchPropertyUsersSummary(propertyId))
    dispatch(fetchResidentSummary(propertyId))
  },
  fetchResidentUsersAction: ({ propertyId, page, params }) => {
    dispatch(fetchResidentUsersAction({ id: propertyId, page, params }))
  },
  fetchNextResidentUsersAction: ({ propertyId, page, params }) => {
    dispatch(fetchNextResidentUsersAction({ id: propertyId, page, params }))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(ResidentSummary))
