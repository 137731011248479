import styled from '@emotion/styled'
import WideBaseCard from '@Common/Cards/BaseCard/WideBaseCard'

export const WideBaseCardStyled = styled(WideBaseCard)`
  height: 209px;
`

export const BalancePan = styled('div')`
  background: rgb(230, 246, 240);
  background: linear-gradient(
    315deg,
    rgba(230, 246, 240, 1) 70%,
    rgba(255, 255, 255, 1) 70%
  );
  width: 130%;
  height: 130%;
  text-align: right;
  align-items: right;
  justify-content: right;
  margin-right: -15%;
  margin-top: -4%;
  padding-top: 2.5em;
  padding-right: 8em;

  & p {
    float: right;
  }
`

export const LeasePan = styled('div')`
  width: 100%;
  font-size: 0.8em;
  justify-content: space-between;
  padding: 25px 10px;
`
export const Title = styled.h3`
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: -25px;
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.lg};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
`

export const MonthlyRentStyled = styled.p`
  font-size: ${({ theme }) => theme.custom.font.size.md};
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  margin: 0;
`

export const IconTextStyled = styled.div`
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  margin-top: 0.3em;
  text-align: center;
`
