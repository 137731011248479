import { connect } from 'react-redux'
import Vehicles from './Vehicles'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { fetchUserVehiclesAction } from 'zego-shared/store/users/profile/actions'

import {
  getVehicles,
  getVehiclesCount
} from 'zego-shared/store/users/selectors'

const VehiclesWithFetchData = withFetchData(Vehicles)
const fetchingSelector = createIsFetchingSelector(['user'])

const mapStateToProps = state => {
  return {
    isFetching: fetchingSelector(state),
    vehicles: getVehicles(state),
    count: getVehiclesCount(state)
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { userId }
    }
  }
) => {
  return {
    fetchData: () => {
      dispatch(fetchUserVehiclesAction({ userId }))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehiclesWithFetchData)
