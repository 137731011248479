import styled from '@emotion/styled'

export const UnreadCount = styled('div')`
  height: 18px;
  width: 18px;
  border-radius: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.grey.main};
  color: ${({ theme }) => theme.palette.common.white};
  font-size: ${({ theme }) => theme.custom.font.size.xs};
`

export const AnnouncementTitle = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.md};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
`
export const AnnouncementText = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  font-weight: ${({ theme }) => theme.custom.font.weight.thin};
  color: ${({ theme }) => theme.palette.text.secondary};
  line-height: 1.5em;
`

export const ViewMore = styled('a')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  font-weight: ${({ theme }) => theme.custom.font.weight.thin};
  color: ${({ theme }) => theme.palette.text.secondary};
`
export const CenteredText = styled('center')`
  font-size: ${({ theme }) => theme.custom.font.size.xs};
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-top: 0.5em;
`
