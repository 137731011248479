// @flow

import get from 'lodash/get'
import moment from 'moment'
// $FlowDisableNextLine
import { getDate } from '../../../../admin/assets/src/utils'
import { getPaginationData } from '../../helpers/selectors/data'

export const getPackageData = (state: Object) => {
  const packages = get(state, ['packages', 'data', 'byId']) || {}
  const packageIds = get(state, ['packages', 'data', 'allIds']) || []
  const unitsById = get(state, ['select', 'units', 'byId'], {})

  return packageIds.map(packageId => {
    const {
      deliver_to_first_name,
      deliver_to_last_name,
      received_by_first_name,
      received_by_last_name,
      unit_id,
      tracking_number,
      status,
      id,
      inserted_at
    } = packages[packageId]
    return {
      recipientName: `${deliver_to_first_name || ''} ${deliver_to_last_name ||
        ''}`,
      unit: unitsById[unit_id] ? `${unitsById[unit_id].name || ''}` : '',
      receivedBy: `${received_by_first_name || ''} ${received_by_last_name ||
        ''}`,
      dateReceived: getDate(inserted_at),
      trackingNumber: `${tracking_number || ''}`,
      status: `${status}`,
      packageId: id
    }
  })
}

export const getPackageReminder = (state: Object) => {
  const reminderTime = get(state, [
    'settings',
    'propertySettings',
    'package_reminder_time'
  ])

  if (reminderTime) {
    const splitTime = reminderTime.split(' ')
    return moment(`${splitTime[1]}:${splitTime[0]}`, 'HH:mm')
  }

  return null
}

export const getPackagesPaginationInfo = (state: Object) =>
  getPaginationData(state, ['packages', 'headers'])
