import { combineReducers } from 'redux'
import summary from 'zego-shared/store/summary/reducers'
import users from 'zego-shared/store/users/property/reducers'
import batteries from 'zego-shared/store/batteries/reducers'
import hubs from 'zego-shared/store/hubs/reducers'

const property = combineReducers({
  summary,
  users,
  batteries,
  hubs
})

export default property
