// @flow

import React from 'react'
import SetupStep from './SetupStep'

import type { SetupStepProps } from './types'

const DeviceConnected = ({ devices }: SetupStepProps) => (
  <SetupStep
    title={`${devices.length} Devices Connected`}
    listItems={devices}
    secondaryListTitle="Online"
  />
)

export default DeviceConnected
