import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  updateScheduleItem,
  updateScheduleEnabled
} from 'zego-shared/store/schedule/actions'
import {
  getSelectedPropertyVacantScheduleID,
  getSelectedPropertyVacantScheduleInfo
} from 'zego-shared/store/schedule/selectors'

import VacantSchedule from './VacantSchedule'

const mapStateToProps = (state, { match: { params } }) => {
  return {
    propertyId: params.propertyId,
    scheduleId: getSelectedPropertyVacantScheduleID(state),
    scheduleInfo: getSelectedPropertyVacantScheduleInfo(state)
  }
}

const defaultsToAdd = {
  home_time: null,
  away_time: "00:00:00"
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateMode: newMode => {
    const update = { away_thermostat_mode: newMode, ...defaultsToAdd }
    dispatch(updateScheduleItem(update))
  },
  updateSetpoints: ({ heatSetpoint, coolSetpoint }) => {
    const update = {
      away_cool_setpoint: coolSetpoint,
      away_heat_setpoint: heatSetpoint,
      home_cool_setpoint: coolSetpoint,
      home_heat_setpoint: heatSetpoint,
      ...defaultsToAdd
    }
    dispatch(updateScheduleItem(update))
  },
  updateScheduleEnabled: enabled => {
    dispatch(updateScheduleEnabled(enabled))
  }
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    (stateProps, dispatchProps, ownProps) => ({
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
      fetchData() {
        dispatchProps.fetchData(stateProps.scheduleId)
      }
    })
  )(VacantSchedule)
)
