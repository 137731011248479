import Cookies from 'universal-cookie'

export const setAdminCookie = () => {
  const cookieUser = 'registeredUser' // Cookie to indicate registered user
  const cookieType = 'currentUserType' // Cookie to indicate user type
  const cookieUserValue = "true" // Value indicates a registered user
  const cookieTypeValue = 1 // Value indicates that the user type is a pm/admin
  const domain = window.location.hostname.replace(/(^payments\.)|(^engage\.)/i, "")
  
  let expirationTime = 5184000 // Two months in seconds
  expirationTime = expirationTime * 1000 // Converts expiration time to milliseconds
  let date = new Date()
  const dateTimeNow = date.getTime()
  date.setTime(dateTimeNow + expirationTime) // Sets expiration time (Time now + two months)
  const dateString = date.toUTCString(); // Converts milliseconds to UTC time string

  // Set the cookies
  document.cookie = `${cookieUser}=${cookieUserValue}; expires=${dateString}; path=/; domain=${domain}; sameSite=lax` // Sets cookie for all subdomains
  document.cookie = `${cookieType}=${cookieTypeValue}; expires=${dateString}; path=/; domain=${domain}; sameSite=lax`
}

export const isAdminCookieSet = () => {
  const cookies = new Cookies()
  // Make sure user is admin
  if (cookies.get('currentUserType') !== 1) {
    return false
  }
  // Check to see if the cookie is set
  if (cookies.get('registeredUser') !== null) {
    return true
  }
  return false
}
