// @flow
import { connect } from 'react-redux'
import withFetchData from 'zego-shared/views/withFetchData'
import { fetchUnit } from 'zego-shared/store/select/actions'
import {
  fetchUsers,
  sendActivationEmail
} from 'zego-shared/store/users/unit/actions'
import { updateUserSetting } from 'zego-shared/store/settings/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { getResidentsCardInfo } from '../../../store/Select/selectors'
import {
  getUnitUsersSummaryData,
  getUnitActiveResidentsCount,
  getResidentsCount,
  hasUnitData,
  getUnitBalance
} from 'zego-shared/store/users/selectors'
import { getAllUserSettings } from 'zego-shared/store/settings/selectors'
import { isSmartEnabled } from 'zego-shared/store/settings/propertySettings/selectors'

import Unit from './Unit'

const fetchingSelector = createIsFetchingSelector([
  'unit',
  'devices',
  'unitusers',
  'usersettings'
])

const mapStateToProps = state => ({
  isFetching: fetchingSelector(state),
  unitCardInfo: getResidentsCardInfo(state),
  users: getUnitUsersSummaryData(state),
  residentsCount: getResidentsCount(state),
  activeResidentsCount: getUnitActiveResidentsCount(state),
  unitBalance: getUnitBalance(state),
  hasData: hasUnitData(state),
  userSettings: getAllUserSettings(state),
  isSmartEnabled: isSmartEnabled(state)
})

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  return {
    fetchData: () => {
      dispatch(fetchUsers(params.unitId))
      dispatch(fetchUnit(params.unitId))
    },
    sendActivationEmail: id => {
      dispatch(sendActivationEmail(id))
    },
    toggleResidentZegoSmartEnabled: (userId, residentZegoSmartEnabled) => {
      dispatch(updateUserSetting({ residentZegoSmartEnabled, userId }))
    }
  }
}

const UnitWithFetchData = withFetchData(Unit)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitWithFetchData)
