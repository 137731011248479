import {
  fetchMimoChecklistSettingsStart,
  fetchMimoChecklistSettingsSuccess,
  fetchMimoChecklistSettingsError,
  fetchSavedMimoChecklistSettingsStart,
  fetchSavedMimoChecklistSettingsSuccess,
  fetchSavedMimoChecklistSettingsError
} from '../actions'

const initialState = {
  isSubmittingSettings: false,
  isError: '',
  isLoadingSettings: false,
  savedChecklistSettings: {},
  isSavedChecklistError: '',
  isSubmitSuccess: false
}
const getChecklistSettings = (state = initialState, { type, payload, error }) => {

  switch (type) {
    case fetchMimoChecklistSettingsStart().type:
      return {
        ...state,
        isSubmittingSettings: true,
        isSubmitSuccess: false
      }
    case fetchMimoChecklistSettingsSuccess().type: {
      return {
        ...state,
        isSubmittingSettings: false,
        isError: '',
        isSubmitSuccess: true
      }
    }
    case fetchMimoChecklistSettingsError().type:
      return {
        ...state,
        isSubmittingSettings: false,
        isError: error,
        isSubmitSuccess: false
      }
    case fetchSavedMimoChecklistSettingsStart().type:
      return {
        ...state,
        isLoadingSettings: true,
        isSubmitSuccess: false
      }
    case fetchSavedMimoChecklistSettingsSuccess().type:
      return {
        ...state,
        isLoadingSettings: false,
        savedChecklistSettings: payload
      }
    case fetchSavedMimoChecklistSettingsError().type:
      return {
        ...state,
        isLoadingSettings: false,
        isSavedChecklistError: error
      }
    default:
      return state
  }
}
export default getChecklistSettings
