// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import LoginSection from './LoginSection'
import MainFlex from '@Common/Flex'
import { Card } from './styles'
import Snackbar from '../Snackbar/SnackbarContainer'
import type { RouterHistory } from 'react-router-dom'

type State = {
  email: string,
  password: string,
  userId: string,
  working: boolean
}

type Props = {
  isAuth: boolean,
  loginEmail: (string, string, string) => mixed,
  fetchResourcesAndRedirectAfterLogin: RouterHistory => void,
  error: string,
  history: RouterHistory,
  theme: any,
  displayErrorMsg: (error: string) => mixed
}

class Login extends React.Component<Props, State> {
  state = {
    email: '',
    password: '',
    userId: '',
    working: false
  }

  handleLogin = () => {
    this.setWorking(true)
    const { loginEmail, displayErrorMsg } = this.props
    const { email, password, userId } = this.state
    if (userId) {
      loginEmail(email, password, userId)
    } else {
      displayErrorMsg('user id is required')
    }
  }

  setWorking = (working: boolean) => {
    this.setState({ working })
  }

  handleChange = ({
    currentTarget: { id, value }
  }: SyntheticEvent<HTMLInputElement>) => {
    this.setState(oldState => {
      return {
        ...oldState,
        [id]: value
      }
    })
  }

  render() {
    const {
      isAuth,
      error,
      history,
      fetchResourcesAndRedirectAfterLogin
    } = this.props
    const { working, email, password, userId } = this.state

    if (isAuth) {
      fetchResourcesAndRedirectAfterLogin(history)
    }

    if (error && working) {
      this.setWorking(false)
    }

    return (
      <React.Fragment>
        <MainFlex
          fullWidth
          fullHeight
          alignCenter
          justifyCenter
          onKeyPress={({ key }) => key === 'Enter' && this.handleLogin()}>
          <Card>
            <MainFlex
              fullHeight
              direction="column"
              alignCenter
              justifySpaceBetween>
              <LoginSection
                isUserSpoofing
                handleChange={this.handleChange}
                handleLogin={this.handleLogin}
                email={email}
                password={password}
                userId={userId}
                error={error}
                working={working}
              />
            </MainFlex>
          </Card>
        </MainFlex>
        <Snackbar />
      </React.Fragment>
    )
  }
}

export default withTheme(Login)
