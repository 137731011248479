// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from './SnackbarContent'
import { SnackbarStyles } from './styles'

type Props = {
  hideSnackbar: boolean,
  message: string,
  show: boolean,
  variant: string
}

const CustomizedSnackbar = ({
  hideSnackbar,
  message,
  show,
  variant
}: Props) => (
  <div>
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={show}
      autoHideDuration={5000}
      onClose={hideSnackbar}>
      <SnackbarContent
        onClose={hideSnackbar}
        variant={variant}
        message={message}
      />
    </Snackbar>
  </div>
)

export default withStyles(SnackbarStyles)(CustomizedSnackbar)
