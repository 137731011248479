// @flow
import React, { Component } from 'react'
import { withTheme } from 'emotion-theming'
import Tabs from '@Common/Cards/Tabs'
import Header from '@Common/Header'

import Search from '../../Common/Search/Search'
import ScrollToTop from '@Common/ScrollToTop'
import {
  ButtonsWrapper,
  HeaderTitleWrapper,
  SearchBarSection,
  SearchBarWrapper,
  TabsWrapper,
  ButtonStyled,
  SearchBarTextFieldClass,
  TableWrapper
} from './styles'

type Props = {
  header: string,
  title: string,
  tabs: ?Array<{
    name: string,
    route: string
  }>,
  buttons: Node,
  onCreateClick: () => void,
  onSearch: () => void,
  table: Node,
  theme: any
}

class BaseLayout extends Component<Props> {
  // This is a layout component and should never need to be updated
  // shouldComponentUpdate() {
  //   return false
  // }

  render() {
    const {
      header,
      title,
      tabs = [],
      buttons,
      onCreateClick,
      onSearch,
      table
    } = this.props
    return (
      <React.Fragment>
        <HeaderTitleWrapper>
          <div>{header}</div>
          <Header blackText>{title}</Header>
        </HeaderTitleWrapper>
        <TabsWrapper>
          <Tabs tabs={tabs} tabStyle={1} marginRight={'46px'} />
        </TabsWrapper>
        <ButtonsWrapper>{buttons}</ButtonsWrapper>
        <SearchBarSection>
          {onCreateClick && (
            <ButtonStyled id="createNewBtn" onClick={onCreateClick}> + create new</ButtonStyled>
          )}
          <SearchBarWrapper>
            <Search
              elementId="groupMessageSearchBar"
              data={[]}
              isGroupMsg
              hasSearchData={false}
              isFetching={false}
              onResultClick={() => console.log('clicked')}
              searchData={onSearch}
              textFieldClassName={SearchBarTextFieldClass}
              minSearchLength={-1}
            />
          </SearchBarWrapper>
        </SearchBarSection>
        <TableWrapper>{table}</TableWrapper>
        <ScrollToTop />
      </React.Fragment>
    )
  }
}

export default withTheme(BaseLayout)
