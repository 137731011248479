import {
  createFetchActions,
  createUpdateActions
} from '../../helpers/actions/creators'
export const FETCH_COMMUNITY_THREAD = 'COMMUNITYTHREAD'
export const DELETE_COMMUNITY_THREAD = 'DELETECOMMUNITYTHREAD'
export const DELETE_COMMUNITY_THREAD_CONFIRM = 'DELETECOMMUNITYTHREADCONFIRM'
export const ATTEND_EVENT = 'ATTENDEVENT'
export const UNATTEND_EVENT = 'UNATTENDEVENT'
export const ATTEND_EVENT_SUCCESS = 'ATTENDEVENTSUCCESS'
export const UNATTEND_EVENT_SUCCESS = 'UNATTENDEVENTSUCCESS'
export const CREATE_COMMENT = 'CREATECOMMENT'
export const DELETE_COMMENT = 'DELETECOMMENT'
export const DELETE_COMMENT_SUCCESS = 'DELETECOMMENTSUCCESS'
export const LIKE_POST = 'LIKEPOST'
export const UNLIKE_POST = 'UNLIKEPOST'
export const UNLIKE_POST_SUCCESS = 'UNLIKEPOSTSUCCESS'
export const LIKE_POST_SUCCESS = 'LIKEPOSTSUCCESS'

export const deleteCommunityThread = (threadId, type, history) => ({
  type: DELETE_COMMUNITY_THREAD,
  threadId,
  threadType: type,
  history
})

export const deleteComment = (threadId, commentKey) => ({
  type: DELETE_COMMENT,
  threadId,
  commentKey
})

export const {
  fetchAction: fetchCommunityThreadAction,
  fetchStartAction: fetchCommunityThreadStart,
  fetchSuccessAction: fetchCommunityThreadSuccess,
  fetchErrorAction: fetchCommunityThreadError
} = createFetchActions(FETCH_COMMUNITY_THREAD)

export const fetchCommunityThread = threadId =>
  fetchCommunityThreadAction({ threadId })

export const attendEvent = threadId => ({
  type: ATTEND_EVENT,
  threadId
})

export const unattendEvent = threadId => ({
  type: UNATTEND_EVENT,
  threadId
})

export const unattendEventSuccess = userId => ({
  type: UNATTEND_EVENT_SUCCESS,
  userId
})

export const attendEventSuccess = (userId, name, picture) => ({
  type: ATTEND_EVENT_SUCCESS,
  userId,
  name,
  picture
})

export const {
  updateOptimistcallyAction: createCommentOptimistcally,
  updateRevertAction: createCommentRevert,
  updateSuccessAction: createCommentSuccess
} = createUpdateActions(CREATE_COMMENT, true, true)

export const createComment = (threadId, comment) => ({
  type: CREATE_COMMENT,
  threadId,
  comment
})

export const deleteCommentSuccess = () => ({
  type: DELETE_COMMENT_SUCCESS
})

export const likePost = threadId => ({
  type: LIKE_POST,
  threadId
})

export const unlikePost = threadId => ({
  type: UNLIKE_POST,
  threadId
})

export const unlikePostSuccess = userId => ({
  type: UNLIKE_POST_SUCCESS,
  userId
})

export const likePostSuccess = (userId, name, picture) => ({
  type: LIKE_POST_SUCCESS,
  userId,
  name,
  picture
})
