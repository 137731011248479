// @flow
import React, { Fragment } from 'react'
import { FabButton } from '@Common/Button'
import Avatar from '@Common/Avatar'
import { CommonIcon, CommunityFeedIcons } from '@icons'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import CloseIcon from '@material-ui/icons/Close'
import {
  ButtonSection,
  TitleBlock,
  Title,
  Caption,
  Attend,
  AttendBlock,
  LikesAttending,
  DropdownList,
  List
} from './styles'
import Button from '@Common/Cards/CardButton'
import Flex, { FlexItem } from '@Common/Flex/FlexDirection'
import { ListItem } from '@material-ui/core'
import { ListItemText } from '@material-ui/core'
import { ListItemSecondaryAction } from '@material-ui/core'
import { Link } from 'react-router-dom'
import type { Theme } from '../../../../theme'

type Props = {
  title: string,
  theme: Theme,
  goToEdit: () => void,
  image?: Object,
  type: string,
  likesOrAttending: number,
  propertyId: string,
  attendeesImage: string,
  userPicture?: string,
  likesBy: Object[],
  attendEvent: () => void,
  unattendEvent: () => void,
  isLikedOrisAttending: boolean,
  action: string,
  goBack: () => void,
  openDialog: () => void,
  togglePostLike: () => void
}

const likedBy = (likes, propertyId) => {
  return likes.map(like => (
    <List key={like.user_id}>
      <ListItem>
        <Link
          to={`/properties/${propertyId}/user-management/profile/${
            like.user_id
          }`}>
          <Avatar picture={like.picture} size={'2.3em'} />{' '}
        </Link>
        <ListItemText
          primary={like.name}
          secondary={like.unit ? like.unit.name : ''}
        />
        {like.role === 'resident' ? (
          <ListItemSecondaryAction>
            <Link
              to={`/properties/${propertyId}/communication/chat/live-chat/users/${
                like.user_id
              }`}>
              Chat
            </Link>
          </ListItemSecondaryAction>
        ) : (
          ''
        )}
      </ListItem>
    </List>
  ))
}

const TitleSection = ({
  title,
  theme,
  goBack,
  goToEdit,
  openDialog,
  image,
  type,
  likesBy,
  propertyId,
  userPicture,
  likesOrAttending,
  attendeesImage,
  attendEvent,
  unattendEvent,
  isLikedOrisAttending,
  togglePostLike
}: Props) => {
  const isEvent = type === 'events'
  return (
    <TitleBlock
      image={image}
      columnStart={image ? 5 : 1}
      columnSpan={image ? 9 : 13}
      rowStart={1}
      rowSpan={6}>
      <Caption image={image}>
        {isEvent ? (
          <Avatar
            picture={attendeesImage}
            size={'1em'}
            showTrail
            hideShadow={likesOrAttending ? false : true}
          />
        ) : (
          <CommunityFeedIcons
            name={isLikedOrisAttending ? 'heart-fill' : 'heart'}
            height="1em"
            width="1em"
            fill={theme.palette.primary.green.mint}
            onClick={togglePostLike}
            style={{ cursor: 'pointer' }}
          />
        )}
        &nbsp;{isEvent && ' \u00a0'}
        <LikesAttending>
          <span>
            {isEvent
              ? `${likesOrAttending} attending`
              : `${likesOrAttending} likes`}
          </span>
          <DropdownList>{likedBy(likesBy, propertyId)}</DropdownList>
        </LikesAttending>
      </Caption>

      <Title>{title}</Title>
      <ButtonSection columnStart={6} columnSpan={1} rowStart={1} rowSpan={3}>
        <Fragment>
          <FabButton
            onClick={goBack}
            component={() => (
              <KeyboardBackspace
                height="0.5em"
                width="0.5em"
                style={{ color: theme.palette.primary.dark }}
              />
            )}
            size="2.7em"
            background={theme.palette.secondary.yellow.solarFlare}
          />
          <br />
          <FabButton
            onClick={goToEdit}
            component={() => (
              <CommonIcon
                name="edit"
                width="1em"
                height="1em"
                fill={theme.palette.primary.navy.midnight}
              />
            )}
            size="2.7em"
            background="transparent"
            outlined
          />
          <br />
          {
            <FabButton
              onClick={openDialog}
              component={() => (
                <CloseIcon
                  height="0.5em"
                  width="0.5em"
                  style={{ color: theme.palette.primary.navy.midnight }}
                />
              )}
              size="2.7em"
              background="transparent"
              outlined
            />
          }
        </Fragment>
      </ButtonSection>
      <AttendBlock
        image={image}
        columnStart={2}
        columnSpan={image ? 9 : 13}
        rowStart={3}
        rowSpan={1}>
        <Attend>
          {isEvent ? (
            <Flex style={{ height: '4em' }}>
              <FlexItem flex={1} displayFlex alignItemsCenter>
                <Button
                  background={theme.palette.blue.main}
                  width={'5.5em'}
                  text={isLikedOrisAttending ? 'UNATTEND' : 'ATTEND'}
                  onClick={isLikedOrisAttending ? unattendEvent : attendEvent}
                />
              </FlexItem>
            </Flex>
          ) : (
            <Avatar hideShadow picture={userPicture} size="4em" />
          )}
        </Attend>
      </AttendBlock>
    </TitleBlock>
  )
}

export default TitleSection
