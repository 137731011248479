// @flow
import React from 'react'
import type { Node } from 'react'
import { Link } from 'react-router-dom'
import { FooterLink } from './styles'

type Props = {
  text: string,
  onClick?: () => {},
  children?: Node,
  to: string,
  className: string,
  elementId?: string
}

const CardFooterLink = ({
  className,
  text,
  onClick,
  children,
  elementId,
  to = '/'
}: Props) => (
  <FooterLink id={elementId} className={className} data-testid="footer-link" onClick={onClick}>
    <Link to={to}> {children ? children : text}</Link>
  </FooterLink>
)

export default CardFooterLink
