// @flow

import get from 'lodash/get'
import { getPaginationData } from '../../helpers/selectors/data'

export const getGroups = (state: Object, noAtSign: boolean) => {
  const groups = get(state, ['groupMessaging', 'groups', 'data'])
  return groups
    ? groups.filter(group => group.type === 'group').map(group => ({
        name: `${noAtSign ? group.name : `@${group.name}`}`,
        description: group.description,
        residentCount: group.user_count,
        id: group.id
      }))
    : null
}

export const getAllGroupResidentsCount = (state: Object) => {
  const groups = getGroups(state, false)
  const residentCount = groups
    ? groups.find(group => group.name === '@All')
    : ''

  return residentCount ? residentCount.residentCount : ''
}

export const getGroupResidents = (state: Object, id: string) => {
  const groups = get(state, ['groupMessaging', 'groups', 'data'])

  if (groups) {
    const singleGroup = groups.filter(
      group => group.type === 'group' && group.id === id
    )[0]

    if (singleGroup && singleGroup.users) {
      return singleGroup.users.map(({ user, unit }) => ({
        name: user.name ? user.name : user.email,
        unit: unit.name
      }))
    }
  }
  return null
}

export const getGroupLabel = (state: Object, id: string) => {
  const groups = get(state, ['groupMessaging', 'groups', 'data'])
  if (groups) {
    const singleGroup = groups.filter(
      group => group.type === 'group' && group.id === id
    )[0]

    if (singleGroup && singleGroup.users) {
      return singleGroup.name
    }
  }
  return null
}

export const getGroupsDropDownData = (state: Object) => {
  const groups = get(state, ['groupMessaging', 'groups', 'data'])
  return groups
    ? groups.map(group => ({
        id: group.id,
        name: group.name.replace(/([A-Z])/g, ' $1').trim()
      }))
    : null
}

export const hasGroups = (state: Object) => {
  return get(state, ['groupMessaging', 'groups', 'data']) != null
}

export const getGroupsPaginationInfo = (state: Object) =>
  getPaginationData(state, ['groupMessaging', 'groups'])

export const getSelectedGroupsUsers = (state: Object) => {
  const users = get(state, ['groupMessaging', 'groups', 'selectedGroupsUsers'])

  return users
    ? users.map(({ user, unit }) => ({
        picture: user.picture,
        name: user.name,
        unit: unit ? unit.name : '',
        id: user.user_id
      }))
    : null
}

export const getGroupName = (state: Object) => {
  const group = get(state, ['groupMessaging', 'groups', 'selectedGroup'])
  return group ? group.name : ''
}

export const groupsToGroupsData = (groups: Object): Object[]  => {
  return groups.data.map(group => ({
    value: group.id,
    label: `@${group.name}`,
    count: group.user_count || 0
  }))
}
