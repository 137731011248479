import uniq from 'lodash/uniq'
import transform from 'lodash/transform'
import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'

const publishedAmenityEditWarnings = [
  {
    name: 'scheduleChanged',
    text: 'Are you sure you want to decrease the operating hours?',
    multiWarningText: 'Decrease the operating hours?',
    warningText: 'By decreasing the operating hours you will cancel any current reservations that no longer fit within the set hours and mark those slots as “CLOSED”.'
  },
  {
    name: 'padding',
    text: 'Are you sure you want to change your buffer times?',
    multiWarningText: 'Remove buffers?',
    warningText: 'By adding or removing the reservation buffer times you will cancel any current reservations for this Amenity from this moment forward and the schedule will be updated with the new time slot availability'
  },
  {
    name: 'duration',
    text: 'Are you sure you want to change the duration of your time slots?',
    multiWarningText: 'Change the time slot duration?',
    warningText: 'By changing the duration of your time slots you will cancel any current reservations for this Amenity from this moment forward and the schedule will be updated with the new time slot availability'
  },
  {
    name: 'scheduleClosed',
    text: 'Are you sure you want to close this day?',
    multiWarningText: 'close this day?',
    warningText: 'By closing this Amenity for the day you will cancel any current reservations for this day moving forward and mark this day of the week as “CLOSED”.'
  },
  {
    name: 'extraAreaQuantity',
    text: 'Are you sure you want to decrease your extra reservable spaces?',
    multiWarningText: 'Remove extra reservable spaces?',
    warningText: 'By decreasing your extra reservable spaces you will cancel any current reservations with extra reservable spaces outside of the limit.'
  },
  {
    name: 'rsvpRequired',
    text: 'Are you sure you want to turn off ‘Reserving’ this Amenity?',
    multiWarningText: 'turn off ‘Reserving’ this Amenity?',
    warningText: 'By turning off the ‘Reserve’ feature of this Amenity you will cancel any current and future reservations.'
  },
  {
    name: 'maxReservationsPerDay',
    text: 'Are you sure you want to decrease the number of allowed reservable slots?',
    multiWarningText: 'decrease the number of allowed reservable slots?',
    warningText: 'By decreasing the number of allowed reservable slots will cancel any current reservations that exceed the allowed amount.'
  },
  {
    name: 'maxPeoplePerReservation',
    text: 'Are you sure you want to decrease the allowed guests?',
    multiWarningText: 'decrease the allowed guests?',
    warningText: 'By decreasing the number of allowed guests you will cancel any current reservations that exceed the allowed amount.'
  }
]

const difference = (origObj, newObj) => {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0
    return transform(newObj, function (result, value, key) {
      if (!isEqual(value, origObj[key])) {
        let resultKey = isArray(origObj) ? arrayIndexCounter++ : key
        result[resultKey] = (isObject(value) && isObject(origObj[key]))
          ? changes(value, origObj[key])
          : { originalValue: origObj[key], newValue: value }
      }
    })
  }
  return changes(newObj, origObj)
}

const getChangedItems = (item, itemOriginal) => {
  const diff = difference(itemOriginal, item)
  const diffTypes = []
  for (const key of Object.keys(diff)) {
    switch (key) {
      case 'schedule':
        let scheduleDiff = diff[key]
        for (const day in scheduleDiff) {
          if (Object.hasOwnProperty.call(scheduleDiff, day)) {
            const dayDiff = scheduleDiff[day];
            if (typeof dayDiff.endTime !== 'undefined' && dayDiff.endTime.newValue === 0) {
              diffTypes.push('scheduleClosed')
            } else if (typeof dayDiff.startTime !== 'undefined' && dayDiff.startTime.originalValue < dayDiff.startTime.newValue && (typeof dayDiff.endTime === 'undefined' || dayDiff.endTime.originalValue !== 0)) {
              diffTypes.push('scheduleChanged')
            } else if (typeof dayDiff.endTime !== 'undefined' && dayDiff.endTime.originalValue > dayDiff.endTime.newValue) {
              diffTypes.push('scheduleChanged')
            }
          }
        }
        break;
      case 'extraAreaQuantity':
        let extraAreaQuantityDiff = diff[key]
        if (typeof extraAreaQuantityDiff.originalValue !== 'undefined' && ((extraAreaQuantityDiff.newValue < extraAreaQuantityDiff.originalValue) || !extraAreaQuantityDiff.newValue)) {
          diffTypes.push(key);
        }
        break;
      case 'rsvpRequired':
        let rsvpRequired = diff[key]
        if (rsvpRequired.newValue === false) {
          diffTypes.push(key);
        }
        break;
      case 'maxReservationsPerDay':
        const maxReservationsPerDay = diff[key]
        if (maxReservationsPerDay.originalValue === 'unlimited' || maxReservationsPerDay.newValue === 1) {
          diffTypes.push(key);
        }
        break;
      case 'maxPeoplePerReservation':
        const maxPeoplePerReservation = diff[key]
        if (maxPeoplePerReservation.newValue < maxPeoplePerReservation.originalValue) {
          diffTypes.push(key);
        }
        break;
      case 'minMultiDayReservation':
        const minMultiDayReservation = diff[key]
        if (minMultiDayReservation.newValue < minMultiDayReservation.originalValue) {
          diffTypes.push(key);
        }
        break;
      case 'maxMultiDayReservation':
        const maxMultiDayReservation = diff[key]
        if (maxMultiDayReservation.newValue < maxMultiDayReservation.originalValue) {
          diffTypes.push(key);
        }
        break;
      case 'guestsAllowed':
        const guestsAllowed = diff[key]
        if (!guestsAllowed.newValue) {
          diffTypes.push('maxPeoplePerReservation');
        }
        break;
      default:
        diffTypes.push(key);
    }
  }
  return uniq(diffTypes)
}

export const getPublishedAmenityEditWarnings = (item, itemOriginal) => {
  if (!item || !itemOriginal) {
    return []
  }
  return getChangedItems(item, itemOriginal)
    .map(name => publishedAmenityEditWarnings.find((msg) => msg.name === name))
    .filter(msg => msg !== undefined)
}

const validationError = (message?: string) => {
  return { isValid: false, message }
}

export const validateReservation = (
  selectedTimeslot: Object,
  reservation: Object,
  availability: Object,
  reservations: Array<Object>,
  amenity: Object,
) => {
  const { name, maxReservationsPerDay, reservationType } = amenity

  if (reservationType === 'multiDay') {
    return { isValid: true, message: null }
  }

  const { groupSize, includeResource, resident } = reservation
  
  if (!availability || !availability?.timeSlots || !resident) {
    // The user isn't finished picking a timeslot/resident yet
    return validationError(null)
  }

  const { userId: residentUuid } = resident
  if (!residentUuid) {
    return validationError('Please select a resident for this reservation.')
  }

  if (maxReservationsPerDay && reservations.filter((s) => (s.residentUuid === residentUuid)).length >= maxReservationsPerDay) {
    return validationError('The selected resident has already reached the maximum reservations for today')
  }

  // The following validations only apply to Hourly amenities
  if (reservationType === 'hourly') {

    const { startTime, startDate } = selectedTimeslot

    if (!startTime || !startDate || !amenity || !availability || !availability?.timeSlots || !resident) {
      // The user isn't finished picking a timeslot/resident yet
      return validationError(null)
    }
    const { timeSlots } = availability
    const requestedSlot = timeSlots.find((s) => (s.startTime === startTime && s.startDate === startDate))

    if (!requestedSlot) {
      return validationError('Unable to find an available reservation slot.')
    }

    const { numAvailable, numResourcesAvailable, isPast } = requestedSlot

    if (isPast) {
      return validationError('The selected time has already passed.')
    }

    if (reservations &&
      timeSlots.find((r) => (r.startTime === startTime && r.startDate === startDate && r.residentUuid === residentUuid))) {
      return validationError(`The selected resident has already reserved this time.`)
    }

    if (groupSize > numAvailable) {
      return validationError(`${name} has ${numAvailable} open spots for this time.`)
    }

    if (includeResource && numResourcesAvailable === 0) {
      const { resources: { name: resName } } = amenity
      return validationError(`All ${resName} have been reserved for this time.`)
    }
  }
  return { isValid: true, message: null }
}

export const getTimeSlotForTime = (startTime: number, timeSlots: Array<Object>) => {
  if (!timeSlots) {
    return null;
  }

  let timeSlot = null
  if (timeSlots) {
    timeSlot = timeSlots.find((s) => (s.startTime === startTime))
  }

  return timeSlot
}
