import styled from '@emotion/styled'
import { css } from 'emotion'
import MaterialButton from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import backgroundImage from '../../../images/group_messaging_bg.png'

export const ButtonsWrapper = styled('div')`
  grid-column: 12;
  grid-row: 1 / span 3;
  display: flex;
  flex-direction: column;
`

export const HeaderTitleWrapper = styled('div')`
  grid-column: 1 / span 6;
  grid-row: 1 / span 2;
  &:after {
    content: ' ';
    background-image: url(${backgroundImage});
    background-repeat: no-repeat;
    background-size: 640px 100%;
    width: 100%;
    height: 91%;
    left: 0;
    position: absolute;
    z-index: -15;
  }
`

export const TitleStyled = styled('div')`
  padding-left: 0.25em;
  padding-top: 0.25em;
  font-size: 2em;
  color: ${({ theme }) => theme.palette.primary.green.mint};
`

export const SearchBarSection = styled('div')`
  display: flex;
  align-items: center;
  padding-left: 2em;
  grid-column: 1 / span 10;
  grid-row: 3 / span 1;
  margin-left: -2em;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.common.white};
  height: 125px;
`

export const SearchBarWrapper = styled('div')`
  flex: 1;
  margin: 2em;
`

export const SearchBarTextFieldClass = css`
  background-color: white !important;
  border: 1px solid lightgray !important;
`

export const TabsWrapper = styled('div')`
  grid-column: 9 / span 3;
  grid-row: 2;
`

export const ButtonStyled = styled(MaterialButton)`
  background-color: ${({ theme }) => theme.palette.blue.main};
  color: ${({ theme }) => theme.palette.common.white};
  height: 25px;
  width: 154px;
  padding: 5px;
  max-height: 25px;
  min-height: 25px;
  line-height: 14px;
  border-radius: 0;
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.green.mint};
    color: ${({ theme }) => theme.palette.primary.navy.midnight};
  }
`

export const TableWrapper = styled('div')`
  grid-column: 2 / span 9;
  grid-row: 4 / span 12;
  border-top-left-radius: 5px;
  margin-left: 50px;
  padding-left: 50px;
  min-height: 600px;
  height: 68vh;
  background-color: ${({ theme }) => theme.palette.common.white};
`

export const GroupMessagingRowStyled = styled('div')`
  &.highlight {
    background-color: rgb(89, 192, 155, 0.15);
    font-weight: ${({ theme }) => theme.custom.font.weight.bold};
    color: ${({ theme }) => theme.palette.primary.green.seeFoam};
  }

  &.text-left {
    text-align: left;
    justify-content: flex-start;
  }

  &.text-right {
    justify-content: flex-end;
    text-align: right;
  }

  &.text-center {
    justify-content: center;
  }

  &.weight-light {
    font-weight: ${({ theme }) => theme.custom.font.weight.light};
  }

  &.weight-normal {
    font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  }

  &.text-primary {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  &.text-grey {
    color: ${({ theme }) => theme.palette.text.grey};
  }

  &.text-black {
    color: ${({ theme }) => theme.palette.text.black};
  }
  height: 3em;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`

export const GroupMessagingColumnStyled = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  ${({ index }) => index > 2 && index < 7 && `text-align: center;`};
  ${({ index }) => index === 7 && `text-align: right;`};
  min-width: 45px;

  &.text-right {
    justify-content: flex-end;
    text-align: right;
  }
`

export const TemplatesRowStyled = styled('div')`
  color: ${({ theme, index }) =>
    index === 0 ? 'black' : theme.palette.text.primary};
  ${({ index }) => index === 2 && `text-align: right;`};
`

export const TemplatesColumnStyled = styled('div')`
  ${({ index }) => index === 2 && `text-align: right;`};
`

export const GroupsRowStyled = styled('div')`
  color: ${({ theme, index }) => theme.palette.text.primary};
  ${({ index }) => index === 2 && `text-align: right;`};
  ${({ theme, index }) =>
    index === 0 && `color: ${theme.palette.common.black}`};
`

export const GroupsColumnStyled = styled('div')`
  ${({ index }) => index === 2 && `text-align: right;`};
  ${({ theme, index }) =>
    index === 2 && `font-size: ${theme.custom.font.size.sm}`};
`

export const BackButtonStyled = styled(MaterialButton)`
  background-color: ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
  width: 3em;
  height: 3em;
  box-shadow: none;

  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.secondary.yellow.solarFlare};
  }
`

export const TemplatesButtonStyled = styled(MaterialButton)`
  background-color: ${({ theme }) => theme.palette.common.white};
  width: 3em;
  height: 3em;
  margin-top: 1em;
  margin-bottom: 1em;
  border: ${({ theme }) =>
    `2.5px solid ${theme.palette.secondary.yellow.solarFlare}`};
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
    border: ${({ theme }) =>
      `3px solid ${theme.palette.secondary.yellow.golden}`};
  }

  box-shadow: none;
`

export const GroupsButtonStyled = styled(MaterialButton)`
  background-color: ${({ theme }) => theme.palette.common.white};
  width: 3em;
  height: 3em;
  border: ${({ theme }) =>
    `3px solid ${theme.palette.secondary.yellow.solarFlare}`};
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
    border: ${({ theme }) =>
      `3px solid ${theme.palette.secondary.yellow.golden}`};
  }

  box-shadow: none;
`

export const Background = styled('div')`
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position-y: 10em;
  position: absolute;
  z-index: -15;
`

export const TableContainer = styled('div')`
  height: auto;
  position: absolute;
  width: 60%;
  padding-bottom: 1em;
`

export const FadedMenuTitle = styled('div')`
  color: ${({ theme }) => theme.palette.neutrals.warmGray};
  font-size: ${({ theme }) => theme.custom.font.xs};
  margin-right: 1.2em;
`

export const StyledRow = styled('div')`
  height: 4em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  ${({ index, theme }) =>
    index === 2 &&
    `
    text-align: center;
    justify-content: center;`};

  ${({ index, theme }) =>
    index === 3 &&
    `
    display: flex;
  justify-content: flex-end;
  color: ${theme.palette.primary.green.mint};
  `};
`

export const StyledColumn = styled('div')`
  height: 4em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: center;
  white-space: nowrap;

  ${({ index, theme }) =>
    index === 2 &&
    `
    font-size: ${theme.custom.font.size.lg};
    color: ${theme.palette.common.black};
  `};

  ${({ index, theme }) =>
    index === 3 &&
    `
  color: ${theme.palette.primary.green.mint};
  text-align: center;
  justify-content: center;
  `};

  ${({ index, theme }) =>
    index === 5 &&
    `
    display: flex;
  justify-content: flex-end;
  `};
`

export const StyledLink = styled(Link)`
  text-decoration: underline;
`
