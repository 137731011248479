import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import Button from '@Common/Button/FabButton'
import { CommonIcon } from '@icons'

const styles = theme => ({
  FilterSwitchBase: {
    marginBottom: "6px",
    marginLeft: "20px",
    '& button': {
      boxShadow: theme.shadows[1]
    },
    '&$FilterChecked': {
      color: theme.palette.common.white,
      '& + $FilterBar': {
        backgroundColor: theme.palette.primary.green.seeFoam
      }
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp
    })
  },
  FilterChecked: {
    transform: 'translateX(40px)',
    '& + $FilterBar': {
      opacity: 1,
      border: 'none'
    }
  },
  FilterBar: {
    borderRadius: 25,
    left: 40,
    width: 80,
    height: 38,
    marginTop: -22,
    backgroundColor: theme.palette.neutrals.lightGray,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  FilterIcon: {
    width: 38,
    height: 38
  },
});

const StyledSwitch = (props) => {

  const classes = props.classes;
  const theme = props.theme;

  return (
    <Switch
      onChange={props.handleClick}
      checked={props.isChecked}
      id="showFilters"
      classes={{
        switchBase: classes.FilterSwitchBase,
        bar: classes.FilterBar,
        icon: classes.FilterIcon,
        checked: classes.FilterChecked,
      }}
      icon={
        <Button
          background={theme.palette.common.white}
          outlined={false}
          size="38px"
          component={() =>
            <CommonIcon
              name="filter"
              width="1.2em"
              height="1.2em"
              fill={theme.palette.primary.navy.midnight}
              strokeWidth={1}
            />}
        />
      }
      checkedIcon={
        <Button
          background={theme.palette.primary.green.mint}
          outlined={false}
          size="38px"
          component={() =>
            <CommonIcon
              name="filter"
              width="1.2em"
              height="1.2em"
              fill={theme.palette.primary.navy.midnight}
              strokeWidth={1}
            />}
        />
      }
    />
  )
}

export default withStyles(styles)(StyledSwitch);
