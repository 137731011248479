// @flow

import React from 'react'
import SetupStep from './SetupStep'

const checklistItems = [
  'Ensure the hub is still on.',
  'Make sure the devices you want to connect have been properly installed.',
  'Check the device has power.',
  'Connect one device at a time.'
]

const AddDevice = () => (
  <SetupStep title="Checklist" listItems={checklistItems} />
)

export default AddDevice
