import styled from '@emotion/styled'

export const Wrapper = styled('div')`
  grid-column: 11 / span 4;
  grid-row: 1 / span 12;
  position: relative;
  padding: 4em 0em 5em;
  ${({ imageSrc }) => imageSrc && `background-image: url(${imageSrc})`};
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: right;
`

export const Image = styled('span')`
  position: absolute;
  pointer-events: none;
  width: 85%;
  height: 100%;
  right: 0;
  bottom: 0;
`

export const SideMenu = styled('div')`
  width: 100%;
  * {
    font-size: 100%;

    @media only screen and (min-width: 2000px) {
      font-size: 105%;
    }
  }
`
