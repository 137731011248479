// @flow
import React from 'react'
import ReactSelect from 'react-select'
import { Divider, Grid, Switch } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { CommunityIcon } from '@icons'
import moment from 'moment'

import {
  AvailabilityDayHeader,
  AmenityReservationsDiv,
  AvailabilityHoursDropdown,
  AvailabilityReservationsDetails,
  AvailabilityReservationsLabel,
  AvailabilityResourceDetails,
  BlockDayLabelSpan,
  BlockLabelSpan,
  ReservationDeleteButton,
  ReservationResidentAvatar,
  ReservationResource,
  ResidentName,
  ResidentEmail,
  ReservationGuest,
  ReservationGuestCount,
  FlexContainer,
  DeleteReservationConfirmation,
  ResidentMessageContainer,
  ResidentMessage,
  ReservationContainer,
  ReservationAvatarContainer,
  ReservationDetailsContainer,
  ReservationDeleteButtonContainer, ReservationDetailsResidentName,
} from '../AmenityShared/styles'

import { MultiDayReservationDetails } from './style.js'
import Flex, {FlexItem} from "../../Common/Flex";
import { formatMilitaryTime } from "../../../utils/scheduleUtils";

type Props = {
  item: Object,
  isAmenityOpen: boolean,
  availability: Object,
  residents: Object,
  reservations: Array<Object>,
  selectedAvailabilityDate: Object,
  selectedAvailabilityTimeSlot: Object,
  setAvailabilityTime: (number) => typeof undefined,
  isAmenityDayBlocked: boolean,
  isAmenitySlotBlocked: boolean,
  setAmenityDayBlocked: (Object, Object) => typeof undefined,
  setAmenitySlotBlocked: (Object) => typeof undefined,
  setSelectedReservation: (Object) => typeof undefined,
  selectedReservation: Object,
  handleResidentMessageChange: (Object) => typeof undefined,
}

type State = {
  reservationList: Array<Object>,
  resourceCount: number,
  timeSlotBlocked: boolean,
}

export default class AmenityReservations extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      reservationList: [],
      resourceCount: 0,
      timeSlotBlocked: false,
      dayBlocked: false,
    }
  }

  getTimeSlotSelectLabel = (slot: Object) => {
    if (!slot || !slot.startTimeShortString || !slot.endTimeShortString) {
      return null
    }
    return `${slot.startTimeShortString} - ${slot.endTimeShortString}`
  }

  // ReactSelect expects a 'value' prop that looks like
  // one of it's option items. It uses the label to find the selected item
  // rather than finding the index we can just wrap the selected item with the label
  wrapSelectedSlotForListValue = () => {
    const { selectedAvailabilityTimeSlot } = this.props
    if (!selectedAvailabilityTimeSlot || !selectedAvailabilityTimeSlot.startTimeShortString) {
      return null
    }
    const getLabel = (slot) => this.getTimeSlotSelectLabel(slot)
    return {
      value: selectedAvailabilityTimeSlot,
      label: getLabel(selectedAvailabilityTimeSlot)
    }
  }

  handleTimeSlotChange = (e: Object) => {
    const { startTime } = e.value
    this.props.setAvailabilityTime(startTime)
  }

  getCurrentSlotInfo = () => {
    let isSlotBlocked = false
    let enableSlotBlockToggle = false
    let isDayBlocked = false;
    let enableDayBlockToggle = true

    const {
      availability,
      selectedAvailabilityTimeSlot,
      item: { reservationType = '' } = {}
    } = this.props

    if (availability && availability?.timeSlots) {
      const { timeSlots = [] } = availability
      const blockedCount = timeSlots.filter(t => (t.facilityBlockUuid)).length
      const futureCount = timeSlots.filter(t => !t.isPast).length
      enableDayBlockToggle = futureCount > 0
      isDayBlocked = blockedCount && blockedCount === timeSlots.length
      enableSlotBlockToggle = enableDayBlockToggle && !isDayBlocked && reservationType === 'fullFacility'
    }

    if (selectedAvailabilityTimeSlot && !isDayBlocked) {
      const { facilityBlockUuid, status, isPast } = selectedAvailabilityTimeSlot
      isSlotBlocked = status === 'closed' && facilityBlockUuid
      enableSlotBlockToggle = (status !== 'closed' || isSlotBlocked) && !isPast
    }

    return {
      isSlotBlocked,
      enableSlotBlockToggle,
      isDayBlocked,
      enableDayBlockToggle,
    }
  }

  getReservationDisplayList = () : Array<Object> => {
    const {
      selectedAvailabilityTimeSlot,
      reservations = [],
    } = this.props
    if (selectedAvailabilityTimeSlot && selectedAvailabilityTimeSlot.startTime) {
      const {
        startTime: selectedStartTime,
        endTime: selectedEndTime
      } = selectedAvailabilityTimeSlot

      return reservations.filter((r)=> r.startTime >= selectedStartTime && r.endTime <= selectedEndTime)
    }
    
    return reservations
  }

  reservationList = () => {
    const {selectedReservation} = this.props
    const reservationIsSelected = (reservationUuid) => selectedReservation
      && selectedReservation.reservationUuid === reservationUuid

    let currentTime = null
    let currentDate = null
    const { availability } = this.props
    if(availability) {
      const { currentFacilityTime } = availability
      if(currentFacilityTime) {
        currentTime = currentFacilityTime.currentTime
        currentDate = currentFacilityTime.currentDate
      }
    }

    const appendUiValues = (reservation) => {
      const {groupSize, reservationUuid, residentUuid} = reservation
      const {picture, display} = this.props.residents.find(resUser => resUser.userId === residentUuid) || {}
      const started = reservation.startDate === currentDate && reservation.startTime >= currentTime
      const guestCount = groupSize-1
      return {
        ...reservation,
        picture,
        started,
        residentName: display,
        guests: guestCount === 1 ? 'and 1 Guest' : `and ${guestCount} Guests`,
        isSelected: reservationIsSelected(reservationUuid)
      }
    }

    const isMultiDay = this.props.item.reservationType === 'multiDay'
    const reservationResource = ({reservationUuid, includeResource}) => includeResource ? <span>
        &nbsp;&nbsp;|&nbsp;&nbsp;
      <ReservationResource
        id={`reservation-${reservationUuid}-resource`}> 1 {this.props.item.resources.name}
        </ReservationResource>
      </span> : <></>

    return (
      <List>
        { this.getReservationDisplayList().map(r => appendUiValues(r))
            .map(r =>
              <ListItem>
                <ReservationContainer>
                  <ReservationAvatarContainer>
                    <ReservationResidentAvatar src={r.picture} alt="Resident Avatar"/>
                  </ReservationAvatarContainer>
                  <ReservationDetailsContainer selected={reservationIsSelected(r.reservationUuid)}>
                    <Flex direction='column' style={{justifyContent: 'flex-start'}}>
                      <Flex style={{alignItems: 'center'}}>
                        <ReservationDetailsResidentName
                          id={`reservation-${r.reservationUuid}-resident-name`}
                          selected={reservationIsSelected(r.reservationUuid)}
                        >
                          {r.residentName}
                        </ReservationDetailsResidentName>
                        <span>&nbsp;</span>
                        <div id={`reservation-${r.reservationUuid}-group-size`}>
                          {r.guests}
                        </div>
                      </Flex>
                      <FlexItem>
                        {!isMultiDay && (
                          <MultiDayReservationDetails id={`reservation-${r.reservationUuid}-time`}>
                            {`${formatMilitaryTime(r.startTime)} - ${formatMilitaryTime(r.endTime)}`}
                          </MultiDayReservationDetails>
                        )}
                        {isMultiDay && (
                          <MultiDayReservationDetails>
                            {`Check in: ${moment(r.startDate).format("MMM D")} | Check out: ${moment(r.endDate).format("MMM D")}`}
                          </MultiDayReservationDetails>
                        )}
                      </FlexItem>
                    </Flex>
                    <Flex>
                      <FlexItem> { reservationResource(r) } </FlexItem>
                    </Flex>
                  </ReservationDetailsContainer>
                  <ReservationDeleteButtonContainer>
                    {!r.started && !reservationIsSelected(r.reservationUuid) &&(
                      <ReservationDeleteButton
                        id={`reservation-${r.reservationUuid}-delete-button`}
                        onClick={() => this.props.setSelectedReservation(r)}
                        title={`Delete Reservation for ${r.residentName}`}>
                        <HighlightOffIcon color="error"/>
                      </ReservationDeleteButton>
                    )}
                  </ReservationDeleteButtonContainer>
                </ReservationContainer>
                { this.props.reservations && this.props.reservations.length > 0 && <Divider /> }
              </ListItem>
            )}
      </List>
    )
  }

  renderReservationDetails = (reservation: Object) => {
    const {
      residents,
      item,
      handleResidentMessageChange
    } = this.props

    const remainingLength =
    this.props.item.message && this.props.item.message.length < 40 ?
      40 - this.props.item.message.length : 0
    const residentMsgLabel = this.props.item.message ?
      `Additional Message to Resident (${remainingLength} characters remaining)` :
      'Additional Message to Resident'

    const { residentUuid, groupSize } = reservation
    const { picture, display, unitName, unitId, email } = residents.find(resident => resident.userId === residentUuid) || {}
    const guests = 'guest' + (groupSize > 1 ? 's' : '')
    return (
      <React.Fragment>
        <FlexContainer>
          <ReservationResidentAvatar large src={picture} alt="Resident Image" />
          <div>
            <ResidentName>{`${display} / ${unitName}`}</ResidentName>
            <ResidentEmail>{`ID: ${unitId} / ${email}`}</ResidentEmail>
            <ReservationGuest>
              <CommunityIcon name="community" width="12px" height="12px" />
              <ReservationGuestCount>{`${groupSize} ${guests}`}</ReservationGuestCount>
            </ReservationGuest>
          </div>
        </FlexContainer>
        <Divider />
        {
          <ResidentMessageContainer>
            <DeleteReservationConfirmation>
              Delete Reservation?
            </DeleteReservationConfirmation>
            <ResidentMessage
              id="message"
              label={residentMsgLabel}
              multiline
              rows={4}
              variant="outlined"
              value={item.message}
              onChange={handleResidentMessageChange}
              InputLabelProps={{ shrink: true }}
            />
          </ResidentMessageContainer>
        }
      </React.Fragment>
    )
  }

  render() {
    const {
      item: { availability = [], reservationType },
      item,
      isAmenityOpen,
      selectedAvailabilityDate,
      selectedAvailabilityTimeSlot,
      setAmenityDayBlocked,
      setAmenitySlotBlocked,
    } = this.props

    const isMultiDay = reservationType === 'multiDay'
    const isHourly = (!reservationType || reservationType === 'hourly')

    const selectOptions = availability.filter(t => !t.buffer).map(slot => ({
      value: slot,
      label: this.getTimeSlotSelectLabel(slot),
    }))

    const {
      isDayBlocked,
      isSlotBlocked,
      enableSlotBlockToggle,
      enableDayBlockToggle,
    } = this.getCurrentSlotInfo()

    const getSelectedDateFormatted = () => {
      const d = new Date(selectedAvailabilityDate)
      return d.toLocaleString('en-us', {
        timeZone: 'UTC',
        dateStyle: 'full',
      }).slice(0, -6)
    }

    return (
      <AmenityReservationsDiv>
        <Grid container>
          <Grid item xs={12}>
            <Grid container wrap="nowrap" alignItems="center" justify="space-between">
              <Grid item>
                <AvailabilityDayHeader id="availability-day">
                  {getSelectedDateFormatted()}
                </AvailabilityDayHeader>
              </Grid>
              {
                item.rsvpRequired && isAmenityOpen && !isMultiDay &&
                <Grid item>
                  <BlockDayLabelSpan>Block Entire Day</BlockDayLabelSpan>
                  <Switch
                    style={{ height: 'auto' }}
                    disabled={!enableDayBlockToggle}
                    checked={isDayBlocked || false}
                    onChange={(newVal) => setAmenityDayBlocked(newVal)}
                  />
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: '12px', paddingBottom: '15px' }}>
            <Divider />
          </Grid>
          <Grid item xs={12} style={{paddingBottom: '30px'}}>
            <Grid container wrap="nowrap" alignItems="center" justify="space-between">
              {
                item.rsvpRequired && isAmenityOpen && !isDayBlocked && isHourly &&
                <Grid item>
                  <AvailabilityHoursDropdown>
                    <ReactSelect
                      inputId={'availability-timeslot'}
                      components={{IndicatorSeparator: () => null}}
                      onChange={this.handleTimeSlotChange}
                      options={selectOptions}
                      value={this.wrapSelectedSlotForListValue()}
                    />
                  </AvailabilityHoursDropdown>
                </Grid>
              }
              &nbsp;
              {
                !isMultiDay && (!isHourly || selectedAvailabilityTimeSlot?.status) &&
                <Grid item>
                  <BlockLabelSpan>Block Time Slot</BlockLabelSpan>
                  <Switch
                    style={{height: 'auto'}}
                    disabled={!enableSlotBlockToggle}
                    checked={isSlotBlocked || false}
                    onChange={(newVal) => setAmenitySlotBlocked(newVal)}
                  />
                </Grid>
              }
            </Grid>
            </Grid>
          {
            item.rsvpRequired && selectedAvailabilityTimeSlot && isHourly &&
            <Grid item xs={12}>
              {selectedAvailabilityTimeSlot.numReservations > -1 &&
                <AvailabilityReservationsDetails>
                  <AvailabilityReservationsLabel>
                    Reservations:
                  </AvailabilityReservationsLabel>&nbsp;
                  <span id="availability-reservation-counts">
                    {
                      isAmenityOpen ?
                        isDayBlocked || isSlotBlocked
                          ? 'Blocked'
                          : `${selectedAvailabilityTimeSlot.numOccupied}/${item.amenityType === 'item' ? item.reservationLimit : item.totalOccupancy}`
                        : 'Closed'
                    }
                  </span>
                </AvailabilityReservationsDetails>
              }
              {
                this.props.isAmenityOpen && item.resources.name && !item.isSlotBlocked && !isDayBlocked &&
                selectedAvailabilityTimeSlot && selectedAvailabilityTimeSlot.numResourcesReserved > -1 &&
                <AvailabilityResourceDetails>
                  <span id="availability-resource-name">{item.resources.name}</span>:&nbsp;
                  <span id="availability-resource-counts">
                    {`${selectedAvailabilityTimeSlot.numResourcesReserved}/${item.resources.count}`}
                  </span>
                </AvailabilityResourceDetails>
              }
            </Grid>
          }
          {
            this.props.isAmenityOpen &&
            <Grid item xs={5} style={{ paddingTop: '20px' }}>
              {this.reservationList()}
            </Grid>
          }
          {
            this.props.selectedReservation &&
            <Grid item xs={7} style={{ paddingLeft: '60px' }}>
              {this.renderReservationDetails(this.props.selectedReservation)}
            </Grid>
          }
        </Grid>
      </AmenityReservationsDiv>
    )
  }
}
