import { select, put, call, takeLatest } from 'redux-saga/effects'
import isPlainObject from 'lodash/isPlainObject'

import api from '../../../utils/api'
import log from '../../../utils/logger'
import { hasChanges } from '../../../utils/object'
import { getToken } from '../../authorization/selectors'
import { getPropertyId } from '../../select/selectors'
import { updateSelectedProperty } from '../../select/actions'
import { getSelectedProperty } from '../../select/selectors'

import {
  UPDATE_PROPERTY,
  updatePropertySuccess,
  updatePropertyError,
  updatePropertyOptimistic,
  GET_PROPERTY_PMS_STATUS,
  GET_PROPERTY_PMS_STATUS_ERROR,
  GET_PROPERTY_PMS_STATUS_SUCCESS,
  GET_PROPERTY_TIMEZONE,
  GET_PROPERTY_TIMEZONE_SUCCESS,
  GET_PROPERTY_TIMEZONE_ERROR
} from '../actions'

export function* updateProperty(action) {
  const property = yield select(getSelectedProperty)
  const { attributes: newAtts, propertyID } = action

  let currentAtts = {}
  let attsToUpdate = {}

  for (let key in newAtts) {
    if (
      (!isPlainObject(newAtts[key]) && newAtts[key] !== property[key]) ||
      (isPlainObject(newAtts[key]) &&
        isPlainObject(property[key]) &&
        hasChanges(newAtts[key], property[key]))
    ) {
      attsToUpdate[key] = newAtts[key]
      currentAtts[key] = property[key]
    }
  }

  if (Object.keys(attsToUpdate).length > 0) {
    const authToken = yield select(getToken)

    try {
      yield put(updatePropertyOptimistic(propertyID, attsToUpdate))
      const response = yield call(
        api.updateProperty,
        authToken,
        propertyID,
        attsToUpdate
      )
      let serverAtts = {}

      for (let key in attsToUpdate) {
        if (response[key]) {
          serverAtts[key] = response[key]
        }
      }

      yield put(updateSelectedProperty(response))
      yield put(updatePropertySuccess(serverAtts))
    } catch (err) {
      yield put(updatePropertyError(err.message, currentAtts))
    }
  }
}

function* watchUpdateProperty() {
  yield takeLatest(UPDATE_PROPERTY, updateProperty)
}

export function* getPropertyPmsStatus() {
  try {
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)
    const { has_pms, pms } = yield call(
      api.getPropertyPmsStatus,
      propertyId,
      authToken
    )
    yield put({
      type: GET_PROPERTY_PMS_STATUS_SUCCESS,
      has_pms,
      pms
    })
  } catch (error) {
    yield put({
      type: GET_PROPERTY_PMS_STATUS_ERROR,
      error: error.message
    })
    log(`Failed to get property pms status, Error: ${error}`)
  }
}

function* watchGetPropertyPmsStatus() {
  yield takeLatest(GET_PROPERTY_PMS_STATUS, getPropertyPmsStatus)
}

export function* getPropertyTimezone() {
  try {
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)
    const { timezone } = yield call(
      api.getPropertyBasicInfo,
      propertyId,
      authToken
    )
    yield put({
      type: GET_PROPERTY_TIMEZONE_SUCCESS,
      timezone
    })
  } catch (error) {
    yield put({
      type: GET_PROPERTY_TIMEZONE_ERROR,
      error: error.message
    })
    log(`Failed to get property timezone, Error: ${error}`)
  }
}

function* watchGetPropertyTimezone() {
  yield takeLatest(GET_PROPERTY_TIMEZONE, getPropertyTimezone)
}

export default [
  watchUpdateProperty(),
  watchGetPropertyPmsStatus(),
  watchGetPropertyTimezone()
]
