// @flow

import get from 'lodash/get'
import { capitalizeFirstLetter, underscoreToSpace } from '../../utils'
import { getData, getDataById } from 'zego-shared/store/helpers/selectors/data'
import { deviceToLockData } from './lockSelectors'
import { deviceToHubProperties } from './hubSelectors'
import { deviceToThermostatData } from './thermostatSelectors'
import { deviceToSwitchData } from './switchSelectors'

const defaultStatus = 'Unknown'

const devicePath = ['unit', 'devices']

const isHub = device => device.type === 'gateway'

const isNotHub = device => !isHub(device)

export const getBatteryLevelText = (batteryPercent: number) => {
  return batteryPercent > 50
    ? 'High'
    : batteryPercent > 25 && batteryPercent <= 50
    ? 'Medium'
    : 'Low'
}

export const getDeviceMakeModel = (device: any) =>
  `${device.make} ${device.model}`

const getDeviceStatus = device => {
  return (
    {
      thermostat: get(
        device,
        ['last_state', 'data', 'system_mode'],
        defaultStatus
      ),
      door_lock: get(device, ['last_state', 'data', 'state'], defaultStatus),
      switch: get(device, ['last_state', 'data', 'state'], defaultStatus)
    }[device.type] || defaultStatus
  )
}

const deviceToDeviceData = device => {
  return device
    ? {
        thermostat: deviceToThermostatData,
        door_lock: deviceToLockData,
        switch: deviceToSwitchData
      }[device.type](device)
    : null
}

const deviceToDeviceListItem = device => ({
  name: device.name || capitalizeFirstLetter(underscoreToSpace(device.type)),
  status: getDeviceStatus(device).toUpperCase(),
  id: device.id
})

export const hasZegoSmart = (state: {}): ?boolean => {
  const data = getData(state, devicePath)
  return data ? (data.length ? true : false) : null
}

export const hasDevices = (state: {}): ?boolean => {
  const data = getData(state, devicePath)
  return data.some(device => isNotHub(device)) ? true : false
}

export const getDeviceData = (state: {}, id: number): ?{} => {
  const data = getDataById(state, id, devicePath)
  return data ? deviceToDeviceData(data) : null
}

export const getDeviceType = (state: {}, id: number): ?string => {
  const data = getDataById(state, id, devicePath)
  return data ? data.type : null
}

export const getDeviceList = (state: {}): ?Array<any> => {
  const data = getData(state, devicePath)
  if (data.length === 1 && isHub(data[0])) return []
  return data ? data.filter(isNotHub).map(deviceToDeviceListItem) : null
}

export const getHubProperties = (state: {}): ?{} => {
  const data = getData(state, devicePath)
  return data ? deviceToHubProperties(data.find(isHub)) : null
}

export const hasData = (state: Object, deviceID: string) => {
  const devices = getDeviceList(state)
  if (devices && devices.length) {
    deviceID = deviceID ? deviceID : devices[0].id
    return devices.findIndex(device => `${device.id}` === `${deviceID}`) !== -1
  }
  return false
}
