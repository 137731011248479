import React from 'react'
import PrivateRoute from '../../PrivateRoute'
import ChatContainer from '../../../views/Communication/Chat/ChatContainer'

const getChatRoutes = () => [
  <PrivateRoute
    exact
    key="chatChannels"
    path="/properties/:propertyId/communication/chat/:channelType"
    component={ChatContainer}
  />,
  <PrivateRoute
    exact
    key="chatChannelsUser"
    path="/properties/:propertyId/communication/chat/:channelType/users/:userId"
    component={ChatContainer}
  />
]

export default getChatRoutes
