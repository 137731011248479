// @flow
import React from 'react'

import FlexDirection from '../Flex/FlexDirection'

import { CarouselUnitProps as Props } from './types'
import { UnitContainer } from './styles'

export const CarouselUnit = ({
  children,
  lastUnit,
  height,
  className
}: Props) => {
  return (
    <UnitContainer lastUnit={lastUnit} height={height}>
      <FlexDirection fullWidth className={className}>
        {children}
      </FlexDirection>
    </UnitContainer>
  )
}

export default CarouselUnit
