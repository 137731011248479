// @flow

import React from 'react'
import AddUserStep from './AddUserStep'
import Flex, { FlexItem } from '@Common/Flex'
import { AddUserBody, TextField, Button } from './styles'
import { ErrorMessage } from '../../Common/Messages'
import { validateEmail } from '../../../utils/validationUtils'

import type { handleDataChangeFunc } from './types'

type Props = {
  handleDataChange?: handleDataChangeFunc,
  userType?: ?string
}

type State = {
  email: string,
  valid: boolean
}

export default class EnterEmail extends React.Component<Props, State> {
  state = {
    email: '',
    valid: false
  }

  handleChange = ({
    target: { value }
  }: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ email: value, valid: validateEmail(value) })
  }

  handleKeyPress = ({ key }: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    const { email, valid } = this.state
    if (!valid) {
      return
    }
    const { handleDataChange } = this.props
    handleDataChange && handleDataChange({ email })
  }

  render() {
    const { valid, email } = this.state
    const { userType } = this.props

    return (
      <AddUserStep
        title={`ADD A ${userType ? userType.toUpperCase() : 'USER'}`}
        subtitle={`Please enter the email address of the ${
          userType ? userType : 'user'
        } you would like to invite to use the Zego App.`}>
        <AddUserBody>
          <Flex fullWidth>
            <FlexItem flex={3}>
              <TextField
                onKeyPress={this.handleKeyPress}
                onChange={this.handleChange}
                InputProps={{
                  disableUnderline: true
                }}
              />
            </FlexItem>
            <FlexItem flex={1}>
              <Button onClick={this.handleSubmit}>SUBMIT</Button>
            </FlexItem>
          </Flex>
          {email.length > 0 &&
            !valid && <ErrorMessage>Please enter valid e-mail</ErrorMessage>}
        </AddUserBody>
      </AddUserStep>
    )
  }
}
