import { call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken } from '../../authorization/selectors'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  fetchResidentActiveAction,
  fetchResidentActiveStart,
  fetchResidentActiveSuccess,
  fetchResidentActiveError
} from './actions'

export function* fetchResidentActive({ unitId }) {
  try {
    yield put(fetchResidentActiveStart())
    const authToken = yield select(getToken)
    const response = yield call(api.getUnitResidentStats, authToken, unitId)

    yield put(fetchResidentActiveSuccess(response))
  } catch (error) {
    yield put(fetchResidentActiveError(error))
    log(`Failed to fetch resident analytics data`)
  }
}

function* watchfetchResidentActive() {
  yield takeLatest(fetchResidentActiveAction().type, fetchResidentActive)
}

export default [watchfetchResidentActive()]
