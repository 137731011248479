import { connect } from 'react-redux'
import queryString from 'query-string'
import PropertyUsers from './PropertyUsers'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { getPropertyUsers, getPropertyUsersCount, setCountText, hasData } from 'zego-shared/store/users/selectors'

const PropertyUsersWithFetchData = withFetchData(PropertyUsers)
const fetchingSelector = createIsFetchingSelector(['propertyUsersSummary'])

const mapStateToProps = (state, { location: { search } }) => {
  const filter = queryString.parse(search).role || 'companyAdmin'

  return {
    hasData: hasData(state),
    isFetching: fetchingSelector(state),
    propertyUsers: getPropertyUsers(state, filter),
    propertyUsersCount: getPropertyUsersCount(state),
    countText: setCountText(filter),
    filter
  }
}

export default connect(
  mapStateToProps
)(PropertyUsersWithFetchData)
