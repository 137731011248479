import React from 'react'
import { DialogMessage, DialogTitle, WaiverModalTitle } from "../../AmenityShared/styles";
import AlertModal from "../../../Common/AlertModal/AlertModal";
import BlockTimeSlotModalContent from './BlockTimeSlotModalContent'
import WaiverModalContent from './WaiverModalContent'
import DeleteReservationModalContent from './DeleteReservationModalContent'
import { withTheme } from 'emotion-theming'

export const modals = {
  deleteAmenity: 'deleteAmenity',
  publish: 'publish',
  exit: 'exit',
  deleteWaiver: 'deleteWaiver',
  waiver: 'waiver',
  deleteReservation: 'deleteReservation',
  blockDay: 'blockDay',
  publishedAmenityEdited: 'publishedAmenityEdited',
  blockSlot: 'blockSlot',
}

// allow passing in of strings to override button content (instead of an entire object)
const asPropIfString = (maybeString) => typeof maybeString === 'string'
  ? { text: maybeString }
  : maybeString

const wrapInTitleComponentIfString = (maybeString) => typeof maybeString === 'string'
  ? <DialogTitle>{maybeString}</DialogTitle>
  : maybeString


function AmenityModals({
  onFileUploadError,
  onFileDelete,
  handleWaivers,
  onModalClose,
  onModalAccept,
  setNewFacilityBlockTime,
  selectWaiverSignatureFrequency,
  saveAmenityIfChanged,
  publishAmenity,
  amenityState: {
    publishedAmenityEditWarnings: editWarnings,
    selectedReservation,
    newFacilityBlock,
    activeModal,
    waivers,
    fileSizeLimit,
    fileUploadError,
    signatureFrequency,
    preventWaiverSubmission,
  },
  amenityProps: {
    item,
    availability,
    residents,
    editMode
  },
  theme,
}) {
  if (!activeModal) {
    return null
  }

  const { timeSlots } = availability || { timeSlots: [] }
  const {
    palette: {
      primary : { navy: { spaceBlue } },
      alert: { error: errorColor }
    }
  } = theme

  // callbacks to tell AmenityPro when modal is accepted/cancelled
  // for simple actions not requiring a callback passed in as props
  const onAccept = () => onModalAccept(activeModal)
  const onCancel = () => onModalClose(activeModal)
  const onAcceptWithCallback = (callback = () => {}) => () => {
    onAccept()
    callback()
  }

  // pass in nothing to get the default No, Yes
  // pass in an array to override,
  // preferably handle accept/cancel in parent component, using the appropriate callback provided
  // e.g. [{text: "Bail"}, {text: "Cool", onClick: () => { doSomething(); onAccept(); } }]
  const makeButtonProps = (...buttons) => [
    { text: 'No', onClick: onCancel, ...asPropIfString(buttons[0]) },
    { text: 'Yes', onClick: onAccept, color: spaceBlue, ...asPropIfString(buttons[1]) },
    ...buttons.slice(2)
  ]

  const createModalProps = ({ title, message, buttons = [], ...other }) => ({
    title: wrapInTitleComponentIfString(title),
    buttons: makeButtonProps(...buttons),
    ...message ? {
      message: <DialogMessage> {message} </DialogMessage>,
      messageIcon: 'warning',
    } : {},
    ...other
  })

  // uses the [value] itself as a key to ensure proper matching
  // with exported modal names
  const modalDefinitions = {
    [modals.publish]: {
      title: 'Are you ready to publish this amenity to residents?',
      message: 'By publishing this amenity, it will be viewable by the residents, and cannot be unpublished.',
      buttons: [
        { text: 'No, save as draft', onClick: onAcceptWithCallback(saveAmenityIfChanged) },
        { text: 'Yes, Publish Now', onClick: onAcceptWithCallback(publishAmenity) }
      ],
    },
    [modals.deleteAmenity]: {
      title: `Are you sure you want to delete ${item.name}?`,
      message: 'By deleting this amenity, you are also canceling all future reservations'
    },
    [modals.deleteReservation]: {
      buttons: ['No, go back', 'Yes, please delete'],
      message: <DeleteReservationModalContent
        selectedReservation={selectedReservation}
        residents={residents}
      />
    },
    [modals.deleteWaiver]: {
      message: `Are you sure you want to delete the file ${item.currentWaiver}?`,
    },
    [modals.waiver]: {
      messageIcon: '',
      topContent: true,
      title: <WaiverModalTitle>Upload Waiver</WaiverModalTitle>,
      buttons: [
        { text: 'Cancel' },
        { name: 'file', disabled: preventWaiverSubmission, text: 'Finish' },
      ],
      message: <WaiverModalContent
        waivers={waivers}
        handleWaivers={handleWaivers}
        fileSizeLimit={fileSizeLimit}
        onFileDelete={onFileDelete}
        fileUploadError={fileUploadError}
        onFileUploadError={onFileUploadError}
        selectWaiverSignatureFrequency={selectWaiverSignatureFrequency}
        signatureFrequency={signatureFrequency}
        errorColor={errorColor}
      />,
    },
    [modals.exit]: {
      buttons: ['No, Stay', 'Yes, Leave'],
      title: editMode
        ? 'Do you wish to cancel editing and lose all changes?'
        : 'Do you wish to leave this page and lose all changes?'
    },
    [modals.blockDay]: {
      title: 'Are you sure you want to block the entire day?',
      message: 'By blocking the entire day you will cancel all current reservations on this day and mark all slots as “CLOSED."',
    },
    [modals.blockSlot]: {
      message: 'By blocking this time slot you will cancel all current reservations on it and mark all slots inside as “CLOSED.”',
      buttons: [{ text: 'Cancel' }, { text: 'Block' }],
      title: <BlockTimeSlotModalContent
        amenity={item}
        timeSlots={timeSlots}
        newFacilityBlock={newFacilityBlock}
        setNewFacilityBlockTime={setNewFacilityBlockTime}
      />
    },
    [modals.publishedAmenityEdited]: {
      topContent: true,
      ...editWarnings.length === 1 ? {
        title: (
          <DialogTitle>
            <div>Are you sure you want to:</div>
            <ul>
              {editWarnings.map((warning,key) => (<li key={key}>{warning.multiWarningText}</li>))}
            </ul>
          </DialogTitle>
        ),
        message: (
          <>
            <DialogMessage>{editWarnings[0].warningText}</DialogMessage>
            <DialogMessage>These changes will go
              into effect at midnight.</DialogMessage>
          </>
        )
      } : {
        title: (
          <DialogTitle>
            <div>Are you sure you want to:</div>
            <ul>{editWarnings.map(warning => (<li>{warning.multiWarningText}</li>))}</ul>
          </DialogTitle>
        ),
        message: (
          <>
            <DialogMessage> By making these changes you will cancel any current reservations for this
              Amenity that no longer fit within the time or space available and the schedule will be updated with the new
              availability.
            </DialogMessage>
            <DialogMessage>
              These changes will go into effect at midnight.
            </DialogMessage>
          </>
        )
      },
    }
  }

  // set defaults for all modals here
  const alertModalProps = {
    theme,
    topContent: false,
    show: true,
    onClose: () => {
      onModalClose(activeModal)
    },
    ...createModalProps(modalDefinitions[activeModal]),
  }
  return modalDefinitions[activeModal] && <AlertModal {...alertModalProps} />
}


export default withTheme(AmenityModals)
