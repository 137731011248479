import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core/styles'
import { Button, Chip } from '@material-ui/core'
import { CommonIcon } from '@icons'
import Card from '@material-ui/core/Card'
import ReactSelect from 'react-select'
import WideBaseCard from '@Common/Cards/BaseCard/WideBaseCard'
import BaseFooterLink from '@Common/Cards/CardFooterLink'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import FabButton from '@Common/Button/FabButton'

export const WideBaseCardStyled = styled(WideBaseCard)`
  height: 209px;
`
export const ModalTitle = styled('h3')`
  font-size: ${({ theme }) => theme.custom.font.size.lg};
  font-style: normal;
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  line-height: 29px;
  letter-spacing: 0px;
  text-align: center;
`

export const StyledSaveButton = styled(FabButton)`
   border: 3px solid ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
   margin-top: 10px;
`

export const SelectStyle = (isErrors) => {
    return {
        control: (base, state) => {
            return ({
                ...base,
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
                borderRadius: 0,
                boxShadow: 'none',
                borderBottom: isErrors && !state.hasValue ?
                    `1px solid ${({ theme }) => theme.palette.secondary.pink.coral}` :
                    `1px solid ${({ theme }) => theme.palette.neutrals.coolGray}`
            })
        }
    }
}

export const ClosedIcon = styled(CancelOutlinedIcon)`
    color: ${({ theme }) => theme.palette.secondary.pink.coral};
    cursor: pointer;
    opacity: 0.5;
    margin-left: 12px;
    margin-bottom: -7px;
`

export const EventWrapper = styled('div')`
    margin-top: 10px;
    display: inline-block;
    margin-right : 10%;
    width: ${({ width }) => (width ? width : '100%')};
    margin-left: -10px;
`

export const TextContainer = styled('div')`
    width: ${({ width }) => (width ? width : '100%')};
    display: inline-block;
`

export const CloseButton1 = styled(CommonIcon)`
    float: right;
    cursor: pointer;
    margin-left: 1em;
    margin-top: -6px;
    stroke: ${({ theme }) => theme.palette.primary.navy.midnight};

    :hover {
    stroke-width: 2;
    }
`

export const ErrorText = styled('div')`
    color: ${({ theme }) => theme.palette.secondary.pink.coral};
    padding-top: 10px;
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    margin-left: 7px;
`

export const ErrorTextContainer = styled('div')`
    color: ${({ theme }) => theme.palette.secondary.pink.coral};
    padding-top: 10px;
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    margin-left: 7px;
`

export const ErrorTextButton = styled('div')`
    color: ${({ theme }) => theme.palette.secondary.pink.coral};
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    margin-left: 26px;
    margin-top: 12px;
`

export const StyledTitle = styled('div')`
    color: ${({ theme }) => theme.palette.text.black1};
    font-size: ${({ theme }) => theme.custom.font.size.mdlg};
    font-weight: ${({ theme }) => theme.custom.font.weight.bold};
`

export const Underline = styled('div')`
    width: 54px;
    height: 6px;
    border-bottom: ${({ theme }) => `2px solid ${theme.palette.primary.green.mint};`};
`

export const Header = styled('p')`
    font-weight: ${({ theme }) => theme.custom.font.weight.bold};
    font-size: ${({ theme }) => theme.custom.font.size.mdlg};
    font-style: italic;
`

export const Loading = styled('div')`
    right: 50%;
    left: 50%;
    position: absolute;
    padding-top: 10px;
    padding-bottom: 15px;
    ~#showMoreBtn {
    margin-top: 30px;
}
`

export const CustomCard = styled(Card)`
    width: 100%;
    margin: auto;
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
    border-radius: '20px';
    padding: 24px;
    margin-top: 2em;
    padding-bottom: 40px;
`

export const CheckListSettingContainerLeft = styled('div')`
    display: inline-block;
    width: 45%;
    padding-top: 20px;
    padding-left: 24px;
    border-right: ${({ theme }) => `2px solid ${theme.palette.neutrals.deepGray}`};
    padding-right: 200px;
    margin-right: 2%;
`

export const CheckListSettingContainerRight = styled('div')`
    width: 45%;
    display: inline-block;
    padding-top: 20px;
    padding-left: 20px;
    vertical-align: top;
    margin: auto;
    margin-left: 3%;
`

export const CircleWrap = styled('div')`
    background-color: ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
    width: ${({ large }) => (large ? '2em' : '1.4em')};
    height: ${({ large }) => (large ? '2em' : '1.4em')};
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({ theme, selected }) =>
        selected
            ? `1px solid ${theme.palette.neutrals.deepGray}`
            : `1px solid ${theme.palette.secondary.pink.coral}`};
    border-radius: 50%;

    &:hover {
    cursor: pointer;
    }
`

export const CloseButton = styled(CommonIcon)`
    float: right;
    cursor: pointer;
    margin-left: 1em;
    stroke-width: 2;
    stroke: ${({ theme }) => theme.palette.neutrals.deepGray};
    background-color: ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
    width: ${({ large }) => (large ? '2em' : '1.4em')};
    height: ${({ large }) => (large ? '2em' : '1.4em')};
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({ theme, selected }) =>
        selected
            ? `1px solid ${theme.palette.neutrals.deepGray}`
            : `0px solid ${theme.palette.secondary.pink.coral}`};
    border-radius: 50%;

    &:hover {
    cursor: pointer;
    }
`

export const Row = styled('div')`
    margin-bottom: 20px;
`

export const Spacing = styled('div')`
    margin-bottom: 20px;
    width: 100%;
`

export const CloseButtonDanger = styled(CommonIcon)`
    cursor: pointer;
    margin-left: 1em;
    float: left;
    stroke-width: 5;
    stroke: ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
    width: ${({ large }) => (large ? '2em' : '1.4em')};
    height: ${({ large }) => (large ? '2em' : '1.4em')};
    display: flex;
    align-items: center;
    justify-content: center;
    stroke: ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
    border-radius: 50%;
    &:hover {
    cursor: pointer;
    }
`

export const UploadButtonContainer = styled('div')`
    display: inline-block;
`

export const FileLink = styled('div')`
    display: inline-block;
    margin-left: 10px;
    font-style: italic;
    color: ${({ theme }) => theme.palette.neutrals.deepGray};
`

export const UploadButton = styled('label')`
    width: 35%;
    height: 18px;
    line-height: 24px;
    margin-left : 24px;
    border-radius: 0;
    font-weight: ${({ theme }) => theme.custom.font.weight.bold};
    border: ${({ theme }) => `4px solid ${theme.palette.primary.dark}`};
    color: ${({ theme }) => theme.palette.primary.dark};
    text-align: center;
    font-size: ${({ theme }) => theme.custom.font.size.s};
    cursor: pointer;
    padding-right: 30px;
    padding-bottom: 3px;
    padding-left: 30px;
`

export const CustomBadge = styled('div')`
    float: right;
    width: 42px;
    height: 25px;
    text-align: center;
    color: white;
    background: ${({ theme }) => theme.palette.primary.green.mint};
    border-radius: 20px;
    font-size: ${({ theme }) => theme.custom.font.size.s};
`

export const FooterLink = styled(BaseFooterLink)`
    font-weight: ${({ theme }) => theme.custom.font.weight.normal};
    font-size: ${({ theme }) => theme.custom.font.size.md};

    a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.text.black1};

    &:hover {
    color: ${({ theme }) => theme.palette.neutrals.deepGray};
    }
    }

    &:hover {
    border: ${({ theme, disabled }) =>
        !disabled && `3px solid ${theme.palette.primary.green.seeFoam}`};
    }

    ${({ disabled, theme }) =>
        !disabled &&
        `
    text-align: center;
    line-height: 30px;
    width: 95px;
    height: 35px;
    border: 3px solid ${theme.palette.secondary.main1};
    margin-bottom: 20px;
    `}

    a:hover {
    ${({ disabled }) => disabled && `cursor: default`}
    color: ${({ theme }) => theme.palette.neutrals.deepGray};
    }

    ${({ disabled, theme }) =>
        disabled &&
        `font-size: ${theme.custom.font.size.mdlg};
    text-align: right;
    span {
    font-size: ${theme.custom.font.size.sm};
    }
    `}
`

export const ButtonContainer = styled('div')`
    display: grid;
    vertical-align: top;
    float: right;
    margin-right: -9px;
    margin-top: -28px;
    margin-left: -14px;
    margin-bottom: 15px
`

export const Label = styled('div')`
    text-align: left;
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    color: ${({ theme }) => theme.palette.neutrals.deepGray};
`

export const InfoText = styled('div')`
    text-align: left;
    font-size: ${({ theme }) => theme.custom.font.size.mdlg};
    margin-bottom: 30px;
`

export const TermsText = styled('div')`
    text-align: left;
    font-size: ${({ theme }) => theme.custom.font.size.mdlg};
    display: inline-block;
    vertical-align: top;
    margin-bottom: 30px;
`

export const SelectContainer = styled('div')`
    display: inline-block;
    width: ${({ width }) => (width ? width : '100%')};
`

export const StyledSelect = styled(ReactSelect)`
    margin-left: -8px;
`

export const StyledVerticalLine = styled('div')`
    width: 0.5%;
    height: 300px;
    background-color: ${({ theme }) => theme.palette.neutrals.underlineGray};
`

export const StyledButton = styled('div')`
  margin-bottom: 15px;
`

export const ShowMoreBtn = withStyles({
    root: {
        display: 'flex',
        marginTop: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '140px',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    label: {
        display: 'flex',
        justifyContent: 'space-between'
    }
})(Button)

const ChipStyles = theme => ({
    root: {
        margin: '15px 10px 5px 0px',
        textTransform: 'capitalize',
        height: '20px',
        fontSize: theme.custom.font.size.sm,
        fontWeight: theme.custom.font.weight.bold
    },
    colorSecondary: {
        border: `1px solid ${theme.palette.primary.green.mint}`,
        backgroundColor: theme.palette.primary.green.iceberg
    },
    colorPrimary: {
        border: `1px solid ${theme.palette.neutrals.mediumGray}`,
        backgroundColo: theme.palette.common.white,
        color: theme.palette.neutrals.mediumGray
    }
})

export const StyledChip = withStyles(ChipStyles)(Chip);
