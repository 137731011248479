import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { Card } from '@material-ui/core'
import { GridItem } from '@Common/Grid'
import { convertHexToRGB } from '../../utils/commonUtils'

//-----------------------------------------------------------------------
// Legacy Styles
//-----------------------------------------------------------------------
export const Content = styled('p')`
  color: ${({ theme }) => `${theme.palette.common.white}`};
  line-height: 1.5em;
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  line-height: 26px;
  margin-top: 80px;
`

export const TableContainer = styled('div')`
  height: 84.5%;
  width: 80.5%;
  position: absolute;
  padding: 3em;
  background: ${({ theme }) => `${theme.palette.common.white}`};
  border-top-left-radius: 5px;
  right: -2em;
  bottom: -1em;
  box-shadow: ${({ theme }) =>
    `0 1px 10px 0 ${convertHexToRGB(theme.palette.neutrals.warmGray, 0.5)}`};
  th,
  td {
    padding-right: 0.5em !important;
  }
  tr {
    border-bottom: ${({ theme }) =>
      `1px solid ${theme.palette.neutrals.deepGray}`};
  }
`

export const Layout = styled('div')`
  width: 100%;
  &:after {
    content: ' ';
    background: ${({ theme }) => `${theme.palette.neutrals.lightGray}`};
    border-top-right-radius: 5px;
    width: 70%;
    height: calc(100% - 1em);
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: -15;
    top: 2em;
  }
`

export const Container = styled('div')`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`

export const StyledColumn = styled('div')`
  height: 4em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-start;
  ${({ index }) => index === 3 && `justify-content: flex-end`};
`

export const StyledRow = styled('div')`
  height: ${({ firstRow }) => (firstRow ? `7em` : `4em`)};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: ${({ firstRow }) => (firstRow ? `flex-end` : `center`)};
  white-space: nowrap;
  position: relative;
  justify-content: flex-start;
  ${({ index }) => index === 3 && `justify-content: flex-end`};
  ${({ firstRow, index }) =>
    firstRow && `padding-bottom: ${index === 1 ? '0.8em' : '1.5em'};`};
  ${({ index, theme }) =>
    index === 1 &&
    `color: ${theme.palette.common.black};
    * :not(svg):not(path) {
      color: ${theme.palette.common.black};
    }`};
`

//-----------------------------------------------------------------------
// Pro Styles
//-----------------------------------------------------------------------
export const PageContainer = styled(GridItem)`
  padding-left: 25px;
`

export const DashBoardScheduleContainer = styled('div')`
  padding-top: 16px;
  pointer-events: none;
  max-height: 40vh;
  background: ${({ theme }) => theme.palette.background.default }
`

export const PageHeader = styled('div')`
  padding-top: 10px;
  padding-left: 10px;
  height: 134px;
  color: ${({ theme }) => theme.palette.text.white};

  :before {
    position: absolute;
    left: 0;
    width: 100%;
    height: 178px;
    background-color: ${({ theme }) => theme.palette.primary.navy.twilight};
    z-index: -1;
    content: ' ';
  }
`

export const PageContent = styled(Card)`
  padding-top: 20px;
  padding-bottom: 40px;
  overflow: hidden;

  h1 {
    display: inline;
    color: ${({ theme }) => theme.palette.primary.navy.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.lg};
  }

  h2 {
    display: inline;
    color: ${({ theme }) => theme.palette.text.grey};
    font-size: ${({ theme }) => theme.custom.font.size.mdl};
    font-weight: normal;
  }
`

export const StyledLink = styled(Link)`
  white-space: nowrap;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};

  :hover {
    color: ${({ theme }) => theme.palette.secondary.yellow.golden};
  }
`

export const StyledCard = styled(Card)`
  ${({ backgroundimage }) => backgroundimage && `background: url(${backgroundimage});`}
  background-size: cover;
  background-position: center center;
  cursor: pointer;
`

export const TileContents = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 173px;
`

export const PlusIcon = styled('div')`
  line-height: 100px;
  font-size: 100px;
  height: 113px;
  text-align: center;
  font-weight: 100;
  :before {
    content: '+';
  }
`

export const NewTileFooter = styled('div')`
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.text.black};
  font-size: ${({ theme }) => theme.custom.font.size.lg};
  width: 1fr;
  height: .35fr;
  padding: 2px 15px 0 15px;
  z-index: 200;
`

export const TileDraftMode = styled('div')`
  padding: 6px 15px 0 15px;
  margin-bottom: 58px;
  background: rgba(255, 255, 255, 0.75);
  width: 220px;
  height: 38px;
  color: ${({ theme }) => theme.palette.text.grey};
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  font-style: italic;
  font-weight: 500;
  opacity: 0.8;
`

export const TileFooter = styled(NewTileFooter)`
  background-color: rgba(14, 20, 39, 0.5);
  color: ${({ theme }) => theme.palette.text.white};
`

export const TileAmenityName = styled('div')`
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  ${({ name }) => name ? `:before { content: "${name}"; }` : ''}
`

export const TileAmenityRow = styled('div')`
  line-height: 1px;
`

export const TileAmenityOccupancy = styled('div')`
  display: inline-block;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  font-weight: 500;
  opacity: 0.8;
  ${({ text }) => text ? `:before { content: "${text}"; }` : ''}
`

export const TileAmenityPin = styled('div')`
  display: inline-block;
  float: right;
  padding-bottom: 4px;
  z-index: 200;
`

export const MultiDayCard = styled('div')`
  display: block;
`
