import styled from '@emotion/styled'

export const UnitContainer = styled('div')`
  height: 56px;
  display: flex;
  -webkit-display: flex;
  -webkit-align-items: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: ${({ theme, lastUnit }) =>
    lastUnit ? `0px` : `1px solid ${theme.palette.neutrals.deepGray}`};

  * {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }

  a {
    color: ${({ theme }) => theme.palette.primary.green.seeFoam};
    text-decoration: underline;
  }

  & > div {
    width: 100%;
  }

  ${({ selected, theme }) =>
    selected &&
    `
    *, a {
    color: ${theme.palette.neutrals.deepGray}
  }
  `};

  .unit-containter-flex {
    height: inherit;

    & > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`

export const SelectorWrap = styled('div')`
  margin-top: -1.5em;
  grid-column: ${({ columnStart }) => columnStart} / span
    ${({ columnSpan }) => columnSpan};
  grid-row: ${({ rowStart }) => rowStart} / span ${({ rowSpan }) => rowSpan};
`

export const Wrapper = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(7, 1fr);
  height: 100%;
  grid-column: ${({ columnStart }) => columnStart} / span
    ${({ columnSpan }) => columnSpan};
  grid-row: ${({ rowStart }) => rowStart} / span ${({ rowSpan }) => rowSpan};
`

export const CircleWrap = styled('div')`
  width: ${({ large }) => (large ? '2em' : '1.4em')};
  height: ${({ large }) => (large ? '2em' : '1.4em')};
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ theme, selected }) =>
    selected
      ? `1px solid ${theme.palette.neutrals.deepGray}`
      : `1px solid ${theme.palette.secondary.pink.coral}`};
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }
`

export const hubDevicesTable = { marginRight: '60px' }
