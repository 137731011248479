// @flow
import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import Typography from '@material-ui/core/Typography'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import { CommonIcon } from '@icons'
import { UnitContainer, SearchResultItem, SearchResultIcon } from './styles'
import type { CarouselUnitProps as Props } from './types'

const renderSearchAttribute = (theme, value) => {
  if (!value) return null
  return (
    <Fragment>
      <SearchResultIcon>
        <CommonIcon
          name="bullet"
          width="4px"
          height="4px"
          fill={theme.palette.grey.main}
          style={{ alignSelf: 'center' }}
        />
      </SearchResultIcon>
      <SearchResultItem>
        <Typography>{value}</Typography>
      </SearchResultItem>
    </Fragment>
  )
}
const CarouselUnit = ({
  result = {},
  lastUnit,
  propertyId,
  theme,
  children,
  addToSearchHistory
}: Props) => (
  <UnitContainer lastUnit={lastUnit}>
    <FlexDirection fullWidth>
      {children ? (
        children
      ) : (
        <React.Fragment>
          <FlexItem flex={3} displayFlex style={{ whiteSpace: 'nowrap' }}>
            {result.name && result.name.toUpperCase()}
            {renderSearchAttribute(theme, result.category)}
            {renderSearchAttribute(theme, result.building)}
            {renderSearchAttribute(theme, result.matched)}
          </FlexItem>
          <FlexItem displayFlex flexEnd flex={2}>
            <a onClick={() => addToSearchHistory(result)}>View</a>
          </FlexItem>
        </React.Fragment>
      )}
    </FlexDirection>
  </UnitContainer>
)

export default withRouter(withTheme(CarouselUnit))
