// @flow

import React from 'react'
import Dropzone from 'react-dropzone'
import {
  DropBox,
  DeleteFileButton,
  ErrorDropBox,
  FileName,
  RedCancelOutlinedIcon,
  UploadedFile,
} from './styles'

type Props = {
  fileSizeLimit: number,
  fileType: Array<string>,
  fileExtension: Array<string>,
  fileUploadError: string,
  onFileUploadError: Function,
  modalType: string,
  onFileUpload: Function,
  onFileDelete: Function,
  uploadedFiles: ?Array<string>,
  maxCount: number,
  multiple: boolean,
}

type State = {
  files: Array<string>,
  fileUrls: Array<string>,
  fileNames: Array<string>,
  selectedIndex: number,
}

export default class FileUpload extends React.Component<Props, State> {
  state = {
    files: [],
    fileFormat: '',
    fileUrls: [],
    fileNames: [],
    selectedIndex: 0,
  }

  fileUploadRef: Object = React.createRef()

  validateFile = (file: Object) => {
    if (!file) {
      this.props.onFileUploadError('Error: a valid file was not detected')
    }
    if (this.state.files.length >= this.props.maxCount) {
      this.props.onFileUploadError('Oops! It looks like you are attempting to upload too many files.')
    } else if (!this.props.fileType.includes(file.type)){
      this.props.onFileUploadError('Oops! It looks like you are trying to upload a file that is not in a compatible format.')
    } else if (file.size > this.props.fileSizeLimit) {
      this.props.onFileUploadError('Oops! It looks like you are attempting to upload a file that is too large.')
    }

    return file
  }

  fileToBase64 = (file: Object): Promise<string> => {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.onload = function (event) {
        resolve((event.target: Object).result)
      }
      reader.readAsDataURL(file)
    })
  }

  deleteFile = (index: number) => {
    index !== 0 && index === this.state.files.length - 1 && this.setState({ selectedIndex: index - 1 })

    const files = this.state.files
    const fileUrls = this.state.files
    const fileNames = this.state.fileNames
    files.splice(index, 1)
    fileUrls.splice(index, 1)
    fileNames.splice(index, 1)
    this.setState({ files: files, fileUrls: fileUrls, fileNames: fileNames, }, () => {
      this.props.onFileUpload(this.state.files, this.state.fileUrls)
      this.props.onFileDelete()
    })
  }

  onDropAccepted = async (acceptedFiles: Array<Object>) => {
    if (acceptedFiles) {
      this.setState({
        files: [],
        fileUrls: [],
        fileNames: [],
      })
      if (this.validateFile(acceptedFiles[0])) {
        const uploadedFileNames = acceptedFiles[0].name
        const uploadedFiles = acceptedFiles[0]
        const uploadedFileUrls = await URL.createObjectURL(acceptedFiles[0])
        uploadedFiles.url = uploadedFileUrls
        this.setState({
          files: [uploadedFiles],
          fileUrls: [uploadedFileUrls],
          fileNames: [uploadedFileNames],
        }, () => {
          this.props.onFileUpload(this.state.files)
        })
      }
    }
  }

  componentDidMount = () => {
    const { uploadedFiles } = this.props
    if (uploadedFiles) {
      this.setState({
        files: uploadedFiles,
        fileUrls: uploadedFiles,
      })
    }
  }

  dropBoxBody = (fileNames: Array<string>) => (
    <React.Fragment>
      <UploadedFile>
        {this.state.fileNames.map(file => (<FileName key={`${file}Key`}>{file}</FileName>))}
      </UploadedFile>
      <DeleteFileButton onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        this.deleteFile(0)
      }}
      >
        <RedCancelOutlinedIcon/>
      </DeleteFileButton>
    </React.Fragment>
  )

  render() {
    const {
      fileNames,
    } = this.state

    const {
      fileExtension,
      multiple,
    } = this.props

    return (
      <React.Fragment>
        {fileNames.length === 0 ?
        <Dropzone multiple={multiple} onDropAccepted={this.onDropAccepted}>
          {({ getRootProps, getInputProps }) => (
            <DropBox {...getRootProps()}>
              <input {...getInputProps()} />
              <UploadedFile>
                {`Drag files here or click to select ${fileExtension[0]} files only.`}
              </UploadedFile>
            </DropBox>
          )}
        </Dropzone>
        :
        //  Disabled so an additional file dialog or drop doesn't try to overwrite the file and freeze the window
        <Dropzone disabled>
          {({ getRootProps, getInputProps }) => {
            if (this.props.fileUploadError !== '') {
              return (
                <ErrorDropBox {...getRootProps()}>
                  <input {...getInputProps()} />
                  {this.dropBoxBody(this.state.fileNames)}
                </ErrorDropBox>
              )
            } else {
              return (
                <DropBox {...getRootProps()}>
                  <input {...getInputProps()} />
                  {this.dropBoxBody(this.state.fileNames)}
                </DropBox>
              )
            }
          }}
        </Dropzone>
      } 
      </React.Fragment>
    )
  }
}
