import {
  FETCH_PREFIX,
  FETCH_NEXT_PREFIX,
  REFRESH_PREFIX,
  UPDATE_PREFIX,
  SUCCESS,
  ERROR,
  START,
  OPTIMISTICALLY,
  REVERT,
  SET_VIEW_PREFIX,
} from './constants'

const createFetchAction = section => args => ({
  type: `${FETCH_PREFIX}${section}`,
  ...args
})

const createFetchStartAction = section => () => ({
  type: `${FETCH_PREFIX}${section}${START}`
})

export const createFetchSuccessAction = section => payload => ({
  type: `${FETCH_PREFIX}${section}${SUCCESS}`,
  payload
})

const createFetchErrorAction = section => error => ({
  type: `${FETCH_PREFIX}${section}${ERROR}`,
  error
})

export const createFetchActions = section => ({
  fetchAction: createFetchAction(section),
  fetchStartAction: createFetchStartAction(section),
  fetchSuccessAction: createFetchSuccessAction(section),
  fetchErrorAction: createFetchErrorAction(section)
})

const createFetchNextAction = section => args => ({
  type: `${FETCH_NEXT_PREFIX}${section}`,
  ...args
})

const createFetchNextStartAction = section => () => ({
  type: `${FETCH_NEXT_PREFIX}${section}${START}`
})

export const createFetchNextSuccessAction = section => payload => ({
  type: `${FETCH_NEXT_PREFIX}${section}${SUCCESS}`,
  payload
})

const createFetchNextErrorAction = section => error => ({
  type: `${FETCH_NEXT_PREFIX}${section}${ERROR}`,
  error
})

export const createFetchNextActions = section => ({
  ...createFetchActions(section),
  fetchNextAction: createFetchNextAction(section),
  fetchNextStartAction: createFetchNextStartAction(section),
  fetchNextSuccessAction: createFetchNextSuccessAction(section),
  fetchNextErrorAction: createFetchNextErrorAction(section)
})

const createRefreshAction = section => () => ({
  type: `${REFRESH_PREFIX}${section}`
})

const createRefreshStartAction = section => () => ({
  type: `${REFRESH_PREFIX}${section}${START}`
})

const createRefreshSuccessAction = section => payload => ({
  type: `${REFRESH_PREFIX}${section}${SUCCESS}`,
  payload
})

const createRefreshErrorAction = section => error => ({
  type: `${REFRESH_PREFIX}${section}${ERROR}`,
  error
})

export const createRefreshActions = section => ({
  refreshAction: createRefreshAction(section),
  refreshStartAction: createRefreshStartAction(section),
  refreshSuccessAction: createRefreshSuccessAction(section),
  refreshErrorAction: createRefreshErrorAction(section)
})

const createUpdateAction = section => args => ({
  type: `${UPDATE_PREFIX}${section}`,
  ...args
})

const createUpdateStartAction = section => () => ({
  type: `${UPDATE_PREFIX}${section}${START}`
})

const createUpdateSuccessAction = section => payload => ({
  type: `${UPDATE_PREFIX}${section}${SUCCESS}`,
  payload
})

const createUpdateErrorAction = section => () => ({
  type: `${UPDATE_PREFIX}${section}${ERROR}`
})

const createUpdateOptimisticallyAction = section => args => ({
  type: `${UPDATE_PREFIX}${section}${OPTIMISTICALLY}`,
  ...args
})

const createUpdateRevertAction = section => revertChanges => ({
  type: `${UPDATE_PREFIX}${section}${REVERT}`,
  revertChanges
})

export const createFetchAndRefreshActions = section => ({
  ...createFetchActions(section),
  ...createRefreshActions(section)
})

export const createUpdateActions = (section, optimistic, withSuccess = false) =>
  optimistic
    ? {
        updateOptimistcallyAction: createUpdateOptimisticallyAction(section),
        updateRevertAction: createUpdateRevertAction(section),
        updateSuccessAction: withSuccess && createUpdateSuccessAction(section)
      }
    : {
        updateAction: createUpdateAction(section),
        updateStartAction: createUpdateStartAction(section),
        updateSuccessAction: createUpdateSuccessAction(section),
        updateErrorAction: createUpdateErrorAction(section)
      }

export const createViewModeAction = section => payload => ({
  type: `${SET_VIEW_PREFIX}${section}`,
  payload
})
