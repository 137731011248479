// @flow
import React from 'react'
import { CommonIcon } from '@icons'
import type { Theme } from '../../../theme'
import Button from '@Common/Cards/CardButton'

export const EditButton = ({
  editable,
  openEditMode,
  theme
}: {
  theme: Theme,
  editable: boolean,
  openEditMode: () => void
}) => (
  <a onClick={openEditMode}>
    <CommonIcon
      name="edit"
      width="0.9em"
      height="0.9em"
      fill={editable ? theme.palette.primary.green.mint : theme.palette.neutrals.warmGray}
    />
  </a>
)

export const SaveButton = ({
  saveChanges,
  theme
}: {
  theme: Theme,
  saveChanges: () => void
}) => (
  <Button
    text={'save'}
    width={'8em'}
    background={theme.palette.blue.main}
    onClick={saveChanges}
  />
)
