import { RESIDENT_ENGAGEMENT } from './actions'
import withFetch from '../../helpers/reducers/withFetch'

const residentEngagement = (state = null, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withFetch(RESIDENT_ENGAGEMENT)(residentEngagement)
