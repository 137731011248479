import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import PaginatedTable from '@Common/PaginatedTable'
import { withStyles } from '@material-ui/core/styles'
import { Button, Chip } from '@material-ui/core'

export const FabButton = styled(Button)`
  position: absolute;
  bottom: 3.5em;
  right: 1.5em;
  height: 59px;
  width: 59px;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.green.mint};
  }
`

export const Loading = styled('div')`
  right: 50%;
  left: 50%;
  position: absolute;
  padding-top: 10px;
  padding-bottom: 15px;
  ~#showMoreBtn {
    margin-top: 30px;
  }
`

export const StyledColumn = styled('div')`
  height: 4em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-start;
  ${({ index }) => index === 9 && `justify-content: flex-end`};
`

export const StyledCell = styled('div')`
  text-transform: capitalize;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  ${({ index }) => index === 9 && `justify-content: flex-end`};
`

export const StyledTitleCard = styled('div')`
  margin-left: -30px;
  margin-right: -30px;
  padding: 30px 30px 60px 30px;
  background-color: ${({ theme }) => theme.palette.primary.green.iceberg};
`

export const StyledFilterText = styled('span')`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
`

export const StyledClearText = styled('a')`
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  margin-left: 15px;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
`

export const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
  font-weight: bold;
  :visited {
    color: ${({ theme }) => theme.palette.alternates.brightPurple};
  }
  :hover {
    color: ${({ theme }) => theme.palette.secondary.yellow.golden};
  }
`

export const StyledEmailLink = styled('a')`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  margin: 0px 4px;
  :hover {
    color: ${({ theme }) => theme.palette.secondary.yellow.golden};
  }
`

export const StyledTable = styled(PaginatedTable)`
  margin-top: -40px;
`

export const ResidentHappinessCard = styled('div')`
  position: absolute;
  right: 0;
  margin-top: 33px;
  width: 570px;
  height: 266px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 0 27px;
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  border-radius: 4px 0 0 4px;
`

export const ResidentHappinessContent = styled('div')`
  display: flex;
`

export const CardHeader = styled('h2')`
  :after {
    margin-top: 10px;
    display: block;
    background-color: ${({ theme }) => theme.palette.primary.green.mint};
    width: 50px;
    height: 3px;
    content: ' ';
  }
`

export const LegendRow = styled('p')`
  :before {
    display: inline-block;
    content: ' ';
    background-color: ${(props) => props.color};
    width: 5px;
    height: 5px;
    margin-right: 0.2em;
  }
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.custom.font.size.xs};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  margin-left: 2em;
  margin-top: 2em;
`

export const LegendLabel = styled('span')`
  margin-left: 0.2em;
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
`

export const HistoryChart = styled('div')`
  flex: 1;
  margin-left: 2em;
  display: flex;
`

export const HistoryChartItem = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 2em;
  align-items: center;
  color: ${({ theme }) => theme.palette.neutrals.mediumGray};
  font-size: ${({ theme }) => theme.custom.font.size.xs};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  :before {
    content: ' ';
    flex: 1
  }
  div:last-child {
    margin-top: 0.3em;
  }
`

export const BarChart = styled('div')`
  width: 20px;
  height: ${(props) => props.value}%;
  background-color: ${(props) => props.color};
`

export const CenteredText = styled('div')`
  text-align: center;
  font-size: ${({ theme }) => theme.custom.font.size.xs};
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const WidgetText = styled('div')`
  margin-top: 0.7em;
  margin-bottom: 0.8em;
`

export const WidgetContainer = styled('div')`
  margin-top: 0.5em;
  width:100%;
`

export const InfoText = styled('div')`
  text-align: center;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.text.secondary.dark};
`

export const VacantWrapper = styled('div')`
  margin-left: 2em;
`

export const OccupancyWrapper = styled('div')`
  display: grid;
  flex: 1;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
`

export const StyledVerticalLine = styled('div')`
  width: 0.5%;
  height: 150px;
  background-color: ${({ theme }) => theme.palette.neutrals.underlineGray};
`

export const StyledBorderButton = styled('div')`
  border: solid;
  border-color: ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
  width: 14em;
  height: 3.0em;
  font-size: ${({ theme }) => theme.custom.font.size.x};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  padding: 0.5em 1.5em;
  margin-top: -0.1em;
  text-align: center;
  cursor : pointer;
`

export const ShowMoreBtn = withStyles({
  root: {
    display: 'flex',
    marginTop: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '140px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between'
  }
})(Button)

const ChipStyles = theme => ({
  root: {
    margin: '15px 10px 5px 0px',
    textTransform: 'capitalize',
    height: '20px',
    fontSize: theme.custom.font.size.sm,
    fontWeight: theme.custom.font.weight.bold
  },
  colorSecondary: {
    border: `1px solid ${theme.palette.primary.green.mint}`,
    backgroundColor: theme.palette.primary.green.iceberg
  },
  colorPrimary: {
    border: `1px solid ${theme.palette.neutrals.mediumGray}`,
    backgroundColo: theme.palette.common.white,
    color: theme.palette.neutrals.mediumGray
  }
})

export const StyledChip = withStyles(ChipStyles)(Chip);
