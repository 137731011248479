// @flow
import React from 'react'
import SmartCard from './SmartCard'
import ChatCard from './ChatCard'
import OccupancyCard from './OccupancyCard'
import ResidentAppUsageCard from './ResidentAppUsageCard'
import SummaryCard, { PropertySummaryCard } from './SummaryCard'
import { PropertySmartCardContent } from './SmartCard'
import Loader from '../Common/Loader'
import { OccupancyWrapper } from './OccupancyCard/styles'
import { NoProperty } from './styles'

type Props = {
  smartCardData: {
    nonSmartUnitsCount: number,
    smartUnitsCount: number,
    viewMoreUrl: string
  },
  usersCardData: {
    adminCount: number,
    companyAdminUsers: { users: Object[], count: number },
    propertyAdminUsers: { users: Object[], count: number },
    propertyStaffUsers: { users: Object[], count: number }
  },
  occupancyData: {
    totalCount: number,
    occupiedCount: number,
    vacantCount: number,
    progressText: string,
    value: number
  },
  isFetching: boolean,
  summaryDetails: Object,
  propertyId: string,
  url: string,
  hasData: boolean,
  isChatEnabled: boolean,
  engagementData: Object,
  isSmartEnabled: boolean
}

const PropertySummary = ({
  smartCardData,
  usersCardData,
  isFetching,
  summaryDetails,
  occupancyData,
  engagementData,
  isChatEnabled,
  hasData,
  propertyId,
  isSmartEnabled,
  url
}: Props) => {
  if (propertyId === '-1') {
    return (
      <NoProperty>
        This company doesnt have any property under it. Please select another
        company from the dropdown above.
      </NoProperty>
    )
  }
  if (isFetching) return <Loader />
  if (!hasData) return null

  return (
    <React.Fragment>
      <SummaryCard
        captionHeading="PROPERTY"
        filters={[{ name: 'My Most Recent Views', route: '' }]}
        unitName="Summary"
        batteryData={summaryDetails.progressData.battery}
        content={() => <PropertySummaryCard data={summaryDetails.history} />}
        rightContent={() => (
          <OccupancyWrapper>
            <OccupancyCard
              occupiedCount={occupancyData.occupiedCount}
              vacantCount={occupancyData.vacantCount}
              total={occupancyData.totalCount}
              progressText={occupancyData.progressText}
              value={occupancyData.value}
            />
          </OccupancyWrapper>
        )}
      />
      {isSmartEnabled && (
        <SmartCard
          viewMoreUrl={smartCardData.viewMoreUrl}
          active={true}
          countText={'units'}
          content={() => <PropertySmartCardContent data={smartCardData} />}
        />
      )}
      <ResidentAppUsageCard data={engagementData} />
      {isChatEnabled && (
        <ChatCard
          viewMoreUrl={`${url.split('/summary')[0]}/communication`}
          text={
            <div>
              <font color="#000" size="3">
                Engage your community.
              </font>
              &nbsp;Write bulk messages, post updates
              <br />
              and events to a community feed or Chat one on one with a<br />
              resident in app.
            </div>
          }
        />
      )}
    </React.Fragment>
  )
}

export default PropertySummary
