import React from 'react'
import { withRouter } from 'react-router-dom'
import Avatar from '@Common/Avatar'
import Flex from '@Common/Flex'
import {
  ChannelDetailsWrapper,
  AvatarWrapper,
  UserDetailsWrapper,
  UserNameWrapper,
  UserInfoWrapper,
  LinkWrapper,
  LinkStyled,
  UserInfoStyled,
  BalanceSpanStyled
} from './ChannelDetailsStyles'

const avatarSize = () => {
  if (window.innerHeight <= 700) return '2.7em'
  if (window.innerHeight > 700) return '3em'
  if (window.innerHeight > 1000) return '5em'
}

const ChannelDetails = ({
  hasData,
  isFetching,
  userData: { id, phoneNumber, email, picture, unitName, name, balance },
  match: { params }
}) => {
  if (!hasData || isFetching) return null
  return (
    <ChannelDetailsWrapper>
      <AvatarWrapper>
        <Avatar hideShadow size={avatarSize()} picture={picture} />
      </AvatarWrapper>
      <UserDetailsWrapper>
        <React.Fragment>
          <UserNameWrapper>{name}</UserNameWrapper>
          <UserInfoWrapper>
            <Flex flex={1} justifySpaceBetween flexWrap="wrap">
              <UserInfoStyled>{unitName}</UserInfoStyled>
              <UserInfoStyled>{phoneNumber}</UserInfoStyled>
              <UserInfoStyled>{email}</UserInfoStyled>
            </Flex>
            <Flex flex={1} justifyRight>
              {balance && (
                <UserInfoStyled>
                  balance due: <BalanceSpanStyled>${balance}</BalanceSpanStyled>
                </UserInfoStyled>
              )}
            </Flex>
          </UserInfoWrapper>
        </React.Fragment>
      </UserDetailsWrapper>
      <LinkWrapper>
        <LinkStyled
          to={`/properties/${params.propertyId}/user-management/profile/${id}`}>
          view more
        </LinkStyled>
      </LinkWrapper>
    </ChannelDetailsWrapper>
  )
}

export default withRouter(ChannelDetails)
