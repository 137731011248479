import styled from '@emotion/styled'
import MaterialTextField from '@material-ui/core/TextField'
import FlexDirection from '@Common/Flex/FlexDirection'
import Flex from '@Common/Flex'

export const Wrapper = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 2em;
  margin: 0 auto;
`

export const PanelHeading = styled('div')`
  display: flex;
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};

  & > div:first-child {
    flex: 0.5;
    width: 15px;
    height: 20px;
    margin-right: 16px;
    font-weight: ${({ theme }) => theme.custom.font.weight.bold};
    color: ${({ theme }) => theme.palette.primary.navy.midnight};
    border-radius: 50px;
    text-align: center;
    background-color: ${({ theme }) => theme.palette.primary.green.mint};
  }
  div:nth-child(2) {
    flex: 11;
    white-space: nowrap;
    font-weight: ${({ theme }) => theme.custom.font.weight.light};
    color: ${({ theme }) => theme.palette.primary.navy.midnight};
  }
`

export const Detail = styled('div')`
  width: 96%;
  margin-left: auto;
  display: flex;
  line-height: 1.8em;
  margin-bottom: 0.5em;
  & > div:first-child {
    display: flex;
    white-space: nowrap;
    flex: 9;
    color: ${({ theme }) => theme.palette.grey.main};
    align-items: center;
    cursor: ${({ theme, verified }) => (verified ? 'initial' : 'pointer')};
    position: relative;
    &::before {
      position: absolute;
      height: 5px;
      width: 5px;
      line-height: 5px;
      background-color: ${({ theme, verified }) =>
        verified ? '#01786C' : theme.palette.error.dark};
      content: '';
      left: -25px;
      border-radius: 50%;
      background-clip: padding-box;
    }

    & p {
      max-height: 20px;
      margin: 0;
    }
  }
  & > div:last-child {
    flex: 0.9;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
  }
`

export const TextField = styled(MaterialTextField)`
  width: ${({ width }) => (width ? width : '40%')};
  padding: 0em 0.7em;
  border: 1px solid ${({ theme }) => theme.palette.common.black};
  border-radius: 1em;
  font-size: ${({ theme }) => theme.custom.font.size.md};

  input {
    flex: 11;
  }

  .cancel-edit:hover {
    cursor: pointer;
  }
`

export const Name = styled('span')`
  font-weight: ${({ theme }) => theme.custom.font.weight.light};

  & > span {
    border-radius: 1em;
    border: 1px solid ${({ theme }) => theme.palette.common.black};
    padding: 0.2em 0.7em;
    width: 100%;
  }
`

export const ActionLink = styled('div')`
  border: 1px solid
    ${({ theme, outlineColor }) =>
      outlineColor ? outlineColor : theme.palette.primary.green.mint};
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  text-align: center;
  line-height: 0.8em;
  margin-right: 0.4em;
  margin-left: 0.4em;

  &:hover {
    cursor: pointer;
  }
`

export const IncreaseCount = styled(ActionLink)`
  transform: rotate(-90deg);
  margin-right: 0.5em;
`

export const DecreaseCount = styled(ActionLink)`
  transform: rotate(90deg);
`

export const Caret = styled('span')`
  font-size: ${({ theme }) => theme.custom.font.size.lg};
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  &::-webkit-selection {
    background: none;
  }
  &::selection {
    background: none;
  }
`

export const DetailWrapper = styled('div')`
  display: block;
  width: 100%;

  overflow-y: scroll;
  max-height: 15em;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${({ theme }) => theme.palette.neutrals.deepGray};
  }

  ${ActionLink} {
    ${({ disableActionLinks, theme }) =>
      disableActionLinks &&
      `
    * {
      color: ${theme.palette.neutrals.deepGray};
      fill: ${theme.palette.neutrals.deepGray};
      stroke: ${theme.palette.neutrals.deepGray};
    }
    cursor:default;
  border: 1px solid ${theme.palette.neutrals.deepGray};`};
  }
`

export const AddNew = styled('div')`
  color: ${({ theme }) => theme.palette.primary.green.mint};
  width: 100%;
  text-align: right;
`
export const Error = styled('span')`
  color: ${({ theme }) => theme.palette.error.main};
`
export const LockCodeSaveButton = styled('div')`
  width: 160%;
  background: ${({ theme }) => theme.palette.primary.green.mint};
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 1em;

  &:hover {
    cursor: pointer;
  }
`

export const CustomFlexDirection = styled(FlexDirection)`
  width: 55%;
  margin: 0 auto;
  justify-content: center;
`

export const LockCodesHeader = styled(Flex)`
  margin-bottom: 71px;

  & .lock-code-resync {
    height: fit-content;
  }
`
