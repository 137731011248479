// @flow

import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import { CommonIcon } from '@icons'
import Tooltip from '../Tooltip'
import { StyledMaterialTextField, ToolTipWrapper } from './styles'
import type { Node } from 'react'
import { withTheme } from 'emotion-theming'
import type { Theme } from '../../../theme'

type Props = {
  value: string,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  prefix?: Node,
  showPencil?: boolean,
  textAlign?: string,
  label: string,
  editMode: boolean,
  readOnly: boolean,
  alwaysShowLabel: boolean,
  disableUnderline?: boolean,
  textMask?: () => {},
  autoFocus: boolean,
  theme: Theme,
  isEditor: boolean,
  hideCaret: boolean,
  toolTipText: string,
  placeholder: string,
  largeFont: boolean,
  padding?: string,
  maxWidth?: string,
  addLabelPadding?: boolean,
  multilineNoResize?: boolean,
  endAdornment: string,
  endAdornmentOnClick: () => void,
  endAdornmentNode: Node
}

class TextField extends React.Component<Props, { active?: boolean }> {
  state = {
    active: this.props.autoFocus
  }

  highlightPencil = () => {
    this.setState({ active: true })
  }

  dimPencil = () => {
    this.setState({ active: false })
  }

  getEndAdornment = () => {
    const {
      endAdornment,
      showPencil = true,
      endAdornmentNode,
      endAdornmentOnClick,
      theme
    } = this.props
    const { active } = this.state
    let iconName = null
    if (showPencil) iconName = 'edit'
    if (endAdornment != null) iconName = endAdornment

    if (iconName != null) {
      return (
        <InputAdornment position="end">
          <CommonIcon
            name={iconName}
            height="0.8em"
            width="0.8em"
            fill={
              active ? theme.palette.primary.green.mint : theme.palette.neutrals.deepGray
            }
            style={{ cursor: endAdornmentOnClick ? 'pointer' : null }}
            onClick={endAdornmentOnClick}
          />
        </InputAdornment>
      )
    }
    if (endAdornmentNode != null) {
      return <InputAdornment position="end">{endAdornmentNode}</InputAdornment>
    }
    return null
  }

  render() {
    const {
      value,
      onChange,
      prefix,
      textAlign = 'right',
      label = '',
      readOnly = false,
      editMode = false,
      alwaysShowLabel = false,
      textMask,
      disableUnderline = false,
      theme,
      isEditor = false,
      hideCaret = false,
      toolTipText = '',
      placeholder = '',
      largeFont = false,
      padding = '',
      maxWidth = '',
      addLabelPadding = true,
      multilineNoResize = false,
      ...props
    } = this.props

    const { active } = this.state
    const color = editMode
      ? ''
      : placeholder === 'Bio'
      ? ''
      : theme.palette.common.black
    const fontSize = largeFont ? theme.custom.font.size.lg : ''

    return (
      // $FlowDisableNextLine
      <StyledMaterialTextField
        isEditor={isEditor}
        hideCaret={hideCaret}
        onChange={onChange}
        onFocus={this.highlightPencil}
        onBlur={this.dimPencil}
        value={value}
        label={alwaysShowLabel || (active && editMode) ? label : ''}
        placeholder={placeholder}
        padding={padding}
        maxwidth={maxWidth}
        addlabelpadding={addLabelPadding ? 'true' : undefined}
        multilinenoresize={multilineNoResize ? 'true' : undefined}
        InputProps={{
          inputComponent: textMask && textMask,
          readOnly,
          startAdornment: prefix && (
            <InputAdornment position="start">
              {active && editMode ? '' : prefix}
              {toolTipText && (
                <ToolTipWrapper>
                  <Tooltip type={toolTipText} />
                </ToolTipWrapper>
              )}
            </InputAdornment>
          ),
          endAdornment: this.getEndAdornment(),
          inputProps: {
            style: { textAlign, color, fontSize }
          },
          disableUnderline
        }}
        {...props}
      />
    )
  }
}

export default withTheme(TextField)
