import { connect } from 'react-redux'
import VendorLockCodes from './VendorLockCodes'
import {
  fetchVendorCodes,
  resendCode,
  invalidateCode,
  fetchVendorCodeUnits,
  searchVendorCodes,
  fetchVendor
} from 'zego-shared/store/lockCodes/actions'
import {
  getVendorCodes,
  getVendorCodeUnits,
  getVendorName
} from 'zego-shared/store/lockCodes/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { getUserRole } from 'zego-shared/store/authorization/selectors'

const mapStateToProps = (
  state,
  { location: { search }, match: { params } }
) => ({
  items: getVendorCodes(state, params.vendorId),
  codeUnits: getVendorCodeUnits(state),
  isFetching: createIsFetchingSelector(['vendor'])(state),
  isFetchingUnits: createIsFetchingSelector(['vendorcodeunits'])(state),
  vendorName: getVendorName(state),
  userRole: getUserRole(state),
})

const mapDispatchToProps = (dispatch, { match }) => {
  const { vendorId, propertyId } = match.params
  return {
    fetchData: () => {
      dispatch(fetchVendorCodes(vendorId, propertyId, { valid: true }))
      dispatch(fetchVendor(vendorId, propertyId))
    },
    resendVendorCode: codeId => dispatch(resendCode(codeId, propertyId)),
    invalidateVendorCode: codeId =>
      dispatch(invalidateCode(codeId, propertyId, vendorId)),
    fetchCodeUnits: codeId =>
      dispatch(fetchVendorCodeUnits(codeId, propertyId)),
    searchVendorCodes: searchTerm => {
      if (searchTerm) {
        dispatch(searchVendorCodes(propertyId, searchTerm))
      } else {
        dispatch(fetchVendorCodes(vendorId, propertyId, { valid: true }))
      }
    },
  }
}

const VendorLockCodesWithFetchData = withFetchData(VendorLockCodes)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorLockCodesWithFetchData)
