// @flow

import React from 'react'
import { Menu, MenuItem } from '@material-ui/core'
import { CommonIcon } from '@icons'
import { IconButton, Link } from './styles'

type Props = {
  logout: () => mixed,
  supportUrl: () => string,
  onSalesForceSelect: () => mixed,
  supportTicketEnabled: boolean

}

type State = {
  anchorEl: ?React.DOM
}

class MoreMenu extends React.Component<Props, State> {
  state = {
    anchorEl: null
  }

  handleClick = ({ currentTarget }: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ anchorEl: currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleSfCase = () => {
    const { onSalesForceSelect } = this.props
    this.handleClose()
    onSalesForceSelect()
  }

  handleLogout = () => {
    const { logout } = this.props
    this.handleClose()
    logout()
  }

  render() {
    const { anchorEl } = this.state

    let showSfMenu = (this.props.supportTicketEnabled) && window._env_.REACT_APP_SF_CASE_URL

    return (
      <div>
        <IconButton
          aria-owns={anchorEl && 'simple-menu'}
          aria-haspopup='true'
          onClick={this.handleClick}>
          <CommonIcon name='3dots' height='16px' />
        </IconButton>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}>
          <Link
            href={this.props.supportUrl}
            target='_blank'
            rel='noopener noreferrer'>
            <MenuItem onClick={this.handleClose}>Get Help</MenuItem>
          </Link>
          { showSfMenu &&
              <MenuItem onClick={this.handleSfCase}>Submit a Case</MenuItem>
          }
          <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    )
  }
}

export default MoreMenu
