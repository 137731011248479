// @flow

import React from 'react'
import reject from 'lodash/reject'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import { generate as shortid } from 'shortid'
import { NavIcon } from '@icons'
import Flex from '@Common/Flex'
import Tooltip from '../Common/Tooltip'
import { Underline } from '@Common/Cards/BaseCard/styles'
import { NavLink } from './styles'

const getMenuLinks = prefix => [
  {
    route: `${prefix}/summary`,
    text: 'summary',
    type: 'summaryQuickLink'
  },
  {
    route: `${prefix}/smart`,
    text: 'zego smart',
    type: 'smartQuickLink',
    key: 'smartResidentAccess'
  },
  {
    route: `${prefix}/user-management`,
    text: 'user management',
    type: 'userManagementQuickLink'
  },
  {
    route: `${prefix}/work-orders`,
    text: 'work orders',
    type: 'workOrdersQuickLink',
    key: 'standaloneWorkOrders'
  },
]

const getActiveMenuLinks = (propertyId, unitID, propertyAccessSettings) => {
  return reject(
    getMenuLinks(`/properties/${propertyId}/units/${unitID}`),
    ({ key }) => key && !propertyAccessSettings[key]
  )
}

const QuickLinks = ({ theme, selected, propertyID, settings, unitID }) => (
  <Flex>
    {getActiveMenuLinks(propertyID, unitID, settings).map(
      ({ route, text, type }, index) => (
        <Flex key={shortid()} direction="column" alignCenter>
          <NavLink key={index} to={route}>
            <Tooltip
              type={type}
              tooltipContent={
                <NavIcon
                  name={text}
                  width="20px"
                  height="22px"
                  opacity="0.75"
                  fill={
                    selected === text
                      ? theme.palette.primary.navy.spaceBlue
                      : theme.palette.neutrals.deepGray
                  }
                />
              }
            />
          </NavLink>
          {selected === text && (
            <Underline
              thick
              borderColor={theme.palette.secondary.yellow.solarFlare}
              height="2px"
              length="10px"
            />
          )}
        </Flex>
      )
    )}
  </Flex>
)

export default withRouter(withTheme(QuickLinks))
