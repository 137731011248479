import { select, put, call, takeLatest, takeEvery } from 'redux-saga/effects'
import api from '../../../../utils/api'
import log from '../../../../utils/logger'
import isEmpty from 'lodash/isEmpty'

import { getToken } from '../../../authorization/selectors'
import {
  fetchPinnedChannelsStart,
  fetchPinnedChannelsSuccess,
  fetchPinnedChannelsError,
  fetchPinnedChannels as fetchPinnedChannelsAction,
  PIN_CHANNEL,
  UNPIN_CHANNEL
} from '../actions'
import { showSnackbar } from '../../../snackbar/actions'
import {
  pinChannelSuccess,
  unpinChannelSuccess,
  genericErrorMsg
} from '../../../../utils/messages'

export function* fetchPinnedChannels(action) {
  try {
    const authToken = yield select(getToken)
    yield put(fetchPinnedChannelsStart())
    const response = yield call(api.getPinnedChannels, authToken)
    yield put(fetchPinnedChannelsSuccess(response))
    return response
  } catch (error) {
    yield put(fetchPinnedChannelsError(error))
    log(`Failed to fetch pinned channels. Error: ${error}`)
  }
}

export function* pinChannel({ channelUrl, pinType }) {
  try {
    const authToken = yield select(getToken)
    const response = yield call(api.pinChannel, authToken, channelUrl, pinType)
    yield put(fetchPinnedChannelsAction())
    yield put(showSnackbar(pinChannelSuccess, 'success'))
  } catch (err) {
    log(`Failed to pin channels. Error: ${err}`)
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

export function* unpinChannel({ channelUrl, pinType }) {
  try {
    const authToken = yield select(getToken)
    const response = yield call(
      api.unpinChannel,
      authToken,
      channelUrl,
      pinType
    )
    yield put(fetchPinnedChannelsAction())
    yield put(showSnackbar(unpinChannelSuccess, 'success'))
  } catch (err) {
    log(`Failed to unpin channels. Error: ${err}`)
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

function* watchFetchPinnedChannel() {
  yield takeEvery(fetchPinnedChannelsAction().type, fetchPinnedChannels)
}

function* watchPinChannel() {
  yield takeLatest(PIN_CHANNEL, pinChannel)
}
function* watchUnpinChannel() {
  yield takeLatest(UNPIN_CHANNEL, unpinChannel)
}

export default [
  watchFetchPinnedChannel(),
  watchPinChannel(),
  watchUnpinChannel()
]
