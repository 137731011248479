import {
  createFetchActions,
  createUpdateActions
} from '../helpers/actions/creators'

export const AMENITIES = 'AMENITIES_LEGACY'
export const AMENITY = 'AMENITY_LEGACY'
export const UPDATE_AMENITY = 'UPDATE_AMENITY_LEGACY'
export const DELETE_AMENITY = 'DELETE_AMENITY_LEGACY'

export const {
  fetchAction: fetchAmenitiesAction,
  fetchStartAction: fetchAmenitiesStart,
  fetchSuccessAction: fetchAmenitiesSuccess,
  fetchErrorAction: fetchAmenitiesError
} = createFetchActions(AMENITIES)

export const fetchAmenities = (propertyId, page) =>
  fetchAmenitiesAction({ propertyId, page })

export const searchAmenities = (propertyId, page, searchTerm, queryParams = {}) =>
  fetchAmenitiesAction({
    propertyId,
    searchTerm,
    queryParams: { ...queryParams, page }
  })

export const {
  fetchAction: fetchAmenityAction,
  fetchStartAction: fetchAmenityStart,
  fetchSuccessAction: fetchAmenitySuccess,
  fetchErrorAction: fetchAmenityError
} = createFetchActions(AMENITY)

export const fetchAmenity = (amenityId, propertyId) => fetchAmenityAction({ amenityId, propertyId })

export const updateAmenityActions = createUpdateActions(AMENITY, true, true)

export const updateAmenity = (update, history, url, amenityId) => ({
  type: UPDATE_AMENITY,
  update,
  history,
  url,
  amenityId
})

export const updateAmenityOptimistically = update =>
  updateAmenityActions.updateOptimistcallyAction({
    update
  })

export const updateAmenitySuccess = payload =>
  updateAmenityActions.updateSuccessAction(payload)

export const updateAmenityRevert = update =>
  updateAmenityActions.updateRevertAction({
    update
  })

export const deleteAmenity = (id, history) => ({
  type: DELETE_AMENITY,
  id,
  history
})
