// @flow
import React from 'react'
import AsyncSelect from 'react-select/async'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tabs from '@Common/Cards/Tabs'
import { CommonIcon } from '@icons'
import { CustomTextField } from '../CreateGroupMessage/styles'
import { MessageDetails, EditModeContainer } from './styles'
import { EditModeHeadProps } from './types'

const handleGroupSearch = (searchGroups, authToken, propertyId, searchTerm) => {
  return new Promise((resolve) => {
    if (searchTerm.charAt(0) === '@' && searchTerm.length !== 1) {
      // remove the @ sign because the backend stores the group without it
      const searchTermWithoutAtSign = searchTerm.substring(1, searchTerm.length)
      searchGroups(authToken, propertyId, searchTermWithoutAtSign).then(
        (groups) => {
          resolve(groups)
        }
      )
    } else {
      resolve([])
    }
  })
}

const EditModeHead = ({
  groups,
  theme,
  title,
  active,
  handleChange,
  currentTab,
  handleTabChange,
  searchGroups,
  authToken,
  propertyId,
  selectedTemplate,
  handleGroupSelect,
  selectedSendMethod,
  templateDisabled,
  isEventView
}: EditModeHeadProps) => {
  const sendingMethods = { name: 'Sending Method' }
  const deliveryMethods = { name: 'Delivery Method' }
  const futureTabs = [deliveryMethods, { name: 'Template' }, sendingMethods]
  const eventsTabs = [deliveryMethods, sendingMethods]

  return (
    <EditModeContainer>
      <br /> <br />
      {selectedSendMethod !== 'event' && (
        <AsyncSelect
          isMulti
          defaultValue={groups}
          loadOptions={(searchTerm) =>
            handleGroupSearch(searchGroups, authToken, propertyId, searchTerm)
          }
          noOptionsMessage={({ inputValue }) =>
            inputValue && inputValue.charAt(0) === '@' && inputValue.length > 1
              ? 'No matches found'
              : null
          }
          onChange={handleGroupSelect}
          styles={{
            container: (styles) => ({
              ...styles,
              marginTop: '1em',
              marginBottom: '1em',
              marginRight: '3em',
            }),
            menu: (styles) => ({
              ...styles,
              top: '',
              position: 'fixed',
              width: '25%',
            }),
          }}
        />
      )}
      <CustomTextField
        className="edit-mode-title"
        value={selectedTemplate.title || title}
        label="Title"
        name="name"
        disabled={templateDisabled}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          disableUnderline: false,
          endAdornment: (
            <InputAdornment position="end">
              <CommonIcon
                name="edit"
                height="14px"
                width="12px"
                fill={
                  active === 'title'
                    ? theme.palette.secondary.main1
                    : theme.palette.grey.main
                }
              />
            </InputAdornment>
          ),
        }}
        onChange={handleChange}
      />
      <MessageDetails
        style={{ color: theme.palette.common.black, paddingBottom: '0.5em' }}>
        <Tabs
          component={null}
          selected={currentTab}
          onTabChange={handleTabChange}
          selectColor={theme.palette.secondary.yellow.solarFlare}
          tabs={isEventView? eventsTabs : futureTabs}
          rightLinks={null}
        />
      </MessageDetails>
    </EditModeContainer>
  )
}

export default EditModeHead
