import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { FlexItem } from '@Common/Flex/FlexDirection'
// carousel unit

export const UnitContainer = styled('div')`
  height: 56px;
  display: flex;
  -webkit-display: flex;
  -webkit-align-items: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: ${({ theme, lastUnit }) =>
    lastUnit ? `0px` : `1px solid ${theme.palette.grey.light7}`};

  * {
    @media only screen and (min-width: 2000px) {
      font-size: ${({ theme }) => theme.custom.font.size.md};
    }
    @media only screen and (min-width: 3000px) {
      font-size: ${({ theme }) => theme.custom.font.size.mdlg};
    }
  }

  a {
    color: ${({ theme }) => theme.palette.secondary.main4};
  }
`

export const UnitName = styled('span')`
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  color: ${({ theme }) => theme.palette.text.black1};
`

export const StyledTypography = styled(Typography)`
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  color: ${({ theme }) => theme.palette.text.black1};
`

export const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  text-decoration: underline;
`

export const ViewLinkItem = styled(FlexItem)`
  text-align: right;
  margin-right: 10px;
`
