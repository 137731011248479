// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import { withRouter } from 'react-router-dom'
import { GridItem } from '../../Common/Grid'
import chunk from 'lodash/chunk'
import PaginatedTable from '../../Common/PaginatedTable'
import Loader from '../../Common/Loader'
import CardButton from '@Common/Cards/CardButton'
import {
  TableContainer,
  StyledColumn,
  StyledRow
} from '../styles'

type Props = {
  theme: any,
  data: Object[],
  count: number,
  isFetching: boolean,
  propertyId: number,
  property: Object[],
  sendLockCode: (id: string) => {}
}

type State = {
  data: Object[],
  currentPage: number
}

const rowsPerPage = 8

class Property extends React.Component<Props, State> {
  state = {
    data: [],
    currentPage: 0
  }

  componentDidUpdate(prevProps) {
    if (prevProps.property !== this.props.property) {
      const { property } = this.props
      this.setState({
        data: chunk(property, rowsPerPage)
      })
    }
  }

  createRows = () => {
    const { data, currentPage } = this.state
    const { theme } = this.props
    const dataToMap = data[currentPage]
    let rows = []

    if (dataToMap) {
      rows = dataToMap.map((rowData, rowIndex) =>
        Object.entries(rowData).map(([key, value], index) => {
          return {
            value: (
              <StyledRow
                firstRow={rowIndex === 0}
                index={index + 1}
                lockCodesMain={index === 2}>
                {index === 2 ? (
                  <CardButton
                    text={'RESEND'}
                    width={'80px'}
                    background={theme.palette.blue.main}
                    onClick={() => this.props.sendLockCode(rowData['id'])}
                  />
                ) : index !==2 && index < 3 && (
                  value
                )}
              </StyledRow>
            )
          }
        })
      )
    }
    return rows
  }

  changePage = page => {
    this.setState({ currentPage: page - 1 })
  }

  render() {
    const { isFetching, count } = this.props
    const { currentPage } = this.state

    return (
      <React.Fragment>
        <GridItem columnStart={3} columnSpan={10} rowStart={3} rowSpan={10}>
          <TableContainer>
          {isFetching ? (
              <Loader />
            ) : (
            <PaginatedTable
              total={count}
              flex={[2, 1, 1]}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              columns={[
                {
                  value: (
                    <StyledColumn index={1}>PROPERTY LEVEL USER</StyledColumn>
                  )
                },
                {
                  value: <StyledColumn index={2}>USER ROLE</StyledColumn>
                },
                {
                  value: <StyledColumn index={3}>CODE</StyledColumn>
                }
              ]}
              rows={this.createRows()}
              changePage={this.changePage}
            />
            )}
          </TableContainer>
        </GridItem>
      </React.Fragment>
    )
  }
}

export default withRouter(withTheme(Property))
