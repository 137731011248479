import { FETCH_PREFIX, SUCCESS } from '../actions/constants'
import { normalizePaginatedResponseWithHeader } from '../normalizer'

// fetch reducer with pagination information saved
export default section => reducer => (state, action) => {
  switch (action.type) {
    case `${FETCH_PREFIX}${section}${SUCCESS}`: {
      return normalizePaginatedResponseWithHeader(action.payload)
    }
    default:
      return reducer(state, action)
  }
}
