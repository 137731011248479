// @flow

import React from 'react'
import { SetupMessageWrapper } from './styles'

const SetupMessage = ({ message }: { message: string }) => (
  <SetupMessageWrapper alignStart justifyCenter>
    <div>{message}</div>
  </SetupMessageWrapper>
)

export default SetupMessage
