import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withTheme } from 'emotion-theming'
import Flex, { FlexItem } from '@Common/Flex/Flex'
import Avatar from '@Common/Avatar'
import { CommonIcon } from '@icons'
import {
  ChatMenuItem,
  TextPreview,
  TimeAndSenderName,
  Sender,
  UnreadPointer,
  Pin
} from './styles'

const isTrue = 1

const ChatListItem = ({
  isSelected,
  shouldShowUnreadIndicator,
  profileUrl,
  name,
  lastMessageInfoText,
  lastMessage,
  userId,
  url,
  height,
  handleChatMenuChange,
  isPinned,
  pinChannel,
  unpinChannel,
  theme
}) => {
  return (
    <React.Fragment>
      <ChatMenuItem
        disableRipple
        height={height}
        selected={isSelected}
        isChatListItem={isTrue}>
        <Flex fullWidth>
          <FlexItem flex={3} onClick={() => handleChatMenuChange(url, userId)}>
            <Flex direction="column" fullWidth>
              <TimeAndSenderName>
              <Flex>
                  <UnreadPointer show={shouldShowUnreadIndicator} />
                  <Avatar size="24px" picture={profileUrl} />
                </Flex>
                <div>
                  <Sender>{name}</Sender>
                  {lastMessageInfoText !== '' && (
                    <CommonIcon name="bullet" width="3px" height="6px" />
                  )}
                  {lastMessageInfoText}
                </div>
              </TimeAndSenderName>
              <TextPreview>
                <div />
                <Typography variant="caption">{lastMessage}</Typography>
              </TextPreview>
            </Flex>
          </FlexItem>
          <Pin>
            {isPinned ? (
              <CommonIcon
                name="pin"
                onClick={() => {
                  unpinChannel(url)
                }}
                fill={theme.palette.primary.green.mint}
                height="10px"
                width="25px"
              />
            ) : (
              <CommonIcon
                name="pin"
                onClick={() => {
                  pinChannel(url)
                }}
                fill={theme.palette.neutrals.warmGray}
                height="10px"
                width="25px"
              />
            )}
          </Pin>
        </Flex>
      </ChatMenuItem>
    </React.Fragment>
  )
}

export default withTheme(ChatListItem)
