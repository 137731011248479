import {
  VEHICLE,
  VEHICLES,
  VEHICLECOLORS,
  VEHICLESTATES,
  VEHICLETYPES,
  VEHICLEMAKES,
  updateVehicleActions
} from './actions'
import withPaginatedFetch from '../../helpers/reducers/withPaginatedFetch'
import withFetch from '../../helpers/reducers/withFetch'

const reducer = (state = null, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const vehicle = (state = null, action) => {
  switch (action.type) {
    case `${updateVehicleActions.updateSuccessAction().type}`:
    case `${updateVehicleActions.updateOptimistcallyAction().type}`:
    case `${updateVehicleActions.updateRevertAction().type}`:
      return { ...state, ...action.update, ...action.payload }
    default:
      return state
  }
}

export const vehicleReducer = withFetch(VEHICLE)(vehicle)
export const vehiclesColorsReducer = withFetch(VEHICLECOLORS)(reducer)
export const vehiclesStatesReducer = withFetch(VEHICLESTATES)(reducer)
export const vehiclesTypesReducer = withFetch(VEHICLETYPES)(reducer)
export const vehiclesMakesReducer = withFetch(VEHICLEMAKES)(reducer)

export default withPaginatedFetch(VEHICLES)(reducer)
