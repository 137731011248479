import { connect } from 'react-redux'
import {
  loginEmail,
  fetchResourcesAndRedirectAfterLogin
} from 'zego-shared/store/authorization/actions'
import { getIsAuth } from 'zego-shared/store/authorization/selectors'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'

import UserSpoofing from './UserSpoofing'

const mapStateToProps = (state, { location: { search } }) => {
  return {
    isAuth: getIsAuth(state),
    error: state.snackbar.message
  }
}

const mapDispatchToProps = {
  loginEmail,
  fetchResourcesAndRedirectAfterLogin,
  displayErrorMsg: message => showSnackbar(message, 'error')
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSpoofing)
