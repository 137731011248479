import { LD_INIT_COMPLETE } from "./actions";

const initialState = {}

const launchdarkly = (state = initialState, action) => {
  switch (action.type) {
    case LD_INIT_COMPLETE:
      return {
        ...state,
        ld: action.ld
      };
    default:
      return state;
  }
}

export default launchdarkly;
