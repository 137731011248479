import moment from 'moment'
import { call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken } from '../../authorization/selectors'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  fetchFutureAction,
  fetchFutureStart,
  fetchFutureSuccess,
  fetchFutureError
} from './actions'

export function* fetchFuture({ propertyId, page }) {
  try {
    yield put(fetchFutureStart())
    const authToken = yield select(getToken)

    const start_time = moment().toISOString()
    const stop_time = moment()
      .add(3, 'M')
      .toISOString()

    const response = yield call(
      api.getAutomationFutureSummary,
      authToken,
      propertyId,
      start_time,
      stop_time
    )
    yield put(fetchFutureSuccess(response))
  } catch (error) {
    yield put(fetchFutureError(error))
    log(`Failed to fetch future group messages`)
  }
}

function* watchFetchFuture() {
  yield takeLatest(fetchFutureAction().type, fetchFuture)
}

export default [watchFetchFuture()]
