// @flow
import React from 'react'
import { ThermostatIcons } from '@icons'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import { withTheme } from 'emotion-theming'
import { ModesWrap, Mode, modeStyle } from './styles'
import { commands } from 'zego-shared/store/devices/sagas/utils/deviceCommands'

const modes = [commands.AUTO, commands.COOL, commands.HEAT, commands.OFF]

const ChangeMode = ({
  theme,
  modeToChangeTo,
  hideText,
  changeThermostatMode
}) => (
  <ModesWrap>
    <FlexDirection>
      {!hideText && (
        <FlexItem style={modeStyle(theme)} flex={1}>
          Change Mode
        </FlexItem>
      )}

      <FlexItem flex={2} displayFlex>
        {modes.map(mode => (
          <Mode key={mode}>
            <ThermostatIcons
              name={mode}
              onClick={() => changeThermostatMode(mode)}
            />
            <span className={modeToChangeTo === mode ? 'selected' : ''}>
              {mode}
            </span>
          </Mode>
        ))}
      </FlexItem>
    </FlexDirection>
  </ModesWrap>
)

export default withTheme(ChangeMode)
