import { combineReducers } from 'redux'
import { devices } from 'zego-shared/store/devices/reducers'
import deviceHistory from 'zego-shared/store/deviceHistory/reducers'
import users from 'zego-shared/store/users/unit/reducers'
import { unitBatteries as batteries } from 'zego-shared/store/batteries/reducers'

const unit = combineReducers({
  devices,
  users,
  deviceHistory,
  batteries
})

export default unit
