import styled from '@emotion/styled'
import { Link as ReactRouterLink } from 'react-router-dom'

export const ChannelDetailsWrapper = styled('div')`
  grid-area: channelDetails;
  display: flex;
  align-items: center;
  z-index: 1;
`

export const UserDetailsWrapper = styled('div')`
  width: 87.5%;
  padding-left: 2.5em;
  padding-right: 2.5em;
`

export const AvatarWrapper = styled('div')`
  width: 5%
  align-self: center;
`

export const UserNameWrapper = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
`

export const UserInfoWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding-top: 0.5em;
  font-size: ${({ theme }) => theme.custom.font.size.md};
`

export const UserInfoStyled = styled('div')`
  color: ${({ theme }) => theme.palette.grey.main};
`

export const LinkWrapper = styled('div')`
  width: 7.5%;
  display: flex;
  white-space: nowrap;
  align-self: center;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`

export const LinkStyled = styled(ReactRouterLink)`
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
  text-decoration: underline;
  margin-top: 30px;
`

export const BalanceSpanStyled = styled('span')`
  color: ${({ theme }) => theme.palette.primary.green.mint};
`
