// @flow
import React, { Component } from 'react'
import { Moment } from 'moment'
import { getFullDate } from '../../../../utils'
import { DescriptionWrapper, DescriptionBlock, Details } from './styles'

type Props = {
  moment: Moment,
  userFullName: string,
  type: string,
  userPicture: string,
  image?: Object,
  createdAt: string,
  eventDuration: string
}

class DateSection extends Component<Props, { showDatePicker: boolean }> {
  render() {
    const {
      moment,
      userFullName,
      type,
      image,
      createdAt,
      eventDuration
    } = this.props

    const isEvent = type === 'events'

    return (
      <DescriptionBlock
        columnStart={!image ? 3 : 6}
        columnSpan={1}
        rowStart={6}
        rowSpan={1}
        image={image}>
        <DescriptionWrapper>
          <Details>
            <span>{isEvent ? getFullDate(moment.format()) : userFullName}</span>
            <br />
            <span>{isEvent ? eventDuration : createdAt}</span>
          </Details>
        </DescriptionWrapper>
      </DescriptionBlock>
    )
  }
}

export default DateSection
