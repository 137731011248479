import { connect } from 'react-redux'
import { fetchUserPets } from 'zego-shared/store/users/profile/actions'
import { getPetsData } from 'zego-shared/store/users/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import Pets from './Pets'

const fetchingSelector = createIsFetchingSelector(['user'])

const mapStateToProps = state => {
  return {
    ...getPetsData(state),
    isFetching: fetchingSelector(state)
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { userId }
    }
  }
) => {
  return {
    fetchData: () => {
      dispatch(fetchUserPets({ userId }))
    }
  }
}

const PetsWithFetchData = withFetchData(Pets)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PetsWithFetchData)
