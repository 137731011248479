// @flow
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
// $FlowDisableNextLine
import { getUserRole } from 'zego-shared/store/authorization/selectors'

const emptyObj = {}

export const getCompanies = (state: Object) => ({
  data: sortBy(Object.values(get(state, 'select.companies.byId', [])), [
    company => company.name.toLowerCase()
  ]),
  byId: get(state, 'select.companies.byId')
})

export const getSelectedCompany = (state: Object) => {
  return get(state, 'select.companies.selected', '')
}

export const getSelectedCompanyProperties = (state: Object) => {
  const company = getSelectedCompany(state)
  const properties = company
    ? company.properties.map(property => property.id)
    : []

  return properties
}

export const getSelectedCompanyID = (state: Object) =>
  get(state, 'select.companies.selected.id', -1)

export const checkSuperAdmin = (state: Object) => {
  const userRole = getUserRole(state)
  return userRole === 'super_admin'
}

export const getHasMoreCompanies = (state: Object) => {
  const totalPages = get(state, 'select.companies.totalPages')
  const currentPage = get(state, 'select.companies.currentPage')
  return totalPages > currentPage
}

export const getNextPage = (state: Object) => {
  const currentPage = get(state, 'select.companies.currentPage')
  return currentPage + 1
}
