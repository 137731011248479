// import React, { Component } from 'react'
import { connect } from 'react-redux'
import withFetchData from 'zego-shared/views/withFetchData'
import { withRouter } from 'react-router-dom'
import ChannelDetails from './ChannelDetails'
import { getChatUserData, getUser } from '../../../../store/Chat/userSelectors'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'

const fetchingSelector = createIsFetchingSelector([
  'chatchannelsbytype',
  'user'
])

const mapStateToProps = (state, { match: { params } }) => ({
  userData: getChatUserData(state),
  hasData: getUser(state),
  isFetching: fetchingSelector(state)
})

const ChannelDetailsWithFetchData = withFetchData(ChannelDetails)

export default withRouter(connect(mapStateToProps)(ChannelDetailsWithFetchData))
