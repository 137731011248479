// @flow
import React from 'react'
import type { Node } from 'react'
import { generate as shortid } from 'shortid'
import { withTheme } from 'emotion-theming'
import { Link, withRouter } from 'react-router-dom'

import type { Theme } from '../../../theme'
import type { Match } from 'react-router-dom'

//components
import { CommonIcon, ChatIcon } from '@icons'
import Flex, { FlexItem } from '@Common/Flex'
import Avatar from '@Common/Avatar'
import { ContactDetails, UserList, UnderLine, ViewMore } from './styles'

export type Props = {
  data: { users: Object[] },
  chatUnreadData: Object,
  theme: Theme,
  match: Match,
  location: { pathname: string }
}

export const UnitSummary = ({
  data: { users },
  chatUnreadData,
  theme,
  match: { url },
  location: { pathname }
}: Props): Node => {
  return (
    <React.Fragment>
      {users &&
        users.slice(0, 3).map((user, index) => (
          <UserList key={shortid()}>
            <Flex paddingTop="10px" alignCenter>
              <FlexItem flex={1}>
                <Avatar picture={user.picture} />
              </FlexItem>
              <FlexItem flex={4.5} flexStart>
                <p style={{ color: theme.palette.common.black }}>
                  {user.displayName}
                </p>
              </FlexItem>
              <FlexItem flex={4}>
                <ContactDetails>
                  <Flex>
                    <FlexItem flex={4}>
                      {user.accountActiveStatus} User
                    </FlexItem>
                    <FlexItem flex={1}>
                      {user.accountActivated && (
                        <Link
                          to={`${
                            url.split('/units')[0]
                          }/communication/chat/live-chat/users/${user.userId}`}>
                          {chatUnreadData[user.userId] === true ? (
                            <ChatIcon
                              name="chatunread"
                              height="20px"
                              width="20px"
                              fill={theme.palette.grey.main}
                            />
                          ) : (
                            <ChatIcon
                              name="chat"
                              height="20px"
                              width="20px"
                              fill={theme.palette.grey.main}
                            />
                          )}
                        </Link>
                      )}
                    </FlexItem>
                    <FlexItem flex={1} paddingLeft="1em">
                      <a href={`mailto:${user.email}`}>
                        <CommonIcon
                          name="envelope_email"
                          height="14px"
                          width="12px"
                        />
                      </a>
                    </FlexItem>
                    <FlexItem flex={4}>
                      {user.phoneNumber && (
                        <div style={{ marginLeft: '0.5em' }}>
                          {user.phoneNumber}
                        </div>
                      )}
                    </FlexItem>
                  </Flex>
                </ContactDetails>
              </FlexItem>
              <FlexItem flex={1.5}>
                <Link
                  to={`${url.split('/units')[0]}/user-management/profile/${
                    user.userId
                  }`}>
                  <p
                    style={{
                      color: theme.palette.primary.green.mint,
                      textAlign: 'right'
                    }}>
                    view profile
                  </p>
                </Link>{' '}
              </FlexItem>
            </Flex>
            {index !== 3 && <UnderLine length="100%" />}
          </UserList>
        ))}
      <Link to={`${url.split('/summary')[0]}/user-management`}>
        <ViewMore>View More +</ViewMore>
      </Link>
    </React.Fragment>
  )
}
export default withRouter(withTheme(UnitSummary))
