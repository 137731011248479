import { PROPERTY_HUBS } from './actions'
import withFetch from '../helpers/reducers/withFetch'

const reducer = (state = null, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withFetch(PROPERTY_HUBS)(reducer)
