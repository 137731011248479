import {
  LD_INIT_COMPLETE,
  LD_INIT_REQUEST
} from "./actions"
import { call, put, takeEvery } from 'redux-saga/effects'
import Promise from 'bluebird'
import * as ldClient from 'launchdarkly-js-client-sdk'

let ld

export function* watchInitLD() {
  yield takeEvery(LD_INIT_REQUEST, initLD)
}

export function* initLD() {
  yield call(getLD, { anonymous: true })
  yield put({ type: LD_INIT_COMPLETE, ld: ld })
}

function getLD () {
  ld = ldClient.initialize(window._env_.REACT_APP_LAUNCHDARKLY_API_KEY, { anonymous: true })
  const ldPromise = Promise.promisify(ld.on)

  return ldPromise('ready')
}

export default [watchInitLD()]
