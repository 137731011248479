// @flow
import get from 'lodash/get'
import moment from 'moment'
import { capitalizeFirstLetter } from 'zego-shared/utils/string'
import type { UnitCardInfo, UnitResidentCardInfo } from './types'

export const getSelectedUnit = (state: Object) =>
  get(state, ['select', 'units', 'selected'], null)

const getOccupiedStatus = (type, occupied): ?string => {
  if (type === 'residential') {
    return occupied ? 'Occupied' : 'Unoccupied'
  }
  return null
}

export const getCardInfo = (state: Object): UnitCardInfo => {
  const selectedUnit = getSelectedUnit(state)
  return selectedUnit
    ? {
        name: capitalizeFirstLetter(selectedUnit.name) || null,
        status: capitalizeFirstLetter(selectedUnit.status) || null,
        activatedDate: selectedUnit.inserted_at
          ? moment(selectedUnit.inserted_at).format('l')
          : null,
        type: capitalizeFirstLetter(selectedUnit.type) || null,
        active: selectedUnit.active,
        occupied:
          getOccupiedStatus(selectedUnit.type, selectedUnit.occupied) || null
      }
    : null
}

export const getResidentsCardInfo = (state: Object): UnitResidentCardInfo => {
  const selectedUnit = getSelectedUnit(state)
  return selectedUnit
    ? {
        name: capitalizeFirstLetter(selectedUnit.name) || null
      }
    : null
}
