// @flow
import React from 'react'

import { CircleText, CircleBackground } from './styles'

type Props = {
  dataCount: number,
  data?: string,
  textColor?: string | (({ theme: any }) => string),
  backgroundColor?: string | (({ theme: any }) => string),
  size?: number,
  elementId?: string,
  onClick?: () => void
}

const CircleWithText = ({
  textColor,
  backgroundColor,
  data,
  dataCount,
  size,
  elementId,
  onClick
}: Props) => (
  <CircleBackground backgroundColor={backgroundColor} size={size}>
    <CircleText onClick={onClick} textColor={textColor} size={size}>
      <span id={elementId}>{dataCount}</span>
      <p>{data}</p>
    </CircleText>
  </CircleBackground>
)

export default CircleWithText
