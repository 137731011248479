import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'
import get from 'lodash/get'
import { getToken, getUserId } from '../../authorization/selectors'
import { getPropertyId, getUnitId } from '../../select/selectors'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import deviceCommands from './utils/deviceCommands'
import { pushMessage } from '../../socket/sagas'
import { genericTimedOutMessage } from '../../../utils/messages'
import { getHubIdentifier, getDeviceById } from '../selectors'
import {
  CREATE_CODE,
  EDIT_CODE,
  EXTEND_CODE,
  DELETE_CODE,
  RESYNC_CODES,
  fetchLockCodesStart,
  fetchLockCodesSuccess,
  fetchLockCodesError,
  fetchPermissionsStart,
  fetchPermissionsError,
  fetchPermissionsSuccess,
  editCodeStart,
  editCodeSuccess,
  editCodeError,
  extendCodeStart,
  extendCodeSuccess,
  extendCodeError,
  deleteCodeStart,
  deleteCodeSuccess,
  deleteCodeError,
  createCodeStart,
  createCodeSuccess,
  createCodeError,
  fetchLockCodes,
  fetchPermissions,
  resyncLockCodesStart,
  resyncLockCodesError,
  resyncLockCodesSuccess
} from '../actions'
import { DEVICE_EVENT } from '../../addDevice/connected/actions'
import {
  getDeviceChannel,
  resourceTypeFromCodeType,
  isLockCodeStatus,
  isCommandResponse,
  isExecutedCommandResponse,
  extractCodeInfo
} from './utils'
import { generateId } from '../../../utils/id'
import { showSnackbar } from '../../snackbar/actions'

const LOCK_CODE_UPDATE_TIME_OUT = 60000

export function* getLockCodes({ id }) {
  try {
    yield put(fetchLockCodesStart())
    const { identifier } = yield select(getDeviceById, parseInt(id, 10))
    const command = deviceCommands.lock.getLockCodesStatus(identifier)

    const test = yield call(isLockCodeStatus, command)
    const result = yield call(sendCommandAndWait, command, test)

    const lockCodes = get(result, ['response', 'result'])

    yield put(fetchLockCodesSuccess({ id, lockCodes }))
  } catch (error) {
    log(`Failed to get lock codes. ${error}`)
    yield put(fetchLockCodesError(error))
  }
}

export function* getLockCodePermissions() {
  try {
    const authToken = yield select(getToken)
    const userId = yield select(getUserId)

    yield put(fetchPermissionsStart())

    const { permissions } = yield call(
      api.getLockCodePermissions,
      authToken,
      userId
    )

    yield put(fetchPermissionsSuccess(permissions))
  } catch (error) {
    log(`Failed to get lock code permissions. ${error}`)
    yield put(fetchPermissionsError())
  }
}

export function* createCode({ id, identifier, codeValue, codeType }) {
  let tempCodeId
  let resourceType
  let resourceId

  try {
    tempCodeId = yield call(generateId)
    resourceType = resourceTypeFromCodeType(codeType)
    if (resourceType === 'unit') {
      resourceId = yield select(getUnitId)
    } else {
      resourceId = yield select(getPropertyId)
    }

    const command = yield call(
      deviceCommands.lock.createCode,
      identifier,
      codeValue,
      codeType,
      resourceType,
      resourceId
    )

    yield put(createCodeStart(id, tempCodeId, codeType))

    const test = yield call(isCommandResponse, command.tag)

    const { response } = yield call(sendCommandAndWait, command, test)
    const codeInfo = yield call(extractCodeInfo, response)

    yield put(createCodeSuccess(id, tempCodeId, codeInfo))
  } catch (error) {
    log(`Failed to create code. ${error}`)

    if (tempCodeId) {
      yield put(createCodeError(id, tempCodeId))
    }
  }
}

export function* editCode({ id, identifier, codeId, codeValue }) {
  try {
    const command = yield call(
      deviceCommands.lock.editCode,
      identifier,
      codeId,
      codeValue
    )

    yield put(editCodeStart(id, codeId))

    const test = yield call(isCommandResponse, command.tag)

    const { response } = yield call(sendCommandAndWait, command, test)
    const codeInfo = yield call(extractCodeInfo, response)

    yield put(editCodeSuccess(id, codeId, codeInfo))
  } catch (error) {
    log(`Failed to edit code. ${error}`)
    yield put(editCodeError(id, codeId))
  }
}

export function* extendCode({ id, identifier, codeId, codeValue }) {
  try {
    const command = yield call(
      deviceCommands.lock.extendCode,
      identifier,
      codeId,
      codeValue
    )

    yield put(extendCodeStart(id, codeId))

    const test = yield call(isCommandResponse, command.tag)

    const { response } = yield call(sendCommandAndWait, command, test)
    const codeInfo = yield call(extractCodeInfo, response)

    yield put(extendCodeSuccess(id, codeId, codeInfo))
  } catch (error) {
    log(`Failed to extend code. ${error}`)
    yield put(extendCodeError(id, codeId))
  }
}

export function* deleteCode({ id, identifier, codeId }) {
  try {
    const command = yield call(
      deviceCommands.lock.deleteCode,
      identifier,
      codeId
    )

    yield put(deleteCodeStart(id, codeId))

    const test = yield call(isExecutedCommandResponse, command.tag)
    yield call(sendCommandAndWait, command, test)

    yield put(deleteCodeSuccess(id, codeId))
  } catch (error) {
    log(`Failed to delete code. ${error}`)
    yield put(deleteCodeError(id, codeId))
  }
}

export function* resyncCodes({ id }) {
  try {
    const { identifier } = yield select(getDeviceById, parseInt(id, 10))
    const command = yield call(deviceCommands.lock.resyncCodes, identifier)
    yield put(resyncLockCodesStart())
    const test = yield call(isLockCodeStatus, command)
    yield call(sendCommandAndWait, command, test)
    yield put(resyncLockCodesSuccess())
    yield put(fetchLockCodes(id))
    yield put(showSnackbar('Lock codes resync successful', 'success'))
  } catch (error) {
    log(`Failed to resync codes. ${error}`)
    yield put(showSnackbar('Lock codes resync failed', 'error'))
    yield put(resyncLockCodesError())
  }
}

/**
 * Send a command and wait for the expected code event
 */
export function* sendCommandAndWait(command, test) {
  const hubIdentifier = yield select(getHubIdentifier)
  const deviceChannel = yield call(getDeviceChannel, hubIdentifier)

  // the order of effects matters, need to start listening first before sending command
  // also using all here offers us automatic cancellation once an effect fails
  const { event } = yield all({
    event: call(waitForCodeEvent, test),
    pushMessage: call(pushMessage, deviceChannel, command)
  })

  return event
}

/**
 * Wait for the expected code event until timed out
 */
export function* waitForCodeEvent(test) {
  const { event, timeout } = yield race({
    event: call(watchForLockCodeEvent, test),
    timeout: delay(LOCK_CODE_UPDATE_TIME_OUT)
  })

  if (timeout) {
    throw new Error(genericTimedOutMessage)
  }

  return event
}

/**
 * Listen to every DEVICE_EVENT till we find the expected one
 */
export function* watchForLockCodeEvent(test) {
  while (true) {
    const action = yield take(DEVICE_EVENT)
    const { event } = action

    if (test(event)) {
      return event
    }
  }
}

function* watchGetLockCodes() {
  yield takeEvery(fetchLockCodes().type, getLockCodes)
}
function* watchCreateCode() {
  yield takeEvery(CREATE_CODE, createCode)
}

function* watchEditCode() {
  yield takeEvery(EDIT_CODE, editCode)
}

function* watchExtendCode() {
  yield takeEvery(EXTEND_CODE, extendCode)
}

function* watchDeleteCode() {
  yield takeEvery(DELETE_CODE, deleteCode)
}

function* watchResyncCodes() {
  yield takeLatest(RESYNC_CODES, resyncCodes)
}

function* watchFetchLockCodePermissions() {
  yield takeLatest(fetchPermissions().type, getLockCodePermissions)
}

export default [
  watchGetLockCodes(),
  watchCreateCode(),
  watchEditCode(),
  watchExtendCode(),
  watchDeleteCode(),
  watchFetchLockCodePermissions(),
  watchResyncCodes()
]
