// @flow
import React from 'react'
import { SearchResultsWrapper, Padder } from './styles'
import CarouselUnit from './CarouselUnit'
import ResultsContentWithScroll, { ResultsContent } from './ResultsContent'
import type { SearchResults as Props } from './types'

const getNumberOfItems = results => {
  return results.length > 10 ? 10 : results.length
}

const SearchResult = ({
  results,
  isFetching,
  propertyId,
  hasSearchData,
  addToSearchHistory
}: Props) => (
  <SearchResultsWrapper>
    {isFetching ? (
      <CarouselUnit>Loading...</CarouselUnit>
    ) : hasSearchData && results.length > 4 ? (
      <Padder>
        <ResultsContentWithScroll
          numberOfItemsPerSlide={getNumberOfItems(results)}
          lastSlideNumber={Math.ceil(
            results.length / getNumberOfItems(results)
          )}
          transitionHeights={['0em', '2em']}
          arrowsRight
          items={results.map((result, index) => (
            <CarouselUnit
              key={index}
              result={result}
              lastUnit={index === results.length - 1}
              propertyId={propertyId}
              addToSearchHistory={addToSearchHistory}
            />
          ))}
        />
      </Padder>
    ) : (
      hasSearchData &&
      results.length > 0 &&
      results.length <= 4 && (
        <ResultsContent
          items={results.map((result, index) => (
            <CarouselUnit
              key={index}
              result={result}
              lastUnit={index === results.length - 1}
              propertyId={propertyId}
              addToSearchHistory={addToSearchHistory}
            />
          ))}
        />
      )
    )}
    {!isFetching && hasSearchData && results.length === 0 && (
      <CarouselUnit>No results found</CarouselUnit>
    )}
  </SearchResultsWrapper>
)

export default SearchResult
