// @flow
import React, { Component, Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import { FabButton } from '@Common/Button'
import { CommonIcon } from '@icons'
import CloseIcon from '@material-ui/icons/Close'
import type { Theme } from '../../../../theme'
import { ImageBlock, ImageWrapper, Button } from './styles'
import 'cropperjs/dist/cropper.css'
import Cropper from 'react-cropper'
import { dataUrlToFile } from '../../../../utils/fileUtils'

type Props = {
  image?: Object | null,
  theme: Theme,
  croppedImage?: Object | null,
  deleteImage: () => void,
  uploadImage: (event: SyntheticInputEvent<HTMLInputElement>) => Promise<void>,
  uploadCroppedImage: (file: File) => void,
  showCropper: boolean
}

class ImageSection extends Component<Props, {}> {
  cropper: any

  crop = () => {
    const { uploadCroppedImage, image } = this.props
    const fileType = image == null ? 'image/jpeg' : image.type
    const dataUrl = this.cropper.getCroppedCanvas().toDataURL(fileType)

    if (image) {
      uploadCroppedImage(dataUrlToFile(dataUrl, image.name, fileType))
    }
  }

  openStockPhotosDrive = () => {
    const driveUrl =
      'https://drive.google.com/drive/folders/1jbgriCWJ19Ctt1SST35F6p2huQVMeGLl'
    window.open(driveUrl, 'googleDrive', 'noopener')
  }

  render() {
    const {
      image,
      croppedImage,
      deleteImage,
      uploadImage,
      theme,
      showCropper
    } = this.props

    return (
      <ImageBlock
        columnStart={1}
        columnSpan={5}
        rowStart={1}
        rowSpan={9}
        image={image}>
        {image ? (
          <Fragment>
            <FabButton
              onClick={deleteImage}
              component={() => (
                <CloseIcon
                height="0.5em"
                width="0.5em"
                style={{ color: theme.palette.primary.dark }}
              />
              )}
              size="2.7em"
              position="absolute"
              right="-1em"
              top="-0.5em"
              zIndex="100"
              background={theme.palette.secondary.yellow.solarFlare}
            />
            {!croppedImage && showCropper && (
              <FabButton
                onClick={this.crop}
                component={() => (
                  <CommonIcon
                    name="tick"
                    width="0.7em"
                    height="0.7em"
                    fill={theme.palette.common.white}
                    strokeWidth={2}
                    stroke={theme.palette.common.white}
                  />
                )}
                size="2.7em"
                position="absolute"
                right="-1em"
                top="3em"
                zIndex="100"
                background={theme.palette.primary.green.mint}
              />
            )}
            <ImageWrapper>
              {!croppedImage && showCropper && (
                <Cropper
                  style={{ height: '100%', width: '100%' }}
                  aspectRatio={1}
                  guides={true}
                  src={image.url}
                  ref={cropper => {
                    this.cropper = cropper
                  }}
                />
              )}

              <img
                src={croppedImage ? croppedImage.url : image.url}
                alt={croppedImage ? croppedImage.url : image.url}
              />
            </ImageWrapper>
          </Fragment>
        ) : (
          <Fragment>
            <Typography component="h2">Upload Image</Typography>
            <Typography component="h4">Drag and Drop Here</Typography>
            <br />
            <Typography component="h5">or</Typography>
            <br /> <br />
            <Button htmlFor="post-image-input">BROWSE FILES</Button>
            <input
              type="file"
              id="post-image-input"
              style={{ display: 'none' }}
              onChange={uploadImage}
            />
            <br />
            <Button onClick={this.openStockPhotosDrive}>STOCK PHOTOS</Button>
          </Fragment>
        )}
      </ImageBlock>
    )
  }
}

export default ImageSection
