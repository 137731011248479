import styled from '@emotion/styled'

export const CenteredText = styled('div')`
  text-align: center;
  font-size: ${({ theme }) => theme.custom.font.size.xs};
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-top: 0.5em;
`

export const VacantWrapper = styled('div')`
  margin-left: 1em;
`

export const OccupancyWrapper = styled('div')`
  display: grid;
  flex: 1;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
`
