import compose from 'lodash/flowRight'
import { SECTION } from '../actions'
import withFetch from '../../helpers/reducers/withFetch'
import withRefresh from '../../helpers/reducers/withRefresh'
import { GET_PROPERTY_PMS_STATUS_SUCCESS, GET_PROPERTY_TIMEZONE_SUCCESS } from '../../properties/actions'

const summary = (state = null, action) => {
  switch (action.type) {
    case GET_PROPERTY_PMS_STATUS_SUCCESS:
      return {
        ...state,
        hasPms: action.has_pms,
        pmsName: action.pms
      }
    case GET_PROPERTY_TIMEZONE_SUCCESS:
      return {
        ...state,
        timezone: action.timezone
      }
    default:
      return state
  }
}

const withFetchAndRefresh = compose(
  withFetch(SECTION),
  withRefresh(SECTION)
)

export default withFetchAndRefresh(summary)
