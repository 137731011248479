import styled from '@emotion/styled'

export const CircleText = styled('div')`
  color: ${props =>
    props.textColor
      ? props.textColor
      : ({ theme }) => theme.palette.text.secondary};
  position: absolute;
  margin: 0 auto;
  align-self: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: ${({ size }) => (size ? `${size}px` : '84px')};
  width: ${({ size }) => (size ? `${size}px` : '84px')};
  justify-content: center;

  & > span:first-child {
    font-size: ${({ theme }) => theme.custom.font.size.xl36};
    font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  }

  & > p:last-child {
    color: ${({ theme }) => theme.palette.neutrals.deepGray};
    font-size: ${({ theme }) => theme.custom.font.size.xxs};
    margin: 0px;
    padding: 0px;
    font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  }

  ${({ onClick }) => onClick && 'cursor: pointer;'};
`

export const CircleBackground = styled('div')`
  height: ${({ size }) => (size ? `${size}px` : '84px')};
  width: ${({ size }) => (size ? `${size}px` : '84px')};
  border-radius: 100%;
  background-color: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : ({ theme }) => theme.palette.neutrals.coolGray};
`
