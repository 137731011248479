// @flow
import React from 'react'
import { SendDetailsWrapper } from './styles'
import { dashToSpace } from '../../../../utils'

type Props = {
  emailRecipient: string,
  textRecipient: string,
  emailSentTime: string,
  textSentTime: string,
  inAppRecipient: string,
  inAppSentTime: string,
  method: string
}

type MessageSendItemProps = {
  prefix: string,
  sentTime: string,
  recipient: string
}

const MessageSendItem = ({
  prefix,
  sentTime,
  recipient
}: MessageSendItemProps) => {
  const prefixInLowerCase = prefix.toLowerCase()
  return recipient && sentTime ? (
    <React.Fragment>
      <div>
        <span>{dashToSpace(prefix)} Sent Time:</span>
        <span>{sentTime}</span>
      </div>
      <div>
        {prefixInLowerCase !== 'in-app' && (
          <React.Fragment>
            <span>{prefix} Sent To:</span>
            <span>{recipient}</span>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  ) : (
    <div>This message failed to send via {dashToSpace(prefixInLowerCase)}.</div>
  )
}

const formatInApp = (method: string): string => {
  return method === 'in-app' ? 'inApp' : method
}

const MessageSendDetails = (props: Props) => {
  const { method: methods = '' } = props
  return (
    <SendDetailsWrapper>
      {methods &&
        methods.split(',').map((method: string) => {
          return (
            <MessageSendItem
              prefix={method}
              sentTime={props[`${formatInApp(method)}SentTime`]}
              recipient={props[`${formatInApp(method)}Recipient`]}
              key={method}
            />
          )
        })}
    </SendDetailsWrapper>
  )
}

export default MessageSendDetails
