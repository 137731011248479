import { capitalizeFirstLetter } from './string'

export const genericTimedOutMessage = 'Communication with server timed out'

export const failedToLogin = error => `Failed to login - ${error.message}`

export const failedToLoginGoogle = 'Failed to login with Google'

export const failedToLogout = 'Failed to logout'

export const failedToConnect = provider => {
  return `Failed to connect to ${capitalizeFirstLetter(provider)}`
}

export const sendEmailConfirmation = 'Activation email sent!'

export const sendEmailError = 'Unable to send activation email'

export const genericDeleteSuccess = 'Successfully deleted'

export const genericErrorMsg = 'Something went wrong!'

export const searchUnitsError = 'Unable to retrieve units'

export const hubFirmwareUpgradeError = 'Hub command upgrade failed'

export const hubCommandError = commandType =>
  `Hub command ${commandType} failed`

export const thermostatCommandError = `Thermostat command failed`

export const lockCommandError = `Lock command failed`

export const hubAddError = 'Adding hub failed'

export const failedToFetchProperties = 'Unable to retrieve properties'

export const failedToSelectProperty = 'Failed to select property'

export const failedToFetchSettings = 'Unable to fetch Settings'

export const failedToRunTest = 'Failed to run test'

export const failedToFetchDevices = 'Faled to retrieve devices'

export const failedToFetchSummary = 'Failed to fetch summary'

export const failedToFetchPropertyBatteryLevels =
  'Failed to fetch property battery summary'

export const failedToFetchUnitBatteryLevels =
  'Failed to fetch unit battery summary'

export const failedToFetchChatChannels = 'Failed to fetch chat channels'

export const failedToFetchChatChannelMessages =
  'Failed to fetch chat channel messages'

export const faileToSendChatMessage = 'Failed to send chat message'

export const failedToGetPermissions = 'Failed to get lock code permissions'

export const noMoreCodeIndex = category =>
  `${capitalizeFirstLetter(category)} codes are full`

export const failedToCreateCode = error => `Failed to create code. ${error}`

export const failedToCreateUserLockCode = error => `Failed to create code: ${error}`

export const failedToEditCode = error => `Failed to edit code. ${error}`

export const failedToDeleteCode = error => `Failed to delete code. ${error}`

export const noUnitSelected = 'No unit has been selected'

export const noPropertySelected = 'No property has been selected'

export const noSocketChannelFound = 'No channel found'

export const failedToRenewToken = 'Failed to reauthenticate'

export const failedToResetPass = 'Failed to reset password'

export const failedToVerifySignupToken = 'Your signup link is no longer valid'

export const failedToSearch = 'Failed to return search results'

export const failedToFetchSearchHistory = 'Failed to return search history'

export const failedToAddSearchHistory = 'Failed to add to search history'

export const failedToEditDeviceName = 'Failed to edit device name'

export const switchCommandError = 'Switch command failed'

export const failedToAddUser = 'Failed to add a user'

export const failedToAddUnit = 'Failed to add unit'

export const failedToFetchSchedule = 'Failed to fetch schedule'
export const failedToSwitchScheduleEnabled = newState =>
  `Failed to ${newState} schedule`
export const failedToUpdateSchedule = 'Failed to update schedule'

export const messageHubAddError = 'Failed to add hub'

export const deleteUserConfirmation = 'User deleted successfully'

export const deleteCommunityThreadConfirmation =
  'Community thread deleted successfully'

export const communityThreadNonExistent = 'Community thread does not exist'

export const commentDeletedConfirmation = 'Comment deleted successfully'

export const failedToFetchMessage = 'Failed to get message'

export const failedToFetchMessageUserSummary =
  'Failed to retrieve user information of message'

export const failedToFetchGroupMessageTemplate = 'Failed to retrieve template'

export const failedToUpdateGroupMessageTemplate = 'Failed to update template'

export const failedToCreateGroupMessageTemplate = error => {
  return error.data.details.template_name_per_resource
    ? `Template name already exists. Please rename the template`
    : `Failed to create template. All fields are required.`
}

export const createGroupMessageTemplateSuccess =
  'Template was created successfully'

export const failedToFetchReplacementOptions = 'Failed to retrieve merge fields'

export const failedToDeleteGroupMessageTemplate = 'Failed to delete template'

export const deleteGroupMessageTemplateSuccess = 'Successfully deleted template'

export const failedToFetchGroupMessageTemplatePermission =
  'Failed to retrieve template permission data'

export const deleteGroupMessageSuccess = 'Successfully deleted message'

export const failedToDeleteDevice = 'Failed to delete device'

export const deleteDeviceSuccess = 'Successfully deleted device'

export const pinChannelSuccess = 'Channel has been pinned'

export const unpinChannelSuccess = 'Channel has been unpinned'

export const failedToUpdatePackage = 'Failed to update package'

export const updatePackageReminderSuccessful =
  'Successfully updated package reminder'

export const failedToUpdatePackageReminder = 'Failed to update package reminder'

export const failedToCreateProAmenity = 'Could not create new Amenity; try again or contact support'

export const failedToUpdateAmenity = 'Failed to update amenity'

export const createAmenitySuccessPrefix = 'New Amenity has been saved'

export const updateAmenitySuccessPrefix = 'Amenity has been updated'

export const saveAmenityDraftSuffix = ', but is not visible to residents yet'

export const saveAmenityPublishedSuffix = ', and is visible to residents'

export const failedToUpdatePet = 'Failed to update pet'

export const failedToUpdateVehicle = 'Failed to update vehicle'

export const failedToResendVendorCode = 'Failed to resend vendor code'

export const failedToCreateVendorCode = 'Failed to create vendor code'

export const failedToUpdateVendorCode = 'Failed to update vendor code'

export const failedToInvalidateVendorCode = 'Failed to delete vendor code'

export const invalidateVendorCodeSuccess = 'Vendor Code deleted Successfully '

export const resendVendorCodeSuccess = 'Vendor Code resend Successful '

export const reSyncSuccess =
  'Re-sync started and will be completed in about 15mins'

export const reSyncInProgress = 'Re-sync in progress. Try again later'

export const failedToSubmitWorkOrder =
  'An error occurred while creating work order'

export const submitWorkOrderSuccess = 'Your work order has been created'

export const failedToLoadWorkOrders = 'Failed to Load Work Orders. Try again later'

export const failedToUpdateWorkOrder =
  'An error occurred while updating work order'

export const updateWorkOrderSuccess = 'Work order has been updated'

export const fetchMarketplaceDealsFailure = 'Failed to fetch marketplace deals'

export const deleteMarketplaceDealsSuccess = 'Successfully deleted Marketplace deal'

export const deleteMarketplaceDealsFailure = 'Failed to delete Marketplace deal'

export const createMarketplaceDealFailure = 'An error occurred while creating a marketplace deal'

export const createMarketplaceDealSuccess = 'Your marketplace deal has been created'

export const viewMarketplaceDealError = 'Could not load marketplace deal.'

export const updateMarketplaceDealError = 'Unable to update your marketplace deal.'

export const updateMarketplaceDealSuccess = 'Successfully updated your marketplace deal.'

export const reservationCreateSuccess = (residentName) =>
  `Reservation for ${residentName} has been created and message sent.`

export const reservationCreateError = 'Error! Unable to create reservation. Please try again later.'

export const reservationDeleteSuccess = (residentName) =>
  `Reservation for ${residentName} has been deleted and message sent.`

export const reservationDeleteError = 'Error! Unable to delete reservation. Please try again later.'

export const amenitiesFetchError = 'Error! Could not fetch amenities. Please try again later.'

export const amenitiyFetchError = 'Error! Could not fetch amenity info. Please try agin later.'

export const blockDaySuccess = 'Successfully blocked the day and cancelled reservations.'

export const blockDayError = 'Error! Unable to block this day. Please try again later.'

export const blockSlotSuccess = 'Successfully blocked this slot and cancelled reservations.'

export const blockSlotError = 'Error! Unable to block this time slot. Please try again later.'

export const deleteBlockDaySuccess = 'Success! This day has been unblocked.'

export const deleteBlockDayError = 'Error! Unable to unblock this day. Please try again later.'

export const deleteBlockSlotSuccess = 'Success! This time slot has been unblocked.'

export const deleteBlockSlotError = 'Error! Unable to unblock this time slot. Please try again later.'

export const fetchPropertyHubsErrorMessage = 'Error! Unable to fetch Property Hubs. Please try again later.'
