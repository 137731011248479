import { combineReducers } from 'redux'
import propertySettings from './propertySettings/reducers'
import userSettings from './userSettings/reducers'

const settings = combineReducers({
  propertySettings,
  userSettings
})

export default settings
