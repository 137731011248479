import styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'

export const UploadedFile = styled('p')`
  color: ${({ theme }) => theme.palette.neutrals.deepGray};
  word-break: break-all;
  max-width: 80%;
  font-size: 16px;
  line-height: 19.2px;
`

export const DropBox = styled('div')`
  height: 135px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23979797FF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='27' stroke-linecap='butt'/%3e%3c/svg%3e");
`

export const ErrorDropBox = styled('div')`
  height: 135px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23FF6E6EFF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='27' stroke-linecap='butt'/%3e%3c/svg%3e");
`

export const DeleteFileButton = styled(IconButton)`
  border-radius: 15px;
  margin: 0;
  min-width: 0px;
  width: 15px;
  height: 15px;
`

export const RedCancelOutlinedIcon = styled(CancelOutlinedIcon)`
  font-size: 15px;
  color: ${({ theme }) => theme.palette.alert.error};
`

export const FileName = styled('span')`
  font-style: italic;
`