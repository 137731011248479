// import React, { Component } from 'react'
import { connect } from 'react-redux'
import HubCommands from './HubCommands'
import { createIsUpdatingSelector } from 'zego-shared/store/isUpdating/selectors'
import {
  pushHubCommand,
  updateFirmware
} from 'zego-shared/store/devices/actions'
import { commands } from 'zego-shared/store/devices/sagas/utils/deviceCommands'

const commandBeingSent = state => ({
  [commands.INCLUSION]: createIsUpdatingSelector(['hubinclusion'])(state),
  [commands.EXCLUSION]: createIsUpdatingSelector(['hubexclusion'])(state),
  [commands.UPDATE]: createIsUpdatingSelector(['hubupdate'])(state),
  [commands.RESTART]: createIsUpdatingSelector(['hubrestart'])(state)
})

const isAnyCommandBeingSent = createIsUpdatingSelector([
  'hubinclusion',
  'hubexclusion',
  'hubupdate',
  'hubrestart'
])

const mapStateToProps = state => ({
  isAnyCommandLoading: isAnyCommandBeingSent(state),
  commandsBeingSent: commandBeingSent(state)
})

const mapDispatchToProps = {
  pushHubCommand,
  updateFirmware
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HubCommands)
