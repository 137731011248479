import styled from '@emotion/styled'

export const MergeFieldsWrapper = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
  cursor: pointer;
`

export const MergeFieldsUL = styled('ul')`
  overflow-y: scroll;
  position: absolute;
  top: 35px;
  left: 5px;
  margin: 0;
  padding: 0;
  background-color: white;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  width: 150px;
  max-height: 250px;
  z-index: 100;
`

export const MergeFieldsLI = styled('li')`
  padding: 5px 5px;
  &:hover {
    background: #f5f5f5;
  }
`
