// @flow
import React from 'react'
import debounce from 'lodash/debounce'
import { Name, Caret, IncreaseCount, DecreaseCount } from './styles'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import { NumberInputProps as Props, NumberInputState as State } from './types'

class NumberInput extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      newExpiry: null
    }
    // $FlowDisableNextLine
    this.containerRef = React.createRef()
  }

  componentDidMount() {
    this.setState({ newExpiry: this.props.expiry })
    document.addEventListener('click', this.handleOutsideClick, false)
  }

  save = debounce(() => {
    const { saveExpiry, id } = this.props
    const { newExpiry } = this.state
    saveExpiry(id, newExpiry)
  }, 400)

  increase = () => {
    this.setState({ newExpiry: this.state.newExpiry + 1 })
    this.save()
  }

  decrease = () => {
    if (this.state.newExpiry > 1) {
      this.setState({ newExpiry: this.state.newExpiry - 1 })
      this.save()
    }
  }

  handleOutsideClick = ({ target }: Object) => {
    if (
      // $FlowDisableNextLine
      this.containerRef &&
      this.containerRef.current &&
      // $FlowDisableNextLine
      !this.containerRef.current.contains(target)
    ) {
      this.props.closeEditMode()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false)
  }

  render() {
    const { newExpiry } = this.state
    return (
      <React.Fragment>
        &nbsp;&nbsp;&nbsp;
        {newExpiry ? (
          <Name>
            {' '}
            -{' '}
            <span>
              <strong>{newExpiry}</strong> day
              {newExpiry > 1 ? 's' : <span>&nbsp;&nbsp;</span>}
            </span>
          </Name>
        ) : (
          ''
        )}
        &nbsp;&nbsp;&nbsp;
        {/* $FlowDisableNextLine */}
        <span ref={this.containerRef}>
          <FlexDirection>
            <FlexItem>
              <IncreaseCount onClick={this.increase}>
                <Caret>›</Caret>
              </IncreaseCount>
            </FlexItem>
            <FlexItem>
              <DecreaseCount onClick={this.decrease}>
                <Caret>›</Caret>
              </DecreaseCount>
            </FlexItem>
          </FlexDirection>
        </span>
      </React.Fragment>
    )
  }
}

export default NumberInput
