import { call, select, put, takeLatest } from 'redux-saga/effects'
import { getToken } from '../../authorization/selectors'
import {
  fetchUnitBatteryLevelsAction,
  fetchUnitBatteryLevelsStart,
  fetchUnitBatteryLevelsSuccess,
  fetchUnitBatteryLevelsError,
  fetchAction as fetchPropertyBatteryLevelsAction,
  fetchPropertyBatteryLevelsStart,
  fetchPropertyBatteryLevelsSuccess,
  fetchPropertyBatteryLevelsError
} from '../actions'
import api from '../../../utils/api'
import log from '../../../utils/logger'

export function* fetchPropertyBatteryLevels(isRefreshing = null, { id }) {
  try {
    const authToken = yield select(getToken)
    yield put(fetchPropertyBatteryLevelsStart())
    const response = yield call(api.getPropertyBatteryLevels, authToken, id)
    yield put(fetchPropertyBatteryLevelsSuccess(response))
    return response
  } catch (error) {
    yield put(fetchPropertyBatteryLevelsError(error))
    log(`Failed to fetch units batteries. Error: ${error}`)
  }
}

function* watchFetchPropertyBatteryLevels() {
  yield takeLatest(
    fetchPropertyBatteryLevelsAction().type,
    fetchPropertyBatteryLevels,
    false
  )
}
export function* fetchUnitBatteryLevels(isRefreshing = null, { id }) {
  try {
    const authToken = yield select(getToken)
    yield put(fetchUnitBatteryLevelsStart())
    const response = yield call(api.getUnitBatteryLevels, authToken, id)
    yield put(fetchUnitBatteryLevelsSuccess(response))
    return response
  } catch (error) {
    yield put(fetchUnitBatteryLevelsError(error))
    log(`Failed to fetch units batteries. Error: ${error}`)
  }
}

function* watchFetchUnitBatteryLevels() {
  yield takeLatest(
    fetchUnitBatteryLevelsAction().type,
    fetchUnitBatteryLevels,
    false
  )
}

export default [
  watchFetchPropertyBatteryLevels(),
  watchFetchUnitBatteryLevels()
]
