// @flow
import React from 'react'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import InputAdornment from '@material-ui/core/InputAdornment'
import { SearchIcon } from '@icons'
import SearchResultWrapper from './SearchResultWrapper'
import { TextField, SearchWrapper } from './styles'
import type { SearchProps as Props, SearchState as State } from './types'

export class Search extends React.Component<Props, State> {
  constructor(props: Object) {
    super(props)
    this.state = {
      searchText: props.searchText || '',
      showSearchResults: false,
      inputFocused: false
    }
    this.wrapperRef = React.createRef()
  }

  componentDidUpdate(prevProps: Object) {
    const { searchText } = this.props
    if (prevProps.searchText !== searchText) {
      this.setState({ searchText })
    }
  }
  // $FlowDisableNextLine
  wrapperRef: { current: ?HTMLDivElement }

  submitSearch = debounce(() => {
    const { searchData } = this.props
    const { searchText } = this.state
    searchData(searchText)
  }, 400)

  handleKeyPress = ({ key }: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      this.submitSearch()
    }
  }

  handleSearchChange = ({
    currentTarget: { value }
  }: SyntheticInputEvent<HTMLInputElement>) => {
    const { minSearchLength = 2 } = this.props
    this.setState({ searchText: value })
    if (value.length > minSearchLength) this.submitSearch()
  }

  handleOutsideClick = ({ target }: Object) => {
    const wrapperRefExists = this.wrapperRef && this.wrapperRef.current
    // $FlowDisableNextLine
    if (wrapperRefExists && !this.wrapperRef.current.contains(target)) {
      this.setState({ showSearchResults: false, inputFocused: false })
    }
  }

  focusInput = () => {
    this.setState({ inputFocused: true })
  }

  componentWillReceiveProps({ data }: { data: Object[] }) {
    if (data && this.state.inputFocused && !this.state.showSearchResults) {
      this.setState({ showSearchResults: true })
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick)
  }

  addToSearchHistory = (result: any) => {
    const { onResultClick } = this.props
    onResultClick(result)

    this.setState({ showSearchResults: false, inputFocused: false })
  }

  render() {
    const {
      data,
      match: { params },
      hasSearchData,
      textFieldClassName,
      isGroupMsg,
      elementId,
      isFetching
    } = this.props
    const { searchText, showSearchResults } = this.state
    return (
      <SearchWrapper>
        <div ref={this.wrapperRef}>
          <TextField
            id={elementId}
            value={searchText}
            isGroupMsg={isGroupMsg}
            className={textFieldClassName}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    name="search"
                    height="18px"
                    width="16px"
                    onClick={this.submitSearch}
                    className="searchSubmitIcon"
                    data-testid="searchSubmitButton"
                  />
                </InputAdornment>
              )
            }}
            onFocus={this.focusInput}
            onChange={this.handleSearchChange}
            onKeyPress={this.handleKeyPress}
          />
          {showSearchResults && !isEmpty(searchText) && (
            <SearchResultWrapper
              addToSearchHistory={this.addToSearchHistory}
              results={data}
              propertyId={params.propertyId || ''}
              hasSearchData={hasSearchData}
              isFetching={isFetching}
            />
          )}
        </div>
      </SearchWrapper>
    )
  }
}

export default withRouter(Search)
