// @flow

import React, { Fragment } from 'react'
import ToggleItemsCard from './ToggleItemsCard'
import Tooltip from '../Common/Tooltip'

export const settingsTitles = {
  batteryDailyText: (
    <Fragment>
      Battery Daily Text Updates <Tooltip type="NotificationBatteryText" />
    </Fragment>
  ),
  batteryDailyEmail: (
    <Fragment>
      Battery Daily Email Updates
      <Tooltip type="NotificationBatteryEmail" />
    </Fragment>
  )
}

type Props = {
  settings: {},
  updateSetting: ({}) => mixed,
  match: { params: Object }
}

export default class PropertyNotifications extends React.Component<Props> {
  render() {
    const {
      settings,
      updateSetting,
      match: { params }
    } = this.props
    return (
      <ToggleItemsCard
        handleToggle={updateSetting}
        settings={settings}
        propertyId={params.propertyId}
        settingsTitles={settingsTitles}
        title="Property Notifications"
      />
    )
  }
}
