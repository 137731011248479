import { createFetchActions, createFetchNextActions } from '../helpers/actions/creators'

export const RESIDENT_MOVEINMOVEOUT = 'RESIDENT_MOVEINMOVEOUT'
export const RESIDENT_MOVEINMOVEOUT_FILTERED_COUNT = 'RESIDENT_MOVEINMOVEOUT_FILTERED_COUNT'
export const RESIDENT_MOVEINMOVEOUT_TOTAL_COUNT = 'RESIDENT_MOVEINMOVEOUT_TOTAL_COUNT'
export const MIMO_CHECKLIST_SUMMARY = 'MIMO_CHECKLIST_SUMMARY'
export const MIMO_CHECKLIST_SETTINGS = 'MIMO_CHECKLIST_SETTINGS'
export const MIMO_SAVED_CHECKLIST_SETTINGS = 'MIMO_SAVED_CHECKLIST_SETTINGS'
export const MIMO_CHECKLIST_PDF = 'MIMO_CHECKLIST_PDF'
export const MIMO_RESET_CHECKLIST_OUT = 'MIMO_RESET_CHECKLIST_OUT'
export const MIMO_CHECKLIST_OPTED_OUT = 'MIMO_CHECKLIST_OPTED_OUT'

export const {
  fetchAction: fetchResidentManagementMoveInMoveOutAction,
  fetchStartAction: fetchResidentManagementMoveInMoveOutStart,
  fetchSuccessAction: fetchResidentManagementMoveInMoveOutSuccess,
  fetchErrorAction: fetchResidentManagementMoveInMoveOutError,
  fetchNextAction: fetchNextResidentManagementMoveInMoveOutAction,
  fetchNextStartAction: fetchNextResidentManagementMoveInMoveOutStart,
  fetchNextSuccessAction: fetchNextResidentManagementMoveInMoveOutSuccess,
  fetchNextErrorAction: fetchNextResidentManagementMoveInMoveOutError
} = createFetchNextActions(RESIDENT_MOVEINMOVEOUT)

export const {
  fetchAction: fetchResidentTotalCountAction,
  fetchStartAction: fetchResidentTotalCountStart,
  fetchSuccessAction: fetchResidentTotalCountSuccess,
  fetchErrorAction: fetchResidentTotalCountError
} = createFetchActions(RESIDENT_MOVEINMOVEOUT_TOTAL_COUNT)

export const {
  fetchAction: fetchResidentFilteredCountAction,
  fetchStartAction: fetchResidentFilteredCountStart,
  fetchSuccessAction: fetchResidentFilteredCountSuccess,
  fetchErrorAction: fetchResidentFilteredCountError
} = createFetchActions(RESIDENT_MOVEINMOVEOUT_FILTERED_COUNT)

export const {
  fetchAction: fetchMimoChecklistSummaryAction,
  fetchStartAction: fetchMimoChecklistSummaryStart,
  fetchSuccessAction: fetchMimoChecklistSummarySuccess,
  fetchErrorAction: fetchMimoChecklistSummaryError
} = createFetchActions(MIMO_CHECKLIST_SUMMARY)

export const {
  fetchAction: fetchMimoChecklistSettingsAction,
  fetchStartAction: fetchMimoChecklistSettingsStart,
  fetchSuccessAction: fetchMimoChecklistSettingsSuccess,
  fetchErrorAction: fetchMimoChecklistSettingsError
} = createFetchActions(MIMO_CHECKLIST_SETTINGS)

export const {
  fetchAction: fetchSavedMimoChecklistSettingsAction,
  fetchStartAction: fetchSavedMimoChecklistSettingsStart,
  fetchSuccessAction: fetchSavedMimoChecklistSettingsSuccess,
  fetchErrorAction: fetchSavedMimoChecklistSettingsError
} = createFetchActions(MIMO_SAVED_CHECKLIST_SETTINGS)

export const {
  fetchAction: fetchMimoChecklistPdfAction,
  fetchStartAction: fetchMimoChecklistPdfStart,
  fetchSuccessAction: fetchMimoChecklistPdfSuccess,
  fetchErrorAction: fetchMimoChecklistPdfError
} = createFetchActions(MIMO_CHECKLIST_PDF)

export const {
  fetchAction: fetchMimoResetCheckListAction,
  fetchStartAction: fetchMimoResetCheckListStart,
  fetchSuccessAction: fetchMimoResetCheckListSuccess,
  fetchErrorAction: fetchMimoResetCheckListError
} = createFetchActions(MIMO_RESET_CHECKLIST_OUT)

export const {
  fetchAction: fetchMimoChecklistOptedOutAction,
  fetchStartAction: fetchMimoChecklistOptedOutStart,
  fetchSuccessAction: fetchMimoChecklistOptedOutSuccess,
  fetchErrorAction: fetchMimoChecklistOptedOutError
} = createFetchActions(MIMO_CHECKLIST_OPTED_OUT)

