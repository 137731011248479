import { createFetchActions } from '../../../helpers/actions/creators'

export const CHAT_CHANNEL_MESSAGES = 'CHATCHANNELMESSAGES'

export const CHAT_CHANNEL_MESSAGES_BY_URL = 'CHATCHANNELMESSAGESBYURL'

const SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE'
const SEND_CHAT_MESSAGE_ERROR = 'SEND_CHAT_MESSAGE_ERROR'

const SENDBIRD_ON_MESSAGE_RECEIVED = 'SENDBIRD_ON_MESSAGE_RECEIVED'

const SENDBIRD_ON_CHANNEL_CHANGED = 'SENDBIRD_ON_CHANNEL_CHANGED'

const SENDBIRD_ADD_MESSAGE = 'SENDBIRD_ADD_MESSAGE'

const INIT_SENDBIRD_CHANNEL_HANDLER = 'INIT_SENDBIRD_CHANNEL_HANDLER'
const CLOSE_SENDBIRD_CHANNEL_HANDLER = 'CLOSE_SENDBIRD_CHANNEL_HANDLER'

const FETCH_UNREAD_MESSAGES_COUNT = 'FETCHUNREADMESSAGESCOUNT'

export const {
  fetchAction: fetchChatChannelMessagesAction,
  fetchStartAction: fetchChatChannelMessagesStart,
  fetchSuccessAction: fetchChatChannelMessagesSuccess,
  fetchErrorAction: fetchChatChannelMessagesError
} = createFetchActions(CHAT_CHANNEL_MESSAGES)

export const fetchChatChannelMessages = channelType =>
  fetchChatChannelMessagesAction({ channelType })

export const {
  fetchAction: fetchChatChannelMessagesByUrlAction
} = createFetchActions(CHAT_CHANNEL_MESSAGES_BY_URL)

export const fetchChatChannelMessagesByUrl = channelUrl =>
  fetchChatChannelMessagesByUrlAction({ channelUrl })

export const sendChatMessage = (message, types, file) => ({
  type: SEND_CHAT_MESSAGE,
  message,
  types,
  file
})

export const sendChatMessageError = error => ({
  type: SEND_CHAT_MESSAGE_ERROR,
  error
})

export const onMessageReceived = (channel, message) => ({
  type: SENDBIRD_ON_MESSAGE_RECEIVED,
  channel,
  message
})

export const onChannelChanged = channel => ({
  type: SENDBIRD_ON_CHANNEL_CHANGED,
  channel
})

export const addMessage = message => ({
  type: SENDBIRD_ADD_MESSAGE,
  message
})

export const initSendbirdChannelHandler = () => ({
  type: INIT_SENDBIRD_CHANNEL_HANDLER
})

export const closeSendbirdChannelHandler = () => ({
  type: CLOSE_SENDBIRD_CHANNEL_HANDLER
})


export const {
  fetchAction: fetchUnreadMessagesCountAction,
  fetchStartAction: fetchUnreadMessagesCountStart,
  fetchSuccessAction: fetchUnreadMessagesCountSuccess,
  fetchErrorAction: fetchUnreadMessagesCountError
} = createFetchActions(FETCH_UNREAD_MESSAGES_COUNT)
