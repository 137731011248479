import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Tooltip } from '@material-ui/core'
import {
  DealCard,
  DealContent,
  DealImage,
  CloseButton,
  LinkHeader,
  DragContainer,
  DropContainer
} from './styles'

export default ({ deal, onClick, onDelete, moveCard, commitMove, index }) => {
  const ref = useRef()

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', index, deal },
    collect(monitor) {
      return {
        isDragging: monitor.isDragging()
      }
    },
    end() {
      commitMove(deal.id, index)
    }
  })

  const [, drop] = useDrop({
    accept: 'card',
    hover(item) {
      if (!deal.global && item.index !== index) {
        moveCard(item.index, index)
        item.index = index
      }
    },
    canDrop(item) {
      return !deal.global && item.index !== index
    }
  })

  drag(drop(ref))

  return (
    <>
      {isDragging ?
        <DropContainer />
      : 
        <DragContainer ref={deal.global ? null : ref}>
          <DealCard>
            <DealImage imageSrc={deal.data.image}>
              {deal.data.discount_description && (<h3>{deal.data.discount_description}</h3>)}
            </DealImage>
            <DealContent>
              {!deal.global && (
                <Tooltip title="Delete" placement="bottom-start">
                  <CloseButton
                    onClick={() => onDelete(deal.id)}
                    width="20px"
                    height="20px"
                    name="close"
                  />
                </Tooltip>
              )}
              {deal.data.title && deal.global ?
                <h3>{deal.data.title}</h3> :
                <LinkHeader onClick={() => onClick(deal.id)}>{deal.data.title}</LinkHeader>}
              {(deal.data.short_title || deal.data.category) && (<h4>{deal.data.short_title || deal.data.category}</h4>)}
              {deal.data.description && (<p>{deal.data.description}</p>)}
              {deal.data.redirect_url && (<p className="link"><a href={deal.data.redirect_url}>{deal.data.redirect_url}</a></p>)}
            </DealContent>
          </DealCard>
        </DragContainer>
      }
    </>
  )
}
