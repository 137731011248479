import {
  fetchResidentManagementMoveInMoveOutStart,
  fetchResidentManagementMoveInMoveOutSuccess,
  fetchNextResidentManagementMoveInMoveOutSuccess,
  fetchMimoChecklistSummarySuccess,
  fetchMimoChecklistSummaryStart,
  fetchNextResidentManagementMoveInMoveOutStart
} from '../actions'

const initialState = {
  residents: [],
  checklistInfo: {},
  checklistSummary: [],
  isLoadingResidents: false,
  isLoadingSummary: false
}

const moveinResidents = (state = initialState, { type, payload }) => {

  switch (type) {
    case fetchResidentManagementMoveInMoveOutStart().type:
      return {
        ...state,
        residents: [],
        isLoadingResidents: true
      }
    case fetchResidentManagementMoveInMoveOutSuccess().type:
      return {
        ...state,
        residents: payload,
        isLoadingResidents: false
      }
    case fetchNextResidentManagementMoveInMoveOutStart().type:
      return {
        ...state,
        isLoadingResidents: true
      }
    case fetchNextResidentManagementMoveInMoveOutSuccess().type:
      return {
        ...state,
        residents: [...state.residents, ...payload],
        isLoadingResidents: false
      }
    case fetchMimoChecklistSummaryStart().type:
      return {
        ...state,
        isLoadingSummary: true,
        checklistSummary: [],
        checklistInfo: {}
      }
    case fetchMimoChecklistSummarySuccess().type:
      payload.residentCheckListTemplateData && payload.residentCheckListTemplateData.forEach(checklistItem => {
        checklistItem.residentRoomCheckListInfo.forEach((checklistAtt) => {
          checklistAtt.actionRequired = true
        })
      });
      return {
        ...state,
        checklistSummary: payload.residentCheckListTemplateData,
        checklistInfo: payload.residentCheckListInfo,
        isLoadingSummary: false
      }
    default:
      return state
  }
}

export default moveinResidents
