// @flow

import get from 'lodash/get'

const engagements = [
  {
    key: 'all_time',
    text: 'at least once',
    color: '#30495E'
  },
  {
    key: 'past_week',
    text: 'in the last 7 days',
    color: '#6DCBB7'
  },
  {
    key: 'past_month',
    text: 'in the last 30 days',
    color: '#08A992'
  }
]

export const getResidentEngagement = (state: Object) => {
  return get(state, ['analytics', 'residentEngagement'])
}

export const getResidentEngagementRate = (data: Object): Object[] => {
  return engagements.map(({ key, text, color }): Object => {
    const { engagement_rate: percentage = 0 } = get(
      data,
      key,
      {}
    )
    return {
      text,
      percentage: Math.round(percentage),
      color
    }
  })
}

export const getResidentEngagementGraphData = (data: Object): Object  => {
  return engagements.reduce((result, { key, text, color }): Object => {
    const { users_engaged: count = 0 } = get(
      data,
      key,
      {}
    )
    return { ...result, [`${text}`]: count, [`${text}Color`]: color }
  }, {})
}

export const getResidentEngagementData = (state: Object): Object => {
  const data = getResidentEngagement(state) || {}
  return {
    rate: getResidentEngagementRate(data),
    graph: {
      keys: engagements.map(({ text }): string => text),
      maxValue: data.total,
      data: [{ ...getResidentEngagementGraphData(data), type: 'count' }]
    }
  }
}

export const hasResidentEngagement = (state: Object) => {
  return get(state, ['analytics', 'residentEngagement']) != null
}
