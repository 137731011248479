import { normalizeArraySectionData } from '../normalizer'
import { FETCH_PREFIX, SUCCESS } from '../actions/constants'

export default section => reducer => (state, action) => {
  switch (action.type) {
    case `${FETCH_PREFIX}${section}${SUCCESS}`: {
      return normalizeArraySectionData(action.payload, section, state)
    }
    default:
      return reducer(state, action)
  }
}
