import { all } from 'redux-saga/effects'
import log from 'zego-shared/utils/logger'
import selectSagas from 'zego-shared/store/select/sagas'
import devicesSagas from 'zego-shared/store/devices/sagas'
import addDevicesSagas from 'zego-shared/store/addDevice/sagas'
import deviceHistorySagas from 'zego-shared/store/deviceHistory/sagas'
import settingSagas from 'zego-shared/store/settings/sagas'
import batteriesSagas from 'zego-shared/store/batteries/sagas'
import hubsSagas from 'zego-shared/store/hubs/sagas'
import summarySagas from 'zego-shared/store/summary/sagas'
import communityThreadSagas from 'zego-shared/store/communityThread/sagas'
import chatActiveChannelSagas from 'zego-shared/store/chat/activeChannel/sagas'
import chatChannelSagas from 'zego-shared/store/chat/channels/sagas'
import chatMessagesSagas from 'zego-shared/store/chat/messages/sagas'
import pinnedChannelsSagas from 'zego-shared/store/chat/pinnedChannels/sagas'
import authorizationSagas from 'zego-shared/store/authorization/sagas'
import userSagas from 'zego-shared/store/users/user/sagas'
import { usersSagas } from 'zego-shared/store/users'
import searchSagas from 'zego-shared/store/search/sagas'
import propertiesSagas from 'zego-shared/store/properties/sagas'
import scheduleSagas from 'zego-shared/store/schedule/sagas'
import packagesSagas from 'zego-shared/store/packages/sagas'
import branchSagas from 'zego-shared/store/branch/sagas'
import {
  residentEngagementSagas,
  residentActiveSagas
} from 'zego-shared/store/analytics/sagas'
import externalServices from './ExternalServices/sagas'
import { communityFeedSagas as communityFeed } from './CommunityFeed'
import {
  templateSagas,
  templatePermissionSagas,
  templatesSagas,
  historySagas,
  futureSagas,
  groupsSagas,
  messageSagas,
  groupEventsSagas,
  replacementOptionsSagas,
  messagesSentTodaySagas,
  groupAutomationSagas
} from 'zego-shared/store/groupMessaging/sagas'
import { amenitiesSagas as amenitiesProSagas } from 'zego-shared/store/amenitiesPro'
import { amenitiesSagas as amenitiesLegacySagas } from 'zego-shared/store/amenitiesLegacy'
import { petsSagas, vehiclesSagas } from 'zego-shared/store/registration/sagas'
import { chatNotificationSagas } from './Chat/Notification'
import { sentrySagas } from './Integrations/Sagas'
import visitorSagas from 'zego-shared/store/registration/visitors/sagas'
import lockCodesSagas from 'zego-shared/store/lockCodes/sagas'
import workOrderSagas from 'zego-shared/store/workOrders/sagas'
import launchDarklySagas from '../integrations/LaunchDarkly/sagas'
import surveySagas from 'zego-shared/store/surveys/sagas'
import moveInMoveOutSagas from 'zego-shared/store/moveInMoveOut/sagas'
import mimoWorkOrderSagas from 'zego-shared/store/MimoWorkorders/sagas'
import marketplaceSagas from 'zego-shared/store/marketplace/sagas'

export function* startSagas() {
  log('starting sagas')
  yield all([
    ...launchDarklySagas,
    ...batteriesSagas,
    ...hubsSagas,
    ...selectSagas,
    ...devicesSagas,
    ...addDevicesSagas,
    ...deviceHistorySagas,
    ...settingSagas,
    ...summarySagas,
    ...authorizationSagas,
    ...usersSagas,
    ...userSagas,
    ...chatChannelSagas,
    ...chatMessagesSagas,
    ...chatActiveChannelSagas,
    ...pinnedChannelsSagas,
    ...searchSagas,
    ...propertiesSagas,
    ...scheduleSagas,
    ...externalServices,
    ...templateSagas,
    ...templatePermissionSagas,
    ...templatesSagas,
    ...communityFeed,
    ...historySagas,
    ...futureSagas,
    ...groupsSagas,
    ...messageSagas,
    ...groupEventsSagas,
    ...replacementOptionsSagas,
    ...communityThreadSagas,
    ...messagesSentTodaySagas,
    ...residentEngagementSagas,
    ...residentActiveSagas,
    ...packagesSagas,
    ...chatNotificationSagas,
    ...amenitiesProSagas,
    ...amenitiesLegacySagas,
    ...petsSagas,
    ...vehiclesSagas,
    ...visitorSagas,
    ...sentrySagas,
    ...branchSagas,
    ...chatNotificationSagas,
    ...visitorSagas,
    ...lockCodesSagas,
    ...workOrderSagas,
    ...surveySagas,
    ...groupAutomationSagas,
    ...moveInMoveOutSagas,
    ...mimoWorkOrderSagas,
    ...marketplaceSagas
  ])
}
