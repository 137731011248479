// @flow
import React from 'react'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import DeliveryMethodRadioGroup from '../CreateGroupMessage/DeliveryMethodRadioGroup'
import { FlexItem } from '@Common/Flex/FlexDirection'
import DropDown from '@Common/DropDown'
import { CommonIcon, RadiobuttonIcon } from '@icons'
import { Content } from './styles'
import { DateTime } from '../CreateGroupMessage/SendSection'
import Event from '../CreateGroupMessage/SendSection/EventUpdate'

import { EditModeTabContentProps } from './types'

const EditModeTabContent = ({
  selectedDeliveryMethods,
  handleDeliveryMethodChange,
  currentTab,
  templates,
  theme,
  chooseTemplate,
  selectedTemplateId,
  selectedSendMethod,
  handleSelectSendMethod,
  dateTimeMoment,
  handleSelectedDateTimeChange,
  groupEventsDropDownOptions,
  offsetDays,
  offsetBeforeAfter,
  handleOffsetBeforeAfter,
  handleOffsetDays,
  handleEmptyOffsetDays,
  handleEventChange,
  selectedEventId
}: EditModeTabContentProps) => {

  
  return (
    <Content>
      <FlexItem displayFlex alignItemsCenter>
        {currentTab === 0 && (
          <DeliveryMethodRadioGroup
            className="radioGroupEditMode"
            selectedDeliveryMethods={selectedDeliveryMethods}
            handleDeliveryMethodChange={handleDeliveryMethodChange}
          />
        )}
        {currentTab === 1 && (
          <DropDown
            width={'100%'}
            classes={{ root: 'template-dropdown' }}
            data={templates}
            IconComponent={() => (
              <CommonIcon
                name="arrow_down"
                width="1em"
                height="1em"
                fill={theme.palette.grey.main}
              />
            )}
            handleChange={chooseTemplate}
            selectedItem={templates.find(
              template => template.id === selectedTemplateId
            )}
          />
        )}
        {currentTab === 2 && (
          <React.Fragment>
            <FormControl classes={{ root: 'checkBoxGroupFieldset' }}>
              <RadioGroup
                classes={{ root: `radioGroupEditModeContent` }}
                aria-label="Delivery Method"
                name="deliveryMethod"
                value={selectedSendMethod}
                onChange={handleSelectSendMethod}>
                <FormControlLabel
                  classes={{ root: 'checkBoxGroupFormControl' }}
                  value={'event'}
                  control={
                    <Radio
                      checkedIcon={
                        <RadiobuttonIcon
                          width={'1.5em'}
                          height={'1.1em'}
                          name="selected"
                        />
                      }
                      icon={
                        <RadiobuttonIcon
                          width={'1.5em'}
                          height={'1.1em'}
                          name="unselected"
                        />
                      }
                    />
                  }
                  label={'Event'}
                />
                <FormControlLabel
                  classes={{ root: 'checkBoxGroupFormControl' }}
                  value={'datetime'}
                  control={
                    <Radio
                      checkedIcon={
                        <RadiobuttonIcon
                          width={'1.5em'}
                          height={'1.1em'}
                          name="selected"
                        />
                      }
                      icon={
                        <RadiobuttonIcon
                          width={'1.5em'}
                          height={'1.1em'}
                          name="unselected"
                        />
                      }
                    />
                  }
                  label={'Date & Time'}
                />
              </RadioGroup>
            </FormControl>
            {selectedSendMethod === 'datetime' ? (
              <DateTime
                theme={theme}
                dateTimeMoment={dateTimeMoment}
                handleSelectedDateTimeChange={handleSelectedDateTimeChange}
              />
            ) : (
              <Event
                theme={theme}
                selectedEvent={selectedEventId}
                events={groupEventsDropDownOptions}
                handleOffsetDays={handleOffsetDays}
                handleEmptyOffsetDays={handleEmptyOffsetDays}
                offsetDays={offsetDays}
                offsetBeforeAfter={offsetBeforeAfter}
                handleOffsetBeforeAfter={handleOffsetBeforeAfter}
                chooseEvent={handleEventChange}
              />
            )}
          </React.Fragment>
        )}
      </FlexItem>
    </Content>
  )
}

export default EditModeTabContent
