import compose from 'lodash/flowRight'
import { USERSSECTION, RESIDENTSECTION, RESIDENTMANAGEMENTSURVEYSSECTION, RESIDENT_MOVEINMOVEOUT, updateUserLeaseInterestStatusPropertySuccess, updateUserLeaseInterestStatusAction } from '../actions'
import withNormalizedFetch from '../../../helpers/reducers/withNormalizedFetch'
import withNormalizedRefresh from '../../../helpers/reducers/withNormalizedRefresh'
import withFetchNext from '../../../helpers/reducers/withFetchNext'
import {
  fetchUsersSuccess,
  fetchResidentUsersSuccess,
  fetchResidentSummarySuccess,
  fetchResidentManagementSurveysSuccess,
  fetchNextResidentManagementSurveysSuccess,
  SET_SEARCH_TERM,
  CLEAR_SEARCH_TERM
} from '../actions'
import { reduce } from 'lodash'

const initialState = {}

const propertyUsers = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm
      }
    case CLEAR_SEARCH_TERM:
      return {
        ...state,
        searchTerm: ''
      }
    case fetchResidentSummarySuccess().type:
      const residentSummary = reduce(action.payload, (acc, item) => {
        if (!acc[item.month]) {
          acc[item.month] = {}
        }

        acc[item.month][item.nps || "NoResponse"] = item.count
        return acc
      }, {})

      return {
        ...state,
        resident_summary: residentSummary
      }
    case fetchResidentManagementSurveysSuccess().type:
      return {
        ...state,
        resident_management_surveys: action.payload.data.residents,
        resident_management_surveys_total: action.payload.data.total_residents
      }
    case fetchNextResidentManagementSurveysSuccess().type:
      return {
        ...state,
        resident_management_surveys: [...state.resident_management_surveys, ...action.payload.data.residents],
        resident_management_surveys_total: action.payload.data.total_residents
      }
    case updateUserLeaseInterestStatusAction().type:
      return { ...state, data: updateResidentsList(state.data, action.userId, action.status) }
    case updateUserLeaseInterestStatusPropertySuccess().type:
      return { ...state, data: updateResidentsList(state.data, action.userId, action.response.lease_interest_status) }
    default:
      return state
  }
}

const updateResidentsList = (residents, userId, lease_interest_status) => {
  return residents.map((resident) => resident.id === userId ? ({ ...resident, lease_interest_status: lease_interest_status }) : resident)
}

const withFetchAndRefresh = compose(
  withNormalizedFetch(USERSSECTION),
  withNormalizedRefresh(USERSSECTION),
  withFetchNext(RESIDENTSECTION)
)

export default withFetchAndRefresh(propertyUsers)
