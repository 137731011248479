// @flow
import React from 'react'
import { LockBase, ThumbTurn } from './styles'
import { Props } from './types'

const Lock = ({ isLocked, onClick }: Props) => (
  <React.Fragment>
    <LockBase className={`${isLocked && 'locked'}`} onClick={onClick}>
      <ThumbTurn />
    </LockBase>
  </React.Fragment>
)

export default Lock
