import { combineReducers } from 'redux'
import deviceProfiles from './deviceProfiles/reducers'
import connected from './connected/reducers'

const addDevice = combineReducers({
  deviceProfiles,
  connected
})

export default addDevice
