import styled from '@emotion/styled'
import MaterialTextField from '@material-ui/core/TextField'
import { convertHexToRGB } from '../../../utils/commonUtils'
import { FlexItem } from '@Common/Flex/FlexDirection'

export const Header = styled('div')`
  padding-left: ${({ big }) => (big ? '0em' : '6em')};
  margin-top: ${({ big }) => (big ? '0em' : '1em')};
  div:first-child {
    font-size: ${({ theme, big }) =>
      big ? theme.custom.font.size.mdlg : theme.custom.font.size.sm};
    color: ${({ theme }) => theme.palette.grey.main};
    margin-bottom: 0.5em;
  }
  div:nth-child(2) {
    font-size: calc(${({ theme }) => theme.custom.font.size.xl} + 0.5em);
    color: ${({ theme }) => theme.palette.common.black};
  }
`

export const CodeDetailsContainer = styled('div')`
  height: 90%;
  width: 37%;
  border-top-left-radius: 2em;
  box-shadow: ${({ theme }) =>
    `0 1px 10px 0 ${convertHexToRGB(theme.palette.neutrals.warmGray)}`};
  right: 0;
  bottom: 0;
  position: absolute;
  padding: 3em 4em;

  & > div {
    height:100%
  }

  .unit {
    ${({ theme }) =>
      `
      flex-direction: row-reverse;
      margin-bottom:1em;
      font-size: ${({ theme }) => theme.custom.font.size.ml};
      max-height: fit-content;
      padding: 1em 0;
      & svg {
        transform: rotate(180deg);
        margin-right:.5em;
      }
  `};
`

export const CodesContainer = styled('div')`
  height: 90%;
  width: 62%;
  bottom: 0;
  position: absolute;
  div {
    border-bottom: 0px;
  }
`

export const ButtonSet = styled('div')`
  position: absolute;
  right: 2em;
  display: flex;
  flex-direction: column;
  height: 80%;
  & > * {
    margin-bottom: 1em;
  }
`

export const RowsWrap = styled('div')`
  margin-right: -4em;
  & > * {
    border-bottom: 0px !important;
    padding: 0em 1.5em;
  }
  & > * > * > * > *:first-child {
    color: ${({ theme }) => theme.palette.grey.main};
  }
  & > * > * > * > *:nth-child(2) {
    ${({ theme, editPage }) =>
      editPage &&
      `color: ${theme.palette.common.black};
  `};
  }
  & > *:nth-child(odd) {
    background: ${({ theme }) => theme.palette.grey.light7};
  }

  ${({ editPage }) =>
    editPage &&
    `
    margin-right: 2em;
    margin-left: -10vw;
`};
  .hub-on {
    ${({ theme }) =>
      `color: ${theme.palette.primary.green.mint};
  `};
  }
  .hub-off {
    ${({ theme }) =>
      `color: ${theme.palette.grey.main};
  `};
  }

  .hub-text {
    ${({ theme }) =>
      `color: ${theme.palette.common.black};
      font-size: ${({ theme }) => theme.custom.font.size.sm};
  `};
`

export const CodesWrap = styled('div')`
  padding-right: 5em;
  position: relative;
  * {
    background-color: transparent !important;
  }
  & .row {
    padding: 0em 3.5em 0em 1.5em;
    white-space: nowrap;
    height: 100%;
  }
  & .row.first {
    padding-right: 1.5em;
  }
  & .row:not(.first):hover,
  & .selected {
    background: ${({ theme }) => theme.palette.secondary.light3} !important;
  }
  & .row:not(.first):hover svg,
  & .selected svg {
    fill: ${({ theme }) => theme.palette.primary.green.mint};
  }
  & .row:not(.first) svg {
    fill: ${({ theme }) => theme.palette.grey.main};
  }
  & .row:not(.first):hover *,
  & .selected * {
    color: ${({ theme }) => theme.palette.common.black};
  }
  & .row:not(.first):hover *:last-child,
  & .selected *:last-child {
    color: ${({ theme }) => theme.palette.primary.green.mint};
  }
  & .row * {
    color: ${({ theme }) => theme.palette.grey.main};
  }
`

export const TextField = styled(MaterialTextField)`
  width: 50%;
  height: 2.7em;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.palette.neutrals.warmGray} !important;
  padding: 0 15px;
  & input {
    color: ${({ theme }) => theme.palette.text.lightGrey};
    padding-top: 8px;
  }
  & > div {
    display: flex;
    align-items: center;
  }
`

export const CodeRow = styled('div')`
  cursor: pointer;
`
export const Units = styled(FlexItem)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
  & svg {
    fill: ${({ theme }) => theme.palette.primary.green.mint};
  }
`

export const NoLockCodeFound = styled('div')`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`
