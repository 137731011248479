// @flow

import React, { Fragment } from 'react'
import { GridItem } from '../../Common/Grid'
import TopNavigation from '../../Common/TopNavigation'
import UnitInfoCard from './UnitInfoCard'
import UsersTab from './GeneralInfo/GeneralInfo'
import type { Props, TabsType } from './types'
import Loader from '../../Common/Loader'

const getTabs = (
  hasUnit: boolean,
  isFetching: boolean,
  url: string
): TabsType => {
  return hasUnit && !isFetching
    ? [
        {
          name: 'General Info',
          route: `${url}/users`
        }
      ]
    : []
}

const Unit = ({
  sendActivationEmail,
  toggleResidentZegoSmartEnabled,
  activeResidentsCount,
  users,
  residentsCount,
  unitCardInfo,
  isFetching,
  hasData,
  match,
  unitBalance,
  userSettings,
  isSmartEnabled
}: Props) => {
  const tabs = getTabs(hasData, isFetching, match.url)

  if (isFetching) return <Loader />
  if (!hasData) return null

  return (
    <Fragment>
      <GridItem columnStart={5} columnSpan={9} rowStart={1} rowSpan={1}>
        <TopNavigation
          tabs={tabs}
          rightLinks={[
            {
              text: 'User Management Summary',
              to: `/properties/${match.params.propertyId}/user-management`
            }
          ]}
        />
      </GridItem>

      <UnitInfoCard
        name={unitCardInfo.name}
        residentsCount={residentsCount}
        activeResidents={activeResidentsCount}
        currentBalance={unitBalance}
      />

      <UsersTab
        users={users}
        userSettings={userSettings}
        match={match}
        isSmartEnabled={isSmartEnabled}
        toggleResidentZegoSmartEnabled={toggleResidentZegoSmartEnabled}
        sendActivationEmail={sendActivationEmail}
      />
    </Fragment>
  )
}

export default Unit
