import ReactSelect from 'react-select'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { IconButton } from '@material-ui/core'
import MaterialCheckbox from '@material-ui/core/Checkbox'

import CommonButton from '@Common/Button'
import CommonTextField from '@Common/TextField'
import MaterialTextField from '@material-ui/core/TextField'
import { convertHexToRGB } from '../../../utils/commonUtils'

export const globalStyles = css`
  html,
  body {
    overflow-x: hidden;
  }
`

export const ImageWrapper = styled('div')`
  position: absolute;
  top: 10%;
  left: 60px;
  width: 60%;
  height: 30%;
  overflow: hidden;
`

export const Container = styled('div')`
  ${({ theme }) => {
    return `
  ${Image} {
    width: 100%;
    height: 30%;
    background: ${theme.palette.neutrals.lightGray};
    top: 40%;
    left:0;
    z-index: -1;
  `
  }};
`

export const Image = styled('span')`
  position: absolute;
  background-size: cover;
  background-image: url(${({ imageSrc }) => imageSrc});
`

export const Content = styled('div')`
  position: absolute;
  right: 0;
  top: 47%;
  width: 85%;
  height: 30%;
  box-shadow: ${({ theme }) =>
    `0 1px 10px 0 ${convertHexToRGB(theme.palette.neutrals.warmGray, 0.5)}`};
  padding: 3em 15em 3em 4em;
  background: ${({ theme }) => theme.palette.common.white};

  & span {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }
`

export const BackgroundDiv = styled('div')`
  background: ${({ theme }) => theme.palette.primary.green.iceberg};
  position: relative;
  margin: 0 -9999rem 0 -9999rem;
  padding: 0 9999rem 0 9999rem;
  height: 210px;
`

export const Label = styled('span')`
  font-size: ${({ theme }) => theme.custom.font.size.md};
  color: ${({ theme }) => theme.palette.text.grey};
`

export const TextField = styled(CommonTextField)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.navy.midnight};
  width: 100%;
`

export const ImageBlock = styled('div')`
  grid-column: ${({ columnStart, columnSpan }) => `${columnStart} / span
  ${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span
  ${rowSpan}`};
  display: flex;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.neutrals.lightGray};
  ${({ image, theme }) =>
    image
      ? `
      img {
       width: 100%;
       height: 100%;
       object-fit: cover;
      }
`
      : `
      border: 1px dashed ${theme.palette.neutrals.deepGray}}
    `};
`

export const Button = styled('label')`
  width: 23%;
  padding: 0px;
  height: 2em;
  line-height: 2em;
  border-radius: 13px;
  background-color: ${({ theme }) => theme.palette.primary.green.mint};
  color: ${({ theme }) => theme.palette.common.white};
  text-align: center;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  cursor: pointer;
`

export const ImageGalleryDiv = styled('div')`
  width: 308px;
  padding-top: 10px;

  .image-gallery-slides .image-gallery-image {
    width: 100%;
    height: 200px;
    overflow: hidden;
    object-position: center center;
  }

  .image-gallery-thumbnails-wrapper {
    margin-top: 12px;
  }

  .image-gallery-thumbnail {
    width: auto;
    height: 116px;
    margin-left
  }

  .pin-image {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 100;
    cursor: pointer;
  }

  .delete-image {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 100;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0px;
  }
  
  .image-gallery-thumbnail-image {
    width: auto;
    height: 108px;
    object-fit: contain;
    overflow: hidden;
    object-position: center center;
  }
`

export const DropBox = styled('div')`
  height: 200px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23979797FF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='27' stroke-linecap='butt'/%3e%3c/svg%3e");
`

export const PhotoUploadDiv = styled('div')`
  width: 100%;
`


export const DeletePhotoButton = styled(IconButton)`
  border-radius: 0;
  margin: 0;
  background-color: ${({ theme }) => theme.palette.primary.main};
  min-width: 0px;
  width: 45px;
  height: 45px;
`

export const Select = styled(ReactSelect)`
  & > div {
    border-radius: 19px;
    padding-right: 5px;
    min-width: 130px;
    width: 70%;
  }

  & .time__control {
    height: 27px;
    min-height: 27px;
    background: ${({ theme }) => theme.palette.common.white};
  }

  & .time__value-container {
    padding: 0 8px;
  }

  & .time__indicators {
    align-items: end;
    padding-top: 2px;
  }

  & .time__indicator {
    padding: 0;
  }

  & .time__placeholder {
    top: 40%;
  }

  & .time__control--is-disabled .time__placeholder {
    color: ${({ theme }) => theme.palette.neutrals.warmGray};
  }
`

export const Checkbox = styled(MaterialCheckbox)`
  padding: 3px;
`

export const AmenityReservationsDiv = styled('div')`
  padding-top: 15px;
  padding-bottom: 15px;
`

export const AvailabilityDayHeader = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.lg};
  white-space: nowrap;
`

export const BlockLabelSpan = styled('span')`
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  vertical-align: middle;
  white-space: nowrap;
`

export const BlockDayLabelSpan = styled(BlockLabelSpan)`
  margin-right: 27px;
`

export const AvailabilityHoursDropdown = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  white-space: nowrap;
  width: 210px;
`

export const AvailabilityHoursHeader = styled('div')`
  padding-top: 8px;
  padding-left: 2px;
  padding-bottom: 37px;
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  white-space: nowrap;
`

export const AvailabilityReservationsDetails = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  white-space: nowrap;
`

export const AvailabilityReservationsLabel = styled('span')`
  font-weight: bold;
`

export const AvailabilityResourceDetails = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.md};
  white-space: nowrap;
`

export const ReservationResidentAvatar = styled('img')`
  border-radius: 50%;
  width: ${({ large }) => large ? '44px' : '24px'};
  height: ${({ large }) => large ? '44px' : '24px'};
  margin-left: 10px;
  margin-right: ${({ large }) => large ? '13px' : '10px'};
`

export const ReservationDetails = styled('div')`
  font-size: ${({ theme, selected }) => selected ? theme.custom.font.size.mdlg : theme.custom.font.size.md};
  font-weight: ${({ theme, selected }) => selected ? theme.custom.font.weight.bold : theme.custom.font.weight.light};
  margin-right: 40px; 
`

export const ReservationContainer = styled('div')`
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 0.05fr 0.25fr 1fr 0.25fr 0.05fr;
  grid-template-rows: 0.05fr 1fr 0.05fr;
  gap: 0px 0px;
  grid-auto-flow: column;
  grid-template-areas:
    ". . . . ."
    ". avatar-area details-area delete-button-area ."
    ". . . . .";
`

export const ReservationAvatarContainer = styled('div')`
  height: 100%;
  display: block;
  justify-content: center;
  align-items: center;
  grid-area: avatar-area;
`

export const ReservationDeleteButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: delete-button-area;
`

export const ReservationDetailsContainer = styled('div')`
  display: block;
  justify-content: left;
  grid-area: details-area;  
  font-size: ${({ theme, selected }) => selected ? theme.custom.font.size.mdlg : theme.custom.font.size.md};
  font-weight: ${({ theme, selected }) => selected ? theme.custom.font.weight.bold : theme.custom.font.weight.normal};
  :not(:last-child) {
    margin-right: 12px;
  }
`


export const ReservationResidentDetails = styled('div')`
  font-size: ${({ theme, selected }) => selected ? theme.custom.font.size.mdlg : theme.custom.font.size.md};
  font-weight: ${({ theme, selected }) => selected ? theme.custom.font.weight.bold : theme.custom.font.weight.normal};
  display: grid;
  grid-template-columns: auto 80% 5%;
`

export const ReservationDetailsResidentName = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
`

export const ReservationResource = styled('span')`
  font-weight: bold;
  font-style: italic;
`

export const ReservationsResources = styled('span')`
  font-style: italic;
`

export const ReservationDeleteButton = styled(IconButton)`
 
`

export const ReservationInvalid = styled('div')`
  padding-top: 10px;
  color: ${({ theme }) => theme.palette.secondary.pink.deepRed};
`

export const ReservationsReserved = styled('span')`
  font-weight: bold;
`

export const AvailabilityCalendarDiv = styled('div')`
  width: 304px;
`

export const CalendarDiv = styled('div')`
  margin-top: 10px;
`

export const AvailabilityTimeSlotsDiv = styled('div')`
  margin-top: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 350px;
`

export const AvailabilityTimeSlotHoursDiv = styled('div')`
  display: inline-block;
  float: left;
`

export const TimeSlotDiv = styled('div')`
  width: 100%;
  text-align: right;
  color: ${({ theme }) => `${theme.palette.neutrals.deepGray}`};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`

export const ReservationsTimeSlotDiv = styled('div')`
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const AvailabilityTimeSlotReservationsDiv = styled('div')`
  display: inline-block;
  float: left;
  width: 80%;
  padding-top: 25px;
  padding-left: 5px;
`

export const AmenityClosedDiv = styled('div')`
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
`

export const Layout = styled('div')`
  width: 100%;
  &:after {
    content: ' ';
    background: ${({ theme }) => `${theme.palette.neutrals.lightGray}`};
    border-top-right-radius: 5px;
    width: 70%;
    height: calc(100% - 1em);
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: -15;
    top: 2em;
  }
`

export const AvailabilityAvailableHours = styled('span')`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
`

export const AvailabilityFormScheduleDiv = styled('div')`
  font-size: 16px;
  white-space: nowrap;
`

export const AvailabilityFormScheduleTableHeader = styled('div')`
  font-size: 14px;
  white-space: nowrap;
  margin-top: 15px;
  ${({ reservationType }) => {
    if (reservationType === 'multiDay') {
      return ``
    }
    return `
      padding-left: 25px;
    `
  }}
`

export const AvailabilityFormScheduleSelectDiv = styled('div')`
  width: 150px;
`

export const AvailabilityFormScheduleCheckBoxDiv = styled('div')`
  width: 150px;
`

export const AvailabilityFormScheduleTextDiv = styled('div')`
  width: 150px;
  margin-left: 10px;
`

export const AvailabilityFormDiv = styled('div')``

export const AvailabilityFormExtrasLabel = styled('div')`
  font-weight: bold;
  padding-right: 8px;
`
export const AvailabilityFormExtrasDiv = styled('div')`
  min-width: 602px;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.neutrals.deepGray};
  margin-top: 30px;
  padding: 1em;
`

export const AvailabilityFormCheckBoxDiv = styled('div')`
  margin-left: -12px;
`

export const AvailabilityFormTextfieldDiv = styled('div')`
  padding: 1em;
  padding-right: 2em;
`

export const AmenityFormCheckBoxDiv = styled('div')`
  margin-left: -12px;
  padding-left: 8px;
`

export const AmenityFormWaiver = styled('div')`
  padding-top: 10px;
  padding-left: 8px;
`

export const AmenityFormDeleteWaiverButton = styled(IconButton)`
  margin-top: -2px;
`

export const AvailabilityFormMaxPeopleField = styled('div')`
  margin-left: -7px;
`

export const DraftModeBannerContainer = styled('div')`
  margin-top: 10px;
  width: 300px;
`

export const DraftModeBanner = styled('div')`
  height: 30px;
  background: ${({ theme }) => `${theme.palette.alternates.brightPurple}`};
  clip-path: polygon(calc(100% - 285px) 0, 100% 0%, 100% 100%, 0% 100%);
`

export const DraftModeBannerLine = styled('div')`
  width: 3px;
  height: 36px;
  position: relative;
  top: -3px;
  background: ${({ theme }) => `${theme.palette.common.white}`};
  transform: rotate(24deg);
`


export const DraftModeBannerText = styled('div')`
  font-size: 16px;
  font-style: italic;
  line-height: 19px;
  text-align: right;
  color: ${({ theme }) => `${theme.palette.common.white}`};
  white-space: nowrap;
`

export const AmenityDetailDiv = styled('div')`
  padding-top: 20px;
  color: ${({ theme }) => `${theme.palette.grey.main}`};
  font-size: 16px;
`

export const AmenityDetailLabel = styled('span')`
  font-weight: bold;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
`


export const AmenityProStyleContainer = styled('div')`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  display: grid;
  height: 100%;
  width: 100%;
  column-gap: 12px;
  row-gap: 0px;
  grid-template-columns: 2fr 8fr 1fr;
  grid-template-rows: .5fr .5fr .5fr 6fr;
  grid-template-areas: 
    "left nav ."
    "left content buttons"
    "left content buttons"
    "left content ."
`

export const AmenityProSidebarArea = styled('div')`
 z-index: 1;
 grid-area: left; 
`

export const AmenityProNavArea = styled('div')`
  display: flex;
  flex-direction: row;
  grid-area: nav;
  justify-content: space-between;
  padding-inline: 16px;
`

export const AmenityProContentArea = styled('div')`
  grid-area: content;
`

export const AmenityProButtonsArea = styled('div')`
  grid-area: buttons;
`

export const AmenityWaiverFileName = styled('span')`
  color: ${({ theme }) => `${theme.palette.neutrals.deepGray}`};
  word-break: break-all;
`

export const AmenityFormDiv = styled('div')`
  padding-top: 20px;
  font-size: 16px;
`

export const DeleteButton = styled(CommonButton)`
  color: ${({ theme }) => theme.palette.secondary.pink.deepRed};
  text-decoration: underline;
  white-space: nowrap;
`

export const PlaceholderText = styled('div')`
  z-index: 10;
  top: 160px;
  position: absolute;
  right: 50px;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.neutrals.mediumGray};
`

export const Padding = styled('div')`
  background-image: linear-gradient(
    -45deg,
    ${({ theme }) => theme.palette.neutrals.lightGray} 25%,
    ${({ theme }) => `${theme.palette.common.white}`} 25%,
    ${({ theme }) => `${theme.palette.common.white}`} 50%,
    ${({ theme }) => theme.palette.neutrals.lightGray} 50%,
    ${({ theme }) => theme.palette.neutrals.lightGray} 75%,
    ${({ theme }) => `${theme.palette.common.white}`} 75%,
    ${({ theme }) => `${theme.palette.common.white}`} 100%
  );
  background-size: 20px 20px;
  width: 100%;
  height: 100%;
`
export const DialogTitle = styled('div')`
    text-align: center;
    font-size: 24px;
    line-height: 29px;
    word-break: break-word;

    li {
      font-size: 18px;
      list-style-type: disc;
      text-align: start;
    }
`
export const DialogMessage = styled('div')`
    font-size: 14px;
    line-height: 22px;
    padding-left: 3px;
    color: ${({ theme }) => theme.palette.neutrals.deepGray};
`

export const BlockATimeModalDropdowns = styled('div')`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 10fr 1fr 10fr;
  grid-gap: 10px;
  font-size-adjust: 0.3;
`

export const WaiverModalTitle = styled('h3')`
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: center;
`
export const RadioGroupLabel = styled('span')`
    font-size: 14px;
    line-height: 17px;
    color: ${({ theme }) => theme.palette.neutrals.deepGray};
`

export const NewReservationDiv = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  font-weight: bold;
`

export const ResidentDiv = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  padding-top: 37px;
  padding-bottom: 3px;
`

export const NewReservationForm = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  line-height: ${({ theme }) => theme.custom.font.size.mdl};
  padding-top: 37px;
  padding-left: 20px;
`

export const NewReservationResidentAvatar = styled('img')`
  border-radius: 50%;
  width: 44px;
  margin-right: 15px;
`

export const NewReservationResidentDetailsDiv = styled('div')`
  font-weight: 500;
`

export const NewReservationDeleteButton = styled(IconButton)`
  margin-top: 3px;
`

export const ResidentName = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  line-height: 19px;
  margin-bottom: 4px;
`

export const ResidentEmail = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.xs};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  line-height: 12px;
  margin-bottom: 8px;
`

export const ReservationGuest = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.xs};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  line-height: 12px;
`

export const ReservationGuestCount = styled('span')`
  vertical-align: top;
  margin-left: 2px;
`

export const FlexContainer = styled('div')`
  display: flex;
  margin-bottom: 26px;
  margin-top: 29px;
`

export const DeleteReservationConfirmation = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  line-height: 22px;
  padding: 13px 0;
`

export const ResidentMessageContainer = styled('div')`
    margin-top: 16px;
`

export const ResidentMessage = styled(MaterialTextField)`
  width: 100%;
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  color: ${({ theme }) => theme.palette.text.grey};

  label {
    font-size: ${({ theme }) => theme.custom.font.size.md};
    line-height: 17px;
  }

  textarea {
    font-size: ${({ theme }) => theme.custom.font.size.mdl};
    line-height: 19px;
  }
`

export const MinMaxDaysTextField = styled('div')`
  max-width: 60%;
`

export const NewReservationGuestDiv = styled('div')`
  padding-top: 30px;
`

export const NewReservationMessageDiv = styled('div')`
  padding-top: 30px;
`

export const NewReservationCheckBoxDiv = styled('div')`
  margin-left: -12px;
  padding-top: 30px;
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  color: ${({ theme }) => theme.palette.neutrals.deepGray};
`

export const DeleteReservationModalMessage = styled('p')`
  width: 100%;
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  color: ${({ theme }) => theme.palette.common.main};
  line-height: 22px;

  span {
    font-weight: ${({ theme }) => theme.custom.font.weight.normal};
    color: ${({ theme }) => theme.palette.text.grey};
  }
`
export const Bold = styled('span')`
  font-weight: 700;
`

export const CheckTime = styled('div')`
  color: ${({ theme }) => theme.palette.neutrals.darkGray};
  line-height: 40px;
`
