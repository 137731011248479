// @flow
import React from 'react'
import debounce from 'lodash/debounce'
import ScrollDownButton from '../ScrollDownButton'
import ScrollUpButton from '../ScrollUpButton'
import { ScrollButtonWrap } from './styles'
import {
  scrollBehaviourProps as Props,
  scrollBehaviourState as State
} from './types'

const withScrollBehaviour = (WrappedComponent: Function) =>
  class extends React.PureComponent<Props, State> {
    state = {
      currentSlideNumber: this.props.currentSlideNo || 1,
      showScrollDown:
        this.props.lastSlideNumber > (this.props.currentSlideNo || 1),
      showScrollUp: false
    }

    componentDidUpdate() {
      this.setState({
        showScrollDown:
          this.props.lastSlideNumber > this.state.currentSlideNumber
      })
    }

    scrollDown = () => {
      const { lastSlideNumber, onLastSlide } = this.props
      const { currentSlideNumber } = this.state
      if (currentSlideNumber === 1) {
        this.setState(prevState => {
          return {
            currentSlideNumber: prevState.currentSlideNumber + 1,
            showScrollUp: true
          }
        })
      }
      if (lastSlideNumber > currentSlideNumber && currentSlideNumber > 1) {
        this.setState(prevState => {
          return {
            currentSlideNumber: prevState.currentSlideNumber + 1,
            showScrollDown: true
          }
        })
      }
      if (lastSlideNumber - 1 === currentSlideNumber) {
        this.setState(
          {
            showScrollUp: true,
            showScrollDown: false
          },
          onLastSlide
        )
      }
    }

    scrollUp = () => {
      const { currentSlideNumber } = this.state

      if (
        currentSlideNumber > 1 &&
        currentSlideNumber <= this.props.lastSlideNumber
      ) {
        this.setState(prevState => {
          return {
            currentSlideNumber: prevState.currentSlideNumber - 1,
            showScrollDown: true,
            showScrollUp: true
          }
        })
      }

      if (currentSlideNumber - 1 === 1) {
        this.setState({
          showScrollDown: true,
          showScrollUp: false
        })
      }
    }

    render() {
      const {
        transitionHeights,
        arrowsRight,
        items,
        numberOfItemsPerSlide
      } = this.props

      const isOneSlide = items.length / numberOfItemsPerSlide <= 1

      return (
        <React.Fragment>
          <ScrollButtonWrap
            className="scroll-up-behaviour"
            arrowsRight={arrowsRight}
            transitionHeights={transitionHeights}
            topArrow
            topScrollButtonVisible={this.state.showScrollUp}>
            {this.state.showScrollUp &&
              !isOneSlide && (
                <ScrollUpButton scrollUp={debounce(this.scrollUp, 100)} />
              )}
          </ScrollButtonWrap>
          <WrappedComponent {...this.state} {...this.props} />
          <ScrollButtonWrap
            className="scroll-down-behaviour"
            arrowsRight={arrowsRight}
            isOneSlide={isOneSlide}>
            {this.state.showScrollDown &&
              !isOneSlide && (
                <ScrollDownButton scrollDown={debounce(this.scrollDown, 100)} />
              )}
          </ScrollButtonWrap>
        </React.Fragment>
      )
    }
  }

export default withScrollBehaviour
