import React from 'react'
import PrivateRoute from '../PrivateRoute'
import { MarketplaceContainer } from '../../views/Marketplace'
import { MarketplaceNewDealContainer } from '../../views/Marketplace/NewDeal'
import MarketplaceViewDealContainer from '../../views/Marketplace/NewDeal/MarketplaceViewDealContainer'

const getMarketPlaceRoutes = () => [
  <PrivateRoute
    exact
    path="/properties/:propertyId/marketplace/overview"
    key="marketplace"
    component={MarketplaceContainer}
  />,
  <PrivateRoute
    exact
    path="/properties/:propertyId/marketplace/new"
    key="marketplace"
    component={MarketplaceNewDealContainer}
  />,
  <PrivateRoute
    exact
    path="/properties/:propertyId/marketplace/:id"
    key="marketplace"
    component={MarketplaceViewDealContainer}
  />
]

export default getMarketPlaceRoutes
