import React from 'react'
import './commonIcons.svg'
import './logos.svg'
import './searchIcons.svg'
import './navIcons.svg'
import './chatIcons.svg'
import './thermostatIcons.svg'
import './hubIcons.svg'
import './socialIcons.svg'
import './checkboxIcons.svg'
import './lockIcons.svg'
import './loading.svg'
import './radiobuttonIcons.svg'
import './selectorIcons.svg'
import './communityFeedIcons.svg'
import './templates.svg'
import './groups.svg'
import './branchIcons.svg'
import './resync.svg'
import './community.svg'

const Icon = ({ prefix, name, ...props }) => (
  <svg {...props}>
    <use xlinkHref={`#${prefix}_${name}`} {...props} />
  </svg>
)
export const Logo = props => <Icon prefix="logos" {...props} />
export const SocialIcon = props => <Icon prefix="socialIcons" {...props} />
export const NavIcon = props => <Icon prefix="navIcons" {...props} />
export const CommonIcon = props => <Icon prefix="commonIcons" {...props} />
export const SearchIcon = props => <Icon prefix="searchIcons" {...props} />
export const ChatIcon = props => <Icon prefix="chatIcons" {...props} />
export const ThermostatIcons = props => (
  <Icon prefix="thermostatIcons" {...props} />
)
export const HubIcon = props => (
  <Icon prefix="hubIcons" fill="#029385" {...props} />
)
export const CheckboxIcon = props => <Icon prefix="checkboxIcons" {...props} />
export const LockIcon = props => <Icon prefix="lockIcons" {...props} />
export const RadiobuttonIcon = props => (
  <Icon prefix="radiobuttonIcons" {...props} />
)
export const Loading = props => <Icon prefix="loading" {...props} />
export const ReSyncIcon = props => <Icon prefix="resync" {...props} />
export const SelectorIcons = props => <Icon prefix="selectorIcons" {...props} />
export const CommunityFeedIcons = props => (
  <Icon prefix="communityFeedIcons" {...props} />
)
export const TemplatesIcons = props => <Icon prefix="templates" {...props} />
export const GroupsIcons = props => <Icon prefix="groups" {...props} />
export const BranchIcons = props => <Icon prefix="branchIcons" {...props} />
export const CommunityIcon = props => <Icon prefix="community" {...props} />
export const PinIcons = props => <Icon prefix="pin" {...props} />
export default Icon
