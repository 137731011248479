import { createFetchActions } from '../../helpers/actions/creators'
export const FETCH_PACKAGES = 'PACKAGES'
export const SEARCH_PACKAGES = 'SEARCHPACKAGES'
export const UPDATE_PACKAGE_STATUS = 'UPDATEPACKAGESTATUS'
export const UPDATE_PACKAGE_STATUS_OPTIMISTICALLY =
  'UPDATEPACKAGESTATUSOPTIMISTICALLY'
export const UPDATE_PACKAGE_STATUS_SUCCESS = 'UPDATEPACKAGESTATUSSUCCESS'
export const UPDATE_PACKAGE_STATUS_ERROR = 'UPDATEPACKAGESTATUSERROR'
export const UPDATE_PACKAGE_REMINDER = 'UPDATEPACKAGEREMINDER'

export const {
  fetchAction: fetchPackagesAction,
  fetchStartAction: fetchPackagesStart,
  fetchSuccessAction: fetchPackagesSuccess,
  fetchErrorAction: fetchPackagesError
} = createFetchActions(FETCH_PACKAGES)

export const fetchPackages = (id, filter, sortBy, page = 1) =>
  fetchPackagesAction({ id, filter, sortBy, page })

export const {
  fetchAction: searchPackagesAction,
  fetchStartAction: searchPackagesStart,
  fetchSuccessAction: searchPackagesSuccess,
  fetchErrorAction: searchPackagesError
} = createFetchActions(SEARCH_PACKAGES)

export const searchPackages = (id, term, filter, sortBy) =>
  searchPackagesAction({ id, term, filter, sortBy })

export const updatePackageStatus = (packageId, status) => ({
  type: UPDATE_PACKAGE_STATUS,
  packageId,
  status
})

export const updatePackageStatusOptimistically = (packageId, status) => ({
  type: UPDATE_PACKAGE_STATUS_OPTIMISTICALLY,
  payload: {
    data: {
      packageId,
      status
    }
  }
})

export const updatePackageReminder = (propertyId, attributes) => ({
  type: UPDATE_PACKAGE_REMINDER,
  propertyId,
  attributes
})
