import React from 'react'
import Modal from '@material-ui/core/Modal'
import { withTheme } from 'emotion-theming'
import FormLabel from '@material-ui/core/FormLabel'
import { withRouter, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import RadioButtons from '@Common/RadioButtons'
import { DefaultBackButton } from '@Common/Button'
import FlexDirection from '@Common/Flex/FlexDirection'
import api from 'zego-shared/utils/api'

import {
  CustomMobileStepper,
  Wrapper,
  RadioButtonContainer,
  CustomFormLabel,
  Heading,
  CustomInput,
  BackLink,
  SuccessScreen,
  ErrorMessage
} from './styles'

export class CreateUnit extends React.Component<{}, {}> {
  state = {
    unitName: '',
    isFormComplete: false,
    formSteps: [
      {
        question: 'What type of unit would you like to add?',
        component: () => (
          <RadioButtons
            width={'60%'}
            handleChange={this.handleNext}
            handleClick={this.handleRadioChange}
            selectedValue={this.state.radioButtonValue}
            options={this.radioOptions}
            name="unitType"
            subHeading=""
            textSize="1.08em"
          />
        )
      },
      {
        question: 'Create a name for this unit',
        component: () => (
          <React.Fragment>
            <CustomInput
              width={'60%'}
              value={this.state.unitName}
              onChange={this.handleInputChange}
              onKeyPress={this.handleKeyPress}
            />
            {this.state.addUnitSuccess === false && (
              <ErrorMessage>
                Something Went Wrong! Please Try Again
              </ErrorMessage>
            )}
          </React.Fragment>
        )
      }
    ],
    currentStep: 0,
    addUnitSuccess: null
  }

  radioOptions = [
    {
      value: 'residential',
      label: 'Residential'
    },
    {
      value: 'common',
      label: 'Common'
    }
  ]

  handleInputChange = event => {
    this.setState({ unitName: event.target.value })
  }

  handleRadioChange = event => {
    this.setState({ radioButtonValue: event.target.value })
  }

  handleKeyPress = ({ key }: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter' && this.state.unitName.trim() !== '') {
      this.handleNext()
    }
  }

  handleNext = () => {
    const { currentStep, formSteps } = this.state

    if (currentStep < formSteps.length - 1) {
      this.setState(state => ({
        currentStep: state.currentStep + 1
      }))
    }

    if (currentStep === formSteps.length - 1) {
      this.submit()
    }
  }

  submit = () => {
    const {
      userToken,
      match: {
        params: { propertyId }
      }
    } = this.props

    const { unitName, radioButtonValue } = this.state

    api
      .createUnit(userToken, propertyId, unitName, radioButtonValue)
      .then(response => {
        if (response) this.setState({ addUnitSuccess: true })
      })
      .catch(error => {
        if (error) this.setState({ addUnitSuccess: false })
      })
  }

  handleBack = () => {
    if (this.state.currentStep > 0) {
      this.setState(prevState => ({
        currentStep: prevState.currentStep - 1
      }))
      this.reset()
    }
  }

  componentDidUpdate() {
    if (this.state.addUnitSuccess) {
      this.timer = setTimeout(() => {
        this.setState({ isFormComplete: true })
      }, 1200)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  reset = () => {
    this.setState({ addUnitSuccess: null, unitName: '' })
  }

  render() {
    const {
      theme,
      match: { url }
    } = this.props

    const {
      formSteps,
      currentStep,
      isFormComplete,
      addUnitSuccess
    } = this.state

    if (isFormComplete) return <Redirect to={`${url.split('/units')[0]}`} />
    return (
      <Modal open={true}>
        <Wrapper>
          <FlexDirection direction="column" fullHeight>
            {addUnitSuccess ? (
              <SuccessScreen>SUCCESS!</SuccessScreen> 
            ) : (
              <React.Fragment>
                <CustomMobileStepper
                  className={'progress-stepper'}
                  variant="progress"
                  steps={3}
                  position="static"
                  activeStep={currentStep}
                />
                {!currentStep ? (
                  <Link
                    style={{ color: theme.palette.grey.main }}
                    onClick={this.reset}
                    to={`${url.split('/create')[0]}`}>
                    <DefaultBackButton />
                  </Link>
                ) : (
                  <BackLink onClick={this.handleBack}>
                    <DefaultBackButton />
                  </BackLink>
                )}
                <RadioButtonContainer>
                  <Heading>
                    <CustomFormLabel headingSize={'1.8em'}>
                      {'ADD UNIT'}
                    </CustomFormLabel>

                    <FormLabel className="subheading">
                      {formSteps[currentStep].question}
                    </FormLabel>
                  </Heading>
                  {formSteps[currentStep].component()}
                </RadioButtonContainer>
              </React.Fragment>
            )}
          </FlexDirection>
        </Wrapper>
      </Modal>
    )
  }
}

export default withRouter(withTheme(CreateUnit))
