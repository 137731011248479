
// @flow 

import get from 'lodash/get'
import { getData } from '../../helpers/selectors/data'
import { humanizeString } from '../../../utils/string'

export const getNonHubDevices = (state: Object) =>
  getData(state, ['unit', 'devices']).filter(
    device => device.type !== 'gateway'
  )

export const getNonHubDevicesName = (state: Object) =>
  getNonHubDevices(state).map(({ name, type }) => name || humanizeString(type))

export const getHub = (state: Object) =>
  getData(state, ['unit', 'devices']).find(device => device.type === 'gateway')

export const getDeviceById = (state: Object, id: number) =>
  getData(state, ['unit', 'devices']).find(device => device.id === id)

export const getHubId = (state: Object) => {
  const hub = getHub(state)
  return hub ? hub.id : null
}

export const getHubIdentifier = (state: Object) => (getHub(state) || {}).identifier

export const getLockState = (state: Object, id: number) =>
  get(getDeviceById(state, id), ['last_state', 'data', 'state'])

export const getSwitchState = (state: Object, id: number) => {
  return get(state, [
    'unit',
    'devices',
    'byId',
    id,
    'last_state',
    'data',
    'state'
  ])
}

export const hasHub = (state: Object) =>
  getData(state, ['unit', 'devices']).some(device => device.type === 'gateway')

export const getRemovalSteps = (state: Object, id: number) => {
  return get(
    state,
    ['unit', 'devices', 'byId', `${id}`, 'profile', 'removal_instructions'],
    []
  )
}

export * from './lockCodes'
