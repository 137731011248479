// @flow

import React from 'react'
import type { Node } from 'react'
import { Title, Subtitle } from './styles'

type Props = {
  title?: string | Node,
  subtitle?: string | Node,
  children?: Node
}

export default class AddUserStep extends React.Component<Props, {}> {
  render() {
    const { title, subtitle, children } = this.props

    return (
      <div>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        {children}
      </div>
    )
  }
}
