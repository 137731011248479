import { property } from 'lodash'
import { call, put, takeLatest, select, delay } from 'redux-saga/effects'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import { getPropertyId, getToken } from '../../authorization/selectors'
import { showSnackbar, hideSnackbar } from '../../snackbar/actions'
import {
  viewMarketplaceDealError,
  updateMarketplaceDealSuccess,
  updateMarketplaceDealError,
  deleteMarketplaceDealsFailure,
  deleteMarketplaceDealsSuccess,
  fetchMarketplaceDealsFailure,
  createMarketplaceDealFailure,
  createMarketplaceDealSuccess
} from '../../../utils/messages'

import {
  fetchMarketplaceDeals,
  fetchMarketplaceDealsStart,
  fetchMarketplaceDealsSuccess,
  fetchMarketplaceDealsError,
  fetchNextMarketplaceDeals,
  fetchNextMarketplaceDealsStart,
  fetchNextMarketplaceDealsSuccess,
  fetchNextMarketplaceDealsError,
  DELETE_MARKETPLACE_DEAL,
  CREATE_MARKETPLACE_DEAL,
  REORDER_MARKETPLACE_DEAL,
  createMarketplaceDealStart,
  createMarketplaceDealEnd,
  fetchMarketplaceCard,
  fetchMarketplaceCardStart,
  fetchMarketplaceCardSuccess,
  fetchMarketplaceCardError,
  updateMarketplaceCard,
  updateMarketplaceCardStart,
  updateMarketplaceCardSuccess,
  updateMarketplaceCardError
} from '../actions'

export function* getMarketplaceDeals({ page = 1 }) {
  try {
    yield put(fetchMarketplaceDealsStart())
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)
    const response = yield call(api.getMarketplaceDeals, propertyId, page, authToken)
    yield put(fetchMarketplaceDealsSuccess(response))
  } catch (error) {
    yield put(showSnackbar('failed to fetch marketplace deals', 'error'))
    yield put(fetchMarketplaceDealsError(error))
    log(error)
  }
}

export function* getNextMarketplaceDeals({ page = 1 }) {
  try {
    yield put(fetchNextMarketplaceDealsStart())
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)
    const response = yield call(api.getMarketplaceDeals, propertyId, page, authToken)
    yield put(fetchNextMarketplaceDealsSuccess(response))
  } catch (error) {
    yield put(showSnackbar(fetchMarketplaceDealsFailure, 'error'))
    yield put(fetchNextMarketplaceDealsError(error))
    log(error)
  }
}

export function* deleteMarketplaceDeal({ id }) {
  try {
    const authToken = yield select(getToken)

    yield call(api.deleteMarketplaceDeal, id, authToken)
    yield put(showSnackbar(deleteMarketplaceDealsSuccess, 'success'))
  } catch (error) {
    yield put(showSnackbar(deleteMarketplaceDealsFailure, 'error'))
    log(error)
  }
}

export function* reorderMarketplaceDeal({ id, index }) {
  try {
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)

    yield call(api.reorderMarketplaceDeal, id, propertyId, index, authToken)
    yield put(showSnackbar(updateMarketplaceDealSuccess, 'success'))
  } catch (error) {
    yield put(showSnackbar(updateMarketplaceDealError, 'error'))
    log(error)
  }
}

export function* createMarketplaceDeal({ marketplaceDeal, image, dealType, history, url }) {
  try {
    yield put(createMarketplaceDealStart)

    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)

    const deal = yield call(api.createMarketplaceDeal, marketplaceDeal, dealType, propertyId, authToken)

    if (image) {
      yield call(api.uploadMarketplaceDealImage, image, deal.id, authToken)
    }

    yield put(createMarketplaceDealEnd)
    yield history.push(url)
    yield put(showSnackbar(createMarketplaceDealSuccess, 'success'))
    yield delay(500)
    yield put(hideSnackbar())
  } catch (error) {
    log(error)
    yield put(showSnackbar(createMarketplaceDealFailure, 'error'))
    yield put(createMarketplaceDealEnd)
  }
}

export function* getMarketplaceDeal({ marketplaceId }) {
  try {
    yield put(fetchMarketplaceCardStart())
    const authToken = yield select(getToken)
    const response = yield call(api.getMarketplaceDeal, marketplaceId, authToken)
    yield put(fetchMarketplaceCardSuccess(response))
  } catch (error) {
    log(error)
    yield put(fetchMarketplaceCardError(error))
    yield put(showSnackbar(viewMarketplaceDealError, 'error'))
  }
}

export function* updateMarketplaceDeal({ marketplaceDeal, image, dealType, history, url }) { 
  try {
    yield put(updateMarketplaceCardStart())
    const authToken = yield select(getToken)

    yield call(api.updateMarketplaceDeal, marketplaceDeal, dealType, authToken)
    
    if (image) {
      yield call(api.uploadMarketplaceDealImage, image, marketplaceDeal.id, authToken)
    }
    
    yield put(showSnackbar(updateMarketplaceDealSuccess, 'success'))
    yield put(updateMarketplaceCardSuccess())
    yield history.push(url)

  } catch (error) {
    log(error)
    yield put(updateMarketplaceCardError(error))
    yield put(showSnackbar(updateMarketplaceDealError, 'error'))
  }
}

export function* watchGetMarketplaceDeals() {
  yield takeLatest(fetchMarketplaceDeals().type, getMarketplaceDeals)
}

export function* watchGetNextMarketplaceDeals() {
  yield takeLatest(fetchNextMarketplaceDeals().type, getNextMarketplaceDeals)
}

export function* watchDeleteMarketplaceDeal() {
  yield takeLatest(DELETE_MARKETPLACE_DEAL, deleteMarketplaceDeal)
}

export function* watchCreateMarketplaceDeal() {
  yield takeLatest(CREATE_MARKETPLACE_DEAL, createMarketplaceDeal)
}

export function* watchGetMarketplaceDeal() {
  yield takeLatest(fetchMarketplaceCard().type, getMarketplaceDeal)
}

export function* watchUpdateMarketplaceDeal() {
  yield takeLatest(updateMarketplaceCard().type, updateMarketplaceDeal)
}

export function* watchReorderMarketplaceDeal() {
  yield takeLatest(REORDER_MARKETPLACE_DEAL, reorderMarketplaceDeal)
}

export default [
  watchGetMarketplaceDeals(),
  watchGetNextMarketplaceDeals(),
  watchDeleteMarketplaceDeal(),
  watchCreateMarketplaceDeal(),
  watchGetMarketplaceDeal(),
  watchUpdateMarketplaceDeal(),
  watchReorderMarketplaceDeal()
]
