import compose from 'lodash/flowRight'
import {
  FETCH_COMMUNITY_THREAD,
  unattendEventSuccess,
  attendEventSuccess,
  createCommentOptimistcally,
  createCommentRevert,
  createCommentSuccess,
  deleteCommentSuccess,
  DELETE_COMMENT_SUCCESS,
  likePostSuccess,
  unlikePostSuccess
} from '../actions'
import withFetch from '../../helpers/reducers/withFetch'

const initialState = null

const communityThread = (state = initialState, action) => {
  switch (action.type) {
    case unattendEventSuccess().type:
      return {
        ...state,
        rsvps: state.rsvps.filter(user => user.user_id !== action.userId)
      }

    case attendEventSuccess().type:
      return {
        ...state,
        rsvps: [
          ...state.rsvps,
          { user_id: action.userId, name: action.name, picture: action.picture }
        ]
      }

    case unlikePostSuccess().type:
      return {
        ...state,
        likes: state.likes - 1,
        likes_by: state.likes_by.filter(user => user.user_id !== action.userId)
      }

    case likePostSuccess().type:
      return {
        ...state,
        likes: state.likes + 1,
        likes_by: [
          ...state.likes_by,
          { user_id: action.userId, name: action.name, picture: action.picture }
        ]
      }

    case createCommentSuccess().type:
      return {
        ...state,
        replies: action.payload
      }

    case createCommentOptimistcally().type:
      return {
        ...state,
        replies: [...state.replies, action.reply]
      }

    case createCommentRevert().type:
      return {
        ...state,
        replies: [...action.revertChanges.replies]
      }

    case deleteCommentSuccess().type:
      return {
        ...state,
        replies: state.replies.filter(reply => reply.key !== action.commentKey)
      }

    default:
      return state
  }
}

export default compose(withFetch(FETCH_COMMUNITY_THREAD))(communityThread)
