// @flow

import React from 'react'
import queryString from 'query-string'
import ScheduleSettings from './ScheduleSettings'
import ScheduleTypes from './ScheduleTypes'
import FilterSection from '../../../Common/FilterSection'
import type { TabsType } from '../../../Common/FilterSection/types'

import type { ScheduleProps, SwitchScheduleActiveFn } from './types'

const tabs: TabsType = [
  {
    name: 'Type',
    query: { mode: 'type' }
  },
  {
    name: 'Settings',
    query: { mode: 'settings' }
  }
]

export default class Schedule extends React.Component<ScheduleProps, {}> {
  selectSchedule = (scheduleType: string) => {
    const {
      hasSchedule,
      scheduleId,
      unitId,
      createNewSchedule,
      changeScheduleTemplate
    } = this.props
    if (!hasSchedule) {
      createNewSchedule(scheduleType, unitId)
    } else {
      changeScheduleTemplate(scheduleType, unitId, scheduleId)
    }
  }

  switchScheduleActive: SwitchScheduleActiveFn = (_e, checked) => {
    const { updateScheduleEnabled } = this.props
    updateScheduleEnabled(checked)
  }

  render() {
    const {
      location,
      unitType,
      scheduleInfo,
      scheduleState,
      hasSchedule,
      updateSetpoints,
      updateMode
    } = this.props
    const readOnly = unitType !== 'common'
    const { name, enabled, home, away } = scheduleInfo
    const { mode } = queryString.parse(location.search)

    return (
      <React.Fragment>
        {mode === 'settings' ? (
          <ScheduleSettings
            enabled={enabled}
            name={name}
            scheduleState={scheduleState}
            unitType={unitType}
            readOnly={readOnly}
            home={home}
            away={away}
            hasSchedule={hasSchedule}
            switchScheduleActive={this.switchScheduleActive}
            updateMode={updateMode}
            updateSetpoints={updateSetpoints}
          />
        ) : (
          <ScheduleTypes
            selectSchedule={this.selectSchedule}
            selectedScheduleType={name}
            readOnly={readOnly}
          />
        )}

        <FilterSection tabs={tabs} />
      </React.Fragment>
    )
  }
}
