import { createFetchActions } from '../../helpers/actions/creators'

export const DEVICE_HISTORY_SECTION = 'DEVICEHISTORY'

export const {
  fetchAction,
  fetchStartAction: fetchDeviceHistoryStart,
  fetchSuccessAction: fetchDeviceHistorySuccess,
  fetchErrorAction: fetchDeviceHistoryError
} = createFetchActions(DEVICE_HISTORY_SECTION)

export const fetchDeviceHistory = (unitId, types, page = 1) =>
  fetchAction({ unitId, types, page })
