// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import TopNavigation from '../../../Common/TopNavigation'
import ChatCarousel from './Carousel'
import ChatListItem from './ChatListItem'
import type { Node } from 'react'
import type { Theme } from '../../../../theme'
import {
  ChatMenuItem,
  ChatMenu,
  ChatMenuList,
  FilterChatItem,
  MainHeading,
  Underline,
  Conversations
} from './styles'

const isTrue = 1

type Props = {
  channels: Array<any>,
  theme: Theme,
  newCount: number,
  match: { params: { userId: string, propertyId: string } },
  setActiveChannelByUrl: (url: string) => void,
  fetchChannelMessageByUrl: (url: string) => void,
  history: Object,
  activeChannelUrl: string,
  unreadCount: number,
  filter: string,
  pinnedChannels: Array<Object>,
  pinChannel: string => mixed,
  unpinChannel: string => mixed
}

const numberOfItemsPerSlide = () => {
  if (window.innerHeight < 700) return 6
  if (window.innerHeight >= 1440) return 13
  return 8
}

const itemHeight = window.innerHeight > 900 ? 64 : 62

export class ChatListDrawer extends React.Component<Props, {}> {
  // when we click a channel we push to history and change the route
  // when the component updates we need to select the new channel and fetch the new channels
  // we have to do it in componentDidUpdate so it also works for search
  componentDidUpdate = ({ match: { params: previousParams } }: Props) => {
    const {
      setActiveChannelByUrl,
      fetchChannelMessageByUrl,
      channels,
      match: { params }
    } = this.props

    if (previousParams.userId !== params.userId) {
      const channel = channels.find(channel => channel.userId === params.userId)
      // new user id from search isn't in the first 100 that we fetch from sendbird
      // chatWindow will refetch the channels so no need to update here
      if (channel && channel.url) {
        setActiveChannelByUrl(channel.url)
        fetchChannelMessageByUrl(channel.url)
      }
    }
  }

  handleChatMenuChange = (url: string, userId: string) => {
    const {
      history,
      match: { params },
      filter
    } = this.props
    filter
      ? history.push(
          `/properties/${
            params.propertyId
          }/communication/chat/live-chat/users/${userId}?filter=${filter}`
        )
      : history.push(
          `/properties/${
            params.propertyId
          }/communication/chat/live-chat/users/${userId}`
        )
  }

  getNoItemMessage = (): string => {
    const { filter } = this.props
    const mostRecent = 'most recent'
    return `no ${filter || mostRecent} messages`
  }

  getListItems = (): Array<Node> => {
    const {
      channels,
      activeChannelUrl,
      pinnedChannels,
      pinChannel,
      unpinChannel
    } = this.props
    return channels.map(
      ({
        url,
        shouldShowUnreadIndicator,
        profileUrl,
        name,
        lastMessageInfoText,
        lastMessage,
        userId
      }) => (
        <ChatListItem
          height={`${itemHeight}px`}
          isSelected={url === activeChannelUrl}
          shouldShowUnreadIndicator={shouldShowUnreadIndicator}
          profileUrl={profileUrl}
          name={name}
          lastMessageInfoText={lastMessageInfoText}
          lastMessage={lastMessage}
          userId={userId}
          url={url}
          handleChatMenuChange={this.handleChatMenuChange}
          isPinned={pinnedChannels.includes(url)}
          pinChannel={pinChannel}
          unpinChannel={unpinChannel}
        />
      )
    )
  }

  render() {
    const {
      match: {
        params: { propertyId }
      },
      unreadCount
    } = this.props
    const listItems = this.getListItems()
    return (
      <ChatMenu
        anchor="bottom"
        id="chat-drawer"
        variant="permanent"
        classes={{
          paper: 'drawerpaper'
        }}>
        <ChatMenuList>
          <ChatMenuItem
            isChatHeader={isTrue}
            disableRipple
            id="chat-toggle-button">
            <div>
              <MainHeading variant="title" gutterBottom>
                <Conversations>Conversations</Conversations>
              </MainHeading>
              <Underline />
            </div>
          </ChatMenuItem>
          <FilterChatItem isChatHeader={isTrue} disableRipple>
            <TopNavigation
              tabs={[
                {
                  name: 'Most Recent',
                  route: `/properties/${propertyId}/communication/chat/live-chat`
                },
                {
                  name: 'Unread',
                  route: `/properties/${propertyId}/communication/chat/live-chat`,
                  query: { filter: 'unread' },
                  className: unreadCount > 0 ? 'unread': ''
                },
                {
                  name: 'Pinned',
                  route: `/properties/${propertyId}/communication/chat/live-chat`,
                  query: { filter: 'pinned' }
                }
              ]}
            />
          </FilterChatItem>
          <ChatCarousel
            itemHeight={itemHeight}
            items={listItems}
            transitionHeights={['0em', '1em']}
            numberOfItemsPerSlide={numberOfItemsPerSlide()}
            lastSlideNumber={Math.ceil(
              listItems.length / numberOfItemsPerSlide()
            )}
            noItemMessage={this.getNoItemMessage()}
          />
        </ChatMenuList>
      </ChatMenu>
    )
  }
}

export default withTheme(ChatListDrawer)
