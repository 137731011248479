import { call, select, put, takeLatest, race, take } from 'redux-saga/effects'
import isArray from 'lodash/isArray'
import { getPropertyId } from '../../select/selectors'
import {
  getToken,
  getUserId,
  getUserName,
  getUserProfilePicture
} from '../../authorization/selectors'

import {
  genericErrorMsg,
  deleteCommunityThreadConfirmation,
  communityThreadNonExistent,
  commentDeletedConfirmation
} from '../../../utils/messages'
import log from '../../../utils/logger'
import api from '../../../utils/api'
import {
  deleteCommunityThread,
  fetchCommunityThread,
  fetchCommunityThreadError,
  fetchCommunityThreadSuccess,
  fetchCommunityThreadStart,
  attendEvent,
  unattendEvent,
  unattendEventSuccess,
  attendEventSuccess,
  createComment,
  createCommentOptimistcally,
  createCommentSuccess,
  createCommentRevert,
  deleteComment,
  deleteCommentSuccess,
  unlikePost,
  unlikePostSuccess,
  likePost,
  likePostSuccess
} from '../actions'
import { showSnackbar } from '../../snackbar/actions'
import { getProfileUrlOrDefault } from '../../../utils/profilePicture'
import { getReplies } from '../selectors'

export function* deleteCommunityThreadSaga({ threadId, threadType, history }) {
  try {
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)

    yield call(api.deleteCommunityThread, authToken, propertyId, threadId)
    yield put(showSnackbar(deleteCommunityThreadConfirmation, 'success'))

    history.push(
      `/properties/${propertyId}/communication/community-feed/${threadType}`
    )
  } catch (error) {
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

function* watchDeleteCommunityThread() {
  yield takeLatest(deleteCommunityThread().type, deleteCommunityThreadSaga)
}

export function* fetchCommunityThreadSaga({ threadId }) {
  try {
    yield put(fetchCommunityThreadStart())
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)

    const response = yield call(
      api.getCommunityThread,
      authToken,
      propertyId,
      threadId
    )

    if (isArray(response) && !response.length) {
      yield put(showSnackbar(communityThreadNonExistent, 'error'))
      yield put(fetchCommunityThreadSuccess(null))
    } else {
      yield put(fetchCommunityThreadSuccess(response))
    }
  } catch (error) {
    yield put(showSnackbar(genericErrorMsg, 'error'))
    yield put(fetchCommunityThreadError(error))
  }
}

function* watchFetchCommunityThread() {
  yield takeLatest(fetchCommunityThread().type, fetchCommunityThreadSaga)
}

export function* attendEventSaga({ threadId }) {
  try {
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)
    const userId = yield select(getUserId)
    const name = yield select(getUserName)
    const picture = yield select(getUserProfilePicture)

    yield call(api.attendEvent, authToken, propertyId, threadId)
    yield put(attendEventSuccess(userId, name, picture))
  } catch (error) {
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

export function* watchAttendEvent() {
  yield takeLatest(attendEvent().type, attendEventSaga)
}

export function* unattendEventSaga({ threadId }) {
  try {
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)
    const userId = yield select(getUserId)

    yield call(api.unattendEvent, authToken, propertyId, threadId)
    yield put(unattendEventSuccess(userId))
  } catch (error) {
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

export function* watchUnattendEvent() {
  yield takeLatest(unattendEvent().type, unattendEventSaga)
}
export function* createCommentSaga({ threadId, comment }) {
  if (typeof comment === 'string' && comment.trim()) {
    let currentReplies
    try {
      currentReplies = yield select(getReplies)
      const authToken = yield select(getToken)
      const propertyId = yield select(getPropertyId)
      const picture = yield select(getUserProfilePicture)
      const name = yield select(getUserName)
      const reply = {
        body: comment,
        inserted_at: new Date().toISOString(),
        user: { name, picture: getProfileUrlOrDefault(picture, name) },
        key: comment
      }
      yield put(createCommentOptimistcally({ reply }))
      const { replies } = yield call(
        api.createCommunityThreadComment,
        authToken,
        propertyId,
        comment,
        threadId
      )
      yield put(createCommentSuccess(currentReplies.concat(replies[0])))
    } catch (error) {
      yield put(createCommentRevert({ replies: currentReplies }))
      yield put(showSnackbar(genericErrorMsg, 'error'))
    }
  }
}

export function* watchCreateComment() {
  yield takeLatest(createComment().type, createCommentSaga)
}

export function* deleteCommentSaga({ threadId, commentKey }) {
  try {
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)

    const commentUrl = `${window.atob(threadId)}/${commentKey}`

    yield call(
      api.deleteCommunityThreadComment,
      authToken,
      propertyId,
      window.btoa(commentUrl)
    )

    yield put(showSnackbar(commentDeletedConfirmation, 'success'))
    yield put({ type: deleteCommentSuccess().type, commentKey })
  } catch (error) {
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

export function* watchDeleteComment() {
  yield takeLatest(deleteComment().type, deleteCommentSaga)
}

export function* unlikePostSaga({ threadId }) {
  try {
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)
    const userId = yield select(getUserId)

    yield call(api.unlikePost, authToken, propertyId, threadId)
    yield put(unlikePostSuccess(userId))
  } catch (error) {
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

export function* watchUnlikePost() {
  yield takeLatest(unlikePost().type, unlikePostSaga)
}

export function* likePostSaga({ threadId }) {
  try {
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)
    const userId = yield select(getUserId)
    const name = yield select(getUserName)
    const picture = yield select(getUserProfilePicture)

    yield call(api.likePost, authToken, propertyId, threadId)
    yield put(likePostSuccess(userId, name, picture))
  } catch (error) {
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

export function* watchLikePost() {
  yield takeLatest(likePost().type, likePostSaga)
}

export default [
  watchDeleteCommunityThread(),
  watchFetchCommunityThread(),
  watchAttendEvent(),
  watchUnattendEvent(),
  watchCreateComment(),
  watchDeleteComment(),
  watchUnlikePost(),
  watchLikePost()
]
