import styled from '@emotion/styled'

export const Container = styled('div')`
  height: 100%;
  width: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 0px 0px;
  ${({ orientation }) => {
  if (orientation === 'column') {
    return `
        grid-template-columns: repeat(3, 1fr); 
        grid-template-rows: .1fr repeat(3, 1fr) .1fr;
        grid-template-areas:       
          "prev-start prev prev"
          "content content content"
          "content content content"
          "content content content"
          "next-start next next-end";
      `;
  }
  return `
      grid-template-areas: 
        "prev-start content content content next-start"
        "prev content content content next"
        "prev-end content content content next-end";

      grid-template-columns: .1fr repeat(3, 1fr) .1fr; 
      grid-template-rows: repeat(3, 1fr); 
      `;
}}
`;

export const Content = styled('div')`
  grid-area: content;
  height: 100%;
  width: 100%;

  display: grid;
  overflow: hidden;
  width: 100%;
  height: 100%;
  ${({ orientation, numItemsPerSlide: numItems }) => {
  if (orientation === 'column') {
    return `
        grid-auto-flow: row;
        grid-auto-rows: ${numItems ? (1 / numItems) * 100 : 25}%;
        gap: 2% 0;
      `;
  }
  return `
      grid-auto-flow: column;
      grid-auto-columns: ${numItems ? (1 / numItems) * 100 : 25}%;
      gap: 0 2%;
    `;
}};
`;

export const ScrollButtonContainer = styled('div')`
  cursor: pointer;
  opacity: 0.5;
  grid-area: ${({ prev, position }) => {
  if (position === 'start' || position === 'end') {
    return `${prev ? 'prev-' : 'next-'}` + position;
  }
  return prev ? 'prev' : 'next';
  }};
  
  :hover { 
    opacity: 0.9; 
  }
`;
