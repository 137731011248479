// @flow

import React from 'react'
import SetupStep from './SetupStep'
import { SetupBody, Select } from './styles'
import type { DeviceProfilesType, SetupStepProps } from './types'

const getDeviceTypes = (deviceProfiles: DeviceProfilesType) =>
  deviceProfiles.map(({ key, name }) => ({ value: key, label: name }))

export default class ChooseDevice extends React.Component<SetupStepProps> {
  handleChange = ({ value }: { value: string }) => {
    const { handleDataChange } = this.props
    handleDataChange({ selectedDeviceProfileID: value }, false)
  }

  render() {
    const { deviceProfiles } = this.props
    return (
      <SetupStep title="CHOOSE A DEVICE">
        <SetupBody>
          <Select
            onChange={this.handleChange}
            options={getDeviceTypes(deviceProfiles)}
          />
        </SetupBody>
      </SetupStep>
    )
  }
}
