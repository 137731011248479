import { combineReducers } from 'redux'
import summary from 'zego-shared/store/users/propertyUsersSummary/reducers'
import profile from 'zego-shared/store/users/profile/reducers'

const users = combineReducers({
  summary,
  profile
})

export default users
