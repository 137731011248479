import compose from 'lodash/flowRight'
import { combineReducers } from 'redux'
import withFetch from '../../../helpers/reducers/withFetch'
import { setActiveChannel } from '../actions'

const initialState = null

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setActiveChannel().type: {
      const { channel } = action
      if (channel) {
        channel.unreadMessageCount = 0
        channel.markAsRead()
        return channel
      }
      return state
    }
    default:
      return state
  }
}

export default reducer
