// @flow
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect, Switch } from 'react-router-dom'
import { getSmartRoutes } from './Smart'
import { SummaryRoutes } from './Summary'
import { getCommunicationRoutes } from './Communication'
import { getUsersRoutes } from './Users'
import { getSettingsRoutes } from './Settings'
import { getPackagesRoutes } from './Packages'
import { getRegistrationRoutes } from './Registration'
import { getLockCodesRoutes } from './LockCodes'
import { getAmenitiesRoutes } from './Amenities'
import { getMarketplaceRoutes } from './Marketplace'
import { getAuthRoutes } from './Auth'
import { getBranchRoutes } from './BranchIO'
import { getWorkOrderRoutes } from './WorkOrder'
import { getResidentRoutes } from './Residents'
import PrivateRoute from './PrivateRoute'

import { STANDALONE_WORKORDER_FEATUREFLAG } from '../views/WorkOrder/Constants'
import { RESIDENT_MANAGEMENT_FEATURE_FLAG } from '../views/Residents/constants'
import { AMENITY_RESERVATION_FEATUREFLAG } from '../views/Amenities/constants'
import { getFeatureFlag } from '../integrations/LaunchDarkly/selectors'
import { ldInitRequest } from '../integrations/LaunchDarkly/actions'
import { useIdleTimer } from 'react-idle-timer'
import { deauth } from 'zego-shared/store/authorization/actions'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'

type Props = {
  featureFlag: Object,
  ldInitRequest: Function,
  logOut: Function,
  showSnackbar: Function
}

const mapStateToProps = state => ({
  featureFlag: getFeatureFlag(state)
})

const mapDispatchToProps = {
  ldInitRequest,
  logOut: deauth,
  showSnackbar
}

const Routes = (props: Props) => {
  const { featureFlag, ldInitRequest, logOut, showSnackbar } = props
  const [showStandAloneWorkOrder, setShowStandAloneWorkOrder] = useState(false)
  const [showResidentManagement, setShowResidentManagement] = useState(false)
  const [showAmenityReservation, setshowAmenityReservation] = useState(false)
  const timeOutMessage = 'Your session has expired. Please login again for continued access.'
  const timeOutTime = 1000 * 60 * 10
  useEffect(() => {
    setShowStandAloneWorkOrder(featureFlag && featureFlag.ld && featureFlag.ld.variation(STANDALONE_WORKORDER_FEATUREFLAG, false))
    setShowResidentManagement(featureFlag && featureFlag.ld && featureFlag.ld.variation(RESIDENT_MANAGEMENT_FEATURE_FLAG, false))
    setshowAmenityReservation(featureFlag && featureFlag.ld && featureFlag.ld.variation(AMENITY_RESERVATION_FEATUREFLAG, false))
  }, [featureFlag])

  useEffect(() => {
    ldInitRequest()
  }, [ldInitRequest])

  const handleOnIdle = () => {
    logOut()
    showSnackbar(timeOutMessage, 'warning')
  }

  useIdleTimer({
    timeout: timeOutTime,
    onIdle: () => handleOnIdle(),
    debounce: 500
  })

  return (
    <Switch>
      <PrivateRoute exact path="/" component={() => <Redirect to="/summary" />} />
      {getAuthRoutes()}
      {getBranchRoutes()}
      {getSmartRoutes()}
      {getCommunicationRoutes()}
      {getUsersRoutes()}
      {getSettingsRoutes()}
      {getPackagesRoutes()}
      {getRegistrationRoutes()}
      {getLockCodesRoutes()}
      {getAmenitiesRoutes(showAmenityReservation)}
      {showResidentManagement && getResidentRoutes()}
      {(showStandAloneWorkOrder) && (
        getWorkOrderRoutes()
      )}
      {getMarketplaceRoutes()}
      <SummaryRoutes />
    </Switch>
  )
}

export { Routes }

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
