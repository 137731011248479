// @flow
import React from 'react'
import BaseCard from '../BaseCard'
import SummaryHeader from './SummaryCardHeader'
import SummaryContent from './SummaryCardContent'
import type { SummaryCardProps } from './types'

const SummaryCard = ({
  captionHeading,
  mainHeading,
  unitCount,
  countText,
  filterNames,
  items,
  headerTabs = [],
  showShadow,
  isFetching,
  hideSummaryCount,
  autoHeight,
  imageSrc,
  overflow,
  SummaryHeaderRightPanelChildren
}: SummaryCardProps) => {
  return (
    <BaseCard
      columnStart={1}
      columnSpan={10}
      rowStart={1}
      rowSpan={11}
      overflow={overflow}
      showShadow={showShadow}
      underlineLength={'80px'}
      toggleFooterOnMouseOver
      header={() => (
        <SummaryHeader
          unitCount={unitCount}
          captionHeading={captionHeading}
          mainHeading={mainHeading}
          headerTabs={headerTabs}
          RightPanelChildren={SummaryHeaderRightPanelChildren}
        />
      )}
      content={() => (
        <SummaryContent
          filterNames={filterNames}
          items={items}
          countText={countText}
          isFetching={isFetching}
          hideSummaryCount={hideSummaryCount}
          autoHeight={autoHeight}
          imageSrc={imageSrc}
        />
      )}
    />
  )
}

export default SummaryCard
