import isPlainObject from 'lodash/isPlainObject'
import { call, select, takeLatest, put } from 'redux-saga/effects'
import { hasChanges } from '../../../utils/object'
import { getToken } from '../../authorization/selectors'
import { getPropertyId } from '../../select/selectors'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import { showSnackbar } from '../../snackbar/actions'
import { failedToUpdatePet } from '../../../utils/messages'
import {
  fetchPetsAction,
  fetchPetsStart,
  fetchPetsSuccess,
  fetchPetsError,
  fetchPetAction,
  fetchPetStart,
  fetchPetSuccess,
  fetchPetError,
  fetchPetsColorsAction,
  fetchPetsColorsStart,
  fetchPetsColorsSuccess,
  fetchPetsColorsError,
  fetchPetsSizesAction,
  fetchPetsSizesStart,
  fetchPetsSizesSuccess,
  fetchPetsSizesError,
  fetchPetsStatusesAction,
  fetchPetsStatusesStart,
  fetchPetsStatusesSuccess,
  fetchPetsStatusesError,
  fetchPetsTypesAction,
  fetchPetsTypesStart,
  fetchPetsTypesSuccess,
  fetchPetsTypesError,
  updatePetOptimistically,
  updatePetRevert,
  updatePetSuccess,
  UPDATE_PET
} from './actions'
import { getPet, petForApi } from './selectors'

export function* fetchPets({ propertyId, page, searchTerm, queryParams }) {
  try {
    yield put(fetchPetsStart())
    const authToken = yield select(getToken)
    let response
    if (searchTerm != null) {
      response = yield call(
        api.searchRegisteredPetsOfProperty,
        authToken,
        propertyId,
        searchTerm,
        queryParams
      )
    } else {
      response = yield call(
        api.getRegisteredPetsOfProperty,
        authToken,
        propertyId,
        page,
        queryParams
      )
    }

    yield put(fetchPetsSuccess(response))
  } catch (error) {
    yield put(fetchPetsError())
    log(`Failed to fetch pets registration`)
  }
}

export function* fetchPet({ petId }) {
  try {
    yield put(fetchPetStart())
    const authToken = yield select(getToken)
    const response = yield call(api.getPet, authToken, petId)

    yield put(fetchPetSuccess(response))
  } catch (error) {
    yield put(fetchPetError(error))
    log(`Failed to fetch pet`)
  }
}

export function* fetchPetsColors({ propertyId }) {
  try {
    yield put(fetchPetsColorsStart())
    const authToken = yield select(getToken)
    const response = yield call(
      api.getPetsSupportedColors,
      authToken,
      propertyId
    )

    yield put(fetchPetsColorsSuccess(response))
  } catch (error) {
    yield put(fetchPetsColorsError(error))
    log(`Failed to fetch pets colors`)
  }
}

export function* fetchPetsSizes({ propertyId }) {
  try {
    yield put(fetchPetsSizesStart())
    const authToken = yield select(getToken)
    const response = yield call(
      api.getPetsSupportedSizes,
      authToken,
      propertyId
    )

    yield put(fetchPetsSizesSuccess(response))
  } catch (error) {
    yield put(fetchPetsSizesError(error))
    log(`Failed to fetch pets sizes`)
  }
}

export function* fetchPetsStatuses({ propertyId }) {
  try {
    yield put(fetchPetsStatusesStart())
    const authToken = yield select(getToken)
    const response = yield call(
      api.getPetsSupportedStatuses,
      authToken,
      propertyId
    )

    yield put(fetchPetsStatusesSuccess(response))
  } catch (error) {
    yield put(fetchPetsStatusesError(error))
    log(`Failed to fetch pets statuses`)
  }
}

export function* fetchPetsTypes({ propertyId }) {
  try {
    yield put(fetchPetsTypesStart())
    const authToken = yield select(getToken)
    const response = yield call(
      api.getPetsSupportedTypes,
      authToken,
      propertyId
    )

    yield put(fetchPetsTypesSuccess(response))
  } catch (error) {
    yield put(fetchPetsTypesError(error))
    log(`Failed to fetch pets types`)
  }
}

export function* updatePet({ update: newAtts, history, url, isNewPet }) {
  const currentState = isNewPet ? {} : yield select(getPet)
  let currentAtts = {}
  let attsToUpdate = {}
  for (let key in newAtts) {
    if (
      (!isPlainObject(newAtts[key]) && newAtts[key] !== currentState[key]) ||
      (isPlainObject(newAtts[key]) &&
        hasChanges(newAtts[key], currentState[key]))
    ) {
      attsToUpdate[key] = newAtts[key]
      currentAtts[key] = currentState[key]
    }
  }
  if (Object.keys(attsToUpdate).length > 0) {
    const authToken = yield select(getToken)
    try {
      yield put(updatePetOptimistically(petForApi(newAtts)))
      let response
      if (currentState.id == null) {
        const propertyId = yield select(getPropertyId)
        response = yield call(
          api.createPet,
          authToken,
          propertyId,
          petForApi(attsToUpdate)
        )
      } else {
        response = yield call(
          api.updatePet,
          authToken,
          currentState.id,
          petForApi(attsToUpdate)
        )
      }

      yield put(updatePetSuccess(response))
      const addMode = url.indexOf('/new') > -1
      if (addMode && response.id != null) {
        const urlPrefix = url.split('/new')[0]
        history.push(`${urlPrefix}/${response.id}`)
      }
    } catch (error) {
      log(`Failed to update pet. Error: ${error}`)
      yield put(updatePetRevert(petForApi(currentState)))
      yield put(showSnackbar(failedToUpdatePet, 'error'))
    }
  }
}

function* watchUpdatePet() {
  yield takeLatest(UPDATE_PET, updatePet)
}

function* watchFetchPets() {
  yield takeLatest(fetchPetsAction().type, fetchPets)
}

function* watchFetchPet() {
  yield takeLatest(fetchPetAction().type, fetchPet)
}
function* watchFetchPetsColors() {
  yield takeLatest(fetchPetsColorsAction().type, fetchPetsColors)
}

function* watchFetchPetsSizes() {
  yield takeLatest(fetchPetsSizesAction().type, fetchPetsSizes)
}

function* watchFetchPetsStatuses() {
  yield takeLatest(fetchPetsStatusesAction().type, fetchPetsStatuses)
}

function* watchFetchPetsTypes() {
  yield takeLatest(fetchPetsTypesAction().type, fetchPetsTypes)
}

export default [
  watchFetchPets(),
  watchFetchPet(),
  watchFetchPetsColors(),
  watchFetchPetsSizes(),
  watchFetchPetsStatuses(),
  watchFetchPetsTypes(),
  watchUpdatePet()
]
