import React from 'react'
import { Route } from 'react-router-dom'
import VendorsContainer from '../../views/LockCodes/Vendors/VendorsContainer'
import PropertyContainer from '../../views/LockCodes/Property/PropertyContainer'
import ResidentsContainer from '../../views/LockCodes/Residents/ResidentsContainer'


const LockCodesTabRoutes = () => (
  <React.Fragment>
    <Route
      exact
      path="/properties/:propertyId/lock-codes"
      component={VendorsContainer}
    />
    <Route
      exact
      path="/properties/:propertyId/lock-codes/vendors"
      component={VendorsContainer}
    />
    <Route
      exact
      path="/properties/:propertyId/lock-codes/property"
      component={PropertyContainer}
    />
    <Route
      path="/properties/:propertyId/lock-codes/residents"
      component={ResidentsContainer}
    />
  </React.Fragment>
)

export default LockCodesTabRoutes
