// @flow

import React, { Component, Fragment } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import LiveAgent from './integrations/LiveAgent'
import Pendo from './integrations/Pendo'

export default class App extends Component<{}> {
  render() {
    return (
      <Fragment>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
        <LiveAgent />
        <Pendo />
      </Fragment>
    )
  }
}
