import withFetchReducer from '../../helpers/reducers/withFetch'
import {
  SECTION,
  updateScheduleActions,
  updateScheduleEnabledActions
} from '../actions'

const withFetch = withFetchReducer(SECTION)

const updateSchedule = (state, action) => ({
  ...state,
  ...action.update,
  ...action.payload
})

const schedules = (state = {}, action) => {
  switch (action.type) {
    case `${updateScheduleActions.updateSuccessAction().type}`:
    case `${updateScheduleActions.updateOptimistcallyAction().type}`:
    case `${updateScheduleActions.updateRevertAction().type}`:
      return updateSchedule(state, action)
    case `${updateScheduleEnabledActions.updateOptimistcallyAction().type}`:
    case `${updateScheduleEnabledActions.updateRevertAction().type}`:
      return updateSchedule(state, { update: { enabled: action.enabled } })
    default:
      return state
  }
}

export default withFetch(schedules)
