import get from 'lodash/get'
import has from 'lodash/has'
import isEqual from 'lodash/isEqual'
import { eventCodes } from '../../../socket/sagas'

export const isResponseForLockStatus = (deviceIdentifier) => event => {
  return true
}

export const isCodeCreatedEvent = (deviceIdentifier, codeType) => event => {
  const { event_code, meta_data } = event
  const identifier = get(meta_data, 'device_address', -1)
  const type = get(meta_data, 'resource_code_type', '')

  return (
    identifier === deviceIdentifier &&
    event_code === eventCodes.LOCK_CODE_CREATED &&
    type === codeType
  )
}

export const isCodeEditedEvent = (deviceIdentifier, codeId) =>
  isCodeEvent(deviceIdentifier, codeId, eventCodes.LOCK_CODE_EDITED)

export const isCodeDeletedEvent = (deviceIdentifier, codeId) =>
  isCodeEvent(deviceIdentifier, codeId, eventCodes.LOCK_CODE_DELETED)

const isCodeEvent = (deviceIdentifier, codeId, eventCode) => event => {
  const { event_code, meta_data } = event
  const identifier = get(meta_data, 'device_address', -1)
  const id = get(meta_data, 'resource_code_id', -1)

  return (
    identifier === deviceIdentifier && event_code === eventCode && id === codeId
  )
}

const unitCodeTypes = ['unit_primary', 'unit_temporary', 'unit_setup', 'master',]

export const resourceTypeFromCodeType = codeType => unitCodeTypes.includes(codeType) ? 'unit' : 'property'

export const isLockCodeStatus = command => response => {
  const originalCommand = get(response, ['response', 'original_command'])
  return isEqual(command, originalCommand)
}

export const isCommandResponse = tag => event => {
  const eventTag = get(event, ['response', 'tag'], '')
  const isCommandResponse = has(event, ['response', 'original_command'])
  return (
    eventTag === tag &&
    isCommandResponse
  )
}

export const isExecutedCommandResponse = tag => event => {
  const eventTag = get(event, ['response', 'tag'], '')
  return eventTag === tag
}

export const extractCodeInfo = response => {
  const { code, confirmed, expiration, resource_code_id, type } = response

  return {
    code,
    confirmed,
    expiration,
    resource_code_id,
    type
  }
}
