import {
  fetchResidentFilteredCountError,
  fetchResidentFilteredCountStart,
  fetchResidentFilteredCountSuccess,
  fetchResidentTotalCountStart,
  fetchResidentTotalCountSuccess,
  fetchResidentTotalCountError
} from '../actions'

const initialState = {
  isFetchingCount: false,
  filteredResidentCount: 0,
  totalResidentCount: 0,
  issueCount: 0,
  noIssueCount: 0,
  awaitingApprovalCount: 0,
  totalApprovalStatusCount: 0
}
const getResidentCounts = (state = initialState, { type, payload }) => {

  switch (type) {
    case fetchResidentFilteredCountStart().type:
      return {
        ...state,
        isFetchingCount: true
      }
    case fetchResidentFilteredCountSuccess().type: {
      return {
        ...state,
        isFetchingCount: false,
        filteredResidentCount: payload
      }
    }
    case fetchResidentFilteredCountError().type:
      return {
        ...state,
        isFetchingCount: false
      }
    case fetchResidentTotalCountStart().type:
      return {
        ...state,
        isFetchingCount: true
      }
    case fetchResidentTotalCountSuccess().type: {
      return {
        ...state,
        isFetchingCount: false,
        totalResidentCount: payload.residentCount || 0,
        issueCount: payload.issueCount || 0,
        noIssueCount: payload.noIssueCount || 0,
        awaitingApprovalCount: payload.awaitingApprovalCount || 0,
        totalApprovalStatusCount: payload.totalApprovalStatusCount || 0
      }
    }
    case fetchResidentTotalCountError().type:
      return {
        ...state,
        isFetchingCount: false
      }
    default:
      return state
  }
}
export default getResidentCounts
