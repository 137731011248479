// @flow

import { combineReducers } from 'redux'
import summary from 'zego-shared/store/workOrders/reducers/summaryReducer'
import details from 'zego-shared/store/workOrders/reducers/detailsReducer'
import categories from 'zego-shared/store/workOrders/reducers/categoriesReducer'

const workOrders = combineReducers({
  summary,
  categories,
  details
})

export default workOrders
