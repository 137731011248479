import get from 'lodash/get'
import moment from 'moment'
import { getDeviceById } from './index'

const emptyArr = []
const emptyObj = {}

export const getLockCodePermissions = state =>
  get(state, 'unit.devices.lockCodes.permissions', emptyArr)

export const getLockCodeStatusesForLock = (state, id) =>
  get(state, `unit.devices.lockCodes.statuses[${id}]`, emptyObj)

export const getLockCodesForLock = (state, id) =>
  get(getDeviceById(state, id), 'udf.lock_codes', emptyArr)

export const getGroupedLockCodesForLock = (state, id) => {
  const lockCodes = getLockCodesForLock(state, id)
  const permissions = getLockCodePermissions(state)

  const result = {}

  for (let permission of permissions) {
    const { category, resource_code_type } = permission

    result[category] = {
      type: resource_code_type,
      create: permission.create,
      update: permission.update,
      delete: permission.delete,
      extend: permission.extend,
      codes: lockCodes
        .filter(code => code.resource_code_type === resource_code_type)
        .map(
          ({
            lock_code: lockCode,
            resource_code_id: codeID,
            code_num: codeIndex,
            user: { name: userName } = {},
            sweep_timeout: sweepTimeout,
            expiration,
            created_at: createdAt,
            verified
          }) => ({
            code: lockCode,
            codeID,
            codeIndex,
            userName,
            codeType: resource_code_type,
            canCreate: permission.create,
            canEdit: permission.update,
            canDelete: permission.delete,
            canExtend: permission.extend,
            showEdit: category !== 'temporary',
            expiresAfter:
              category === 'temporary'
                ? getExpiresAfter(sweepTimeout, expiration)
                : null,
            createdAt,
            verified
          })
        )
    }
  }

  return result
}

const getExpiresAfter = (sweepTimeout, expiration) => {
  if (expiration) {
    return moment(expiration).diff(moment.now(), 'seconds')
  }
  return sweepTimeout
}

export const getLockCodeIdentifier = (state, id) =>
  get(getDeviceById(state, id), 'identifier')

export const getLockCodeResyncingStatus = state =>
  get(state, 'unit.devices.lockCodes.resyncingLockCodes', false)
