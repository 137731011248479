// @flow

import React from 'react'
import Btn from '@Common/Button'
import { SocialIcon } from '@icons'
import { Login, BtnText } from './styles'

type Props = {
  onFailure?: (Object, Object) => mixed,
  onSuccess?: (string, Object) => mixed,
  mode: string
}

export default class Google extends React.Component<Props> {
  handleGLogin = (user_obj: {}) => {
    const { onSuccess } = this.props
    onSuccess && onSuccess('google', user_obj)
  }

  handleSocialFailure = (error: {}, details: {}) => {
    const { onFailure } = this.props
    onFailure && onFailure(error, details)
  }

  render() {
    const { mode } = this.props
    return (
      <Login
        clientId={window?._env_?.REACT_APP_GOOGLE_CLIENT_ID}
        scope="profile email https://www.googleapis.com/auth/user.phonenumbers.read"
        tag="div"
        onSuccess={this.handleGLogin}
        onFailure={this.handleSocialFailure}
        style={{}}
        render={renderProps => (
          <Btn onClick={renderProps.onClick} disabled={renderProps.disabled} disableRipple fullWidth variant="raised" left>
            <SocialIcon width="20px" height="15px" name="google" />
            <BtnText>{mode === 'login' ? 'Login' : 'Signup'} with Google</BtnText>
          </Btn>
        )}
        className="google-login pad_bottom m-pad-zero_bottom"/>
    )
  }
}
