import { createFetchActions } from '../helpers/actions/creators'

export const MIMO_WORKORDER_SUBMIT = 'MIMO_WORKORDER_SUBMIT'
export const INTEGRATED_WORKORDER_SUBMIT = 'INTEGRATED_WORKORDER_SUBMIT'

export const {
  fetchAction: fetchMimoWorkOrderSubmitAction,
  fetchStartAction: fetchMimoWorkOrderSubmitStart,
  fetchSuccessAction: fetchMimoWorkOrderSubmitSuccess,
  fetchErrorAction: fetchMimoWorkOrderSubmitError
} = createFetchActions(MIMO_WORKORDER_SUBMIT)

export const {
  fetchAction: fetchIntegratedWorkOrderSubmitAction,
  fetchStartAction: fetchIntegratedWorkOrderSubmitStart,
  fetchSuccessAction: fetchIntegratedWorkOrderSubmitSuccess,
  fetchErrorAction: fetchIntegratedWorkOrderSubmitError
} = createFetchActions(INTEGRATED_WORKORDER_SUBMIT)
