// @flow

import React from 'react'
import AddUserStep from './AddUserStep'
import { AddUserBody, Select } from './styles'
import { getAssignableRoles } from '../../../utils/roleUtils'

import type { AddUserStepProps } from './types'

export default class ChooseRole extends React.Component<AddUserStepProps> {
  handleChange = ({ value }: { value: string }) => {
    const { handleDataChange } = this.props
    handleDataChange({ selectedUserRole: value }, false)
  }

  render() {
    const { userRole, hasPms } = this.props
    return (
      <AddUserStep
        title="SELECT A USER ROLE"
        subtitle="Please select which type of user you would like to create.">
        <AddUserBody>
          <Select
            onChange={this.handleChange}
            options={getAssignableRoles(userRole, hasPms)}
          />
        </AddUserBody>
      </AddUserStep>
    )
  }
}
