import { createFetchActions } from '../../helpers/actions/creators'

export const HISTORY = 'GROUPHISTORY'

export const {
  fetchAction: fetchHistoryAction,
  fetchStartAction: fetchHistoryStart,
  fetchSuccessAction: fetchHistorySuccess,
  fetchErrorAction: fetchHistoryError
} = createFetchActions(HISTORY)

export const fetchHistory = (propertyId, page) =>
  fetchHistoryAction({ propertyId, page })

export const searchHistory = (propertyId, page, searchTerm, queryParams = {}) =>
  fetchHistoryAction({ propertyId, searchTerm, queryParams: { ...queryParams, page } })
