import styled from '@emotion/styled'
import { Chip, withStyles } from '@material-ui/core'
import NoSurveysImg from '../../../../images/no_surveys_taken.png'

export const SurveyCard = styled('div')`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  width: 340px;
  padding: 20px;
  margin-bottom: 40px;
`

export const Header = styled('div')`
  display: flex;
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutrals.warmGray};
  padding: 0 7px 10px 7px;
  margin-bottom: 20px;
  align-items: center;
  height: 40px;

  h1 {
    flex: 1;
    margin: 0;
    font-size: ${({ theme }) => theme.custom.font.size.mdlg};
    font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  }
  
  div span {
    font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  }
`

export const Content = styled('div')`
  padding: 0 7px;
  font-size: ${({ theme }) => theme.custom.font.size.sm};

  div {
    display: flex;
    margin-bottom: 13px;

    .left {
      width: 25%;
      color: ${({ theme }) => theme.palette.primary.navy.midnight};
      font-weight: ${({ theme }) => theme.custom.font.weight.bold};
      margin: 0;
    }
    .right {
      flex: 1;
      color: ${({ theme }) => theme.palette.neutrals.deepGray};
      margin: 0;
    }
  }

  & + & {
    :before {
      display: block;
      border-top: 1px solid ${({ theme }) => theme.palette.neutrals.warmGray};
      margin: 13px 20px;
      content: " ";
    }
  }
`

export const NewIndicator = styled('div')`
  position: absolute;
  margin-left: -12px;
  width: 5px;
  height: 5px;
  background-color: ${({ theme }) => theme.palette.secondary.pink.coral};
  border-radius: 50%;
`

export const NewTag = styled('span')`
  margin-left: 0.5em;
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
`

export const NoSurveys = styled('div')`
  flex: 0 0 139px;
  text-align: right;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  background: url(${NoSurveysImg}) right top no-repeat;
  height: 30px;
  margin-right: -27px;
  padding-right: 20px;
  padding-top: 8px;
`

export const Footer = styled('div')`
  display: flex;
  align-items: flex-end;
`

export const ViewMoreButton = styled('a')`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 0;

  span {
    color: ${({ theme }) => theme.palette.primary.green.seeFoam};
    font-size: ${({ theme }) => theme.custom.font.size.xs};
    text-decoration: underline;
    margin-left: 0.5em;
    white-space: nowrap;
  }
`

export const ContactButtons = styled('div')`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`

export const ContactButton = styled('a')`
  display: flex;
  width: 30px;
  height: 30px;
  border: 1.4px solid ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  cursor: pointer;

  svg {
    margin-left: ${props => props.chat ? '5px' : '0'};
  }
`

export const SeeAllButton = styled('a')`
  display: block;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 13px;
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`

export const SurveyListHeader = styled('div')`
  display: flex;
  align-items: center;

  h1 {
    flex: 1;
    margin-top: 0;
    color: ${({ theme }) => theme.palette.primary.navy.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.xl36};
    font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  }
`

export const SurveyListContent = styled('div')`
  margin-right: 155px;
  padding-bottom: 5em;
`

export const Filters = styled('div')`
  display: flex;
  @media only screen and (max-width: 1600px) {
    flex-direction: column;
  }

  h2 {
    flex: 1;
    margin-top: 0;
    color: ${({ theme }) => theme.palette.primary.navy.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.mdlg};
    font-weight: ${({ theme }) => theme.custom.font.weight.bold};

    :after {
      margin-top: 10px;
      display: block;
      background-color: ${({ theme }) => theme.palette.primary.green.mint};
      width: 50px;
      height: 3px;
      content: " ";
    }
  }
`

export const SurveyListSubHead = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SurveyListAverage = styled('div')`
  padding: 8px 14px;
  background-color: ${({ theme }) => theme.palette.primary.green.iceberg};
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  
  @media only screen and (max-width: 1600px) {
    margin-top: 20px;
  }

  span {
    font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  }
`

export const SelectStyle = {
  control: base => ({
    ...base,
    border: 0,
    background: 'transparent',
    boxShadow: 'none',
    width: 250
  }),
}

export const SurveyListItem = styled('div')`
  margin-top: 50px;
  color: ${({ theme }) => theme.palette.neutrals.deepGray};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};

  div + div {
    margin-top: 12px;
  }

  a {
    text-decoration: underline;
  }
`

export const SurveyListItemHead = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  font-style: italic;
`

const ChipStyles = theme => ({
  root: {
    margin: "15px 10px 5px 0px",
    textTransform: 'capitalize',
    height: "20px",
    fontSize: theme.custom.font.size.sm,
    fontWeight: theme.custom.font.weight.bold
  },
  colorSecondary: {
    border: `1px solid ${theme.palette.primary.green.mint}`,
    backgroundColor: theme.palette.primary.green.iceberg
  },
  colorPrimary: {
    border: `1px solid ${theme.palette.neutrals.mediumGray}`,
    backgroundColo: theme.palette.common.white,
    color: theme.palette.neutrals.mediumGray
  }
})

export const StyledChip = withStyles(ChipStyles)(Chip);
