// @flow
import React from 'react'
import ReSyncButton from '../../Common/ReSyncButton'

type Props = {
  hasPms: Boolean,
  reSyncProperty: Function,
  userToken: string,
  pmsName: string
}

const ReSyncPmsButton = ({
  hasPms,
  reSyncProperty,
  userToken,
  pmsName
}: Props) => {
  if (hasPms && pmsName !== 'paylease') {
    return (
      <ReSyncButton
        hoverText={`re-sync zego with ${pmsName}`}
        onClick={() => reSyncProperty(userToken)}
      />
    )
  }
  return null
}

export default ReSyncPmsButton
