// @flow
import { connect } from 'react-redux'
import queryString from 'query-string'
import { capitalizeFirstLetter } from '../../../../utils'
import { getOnlinePropertyHubs, getOfflinePropertyHubs, getPropertyHubsCounts } from 'zego-shared/store/hubs/selectors'
import HubSmartSummary from './HubSmartSummary'

const validFilters = ['offline', 'online']

const mapStateToProps = (
  state,
  { location: { search }, match: { params } }
) => {
  const searchFilter = queryString.parse(search).filter
  const filter = validFilters.includes(searchFilter) ? searchFilter : 'offline'
  return {
    onlineHubs: getOnlinePropertyHubs(state),
    offlineHubs: getOfflinePropertyHubs(state),
    hubCount: getPropertyHubsCounts(state),
    filter: capitalizeFirstLetter(filter),
    propertyId: params.propertyId
  }
}

export default connect(mapStateToProps)(HubSmartSummary)
