// @flow

import React, { useState } from 'react'

import AmenityDashboardProContainer from './AmenityDashboardProContainer'
import AmenityDashboardLegacyContainer from './AmenityDashboardLegacyContainer'

const AmenityDashboardVersionSelector = (props: Object) => {
  const { isProAmenity, ldInitRequest } = props

  const [ ldCalled, setLdCalled ] = useState(false)
  if (!ldCalled && isProAmenity === undefined) {
    ldInitRequest()
    setLdCalled(true)
  }

  if (isProAmenity === undefined) {
    return null
  }

  const DetailView = isProAmenity 
    ? AmenityDashboardProContainer 
    : AmenityDashboardLegacyContainer

  return <DetailView {...props} />
}

export default AmenityDashboardVersionSelector
