import jwtDecode from 'jwt-decode'

export const isValidToken = token => {
  if (token == null) {
    return false
  }
  const decodedToken = jwtDecode(token)
  if (typeof decodedToken.exp !== 'number') {
    return false
  }
  const current_ts = Math.floor(new Date() / 1000)
  if (!decodedToken.jti || decodedToken.exp < current_ts) {
    return false
  }
  return true
}
