import compose from 'lodash/flowRight'
import { formatPhoneNumber } from 'zego-shared/utils/string'

const userToChatUserData = user => {
  return user
    ? {
        id: user.user_id,
        phoneNumber: formatPhoneNumber(user.phone_number),
        email: user.email,
        picture: user.picture,
        name: user.name,
        balance: user.balance,
        unitName:
          Array.isArray(user.units) && user.units.length > 0
            ? user.units[0].name
            : 'Please select a chat message from left'
      }
    : null
}

export const getUser = state => state.chat.user || null

export const getChatUserData = compose(
  userToChatUserData,
  getUser
)
