// @flow
import React from 'react'
import { Button } from './styles'
import { Link } from 'react-router-dom'

type Props = {
  id?: string,
  text: string,
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => any,
  width?: string,
  background?: string | ((props: any) => string),
  to?: string,
  disabled?: boolean,
  height?: string,
  outlined?: boolean
}

const CardButton = ({
  id,
  text,
  onClick,
  width,
  background,
  to,
  height,
  outlined,
  disabled = false
}: Props) =>
  to ? (
    <Link to={to}>
      <Button
        id={id}
        outlined={outlined}
        data-testid="footer-button"
        onClick={onClick}
        variant="flat"
        width={width}
        height={height}
        background={background}>
        {text}
      </Button>
    </Link>
  ) : (
    <Button
      id={id}
      disabled={disabled}
      data-testid="footer-button"
      onClick={onClick}
      variant="flat"
      width={width}
      height={height}
      outlined={outlined}
      background={background}>
      {text}
    </Button>
  )

export default CardButton
