import styled from '@emotion/styled'
import MaterialListItem from '@material-ui/core/ListItem'
import { FlexItem } from '@Common/Flex'

export const Bio = styled(FlexItem)`
  padding: 1.25em 0em;
  & textarea {
    width: 100%
  }
`

export const ListItem = styled(MaterialListItem)`
  padding: 20px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`

export const CheckboxListItem = styled(MaterialListItem)`
  padding: 0;
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`

export const Name = styled('div')`
  padding: 0px 0px 15px 10px;
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.lg};
`
export const PropertyCodeTitle = styled('div')`
  color: ${({ theme }) => theme.palette.text.primary};
  padding-top: 2em;
  padding-bottom: 1.3625em;
  padding-left: 0.625em;
`

export const PropertyCode = styled('div')`
  color: ${({ theme }) => theme.palette.text.secondary};
  padding-left: 0.625em;
`

export const CreateLink = styled('div')`
  border: 1px solid ${({ theme }) => theme.palette.primary.green.mint};
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  text-align: center;
  line-height: 1em;
  vertical-align: top;

  &:hover {
    cursor: pointer;
  }
`

export const DeleteLink = styled('div')`
  border: 1px solid ${({ theme }) => theme.palette.error.main};
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  text-align: center;
  line-height: 1em;
  vertical-align: top;

  &:hover {
    cursor: pointer;
  }
`

export const AvatarInput = styled('input')`
  display: none;
`

export const Role = styled('div')`
  padding-bottom: 2em;
  padding: 10px;
`

export const CheckList = styled('div')`
  padding-top: 2em;
`
export const Email = styled('div')`
  padding-top: 2.5em;
`
export const Profileimg = styled('img')`
  border-radius: 50%;
`
export const PropertyName = styled('div')`
  padding-bottom: 1em;
  font-size: ${({ theme }) => theme.custom.font.size.lg};
`

export const StaffTitle = styled('div')`
  padding-left: 10px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
`

export const ToolTipWrapper = styled('div')`
  position: absolute;
  margin-top: -0.5em;
`

export const StaffRole = styled('div')`
  padding-left: 10px;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.primary.main};
`
