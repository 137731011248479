// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import SummaryCard from '@Common/Cards/SummaryCard'
import Tooltip from '@Common/Tooltip/Tooltip'
import CarouselUnit from './HubCarouselUnit'
import SummaryBG from '../../../../images/summary_bg.png'
import { getSmartHeaderTabs } from '../smartUtils'
import { State, Props } from '../types'

class HubSmartSummary extends React.Component<Props, State> {
  filtersToDisplay = () => {
    return [
      { name: 'Offline', query: { filter: 'offline' } },
      { name: 'Online', query: { filter: 'online' } }
    ]
  }

  getCarouselRows = (filter) => {
    const { onlineHubs, offlineHubs } = this.props
    let viewableHubs = filter === 'Online' ? onlineHubs : offlineHubs
    return viewableHubs.map((data, index) => (
      <CarouselUnit
        deviceId={data.id}
        hubId={data.id}
        key={index}
        lastUnit={index === viewableHubs.length}
        unitId={data.unitId}
        unitName={data.unitName}
        unitOccupied={data.unitOccupied}
      />
    ))
  }

  maybeGetTooltipAndCountText = (filter) => {
    const tooltip = <Tooltip type="OfflinePropertyHubs" />
    const displayArray = filter === 'Offline' ? [filter, tooltip] : [filter]

    return displayArray.map(uiElement => uiElement)
  }

  render() {
    const { filter, propertyId, hubCount, scheduleEnabled } = this.props

    return (
      <React.Fragment>
        <SummaryCard
          headerTabs={getSmartHeaderTabs(propertyId, '', scheduleEnabled)}
          captionHeading="ZEGO SMART"
          mainHeading="Hubs"
          filterNames={this.filtersToDisplay()}
          unitCount={hubCount}
          countText={this.maybeGetTooltipAndCountText(filter)}
          items={this.getCarouselRows(filter)}
          imageSrc={SummaryBG}
          overflow="visible"
        />
      </React.Fragment>
    )
  }
}

export default withTheme(HubSmartSummary)
