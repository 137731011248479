import {
  fetchMimoChecklistPdfStart,
  fetchMimoChecklistPdfSuccess,
  fetchMimoChecklistPdfError
} from '../actions'

const initialState = {
  isSubmittingPdf: false,
  isError: ''
}
const getChecklistPdf = (state = initialState, { type, payload, error }) => {

  switch (type) {
    case fetchMimoChecklistPdfStart().type:
      return {
        ...state,
        isSubmittingPdf: true
      }
    case fetchMimoChecklistPdfSuccess().type: {
      return {
        ...state,
        isSubmittingPdf: false,
        isError: ''
      }
    }
    case fetchMimoChecklistPdfError().type:
      return {
        ...state,
        isSubmittingPdf: false,
        isError: error
      }
    default:
      return state
  }
}
export default getChecklistPdf
