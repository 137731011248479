import { select, put, call, takeLatest } from 'redux-saga/effects'
import api from '../../../../utils/api'
import log from '../../../../utils/logger'
import { getToken } from '../../../authorization/selectors'
import {
  fetchPropertySettingsStart,
  fetchPropertySettingsSuccess,
  fetchPropertySettingsError,
  fetchPropertySettings as fetchPropertySettingsAction,
  updatePropertySettingOptimistic,
  updatePropertySettingSuccess,
  updatePropertySettingError,
  UPDATE_PROPERTY_SETTING
} from '../actions'
import { getPropertySettings } from '../selectors'

export function* fetchPropertySettings({ propertyId }) {
  try {
    const authToken = yield select(getToken)
    yield put(fetchPropertySettingsStart())
    const response = yield call(api.getPropertySettings, authToken, propertyId)
    yield put(fetchPropertySettingsSuccess(response))
    return response
  } catch (error) {
    yield put(fetchPropertySettingsError(error))
    log(`Failed to fetch properties. Error: ${error}`)
  }
}

export function* updatePropertySetting(action: Object) {
  const settings = yield select(getPropertySettings)
  const { attributes: newAtts, propertyId } = action

  let currentAtts = {}
  let attsToUpdate = {}

  // loop through each attribute
  // if attribute is different from what we have then add it to update list
  // also save it to current attributes so we can revert later if an error occurs
  for (let key in newAtts) {
    if (newAtts[key] !== settings[key]) {
      attsToUpdate[key] = newAtts[key]
      currentAtts[key] = settings[key]
    }
  }

  if (Object.keys(attsToUpdate).length > 0) {
    const authToken = yield select(getToken)

    try {
      yield put(updatePropertySettingOptimistic(propertyId, attsToUpdate))
      const response = yield call(
        api.updatePropertySettings,
        authToken,
        propertyId,
        attsToUpdate
      )
      let serverAtts = {}

      for (let key in attsToUpdate) {
        if (response[key]) {
          serverAtts[key] = response[key]
        }
      }

      yield put(updatePropertySettingSuccess(serverAtts))
    } catch (err) {
      yield put(updatePropertySettingError(err.message, currentAtts))
    }
  }
}

function* watchFetchPropertySetting() {
  yield takeLatest(fetchPropertySettingsAction().type, fetchPropertySettings)
}

function* watchUpdatePropertySetting() {
  yield takeLatest(UPDATE_PROPERTY_SETTING, updatePropertySetting)
}

export default [watchFetchPropertySetting(), watchUpdatePropertySetting()]
