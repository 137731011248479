import styled from '@emotion/styled'
import MaterialAppBar from '@material-ui/core/AppBar'
import MaterialTypography from '@material-ui/core/Typography'

export const StyledAppBar = styled(MaterialAppBar)`
  padding: 0 0 0 50px;
  background-color: transparent;
  grid-area: head;

  .right-part {
    font-weight: ${({ theme }) => theme.custom.font.weight.light};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
export const Typography = styled(MaterialTypography)`
  flex: 1;
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
`

export const Separator = styled.span`
  line-height: 1.78em;
`
