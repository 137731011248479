import { eventChannel, buffers } from 'redux-saga'
import { call, select, take, takeEvery, put } from 'redux-saga/effects'
import castArray from 'lodash/castArray'
import log from '../../../utils/logger'
import Socket from '../../../utils/socket'
import { getToken } from '../../authorization/selectors'
import { DEAUTH } from '../../authorization/actions'
// import { ON_FIRST_RUN, ON_BACK_TO_FOREGROUND } from '../../appstate/actions'
// import { ON_INTERNET_RECONNECTED } from '../../network/actions'

// event related
export const eventKeys = {
  REPLY: 'phx_reply',
  NEW: 'new',
  UPDATE: 'update'
}

export const eventCodes = {
  DEVICE_CREATED: 1,
  DEVICE_EXCLUDED: 2,
  LOCK_CODE_CREATED: 1800,
  LOCK_CODE_DELETED: 1710,
  LOCK_CODE_EDITED: 1820
}

export function* connectSocket() {
  try {
    let token = yield select(getToken)

    if (!token) {
      throw 'token is missing'
    }

    yield call(Socket.init, token)
  } catch (error) {
    log(`Failed to create a socket connection. Error: ${error}`)
  }
}

export function* disconnectSocket() {
  try {
    yield call(Socket.disconnect)
  } catch (error) {
    log(`Failed to disconnect the socket connection. Error: ${error}`)
  }
}

export function* joinChannel(topic) {
  try {
    return yield call(Socket.joinChannel, topic)
  } catch (error) {
    log(`Failed to join channel ${topic}`)
  }
}

export function* leaveChannel(channel) {
  try {
    return yield call(Socket.leaveChannel, channel)
  } catch (error) {
    log(`Failed to leave channel ${channel}. Error: ${error}`)
  }
}

export function* pushMessage(topic, command) {
  try {
    yield call(Socket.pushMessage, topic, command)
  } catch (error) {
    log(`Failed to push message ${command} for channel ${topic}. ${error}`)
    // rethrow error so who ever called this can stop operating
    throw error
  }
}

export function* watchChannel(channel, eventKeys, action, test) {
  const socketChannel = createChannel(channel, eventKeys)
  while (true) {
    const { payload } = yield take(socketChannel)

    if (test) {
      if (test(payload)) yield put(action(payload))
    } else {
      yield put(action(payload))
    }
  }
}

function createChannel(channel, eventKeys) {
  eventKeys = castArray(eventKeys)
  return eventChannel(emitter => {
    const phoenixListener = eventKeys.reduce(async (phoenixListener, eventKey) =>
      await channel.on(eventKey, payload => {
        console.log(`Response on ${channel.topic} channel for event '${eventKey}': `, payload)
        return emitter({ eventKey, payload })
      }), 0)
    return () => {
      channel.off(eventKey, phoenixListener)
    }
  }, buffers.expanding())
}

function* watchDeauth() {
  yield takeEvery(DEAUTH, disconnectSocket)
}

function* watchOnFirstRun() {
  yield takeEvery(ON_FIRST_RUN, connectSocket)
}

function* watchOnInternetReconnected() {
  yield takeEvery(ON_INTERNET_RECONNECTED, connectSocket)
}

function* watchOnBackToForeground() {
  yield takeEvery(ON_BACK_TO_FOREGROUND, connectSocket)
}

export default [
  watchDeauth(),
  watchOnFirstRun(),
  watchOnBackToForeground(),
  watchOnInternetReconnected()
]
