//@flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import { CommonIcon } from '@icons'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'
import { InputContainer } from './style.js'
import 'react-day-picker/lib/style.css';

const DateInput = ({ handleDayPick, date, dateFilter }) => {
  
  let selectedDate = null;
  if(date) {
    const splitDate = date.split('-');
    selectedDate = new Date(splitDate[0],splitDate[1]-1,splitDate[2]);
  }

  const dayPickerProps = dateFilter ? { disabledDays: dateFilter } : {}
  return (
    <InputContainer>
      <DayPickerInput
        onDayChange={handleDayPick}
        formatDate={formatDate}
        parseDate={parseDate}
        format={'LL'}
        value={selectedDate}
        dayPickerProps={dayPickerProps}
      />
      <CommonIcon name="calender" height="20px" width="20px" />
    </InputContainer>
  )
}

DateInput.defaultProps = {
  onDayChange: () => null,
}

export default withTheme(DateInput)
