import get from 'lodash/get'
import { FETCH_PREFIX, SUCCESS } from '../../../helpers/actions/constants'

import {
  UPDATE_PINNED_CHANNEL_OPTIMISTIC,
  PINNED_CHANNELS_FETCH_SECTION,
  fetchPinnedChannelsSuccess
} from '../actions'

const initialState = null

const reducer = (state = initialState, action) => {
  const { userId = 'self', ...payload } = action.payload || {}
  const { user_id = 'self', ...attributes } = action.attributes || {}
  switch (action.type) {
    case `${FETCH_PREFIX}${PINNED_CHANNELS_FETCH_SECTION}${SUCCESS}`:
      return { ...state, [userId]: payload }
    default:
      return state
  }
}

export default reducer
