// @flow

import get from 'lodash/get'
import { getFeatureFlag } from '../../../admin/assets/src/integrations/LaunchDarkly/selectors'
import { getPropertyAccessSettings } from 'zego-shared/store/settings/selectors'

const AMENITY_RESERVATION_FEATUREFLAG = 'amenity-reservation-admin-web'

export const getAmenitiesFeatureMode = (state: Object) => {
  const featureFlag = getFeatureFlag(state)

  return get(featureFlag, 'ld.variation', () => {})(AMENITY_RESERVATION_FEATUREFLAG, 'Legacy')
}

export const isProAmenity = (state: Object) => {
  const featureMode = getAmenitiesFeatureMode(state)
  const settings = getPropertyAccessSettings(state)

  if (featureMode === undefined || settings === undefined) {
    return undefined
  }

  return (featureMode === 'New' && settings.amenityReservations)
}
