import { combineReducers } from 'redux'
import flowRight from 'lodash/flowRight'
import withNormalizedFetch from '../../helpers/reducers/withNormalizedFetch'
import withNormalizedRefresh from '../../helpers/reducers/withNormalizedRefresh'
import {
  SECTION,
  clearHubCommandExcludeStatus,
  DELETE_DEVICE_START,
  DELETE_DEVICE_FINISH
} from '../actions'
import { lockCodes, generateLockCodes } from './lockCodes'
import allIds from './allIds'
import byId from './byId'

const withFetchAndRefresh = flowRight(
  withNormalizedFetch(SECTION),
  withNormalizedRefresh(SECTION)
)

export const generateDevices = enhancerMap => {
  const devices = combineReducers({
    byId: enhancerMap.byId(byId),
    allIds: enhancerMap.allIds(allIds),
    lockCodes: generateLockCodes(enhancerMap.lockCodes)
  })

  return enhancerMap.self(withFetchAndRefresh(devices))
}

const hubCommand = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_HUBEXCLUSION_START':
      return { ...state, excludeLoading: true, excludeSuccess: false }
    case 'UPDATE_HUBEXCLUSION_SUCCESS':
      return { ...state, excludeLoading: false, excludeSuccess: true }

    case clearHubCommandExcludeStatus().type:
      return { ...state, excludeLoading: undefined, excludeSuccess: undefined }
    default:
      return state
  }
}

const deleteDevice = (state = {}, action) => {
  switch (action.type) {
    case DELETE_DEVICE_START:
      return { ...state, loading: true }
    case DELETE_DEVICE_FINISH:
      return { ...state, loading: false }
    default:
      return state
  }
}

export const devices = withFetchAndRefresh(
  combineReducers({
    byId,
    allIds,
    lockCodes,
    hubCommand,
    deleteDevice
  })
)
