import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import CodeDetails from './CodeDetails'
import {
  fetchVendorCode,
  fetchVendorCodeUnits,
  fetchVendor
} from 'zego-shared/store/lockCodes/actions'
import withFetchData from 'zego-shared/views/withFetchData'
import { fetchSummary } from 'zego-shared/store/summary/actions'
import { fetchUnits } from 'zego-shared/store/select/actions'
import { getToken } from 'zego-shared/store/authorization/selectors'
import { getUserRole } from 'zego-shared/store/authorization/selectors'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import {
  failedToCreateVendorCode,
  failedToUpdateVendorCode
} from 'zego-shared/utils/messages'
import api from 'zego-shared/utils/api'

import {
  getVendorCode,
  hasVendorCode,
  getUnitList,
  getVendorCodeUnits
} from 'zego-shared/store/lockCodes/selectors'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { getISOString } from '../../../utils/dateUtils'

const getCreateMode = url => {
  return url.indexOf('/create') > -1
}

const buildCreateVendorPayload = (vendor, propertyId, vendorId) => {
  const payload = {
    email: vendor.Email,
    phone: vendor.Phone,
    time_start: getISOString(vendor['Start Date']),
    time_end: getISOString(vendor['End Date']),
    name: vendor['Vendor Name'],
    resource_access_type: vendor['Access Type'].id,
    property_id: propertyId,
    unit_ids: vendor.units
  }
  if (vendorId && vendorId !== 'new') {
    payload.vendor_id = vendorId
  } else {
    payload.vendor_name = vendor['Vendor Name']
  }
  return payload
}

const buildUpdateVendorPayload = (vendor, propertyId) => {
  return {
    email: vendor.Email,
    phone: vendor.Phone,
    time_start: getISOString(vendor['Start Date']),
    time_end: getISOString(vendor['End Date']),
    resource_access_type: vendor['Access Type'].id,
    unit_ids: vendor.units
  }
}

const createVendor = async (vendor, token, ids) => {
  const { propertyId, vendorId } = ids
  const data = buildCreateVendorPayload(vendor, propertyId, vendorId)
  const vendorCode = await api.createVendorCode(token, data, propertyId)
  return vendorCode
}

const updateVendor = async (vendor, token, ids) => {
  const { propertyId, codeId } = ids
  const data = buildUpdateVendorPayload(vendor, propertyId)
  const vendorCode = await api.updateVendorCode(token, data, propertyId, codeId)
  return vendorCode
}

const isFetchingUnitSelector = createIsFetchingSelector(['units'])

const getIsVendorExisting = vendorId => {
  return vendorId !== 'new'
}

const mapStateToProps = (
  state,
  { location: { search }, history, match: { url, params } }
) => {
  const createMode = getCreateMode(url)
  const isVendorExisting = getIsVendorExisting(params.vendorId)
  const { propertyId } = params
  return {
    history,
    propertyId,
    codeUnits: getVendorCodeUnits(state, createMode),
    unitList: getUnitList(state),
    codeDetails: getVendorCode(state, createMode, isVendorExisting),
    hasData: hasVendorCode(state),
    createMode,
    isVendorNameEditable: isVendorExisting,
    userToken: getToken(state),
    isFetchUnits: isFetchingUnitSelector(state),
    userRole: getUserRole(state),
  }
}

const mapDispatchToProps = (dispatch, { match }) => {
  const {
    params: { codeId, propertyId, vendorId },
    url
  } = match
  const isCreateMode = getCreateMode(url)
  const isVendorExistingVendor = getIsVendorExisting(vendorId)
  return {
    fetchData: () => {
      if (!isCreateMode) {
        dispatch(fetchVendorCode(codeId, propertyId))
        dispatch(fetchVendorCodeUnits(codeId, propertyId))
      }
      if (vendorId && isVendorExistingVendor) {
        dispatch(fetchVendor(vendorId, propertyId))
      }
      dispatch(fetchSummary(propertyId))
    },
    saveVendorCode: async (vendor, token) => {
      const saveFn = isCreateMode ? createVendor : updateVendor
      const eMsg = isCreateMode
        ? failedToCreateVendorCode
        : failedToUpdateVendorCode
      try {
        const vendorCode = await saveFn(vendor, token, {
          codeId,
          propertyId,
          vendorId
        })
        return `/properties/${propertyId}/lock-codes/vendors/${
          vendorCode.vendor_id
        }/codes/${vendorCode.external_user_id}`
      } catch (error) {
        dispatch(showSnackbar(eMsg, 'error'))
      }
    },
    searchUnits: debounce(name => {
      dispatch(fetchUnits(name))
    }, 400),
    displayErrorMessage: message => dispatch(showSnackbar(message, 'error'))
  }
}
const CodeDetailsWithFetchData = withFetchData(CodeDetails)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CodeDetailsWithFetchData)
