import { connect } from 'react-redux'
import {
  validateSignUpLink,
  signUp,
  fetchResourcesAndRedirectAfterLogin
} from 'zego-shared/store/authorization/actions'
import {
  getIsValidSignupToken,
  getSignupSuccessful,
  getUserRole
} from 'zego-shared/store/authorization/selectors'
import Signup from './Signup'

const mapStateToProps = state => ({
  isValidSignupToken: getIsValidSignupToken(state),
  signupSuccess: getSignupSuccessful(state),
  userRole: getUserRole(state)
})

const mapDispatchToProps = {
  validateSignUpLink,
  signUp,
  fetchResourcesAndRedirectAfterLogin
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup)
