import { WORKORDERSUMMARYLIST, UPDATE_WORK_ORDER_END } from '../actions'
import withFetch from '../../helpers/reducers/withFetch'

const workOrderSummary = (state = [], action) => {
  switch (action.type) {
    case UPDATE_WORK_ORDER_END:
      return state.map(workOrder => workOrder.workOrderId === action.result.workOrderId ? action.result : workOrder)
    default:
      return state
  }
}

export default withFetch(WORKORDERSUMMARYLIST)(workOrderSummary)
