import styled from '@emotion/styled'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import isPropValid from '@emotion/is-prop-valid'

export const CustomCard = styled(Card, { shouldForwardProp: isPropValid })`
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  border-radius: ${({ noBorderRadius }) => !noBorderRadius && '5px'};
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  grid-column: ${({ columnStart, columnSpan }) => `${columnStart} / span
  ${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span
  ${rowSpan}`};
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  & > div:last-child {
    padding-bottom: 0.2em;
    ${({ isLeftSideBG }) => isLeftSideBG && 'padding: 35px 0px 0px'};
    ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};
    ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`};
  }

  ${({ theme, type }) =>
    type === 'summary'
      ? `background: ${theme.palette.text.light3};
  `
      : ''};
  ${({ showShadow }) => !showShadow && `box-shadow: none;`};
  ${({ overflow }) => overflow && `overflow: ${overflow};`};

  ${({ isCommunicationHome }) =>
    isCommunicationHome &&
    `  position: relative;
    min-height: 547px;
  top: -76px;`};

  ${({ transparentBackground }) =>
    transparentBackground && `background: transparent;`};
`
export const Head = styled('div')`
  display: flex;
  margin-bottom: ${({ headerMarginBottom }) =>
    headerMarginBottom && `${headerMarginBottom}px`};
`

export const Title = styled('div')`
  color: ${({ theme }) => theme.palette.common.black};
  font-size: ${({ titlesize }) => (titlesize ? titlesize : '18px')};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
`
export const Body = styled('div')`
  position: relative;
  align-content: center;
  display: flex;
  flex: 3;
`
export const RightPanel = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  flex: 1;
  display: flex;
  justify-content: flex-end;

  & > span {
    margin: 0em 0.5em;
    align-self: center;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & > span:last-child {
    display: flex;
    justify-content: flex-end;
  }
`

export const Underline = styled('div')`
  width: ${({ length }) => (length ? length : '21px')};
  height: ${({ height }) => (height ? height : '10px')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '0')};
  ${({ borderColor, thick, theme }) =>
    thick
      ? `border-bottom: 2px solid ${
          borderColor ? borderColor : theme.palette.neutrals.deepGray
        }`
      : `border-bottom: 1px solid ${
          borderColor ? borderColor : theme.palette.neutrals.underlineGray
        }`};
`

export const Footer = styled('div')`
  display: flex;
  flex: 0.5;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 10px;
`

export const CustomCardContent = styled(CardContent, {
  shouldForwardProp: prop => ['imageSrc'].indexOf(prop) === -1
})`
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: url(${({ imageSrc }) => imageSrc});
  background-position: ${({ backgroundposition }) =>
    backgroundposition ? backgroundposition : 'right'};
  background-size: ${({ backgroundsize }) =>
    backgroundsize ? backgroundsize : 'contain'};
  background-repeat: no-repeat;
  ${Footer}, ${Head} {
    z-index: 100;
  }

  ${Body} {
    z-index: 150;
  }
`

export const FooterNote = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.text.tertiary};
`
