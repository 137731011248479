// @flow
import React from 'react'
import AlertModal from './AlertModal'
import ScrollToTop from '../ScrollToTop'

type State = {
  showDialog: boolean
}

type Props = {
  alertYesButtonText: string,
  alertNoButtonText: string,
  alertTitle: string,
  alertMessage: string,
  onAlertClickYes: () => {},
  showScrollToTop?: boolean,
  theme: any
}

const withAlertModal = (WrappedComponent: Function) =>
  class withDialog extends React.Component<Props, State> {
    state = {
      showDialog: false
    }

    showAlertModal = () => {
      this.setState({
        showDialog: true
      })
    }

    closeAlertModal = () => {
      this.setState({
        showDialog: false
      })
    }

    onClickYes = () => {
      const { onAlertClickYes } = this.props
      onAlertClickYes()

      this.setState({
        showDialog: false
      })
    }

    render() {
      const {
        alertYesButtonText,
        alertNoButtonText,
        alertTitle,
        alertMessage,
        showScrollToTop,
        theme,
        ...rest
      } = this.props
      return (
        <React.Fragment>
          <WrappedComponent
            {...rest}
            showAlertModal={this.showAlertModal}
            theme={theme}
          />
          <AlertModal
            title={alertTitle}
            message={alertMessage}
            buttons={[
              {
                onClick: this.onClickYes,
                text: alertYesButtonText
              },
              {
                onClick: this.closeAlertModal,
                color: theme.palette.primary.navy.spaceBlue,
                text: alertNoButtonText
              }
            ]}
            show={this.state.showDialog}
            onClose={this.closeAlertModal}
          />
          {showScrollToTop && <ScrollToTop />}
        </React.Fragment>
      )
    }
  }

export default withAlertModal
