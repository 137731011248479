// @flow

import React from 'react'
import Flex from '@Common/Flex'
import Button from '@Common/Button'
import { SuccessMsgGrid, Failed } from './styles'
import Loader from '../../Common/Loader'

type Props = {
  message?: string,
  isCreatingUser: boolean,
  addUserError: ?{ message: string },
  exit?: () => void,
  reset?: () => void
}

export default class Success extends React.Component<Props, {}> {
  timeout: TimeoutID

  componentDidUpdate() {
    const { isCreatingUser, addUserError, exit } = this.props
    if (isCreatingUser || (!isCreatingUser && addUserError)) {
      clearTimeout(this.timeout)
    } else if (!isCreatingUser && !addUserError) {
      this.timeout = setTimeout(() => {
        exit && exit()
      }, 1200)
    }
  }

  render() {
    const { message, isCreatingUser, addUserError, reset, exit } = this.props
    return (
      <SuccessMsgGrid>
        <div>
          {addUserError ? (
            <React.Fragment>
              <Failed>{addUserError.message}</Failed>
              <Flex justifySpaceBetween>
                <Button long secondaryLight onClick={reset}>
                  Back
                </Button>
                <Button long secondaryLight onClick={exit}>
                  Exit
                </Button>
              </Flex>
            </React.Fragment>
          ) : isCreatingUser ? (
            <Loader fullScreen={false} color="#fff" />
          ) : (
            message
          )}
        </div>
      </SuccessMsgGrid>
    )
  }
}
