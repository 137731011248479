// @flow
import React from 'react'
import { PanelHeading } from './styles'
import Tooltip from '../../Common/Tooltip'
import Flex, { FlexItem } from '@Common/Flex/FlexDirection'

const PanelUnit = ({
  count,
  codeType
}: {
  codeType: string,
  count: number
}) => (
  <PanelHeading>
    <div>{count}</div>
    <div>
      <Flex>
        <FlexItem displayFlex alignItemsCenter>
          {codeType} {`Code${count > 1 ? 's' : ''}`}
          {codeType === 'Temporary' && <Tooltip type={'TemporaryCodeLimit'} />}
        </FlexItem>
      </Flex>
    </div>
  </PanelHeading>
)

export default PanelUnit
