import { USER_SECTION } from '../actions'
import withFetch from '../../../helpers/reducers/withFetch'

const initialState = {}

const user = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withFetch(USER_SECTION)(user)
