// @flow
import * as React from 'react'
import { withTheme } from 'emotion-theming'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'

import CarouselList from '@Common/CarouselList'
import withScrollBehaviour from '@Common/CarouselList/withScrollBehaviour'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import { CommonIcon, SearchIcon } from '@icons'
import type { Theme } from '../../../theme'

import {
  BulletIconSpan,
  LoadingDiv,
  NoResultsDiv,
  ResidentNameSpan,
  ResidentSearchDiv,
  ResultItemDiv,
  ResultsDiv,
  SearchInputAdornment,
  TextField,
} from './styles'

const BulletIcon = withTheme(({ theme }: { theme: Theme }) => (
  <CommonIcon
    name="bullet"
    width="3px"
    height="3px"
    fill={theme.palette.primary.green.mint}
    style={{ marginBottom: '2px' }}
  />
))

const ResultsList = withScrollBehaviour(({
  items,
  itemHeight,
  numberOfItemsPerSlide,
  lastSlideNumber,
  currentSlideNumber = 1,
}: {
  items: React.Node[],
  itemHeight: number,
  numberOfItemsPerSlide: number,
  lastSlideNumber: number,
  currentSlideNumber: number,
}) => (
  <CarouselList
    items={items}
    itemHeight={itemHeight}
    numberOfItemsPerSlide={numberOfItemsPerSlide}
    lastSlide={lastSlideNumber}
    currentSlide={currentSlideNumber}
  />
))

type Props = {
  isFetching: boolean,
  results: Object[],
  hasResults: boolean,
  dispatchSearch: (searchText: ?string) => void,
  elementId?: string,
  handleResultClick: (result: Object) => void,
  itemHeight?: number,
  minSearchLength?: number,
  numItemsPerSlide?: number,
}

type State = {
  isInputFocused: boolean,
  searchText: string,
  showResults: boolean,
}

export default class ResidentSearch extends React.Component<Props, State> {
  elementId: string
  itemHeight: number
  minSearchLength: number
  numItemsPerSlide: number
  searchRef: { current: ?HTMLDivElement }

  constructor(props: Object) {
    super(props)

    const { elementId, itemHeight, minSearchLength, numItemsPerSlide } = props
    this.elementId = elementId || 'resident-search'
    this.itemHeight = itemHeight || '40'
    this.minSearchLength = minSearchLength || 3
    this.numItemsPerSlide = numItemsPerSlide || 10
    this.searchRef = React.createRef()
    this.state = { isInputFocused: false, searchText: '', showResults: false }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick)
  }

  UNSAFE_componentWillReceiveProps({ results }: Object, _nextContext: Object) {
    const { isInputFocused, showResults } = this.state

    if (results && isInputFocused && !showResults) {
      this.setState({ showResults: true })
    }
  }

  handleOnChange = ({ target: { value: searchText } }: Object) => {
    this.setState({ searchText })
    this.submitSearch()
  }

  handleOnFocus = () => {
    this.setState({ isInputFocused: true })
  }

  handleOnKeyPress = ({ key }: Object) => {
    if (key === 'Enter') {
      this.submitSearch()
    }
  }

  handleOutsideClick = ({ target }: Object) => {
    const { current } = this.searchRef

    if (current && !current.contains(target)) {
      this.setState({ isInputFocused: false, showResults: false })
    }
  }

  handleResultClick = (result: Object) => {
    const { handleResultClick } = this.props

    if (handleResultClick) {
      handleResultClick(result)
    }

    this.setState({ isInputFocused: false, searchText: '', showResults: false })
  }

  submitSearch = debounce(() => {
    const { dispatchSearch } = this.props
    const { searchText } = this.state

    if (searchText.length >= this.minSearchLength) {
      dispatchSearch(searchText)
    }
  }, 400)

  buildResultListItems = (): Array<React.Node> => {
    const { results } = this.props

    return results.map((result, index): Object => {
      const idPrefix = `${this.elementId}-result${index + 1}`

      return (
        <ResultItemDiv
          key={idPrefix}
          height={this.itemHeight}
          onClick={() => this.handleResultClick(result)}
          isLastItem={index === results.length - 1}
        >
          <FlexDirection fullWidth>
            <FlexItem flex={2} displayFlex alignItemsCenter>
              <span id={`${idPrefix}`}>
                <ResidentNameSpan id={`${idPrefix}-name`}>{result.name}</ResidentNameSpan>
                <BulletIconSpan><BulletIcon/></BulletIconSpan>
                <span id={`${idPrefix}-unit-name`}>{result.unitName}</span>
              </span>
            </FlexItem>
          </FlexDirection>
        </ResultItemDiv>
      )
    })
  }

  render() {
    const { isFetching, results, hasResults } = this.props
    const { searchText, showResults } = this.state

    const numberOfItemsPerSlide = results.length > this.numItemsPerSlide
      ? this.numItemsPerSlide : results.length
    const lastSlideNumber = Math.ceil(results.length / numberOfItemsPerSlide)

    return (
      <ResidentSearchDiv>
        <div ref={this.searchRef}>
          <TextField
            id={`${this.elementId}-input`}
            value={searchText}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <SearchInputAdornment position="start">
                  <SearchIcon
                    name="search"
                    height="18px"
                    width="16px"
                    onClick={this.submitSearch}
                    className="search-submit-icon"
                    data-testid={`${this.elementId}-submit-icon`}
                  />
                </SearchInputAdornment>
              ),
            }}
            onFocus={this.handleOnFocus}
            onChange={this.handleOnChange}
            onKeyPress={this.handleOnKeyPress}
          />
          {
            !isEmpty(searchText) && showResults && (
              <ResultsDiv>
                {
                  isFetching ? (
                    <LoadingDiv height={this.itemHeight}>Loading...</LoadingDiv>
                  ) : (
                    hasResults && (
                      results.length === 0 ? (
                        <NoResultsDiv height={this.itemHeight}>No results found</NoResultsDiv>
                      ) : (
                        <ResultsList
                          items={this.buildResultListItems()}
                          itemHeight={this.itemHeight}
                          numberOfItemsPerSlide={numberOfItemsPerSlide}
                          lastSlideNumber={lastSlideNumber}
                          transitionHeights={['0', '3em']}
                          arrowsRight
                        />
                      )
                    )
                  )
                }
              </ResultsDiv>
            )
          }
        </div>
      </ResidentSearchDiv>
    )
  }
}
