// @flow
import { connect } from 'react-redux'
import queryString from 'query-string'
import BatterySmartSummary from './BatterySmartSummary'
import { capitalizeFirstLetter } from '../../../../utils'
import {
  getSmartSummaryBatteryInfo,
  getSmartSummaryBatteryCount
} from '../../../../store/Property/selectors'
import { fetchUnits } from 'zego-shared/store/select/units/actions'

const validFilters = ['low', 'mid', 'high']

const mapStateToProps = (
  state,
  { location: { search }, match: { params } }
) => {
  const searchFilter = queryString.parse(search).filter
  const filter = validFilters.includes(searchFilter) ? searchFilter : 'low'
  return {
    batteryInfo: getSmartSummaryBatteryInfo(state, filter),
    filter: capitalizeFirstLetter(filter),
    count: getSmartSummaryBatteryCount(state, filter),
    propertyId: params.propertyId
  }
}

const mapDispatchToProps = (dispatch) => ({fetchData: dispatch(fetchUnits())})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BatterySmartSummary)
