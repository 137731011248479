import { connect } from 'react-redux'
import queryString from 'query-string'
import { getPropertyId } from 'zego-shared/store/select/selectors'
import {
  getCommunityThreadData,
  hasData
} from 'zego-shared/store/communityThread/selectors'
import { getToken } from 'zego-shared/store/authorization/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import ViewPost from './ViewPostEvent'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import {
  deleteCommunityThread,
  fetchCommunityThread,
  attendEvent,
  unattendEvent,
  createComment,
  deleteComment,
  unlikePost,
  likePost
} from 'zego-shared/store/communityThread/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'

const fetchingSelector = createIsFetchingSelector(['communitythread'])

const mapStateToProps = (
  state,
  { title, image, message, threadId, startTime, endTime, match: { params } }
) => ({
  propertyId: getPropertyId(state),
  userToken: getToken(state),
  action: params.action,
  type: params.type,
  data: getCommunityThreadData(state, params.action, params.threadId),
  showDialog: false,
  isFetching: fetchingSelector(state),
  hasData: hasData(state),
  threadId: params.threadId
})

const mapDispatchToProps = (
  dispatch,
  { match: { params }, history, location: { search } }
) => {
  return {
    fetchData: () => {
      dispatch(fetchCommunityThread(params.threadId))
    },
    showError: errorMsg => dispatch(showSnackbar(errorMsg, 'error')),
    onAlertClickYes: () => {
      const commentKey = queryString.parse(search).comment
      if (commentKey) {
        dispatch(deleteComment(params.threadId, commentKey))
      } else {
        dispatch(deleteCommunityThread(params.threadId, params.type, history))
      }
    },
    attendEvent: () => {
      dispatch(attendEvent(params.threadId))
    },
    unattendEvent: () => {
      dispatch(unattendEvent(params.threadId))
    },
    postComment: comment => {
      dispatch(createComment(params.threadId, comment))
    },
    unlikePost: () => {
      dispatch(unlikePost(params.threadId))
    },
    likePost: () => {
      dispatch(likePost(params.threadId))
    }
  }
}

const ViewPostWithFetchData = withFetchData(ViewPost)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewPostWithFetchData)
