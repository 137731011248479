import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import MaterialListItem from '@material-ui/core/ListItem'
import MaterialListItemText from '@material-ui/core/ListItemText'
import MaterialListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'

export const ListItem = styled(MaterialListItem, {
  shouldForwardProp: prop =>
    ['borderTop', 'selected', 'extraPadding'].indexOf(prop) === -1
})`
  font-size: ${({ theme }) => theme.custom.font.size.md};
  ${({ borderTop, theme }) =>
    borderTop && `border-top: 1px solid ${theme.palette.neutrals.deepGray}`};

  ${({ selected, theme }) => selected && `color: ${theme.palette.neutrals.warmGray}`};
  ${({ extraPadding }) => extraPadding && `padding: 30px 15px `};
  &:hover {
    background: none;
  }
`

export const ListItemText = styled(MaterialListItemText)`
  padding: 0;
`

export const ListItemSecondaryAction = styled(MaterialListItemSecondaryAction)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`

export const UserLink = styled(Link, {
  shouldForwardProp: prop => ['header'].indexOf(prop) === -1
})`
  color: ${({ theme }) => theme.palette.primary.green.mint};
  ${({ header, theme }) => header && `font-size: ${theme.custom.font.size.md}`};
`

export const Clickable = styled('span')`
  cursor: pointer;
`

export const GreyText = styled('span')`
  color: ${({ theme }) => theme.palette.text.light};
  width: 100%;
  text-align: center;
`

export const OnText = styled('span')`
  color: ${({ theme }) => theme.palette.primary.green.mint};
`

export const FabButton = styled(Button)`
  position: absolute;
  bottom: 15px;
  right: 15px;
`
