import styled from '@emotion/styled'
import MaterialButton from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import PostFeedUnitImage from '../../../images/community_feed_post_card.png'
import PostsFeedBg from '../../../images/community_feed_backdrop.png'
import MaterialTextField from '@material-ui/core/TextField'
import { convertHexToRGB } from '../../../utils'
import Chip from '@material-ui/core/Chip'

export const HeaderTitleWrapper = styled('div')`
  grid-column: 1 / span 4;
  grid-row: 1 / span 1;
`

export const TitleStyled = styled('div')`
  padding-left: 10px;
  padding-top: 10px;
  font-size: 2em;
  color: ${({ theme }) => theme.palette.primary.green.mint};
`

export const TabsWrapper = styled('div')`
  display: flex;
  margin-top: -3em;
  margin-top: -1em;
  padding-bottom: 1em;
  width: 100%;
  padding-top: 5em;
  grid-row: 1 / span 1;
  grid-column: 1 / span 12;
  align-items: flex-end;

  & > div:first-child {
    flex: 0.1;
  }

  & > div:nth-child(2) {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`

export const ButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  background: white;
  flex: 0.2;
  align-items: flex-end;
  padding-left: 3em;
  margin-top: -2em;
`

export const FilterButtonStyled = styled(MaterialButton)`
  background-color: ${({ active, theme }) =>
    active ? theme.palette.primary.green.mint : theme.palette.common.white};
  width: 50px;
  height: 50px;
  margin-top: 1em;
  box-shadow: none;
  border: ${({ theme, active }) =>
    active
      ? theme.palette.primary.green.mint
      : `3px solid ${theme.palette.neutrals.coolGray}`};
`

export const AddButtonStyled = styled(MaterialButton)`
  background-color: ${({ theme }) => theme.palette.common.white};
  width: 3em;
  height: 3em;
  margin-top: 1em;
  border: ${({ theme }) =>
    `2.5px solid ${theme.palette.secondary.yellow.solarFlare}`};

  box-shadow: none;
  &:hover {
    border: ${({ theme }) =>
      `2.5px solid ${theme.palette.secondary.yellow.golden}`};
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`

export const FilterWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  position: fixed;
  padding-top: 15vh;
  z-index: 2;
`

export const EventsWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 4em;
  margin-left: 1%;
  width: 98%;
`

export const EventBackdrop = styled('div')`
  width: 130%;
  left: -1em;
  padding-left: 80%;
  height: 39vh;
  top: ${({ index }) => (index === 1 ? '-3em' : '0em')};
  background-color: ${({ theme }) => theme.palette.neutrals.coolGray};
  background-size: 100% 100%;
  position: absolute;
  z-index: -30;
`

export const EventWrapper = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  height: 15vh;
  min-height: 115px;
`

export const EventTitleWrapper = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.lg};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
`

export const EventDateWrapper = styled('div')`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
`

export const EventInfoWrapper = styled('div')`
  flex: 4;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2em;
  border-bottom: 1px solid lightgrey;
`

export const EventDayStyled = styled('div')`
  font-size: 3.5em;
  color: white;
`

export const EventMonthStyled = styled('div')`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1em;
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
`

export const EventButtonWrapper = styled('div')`
  margin-left: auto;
  padding-right: 2em;
`

export const Underline = styled('div')`
  width: 10%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey.light7};
  margin-botton: 1em;
`

export const AttendeesWrapper = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.xxs};
  color: ${({ theme }) => theme.palette.neutrals.deepGray};
`

export const DateWrapper = styled('div')`
  padding-top: 15px;
  font-size: ${({ theme }) => theme.custom.font.size.xs};
  color: ${({ theme }) => theme.palette.neutrals.deepGray};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
`

export const ButtonStyled = styled(MaterialButton)`
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.blue.main};
  height: 35px;
  width: 105px;
  border-radius: 0;
  border: ${({ theme }) => `3px solid ${theme.palette.primary.green.mint}`};
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  line-height: 15px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
    border: ${({ theme }) =>
      `3px solid ${theme.palette.primary.green.seeFoam}`};
  }
`

export const PostsWrapper = styled(Card)`
  display: flex;
  align-items: center;
  height: 31.25em;
  margin-top: 1em;
  margin-bottom: 4em;
  margin-left: 1%;
  width: 98%;
  background-image: url(${PostFeedUnitImage});
  background-repeat: no-repeat;
  background-size: 100% 54%;
  background-position: 40% 100%;
`

export const PostImageStyled = styled('img')`
  objectfit: cover;
  height: 85%;
`

export const PostInfoWrapper = styled('div')`
  height: 100%;
  padding-top: 50px;
  padding-bottom: 10%;
  padding-left: 2.938em;
  padding-right: 1em;
`

export const PostNumberOfCommentsStyled = styled('div')`
  font-size: 0.75em;
  color: ${({ theme }) => theme.palette.grey.main};
`

export const PostNumberOfLikesStyled = styled('div')`
  font-size: 0.75em;
  color: ${({ theme }) => theme.palette.grey.main};
`

export const PostTitleStyled = styled('div')`
  margin-top: 16px;
  font-size: ${({ theme }) => theme.custom.font.size.xl36};
  color: ${({ theme }) => theme.palette.text.black1};
  min-height: 80px;
  max-height: 80px;
  line-height: 40px;
`

export const PostStyled = styled('div')`
  margin-top: 47px;
  margin-bottom: 13px;
`

export const PostUsernameStyled = styled('div')`
  font-size: 1.25em;
`

export const PostBodyStyled = styled('div')`
  margin-top: 40px;
  color: ${({ theme }) => theme.palette.text.grey};
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  line-height: 22px;

  & a {
    text-decoration: underline;
  }
`

export const PostDateText = styled('div')`
  margin-top: 0.85em;
  color: ${({ theme }) => theme.palette.text.grey};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
`

export const ReadMoreStyled = styled('span')`
  color: ${({ theme }) => theme.palette.primary.green.mint};
  text-decoration: underline;
`

export const FavoriteIconStyled = styled(FavoriteIcon)`
  height: 0.5em;
  width: 0.5em;
  margin-right: 0.25em;
  color: ${({ theme }) => theme.palette.grey.main};
`

export const ChatBubbleIconStyled = styled(ChatBubbleOutlineIcon)`
  height: 0.65em;
  width: 0.65em;
  margin-right: 0.25em;
  margin-left: 1em;
  color: ${({ theme }) => theme.palette.grey.main};
`

export const EmptyListStyled = styled('div')`
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
  min-height: 150px;
`

export const PostsEventsFeedWrapper = styled('div')`
  grid-column: 3 / span 8;
  grid-row: 2;
`

export const Backdrop = styled('div')`
  background-image: url(${PostsFeedBg});
  height: 100%;
  width: 50%;
  position: absolute;
  background-size: 100% 60%;
  background-repeat: no-repeat;
  opacity: 0.5;
  z-index: -50;
  margin-top: 245px;
  right: 0;
`

export const WhiteTop = styled('div')`
  height: 14em;
  position: absolute;
  width: 100%;
  right: 0;
  top: -3em;
  z-index: -40;
  background: ${({ theme }) => theme.palette.common.white};
  width: 100%;
`

export const UserFilterWrapper = styled('div')`
  background-color: ${({ theme }) => theme.palette.common.white};
  width: 290px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  height: 380px;
  padding: 30px 20px 0px 20px;
  margin-left: 65px;
  position: absolute;
  margin-top: 200px;
`

export const UsersList = styled('div')`
  background-color: ${({ theme }) => theme.palette.common.white};
  width: 270px;
  height: 275px;
  overflow-x: auto;
  margin-top: 30px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.palette.neutrals.deepGray};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.neutrals.warmGray};
    opacity: 0.5;
    height: 1em;
  }
`

export const List = styled('div')`
  width: 100%;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.palette.neutrals.mediumGray};
  }
`

export const SearchWrapper = styled('div')`
  height: 30px;
  width: 270px;
  margin-bottom: 30px;
`

export const TextField = styled(MaterialTextField)`
  width: 240px;
  border-radius: 50px;
  border: none;
  padding: 0 10px;
  border: 1px solid ${({ theme }) => theme.palette.text.lightGrey};

  & input {
    color: ${({ theme }) => theme.palette.text.lightGrey};
  }
`

export const CalendarWrapper = styled('div')`
  width: 250px;
  height: 358px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 1px 15px 0
    ${({ theme }) => convertHexToRGB(theme.palette.grey.main)};
  margin-left: 65px;
  position: absolute;
  margin-top: 140px;
`

export const CalendarActionButtons = styled('div')`
  height: 30px;
  line-height: 30px;
  width: 100%;
  text-align: right;
`

export const CancelDateSelection = styled('span')`
  color: ${({ theme }) => theme.palette.text.lightGrey};
  margin-right: 15px;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  padding: 5px;

  &:hover {
    cursor: pointer;
  }
`

export const ConfirmDateSelection = styled('span')`
  color: ${({ theme }) => theme.palette.primary.green.mint};
  margin-right: 28px;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  padding: 5px;

  &:hover {
    cursor: pointer;
  }
`

export const DisplaySearchCriteria = styled('div')`
  grid-column: 3 / span 8;
  grid-row: 2;
  padding: 20px 0px;
  display: flex;
  justify-content: flex-end;
  margin-top: -2em;
`

export const FilterTag = styled(Chip)`
  display: flex;
  flexwrap: wrap;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  height: 2em;
  margin-right: 10px;
  justify-content: space-between;
  min-width: 160px;
  padding: 0px 5px 0px 10px;
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  background: ${({ theme }) => theme.palette.primary.green.hintMint};
`

export const Space = styled('div')`
  height: 20px;
  width: 100%;
`
