// @flow
import React from 'react'
import Flex from '@Common/Flex'
import Linkify from 'linkifyjs/react';
import ChatFile from './ChatFile'
import Avatar from '@Common/Avatar'
import {
  extractFile,
  extractMessage,
} from '../../../../utils/chatUtils'

import {
  MessageContainer,
  Message,
  HrWithText,
  MessageBubble,
  MessageTime,
} from './styles'

type Props = {
  message: Object,
}

const ChatMessage = ({
  message: {
    name,
    type,
    time,
    date,
    image,
    message,
    senderIsCurrentUser,
    lastSenderIsCurrentSender,
  },
}: Props) => {
  let msg = <Linkify tagName="span">{extractMessage(message)}</Linkify>;
  let file = extractFile(message)

  return (
    <React.Fragment>
      {date && (
        <HrWithText>
          <span>{date}</span>
        </HrWithText>
      )}
      <MessageContainer senderIsCurrentUser={senderIsCurrentUser}>
        <Message
          senderIsCurrentUser={senderIsCurrentUser}
          lastSenderIsCurrentSender={lastSenderIsCurrentSender}>
          {!lastSenderIsCurrentSender || date !== null ? (
            <span>
              {name} / {type}
            </span>
          ) : null}
          {senderIsCurrentUser ? (
            <Flex flexWrap="nowrap">
              <MessageTime>{time}</MessageTime>
              <MessageBubble senderIsCurrentUser={senderIsCurrentUser}>
                {msg}
                {file && <ChatFile file={file} />}
              </MessageBubble>
            </Flex>
          ) : (
            <Flex flexWrap="nowrap">
              <MessageBubble senderIsCurrentUser={senderIsCurrentUser}>
                {msg}
                {file && <ChatFile file={file} />}
              </MessageBubble>
              <MessageTime>{time}</MessageTime>
            </Flex>
          )}
        </Message>
        <Avatar picture={image} size={'2.3em'} />
      </MessageContainer>
    </React.Fragment>
  )
}

export default ChatMessage
