import { call, select, takeLatest, put } from 'redux-saga/effects'
import {
  getToken,
  getPropertyId,
  getUnitId,
  getUserId
} from '../authorization/selectors'
import api from '../../utils/api'
import log from '../../utils/logger'
import {
  getPage,
  getFilterSortBy,
  getFilterUserId,
  getFilterDate
} from './postsSelectors'
import {
  fetchPostsAction,
  fetchPostsStart,
  fetchPostsSuccess,
  fetchPostsNextAction,
  fetchPostsNextStart,
  fetchPostsNextSuccess,
  FILTER_POSTS,
  fetchFilteredPostsSuccess,
  setFilterValuesToState
} from './postsActions'

export function* fetchPosts({ propertyId }) {
  try {
    yield put(fetchPostsStart())
    const authToken = yield select(getToken)

    const posts = yield call(api.getPosts, authToken, propertyId)

    yield put(fetchPostsSuccess(posts))
  } catch (error) {
    log(error)
  }
}

export function* getFilteredPosts({ date, userId, threadType, sortBy, propertyId }) {
  try {
    const authToken = yield select(getToken)

    const posts = yield call(
      api.getFilteredThread,
      authToken,
      propertyId,
      date,
      userId,
      threadType,
      sortBy
    )

    yield put(setFilterValuesToState(date, userId, threadType, sortBy))
    yield put(fetchFilteredPostsSuccess(posts))
  } catch (error) {
    log(error)
  }
}

export function* fetchNextPosts({ propertyId }) {
  try {
    yield put(fetchPostsNextStart())
    const authToken = yield select(getToken)
    const page = yield select(getPage)

    const userId = yield select(getFilterUserId)
    const sortBy = yield select(getFilterSortBy)
    const date = yield select(getFilterDate)
    const threadType = 'feed'

    if (sortBy || userId || date) {
      const posts = yield call(
        api.getFilteredThread,
        authToken,
        propertyId,
        date,
        userId,
        threadType,
        sortBy,
        page
      )
      yield put(fetchPostsNextSuccess(posts))
    } else {
      const posts = yield call(api.getPosts, authToken, propertyId, page)
      yield put(fetchPostsNextSuccess(posts))
    }
  } catch (error) {
    log(error)
  }
}

function* watchFetchPosts() {
  yield takeLatest(fetchPostsAction().type, fetchPosts)
}

function* watchfetchNextPosts() {
  yield takeLatest(fetchPostsNextAction().type, fetchNextPosts)
}

function* watchGetFilteredPosts() {
  yield takeLatest(FILTER_POSTS, getFilteredPosts)
}

export default [
  watchFetchPosts(),
  watchfetchNextPosts(),
  watchGetFilteredPosts()
]
