// @flow
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Search from './Search'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {
  getSearchResults,
  hasSearchData
} from 'zego-shared/store/search/selectors'
import { search, fetchSearchHistory } from 'zego-shared/store/search/actions'

const fetchingSelector = createIsFetchingSelector(['search', 'devicehistory'])

const mapStateToProps = (state, { match: { params } }) => {
  return {
    data: getSearchResults(state, params.propertyId),
    isFetching: fetchingSelector(state),
    hasSearchData: hasSearchData(state)
  }
}

const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  searchData: name => dispatch(search(name, params.propertyId)),
  fetchData: () => {
    dispatch(fetchSearchHistory(params.propertyId))
  }
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Search)
)
