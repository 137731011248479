import { all, call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken, getUserRole } from '../../authorization/selectors'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import { uniqBy } from 'lodash'
import {
  fetchGroupsAction,
  fetchGroupsStart,
  fetchGroupsSuccess,
  fetchGroupsError,
  fetchGroupUsersAction,
  fetchGroupUsersStart,
  fetchGroupUsersSuccess,
  fetchGroupUsersError,
  fetchSingleGroupAction,
  fetchSingleGroupStart,
  fetchSingleGroupSuccess,
  fetchSingleGroupError
} from './actions'

export function* fetchGroups({ propertyId, page, searchTerm, queryParams }) {
  try {
    yield put(fetchGroupsStart())
    const authToken = yield select(getToken)
    const userRole = yield select(getUserRole)
    let response

    if (searchTerm != null && searchTerm.length > 0) {
      response = yield call(
        api.searchGroupMessagingGroups,
        authToken,
        propertyId,
        searchTerm,
        queryParams
      )
    } else {
      //  Do property_staff check due to core api role check limitations, double refresh otherwise
      response = (userRole === "property_staff")
        ? []
        : yield call(
          api.getGroupMessagingGroups,
          authToken,
          propertyId,
          page
        )
    }

    yield put(fetchGroupsSuccess(response))
  } catch (error) {
    yield put(fetchGroupsError(error))
    log(`Failed to fetch groups`)
  }
}

export function* fetchSingleGroup({ groupId }) {
  try {
    yield put(fetchSingleGroupStart())
    const authToken = yield select(getToken)
    //  Do property_staff check due to core api role check limitations, double refresh otherwise
    const userRole = yield select(getUserRole)
    let response = (userRole === "property_staff")
      ? []
      : yield call(
        api.getGroupMessagingSingleGroup,
        authToken,
        groupId
      )

    yield put(fetchSingleGroupSuccess(response))
  } catch (error) {
    yield put(fetchSingleGroupError(error))
    log(`Failed to fetch groups`)
  }
}

export function* fetchGroupUsers({ groupIds, searchTerm }) {
  try {
    yield put(fetchGroupUsersStart())
    const authToken = yield select(getToken)
    let response
    if (searchTerm)
      response = yield all(groupIds.map(groupId =>
        api.getGroupUsers(authToken, groupId, searchTerm)
      ))
    else {
      //  Do property_staff check due to core api role check limitations, double refresh otherwise
      response = (userRole === "property_staff")
        ? []
        : yield all(groupIds.map(groupId =>
          api.getGroupUsers(authToken, groupId)
        ))
    }

    let users = []

    response.forEach(list => {
      users = [...users, ...list]
    })

    yield put(fetchGroupUsersSuccess(uniqBy(users, 'user.user_id')))
  } catch (error) {
    yield put(fetchGroupUsersError(error))
    log(`Failed to fetch group users`)
  }
}

function* watchFetchGroups() {
  yield takeLatest(fetchGroupsAction().type, fetchGroups)
}

function* watchFetchSingleGroup() {
  yield takeLatest(fetchSingleGroupAction().type, fetchSingleGroup)
}

function* watchFetchGroupUsers() {
  yield takeLatest(fetchGroupUsersAction().type, fetchGroupUsers)
}

export default [
  watchFetchGroups(),
  watchFetchGroupUsers(),
  watchFetchSingleGroup()
]
