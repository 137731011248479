import get from 'lodash/get'

export const deviceToHubProperties = device => {
  const identifier = (device.visible_identifier || device.identifier).toUpperCase()
  const isCellular = `${get(device, ['last_state', 'data', 'cellular'], 'False')}`.toUpperCase()
  return device
    ? {
        hubCommands: {
          name: 'Hub Commands'
        },
        status: {
          name: 'Status',
          value: `${get(device, ['last_state', 'status'])}`.toUpperCase(),
          text:
            get(device, ['last_state', 'status']) === 'online'
              ? 'Hub is currently online and running as expected'
              : 'Hub is currently offline. Please check if the hub is connected to power. If the hub is not running on cellular, then check that it is properly connected to a wifi router or modem.'
        },
        identifier: {
          name: 'Identifier',
          value: identifier,
          text: `Unique identifier for this hub: ${identifier}`
        },
        cellular: {
          name: 'Cellular',
          value: isCellular,
          text: 'If True this hub is running on its cellular connection'
        },
        battery: {
          name: 'Battery',
          value: `${get(device, [
            'last_state',
            'data',
            'battery'
          ])}`.toUpperCase(),
          text:
            `The Zego hub can run for a few hours on battery backup if they are disconnected from power. To ensure the hub doesn't lose power it is recommended that the hub always be securely connected to power`
        },
        firmware: {
          name: 'Firmware',
          value: `${get(device, [
            'last_state',
            'data',
            'fw_version'
          ]) || 'Unknown'}`.toUpperCase(),
          text: `This hub is running firmware version ${get(
            device,
            ['last_state', 'data', 'fw_version']
          ) || 'Unknown'}`
        }
      }
    : null
}
