// @flow

import { createMuiTheme } from '@material-ui/core/styles'

import colors from './color'
import type { Colors } from './color'

export type Theme = {|
  props: {|
    MuiButtonBase: {|
      color: string
    |},
    MuiAppBar: {|
      elevation: number
    |},
    MuiSelect: {|
      disableUnderline: boolean,
      autoFocus: boolean
    |},
    MuiListItem: {|
      disableRipple: boolean,
      disableGutters: boolean,
      button: boolean
    |},
    MuiButton: {
      root: {
        textTransform: string
      }
    }
  |},
  MuiToggleButton: {|
    root: {
      '&$selected': {
        color: string,
        backgroundColor: string,
        '&:hover': {
          backgroundColor: string
        }
      },
      '&:hover': {
        backgroundColor: string
      }
    }
  |},
  palette: Colors,
  typography: {|
    fontFamily: string,
    fontSize: string
  |},
  status: string,
  overrides: {|
    MuiDrawer: {|
      paper: {|
        backgroundColor: string,
        color: string
      |}
    |},
    MuiMobileStepper: {|
      progress: {|
        width: string,
        height: string
      |}
    |},
    MuiLinearProgress: {|
      bar1Determinate: {|
        backgroundColor: string
      |}
    |}
  |},
  custom: {|
    font: {|
      size: {|
        xs: string,
        sm: string,
        md: string,
        mdl: string,
        mdlg: string,
        lg: string,
        xl: string,
        xxl: string,
        xxxl: string
      |},
      weight: {|
        light: number,
        normal: number,
        regular: number
      |}
    |}
  |},
  screenSize: {|
    desktop: string
  |}
|}

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      color: 'secondary'
    },
    MuiAppBar: {
      elevation: 0
    },
    MuiSelect: {
      disableUnderline: true,
      autoFocus: false
    },
    MuiListItem: {
      disableGutters: true,
      button: false
    }
  },
  palette: {
    common: {
      black: colors.common.black,
      white: colors.common.white,
      main: colors.common.main
    },
    primary: {
      navy: {
        midnight: colors.primary.navy.midnight,
        twilight: colors.primary.navy.twilight,
        spaceBlue: colors.primary.navy.spaceBlue
      },
      green: {
        seeFoam: colors.primary.green.seeFoam,
        mint: colors.primary.green.mint,
        hintMint: colors.primary.green.hintMint,
        iceberg: colors.primary.green.iceberg
      },
      main: colors.primary.main,
      dark: colors.primary.dark,
      light1: colors.primary.light1
    },
    secondary: {
      yellow: {
        golden: colors.secondary.yellow.golden,
        solarFlare: colors.secondary.yellow.solarFlare
      },
      pink: {
        deepRed: colors.secondary.pink.deepRed,
        coral: colors.secondary.pink.coral
      },
      main: colors.primary.green.mint,
      main1: colors.secondary.main1,
      main3: colors.secondary.main3,
      main4: colors.secondary.main4
    },
    neutrals: {
      deepGray: colors.neutrals.deepGray,
      darkGray: colors.neutrals.darkGray,
      mediumGray: colors.neutrals.mediumGray,
      warmGray: colors.neutrals.warmGray,
      coolGray: colors.neutrals.coolGray,
      lightGray: colors.neutrals.lightGray,
      underlineGray: colors.neutrals.underlineGray
    },
    blue: {
      main: colors.blue.main
    },
    grey: {
      main: colors.grey.main,
      light7: colors.grey.light7
    },
    text: {
      black: colors.text.black,
      black1: colors.text.black1,
      grey: colors.text.grey,
      white: colors.text.white,
      light: colors.text.light
    },
    alternates: {
      brightBlue: colors.alternates.brightBlue,
      brightPurple: colors.alternates.brightPurple
    },
    alert: {
      success: colors.alerts.success,
      warning: colors.alerts.warning,
      error: colors.alerts.error,
      errorDark: colors.alerts.errorDark
    },
    background: {
      default: colors.common.white
    }
  },
  typography: {
    fontFamily: 'acumin-pro, neuzeit-grotesk, sans-serif',
    fontSize: '12px'
  },
  //status: colors.status,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: colors.primary.light1,
        color: '#fff'
      }
    },
    MuiMobileStepper: {
      progress: {
        width: '100%',
        height: '10px',
        backgroundColor: colors.neutrals.coolGray
      }
    },
    MuiLinearProgress: {
      bar1Determinate: {
        // backgroundColor: colors.secondary.light2
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontSize: '16px',
        lineHeight: '1.4em'
      }
    },
    MuiToggleButton: {
      root: {
        '&$selected': {
          color: colors.primary.navy.midnight,
          backgroundColor: colors.primary.green.mint,
          '&:hover': {
            backgroundColor: colors.primary.green.mint
          }
        },
        '&:hover': {
          backgroundColor: colors.neutrals.mediumGray
        }
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `1px solid ${colors.neutrals.coolGray}`
        },
        '&:after': {
          borderBottom: `1px solid ${colors.primary.green.mint}`
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `1px solid ${colors.neutrals.coolGray}`
        }
      },
      input: {
        flexGrow: 1,
        width: 'unset'
      }
    },
    MuiTooltip: {
      popper: {
        opacity: 1
      },
      tooltip: {
        backgroundColor: colors.common.white,
        color: colors.text.light,
        fontSize: '12px',
        borderRadius: '0 30px 30px 15px',
        boxShadow: '2px 7px 20px 0 rgba(0,0,0,0.1)',
        maxWidth: '384px',
        padding: '20px'
      }
    },
    MuiCardContent: {
      root: {
        paddingLeft: '24px',
        paddingRight: '24px'
      }
    },
    MuiTab: {
      labelContainer: {
        width: 'auto'
      }
    }
  },
  custom: {
    font: {
      size: {
        xxs: '8px',
        xs: '10px',
        sm: '12px',
        md: '14px',
        mdl: '16px',
        mdlg: '18px',
        lg: '24px',
        xl: '30px',
        xl36: '36px',
        xl48: '48px',
        xxl: '50px',
        xxxl: '70px',
        xxxxl: '72px'
      },
      weight: {
        light: 300,
        regular: 400,
        normal: 500,
        bold: 700
      }
    }
  },
  screenSize: { desktop: '1024px' }
})

export default theme
