import styled from '@emotion/styled'

export const Container = styled('div')`
  width: ${({ fullWidth }) => fullWidth && '100%'};
  height: ${({ fullHeight }) => fullHeight && '100%'};
  display: flex;
  display: -webkit-flex;
  -webkit-flex-direction: ${({ direction }) =>
    direction ? direction : 'row'}};
  flex-direction: ${({ direction }) => (direction ? direction : 'row')}};
`

export const Item = styled('div')`
  flex: ${({ flex }) => (flex ? flex : 'none')}};
  -webkit-flex: ${({ flex }) => (flex ? flex : 'none')}};
  flex-direction: ${({ direction }) => (direction ? direction : 'row')}};

  ${({ displayFlex }) => displayFlex && `display: flex;`};
  ${({ flexEnd }) => flexEnd && `justify-content: flex-end`};
  ${({ flexCenter }) => flexCenter && `justify-content: center`};
  ${({ flexStart }) => flexStart && `justify-content: flex-start`};
  ${({ alignItemsCenter }) => alignItemsCenter && `align-items: center`};
  ${({ alignItemsRight }) => alignItemsRight && `align-items: flex-end`};
  ${({ alignItemsLeft }) => alignItemsLeft && `align-items: flex-start`};
`
