import { normalize, schema } from 'normalizr'
import get from 'lodash/get'

const createSchema = entityName => new schema.Entity(entityName)

const normalizeArray = (data, entityName) => {
  const schema = createSchema(entityName)
  const listSchema = [schema]

  return normalize(data, listSchema)
}

export const normalizeArraySectionData = (payload, section, state) => {
  const reducerKey = section.toLowerCase()
  const { entities, result } = normalizeArray(payload, reducerKey)
  return {
    ...state,
    byId: entities[reducerKey] || {},
    allIds: result
  }
}

const getHeaderItem = (headers, key) => parseInt(get(headers, key, 0), 10)

export const normalizePaginatedResponseWithHeader = ({ data, headers }) => {
  const total = getHeaderItem(headers, 'total')
  return {
    data,
    total: data.length > total ? data.length : total,
    totalPages: getHeaderItem(headers, 'total-pages'),
    perPage: getHeaderItem(headers, 'per-page'),
    currentPage: getHeaderItem(headers, 'page-number')
  }
}
