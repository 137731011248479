// @flow

import React, { Fragment } from 'react'
import { withTheme } from 'emotion-theming'
import type { Node } from 'react'
import { Button, CommonIconText } from './styles'
import { CommonIcon } from '@icons'

type Props = {
  name?: string,
  children?: Node,
  secondary?: boolean,
  secondaryLight?: boolean,
  left?: boolean,
  elong?: boolean,
  long?: boolean,
  thin?: boolean
}

const Btn = ({ name, children, ...props }: Props) => (
  <Button {...props}>
    {name}
    {children}
  </Button>
)

export const DefaultBackButton = withTheme(({ theme, disabled }) => (
  <Fragment>
    <CommonIcon
      name="arrow_left"
      height="12px"
      width="24px"
      fill={disabled ? theme.palette.grey.main : theme.palette.common.black}
    />
    <CommonIconText disabled={disabled}>BACK</CommonIconText>
  </Fragment>
))

export const DefaultNextButton = withTheme(({ theme, disabled }) => (
  <Fragment>
    <CommonIconText disabled={disabled}>NEXT</CommonIconText>
    <CommonIcon
      name="arrow_right"
      height="12px"
      width="24px"
      fill={disabled ? theme.palette.grey.main : theme.palette.common.black}
    />
  </Fragment>
))

export default Btn
