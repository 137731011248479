import styled from '@emotion/styled'
import Button from '@material-ui/core/Button'

export const FabButton = styled(Button)`
  position: absolute;
  right: 1.5em;
  bottom: 3.5em;
  z-index: 20;

  background-color: ${({ theme }) => theme.palette.primary.dark};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.green.mint};
  }
`
