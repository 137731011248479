import { createFetchActions } from '../helpers/actions/creators'

export const SURVEY_STATS  = 'SURVEY_STATS'
export const RESIDENT_SURVEYS  = 'RESIDENT_SURVEYS'

export const {
  fetchAction: fetchSurveyStats,
  fetchStartAction: fetchSurveyStatsStart,
  fetchSuccessAction: fetchSurveyStatsSuccess,
  fetchErrorAction: fetchSurveyStatsError
} = createFetchActions(SURVEY_STATS)

export const {
  fetchAction: fetchResidentSurveysAction,
  fetchStartAction: fetchResidentSurveysStart,
  fetchSuccessAction: fetchResidentSurveysSuccess,
  fetchErrorAction: fetchResidentSurveysError
} = createFetchActions(RESIDENT_SURVEYS)

export const fetchResidentSurveys = (userId) => ({
  type: fetchResidentSurveysAction().type,
  userId
})
