// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import { generate as shortid } from 'shortid'
import { withRouter, Link } from 'react-router-dom'
import { capitalizeFirstLetter } from 'zego-shared/utils/string'
import { formatDateMMDDYY } from 'zego-shared/utils/date'
import { withTheme } from 'emotion-theming'
import moment, { Moment } from 'moment'
import type { Theme } from '../../theme'
import type { Match } from 'react-router-dom'
import Grid, { GridItem } from '../Common/Grid'
import BaseCard from '@Common/Cards/BaseCard'
import Search from '../Common/Search/Search'
import Tabs from '@Common/Cards/Tabs'
import Flex, { FlexItem } from '@Common/Flex/FlexDirection'
import { CommonIcon } from '@icons'
import PaginatedTable from '../Common/PaginatedTable'
import Loader from '../Common/Loader'
import { CTAButtonAdd } from '../Common/CTAButtons'
import DropDown from '@Common/DropDown'
import Calendar from '@Common/Calendar'
import Header from '@Common/Header'

import TextField from '../Common/TextField'
import {
  Layout,
  Content,
  Container,
  StyledRow,
  StyledColumn,
  TableContainer,
  CalendarWrapper,
  SearchBarWrapper,
  HeaderTitleWrapper,
  InputSearchResults,
  InputSearchWrapper,
  TextInputWrapper,
  InputSearchResultsItem,
  SearchBarTextFieldClass
} from './styles'

type Props = {
  match: Match,
  theme: Theme,
  total: number,
  items: Object[],
  validPetTypes: Object[],
  validPetStatuses: Object[],
  validYears: Object[],
  validColors: Object[],
  validStates: Object[],
  perPage: number,
  fetchPage: number => mixed,
  searchRegistration: (term: ?string, page: ?number) => void,
  settings: {
    petRegistration: boolean,
    visitorRegistration: boolean,
    vehicleRegistration: boolean
  },
  filter?: string,
  hasData: boolean,
  isFetching: boolean,
  currentPage: number,
  propertyId: string,
  vehiclesMakes: Object[],
  fetchPage: (page: number, queryParams: ?{}) => void,
  filterVisitors: (page: number, queryParams: ?{}) => void,
  filterPets: (page: number, queryParams: ?{}) => void,
  filterVehicles: (page: number, queryParams: ?{}) => void,
  searchRegistration: (term: ?string, page: ?number) => void
}

type State = {
  currentPage: number,
  newItems: Object[],
  searchText: ?string,
  sortBy: ?string,
  startDate: Moment,
  endDate: Moment,
  moment: Moment,
  filteredMakes: Object[],
  activeTab: string,
  selectedMake: string,
  makeOrModel: string,
  showModelsInput: boolean,
  showMakesInput: boolean,
  showEndDateCal: boolean,
  showStartDateCal: boolean
}

const tabs = [
  {
    name: 'Need Approval',
    query: { filter: 'need-approval' },
    key: 'petRegistration'
  },
  { name: 'Pets', query: { filter: 'pets' }, key: 'petRegistration' },
  {
    name: 'Vehicles',
    query: { filter: 'vehicles' },
    key: 'vehicleRegistration'
  },
  {
    name: 'Visitors',
    query: { filter: 'visitors' },
    key: 'visitorRegistration'
  }
]

const statusData = [
  { name: 'Approved', id: 0 },
  { name: 'Rejected', id: 1 },
  { name: 'Pending', id: 2 }
]

const getSelectedStatus = value => {
  let id
  statusData.forEach(status => {
    if (status.name === value) id = status.id
  })

  return id || id === 0 ? { id, name: value } : null
}

class Registration extends React.Component<Props, State> {
  state = {
    sortBy: '',
    activeTab: '',
    searchText: '',
    currentPage: 0,
    makeOrModel: '',
    selectedMake: '',
    moment: moment(),
    endDate: moment(),
    startDate: moment(),
    filteredMakes: [],
    showMakesInput: false,
    showEndDateCal: false,
    showModelsInput: false,
    showStartDateCal: false,
    newItems: this.props.items
  }

  addInputRef = null
  wrapperRef: Element

  setWrapperRef = (node: Element | null) => {
    // $FlowDisableNextLine
    this.wrapperRef = node
  }

  handleOutsideClick = (event: MouseEvent) => {
    if (
      // $FlowDisableNextLine
      this.wrapperRef &&
      // $FlowDisableNextLine
      !ReactDOM.findDOMNode(this.wrapperRef).contains(
        ((event.target: any): Node)
      )
    ) {
      this.setState({
        showMakesInput: false,
        showEndDateCal: false,
        showModelsInput: false,
        showStartDateCal: false
      })
      document.removeEventListener('click', this.handleOutsideClick, false)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      const { items } = this.props
      this.setState({
        newItems: items
      })
    }

    if (this.addInputRef) {
      this.addInputRef.focus()
    }

    if (prevProps.filter !== this.props.filter) {
      this.setState({
        searchText: ''
      })
    }
  }

  tabConfig = filter => {
    if (filter === 'pets')
      return {
        flex: [1.8, 0.7, 1, 0.5, 1, 2.3],
        columns: [
          {
            key: 'name',
            title: 'PET',
            link: true
          },
          {
            key: 'type',
            headerDropdown: this.isPetsTab(),
            rowDropdown: false,
            dropdownSource: 'petTypes',
            resetTab: this.resetTab
          },
          {
            key: 'owner'
          },
          {
            key: 'unit'
          },
          {
            key: 'lastVaccinated',
            title: 'LAST VACCINATED'
          },
          {
            key: 'status',
            headerDropdown: this.isPetsTab(),
            rowDropdown: false,
            dropdownSource: 'petStatus',
            resetTab: this.resetTab
          }
        ]
      }
    if (filter === 'vehicles')
      return {
        flex: [1.8, 0.7, 1, 0.5, 1, 1, 1, 2.3],
        columns: [
          {
            key: 'plateNumber',
            title: 'VEHICLE PLATE',
            link: true
          },
          {
            key: 'owner'
          },
          {
            key: 'unit'
          },
          {
            key: 'make',
            sort: true,
            inputSearch: this.showMakesInputSearch(),
            onClick: this.toggleMakesInput,
            resetTab: this.resetTab
          },
          {
            key: 'model',
            sort: true,
            inputSearch: this.showModelsInputSearch(),
            onClick: this.toggleModelsInput,
            resetTab: this.resetTab
          },
          {
            key: 'year',
            headerDropdown: true,
            rowDropdown: false,
            dropdownSource: 'vehiclesYears',
            resetTab: this.resetTab
          },
          {
            key: 'color',
            headerDropdown: true,
            rowDropdown: false,
            dropdownSource: 'vehiclesColors',
            resetTab: this.resetTab
          },
          {
            key: 'state',
            headerDropdown: true,
            rowDropdown: false,
            dropdownSource: 'vehiclesStates',
            resetTab: this.resetTab
          }
        ]
      }
    if (filter === 'visitors')
      return {
        flex: [1.8, 0.7, 1, 0.5, 1, 2.3],
        columns: [
          {
            key: 'visitorName',
            title: 'VISITOR NAME',
            link: true
          },
          {
            key: 'visitingName',
            title: 'VISITING'
          },
          {
            key: 'unit'
          },
          {
            key: 'startDate',
            title: 'START DATE',
            datePicker: this.showStartDateCalendar(),
            sort: true,
            resetTab: this.resetTab,
            onClick: this.toggleStartDateCal
          },
          {
            key: 'endDate',
            title: 'END DATE',
            datePicker: this.showEndDateCalendar(),
            sort: true,
            resetTab: this.resetTab,
            onClick: this.toggleEndDateCal
          },
          {
            key: 'phoneNumber',
            title: 'PHONE'
          }
        ]
      }
  }

  isPetsTab = () => {
    const { filter } = this.props
    return filter === 'pets'
  }

  getActiveTab = () => {
    const { activeTab } = this.state

    return activeTab
  }

  getTabs = () => {
    const { settings } = this.props
    return tabs.reduce((newTabs, tab) => {
      if (settings[tab.key]) {
        newTabs.push(tab)
      }
      return newTabs
    }, [])
  }

  changeStatus = (_registrationId, _value, _selected) => {
    // this.props.changeRegistrationStatus(selected.name, registrationId)
  }

  createColumns = ({ columns }: any, moment) => {
    const {
      sortBy,
      activeTab,
      makeOrModel,
      filteredMakes,
      showModelsInput,
      showMakesInput
    } = this.state
    const { theme } = this.props

    const isFiltered = filteredMakes.length > 0 && makeOrModel.length > 1
    const noCapitalizeTabs = ['year', 'startDate', 'endDate']
    const activeFilter = noCapitalizeTabs.includes(activeTab)
      ? sortBy
      : capitalizeFirstLetter(sortBy)

    return columns.map(
      (
        {
          key,
          title,
          sort,
          headerDropdown,
          dropdownSource,
          inputSearch,
          datePicker,
          resetTab,
          onClick
        },
        index
      ) => {
        title = title ? title : key.toUpperCase()
        const options = this.props[dropdownSource]
        const isActiveTab = key === this.getActiveTab()
        const value = (
          <StyledColumn
            key={shortid()}
            index={index + 1}
            activeTab={isActiveTab}>
            {headerDropdown && !isActiveTab ? (
              <DropDown
                handleChange={this.sortByType}
                selectedItem={options[0]}
                data={options}
                style={{
                  textTransform: 'uppercase',
                  minWidth: '70px',
                  textAlign: 'right'
                }}
              />
            ) : isActiveTab ? (
              <React.Fragment>
                {headerDropdown && (
                  <DropDown
                    handleChange={this.sortByType}
                    selectedItem={options[0]}
                    data={options}
                    style={{
                      textTransform: 'uppercase',
                      minWidth: '70px',
                      textAlign: 'right'
                    }}
                  />
                )}
                <HeaderTitleWrapper
                  clickable={onClick ? true : false}
                  onClick={onClick ? () => onClick() : () => {}}>
                  {!headerDropdown && title}
                  {!headerDropdown && sort && (
                    <CommonIcon
                      name="arrow_down"
                      width="0.6em"
                      height="0.6em"
                      style={{ marginLeft: '0.5em' }}
                    />
                  )}
                </HeaderTitleWrapper>{' '}
                / {activeFilter}
                <HeaderTitleWrapper
                  onClick={resetTab ? () => resetTab() : () => {}}>
                  <CommonIcon
                    name="close"
                    width="0.6em"
                    height="0.6em"
                    fill={theme.palette.primary.green.mint}
                    strokeWidth={6}
                    stroke={theme.palette.primary.green.mint}
                    style={{ marginLeft: '0.5em' }}
                  />
                </HeaderTitleWrapper>
              </React.Fragment>
            ) : (
              <HeaderTitleWrapper
                clickable={onClick ? true : false}
                onClick={onClick ? () => onClick() : () => {}}>
                {title}
                {sort && (
                  <CommonIcon
                    name="arrow_down"
                    width="0.6em"
                    height="0.6em"
                    style={{ marginLeft: '0.5em' }}
                  />
                )}
              </HeaderTitleWrapper>
            )}
            {datePicker && (
              <CalendarWrapper ref={this.setWrapperRef}>
                <Calendar
                  flex={1}
                  moment={moment}
                  handleChange={this.handleCalendarChange}
                  previousDatesEnabled
                />
              </CalendarWrapper>
            )}
            {inputSearch && (
              <InputSearchWrapper
                isFiltered={showMakesInput && isFiltered}
                ref={this.setWrapperRef}>
                <TextInputWrapper>
                  <TextField
                    inputRef={input => {
                      this.addInputRef = input
                    }}
                    fullWidth
                    value={makeOrModel}
                    onChange={this.handleInputChange}
                    textAlign={'left'}
                    placeholder={
                      showModelsInput
                        ? 'type the model you are looking for'
                        : 'type the make you are looking for'
                    }
                    onKeyPress={({ key }) =>
                      key === 'Enter' && this.onPressEnterKey()
                    }
                  />
                </TextInputWrapper>
                <InputSearchResults isFiltered={showMakesInput && isFiltered}>
                  {this.getFilteredMakes()}
                </InputSearchResults>
              </InputSearchWrapper>
            )}
          </StyledColumn>
        )
        return { value }
      }
    )
  }

  createRows = ({ columns }: any) => {
    const { filter, propertyId } = this.props
    const linkObject =
      filter == null || filter === 'need-approval' ? 'pets' : filter
    const { newItems } = this.state

    let rows = []

    if (newItems) {
      rows = newItems.map(({ id, ...rowData }, rowIndex) => {
        return columns.map(({ key, link, rowDropdown }, index) => {
          const rawValue = rowData[key]
          const selectedStatus = getSelectedStatus(rawValue)
          const value = (
            <StyledRow
              firstRow={rowIndex === 0}
              index={index + 1}
              activeTab={key === this.getActiveTab()}>
              {rowDropdown ? (
                <DropDown
                  handleChange={this.changeStatus.bind(this)}
                  selectedItem={selectedStatus}
                  data={statusData}
                />
              ) : link ? (
                <Link
                  to={`/properties/${propertyId}/registration/${linkObject}/${id}`}>
                  {rawValue || 'NA'}
                </Link>
              ) : (
                rawValue
              )}
            </StyledRow>
          )
          return { value }
        })
      })
    }

    return rows
  }

  searchRegistration = searchText => {
    this.setState({ searchText }, () => {
      this.props.searchRegistration(searchText)
    })
  }

  showModelsInputSearch = () => {
    return this.state.showModelsInput
  }

  showMakesInputSearch = () => {
    return this.state.showMakesInput
  }

  toggleMakesInput = () => {
    if (!this.state.showMakesInput) {
      document.addEventListener('click', this.handleOutsideClick, false)
    }
    if (this.state.showMakesInput) {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }

    this.setState({
      showModelsInput: false,
      showMakesInput: !this.state.showMakesInput,
      makeOrModel: ''
    })
  }

  toggleModelsInput = () => {
    if (!this.state.showModelsInput) {
      document.addEventListener('click', this.handleOutsideClick, false)
    }
    if (this.state.showModelsInput) {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }
    this.setState({
      showMakesInput: false,
      showModelsInput: !this.state.showModelsInput,
      makeOrModel: ''
    })
  }

  showEndDateCalendar = () => {
    return this.state.showEndDateCal
  }

  showStartDateCalendar = () => {
    return this.state.showStartDateCal
  }

  toggleStartDateCal = () => {
    if (!this.state.showStartDateCal) {
      document.addEventListener('click', this.handleOutsideClick, false)
    }
    if (this.state.showStartDateCal) {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }
    this.setState({
      showEndDateCal: false,
      showStartDateCal: !this.state.showStartDateCal,
      moment: moment()
    })
  }

  toggleEndDateCal = () => {
    if (!this.state.showEndDateCal) {
      document.addEventListener('click', this.handleOutsideClick, false)
    }
    if (this.state.showEndDateCal) {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }

    this.setState({
      showEndDateCal: !this.state.showEndDateCal,
      showStartDateCal: false,
      moment: moment()
    })
  }

  handleCalendarChange = (selectedDate: Moment) => {
    const page = 1
    const { filter } = this.props
    const { showStartDateCal, showEndDateCal } = this.state

    if (filter === 'visitors') {
      if (showStartDateCal) {
        this.setState(
          {
            activeTab: 'startDate',
            sortBy: formatDateMMDDYY(selectedDate),
            currentPage: 0,
            startDate: moment(selectedDate).toISOString(),
            moment: selectedDate,
            showStartDateCal: false
          },
          () => {
            this.props.filterVisitors(page, {
              start_date: this.state.startDate
            })
          }
        )
        document.removeEventListener('click', this.handleOutsideClick, false)
      }

      if (showEndDateCal) {
        this.setState(
          {
            activeTab: 'endDate',
            sortBy: formatDateMMDDYY(selectedDate),
            currentPage: 0,
            showEndDateCal: false,
            endDate: moment(selectedDate).toISOString(),
            moment: selectedDate
          },
          () => {
            this.props.filterVisitors(page, { end_date: this.state.endDate })
          }
        )
        document.removeEventListener('click', this.handleOutsideClick, false)
      }
    }
  }

  onPressEnterKey = () => {
    const { showModelsInput, showMakesInput, makeOrModel } = this.state
    const { fetchPage } = this.props
    const page = 1

    if (showModelsInput) {
      this.setState({
        activeTab: 'model',
        sortBy: makeOrModel,
        currentPage: 0,
        showModelsInput: false
      })

      document.removeEventListener('click', this.handleOutsideClick, false)
      return fetchPage(page, { model: makeOrModel })
    }

    if (showMakesInput) {
      this.setState({
        activeTab: 'make',
        sortBy: makeOrModel,
        currentPage: 0,
        showMakesInput: false
      })

      document.removeEventListener('click', this.handleOutsideClick, false)
      return fetchPage(page, { make: makeOrModel })
    }
  }

  onSelectMake = selectedMake => {
    const { showMakesInput } = this.state
    const { fetchPage } = this.props
    const page = 1

    this.setState({
      makeOrModel: selectedMake
    })

    if (showMakesInput) {
      this.setState({
        activeTab: 'make',
        sortBy: selectedMake,
        currentPage: 0,
        showMakesInput: false
      })

      document.removeEventListener('click', this.handleOutsideClick, false)
      return fetchPage(page, { make: selectedMake })
    }
  }

  getFilteredMakes = () => {
    const { filteredMakes, showMakesInput, makeOrModel } = this.state

    const filtered = filteredMakes
      ? filteredMakes.map(make => (
          <InputSearchResultsItem
            key={make.name}
            onClick={() => this.onSelectMake(make.name)}>
            {make.name}
          </InputSearchResultsItem>
        ))
      : ''

    if (showMakesInput && makeOrModel.length > 1) {
      return filtered
    }
  }

  handleInputChange = event => {
    const makeOrModel = event.target.value
    const { showMakesInput } = this.state
    this.setState({ makeOrModel })

    if (showMakesInput) {
      const { vehiclesMakes } = this.props

      const filteredMakes = vehiclesMakes.filter(
        make =>
          `${make.id}`.toUpperCase().indexOf(makeOrModel.toUpperCase()) >= 0
      )

      this.setState({
        filteredMakes
      })
    }
  }

  changePage = page => {
    this.setState({ currentPage: page - 1 })
    const { searchText, sortBy, activeTab, startDate, endDate } = this.state
    const { searchRegistration, fetchPage } = this.props

    if (searchText) {
      return searchRegistration(searchText, page)
    }

    if (activeTab === 'type') {
      return fetchPage(page, { type: sortBy })
    }

    if (activeTab === 'status') {
      return fetchPage(page, { status: sortBy })
    }

    if (activeTab === 'make') {
      return fetchPage(page, { make: sortBy })
    }

    if (activeTab === 'model') {
      return fetchPage(page, { model: sortBy })
    }

    if (activeTab === 'year') {
      return fetchPage(page, { year: sortBy })
    }

    if (activeTab === 'color') {
      return fetchPage(page, { color: sortBy })
    }

    if (activeTab === 'state') {
      return fetchPage(page, { state: sortBy })
    }

    if (activeTab === 'startDate') {
      return fetchPage(page, { start_date: startDate })
    }

    if (activeTab === 'endDate') {
      return fetchPage(page, { start_date: endDate })
    }

    fetchPage(page)
  }

  sortByType = (value, selected) => {
    const {
      filter,
      validPetTypes,
      validPetStatuses,
      validYears,
      validColors,
      validStates
    } = this.props

    const page = 1

    if (filter === 'pets') {
      const valueCapitalized = capitalizeFirstLetter(value)
      const ispetType =
        value !== 'TYPE' && validPetTypes.includes(valueCapitalized)
      const isStatus =
        value !== 'STATUS' && validPetStatuses.includes(valueCapitalized)

      if (ispetType) {
        this.setState({
          activeTab: 'type',
          sortBy: value,
          currentPage: 0
        })

        return this.props.filterPets(page, { type: value })
      }

      if (isStatus) {
        this.setState({
          activeTab: 'status',
          sortBy: value,
          currentPage: 0
        })

        return this.props.filterPets(page, { status: value })
      }
    }

    if (filter === 'vehicles') {
      const isYear = value !== 'YEAR' && validYears.includes(value)
      const isColor = !isYear && validColors.includes(value)

      const isState = value !== 'STATE' && validStates.includes(value)

      if (isYear) {
        this.setState({
          activeTab: 'year',
          sortBy: value,
          currentPage: 0
        })

        return this.props.filterVehicles(page, { year: value })
      }

      if (isColor) {
        this.setState({
          activeTab: 'color',
          sortBy: value,
          currentPage: 0
        })

        return this.props.filterVehicles(page, { color: value })
      }

      if (isState) {
        this.setState({
          activeTab: 'state',
          sortBy: value,
          currentPage: 0
        })

        return this.props.filterVehicles(page, { state: value })
      }
    }
  }

  resetTab = () => {
    const { fetchPage } = this.props
    const page = 1

    this.setState({
      activeTab: '',
      sortBy: '',
      makeOrModel: '',
      currentPage: 0,
      moment: moment()
    })

    fetchPage(page)
  }

  render() {
    let {
      isFetching,
      theme,
      filter,
      total,
      perPage = 8,
      items,
      match: {
        params: { propertyId }
      }
    } = this.props

    const { searchText, currentPage, moment } = this.state

    const showLoader = isFetching && (searchText == null || searchText === '')

    if (filter == null || filter === 'need-approval') {
      filter = 'pets'
    }

    const config = this.tabConfig(filter)
    const columns = this.createColumns(config, moment)
    propertyId = propertyId == null ? '' : propertyId
    return (
      <React.Fragment>
        <GridItem columnStart={1} columnSpan={3} rowStart={1} rowSpan={12}>
          <Grid>
            <BaseCard
              columnStart={1}
              columnSpan={8}
              rowStart={1}
              rowSpan={12}
              isLeftSideBG={true}
              header={() => <Header alternateFontSize>Registration</Header>}
              showShadow={false}
              transparentBackground
              content={() => (
                <Content>
                  <b>Keep track</b> of all pets, vehicles
                  <br />
                  and visitors on the premises.
                  <br />
                  <br />
                  We’ll alert you when a new
                  <br />
                  registration needs your approval.
                </Content>
              )}
            />
          </Grid>
        </GridItem>
        <GridItem columnStart={2} columnSpan={11} rowStart={1} rowSpan={12}>
          <Container>
            <Layout>
              <Grid>
                <CTAButtonAdd
                  to={`/properties/${propertyId}/registration/${filter}/new`}
                  color={theme.palette.common.white}
                />
                <GridItem
                  columnStart={4}
                  columnSpan={9}
                  rowStart={2}
                  rowSpan={2}>
                  <Flex fullWidth>
                    <FlexItem flex={4}>
                      <Tabs
                        selectTextColor={theme.palette.common.black}
                        tabs={this.getTabs()}
                        customStyle={`
                          .tab-root {
                            color: ${theme.palette.grey.main};
                            font-weight: ${theme.custom.font.weight.light};
                          }
                        `}
                      />
                    </FlexItem>
                    <FlexItem flex={1} displayFlex flexEnd>
                      <SearchBarWrapper>
                        <Search
                          searchText={searchText}
                          data={[]}
                          hasSearchData={false}
                          isFetching={false}
                          onResultClick={() => console.log('clicked')}
                          searchData={this.searchRegistration}
                          textFieldClassName={SearchBarTextFieldClass}
                          minSearchLength={-1}
                        />
                      </SearchBarWrapper>
                    </FlexItem>
                  </Flex>
                </GridItem>
                <GridItem
                  columnStart={3}
                  columnSpan={10}
                  rowStart={3}
                  rowSpan={10}>
                  <TableContainer>
                    {showLoader && <Loader />}
                    {!showLoader && (
                      <PaginatedTable
                        total={total || items.length}
                        flex={[1.8, 0.7, 1, 0.5, 1, 1, 1, 2.3]}
                        currentPage={currentPage}
                        rowsPerPage={perPage}
                        columns={columns}
                        rows={this.createRows(config)}
                        changePage={this.changePage}
                      />
                    )}
                  </TableContainer>
                </GridItem>
              </Grid>
            </Layout>
          </Container>
        </GridItem>
      </React.Fragment>
    )
  }
}

export default withRouter(withTheme(Registration))
