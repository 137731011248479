import { select, put, call, takeLatest } from 'redux-saga/effects'
import api from '../../../../utils/api'
import log from '../../../../utils/logger'
import { noPropertySelected } from '../../../../utils/messages'
import { getToken, getUserRole } from '../../../authorization/selectors'
import {
  CHANGE_PROPERTY,
  updatePropertyRenderReady,
  selectProperty,
  fetchPropertiesAction,
  fetchPropertiesStart,
  fetchPropertiesError,
  fetchPropertiesSuccess,
  FETCH_PROPERTY_INFO
} from '../actions'
import { showSnackbar } from '../../../snackbar/actions'
import { fetchCompaniesPropertiesAndSelect } from '../../../select/companies/actions'
import { getSelectedCompanyID } from '../../companies/selectors'

export function* changeProperty({ id }) {
  yield put(updatePropertyRenderReady(false))
  yield put(selectProperty(id))
}

export function* fetchPropertyInfo({ id, url, history }) {
  try {
    if (id === -1) throw new Error(noPropertySelected)
    const authToken = yield select(getToken)
    const response = yield call(api.getPropertyInfo, authToken, id)

    yield put(
      fetchCompaniesPropertiesAndSelect(response.company_id, history, id, url)
    )

  } catch (error) {
    yield put(showSnackbar('Error fetching property', 'error'))
    log(`Error fetching property: ${error}`)
  }
}

export function* fetchProperties({ page }) {
  try {
    yield put(fetchPropertiesStart())
    const authToken = yield select(getToken)
    const role = yield select(getUserRole)
    const companyId = yield select(getSelectedCompanyID)
    let properties

    if (role === 'super_admin') {
      properties = yield call(
        api.getCompaniesProperties,
        authToken,
        companyId,
        page
      )
    } else {
      properties = yield call(api.getProperties, authToken, page)
    }

    yield put(fetchPropertiesSuccess(properties))
  } catch (error) {
    yield put(fetchPropertiesError(error))
    log(`Failed to fetch properties. Error: ${error}`)
  }
}

function* watchFetchPropertyInfo() {
  yield takeLatest(FETCH_PROPERTY_INFO, fetchPropertyInfo)
}

function* watchChangeProperty() {
  yield takeLatest(CHANGE_PROPERTY, changeProperty)
}

function* watchFetchProperties() {
  yield takeLatest(fetchPropertiesAction().type, fetchProperties)
}

export default [watchChangeProperty(), watchFetchPropertyInfo(), watchFetchProperties()]
