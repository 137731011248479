import { connect } from 'react-redux'
import DevicesList from './DevicesList'
import {
  getDeviceList,
  hasData,
  hasDevices,

} from '../../../../store/Unit/selectors'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {

} from 'zego-shared/store/devices/actions'

const fetchingSelector = createIsFetchingSelector(['devices', 'unit'])

const mapStateToProps = (
  state,
  {
    match: {
      params: { deviceID }
    }
  }
) => ({
  isFetching: fetchingSelector(state),
  devices: getDeviceList(state),
  hasData: hasData(state, deviceID),
  hasDevices: hasDevices(state)
})

export default connect(
  mapStateToProps,
  null
)(DevicesList)
