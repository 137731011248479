// @flow
import React from 'react'
import Header from '@Common/Header'

import Tabs from '../Tabs'
import type { SmartSummaryHeaderProps } from './types'
import FlexDirection from '../../Flex/FlexDirection'
import { RightPanel, CaptionHeading } from './styles'

const SummaryHeader = ({
  unitCount,
  captionHeading,
  mainHeading,
  headerTabs,
  RightPanelChildren
}: SmartSummaryHeaderProps) => {
  const hasHeaderTabs = headerTabs && headerTabs.length > 0
  return (
    <React.Fragment>
      <FlexDirection direction="column">
        <CaptionHeading>{captionHeading}</CaptionHeading>
        <Header blackText variant="display1" gutterBottom>
          {unitCount} {mainHeading}
        </Header>
      </FlexDirection>
      <RightPanel>
        {hasHeaderTabs && (
          <Tabs
            tabStyle={2}
            customStyle={`
              position: absolute;
              top: 0;
              right: 0;
            `}
            styleClass="custom-tab"
            tabs={headerTabs}
          />
        )}
        {RightPanelChildren}
      </RightPanel>
    </React.Fragment>
  )
}

SummaryHeader.defaultProps = {
  RightPanelChildren: null
}

export default SummaryHeader
