// @flow
import React from 'react'

import FlexDirection from '@Common/Flex/FlexDirection'
import Header from '@Common/Header'

import { CaptionHeading } from './styles'

const SummaryHeader = ({
  captionHeading,
  mainHeading
}: {
  captionHeading: string,
  mainHeading: string
}) => {
  return (
    <React.Fragment>
      <FlexDirection direction="column">
        <CaptionHeading>{captionHeading}</CaptionHeading>
        <Header blackText isSummary variant="display1">{mainHeading}</Header>
      </FlexDirection>
    </React.Fragment>
  )
}

export default SummaryHeader
