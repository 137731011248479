// @flow

import React, { Fragment } from 'react'
import SetupStep from './SetupStep'
import Flex from '@Common/Flex'
import { ButtonLight, ButtonDark } from './styles'

import type { SetupStepProps } from './types'

type State = {
  ready: boolean
}

export default class TestDeviceResult extends React.Component<
  SetupStepProps,
  State
> {
  timer: ?TimeoutID

  state = {
    ready: false
  }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ ready: true })
    }, 5000)
  }

  componentWillUnmount() {
    this.timer = null
  }

  render() {
    const { handleBackStep, handleNextStep, afterTestInstruction } = this.props
    const { ready } = this.state

    return (
      <SetupStep
        title={
          <Flex justifySpaceBetween>
            {ready ? (
              <Fragment>
                {afterTestInstruction}
                <div>
                  <ButtonLight onClick={handleBackStep}>TRY AGAIN</ButtonLight>
                  <ButtonDark onClick={handleNextStep}>YES</ButtonDark>
                </div>
              </Fragment>
            ) : (
              <div>Running Test...</div>
            )}
          </Flex>
        }
      />
    )
  }
}
