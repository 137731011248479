export const createCase = (url, data) => {
  const fields = {
    origin: 'Admin Web - Offline',
    retURL: window.location,
    external: 1,
    ...data
  }

  const customHiddenIframeName = 'WebToCase_API'
  if (!document.getElementById(customHiddenIframeName)) {
    const iFrame = document.createElement('iframe')
    iFrame.id = customHiddenIframeName
    iFrame.name = customHiddenIframeName
    iFrame.src = 'about:blank'
    iFrame.style.display = 'none'
    document.body.appendChild(iFrame)
  }
  const form = document.createElement('form')
  form.method = 'POST'
  form.action = url
  form.setAttribute('target', customHiddenIframeName)
  for (const fieldName in fields) {
    const sfInput = document.createElement('input')
    sfInput.name = fieldName
    sfInput.value = fields[fieldName]
    sfInput.setAttribute('type', 'hidden')
    form.appendChild(sfInput)
  }
  document.body.appendChild(form)
  form.submit()
  return Promise.resolve(() => true)
}
