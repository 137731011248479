// @flow
import React from 'react'
import { generate as shortid } from 'shortid'
import { withTheme } from 'emotion-theming'
import { Select } from '@material-ui/core'
import {
  FormControl,
  InputLabel,
  MenuItem,
  menuItemFocus,
  ButtonStyled
} from './styles'
import { selectItem } from 'zego-shared/utils/view/dropDown'

export type MenuItemType = {
  id: number | string,
  name: string
}

type Props = {
  data: Array<MenuItemType>,
  IconComponent: Node,
  handleChange: ?(string, selected?: any) => mixed,
  selectedItem: MenuItemType,
  classes: Object,
  width: string,
  label: string,
  defaultOption: MenuItemType,
  style: Object,
  hasMore: ?boolean,
  loadMore: ?Function,
  isFetching: ?boolean,
  weight: number,
  elementId?: string,
  className?: string
}

type State = {
  selected: MenuItemType
}

class DropDown extends React.Component<Props, State> {
  menuItems = null
  keyPress = ''
  state = {
    selected: this.props.selectedItem
  }

  componentDidMount() {
    this.menuItems = document.getElementsByClassName('menu-item-js')
  }

  componentDidUpdate(_prevProps, prevState) {
    const { selectedItem } = this.props
    const { selected } = this.state
    if (selected.id !== selectedItem.id) {
      this.setState(oldState => ({ ...oldState, selected: selectedItem }))
    }
    if (!this.menuItems) {
      this.menuItems = document.getElementsByClassName('menu-item-js')
    }
  }

  handleChange = ({ target: { value, name } }) => {
    const { data, handleChange } = this.props
    const selected = data.find(datum => `${datum.id}` === `${value}`)
    this.setState(
      {
        selected
      },
      () => {
        if (typeof handleChange === 'function') {
          handleChange(value, selected)
        }
      }
    )
  }

  onKeyPress = ({ key }) => {
    if (this.menuItems) {
      selectItem(key, this.menuItems)
    }
  }

  loadMore = event => {
    const { loadMore } = this.props
    event.stopPropagation()
    loadMore && loadMore()
  }

  render() {
    const {
      data,
      IconComponent,
      classes,
      width,
      label,
      defaultOption,
      style,
      hasMore,
      isFetching,
      weight,
      className
    } = this.props
    const { selected } = this.state
    return (
      <FormControl weight={weight} width={width} style={style} className={className}>
        {label && <InputLabel htmlFor={`${label}-id`}>{label}</InputLabel>}
        <Select
          inputProps={{
            id: label ? `${label}-id` : ''
          }}
          onKeyPress={this.onKeyPress}
          classes={classes}
          IconComponent={IconComponent}
          value={selected ? selected.id : data == null ? '' : data[0].id}
          onChange={this.handleChange}>
          {defaultOption ? (
            <MenuItem
              id={defaultOption.name.replace(/\s/g, '')}
              key={shortid()}
              value={defaultOption.id}
              disabled
              className="menu-item-js">
              {defaultOption.name}
            </MenuItem>
          ) : null}
          {data && data.map(datum => (
            <MenuItem
              id={datum.name ? datum.name.toString().replace(/\s/g, '') : ''}
              key={shortid()}
              value={datum.id}
              className={`menu-item-js ${menuItemFocus}`}>
              {datum.name}
            </MenuItem>
          ))}

          {hasMore && (
            <div key={shortid()}>
              <ButtonStyled onClick={this.loadMore} disableRipple>
                {isFetching ? 'Loading...' : 'Load More'}
              </ButtonStyled>
            </div>
          )}
        </Select>
      </FormControl>
    )
  }
}

export default withTheme(DropDown)
