import styled from '@emotion/styled'
import { css } from 'emotion'
import MaterialTextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { convertHexToRGB } from '../../utils/commonUtils'

export const HeaderTitleWrapper = styled('div')`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : '')};
`

export const Content = styled('p')`
  color: ${({ theme }) => `${theme.palette.common.white}`};
  line-height: 1.5em;
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  line-height: 26px;
  margin-top: 80px;
`

export const TableContainer = styled('div')`
  height: 84.5%;
  width: 80.5%;
  position: absolute;
  padding: 3em;
  background: ${({ theme }) => `${theme.palette.common.white}`};
  border-top-left-radius: 5px;
  right: -2em;
  bottom: -1em;
  box-shadow: ${({ theme }) =>
    `0 1px 10px 0 ${convertHexToRGB(theme.palette.neutrals.warmGray, 0.5)}`};
  th,
  td {
    padding-right: 0.5em !important;
  }
  tr {
    border-bottom: ${({ theme }) =>
      `1px solid ${theme.palette.neutrals.deepGray}`};
  }
`
export const Layout = styled('div')`
  width: 100%;
  &:after {
    content: ' ';
    background: ${({ theme }) => `${theme.palette.neutrals.lightGray}`};
    border-top-right-radius: 5px;
    width: 70%;
    height: calc(100% - 1em);
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: -15;
    top: 2em;
  }
`

export const Container = styled('div')`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`

export const TextField = styled(MaterialTextField)`
  width: 100%;
  height: 2em;
  margin-right: 1em;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.palette.neutrals.warmGray};
  padding: 0 15px;
  & input {
    color: ${({ theme }) => theme.palette.text.lightGrey};
    padding-top: 8px;
  }
`

export const StyledColumn = styled('div')`
  height: 4em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  ${({ index }) => index === 1 && `justify-content: flex-start`};
  ${({ index, activeTab }) =>
    index === 6 && !activeTab ? `justify-content: flex-end` : ''};
  ${({ activeTab, theme }) =>
    activeTab &&
    `color: ${theme.palette.primary.green.mint};
    background: ${theme.palette.secondary.light3};`};
`

export const StyledRow = styled('div')`
  height: ${({ firstRow }) => (firstRow ? `7em` : `4em`)};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: ${({ firstRow }) => (firstRow ? `flex-end` : `center`)};
  white-space: nowrap;
  position: relative;
  justify-content: center;
  ${({ index }) => index === 1 && `justify-content: flex-start`};
  ${({ index, activeTab }) =>
    index === 6 && !activeTab ? `justify-content: flex-end` : ''};
  ${({ activeTab, theme }) =>
    activeTab &&
    `color: ${theme.palette.primary.green.mint};
    background: ${theme.palette.secondary.light3};`};
  ${({ firstRow }) => firstRow && `padding-bottom: 1.5em;`};
  ${({ index, theme }) =>
    (index === 1 || index === 6) &&
    `color: ${theme.palette.common.black};
    * :not(svg):not(path) {
      color: ${theme.palette.common.black};
    }`};
`

export const FabButton = styled(Button)`
  position: absolute;
  bottom: 15px;
  right: 15px;
`

export const SearchBarWrapper = styled('div')``

export const SearchBarTextFieldClass = css`
  background-color: white !important;
  border: 1px solid lightgray !important;
`

export const CalendarWrapper = styled('div')`
  height: auto;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.2);
  margin-left: 65px;
  position: absolute;
  margin-top: 110px;
  z-index: 9999;
`

export const InputSearchWrapper = styled('div')`
  min-height: 40px;
  height: auto;
  width: 226px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 1px 15px 0
    ${({ theme }) => convertHexToRGB(theme.palette.grey.main)};
  position: absolute;
  margin-top: ${({ isFiltered }) => (isFiltered ? '80px' : '55px')};
  z-index: 9999;
  padding-right: 12px;
`
export const TextInputWrapper = styled('div')`
  height: 40px;
  width: 213px;
  padding: 5px;
`

export const InputSearchResults = styled('div')`
  height: ${({ isFiltered }) => (isFiltered ? '50px' : '')};
  width: 226px;
  margin-top: 20px;
`

export const InputSearchResultsItem = styled('div')`
  padding: 5px;
  padding-right: 10px;
  text-align: right;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.common.black};
  width: 100%;

  &:hover {
    background: ${({ theme }) => theme.palette.secondary.light3};
    cursor: pointer;
  }
`
