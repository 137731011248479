import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import PaginatedTable from '../Common/PaginatedTable'
import { withStyles } from '@material-ui/core/styles'
import { Button, Chip } from '@material-ui/core'
import DropDown from '@Common/DropDown'

export const DropDownStyled = styled(DropDown)`
font-weight: ${({ theme }) => theme.custom.font.weight.light};
`

export const FabButton = styled(Button)`
  position: fixed;
  bottom: 3.5em;
  right: 1.5em;
  height: 59px;
  width: 59px;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.green.mint};
  }
`

export const Loading = styled('div')`
  right: 50%;
  left: 50%;
  position: absolute;
  padding-top: 10px;
  padding-bottom: 15px;
  ~ #showMoreBtn {
    margin-top: 30px;
  }
`

export const StyledColumn = styled('div')`
  height: 4em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-start;
  justify-content: ${({ index }) =>
    index < 4 ? 'flex-start' : index < 8 ? 'center' : 'flex-end'};
`
export const StyledCell = styled('div')`
  text-transform: capitalize;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.neutrals.deepGray};
  display: flex;
  align-items: center;
  height: 42px;
  justify-content: ${({ index }) =>
    index < 4 ? 'flex-start' : index < 8 ? 'center' : 'flex-end'};
`
export const StyledFinalColumn = styled('div')`
  height: 4em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-end;
`
export const StyledFinalCell = styled('div')`
  text-transform: capitalize;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  justify-content: flex-end;
`

export const StyledTitleCard = styled('div')`
  margin-left: -30px;
  margin-right: -30px;
  padding: 30px 30px 60px 30px;
  background-color: ${({ theme }) => theme.palette.primary.green.iceberg};
`

export const StyledFilterText = styled('span')`
  font-weight: bold;
  margin-left: 8px;
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
`

export const StyledClearText = styled('a')`
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  margin-left: 15px;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
`
export const RedDotStyled = styled.div`
  height: 5px;
  width: 5px;
  background: ${({ theme }) => theme.palette.secondary.pink.coral};
  border-radius: 50%;
  margin: 2px 5px 0 0;
`

export const StyledLink = styled(Link)`
  text-decoration: ${({ noUnderline }) => noUnderline ? 'none' : 'underline'};
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  width: 100%;
  :visited {
    color: ${({ theme }) => theme.palette.alternates.brightPurple};
  }
  :hover {
    color: ${({ theme }) => theme.palette.secondary.yellow.golden};
  }

  & .text-left{
    text-align: center;
  }
`

export const StyledNoSurveysText = styled('p')`
  color: ${({ theme }) => theme.palette.neutrals.mediumGray};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
`

export const StyledRowText = styled('p')`
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.neutrals.deepGray};
`
export const StyledGreyRow = styled('div')`
  background-color: ${({ theme }) => theme.palette.neutrals.lightGray};
  width: 100%;
  height: 2em;
`

export const StyledSurveysColumn = styled('div')`
  height: 4em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  ${({ index }) => index === 8 && `justify-content: flex-end`};
`
export const StyledSurveysCell = styled('div')`
  text-transform: capitalize;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  justify-content: center;
  ${({ index }) => index === 8 && `justify-content: flex-end`};
`
export const StyledRenewalColumn = styled('div')`
  height: 4em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: ${({ index }) =>
    index < 4 ? 'flex-start' : index < 8 ? 'center' : 'flex-end'};
  ${({ index }) => (index === 5 || 6) && `cursor: pointer`};

  &.content-left {
    justify-content: flex-start
  }

  &.content-right {
    justify-content: flex-end
  }
`
export const StyledEmailLink = styled('a')`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
  font-weight: bold;
  margin: 0px 4px;
  :hover {
    color: ${({ theme }) => theme.palette.secondary.yellow.golden};
  }
`

export const StyledTable = styled(PaginatedTable)`
  margin-top: -40px;
  margin-left: 8px;
`

export const ResidentHappinessCard = styled('div')`
  position: absolute;
  right: 0;
  margin-top: 33px;
  width: 570px;
  height: 266px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 0 27px;
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  border-radius: 4px 0 0 4px;
`

export const ResidentHappinessContent = styled('div')`
  display: flex;
  justify-content: space-evenly;
`

export const CardHeader = styled('h2')`
  :after {
    margin-top: 10px;
    background-color: ${({ theme }) => theme.palette.primary.green.mint};
    width: auto;
    height: 3px;
    content: ' ';
  }
`

export const LegendRow = styled('p')`
  :before {
    display: inline-block;
    content: ' ';
    background-color: ${(props) => props.color};
    width: 5px;
    height: 5px;
    margin-right: 0.2em;
  }
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.custom.font.size.xs};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  margin-left: 2em;
  margin-top: 2em;
`

export const LegendLabel = styled('span')`
  margin-left: 0.2em;
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
`

export const LegendInline = styled('span')`
  :before {
    display: flex;
    content: ' ';
    background-color: ${(props) => props.color};
    width: 5px;
    height: 5px;
    margin-right: 0.2em;
  }
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.custom.font.size.xs};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  margin-left: 2em;
`

export const LegendInlineRow = styled('div')`
  display: flex;
  margin-top: 0.5em;
  padding: 0.3em;
  background-color: ${({ theme }) => theme.palette.primary.green.iceberg};
`

export const LegendLabelInline = styled('span')`
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
`

export const SurveyChartWrapper = styled('div')`
  margin: 0.2em;
`

export const HistoryChart = styled('div')`
  flex: 1;
  margin-left: 2em;
  display: flex;
`

export const HistoryChartItem = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 2em;
  align-items: center;
  color: ${({ theme }) => theme.palette.neutrals.mediumGray};
  font-size: ${({ theme }) => theme.custom.font.size.xs};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  :before {
    content: ' ';
    flex: 1;
  }
  div:last-child {
    margin-top: 0.3em;
  }
`

export const BarChart = styled('div')`
  width: 20px;
  height: ${(props) => props.value}%;
  background-color: ${(props) => props.color};
`

export const SurveysAvailableIndicator = styled('div')`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-top: 17px;
  margin-right: 5px;
  background-color: ${({ theme }) => theme.palette.secondary.pink.coral};
`

export const SeparationLine = styled('div')`
  width: 100%;
  height: 1px;
  border-bottom: 0.5px ;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.palette.neutrals.underlineGray};
`

export const StyledBorderButton = styled(Link)`
  border: solid;
  border-color: ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
  width: 16em;
  height: 3.6em;
  font-size: 0.6em;
  font-weight: 800;
  padding: 0.8em 1.5em;
  margin-top: 1.6em;
`

export const RowItem = styled('div')`
  display: flex;
  align-items: center;
`

export const BoldInfo = styled('span')`
  font-weight: 800;
  margin-left: 10px;
  `


export const Spacing = styled('div')`
  @media only screen and (max-height: 1080px) {
    margin-top: 9em;
  }
`

export const AfterTableSpacing = styled('div')`
  height: 118px;
`

export const RedDotImage = styled('img')`
  width: 5px;
  height: auto;
  margin-right: 9px;
`

export const FilterChipsWrapper = styled('div')`
  margin-left: 8px;
`

export const StyledCenteted = styled('div')`
  width: 100%;
  text-align: center;
`

export const StyledMimoColumn = styled('div')`
  height: 4em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
`
export const ShowMoreBtn = withStyles({
  root: {
    display: 'flex',
    marginTop: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '140px',
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  label: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})(Button)

const ChipStyles = (theme) => ({
  root: {
    margin: '15px 10px 5px 0px',
    textTransform: 'capitalize',
    height: '20px',
    fontSize: theme.custom.font.size.sm,
    fontWeight: theme.custom.font.weight.bold,
  },
  colorSecondary: {
    border: `1px solid ${theme.palette.primary.green.mint}`,
    backgroundColor: theme.palette.primary.green.iceberg,
  },
  colorPrimary: {
    border: `1px solid ${theme.palette.neutrals.mediumGray}`,
    backgroundColo: theme.palette.common.white,
    color: theme.palette.neutrals.mediumGray,
  },
})

export const StyledChip = withStyles(ChipStyles)(Chip)
