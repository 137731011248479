// @flow
import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import { FabButton } from '@Common/Button'
import { CommonIcon } from '@icons'
import type { Theme } from '../../../theme'
import { ImageBlock, Button } from '../AmenityShared/styles'
import 'cropperjs/dist/cropper.css'
import Cropper from 'react-cropper'
import { dataUrlToFile } from '../../../utils/fileUtils'

type Props = {
  readMode: boolean,
  image?: Object,
  theme: Theme,
  croppedImage?: Object | null,
  deleteImage: () => void,
  uploadImage: (event: SyntheticInputEvent<HTMLInputElement>) => mixed,
  uploadCroppedImage: (file: File) => void,
  showCropper: boolean
}

export default class AmenityLegacyImage extends React.Component<Props, {}> {
  cropper: any

  crop = () => {
    const { uploadCroppedImage, image } = this.props
    const fileType = image == null ? 'image/jpeg' : image.type
    const dataUrl = this.cropper.getCroppedCanvas().toDataURL(fileType)

    if (image) {
      uploadCroppedImage(dataUrlToFile(dataUrl, image.name, fileType))
    }
  }

  render() {
    const {
      readMode,
      image,
      croppedImage,
      deleteImage,
      uploadImage,
      theme,
      showCropper
    } = this.props

    return (
      <ImageBlock
        columnStart={1}
        columnSpan={5}
        rowStart={1}
        rowSpan={9}
        image={image}>
        {image ? (
          <Fragment>
            {!readMode && (
              <FabButton
                onClick={deleteImage}
                component={() => (
                  <CommonIcon
                    name="close"
                    width="0.7em"
                    height="0.7em"
                    fill={theme.palette.common.white}
                    strokeWidth={2}
                    stroke={theme.palette.common.white}
                  />
                )}
                size="2.7em"
                position="absolute"
                right="0em"
                top="0em"
                zIndex="100"
                background={theme.palette.primary.green.mint}
              />
            )}

            {!readMode && !croppedImage && showCropper && (
              <Fragment>
                <FabButton
                  onClick={this.crop}
                  component={() => (
                    <CommonIcon
                      name="tick"
                      width="0.7em"
                      height="0.7em"
                      fill={theme.palette.common.white}
                      strokeWidth={2}
                      stroke={theme.palette.common.white}
                    />
                  )}
                  size="2.7em"
                  position="absolute"
                  right="0em"
                  top="3em"
                  zIndex="1000"
                  background={theme.palette.primary.green.mint}
                />
                <Cropper
                  aspectRatio={2.84}
                  guides={true}
                  src={image.url}
                  ref={cropper => {
                    this.cropper = cropper
                  }}
                />
              </Fragment>
            )}
            {(readMode || !showCropper) && (
              <img
                src={
                  croppedImage
                    ? croppedImage.url
                    : image instanceof File
                    ? image.url
                    : image
                }
                alt=""
              />
            )}
          </Fragment>
        ) : (
          !readMode && (
            <Fragment>
              <Typography component="h2">Upload Image</Typography>
              <Typography component="h4">Drag and Drop Here</Typography>
              <br />
              <Typography component="h5">or</Typography>
              <br /> <br />
              <Button secondary htmlFor="amenity-picture-input">
                BROWSE FILES
              </Button>
              <input
                type="file"
                id="amenity-picture-input"
                style={{ display: 'none' }}
                onChange={uploadImage}
              />
            </Fragment>
          )
        )}
      </ImageBlock>
    )
  }
}
