import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getIsAuth } from 'zego-shared/store/authorization/selectors'
import AppContainer from '../views/AppContainer'
import PropertyCategoryBG from '../images/property_category_BG.png'
import UnitCategoryImage from '../images/unit_category_BG.png'
import PackagesImage from '../images/long_left_bg.png'
import RegistrationImage from '../images/long_left_bg.png'
import LockCodesImage from '../images/long_left_bg.png'
import AmenitiesImage from '../images/long_left_bg.png'
import WorkOrdersImage from '../images/long_left_bg.png'
import { getPropertyAccessSettings } from 'zego-shared/store/settings/selectors'

const getRightBackgroundImage = pathname => {
  const splitPath = pathname.split('/')
  if (splitPath[3] === 'smart' || splitPath[3] === 'settings')
    return PropertyCategoryBG
  if (
    splitPath[3] === 'user-management' &&
    splitPath[4] !== 'profile' &&
    splitPath[4] !== 'units'
  )
    return PropertyCategoryBG
  if (splitPath[3] === 'communication') return null
}

const getLeftBackgroundImage = (pathname, showAmenityReservations) => {
  const splitPath = pathname.split('/')
  if (
    (splitPath[3] === 'user-management' && splitPath[4] === 'profile') ||
    (splitPath[3] === 'residents' && splitPath[4] === 'profile') ||
    (splitPath[5] === 'user-management' && splitPath[3] === 'units')
  )
    return UnitCategoryImage
  if (splitPath[5] === 'smart' && splitPath[3] === 'units')
    return UnitCategoryImage
  if (splitPath[3] === 'packages') return PackagesImage
  if (splitPath[3] === 'registration' && splitPath[4] == null)
    return RegistrationImage
  if (splitPath[3] === 'lock-codes' && !splitPath[5]) {
    return LockCodesImage
  }
  if (splitPath[3] === 'amenities' && !splitPath[4] && !showAmenityReservations) return AmenitiesImage
  if (splitPath[3] === 'work-orders' && splitPath[4] === 'summary' && !splitPath[5]) return WorkOrdersImage
}

const isUnitOrProfileView = pathname => {
  const splitPath = pathname.split('/')
  if (
    (splitPath[3] === 'user-management' && splitPath[4] === 'profile') ||
    (splitPath[3] === 'residents' && splitPath[4] === 'profile') ||
    (splitPath[5] === 'user-management' && splitPath[3] === 'units') ||
    (splitPath[5] === 'smart' && splitPath[3] === 'units')
  )
    return true
}

type PrivateRouteType = {
  component: ComponentType<any>,
  hideAppContainer?: boolean
}

const PrivateRoute = ({
  component: Component,
  hideAppContainer,
  reservationLdFlag,
  settings,
  isAuth,
  ...props
}: PrivateRouteType) => {
  
  const showAmenityReservations = reservationLdFlag === 'New' && settings.amenityReservations

  return (
    <Route
      {...props}
      render={props =>
        isAuth ? (
          hideAppContainer ? (
            <Component {...props} />
          ) : (
            <AppContainer
              leftImageSrc={getLeftBackgroundImage(props.location.pathname, showAmenityReservations)}
              rightImageSrc={getRightBackgroundImage(props.location.pathname)}
              showAmenityReservations={showAmenityReservations}
              isUnitOrProfileView={isUnitOrProfileView(
                props.location.pathname
              )}>
              <Component {...props} />
            </AppContainer>
          )
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

const PrivateRouteWrapper = ({ isAuth, location, ...rest }) =>
  isAuth ? (
    <PrivateRoute isAuth={isAuth} {...rest} />
  ) : (
    <Redirect to={{ pathname: '/login', state: { from: location } }} />
  )

const mapStateToProps = state => ({
  isAuth: getIsAuth(state),
  settings: getPropertyAccessSettings(state)
})

export default withRouter(connect(mapStateToProps)(PrivateRouteWrapper))
