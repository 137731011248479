// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import { withRouter } from 'react-router-dom'
import trimStart from 'lodash/trimStart'
import Grid, { GridItem } from '../Common/Grid'
import Tabs from '@Common/Cards/Tabs'
import { css } from 'emotion'
import BaseCard from '@Common/Cards/BaseCard'
import Flex, { FlexItem } from '@Common/Flex/FlexDirection'
import InputAdornment from '@material-ui/core/InputAdornment'
import { SearchIcon, CommonIcon } from '@icons'
import Header from '@Common/Header'

import LockCodesTabRoutes from '../../routes/LockCodes/LockCodesTabRoutes'

import {
  Content,
  Layout,
  Container,
  TextField,
  FabButton,
  FabButtonWrap
} from './styles'

type Props = {
  filter: any,
  theme: any,
  propertyId: number,
  clearSearchTerm: () => {},
  fetchPropertyUsers: () => {},
  fetchResidentUsers: () => {},
  fetchVendors: () => {},
  filterVendors: (searchTerm: string) => {},
  filterPropertyUsers: (searchTerm: string) => {},
  filterResidentUsers: (searchTerm: string) => {},
  clearVendorsSearchTerm: () => {},
  match: { params: { propertyId: number } },
  data: Object[],
  propertyId: number,
  match: Object,
  history: Object,
  userRole: string
}

type State = {
  searchTerm: string
}

const minSearchCriteria = 1

class LockCodes extends React.Component<Props, State> {
  state = {
    searchTerm: ''
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.setState({
        searchTerm: ''
      })
    }
  }

  goToCreateVendor = () => {
    let {
      match: { url },
      history: { push }
    } = this.props
    push(`${url.split('/vendors')[0]}/vendors/new/codes/create`)
  }

  handleSearchChange = ({ target: { value } }) => {
    const searchTerm = trimStart(value)

    this.setState({ searchTerm }, () => {
      if (searchTerm.length >= minSearchCriteria) {
        this.doSearch()
      }
      if (searchTerm.length < minSearchCriteria) {
        this.resetTab()
      }
    })
  }

  doSearch = () => {
    const { filter } = this.props
    const { searchTerm } = this.state

    if (filter === 'property') {
      this.props.filterPropertyUsers(searchTerm)
    }
    if (filter === 'residents') {
      this.props.filterResidentUsers(searchTerm)
    }
    if (filter === 'vendors') {
      this.props.filterVendors(searchTerm)
    }
  }

  resetTab = () => {
    const { filter } = this.props

    if (filter === 'property') {
      this.props.fetchPropertyUsers()
    }
    if (filter === 'residents') {
      this.props.clearSearchTerm()
      this.props.fetchResidentUsers()
    }
    if (filter === 'vendors') {
      this.props.clearVendorsSearchTerm()
      this.props.fetchVendors()
    }
  }

  getTabs = () => {
    const {
      match: {
        params: { propertyId }
      }
    } = this.props

    return [
      {
        name: 'Vendors',
        route: `/properties/${propertyId}/lock-codes/vendors`
      },
      {
        name: 'Property',
        route: `/properties/${propertyId}/lock-codes/property`
      },
      {
        name: 'Residents',
        route: `/properties/${propertyId}/lock-codes/residents`
      }
    ]
  }

  render() {
    const { theme, filter, userRole } = this.props
    const { searchTerm } = this.state
    return (
      <React.Fragment>
        {filter === 'vendors' ? (
          <FabButtonWrap>
            <FabButton
              component="button"
              disabled={(userRole === "property_staff")}
              onClick={this.goToCreateVendor}
              variant="fab"
              color="secondary">
              <CommonIcon
                name="plus"
                width="1em"
                height="1em"
                fill={theme.palette.common.white}
                strokeWidth={2}
                stroke={theme.palette.common.white}
              />
            </FabButton>
          </FabButtonWrap>
        ) : (
          ''
        )}

        <GridItem columnStart={1} columnSpan={3} rowStart={1} rowSpan={12}>
          <Grid>
            <BaseCard
              style={{ 'pointer-events': 'none' }}
              header={() => <Header alternateFontSize>Lock codes</Header>}
              columnStart={1}
              columnSpan={12}
              rowStart={1}
              rowSpan={12}
              isLeftSideBG={true}
              showShadow={false}
              transparentBackground
              content={() => (
                <Content>
                  <b>Did someone forget their code?</b>
                  <br />
                  Resend property staff and resident
                  <br />
                  codes all in one place.
                  <br />
                  <br />
                  Or manage all your trusted vendors
                  <br />
                  access with lock codes set to a<br />
                  specific time frame and access
                  <br />
                  level.
                </Content>
              )}
            />
          </Grid>
        </GridItem>

        <GridItem columnStart={2} columnSpan={11} rowStart={1} rowSpan={12}>
          <Container>
            <Layout>
              <Grid>
                <GridItem
                  columnStart={4}
                  columnSpan={9}
                  rowStart={2}
                  rowSpan={2}>
                  <Flex fullWidth>
                    <FlexItem flex={4}>
                      <Tabs
                        selectTextColor={theme.palette.text.black}
                        tabs={this.getTabs()}
                        customStyle={`
                          .tab-root {
                            color: ${theme.palette.grey.main};
                            font-weight: ${theme.custom.font.weight.light};
                          }
                        `}
                        scrollable={css`
                          .scrollable-tab {
                            padding-left: '24px;';
                          }
                        `}
                      />
                    </FlexItem>

                    <FlexItem flex={1} displayFlex flexEnd>
                      <TextField
                        value={searchTerm}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon
                                name="search"
                                height="20px"
                                width="18px"
                                onClick={() => {}}
                                className="searchIcon"
                              />
                            </InputAdornment>
                          )
                        }}
                        onChange={this.handleSearchChange}
                      />
                    </FlexItem>
                  </Flex>
                </GridItem>
                <LockCodesTabRoutes />
              </Grid>
            </Layout>
          </Container>
        </GridItem>
      </React.Fragment>
    )
  }
}

export default withRouter(withTheme(LockCodes))
