// @flow
import React from 'react'
import type { Node } from 'react'

import { Container, Item } from './styles'

type FlexProps = {
  children: Node,
  direction?: string
}

type FlexItemProps = {
  children: Node | mixed,
  flex?: number,
  fullWidth?: boolean
}

export const Flex = (props: FlexProps) => {
  const { direction, children, ...rest } = props
  return (
    <Container {...rest} direction={direction}>
      {children}
    </Container>
  )
}

export const FlexItem = (props: FlexItemProps) => {
  const { flex, children, ...rest } = props
  return (
    <Item {...rest} flex={flex}>
      {children}
    </Item>
  )
}

export default Flex
