// @flow
import React from 'react'
import { LinkifyStyled } from './styles'

type Props = {
  content: string,
  options?: Object,
}

const Linkify = ({ content, ...props }: Props) => {
  const linkProps = {
    onClick: (event) => {
      event.stopPropagation()
    },
  }

  return (
    <LinkifyStyled options={{ attributes: linkProps }} {...props}>
      {content}
    </LinkifyStyled>
  )
}

export default Linkify
