// @flow

import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { GridItem } from '../../Common/Grid'
import { Title, Content, Image } from './styles'
import { Button } from './styles'

const UnitSetup = ({
  propertyId,
  unitId
}: {
  propertyId: number,
  unitId: number
}) => (
  <Fragment>
    <GridItem columnStart={5} columnSpan={9} rowStart={2} rowSpan={9}>
      <Title>THIS UNIT CURRENTLY DOES NOT HAVE ZEGO SMART</Title>
      <Content>
        <Image imageSrc="/" />
        You can connect the devices to the Zego hub and <br />
        software by clicking 'START SETUP' below or using the Zego Admin mobile
        app,
        <br />
        available on App Store and Google Play.
      </Content>
      <Link to={`/properties/${propertyId}/units/${unitId}/smart/setup`}>
        <Button name="START SETUP" secondary />
      </Link>
    </GridItem>
  </Fragment>
)

export default UnitSetup
