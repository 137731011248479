export const tabs = [
  { name: 'All', query: { filter: 'all' } },
  { name: 'Received', query: { filter: 'received' } },
  { name: 'On Hold', query: { filter: 'hold' } },
  { name: 'Delivered', query: { filter: 'delivered' } },
  { name: 'Reminders', query: { filter: 'reminders' } }
]

export const statusData = [
  { name: 'Received', id: 0 },
  { name: 'Delivered', id: 1 },
  { name: 'On Hold', id: 2 }
]

export const getSelectedStatus = value => {
  let id
  statusData.forEach(status => {
    if (status.name.toLowerCase().includes(value)) id = status.id
  })

  return id || id === 0 ? { id, name: value } : null
}

export const columns = [
  'RECIPIENT',
  'UNIT',
  'RECEIVED BY',
  'DATE RECEIVED',
  'TRACKING #',
  'STATUS'
]

export const sortFields = [
  'deliver_to',
  'unit_name',
  'received_by',
  'date_received'
]
