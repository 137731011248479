// @flow

import React from 'react'
import Dropzone from 'react-dropzone'
import Flex from '@Common/Flex'
import AddIcon from '@material-ui/icons/Add'
import theme from './../../../theme'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AlertModal from '@Common/AlertModal/AlertModal'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { DropBox, PhotoNavigation, DeletePhotoButton } from './styles'

const workOrderFileSizeLimit = 10485760

type Props = {
  onPhotoUpload: Array<string> => void,
  uploadedPhotos: ?Array<string>,
  editWorkOrder: boolean
}

type State = {
  photos: Array<string>,
  photoUrls: Array<string>,
  selectedIndex: number,
  photoUploadError: ?string,
  navigation: boolean
}

export default class PhotoUpload extends React.Component<Props, State> {
  state = {
    photos: [],
    photoUrls: [],
    selectedIndex: 0,
    photoUploadError: null,
    navigation: false
  }

  fileUploadRef: Object = React.createRef()

  validateWOPhoto = (file: Object) => {
    if (!file) {
      return false
    }

    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
      this.setState({ photoUploadError: 'Oops! It looks like you are attempting to upload a file that is not in a compatible format. Please try uploading your file again in jpeg or png format.' })
      return false;
    } else if (file.size > workOrderFileSizeLimit) {
      this.setState({ photoUploadError: 'Oops! It looks like you are attempting to upload a file that is too large. Please try again uploading a smaller image.' })
      return false;
    } else if (this.state.photos.length === 4) {
      this.setState({ photoUploadError: 'Oops! It looks like you are attempting to upload too many photos.' })
      return false;
    }

    return true;
  }

  fileToBase64 = (file: Object): Promise<string> => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = function (event) {
        resolve((event.target: Object).result);
      };

      reader.readAsDataURL(file);
    });
  };

  deletePhoto = (index: number) => {
    index !== 0 && index === this.state.photos.length - 1 && this.setState({ selectedIndex: index - 1 })

    this.state.photos.splice(index, 1);
    this.state.photoUrls.splice(index, 1);
    this.setState((state) => { return { photos: state.photos, photoUrls: state.photoUrls } })
    this.props.onPhotoUpload(this.state.photos)
  }

  nextPhoto = (index: number) => {
    if (index === this.state.photos.length - 1) {
      this.setState({ selectedIndex: 0 })
    } else {

      this.setState({ selectedIndex: index + 1 })
    }
  }

  previousPhoto = (index: number) => {
    if (index === 0) {
      this.setState((state) => { return { selectedIndex: state.photos.length - 1 } })
    } else {
      this.setState({ selectedIndex: index - 1 })
    }
  }

  onDrop = async (acceptedFiles: Array<Object>) => {
    if (acceptedFiles) {
      if (this.validateWOPhoto(acceptedFiles[0])) {
        const uploadedPhotos = this.state.photos.concat(await this.fileToBase64(acceptedFiles[0]));
        const uploadedPhotoUrls = this.state.photoUrls.concat(URL.createObjectURL(acceptedFiles[0]));

        this.setState({
          photos: uploadedPhotos,
          photoUrls: uploadedPhotoUrls,
        })
        this.props.onPhotoUpload(this.state.photos)
      }
    }
  }

  addPhoto = async ({ target: { files } }: Object) => {
    if (files) {
      if (this.validateWOPhoto(files[0])) {
        const uploadedPhotos = this.state.photos.concat(await this.fileToBase64(files[0]));
        const uploadedPhotoUrls = this.state.photoUrls.concat(URL.createObjectURL(files[0]));

        this.setState({
          photos: uploadedPhotos,
          photoUrls: uploadedPhotoUrls,
        })
        this.props.onPhotoUpload(this.state.photos)
      }
    }
  }

  hidePhotoUploadErrorModal = () => {
    this.setState({ photoUploadError: null })
  }

  setNavigation = (enabled: boolean) => {
    this.setState({ navigation: enabled })
  }

  componentDidMount = () => {
    const { editWorkOrder, uploadedPhotos } = this.props

    if (editWorkOrder && uploadedPhotos) {
      this.setState({
        photos: uploadedPhotos,
        photoUrls: uploadedPhotos
      })
    }
  }

  render() {
    const { uploadedPhotos } = this.props
    const { photoUrls, selectedIndex, photoUploadError, navigation } = this.state
    return (
      <div>
        <AlertModal
          showCloseButton
          title={photoUploadError}
          message=''
          buttons={[
            {
              onClick: this.hidePhotoUploadErrorModal,
              text: 'OK'
            }
          ]}
          show={photoUploadError}
          onClose={this.hidePhotoUploadErrorModal}
        />
        {photoUrls.length === 0 ?
          <Dropzone onDrop={this.onDrop}>
            {({ getRootProps, getInputProps }) => (
              <DropBox {...getRootProps()} >
                <input {...getInputProps()} />
                <p style={{ color: theme.palette.neutrals.deepGray, fontSize: '16px' }}>
                  Drag files here or <br></br> click to select files
                </p>
              </DropBox>
            )}
          </Dropzone>
          :
          <div style={{ fontSize: "0px" }} onMouseEnter={() => this.setNavigation(true)} onMouseLeave={() => this.setNavigation(false)}>
            <div style={{ position: 'relative' }}>
              {navigation &&
                <div>
                  <PhotoNavigation style={{ bottom: '100px', left: '-20px' }} onClick={() => { this.previousPhoto(selectedIndex) }}>
                    <NavigateBeforeIcon fontSize='large' style={{ color: theme.palette.primary.dark }} />
                  </PhotoNavigation>
                  <PhotoNavigation style={{ bottom: '100px', left: '285px' }} onClick={() => { this.nextPhoto(selectedIndex) }}>
                    <NavigateNextIcon fontSize='large' style={{ color: theme.palette.primary.dark }} />
                  </PhotoNavigation>
                </div>
              }
              { (uploadedPhotos && uploadedPhotos.includes(photoUrls[selectedIndex])) ? null :
                <IconButton style={{ position: 'absolute', bottom: '215px', left: '265px' }} onClick={() => { this.deletePhoto(selectedIndex) }}>
                  <CloseIcon style={{ color: theme.palette.common.white }} />
                </IconButton>
              }
              <div style={{width:"300px", height:"255px", backgroundImage: `url(${photoUrls[selectedIndex]})`, backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat'}}>
              </div>
            </div><Flex style={{ width: "300px", backgroundColor: theme.palette.neutrals.lightGray }}>
              {photoUrls.map((photoUrl) => (
                <img width="45px" height="45px" src={photoUrl} alt="work-order" />
              ))}
              {photoUrls.length < 4 &&
                <div>
                  <DeletePhotoButton onClick={() => this.fileUploadRef.current.click()}>
                    <AddIcon
                      style={{ fontSize: 15, color: theme.palette.common.white }}
                    />
                  </DeletePhotoButton>
                  <input id="addWoInput" onChange={this.addPhoto} ref={this.fileUploadRef} type='file' style={{ display: 'none' }} />
                </div>
              }
            </Flex>
          </div>
        }
      </div>
    )
  }
}
