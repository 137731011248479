import { connect } from 'react-redux'
import CreateUnit from './CreateUnit'
import { getToken } from 'zego-shared/store/authorization/selectors'

const mapStateToProps = state => ({
  userToken: getToken(state)
})

export default connect(
  mapStateToProps,
  null
)(CreateUnit)
