import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import MaterialCard from '@material-ui/core/Card'
import { GridItem } from '../Common/Grid'
import FabButton from '@Common/Button/FabButton'
import PointImage from '../../images/point.png'
import { CommonIcon } from '@icons'
import { Button } from '@material-ui/core'

export const PageContainer = styled(GridItem)`
  padding-left: 25px;
`

export const PageHeader = styled('div')`
  padding-top: 10px;
  padding-left: 10px;
  height: 134px;
  color: ${({ theme }) => theme.palette.text.white};

  :before {
    position: absolute;
    left: 0;
    width: 100%;
    height: 178px;
    background-color: ${({ theme }) => theme.palette.primary.navy.twilight};
    z-index: -1;
    content: ' ';
  }
`

export const BackButton = styled(FabButton)`
  min-width: 2.7em;
  min-height: 2.7em;
  width: 2.7em;
  height: 2.7em;
  margin-top: 27px;
  float: right;

  svg {
    fill: ${({ theme }) => theme.palette.primary.dark};
  }
`

export const PageContent = styled(MaterialCard)`
  margin-right: 20px;
  padding: 37px 37px 0 37px;

  h1 {
    margin: 0 3px;
    color: ${({ theme }) => theme.palette.primary.navy.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.lg};
  }

  h2 {
    margin: 1em 3px;
    color: ${({ theme }) => theme.palette.neutrals.deepGray};
    font-size: ${({ theme }) => theme.custom.font.size.mdl};
    font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  }

  .globalDealsHeader {
    visibility: hidden;
  }
`

export const Deals = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.global {
    border-bottom: 2px solid ${({ theme }) => theme.palette.neutrals.warmGray};
    margin-bottom: 22px;
  }
`

export const DragContainer = styled('div')`
  flex: 0 0 40%;
  padding: 2px 57px 22px 2px;
  overflow: hidden;
`

export const DropContainer = styled(DragContainer)`
  :before {
    display: block;
    height: 100%;
    border: 2px dashed ${({ theme }) => theme.palette.neutrals.warmGray};
    content: " ";
  }
`

export const DealCard = styled(MaterialCard)`
  padding: 15px;
  display: flex;
  flex-direction: row;
`

export const DealImage = styled('div')`
  flex: 0 0 33%;
  border-right: 2px solid ${({ theme }) => theme.palette.neutrals.warmGray};
  padding: 0 19px 6px 19px;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  min-height: 125px;

  h3 {
    color: ${({ theme }) => theme.palette.common.black};
    font-size: ${({ theme }) => theme.custom.font.size.md};
    font-weight: ${({ theme }) => theme.custom.font.weight.bold};
    background-color: ${({ theme }) => theme.palette.primary.green.iceberg};
    background-image: url(${PointImage});
    background-repeat: no-repeat;
    background-position: right;
    font-style: italic;
    line-height: 32px;
    position: absolute;
    margin: 0 0 0 -44px;
    padding: 0 23px 0 12px;
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.25);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 190px;
  }

  :after {
    margin-top: 40px;
    background-image: url(${({ imageSrc }) => imageSrc});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    content: " ";
    flex: 1;
  }
`

export const DealContent = styled('div')`
  flex: 1 0;
  padding: 6px 0 6px 19px;
  align-self: flex-start;
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  overflow: hidden;

  h3 {
    margin: 0;
    font-size: ${({ theme }) => theme.custom.font.size.mdlg};
    font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  }

  h4 {
    margin: 0;
    color: ${({ theme }) => theme.palette.neutrals.deepGray};
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  }

  p {
    font-size: ${({ theme }) => theme.custom.font.size.sm};

    &.link {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: ${({ theme }) => theme.palette.primary.green.seeFoam};

      a {
        text-decoration: underline;
      }
    }
  }
`

export const LinkHeader = styled('h3')`
  cursor: pointer;
`

export const CloseButton = styled(CommonIcon)`
  float: right;
  cursor: pointer;
  margin-left: 1em;
  margin-top: -6px;
  stroke: ${({ theme }) => theme.palette.primary.navy.midnight};

  :hover {
    stroke-width: 2;
  }
`

export const ShowMoreBtn = styled(Button)`
  display: block;
  margin: 1em auto;

  svg {
    vertical-align: middle;
  }
`

export const StyledTitleCard = styled('div')`
  margin-left: -30px;
  margin-right: -30px;
  padding: 30px 30px 60px 30px;
  background-color: ${({ theme }) => theme.palette.primary.navy.twilight};
`

export const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  float: right;
  margin-top: -75px;

  :hover {
    color: ${({ theme }) => theme.palette.secondary.yellow.golden};
  }
`
