// @flow
import * as React from 'react'

import { Wrap } from './styles'

type Props = {
  children: React.Node,
  rowDirection: string
}
const CardBodyWrap = (props: Props) => {
  return <Wrap rowDirection={props.rowDirection}>{props.children}</Wrap>
}

export default CardBodyWrap
