import {
  getSelectedProperty
} from 'zego-shared/store/select/selectors'
import { getProfile } from 'zego-shared/store/authorization/selectors'

export const getLiveAgentProfile = state => {
  const {
    id: propertyId,
    name: propertyName,
    company_id: companyId,
    company_name: companyName,
    company_pms_id: companyPmsId,
    pms
  } = getSelectedProperty(state)

  const {
    sub: userId,
    first_name: firstName,
    last_name: lastName,
    name: fullName,
    email,
  } = getProfile(state)

  const isPayleaseUser = pms === 'paylease'

  return {
    email,
    userId,
    firstName,
    lastName,
    fullName,
    propertyName,
    propertyId,
    companyId,
    companyName,
    companyPmsId,
    isPayleaseUser
  }
}
