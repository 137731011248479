// @flow
import { connect } from 'react-redux'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import SmartSummary from './UnitsSmartSummary'
import {
  getUnitsCount,
  getUnitsInfo,
  hasPms
} from 'zego-shared/store/summary/selectors'
import { capitalizeFirstLetter } from '../../../../utils'

const validFilters = ['occupied', 'vacant', 'common']

const mapStateToProps = (
  state,
  { location: { search }, match: { params } }
) => {
  const searchFilter = queryString.parse(search).filter
  const filter = validFilters.includes(searchFilter) ? searchFilter : 'occupied'
  const type = queryString.parse(search).type || 'smart'
  return {
    headingText: type === 'inactive' ? 'non-smart' : type,
    unitsCount: getUnitsCount(state, type),
    unitsInfo: getUnitsInfo(state, filter, type),
    filter: capitalizeFirstLetter(filter),
    propertyId: params.propertyId,
    hasPms: hasPms(state),
    type
  }
}

export default withRouter(connect(mapStateToProps)(SmartSummary))
