// @flow
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AppBar from './AppBar'
import { wasRecentlySearched } from 'zego-shared/store/search/selectors'
import { addSearchHistory } from 'zego-shared/store/search/actions'
import { fetchCompanies } from 'zego-shared/store/select/companies/actions'
import { fetchProperties } from 'zego-shared/store/select/properties/actions'
import {
  getHasMoreCompanies,
  getNextPage
} from 'zego-shared/store/select/companies/selectors'
import {
  getHasMoreProperties,
  getNextPropertiesPage
} from 'zego-shared/store/select/properties/selectors'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {
  showSnackbar,
} from 'zego-shared/store/snackbar/actions'

const fetchingSelector = createIsFetchingSelector(['companies'])

const mapStateToProps = (state, { match: { params } }) => {
  return {
    wasRecentlySearched: url => wasRecentlySearched(state, url),
    hasMoreCompanies: getHasMoreCompanies(state),
    nextCompanies: getNextPage(state),
    hasMoreProperties: getHasMoreProperties(state),
    nextProperties: getNextPropertiesPage(state),
    isFetching: fetchingSelector(state)
  }
}

const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  addToSearchHistory: (category, url) =>
    dispatch(addSearchHistory(params.propertyId, category, url)),
  fetchCompanies: nextPage => dispatch(fetchCompanies(nextPage)),
  fetchProperties: nextPage => dispatch(fetchProperties(nextPage)),
  showSnackbar: (message, mode = undefined) => dispatch(showSnackbar(message, mode))    
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppBar)
)
