// @flow
import { connect } from 'react-redux'
import withFetchData from 'zego-shared/views/withFetchData'
import { fetchUnit } from 'zego-shared/store/select/actions'
import { fetchDevices, clearDevices } from 'zego-shared/store/devices/actions'
import { fetchDeviceHistory } from 'zego-shared/store/deviceHistory/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { getSelectedUnitType } from 'zego-shared/store/select/selectors'
import { getSelectedUnitScheduleID } from 'zego-shared/store/schedule/selectors'
import Smart from './Smart'
import { getCardInfo } from '../../../store/Select/selectors'
import { hasZegoSmart } from '../../../store/Unit/selectors'

const fetchingSelector = createIsFetchingSelector(['unit', 'devices'])

const mapStateToProps = state => ({
  isFetching: fetchingSelector(state),
  unitCardInfo: getCardInfo(state),
  unitType: getSelectedUnitType(state),
  scheduleId: getSelectedUnitScheduleID(state),
  hasZegoSmart: hasZegoSmart(state)
})

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  return {
    fetchData: (scheduleId, unitType) => {
      dispatch(fetchUnit(params.unitId))
      dispatch(fetchDevices(params.unitId))
      dispatch(fetchDeviceHistory(params.unitId))
    },

    componentWillUnmount: () => dispatch(clearDevices())
  }
}

const SmartWithFetchData = withFetchData(Smart)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    fetchData() {
      dispatchProps.fetchData(stateProps.scheduleId, stateProps.unitType)
    }
  })
)(SmartWithFetchData)
