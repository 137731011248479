// @flow

import { connect } from 'react-redux'
import { fetchUnits } from 'zego-shared/store/select/actions'
import { getSelectedCompanyID } from 'zego-shared/store/select/selectors'
import { getPropertyId, getUnits } from 'zego-shared/store/select/selectors'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import AddUser from './AddUser'
import {
  getUserRole,
  getToken
} from 'zego-shared/store/authorization/selectors'
import { getPropertyPmsStatus } from 'zego-shared/store/properties/actions'
import { hasPms } from 'zego-shared/store/summary/selectors'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'

const fetchingSelector = createIsFetchingSelector(['units'])

const mapStateToProps = state => ({
  companyId: getSelectedCompanyID(state),
  propertyId: getPropertyId(state),
  units: getUnits(state),
  isFetchingUnits: fetchingSelector(state),
  userToken: getToken(state),
  userRole: getUserRole(state),
  hasPms: hasPms(state)
})

const mapDispatchToProps = {
  fetchUnits,
  getPropertyPmsStatus,
  showSnackbar
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUser)
