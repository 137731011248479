// @flow
import { connect } from 'react-redux'
import ResidentLeaseRenewals from './ResidentLeaseRenewals'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { searchTemplates } from 'zego-shared/store/groupMessaging/templates'
import { fetchGroupAutomation } from 'zego-shared/store/groupMessaging/groupAutomation'
import { fetchPropertyUsersSummary } from 'zego-shared/store/users/propertyUsersSummary/actions'
import { fetchResidentUsersAction, fetchNextResidentUsersAction, updateUserLeaseInterestStatusAction } from 'zego-shared/store/users/property/actions'
import { getResidentData, getResidentDataCount, getResidentSummary } from '../../../store/Property/selectors'
import { getPropertyId } from 'zego-shared/store/select/selectors'
import { getTemplateWithAutomationInfo } from 'zego-shared/store/groupMessaging/templates/selectors'
import { hasPms, getPmsName } from 'zego-shared/store/summary/selectors'
import { getChatEnabled } from 'zego-shared/store/settings/propertySettings/selectors'
import { getPropertyResidentsCount } from 'zego-shared/store/users/selectors'
import withFetchData from 'zego-shared/views/withFetchData'

const defaultSort = "lease_end_date,asc"
const fetchingSelector = createIsFetchingSelector(['property', 'users'])
const statuses = [
  { id: 'not_interested', name: 'Not interested' },
  { id: 'interested', name: 'Interested' },
  { id: 'renewed', name: 'Renewed' },
  { id: 'communication_sent', name: 'Contacted' },
  { id: 'blocked', name: 'Blocked' },
]

export const mapStateToProps = (state: Object) => ({
  hasPms: hasPms(state),
  pmsName: getPmsName(state),
  chatEnabled: getChatEnabled(state),
  isFetching: fetchingSelector(state),
  residents: getResidentData(state),
  residentSummary: getResidentSummary(state),
  propertyId: getPropertyId(state),
  residentsFliteredTotal: getResidentDataCount(state),
  residentsCount: getPropertyResidentsCount(state),
  statuses: statuses,
  templates: getTemplateWithAutomationInfo(state)
})

export const mapDispatchToProps = (
  dispatch: Object,
  { match: { params: { propertyId } } }: {match : Object}
) => ({
  fetchData: () => {
    const filters = {
      lease_interest_status: statuses.map((status) => status.id),
      sort_by: defaultSort
    }

    dispatch(fetchResidentUsersAction({ propertyId, page: 1, params: filters }))
    dispatch(fetchPropertyUsersSummary(propertyId))
    dispatch(searchTemplates(propertyId, 'lease renewal'))
    dispatch(fetchGroupAutomation(propertyId))
  },
  fetchResidentUsersAction: ({ propertyId, page, params }: { propertyId : string, page : number, params : Object}) => {
    dispatch(fetchResidentUsersAction({ id: propertyId, page, params }))
  },
  fetchNextResidentUsersAction: ({ propertyId, page, params }: { propertyId : string, page : number, params : Object}) => {
    dispatch(fetchNextResidentUsersAction({ id: propertyId, page, params }))
  },
  updateLeaseInterestStatus: (userId: string, status: string) => {
    dispatch(updateUserLeaseInterestStatusAction(userId, status))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(ResidentLeaseRenewals))
