import { connect } from 'react-redux'
import queryString from 'query-string'
import {
  fetchPets,
  searchPets,
  fetchPetsTypes,
  fetchPetsStatuses
} from 'zego-shared/store/registration/pets'
import {
  fetchVehicles,
  searchVehicles,
  fetchVehiclesMakes,
  fetchVehiclesColors,
  fetchVehiclesStates
} from 'zego-shared/store/registration/vehicles'
import {
  fetchVisitors,
  searchVisitors
} from 'zego-shared/store/registration/visitors'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import {
  hasPets,
  getPets,
  getPetsTypes,
  getValidPetTypes,
  getValidPetStatuses,
  getPetsStatuses,
  getPetsPaginationInfo
} from 'zego-shared/store/registration/pets/selectors'
import {
  hasVehicles,
  getVehicles,
  getVehiclesColors,
  getVehiclesStates,
  getVehiclesYears,
  getValidYears,
  getValidColors,
  getValidStates,
  getVehiclesMakes,
  getVehiclesPaginationInfo
} from 'zego-shared/store/registration/vehicles/selectors'
import {
  getVisitors,
  hasVisitors,
  getVisitorsPaginationInfo
} from '../../store/Registration/visitor/selector'
import { getRegistrationSettings } from 'zego-shared/store/settings/selectors'

import Registration from './Registration'

const getActionsSelectorsByFilter = filter => {
  switch (filter) {
    case 'pets':
    case 'need-approval':
      return {
        fetchAction: fetchPets,
        searchAction: searchPets,
        getDataItems: getPets,
        hasData: hasPets,
        getPaginationInfo: getPetsPaginationInfo,
        fetchingSelector: createIsFetchingSelector(['pets'])
      }
    case 'visitors':
      return {
        fetchAction: fetchVisitors,
        searchAction: searchVisitors,
        getDataItems: getVisitors,
        hasData: hasVisitors,
        getPaginationInfo: getVisitorsPaginationInfo,
        fetchingSelector: createIsFetchingSelector(['visitors'])
      }
    case 'vehicles':
      return {
        fetchAction: fetchVehicles,
        searchAction: searchVehicles,
        getDataItems: getVehicles,
        hasData: hasVehicles,
        getPaginationInfo: getVehiclesPaginationInfo,
        fetchingSelector: createIsFetchingSelector(['vehicles'])
      }
    default:
      return {
        fetchAction: () => ({ type: 'UNKNOWN' }),
        searchAction: () => ({ type: 'UNKNOWN' })
      }
  }
}

const mapStateToProps = (state, { location: { search }, match: { params: {propertyId} } }) => {
  const filter = queryString.parse(search).filter || 'need-approval'
  const {
    getDataItems,
    hasData: hasDataFn,
    getPaginationInfo,
    fetchingSelector
  } = getActionsSelectorsByFilter(filter)
  const dataItems = getDataItems ? getDataItems(state, filter) : []
  const hasData = hasDataFn ? hasDataFn(state) : true
  const paginationInfo = getPaginationInfo
    ? getPaginationInfo(state, filter)
    : {}
  const isFetching = fetchingSelector ? fetchingSelector(state) : false
  return {
    filter,
    items: dataItems,
    isFetching,
    propertyId,
    hasData,
    ...paginationInfo,
    petTypes: getPetsTypes(state),
    petStatus: getPetsStatuses(state),
    validPetTypes: getValidPetTypes(state),
    validPetStatuses: getValidPetStatuses(state),
    vehiclesColors: getVehiclesColors(state),
    vehiclesStates: getVehiclesStates(state),
    vehiclesYears: getVehiclesYears(state),
    validYears: getValidYears(),
    validColors: getValidColors(state),
    validStates: getValidStates(state),
    vehiclesMakes: getVehiclesMakes(state),
    settings: getRegistrationSettings(state),
  }
}

const mapDispatchToProps = (
  dispatch,
  { location: { search }, match: { params } }
) => {
  const filter = queryString.parse(search).filter || 'need-approval'
  const { fetchAction, searchAction } = getActionsSelectorsByFilter(filter)
  return {
    fetchData: () => {
      dispatch(fetchAction(params.propertyId))
      dispatch(fetchPetsTypes(params.propertyId))
      dispatch(fetchPetsStatuses(params.propertyId))
      dispatch(fetchVehiclesColors(params.propertyId))
      dispatch(fetchVehiclesStates(params.propertyId))
      dispatch(fetchVehiclesMakes(params.propertyId))
    },
    fetchPage: (page, queryParams) =>
      dispatch(fetchAction(params.propertyId, page, queryParams)),
    filterVisitors: (page, queryParams) =>
      dispatch(fetchAction(params.propertyId, page, queryParams)),
    filterVehicles: (page, queryParams) => {
      dispatch(fetchAction(params.propertyId, page, queryParams))
    },
    filterPets: (page, queryParams) =>
      dispatch(fetchAction(params.propertyId, page, queryParams)),
    searchRegistration: (searchTerm, page) => {
      if (searchTerm == null || searchTerm === '') {
        dispatch(fetchAction(params.propertyId))
      } else {
        dispatch(searchAction(params.propertyId, page, searchTerm))
      }
    },
    componentDidUpdate: prevProps => {
      if (prevProps.filter !== filter) {
        dispatch(fetchAction(params.propertyId))
      }
    }
  }
}

const RegistrationWithFetchData = withFetchData(Registration)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationWithFetchData)
