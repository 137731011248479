// @flow
import React from 'react'
import { Block } from './styles'
import { isValidImage } from '../../../../utils/validationUtils'

const ChatFile = ({ file }: { file: string }) => {
  let isNotImage = file ? !isValidImage(file) : false

  return file && isNotImage ? (
    <Block>
      <a href={file}>{file}</a>
    </Block>
  ) : (
    <Block>
      <img src={file} alt={file} />
    </Block>
  )
}

export default ChatFile
