// @flow
import React from 'react'
import Flex from '@Common/Flex'
import { components } from 'react-select';

type Props = {
  subCategory: string,
  data: Object
}

export class SingleValue extends React.Component<Props, {}> {
  render() {
    const { subCategory, data: { selectedLabel } } = this.props

    return (
      <components.SingleValue {...this.props}>
        <Flex direction="row" fullWidth justifySpaceBetween>
          <span>{selectedLabel} {subCategory ? ` - ${subCategory}` : null}</span>
        </Flex>
      </components.SingleValue >
    )
  }
}

export default SingleValue
