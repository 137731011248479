import { call, delay, select, put, fork, takeLatest } from 'redux-saga/effects'
import queryString from 'query-string'
import jwtDecode from 'jwt-decode'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  failedToLogin,
  failedToResetPass,
  failedToVerifySignupToken,
  genericErrorMsg
} from '../../../utils/messages'
import {
  deauth,
  saveAuth,
  updateUserOptimistic,
  updateUserSuccess,
  updateUserError,
  validateSignUpLinkSuccess,
  validateSignUpLinkFailure,
  signUpSuccess,
  loginFailure,
  UPDATE_USER,
  LOGIN_REFRESH,
  LOGIN_EMAIL,
  LOGIN_SOCIAL,
  LOGIN_TOKEN,
  VALIDATE_SIGN_UP_LINK,
  SIGN_UP,
  REQUEST_RESET_PASS,
  RESET_PASS,
  FETCH_RESOURCES_AND_REDIRECT_AFTER_LOGIN
} from '../actions'
import { showSnackbar, hideSnackbar } from '../../snackbar/actions'
import { getToken, getUserRole } from '../selectors'
import { getProfile, getUserId } from 'zego-shared/store/users/selectors'
import {
  fetchCompaniesSuccess,
  selectCompany,
  selectProperty,
  fetchPropertiesSuccess,
  updatePropertyRenderReady
} from '../../select/actions'

export function* saveAuthInfo(info) {
  const { id_token } = info

  const profile = yield call(jwtDecode, id_token)

  if (!profile) {
    throw new Error('invalid profile')
  }

  // save auth info
  yield put(saveAuth(profile, info))
}

export function* updateUser(action) {
  const profile = yield select(getProfile)
  const newAtts = action.attributes

  let currentAtts = {}
  let attsToUpdate = {}

  // loop through each attribute
  // if attribute is different from what we have then add it to update list
  // also save it to current attributes so we can revert later if an error occurs
  for (let key in newAtts) {
    if (newAtts[key] && newAtts[key] !== profile[key]) {
      attsToUpdate[key] = newAtts[key]
      currentAtts[key] = profile[key]
    }
  }

  if (Object.keys(attsToUpdate).length > 0) {
    const userId = yield select(getUserId)
    const authToken = yield select(getToken)

    try {
      yield put(updateUserOptimistic(attsToUpdate))

      const response = yield call(
        api.updateUser,
        userId,
        attsToUpdate,
        authToken
      )

      let serverAtts = {}

      for (let key in attsToUpdate) {
        if (response[key]) {
          serverAtts[key] = response[key]
        }
      }

      yield put(updateUserSuccess(serverAtts))
    } catch (err) {
      yield put(updateUserError(err.message, currentAtts))
    }
  }
}

export function* login(func, args) {
  try {
    const info = yield call(func, ...args)

    yield call(saveAuthInfo, info)
  } catch (error) {
    yield put(showSnackbar(failedToLogin(error), 'error'))
    yield put(loginFailure())
  }
}

export function* refreshToken({ refreshToken, resolve, reject }) {
  try {
    yield delay(200)
    const info = yield call(api.refreshToken, refreshToken)
    yield call(saveAuthInfo, info)
    resolve()
  } catch (error) {
    yield put(showSnackbar(failedToLogin(error), 'error'))
    reject(error)
  }
}

export function* loginEmail(action) {
  const { username, password, spoofUserId } = action

  const func = api.loginEmail
  const args = [username, password, spoofUserId]

  yield fork(login, func, args)
}

export function* loginSocial(action) {
  const { provider, data } = action
  const func = api.loginSocial
  const args = [provider, data]

  yield fork(login, func, args)
}

export function* loginToken(action) {
  yield delay(200)
  const { token } = action
  const func = api.loginToken
  let args = [token]

  yield fork(login, func, args)
}

export function* validateSignUpLink(action) {
  try {
    const { token } = action.data

    const result = yield call(api.checkActivationToken, token)
    log(result)

    yield put(validateSignUpLinkSuccess())
    // yield put(showSnackbar('Ok', 'success'))
  } catch (error) {
    log(`Failed to validate sign-up link. Error: ${error}`)
    yield put(validateSignUpLinkFailure())
    yield put(showSnackbar(failedToVerifySignupToken, 'error'))
  }
}

export function* signUp(action) {
  try {
    const { token, data, provider } = action
    // yield put(showOverlayLoading())

    let info

    if (provider) {
      info = yield call(api.signUpSocial, token, data, provider)
    } else {
      info = yield call(api.signUp, token, data)
    }

    yield fork(saveAuthAndShowWelcome, info)
    yield delay(200)
    // yield put(hideOverlayLoading())
  } catch (error) {
    log(`Failed to sign up. Error: ${error}`)
    // yield put(hideOverlayLoading())
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

export function* saveAuthAndShowWelcome(info) {
  try {
    yield call(saveAuthInfo, info)
    yield delay(500)

    yield put(signUpSuccess())
    yield put(showSnackbar('Welcome', 'success'))
  } catch (error) {
    yield put(showSnackbar(failedToLogin(error), 'error'))
  }
}

export function* resetPass({ data, history }) {
  try {
    const result = yield call(api.resetPassword, data)
    log(result)

    yield put(showSnackbar('Password reset successfully!', 'success'))
    yield delay(600)
    yield put(hideSnackbar())
    history.push('/login')
  } catch (error) {
    log('failed to reset password', error)
    yield put(showSnackbar(failedToResetPass, 'error'))
  }
}

export function* requestResetPass({ email }) {
  try {
    const result = yield call(api.resetPassViaEmail, email)
    log(result)

    yield put(showSnackbar(result.details.msg, 'success'))
  } catch (error) {
    log('failed to reset password', error)
    yield put(showSnackbar(failedToResetPass, 'error'))
  }
}

export function* fetchResourcesAndRedirectAfterLogin({ history }) {
  try {
    yield put(updatePropertyRenderReady(false))
    const authToken = yield select(getToken)
    const role = yield select(getUserRole)

    // oauth redirect
    const redirectHosts = (
      window._env_.REACT_APP_REDIRECT_HOSTS ||
      'https://api.zego.io,https://api-stage.zego.io'
    ).split(',')

    const {
      url,
      query: { redirect_uri: redirectURI, include_token: includeToken }
    } = queryString.parseUrl(window.location.href)

    if (redirectURI) {
      const origin = new window.URL(redirectURI).origin
      const baseURL = redirectHosts.includes(origin) ? redirectURI : '/'
      const redirectURL =
        includeToken === 'true' ? `${baseURL}&token=${authToken}` : baseURL
      window.location = redirectURL
      return
    }

    let properties
    if (role === 'super_admin') {
      const companies = yield call(api.getCompanies, authToken)

      const firstCompanyId = companies.data[0].id
      yield put(fetchCompaniesSuccess(companies))
      yield put(selectCompany(firstCompanyId))

      properties = yield call(
        api.getCompaniesProperties,
        authToken,
        firstCompanyId
      )
    } else {
      properties = yield call(api.getProperties, authToken)
    }

    yield put(fetchPropertiesSuccess(properties))

    if (properties.data.length > 0) {
      const firstPropertyId = properties.data[0].id
      yield put(selectProperty(firstPropertyId))
    }

    // no oauth redirect
    history.push('/')
  } catch (error) {
    yield put(deauth())
    yield put(showSnackbar(failedToLogin(error), 'error'))
  }
}

// spawn a new updateUser task on the latest UPDATE_USER
function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER, updateUser)
}

function* watchLoginRefresh() {
  yield takeLatest(LOGIN_REFRESH, refreshToken)
}

function* watchLoginEmail() {
  yield takeLatest(LOGIN_EMAIL, loginEmail)
}

function* watchLoginSocial() {
  yield takeLatest(LOGIN_SOCIAL, loginSocial)
}

function* watchLoginToken() {
  yield takeLatest(LOGIN_TOKEN, loginToken)
}

function* watchValidateSignUpLink() {
  yield takeLatest(VALIDATE_SIGN_UP_LINK, validateSignUpLink)
}

function* watchSignUp() {
  yield takeLatest(SIGN_UP, signUp)
}

function* watchResetPass() {
  yield takeLatest(RESET_PASS, resetPass)
}

function* watchRequestResetPass() {
  yield takeLatest(REQUEST_RESET_PASS, requestResetPass)
}

function* watchRedirectAfterLogin() {
  yield takeLatest(
    FETCH_RESOURCES_AND_REDIRECT_AFTER_LOGIN,
    fetchResourcesAndRedirectAfterLogin
  )
}

export default [
  watchUpdateUser(),
  watchLoginRefresh(),
  watchLoginEmail(),
  watchLoginSocial(),
  watchLoginToken(),
  watchValidateSignUpLink(),
  watchSignUp(),
  watchResetPass(),
  watchRequestResetPass(),
  watchRedirectAfterLogin()
]
