import { combineReducers } from 'redux'
import withFetchNext from '../../helpers/reducers/withFetchNext'
import withFetch from '../../helpers/reducers/withFetch'
import {
  SET_VENDORS_SEARCH_TERM,
  CLEAR_VENDORS_SEARCH_TERM,
  LOCKCODESVENDORS,
  VENDOR_CODES,
  VENDOR_CODE,
  VENDOR_CODE_UNITS,
  VENDORCODEUNITS,
  VENDOR
} from '../actions'

const initialState = {}

const lockCodesVendors = (state = initialState, action) => {
  switch (action.type) {
    case SET_VENDORS_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm
      }
    case CLEAR_VENDORS_SEARCH_TERM:
      return {
        ...state,
        searchTerm: ''
      }
    default:
      return state
  }
}

const vendor = withFetch(VENDOR)((state = null, action) => {
  return state
})

const vendorCodes = withFetch(VENDOR_CODES)((state = null, action) => {
  return state
})

const vendorCode = withFetch(VENDOR_CODE)((state = null, action) => {
  return state
})

const vendorCodeUnits = withFetch(VENDORCODEUNITS)((state = null, action) => {
  return state
})

const vendorsCodes = withFetchNext(LOCKCODESVENDORS)(lockCodesVendors)

export default combineReducers({
  vendorCodes,
  vendorCode,
  vendorCodeUnits,
  vendorsCodes,
  vendor
})
