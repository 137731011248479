import { connect } from 'react-redux'
import MimoChecklistSummary from './MimoChecklistSummary'
import {
  getMimoChecklistSummary,
  getMimoChecklistWorkOrder,
  getMimoChecklistSummaryLoading,
  getSavedMimoChecklistSettings,
  getMimoChecklistPdfSubmitting
} from 'zego-shared/store/moveInMoveOut/selectors'
import {
  fetchSavedMimoChecklistSettingsAction,
  fetchMimoChecklistPdfAction,
  fetchMimoChecklistSummaryAction
} from 'zego-shared/store/moveInMoveOut/actions'
import { getPropertyId } from 'zego-shared/store/select/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import {
  fetchMimoWorkOrderSubmitAction,
  fetchIntegratedWorkOrderSubmitAction
} from 'zego-shared/store/MimoWorkorders/actions'
import { getProfile } from 'zego-shared/store/users/selectors'
import {
  getResidentStandaloneWorkOrderEnabled,
  getResidentIntegratedWorkOrderEnabled
} from 'zego-shared/store/settings/propertySettings/selectors'
import {
  getIsMimoWorkorderSubmitting, getIsMimoWorkorderPdfUpload
} from 'zego-shared/store/MimoWorkorders/selectors'
import { getUserId } from 'zego-shared/store/authorization/selectors'
import {
  showSnackbar,
} from 'zego-shared/store/snackbar/actions'

export const mapStateToProps = state => ({
  checklistSummaryRemote: getMimoChecklistSummary(state),
  checklistinfo: getMimoChecklistWorkOrder(state),
  isLoading: getMimoChecklistSummaryLoading(state),
  savedChecklist: getSavedMimoChecklistSettings(state),
  profileComplete: getProfile(state),
  isSubmittingPdf: getMimoChecklistPdfSubmitting(state),
  propertyId: getPropertyId(state),
  isStandaloneWoEnabled: getResidentStandaloneWorkOrderEnabled(state),
  isIntegratedWoEnabled: getResidentIntegratedWorkOrderEnabled(state),
  IsWOSubmitting: getIsMimoWorkorderSubmitting(state),
  pmId: getUserId(state),
  IsPdfUpload: getIsMimoWorkorderPdfUpload(state)
})

const mapDispatchToProps = (dispatch, { match: { params: { userId } } }) => {
  return {
    fetchChecklistSettings: () => {
      dispatch(fetchSavedMimoChecklistSettingsAction())
    },
    getChecklistSummary: (residentId, unitId) => {
      dispatch(fetchMimoChecklistSummaryAction({ residentId, unitId }))
    },
    submitChecklistPdf: (residentId, unitId, profileComplete, disclosure, signature) => {
      dispatch(fetchMimoChecklistPdfAction({ residentId, unitId, profileComplete, disclosure, signature }))
    },
    submitWorkOrder: (checklistSummary, pmId, profileComplete, disclosure, signature) => {
      dispatch(fetchMimoWorkOrderSubmitAction({ checklistSummary, pmId, profileComplete, disclosure, signature }))
    },
    submitIntegratedWorkOrder: (checklistSummary, residentId, unitId, pmId, profileComplete, disclosure, signature) => {
      dispatch(fetchIntegratedWorkOrderSubmitAction({ checklistSummary, residentId, unitId, pmId, profileComplete, disclosure, signature }))
    },
    showSnackbar: (message, mode = undefined) => dispatch(showSnackbar(message, mode))   
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(MimoChecklistSummary))
