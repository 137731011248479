import { call, select, put, takeLatest } from 'redux-saga/effects'
import {
  fetchDeviceHistory,
  fetchDeviceHistoryStart,
  fetchDeviceHistoryError,
  fetchDeviceHistorySuccess
} from '../actions'
import api from '../../../utils/api'
import { getToken } from '../../authorization/selectors'

export function* fetchHistory({ unitId, types, page }) {
  try {
    const authToken = yield select(getToken)

    yield put(fetchDeviceHistoryStart())
    const response = yield call(api.getHistory, authToken, unitId, types, page)
    yield put(fetchDeviceHistorySuccess(response))
  } catch (error) {
    yield put(fetchDeviceHistoryError())
  }
}

function* watchFetchDeviceHistory() {
  yield takeLatest(fetchDeviceHistory().type, fetchHistory)
}

export default [watchFetchDeviceHistory()]
