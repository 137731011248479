// @flow
import React from 'react'
import chunk from 'lodash/chunk'
import toString from 'lodash/toString'
import { withRouter } from 'react-router-dom'
import type { Match } from 'react-router-dom'
import { BrowserHistory } from 'history'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import Grid, { GridItem } from '../../Common/Grid'
import { InputAdornment } from '@material-ui/core'
import { SearchIcon } from '@icons'
import GroupsUsersTable from '../../Common/PaginatedTable'
import Flex from '@Common/Flex/FlexDirection'
import Header from '@Common/Header'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import {
  HeadBackground,
  Body,
  Recipients,
  MessageDetails,
  Wrapper,
  Break,
  ButtonSet,
  TextField
} from './SingleGroupMessage/styles'
import { withTheme } from 'emotion-theming'
import { StyledRow, StyledColumn, BackButtonStyled, StyledLink } from './styles'
import Avatar from '@Common/Avatar'
import ScrollToTop from '@Common/ScrollToTop'

type State = {
  currentPage: number,
  rowsPerPage: number,
  newItems: Object[],
  searchTerm: string
}

type Props = {
  history: BrowserHistory,
  match: Match,
  params: Object,
  items: Object[],
  title: string,
  isFetching: boolean,
  searchGroupsUsers: (term?: string) => void,
  theme: Object,
  propertyId: string,
  userRole: string,
  displayErrorMessage: (message: string) => void
}

class GroupsUsers extends React.Component<Props, State> {
  state = {
    currentPage: 0,
    rowsPerPage: 20,
    newItems: [],
    searchTerm: ''
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      const { items } = this.props
      this.setState({
        newItems: chunk(items, this.state.rowsPerPage),
        currentPage: 0
      })
    }
  }

  goBack = () => {
    const {
      history,
      match: { url }
    } = this.props
    history.push(`${url.split('/groups')[0]}/groups`)
  }

  createRows = () => {
    const {
      params: { propertyId }
    } = this.props

    const { currentPage, newItems } = this.state

    const itemsToMap = newItems[currentPage]

    return itemsToMap
      ? itemsToMap.map(rowData =>
          Object.entries(rowData).map(([key, value], index) => ({
            value: (
              <StyledRow index={index}>
                {key === 'picture' ? (
                  <Avatar picture={value} size="2em" />
                ) : key === 'id' ? (
                  <StyledLink
                    to={`/properties/${propertyId}/user-management/profile/${toString(
                      value
                    )}`}>
                    User Details
                  </StyledLink>
                ) : (
                  value
                )}
              </StyledRow>
            )
          }))
        )
      : []
  }

  changePage = page => {
    this.setState({ currentPage: page - 1 })
  }

  handleSearchChange = ({ target: { value } }) => {
    this.setState({ searchTerm: value }, () => {
      if (value.length >= 3) this.props.searchGroupsUsers(this.state.searchTerm)
      if (value.length === 0) this.props.searchGroupsUsers()
    })
  }

  handleKeyPress = ({ key }: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      this.searchUsers()
    }
  }

  searchUsers = () => {
    this.props.searchGroupsUsers(this.state.searchTerm)
  }

  render() {
    const { title, items, isFetching, theme, userRole, propertyId, history, displayErrorMessage } = this.props
    const { currentPage, rowsPerPage, searchTerm } = this.state

    if (userRole === "property_staff") {
      history.replace(`/properties/${propertyId}/communication/group-messaging`)
      setTimeout(() => {
        displayErrorMessage("Group Messaging Groups are not available for Property Staff.")
      }, 1000)
  
      return null
    }  

    return (
      <React.Fragment>
        <Wrapper>
          <FlexDirection
            direction="column"
            fullHeight
            fullWidth
            className="wrapper-flex">
            <FlexItem>
              <HeadBackground>
                <div>
                  <div>
                    <Recipients />
                    <br />
                    <Header midnight>
                      <span>{title}</span>
                    </Header>
                    <MessageDetails />
                  </div>
                  <ButtonSet>
                    <BackButtonStyled variant="fab" onClick={this.goBack}>
                      <KeyboardBackspace
                        style={{ color: theme.palette.primary.dark }}
                        height="0.5em"
                        width="0.5em"
                      />
                    </BackButtonStyled>
                  </ButtonSet>
                </div>
              </HeadBackground>
            </FlexItem>
            <FlexItem flex={1} style={{ paddingBottom: '5em' }}>
              <Body>
                <Grid>
                  <GridItem columnStart={1} columnSpan={12} rowStart={2}>
                    <br />
                    {isFetching ? (
                      <div>Loading...</div>
                    ) : (
                      <GroupsUsersTable
                        total={items ? items.length : 0}
                        flex={[0.3, 1.2, 0.4, 4]}
                        currentPage={currentPage}
                        rowsPerPage={rowsPerPage}
                        columns={[
                          {
                            value: <StyledColumn index={1} />
                          },
                          {
                            value: (
                              <StyledColumn
                                style={{ marginLeft: '-20%' }}
                                index={2}>
                                {items ? items.length : ''} Recipients
                              </StyledColumn>
                            )
                          },
                          {
                            value: <StyledColumn index={3}>Unit</StyledColumn>
                          },
                          {
                            value: (
                              <StyledColumn index={4}>
                                <Flex fullWidth>
                                  <FlexItem flex={1} displayFlex flexEnd>
                                    <TextField
                                      value={searchTerm}
                                      InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <SearchIcon
                                              name="search"
                                              height="20px"
                                              width="18px"
                                              onClick={this.searchUsers}
                                              className="searchIcon"
                                            />
                                          </InputAdornment>
                                        )
                                      }}
                                      onChange={this.handleSearchChange}
                                      onKeyPress={this.handleKeyPress}
                                    />
                                  </FlexItem>
                                </Flex>
                              </StyledColumn>
                            ),
                            span: 2
                          }
                        ]}
                        rows={this.createRows()}
                        changePage={this.changePage}
                      />
                    )}
                  </GridItem>
                  <Break />
                </Grid>
              </Body>
            </FlexItem>
            <Break />
          </FlexDirection>
        </Wrapper>
        <ScrollToTop />
      </React.Fragment>
    )
  }
}

export default withRouter(withTheme(GroupsUsers))
