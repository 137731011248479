import { call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken } from '../../authorization/selectors'
import {
  searchAction,
  searchError,
  searchStart,
  searchSuccess,
  fetchSearchHistoryAction,
  fetchSearchHistoryError,
  fetchSearchHistorySuccess,
  fetchSearchHistoryStart,
  ADD_SEARCH_HISTORY_SECTION
} from '../actions'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import { noPropertySelected } from '../../../utils/messages'
import { getPropertyId } from '../../select/selectors'

const getActions = () => ({
  start: searchStart,
  success: searchSuccess,
  error: searchError
})

export function* search({ name, id }) {
  const actions = getActions()

  try {
    yield put(actions.start())
    const authToken = yield select(getToken)
    const propertyID = id ? id : yield select(getPropertyId)

    if (propertyID === -1) throw new Error(noPropertySelected)

    const response = yield call(api.search, authToken, name.trim(), propertyID)
    yield put(actions.success(response))
  } catch (error) {
    yield put(actions.error(error))
    log(`Failed to fetch search results`)
  }
}

export function* fetchSearchHistory({ id }) {
  try {
    yield put(fetchSearchHistoryStart())
    const authToken = yield select(getToken)
    const propertyID = id ? id : yield select(getPropertyId)

    if (propertyID === -1) throw new Error(noPropertySelected)

    const response = yield call(api.getSearchHistory, authToken, propertyID)
    yield put(fetchSearchHistorySuccess(response))
  } catch (error) {
    yield put(fetchSearchHistoryError(error))
    log(`Failed to fetch search history`)
  }
}

export function* addSearchHistory({ id, resourceKey, resourceValue }) {
  try {
    const authToken = yield select(getToken)
    const propertyID = id ? id : yield select(getPropertyId)

    if (propertyID === -1) throw new Error(noPropertySelected)

    yield call(
      api.addSearchHistory,
      authToken,
      propertyID,
      resourceKey,
      resourceValue
    )
  } catch (error) {
    log(`Failed to add search history`)
  }
}

function* watchfetchSearchHistory() {
  yield takeLatest(fetchSearchHistoryAction().type, fetchSearchHistory)
}

function* watchSearch() {
  yield takeLatest(searchAction().type, search)
}

function* watchAddSearchHistory() {
  yield takeLatest(ADD_SEARCH_HISTORY_SECTION, addSearchHistory)
}
export default [
  watchSearch(),
  watchfetchSearchHistory(),
  watchAddSearchHistory()
]
