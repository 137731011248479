import { connect } from 'react-redux'
import LeaseInfo from './LeaseInfo'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { getGeneralInfo } from '../../../../store/Property/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { getFeatureFlag } from '../../../../integrations/LaunchDarkly/selectors'
import { ldInitRequest } from '../../../../integrations/LaunchDarkly/actions'
import {
  getUserBalance,
  getUserLeaseInterestStatus,
  updateUserLeaseInterestStatus
} from 'zego-shared/store/users/profile/actions'
import {
  getUserLeaseInfo,
  hasProfileData,
  getUserProfile,
  getProfile
} from 'zego-shared/store/users/selectors'
import { getLeaseRenewalEnabled, getMoveInChecklistSetting } from 'zego-shared/store/settings/selectors'
import { fetchMimoChecklistSummaryAction, fetchMimoChecklistOptedOutAction, fetchMimoResetCheckListAction } from 'zego-shared/store/moveInMoveOut/actions'
import {
  getMimoChecklistStatus,
  getMimoApprovalStatus,
  getMimoChecklistSummary,
  SubmitMimoResetCheckList,
  getMimoChecklistWorkOrder
} from 'zego-shared/store/moveInMoveOut/selectors'
import {
  getUserId
} from 'zego-shared/store/authorization/selectors'

const LeaseInfoWithFetchData = withFetchData(LeaseInfo)
const fetchingSelector = createIsFetchingSelector(['users', 'profile'])

const mapStateToProps = state => {
  return {
    hasData: hasProfileData(state),
    isFetching: fetchingSelector(state),
    leaseInfo: getUserLeaseInfo(state),
    profile: getUserProfile(state),
    profileComplete: getProfile(state),
    checkListStatus: getMimoChecklistStatus(state),
    checkListApprovalStatus: getMimoApprovalStatus(state),
    leaseRenewalEnabled: getLeaseRenewalEnabled(state),
    moveInChecklist: getMoveInChecklistSetting(state),
    featureFlag: getFeatureFlag(state),
    moveOutChecklist: false,
    checklistSummary: getMimoChecklistSummary(state),
    resetCheckList: SubmitMimoResetCheckList(state),
    checklistInfo: getMimoChecklistWorkOrder(state),
    property: getGeneralInfo(state),
    pmsId: getUserId(state)
  }
}

const mapDispatchToProps = (dispatch, { match: { params: { userId } } }) => {
  return {
    fetchData: () => {
      dispatch(getUserBalance(userId))
      dispatch(getUserLeaseInterestStatus(userId))
      dispatch(ldInitRequest())
    },
    updateLeaseInterestStatus: status => {
      dispatch(updateUserLeaseInterestStatus(userId, status))
    },
    getChecklistSummary: (residentId, unitId) => {
      dispatch(fetchMimoChecklistSummaryAction({ residentId, unitId }))
    },
    SubmitMimoResetCheckList: (residentId, propertyId, propertyName, unitId, unitName) => {
      dispatch(fetchMimoResetCheckListAction({ residentId, propertyId, propertyName, unitId, unitName }))
    },
    submitedOptedOut: (residentCheckListId, userId, unitId, pmsId) => {
      dispatch(fetchMimoChecklistOptedOutAction({residentCheckListId, userId, unitId, pmsId } ))
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaseInfoWithFetchData)
