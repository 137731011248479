// @flow
import React from 'react'
import FlexDirection, {
  FlexItem
} from '@Common/Flex/FlexDirection'
import Flex from '@Common/Flex'
import CurrentThermostatValue from './CurrentThermostatValue'
import Slider from '../Slider'
import { withTheme } from 'emotion-theming'
import { MainWrap, SliderWrap } from './styles'
import { commands } from 'zego-shared/store/devices/sagas/utils/deviceCommands'

class ChangeSetPoints extends React.Component<any, any> {
  getCurrentThermostatValue = () => {
    const {
      currentThermostatMode,
      heatSetpointToChangeTo,
      coolSetpointToChangeTo
    } = this.props
    if (currentThermostatMode === commands.AUTO) {
      return (
        <React.Fragment>
          <CurrentThermostatValue value={heatSetpointToChangeTo} mode="Heat" />
          <CurrentThermostatValue value={coolSetpointToChangeTo} mode="Cool" />
        </React.Fragment>
      )
    }
    if (currentThermostatMode === commands.HEAT) {
      return (
        <CurrentThermostatValue value={heatSetpointToChangeTo} mode="Heat" />
      )
    }
    if (currentThermostatMode === commands.COOL) {
      return (
        <CurrentThermostatValue value={coolSetpointToChangeTo} mode="Cool" />
      )
    }
    return null
  }

  getSliderValues = () => {
    const {
      currentThermostatMode,
      heatSetpointToChangeTo,
      coolSetpointToChangeTo
    } = this.props
    if (currentThermostatMode === commands.HEAT) {
      return [heatSetpointToChangeTo]
    }
    if (currentThermostatMode === commands.COOL) {
      return [coolSetpointToChangeTo]
    }
    if (currentThermostatMode === commands.AUTO) {
      return [heatSetpointToChangeTo, coolSetpointToChangeTo]
    }
    return []
  }

  mode = (current, next) => {
    const { currentThermostatMode } = this.props
    if (currentThermostatMode === commands.AUTO) {
      const [currentHeat] = current
      const [nextHeat, nextCool] = next
      if (currentHeat.key !== nextHeat.key) {
        return current
      }
      if (nextHeat.val < nextCool.val - 2) {
        return next
      }
      return current
    }
    return next
  }

  render() {
    const {
      hideThermostatValues,
      currentThermostatMode,
      changeSetPoint,
      theme,
      hideText
    } = this.props
    return (
      <FlexDirection direction="column" style={{ width: '100%' }}>
        <FlexItem displayFlex flex={1}>
          {!hideText && (
            <FlexItem
              flex={2}
              style={{
                fontSize: theme.custom.font.size.mdlg
              }}>
              Change {currentThermostatMode} Setpoint
            </FlexItem>
          )}

          <Flex
            justifySpaceBetween
            flex={currentThermostatMode === commands.AUTO ? 2 : 1}>
            {!hideThermostatValues && this.getCurrentThermostatValue()}
          </Flex>
          <FlexItem flex={2} />
        </FlexItem>
        <FlexItem flex={2}>
          <MainWrap data-testid="range-slider">
            <SliderWrap>
              <Slider
                values={this.getSliderValues()}
                ticksCount={40}
                onChange={changeSetPoint}
                mode={this.mode}
              />
            </SliderWrap>
          </MainWrap>
        </FlexItem>
      </FlexDirection>
    )
  }
}

export default withTheme(ChangeSetPoints)
