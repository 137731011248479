// @flow
import React from 'react'
import { StyledCircularProgress } from './styles'
import { ProgressText, CenteredText, TitleText } from './styles'

type Props = {
  title: string,
  progressText: string,
  progressCaption: string,
  infoText: string,
  size: string,
  underlayColor: string,
  backgroundColor: string,
  lgFont: Boolean,
  stackColors: Array<string>,
  values: Array<number>
}

class CircularProgress extends React.Component<Props> {
  render() {
    const {
      title,
      progressText,
      progressCaption,
      infoText,
      size,
      underlayColor,
      lgFont,
      stackColors,
      values,
      ...rest
    } = this.props

    const stackValues = values.reduce((acc, value) => acc.concat([(acc[acc.length - 1] || 0) + value]), []).reverse()
    const total = stackValues[0]

    return (
      <div>
        <TitleText>{title}</TitleText>
        <ProgressText size={size} lgFont={lgFont}>
          <span>{progressText}</span>
          {progressCaption && <p>{progressCaption}</p>}
        </ProgressText>
        <StyledCircularProgress
          {...rest}
          size={size}
          value={100}
          underlayColor={underlayColor}
          style={{ position: 'absolute' }}
          classes={{
            circle: 'circle-custom-backdrop'
          }}
        />
        {stackValues.slice(1).map((value, index) => (
          <StyledCircularProgress
            {...rest}
            value={100 * value / total}
            size={size}
            underlayColor={stackColors[index]}
            style={{ position: index < values.length - 2 ? 'absolute' : '' }}
            classes={{
              circle: 'circle-custom-backdrop'
            }}
          />
        ))}
        <CenteredText>{infoText}</CenteredText>
      </div>
    )
  }
}

export default CircularProgress
