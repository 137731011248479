// @flow
import React from 'react'
import CarouselList from '@Common/CarouselList'
import FlexDirection from '@Common/Flex/FlexDirection'
import withScrollBehaviour from '@Common/CarouselList/withScrollBehaviour'

const TemplateHistory = ({
  items,
  currentSlideNumber,
  numberOfItemsPerSlide,
  lastSlideNumber
}) => (
  <FlexDirection direction="column">
    <CarouselList
      numberOfItemsPerSlide={numberOfItemsPerSlide}
      itemHeight={46}
      lastSlide={lastSlideNumber}
      items={items}
      currentSlide={currentSlideNumber}
    />
  </FlexDirection>
)

export default withScrollBehaviour(TemplateHistory)
