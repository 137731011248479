// @flow
import React from 'react'
import BaseCard from '@Common/Cards/BaseCard'
import WideBaseCard from '@Common/Cards/BaseCard/WideBaseCard'
import Flex, { FlexItem } from '@Common/Flex/FlexDirection'
import HubCommandsContainer from './HubCommandsContainer'
import Image from '../../../../images/thermostat_card.png'
import { withTheme } from 'emotion-theming'
import type { Theme } from '../../../../theme'

type CardProps = {
  text: ?string,
  value: ?string,
  name: string,
  selectedHubProperty: string,
  theme: Theme
}

const HubCard = ({
  text,
  name,
  value,
  selectedHubProperty,
  theme
}: CardProps) => {
  return selectedHubProperty !== 'hubCommands' ? (
    <BaseCard
      title={name.toUpperCase()}
      headerRight={() => (
        <span style={{ color: theme.palette.primary.green.mint }}>{value}</span>
      )}
      columnStart={5}
      columnSpan={9}
      rowStart={2}
      rowSpan={4}
      imageSrc={Image}
      content={() => (
        <Flex fullWidth fullHeight>
          {text ? (
            <FlexItem
              flex={1}
              displayFlex
              alignItemsCenter
              style={{ color: theme.palette.grey.main }}>
              {text}
            </FlexItem>
          ) : (
            <FlexItem
              flex={1}
              displayFlex
              alignItemsCenter
              style={{ color: theme.palette.grey.main }}>
              <span>
                Include or exclude a device, update the firmware, or restart the
                hub.
              </span>
            </FlexItem>
          )}
        </Flex>
      )}
    />
  ) : (
    <WideBaseCard
      title={name.toUpperCase()}
      headerRight={() => value}
      columnStart={5}
      columnSpan={9}
      rowStart={2}
      rowSpan={4}
      imageSrc={Image}
      leftContent={() => (
        <Flex fullWidth fullHeight>
          {text ? (
            <FlexItem
              flex={1}
              displayFlex
              alignItemsCenter
              style={{ color: theme.palette.grey.main }}>
              {text}
            </FlexItem>
          ) : (
            <FlexItem
              flex={1}
              displayFlex
              alignItemsCenter
              style={{ color: theme.palette.grey.main }}>
              <span>
                Include or exclude a device, update the firmware, or restart the
                hub.
              </span>
            </FlexItem>
          )}
        </Flex>
      )}
      rightContent={() => (
        <Flex fullWidth>
          <FlexItem flex={1} displayFlex flexEnd>
            <HubCommandsContainer
              width={'50px'}
              height={'50px'}
              showDescription
            />
          </FlexItem>
        </Flex>
      )}
    />
  )
}

export default withTheme(HubCard)
