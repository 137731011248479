import {
  SAVE_AUTH,
  UPDATE_USER_OPTIMISTIC,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  VALIDATE_SIGN_UP_LINK_SUCCESS,
  VALIDATE_SIGN_UP_LINK_FAILURE,
  SIGN_UP_SUCCESS,
  UPDATE_SENDBIRD_INFO,
  LOGIN_FAILED
} from '../actions/'

const initialState = {
  isAuth: false,
  id: undefined,
  profile: undefined,
  token: undefined,
  refreshToken: undefined,
  sendbirdToken: undefined,
  sendbirdAppID: undefined,
  sendbirdUserID: undefined,
  isValidSignupToken: undefined,
  signupSuccessful: undefined,
  loginFailed: undefined
}

const authorization = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_AUTH: {
      const {
        profile,
        info: {
          id,
          id_token: token,
          refresh_token: refreshToken,
          sendbird_token: sendbirdToken,
          sendbird_app_id: sendbirdAppID,
          sendbird_user_id: sendbirdUserID
        }
      } = action
      const { signupSuccessful } = state

      return {
        isAuth: true,
        id,
        profile,
        token,
        refreshToken,
        sendbirdToken,
        sendbirdAppID,
        sendbirdUserID,
        ...(signupSuccessful != null ? { signupSuccessful } : {})
      }
    }
    case UPDATE_USER_OPTIMISTIC:
    case UPDATE_USER_SUCCESS:
    case UPDATE_USER_ERROR:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.attributes
        }
      }
    case VALIDATE_SIGN_UP_LINK_SUCCESS:
      return {
        ...state,
        isValidSignupToken: 'YES'
      }
    case VALIDATE_SIGN_UP_LINK_FAILURE:
      return {
        ...state,
        isValidSignupToken: 'NO'
      }
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signupSuccessful: true
      }
    case UPDATE_SENDBIRD_INFO: {
      const {
        sendbirdInfo: { sendbirdToken, sendbirdAppID, sendbirdUserID }
      } = action

      return {
        ...state,
        sendbirdToken,
        sendbirdAppID,
        sendbirdUserID
      }
    }
    case LOGIN_FAILED:
      return {
        ...state,
        loginFailed: true
      }
    default:
      return state
  }
}

export default authorization
