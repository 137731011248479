import React from 'react'
import { RadioGroupLabel } from '../../AmenityShared/styles'
import HorizRadioButtons from '@Common/HorizRadioButtons'
import FileUpload from '@Common/FileUpload'
import Flex, { FlexItem } from '@Common/Flex'

export default function WaiverModalContent({
  waivers,
  errorColor,
  handleWaivers,
  onFileDelete,
  fileSizeLimit,
  fileUploadError,
  onFileUploadError,
  signatureFrequency,
  selectWaiverSignatureFrequency,
}) {
  return (
    <Flex direction="column">
      <FlexItem>
        <FileUpload
          modalType={'waiver'}
          uploadedFiles={waivers}
          onFileUpload={handleWaivers}
          onFileUploadError={onFileUploadError}
          onFileDelete={onFileDelete}
          fileSizeLimit={fileSizeLimit}
          fileType={['application/pdf']}
          fileUploadError={fileUploadError}
          fileExtension={['PDF']}
          maxCount={1}
          multiple={false}
        />
        { fileUploadError !== '' && (
          <FlexItem style={{ color: errorColor, fontSize: '12px' }}>
            File must be a PDF and smaller than 10MB
          </FlexItem>
        )
        }
        <FlexItem paddingTop="40px" paddingLeft="44px" paddingBottom="35px">
          <Flex direction="column">
            <FlexItem><RadioGroupLabel>Signature Frequency</RadioGroupLabel></FlexItem>
            <FlexItem>
              <HorizRadioButtons
                handleChange={selectWaiverSignatureFrequency}
                handleClick={selectWaiverSignatureFrequency}
                name='Signature frequency'
                defaultValue={'everyTime'}
                selectedValue={signatureFrequency}
                options={[
                  {
                    id: 'sig-frequency-every-time',
                    label: 'Every Time',
                    value: 'everyTime',
                    classLabel: 'radio-button-amenity-availability-label',
                  },
                  {
                    id: 'sig-frequency-once-per-year',
                    label: 'Once Per Year',
                    value: 'annual',
                    classLabel: 'radio-button-amenity-availability-label',
                  },
                ]}
                textSize="16px"
                marginLeft="8px"
                noUnderline
              />
            </FlexItem>
          </Flex>
        </FlexItem>
      </FlexItem>
    </Flex>
  )
}
