// @flow
import React, { Fragment } from 'react'
import { List } from '@material-ui/core'
import { components } from 'react-select';
import {
  SelectionMenuFlex,
  SelectionMenuBackButtonBG,
  SubcategoryHeading,
  SubcategoryListItemText,
  SubcategoryListItem,
  SelectionMenuBack
} from './styles'

type Category = {
  name: string,
  subCategories: [string]
}

type Props = {
  handleToggle: (Object) => any,
  handleSubcategory: (Object) => any,
  handleMenuClose: (Object) => any,
  categories: [Category],
  category: string,
  subCategorySelection: boolean,
  children: Object
}

export class SelectionMenu extends React.Component<Props, {}> {
  render() {
    const { categories, subCategorySelection, category, handleSubcategory, handleMenuClose } = this.props
    const categoryObj = categories.find(c => c.name === category)
    return (
      <Fragment>
        {subCategorySelection ?
          <components.Menu {...this.props}>
            <SelectionMenuFlex direction="row">
              <SelectionMenuBackButtonBG
                onClick={handleMenuClose}
                size="small"
              >
                <SelectionMenuBack fontSize='small' />
              </SelectionMenuBackButtonBG>
              <SubcategoryHeading>{category}</SubcategoryHeading>
            </SelectionMenuFlex>
            <List dense={true} component="nav">
              {categoryObj && categoryObj.subCategories.map(sc =>
                <SubcategoryListItem button onClick={() => handleSubcategory({ value: sc })}>
                  <SubcategoryListItemText primary={sc.toUpperCase()} />
                </SubcategoryListItem>
              )}
            </List>
          </components.Menu>
          :
          <components.Menu {...this.props}>{this.props.children}</components.Menu>
        }
      </Fragment>
    )
  }
}

export default SelectionMenu
