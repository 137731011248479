import WorkOrderSummary from './WorkOrderSummary'
import { connect } from 'react-redux'
import { fetchWorkOrdersList, updateWorkOrder } from 'zego-shared/store/workOrders/actions'
import { fetchUnits, fetchUnit } from 'zego-shared/store/select/units/actions'
import { fetchPropertyUsersSummary } from 'zego-shared/store/users/propertyUsersSummary/actions'
import withFetchData from 'zego-shared/views/withFetchData'
import { getWorkOrders, getWorkOrderCounts, getWorkOrderAssignees } from 'zego-shared/store/workOrders/selectors'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { getUnitName } from 'zego-shared/store/select/selectors'
import queryString from 'query-string'

const fetchingSelector = createIsFetchingSelector(['workordersummarylist'])

export const mapStateToProps = (
  state,
  {
    location: { search },
    match: { params: { propertyId, unitId } }
  }
) => ({
  workOrders: getWorkOrders(state, unitId),
  workOrderCounts: getWorkOrderCounts(state, unitId),
  unitName: getUnitName(state),
  isFetching: fetchingSelector(state),
  assignees: getWorkOrderAssignees(state),
  filter: queryString.parse(search).filter,
  propertyId,
  unitId
})

export const mapDispatchToProps = (
  dispatch,
  { match: { params: { unitId, propertyId } } }
) => ({
  fetchData: () => {
    dispatch(fetchWorkOrdersList())
    dispatch(fetchUnits())
    unitId && dispatch(fetchUnit(unitId))
    dispatch(fetchPropertyUsersSummary())
  },
  updateWorkOrder: (workOrder) => dispatch(updateWorkOrder(workOrder))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(WorkOrderSummary))
