import { SURVEY_STATS } from '../actions'
import withFetch from '../../helpers/reducers/withFetch'

const initState = {}

const reducer = (state = initState, action) => {
  return state
}

export default withFetch(SURVEY_STATS)(reducer)
