import moment from 'moment'

export type Availabilities = {
  numResourcesAvailable: number,
  startTime: string,
  endTime: string,
  status: string,
  numAvailable: number,
  numTotal: number,
}

export type Interval = number // minutes

export type TimeBlock = {
  status?: string, // reservation, closed, blocked, buffer
  content: string,
  start: number,
  length: number,
  startTime: Date,
  endTime: Date,
  numAvailable: number,
  numTotal: number,
  numResourcesAvailable: number,
}

export const scheduleIncrement = 15;
export const getScheduleRowIndex = (time, offset = 0) => 1
  + Math.round((militaryTimeToMinutes(time) - militaryTimeToMinutes(offset)) / scheduleIncrement)

export type MultiBlock = {
  blocks: Array<TimeBlock>,
}

const dateEquality = (a: Date, b: Date) => {
  return a.getTime() === b.getTime()
}

const addInterval = (date: Date, interval: Interval) => {
  return moment(date).add(interval, 'm').toDate()
}

const toDateJS = (dateTime: string) => {
  const dateMoment = moment(dateTime).startOf('minute').format()

  return new Date(dateMoment)
}

const toInterval = (start: Date, end: Date) => {
  let diff = (start.getTime() - end.getTime()) / 1000
  diff /= 60
  return Math.abs(Math.round(diff))
}

const firstBlockIndex = (startWindow: Date, avails, index = 0) => {
  if (index >= avails.length) {
    return index
  }

  return startWindow.getTime() < avails[index].endTime.getTime()
    ? index
    : firstBlockIndex(startWindow, avails, index + 1)
}

const lastBlockIndex = (endWindow: Date, avails, index = avails.length - 1) => {
  if (!index || index < 0) return 0

  return endWindow.getTime() > avails[index].startTime.getTime()
    ? index
    : lastBlockIndex(endWindow, avails, index - 1)
}

const getFirstBlock = (
  availabilities: Availabilities,
  startWindow: Date,
  duration: Interval
) => {
  if (!availabilities.length)
    return [
      {
        start: 0,
        startTime: startWindow,
        endTime: addInterval(startWindow, duration),
        length: duration,
        status: 'closed',
      },
    ]
  const { startTime, endTime, status } = availabilities[0]

  if (dateEquality(startTime, startWindow)) {
    return [
      {
        start: 0,
        startTime,
        endTime,
        status,
        length: toInterval(startTime, endTime),
        ...availabilities[0],
      },
    ]
  } else if (startTime > startWindow) {
    return [
      {
        start: 0,
        startTime: startWindow,
        endTime: startTime,
        length: toInterval(startWindow, startTime),
        status: 'closed',
      },
    ]
  } else {
    return [
      {
        ...availabilities[0],
        start: 0,
        startTime: startWindow,
        endTime: endTime,
        length: toInterval(startWindow, endTime),
        status,
      },
    ]
  }
}

const getLastBlock = (result: MultiBlock, duration: Interval) => {
  const endWindow = addInterval(result[0].startTime, duration)
  const lastBlock = result[result.length - 1]
  if (lastBlock.endTime.getTime() < endWindow.getTime()) {
    result.push({
      start: lastBlock.start + lastBlock.length,
      startTime: lastBlock.endTime,
      endTime: endWindow,
      length: duration - lastBlock.start - lastBlock.length,
      status: 'closed',
    })
  } else if (lastBlock.endTime.getTime() > endWindow.getTime()) {
    const newLastBlock = {
      ...lastBlock,
      endTime: endWindow,
      length: toInterval(lastBlock.startTime, endWindow),
    }
    result[result.length - 1] = newLastBlock
  }

  return result
}

const reshapeAvailabilities = (
  availabilities,
  startWindow: Date,
  duration: number
) => {
  const endWindow = addInterval(startWindow, duration)
  const firstIndex = firstBlockIndex(startWindow, availabilities)
  const lastIndex = lastBlockIndex(endWindow, availabilities)

  const result = availabilities.slice(firstIndex, lastIndex + 1)

  return result
}

export const formatMilitaryTime = (time: number) => {
  const minute = time % 100
  const hour = ((time - minute) / 100) % 24
  const hourAdjusted = (hour === 0 || hour === 12) ? 12 : hour % 12
  const amPm = hour < 12 ? 'am' : 'pm'
  return minute === 0
    ? `${hourAdjusted}${amPm}` :
    `${hourAdjusted}:${minute < 10 ? `0${minute}` : minute}${amPm}`
}

export const getTimeArray = (min: number, max: number) => {
  // returns true if the next time is within the range
  const shouldYield = (t) => t >= min && (t <= max)
  function* generateNextTime() {
    let hour = Math.floor(min / 100)
    while (hour < 24) {
      let t = hour * 100
      while (t % 100 < 60) {
        if (shouldYield(t)) {
          yield { value: t, label: formatMilitaryTime(t) }
        }
        t += 15
      }
      hour += 1
    }
    if (max >= 2400) {
      yield { value: 2400, label: `12:00 am` }
    }
  }
  return Array.from(generateNextTime())
}


export const getTimeBlockEndTimes = (amenity, timeSlots, newFacilityBlock) => {
  const { startTime: scheduleStart, endTime: scheduleEnd } = getStartAndEndTimeFromTimeSlots(timeSlots)
  const { startTime: selectedStart, endTime: selectedEnd } = newFacilityBlock
  const selectedValue =  {};
  const options = []
  // we add one so that we can't select the same start and end time
  const min = Math.max(scheduleStart + 1, selectedStart >= 0 ? selectedStart + 1 : 0)
  switch (amenity.reservationType) {
    case 'multiDay':
    case 'fullFacility':
      options.push(...getTimeArray(min, scheduleEnd + 15))
      break
    default:
      options.push(...timeSlots
        .map(({ endTime }) => ({value: endTime, label: formatMilitaryTime(endTime)}))
        .filter(({ value }) => value > selectedStart))
  }
  selectedValue.value = selectedEnd
  selectedValue.label = selectedValue.value >= 0 ? formatMilitaryTime(selectedValue.value) : null
  return {
    options,
    value : selectedValue.label ? selectedValue : null,
  }
}

export const getTimeBlockStartTimes = (amenity, timeSlots, newFacilityBlock) => {
  const { startTime: scheduleStart, endTime: scheduleEnd } = getStartAndEndTimeFromTimeSlots(timeSlots)
  const { startTime: selectedStart } = newFacilityBlock
  const selectedValue =  {};
  const options = []
  switch (amenity.reservationType) {
    case 'multiDay':
    case 'fullFacility':
      options.push(...getTimeArray(scheduleStart, scheduleEnd))
      break
    default:
      options.push(...timeSlots.map(({ startTime }) => ({
        value: startTime,
        label: formatMilitaryTime(startTime)
      })))
  }
  selectedValue.value = selectedStart
  selectedValue.label = selectedValue.value >= 0 ? formatMilitaryTime(selectedValue.value) : null
  return {
    options,
    value : selectedValue.label ? selectedValue : null,
  }
}

export const getTimeSlotLabel = (
  slot: Object,
  reservationType: string,
  extraAreaName?: string
) => {
  const { status, startTime, endTime } = slot
  let {startTimeShortString, endTimeShortString } = slot
  if(startTimeShortString === '12:00 am') { startTimeShortString = 'midnight'}
  if(endTimeShortString === '12:00 am') { endTimeShortString = 'midnight'}
  switch (reservationType) {
    case 'fullFacility':
      const secondaryLabel = startTimeShortString !== 'midnight' || endTimeShortString !== 'midnight' ?
        `${startTimeShortString} - ${endTimeShortString}` : 'all day'
      if (status === 'full') {
        return { primaryLabel: 'Reserved', secondaryLabel }
      } else if (status === 'available' || status === 'open') {
        return { primaryLabel: 'available', secondaryLabel }
      }
      return { primaryLabel: status, secondaryLabel }
    default:
      const { numOccupied, numResourcesReserved } = slot
      const extraAreaString = !!numResourcesReserved && extraAreaName
        && `${numResourcesReserved} ${extraAreaName}`

      if (status === 'available') {
        return {
          primaryLabel: `${numOccupied} reserved`,
          secondaryLabel: extraAreaString
        }
      } else if(status === 'closed') {
        const duration = militaryTimeToMinutes(endTime) - militaryTimeToMinutes(startTime)
        if(duration > 60) {
          const closedLabel = startTimeShortString !== 'midnight' || endTimeShortString !== 'midnight' ?
          `${startTimeShortString} - ${endTimeShortString}` : 'all day'
          return { primaryLabel: status, secondaryLabel: closedLabel }
        }
      }
      return { primaryLabel: status }
  }
}

export const minutesToMilitaryTime = (minutes, wrap = true) => {
  let mt = (100 * ((minutes - (minutes % 60)) / 60) + (minutes % 60))
  if (mt === 2400 || !wrap) { return mt }
  return mt % 2400
}
export const militaryTimeToMinutes = (militaryTime) => (militaryTime % 100) + (60 * (militaryTime - (militaryTime % 100)) / 100)

export const addMinutesToMilitaryTime = (time, minutes, wrap = true) => minutesToMilitaryTime(militaryTimeToMinutes(time,) + minutes, wrap)

export const getLengthInMinutes = ({ startTime, endTime }) => militaryTimeToMinutes(endTime) - militaryTimeToMinutes(startTime)

export const getScheduleStartAndEndTimes = (timeSlots) => {
  if (!timeSlots?.length) {
    return { startTime: 0, endTime: 0 }
  }

  const { startTime, startDate } = timeSlots[0]
  let { endTime, endDate } = timeSlots[timeSlots.length - 1]
  if (startDate !== endDate) {
    endTime += 2400
  }

  return { startTime, endTime }
}

export const filterTimeSlotsToWindow = (timeSlots, windowStartTime, duration) => {
  if(!timeSlots || timeSlots.length === 0) {
    return []
  }

  let remaining = duration
  let { startTime: initialTime } = timeSlots[0]
  const result = []

  if(initialTime < windowStartTime) {
    timeSlots[0].startTime = windowStartTime
    initialTime = windowStartTime
  }

  for(let timeSlot of timeSlots) {
    const {startTime, endTime} = timeSlot
    const endMinutes = militaryTimeToMinutes(endTime)
    remaining = remaining - (endMinutes-militaryTimeToMinutes(startTime))
    if(remaining < 0) {
      timeSlot.endTime = minutesToMilitaryTime(endMinutes + remaining)
    }
    if(timeSlot.startTime !== timeSlot.endTime) {
      if(timeSlot.startTime < initialTime) {
        timeSlot.startTime += 2400
        timeSlot.endTime += 2400
      }
      result.push(timeSlot)
    }
    if(remaining <= 0) {
      break
    }
  }
  return result
}

export const getStartAndEndTimeFromTimeSlots = (timeSlots = []) => {
  let startTime = 0;
  let endTime = 0;

  if (!timeSlots?.length) {
    return { startTime, endTime }
  }

  if (timeSlots[0].startTime === 0 && timeSlots[0].status === 'closed') {
    startTime = timeSlots[0].endTime
  } else {
    startTime = timeSlots[0].startTime
  }

  if (timeSlots[timeSlots.length - 1].endTime === 2400 && timeSlots[timeSlots.length - 1].status === 'closed') {
    endTime = timeSlots[timeSlots.length - 1].startTime
  } else {
    endTime = timeSlots[timeSlots.length - 1].endTime
  }
  return { startTime, endTime }
}

export const getTimeBlocks: () => MultiBlock = (
  availabilitiesString: Availabilities,
  start: string,
  duration: Integer
) => {
  const startWindow = toDateJS(start)

  let availabilitiesFormatted = availabilitiesString.map((avail) => {
    return {
      ...avail,
      startTime: toDateJS(avail.startTime),
      endTime: toDateJS(avail.endTime),
    }
  })
  let availabilities = reshapeAvailabilities(
    availabilitiesFormatted,
    startWindow,
    duration
  )
  let result = getFirstBlock(availabilities, startWindow, duration)

  availabilities.forEach((avail, index) => {
    const {
      startTime,
      endTime,
      status,
      numResourcesAvailable,
      numAvailable,
      numTotal,
      blockId
    } = avail
    let prev = result[result.length - 1]

    if (
      dateEquality(startTime, prev.startTime) ||
      dateEquality(endTime, prev.endTime)
    )
      return

    if (!dateEquality(prev.endTime, startTime)) {
      result.push({
        start: prev.start + prev.length,
        startTime: prev.endTime,
        endTime: startTime,
        status: 'buffer',
        length: toInterval(prev.endTime, startTime),
      })

      prev = result[result.length - 1]
    }

    const block = {
      start: prev.start + prev.length,
      length: toInterval(startTime, endTime),
      startTime,
      endTime,
      status,
      numResourcesAvailable,
      numAvailable,
      numTotal,
      blockId
    }

    result.push(block)
  })

  return availabilities.length ? getLastBlock(result, duration) : result
}

// #region Move to amenityUtils.js
export const getDefaultClosedDaySchedule = () => ({ startTime: 0, endTime: 0 })

export const getDefaultAllDaySchedule = () => ({ startTime: 0, endTime: 2400 })

export const getDefaultDaySchedule = (reservationType) =>
  (reservationType === 'multiDay' ? getDefaultAllDaySchedule() : { startTime: 900, endTime: 2200 })

export const getDefaultCheckInDaySchedule = (reservationType) =>
  (reservationType === 'multiDay' ? { checkInTime: 1500, checkOutTime: 1000 } : undefined)

export const getDefaultDuration = (reservationType) => {
  switch (reservationType) {
    case 'fullFacility':
      return { minDuration: 60, duration: 180 }
    case 'hourly':
      return { duration: 60 }
    default:
      return undefined
  }
}

const dayArray = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

export const getDefaultSchedule = (reservationType: string) => {
  let schedule = undefined;

  if (reservationType) {
    schedule = {}
    switch (reservationType) {
      case 'multiDay':
        const mdDaySchedule = getDefaultAllDaySchedule()
        const mdDayCheckInSchedule = getDefaultCheckInDaySchedule(reservationType)
        dayArray.forEach((day) => schedule[day] = {
          ...mdDaySchedule,
          ...mdDayCheckInSchedule,
        })
        break;
      case 'hourly':
      case 'fullFacility':
      default:
        const daySchedule = getDefaultDaySchedule(reservationType)
        dayArray.forEach((day) => schedule[day] = {
          ...daySchedule,
        })
        break;
    }
  }

  return schedule ? { schedule } : undefined
}

export const getDefaultAmenityProperties = (params: Object) => {
  const { reservationType } = params
  const schedule = getDefaultSchedule(reservationType)
  const checkInSchedule = getDefaultCheckInDaySchedule(reservationType)
  const defaultDuration = getDefaultDuration(reservationType)

  return {
    ...params,
    ...schedule,
    ...checkInSchedule,
    ...defaultDuration,
  }
}
// #endregion
