import { FETCH_PREFIX, SUCCESS } from '../actions/constants'

// fetch reducer without normalization
export default (section, key) => reducer => (state, action) => {
  switch (action.type) {
    case `${FETCH_PREFIX}${section}${SUCCESS}`: {
      if (key) {
        return {
          ...state,
          [key]: action.payload
        }
      }

      if (Array.isArray(action.payload)) {
        return action.payload
      }

      return { ...state, ...action.payload }
    }
    default:
      return reducer(state, action)
  }
}
