import get from 'lodash/get'
import {
  getTimeWithAMPM,
  getDate,
  isToday,
  isYesterday,
  isSameDay
} from '../../../utils'
import getDeviceMessage from './deviceHistorySelectorUtils'

const getEventDate = date => {
  if (isToday(date)) {
    return 'Today'
  }
  if (isYesterday(date)) {
    return 'Yesterday'
  }
  return getDate(date)
}

const isNextEventSameDay = (index, data) => {
  return index !== 0
    ? isSameDay(data[index].inserted_at, data[index - 1].inserted_at)
    : false
}

const deviceHistoryToDeviceHistoryCarouselItem = (
  deviceHistoryCarouselItemList,
  deviceHistory,
  index,
  data
) => {
  // this is the header
  if (!isNextEventSameDay(index, data)) {
    deviceHistoryCarouselItemList.push({
      date: '',
      time: '',
      message: '',
      divider: true
    })
  }
  deviceHistoryCarouselItemList.push({
    date: getEventDate(deviceHistory.inserted_at),
    time: getTimeWithAMPM(deviceHistory.inserted_at),
    message: getDeviceMessage(deviceHistory)
  })
  return deviceHistoryCarouselItemList
}

export const getDeviceHistory = (state: {}) => {
  const data = get(state, 'unit.deviceHistory.data')
  return data ? data.reduce(deviceHistoryToDeviceHistoryCarouselItem, []) : []
}
