// @flow
import type { Match } from 'react-router-dom'
import type { Theme } from '../../../theme'
import type { TypeDefaultSettings } from './types'

import React from 'react'
import 'react-image-gallery/styles/css/image-gallery.css'
import { Global } from '@emotion/core'
import { BrowserHistory } from 'history'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import Tooltip from '@material-ui/core/Tooltip'
import moment from 'moment'
import { withTheme } from 'emotion-theming'

import Calendar from '@Common/CalendarV2'
import CTAButtons from '@Common/CTAButtons'
import Flex, { FlexItem } from '@Common/Flex'
import InfoCard from '@Common/InfoCard'
import Loader from '@Common/Loader'
import TopNavigation from '@Common/TopNavigation'

import AmenityReservationDetail from './AmenityProReservations'
import AmenityAvailabilityForm from './AmenityProAvailability'
import AmenityCreateReservation from './AmenityProCreateReservation'
import AmenityForm from './AmenityProDetails'
import AmenityAvailabilitySchedule from './AmenityProAvailabilitySchedule'
import AmenityProDetailImageGallery from './AmenityProDetailImageGallery'
import AmenityModals, { modals } from "./Modals/AmenityProModals"
import { validateNumber } from '../../../utils/validationUtils'

import {
  getLengthInMinutes,
  addMinutesToMilitaryTime,
  formatMilitaryTime,
  getDefaultClosedDaySchedule,
  getDefaultDaySchedule
} from "../../../utils/scheduleUtils";

import {
  validateReservation,
  getTimeSlotForTime,
  getPublishedAmenityEditWarnings
} from '../../../utils/amenityUtils'

import {
  AmenityClosedDiv,
  AmenityProButtonsArea,
  AmenityProContentArea,
  AmenityProNavArea,
  AmenityProSidebarArea,
  AmenityProStyleContainer,
  AvailabilityAvailableHours,
  AvailabilityCalendarDiv,
  AvailabilityTimeSlotsDiv,
  CalendarDiv,
  DraftModeBanner,
  DraftModeBannerContainer,
  DraftModeBannerLine,
  DraftModeBannerText,
  globalStyles,
} from '../AmenityShared/styles'

const baseRequiredFields = [
  'name',
  'description',
  'duration',
  'maxReservationsPerDay',
  'amenityItem',
]

const minLengths = {
  name: 2,
  description: 5,
}

const maxLengths = {
  name: 50,
  description: 2048,
  extraAreaName: 250,
  message: 40
}

const dayIndex = {
  "sun": 0,
  "mon": 1,
  "tue": 2,
  "wed": 3,
  "thu": 4,
  "fri": 5,
  "sat": 6
}

type Tab = {
  name: string,
  route?: string,
  query?: mixed,
}

export type TabsType = Array<Tab>

const getTabs = (url: string, showReservations): TabsType => [
  {
    id: 'tab-amenity-details',
    name: 'Details',
    route: `${url}/details`,
  },
  {
    id: 'tab-amenity-availability',
    name: 'Availability',
    route: `${url}/availability`,
  },
  ...showReservations ? [{
    id: 'tab-amenity-reservations',
    name: 'Reservations',
    route: `${url}/reservations`
  }] : []
]

const dayArray = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
const getSchedule = (date, schedule): Object => {
  if (!date) { return null }
  const day = dayArray[new Date(date).getUTCDay()]
  return schedule ? schedule[day] : undefined
}

type newReservationProps = {
  resident: Object,
  groupSize: number,
  message: ?string,
  includeResource: boolean,
  selectedFlexStartTime: ?number,
  selectedFlexEndTime: ?number,
  checkIn: ?string,
  checkOut: ?string
}

const emptyNewReservation: newReservationProps = {
  resident: null,
  groupSize: 1,
  message: null,
  includeResource: false,
  selectedFlexStartTime: null,
  selectedFlexEndTime: null,
  checkIn: null,
  checkOut: null,
}

const ViewModes = { edit: 'editMode', read: 'readMode' }

type Props = {
  item: Object,
  reservations: Array<{
    startDateTime: string,
    groupSize: number,
    includeResource: boolean,
    facilityUuid: string,
    startTime: number,
    startDate: string,
  }>,
  availability: Object,
  theme: Theme,
  history: BrowserHistory,
  match: Match,
  updateAmenity: (Object, number, string) => mixed,
  updateAmenityPhotoPin: (Object, number) => mixed,
  deleteAmenity: (number) => mixed,
  getAvailability: (string) => mixed,
  fetchReservations: (string) => mixed,
  createReservation: (Object, string) => mixed,
  deleteReservation: (Object, string, string) => mixed,
  isFetching: boolean,
  isUpdating: boolean,
  hasData: boolean,
  selectedAvailabilityDate: string,
  residents: Object,
  setViewMode: (Object) => mixed,
  readMode: boolean,
  editMode: boolean,
  showSnackbar: (string, string) => mixed,
  propertyId: number,
  deleteWaiver: Function,
  createFacilityBlock: Function,
  userName: string,
  deleteFacilityBlock: Function,
}

type State = {
  activeModal: string,
  item: Object,
  edited: Object,
  errors: Object,
  photos: Array<Object>,
  selectedAvailabilityDate: string,
  selectedAvailabilityTime: number,
  formFields: Object,
  currentSchedule: Object,
  createReservationMode: boolean,
  newReservation: {
    resident: Object,
    groupSize: number,
    message: ?string,
    includeResource: boolean,
    selectedFlexStartTime: ?number,
    selectedFlexEndTime: ?number,
    checkIn: ?string,
    checkOut: ?string,
  },
  publishedAmenityEditWarnings: Array<string>,
  newFacilityBlock: Object,
  waivers: Object,
  fileUploadError: string,
  preventWaiverSubmission: boolean,
  signatureFrequency: string,
  fileSizeLimit: number,
  isAmenityDayBlocked: boolean,
  isAmenitySlotBlocked: boolean,
  selectedReservation: Object,
  reservations: Array<Object>,
  minMultiDayReservation: number,
  maxMultiDayReservation: number,
  defaultSettings: TypeDefaultSettings,
}

class AmenityPro extends React.Component<Props, State> {
  state = {
    activeModal: '',
    item: this.props.item,
    reservations: this.props.reservations,
    edited: {},
    errors: {},
    publishedAmenityEditWarnings: [],
    photos: [],
    images: [],
    formFields: {}, // will be populated by form as fields get declared
    currentSchedule: {},
    createReservationMode: false,
    newReservation: emptyNewReservation,
    selectedAvailabilityDate: this.props.selectedAvailabilityDate,
    selectedAvailabilityTime: 0,
    waivers: [],
    fileUploadError: '',
    preventWaiverSubmission: true,
    signatureFrequency: 'everyTime',
    fileSizeLimit: 10485760,
    ...(this.props.item
      ? {
        currentSchedule: getSchedule(this.props.selectedAvailabilityDate, this.props.item.schedule),
      }
      : {}
    ),
    newFacilityBlock: {},
    isAmenityDayBlocked: false,
    isAmenitySlotBlocked: false,
    selectedReservation: null,
    minMultiDayReservation: 1,
    maxMultiDayReservation: 3,
    defaultSettings: {
      checkInTime: 1500,
      checkOutTime: 1100,
      dayDefaults: {
        mon: {
          isClose: true,
        },
        tue: {
          isClose: true,
        },
        wed: {
          isClose: true,
        },
        thu: {
          isClose: true,
        },
        fri: {
          isClose: true,
        },
        sat: {
          isClose: true,
        },
        sun: {
          isClose: true,
        },
      },
    }
  }

  setActiveModal = (modal: string) => {
    this.setState({ activeModal: modal })
  }

  onModalAccept = (modal: string) => {
    this.setActiveModal('')
    switch (modal) {
      case modals.exit:
      case modals.publish:
        // handled via callback as modal prop
        return
      case modals.publishedAmenityEdited:
        this.publishAmenity()
        return
      case modals.deleteAmenity:
        this.deleteAmenity()
        return
      case modals.deleteReservation:
        this.deleteReservation()
        return
      case modals.deleteWaiver:
        this.deleteWaiver()
        this.updateItem({ waiverDocs: [], currentWaiver: '' })
        return
      case modals.blockSlot:
        this.confirmAmenitySlotBlocked()
        return
      case modals.blockDay:
        this.confirmAmenityDayBlocked()
        return
      case modals.waiver:
        this.saveWaiver()
        return
      default:
        return;
    }
  }

  onModalClose = (modal: string) => {
    this.setActiveModal('')
    switch (modal) {
      case modals.blockSlot:
        this.setState({ newFacilityBlock: {}, isAmenitySlotBlocked: false })
        return
      case modals.blockDay:
        this.setState({ isAmenityDayBlocked: false })
        return
      case modals.waiver:
        this.closeWaiverAlertModal()
        return
      case modals.deleteWaiver:
      case modals.exit:
      case modals.publish:
      case modals.deleteAmenity:
      case modals.deleteReservation:
      default:
        return
    }
  }

  closeAlertModal = () => {
    this.setState({ activeModal: '' })
  }

  setViewMode = (newMode: string) => {
    const { setViewMode } = this.props

    const falses = {
      readMode: false,
      editMode: false,
    }

    switch (newMode) {
      case ViewModes.read:
        setViewMode({
          ...falses,
          readMode: true,
        })
        break
      case ViewModes.edit:
        setViewMode({
          ...falses,
          editMode: true,
        })
        break
      default:
        break
    }
  }

  registerFormField = (field: string) => {
    const { formFields } = this.state
    formFields[field] = true

    if (field === 'reservationLimit') {
      delete formFields.totalOccupancy
    } else if (field === 'totalOccupancy') {
      delete formFields.reservationLimit
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // allow changes in props to override values we've cached in state
    const {
      availability,
      reservations,
      item,
    } = this.props

    const {
      item: prevItem,
      availability: prevAvailability,
      reservations: prevReservations,
    } = prevProps

    const newState = {
      // $FlowDisableNextLine
      ...(!isEqual(prevItem, item) ? { item } : {}),
      // $FlowDisableNextLine
      ...(!isEqual(prevAvailability, availability) ? { availability } : {}),
      // $FlowDisableNextLine
      ...(!isEqual(prevReservations, reservations) ? { reservations } : {}),
    }

    if (!isEmpty(newState)) {
      this.setState(newState)
    }
  }

  goToAmenitiesList = () => {
    const {
      history,
      match: { url },
    } = this.props

    const splitText = '/amenities'
    history.push(`${url.split(splitText)[0]}${splitText}`)
  }

  initiateExit = () => {
    const { readMode } = this.props
    const { edited, createReservationMode, selectedReservation } = this.state

    if (createReservationMode) {
      this.setState({ createReservationMode: false, newReservation: emptyNewReservation })
      return
    }

    if (selectedReservation !== null) {
      this.setState({ selectedReservation: null })

      return
    }

    if (readMode) {
      this.goToAmenitiesList()

      return
    }

    // edit mode
    const anyEdited = Object.values(edited).some((val) => val)
    if (anyEdited) {
      this.setActiveModal(modals.exit)
    } else {
      this.exitEditMode()
    }
  }

  exitEditMode = () => {
    const {
      history,
      item: oldItem,
      match: {
        params: { propertyId = '' },
      },
    } = this.props

    this.setViewMode(ViewModes.read)

    if (oldItem.placeholder) {
      this.deleteAmenity()

      if (!propertyId.toString().match(/\d+/)) {
        history.push('/summary')
        return null
      }
      history.push(`/properties/${propertyId}/amenities`)
    }

    //this.closeAlertModal()
    this.updateItem(
      { item: oldItem },
      // callback for after state changed:
      () => this.setViewMode(ViewModes.read),
    )
  }

  initiateAmenitySave = () => {
    const { item: { published } } = this.state

    if (this.hasErrors()) {
      this.showFixHighlightedErrorsSnackbar()
      return
    }

    if (published) {
      this.showPublishedAmenityEditDialog()
    } else {
      this.setActiveModal(modals.publish)
    }
  }

  showPublishedAmenityEditDialog = () => {
    const {
      item
    } = this.state

    const {
      item: itemOriginal
    } = this.props

    const warnings = getPublishedAmenityEditWarnings(item, itemOriginal)
    this.setState({ publishedAmenityEditWarnings: getPublishedAmenityEditWarnings(item, itemOriginal) })
    if (warnings.length !== 0) {
      this.setActiveModal(modals.publishedAmenityEdited)
    } else {
      this.publishAmenity()
    }
  }

  saveAmenityIfChanged = () => {
    const {
      edited,
      selectedAvailabilityDate,
      item
    } = this.state

    this.closeAlertModal()

    const { updateAmenity } = this.props

    const error = this.hasErrors()
    if (!error) {
      if (!item.extraReservableArea) {
        delete item.resources
        delete edited.extraAreaName
        delete edited.extraAreaQuantity
      }
      // remove already added photos from items
      item.photos = item.photos.filter(photo => !photo.hasOwnProperty('photoUuid'))

      const anyEdited = Object.values(edited).some((val) => val)
      if (anyEdited) {
        item.placeholder = false
        updateAmenity(item, item.facilityUuid, selectedAvailabilityDate)
      } else {
        this.setViewMode(ViewModes.read)
        if (item.published) {
          this.props.getAvailability(selectedAvailabilityDate)
        }
        this.setState({ publishedAmenityEditWarnings: [] })
      }
    }
  }

  initiateDelete = () => {
    this.setActiveModal(modals.deleteAmenity)
  }

  initiateWaiverDelete = () => {
    this.setActiveModal(modals.deleteWaiver)
  }

  showWaiverModal = () => {
    this.setActiveModal(modals.waiver)
  }

  publishAmenity = () => {
    if (this.props.item.published) {
      this.saveAmenityIfChanged()
      return
    }
    this.updateItem({ published: true }, this.saveAmenityIfChanged)
  }

  initiateReservationDelete = () => {
    this.setActiveModal(modals.deleteReservation)
  }

  deleteAmenity = () => {
    const { deleteAmenity: deleteAmenityFromProps } = this.props
    const { facilityUuid } = this.state.item
    deleteAmenityFromProps(facilityUuid)
  }

  deleteReservation = () => {
    const { selectedReservation, selectedAvailabilityDate, item } = this.state
    const { message } = item;
    const { residentUuid } = selectedReservation || {}
    const { display } = this.props.residents.find(resident => resident.userId === residentUuid) || {}
    this.props.deleteReservation(selectedReservation, message, display)

    this.setState({
      createReservationMode: false,
      newReservation: emptyNewReservation,
      selectedAvailabilityDate: selectedAvailabilityDate,
      selectedReservation: null
    })

    this.closeAlertModal()
  }

  toggleEditMode = () => {
    const {
      item,
      createReservation,
      readMode,
      editMode,
      availability,
    } = this.props

    let timeSlots = null
    if (availability) {
      timeSlots = availability.timeSlots
    }

    const {
      edited,
      formFields,
      createReservationMode,
      newReservation,
      selectedAvailabilityTime: selectedTime,
      selectedReservation,
      selectedAvailabilityDate,
    } = this.state

    if (createReservationMode) {
      const { facilityUuid, schedule } = item
      let {
        resident: { userId: residentUuid, name, unitName, unitId },
        groupSize,
        message,
        includeResource,
        checkIn,
        checkOut,
        selectedFlexStartTime,
        selectedFlexEndTime,
      } = newReservation

      let startDate
      let endDate
      let startTime
      let endTime

      if (item.reservationType === 'multiDay') {
        if (!checkIn) {
          checkIn = moment().format('yyyy-MM-DD');
        }

        const dayStart = moment(checkIn).format('dddd').slice(0, 3).toLocaleLowerCase()
        const dayEnd = moment(checkOut).format('dddd').slice(0, 3).toLocaleLowerCase()

        startTime = schedule[dayStart].checkInTime
        endTime = schedule[dayEnd].checkOutTime
        startDate = moment(checkIn).format('yyyy-MM-DD')
        endDate = moment(checkOut).format('yyyy-MM-DD')

        const { maxPeoplePerReservation = 1 } = item
        // Minus one means excluding the resident who made the reservation from counting.
        groupSize = Math.max(maxPeoplePerReservation - 1, 1)

      } else if (item.reservationType === 'fullFacility') {
        startTime = selectedFlexStartTime
        endTime = selectedFlexEndTime
        startDate = selectedAvailabilityDate
        endDate = selectedAvailabilityDate
      } else {
        const { startDate: sd, endDate: ed, startTime: st, endTime: et } = getTimeSlotForTime(selectedTime, timeSlots)

        startTime = st
        endTime = et
        startDate = sd
        endDate = ed
      }

      const reservation = {
        facilityUuid,
        residentUuid,
        startDate,
        endDate,
        startTime,
        endTime,
        residentUnitName: unitName || '',
        residentUnitId: unitId || null,
        residentName: name || '',
        includeResource,
        message,
        groupSize,
      }

      createReservation(reservation, name)

      this.setState({
        createReservationMode: false,
        newReservation: emptyNewReservation,
      })

      return
    }

    if (selectedReservation !== null) {
      this.initiateReservationDelete();

      return
    }

    if (readMode) {
      this.setViewMode(ViewModes.edit)
      this.setState({ edited: {} })
      return
    }

    if (editMode) {
      const [scheduleFields] =
        Object.keys(edited).filter((key) => key.indexOf('schedule-') > -1 && key.split('-').length === 2).map((key) => {
          delete edited[key]
          delete formFields[key]

          return { [key + '-startTime']: true, [key + '-endTime']: true }
        })

      this.setState(
        {
          edited: {
            ...edited,
            ...formFields,
            ...scheduleFields
          },
        },

        // callback after state is updated to "all fields touched"
        () => {
          this.initiateAmenitySave()
        },
      )
    }
  }

  showCreateReservationButtonForMultiDay = () => {
    const {
      match: { params: { tab } },
      readMode,
    } = this.props

    const {
      createReservationMode,
    } = this.state

    if (!['reservations', 'availability'].find(x => x === tab)) {
      return false
    }

    return !this.props.editMode && !createReservationMode && readMode
  }

  showCreateReservationButtonForFullFacility = () => {
    const {
      match: { params: { tab } },
    } = this.props

    const {
      createReservationMode,
      item: { availability },
    } = this.state

    if (!['reservations', 'availability'].find(x => x === tab)) {
      return false
    }

    if (createReservationMode || !availability || availability === {}) {
      return false
    }

    if (availability.find(x => (!x.isPast && (x.status === 'open' || x.status === 'available')))) {
      return true;
    }

    return false;
  }

  showCreateReservationButtonForHourly = () => {
    const {
      match: { params: { tab } },
      readMode,
      availability,
    } = this.props

    const {
      selectedAvailabilityTime: selectedTime,
      createReservationMode,
    } = this.state

    if (!availability) {
      return false
    }

    const { timeSlots } = availability
    const selectedTimeSlot = getTimeSlotForTime(selectedTime, timeSlots)

    if (!selectedTimeSlot) return false

    if (!['reservations', 'availability'].find(x => x === tab)) {
      return false
    }

    if (!readMode || createReservationMode) {
      return false
    }

    const isAvailable = ['open', 'available'].find(x => x === selectedTimeSlot?.status)

    return isAvailable && !selectedTimeSlot.isPast
  }

  showCreateReservationButton = () => {
    const {
      item: { reservationType },
    } = this.state

    switch (reservationType) {
      case 'multiDay':
        return this.showCreateReservationButtonForMultiDay()
      case 'fullFacility':
        return this.showCreateReservationButtonForFullFacility()
      default:
        return this.showCreateReservationButtonForHourly()
    }
  }

  showCreateReservation = () => {
    this.setState({ createReservationMode: true, newReservation: emptyNewReservation })
  }

  updateNewReservation = (newReservation: Object) => {
    this.setState((oldState) => {
      return { newReservation: { ...oldState.newReservation, ...newReservation } }
    })
  }

  updateItem = (update: Object, postStateChangeCallback: Function) => {
    this.setState(
      (oldState) => {
        const { extraReservableAreaOld = false } = oldState.item

        const edits = Object.fromEntries(
          Object.entries(update)
            .map(([k]) => [k, true])
        )

        const newEdited = {
          ...oldState.edited,
          ...edits,
          ...(
            !extraReservableAreaOld
              ? {
                extraAreaName: false,
                extraAreaQuantity: false,
              }
              : {}
          ),
        }

        return {
          ...oldState,
          item: {
            ...oldState.item,
            ...update,
          },
          edited: newEdited,
        }
      },
      postStateChangeCallback,
    )
  }

  deleteImage = () => {
    this.updateItem({ croppedImage: null, picture: null })
  }

  handlePhotos = (uploadedPhotos: Array<string>) => {
    this.updateItem({ photos: uploadedPhotos })
  }

  handlePhotoPin = (pinnedPhotoUuid: string) => {
    const { updateAmenityPhotoPin } = this.props
    const { item: { facilityUuid, pinnedPhotoUuid: currentPinnedPhotoUuid } } = this.state
    const pinnedPhotoUuidObj = { pinnedPhotoUuid }
    if (currentPinnedPhotoUuid !== pinnedPhotoUuid) {
      this.updateItem(pinnedPhotoUuidObj)
      updateAmenityPhotoPin(pinnedPhotoUuidObj, facilityUuid)
    }
  }

  handlePhotoDelete = (photo: Object) => {
    const { item: { deletedPhotos, photos } } = this.state
    let updatedPhotos = photos.filter((p) => p.photoUuid !== photo.photoUuid)

    if (photo.photoUuid) {
      this.updateItem({ deletedPhotos: [...new Set([...deletedPhotos, photo.photoUuid])], photos: updatedPhotos })
    } else {
      updatedPhotos = photos.filter((p) => p.name !== photo.name)
      this.updateItem({ photos: updatedPhotos })
    }
  }

  handleChange = ({ currentTarget: { id, value } }: SyntheticEvent<HTMLInputElement>) => {
    let parsedValue = value

    const numericalFields = [
      'reservationLimit',
      'extraAreaQuantity',
      'maxPeoplePerReservation',
      'totalOccupancy',
      "maxReservationDays",
      'minMultiDayReservation',
      'maxMultiDayReservation'
    ]
    if (numericalFields.includes(id)) {
      if (!validateNumber(value)) {
        parsedValue = ''
      } else {
        parsedValue = parseInt(value, 10)
      }
    }

    if (id in maxLengths) {
      parsedValue = value.slice(0, maxLengths[id])
    }

    this.updateItem({ [id]: parsedValue })
  }

  showFixHighlightedErrorsSnackbar = () => {
    // we can make this more specific if we want
    this.props.showSnackbar('Please fix errors in the highlighted fields', 'error')
  }

  handleTabClick = (e: Object) => {
    if (!this.props.editMode) {
      return
    }
    const {
      formFields,
      edited,
    } = this.state

    // set the 'edited' field to true, otherwise component will not show error
    Object.keys(formFields).forEach(field => {
      edited[field] = true
    })

    this.setState({ edited: edited })

    if (this.hasErrors()) {
      // prevents the tab from switching
      e.preventDefault()
      this.showFixHighlightedErrorsSnackbar()
    }
  }

  switchDraftMode = (_e: Object, checked: boolean) => {
    const { editMode } = this.props
    if (editMode) {
      this.updateItem({ published: !checked })
    }
  }

  getTimeslotsForSelectedDate = (): Array<Object> | null => {
    const { availability } = this.props;
    const { selectedAvailabilityDate } = this.state;
    if (!availability) {
      return null
    }
    const { timeSlots } = availability;
    if (!timeSlots) {
      return null
    }

    const date = selectedAvailabilityDate || moment().format('yyyy-MM-DD')
    const result = (timeSlots.length > 0) ? timeSlots.filter(timeSlot => timeSlot.startDate === date) : null

    return result
  }

  setAvailabilityTime = (time: number) => {
    const { availability } = this.props
    if (!availability) {
      return
    }

    const { timeSlots } = availability
    const timeSlot = getTimeSlotForTime(time, timeSlots)
    let isAmenitySlotBlocked = false

    if (timeSlot) {
      const { facilityBlockUuid } = timeSlot
      isAmenitySlotBlocked = Boolean(facilityBlockUuid)
      this.setNewFacilityBlockTime(timeSlot)
    }
    this.setState((oldState) => ({
      ...oldState,
      createReservationMode: false,
      newReservation: emptyNewReservation,
      selectedAvailabilityTime: time,
      selectedReservation: null,
      isAmenitySlotBlocked,
    }))
  }

  setAvailabilityDate = (selectedDate: moment) => {
    const { schedule } = this.props.item
    const selectedDateString = selectedDate.format('yyyy-MM-DD')
    this.setState({
      createReservationMode: false,
      newReservation: emptyNewReservation,
      currentSchedule: getSchedule(selectedDateString, schedule),
      selectedAvailabilityDate: selectedDateString,
      selectedReservation: null,
    })
    this.props.getAvailability(selectedDateString)
    this.props.fetchReservations(selectedDateString)
  }

  handleSchedule = (day: string, time: string, value: number) => {
    this.registerFormField('schedule-' + day)
    this.setState(
      (oldState) => {
        const oldSchedule = JSON.parse(JSON.stringify(get(oldState, 'item.schedule', {})))
        oldSchedule[day][time] = value

        return {
          ...oldState,
          item: { ...oldState.item, ...{ schedule: oldSchedule } },
          edited: { ...oldState.edited, ['schedule-' + day + '-' + time]: true },
        }
      }
    )
  }

  handleScheduleDayCloseAsDefault = (day: string, checked: boolean) => {

    this.registerFormField(`schedule-${day}`)
    this.registerFormField(`schedule-${day}-startTime`)
    this.registerFormField(`schedule-${day}-endTime`)
    this.setState(
      (oldState) => {
        const oldSchedule = { ...oldState.item.schedule } || {}
        oldSchedule[day] = checked ? getDefaultClosedDaySchedule() : getDefaultDaySchedule()

        return {
          ...oldState,
          item: { ...oldState.item, ...{ schedule: oldSchedule } },
          edited: { ...oldState.edited, ['schedule-' + day]: true },
        }
      }
    )
  }

  _dayKeys = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
  getNextDayKey = (dayKey: string): string => {
    const index = this._dayKeys.findIndex(dk => dk === dayKey)
    if (index === -1)
      return ''
    else
      return this._dayKeys[(index + 1) === this._dayKeys.length ? 0 : index + 1]
  }

  getPreviousDayKey = (dayKey: string): string => {
    const index = this._dayKeys.findIndex(dk => dk === dayKey)
    if (index === -1)
      return ''
    else
      return this._dayKeys[(index - 1) < 0 ? this._dayKeys.length - 1 : index - 1]
  }

  handleScheduleDayCloseAsMultiDayReservationType = (day: string, checked: boolean) => {

    const { reservationType, checkInTime, checkOutTime } = this.state.item
    const nextDay = this.getNextDayKey(day)
    const previousDay = this.getPreviousDayKey(day)

    this.setState(
      (oldState) => {
        const oldSchedule = { ...oldState.item.schedule } || {}
        oldSchedule[day] = checked ? getDefaultClosedDaySchedule() : getDefaultDaySchedule(reservationType);

        oldSchedule[day]['checkInTime'] = oldSchedule[day]['endTime'] > 0 ? checkInTime : null
        oldSchedule[day]['checkOutTime'] = oldSchedule[previousDay]['endTime'] > 0 ? checkOutTime : null
        oldSchedule[nextDay]['checkOutTime'] = oldSchedule[day]['endTime'] > 0 ? checkOutTime : null

        return {
          ...oldState,
          item: { ...oldState.item, ...{ schedule: oldSchedule } },
        }
      }
    )
  }

  handleScheduleDayClose = (day: string, checked: boolean) => {
    const {
      item: {
        reservationType
      }
    } = this.state

    if (reservationType === 'multiDay') {
      this.handleScheduleDayCloseAsMultiDayReservationType(day, checked)
    } else {
      this.handleScheduleDayCloseAsDefault(day, checked)
    }
  }

  assignTimeValue = (dayKey: string, timePropName: string, time: number): ?number => {

    const prevDayKey = this.getPreviousDayKey(dayKey)

    // Something wrong.  Just return value as is.
    if (prevDayKey === '')
      return time

    const {
      item: { schedule }
    } = this.state

    switch (timePropName) {
      case 'checkInTime':
        // Assign value if current day is opened.
        return schedule[dayKey]['endTime'] > 0 ? time : null;
      case 'checkOutTime':
        // Assign value if previous day is opened.
        return schedule[prevDayKey]['endTime'] > 0 ? time : null;
      default:
        return time
    }
  }

  handleCheckInAndOutSchedule = (timePropName: string, time: number) => {
    let { checkInTime, checkOutTime } = this.state.item
    if (timePropName === 'checkInTime') {
      checkInTime = time
      if (checkInTime <= checkOutTime) {
        checkOutTime = addMinutesToMilitaryTime(checkInTime, -30)
      }
    } else if (timePropName === 'checkOutTime') {
      checkOutTime = time
      if (checkInTime <= checkOutTime) {
        checkInTime = addMinutesToMilitaryTime(checkOutTime, 30)
      }
    }

    this.setState(
      (oldState) => {
        const oldSchedule = JSON.parse(JSON.stringify(get(oldState, 'item.schedule', {})))
        for (const dayKey in oldSchedule) {
          oldSchedule[dayKey][checkOutTime] =
            this.assignTimeValue(dayKey, 'checkOutTime', checkOutTime)
          oldSchedule[dayKey][checkInTime] =
            this.assignTimeValue(dayKey, 'checkInTime', checkInTime)
        }

        return {
          ...oldState,
          item: { ...oldState.item, ...{ schedule: oldSchedule, checkOutTime, checkInTime } },
        }
      }
    )
  }

  handleSlotClick = (slot: Object) => {
    if (!slot) {
      return
    }
    const { item: { reservationType } } = this.state
    if (reservationType === 'fullFacility') {
      return
    }

    const { selectedAvailabilityTime: oldTime } = this.state
    if (!slot || slot.buffer || (slot.startTime === oldTime)) {
      return
    }

    if ((slot.status !== 'closed' && slot.status !== 'buffer')
      || (slot.facilityBlockUuid)) {
      this.setAvailabilityTime(slot.startTime)
    }
  }

  isError = (field: string) => {
    const {
      edited,
      item,
      item: {
        amenityType,
        reservationType,
        extraReservableArea,
        guestsAllowed,
        schedule
      },
    } = this.state

    if (!edited[field]) {
      return false
    }

    const { [field]: value } = item

    if (field.indexOf("schedule-") > -1) {
      const keys = field.split("-")
      const [day, time] = [keys[1], keys[2]]

      return (schedule[day][time] === -1 || this.getScheduleDurationError(day))
    }

    const requiredFields = [
      ...baseRequiredFields,
      ...extraReservableArea ? ['extraAreaName', 'extraAreaQuantity'] : [],
      ...amenityType === 'item' ? ['reservationLimit'] : ['totalOccupancy'],
      ...guestsAllowed ? ['maxPeoplePerReservation'] : [],
      ...reservationType === 'multiDay' ? ['minMultiDayReservation', 'maxMultiDayReservation'] : [],
      ...reservationType === 'fullFacility' ? ['minDuration'] : []
    ]

    if (requiredFields.includes(field) && !value) {
      return true
    }

    if (field === 'maxReservationDays') {
      const { maxReservationDays } = item
      return maxReservationDays > 180 || maxReservationDays < 0
    }

    // Verify day schedule start time and end time has sufficient duration
    if (field === "duration" || field === 'minDuration') {
      if (reservationType === 'fullFacility') {
        const {
          item: {
            duration,
            minDuration,
          },
        } = this.state
        return duration < minDuration || Object.keys(schedule).some(this.getScheduleDurationError)
      } else {
        return value === 60 && Object.keys(schedule).some(this.getScheduleDurationError)
      }
    }

    if (typeof value === "string" && field in minLengths
      && value.trim().replace(/ /g, "").length < minLengths[field]) {
      return true
    }

    if (['minMultiDayReservation', 'maxMultiDayReservation'].includes(field)) {
      if (reservationType !== 'multiDay')
        return true

      const {
        item: {
          minMultiDayReservation,
          maxMultiDayReservation,
        },
      } = this.state
      return maxMultiDayReservation < minMultiDayReservation;
    }

    return typeof value === "string" && field in maxLengths && value.length > maxLengths[field];
  }

  hasErrors = () => {
    const { formFields } = this.state
    const fields = Object.keys(formFields)
    const errors = fields.map(this.isError)
    return errors.some(a => a)
  }

  getIsAmenityOpen = () => {
    const { currentSchedule } = this.state

    return !(!currentSchedule
      || (currentSchedule.endTime === 0));
  }

  getScheduleDurationError = (day: string) => {
    const {
      item: {
        schedule,
        duration,
        minDuration,
        reservationType,
      },
    } = this.state
    const startTime = schedule[day]["startTime"], endTime = schedule[day]["endTime"];

    if ([startTime, endTime].includes(-1))
      return false;
    else {
      const diff = getLengthInMinutes({ endTime, startTime })
      const isDayScheduleClosed = diff === 0
      switch (reservationType) {
        case 'fullFacility':
          return !isDayScheduleClosed && (diff < minDuration || diff < duration)
        default:
          return !isDayScheduleClosed && diff < duration
      }
    }
  }

  getInfoCardContent = (availability, selectedTimeSlot) => {
    const {
      item,
      reservations,
      currentSchedule,
      selectedAvailabilityDate,
    } = this.state

    let timeSlots = null
    if (availability) {
      timeSlots = availability.timeSlots
    }

    const {
      match: { params: { tab } },
      readMode,
    } = this.props

    const isDetails = tab === 'details' || !tab
    const isAmenityOpen = this.getIsAmenityOpen()

    let isDayBlocked = false

    if (timeSlots && timeSlots.length === 1) {
      isDayBlocked = !!timeSlots[0].facilityBlockUuid
    }
    const {
      photos: existingPhotos = [],
      pinnedPhotoUuid,
      rsvpRequired,
      extraAreaQuantity,
      extraAreaName,
      reservationType,
      schedule
    } = item

    let availableHours = ''

    if (currentSchedule) {
      availableHours = formatMilitaryTime(currentSchedule.startTime)
      availableHours += ' - '
      availableHours += formatMilitaryTime(currentSchedule.endTime)
    }

    const getDateForPicker = () => {
      return moment(`${selectedAvailabilityDate}T00:00:00`)
    }

    const isMultiDay = reservationType === 'multiDay'

    // Mock Data until the multiday reservaion type is imoplemented
    const bookedDays = isMultiDay ?
      reservations.map(({ startDateTime, endDateTime }) => ({
        from: new Date(startDateTime),
        to: new Date(endDateTime)
      })) : []

    let closedDays = Object.keys(schedule).filter(day => {
      const { startTime, endTime } = schedule[day]
      return startTime === 0 && endTime === 0
    })
    closedDays = closedDays.map(day => dayIndex[day])

    return isDetails ? (
      <AmenityProDetailImageGallery
        existingPhotos={existingPhotos}
        pinnedPhotoUuid={pinnedPhotoUuid}
        readMode={readMode}
        onPhotoUpload={this.handlePhotos}
        onPhotoPinned={this.handlePhotoPin}
        onPhotoDelete={this.handlePhotoDelete}
        maxCount={10}
      />
    ) : (
      <AvailabilityCalendarDiv>
        {
          (isAmenityOpen && !isDayBlocked && !isMultiDay) &&
          <div>
            <AvailabilityAvailableHours>
              Available Hours:
            </AvailabilityAvailableHours>&nbsp;
            <span id="amenity-available-hours"> {availableHours} </span>
          </div>
        }
        {
          isMultiDay &&
          <div>
            <AvailabilityAvailableHours>
              Check-in time:
            </AvailabilityAvailableHours>&nbsp;
            <span id="amenity-available-hours">
              {formatMilitaryTime(currentSchedule.checkInTime)}
            </span>
            &#160;|&#160;
            <AvailabilityAvailableHours>
              Check-out time:
            </AvailabilityAvailableHours>&nbsp;
            <span id="amenity-available-hours">
              {formatMilitaryTime(currentSchedule.checkOutTime)}
            </span>
          </div>
        }
        <CalendarDiv>
          <Calendar
            date={getDateForPicker()}
            onDayClick={this.setAvailabilityDate}
            ranges={bookedDays}
            modifiers={{
              booked: bookedDays,
              closed: { daysOfWeek: closedDays },
              today: new Date()
            }}
            multidayEnabled={isMultiDay}
            previousDatesEnabled
            noSidebar
            width="100%"
          />
        </CalendarDiv>
        <AvailabilityTimeSlotsDiv>
          {
            (isAmenityOpen && !isDayBlocked) && availability ?
              <AmenityAvailabilitySchedule
                availability={isMultiDay ? item.upcomingAvailability : availability}
                onClickTimeSlot={this.handleSlotClick}
                reservationType={item.reservationType}
                rsvpRequired={rsvpRequired}
                extraAreaName={extraAreaName}
                selectedAvailabilityTimeSlot={selectedTimeSlot}
                extraAreaQuantity={extraAreaQuantity}
              />
              : (
                <AmenityClosedDiv id="amenity-closed">{
                  isDayBlocked
                    ? 'Note: This amenity is blocked all day. To change this please toggle to unblock the day.'
                    : 'Note: This amenity is closed all day. To change this please edit the Availability.'
                }

                </AmenityClosedDiv>
              )
          }
        </AvailabilityTimeSlotsDiv>
      </AvailabilityCalendarDiv>
    )
  }

  selectWaiverSignatureFrequency = (e: Object) => {
    this.updateItem({ waiverRequired: e.currentTarget.value })
    this.setState({ signatureFrequency: e.currentTarget.value })
  }

  handleWaivers = (uploadedFiles: Object) => {
    if (this.state.fileUploadError === '') {
      let waiverFile = uploadedFiles[0]
      this.updateItem({ waiverDocs: waiverFile, waiverRequired: this.state.signatureFrequency })
      this.setState({
        waivers: waiverFile,
        preventWaiverSubmission: false,
      })
    } else {
      this.setState({
        preventWaiverSubmission: true,
      })
    }
  }

  onFileDelete = () => {
    this.updateItem({ waiverDocs: [], waiverRequired: 'never' })
    this.setState({
      waivers: {},
      preventWaiverSubmission: true,
      fileUploadError: '',
    })
  }

  onFileUploadError = (error: string) => {
    this.setState({
      fileUploadError: error,
    })
  }

  closeWaiverAlertModal = () => {
    this.setState({
      activeModal: '',
      preventWaiverSubmission: true,
      fileUploadError: '',
      waivers: [],
      signatureFrequency: 'everyTime',
    })
  }

  saveWaiver = () => {
    this.updateItem({
      waiverDocs: [this.state.waivers],
      waiverRequired: this.state.signatureFrequency,
    })

    this.setState({
      activeModal: '',
      fileUploadError: '',
      preventWaiverSubmission: true,
      waivers: [],
    }, () => {
      //  necessary for the modal to be updated when not visible to user, otherwise the radio immediately selects everytime
      setTimeout(() => {
        this.setState({
          signatureFrequency: 'everyTime'
        })
      }, 500);
    })
  }

  getPropsForModalContainer = () => {
    return {
      onFileUploadError: this.onFileUploadError,
      onFileDelete: this.onFileDelete,
      handleWaivers: this.handleWaivers,
      onModalClose: this.onModalClose,
      onModalAccept: this.onModalAccept,
      setNewFacilityBlockTime: this.setNewFacilityBlockTime,
      selectWaiverSignatureFrequency: this.selectWaiverSignatureFrequency,
      saveAmenityIfChanged: this.saveAmenityIfChanged,
      publishAmenity: this.publishAmenity,
      amenityState: {
        selectedReservation: this.state.selectedReservation,
        newFacilityBlock: this.state.newFacilityBlock,
        activeModal: this.state.activeModal,
        waivers: this.state.waivers,
        fileSizeLimit: this.state.fileSizeLimit,
        fileUploadError: this.state.fileUploadError,
        signatureFrequency: this.state.signatureFrequency,
        preventWaiverSubmission: this.state.preventWaiverSubmission,
        publishedAmenityEditWarnings: this.state.publishedAmenityEditWarnings,
      },
      amenityProps: {
        availability: this.props.availability,
        currentWaiver: this.props.item.currentWaiver,
        item: this.props.item,
        residents: this.props.residents,
        editMode: this.props.editMode,
      }
    }
  }

  confirmAmenityDayBlocked = () => {
    const { createFacilityBlock, userName } = this.props
    const { item: { facilityUuid }, selectedAvailabilityDate } = this.state

    const today = new Date().toLocaleDateString()
    const now = new Date().toLocaleTimeString()
    const body = {
      startDate: selectedAvailabilityDate,
      endDate: selectedAvailabilityDate,
      startTime: 0,
      endTime: 2400,
      reason: `${userName} - Blocked at ${now} on ${today}`
    }
    createFacilityBlock(facilityUuid, body)
    this.setState({ isAmenityDayBlocked: true })
  }

  confirmAmenitySlotBlocked = () => {
    const { createFacilityBlock, userName } = this.props
    const {
      item: { facilityUuid },
      selectedAvailabilityDate: startDate,
      newFacilityBlock: { startTime, endTime }
    } = this.state

    // currently assuming that the start date/end date are the same
    const body = {
      startDate,
      endDate: startDate,
      startTime,
      endTime,
      reason: `${userName} - Blocked at ${new Date().toLocaleTimeString()} on ${new Date().toLocaleDateString()}`
    }
    createFacilityBlock(facilityUuid, body)
  }

  deleteWaiver = () => {
    const {
      item: { placeholder }
    } = this.state

    if (placeholder) {
      this.setState({ waivers: [], signatureFrequency: 'everyTime' })
      this.updateItem({ waiverDocs: [], waiverRequired: 'everyTime', currentWaiverUuid: '' })
      return
    }

    const { deleteWaiver: deleteWaiverFromProps } = this.props
    const { facilityUuid, currentWaiverUuid } = this.state.item

    if (currentWaiverUuid !== '') {
      deleteWaiverFromProps(currentWaiverUuid, facilityUuid)
      this.setState({ waivers: [], signatureFrequency: 'everyTime' })
      this.updateItem({ waiverDocs: [], waiverRequired: 'everyTime', currentWaiverUuid: '' })
    }

    if (!placeholder && currentWaiverUuid === '') {
      this.setState({ waivers: [], signatureFrequency: 'everyTime' })
      this.updateItem({ waiverDocs: [], waiverRequired: 'everyTime', currentWaiverUuid: '' })
    }
  }

  setNewFacilityBlockTime = ({ startTime, endTime }: Object) => {
    this.setState(prevState => ({
      ...prevState,
      newFacilityBlock: {
        startTime: startTime >= 0 ? startTime : prevState.newFacilityBlock.startTime,
        endTime: endTime >= 0 ? endTime : prevState.newFacilityBlock.endTime,
      }
    }))
  }

  render() {
    const {
      item,
      selectedAvailabilityDate,
      selectedAvailabilityTime,
      createReservationMode,
      newReservation,
      isAmenityDayBlocked,
      isAmenitySlotBlocked,
      selectedReservation,
    } = this.state

    const {
      hasData,
      history,
      isFetching,
      isUpdating,
      residents,
      match: {
        params: {
          propertyId, amenityId, tab
        } = {}
      } = {},
      readMode,
      editMode,
      availability,
      reservations,
    } = this.props

    const { reservationType } = item
    const isMultiDay = reservationType === 'multiDay'
    const isAmenityOpen = this.getIsAmenityOpen()

    let timeSlots = null
    if (availability) {
      timeSlots = availability.timeSlots
    }
    const selectedTimeSlot = reservationType !== 'fullFacility' ?
      getTimeSlotForTime(selectedAvailabilityTime, timeSlots) : null

    if (isFetching || isUpdating) {
      return <Loader/>
    }

    if (!hasData && !readMode) {
      return null
    }

    if (!propertyId.toString().match(/\d+/)) {
      history.push('/summary')

      return null
    }

    const url = `/properties/${propertyId}/amenities/${amenityId}`
    const tabs = getTabs(url, this.state.item?.published)
    const isDetails = tab === 'details'

    if (createReservationMode && isDetails) {
      this.setState({ createReservationMode: false, newReservation: emptyNewReservation })
    }

    const isDayReallyBlocked = () => {
      return timeSlots && timeSlots[0] && timeSlots[0].startTime === 0 &&
        timeSlots[0].endTime === 2400 && timeSlots[0].facilityBlockUuid
    }

    const handleDayBlockChecked = ({ target: { checked } }) => {
      const { item: { facilityUuid } } = this.state

      const {
        deleteFacilityBlock,
        getAvailability,
        fetchReservations
      } = this.props

      if (checked) {
        this.setActiveModal(modals.blockDay)
      } else if (timeSlots) {
        if (isDayReallyBlocked()) {
          const selectedDate = timeSlots[0].startDate
          deleteFacilityBlock(facilityUuid, timeSlots[0])
          fetchReservations(selectedDate)
          getAvailability(selectedDate)
          this.setState({ isAmenityDayBlocked: checked })
        }
      }
    }

    const handleSlotBlockToggle = ({ target: { checked } }) => {
      const { facilityUuid } = item

      const {
        deleteFacilityBlock,
      } = this.props

      if (checked) {
        this.setActiveModal(modals.blockSlot)
      } else {
        deleteFacilityBlock(facilityUuid, selectedTimeSlot)
      }
    }

    const setSelectedReservation = (reservation: Object) => {
      this.setState({ selectedReservation: reservation, item: { ...item, message: "" } })
    }

    const renderAvailabilityTabContent = () => {
      return React.createElement(AmenityAvailabilityForm, {
        item: this.state.item,
        editMode: this.props.editMode,
        isError: this.isError,
        handleSchedule: this.handleSchedule,
        handleScheduleDayClose: this.handleScheduleDayClose,
        handleCheckInAndOutSchedule: this.handleCheckInAndOutSchedule,
        handleChange: this.handleChange,
        updateItem: this.updateItem,
        registerFormField: this.registerFormField,
        defaultSettings: this.state.defaultSettings,
      })
    }

    const renderDetailsTabContent = () =>
      React.createElement(AmenityForm, {
        item,
        isError: this.isError,
        editMode: this.props.editMode,
        handleChange: this.handleChange,
        updateItem: this.updateItem,
        registerFormField: this.registerFormField,
        initiateDelete: this.initiateDelete,
        showWaiverModal: this.showWaiverModal,
        initiateWaiverDelete: this.initiateWaiverDelete,
      })

    const renderReservationTabContent = () => {
      if (createReservationMode) {
        return React.createElement(AmenityCreateReservation, {
          item: item,
          availability: isMultiDay ? item?.upcomingAvailability : availability,
          reservations,
          selectedAvailabilityDate: selectedAvailabilityDate,
          selectedTimeSlot,
          updateNewReservation: this.updateNewReservation,
          newReservation: newReservation,
        })
      }

      return React.createElement(AmenityReservationDetail, {
        item: item,
        isAmenityOpen: isAmenityOpen,
        residents: residents,
        reservations: this.props.reservations,
        availability: isMultiDay ? item?.upcomingAvailability : availability,
        selectedAvailabilityDate: selectedAvailabilityDate,
        selectedAvailabilityTimeSlot: selectedTimeSlot,
        setAvailabilityTime: this.setAvailabilityTime,
        isAmenityDayBlocked: isAmenityDayBlocked,
        setAmenityDayBlocked: handleDayBlockChecked,
        isAmenitySlotBlocked: isAmenitySlotBlocked,
        setAmenitySlotBlocked: handleSlotBlockToggle,
        selectedReservation: selectedReservation,
        setSelectedReservation: setSelectedReservation,
        handleResidentMessageChange: this.handleChange,
      })
    }

    const renderCurrentTabMainContent = () => {

      switch (tab) {
        case 'availability':
          return renderAvailabilityTabContent()
        case 'reservations':
          return renderReservationTabContent()
        default:
          return renderDetailsTabContent()
      }
    }

    const getButtonProps = () => {
      // configure CTAButtons prop values for details or availability tab
      const defaults = {
        toggleEditMode: this.toggleEditMode,
        goTo: this.initiateExit,
        isEditing: editMode,
        showAdd: false,
        onAdd: item.published ? this.showCreateReservation : null,
      }

      if (tab !== 'reservations') {
        return defaults
      }

      const canAddReservation = this.showCreateReservationButton() &&
        !createReservationMode && !selectedReservation
      const { resident } = newReservation

      let isEditing = false
      if (selectedReservation) {
        isEditing = true
      } else if (createReservationMode && resident) {

        const { isValid } = validateReservation(
          selectedTimeSlot,
          newReservation,
          availability,
          this.props.reservations,
          item
        )

        isEditing = isValid
      }


      // configure the buttons for when reservations tab is active
      return {
        ...defaults,
        isEditing,
        showEdit: false,
        showAdd: canAddReservation,
        showAddDisabled: !canAddReservation
      }
    }

    return (<>
      <AmenityProStyleContainer>
        <Global styles={globalStyles} />
        <AmenityProSidebarArea>
          <FlexItem>
            <InfoCard
              style={{ width: '352px' }}
              styleInfoHead={{ padding: '0' }}
              styleTitle={{
                display: 'block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                top: '-10px',
              }}
              paddingTop="0px"
              paddingBottom="20px"
              small
              noTextTransform
              infoHead=""
              title={item.name && (
                <Tooltip title={item.name} placement="bottom-start">
                  <span id="amenity-name-title">{item.name}</span>
                </Tooltip>
              )}
              content={() => this.getInfoCardContent(availability, selectedTimeSlot)}
            />
          </FlexItem>
        </AmenityProSidebarArea>
        <AmenityProNavArea>
          <TopNavigation tabs={tabs} onTabClick={this.handleTabClick} />
          <FlexItem>
            {!item.published ? (
              <React.Fragment>
                <DraftModeBannerContainer>
                  <DraftModeBanner>
                    <Flex>
                      <FlexItem paddingLeft="27px">
                        <DraftModeBannerLine>&nbsp;</DraftModeBannerLine>
                      </FlexItem>
                      <FlexItem paddingLeft="18px">
                        <DraftModeBannerLine>&nbsp;</DraftModeBannerLine>
                      </FlexItem>
                      <FlexItem fullWidth paddingTop="5px" paddingRight="70px">
                        <DraftModeBannerText id="draft-mode-banner-text">
                          Draft Mode
                        </DraftModeBannerText>
                      </FlexItem>
                    </Flex>
                  </DraftModeBanner>
                </DraftModeBannerContainer>
              </React.Fragment>
            ) : <></>
            }
          </FlexItem>
        </AmenityProNavArea>
        <AmenityProContentArea children={renderCurrentTabMainContent()}/>
        <AmenityProButtonsArea children={(<CTAButtons {...getButtonProps()} />)}/>
      </AmenityProStyleContainer>
      <AmenityModals {...this.getPropsForModalContainer()} />
    </>)
  }
}

export default withTheme(AmenityPro)
