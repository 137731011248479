// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import Tabs from '../Cards/Tabs'
import { Wrapper, SideMenu } from './styles'

const SummarySideNavigation = ({
  tabs,
  selectColor,
  handleChange,
  theme,
  imageSrc
}) => {
  return (
    <Wrapper imageSrc={imageSrc}>
      <SideMenu data-testid="summary-side-menu">
        <Tabs
          tabs={tabs}
          tabStyle={1}
          handleChange={handleChange}
          vertical
          selectTextColor={theme.palette.text.black1}
        />
      </SideMenu>
    </Wrapper>
  )
}

export default withTheme(SummarySideNavigation)
