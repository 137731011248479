// @flow

import get from 'lodash/get'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
// $FlowDisableNextLine
import { getUserId } from 'zego-shared/store/authorization/selectors'
// $FlowDisableNextLine
import { formatDateMMDDYY } from 'zego-shared/utils/date'
import { Reply } from './types'

const getComments = (replies: Array<Reply>): Object[] => {
  return replies.map(reply => ({
    body: reply.body,
    date: formatDateMMDDYY(reply.inserted_at),
    name: reply.user.name,
    userImage: reply.user.picture,
    key: reply.key
  }))
}

export const getReplies = (state: Object) => {
  return get(state, ['communityFeed', 'thread', 'replies'])
}

const getLikedAttendingStatus = (data: Object, state: Object) => {
  const key = data.type !== 'feed' ? 'rsvps' : 'likes_by'
  const status = data[key].filter(user => user.user_id === getUserId(state))
  return status.length > 0
}

export const getCommunityThreadData = (
  state: Object,
  action: string,
  threadId: string
) => {
  const data = get(state, ['communityFeed', 'thread'])

  const isEdit = action === 'edit'
  const isViewPage = !action

  return data
    ? {
        isLikedOrisAttending: getLikedAttendingStatus(data, state),
        comments: getComments(data.replies),
        eventDuration: `${moment
          .unix(data.event_datetime)
          .format('hh:mma')} - ${moment
          .unix(data.event_end_datetime)
          .format('hh:mma')}`,
        attendeesImage:
          data.rsvps && data.rsvps.length > 0
            ? data.rsvps[data.rsvps.length - 1].picture
            : null,
        likesOrAttending: data.type === 'feed' ? data.likes : data.rsvps.length,
        likesBy: data.type === 'event' ? data.rsvps : data.likes_by,
        createdAt: moment
          .unix(data.created_at)
          .format('MMMM D, YYYY [@] h:mma'),
        threadType: data.type === 'feed' ? 'posts' : 'events',
        userFullName: data.user
          ? `${data.user.first_name} ${data.user.last_name}`
          : null,
        userPicture: data.user ? data.user.picture : null,
        title: isEdit || isViewPage ? data.title || '' : '',
        image:
          (isEdit || isViewPage) && data.metadata && data.metadata.images.length
            ? { url: data.metadata.images[0] }
            : null,
        message: isEdit || isViewPage ? data.body || '' : '',
        threadId: isEdit || isViewPage ? threadId || '' : '',
        startTime:
          isEdit || isViewPage
            ? moment
                .unix(data.event_datetime)
                .format('HH:mm') || ''
            : '',
        endTime:
          isEdit || isViewPage
            ? moment
                .unix(data.event_end_datetime)
                .format('HH:mm') || ''
            : '',
        moment:
          isEdit || isViewPage
            ? moment.unix(data.event_datetime) || moment()
            : moment()
      }
    : null
}

export const hasData = (state: Object) => {
  const data = get(state, ['communityFeed', 'thread'])
  return data && !isEmpty(data) ? true : false
}
