import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import {
  getTimeWithAMPM,
  isSameDay,
  getChatDateSeperator
} from '../../utils/dateUtils'
import { getProfileUrlOrDefault } from 'zego-shared/utils/profilePicture'
import { getSendbirdUserId } from 'zego-shared/store/authorization/selectors'

const sendbirdUserToUser = user => {
  return {
    name: user.nickname,
    id: user.userId
  }
}

const getType = (messageType = '', customType) => {
  if (messageType.toLowerCase() === 'admin') {
    return 'in-app notification'
  }
  return customType
}

const messageToMessageData = (message, sendbirdUserID, lastMessage) => {
  return {
    name: message._sender.nickname,
    image: getProfileUrlOrDefault(
      message._sender.profileUrl,
      message._sender.nickname
    ),
    type: getType(message.messageType, message.customType),
    date:
      lastMessage && !isSameDay(message.createdAt, lastMessage.createdAt)
        ? getChatDateSeperator(message.createdAt)
        : null,
    time: getTimeWithAMPM(message.createdAt),
    message: message.message,
    senderIsCurrentUser: message._sender.userId === sendbirdUserID,
    lastSenderIsCurrentSender: lastMessage
      ? message._sender.userId === lastMessage._sender.userId
      : false
  }
}

const getMessages = state => {
  return get(state, ['chat', 'messages'], [])
}

export const getUsersFromMessages = state => {
  const messages = get(state, ['chat', 'messages'], [])

  return uniqBy(messages, message => {
    if (message.messageType === 'admin' && message.data.length) {
      const user = JSON.parse(message.data)
      message['_sender'] = { ...user.sender, userId: user.sender.user_id }
    }

    return message._sender.userId
  }).map(message => sendbirdUserToUser(message._sender))
}

export const getMessageData = state => {
  const messages = getMessages(state)
  const sendbirdUserID = getSendbirdUserId(state)

  return messages.map((message, index, array) => {
    const previousMessage = index !== 0 ? array[index - 1] : null
    return messageToMessageData(message, sendbirdUserID, previousMessage)
  })
}

export const hasData = state => {
  const channels = get(state, ['chat', 'byType'], null)
  return channels !== null
}
