import { connect } from 'react-redux'
import {
  addHub,
  connectDevice,
  runTest,
  submitName,
  clearConnectedDevice
} from 'zego-shared/store/addDevice/connected/actions'
import {
  fetchDeviceProfiles,
  selectDeviceProfile
} from 'zego-shared/store/addDevice/deviceProfiles/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {
  getDeviceProfileData,
  getDeviceProfileType,
  getDeviceSetupInstructions,
  getDeviceTestInstructionsBefore,
  getDeviceTestInstructionsAfter,
  hasConnectedDevice,
  getConnectedDevice,
  getConnectedDeviceTestPayload,
  getConnectedDeviceTestWaitTimeout
} from 'zego-shared/store/addDevice/connected/selectors'
import { getSelectedDeviceProfileID } from 'zego-shared/store/addDevice/deviceProfiles/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { fetchDevices } from 'zego-shared/store/devices/actions'
import {
  getHubIdentifier,
  getNonHubDevicesName
} from 'zego-shared/store/devices/selectors'

import Setup from './Setup'

const fetchingSelector = createIsFetchingSelector(['deviceprofile', 'devices'])
const SetupWithFetchData = withFetchData(Setup)

const mapStateToProps = (state, { selectedDeviceProfileID }) => ({
  isFetching: fetchingSelector(state),
  deviceProfiles: getDeviceProfileData(state),
  setupInstruction: getDeviceSetupInstructions(state, selectedDeviceProfileID),
  beforeTestInstruction: getDeviceTestInstructionsBefore(
    state,
    selectedDeviceProfileID
  ),
  afterTestInstruction: getDeviceTestInstructionsAfter(
    state,
    selectedDeviceProfileID
  ),
  hasConnectedDevice: hasConnectedDevice(state),
  connectedDevice: getConnectedDevice(state),
  testPayload: getConnectedDeviceTestPayload(state, selectedDeviceProfileID),
  testWaitTimeout: getConnectedDeviceTestWaitTimeout(state, selectedDeviceProfileID),
  hubIdentifier: getHubIdentifier(state),
  deviceProfileType: getDeviceProfileType(state, selectedDeviceProfileID),
  selectedDeviceProfileID: getSelectedDeviceProfileID(state),
  devices: getNonHubDevicesName(state)
})

const mapDispatchToProps = dispatch => ({
  addHub: hubIdentifier => dispatch(addHub(hubIdentifier)),
  connectDevice: () => dispatch(connectDevice()),
  runTest: selectedDevice => dispatch(runTest(selectedDevice)),
  submitName: deviceName => dispatch(submitName(deviceName)),
  clearConnectedDevice: () => dispatch(clearConnectedDevice()),
  selectDeviceProfile: profileId => dispatch(selectDeviceProfile(profileId)),
  fetchData: () => {
    dispatch(fetchDeviceProfiles())
    dispatch(fetchDevices())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupWithFetchData)
