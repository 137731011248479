import styled from '@emotion/styled'
import FormControl from '@material-ui/core/FormControl'
import isPropValid from '@emotion/is-prop-valid'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export const CustomFormControl = styled(FormControl, {
  shouldForwardProp: isPropValid
})`
  width: ${({ width }) => (width ? width : '100%')};
  .radio-button-group {
    display: flex;
    flex-direction: column;
  }

  .radio-button-control-label {
    flex: 1;
  }

  ${({ textSize }) =>
    textSize &&
    ` * { 
        font-size: ${textSize}
    }
    `};
`

export const CustomFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: prop => prop !== 'lastRow'
})`
  border-bottom: ${({ theme, lastRow }) =>
    lastRow ? 'none' : `1px solid ${theme.palette.neutrals.deepGray}`};
  padding: 0.8em 0em;
`
