import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import MaterialButton from '@material-ui/core/Button'

export const Button = styled(MaterialButton, {
  shouldForwardProp: prop =>
    [
      'secondary',
      'secondaryLight',
      'left',
      'elong',
      'long',
      'animate',
      'thin',
      'outline'
    ].indexOf(prop) === -1
})`
  margin: 7px 0;
  padding: 3px 15px;
  min-width: 90px;
  min-height: 36px;
  border-radius: 0;
  background-color: ${({ theme }) => theme.palette.common.white};
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.custom.font.size.md};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  ${({ left }) => left && `justify-content:left`};
  ${({ elong }) => elong && `padding: 5px 45px;`};
  ${({ long }) => long && `width: 180px;`};
  ${({ thin }) => thin && `min-height: 25px;`};
  ${({ primary, theme }) =>
    primary &&
    `background-color: ${theme.palette.primary.dark};
      color: ${theme.palette.common.white};
    &:hover {
      background-color: ${theme.palette.primary.green.mint};
      color: ${theme.palette.primary.navy.midnight};
    }`};

  ${({ secondaryLight, theme }) =>
    secondaryLight &&
    `background-color: ${theme.palette.secondary.light1};
    color: ${theme.palette.common.white};
    &:hover {
      background-color: ${theme.palette.secondary.light1};
    }`};

  ${({ outline, primary, theme }) =>
    outline &&
    primary &&
    ` background-color:transparent;
      color: ${theme.palette.primary.main};
      border: 3px solid ${theme.palette.primary.main};
      font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
      &:hover {
        background-color: transparent;
        border: 3px solid ${theme.palette.secondary.main};
        color: ${theme.palette.primary.main};
      }`};

  ${({ animate }) =>
    animate &&
    `&:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      animation: ${ellipsis} steps(4, end) 1200ms infinite;
      content: '\\2026';
      width: 0;
    }
    `};

  .login-label {
    width: auto;
  }
`

const ellipsis = keyframes`
  to {
    width: 1.25em;
  }
`

export const FabButton = styled(MaterialButton, {
  shouldForwardProp: prop => !['component', 'size', 'zIndex'].includes(prop)
})`
  position: ${({ position }) => (position ? position : 'relative')};
  color: ${({ theme }) => theme.palette.common.white};
  min-width: ${({ theme, size }) => (size ? size : '3em')};
  width: ${({ theme, size }) => (size ? size : '3em')};
  min-height: ${({ theme, size }) => (size ? size : '3em')};
  height: ${({ theme, size }) => (size ? size : '3em')};
  border-radius: 50%;
  box-shadow: none;
  border: ${({ theme, outlined }) =>
    outlined && `3px solid ${theme.palette.secondary.yellow.solarFlare}`};

  background: ${({ theme, background }) =>
    background ? background : theme.palette.primary.dark};

  &:hover {
    background: ${({ theme, background }) =>
      background ? background : theme.palette.primary.dark};
    border: ${({ theme, outlined }) =>
      outlined && `3px solid ${theme.palette.secondary.yellow.golden}`};
  }

  ${({ zIndex }) => zIndex && `z-index:${zIndex}`};
  ${({ left }) => left && `left:${left}`};
  ${({ right }) => right && `right:${right}`};
  ${({ top }) => top && `top:${top}`};
  ${({ bottom }) => bottom && `bottom:${bottom}`};

  & > span {
    width: auto;
  }
`
export const CommonIconText = styled('span')`
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.grey.main : theme.palette.common.black};
  font-size: ${({ theme }) => theme.custom.font.size.md};
`
