// @flow
import React, { Fragment } from 'react'
import Flex, { FlexItem } from '@Common/Flex'
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  HubLink
} from './styles'
import HubCommandsContainer from './HubCommandsContainer'
import type { Hub as HubType } from './types'

type HubPropertyProps = {
  value: ?string,
  name: string,
  field: string,
  selected: boolean,
  borderTop: boolean,
  onSelect: string => mixed
}

type HubPropertiesProps = {
  hub: HubType,
  selectedHubProperty: string,
  onSelect: string => mixed
}

const HubProperty = ({
  value,
  name,
  field,
  borderTop,
  selected,
  onSelect
}: HubPropertyProps) => (
  <ListItem
    borderTop={borderTop}
    selected={selected}
    extraPadding
    onClick={() => {
      onSelect(field)
    }}>
    <ListItemText
      disableTypography
      primary={
        <Flex justifyStart>
          <FlexItem flex={1}>{name}</FlexItem>
          <Flex flex={3}>
            {value ? (
              <font color="#b0b6bd">{value}</font>
            ) : (
              <HubCommandsContainer
                width={'35px'}
                height={'25px'}
                isHubProperties
              />
            )}
          </Flex>
        </Flex>
      }
    />
    <ListItemSecondaryAction>
      {selected ? (
        'More Info'
      ) : (
        <HubLink to={`/smart/hub/${field}`}>More Info</HubLink>
      )}
    </ListItemSecondaryAction>
  </ListItem>
)

const HubProperties = ({
  hub,
  selectedHubProperty,
  onSelect
}: HubPropertiesProps) => (
  <Fragment>
    {Object.entries(hub).map(
      ([key, { name, text, value }]: [string, any], index) => (
        <HubProperty
          borderTop={index !== 0}
          onSelect={onSelect}
          selected={key === selectedHubProperty}
          key={key}
          hub={hub}
          field={key}
          name={name}
          value={value}
        />
      )
    )}
  </Fragment>
)

export default HubProperties
