// @flow

import React from 'react'
import Typography from '@material-ui/core/Typography'
import Flex from '@Common/Flex'
import Switch from '@material-ui/core/Switch'
import type { ScheduleEnableProps } from './types'

const ScheduleEnable = ({
  switchScheduleActive,
  enabled,
  scheduleState,
  readOnly
}: ScheduleEnableProps) => (
  <Flex justifySpaceBetween alignCenter fullWidth>
    <Flex direction="column">
      <font color="black" size="4">
        Schedule {enabled ? 'On' : 'Off'}
      </font>
      <Typography variant="caption">
        currently in {scheduleState} mode
      </Typography>
    </Flex>
    {readOnly && <div>Read Only</div>}
    {!readOnly &&
    <Switch
      disabled={readOnly}
      onChange={switchScheduleActive}
      checked={enabled}
    />
  }
  </Flex>
)

export default ScheduleEnable
