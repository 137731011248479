import compose from 'lodash/flowRight'
import { get } from 'lodash'
import { combineReducers } from 'redux'
import {
  FETCH_PACKAGES,
  SEARCH_PACKAGES,
  updatePackageStatusOptimistically,
  UPDATE_PACKAGE_STATUS_ERROR,
  UPDATE_PACKAGE_STATUS_SUCCESS,
  fetchPackagesSuccess,
  searchPackagesSuccess,
} from '../actions'
import withNormalizedFetch from '../../helpers/reducers/withNormalizedFetch'
import withNormalizedRefresh from '../../helpers/reducers/withNormalizedRefresh'
import withPaginatedFetch from '../../helpers/reducers/withPaginatedFetch'

const initialState = {}

const propertyPackages = (state = initialState, { type, payload }) => {
  const packageId = get(payload, 'packageId', undefined)
  const status = get(payload, 'status', undefined)
  switch (type) {
    case updatePackageStatusOptimistically().type:
      return {
        ...state,
        byId: {
          ...state.byId,
          [packageId]: { ...state.byId[packageId], status }
        },
        previous: {
          byId: state.byId
        }
      }
    case UPDATE_PACKAGE_STATUS_ERROR:
      return {
        ...state,
        byId: state.previous.byId,
      }
    case UPDATE_PACKAGE_STATUS_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [packageId]: { ...state.byId[packageId], status }
        },
        previous: {
          byId: state.byId
        }
      }
    case fetchPackagesSuccess().type:
      return {
        ...state,
        headers: normalizeHeader(action.payload.headers)
      }
    default:
      return state
  }
}

const headers = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const removeDataFromHeaderData = reducer => (state = {}, action) => {
  switch (action.type) {
    case fetchPackagesSuccess().type:
    case searchPackagesSuccess().type: {
      const { data, ...rest } = reducer(state, action)
      return rest
    }
    default:
      return state
  }
}

const withFetchAndRefresh = compose(
  withNormalizedFetch(FETCH_PACKAGES),
  withNormalizedRefresh(FETCH_PACKAGES),
  withNormalizedFetch(SEARCH_PACKAGES),
  withNormalizedRefresh(SEARCH_PACKAGES)
)

const headerReducers = compose(
  withPaginatedFetch(FETCH_PACKAGES),
  withPaginatedFetch(SEARCH_PACKAGES)
)

const addData = reducer => (state = {}, { payload, type }) => {
  const data = payload ? payload.data : []
  return reducer(state, { type, payload: data })
}

export default combineReducers({
  headers: removeDataFromHeaderData(headerReducers(headers)),
  data: addData(withFetchAndRefresh(propertyPackages))
})
