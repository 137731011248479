// @flow

import { get } from "lodash";

export const getCardData = (state: Object) => {
  const card = get(state, ['marketplace', 'selected'], {})
  const data = card.data || {};

  const marketplaceDeal = {
    image: data.image,
    description: data.description,
    discount: data.discount_description,
    url: data.redirect_url,
    companyName: data.title,
    category: data.category
  }
  
  return marketplaceDeal
}

export const getMarketplaceDeals = (state : Object) => {
  const deals = get(state, ['marketplace', 'list', 'data'], [])
  const nextPage = get(state, ['marketplace', 'list', 'nextPage'], 1)
  const totalPages = get(state, ['marketplace', 'list', 'totalPages'], 0)

  return {
    global: deals.filter(s => s.global),
    property: deals.filter(s => !s.global),
    nextPage,
    totalPages
  }
}
