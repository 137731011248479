import React from 'react'
import { Route } from 'react-router-dom'
import DevicesList from '../../views/Smart/SmartUnit/DevicesList'
import HistoryContainer from '../../views/Smart/SmartUnit/History'
import HubTabContainer from '../../views/Smart/SmartUnit/HubTab/HubTabContainer'
import ScheduleContainer from '../../views/Smart/SmartUnit/Schedule'
// import UnitSetup from '../../views/Smart/UnitSetup'

const SmartTabRoutes = () => (
  <React.Fragment>
    {/* <Route
      exact
      path="/smart/:propertyId/setup"
      component={UnitSetup}
    /> */}
    <Route
      exact
      path="/properties/:propertyId/units/:unitId/smart"
      component={DevicesList}
    />
    <Route
      path="/properties/:propertyId/units/:unitId/smart/devices/:deviceID?"
      component={DevicesList}
    />
    <Route
      path="/properties/:propertyId/units/:unitId/smart/hub/:hubProperty?"
      component={HubTabContainer}
    />
    <Route
      exact
      path="/properties/:propertyId/units/:unitId/smart/history"
      component={HistoryContainer}
    />
    <Route
      exact
      path="/properties/:propertyId/units/:unitId/smart/schedule"
      component={ScheduleContainer}
    />
  </React.Fragment>
)

export default SmartTabRoutes
