import { connect } from 'react-redux'
import ChatNotification from './ChatNotification'
import {
  getChatNotificationData,
  setChatNotificationData
} from '../../../store/Chat/Notification'
import {
  initSendbirdChannelHandler,
  closeSendbirdChannelHandler
} from 'zego-shared/store/chat/messages/actions'
import withFetchData from 'zego-shared/views/withFetchData'
import { fetchChatChannelsByType } from 'zego-shared/store/chat/channels/actions'

const mapStateToProps = state => {
  return {
    ...getChatNotificationData(state)
  }
}

const ChatNotificationWithFetchData = withFetchData(ChatNotification)

const mapDispatchToProps = dispatch => ({
  clearNotificationData: () => dispatch(setChatNotificationData({})),
  fetchData: () => {
    dispatch(initSendbirdChannelHandler())
    dispatch(fetchChatChannelsByType('live-chat', null, false))
  },
  componentWillUnmount: () => {
    dispatch(closeSendbirdChannelHandler())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatNotificationWithFetchData)
