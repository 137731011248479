// @flow

export const SUPER_ADMIN = 'super_admin'
export const SUPER_ADMIN_DISPLAY_NAME = 'Super Admin'

export const COMPANY_ADMIN = 'company_admin'
export const COMPANY_ADMIN_DISPLAY_NAME = 'Company Admin'

export const PROPERTY_ADMIN = 'property_admin'
export const PROPERTY_ADMIN_DISPLAY_NAME = 'Property Admin'

export const PROPERTY_STAFF = 'property_staff'
export const PROPERTY_STAFF_DISPLAY_NAME = 'Property Staff'

export const RESIDENT = 'resident'
export const RESIDENT_DISPLAY_NAME = 'Resident'

export const TEMPORARY = 'temporary'
export const TEMPORARY_DISPLAY_NAME = 'Temporary'

export const SETUP = 'setup'
export const SETUP_DISPLAY_NAME = 'Setup'
