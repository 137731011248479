import { connect } from 'react-redux'
import {
  fetchVehicle,
  fetchVehiclesColors,
  fetchVehiclesStates,
  fetchVehiclesTypes,
  fetchVehiclesMakes,
  updateVehicle
} from 'zego-shared/store/registration/vehicles'
import { fetchPropertyUsersSummary } from 'zego-shared/store/users/propertyUsersSummary/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import {
  hasVehiclesSupportedData,
  hasVehicle,
  getVehicle,
  getVehicleYears,
  getVehiclesColors,
  getVehiclesStates,
  getVehiclesTypes,
  getVehicleMakesForCreate
} from 'zego-shared/store/registration/vehicles/selectors'
import { getAllPropertyResidents } from 'zego-shared/store/users/selectors'

import Vehicle from './Vehicle'

const fetchingSelector = createIsFetchingSelector([
  'vehicle',
  'vehiclecolors',
  'vehiclestates',
  'vehicletypes'
])


const mapStateToProps = (state, { location: { search }, match: { url } }) => {
  const addMode = url.indexOf('/new') > -1
  const defaultItem = {
    state: 'AK',
    year: new Date().getFullYear(),
    color: 'yellow',
    type: 'other',
    make: ''
  }

  return {
    item: addMode ? defaultItem : getVehicle(state),
    isFetching: fetchingSelector(state),
    hasData: addMode
      ? hasVehiclesSupportedData(state)
      : hasVehicle(state) && hasVehiclesSupportedData(state),
    residents: getAllPropertyResidents(state),
    addMode,
    vehicleYears: getVehicleYears(state),
    vehicleTypes: getVehiclesTypes(state),
    vehicleColors: getVehiclesColors(state),
    vehicleStates: getVehiclesStates(state),
    vehiclesMakes: getVehicleMakesForCreate(state)
  }
}

const mapDispatchToProps = (dispatch, { history, match: { url, params } }) => {
  return {
    fetchData: () => {
      params.vehicleId !== 'new' && dispatch(fetchVehicle(params.vehicleId))
      dispatch(fetchPropertyUsersSummary(params.propertyId))
      dispatch(fetchVehiclesColors(params.propertyId))
      dispatch(fetchVehiclesStates(params.propertyId))
      dispatch(fetchVehiclesTypes(params.propertyId))
      dispatch(fetchVehiclesMakes(params.propertyId))
    },
    updateVehicle: update => {
      dispatch(updateVehicle(update, history, url))
    }
  }
}

const VehicleWithFetchData = withFetchData(Vehicle)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleWithFetchData)
