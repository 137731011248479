// @flow
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import AddIcon from '@material-ui/icons/Add'
import { GridItem } from '../../Common/Grid'
import TopNavigation from '../../Common/TopNavigation'
import UnitInfoCard from './UnitInfoCard'
import { SmartTabRoutes } from '../../../routes/Smart'
import type { Props, TabsType } from './types'
import UnitSetup from './UnitSetup'
import { FabButton } from './styles'
import Loader from '../../Common/Loader'

const getTabs = (
  hasZegoSmart: boolean,
  isFetching: boolean,
  url: string
): TabsType => {
  return hasZegoSmart && !isFetching
    ? [
        {
          name: 'Devices',
          route: `${url}/devices`
        },
        {
          name: 'Hub',
          route: `${url}/hub`
        },
        {
          name: 'History',
          route: `${url}/history`
        },
        {
          name: 'Schedule',
          route: `${url}/schedule`
        }
      ]
    : []
}

const Smart = ({
  unitCardInfo,
  isFetching,
  hasZegoSmart,
  theme,
  match: { url, params }
}: Props) => {
  const tabs = getTabs(hasZegoSmart, isFetching, url)
  return hasZegoSmart === null ? null : isFetching ? (
    <Loader />
  ) : hasZegoSmart ? (
    <Fragment>
      <GridItem columnStart={5} columnSpan={9} rowStart={1} rowSpan={1}>
        <TopNavigation
          tabs={tabs}
          backArrowUrl={`/properties/${params.propertyId}/smart`}
        />
      </GridItem>
      <UnitInfoCard data={unitCardInfo} />
      <SmartTabRoutes />
      <Link
        to={`/properties/${params.propertyId}/units/${
          params.unitId
        }/smart/setup`}>
        <FabButton variant="fab" color="secondary">
          <AddIcon style={{ color: theme.palette.common.white }}/> 
        </FabButton>
      </Link>
    </Fragment>
  ) : (
    hasZegoSmart === false && (
      <Fragment>
        <UnitInfoCard data={unitCardInfo} />
        <UnitSetup propertyId={params.propertyId} unitId={params.unitId} />
      </Fragment>
    )
  )
}

export default withTheme(Smart)
