import { css } from 'emotion'
import styled from '@emotion/styled'
import TableCell from '@material-ui/core/TableCell'
import Switch from '@material-ui/core/Switch'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import Header from '@Common/Header'
import Flex from '@Common/Flex'

const PADDING_RIGHT = '35px'

export const WorkOrderSpan = {
  paddingTop: '10px',
  marginLeft: '-40%'
}

export const NoActionStyledBlank = {
  width: '70px'
}

export const WorkOrderNumberStyle = {
  width: '50px',
  textAlign: 'center'
}

export const MimoChecklistSummaryStyled = styled(Flex)`
  width: 100%;
  flex-direction: column;
  overflow: hidden;
`

export const ApprovalButtonStyles = {
  minWidth: '190px'
}

export const HeaderStyled = styled(Header)`
  color: ${({ theme }) => theme.palette.text.black};
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  width: 32%;
`
export const UnApprovedHeaderStyled = styled(Header)`
  color: ${({ theme }) => theme.palette.text.black};
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  width: 32%;
  margin-bottom: 41px;
`
export const HeaderWrapperStyled = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

`
export const SummaryTablesStyled = styled(Flex)`
  height: 400px;
  overflow: auto;
  flex-direction: column;
  padding-right: ${PADDING_RIGHT};
`

export const FooterStyled = styled(Flex)`
  flex-direction: column;
  width: 100%;
  margin: 100px 0 50px;
  align-items: flex-end;
  align-self: flex-end;

  & button {
    width: 206px;
    margin-right: 10px;
    padding-left: 11px;
    padding-right: 6px;
    margin-top: -59px;
  }
`
export const LeftFooterStyled = styled(Flex)`
  margin-top : 5px;
  align-items: flex-start;
  flex-direction: column;
  width: 35%;
  margin-left: 2px;
  align-self: flex-start;
`
export const RightFooterStyled = styled(Flex)`
margin-top : 5px;
flex-direction: column;
width: 35%;
margin-left: 2px;
margin-right: 30px;
align-self: flex-start;
`
export const SignatureFooter = styled(Flex)`
  flex-direction: row;
  padding-top: 10px;
  justify-content: space-between;
  margin-bottom: 50px;
`
export const ApproverDisplay = styled(Flex)`
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-right: 25px;
  align-self: flex-end;
`
export const DateWithTime = styled(TableCell)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  border : none;
  text-align : left;
  margin-top: -8px;
 `
export const ApproverName = styled(TableCell)`
  border: none;
  text-align: left;
`
export const PMSignatureText = styled(TableCell)`
  border: none;
  text-align: left;
`
export const DateLabels = styled(TableCell)`
  margin-top: -22px;
  margin-bottom: 10px;
  border: none;
  text-align: left;
  padding-left: 0px;
`
export const SubmittedDaysAgo = styled(TableCell)`
  flex: auto;
  margin-top: 76px;
  border: none;
  text-align: left;
  margin-left: -260px;
`
export const ResidentSignatureImageStyle = styled.img`
  width: 100%;
  max-height: 170px;
`
export const StyledDaysAgo = styled('span')`
  font-weight: bold;
  color: red;
`
export const ResidentFullName = styled(TableCell)`
  border: none;
  margin-bottom: 42px;
`
export const ChecklistSubmittedDateWithTime = styled(TableCell)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  border : none;
  margin-top:-50px;
  margin-left:1px;
`
export const ResidentSignature = styled(TableCell)`
  border: none;
  margin-left:1px;
`
export const WorkOrderNeededStyled = styled.p`
  margin-right: 54px;
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  background-color: ${({ theme }) => theme.palette.primary.green.iceberg};
  padding: 6px;
  margin-bottom: -51px;
`

export const TableStyled = styled(Table)`
  margin-bottom: 20px;
`

export const TableRowStyled = styled(TableRow)`
  height: 35px;
  & td {
    border: 0;
  }
  &:nth-child(even) {
    background-color: ${({ theme }) => theme.palette.neutrals.lightGray};
  }
`

const TableHeaderStyled = styled(TableCell)`
  padding-left: 5px !important;
  padding-right: 5px !important;
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  width: 120px;
`

export const TableSectionNameStyled = styled(TableHeaderStyled)`
  text-align: left;
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
`

export const TableActionStyled = styled(TableHeaderStyled)`
  text-align: right;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`

export const ChecklistItemName = styled(TableCell)`
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  color: ${({ theme }) => theme.palette.text.grey};
  width: ${({ hasData }) => (hasData ? `180px` : '100px')};
  padding: 0;
  padding: 0 20px 0 10px;
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  vertical-align: top;
`

export const ChecklistItemDescription = styled(TableCell)`
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  color: ${({ theme }) => theme.palette.text.grey};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
`
export const SwitchStyled = styled(Switch)`
  height: 30px;
  & .bar {
    border-radius: 30px;
    margin-top: -10px;
    height: 20px;
    width: 38px;
    margin-left: -19px;
    background-color: ${({ theme }) => theme.palette.neutrals.warmGray};
    opacity: 1;
  }

  & .base {
    width: 50px;
    height: 100%;
  }

  & .checked {
    transform: translateX(20px);

    & .icon {
      background-color: ${({ theme }) => theme.palette.primary.green.mint};
    }
  }

  & .checked + .bar {
    background-color: ${({ theme }) => theme.palette.primary.green.seeFoam};
  }
`
export const ActionTableCellStyleImage = styled(TableCell)`
  width: ${({ hasData }) => (hasData ? '300px' : '100px')};

  & > div {
    justify-content: space-around;
  }
`
export const ActionTableCellStyle = styled(TableCell)`
  width: ${({ hasData }) => (hasData ? '200px' : '100px')};
  vertical-align: top;

  & > div {
    justify-content: space-around;
  }
`
export const RatingCommentsStyle = styled(TableCell)`
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  color: ${({ theme }) => theme.palette.text.grey};
  width: ${({ hasData }) => (hasData ? `55px` : '20px')};
  font-weight: bold;
  float: left;
`
export const CommentsStyle = styled(TableCell)`
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  color: ${({ theme }) => theme.palette.text.grey};
  width: ${({ hasData }) => (hasData ? `250px` : '100px')};
  float: left;
  text-align: left;
`
export const ImageHolder = styled.p`
  margin-right: 5px;
  margin-top: 5px;
  height: 35px;
`
export const SignatureImageHolder = styled.p`
  margin-right: 5px;
  margin-top: 5px;
  height: 150px;
`
export const PipeStyle = styled('div')`
 width: 5px;
 float: left;
`
export const ImageStyle = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  float: right;
`
export const TotalStyled = styled.p`
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  margin: 0 10px;
  margin-top: -102px;
`
export const NoActionStyled = styled(Flex)`
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
`
export const NoActionStyledInner = styled(Flex)`
  width: 70px;
`
export const AlertModalClass = css`
  display: flex;
  justify-content: center;
  width: 840px !important;
  text-align: center;
  overflow-y: revert !important ;

  & p {
    margin-bottom: 20px;
    width: 95%;
  }
  & h2 {
    width: 85%;
    padding-left: 10%
  }
`
export const Link = styled('a')`
  font-size: 12px;
  text-decoration-line: underline;
  font-weight: 500;
  margin-left: 20px;
  width: 50px;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
`
export const WorkOrderText = styled.span`
  font-size: 17px;
  padding-left: 5px;
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
`
export const DownloadButton = styled('img')`
  width: 3em;
  height: 3em;
  box-sizing: none
  box-shadow: none;
  border-radius: 50%;
  border: 2px solid #FDB627;
  margin-right: 0px;
  padding: 5px;
  cursor : pointer;
`
