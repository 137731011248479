import compose from 'lodash/flowRight'
import {
  SECTION,
  FETCH_SEARCH_HISTORY_SECTION,
  ADD_SEARCH_HISTORY_SECTION
} from '../actions'
import withFetch from '../../helpers/reducers/withFetch'

const initialState = {}

const search = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SEARCH_HISTORY_SECTION:
      return state
    default:
      return state
  }
}

export default compose(
  withFetch(SECTION, 'searchResult'),
  withFetch(FETCH_SEARCH_HISTORY_SECTION, 'history')
)(search)
