// @flow
import React, { Component } from 'react'
import { withTheme } from 'emotion-theming'
import type { Node } from 'react'
import { withRouter } from 'react-router-dom'
import type { Match } from 'react-router-dom'
import BaseCard from '@Common/Cards/BaseCard/WideBaseCard'
import SummaryHeader from './SummaryCardHeader'
import TopNavigation from '../../Common/TopNavigation'
import FlexDirection from '@Common/Flex/FlexDirection'
import type { Theme } from '../../../theme'
import { ContentWrap, FilteredData } from './styles'

type Props = {
  captionHeading?: string,
  unitName: string,
  chatData: { unread: number, total: number },
  batteryData: { low: number, total: number },
  content: () => Node,
  rightContent: Node,
  match: Match,
  theme: Theme,
  filters: Array<Object>,
  progressContent: () => {},
  occupancyData: {
    totalCount: number,
    occupiedCount: number,
    vacantCount: number,
    progressText: string,
    value: number
  }
}

class SummaryCard extends Component<Props, {}> {
  getLeftContent = () => {
    const { filters, content } = this.props

    return (
      <ContentWrap>
        <FilteredData>
          <TopNavigation selected={0} tabs={filters} rightLinks={[]} />
          <br />
          <FlexDirection direction="column" fullHeight>
            {content()}
          </FlexDirection>
        </FilteredData>
      </ContentWrap>
    )
  }

  render() {
    const { captionHeading, unitName, rightContent } = this.props

    return (
      <BaseCard
        header={() => (
          <SummaryHeader
            captionHeading={captionHeading || 'SUMMARY'}
            mainHeading={unitName}
          />
        )}
        showShadow={false}
        type="summary"
        toggleFooterOnMouseOver
        imageSrc={null}
        columnStart={1}
        columnSpan={8}
        rowStart={1}
        rowSpan={8}
        leftContent={this.getLeftContent}
        rightContent={rightContent}
        headerRight={null}
        footer={null}
        isSummary
      />
    )
  }
}

export default withRouter(withTheme(SummaryCard))
