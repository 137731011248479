// @flow
import React from 'react'
import { css } from 'emotion'
import { withTheme } from 'emotion-theming'
import Typography from '@material-ui/core/Typography'
import { CommonIcon } from '@icons'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import { Temperature } from './styles'

const CurrentTemperature = ({ currentTemp, theme }: any) => (
  <FlexItem flex={3}>
    <FlexDirection
      className={css`
        & > * {
          margin-right: 1em;
          font-size: ${theme.custom.font.size.mdlg};
        }
      `}>
      <div>
        <Temperature>{currentTemp}</Temperature>
      </div>
      <div>
        <CommonIcon name="bullet" width="6px" height="6px" />
      </div>
      <div>
        <Typography>Current Temp</Typography>
      </div>
    </FlexDirection>
  </FlexItem>
)

export default withTheme(CurrentTemperature)
