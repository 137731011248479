import fromPairs from 'lodash/fromPairs'
import { createFetchActions } from '../../../helpers/actions/creators'
import { getUserSettingValue } from '../utils'

export const USER_SETTINGS_FETCH_SECTION = 'USERSETTINGS'
export const UPDATE_USER_SETTING = 'UPDATE_USER_SETTING'
export const UPDATE_USER_SETTING_OPTIMISTIC = 'UPDATE_USER_SETTING_OPTIMISTIC'
export const UPDATE_USER_SETTING_ERROR = 'UPDATE_USER_SETTING_ERROR'
export const UPDATE_USER_SETTING_SUCCESS = 'UPDATE_USER_SETTING_SUCCESS'

export const updateUserSetting = (attributes: Object) => {
  attributes = fromPairs(
    Object.entries(attributes).map(([k, v]) => [getUserSettingValue(k), v])
  )
  return {
    type: UPDATE_USER_SETTING,
    attributes
  }
}

export const updateUserSettingOptimistic = (attributes: Object) => ({
  type: UPDATE_USER_SETTING_OPTIMISTIC,
  attributes
})

export function updateUserSettingError(error, attributes) {
  return {
    type: UPDATE_USER_SETTING_ERROR,
    error,
    attributes
  }
}

export function updateUserSettingSuccess(attributes) {
  return {
    type: UPDATE_USER_SETTING_SUCCESS,
    attributes
  }
}

export const {
  fetchAction: fetchActionUserSettings,
  fetchStartAction: fetchUserSettingsStart,
  fetchSuccessAction: fetchUserSettingsSuccess,
  fetchErrorAction: fetchUserSettingsError
} = createFetchActions(USER_SETTINGS_FETCH_SECTION)

export const fetchUserSettings = userId =>
  fetchActionUserSettings({ userId })
