import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

export const HeaderStyled = styled(Typography, {
  shouldForwardProp: prop => !['blackText', 'alternateFontSize', 'noTextTransform'].includes(prop)
})`
  height: 48px;
  color: ${({ theme, blackText, midnight }) =>
    midnight
      ? theme.palette.primary.navy.midnight
      : blackText
        ? theme.palette.primary.dark
        : theme.palette.common.white};
  font-size: ${({ theme, alternateFontSize }) =>
    alternateFontSize ? theme.custom.font.size.xl36 : '48px'};
  font-weight: bold;
  line-height: 58px;
  ${({ noTextTransform }) => !noTextTransform && `text-transform: capitalize;`}
  margin: 18px 0;
  position: relative;
  max-width: ${({ isSummary }) => (isSummary ? '386px' : 'fit-content')};

  & span {
    position: relative;
    white-space: nowrap; 
  }

  &::before {
    left: 0;
    right: 5px;
    bottom: -7px;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.secondary.main1};
    transform: translateX(10px);
    content: '';
    ${({ small }) => small ?
      `
        height: 3px;
        left: -5px;
        width: 60px;
      `
      :
      `
        height: 14px;
        left: 0;
      `
    }
  }
`
