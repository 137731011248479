import styled from '@emotion/styled'
import Button from '@material-ui/core/Button'

export const FabButton = styled(Button)`
  position: absolute;
  bottom:3.5em;
  right: 1.5em;
  height: 59px;
  width: 59px;
  background-color: ${({ theme }) => theme.palette.primary.dark};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.green.mint};
  }
`

export const UnitContainer = styled('div')`
  height: 56px;
  display: flex;
  -webkit-display: flex;
  -webkit-align-items: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: ${({ theme, lastUnit }) =>
    lastUnit ? `0px` : `1px solid ${theme.palette.grey.light7}`};

  * {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }

  a {
    color: ${({ theme }) => theme.palette.secondary.main4};
  }
`

export const Name = styled('div')`
  line-height: 24px;
  text-transform: uppercase;
  padding-left: 0.425em;
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  color: ${({ theme }) => theme.palette.text.black1};
`

export const UnitName = styled('div')`
  line-height: 24px;
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  color: ${({ theme }) => theme.palette.text.black1};
`

export const LinkItem = styled('div')`
  line-height: 24px;
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  text-decoration: underline;
`
