import { combineReducers } from 'redux'
import statuses from './statuses'
import permissions from './permissions'
import lockCodesReducer, { resyncingLockCodes } from './lockCodes'

export const generateLockCodes = enhancerMap => {
  const lockCodes = combineReducers({
    statuses: enhancerMap.statuses(statuses),
    permissions: enhancerMap.permissions(permissions),
    lockCodes: enhancerMap.permissions(lockCodesReducer)
  })

  return enhancerMap.self(lockCodes)
}

export const lockCodes = combineReducers({
  statuses,
  permissions,
  lockCodes: lockCodesReducer,
  resyncingLockCodes
})
