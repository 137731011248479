import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

const styles = theme => ({ 
  GroupSelected: {
    borderRadius: "25px!important",
    height: "32px",
    marginTop: "calc(4.35em - 16px)",
    marginRight: "126px",
    background: theme.palette.primary.green.iceberg
  },
  GroupClosed: {
    borderRadius: "25px!important",
    height: "32px",
    marginTop: "calc(4.35em - 16px)",
    marginRight: "126px",
    background: theme.palette.grey[500],
  },
  ButtonSelected: {
    borderRadius: "25px !important"
  },
  ButtonClosed: {
    background: `${theme.palette.grey[700]} !important`,
    borderRadius: "25px!important",
    cursor: "not-allowed"
  },
  ButtonRoot: {
    '&:hover': {
      borderRadius: "25px!important"
    }
  },
  ButtonRootClosed: {
    '&:hover': {
      borderRadius: 0,
      background: `${theme.palette.grey[500]} !important`,
    },
    cursor: "not-allowed"
  },
})

const StyledToggle = (props) => {

  const {startingValue, classes: { ButtonRoot, ButtonRootClosed, GroupClosed, ButtonSelected, GroupSelected, ButtonClosed } } = props
  const GroupClass = startingValue === "Closed" ?  GroupClosed : GroupSelected
  const ButtonSelectedClass = startingValue === "Closed" ?  ButtonClosed : ButtonSelected
  const ButtonRootClass = startingValue === "Closed" ?  ButtonRootClosed : ButtonRoot
  
  return (
    <ToggleButtonGroup
      classes={{
        selected: GroupClass
      }}
      exclusive
      disableRipple
      value={props.startingValue}
      onChange={props.handleToggle}>
      <ToggleButton
        classes={{
          selected: ButtonSelectedClass,
          root: ButtonRootClass
        }}
        value="Open">
        Open
      </ToggleButton>
      <ToggleButton
        classes={{
          selected: ButtonSelectedClass,
          root: ButtonRootClass
        }}
        value="InProgress">
        In Progress
      </ToggleButton>
      <ToggleButton
        classes={{
          selected: ButtonSelectedClass,
          root: ButtonRootClass
        }}
        value="Closed">
        Closed
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default withStyles(styles)(StyledToggle);
