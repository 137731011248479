// @flow
import { connect } from 'react-redux'
import CreateGroupMessage from './CreateGroupMessage'
import { fetchTemplates } from 'zego-shared/store/groupMessaging/templates'
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { fetchGroupEvents } from 'zego-shared/store/groupMessaging/groupEvents'
import { getToken, getUserRole } from 'zego-shared/store/authorization/selectors'
import api from 'zego-shared/utils/api'
import { getFeatureFlag } from '../../../../integrations/LaunchDarkly/selectors'
import { ldInitRequest } from '../../../../integrations/LaunchDarkly/actions'
import {
  getGroupResidents,
  getGroupLabel
} from 'zego-shared/store/groupMessaging/groups/selectors'

import {
  sendGroupMessage,
  resetStatuses,
  continueSendGroupMessage
} from 'zego-shared/store/groupMessaging/message/actions'
import {
  getSendMessageSuccess,
  getSaveAsTemplateFailedStatus,
  getContinueSendMessageStatus
} from 'zego-shared/store/groupMessaging/message/selectors'
import { fetchReplacementOptions } from 'zego-shared/store/groupMessaging/replacementOptions'
import {
  getSelectedGroupsUsers,
  groupsToGroupsData
} from 'zego-shared/store/groupMessaging/groups/selectors'
import {
  hasReplacementOptions,
  getReplacementOptions
} from 'zego-shared/store/groupMessaging/replacementOptions/selectors'
import {
  fetchGroups,
  fetchGroupUsers
} from 'zego-shared/store/groupMessaging/groups'
import {
  hasTemplates,
  getTemplatesDropdownData
} from 'zego-shared/store/groupMessaging/templates/selectors'
import {
  hasGroupEvents,
  getGroupEvents,
  getGroupEventsDropDownData
} from 'zego-shared/store/groupMessaging/groupEvents'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import { getPropertyTimezone } from 'zego-shared/store/properties/actions'
import { getTimezone } from 'zego-shared/store/summary/selectors'

const fetchingSelector = createIsFetchingSelector([
  'templates',
  'groups',
  'replacement'
])

const fetchingGroupsUsersSelector = createIsFetchingSelector(['groupsusers'])

const searchGroups = async (authToken, propertyId, term) => {
  const groups = await api.searchGroupMessagingGroups(
    authToken,
    propertyId,
    term
  )
  return groupsToGroupsData(groups)
}

const getDefaultGroups = async (authToken, propertyId) => {
  const groups = await api.getDefautlGroupMessagingGroups(authToken, propertyId)
  return groupsToGroupsData(groups)
}
const isEdit = false

const mapStateToProps = (state, { match: { params } }) => ({
  templates: getTemplatesDropdownData(state, isEdit),
  groupEventsDropDownOptions: getGroupEventsDropDownData(state),
  groupEvents: getGroupEvents(state),
  isFetching: fetchingSelector(state),
  authToken: getToken(state),
  replacementOptions: getReplacementOptions(state),
  getGroupResidents: id => getGroupResidents(state, id),
  getGroupLabel: id => getGroupLabel(state, id),
  hasData:
    hasTemplates(state) &&
    hasGroupEvents(state) &&
    hasReplacementOptions(state),
  propertyId: params.propertyId,
  searchGroups,
  getDefaultGroups,
  groupsUsers: getSelectedGroupsUsers(state),
  groupsUsersLoading: fetchingGroupsUsersSelector(state),
  sendMessageSuccess: getSendMessageSuccess(state),
  saveAsTemplateFailed: getSaveAsTemplateFailedStatus(state),
  continueSendMessage: getContinueSendMessageStatus(state),
  featureFlag: getFeatureFlag(state),
  timezone: getTimezone(state),
  userRole: getUserRole(state)
})

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  return {
    fetchData: () => {
      dispatch(fetchTemplates(params.propertyId))
      dispatch(fetchGroups(params.propertyId))
      dispatch(fetchGroupEvents(params.propertyId))
      dispatch(fetchReplacementOptions(params.propertyId))
      dispatch(getPropertyTimezone())
    },
    getGroupUsers: groupIds => dispatch(fetchGroupUsers(groupIds)),
    sendGroupMessage: (
      type,
      templates,
      message,
      selectedTemplateId,
      selectedGroups,
      selectedDeliveryMethods,
      selectedDateTime,
      events,
      selectedEventId,
      offsetBeforeAfter,
      offsetDays,
      timezone
    ) => {
      dispatch(
        sendGroupMessage({
          type,
          templates,
          message,
          selectedTemplateId,
          selectedGroups,
          selectedDeliveryMethods,
          selectedDateTime,
          events,
          selectedEventId,
          offsetBeforeAfter,
          offsetDays,
          timezone
        })
      )
    },
    onAlertClickYes: () => dispatch(continueSendGroupMessage()),
    resetStatuses: () => dispatch(resetStatuses()),
    ldInitRequest,
    showSnackbar,
    displayErrorMessage: (message) => dispatch(showSnackbar(message, 'error'))
  }
}

const CreateGroupMessageWithFetchData = withFetchData(CreateGroupMessage)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateGroupMessageWithFetchData)
