// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import UserInfoCard from './UserInfoCard'
import { GridItem } from '../../Common/Grid'
import TopNavigation from '../../Common/TopNavigation'
import UserProfileTabRoutes from '../../../routes/Users/UserProfileTabRoutes'
import withAlertModal from '@Common/AlertModal/withAlertModal'
import Loader from '../../Common/Loader'
import { UserProfileTabRoutesWrapper } from './styles'

type Props = {
  isFetching: boolean,
  hasData: boolean,
  match: { params: { propertyId: string, userId: string } },
  currentTab: string,
  profile: {
    role: string,
    status: string,
    userId: string,
    name: string,
    isPmsUser: boolean
  },
  showAlertModal: () => {},
  showSnackbar: (message: string, variant: string) => {},
  residentSurveysEnabled: boolean
}

export class UserProfile extends React.Component<Props, {}> {
  getTabs = () => {
    const {
      isFetching,
      match: {
        params: { propertyId, userId }
      },
      profile: { role },
      residentSurveysEnabled
    } = this.props

    return !isFetching && role === 'Resident'
      ? [
          {
            name: 'General Info',
            route: `/properties/${propertyId}/user-management/profile/${userId}/general`
          },
          {
            name: 'Lease Info',
            route: `/properties/${propertyId}/user-management/profile/${userId}/lease`
          },
          residentSurveysEnabled && {
            name: 'Surveys',
            route: `/properties/${propertyId}/user-management/profile/${userId}/survey`
          }
          //   {
          //     name: 'Pets',
          //     route: `/properties/${propertyId}/user-management/profile/${userId}/pets`
          //   },
          //   {
          //     name: 'Vehicles',
          //     route: `/properties/${propertyId}/user-management/profile/${userId}/vehicles`
          //   },
          //   {
          //     name: 'Visitors',
          //     route: `/properties/${propertyId}/user-management/profile/${userId}/visitors`
          //   }
        ]
      : !isFetching && role !== 'Resident'
      ? [
          {
            name: 'General Info',
            route: `/properties/${propertyId}/user-management/profile/${userId}/general`
          }
        ]
      : []
  }

  render() {
    const {
      isFetching,
      profile,
      hasData,
      match: { params },
      showAlertModal,
      currentTab
    } = this.props

    if (isFetching) return <Loader />
    if (!hasData) return null

    return (
      <React.Fragment>
        <GridItem columnStart={5} columnSpan={9} rowStart={1} rowSpan={1}>
          <TopNavigation
            scrollable={false}
            tabs={this.getTabs()}
            backArrowUrl={`/properties/${params.propertyId}/user-management`}
          />
        </GridItem>
        <UserProfileTabRoutesWrapper
          columnStart={5}
          columnSpan={9}
          rowStart={2}
          rowSpan={1}>
          <UserProfileTabRoutes />
        </UserProfileTabRoutesWrapper>
        <UserInfoCard
          name={profile.name}
          status={profile.status}
          role={profile.role}
          userId={profile.userId}
          showAlertModal={showAlertModal}
          isPmsUser={profile.isPmsUser}
          currentTab={currentTab}
        />
      </React.Fragment>
    )
  }
}

export default withTheme(withAlertModal(UserProfile))
