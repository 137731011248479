// @flow
import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { FlexItem } from '@Common/Flex/FlexDirection'
import { capitalizeFirstLetter } from '../../../../../utils'
import { Specs, BatteryPercentWrap, BatteryLevelText } from '../../styles'

const ThermostatSpecs = ({
  deviceModel,
  deviceStatus,
  installationDate,
  batteryPercent,
  batteryLevelPercentText,
  toggleViewBatteryMode
}: any) => (
  <FlexItem flex={1}>
    <Specs>
      <BatteryPercentWrap onClick={() => toggleViewBatteryMode()}>
        <LinearProgress
          variant="determinate"
          value={batteryPercent}
          classes={{
            bar: 'battery-bar',
            root: 'battery-root'
          }}
        />
      </BatteryPercentWrap>
      <BatteryLevelText onClick={() => toggleViewBatteryMode()}>
        {batteryLevelPercentText}
      </BatteryLevelText>
      <div>{deviceModel}</div>
      <div>Installed {installationDate}</div>
      <div>{capitalizeFirstLetter(deviceStatus)}</div>
    </Specs>
  </FlexItem>
)

export default ThermostatSpecs
