import { TEMPLATE, createTemplateSuccess } from './actions'
import withFetch from '../../helpers/reducers/withFetch'

const template = (state = null, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withFetch(TEMPLATE)(template)
