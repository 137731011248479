// @flow
import React from 'react'
import { List, ListItem } from './styles'

type Props = {
  items: Node[],
  height: number,
  currentSlide: number,
  numberOfItemsPerSlide: number,
  itemHeight: number
}

const CarouselList = ({
  items,
  itemHeight,
  currentSlide,
  numberOfItemsPerSlide
}: Props) => (
  <List
    itemHeight={itemHeight}
    numberOfItemsPerSlide={numberOfItemsPerSlide}
    slideNumber={currentSlide}>
    {items.map((item, index) => (
      <ListItem key={index}>{item}</ListItem>
    ))}
  </List>
)

export default CarouselList
