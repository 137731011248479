import { createFetchActions } from '../../../helpers/actions/creators'

export const COMPANIES_FETCH_SECTION = 'COMPANIES'
export const SELECT_COMPANY = 'SELECT_COMPANY'
export const FETCH_COMPANY_PROPERTIES_AND_SELECT =
  'FETCH_COMPANY_PROPERTIES_AND_SELECT'

export const {
  fetchSuccessAction: fetchCompaniesSuccess,
  fetchAction: fetchCompaniesAction,
  fetchErrorAction: fetchCompaniesError,
  fetchStartAction: fetchCompaniesStart
} = createFetchActions(COMPANIES_FETCH_SECTION)

export const fetchCompanies = (page = 1) => fetchCompaniesAction({ page })

export const selectCompany = (id, fromSelectedProperty = true) => ({
  type: SELECT_COMPANY,
  fromSelectedProperty,
  id
})

export const fetchCompaniesPropertiesAndSelect = (
  companyId,
  history,
  propertyId,
  url
) => ({
  type: FETCH_COMPANY_PROPERTIES_AND_SELECT,
  companyId,
  history,
  propertyId,
  url
})
