import styled from '@emotion/styled'
import MaterialButton from '@material-ui/core/Button'
import isPropValid from '@emotion/is-prop-valid'

export const Button = styled(MaterialButton, {
  shouldForwardProp: isPropValid
})`
  width: ${props => (props.width ? props.width : '30%')};
  padding: 0px;
  height: ${({ height }) => (height ? height : '25px')};
  min-height: 25px;
  background-color: ${props =>
    props.background ? props.background : props.theme.palette.grey.light};
  color: ${({ theme, outlined }) =>
    outlined
      ? theme.palette.primary.navy.midnight
      : theme.palette.common.white};
  text-align: center;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  border: ${({ theme, outlined }) =>
    outlined && `3px solid ${theme.palette.primary.navy.spaceBlue}`};
  border-radius: 0;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme, outlined }) =>
      outlined ? theme.palette.common.white : theme.palette.primary.green.mint};

    border: ${({ theme, outlined }) =>
      outlined && `3px solid ${theme.palette.primary.green.mint}`};
    color: ${({ theme }) => theme.palette.primary.navy.midnight};
  }
  ${({ disabled, theme }) =>
    disabled &&
    `
  background-color: ${theme.palette.neutrals.warmGray};
  * {color: ${theme.palette.common.white};}
  `};
`
