import { createFetchActions } from '../../helpers/actions/creators'

export const REPLACEMENT_OPTIONS = 'REPLACEMENT_OPTIONS'

export const {
  fetchAction: fetchReplacementOptionsAction,
  fetchStartAction: fetchReplacementOptionsStart,
  fetchSuccessAction: fetchReplacementOptionsSuccess,
  fetchErrorAction: fetchReplacementOptionsError
} = createFetchActions(REPLACEMENT_OPTIONS)

export const fetchReplacementOptions = propertyId => fetchReplacementOptionsAction({ propertyId })
