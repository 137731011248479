import { call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken, getPropertyId } from '../authorization/selectors'
import {
  fetchSurveyStats as fetchSurveyStatsAction,
  fetchSurveyStatsStart,
  fetchSurveyStatsSuccess,
  fetchSurveyStatsError,
  fetchResidentSurveys as fetchResidentSurveysAction,
  fetchResidentSurveysStart,
  fetchResidentSurveysSuccess,
  fetchResidentSurveysError
} from './actions'

import api from '../../utils/api'
import log from '../../utils/logger'

export function* fetchSurveyStats() {
  try {
    yield put(fetchSurveyStatsStart())
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)

    const response = yield call(api.getResidentSurveyStats, authToken, propertyId) 
    yield put(fetchSurveyStatsSuccess(response))
  } catch (error) {
    yield put(fetchSurveyStatsError(error))
    log('Failed to fetch survey statistics for property')
  }
}

export function* fetchResidentSurveys({ userId }) {
  try {
    yield put(fetchResidentSurveysStart())
    const authToken = yield select(getToken)

    const response = yield call(api.getResidentSurveys, authToken, userId) 
    yield put(fetchResidentSurveysSuccess(response))
  } catch (error) {
    yield put(fetchResidentSurveysError(error))
    log('Failed to fetch resident surveys for user')
  }
}

function* watchFetchSurveyStats() {
  yield takeLatest(fetchSurveyStatsAction().type, fetchSurveyStats)
}

function* watchFetchResidentSurveys() {
  yield takeLatest(fetchResidentSurveysAction().type, fetchResidentSurveys)
}

export default[watchFetchSurveyStats(), watchFetchResidentSurveys()]
