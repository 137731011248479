// @flow

import get from 'lodash/get'
// $FlowDisableNextLine
import { formatPhoneNumber } from 'zego-shared/utils/string'

const emptyObj = {}

export const getProfile = (state: Object) =>
  state.authorization.profile || emptyObj

export const getUserPhoneNumber = (state: Object) => {
  const phoneNum = get(state, ['authorization', 'profile', 'phone_number'], '')
  return formatPhoneNumber(phoneNum)
}

export const getUserProfilePicture = (state: Object) =>
  get(state, ['authorization', 'profile', 'picture'], '')

export const getToken = (state: Object) => state.authorization.token

export const getRefreshToken = (state: Object) =>
  state.authorization.refreshToken

export const getUnitId = (state: Object) => state.authorization.profile.units[0]

export const getPropertyId = (state: Object) =>
  state.authorization.profile.properties[0]

export const getUserId = (state: Object) => state.authorization.id

export const getSendbirdUserId = (state: Object) =>
  state.authorization.sendbirdUserID

export const getSendbirdToken = (state: Object) =>
  state.authorization.sendbirdToken

export const getSendbirdAppID = (state: Object) =>
  state.authorization.sendbirdAppID

export const getSendbirdUserID = (state: Object) =>
  state.authorization.sendbirdUserID

export const getIsAuth = (state: Object) => state.authorization.isAuth

export const hasSendbirdToken = (state: Object) => {
  return state.authorization.sendbirdToken ? true : false
}

export const getSendBirdInfo = (state: Object) => {
  const sendbirdToken = getSendbirdToken(state)
  const sendbirdAppID = getSendbirdAppID(state)
  const sendbirdUserID = getSendbirdUserId(state)

  return sendbirdToken && sendbirdAppID
    ? {
        sendbirdToken,
        sendbirdAppID,
        sendbirdUserID
      }
    : null
}

export const getUserEmail = (state: Object) =>
  get(state, ['authorization', 'profile', 'email'], '')

export const getUserName = (state: Object) =>
  get(state, ['authorization', 'profile', 'name'], '')

export const getUserRole = (state: Object) =>
  get(state, ['authorization', 'profile', 'role'], '')

export const getIsValidSignupToken = (state: Object) =>
  get(state, ['authorization', 'isValidSignupToken'])

export const getSignupSuccessful = (state: Object) =>
  get(state, ['authorization', 'signupSuccessful'])

export const getUserBio = (state: Object) =>
  state.authorization.profile.user_metadata.bio

export const hasLoginFailed = (state: Object) =>
  get(state, ['authorization', 'loginFailed'])
