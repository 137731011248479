// @flow

import React from 'react'
import { Input } from './styles'

type Props = {
  placeholder: string,
  dark?: boolean
}

const ZegoInput = ({ placeholder, ...props }: Props) => (
  // $FlowDisableNextLine
  <Input disableUnderline placeholder={placeholder} {...props} />
)

export default ZegoInput
