import styled from '@emotion/styled'
import { css } from 'emotion'
import Typography from '@material-ui/core/Typography'

export const ContentWrap = styled('div')`
  overflow-x: hidden;
  width: ${({ numberOfItemsPerSlide }) =>
    numberOfItemsPerSlide && `${numberOfItemsPerSlide * 110}px`};
  .tabs-root {
    overflow-x: hidden;
    margin-left: ${({ slideNumber, numberOfItemsPerSlide }) =>
      slideNumber && numberOfItemsPerSlide
        ? `-${110 * numberOfItemsPerSlide * (slideNumber - 1)}px`
        : '0'};
    -webkit-transition: margin-left 1s;
    transition: margin-left 1s;
  }

  .tab-root {
    width: ${({ numberOfItemsPerSlide }) => numberOfItemsPerSlide && `110px`};
  }

  &.main-tab-root1 {
    .tab-root {
      font-size: ${({ theme, vertical }) => vertical && '20px'};
      margin: ${({ numberOfItemsPerSlide }) =>
        numberOfItemsPerSlide && `0px 10px 0px 0px`};
      min-width: 1em;
      text-transform: none;
      pointer-events: none;
      margin-right: ${({ marginRight }) => marginRight && marginRight};

      & > span > span {
        pointer-events: auto;
      }

      * {
        padding-left: 0;
        padding-right: ${({ numberOfItemsPerSlide }) =>
          numberOfItemsPerSlide && `0px`};
        align-items: flex-start;
        color: ${({ theme }) => theme.palette.primary.navy.midnight};
      }
    }

    .tab-selected {
      font-weight: ${({ theme }) => theme.custom.font.weight.bold};
      font-size: ${({ theme, vertical }) =>
        vertical && theme.custom.font.size.lg};
    }

    ${({ selectTextColor }) =>
      selectTextColor &&
      `
      .tab-selected {
    color: ${selectTextColor}
      }
    `} .tabs-indicator {
      max-width: 15px;
      height: 5px;
      background-color: ${({ theme, selectColor }) =>
        selectColor ? selectColor : theme.palette.secondary.yellow.solarFlare};
    }
  }

  ${({ vertical, theme, selectColor }) =>
    vertical &&
    `
.container {
flex-direction:  column};
}

.tabs-indicator {
  display: none;
  }

.tab-selected.tab-root:after {
content: ' ';
width: 15px;
height: 5px;
background-color: ${
      selectColor ? selectColor : theme.palette.secondary.yellow.solarFlare
    };
position: absolute;
bottom: 8%;
left: 0;
}
`};

  &.main-tab-root2 {
    .tab-root {
      padding-top: 1em;
      min-width: 0em;
      width: ${({ numberOfItemsPerSlide }) => !numberOfItemsPerSlide && `auto`};
      color: ${({ theme }) => theme.palette.grey.main};
      opacity: 1;
      text-align: center;
      text-transform: capitalize;

      * {
        padding: 0 0.5em;
      }
    }

    .tab-selected {
      color: ${({ theme }) => theme.palette.text.black1};
      background-color: ${({ theme, selectColor }) =>
        selectColor ? selectColor : theme.palette.neutrals.coolGray};
      font-weight: ${({ theme }) => theme.custom.font.weight.bold};
    }

    .tabs-indicator {
      display: none;
    }
  }

  ${props => props.customStyle};
`

export const customCss = scrollable =>
  scrollable
    ? css`
        & > div:first-of-type {
          display: flex;
        }
        & > div:last-of-type {
          width: 1em;
          display: flex;
        }
        .scroll-button {
          cursor: pointer;
          align-self: center;
        }
      `
    : null

export const RightPanel = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  flex: 1;
  display: flex;
  justify-content: flex-end;

  & > span {
    margin: 0em 0.5em;
    align-self: center;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & > span:last-child {
    display: flex;
    justify-content: flex-end;
  }
`

export const Count = styled('span')`
  color: ${({ theme }) => theme.palette.secondary.light1};
`

export const CaptionHeading = styled(Typography)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.grey.main};
`

export const MainHeading = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
`
