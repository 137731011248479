// @flow
export const convertHexToRGB = (hexColor: string, opacity: number = 1) => {
  const hexCode = hexColor.replace('#', '')
  return `rgba(${parseInt(hexCode.substring(0, 2), 16)}, ${parseInt(
    hexCode.substring(2, 4),
    16
  )}, ${parseInt(hexCode.substring(4, 6), 16)}, ${opacity})`
}

export const selectItem = (() => {
  let keyBuffer = ''
  let timeout = null
  return (key: string, menuItems: HTMLCollection<HTMLElement> | null) => {
    keyBuffer += key
    if (menuItems) {
      const menuItem = Array.prototype.find.call(menuItems, item =>
        item.innerText.toLowerCase().startsWith(keyBuffer.toLowerCase())
      )
      if (menuItem) {
        menuItem.focus()
        menuItem.scrollIntoView(true)
      }
    }
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      keyBuffer = ''
      timeout = null
    }, 500)
  }
})()
