// @flow

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import moment from 'moment'

const getMessageDetailByMethod = (
  sendingMethods: Object[],
  expectedMethod: string
) => {
  const result = sendingMethods.find(({ method }) => method === expectedMethod)
  if (result == null) {
    return {}
  }
  return result
}

const getMessageDetails = (sendingMethods: Object[]) => {
  const {
    time: emailSentTime,
    address: emailRecipient
  } = getMessageDetailByMethod(sendingMethods, 'email')
  const {
    time: textSentTime,
    address: textRecipient
  } = getMessageDetailByMethod(sendingMethods, 'text')

  const {
    time: inAppSentTime,
    address: inAppRecipient
  } = getMessageDetailByMethod(sendingMethods, 'in-app')

  return {
    emailSentTime:
      emailSentTime && emailRecipient
        ? moment
            .utc(emailSentTime)
            .local()
            .format('M/D/YY hh:mma')
        : null,
    emailRecipient,
    textSentTime:
      textSentTime && textRecipient
        ? moment
            .utc(textSentTime)
            .local()
            .format('M/D/YY hh:mma')
        : null,
    textRecipient,
    inAppSentTime:
      inAppSentTime && inAppRecipient
        ? moment
            .utc(inAppSentTime)
            .local()
            .format('M/D/YY hh:mma')
        : null,
    inAppRecipient
  }
}

const getSentMessageSendingMethods = ( sendingMethods: Array<{method: string}>) =>
  uniq(sendingMethods.map(({ method }) => method))

const getMessageSendingMethods = (sendingMethods: Array<string>) =>
  sendingMethods ? uniq(sendingMethods).join(', ') : 'NA'

const createRecipientsData = (recipients, isFuture) =>
  recipients.map(
    ({
      user_id: userId,
      name,
      picture: image,
      user,
      methods,
      groups,
      sending_methods: sentSendingMethods
    }) => {
      methods = isFuture
        ? methods
        : getSentMessageSendingMethods(sentSendingMethods)
      return {
        userId: userId ? userId : user.user_id,
        name: name ? name : user ? `${user.first_name} ${user.last_name}` : '',
        image: image ? image : user ? user.picture : null,
        method: methods ? getMessageSendingMethods(methods) : 'NA',
        groups,
        messageDetails: sentSendingMethods ? getMessageDetails(sentSendingMethods) : null
      }
    }
  )

const cronToMoment = (cron: string) => {
  if (cron) {
    const [minute, hour, day, month] = cron.split(' ')
    const momentDate = moment()
      .utc()
      .set({
        minute,
        hour,
        date: day,
        month: month === '*' ? moment().month() : parseInt(month) - 1
      })
    return momentDate.local()
  }

  return null
}

const getRecurringOption = (cron: string) => {
  if (cron) {
    const [_minute, _hour, _day, month, _dayOfWeek, year] = cron.split(' ')
    if (month !== '*') {
      return 'once a year'
    }
    return 'once a month'
  }
  return ''
}

const convertCronTotext = (cron: string, type: string) => {
  if (type === 'once') {
    return 'One Time Only'
  }

  if (cron && type === 'recurring') {
    return getRecurringOption(cron)
  }

  return ''
}

const isEventType = (groups: Object) => groups && groups[0].type === 'event'

// eslint-disable-next-line import/prefer-default-export
export const getMessage = (state: Object, status: string, time: string) => {
  const isFuture = status === 'future'
  const templatePermissions = state.groupMessaging.templatePermission || []
  const {
    id,
    group_selector_names,
    resource_type,
    groups,
    title,
    name,
    text,
    text_body: textBody,
    sending_methods,
    sending_user: sendingUser,
    attachments,
    recipients,
    inserted_at: sentAt,
    automation_name: automationName,
    template_name: templateName,
    template_id: templateId,
    summary,
    user,
    sending_methods_summary,
    template,
    cron_run: cron,
    type,
    totalPages,
    currentPage,
    perPage,
    total
  } = get(state, ['groupMessaging', 'message'], {})

  const sentDateTime = isFuture
    ? moment
        .utc(time)
        .local()
        .format('MMMM D, YYYY @ h:mmA')
    : moment
        .utc(sentAt)
        .local()
        .format('MMMM D, YYYY @ h:mmA')
  let {
    in_app_count: inAppCount = 0,
    email_count: emailCount = 0,
    text_count: textCount = 0
  } = summary || {}

  if (isFuture && sending_methods_summary) {
    let { 'in-app': inApp, email, text } = sending_methods_summary

    inAppCount = inApp
    emailCount = email
    textCount = text
  }

  const futureTemplate = template || {
    id: '',
    body: '',
    text_body: '',
    title: '',
    name: '',
    attachments: {},
    resource_type: 'string'
  }

  const isEvent = isEventType(groups)

  const sendMethod = type === "event" ?
    group_selector_names.map(eventName => eventName).join(', ') : "One Time Only"

  const eventLabels = {
    birthdate: 'Date of Birth',
    lease_start_date: 'Lease Start',
    lease_end_date: 'Lease End',
    move_in_date: 'Move In',
    move_out_date: 'Move Out',
  }

  return {
    id,
    groups: !isFuture
      ? group_selector_names
      : groups
        ? groups.map(group => {
            return {
              label: `@${group.name}`,
              value: group.id
            }
          })
        : [],
    text: isFuture ? futureTemplate.body : text,
    textBody: isFuture ? futureTemplate.text_body : textBody,
    title: isFuture ? futureTemplate.title : title,
    sendingMethods: sending_methods,
    sendingUser: isFuture ? user || { name: '' } : sendingUser || { name: '' },
    attachments: isFuture ? futureTemplate.attachments : attachments || {},
    recipients: createRecipientsData(recipients || [], isFuture),
    automationName: isFuture ? name : automationName,
    templateName: isFuture ? futureTemplate.name : templateName,
    templateId: isFuture ? futureTemplate.id : templateId,
    cronText: isFuture ? (isEvent ? (eventLabels[groups[0].event_name] || groups[0].event_name) : convertCronTotext(cron, type)
    ) : sendMethod,
    sentDateTime,
    inAppCount,
    emailCount,
    textCount,
    dateTimeMoment: {
      moment: cronToMoment(cron),
      recurringOptionName: getRecurringOption(cron)
    },
    canEditTemplate: templatePermissions.includes(
      isFuture ? futureTemplate.resource_type : resource_type
    ),
    event: {
      offset: isEvent ? groups[0].user_selectors[1].value : '0',
      eventType: isEvent ? groups[0].event_name : ''
    },
    isEvent,
    totalPages,
    currentPage,
    perPage,
    total
  }
}

export const hasMessage = (state: Object) => {
  const { id } = get(state, ['groupMessaging', 'message'], {})
  return id != null
}

export const getSendMessageSuccess = (state: Object) => {
  const { sendSuccess } = get(state, ['groupMessaging', 'message'], {})
  return sendSuccess
}

export const getSaveAsTemplateFailedStatus = (state: Object) => {
  const { saveAsTemplateFailed } = get(state, ['groupMessaging', 'message'], {})
  return saveAsTemplateFailed
}

export const getContinueSendMessageStatus = (state: Object) => {
  const { continueSendMessage } = get(state, ['groupMessaging', 'message'], {})
  return continueSendMessage
}

export const isMessageOfEventType = (state: Object) => {
  const { type } = get(state, ['groupMessaging', 'message'], {})
  return type === 'event'
}
