// @flow
import { connect } from 'react-redux'
import queryString from 'query-string'
import moment from 'moment'
import { getUnixStartEndMonth } from '../../../utils'
import EventsFeed from './EventsFeed'
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { getToken } from 'zego-shared/store/authorization/selectors'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import { fetchNextMonthEvents } from 'zego-shared/store/events/eventsActions'
import api from 'zego-shared/utils/api'
import {
  fetchEventsInitial,
  fetchMonthEvents,
  clearEventTimestamps,
  updateEventRSVP
} from 'zego-shared/store/events'
import {
  getNewOrUpcomingEvents,
  hasEventData,
  getTimestamps,
  getEventsByMonth,
  getFilteredEvents,
  getFilteredStatus,
  getShouldFetchNextEvents
} from '../../../store/CommunityFeed'

const fetchingSelector = createIsFetchingSelector(['eventsinitial'])
const fetchingMonthSelector = createIsFetchingSelector(['monthevents'])

const getStartEndUnix = search => {
  const { month = moment().month() } = queryString.parse(search)
  return getUnixStartEndMonth(month)
}

const mapStateToProps = (state, { location: { search } }) => {
  const { filter, month } = queryString.parse(search)
  const { start } = getStartEndUnix(search)
  return {
    newOrUpcomingEvents: getNewOrUpcomingEvents(state, filter),
    eventsByMonth: getEventsByMonth(state, month),
    filteredEvents: getFilteredEvents(state),
    filtered: getFilteredStatus(state),
    hasData: hasEventData(state, filter),
    isFetching: fetchingSelector(state),
    isFetchingMonth: fetchingMonthSelector(state),
    shouldFetchNext: getShouldFetchNextEvents(state, start),
    timestamps: getTimestamps(state),
    authToken: getToken(state),
    filter: filter || 'upcoming'
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { propertyId }
    },
    location: { search }
  }
) => ({
  fetchData: () => {
    const { start, end } = getStartEndUnix(search)
    dispatch(fetchEventsInitial(propertyId, start, end))
  },
  componentDidUpdate: (previousProps, props) => {
    if (previousProps.location.search !== props.location.search) {
      const { start, end } = getStartEndUnix(search)

      // don't fetch this months events if we already have
      if (!props.timestamps.includes(start)) {
        dispatch(fetchMonthEvents(propertyId, start, end))
      }
    }
  },
  componentWillUnmount: () => {
    dispatch(clearEventTimestamps())
  },
  fetchNext: () => {
    const { start, end } = getStartEndUnix(search)

    dispatch(fetchNextMonthEvents(propertyId, start, end))
  },
  rsvpToEvent: async (authToken, id) => {
    try {
      await api.rsvpToEvent(authToken, propertyId, id)
      dispatch(updateEventRSVP(id))
      dispatch(showSnackbar("Successfully rsvp'd to event!", 'success'))
    } catch (error) {
      dispatch(showSnackbar('Unable to rsvp. Please try again.', 'error'))
    }
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(EventsFeed))
