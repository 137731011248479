// @flow
import { connect } from 'react-redux'
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import SmartSummary from './SmartSummary'
import { fetchSummary } from 'zego-shared/store/summary/actions'
// $FlowDisableNextLine
import { fetchPropertyBatteryLevels } from 'zego-shared/store/batteries/actions'
import {
  getSmartUnitsCount,
  getInactiveUnitsCount,
  hasSummaryData
} from 'zego-shared/store/summary/selectors'
import {
  getSelectedPropertyVacantScheduleID,
  getSelectedPropertyVacantScheduleInfo
} from 'zego-shared/store/schedule/selectors'
import { fetchSchedule } from 'zego-shared/store/schedule/actions'
import { getPropertyPmsStatus } from 'zego-shared/store/properties/actions'
import { fetchPropertyHubs } from 'zego-shared/store/hubs/actions'

const fetchingSelector = createIsFetchingSelector([
  'summary',
  'propertybatterylevels',
  'schedule',
  'propertyhubs'
])

const mapStateToProps = (
  state,
  { location: { search }, match: { params } }
) => {
  return {
    isFetching: fetchingSelector(state),
    scheduleId: getSelectedPropertyVacantScheduleID(state),
    hasData: hasSummaryData(state),
    smartUnitsCount: getSmartUnitsCount(state),
    inactiveUnitsCount: getInactiveUnitsCount(state),
    propertyId: params.propertyId,
    scheduleInfo: getSelectedPropertyVacantScheduleInfo(state)
  }
}

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  return {
    fetchData: scheduleId => {
      dispatch(fetchSummary(params.propertyId))
      dispatch(fetchPropertyBatteryLevels(params.propertyId))
      dispatch(fetchSchedule(scheduleId))
      dispatch(getPropertyPmsStatus(params.propertyId))
      dispatch(fetchPropertyHubs(params.propertyId))
    }
  }
}

const SmartWithFetchData = withFetchData(SmartSummary)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    fetchData() {
      dispatchProps.fetchData(stateProps.scheduleId)
    }
  })
)(SmartWithFetchData)
