// @flow

import React from 'react'
import { connect } from 'react-redux'
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {
  fetchAmenity,
  updateAmenity,
  deleteAmenity
} from 'zego-shared/store/amenitiesLegacy'
import {
  hasAmenity,
  getAmenity
} from 'zego-shared/store/amenitiesLegacy/selectors'
import {
  getAmenityContact
} from 'zego-shared/store/settings/propertySettings/selectors'

import AmenityLegacy from './AmenityLegacy'
import {validateEmail} from "../../../utils/validationUtils"

const fetchingSelector = createIsFetchingSelector(['amenity'])

export const hasAmenityContactInfo = (state: Object) => {
  const contactInfo = getAmenityContact(state)
  if (typeof contactInfo === 'string') {
    return validateEmail(contactInfo)
  }
  return false
}

const mapStateToProps = (state, { match: { url } }) => {
  const addMode = url.indexOf('/new') > -1
  const defaultItem = {}

  return {
    item: addMode ? defaultItem : getAmenity(state),
    isFetching: fetchingSelector(state),
    hasData: addMode ? true : hasAmenity(state),
    hasAmenityContactInfo: hasAmenityContactInfo(state),
    addMode
  }
}

const mapDispatchToProps = (dispatch, { history, match: { url, params } }) => {
  return {
    fetchData: () => {
      params.amenityId !== 'new' && dispatch(fetchAmenity(params.amenityId, params.propertyId))
    },
    updateAmenity: (update, amenityId) => {
      dispatch(updateAmenity(update, history, url, amenityId))
    },
    deleteAmenity: id => {
      dispatch(deleteAmenity(id, history))
    }
  }
}

class AmenityLegacyWrapper extends React.Component<any, any> {
  componentDidMount = () => {
    const { ldInitRequest } = this.props
    ldInitRequest()
  }

  render() {
    const {
      props,
      props: {
        isFetching,
      },
    } = this

    if (isFetching) {
      return null
    }

    return <AmenityLegacy {...props}/>
  }
}

const AmenityWithFetchData = withFetchData(AmenityLegacyWrapper)

const AmenityLegacyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AmenityWithFetchData)

export default AmenityLegacyContainer
