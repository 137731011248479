// @flow
import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { convertHexToRGB } from '../../../../../utils/commonUtils'
import { TickType } from './types'

const Tick = ({ tick, theme }: TickType) => (
  <div>
    <div
      style={{
        position: 'absolute',
        width: 1,
        height: '2em',
        marginTop: '0.5em',
        backgroundColor: `${convertHexToRGB(theme.palette.grey.main, 0.2)}`,
        left: `${tick.percent}%`
      }}
    />
  </div>
)

export default withTheme(Tick)
