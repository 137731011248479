import { getUserId } from 'zego-shared/store/authorization/selectors'
import { getResidentUserIdFromResidentChannel } from '../channelSelectors'

const latestMessageToChatNotificationData = (latestMessage, channel) => {
  return {
    title: latestMessage._sender.nickname,
    channelId: getResidentUserIdFromResidentChannel(channel) || '',
    body: latestMessage.message,
    icon: latestMessage._sender.profileUrl,
    tag: String(Date.now())
  }
}

export const getChatNotificationData = state => {
  const { message = {}, channel = {} } = state.chatNotificationData
  if (message._sender) {
    const senderId = message._sender.userId
    if (senderId !== getUserId(state)) {
      return latestMessageToChatNotificationData(message, channel)
    }
  }
  return {}
}
