import compose from 'lodash/flowRight'
import withNormalizedFetch from '../../helpers/reducers/withNormalizedFetch'
import withFetch from '../../helpers/reducers/withFetch'
import {
  PROPERTIES_FETCH_SECTION,
  SELECT_PROPERTY,
  PROPERTY_BATTERY_LEVELS_SECTION,
  UNIT_BATTERY_LEVELS_SECTION
} from '../actions'

const initialState = {}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export const unitBatteries = withFetch(UNIT_BATTERY_LEVELS_SECTION)(reducer)

export default withFetch(PROPERTY_BATTERY_LEVELS_SECTION)(reducer)
