import { takeLatest, put } from 'redux-saga/effects'
import { setChatNotificationData as setChatNotificationDataAction } from './actions'
import { onMessageReceived } from 'zego-shared/store/chat/messages/actions'

function* setChatNotificationData({ message, channel }) {
  yield put(setChatNotificationDataAction({ message, channel }))
}

function* watchOnMessageReceived() {
  yield takeLatest(onMessageReceived().type, setChatNotificationData)
}

export default [watchOnMessageReceived()]
