import { isString } from '../../../utils/string'

/**
 * Reset reducer state to initialState for the specified action(s)
 *
 * @param {array or string} resetActionTypes - the reset action(s)
 *
 * Notes: base reducer must return initial state when passed state is undefined
 */
const withReset = resetActionTypes => reducer => (state, action) => {
  if (isString(resetActionTypes) && resetActionTypes === action.type) {
    return getInitialState(reducer)
  } else {
    // assume this is an array
    if (resetActionTypes.includes(action.type)) {
      return getInitialState(reducer)
    }
  }

  return reducer(state, action)
}

const getInitialState = reducer => reducer(undefined, {})

export default withReset
