// @flow
import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import IconButton from '@material-ui/core/IconButton'
import Flex from '@Common/Flex'
import { isFunction } from '../../../utils'
import {
  TableRowStyled,
  TableCellStyled,
  TableBodyStyled,
  TableHeadStyled,
  DotWrapper,
  DotStyled,
  KeyboardArrowLeftStyled,
  KeyboardArrowRightStyled,
} from './styles'

type Props = {
  columns: Array<any>,
  rows: Array<any>,
  rowsPerPage: number,
  total: number,
  currentPage?: number,
  changePage?: (number) => mixed,
  flex?: number[],
  noDataText?: string,
  className?: string,
  rowClickHandler?: (Object, number) => {},
  noBorder?: boolean,
  alternateColor?: boolean,
  headerBorderTop?: boolean,
  elementId?: string
}

type State = {
  page: number,
}

class PaginatedTable extends Component<Props, State> {
  static defaultProps = {
    rowsPerPage: 10,
  }

  state = {
    page: this.props.currentPage || 0,
  }

  changePage = (newPage: number, numberOfPages: number) => {
    const { changePage } = this.props
    if (newPage >= 0 && newPage < numberOfPages) {
      this.setState(
        {
          page: newPage,
        },
        () => {
          if (changePage != null && isFunction(changePage)) {
            changePage(newPage + 1)
          }
        }
      )
    }
  }

  getColumns = (): any => {
    const { columns, flex } = this.props
    return columns.map((columnData, index) => {
      return (
        <TableCellStyled
          className={columnData.className}
          key={index}
          colSpan={columnData.span}
          flex={flex ? flex[index] : null}
          columnLength={flex ? flex.length : null}>
          {columnData.value}
        </TableCellStyled>
      )
    })
  }

  getRows = (): any => {
    const {
      rows,
      columns,
      flex,
      noDataText,
      rowClickHandler,
      noBorder,
    } = this.props

    if (rows.length === 0) {
      return (
        <TableRowStyled noData>
          <TableCellStyled colSpan={columns.length} noData>
            {noDataText}
          </TableCellStyled>
        </TableRowStyled>
      )
    }

    return rows.map((row, index) => {
      return (
        <TableRowStyled
          noBorder={noBorder}
          key={index}
          onClick={(event) => rowClickHandler && rowClickHandler(event, row)}>
          {row.map((rowData, index) => {
            return (
              <TableCellStyled
                noBorder={noBorder}
                className={rowData.className}
                flex={flex ? flex[index] : null}
                columnLength={flex ? flex.length : null}
                colSpan={rowData.span}
                key={index}>
                {rowData.value}
              </TableCellStyled>
            )
          })}
        </TableRowStyled>
      )
    })
  }

  getDots = (numberOfPages: number): any => {
    const { page } = this.state
    return Array.from({ length: numberOfPages }, (_, index) => (
      <DotStyled
        isActive={page === index}
        key={index}
        onClick={() => this.changePage(index, numberOfPages)}
      />
    ))
  }

  render() {
    const {
      total,
      rowsPerPage,
      className,
      alternateColor,
      headerBorderTop,
      elementId
    } = this.props
    const { page } = this.state
    const numberOfPages = Math.ceil(total / rowsPerPage)

    return (
      <React.Fragment>
        <Table
          id={elementId}
          className={className}>
          <TableHeadStyled>
            <TableRowStyled headerBorderTop={headerBorderTop}>
              {this.getColumns()}
            </TableRowStyled>
          </TableHeadStyled>
          <TableBodyStyled alternateColor={alternateColor}>
            {this.getRows()}
          </TableBodyStyled>
        </Table>
        {total / rowsPerPage > 1 && (
          <Flex justifyCenter marginTop="20px">
            <IconButton
              onClick={() => this.changePage(page - 1, numberOfPages)}>
              <KeyboardArrowLeftStyled />
            </IconButton>
            <DotWrapper numberOfPages={numberOfPages}>
              {this.getDots(numberOfPages)}
            </DotWrapper>
            <IconButton
              onClick={() => this.changePage(page + 1, numberOfPages)}>
              <KeyboardArrowRightStyled />
            </IconButton>
          </Flex>
        )}
      </React.Fragment>
    )
  }
}

export default PaginatedTable
