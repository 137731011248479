// @flow

import React, { useEffect, useState } from 'react'
import type { Match } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import Flex from '@Common/Flex'
import DropDown from '@Common/DropDown'
import { GreyText } from '../styles'
import { LeaseInfoContainer, Link, EmptySpace, RedDotStyled, ResetLink, DialogMessage, DialogFooterContainer, DialogFooterMessage, warningIcon, ResetCheckListDateStyled } from './styles'
import LeaseDetailsCard from './LeaseDetailsCard'
import AlertModal from '@Common/AlertModal/AlertModal'
import Loader from '../../../Common/Loader'
import { SMART_RENEW_LD_FLAG } from '../../constants'
import type { BrowserHistory } from 'history'
import find from 'lodash/find'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { getDateFullYearAmPm } from 'zego-shared/utils/date.js'

type Props = {
  isFetching: boolean,
  hasData: boolean,
  leaseInfo: {
    monthlyCharge: number,
    leaseEndDate: string,
    balance: number,
    moveInDate: string,
    leaseStartDate: string,
    leaseInterestStatus: string
  },
  history: BrowserHistory,
  match: Match,
  profile: Object,
  profileComplete: Object,
  getChecklistSummary: Function,
  checkListStatus: number,
  checkListApprovalStatus: number,
  leaseRenewalEnabled: boolean,
  updateLeaseInterestStatus: (leaseInterestStatus: String) => {},
  moveInChecklist: boolean,
  featureFlag: Object,
  theme: Object,
  updateLeaseInterestStatus: (leaseInterestStatus: String) => {},
  moveOutChecklist: boolean,
  SubmitMimoResetCheckList: Function,
  submitedOptedOut: Function,
  isError: Object,
  checklistInfo: Object,
  property: Object,
  pmsId: string
}

const statuses = [
  { id: 'not_interested', name: 'Not interested' },
  { id: 'interested', name: 'Interested' },
  { id: 'renewed', name: 'Renewed' },
  { id: 'communication_sent', name: 'Contacted' },
  { id: 'not_applicable', name: 'Not Applicable' },
  { id: 'blocked', name: 'Blocked' },
]
const LeaseInfo = (props: Props) => {
  const updateLeaseInterestStatus = (leaseInterestStatus: String) => {
    props.updateLeaseInterestStatus(leaseInterestStatus)
  }
  const leaseInterestStatus = () => {
    const status = props.leaseInfo.leaseInterestStatus
    return find(statuses, o => o.id === status)
  }
  const {
    isFetching,
    hasData,
    leaseInfo,
    match,
    history,
    profile,
    profileComplete,
    getChecklistSummary,
    checkListStatus,
    checkListApprovalStatus,
    leaseRenewalEnabled,
    moveInChecklist,
    moveOutChecklist,
    featureFlag,
    theme,
    SubmitMimoResetCheckList,
    submitedOptedOut,
    checklistInfo,
    property,
    pmsId
  } = props

  const { user_id, units, resident_property_id } = profileComplete
  const { name } = property
  useEffect(() => {
    getChecklistSummary(user_id, units[0].id)
    // eslint-disable-next-line
  }, [])

  const submitOptedOut = async (residentCheckListId, user_id) => {
    await submitedOptedOut(residentCheckListId, user_id, units[0].id, pmsId)
    setShowModal(false)
  }

  const leaseRenewFlag = (featureFlag && featureFlag.ld && featureFlag.ld.variation(SMART_RENEW_LD_FLAG, false))
  const GoToChecklist = () => {
    history.push(
      `/properties/${match.params.propertyId}/residents/profile/${match.params.userId}/summary`
    )
  }
  const [showModal, setShowModal] = useState(false)
  const [showResetChecklistModal, setShowResetChecklistModal] = useState(false)

  if (isFetching) return <Loader />
  if (!hasData) return null

  const ResetChecklist = async () => {
    await SubmitMimoResetCheckList(user_id, resident_property_id, name, units[0].id, units[0].name)
    setShowResetChecklistModal(false)
  }

  return (
    <React.Fragment>
      <LeaseDetailsCard leaseInfo={leaseInfo} />
      <EmptySpace />
      <Flex direction="column" marginTop="3em">
        {(leaseRenewalEnabled && leaseRenewFlag) ? (
          <LeaseInfoContainer>
            <FlexDirection fullWidth>
              <FlexItem flex={2}>Lease Renewal</FlexItem>
              <FlexItem flexStart flex={4}>
                <DropDown
                  handleChange={updateLeaseInterestStatus}
                  selectedItem={leaseInterestStatus()}
                  data={statuses}
                  weight={theme.custom.font.weight.light}
                />
              </FlexItem>
              <FlexItem flex={2}></FlexItem>
              <FlexItem displayFlex flexEnd flex={1}>
                <Link href={`mailto:${profile.email}`}>Email</Link>
                &nbsp;|&nbsp;
                <Link
                  onClick={() =>
                    history.push(
                      `/properties/${match.params.propertyId}/communication/chat/live-chat/users/${match.params.userId}`
                    )
                  }>
                  Chat
                </Link>
              </FlexItem>
            </FlexDirection>
          </LeaseInfoContainer>
        ) : null}

        {moveInChecklist && <LeaseInfoContainer>
          <Flex fullWidth >
            {checkListApprovalStatus === 'Awaiting Approval' ? <RedDotStyled /> : null}
            <FlexItem flex={2.3}>Move-in Checklist Status</FlexItem>
            <FlexItem flexStart flex={4}>
              <GreyText>{checkListStatus}</GreyText>
            </FlexItem>
            <FlexItem flex={2}>{checkListApprovalStatus}</FlexItem>
            <FlexItem displayFlex flexEnd flex={2}>
              {checkListStatus === 'Completed'
                ? <Link onClick={() => GoToChecklist()}>View Checklist</Link>
                : null}
              {checkListStatus === 'In Progress' || checkListStatus === 'Not Started'
                ? <Link onClick={() => { setShowModal(true) }
                } >Opt-out of Checklist</Link>
                : null}
            </FlexItem>
          </Flex>
        </LeaseInfoContainer>}
        {moveOutChecklist && <LeaseInfoContainer>
          <FlexDirection fullWidth>
            <FlexItem flex={2}>Move-out Checklist Status</FlexItem>
            <FlexItem flexStart flex={4}>
              <GreyText>Not started</GreyText>
            </FlexItem>
            <FlexItem flex={2}></FlexItem>
            <FlexItem
              alignItemsRight
              flex={1}></FlexItem>
          </FlexDirection>
        </LeaseInfoContainer>}
        {moveInChecklist ? (
          <ResetLink>
            <Link onClick={() => setShowResetChecklistModal(true)} >Reset Checklist</Link>
            {checklistInfo && checklistInfo.resetChecklistDate ? (
              <ResetCheckListDateStyled>
                  Last Reset: {getDateFullYearAmPm(checklistInfo.resetChecklistDate)}
              </ResetCheckListDateStyled>) : null}
          </ResetLink>) : null}
      </Flex>
      {showResetChecklistModal &&
        <AlertModal
          id="LeavePageConfirm"
          title={<DialogMessage>Are you sure you want to reset this resident's move-in checklist?</DialogMessage>}
          maxWidth="md"
          show={showResetChecklistModal}
          messageContainerWidth='100%'
          footerText={
            <DialogFooterContainer>
              <ReportProblemOutlinedIcon style={warningIcon} />
              <DialogFooterMessage>
                By resetting this resident's move-in checklist any previous checklists will be deleted,
                and the resident will be notified they have a new move-in checklist to submit.
              </DialogFooterMessage>
            </DialogFooterContainer>}
          onClose={() => setShowResetChecklistModal(false)}
          buttons={[
            {
              onClick: () => setShowResetChecklistModal(false),
              text: "No",
              width: "135px",
              height: "35px"
            },
            {
              onClick: () => ResetChecklist(),
              text: "Yes",
              color: theme.palette.primary.navy.spaceBlue,
              width: "245px",
              height: "35px"
            }
          ]}
        />}
      {showModal &&
        <AlertModal
          id="LeavePageConfirm"
          title={<DialogMessage>Are you sure you want to opt this resident out of completing a checklist in the app?</DialogMessage>}
          maxWidth="md"
          show={showModal}
          messageContainerWidth='100%'
          footerText={
            <DialogFooterContainer>
              <ReportProblemOutlinedIcon style={warningIcon} />
              <DialogFooterMessage>
                By opting this resident out of completing a checklist through the Zego product,
                we will mark this checklist as completed and approved and no longer require the
                resident to submit a checklist.
              </DialogFooterMessage>
            </DialogFooterContainer>}
          onClose={() => setShowModal(false)}
          buttons={[
            {
              onClick: () => setShowModal(false),
              text: "No",
              width: "135px",
              height: "35px"
            },
            {
              onClick: () => submitOptedOut(checklistInfo && checklistInfo.id, user_id),
              text: "Yes",
              color: theme.palette.primary.navy.spaceBlue,
              width: "245px",
              height: "35px"
            }
          ]}
        />}
    </React.Fragment>
  )
}


export default withTheme(LeaseInfo)
