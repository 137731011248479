// @flow
import React from 'react'
import { FabButton } from './styles'

type Props = {
  component?: Function,
  text?: string
}

export default (props: Props) => (
  <FabButton {...props}>
    {props.component ? props.component() : props.text ? props.text : ''}
  </FabButton>
)
