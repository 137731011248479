import { createFetchActions } from '../../helpers/actions/creators'

export const RESIDENT_ENGAGEMENT = 'RESIDENTENGAGEMENT'

export const {
  fetchAction: fetchResidentEngagementAction,
  fetchStartAction: fetchResidentEngagementStart,
  fetchSuccessAction: fetchResidentEngagementSuccess,
  fetchErrorAction: fetchResidentEngagementError
} = createFetchActions(RESIDENT_ENGAGEMENT)

export const fetchPropertyResidentEngagement = propertyId =>
  fetchResidentEngagementAction({ propertyId })

export const fetchUnitResidentEngagement = unitId =>
  fetchResidentEngagementAction({ unitId })
