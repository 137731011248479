import React from 'react'
import { Route } from 'react-router-dom'
import PropertyUsersContainer from '../../views/UserManagement/PropertyUsers/PropertyUsersContainer'
import ResidentUsersContainer from '../../views/UserManagement/ResidentUsers/ResidentUsersContainer'

const UsersSummaryRoutes = () => (
  <React.Fragment>
    <Route
      exact
      path="/properties/:propertyId/user-management/property"
      component={PropertyUsersContainer}
    />
    <Route
      exact
      path="/properties/:propertyId/user-management"
      component={ResidentUsersContainer}
    />
    <Route
      exact
      path="/properties/:propertyId/user-management/resident"
      component={ResidentUsersContainer}
    />
  </React.Fragment>
)

export default UsersSummaryRoutes
