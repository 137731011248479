import styled from '@emotion/styled'
import MaterialButton from '@material-ui/core/Button'

export const Wrap = styled('div')`
  display: flex;
  justify-content: space-between;
  grid-column: ${({ columnStart, columnSpan }) => `${columnStart} / span
  ${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span
  ${rowSpan}`};
`

export const RightNav = styled('div')`
  display: flex;
  align-items: center;
  a {
    margin: 0em 1em;
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    color: ${({ theme }) => theme.palette.grey.main};
    white-space: nowrap;
  }
`

export const BackButtonStyled = styled(MaterialButton)`
  background-color: ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
  width: 3em;
  height: 3em;
  position: relative;
  top: 13px;
  box-shadow: none;

  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.secondary.yellow.solarFlare};
  }
`
