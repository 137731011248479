// @flow

import React from 'react'
import { GridItem } from '../Common/Grid'
import { WelcomeMsgGrid } from './styles'
import WelcomeResident from './WelcomeResident'
import { isResident } from '../../utils/roleUtils'

const Welcome = ({ userRole, firstName }: { userRole: string, firstName: string }) => {
  if (userRole == null) return null
  if (isResident(userRole)) return <WelcomeResident />
  return (
    <WelcomeMsgGrid alignStart justifyCenter>
      <GridItem columnStart={1} columnSpan={12} rowStart={5} rowSpan={2}>
        Hello {firstName}
      </GridItem>
    </WelcomeMsgGrid>
  )
}

export default Welcome
