// @flow
import React, { Fragment } from 'react'
import { Picture, OverLay, Wrapper, Circle } from './styles'

export type Props = {
  picture: string,
  size: string,
  hideShadow: boolean,
  overlayColor: string,
  overlayContent: () => void,
  showOverlay: boolean,
  alwaysShowOverlay: boolean,
  showTrail: boolean,
  position: string
}

const Avatar = ({
  picture,
  size,
  hideShadow,
  overlayColor,
  overlayContent,
  position,
  showOverlay = false,
  alwaysShowOverlay = false,
  showTrail = false
}: Props) => (
  <Wrapper
    position={position}
    size={size}
    alwaysShowOverlay={alwaysShowOverlay}>
    {showTrail && (
      <Fragment>
        <Circle size={size} index={2} />
        <Circle size={size} index={1} />
      </Fragment>
    )}
    {showOverlay && <OverLay size={size}>{overlayContent()}</OverLay>}
    <Picture
      showTrail={showTrail}
      src={picture}
      overlayColor={overlayColor}
      size={size}
      hideShadow={hideShadow}
    />
  </Wrapper>
)

export default Avatar
