import { connect } from 'react-redux'
import LockCodes from './LockCodes'
import {
  searchPropertyUsers,
  fetchUsersAction,
  searchResidents,
  fetchResidentUsersAction,
  clearSearchTerm
} from 'zego-shared/store/users/property/actions'
import {
  fetchLockCodesVendorsAction,
  searchVendors,
  clearVendorsSearchTerm
} from 'zego-shared/store/lockCodes/actions'
import { getUserRole } from 'zego-shared/store/authorization/selectors'

const mapStateToProps = (state, {
  match: {
    params: { propertyId }
  },
  location: { pathname }
}) => {
  const filter = pathname.split('/')[4] || 'vendors'
  return {
    filter,
    propertyId,
    userRole: getUserRole(state)
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { propertyId }
    }
  }
) => ({
  filterPropertyUsers: searchTerm => {
    dispatch(searchPropertyUsers(propertyId, searchTerm))
  },
  fetchPropertyUsers: () => {
    dispatch(fetchUsersAction(propertyId))
  },
  filterResidentUsers: searchTerm => {
    dispatch(searchResidents(propertyId, searchTerm))
  },
  fetchResidentUsers: () => {
    dispatch(fetchResidentUsersAction(propertyId))
  },
  filterVendors: searchTerm => {
    dispatch(searchVendors(propertyId, searchTerm))
  },
  fetchVendors: () => {
    dispatch(fetchLockCodesVendorsAction({ propertyId }))
  },
  clearSearchTerm: () => {
    dispatch(clearSearchTerm())
  },
  clearVendorsSearchTerm: () => {
    dispatch(clearVendorsSearchTerm())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LockCodes)
