import styled from '@emotion/styled'
import CircularProgress from '@material-ui/core/CircularProgress'

export const TitleText = styled('div')`
  text-align: center;
  margin-bottom: 0.5em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.grey.main};
`

export const ProgressText = styled('div')`
  position: absolute;
  margin: 0 auto;
  align-self: flex-end;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: ${({ size }) => `${size}px` || '40px'};
  width: ${({ size }) => `${size}px` || '40px'};
  justify-content: center;
  color: ${({ theme }) => theme.palette.grey.main};

  & > span:first-child {
    color: ${({ theme }) => theme.palette.common.black};
    font-size: ${({ theme, lgFont }) =>
      lgFont ? theme.custom.font.size.lg : theme.custom.font.size.xl36};
    font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  }

  & > p:last-child {
    color: ${({ theme }) => theme.palette.neutrals.deepGray};
    font-size: ${({ theme }) => theme.custom.font.size.xxs};
    margin: 0px;
    padding: 0px;
    font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  }
`

export const CenteredText = styled('div')`
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.xs};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
`

export const StyledCircularProgress = styled(CircularProgress, {
  shouldForwardProp: prop =>
    ['underlayColor', 'backgroundColor'].indexOf(prop) === -1
})`
  .circle-custom-backdrop {
    color: ${({ underlayColor }) => underlayColor && underlayColor};
    position: absolute !important;
  }

  ${({ onClick }) => onClick && 'cursor: pointer;'};
`
