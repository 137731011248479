// @flow

import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import { generate as shortid } from 'shortid'
import Carousel, { CarouselItem } from './Carousel'
import BaseSettingCard from './BaseSettingCard'
import FlexDirection from '@Common/Flex/FlexDirection'
import Tabs from '@Common/Cards/Tabs'
import type { Node } from 'react'
import type { Theme } from '../../theme'

type ToggleItemsDataType = {
  settings?: {},
  settingsTitles: {},
  title?: string,
  disabled?: boolean,
  footerNote?: Node,
  handleToggle: ({}) => mixed,
  match: { url: string },
  theme: Theme,
  propertyId: number,
  isSmartEnabled: boolean
}

class ToggleItemsCard extends React.Component<ToggleItemsDataType> {
  handleToggle = (
    { currentTarget: { id } }: SyntheticEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { handleToggle, disabled } = this.props
    !disabled && handleToggle({ [id]: checked })
  }

  getSettingItems = (): Array<Node> => {
    let { settings, settingsTitles, isSmartEnabled } = this.props
    // $FlowDisableNextLine
    settings = Object.entries(settings)
    const settingsCount = settings.length
    return settings.map(
      ([field, checked]: [string, mixed], index: number): any => {
        if (
          (field === 'smartResidentAccess' || field === 'vendorCodes') &&
          !isSmartEnabled
        )
          return null
        return (
          <CarouselItem
            key={shortid()}
            field={field}
            title={settingsTitles[field]}
            checked={checked}
            handleToggle={this.handleToggle}
            lastItem={index === settingsCount - 1}
          />
        )
      }
    )
  }

  render() {
    const {
      title,
      footerNote,
      propertyId,
      theme,
      match: { url }
    } = this.props

    const isMyAccountOrGeneralTab = url.includes('my-account')

    const settingItems = this.getSettingItems()
    return (
      <BaseSettingCard
        footerNote={footerNote}
        subheading={title}
        headerRight={
          isMyAccountOrGeneralTab ? (
            <Tabs
              customStyle={{
                position: 'absolute',
                top: 0,
                right: 0,
                fontSize: theme.custom.font.size.sm
              }}
              tabStyle={2}
              tabs={[
                {
                  name: 'My Info',
                  route: `/properties/${propertyId}/settings/my-account`,
                  query: { type: '' }
                },
                {
                  name: 'My Notifications',
                  route: `/properties/${propertyId}/settings/my-account`,
                  query: { type: 'notifications' }
                }
              ]}
            />
          ) : null
        }
        content={
          <FlexDirection direction="column" fullWidth>
            <Carousel
              items={settingItems}
              lastSlideNumber={Math.ceil(settingItems.length / 5)}
              arrowsRight
              numberOfItemsPerSlide={5}
            />
          </FlexDirection>
        }
      />
    )
  }
}

export default withRouter(withTheme(ToggleItemsCard))
