import React from 'react'
import { Route } from 'react-router-dom'
import EventsFeedContainer from '../../../views/Communication/CommunityFeed/EventsFeedContainer'
import PostsFeedContainer from '../../../views/Communication/CommunityFeed/PostsFeedContainer'

const CommunityFeedTabRoutes = () => (
  <React.Fragment>
    <Route
      exact
      path="/properties/:propertyId/communication/community-feed"
      component={PostsFeedContainer}
    />
    <Route
      exact
      path="/properties/:propertyId/communication/community-feed/posts"
      component={PostsFeedContainer}
    />
    <Route
      exact
      path="/properties/:propertyId/communication/community-feed/events"
      component={EventsFeedContainer}
    />
  </React.Fragment>
)

export default CommunityFeedTabRoutes
