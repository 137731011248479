import {
  createFetchActions,
  createFetchSuccessAction,
  createFetchNextActions
} from '../helpers/actions/creators'

export const EVENTSINITIAL = 'EVENTSINITIAL'
export const NEWEVENTS = 'NEWEVENTS'
export const UPCOMINGEVENTS = 'UPCOMINGEVENTS'
export const EVENTS = 'EVENTS'
export const UPDATE_EVENT_TIMESTAMP = 'UPDATE_EVENT_TIMESTAMP'
export const MONTHEVENTS = 'MONTHEVENTS'
export const CLEAR_EVENT_TIMESTAMP = 'CLEAR_EVENT_TIMESTAMP'
export const UPDATE_EVENT_RSVP = 'UPDATE_EVENT_RSVP'
export const UPDATE_EVENT_RSVP_WITH_USER = 'UPDATE_EVENT_RSVP_WITH_USER'
export const FILTER_EVENTS = 'FILTER_EVENTS'
export const FILTER_EVENTS_SUCCESS = 'FILTER_EVENTS_SUCCESS'
export const CANCEL_FILTER_EVENTS = 'CANCEL_FILTER_EVENTS'

export const {
  fetchAction: fetchEventsInitialAction,
  fetchStartAction: fetchEventsInitialStart,
  fetchSuccessAction: fetchEventsInitialSuccess
} = createFetchActions(EVENTSINITIAL)

export const fetchEventsInitial = (propertyId, start, end) =>
  fetchEventsInitialAction({ propertyId, start, end })

export const {
  fetchAction: fetchMonthEventsAction,
  fetchStartAction: fetchMonthEventsStart,
  fetchSuccessAction: fetchMonthEventsSuccess,
  fetchNextAction: fetchNextMonthEventsAction,
  fetchNextStartAction: fetchNextMonthEventsStart,
  fetchNextSuccessAction: fetchNextMonthEventsSuccess
} = createFetchNextActions(MONTHEVENTS)

export const {
  fetchAction: fetchUpcomingEventsAction,
  fetchStartAction: fetchUpcomingEventsStart,
  fetchSuccessAction: fetchUpcomingEventsSuccess
} = createFetchActions(UPCOMINGEVENTS)

export const fetchUpcomingEvents = () => fetchUpcomingEventsAction()

export const fetchMonthEvents = (propertyId, start, end) =>
  fetchMonthEventsAction({ propertyId, start, end })

export const fetchNextMonthEvents = (propertyId, start, end, page) =>
  fetchNextMonthEventsAction({ propertyId, start, end, page })

export const fetchNewEventsSuccess = createFetchSuccessAction(NEWEVENTS)

export const fetchEventsSuccess = createFetchSuccessAction(EVENTS)

export const updateEventTimestamp = end => ({
  type: UPDATE_EVENT_TIMESTAMP,
  endTimestamp: end
})

export const clearEventTimestamps = () => ({
  type: CLEAR_EVENT_TIMESTAMP
})

export const updateEventRSVP = id => ({
  type: UPDATE_EVENT_RSVP,
  id
})

export const updateEventRSVPWithUser = (id, user) => ({
  type: UPDATE_EVENT_RSVP_WITH_USER,
  id,
  user
})

export const filterEvents = (date, userId, threadType, sortBy, propertyId) => ({
  type: FILTER_EVENTS,
  date,
  userId,
  threadType,
  sortBy,
  propertyId
})

export const fetchFilteredEventsSuccess = events => ({
  type: FILTER_EVENTS_SUCCESS,
  events
})

export const cancelFilterEvents = () => ({
  type: CANCEL_FILTER_EVENTS,
  filtered: false
})
