import {
  createFetchActions,
  createUpdateActions
} from '../../helpers/actions/creators'

export const TEMPLATE = 'TEMPLATE'
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE'
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE'
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE'
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS'

export const {
  fetchAction: fetchTemplateAction,
  fetchStartAction: fetchTemplateStart,
  fetchSuccessAction: fetchTemplateSuccess,
  fetchErrorAction: fetchTemplateError
} = createFetchActions(TEMPLATE)

export const fetchTemplate = templateId => fetchTemplateAction({ templateId })

export const updateTemplateActions = createUpdateActions(TEMPLATE, true, true)

export const updateTemplate = update => ({
  type: UPDATE_TEMPLATE,
  update
})

export const createTemplate = (payload, callback) => ({
  type: CREATE_TEMPLATE,
  payload,
  callback
})

export const createTemplateSuccess = payload => ({
  type: CREATE_TEMPLATE_SUCCESS,
  payload
})

export const updateTemplateOptimistically = update =>
  updateTemplateActions.updateOptimistcallyAction({
    update
  })

export const updateTemplateSuccess = payload =>
  updateTemplateActions.updateSuccessAction(payload)

export const updateTemplateRevert = update =>
  updateTemplateActions.updateRevertAction({
    update
  })

export const deleteTemplate = (templateId, history) => ({
  type: DELETE_TEMPLATE,
  templateId,
  history
})
