import {
  PET,
  PETS,
  PETCOLORS,
  PETSIZES,
  PETSTATUSES,
  PETTYPES,
  updatePetActions
} from './actions'
import withPaginatedFetch from '../../helpers/reducers/withPaginatedFetch'
import withFetch from '../../helpers/reducers/withFetch'

const reducer = (state = null, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const pet = (state = null, action) => {
  switch (action.type) {
    case `${updatePetActions.updateSuccessAction().type}`:
    case `${updatePetActions.updateOptimistcallyAction().type}`:
    case `${updatePetActions.updateRevertAction().type}`:
      return { ...state, ...action.update, ...action.payload }
    default:
      return state
  }
}

export const petReducer = withFetch(PET)(pet)
export const petsColorsReducer = withFetch(PETCOLORS)(reducer)
export const petsSizesReducer = withFetch(PETSIZES)(reducer)
export const petsStatusesReducer = withFetch(PETSTATUSES)(reducer)
export const petsTypesReducer = withFetch(PETTYPES)(reducer)

export default withPaginatedFetch(PETS)(reducer)
