// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import UserProfileTable from '../UserProfileTable'
import Loader from '../../../Common/Loader'

type Props = {
  isFetching: boolean,
  hasData: boolean,
  total: number,
  visitors: Object[],
  match: { params: { propertyId: string } } 
}

export class Visitors extends React.Component<Props, {}> {
  getHeaders = (total: number) => {
    return [`${total} Visitors`, 'START DATE', 'END DATE', 'PHONE']
  }

  getKeys = () => {
    return ['name', 'startDate', 'endDate', 'phone']
  }

  render() {
    const { isFetching, visitors, total,  match: { params: { propertyId } } } = this.props

    if (isFetching) return <Loader />

    return (
      <React.Fragment>
        <UserProfileTable
          total={total}
          headers={this.getHeaders(total)}
          rowsPerPage={total}
          tableData={visitors}
          keys={this.getKeys()}
          visitorsTable={'true'}
          currentTab={'visitors'}
          propertyId={propertyId}
        />
      </React.Fragment>
    )
  }
}

export default withTheme(Visitors)
