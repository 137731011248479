// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import Pluralize from 'react-pluralize'
import InputAdornment from '@material-ui/core/InputAdornment'
import { generate as shortid } from 'shortid'
import filesize from 'filesize'
import debounce from 'lodash/debounce'
import isObject from 'lodash/isObject'
import { withTheme } from 'emotion-theming'
import ScrollToTop from '@Common/ScrollToTop'
import Tooltip from '../../../Common/Tooltip'
import FlexDirection, {
  FlexItem
} from '@Common/Flex/FlexDirection'
import Flex from '@Common/Flex'
import { CarouselUnit } from '@Common/CarouselList'
import Avatar from '@Common/Avatar'
import { SearchIcon, CommonIcon } from '@icons'
import AlertModal from '@Common/AlertModal/AlertModal'
import { hasChanges } from 'zego-shared/utils/object'
import { Attachment } from '../CreateGroupMessage/styles'
import EditModeHead from './EditModeHead'
import MessageEditor from './MessageEditor'
import TemplateHistory from './TemplateHistory'
import type { TemplateProps, TemplateState } from './types'
import EmailTextTabs from '../EmailTextTabs'
import Loader from '../../../Common/Loader'
import Header from '@Common/Header'
import { CHAT_METHOD_OPTION } from '../../constants'

import {
  Wrapper,
  HeadBackground,
  Name,
  MessageDetails,
  Body,
  Message,
  MessageBody,
  Underline,
  FabButton,
  ButtonSet,
  Break,
  TextField
} from './styles'

class Template extends React.Component<TemplateProps, TemplateState> {
  state = {
    showEditMode: this.props.isCreate ? true : false,
    showDialog: false,
    searchText: '',
    ...(this.props.data || {
      name: '',
      title: '',
      message: '',
      textMessage: '',
      resourceType: 'property',
      attachments: {},
      files: []
    }),
    exitWarningTitle: '',
    exitWarningMsg: '',
    onClickYes: null,
    showChatOption: false
  }

  componentDidMount() {
    this.props.ldInitRequest()
  }

  componentDidUpdate(prevProps, _prevState) {
    const { data, isCreate, featureFlag } = this.props
    const { data: prevData } = prevProps
    if (!isCreate) {
      if ((prevData == null && isObject(data)) || hasChanges(prevData, data)) {
        this.setState(oldState => ({ ...oldState, ...data }))
      }
    }
    const chatOptionFlag = (featureFlag && featureFlag.ld && featureFlag.ld.variation(CHAT_METHOD_OPTION, false))
    if(this.state.showChatOption !== chatOptionFlag) {
      this.setState({ showChatOption: chatOptionFlag })
    }
  }

  getHistoryItems = () => {
    const {
      historyData,
      match: { params }
    } = this.props
    return historyData.map(
      (
        {
          id,
          senderName,
          senderPicture,
          sendingMethods,
          recipientsCount,
          groups,
          date
        },
        index
      ) => (
        <CarouselUnit
          className="recipient-row"
          height={'46px'}
          lastUnit={index === historyData.length - 1}>
          <FlexItem flex={0.5}>
            <Avatar picture={senderPicture} size="2.5em" />
          </FlexItem>
          <FlexItem flex={2}>{senderName}</FlexItem>
          <FlexItem flex={1}>{sendingMethods.join(', ')}</FlexItem>
          <FlexItem flex={1}>{recipientsCount}</FlexItem>
          <FlexItem flex={2}>{groups.join(', ')}</FlexItem>
          <FlexItem flex={1}>{date}</FlexItem>
          <FlexItem flex={1.5} displayFlex flexEnd>
            <Link
              to={`/properties/${
                params.propertyId
              }/communication/group-messaging/sent/${id}`}>
              View Message
            </Link>
          </FlexItem>
        </CarouselUnit>
      )
    )
  }

  handlePermissionChange = event => {
    this.setState({ resourceType: event.target.value })
  }

  editTemplate = () => {
    this.setState({ showEditMode: true })
  }

  showAlertModal = () => {
    this.setState({
      ...this.state,
      showDialog: true,
      exitWarningTitle: 'Are you sure you want to exit?',
      exitWarningMsg: 'you have unsaved changes',
      onClickYes: this.goBack
    })
  }

  goBack = () => {
    const { showEditMode } = this.state
    const {
      data,
      history,
      match: { url },
      isCreate
    } = this.props

    isCreate &&
      history.push(
        `${url.split('/group-messaging')[0]}/group-messaging/templates`
      )

    showEditMode
      ? this.setState(oldState => ({
          ...data,
          files: null,
          showEditMode: false,
          showDialog: false,
          exitWarningTitle: '',
          exitWarningMsg: '',
          onClickYes: null
        }))
      : history.push(
          `${url.split('/group-messaging')[0]}/group-messaging/templates`
        )
  }

  saveTemplate = async () => {
    const {
      title,
      name,
      message,
      textMessage,
      attachments,
      files,
      resourceType
    } = this.state
    const {
      updateTemplate,
      isCreate,
      createTemplate,
      history,
      data,
      match: { url }
    } = this.props
    let payload = {
      title,
      name,
      text_body: textMessage,
      body: message,
      attachments
    }

    if (!data || resourceType !== data.resourceType) {
      payload = { resourceType, ...payload }
    }

    if (files != null && files.length > 0) {
      payload = { ...payload, files }
    }
    if (isCreate) {
      createTemplate(payload, () =>
        history.push(
          `${url.split('/group-messaging')[0]}/group-messaging/templates`
        )
      )
    } else {
      await updateTemplate(payload)
      this.setState({ showEditMode: false })
    }
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value
    })
  }

  handleEmailMessageChange = value => {
    this.setState({
      message: value
    })
  }

  handleMergeFieldChange = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  removeAttachment = (attachment: string) => {
    const { attachments } = this.state
    delete attachments[attachment]
    this.setState(oldState => ({
      ...oldState,
      attachments: attachments
    }))
  }

  removeFile = debounce((fileToRemove: any) => {
    this.setState(oldState => ({
      ...oldState,
      files: this.state.files.filter(file => file.name !== fileToRemove.name)
    }))
  }, 200)

  addAttachment = debounce(file => {
    this.setState(oldState => ({
      ...oldState,
      files: [file, ...(oldState.files || [])]
    }))
  }, 200)

  deleteTemplate = () => {
    const { deleteTemplate } = this.props
    deleteTemplate()
    this.closeAlertModal()
  }

  closeAlertModal = () => {
    this.setState({ showDialog: false })
  }

  openDialog = () => {
    this.setState({ showDialog: true })
  }

  searchHistory = debounce(() => {
    const { searchHistory } = this.props
    const { searchText } = this.state
    searchHistory(searchText)
  }, 400)

  handleSearchChange = ({
    currentTarget: { value }
  }: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ searchText: value })
    this.searchHistory()
  }

  handleKeyPress = ({ key }: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      this.searchHistory()
    }
  }

  render() {
    const {
      isFetching,
      hasData,
      theme,
      replacementOptions,
      totalHistoryCount,
      canChange,
      permissions,
      isCreate,
      showTextMessage,
      userRole,
      propertyId,
      history,
      showSnackbar
    } = this.props

    if (userRole === "property_staff") {
      history.replace(`/properties/${propertyId}/communication/group-messaging`)
      setTimeout(() => {
        showSnackbar("Group Messaging Templates are not available for Property Staff.")
      }, 1000)
  
      return null
    }

    if (!isCreate && (isFetching || !hasData)) {
      return <Loader />
    }

    const {
      showEditMode,
      exitWarningMsg,
      exitWarningTitle,
      onClickYes,
      showDialog,
      name,
      title,
      message,
      resourceType,
      attachments,
      files,
      textMessage,
      footNote,
      isDefault
    } = this.state

    const historyItems = this.getHistoryItems()
    const canDelete= (canChange && !isCreate && !isDefault)
    const attachmentsCount =
      Object.keys(attachments).length + (files || []).length
    return (
      <React.Fragment>
        <Wrapper>
          <FlexDirection
            direction="column"
            fullHeight
            fullWidth
            className="wrapper-flex">
            <FlexItem>
              <HeadBackground editMode={showEditMode}>
                <div>
                  {showEditMode ? (
                    <EditModeHead
                      theme={theme}
                      name={name}
                      resourceType={resourceType}
                      handleChange={this.handleChange}
                      permissions={permissions}
                      isCreate={isCreate}
                      selectedPermission={resourceType}
                      handlePermissionChange={this.handlePermissionChange}
                      isDefault = {isDefault}
                    />
                  ) : (
                    <div>
                      <Name>{name}</Name>
                      <Flex>
                        <Header blackText>
                          <span>{title}</span>
                        </Header>
                        <Flex marginTop="15px">
                          <Tooltip type="WordCount" />
                        </Flex>
                      </Flex>
                      <MessageDetails>
                        <span>{resourceType} template</span>
                      </MessageDetails>
                    </div>
                  )}
                  <ButtonSet>
                    <FabButton
                      color="primary"
                      onClick={
                        showEditMode ? this.showAlertModal : this.goBack
                      }>
                      ←
                    </FabButton>
                    {showEditMode ? (
                      <FabButton
                        outlined
                        onClick={this.saveTemplate}>
                        <CommonIcon
                          name="tick"
                          width="1em"
                          height="1em"
                          fill={'none'}
                          strokeWidth={2}
                          stroke={theme.palette.primary.navy.spaceBlue}
                        />
                      </FabButton>
                    ) : canChange ? (
                      <FabButton
                        outlined
                        onClick={this.editTemplate}>
                        <CommonIcon
                          name="edit"
                          width="0.7em"
                          height="0.7em"
                          stroke={theme.palette.primary.navy.spaceBlue}
                        />
                      </FabButton>
                    ) : null}
                    { canDelete ? (
                      <FabButton
                        outlined
                        onClick={this.openDialog}>
                        <CommonIcon
                          name="close"
                          width="0.5em"
                          height="0.5em"
                          strokeWidth={5}
                          stroke={theme.palette.primary.navy.spaceBlue}
                        />
                      </FabButton>
                    ): null}
                  </ButtonSet>
                </div>
              </HeadBackground>
            </FlexItem>
            <Body>
              <div style={{ marginBottom: '2em' }}>
                {!showEditMode && <EmailTextTabs />}
              </div>
              <FlexItem flex={1}>
                <div>
                  <Message>
                    {showEditMode ? (
                      <MessageEditor
                        message={{ message, textMessage }}
                        subjectLineText={title}
                        replacementOptions={replacementOptions}
                        attachments={attachments}
                        files={files}
                        addAttachment={this.addAttachment}
                        removeAttachment={this.removeAttachment}
                        removeFile={this.removeFile}
                        handleChange={this.handleChange}
                        handleEmailMessageChange={this.handleEmailMessageChange}
                        handleMergeFieldChange={this.handleMergeFieldChange}
                        showChatOption={this.state.showChatOption}
                        footNote = {footNote}
                        isDefault = {isDefault}
                      />
                    ) : showTextMessage ? (
                      <MessageBody>{textMessage}</MessageBody>
                    ) : (
                      <MessageBody
                        dangerouslySetInnerHTML={{
                          __html: message
                        }}
                      />
                    )}
                  </Message>
                </div>
              </FlexItem>
              <Break />
              {!showEditMode && attachmentsCount > 0 && (
                <Attachment>
                  <span>
                    <Pluralize
                      singular={'attachment'}
                      plural={'attachments'}
                      count={attachmentsCount}
                    />
                  </span>
                  {attachments &&
                    Object.keys(attachments).map(attachment => (
                      <span key={shortid()}>{attachment}</span>
                    ))}
                  {files &&
                    files.map(file => (
                      <span key={shortid()}>
                        {file.name} {filesize(file.size)}
                      </span>
                    ))}
                </Attachment>
              )}

              <Break />
              {!showEditMode && (
                <React.Fragment>
                  <Underline />
                  <Break />
                  <FlexItem flex={1}>
                    <CarouselUnit
                      className="row-head"
                      height={'46px'}
                      lastUnit={true}>
                      <FlexItem flex={2.5}>
                        <Pluralize
                          singular={'Time Used'}
                          plural={'Times Used'}
                          count={totalHistoryCount}
                        />
                      </FlexItem>
                      <FlexItem flex={1}>Method </FlexItem>
                      <FlexItem flex={1}>Count</FlexItem>
                      <FlexItem flex={2}>Recipients</FlexItem>
                      <FlexItem flex={1}>Date</FlexItem>
                      <FlexItem flex={1.5} displayFlex flexEnd>
                        <TextField
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon
                                  name="search"
                                  height="20px"
                                  width="18px"
                                  className="searchIcon"
                                />
                              </InputAdornment>
                            )
                          }}
                          onChange={this.handleSearchChange}
                          onKeyPress={this.handleKeyPress}
                        />
                      </FlexItem>
                    </CarouselUnit>
                    <TemplateHistory
                      lastSlideNumber={Math.ceil(historyItems.length / 7)}
                      transitionHeights={['0em', '2em']}
                      arrowsRight
                      items={historyItems}
                      numberOfItemsPerSlide={7}
                      itemHeight={46}
                    />
                  </FlexItem>
                </React.Fragment>
              )}
            </Body>
          </FlexDirection>
        </Wrapper>
        <AlertModal
          title={exitWarningTitle || 'Are you sure you wish to delete?'}
          message={exitWarningMsg || ''}
          buttons={[
            {
              onClick: onClickYes || this.deleteTemplate,
              text: 'YES'
            },
            {
              onClick: this.closeAlertModal,
              color: theme.palette.primary.navy.spaceBlue,
              text: 'NO'
            }
          ]}
          show={showDialog}
          onClose={this.closeAlertModal}
        />
        <ScrollToTop />
      </React.Fragment>
    )
  }
}

export default withTheme(Template)
