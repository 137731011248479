// @flow
export const convertHexToRGB = (hexColor: string, opacity: number = 1) => {
  const hexCode = hexColor.replace('#', '')
  return `rgba(${parseInt(hexCode.substring(0, 2), 16)}, ${parseInt(
    hexCode.substring(2, 4),
    16
  )}, ${parseInt(hexCode.substring(4, 6), 16)}, ${opacity})`
}

export const isFunction = (func: *) =>
  func && {}.toString.call(func) === '[object Function]'

export const removeEmptyArrayElements = (elements: Array<any>): Array<any> => {
  return elements.filter(element => element)
}
