import { TEMPLATE_PERMISSION } from './actions'
import withFetch from '../../helpers/reducers/withFetch'

const templatePermission = (state = null, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withFetch(TEMPLATE_PERMISSION)(templatePermission)
