// @flow
import React from 'react'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import { Link, withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import ExpansionPanels from '../../Common/ExpansionPanels'
import ReSyncButton from '../../Common/ReSyncButton'
import { DefaultBackButton } from '@Common/Button' 
import PanelUnit from './PanelHeading'
import PanelDetails from './PanelDetails'
import { LockCodesProps as Props, CodesType } from './types'
import { capitalizeFirstLetter } from '../../../utils'
import { Wrapper, CustomFlexDirection, LockCodesHeader } from './styles'

class LockCodes extends React.Component<Props> {
  generatePanelsForLockCodes = () => {
    const {
      lockCodes,
      addLockCode,
      deleteLockCode,
      editLockCode,
      identifier,
      statuses,
      showSnackbar,
      resyncLockCodes
    } = this.props
    const panels = []
    // $FlowDisableNextLine
    Object.entries(lockCodes).forEach(([key, value]: [string, CodesType]) => {
      panels.push({
        heading: (
          <PanelUnit
            count={value.codes.length}
            codeType={capitalizeFirstLetter(key)}
          />
        ),
        details: (
          <PanelDetails
            codeType={value.type}
            showSnackbar={showSnackbar}
            codes={value.codes}
            canCreate={true}
            canDelete={true}
            canUpdate={true}
            addLockCode={addLockCode}
            deleteLockCode={deleteLockCode}
            editLockCode={editLockCode}
            identifier={identifier}
            statuses={statuses}
            updateExpiryMode={this.updateExpiryMode}
            resyncLockCodes={resyncLockCodes}
          />
        )
      })
    })
    return panels
  }

  updateExpiryMode = (codeID, expiration) => {
    const { identifier, updateExpiryMode } = this.props
    updateExpiryMode(codeID, identifier, expiration)
  }

  render() {
    const { theme, location, resyncLockCodes, isResyncing } = this.props
    return (
      <Modal open={true}>
        <Wrapper>
          <Link
            style={{ color: theme.palette.grey.main }}
            to={location.pathname
              .split('/')
              .slice(0, -1)
              .join('/')}>
            <DefaultBackButton />
          </Link>
          <CustomFlexDirection direction="column" fullHeight>
            <LockCodesHeader justifySpaceBetween alignCenter>
              <Typography component="h2" variant="headline">
                LOCK CODES
              </Typography>
              <ReSyncButton
                className="lock-code-resync"
                onClick={resyncLockCodes}
                FabProps={{ disabled: isResyncing }}
                hoverText="Re-Sync Lock Codes"
              />
            </LockCodesHeader>

            <ExpansionPanels panels={this.generatePanelsForLockCodes()} />
          </CustomFlexDirection>
        </Wrapper>
      </Modal>
    )
  }
}

export default withRouter(withTheme(LockCodes))
