import {
  createFetchActions,
  createRefreshActions,
  createFetchAndRefreshActions
} from '../../../helpers/actions/creators'

export const USERSSECTION = 'PROPERTY_USERS_SUMMARY'

export const {
  fetchAction: fetchPropertyUsersSummaryAction,
  fetchStartAction: fetchPropertyUsersSummaryStart,
  fetchSuccessAction: fetchPropertyUsersSummarySuccess,
  fetchErrorAction: fetchPropertyUsersSummaryError,
  refreshAction: refreshPropertyUsersSummary,
  refreshStartAction: refreshPropertyUsersSummaryStart,
  refreshSuccessAction: refreshPropertyUsersSummarySuccess,
  refreshErrorAction: refreshPropertyUsersSummaryError
} = createFetchAndRefreshActions(USERSSECTION)

export const fetchPropertyUsersSummary = id => fetchPropertyUsersSummaryAction({ id }) 
