import styled from '@emotion/styled'

export const ModesWrap = styled('div')`
  width: 100%;
`

export const Mode = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  svg {
    width: 4.5em;
    height: 4.5em;
    margin: 0 auto;
    margin-bottom: 1em;
    &:hover {
      cursor: pointer;
    }
  }

  span {
    text-transform: capitalize;
    &:hover {
      cursor: pointer;
    }
  }

  span.selected {
    color: ${({ theme }) => theme.palette.primary.green.mint};
    text-transform: uppercase;
  }
`

export const modeStyle = theme => ({
  fontWeight: theme.custom.font.weight.regular,
  fontSize: theme.custom.font.size.mdlg,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
})
