// @flow

import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { RadiobuttonIcon } from '@icons'
import { GridContainer, FormControl } from './styles'

type PetColorRadioGroupProps = {
  petColors: Array<Object>,
  selectedColors: Array<Object>,
  handleColorChange: (Object, boolean) => mixed
}

const PetColorRadioGroup = ({
  petColors,
  selectedColors,
  handleColorChange
}: PetColorRadioGroupProps) => {
  return (
    <FormControl classes={{ root: 'checkBoxGroupFieldset' }}>
      <GridContainer>
        {petColors.map(({ id, name }, index) => (
          <FormControlLabel
            key={index}
            classes={{ root: 'checkBoxGroupFormControl' }}
            control={
              <Checkbox
                checked={selectedColors.includes(id)}
                onChange={handleColorChange}
                name={id}
                checkedIcon={
                  <RadiobuttonIcon
                    width={'1.5em'}
                    height={'1.1em'}
                    name="selected"
                  />
                }
                icon={
                  <RadiobuttonIcon
                    width={'1.5em'}
                    height={'1.1em'}
                    name="unselected"
                  />
                }
              />
            }
            label={name}
          />
        ))}
      </GridContainer>
    </FormControl>
  )
}

export default PetColorRadioGroup
