// @flow

import React, { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import { withRouter } from 'react-router-dom'
import type { Match } from 'react-router-dom'
import { BrowserHistory } from 'history'
import { withTheme } from 'emotion-theming'
import { IconButton } from '@material-ui/core'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import CloseIcon from '@material-ui/icons/Close'
import { CommonIcon } from '@icons'
import type { Theme } from '../../../theme'
import Flex from '@Common/Flex'
import TextField from '@Common/TextField'
import AlertModal from '@Common/AlertModal/AlertModal'
import { GridItem } from '@Common/Grid'
import Loader from '../../Common/Loader'
import FabButton from '@Common/Button/FabButton'
import { isValidImageFile } from '../../../utils/validationUtils'
import {
  StyledCard,
  DropBox,
  CardHeader,
  DropboxCaption,
  UploadedPhoto,
  BackButton,
  AlertModalClass
} from './styles'

type Props = {
  theme: Theme,
  history: BrowserHistory,
  match: Match,
  marketplaceDeal: Object,
  isFetching: Boolean,
  createMarketplaceDeal: ?Function,
  updateMarketplaceDeal: ?Function
}

const MarketplaceNewDeal = (props: Props) => {
  const {
    marketplaceDeal,
    isFetching,
    history,
    theme,
    match: { url }
  } = props

  const [photo, setPhoto] = useState()
  const [fileToUpload, setFileToUpload] = useState()
  const [companyName, setCompanyName] = useState("")
  const [nameError, setNameError] = useState(false)
  const [discountError, setDiscountError] = useState(false)
  const [discount, setDiscount] = useState("")
  const [description, setDescription] = useState("")
  const [category, setCategory] = useState("")
  const [link, setLink] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [valid, setValid] = useState(false)
  const [isEditable, setIsEditable] = useState(true)
  const [edited, setEdited] = useState(false)
  // eslint-disable-next-line
  const [type, setType] = useState('service')

  useEffect(() => {
    let isValid = true

    if (companyName || discount || category || link || description || photo) {
      setEdited(true)
    }

    if (!companyName || !discount || !category || !link) {
      isValid = false
    }

    if (companyName && companyName.length > 50) {
      isValid = false
      setNameError(true)
    } else {
      setNameError(false)
    }

    if (discount && discount.length > 18) {
      isValid = false
      setDiscountError(true)
    } else {
      setDiscountError(false)
    }

    setValid(isValid)
    // eslint-disable-next-line
  }, [companyName, discount, category, link])

  useEffect(() => {
    if (marketplaceDeal) {    
      const { 
        discount: mp_discount, 
        image: mp_image,
        description: mp_description, 
        url: mp_url, 
        category: mp_category, 
        companyName: mp_companyName 
      } = marketplaceDeal
      
      setPhoto(mp_image || "")
      setCompanyName(mp_companyName || "")
      setDiscount(mp_discount || "") 
      setDescription(mp_description || "")
      setCategory(mp_category || "")
      setLink(mp_url || "")

      setIsEditable(false)
    }
    // eslint-disable-next-line
  }, [marketplaceDeal])

  if (isFetching) {
    return <Loader /> 
  }

  const onDrop = async (acceptedFiles: Array<Object>) => {
    const file = acceptedFiles[0]

    if (file && isValidImageFile(file)) {
      setPhoto(URL.createObjectURL(file))
      setFileToUpload(file)
    }
  }

  const goBack = () => {
    let splitText = '/marketplace'
    history.push(`${url.split(splitText)[0]}${splitText}/overview`)
  }

  const submit = () => {
    const { createMarketplaceDeal, updateMarketplaceDeal } = props
    const id = url.split("/").pop()

    const deal = {
      title: companyName,
      category,
      redirect_url: link,
      discount_description: discount,
      description: description
    }

    if (valid && createMarketplaceDeal) {
      createMarketplaceDeal(deal, fileToUpload, type)
    }
    else if (valid && updateMarketplaceDeal && id !== "new") {
      updateMarketplaceDeal({ ...deal, id }, fileToUpload, type)
    }
  }

  const renderSmallTextField = (value: string, setValue: Function, label: string, error = false) => {
    const errorText = `Maximum character count is ${label === "Discount" ? 18 : 50}`

    return (
      <TextField
        style={{ width: '47%' }}
        showPencil={true}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        label={label}
        editMode={true}
        textAlign="left"
        alwaysShowLabel={true}
        disabled={!isEditable}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          readOnly: !isEditable,
        }}
        error={error}
        helperText={error && errorText}
      />
    )
  }

  const deleteImage = (e) => {
    e.stopPropagation()
    setPhoto()
  }

  const submitBtn = () => {
    return (
      <FabButton
        id="submitButton"
        onClick={submit}
        style={{ marginTop: '15px' }}
        background={
          valid
            ? theme.palette.common.white
            : theme.palette.neutrals.deepGray
        }
        outlined={valid}
        disabled={!valid}
        component={() => (
          <CommonIcon
            name="tick"
            width="1.5em"
            height="1.5em"
            fill="none"
            strokeWidth={2.5}
            stroke={theme.palette.primary.dark}
          />
        )}
        size="2.7em"
      />
    )
  }

  const editBtn = () => {
    return (
      <FabButton
        id="editButton"
        onClick={() =>setIsEditable(true)}
        style={{ marginTop: '15px' }}
        background={theme.palette.common.white}
        outlined={true}
        disabled={false}
        component={() => (
          <CommonIcon
            name="edit"
            width="1.5em"
            height="1.5em"
            fill="none"
            strokeWidth={2.5}
            stroke={theme.palette.primary.dark}
          />
        )}
        size="2.7em"
      />
    )
  }

  const onBack = () => {
    if (!edited) {
      goBack()
    } else {
      setShowModal(true)
    }
  }

  return (
    <>
      <GridItem columnStart={1} columnSpan={1} rowStart={1} rowSpan={1}>
        <Flex direction="column" flex='1 0 auto'>
          <StyledCard>
            <CardHeader>
              {companyName || 'New Discount'}
              </CardHeader>
            { !isEditable 
            ? <UploadedPhoto style={{ marginTop: '10px' }} photoUrl={photo} /> 
            : <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps }) => (
                <DropBox {...getRootProps()} >
                  <input {...getInputProps()} />
                  {photo ?
                    <UploadedPhoto photoUrl={photo}>
                      <IconButton style={{ bottom: '85px', left: '140px' }} onClick={(event) => deleteImage(event)}>
                        <CloseIcon style={{ color: theme.palette.common.black }} />
                      </IconButton>
                    </UploadedPhoto>
                    :
                    <DropboxCaption>
                      Drag company logo/image files here or click to select files
                    </DropboxCaption>
                  }
                </DropBox>
              )}
            </Dropzone>}
          </StyledCard>
        </Flex>
      </GridItem>
      <GridItem columnStart={2} columnSpan={10} rowStart={1} rowSpan={10}>
        <Flex direction="column" justifySpaceBetween style={{ height: '35%' }}>
          <Flex direction="row" justifySpaceBetween marginBottom=".8em" marginTop="1.5em">
            {renderSmallTextField(companyName, setCompanyName, "Company Name", nameError)}
            {renderSmallTextField(discount, setDiscount, "Discount", discountError)}
          </Flex>
          <TextField
            showPencil={true}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            label="Description of Discount (Optional)"
            editMode={marketplaceDeal === null}
            textAlign="left"
            alwaysShowLabel={true}
            fullWidth
            disabled={!isEditable}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              readOnly: !isEditable,
            }}
          />
          <Flex direction="row" justifySpaceBetween fullWidth marginBottom=".5em">
            {renderSmallTextField(category, setCategory, "Category")}
            {renderSmallTextField(link, setLink, "Link/URL")}
          </Flex>
        </Flex>
      </GridItem>
      <Flex direction="column" marginTop="2em">
        <BackButton
          id="backButton"
          onClick={onBack}
          background={theme.palette.secondary.yellow.solarFlare}
          component={() => (
            <KeyboardBackspace height="0.5em" width="0.5em" />
          )}
        />
        {!isEditable ? editBtn() : submitBtn()}
      </Flex>
      {showModal &&
        <AlertModal
          id='LeavePageConfirm'
          title='Are you sure you want to leave this page?'
          message='By leaving this page you will lose all changes that have been made'
          buttons={[
            {
              onClick: () => setShowModal(false),
              text: 'No, go back'
            },
            {
              onClick: goBack,
              text: 'Yes, leave page',
              color: theme.palette.primary.navy.spaceBlue
            }
          ]}
          show={showModal}
          onClose={() => setShowModal(false)}
          classes={{
            root: AlertModalClass
          }}
        />
      }
    </>
  )
}

export default withRouter(withTheme(MarketplaceNewDeal))
