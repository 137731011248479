// @flow
import React from 'react'
import ListItemText from '@material-ui/core/ListItemText'
import InfoCard from '../../../Common/InfoCard'
import { CommonIcon } from '@icons'
import { ListItem, EmptySpace } from '../styles'
import QuickLinks from '../../../QuickLinks'
import Flex from '@Common/Flex'
import type { UnitCardInfo } from '../../../../store/Select/types'

export type Props = {
  data: UnitCardInfo
}

const UnitInfoCard = ({ data }: Props) => (
  <InfoCard
    infoHead="ZEGO SMART"
    title={data ? `UNIT ${data.name || ''}` : null}
    headerRight={<QuickLinks selected="zego smart" />}
    content={() => {
      return (
        data && (
          <Flex direction="column">
            <EmptySpace />
            <ListItem>
              <ListItemText primary={data.status} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  data.activatedDate ? `Activated ${data.activatedDate}` : null
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <div>
                    {data.type}
                    <CommonIcon
                      name="bullet"
                      width="5px"
                      height="5px"
                      style={{
                        alignSelf: 'center',
                        margin: 'auto 10px',
                        marginBottom: '4px'
                      }}
                    />
                    {data.occupied}
                  </div>
                }
              />
            </ListItem>
          </Flex>
        )
      )
    }}
  />
)

export default UnitInfoCard
