import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

export const StatArea = styled('div')`
  width: 80%;
  margin-left: -25px;
  -webkit-display: flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.secondary.light3};
  padding: 4em 2em;
  *:first-child {
    @media only screen and (min-width: 2000px) {
      font-size: 180%;
    }
    @media only screen and (min-width: 3000px) {
      font-size: 310%;
    }
  }
`

export const CaptionHeading = styled(Typography)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.grey.main};
  margin-top: 2em;
`
export const MainHeading = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
`

export const Count = styled('span')`
  color: ${({ theme }) => theme.palette.primary.green.mint};
`

export const RightPanel = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  -webkit-display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  & div > span {
    margin: 0em 0.5em;
    -webkit-align-self: center;
    align-self: center;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  & div > span:last-child {
    display: flex;
    -webkit-display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
`

export const StatsOverlay = styled('div')`
  position: absolute;
  ${({ imageSrc }) => imageSrc && `background-image: url(${imageSrc})`};
  min-width: 295px;
  left: -90px;
  z-index: -50;
  height: 365px;
  top: 120px;
`

export const StyledCount = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.black1};
  height: 72px;
  line-height: 66px;
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  font-size: ${({ theme }) => theme.custom.font.size.xxxxl};
`

export const StyledCountBody = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  font-size: ${({ theme }) => theme.custom.font.size.md};
`
