// @flow
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import type { RouterHistory } from 'react-router-dom'
import Link from 'react-router-dom/Link'
import { withTheme } from 'emotion-theming'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import AddIcon from '@material-ui/icons/Add'
import ScrollToTop from '@Common/ScrollToTop'
import { InputAdornment } from '@material-ui/core'
import Tabs from '@Common/Cards/Tabs'
import Avatar from '@Common/Avatar'
import Header from '@Common/Header'
import {
  CommunityFeedIcons,
  SearchIcon,
  CommonIcon
} from '@icons'
import { ListItem } from '@material-ui/core'
import { ListItemText } from '@material-ui/core'
import moment, { Moment } from 'moment'
import Calendar from '@Common/Calendar'
import CommunityFeedTabRoutes from '../../../routes/Communication/CommunityFeed/CommunityFeedTabRoutes'
import { getFilteredDate } from '../../../utils/dateUtils'
import {
  HeaderTitleWrapper,
  TabsWrapper,
  ButtonsWrapper,
  AddButtonStyled,
  PostsEventsFeedWrapper,
  FilterButtonStyled,
  FilterWrapper,
  UsersList,
  List,
  TextField,
  SearchWrapper,
  CalendarWrapper,
  CalendarActionButtons,
  CancelDateSelection,
  ConfirmDateSelection,
  DisplaySearchCriteria,
  UserFilterWrapper,
  FilterTag
} from './styles'
import { BackButtonStyled } from '../styles'

type Props = {
  match: {
    params: { propertyId: string, type: ?string },
    url: string
  },
  history: RouterHistory,
  theme: any,
  type: string,
  postAuthors: Array<any>,
  filterEvents: (unixDate: string, authorId: string, sortKey: string) => {},
  filterPosts: (unixDate: string, authorId: string, sortKey: string) => {},
  fetchPosts: () => {},
  cancelFilterEvents: () => {}
}

type State = {
  authorId: string,
  selectedUser: string,
  selectedDate: Moment,
  moment: Moment,
  postAuthors: Array<any>,
  searchTerm: string,
  userFilter: boolean,
  dateFilter: boolean,
  showCalendar: boolean,
  showUserFilter: boolean,
  unixDate: string,
  sortBy: boolean,
  sortKey: string
}

class CommunityFeedLayout extends Component<Props, State> {
  state = {
    moment: moment(),
    authorId: '',
    selectedUser: '',
    selectedDate: moment(),
    postAuthors: this.props.postAuthors,
    searchTerm: '',
    userFilter: false,
    dateFilter: false,
    showCalendar: false,
    showUserFilter: false,
    unixDate: '',
    sortBy: false,
    sortKey: ''
  }

  wrapperRef: Element

  setWrapperRef = (node: Element | null) => {
    // $FlowDisableNextLine
    this.wrapperRef = node
  }

  handleOutsideClick = (event: MouseEvent) => {
    if (
      // $FlowDisableNextLine
      this.wrapperRef &&
      // $FlowDisableNextLine
      !ReactDOM.findDOMNode(this.wrapperRef).contains(
        ((event.target: any): Node)
      )
    ) {
      this.setState({ showUserFilter: false })
      document.removeEventListener('click', this.handleOutsideClick, false)
    }
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.type !== this.props.type) {
      this.setState({
        moment: moment(),
        dateFilter: false,
        unixDate: '',
        showCalendar: false,
        showUserFilter: false,
        searchTerm: '',
        userFilter: false,
        authorId: '',
        sortBy: false
      })
    }
  }

  getPostAuthors = () => {
    const authors = this.state.postAuthors || this.props.postAuthors
    return authors
      ? authors.map(author => (
          <List
            key={author.userId}
            onClick={() => this.onSelectUser(author.userId, author.userName)}>
            <ListItem>
              <Avatar picture={author.userPicture} size={'1.8em'} />{' '}
              <ListItemText primary={author.userName} />
            </ListItem>
          </List>
        ))
      : ''
  }

  handleCalendarChange = (selectedDate: Moment) => {
    this.setState({
      selectedDate,
      unixDate: moment(selectedDate).unix(),
      moment: selectedDate
    })
  }

  onSelectUser = (authorId, userName) => {
    this.setState(
      {
        authorId: authorId,
        selectedUser: userName,
        userFilter: true,
        showUserFilter: false
      },
      () => {
        this.getFilteredThread()
      }
    )
    document.removeEventListener('click', this.handleOutsideClick, false)
  }

  onCalenderClickOK = () => {
    this.setState({
      dateFilter: true,
      showCalendar: false
    })

    this.getFilteredThread()
  }

  getFilteredThread = () => {
    const type = this.props.type
    const { unixDate, authorId, sortKey } = this.state

    if (type === 'feed') {
      this.props.filterPosts(unixDate, authorId, sortKey)
    }

    if (type === 'event') {
      this.props.filterEvents(unixDate, authorId, sortKey)
    }
  }

  filterByLikes = key => {
    const type = this.props.type
    const { unixDate, authorId, sortBy } = this.state
    if (!sortBy) {
      this.setState({
        sortBy: true,
        showCalendar: false,
        showUserFilter: false,
        sortKey: key
      })

      if (type === 'feed') {
        this.props.filterPosts(unixDate, authorId, key)
      }

      if (type === 'event') {
        this.setState(
          {
            sortKey: 'rsvps_count'
          },
          () => {
            this.props.filterEvents(unixDate, authorId, this.state.sortKey)
          }
        )
      }
    }

    if (sortBy) {
      this.setState({
        sortBy: false,
        sortKey: ''
      })

      if (type === 'feed') {
        this.props.fetchPosts()
      }
      if (type === 'event') {
        this.props.cancelFilterEvents()
      }
    }
  }

  toggleCalendarFilter = () => {
    this.setState({
      showCalendar: !this.state.showCalendar,
      showUserFilter: false
    })
  }

  toggleUserFilter = () => {
    if (!this.state.showUserFilter) {
      document.addEventListener('click', this.handleOutsideClick, false)
    }
    if (this.state.showUserFilter) {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }
    this.setState({
      showUserFilter: !this.state.showUserFilter,
      showCalendar: false
    })
  }

  onSearchInputChange = event => {
    const userName = event.target.value
    this.setState({
      searchTerm: userName
    })

    const { postAuthors } = this.props

    if (userName.length > 1) {
      const filteredUsers = postAuthors.filter(
        author =>
          `${author.userName}`.toUpperCase().indexOf(userName.toUpperCase()) >=
          0
      )

      this.setState({
        postAuthors: filteredUsers
      })
    }

    if (userName.length <= 1) {
      this.setState({
        postAuthors: this.props.postAuthors
      })
    }
  }

  cancelFilter = filter => {
    const type = this.props.type

    if (filter === 'date') {
      this.setState(
        {
          moment: moment(),
          dateFilter: false,
          unixDate: '',
          showCalendar: false,
          showUserFilter: false
        },
        () => {
          if (!this.state.userFilter) {
            if (type === 'feed') {
              if (this.state.sortBy) {
                this.getFilteredThread()
              } else {
                this.props.fetchPosts()
              }
            }
            if (type === 'event') {
              if (this.state.sortBy) {
                this.getFilteredThread()
              } else {
                this.props.cancelFilterEvents()
              }
            }
          }
          if (this.state.userFilter) {
            this.getFilteredThread()
          }
        }
      )
    }

    if (filter === 'name') {
      this.setState(
        {
          searchTerm: '',
          userFilter: false,
          authorId: '',
          showUserFilter: false,
          showCalendar: false
        },
        () => {
          if (!this.state.dateFilter) {
            if (type === 'feed') {
              if (this.state.sortBy) {
                this.getFilteredThread()
              } else {
                this.props.fetchPosts()
              }
            }
            if (type === 'event') {
              if (this.state.sortBy) {
                this.getFilteredThread()
              } else {
                this.props.cancelFilterEvents()
              }
            }
          }
          if (this.state.dateFilter) {
            this.getFilteredThread()
          }
        }
      )
    }
  }

  getTabs = () => {
    const {
      match: {
        params: { propertyId }
      }
    } = this.props
    return [
      {
        name: 'Posts',
        route: `/properties/${propertyId}/communication/community-feed/posts`
      },
      {
        name: 'Events',
        route: `/properties/${propertyId}/communication/community-feed/events`
      }
    ]
  }

  onCreateClick = (history, propertyId, type) =>
    type
      ? history.push(
          `/properties/${propertyId}/communication/community-feed/${type}/create`
        )
      : history.push(
          `/properties/${propertyId}/communication/community-feed/posts/create`
        )

  getButtons = () => {
    const {
      history,
      match: {
        params: { propertyId, type },
        url
      },
      theme
    } = this.props

    const feedType = type || 'posts'
    return (
      <React.Fragment>
        <BackButtonStyled 
          variant="fab"
          onClick={() =>
            history.push(`/properties/${propertyId}/communication`)
          }>
          <KeyboardBackspace
            style={{ color: theme.palette.primary.dark }}
            height="1.5em"
            width="1.5em"
          />
        </BackButtonStyled>
        <Link to={`${url.split(`/${feedType}`)[0]}/${feedType}/create`}>
          <AddButtonStyled variant="fab">
            <AddIcon style={{ color: theme.palette.primary.dark }} height="1.7em" width="1.7em" />
          </AddButtonStyled>
        </Link>
      </React.Fragment>
    )
  }

  render() {
    const { theme } = this.props
    const {
      moment,
      searchTerm,
      userFilter,
      dateFilter,
      showCalendar,
      showUserFilter,
      selectedUser,
      sortBy
    } = this.state

    const filteredDate = getFilteredDate(moment)

    return (
      <React.Fragment>
        <HeaderTitleWrapper>
          <div>COMMUNICATION</div>
          <Header blackText>Community feed</Header>
        </HeaderTitleWrapper>
        <TabsWrapper>
          <div />
          <div>
            <Tabs
              tabs={this.getTabs()}
              tabStyle={1}
              marginRight={'46px'}
            />
          </div>
          <ButtonsWrapper>{this.getButtons()}</ButtonsWrapper>
        </TabsWrapper>
        <FilterWrapper>
          <FilterButtonStyled
            variant="fab"
            disableRipple
            active={sortBy ? 'true' : ''}
            onClick={() => this.filterByLikes('likes')}>
            <CommunityFeedIcons
              height="1.25em"
              width="1.25em"
              name="heart"
              fill={
                sortBy ? theme.palette.primary.navy.spaceBlue : theme.palette.text.light
              }
            />
          </FilterButtonStyled>
          <FilterButtonStyled
            variant="fab"
            disableRipple
            active={dateFilter ? 'true' : ''}
            onClick={() => this.toggleCalendarFilter()}>
            <CommunityFeedIcons
              height="1.25em"
              width="1.25em"
              name="calendar"
              fill={
                dateFilter
                  ? theme.palette.primary.navy.spaceBlue 
                  : theme.palette.text.light
              }
            />
          </FilterButtonStyled>
          {showCalendar ? (
            <CalendarWrapper>
              <Calendar
                flexDirection={'column'}
                flex={1}
                moment={moment}
                handleChange={this.handleCalendarChange}
                width={'250px'}
                isCommunityFeed={true}
                previousDatesEnabled
                noBoxShadow
              />
              <CalendarActionButtons>
                <CancelDateSelection
                  onClick={() => this.toggleCalendarFilter()}>
                  Cancel
                </CancelDateSelection>
                <ConfirmDateSelection onClick={() => this.onCalenderClickOK()}>
                  Ok
                </ConfirmDateSelection>
              </CalendarActionButtons>
            </CalendarWrapper>
          ) : (
            ''
          )}
          <FilterButtonStyled
            variant="fab"
            disableRipple
            active={userFilter ? 'true' : ''}
            onClick={() => this.toggleUserFilter()}>
            <CommunityFeedIcons
              height="1.25em"
              width="1.25em"
              name="user"
              fill={
                userFilter
                  ? theme.palette.primary.navy.spaceBlue
                  : theme.palette.text.light
              }
            />
          </FilterButtonStyled>
          {showUserFilter ? (
            <UserFilterWrapper ref={this.setWrapperRef}>
              <SearchWrapper>
                <TextField
                  value={searchTerm}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          name="search"
                          height="15px"
                          width="16px"
                          className="searchSubmitIcon"
                          data-testid="searchSubmitButton"
                        />
                      </InputAdornment>
                    )
                  }}
                  onChange={this.onSearchInputChange}
                />
              </SearchWrapper>
              <UsersList>{this.getPostAuthors()}</UsersList>
            </UserFilterWrapper>
          ) : (
            ''
          )}
        </FilterWrapper>
        {userFilter || dateFilter ? (
          <DisplaySearchCriteria>
            {userFilter ? (
              <FilterTag
                label={userFilter ? selectedUser : ''}
                onClick={() => this.cancelFilter('name')}
                onDelete={() => this.cancelFilter('name')}
                className={'classes.chip'}
                deleteIcon={
                  <CommonIcon
                    name="close"
                    height="9px"
                    strokeWidth="2px"
                    stroke={theme.palette.primary.green.seeFoam}
                    width="9px"
                    fill={theme.palette.primary.green.seeFoam}
                  />
                }
              />
            ) : (
              ''
            )}
            {dateFilter ? (
              <FilterTag
                label={dateFilter ? filteredDate : ''}
                onClick={() => this.cancelFilter('date')}
                onDelete={() => this.cancelFilter('date')}
                className={'classes.chip'}
                deleteIcon={
                  <CommonIcon
                    name="close"
                    height="9px"
                    strokeWidth="2px"
                    stroke={theme.palette.primary.green.seeFoam}
                    width="9px"
                    fill={theme.palette.primary.green.seeFoam}
                  />
                }
              />
            ) : (
              ''
            )}
          </DisplaySearchCriteria>
        ) : (
          ''
        )}
        <PostsEventsFeedWrapper>
          <CommunityFeedTabRoutes />
        </PostsEventsFeedWrapper>
        <ScrollToTop />
      </React.Fragment>
    )
  }
}

export default withTheme(CommunityFeedLayout)
