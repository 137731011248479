// @flow

import get from 'lodash/get'
import { humanizeString } from '../../../utils/string'

export const getGroupEventsDropDownData = (state: Object, groupTemplate: Object): Array<Object> | null => {
  const groupEvents = get(state, ['groupMessaging', 'groupEvents'])

  const eventMap = groupEvents
    ? groupEvents.map((groupEvent, index) => ({
      id: index,
      name: humanizeString(groupEvent)
    }))
    : []
  return (groupTemplate && groupTemplate.is_default) ?
    eventMap.filter((e) => e.name == humanizeString("lease_end_date") || e.name == humanizeString("move_out_date"))
    : eventMap
}

export const getGroupEvents = (state: Object) => {
  const groupEvents = get(state, ['groupMessaging', 'groupEvents'])
  return groupEvents || null
}

export const hasGroupEvents = (state: Object) => {
  return get(state, ['groupMessaging', 'groupEvents']) != null
}
