// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import BaseCard from '@Common/Cards/BaseCard'
import ViewMode, { RightViewMode } from './ViewMode'
import WideBaseCard from '@Common/Cards/BaseCard/WideBaseCard'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import ThermostatContent from './ThermostatContent'
import type { ThermostatProps, ThermostatState } from './types'
import { HeaderRightLink } from './styles'
import { commands } from 'zego-shared/store/devices/sagas/utils/deviceCommands'
import withRouter from 'react-router-dom/withRouter'
import Image from '../../../../images/thermostat_card.png'
import { CircleWrap } from '../DevicesList/styles'
import { CommonIcon } from '@icons'
import { capitalizeFirstLetter } from 'zego-shared/utils/string'

class Thermostat extends React.Component<ThermostatProps, ThermostatState> {
  state = {
    componentMode: 'VIEW',
    modeToChangeTo: this.props.currentThermostatMode,
    heatSetpointToChangeTo: this.props.heatSetPoint,
    coolSetpointToChangeTo: this.props.coolSetPoint
  }

  toggleViewBatteryMode = () => {
    const {
      id,
      viewBatteryMode,
      history,
      match: { params }
    } = this.props
    viewBatteryMode
      ? history.push(
          `/properties/${params.propertyId}/units/${
            params.unitId
          }/smart/devices/${id}`
        )
      : history.push(
          `/properties/${params.propertyId}/units/${
            params.unitId
          }/smart/devices/${id}/battery`
        )
  }

  changeComponentMode = (
    componentMode: 'VIEW' | 'CHANGESETPOINT' | 'CHANGEMODE'
  ): void => {
    this.setState({ componentMode })
  }

  changeThermostatMode = (mode: 'auto' | 'heat' | 'cool' | 'off'): void => {
    this.setState({ modeToChangeTo: mode })
  }

  sendChangeModeCommand = () => {
    const {
      pushThermostatCommand,
      id,
      identifier,
      currentThermostatMode
    } = this.props
    const { modeToChangeTo } = this.state

    if (modeToChangeTo !== currentThermostatMode) {
      pushThermostatCommand(
        commands.MODE,
        { mode: modeToChangeTo },
        id,
        identifier
      )
    } else {
      this.changeComponentMode('VIEW')
    }
  }

  sendChangeSetPointCommand = () => {
    const {
      pushThermostatCommand,
      id,
      identifier,
      heatSetPoint,
      coolSetPoint,
      currentThermostatMode
    } = this.props
    const { heatSetpointToChangeTo, coolSetpointToChangeTo } = this.state

    if (
      (currentThermostatMode === commands.AUTO &&
        heatSetpointToChangeTo !== heatSetPoint) ||
      coolSetpointToChangeTo !== coolSetPoint ||
      (currentThermostatMode === commands.COOL &&
        coolSetpointToChangeTo !== coolSetPoint) ||
      (currentThermostatMode === commands.HEAT &&
        heatSetpointToChangeTo !== heatSetPoint)
    ) {
      let command = {}
      if (currentThermostatMode === commands.HEAT) {
        command = { heat: heatSetpointToChangeTo }
      }
      if (currentThermostatMode === commands.COOL) {
        command = { cool: coolSetpointToChangeTo }
      }
      if (currentThermostatMode === commands.AUTO) {
        command = { heat: heatSetpointToChangeTo, cool: coolSetpointToChangeTo }
      }
      pushThermostatCommand(currentThermostatMode, command, id, identifier)
    } else {
      this.changeComponentMode('VIEW')
    }
  }

  // values comes back from slider as an array
  // auto is two values cool is always second because its always bigger
  // values: [heat, cool], [heat], [cool]
  changeSetPoint = values => {
    const { currentThermostatMode } = this.props
    switch (currentThermostatMode) {
      case commands.AUTO:
        this.setState({
          heatSetpointToChangeTo: values[0],
          coolSetpointToChangeTo: values[1]
        })
        break
      case commands.HEAT:
        this.setState({
          heatSetpointToChangeTo: values[0]
        })
        break
      case commands.COOL:
        this.setState({
          coolSetpointToChangeTo: values[0]
        })
        break
      default:
        break
    }
  }

  getContent = () => {
    const {
      componentMode,
      modeToChangeTo,
      heatSetpointToChangeTo,
      coolSetpointToChangeTo
    } = this.state
    const {
      currentThermostatMode,
      deviceModel,
      deviceStatus,
      currentTemp,
      heatSetPoint,
      coolSetPoint,
      batteryPercent,
      batteryLevelText,
      batteryLevelPercentText,
      viewBatteryMode,
      abnormal
    } = this.props
    return (
      <ThermostatContent
        componentMode={componentMode}
        modeToChangeTo={modeToChangeTo}
        heatSetpointToChangeTo={heatSetpointToChangeTo}
        coolSetpointToChangeTo={coolSetpointToChangeTo}
        batteryPercent={batteryPercent}
        batteryLevelText={batteryLevelText}
        batteryLevelPercentText={batteryLevelPercentText}
        heatSetPoint={heatSetPoint}
        coolSetPoint={coolSetPoint}
        currentThermostatMode={currentThermostatMode}
        deviceModel={deviceModel}
        deviceStatus={
          abnormal ? 'Abnormal' : capitalizeFirstLetter(deviceStatus)
        }
        currentTemp={currentTemp}
        viewBatteryMode={viewBatteryMode}
        toggleViewBatteryMode={this.toggleViewBatteryMode}
        changeComponentMode={this.changeComponentMode}
        changeThermostatMode={this.changeThermostatMode}
        changeSetPoint={this.changeSetPoint}
      />
    )
  }

  showDeleteDeviceModal = () => {
    const {
      history,
      match: { url }
    } = this.props
    history.push(`${url}/delete`)
  }

  getHeaderRight = () => {
    const {
      currentThermostatMode,
      viewBatteryMode,
      abnormal,
      theme
    } = this.props
    const { componentMode } = this.state

    if (abnormal)
      return (
        <CircleWrap large onClick={this.showDeleteDeviceModal}>
          <CommonIcon
            name="close"
            strokeWidth={5}
            width={'1em'}
            height={'1em'}
            stroke={theme.palette.primary.green.mint}
          />
        </CircleWrap>
      )

    return componentMode !== 'VIEW' ? (
      <HeaderRightLink
        onClick={mode => {
          if (componentMode === 'CHANGEMODE') {
            this.sendChangeModeCommand()
          }
          if (componentMode === 'CHANGESETPOINT') {
            this.sendChangeSetPointCommand()
          }
        }}>
        DONE
      </HeaderRightLink>
    ) : viewBatteryMode ? (
      <HeaderRightLink onClick={() => this.toggleViewBatteryMode()}>
        DONE
      </HeaderRightLink>
    ) : (
      <HeaderRightLink onClick={() => this.changeComponentMode('CHANGEMODE')}>
        {currentThermostatMode.toUpperCase()}
      </HeaderRightLink>
    )
  }

  render() {
    const {
      currentThermostatMode,
      deviceModel,
      deviceStatus,
      currentTemp,
      heatSetPoint,
      coolSetPoint,
      batteryPercent,
      batteryLevelText,
      batteryLevelPercentText,
      installationDate,
      viewBatteryMode,
      abnormal
    } = this.props

    return this.state.componentMode === 'VIEW' ? (
      <WideBaseCard
        title="THERMOSTAT"
        rowStart={1}
        rowSpan={3}
        columnStart={1}
        columnSpan={9}
        imageSrc={Image}
        leftContent={() => (
          <ViewMode
            installationDate={installationDate}
            deviceModel={deviceModel}
            deviceStatus={deviceStatus}
            currentTemp={currentTemp}
            currentThermostatMode={currentThermostatMode}
            heatSetPoint={heatSetPoint}
            coolSetPoint={coolSetPoint}
            batteryPercent={batteryPercent}
            batteryLevelText={batteryLevelText}
            batteryLevelPercentText={batteryLevelPercentText}
            viewBatteryMode={viewBatteryMode}
            toggleViewBatteryMode={this.toggleViewBatteryMode}
            changeComponentMode={this.changeComponentMode}
          />
        )}
        rightContent={() => (
          <FlexDirection direction="column" fullWidth fullHeight>
            <FlexItem
              flex={0.1}
              style={{
                textAlign: 'right',
                alignSelf: 'flex-end',
                height: '100%'
              }}>
              {this.getHeaderRight()}
            </FlexItem>
            {!abnormal && (
              <RightViewMode
                deviceModel={deviceModel}
                deviceStatus={deviceStatus}
                currentTemp={currentTemp}
                currentThermostatMode={currentThermostatMode}
                heatSetPoint={heatSetPoint}
                coolSetPoint={coolSetPoint}
                batteryPercent={batteryPercent}
                batteryLevelText={batteryLevelText}
                batteryLevelPercentText={batteryLevelPercentText}
                viewBatteryMode={viewBatteryMode}
                toggleViewBatteryMode={this.toggleViewBatteryMode}
                changeComponentMode={this.changeComponentMode}
              />
            )}
          </FlexDirection>
        )}
      />
    ) : (
      <BaseCard
        title="THERMOSTAT"
        rowStart={1}
        rowSpan={3}
        columnStart={1}
        columnSpan={9}
        content={this.getContent}
        headerRight={this.getHeaderRight}
      />
    )
  }
}

export default withRouter(withTheme(Thermostat))
