import { call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken } from '../../../authorization/selectors'
import {
  fetchDeviceProfiles as fetchDeviceProfilesAction,
  fetchDeviceProfilesStart,
  fetchDeviceProfilesSuccess,
  fetchDeviceProfilesError
} from '../actions'
import api from '../../../../utils/api'
import log from '../../../../utils/logger'

export function* fetchDeviceProfiles() {
  try {
    yield put(fetchDeviceProfilesStart())
    const authToken = yield select(getToken)

    const response = yield call(api.getDeviceProfiles, authToken)
    yield put(fetchDeviceProfilesSuccess(response))
  } catch (error) {
    yield put(fetchDeviceProfilesError(error))
    log(`Failed to fetch devices`)
  }
}

function* watchFetchDeviceProfiles() {
  yield takeLatest(fetchDeviceProfilesAction().type, fetchDeviceProfiles)
}

export default [watchFetchDeviceProfiles()]
