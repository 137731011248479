import get from 'lodash/get'
import moment from 'moment'

import { getDeviceMakeModel } from './selectors'
import { getDate } from '../../utils'

export const deviceToSwitchData = device => {
  const time = moment(get(device, ['last_state', 'data', 'device_timestamp']))
  const abnormal =
    get(device, ['last_state', 'data', 'state']) !== 'on' &&
    get(device, ['last_state', 'data', 'state']) !== 'off'

  return {
    deviceModel: getDeviceMakeModel(device),
    installationDate: getDate(device.inserted_at),
    deviceStatus: get(device, ['last_state', 'data', 'status']),
    lastUpdated: get(device, ['last_state', 'device_timestamp']),
    switchName: device.name,
    state: get(device, ['last_state', 'data', 'state']),
    date: moment().isSame(time, 'day') ? 'Today' : time.format('M/D'),
    time: time.format('h:mma'),
    abnormal,
    id: get(device, ['id']),
    identifier: get(device, ['identifier'])
  }
}
