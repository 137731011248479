import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import { Underline } from '@Common/Cards/BaseCard/styles'

export const VacantSchedule = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.md};
  color: ${({ theme }) => theme.palette.text.secondary};
  padding: 2em 0 4em 0;
  height: 200px;
`
export const Title = styled(Typography)`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  color: ${({ theme }) => theme.palette.common.black};
  padding-bottom: 1em;
`
export const Value = styled('span')`
  color: ${({ theme }) => theme.palette.primary.green.mint};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  padding-right: 1.5em;
  cursor: pointer;
`
export const Type = styled('span')`
  cursor: pointer;
`

export const Status = styled('div')`
  padding: 3em 0;
`

export const Selected = styled(Underline)`
  border-bottom: 2px solid ${({ theme }) => theme.palette.primary.green.mint};
`
