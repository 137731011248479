// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import InfiniteScroll from 'react-infinite-scroll-component'
import ChatMessage from './ChatMessage'
import { MessagesFrame, Pin } from './styles'
import Loader from '../../../Common/Loader'
import { CommonIcon } from '@icons'
import type { Theme } from '../../../../theme'

type Props = {
  messages: Object[],
  isFetching: boolean,
  isPinned: boolean,
  channelUrl: string,
  pinChannel: string => mixed,
  unpinChannel: string => mixed,
  theme: Theme
}

class MessageList extends React.Component<Props, {}> {
  shouldComponentUpdate(nextProps: Props) {
    if (this.props.isPinned !== nextProps.isPinned) {
      return true
    }
    if (this.props.isFetching !== nextProps.isFetching) {
      return true
    }
    if (this.props.messages.length !== nextProps.messages.length) {
      return true
    }
    return false
  }

  render() {
    const {
      messages,
      isFetching,
      isPinned,
      pinChannel,
      unpinChannel,
      channelUrl,
      theme
    } = this.props

    return (
      <React.Fragment>
        <Pin>
          {isPinned ? (
            <CommonIcon
              name="pin"
              onClick={() => {
                unpinChannel(channelUrl)
              }}
              fill={theme.palette.primary.green.mint}
              height="10px"
              width="25px"
            />
          ) : (
            <CommonIcon
              name="pin"
              onClick={() => {
                pinChannel(channelUrl)
              }}
              fill={theme.palette.neutrals.warmGray}
              height="10px"
              width="25px"
            />
          )}
        </Pin>
        <MessagesFrame id="messageFrame">
          <InfiniteScroll
            style={{
              width: '100%',
              paddingBottom: '30px'
            }}
            scrollableTarget="messageFrame"
            outerDivStyle={{ width: '100%' }}
            scrollDirection="up">
            {isFetching ? (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Loader />
              </div>
            ) : (
              messages.map((message, index) => (
                <ChatMessage key={index} message={message} />
              ))
            )}
          </InfiniteScroll>
        </MessagesFrame>
      </React.Fragment>
    )
  }
}

export default withTheme(MessageList)
