import get from 'lodash/get'
import isBool from 'lodash/isBoolean'
import moment from 'moment'
import { getDeviceMakeModel, getBatteryLevelText } from './selectors'
import { capitalizeFirstLetter, getDate } from '../../utils'

const getLockLastStateText = (lastUpdated, isLocked) => {
  const time = moment(lastUpdated)
  if (!isBool(isLocked)) return 'Unknown Current Status'
  return `${isLocked ? `Locked` : `Unlocked`} ${
    moment().isSame(time, 'day') ? 'Today' : time.format('M/D')
    } at ${time.format('h:mma')}`
}

export const deviceToLockData = device => {
  const lastUpdated = get(device, ['last_state', 'device_timestamp'])
  const deviceStatus = get(device, ['last_state', 'data', 'status'])
  const isLocked = get(device, ['last_state', 'data', 'state']) === 'locked'

  const abnormal =
    get(device, ['last_state', 'data', 'state']) !== 'locked' &&
    get(device, ['last_state', 'data', 'state']) !== 'unlocked'

  return {
    deviceModel: getDeviceMakeModel(device),
    lastStateText: getLockLastStateText(lastUpdated, isLocked),
    batteryLevelText: `Battery Level ${getBatteryLevelText(device.battery)}`,
    batteryLevelPercentText: `Battery Level ${device.battery}%`,
    batteryPercent: device.battery,
    id: device.id,
    identifier: device.identifier,
    deviceStatus: deviceStatus ? capitalizeFirstLetter(deviceStatus) : 'Unknown',
    lastUpdated,
    isLocked,
    abnormal,
    installationDate: getDate(device.inserted_at)
  }
}
