// @flow
import React, { Fragment } from 'react'
import { SettingsSideNavRoutes } from '../../routes/Settings'
import { withTheme } from 'emotion-theming'
import type { Match } from 'react-router-dom'
import type { BrowserHistory } from 'history'
import type { Theme } from '../../theme'
import SummarySideNavigation from '@Common/SummarySideNavigation'

type Props = {
  match: Match,
  history: BrowserHistory,
  theme: Theme,
  isFetching: boolean,
  hasData: boolean
}

type State = {
  baseUrl: string
}

export class Settings extends React.Component<Props, State> {
  getTabs = () => {
    let {
      match: {
        params: { propertyId }
      }
    } = this.props

    propertyId = propertyId || ''

    return [
      {
        name: 'General Info',
        route: `/properties/${propertyId}/settings/general-info`
      },
      { name: 'Access', route: `/properties/${propertyId}/settings/access` },
      {
        name: 'My Account',
        route: `/properties/${propertyId}/settings/my-account`
      }
    ]
  }
  render() {
    const { theme, hasData } = this.props

    if (!hasData) {
      return null
    }

    return (
      <Fragment>
        <SettingsSideNavRoutes />
        <SummarySideNavigation
          selectColor={theme.palette.primary.green.mint}
          tabs={this.getTabs()}
        />
      </Fragment>
    )
  }
}

export default withTheme(Settings)
