// @flow

import get from 'lodash/get'
import { getPaginationData } from '../../helpers/selectors/data'
// $FlowDisableNextLine
import { getDateForGroupMessaging } from '../../../../admin/assets/src/utils/dateUtils'

export const getHistory = (state: Object) => {
  const history = get(state, ['groupMessaging', 'history', 'data'])
  return history
    ? history.map(historyItem => ({
        id: historyItem.id,
        senderName: historyItem.sender_name,
        senderPicture: historyItem.sender_picture,
        sendingMethods: historyItem.sending_methods,
        title: historyItem.title,
        inAppCount: historyItem.in_app_count,
        emailCount: historyItem.email_count,
        textCount: historyItem.text_count,
        recipientsCount: historyItem.recipient_count,
        groups: historyItem.group_selector_names || [],
        date: getDateForGroupMessaging(historyItem.date)
      }))
    : null
}

export const hasHistory = (state: Object) => {
  return get(state, ['groupMessaging', 'history', 'data']) != null
}

export const getHistoryPaginationInfo = (state: Object) =>
  getPaginationData(state, ['groupMessaging', 'history'])
