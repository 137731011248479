// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import { Props } from './types'
import SummarySideNavigation from '@Common/SummarySideNavigation'
import SmartSummaryTabRoutes from '../../../routes/Smart/SmartSummaryTabRoutes'
import Loader from '../../Common/Loader'

const getSideNavigationTabs = (
  smartUnitsCount,
  inactiveUnitsCount,
  propertyId
) => [
  {
    name: `${smartUnitsCount} Smart Units`,
    query: { type: 'smart' }
  },
  {
    name: `${inactiveUnitsCount} Non Smart Units`,
    route: `/properties/${propertyId}/smart/units`,
    query: { type: 'inactive', filter: '' }
  }
]

export class SmartSummary extends React.Component<Props> {
  render() {
    const {
      isFetching,
      hasData,
      smartUnitsCount,
      inactiveUnitsCount,
      propertyId,
      theme,
      scheduleInfo
    } = this.props

    const tabs = getSideNavigationTabs(
      smartUnitsCount,
      inactiveUnitsCount,
      propertyId
    )

    return isFetching ? (
      <Loader />
    ) : hasData ? (
      <React.Fragment>
        <SmartSummaryTabRoutes
          scheduleEnabled={scheduleInfo != null && scheduleInfo.enabled}
        />
        <SummarySideNavigation
          selectColor={theme.palette.primary.green.mint}
          tabs={tabs}
        />
      </React.Fragment>
    ) : null
  }
}

export default withTheme(SmartSummary)
