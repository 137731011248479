import styled from '@emotion/styled'
import { convertHexToRGB } from '../../../utils'

export const CalendarWrapper = styled('div')`
  border: 1px solid ${({ theme }) => theme.palette.neutrals.warmGray};

  & .DayPicker-wrapper,
  .DayPicker-Months {
    outline: none;
  }

  & .DayPicker {
    ${({ width }) => width && `width: ${width}; box-sizing: initial;`};
  }

  & .DayPicker-Month {
    margin: 1.25em;
    margin-bottom: 0;
    border-collapse: separate;
    outline: none;
  }

  & .DayPicker-Day {
    outline: none;
    padding: 0.4em;
    width: 27px;
    height: 27px;
    font-size: ${({ theme }) => theme.custom.font.size.md};
  }

  & .DayPicker-Day:focus,
  .DayPicker-Day:active {
    perspective: 1px;
    overflow: hidden;
    box-shadow: 0 0 0 1px
      ${({ theme }) =>
        convertHexToRGB(theme.palette.primary.navy.twilight, 0.5)};
  }

  & .DayPicker-NavButton {
    margin-top: 10px;
    border: 1px solid
      ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
    border-radius: 50%;
    outline: none;
  }

  & .DayPicker-NavButton--prev {
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%278%27%20height%3D%276%27%20viewBox%3D%270%200%208%206%27%20fill%3D%27none%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%3E%3Cpath%20fill-rule%3D%27evenodd%27%20clip-rule%3D%27evenodd%27%20d%3D%27M2.90874%200.455078L3.45419%201.00053L1.81783%202.6369H7.63601V3.36417H1.81783L3.45419%205.00053L2.90874%205.54599L0.363281%203.00053L2.90874%200.455078Z%27%20fill%3D%27%230E1427%27%2F%3E%3C%2Fsvg%3E');
  }

  & .DayPicker-NavButton--next {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nOCcgaGVpZ2h0PSc2JyB2aWV3Qm94PScwIDAgOCA2JyBmaWxsPSdub25lJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxwYXRoIGZpbGwtcnVsZT0nZXZlbm9kZCcgY2xpcC1ydWxlPSdldmVub2RkJyBkPSdNNS4wOTEyNiA1LjU0NDkyTDQuNTQ1ODEgNC45OTk0N0w2LjE4MjE3IDMuMzYzMUwwLjM2Mzk5IDMuMzYzMUwwLjM2Mzk5MSAyLjYzNTgzTDYuMTgyMTcgMi42MzU4M0w0LjU0NTgxIDAuOTk5NDY3TDUuMDkxMjYgMC40NTQwMTJMNy42MzY3MiAyLjk5OTQ3TDUuMDkxMjYgNS41NDQ5MlonIGZpbGw9JyMwRTE0MjcnLz48L3N2Zz4');
  }

  & .DayPicker-WeekdaysRow {
    box-shadow: 0 1px 0 0 ${({ theme }) => theme.palette.neutrals.mediumGray};
  }

  & .DayPicker-Day--booked::after {
    content: '●';
    position: absolute;
    margin-left: 9px;
    margin-top: -10px;
    display: block;
    color: ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
  }

  & .DayPicker-Day--today {
    color: ${({ theme }) => theme.palette.common.main};
    border: solid 0.5px ${({ theme }) => theme.palette.neutrals.mediumGray}; 
    border-radius: 50% !important;
  }

  & .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    color: ${({ theme }) => theme.palette.common.main};
    background-color: ${({ theme }) => theme.palette.primary.green.mint};
    border-radius: 50% !important;
    border: none;
  }

  & .DayPicker-Day--closed {
    color: ${({ theme }) => theme.palette.neutrals.mediumGray}; 
  }
`

export const NavElement = styled('button')`
  border: 1px solid ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
`

export const DateSelect = styled('select')`
  background: ${({ theme }) => theme.palette.primary.green.iceberg};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  border: none;
  outline: none;
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  
  &:first-child {
    margin-right: 5px;
  }

`

