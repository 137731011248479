import fromPairs from 'lodash/fromPairs'
import { createFetchActions } from '../../../helpers/actions/creators'
import { getPropertySettingValue } from '../utils'

export const PROPERTY_SETTINGS_FETCH_SECTION = 'PROPERTYSETTINGS'
export const UPDATE_PROPERTY_SETTING = 'UPDATE_PROPERTY_SETTING'
export const UPDATE_PROPERTY_SETTING_OPTIMISTIC =
  'UPDATE_PROPERTY_SETTING_OPTIMISTIC'
export const UPDATE_PROPERTY_SETTING_ERROR = 'UPDATE_PROPERTY_SETTING_ERROR'
export const UPDATE_PROPERTY_SETTING_SUCCESS = 'UPDATE_PROPERTY_SETTING_SUCCESS'

export const updatePropertySetting = (propertyId, attributes: Object) => {
  attributes = fromPairs(
    Object.entries(attributes).map(([k, v]) => [getPropertySettingValue(k), v])
  )
  return {
    type: UPDATE_PROPERTY_SETTING,
    propertyId,
    attributes
  }
}

export const updatePropertySettingOptimistic = (
  propertyID,
  attributes: Object
) => ({
  type: UPDATE_PROPERTY_SETTING_OPTIMISTIC,
  propertyID,
  attributes
})

export function updatePropertySettingError(error, attributes) {
  return {
    type: UPDATE_PROPERTY_SETTING_ERROR,
    error,
    attributes
  }
}

export function updatePropertySettingSuccess(attributes) {
  return {
    type: UPDATE_PROPERTY_SETTING_SUCCESS,
    attributes
  }
}

export const {
  fetchAction: fetchActionPropertySettings,
  fetchStartAction: fetchPropertySettingsStart,
  fetchSuccessAction: fetchPropertySettingsSuccess,
  fetchErrorAction: fetchPropertySettingsError
} = createFetchActions(PROPERTY_SETTINGS_FETCH_SECTION)

export const fetchPropertySettings = propertyID =>
  fetchActionPropertySettings({ propertyID })
