import styled from '@emotion/styled'
import MaterialToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import MaterialToggleButton from '@material-ui/lab/ToggleButton'

export const ToggleContainer = styled('div')`
  height: 76;
  display: flex;
  alignItems: 'center';
  justify-content: 'space-between'
`

export const ToggleButtonGroup = styled(MaterialToggleButtonGroup)`
  border-radius: 20px;
  display: flex;
  width: 300px;
  height: 25px;
  background: ${({ theme }) => theme.palette.neutrals.lightGray};
  box-shadow: none;
`

export const ToggleButton = styled(MaterialToggleButton)`
  flex: 1;
  height: 25px;
  border-radius: 20px !important;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.neutrals.deepGray};
`
