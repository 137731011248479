// @flow

import React from 'react'

import AmenityProContainer from '../AmenityPro/AmenityProContainer'
import AmenityLegacyContainer from '../AmenityLegacy/AmenityLegacyContainer'

const AmenityVersionSelector = (props: Object) => {
  const { isProAmenity } = props

  if (isProAmenity === undefined) {
    return null
  }

  const DetailView = isProAmenity ? AmenityProContainer : AmenityLegacyContainer

  return <DetailView {...props}/>
}

export default AmenityVersionSelector
