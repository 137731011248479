import {
  GROUP_MESSAGE,
  fetchMessageUsersSummarySuccess,
  sendGroupMessageSuccess,
  SAVE_AS_TEMPLATE_FAILED,
  continueSendGroupMessage,
  resetStatuses
} from './actions'
import withFetch from '../../helpers/reducers/withFetch'

const message = (state = {}, action) => {
  switch (action.type) {
    case fetchMessageUsersSummarySuccess().type:
      return {
        ...state,
        recipients: action.payload.data,
        totalPages: action.payload.headers['total-pages'],
        currentPage: action.payload.headers['page-number'],
        perPage: action.payload.headers['per-page'],
        total: action.payload.headers.total
      }
    case sendGroupMessageSuccess().type:
      return { ...state, sendSuccess: true }
    case resetStatuses().type:
      return { ...state, sendSuccess: false, continueSendMessage: false }
    case continueSendGroupMessage().type:
      return { ...state, continueSendMessage: true }
    default:
      return state
  }
}

export default withFetch(GROUP_MESSAGE)(message)
