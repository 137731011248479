// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import Raven from 'raven-js'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import api from 'zego-shared/utils/api'
import { isAdminCookieSet, setAdminCookie, } from 'zego-shared/utils/marketing'
import Socket from 'zego-shared/utils/socket'
import { ThemeProvider } from 'emotion-theming'
import * as serviceWorker from './serviceWorker'
import configureStore from './store'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Global } from '@emotion/core'
import ZegoTheme from './theme'
import globalStyles from './globalStyles'
import App from './App'
import networkUtils from './utils/networkUtils'

// configure sentry
if (process.env.NODE_ENV === 'production') {
  Raven.config(window._env_.REACT_APP_SENTRY_DSN).install()
}

// set api urls
api.configure(window._env_.REACT_APP_API_URL, window._env_.REACT_APP_APP_TYPE)

// set socket url
Socket.url(window._env_.REACT_APP_SOCKET_URL)

const { store, persistor } = configureStore(Raven)

// Set tracking cookies for marketing
if( !isAdminCookieSet() ) {
  setAdminCookie()
}

// wait for 401 check for expired token and redirect to login
networkUtils.handleRedirectToLogin(store)

ReactDOM.render(
  <React.Fragment>
    <Global styles={globalStyles} />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MuiThemeProvider theme={ZegoTheme}>
          <CssBaseline />
            <ThemeProvider theme={ZegoTheme}>
              <App />
            </ThemeProvider>
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  </React.Fragment>,
  (document.getElementById('root'): any)
)

serviceWorker.unregister()
