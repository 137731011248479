// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import SummaryCard from '@Common/Cards/SummaryCard'
import SummaryBG from '../../../images/summary_bg.png'
import CarouselUnit from './CarouselUnit'
import { Props } from './types'
import Loader from '../../Common/Loader'
import ReSyncPmsButton from '../ReSyncPmsButton'

export class ResidentUsers extends React.Component<Props, {}> {
  getFilters = () => {
    return [
      { name: 'Active', query: { status: 'active' } },
      { name: 'Pending', query: { status: 'pending' } },
      { name: 'Inactive', query: { status: 'inactive' } }
    ]
  }

  getCarouselRows = () => {
    const data = this.props.residents
    return data.map((datum, index) => (
      <CarouselUnit
        key={index}
        lastUnit={index === data.length - 1}
        {...datum}
      />
    ))
  }

  render() {
    const { residentsCount, isFetching, filter, hasData } = this.props

    if (isFetching) return <Loader />
    if (!hasData) return null

    return (
      <React.Fragment>
        <SummaryCard
          unitCount={residentsCount}
          imageSrc={SummaryBG}
          overflow="visible"
          countText={`${filter} Residents`}
          captionHeading="USER MANAGEMENT"
          mainHeading="residents"
          filterNames={this.getFilters()}
          items={this.getCarouselRows()}
          SummaryHeaderRightPanelChildren={<ReSyncPmsButton />}
        />
      </React.Fragment>
    )
  }
}

export default withTheme(ResidentUsers)
