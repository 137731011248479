import { createFetchActions } from '../../helpers/actions/creators'

export const GROUPEVENTS = 'GROUPEVENTS'

export const {
  fetchAction: fetchGroupEventsAction,
  fetchStartAction: fetchGroupEventsStart,
  fetchSuccessAction: fetchGroupEventsSuccess,
  fetchErrorAction: fetchGroupEventsError
} = createFetchActions(GROUPEVENTS)

export const fetchGroupEvents = (propertyId, page) =>
  fetchGroupEventsAction({ propertyId, page })
