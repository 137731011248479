import { select, put, takeLatest } from 'redux-saga/effects'
import { getChannelsByType } from '../../channels/selectors'

import {
  setActiveChannel,
  setActiveChannelByUrl as setActiveChannelByUrlAction,
  setActiveChannelByUserId as setActiveChannelByUserIdAction
} from '../actions'

export function* setActiveChannelByUrl({ url }) {
  try {
    const channels = yield select(getChannelsByType)
    const activeChannel = channels.find(channel => channel.url === url)
    yield put(setActiveChannel(activeChannel))
  } catch (error) {
    log(`Failed to set active chat channel by url. Error: ${error}`)
  }
}

export function* setActiveChannelByUserId({ userId }) {
    try {
    const channels = yield select(getChannelsByType)
    const activeChannel = channels.find((channel) => {
      return channel.members.some(member => member.userId == userId);
    })
    if (activeChannel) {
      yield put(setActiveChannel(activeChannel))
    }
  } catch (error) {
    log(`Failed to set active chat channel by userId. Error: ${error}`)
  }
}

function* watchSetActiveChannelByUrl() {
  yield takeLatest(setActiveChannelByUrlAction().type, setActiveChannelByUrl)
}

function* watchSetActiveChannelByUserId() {
  yield takeLatest(setActiveChannelByUserIdAction().type, setActiveChannelByUserId)
}

export default [
  watchSetActiveChannelByUserId(),
  watchSetActiveChannelByUrl()
]
