// @flow
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import Tabs from '@Common/Cards/Tabs'
import { Wrap, RightNav } from './styles'
import { BackButtonStyled } from './styles'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'

type Props = {
  columnSpan: number,
  columnStart: number,
  rowSpan: number,
  rowStart: number,
  tabs?: string[],
  rightLinks: Array<{ text: string, to: string }>,
  selected: number,
  handleTabContentChange: (value: number) => void,
  onTabClick: Function,
  scrollable: boolean,
  history: Object,
  theme: Object,
  goBack: boolean,
  backArrowUrl?: string
}

class TopNavigation extends React.Component<Props, {}> {
  render() {
    const {
      columnSpan,
      columnStart,
      rowSpan,
      rowStart,
      tabs = [],
      rightLinks,
      selected,
      backArrowUrl,
      onTabClick,
      history,
      goBack,
      theme,
      scrollable = null
    } = this.props

    return (
      <Wrap
        columnStart={columnStart}
        columnSpan={columnSpan}
        rowStart={rowStart}
        rowSpan={rowSpan}>
        <Tabs
          handleChange={this.props.handleTabContentChange}
          onTabClick={onTabClick}
          scrollable={scrollable !== null ? scrollable : tabs.length > 3}
          numberOfItemsPerSlide={3}
          selected={selected}
          tabStyle={1}
          styleClass="custom-tab"
          tabs={tabs}
        />
        <RightNav>
          {rightLinks &&
            rightLinks.map(link => (
              <Link key={link.text} to={link.to}>
                {link.text}
              </Link>
            ))}
          {(backArrowUrl || goBack) && (
            <BackButtonStyled
              variant="fab"
              onClick={() => goBack ? history.goBack() : history.push(backArrowUrl)}>
              <KeyboardBackspace
                style={{ color: theme.palette.primary.dark }}
                height="1.5em"
                width="1.5em"
              />
            </BackButtonStyled>
          )}
        </RightNav>
      </Wrap>
    )
  }
}

export default withTheme(withRouter(TopNavigation))
