import React from 'react'
import PrivateRoute from '../PrivateRoute'
import { WorkOrderNewContainer } from '../../views/WorkOrder/New'
import { WorkOrderSummaryContainer } from '../../views/WorkOrder/Summary'
import { WorkOrderDetailsContainer } from '../../views/WorkOrder/Details'

const getWorkOrderRoutes = () => [
  <PrivateRoute
    exact
    path="/properties/:propertyId/work-orders/new"
    key="workOrdersNew"
    component={WorkOrderNewContainer}
  />,
  <PrivateRoute
    exact
    path="/properties/:propertyId/work-orders/summary"
    key="workOrdersSummary"
    component={WorkOrderSummaryContainer}
  />,
  <PrivateRoute
    exact
    path="/properties/:propertyId/work-orders/details/:workOrderId"
    key="workOrdersDetails"
    component={WorkOrderDetailsContainer}
  />,
  <PrivateRoute
    exact
    path="/properties/:propertyId/units/:unitId/work-orders"
    key="workOrdersSummary"
    component={WorkOrderSummaryContainer}
  />
]

export default getWorkOrderRoutes
