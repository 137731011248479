// @flow

import React from 'react'
import { Helmet } from 'react-helmet'
import { BranchIcons } from '@icons'
import Loader from '../Common/Loader'
import { ActivationLink, ActivationText } from './styles'
type Props = {
  appType: string,
  sendBranchSms: (appType: string, phoneNumber: string) => {},
  event: Node
}

type State = {
  phoneNumber: string,
  btnMessage: string,
  loading: boolean
}

export class BranchIO extends React.Component<Props, State> {
  state = {
    phoneNumber: '',
    btnMessage: 'Send me the app',
    loading: true
  }

  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ loading: false })
      }.bind(this),
      3000
    )
  }

  sendSMS = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault()
    const { phoneNumber } = this.state
    const { appType } = this.props

    if (phoneNumber) {
      this.setState({
        btnMessage: 'Sent!',
        phoneNumber: ''
      })
      this.props.sendBranchSms(appType, phoneNumber)
    }
  }

  handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ phoneNumber: event.target.value })
  }

  render() {
    const { phoneNumber, btnMessage, loading } = this.state

    return (
      <React.Fragment>
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdn.branch.io/static/styles.css"
          />
        </Helmet>

        {loading ? (
          <Loader />
        ) : (
          <div className="container">
            <h1>Welcome to Zego!</h1>
            <h3 className="subtitle">
              You will receive a one-time SMS to download the app
            </h3>
            <form name="form" id="form" onSubmit={this.sendSMS}>
              <div className="form-item">
                <label htmlFor="phone">
                  <h2>Phone number:</h2>
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  className="text"
                  placeholder="+1 (123) 123-1234"
                  onChange={this.handleChange}
                  value={phoneNumber}
                />
                <button id="btn-submit" type="submit" className="btn-submit">
                  <BranchIcons name="plane" height="17px" width="17px" />
                  <span id="btn-submit-text" className="btn-submit-label">
                    {btnMessage}
                  </span>
                </button>
              </div>
            </form>
            {window.deeplink_path !== 'property' && (
              <ActivationText>
                Don't have a smart phone? Click
                <ActivationLink>
                  <a href={window.fallback_url || ''}> here </a>
                </ActivationLink>
                to activate on desktop
              </ActivationText>
            )}
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default BranchIO
