// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import { withRouter } from 'react-router-dom'
import BaseCard from '@Common/Cards/BaseCard'
import CircleWithText from '@Common/Cards/CircleWithText'
import Flex from '@Common/Flex'
import CustomCircularProgress from '../../Common/CustomCircularProgress'
import type { Theme } from '../../../theme'
import { CenteredText, VacantWrapper } from './styles'

type Props = {
  occupiedCount: number,
  vacantCount: number,
  total: number,
  theme: Theme,
  progressText: string,
  value: string
}

export class OccupancyCard extends React.Component<Props> {
  getContent = () => {
    const {
      occupiedCount,
      vacantCount,
      total,
      progressText,
      value,
      theme
    } = this.props
    return (
      <Flex fullWidth fullHeight>
        <Flex fullWidth fullHeight>
          <Flex flex={1} justifyLeft alignCenter>
            <div>
              <CustomCircularProgress
                variant="static"
                progressCaption={`of ${total} units`}
                progressText={progressText}
                infoText="OCCUPANCY"
                lgFont
                value={value}
                size={84}
                thickness={4}
                backgroundColor={({ theme }) => theme.palette.neutrals.coolGray}
                underlayColor={theme.palette.secondary.main1}
              />
            </div>
          </Flex>
          <Flex
            alignCenter
            fullWidth
            fullHeight
            flex={2}
            justifyCenter
            style={{
              background: theme.palette.neutrals.lightGray,
              marginRight: '-1.5em',
              marginTop: '0.1em'
            }}>
            <div>
              <CircleWithText
                data="units"
                dataCount={occupiedCount}
                backgroundColor={({ theme }) => theme.palette.primary.dark}
                textColor={({ theme }) => theme.palette.background.default}
              />
              <CenteredText>OCCUPIED</CenteredText>
            </div>
            <VacantWrapper>
              <CircleWithText
                data="units"
                dataCount={vacantCount}
                backgroundColor={({ theme }) => theme.palette.primary.dark}
                textColor={({ theme }) => theme.palette.background.default}
              />
              <CenteredText>VACANT</CenteredText>
            </VacantWrapper>
          </Flex>
        </Flex>
      </Flex>
    )
  }

  render() {
    return (
      <BaseCard
        title="Occupancy"
        columnStart={1}
        columnSpan={2}
        rowStart={1}
        rowSpan={3}
        content={this.getContent}
      />
    )
  }
}

export default withRouter(withTheme(OccupancyCard))
