// @flow
import React from 'react'
import { withRouter } from 'react-router-dom'
import Switch from '@material-ui/core/Switch'
import { FlexItem } from '@Common/Flex/FlexDirection'
import { withTheme } from 'emotion-theming'
import Typography from '@material-ui/core/Typography'
import BaseCard from '@Common/Cards/BaseCard/WideBaseCard'
import { CommonIcon } from '@icons'
import SwitchContent from './SwitchContent'
import Image from '../../../../images/thermostat_card.png'
import {
  SwitchDeviceProps as Props,
  SwitchDeviceState as State,
  handleSwitchNameChangeType
} from './types'
import {
  SwitchStatus,
  RightPanel,
  SwitchName,
  EditLink,
  SwitchWrap
} from './styles'
import { CircleWrap } from '../DevicesList/styles'

class SwitchDevice extends React.Component<Props, State> {
  state = {
    editNameMode: false,
    switchName: null
  }

  switchStates = {
    off: false,
    on: true
  }

  toggleSwitch = () => {
    const { pushSwitchStateCommand, id, identifier } = this.props
    pushSwitchStateCommand(id, identifier)
  }

  toggleEditSwitchNameMode = () => {
    this.setState({ editNameMode: !this.state.editNameMode })
  }

  handleSwitchNameChange = ({
    target: { value }
  }: handleSwitchNameChangeType) => {
    this.setState({ switchName: value })
  }

  hasValidName = () => {
    return this.state.switchName && this.state.switchName.trim().length !== 0
  }

  handleKeyPress = ({ key }: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      if (!this.hasValidName()) {
        this.props.showError(
          'A device name with a minimum of 2 characters that are not spaces is required, please enter a valid device name.'
        )
        return
      }
      this.saveSwitchName()
    }
  }

  saveSwitchName = () => {
    if (this.hasValidName()) {
      this.props.updateSwitchName(this.state.switchName, this.props.switchName)
    }
    this.toggleEditSwitchNameMode()
  }

  showDeleteDeviceModal = () => {
    const {
      history,
      match: { url }
    } = this.props
    history.push(`${url}/delete`)
  }

  render() {
    const { editNameMode } = this.state
    const {
      deviceModel,
      installationDate,
      lastUpdated,
      isFetching,
      loader,
      state,
      time,
      date,
      id,
      identifier,
      deviceStatus,
      theme,
      switchName,
      abnormal
    } = this.props

    return (
      <BaseCard
        title=" "
        imageSrc={Image}
        rowStart={1}
        rowSpan={3}
        columnStart={1}
        columnSpan={9}
        isFetching={isFetching}
        loader={loader}
        header={() => (
          <React.Fragment>
            <Typography variant="subheading">
              {editNameMode ? (
                <SwitchName
                  autoFocus
                  onBlur={this.saveSwitchName}
                  onChange={this.handleSwitchNameChange}
                  onKeyPress={this.handleKeyPress}
                  value={
                    this.state.switchName === null
                      ? switchName
                      : this.state.switchName
                  }
                />
              ) : (
                switchName
              )}
            </Typography>
            <EditLink
              onClick={this.toggleEditSwitchNameMode}
              style={{ alignSelf: 'center' }}>
              <CommonIcon
                name="edit"
                width="1em"
                height="1em"
                fill={theme.palette.primary.green.mint}
              />
            </EditLink>
          </React.Fragment>
        )}
        leftContent={() => (
          <SwitchContent
            abnormal={abnormal}
            deviceModel={deviceModel}
            deviceState={state}
            installationDate={installationDate}
            lastUpdated={lastUpdated}
            deviceStatus={deviceStatus}
            time={time}
            date={date}
            id={id}
            identifier={identifier}
          />
        )}
        rightContent={() => (
          <SwitchWrap>
            <FlexItem flex={0.5} />
            <FlexItem
              flex={0.5}
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
              {!abnormal && (
                <Switch
                  classes={{
                    root: 'switch-root',
                    switchBase: 'switch-base',
                    bar: 'bar',
                    icon: 'icon',
                    iconChecked: 'icon-checked',
                    checked: 'switch-checked'
                  }}
                  disableRipple={true}
                  checked={this.switchStates[state]}
                  onClick={this.toggleSwitch}
                />
              )}
            </FlexItem>
            <FlexItem flex={0.1} style={{ height: '100%' }}>
              <RightPanel>
                <SwitchStatus>
                  {abnormal ? (
                    <CircleWrap large onClick={this.showDeleteDeviceModal}>
                      <CommonIcon
                        name="close"
                        strokeWidth={5}
                        width={'1em'}
                        height={'1em'}
                        stroke={theme.palette.primary.green.mint}
                      />
                    </CircleWrap>
                  ) : (
                    state.toUpperCase()
                  )}
                </SwitchStatus>
              </RightPanel>
            </FlexItem>
          </SwitchWrap>
        )}
      />
    )
  }
}

export default withRouter(withTheme(SwitchDevice))
