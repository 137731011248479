import { createUpdateActions } from '../../helpers/actions/creators'

export const CONNECT_DEVICE = 'CONNECT_DEVICE'
export const CONNECT_DEVICE_SUCCESS = 'CONNECT_DEVICE_SUCCESS'
export const EXCLUDE_DEVICE = 'EXCLUDE_DEVICE'
export const EXCLUDE_DEVICE_SUCCESS = 'CONNECT_DEVICE_SUCCESS'

export const DEVICE_EVENT = 'DEVICE_EVENT'

export const ADDHUB = 'ADDHUB'

export const RUN_TEST = 'RUN_TEST'

export const SUBMIT_NAME = 'SUBMIT_NAME'

export const COMPLETE_ADD_DEVICE = 'COMPLETE_ADD_DEVICE'

export const CLEAR_CONNECTED_DEVICE = 'CLEAR_CONNECTED_DEVICE'

export const {
  updateAction,
  updateStartAction: loadAddHubStart,
  updateSuccessAction: loadAddHubSuccess,
  updateErrorAction: loadAddHubError
} = createUpdateActions(ADDHUB)

export const addHub = value => updateAction({ hubIdentifier: value })

export const connectDevice = () => ({
  type: CONNECT_DEVICE
})

export const excludeDevice = (url, history) => ({
  type: EXCLUDE_DEVICE,
  history,
  url
})

export const connectDeviceSuccess = device => ({
  type: CONNECT_DEVICE_SUCCESS,
  device
})

export const excludeDeviceSuccess = device => ({
  type: EXCLUDE_DEVICE_SUCCESS,
  device
})

export const deviceEvent = event => ({
  type: DEVICE_EVENT,
  event
})

export const runTest = selectedDeviceName => ({
  type: RUN_TEST,
  selectedDeviceName
})

export const submitName = deviceName => ({
  type: SUBMIT_NAME,
  deviceName
})

export const completeAddDevice = () => ({
  type: COMPLETE_ADD_DEVICE
})

export const clearConnectedDevice = () => ({
  type: CLEAR_CONNECTED_DEVICE
})
