// @flow
import React from 'react'
import { Moment } from 'moment'
import debounce from 'lodash/debounce'
import { BrowserHistory } from 'history'
import { withRouter } from 'react-router-dom'
import type { Match } from 'react-router-dom'
import InputAdornment from '@material-ui/core/InputAdornment'
import { withTheme } from 'emotion-theming'

import Grid, { GridItem } from '../../Common/Grid'
import Button from '@Common/Cards/CardButton'
import { CommonIcon, SearchIcon } from '@icons'
import CarouselList, { CarouselUnit } from '@Common/CarouselList'
import Loader from '../../Common/Loader'
import AlertModal from '@Common/AlertModal/AlertModal'
import { NavIcon } from '@icons'
import withScrollBehaviour from '@Common/CarouselList/withScrollBehaviour'
import Flex from '@Common/Flex'
import { FlexItem } from '@Common/Flex/FlexDirection'
import { FabButton } from '@Common/Button'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import { Theme } from '../../../theme'
import {
  Header,
  CodeDetailsContainer,
  CodesContainer,
  ButtonSet,
  RowsWrap,
  CodesWrap,
  TextField,
  CodeRow,
  Units,
  NoLockCodeFound
} from './styles'

const Carousel = withScrollBehaviour(CarouselList)

type Props = {
  items: Object[],
  theme: Theme,
  moment: Moment,
  isReminderPage: boolean,
  changePackageStatus: (name: string, id: number) => void,
  searchPackages: (term: string) => void,
  vendorCodes: Object[],
  history: BrowserHistory,
  match: Match,
  isFetching: boolean,
  resendVendorCode: (codeId: string) => mixed,
  invalidateVendorCode: (codeId: string) => mixed,
  fetchCodeUnits: (codeId: string) => mixed,
  searchVendorCodes: (name: string) => mixed,
  codeUnits: Object[],
  isFetchingUnits: boolean,
  vendorName: string,
  userRole: string,
}

type State = {
  currentPage: number,
  newItems: Object[],
  searchTerm: string,
  currentCode: Object,
  showDialog: boolean,
  showUnitsDetails: boolean
}

const detailsFields = [
  'Access Type',
  'Units',
  'Start Date',
  'End Date',
  'Phone',
  'Email',
  'Created By'
]

class VendorLockCodes extends React.Component<Props, State> {
  searchInput = null
  state = {
    searchTerm: '',
    currentPage: 0,
    newItems: [],
    currentCode: {},
    showDialog: false,
    showUnitsDetails: false
  }

  fabButtonIsDisabled = (this.props.userRole === "property_staff")

  fabButtons = [
    { name: 'plus', onClick: () => this.goToCreate(), disabled: this.fabButtonIsDisabled },
    { name: 'edit', onClick: () => this.goToEdit(), disabled: this.fabButtonIsDisabled },
    { name: 'close', onClick: () => this.showDialog(), disabled: this.fabButtonIsDisabled }
  ]

  componentDidUpdate(prevProps: Props) {
    const { items } = this.props
    if (prevProps.items !== items) {
      this.setState({ currentCode: this.getCurrentCode(items) })
    }
    this.setSearchInputFocus()
  }

  setSearchInputFocus = () => {
    if (this.searchInput) {
      this.searchInput.focus()
    }
  }

  goToCreate = () => {
    const {
      history,
      match: { url }
    } = this.props
    history.push(`${url.split('/code')[0]}/codes/create`)
  }

  goToEdit = () => {
    const {
      history,
      match: { url }
    } = this.props
    history.push(
      `${url.split('/code')[0]}/codes/edit/${this.state.currentCode.Id}`
    )
  }

  goBack = () => {
    const {
      history,
      match: { url }
    } = this.props
    history.push(url.split('/vendors')[0])
  }

  searchCodes = debounce(() => {
    const { searchVendorCodes } = this.props
    const { searchTerm } = this.state
    this.setState({ showUnitsDetails: false })
    searchVendorCodes(searchTerm)
  }, 400)

  handleSearchChange = ({ target: { value } }) => {
    this.setState({ searchTerm: value }, () => {
      this.searchCodes()
    })
  }

  handleKeyPress = ({ key }) => {
    if (key === 'Enter') {
      this.searchCodes()
    }
  }

  closeDialog = () => {
    this.setState({ showDialog: false })
  }

  showDialog = () => {
    this.setState({ showDialog: true })
  }

  handleCodeRowClick = code => () => {
    const { Id } = code
    const { currentCode } = this.state

    const {
      history,
      match: { url }
    } = this.props
    if (Id !== currentCode.Id) {
      history.push(`${url.split('/code')[0]}/codes/${Id}`)
      this.setState({ currentCode: code, showUnitsDetails: false })
    }
  }

  getCurrentCode = (codes: Object[] = []) => {
    const {
      match: {
        params: { codeId }
      }
    } = this.props
    const code = codes.find(code => code.Id === codeId)
    return code || codes[0] || {}
  }

  resendCode = () => {
    const { resendVendorCode } = this.props
    const {
      currentCode: { Id }
    } = this.state
    Id && resendVendorCode(Id)
  }

  inValidateCode = () => {
    const { invalidateVendorCode } = this.props
    const {
      currentCode: { Id }
    } = this.state
    Id && invalidateVendorCode(Id)
    this.closeDialog()
  }

  fetchCodeUnits = () => {
    const {
      currentCode: { Id }
    } = this.state
    this.props.fetchCodeUnits(Id)
    this.toggleShowUnits()
  }

  toggleShowUnits = () => {
    const { showUnitsDetails } = this.state
    this.setState({ showUnitsDetails: !showUnitsDetails })
  }

  createUnits = ({
    count,
    handleClick,
    ...props
  }: {
    count: string,
    handleClick?: Function
  }) => {
    return (
      // $FlowDisableNextLine
      <Units
        theme={this.props.theme}
        flex={2}
        displayFlex
        flexEnd
        alignItemsCenter
        onClick={handleClick}
        {...props}>
        {count}
        <CommonIcon name={'arrow_right'} width="1.2em" height="0.8em" />
      </Units>
    )
  }

  createCodeDetailsRows = () => {
    const { currentCode } = this.state
    return (
      <RowsWrap>
        {detailsFields.map((field, index) => {
          const isUnits =
            currentCode['Access Type'] === 'Unit' && field === 'Units'
          return (
            <CarouselUnit key={index}>
              <Flex fullWidth>
                <FlexItem flex={1}>{field}</FlexItem>
                <FlexItem flex={2} displayFlex flexEnd>
                  {!isUnits && currentCode[field]}
                  {isUnits &&
                    this.createUnits({
                      count: currentCode['Units Count'],
                      handleClick: this.fetchCodeUnits
                    })}
                </FlexItem>
                <FlexItem flex={1} />
              </Flex>
            </CarouselUnit>
          )
        })}
      </RowsWrap>
    )
  }

  createCodeUnitsRows = () => {
    const { codeUnits, theme, isFetchingUnits } = this.props
    const { currentCode } = this.state
    if (isFetchingUnits) {
      return <Loader />
    }
    return (
      <React.Fragment>
        {this.createUnits({
          count: currentCode['Units Count'],
          className: 'unit',
          handleClick: this.toggleShowUnits
        })}
        <RowsWrap>
          {codeUnits.map(({ name, hubStatus }, index) => {
            return (
              <CarouselUnit key={index}>
                <Flex fullWidth>
                  <FlexItem
                    flex={1}
                    displayFlex
                    alignItemsCenter
                    className="hub-text">
                    {name}
                  </FlexItem>
                  <FlexItem
                    alignItemsCenter
                    flex={2}
                    displayFlex
                    flexEnd
                    className={`hub-${hubStatus}`}>
                    <NavIcon
                      name="zego smart"
                      width="1em"
                      height="1em"
                      fill={
                        theme.palette[hubStatus === 'on' ? 'secondary' : 'grey']
                          .main
                      }
                    />
                    &nbsp;HUB {hubStatus.toUpperCase()}
                  </FlexItem>
                  <FlexItem flex={1} />
                </Flex>
              </CarouselUnit>
            )
          })}
        </RowsWrap>
      </React.Fragment>
    )
  }

  createCodeRow = (code, index, id) => {
    return (
      <CodeRow
        key={index}
        onClick={this.handleCodeRowClick(code)}
        role="presentation">
        <CarouselUnit className={`row ${code.Id === id ? 'selected' : ''}`}>
          <Flex fullWidth paddingLeft="9em">
            <FlexItem flex={1} displayFlex flexEnd alignItemsCenter>
              {code['Start Date']}
            </FlexItem>
            <FlexItem flex={1} flexCenter alignItemsCenter displayFlex>
              <CommonIcon name="bullet" width="0.4em" height="0.4em" />
            </FlexItem>
            <FlexItem flex={2} displayFlex alignItemsCenter>
              {code['End Date']}
            </FlexItem>
            <FlexItem flex={4} displayFlex flexEnd alignItemsCenter>
              {code['Units Count']}{' '}
              <CommonIcon name="arrow_right" width="1.2em" height="0.8em" />
            </FlexItem>
          </Flex>
        </CarouselUnit>
      </CodeRow>
    )
  }

  createCodesRows = () => {
    const {
      currentCode: { Id },
      searchTerm
    } = this.state
    const { items } = this.props
    const hasData = items && items.length > 0
    let rowUnits
    if (!hasData) {
      rowUnits = [<NoLockCodeFound>No lock code found</NoLockCodeFound>]
    } else {
      rowUnits = items.map((code, index) => this.createCodeRow(code, index, Id))
    }

    return (
      <CodesWrap>
        <Carousel
          items={[
            <CarouselUnit height={'5em'} className="row first">
              <Flex fullWidth paddingLeft="9em">
                <FlexItem flex={1} displayFlex flexEnd alignItemsCenter>
                  START DATE
                </FlexItem>
                <FlexItem flex={0.95} />
                <FlexItem flex={2} displayFlex alignItemsCenter>
                  END DATE
                </FlexItem>
                <FlexItem flex={4} displayFlex flexEnd alignItemsCenter>
                  <TextField
                    value={searchTerm}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            name="search"
                            height="20px"
                            width="18px"
                            onClick={this.searchCodes}
                            className="searchIcon"
                          />
                        </InputAdornment>
                      )
                    }}
                    onChange={this.handleSearchChange}
                    onKeyPress={this.handleKeyPress}
                    inputRef={input => {
                      this.searchInput = input
                    }}
                  />
                </FlexItem>
              </Flex>
            </CarouselUnit>,
            ...rowUnits
          ]}
        />
      </CodesWrap>
    )
  }

  createCodeDetails = () => {
    const { theme, isFetchingUnits } = this.props
    const { currentCode, showUnitsDetails } = this.state
    const hasDetails = currentCode.Id
    return (
      <CodeDetailsContainer>
        <Flex fullWidth>
          <FlexItem flex={1}>
            <Header big>
              <div>CODE DETAILS</div>
            </Header>
            <Flex direction="column" paddingTop="24%" fullHeight fullWidth>
              {!showUnitsDetails && this.createCodeDetailsRows()}
              {showUnitsDetails && this.createCodeUnitsRows()}
              <FlexItem
                flex={1}
                displayFlex
                direction="column"
                flexEnd
                style={{ marginBottom: '2em' }}>
                <br />
                <br />
                {hasDetails && !isFetchingUnits && (
                  <Button
                    text="RESEND CODE"
                    background={theme.palette.blue.main}
                    width="70%"
                    height="2.5em"
                    onClick={this.resendCode}
                  />
                )}
              </FlexItem>
            </Flex>
          </FlexItem>
          <ButtonSet>
            <FabButton
              color="primary"
              background={theme.palette.secondary.yellow.solarFlare}
              onClick={this.goBack}
              component={() => (
                <KeyboardBackspace
                  style={{ color: theme.palette.primary.dark }}
                  height="0.5em"
                  width="0.5em"
                />
              )}
            />
            {this.fabButtons.map(({ disabled, name, onClick }, index) => {
              if (hasDetails || name === 'plus') {
                return (
                  <FabButton
                    onClick={onClick}
                    variant="fab"
                    background="transparent"
                    style={ disabled ? {
                      border: `3px solid ${theme.palette.neutrals.mediumGray}`
                    } : null}
                    disabled={disabled}
                    outlined
                    component={() => (
                      <CommonIcon
                        name={name}
                        width="1em"
                        height="1em"
                        fill={theme.palette.primary.navy.midnight}
                        strokeWidth={2}
                        stroke={theme.palette.primary.navy.midnight}
                      />
                    )}
                  />
                )
              }
              return null
            })}
          </ButtonSet>
        </Flex>
      </CodeDetailsContainer>
    )
  }

  render() {
    const { isFetching, theme, vendorName } = this.props
    const { searchTerm } = this.state
    if (isFetching && !searchTerm) {
      return <Loader />
    }
    return (
      <React.Fragment>
        <GridItem columnStart={1} columnSpan={8} rowStart={1} rowSpan={12}>
          <CodesContainer>
            <Grid>
              <GridItem
                style={{ position: 'relative' }}
                columnStart={1}
                columnSpan={12}
                rowStart={1}
                rowSpan={12}>
                <Header style={{ height: 60 }}>
                  <div>LOCK CODES</div>
                  <div style={{ textTransform: 'uppercase' }}>{vendorName}</div>
                </Header>
                <Flex
                  className="container"
                  direction="column"
                  paddingTop={'4vh'}
                  fullWidth
                  style={{ height: '88%' }}>
                  {this.createCodesRows()}
                </Flex>
              </GridItem>
            </Grid>
          </CodesContainer>
        </GridItem>
        <GridItem columnStart={8} columnSpan={5} rowStart={1} rowSpan={12}>
          {this.createCodeDetails()}
        </GridItem>
        <AlertModal
          title="Are you sure you wish to delete this code?"
          message=""
          buttons={[
            {
              onClick: this.inValidateCode,
              text: 'YES'
            },
            {
              onClick: this.closeDialog,
              color: theme.palette.primary.navy.spaceBlue,
              text: 'NO'
            }
          ]}
          show={this.state.showDialog}
          onClose={this.closeDialog}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(withTheme(VendorLockCodes))
