import React from 'react'
import MaterialTooltip from '@material-ui/core/Tooltip'
import { CommonIcon } from '@icons'
import TooltipData from './TooltipContent'
import { IconContainer } from './styles'

const Tooltip = ({ type, tooltipContent }: Props) => (
  <MaterialTooltip placement="bottom-start" title={TooltipData[type]}>
    {tooltipContent ? (
      tooltipContent
    ) : (
      <IconContainer>
        <CommonIcon name="tooltip" height="10px" width="10px" />
      </IconContainer>
    )}
  </MaterialTooltip>
)

export default Tooltip
