// @flow
import React from 'react'
import { GroupOption } from '../CreateGroupMessage/styles'
import { FlexItem } from '@Common/Flex/FlexDirection'

const customOption = (
  {
    innerProps,
    label,
    value,
    data
  }: { label: string, data: Object, value: number, innerProps: Object },
  nestedResidents: Object | null,
  showRecipients: Function
) => (
  <GroupOption
    active={nestedResidents && nestedResidents.groupId === value}
    onMouseEnter={showRecipients.bind(this, value)}
    {...innerProps}>
    <FlexItem flex={4} id={label.replace(/\s|@/g, '')}>{label}</FlexItem>
    <FlexItem flex={1}>
      {label === '@BalanceDue' ? (data.count ? data.count : '-') : data.count}
    </FlexItem>
  </GroupOption>
)

export default customOption
