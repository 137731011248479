// @flow

import get from 'lodash/get'

export const getResidentManagementMoveinMoveoutData = (state: Object) => {
  return get(state, ['moveInMoveOut', 'residentMoveInMoveOut', 'residents'])
}

export const getResidentManagementMoveinMoveoutLoading = (state: Object) => {
  return get(state, ['moveInMoveOut', 'residentMoveInMoveOut', 'isLoadingResidents'])
}

export const getFilteredDataCount = (state: Object) => {
  return get(state, ['moveInMoveOut', 'moveInCounts', 'filteredResidentCount'])
}

export const getMoveInResidentsCount = (state: Object) => {
  return get(state, ['moveInMoveOut', 'moveInCounts', 'totalResidentCount'])
}

export const getIssueCount = (state: Object) => {
  return get(state, ['moveInMoveOut', 'moveInCounts', 'issueCount'])
}

export const getNoIssueCount = (state: Object) => {
  return get(state, ['moveInMoveOut', 'moveInCounts', 'noIssueCount'])
}

export const getAwaitingApprovalCount = (state: Object) => {
  return get(state, ['moveInMoveOut', 'moveInCounts', 'awaitingApprovalCount'])
}

export const getTotalApprovalStatusCount = (state: Object) => {
  return get(state, ['moveInMoveOut', 'moveInCounts', 'totalApprovalStatusCount'])
}

export const getMimoChecklistSummary = (state: Object) => {
  return get(state, ['moveInMoveOut', 'residentMoveInMoveOut', 'checklistSummary'])
}

export const getMimoChecklistSummaryLoading = (state: Object) => {
  return get(state, ['moveInMoveOut', 'residentMoveInMoveOut', 'isLoadingSummary'])
}

export const getMimoChecklistStatus = (state: Object) => {
  return get(state, ['moveInMoveOut', 'residentMoveInMoveOut', 'checklistInfo', 'checklistStatus'])
}

export const getMimoApprovalStatus = (state: Object) => {
  return get(state, ['moveInMoveOut', 'residentMoveInMoveOut', 'checklistInfo', 'approvalStatus'])
}

export const getMimoChecklistWorkOrder = (state: Object) => {
  return get(state, ['moveInMoveOut', 'residentMoveInMoveOut', 'checklistInfo'])
}

export const getIsMimoChecklistSubmitting = (state: Object) => {
  return get(state, ['moveInMoveOut', 'moveInSettings', 'isSubmittingSettings'])
}

export const getIsLoadingMimoChecklistSetting = (state: Object) => {
  return get(state, ['moveInMoveOut', 'moveInSettings', 'isLoadingSettings'])
}

export const getSavedMimoChecklistSettings = (state: Object) => {
  return get(state, ['moveInMoveOut', 'moveInSettings', 'savedChecklistSettings'])
}

export const getIsSuccessMimoChecklistSetting = (state: Object) => {
  return get(state, ['moveInMoveOut', 'moveInSettings', 'isSubmitSuccess'])
}

export const getMimoChecklistPdfSubmitting = (state: Object) => {
  return get(state, ['moveInMoveOut', 'moveInPdf', 'isSubmittingPdf'])
}

export const SubmitMimoResetCheckList = (state: Object) => {
  return get(state, ['moveInMoveOut', 'moveInResetCheckList', 'resetCheckList'])
}