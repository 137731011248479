// @flow

import React from 'react'
import { connect } from 'react-redux'

import { ldInitRequest } from '../../integrations/LaunchDarkly/actions'

import {
  isProAmenitySel,
} from 'zego-shared/store/amenitiesShared'

import AmenityDashboardVersionSelector from './AmenityDashboardVersionSelector'

const mapStateToProps = state => ({
  isProAmenity: isProAmenitySel(state),
})

const mapDispatchToProps = (dispatch) => ({
  ldInitRequest: () => dispatch(ldInitRequest()),
})

class AmenityDashboardVersionSelectorContainer extends React.Component<any, any> {
  render() {
    const { props } = this

    return <AmenityDashboardVersionSelector {...props}/>
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AmenityDashboardVersionSelectorContainer)
