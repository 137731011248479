// import React, { Component } from 'react'
import { connect } from 'react-redux'
import HubTab from './HubTab'
import { getHubProperties } from '../../../../store/Unit/selectors'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'

const fetchingSelector = createIsFetchingSelector(['devices'])

const mapStateToProps = state => ({
  isFetching: fetchingSelector(state),
  data: getHubProperties(state)
})

export default connect(mapStateToProps)(HubTab)
