// @flow
import React from 'react'
import moment from 'moment'
import queryString from 'query-string'
import debounce from 'lodash/debounce'
import { withTheme } from 'emotion-theming'
import { withRouter } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import { generate as shortid } from 'shortid'
import { Stepper } from '@material-ui/core'
import Button from '@Common/Cards/CardButton'
import { StepLabel } from '@material-ui/core'
import { StepContent } from '@material-ui/core'
import { StepConnector } from '@material-ui/core'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import { SelectorIcons, CommonIcon } from '@icons'
import DeliveryMethodRadioGroup from './DeliveryMethodRadioGroup'
import TemplateEditor from './TemplateEditor'
import SendGroupMessageScheduler from './SendGroupMessageScheduler'
import withDialog from '@Common/AlertModal/withAlertModal'
import FlexDirection, {
  FlexItem
} from '@Common/Flex/FlexDirection'
import { CHAT_METHOD_OPTION } from '../../constants'
import { onceAYearDateFormat } from '../../../../utils'
import StatusScreen, { StatusScreenContent } from '../../../Common/StatusScreen'
import { RecipientsList } from './styles'
import { CarouselUnit } from '@Common/CarouselList'
import Flex from '@Common/Flex/FlexDirection'
import Avatar from '@Common/Avatar'
import customOption from './CustomOption'
import AlertModal from '@Common/AlertModal/AlertModal'
import api from 'zego-shared/utils/api'
import Loader from '../../../Common/Loader'
import {
  CreateGroupMessageState as State,
  CreateGroupMessageProps as Props
} from '../types'
import {
  Wrapper,
  ClearFix,
  StepDescription,
  StepIndicator,
  CustomStep,
  CustomModal,
  FabButton,
  RecipientsFloatingDiv
} from '../CreateGroupMessage/styles'

const steps = ['Delivery Method', 'Recipients', 'Message', 'Send']
const randomId = shortid()

class CreateGroupMessage extends React.Component<Props, State> {
  state = {
    activeStep: 0,
    nestedResidents: null,
    selectedDeliveryMethods: ['preferred'],
    recipients: '',
    saveAsTemplate: false,
    selectedTemplateId: 0,
    sendMessageSuccess: false,
    stepStatus: { '0': true, '1': false, '2': false, '3': false },
    isSearchingGroups: false,
    selectedEventId: randomId,
    selectedGroups: [],
    selectedGroupsUsers: [],
    groups: [],
    offsetDays: "0",
    offsetBeforeAfter: 'before',
    selectedDateTime: {
      text: `Once a year on ${onceAYearDateFormat(moment())}`,
      moment: moment(),
      recurringOptionName: 'once a year'
    },
    dateTimeCalendarMoment: moment(),
    message: {
      name: '',
      message: '',
      title: '',
      templateName: '',
      textMessage: '',
      attachments: {},
      files: []
    },
    defaultEvent: {
      id: randomId,
      name: 'select an event'
    },
    showDialog: false,
    defaultOptions: [],
    groupEvents: [],
    showChatOption: false
  }

  mounted = false

  componentDidMount() {
    if (this.props.userRole !== "property_staff") {
      this.loadDefaultOptions()
      this.mounted = true
      if (this.mounted) {
        this.props.resetStatuses()
      }
      this.props.ldInitRequest()
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  componentDidUpdate(prevProps) {
    const { location, sendMessageSuccess, continueSendMessage, featureFlag } = this.props
    const chatOptionFlag = (featureFlag && featureFlag.ld && featureFlag.ld.variation(CHAT_METHOD_OPTION, false))
    if(this.state.showChatOption !== chatOptionFlag) {
      this.setState({ showChatOption: chatOptionFlag })
    }


    if (!this.state.sendMessageSuccess && sendMessageSuccess) {
      this.setState({ sendMessageSuccess: true })
    }

    if (
      continueSendMessage &&
      continueSendMessage !== prevProps.continueSendMessage
    ) {
      this.sendMessage(null)
    }

    // send option changed make them click select again to choose new options
    if (
      queryString.parse(prevProps.location.search).type !==
      queryString.parse(location.search).type
    ) {
      this.setState({
        stepStatus: { ...this.state.stepStatus, '3': false }
      })
    }
  }

  handleDeliveryMethodChange = event => {
    const checked = event.target.checked
    const name = event.target.name

    this.setState(({ selectedDeliveryMethods, stepStatus }) => {
      if (checked) {
        return {
          selectedDeliveryMethods: [...selectedDeliveryMethods, name],
          stepStatus: {
            ...stepStatus,
            '0': true
          }
        }
      } else {
        return selectedDeliveryMethods.length > 1
          ? {
              selectedDeliveryMethods: selectedDeliveryMethods.filter(
                method => method !== name
              )
            }
          : null
      }
    }, this.handleTemplateValidation)
  }

  loadDefaultOptions = () => {
    const { getDefaultGroups, authToken, propertyId } = this.props

    getDefaultGroups(authToken, propertyId).then(groups => {
      this.setState({
        defaultOptions: groups
      })
    })
  }

  handleGroupSearch = searchTerm => {
    const { searchGroups, authToken, propertyId } = this.props
    return new Promise(resolve => {
      const slashAndQuestionMarkCheck =
        searchTerm.charAt(1) !== '/' && searchTerm.charAt(1) !== '?'
      if (
        searchTerm.charAt(0) === '@' &&
        searchTerm.length !== 1 &&
        slashAndQuestionMarkCheck
      ) {
        // remove the @ sign because the backend stores the group without it
        const searchTermWithoutAtSign = searchTerm.substring(
          1,
          searchTerm.length
        )
        searchGroups(authToken, propertyId, searchTermWithoutAtSign).then(
          groups => {
            resolve(groups)
          }
        )
      } else {
        resolve()
      }
    })
  }

  handleGroupSelect = selections => {
    if (!selections?.length) {
      this.props.getGroupUsers([])
      this.setState(prevState => ({
        ...prevState,
        selectedGroups: [],
        stepStatus: { ...this.state.stepStatus, '1': false },
        nestedResidents: null
      }))
      return
    }
    this.props.getGroupUsers(selections.map(selection => selection.value))
    this.setState(prevState => ({
      ...prevState,
      selectedGroups: selections,
      stepStatus: { ...this.state.stepStatus, '1': true },
      nestedResidents: null
    }))
  }

  handleMessageChange = (event, value) => {
    const { message } = this.state
    this.setState(
      {
        message: {
          ...message,
          [event.target.name]: event.target.value
        }
      },
      this.handleTemplateValidation
    )
  }

  handleEmailMessageChange = value => {
    const { message } = this.state
    this.setState(
      {
        message: {
          ...message,
          message: value
        }
      },
      this.handleTemplateValidation
    )
  }

  handleMergeFieldChange = (key, value) => {
    const { message } = this.state
    this.setState(
      {
        message: {
          ...message,
          [key]: value
        }
      },
      this.handleTemplateValidation
    )
  }

  handleTemplateValidation = () => {
    const {
      saveAsTemplate,
      selectedDeliveryMethods,
      message: { title, message, textMessage, templateName }
    } = this.state

    const isTemplateValid =
      (saveAsTemplate && templateName) || saveAsTemplate === false

    const isEmail = selectedDeliveryMethods.some(
      method => method === 'preferred' || method === 'email'
    )

    const isTextMessage = selectedDeliveryMethods.some(
      method =>
        ['preferred', 'text', 'in-app', 'chat'].includes(method)
    )

    if (
      // email and text so validate everything
      (isEmail &&
        isTextMessage &&
        title &&
        message &&
        textMessage &&
        isTemplateValid) ||
      // only email so validate email fields
      (isEmail && !isTextMessage && title && message && isTemplateValid) ||
      // only text so validate text fields
      (isTextMessage && !isEmail && textMessage && isTemplateValid)
    ) {
      this.setState({
        stepStatus: { ...this.state.stepStatus, '2': true }
      })
    } else {
      this.setState({
        stepStatus: { ...this.state.stepStatus, '2': false }
      })
    }
  }

  updateMessageState = updates => {
    const { message } = this.state
    this.setState(
      {
        message: {
          ...message,
          ...updates
        }
      },
      this.handleTemplateValidation
    )
  }

  removeAttachment = (attachment: string) => {
    const {
      message: { attachments }
    } = this.state
    delete attachments[attachment]
    this.updateMessageState({ attachments })
  }

  removeFile = debounce((fileToRemove: any) => {
    const {
      message: { files }
    } = this.state
    const newFiles = files.filter(file => file.name !== fileToRemove.name)
    this.updateMessageState({ files: newFiles })
  }, 200)

  addAttachment = debounce(file => {
    const {
      message: { files }
    } = this.state
    this.updateMessageState({ files: [file, ...(files || [])] })
  }, 200)

  handleSaveAsTemplate = event => {
    const isChecked = event.target.checked
    const { message, stepStatus } = this.state

    if (isChecked && message.templateName) {
      this.setState({
        saveAsTemplate: isChecked,
        stepStatus: { ...stepStatus, '2': true }
      })
    }
    if (isChecked && message.templateName === '') {
      this.setState({
        saveAsTemplate: isChecked,
        stepStatus: { ...stepStatus, '2': false },
        message: {
          ...message,
          templateName: ''
        }
      })
    } else {
      this.setState({
        saveAsTemplate: isChecked,
        stepStatus: { ...stepStatus, '2': true },
        message: {
          ...message,
          templateName: ''
        }
      })
    }
  }

  chooseTemplate = value => {
    const selectedEventId = this.state.selectedTemplateId === value ? this.state.selectedEventId : randomId;
    if (value === 0) {
      this.setState({
        message: {
          ...this.state.message,
          message: '',
          textMessage: '',
          title: ''
        },
        selectedEventId: selectedEventId,
        selectedTemplateId: value,
        stepStatus: { ...this.state.stepStatus, '2': false }
      })
    } else {
      const { templates } = this.props
      const template = templates.find(template => template.id === value)
      this.setState({
        message: {
          ...this.state.message,
          message: template.message,
          textMessage: template.textMessage,
          title: template.title,
          attachments: template.attachments
        },
        selectedEventId: selectedEventId,
        selectedTemplateId: value,
        stepStatus: { ...this.state.stepStatus, '2': true }
      })
    }
  }

  handleEventChange = event => {
    const template = this.props.templates.find(
      template => template.id === this.state.selectedTemplateId
    )

    this.setState({
      selectedEventId: event,
      groupEvents:  template.groupEvents,
      stepStatus: { ...this.state.stepStatus, '3': false }
    })
  }

  handleOffsetDays = ({ target: { value } }) => {
    value > -1 &&
      this.setState({
        offsetDays: value,
        stepStatus: { ...this.state.stepStatus, '3': false }
      })
  }
  
  handleEmptyOffsetDays = ({ currentTarget: { value } }) => {
    this.setState({
      offsetDays: value.replace(/\D/g, '') === '' ? '0' : value,
      stepStatus: { ...this.state.stepStatus, '3': false }
    })
  }

  handleOffsetBeforeAfter = offsetBeforeAfter => {
    this.setState({
      offsetBeforeAfter,
      stepStatus: { ...this.state.stepStatus, '3': false }
    })
  }

  showRecipients = async id => {
    this.setState({
      nestedResidents: {
        groupId: id,
        users: await this.props.getGroupResidents(id),
        name: await this.props.getGroupLabel(id)
      }
    })
  }

  handleSelectedDateTimeChange = (text, moment, recurringOptionName) => {
    this.setState({
      selectedDateTime: {
        text,
        moment,
        recurringOptionName
      },
      stepStatus: { ...this.state.stepStatus, '3': false }
    })
  }

  selectSendMethod = () => {
    const selectedType =
      queryString.parse(this.props.location.search).type || ''

    if (this.state.selectedEventId !== randomId || selectedType !== 'event') {
      this.setState({
        stepStatus: { ...this.state.stepStatus, '3': true },
        activeStep: 4
      })
    } else {
      this.props.showSnackbar('Please select an event')
    }
  }

  stepContent = activeStep => {
    switch (activeStep) {
      case 0:
        return {
          description: `Choose which method you would like to use to send this message. You can send a message via text, in app notification, email${this.state.showChatOption ? ', chat' : ''} or preferred. Preferred will send the message to the recipient’s preferred method of contact and in app notification.`,
          body: (
            <DeliveryMethodRadioGroup
              selectedDeliveryMethods={this.state.selectedDeliveryMethods}
              handleDeliveryMethodChange={this.handleDeliveryMethodChange}
              showChatOption={this.state.showChatOption}
              ldInitRequest={this.props.ldInitRequest}
            />
          )
        }
      case 1:
        return {
          description:
            'Please type in the group name you would like to send a message. In order to address the message correctly be sure to insert the @ sign before the group.',
          body: (
            <div style={{ marginTop: '1em' }}>
              <div>
                <AsyncSelect
                  id="recipientDropDown"
                  isMulti
                  components={{
                    Option: props =>
                      customOption(
                        props,
                        this.state.nestedResidents,
                        this.showRecipients
                      )
                  }}
                  openMenuOnClick={true}
                  noOptionsMessage={({ inputValue }) =>
                    inputValue &&
                    inputValue.charAt(0) === '@' &&
                    inputValue.length > 1
                      ? 'No matches found'
                      : null
                  }
                  onBlur={this.showRecipients.bind(this, null)}
                  defaultValue={this.state.selectedGroups}
                  defaultOptions={this.state.defaultOptions}
                  loadOptions={this.handleGroupSearch}
                  onChange={this.handleGroupSelect}
                  styles={{
                    container: styles => ({
                      ...styles,
                      marginTop: '1em',
                      marginBottom: '1em',
                      marginRight: '3em'
                    }),
                    menu: styles => ({
                      ...styles,
                      top: '',
                      position: 'fixed',
                      width: '25%'
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => ({
                      ...styles,
                      backgroundColor: isFocused
                        ? null
                        : isFocused
                        ? 'red'
                        : null
                    })
                  }}
                />

                {this.state.nestedResidents &&
                  this.state.nestedResidents.users && (
                    <RecipientsFloatingDiv
                      onMouseEnter={this.showRecipients.bind(
                        this,
                        this.state.nestedResidents.groupId
                      )}
                      onMouseLeave={this.showRecipients.bind(this, null)}>
                      {this.state.nestedResidents && this.state.nestedResidents.users && this.state.nestedResidents.users.length > 0 ? (
                        this.state.nestedResidents.users.map(
                          (resident, index) => (
                            <Flex key={index}>
                              <FlexItem flex={3}>{resident.name}</FlexItem>
                              <FlexItem flex={1}>{resident.unit}</FlexItem>
                            </Flex>
                          )
                        )
                      ) : (
                        <center>
                          {this.state.nestedResidents && this.state.nestedResidents.name === 'BalanceDue' ? (
                            <span
                              style={{
                                whiteSpace: 'normal',
                                textAlign: 'left'
                              }}>
                              Recipients will be added to this group based on
                              their current balance immediately prior to the
                              message being sent
                            </span>
                          ) : (
                            'No residents exist'
                          )}
                        </center>
                      )}
                    </RecipientsFloatingDiv>
                  )}
              </div>
              <RecipientsList>
                {this.props.groupsUsers
                  ? this.props.groupsUsers.map(
                      ({ name, unit, picture }, index) => (
                        <CarouselUnit height="2em" key={index}>
                          <Flex fullWidth>
                            <FlexItem flex={1}>
                              <Avatar
                                size={'1.5em'}
                                picture={picture}
                                hideShadow
                              />
                            </FlexItem>
                            <FlexItem flex={9}>{name}</FlexItem>
                            <FlexItem className="unit-section" flex={2}>
                              Unit {unit}
                            </FlexItem>
                          </Flex>
                        </CarouselUnit>
                      )
                    )
                  : this.props.groupsUsersLoading
                  ? 'Loading...'
                  : null}
              </RecipientsList>
            </div>
          )
        }
      case 2:
        return {
          description: `Select a previously created template, or create your own custom message. Remember to save new messages as a template if you wish to use it again in the future.`,
          body: (
            <TemplateEditor
              chooseTemplate={this.chooseTemplate}
              theme={this.props.theme}
              handleMessageChange={this.handleMessageChange}
              handleEmailMessageChange={this.handleEmailMessageChange}
              handleMergeFieldChange={this.handleMergeFieldChange}
              handleSaveAsTemplate={this.handleSaveAsTemplate}
              templates={this.props.templates}
              saveAsTemplate={this.state.saveAsTemplate}
              addAttachment={this.addAttachment}
              removeAttachment={this.removeAttachment}
              removeFile={this.removeFile}
              message={this.state.message}
              replacementOptions={this.props.replacementOptions}
              selectedDeliveryMethods={this.state.selectedDeliveryMethods}
              showChatOption={this.state.showChatOption}
              selectedTemplate={
                this.props.templates.find(
                  template => template.id === this.state.selectedTemplateId
                ) || this.props.templates[0]
              }
            />
          )
        }
      case 3:
        return {
          description: `Choose when you would like to send your message. You can send it now, set to send on a specific future event like move out, or at a future date & time.`,
          body: (
            <SendGroupMessageScheduler
              selectSendMethod={this.selectSendMethod}
              selectedDateTime={this.state.selectedDateTime.text}
              handleEventChange={this.handleEventChange}
              defaultEvent={this.state.defaultEvent}
              handleOffsetDays={this.handleOffsetDays}
              handleEmptyOffsetDays={this.handleEmptyOffsetDays}
              offsetDays={this.state.offsetDays}
              offsetBeforeAfter={this.state.offsetBeforeAfter}
              handleOffsetBeforeAfter={this.handleOffsetBeforeAfter}
              handleSelectedDateTimeChange={this.handleSelectedDateTimeChange}
              selectedEvent={
                this.state.groupEvents.find(
                  event => event.id === this.state.selectedEventId
                ) || this.state.defaultEvent
              }
              selectedTemplate={
                this.props.templates.find(
                  template => template.id === this.state.selectedTemplateId
                )
              }
            />
          )
        }
      default:
        return {
          description: null,
          body: null
        }
    }
  }

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }))
  }

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }))
  }
  
  allPreviousStepsComplete = (step) => {
    let s = 1
    while (s < step) {
      if (!this.state.stepStatus[s]) { 
        return false 
      }
      s += 1
    }
    return true
  }

  handleStepChange = index => {
    const {
      state: { stepStatus, activeStep } 
    } = this
    
    if (index === activeStep) {
      if (stepStatus[index]) {
        this.setState((oldState) => ({
          ...oldState,
          activeStep: index + 1
        }))
        return
      }
      if (index !== 0) {
        this.setState((oldState) => ({
          ...oldState,
          activeStep: index - 1
        }))
      }
      return
    }
    if (this.allPreviousStepsComplete(index)) {
      this.setState((oldState) => ({
        ...oldState,  
        activeStep: index 
      }))
    }
  }

  trySaveTemplate = async () => {
    const { authToken, propertyId, showAlertModal } = this.props
    const { message } = this.state

    if (message.templateName) {
      try {
        let newTemplate = await api.createGroupMessagingTemplate(
          authToken,
          propertyId,
          'properties',
          {
            title: message.title,
            name: message.templateName,
            body: message.message,
            text_body: message.textMessage,
            resourceType: 'property',
            attachments: {}
          }
        )

        this.sendMessage(newTemplate)
      } catch (error) {
        showAlertModal()
      }
    }
  }

  sendMessage = (newTemplate = null) => {
    const { templates, location, groupEvents, timezone } = this.props
    const {
      message,
      selectedTemplateId: templateId,
      selectedGroups,
      selectedDeliveryMethods,
      selectedDateTime,
      selectedEventId,
      offsetBeforeAfter,
      offsetDays
    } = this.state

    const selectedType = queryString.parse(location.search).type || ''

    const selectedTemplateId = newTemplate ? newTemplate.id : templateId

    this.props.sendGroupMessage(
      selectedType,
      [...templates, newTemplate],
      message,
      selectedTemplateId,
      selectedGroups,
      selectedDeliveryMethods,
      selectedDateTime,
      groupEvents,
      selectedEventId,
      offsetBeforeAfter,
      offsetDays,
      timezone
    )
  }

  isFormComplete = () => {
    return Object.values(this.state.stepStatus).every(value => value)
  }

  goBack = () => {
    const {
      history,
      match: { url }
    } = this.props
    history.push(`${url.split('/create')[0]}`)
  }

  closeAlertModal = () => {
    this.setState({ showDialog: false })
  }

  openDialog = () => {
    this.setState({ showDialog: true })
  }

  render() {
    const { isFetching, hasData, theme, location, history, userRole, propertyId, displayErrorMessage } = this.props
    const {
      activeStep,
      sendMessageSuccess,
      stepStatus,
      message,
      showDialog
    } = this.state

    if (userRole === "property_staff") {
      history.replace(`/properties/${propertyId}/communication/group-messaging`)
      setTimeout(() => {
        displayErrorMessage("Group Messaging Templates are not available for Property Staff.")
      }, 1000)
  
      return null
    }

    const stepContent = this.stepContent(activeStep)
    const selectedType = queryString.parse(location.search).type || ''

    return (
      <CustomModal open={true}>
        <React.Fragment>
          <StatusScreen
            open={sendMessageSuccess}
            autoHideDuration={2000}
            goTo={this.goBack}>
            <StatusScreenContent
              type='success'
              heading="SUCCESS!"
              subheading={`Your message has been ${
                selectedType === '' ? 'sent' : 'created'
              }`}
            />
          </StatusScreen>
          <Wrapper className="stepperWrapper">
            <FabButton variant="fab" onClick={this.openDialog}>
              <KeyboardBackspace
                style={{ color: theme.palette.primary.dark }}
                height="1.5em"
                width="1.5em"
              />
            </FabButton>
            <FlexItem flex={1} />
            <FlexItem flex={4}>
              {isFetching ? (
                <Loader />
              ) : !hasData ? null : (
                <Stepper
                  classes={{ root: 'stepperRoot' }}
                  activeStep={activeStep}
                  orientation="vertical"
                  connector={
                    <StepConnector
                      classes={{
                        line: 'connectorLine',
                        root: 'connectorRoot'
                      }}
                    />
                  }>
                  {steps.map((label, index) => {
                    const isActive = activeStep === index
                    return (
                      <CustomStep key={label} selected={isActive}>
                        <StepIndicator active={isActive}> {`Step ${index + 1}`} </StepIndicator>
                        <StepLabel
                          classes={{
                            root: 'stepLabel',
                            label: 'stepLabelText',
                            iconContainer: 'stepLabelIcon'
                          }}
                          icon={
                            this.state.stepStatus[index] ? (
                              <SelectorIcons
                                height="1.7em"
                                width="1.7em"
                                name="completed"
                                style={{ cursor: 'auto' }}
                              />
                            ) : (
                              <SelectorIcons
                                height="1.5em"
                                width="1.5em"
                                name="todo"
                                style={{ cursor: 'auto' }}
                              />
                            )
                          }>
                          {label}
                          <CommonIcon
                            name={ isActive ? 'arrow_up' : 'arrow_down' }
                            onClick={() => { this.handleStepChange(index) }}
                            width="1em"
                            height="0.8em"
                            fill={this.allPreviousStepsComplete(index) 
                              ? theme.palette.primary.dark 
                              : theme.palette.neutrals.warmGray}
                          />
                        </StepLabel>
                        <StepContent classes={{ root: 'stepContent' }}>
                          <div>
                            <StepDescription>
                              {isActive && stepContent.description}
                            </StepDescription>
                            {isActive && stepContent.body}
                          </div>
                        </StepContent>
                        <ClearFix selected={activeStep === index} />
                      </CustomStep>
                    )
                  })}
                  <FlexDirection
                    style={{
                      marginRight: '-3.3em',
                      paddingTop: '2em',
                      paddingBottom: '5em'
                    }}>
                    <FlexItem flex={1} displayFlex flexEnd>
                      <Button
                        id="continueBtn"
                        text={activeStep >= 3 ? 'FINISHED' : 'NEXT'}
                        width="8em"
                        disabled={
                          stepStatus[activeStep] === false || activeStep >= 3
                            ? !this.isFormComplete()
                            : false
                        }
                        onClick={
                          activeStep >= 3
                            ? message.templateName && selectedType === ''
                              ? this.trySaveTemplate
                              : this.sendMessage.bind(this, null)
                            : this.handleNext
                        }
                        background={props => props.theme.palette.blue.main}
                      />
                    </FlexItem>
                  </FlexDirection>
                </Stepper>
              )}
            </FlexItem>
          </Wrapper>
          <AlertModal
            title={'Are you sure you wish to exit?'}
            message={
              'In order to save or send this message you must complete all steps'
            }
            buttons={[
              {
                onClick: this.goBack,
                text: 'YES'
              },
              {
                onClick: this.closeAlertModal,
                color: theme.palette.primary.navy.spaceBlue,
                text: 'NO'
              }
            ]}
            show={showDialog}
            onClose={this.closeAlertModal}
          />
        </React.Fragment>
      </CustomModal>
    )
  }
}

const CreateGroupMessageWithDialog = withRouter(
  withRouter(withTheme(withDialog(CreateGroupMessage)))
)

export default (props: Object) => (
  <CreateGroupMessageWithDialog
    alertTitle={
      'Save as template failed, Do you want to continue sending message?'
    }
    alertYesButtonText={'Yes'}
    alertNoButtonText={'No'}
    {...props}
  />
)
