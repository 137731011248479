// @flow

import React from 'react'
import { NavIcon } from '@icons'
import { FlexItem } from '@Common/Flex'
import Btn from '@Common/Cards/CardButton'
import { CarouselUnit } from '@Common/CarouselList'
import { Clickable, UserLink, GreyText, OnText } from './styles'
import type themeType from '../../../../theme'

type UserItemProps = {
  id: string,
  propertyId: string,
  name: string,
  status: string,
  theme: themeType,
  isSmartEnabled: boolean,
  account_status: string,
  residentZegoSmartEnabled: boolean,
  sendActivationEmail: (id: string) => void,
  toggleResidentZegoSmartEnabled: (string, boolean) => mixed
}

class UserItem extends React.Component<UserItemProps, {}> {
  toggleResidentZegoSmartEnabled = (
    _e: SyntheticInputEvent<HTMLInputElement>
  ) => {
    const {
      toggleResidentZegoSmartEnabled,
      residentZegoSmartEnabled,
      id
    } = this.props
    toggleResidentZegoSmartEnabled(id, !residentZegoSmartEnabled)
  }

  sendActivationEmail = () => {
    this.props.sendActivationEmail(this.props.id)
  }

  setButtonText = (status: string) => {
    return status === 'pending' ? 'REMIND ME' : 'ACTIVATE'
  }

  render() {
    const {
      id,
      propertyId,
      name,
      status,
      account_status,
      theme,
      isSmartEnabled,
      residentZegoSmartEnabled
    } = this.props
    return (
      <CarouselUnit>
        <FlexItem flex={3}>{name}</FlexItem>
        <FlexItem flex={4}>
          <GreyText>
            {account_status === 'active'
              ? 'Invite Accepted'
              : account_status === 'pending'
              ? 'Invite has been sent. Send me another'
              : 'Send me an invite'}
          </GreyText>
        </FlexItem>
        <FlexItem flex={2}>
          <GreyText>{status}</GreyText>
        </FlexItem>
        <FlexItem flex={2}>
          <GreyText>
            {account_status === 'active' ? (
              'Active'
            ) : (
              <Btn
                text={this.setButtonText(account_status)}
                background={theme.palette.blue.main}
                width={'8em'}
                onClick={this.sendActivationEmail}
              />
            )}
          </GreyText>
        </FlexItem>
        <FlexItem flex={1}>
          {isSmartEnabled && (
            <Clickable onClick={this.toggleResidentZegoSmartEnabled}>
              <NavIcon
                name="zego smart"
                width="20px"
                height="22px"
                opacity="0.75"
                fill={
                  residentZegoSmartEnabled
                    ? theme.palette.primary.green.mint
                    : theme.palette.primary.main
                }
              />
              {residentZegoSmartEnabled ? (
                <OnText>ON</OnText>
              ) : (
                <GreyText>OFF</GreyText>
              )}
            </Clickable>
          )}
        </FlexItem>
        <FlexItem flex={1}>
          <UserLink
            to={`/properties/${propertyId}/user-management/profile/${id}`}>
            View More >
          </UserLink>
        </FlexItem>
      </CarouselUnit>
    )
  }
}

export default UserItem
