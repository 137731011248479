import styled from '@emotion/styled'
import { css } from 'emotion'

export const customCss = theme => css`
  padding-top: 1.5em;
  font-size: ${theme.custom.font.size.mdlg};
  span {
    margin: 0em 0.5em;
  }
`
export const SwitchWrap = styled('div')`
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  .switch-root {
    transform: rotate(-90deg);
    height: 3em;
    width: 7em;
    justify-content: flex-start;
    align-self: center;
  }

  .switch-base {
    justify-content: flex-start;
    width: 9.1em;
    height: 3em;
    left: -1em;
    transform: none;
    border-radius: 1.5em;
    display: flex;
  }

  .bar {
    border-radius: 1.5em;
    width: 9em;
    height: 3em;
    margin-top: -1.5em;
    left: 0;
    background-color: ${({ theme }) => theme.palette.neutrals.warmGray};
  }

  .icon {
    width: 3em;
    height: 3em;
    margin-left: -0.7em;
  }

  .icon-checked {
    margin-left: 6em;
    width: 3em;
    height: 3em;
    background: ${({ theme }) => theme.palette.common.white};
  }

  .switch-checked + .bar {
    background-color: ${({ theme }) => theme.palette.primary.green.hintMint};
    opacity: 1;
  }
`

export const Specs = styled('div')`
  display: flex;
  white-space: nowrap;
  & > * {
    margin-right: 2em;
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    color: ${({ theme }) => theme.palette.grey.main};
  }
`

export const SwitchStatus = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  color: ${({ theme }) => theme.palette.primary.green.mint};
  align-self: flex-start;
`

export const RightPanel = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  flex: 1;
  display: flex;
  justify-content: flex-end;

  & > span {
    margin: 0em 0.5em;
    align-self: center;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & > span:last-child {
    display: flex;
    justify-content: flex-end;
  }
`

export const SwitchName = styled('input')`
  border: none;
  flex: 0.3;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.green.mint};
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};

  &:focus {
    outline: none;
  }
`

export const EditLink = styled('a')`
  margin: 0em 1em;
`
