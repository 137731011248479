import React from 'react'
import PrivateRoute from '../PrivateRoute'
import Registration from '../../views/Registration'
import Pet from '../../views/Registration/Pet'
import Visitor from '../../views/Registration/Visitor'
import Vehicle from '../../views/Registration/Vehicle'

const getRegistrationRoutes = () => [
  <PrivateRoute
    exact
    key="pet-registration"
    path="/properties/:propertyId/registration/pets/:petId"
    component={Pet}
  />,
  <PrivateRoute
    exact
    key="visitor-registration"
    path="/properties/:propertyId/registration/visitors/:visitorId"
    component={Visitor}
  />,
  <PrivateRoute
  exact
    key="vehicle-registration"
    path="/properties/:propertyId/registration/vehicles/:vehicleId"
    component={Vehicle}
  />,
  <PrivateRoute
    exact
    key="registration"
    path="/properties/:propertyId/registration"
    component={Registration}
  />
]

export default getRegistrationRoutes
