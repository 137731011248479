// @flow
import React from 'react'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import ThermostatSpecs from './ThermostatSpecs'
import CurrentTemperature from './CurrentTemperature'
import { SliderWrap } from './styles'
import Slider from '../../../../Common/Devices/Thermostat/Slider'
import { PercentageText, Superscript } from '../../styles'
import { commands } from 'zego-shared/store/devices/sagas/utils/deviceCommands'

const getSliderValues = (mode, heatSetPoint, coolSetPoint) => {
  if (mode === commands.HEAT) {
    return [heatSetPoint]
  }
  if (mode === commands.COOL) {
    return [coolSetPoint]
  }
  if (mode === commands.AUTO) {
    return [heatSetPoint, coolSetPoint]
  }
  return []
}

export const ViewMode = ({
  currentTemp,
  deviceModel,
  installationDate,
  deviceStatus,
  batteryPercent,
  batteryLevelText,
  batteryLevelPercentText,
  viewBatteryMode,
  toggleViewBatteryMode
}: any) => (
  <React.Fragment>
    <FlexDirection direction="column" fullHeight>
      <FlexItem
        flex={1}
        displayFlex
        flexCenter
        style={{ flexDirection: 'column', marginTop: '5%' }}>
        {!viewBatteryMode ? (
          <React.Fragment>
            <CurrentTemperature currentTemp={currentTemp} />
            <ThermostatSpecs
              deviceModel={deviceModel}
              deviceStatus={deviceStatus}
              installationDate={installationDate}
              batteryPercent={batteryPercent}
              batteryLevelText={batteryLevelText}
              batteryLevelPercentText={batteryLevelPercentText}
              toggleViewBatteryMode={toggleViewBatteryMode}
            />
          </React.Fragment>
        ) : (
          <span>{batteryLevelText}</span>
        )}
      </FlexItem>
    </FlexDirection>
  </React.Fragment>
)

export const RightViewMode = ({
  changeComponentMode,
  heatSetPoint,
  coolSetPoint,
  currentThermostatMode,
  batteryPercent,
  viewBatteryMode
}: any) =>
  !viewBatteryMode ? (
    <FlexItem
      flex={0.9}
      displayFlex
      flexCenter
      alignItemsRigh
      align
      style={{ direction: 'column', paddingLeft: '40%', paddingRight: '10%' }}>
      <SliderWrap>
        <Slider
          values={getSliderValues(
            currentThermostatMode,
            heatSetPoint,
            coolSetPoint
          )}
          ticksCount={15}
          handleOnMouseDown={() => changeComponentMode('CHANGESETPOINT')}
          percentageValues={
            currentThermostatMode === commands.AUTO ? [20, 65] : null
          }
          vertical
        />
      </SliderWrap>
    </FlexItem>
  ) : (
    <React.Fragment>
      <FlexItem
        flex={4}
        flexCenter
        displayFlex
        style={{
          paddingLeft: '35%'
        }}>
        <PercentageText>
          {batteryPercent}
          <Superscript>%</Superscript>
        </PercentageText>
      </FlexItem>
    </React.Fragment>
  )

export default ViewMode
