// @flow
import {
  getGroupEventsDropDownData
} from 'zego-shared/store/groupMessaging/groupEvents'


export const getTemplates = (state: Object) => {
  const templates = state.groupMessaging.templates
  return templates
    ? templates.map(template => ({
        id: template.id,
        name: template.name,
        title: template.title,
        type: template.resource_type
      }))
    : null
}

export const getTemplatesDropdownData = (state: Object, isEdit: boolean) => {
  const templates = state.groupMessaging.templates
  const templatePermissions = state.groupMessaging.templatePermission || []

  if (templates) {
    const templateDropdown = isEdit
      ? []
      : [
          {
            id: 0,
            name: 'select a template',
            message: '',
            title: 'select a template',
            templateName: ''
          }
        ]
    templates.forEach(template => {
      templateDropdown.push({
        id: template.id,
        name: template.name || '',
        title: template.title || '',
        message: template.body || '',
        textMessage: template.text_body || '',
        canEdit: templatePermissions.includes(template.resource_type),
        attachments: template.attachments || {},
        isDefault: template.is_default || false,
        footNote: template.foot_note || '',
        groupEvents: getGroupEventsDropDownData(state, template)
      })
    })
    return templateDropdown
  }
  return null
}

export const hasTemplates = (state: Object) => {
  return state.groupMessaging.templates !== null
}

export const getTemplateWithAutomationInfo = (state: Object) => {
  const templates = state.groupMessaging.templates || []
  const automationMap = {}

  const groupAutomations = 
    Array.isArray(state.groupMessaging.groupAutomation) 
      ? state.groupMessaging.groupAutomation
      : []
  
  groupAutomations.forEach(
    cron => automationMap[cron.group_message_template_id] = { 
      groups: cron.groups, 
      sending_methods: cron.sending_methods
    })

  const groupTemplate = templates.map(template => ({ 
    ...template, 
    ...automationMap[template.id]

  }));

  return groupTemplate;
}