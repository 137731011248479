import React, { useState, useEffect } from 'react'
import ScrollDownButton from '@Common/ScrollDownButton'
import AddIcon from '@material-ui/icons/Add'
import Loader from '@Common/Loader'
import { GridItem } from '@Common/Grid'
import { FlexItem } from '@Common/Flex/FlexDirection'
import Avatar from '@Common/Avatar'
import { getDateFullYear } from 'zego-shared/utils/date'
import { withTheme } from 'emotion-theming'
import { PropagateLoader } from 'react-spinners'
import { getProfileUrlOrDefault } from 'zego-shared/utils/profilePicture'
import ResidentMoveinMoveoutCard from './ResidentMoveinMoveoutCard'
import ResidentMoveinMoveoutChecklistSettings from './ResidentMoveinMoveoutChecklistSettings'
import moment from 'moment'
import {
  StyledTable,
  StyledMimoColumn,
  StyledCell,
  StyledLink,
  StyledChip,
  StyledFilterText,
  StyledClearText,
  Loading,
  FabButton,
  ShowMoreBtn,
  RedDotStyled,
  StyledCenteted
} from '../styles'

const ChecklistStatus = {
  ChecklistStatusCompleted: 'Completed',
  ApprovalStatusAwaiting: 'Awaiting Approval'
}

const ResidentManagementMoveInMoveOut = (props) => {

  const {
    residentsCount,
    residents,
    propertyId,
    theme,
    hasPms,
    pmsName,
    isFetching,
    filteredResidentCount,
    fetchResidentManagementMoveInMoveOutAction,
    updateResidentCount,
    fetchNextResidentManagementMoveInMoveOutAction,
    setShowReSyncButton,
    isLoadingResidents
  } = props

  const [currentPage, setCurrentPage] = useState(1)
  const [selectedFilters, setFilters] = useState({ 'approvalStatus': [], 'checklistStatus': [], 'issuesStatus': [] })
  const [showSettings, setShowSettings] = useState(false)
  const integrated = hasPms && pmsName !== 'paylease'
  const mimoFilters = {
    approvalStatus: ['approved', 'awaiting approval', 'auto approved'],
    checklistStatus: ['completed', 'in progress', 'not started', 'opted out'],
    issuesStatus: ['issue reported', 'no issues']
  }

  useEffect(() => {
    if (currentPage > 1) {
      fetchNextResidentManagementMoveInMoveOutAction({ propertyId, page: currentPage, params: selectedFilters })
    }
    // eslint-disable-next-line
  }, [currentPage])

  useEffect(() => {
    if (residentsCount) {
      updateResidentCount(residentsCount)
    }
    // eslint-disable-next-line
  }, [residentsCount])


  useEffect(() => {
    setShowReSyncButton(!showSettings)
    // eslint-disable-next-line
  }, [showSettings])

  if (isFetching) {
    return <Loader />
  }

  const renderCheckListLinks = (rowData) => {
    return <React.Fragment >
      {rowData.checklistStatus === ChecklistStatus.ChecklistStatusCompleted ?
        <StyledLink to={`/properties/${propertyId}/residents/profile/${rowData.residentId}/summary`}
          target="blank">
          View Checklist
        </StyledLink>
        :
        <StyledLink noUnderline style={{ color: 'grey', cursor: 'auto' }}>
          View Checklist
        </StyledLink>
      }
    </React.Fragment>
  }

  const renderStatusWithIndicator = (approvalStatus) => {
    return (<> {approvalStatus === ChecklistStatus.ApprovalStatusAwaiting && <RedDotStyled />}{approvalStatus}</>)
  }

  const clearFilters = () => {
    setFilters({ 'approvalStatus': [], 'checklistStatus': [], 'issuesStatus': [] })
    setCurrentPage(1)
    fetchResidentManagementMoveInMoveOutAction({ propertyId })
  }

  const createRows = () => {
    const formattedRows = residents.map((rowData) => {
      const name = rowData.firstName + ' ' + rowData.lastName;
      const photo = getProfileUrlOrDefault(rowData.photoUrl, name)
      const optOut = 'Opted Out'
      let today = new Date().toString()

      return {
        ID: (
          <FlexItem flex={0.8}>
            <StyledLink to={`/properties/${propertyId}/residents/profile/${rowData.residentId}`}>
              <Avatar size="2.2em" picture={photo} />
            </StyledLink>
          </FlexItem>
        ),
        Resident: <StyledLink to={`/properties/${propertyId}/user-management/profile/${rowData.residentId}`}> {name} </StyledLink>,
        Unit: rowData.unitId ? <StyledLink to={`/properties/${propertyId}/units/${rowData.unitId}/user-management`}> {rowData.unitName} </StyledLink> : '',
        ApprovalStatus: rowData.approvalStatus ? renderStatusWithIndicator(rowData.approvalStatus) : '--',
        Status: rowData.checklistStatus,
        DueDate: rowData.dueDate ? getDateFullYear(rowData.dueDate) : '--',
        SubmittedDate: rowData.checklistSubmittedDate ? moment(getDateFullYear(today)).diff(getDateFullYear(rowData.checklistSubmittedDate),'days') : '--',
        Issues: rowData.checklistStatus !== optOut ? rowData.issues : '',
        WorkOrderStarted: <StyledCenteted>{rowData.workordersStarted}</StyledCenteted>,
        Contact: rowData.checklistStatus !== optOut ? renderCheckListLinks(rowData) : ''
      }
    })

    const styledRows = formattedRows.map((rowData) => {
      return Object.entries(rowData).map(([key, value], index) => {
        return { key: key, value: (<StyledCell index={index + 1}>{value}</StyledCell>) }
      })
    })
    return styledRows
  }

  const columns = [
    {
      value: <StyledMimoColumn index={1}> Resident </StyledMimoColumn>
    },
    {
      value: <StyledMimoColumn index={2}> </StyledMimoColumn>
    },
    {
      value: <StyledMimoColumn index={3}> Unit </StyledMimoColumn>
    },
    {
      value: <StyledMimoColumn index={4}> Approval Status </StyledMimoColumn>
    },
    {
      value: <StyledMimoColumn index={5}> Checklist Status </StyledMimoColumn>
    },
    {
      value: <StyledMimoColumn index={6}> Due Date </StyledMimoColumn>
    },
    {
      value: <StyledMimoColumn index={7}> Days Since Submitted </StyledMimoColumn>
    },
    {
      value: <StyledMimoColumn index={8}> Issues </StyledMimoColumn>
    },
    {
      value: <StyledMimoColumn index={9}> Work Order Started </StyledMimoColumn>
    },
    {
      value: <StyledMimoColumn index={10}> Move in Checklist </StyledMimoColumn>
    }
  ]

  const handleChipClick = (filterType, filterValue) => {
    let selectedValues = selectedFilters[filterType]

    // toggle filters
    if (selectedValues?.includes(filterValue)) {
      selectedValues = selectedValues.filter(filter => filter !== filterValue)
    } else {
      selectedValues.push(filterValue)
    }
    let filters = { ...selectedFilters, [filterType]: selectedValues }
    setFilters(filters)
    setCurrentPage(1)
    fetchResidentManagementMoveInMoveOutAction({ propertyId, page: 1, params: filters })
  }

  const filterChipGroup = (mimoFilters) => {
    // map label to status
    const chips = []

    for (const [filterType, filterValues] of Object.entries(mimoFilters)) {
      const selectedValues = selectedFilters?.[filterType] || []

      if (Array.isArray(filterValues)) {
        for (const index in filterValues) {
          const value = filterValues[index]
          let color = "primary"

          if (selectedValues.includes(value)) {
            color = "secondary"
          }
          chips.push(
            <StyledChip
              theme={theme}
              variant="outlined"
              color={color}
              label={value}
              onClick={() => { handleChipClick(filterType, value) }} />
          )
        }
      } else {

        let color = "primary"
        if (filterValues === selectedValues) {
          color = "secondary"
        }

        chips.push(
          <StyledChip
            theme={theme}
            variant="outlined"
            color={color}
            label={filterValues}
            onClick={() => handleChipClick(filterType, filterValues)} />
        )
      }
    }
    return chips
  }

  const showMore = residents.length < filteredResidentCount
  const handleShowMore = () => {
    setCurrentPage(currentPage + 1)
  }
  return (
    <>
      <GridItem columnStart={9} columnSpan={4} rowStart={2} rowSpan={2} style={{ zIndex: 1 }}>
        <ResidentMoveinMoveoutCard setShowSettings={setShowSettings} />
      </GridItem>

      { showSettings ? <ResidentMoveinMoveoutChecklistSettings setShowSettings={setShowSettings} ></ResidentMoveinMoveoutChecklistSettings> :
        <>
          <GridItem columnStart={1} columnSpan={10} rowStart={5} rowSpan={1}>
            <div>
              <StyledFilterText>{filteredResidentCount} Filtered Residents</StyledFilterText>
              <StyledClearText onClick={clearFilters}> Clear Filters</StyledClearText>
            </div>
            {filterChipGroup(mimoFilters)}
          </GridItem>
          <GridItem columnStart={1} columnSpan={12} rowStart={6} rowSpan={10}>
            <StyledTable
              noDataText="No Residents"
              flex={[0.2, 1, 1, 1, 1, 1, 1, 1, 1]}
              columns={columns}
              rows={createRows()}
            />

            {isLoadingResidents &&
              <Loading>
                <PropagateLoader color={theme.palette.secondary.main1} />
              </Loading>
            }
            {showMore && !isLoadingResidents &&
              <ShowMoreBtn
                id="showMoreBtn"
                disableRipple
                onClick={handleShowMore}>
                Show More <ScrollDownButton />
              </ShowMoreBtn>
            }
          </GridItem>
          {!integrated && (
            <StyledLink to={`/properties/${propertyId}/user-management/add-user`}>
              <FabButton variant="fab">
                <AddIcon style={{ color: theme.palette.common.white }} />
              </FabButton>
            </StyledLink>
          )}
        </>
      }
    </>
  )
}

export default withTheme(ResidentManagementMoveInMoveOut)
