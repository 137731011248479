// @flow

import React from 'react'
import SelectedSchedule from './SelectedSchedule'
import { GridItem } from '../../../Common/Grid'
import Flex, { FlexItem } from '@Common/Flex'
import Button from '@Common/Button'
import { ListItem, ListItemText, ListItemSecondaryAction } from './styles'

import type { ScheduleTypesProps, ScheduleType } from './types'

const scheduleTypes: Array<ScheduleType> = [
  {
    name: 'early-riser',
    title: 'Early Riser',
    description: 'Set to away from 6am to 3pm. Never away on weekend',
    fullDescription:
      'Your Schedule is set for early riser. This sets the away time range from 6pm to 3pm. And never away on the weekend.'
  },
  {
    name: 'working-late',
    title: 'Working Late',
    description: 'Set to away from 10am to 7pm. Never away on weekend',
    fullDescription:
      'Your Schedule is set for working late. This sets the away time range from 10am to 7pm. And never away on the weekend.'
  },
  {
    name: 'nine-to-five',
    title: '9 to 5',
    description: 'Set to away from 9am to 5pm. Never away on weekend',
    fullDescription:
      'Your Schedule is set for working late. This sets the away time range from 9am to 5pm. And never away on the weekend.'
  },
  {
    name: 'home-body',
    title: 'Home Body',
    description: 'Always home, never away',
    fullDescription:
      'Your schedule is set for home body. Always home and never away.'
  },
  {
    name: 'vacation',
    title: 'Vacation',
    description: 'Always away, never home',
    fullDescription:
      'Your schedule is set for vacation. Never home and always away.'
  },
  {
    name: undefined,
    title: 'Schedule Not Set',
    description: 'Please choose a schedule type from the list below',
    fullDescription:
      'You do not have schedule set right now. Please choose a schedule type from the list below'
  }
]
const ScheduleTypes = ({
  selectedScheduleType,
  selectSchedule,
  readOnly
}: ScheduleTypesProps) => {
  const { title = '', fullDescription = '' } =
    scheduleTypes.find(({ name }) => name === selectedScheduleType) || {}
  return (
    <React.Fragment>
      <GridItem columnStart={5} columnSpan={6} rowStart={2} rowSpan={5}>
        <SelectedSchedule
          selectedScheduleType={selectedScheduleType}
          title={title}
          description={fullDescription}
        />
      </GridItem>
      <GridItem columnStart={5} columnSpan={6} rowStart={5} rowSpan={10}>
        {scheduleTypes
          .filter(({ name }) => name != null && name !== selectedScheduleType)
          .map(({ name, title, description }) => (
            <ListItem key={name} borderTop>
              <Flex fullWidth>
                <FlexItem flex={6}>
                  <ListItemText>
                    <Flex justifyStart fullWidth>
                      <FlexItem flex={1}>
                        <font color="black">{title}</font>
                      </FlexItem>
                      <FlexItem flex={4}>{description}</FlexItem>
                    </Flex>
                  </ListItemText>
                </FlexItem>
                {!readOnly && (
                  <FlexItem flex={1}>
                    <ListItemSecondaryAction>
                      <Button
                        thin
                        onClick={() => {
                          selectSchedule(name)
                        }}
                        secondary>
                        SELECT
                      </Button>
                    </ListItemSecondaryAction>
                  </FlexItem>
                )}
              </Flex>
            </ListItem>
          ))}
      </GridItem>
    </React.Fragment>
  )
}

export default ScheduleTypes
