import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { formatDateMDYY } from '../../../utils/date'
import { getUnits } from '../../select/units/selectors'

const propertyRoles = {
  property_staff: 'Property Staff',
  company_admin: 'Company Admin',
  property_admin: 'Property Admin'
}

const accessTypesData = {
  property_vacant: 'Vacant',
  unit: 'Unit',
  property: 'Property'
}

const propertyLockCodesToPropertyLockCodesData = users => {
  return Object.values(users).map(user => {
    return {
      name: user.name ? user.name : user.email,
      role: propertyRoles[user.role],
      code: '',
      id: user.id
    }
  })
}

export const getPropertyUsers = (state: Object) => {
  const users = get(state, ['property', 'users', 'byId'])
  if (isEmpty(users)) {
    return null
  }

  return users ? propertyLockCodesToPropertyLockCodesData(users) : []
}

export const getPropertyUsersCount = (state: Object) => {
  const users = get(state, ['property', 'users', 'byId'])

  return users ? Object.keys(users).length : 0
}

const accessTypeToAccessTypeData = accessTypes => {
  return accessTypes.map(accessType => {
    return accessTypesData[accessType]
  })
}

const countToCountData = count => {
  if (count) {
    return count == 1 ? `${count} code` : `${count} codes`
  }

  return `0 codes`
}

const vendorsToVendorsData = vendors => {
  return vendors.map(vendor => {
    return {
      name: vendor.name,
      accessType:
        vendor.access_types &&
        accessTypeToAccessTypeData(vendor.access_types).join(', '),
      count: countToCountData(vendor.lock_codes_count),
      id: vendor.id
    }
  })
}

export const getLockCodesVendors = (state: Object) => {
  const vendors = get(state, ['lockCodes', 'vendorsCodes', 'data'])

  if (isEmpty(vendors)) {
    return null
  }

  return vendors ? vendorsToVendorsData(vendors) : []
}

export const getVendorsCount = (state: Object) => {
  const count = get(state, ['lockCodes', 'vendorsCodes', 'total'])

  return count ? count : ''
}

const residentsToResidentsData = residents => {
  return residents.map(({ name, email, units, user_id }) => {
    return {
      name: name ? name : email,
      unit: Array.isArray(units) && units.length > 0 ? units[0].name : '',
      code: '',
      id: user_id
    }
  })
}

export const getResidentUsers = (state: Object) => {
  const residents = get(state, ['property', 'users', 'data'])

  return residents ? residentsToResidentsData(residents) : []
}

export const getResidentUsersCount = (state: Object) => {
  const count = get(state, ['property', 'users', 'total'])

  return count ? count : ''
}

export const getCurrentPage = (state: Object) => {
  const nextPage = get(state, ['property', 'users', 'nextPage'])

  return nextPage ? nextPage - 1 : ''
}

export const getSearchTerm = (state: Object) => {
  const searchTerm = get(state, ['property', 'users', 'searchTerm'])

  return searchTerm ? searchTerm : ''
}

export const getVendorsSearchTerm = (state: Object) => {
  const searchTerm = get(state, ['lockCodes', 'vendorsCodes', 'searchTerm'])

  return searchTerm ? searchTerm : ''
}

const getUnitCount = (units, accessType) => {
  const unitCount = units ? units.length : 0
  switch (accessType) {
    case 'property_vacant':
      return 'All Vacant'
    case 'property':
      return 'All Property'
    case 'unit':
      return `${unitCount} Units`
    default:
      break
  }
}

const getAccessType = accessType => {
  return accessTypesData[accessType] || ''
}

const vendorCodeToVendorCodesData = vendorCode => {
  return {
    'Access Type': getAccessType(vendorCode.resource_access_type) || '',
    Units: vendorCode.unit_ids || [],
    'Units Count': getUnitCount(
      vendorCode.unit_ids,
      vendorCode.resource_access_type
    ),
    'Start Date': formatDateMDYY(vendorCode.time_start),
    'End Date': formatDateMDYY(vendorCode.time_end),
    Phone: vendorCode.phone || '',
    Email: vendorCode.email || '',
    'Created By': `${vendorCode.created_by_first_name} ${
      vendorCode.created_by_last_name
    }`,
    'Vendor Name': vendorCode.vendor_name || vendorCode.name,
    Id: vendorCode.external_user_id
  }
}

const getAccessTypeObj = (accessType = 'property') => {
  return {
    id: accessType,
    name: getAccessType(accessType)
  }
}

const vendorCodeToVendorCodeData = vendorCode => {
  return {
    'Vendor Name': vendorCode.vendor_name || '',
    'Access Type': getAccessTypeObj(vendorCode.resource_access_type),
    'Start Date': formatDateMDYY(vendorCode.time_start),
    'End Date': formatDateMDYY(vendorCode.time_end),
    Phone: vendorCode.phone || '',
    Email: vendorCode.email || ''
  }
}

const unitToUnitListData = unit => {
  return {
    name: unit.name || '',
    hubStatus: unit.status === 'online' ? 'on' : 'off',
    id: unit.id
  }
}

export const getUnitList = (state: Object) => {
  const units = getUnits(state) || []
  return units.map(unitToUnitListData)
}

export const getVendorCodes = (state: Object, vendorId: number) => {
  const vendorCodes = get(state, ['lockCodes', 'vendorCodes']) || []
  return vendorCodes.reduce((codes, code) => {
    if (vendorId === String(code.vendor_id) && code.valid) {
      codes.push(vendorCodeToVendorCodesData(code))
    }
    return codes
  }, [])
}

export const getVendorCode = (
  state: Object,
  isCreateMode: boolean = false,
  isExistingVendor: boolean = false
) => {
  const defaultVendor = {}
  if (isExistingVendor) {
    defaultVendor['vendor_name'] = getVendorName(state)
  }
  const vendorCode = get(state, ['lockCodes', 'vendorCode']) || {}
  return vendorCodeToVendorCodeData(isCreateMode ? defaultVendor : vendorCode)
}

export const getVendorName = (state: Object): string => {
  const vendor = get(state, ['lockCodes', 'vendor']) || {}
  return vendor.name || ''
}

export const hasVendorCode = (state: Object) => {
  return Boolean(get(state, ['lockCodes', 'vendorCode']))
}

export const getVendorCodeUnits = (state: Object, isCreateMode: boolean) => {
  if (isCreateMode) return []

  const vendorCodeUnits = get(state, ['lockCodes', 'vendorCodeUnits']) || []
  const { unit_ids: unitIds } = get(state, ['lockCodes', 'vendorCode']) || {}

  return vendorCodeUnits.map((unit, index) => {
    return {
      name: unit.unit_name || '',
      hubStatus: unit.hub_status === 'online' ? 'on' : 'off',
      id: (unitIds && unitIds[index]) || ''
    }
  })
}
