import { connect } from 'react-redux'
import queryString from 'query-string'
import {
  loginEmail,
  loginSocial,
  loginToken,
  requestResetPass,
  resetPass,
  setGoogleLoginError,
  fetchResourcesAndRedirectAfterLogin
} from 'zego-shared/store/authorization/actions'
import { getIsAuth, hasLoginFailed } from 'zego-shared/store/authorization/selectors'
import Login from './Login'
import { getFeatureFlag } from '../../integrations/LaunchDarkly/selectors'

const mapStateToProps = (state, { location: { search } }) => {
  const { redirect_uri: redirectURI, sso: token } = queryString.parse(search)
  return {
    featureFlag: getFeatureFlag(state),
    isAuth: getIsAuth(state),
    error: state.snackbar.message,
    hasRedirect: redirectURI != null,
    loginFailed: hasLoginFailed(state),
    token
  }
}

const mapDispatchToProps = {
  loginEmail,
  loginSocial,
  loginToken,
  requestResetPass,
  resetPass,
  fetchResourcesAndRedirectAfterLogin,
  handleSocialFailure: () => setGoogleLoginError()
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
