// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import { withRouter, Redirect } from 'react-router-dom'
import CarouselUnit from './CarouselUnit'
import DeviceSelector from './DevicesSelector'
import { DevicesListProps, DevicesListState } from './types'
import { SmartDeviceRoutes } from '../../../../routes/Smart'
import { SelectorWrap, Wrapper, hubDevicesTable } from './styles'

const numberOfItemsPerSlide = 5

class DevicesList extends React.Component<DevicesListProps, DevicesListState> {
  showDeleteDeviceModal = id => {
    const {
      history,
      match: { url }
    } = this.props
    history.push(`${url.split('/devices')[0]}/devices/${id}/delete`)
  }

  getDeviceComponents = () => {
    const {
      match: { params },
      location,
      devices
    } = this.props

    return devices.map((device, index) => (
      <CarouselUnit
        {...device}
        key={index}
        showDeleteDeviceModal={this.showDeleteDeviceModal.bind(this, device.id)}
        lastUnit={index === devices.length - 1}
        url={`/properties/${params.propertyId}/units/${
          params.unitId
        }/smart/devices/${device.id}`}
        selected={
          params.deviceID
            ? location && location.pathname.includes(device.id)
            : index === 0
        }
      />
    ))
  }

  forceDelete = () => {}

  getRedirectUrl = () => {
    const {
      match: { params },
      devices
    } = this.props
    const device = devices && devices[0]
    if (device && !params.deviceID) {
      return `/properties/${params.propertyId}/units/${
        params.unitId
      }/smart/devices/${device.id}`
    }
  }

  render() {
    const { devices, hasData, hasDevices } = this.props

    const deviceComponents = this.getDeviceComponents()
    const firstDeviceID = devices[0] ? devices[0].id : null
    const redirectUrl = this.getRedirectUrl()

    if (redirectUrl) {
      return <Redirect to={redirectUrl} />
    }

    if (!hasDevices)
      return (
        <Wrapper
          columnStart={5}
          rowStart={2}
          rowSpan={6}
          columnSpan={9}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          No devices have been set up yet. Please click the plus button to add a
          device!
        </Wrapper>
      )

    return (
      devices &&
      hasData && (
        <Wrapper columnStart={5} rowStart={2} rowSpan={9} columnSpan={9}>
          <SmartDeviceRoutes id={firstDeviceID} />
          <SelectorWrap
            columnStart={1}
            rowStart={5}
            rowSpan={5}
            columnSpan={9}
            style={hubDevicesTable}>
            <DeviceSelector
              items={deviceComponents}
              numberOfItemsPerSlide={numberOfItemsPerSlide}
              lastSlideNumber={Math.ceil(
                deviceComponents.length / numberOfItemsPerSlide
              )}
              transitionHeights={['0em', '2em']}
            />
          </SelectorWrap>
        </Wrapper>
      )
    )
  }
}

export default withRouter(withTheme(DevicesList))
