// @flow

import get from 'lodash/get'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
// $FlowDisableNextLine
import { capitalizeFirstLetter } from 'zego-shared/utils/string'
// $FlowDisableNextLine
import { formatDateMMDDYY, formatDateMMMMDDYYYY } from 'zego-shared/utils/date'
// $FlowDisableNextLine
import { formatPhoneNumber, currencyToUsd } from 'zego-shared/utils/string'
// $FlowDisableNextLine
import { getProfileUrlOrDefault } from 'zego-shared/utils/profilePicture'

const statuskeys: Object = {
  pre_resident: 'Pre Resident',
  current: 'Current Resident',
  former_resident: 'Former Resident',
  non_resident: 'Non Resident'
}

const checkName = (usersData: Array<any>): Object[] => {
  return usersData.map(user => {
    user.name = user.name ? user.name : user.email
    return user
  })
}

const userToUserData = (user: Object) => {
  return {
    userId: user.user_id,
    name: user.display,
    picture: user.picture,
    unitName: user.unit_name,
    unitId: user.unit
  }
}

const getFilteredUsers = (users: Object, filterType: Array<string>) => {
  const userIds = users.allIds
  const usersById = users.byId

  const filteredUsersIds: Object[] = users
    ? userIds.filter(id => {
        return filterType.includes(usersById[id].role)
      })
    : []

  const filteredUsers: Object[] = filteredUsersIds.map(id => usersById[id])

  return {
    users: filteredUsers,
    count: filteredUsersIds.length
  }
}

const getActiveResidentsCount = (users: Object, filterType: Array<string>) => {
  const userIds = users.allIds
  const usersById = users.byId

  const filteredUsersIds = users
    ? userIds.filter(id => {
        return filterType.includes(usersById[id].account_status)
      })
    : []

  return {
    count: filteredUsersIds.length
  }
}

export const hasUnitData = (state: Object) => {
  if (isEmpty(state.unit.users)) return null
  const data = get(state, ['unit', 'users'])
  return data ? true : null
}

const highestUnitBalance = unitUsers => {
  let maxBalance = 0
  unitUsers.map(user => {
    maxBalance = Math.max(user.balance, maxBalance)
  })

  return maxBalance
}

export const getUnitBalance = (state: Object) => {
  if (isEmpty(state.unit.users.balance)) return null
  const data = get(state, ['unit', 'users', 'balance'])

  return highestUnitBalance(data)
}

export const hasData = (state: Object) => {
  const users = get(state, ['users', 'summary'])

  return isEmpty(users) ? null : true
}

export const getPropertyUsersCardData = (state: Object) => {
  if (isEmpty(state.property.users)) return null
  const users = get(state, ['property', 'users'])
  const adminUsers = getFilteredUsers(users, [
    'property_admin',
    'company_admin'
  ])

  return {
    companyAdminUsers: getFilteredUsers(users, ['company_admin']),
    propertyAdminUsers: getFilteredUsers(users, ['property_admin']),
    propertyStaffUsers: getFilteredUsers(users, ['property_staff']),
    adminCount: adminUsers.count
  }
}

const rewriteResidentStatus = (residents: Object[]): Object[] => {
  return residents.map(resident => {
    if (resident.status !== statuskeys[resident.status]) {
      resident.status = statuskeys[resident.status]
      return resident
    }

    return resident
  })
}

export const getPropertyUsers = (state: Object, filter: string) => {
  const users = get(state, ['users', 'summary'])
  if (isEmpty(users)) {
    return null
  }

  const data = {
    companyAdmin: users.company_admin.data,
    propertyAdmin: users.property_admin.data,
    propertyStaff: users.property_staff.data,
    setup: users.setup.data
  }

  return data[filter].map(userToUserData)
}

export const setCountText = (filter: string) =>
  filter.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())

export const setRoleTitle = (role: string) =>
  setCountText(role).replace('_', ' ').toUpperCase()

export const getPropertyUsersCount = (state: Object) => {
  const users = get(state, ['users', 'summary'])
  if (isEmpty(users)) {
    return null
  }

  return (
    users.property_admin.count +
    users.company_admin.count +
    users.property_staff.count +
    users.setup.count
  )
}

export const getPropertyResidents = (state: Object, filter: string) => {
  const residentUsers = get(state, ['users', 'summary', 'resident'])
  return isEmpty(residentUsers)
    ? null
    : residentUsers[filter.toLowerCase()].data.map(userToUserData)
}

const getAllPropertyResidentsMemoize = (): Function => {
  let cachedUsers = null
  let cachedPropertyResidents = []
  return (state: Object) => {
    const users = flatten(
      ['active', 'pending', 'inactive'].map(status =>
        get(state, ['users', 'summary', 'resident', status, 'data'], [])
      )
    )
    if (!isEqual(users, cachedUsers)) {
      cachedUsers = users
      cachedPropertyResidents = users
        .map(
          ({ user_id: userId, unit_name: unitName, unit: unitId, ...user }) => {
            return {
              ...user,
              userId,
              unitName,
              unitId
            }
          }
        )
        .sort(
          ({ display: firstDisplay }, { display: secondDisplay }): number => {
            if (
              typeof firstDisplay === 'string' &&
              typeof secondDisplay === 'string'
            ) {
              return firstDisplay.toLowerCase() > secondDisplay.toLowerCase()
                ? 1
                : -1
            }
            return 0
          }
        )
    }
    return cachedPropertyResidents
  }
}

export const getAllPropertyResidents = getAllPropertyResidentsMemoize()

export const getPropertyUsersSummary = (state: Object) => {
  const users = get(state, ['users', 'summary'])
  return isEmpty(users) ? null : users
}

export const getPropertyResidentsCount = (state: Object) => {
  const users = get(state, ['users', 'summary'])
  if (isEmpty(users)) {
    return null
  }

  const residentsCount = users.resident.count
  return residentsCount
}

export const getUnitUsersCardData = (state: Object): ?Object => {
  if (isEmpty(state.unit.users)) return null
  const users = get(state, ['unit', 'users'])
  let { count, users: residentUsers } = getFilteredUsers(users, ['resident'])

  residentUsers = residentUsers.map(
    ({
      user_id: userId,
      name,
      email,
      picture,
      account_activated: accountActivated,
      phone_number: phoneNumber
    }) => {
      const displayName = (name ? name : email || '').substring(0, 20)
      return {
        userId,
        name,
        email,
        picture,
        accountActivated,
        phoneNumber,
        displayName,
        accountActiveStatus: accountActivated ? 'Active' : 'Inactive'
      }
    }
  )

  return {
    count,
    users: residentUsers
  }
}

export const getUnitUsersSummaryData = (state: Object) => {
  if (isEmpty(state.unit.users)) return null

  const users = get(state, ['unit', 'users'])
  const residentUsers = getFilteredUsers(users, ['resident'])

  return rewriteResidentStatus(checkName(residentUsers.users))
}

export const getResidentsCount = (state: Object) => {
  if (isEmpty(state.unit.users)) return null
  const users = get(state, ['unit', 'users'])
  const residentUsers = getFilteredUsers(users, ['resident'])

  return residentUsers.count
}

export const getUnitActiveResidentsCount = (state: Object) => {
  if (isEmpty(state.unit.users)) return null
  const users = get(state, ['unit', 'users'])
  const activeResidentsCount = getActiveResidentsCount(users, ['active'])

  return activeResidentsCount.count
}

export const hasProfileData = (state: Object) => {
  const data = get(state, ['users', 'profile'])

  return isEmpty(data) ? null : true
}

export const userRoles = {
  resident: 'Resident',
  property_admin: 'Property Admin',
  company_admin: 'Company Admin',
  property_staff: 'Property Staff',
  setup: 'Setup'
}

const userToProfileData = user => {
  return {
    userId: user.user_id,
    name: user.name,
    firstName: user.first_name,
    lastName: user.last_name,
    picture: user.picture,
    phoneNumber: formatPhoneNumber(user.phone_number || ''),
    email: user.email,
    bio: user.bio || '',
    title: user.user_metadata.title || '',
    status: capitalizeFirstLetter(user.account_status),
    role: userRoles[user.role],
    lockCode: user.lock_code,
    lastActive:
      user.last_active_time == null
        ? 'Never'
        : formatDateMMDDYY(user.last_active_time),
    accountActivatedTime:
      user.account_activated_time == null
        ? null
        : formatDateMMDDYY(user.account_activated_time),
    unit: user.units[0] ? user.units[0].name : '',
    isPmsUser: user.pms_user ? true : false
  }
}

export const getUserProfile = (state: Object) => {
  const userProfile = get(state, ['users', 'profile'])
  return isEmpty(userProfile) ? null : userToProfileData(userProfile)
}

export const getProfile = (state: Object) => {
  const userProfile = get(state, ['users', 'profile'])
  return isEmpty(userProfile) ? null : userProfile
}

export const getUserId = (state: Object) => {
  const userProfile = get(state, ['users', 'profile'])
  return isEmpty(userProfile) ? null : userProfile.id
}

const userToLeaseInfoData = user => {
  return {
    moveInDate: formatDateMMDDYY(user.move_in_date),
    moveOutDate: formatDateMMDDYY(user.move_out_date),
    leaseStartDate: formatDateMMDDYY(user.lease_start_date),
    leaseEndDate: formatDateMMDDYY(user.lease_end_date),
    balance: currencyToUsd(user.balance ? user.balance.balance : ''),
    monthlyCharge: currencyToUsd(user.balance ? user.balance.monthly_charge : ''),
    leaseInterestStatus: user.lease_interest_status || 'not_applicable'
  }
}

export const getUserLeaseInfo = (state: Object) => {
  const userLeaseInfo = get(state, ['users', 'profile'])
  return isEmpty(userLeaseInfo) ? null : userToLeaseInfoData(userLeaseInfo)
}

export const getUserlockCode = (state: Object) => {
  return get(state, ['users', 'profile', 'lock_code'])
}

export const getUserProfilePicture = (state: Object) => {
  let profilePic = get(state, ['users', 'profile', 'picture'])

  return profilePic
}

export const getUsersResourceInfo = (state: Object) => {
  const user = get(state, ['users', 'profile'])

  if (user.companies.length > 0) {
    return {
      resource: 'companies',
      resourceId: user.companies[0]
    }
  }

  if (user.properties.length > 0) {
    return {
      resource: 'properties',
      resourceId: user.properties[0]
    }
  }

  return {
    resource: 'units',
    resourceId: user.units[0].id
  }
}

export const getUserFirstName = (state: Object) => {
  const firstName = get(state, ['users', 'profile', 'first_name'], '')
  return firstName
}

export const getUserLastName = (state: Object) => {
  const lastName = get(state, ['users', 'profile', 'last_name'], '')
  return lastName
}

export const getUserName = (state: Object) => {
  const user = get(state, ['users', 'profile'])
  return user.name
}

export const getUserBio = (state: Object) => {
  const bio = get(state, ['users', 'profile', 'bio'], '') || ''
  return bio.trim()
}

export const getUserTitle = (state: Object) => {
  const title = get(state, ['users', 'profile', 'user_metadata', 'title'], '')
  return title
}

export const visitorToVisitorData = (visitors: Array<any>): Object[] => {
  return visitors.map(visitor => {
    return {
      id: visitor.visitor_id,
      name: `${visitor.visitor_first_name} ${visitor.visitor_last_name}`,
      startDate: formatDateMMDDYY(visitor.start_date),
      endDate: formatDateMMDDYY(visitor.end_date),
      phone: formatPhoneNumber(visitor.visitor_phone)
    }
  })
}

export const getVisitors = (state: Object) => {
  const visitors = get(state, ['users', 'profile', 'visitors'], [])

  return visitors ? visitorToVisitorData(visitors) : []
}

export const getVisitorsCount = (state: Object) => {
  const visitors = get(state, ['users', 'profile', 'visitors'], [])

  return visitors ? visitors.length : ''
}

const petToPetData = (pet = {}) => {
  return {
    type: pet.type,
    weight: pet.weight,
    id: pet.id,
    breed: pet.breed,
    color: pet.color && pet.color.join(', '),
    status: pet.status,
    vaccination: pet.last_vaccination_date || '',
    name: pet.name,
    imageUrl: getProfileUrlOrDefault(pet.photo_url, pet.name)
  }
}

export const getPetsData = (state: Object) => {
  const userPets = get(state, ['users', 'profile', 'pets'], [])
  return { pets: userPets.map(petToPetData), total: userPets.length }
}

export const vehiclesToVehiclesData = (vehicles: Array<any>): Object[] => {
  return vehicles.map(vehicle => {
    return {
      id: vehicle.id,
      make: vehicle.make,
      model: vehicle.model,
      year: vehicle.year,
      name: vehicle.plate_number,
      color: vehicle.color,
      state: vehicle.state,
      imageUrl: getProfileUrlOrDefault(
        vehicle.resident_picture,
        vehicle.resident_name
      )
    }
  })
}

export const getVehicles = (state: Object) => {
  const vehicles = get(state, ['users', 'profile', 'vehicles'], [])

  return vehicles ? vehiclesToVehiclesData(vehicles) : []
}

export const getVehiclesCount = (state: Object) => {
  const vehicles = get(state, ['users', 'profile', 'vehicles'], [])

  return vehicles ? vehicles.length : ''
}

const userToOption = ({ user_id, name, user_metadata: { title } }) => {
  return { value: user_id, label: name + (title ? ` • ${title}` : '') }
}

export const getAssigneeList = (
  state: Object
): Array<{ value: ?string, label: string }> => {
  const propertyUsers = get(state, ['property', 'users'], {})
  const companyAdmins = get(
    state,
    ['users', 'summary', 'company_admin', 'data'],
    []
  )

  const options = (propertyUsers.allIds || [])
    .map(userId => propertyUsers.byId[userId])
    .concat(
      companyAdmins.map(({ user_id, display: name }) => {
        return { user_id, name, user_metadata: { title: 'Company Admin' } }
      })
    )
    .map(userToOption)
    .sort((a, b) => (a.label < b.label ? -1 : 1))

  return [{ value: null, label: 'Unassigned' }].concat(options)
}

export const getUnitResidents = (state: Object) => {
  const residents = get(
    state,
    ['users', 'summary', 'resident', 'active', 'data'],
    []
  )

  return residents.reduce((acc, { unit, display, user_id }) => {
    acc[unit] = acc[unit] || []
    acc[unit].push({ value: user_id, label: display })
    return acc
  }, {})
}
