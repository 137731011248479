import { call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken, getUserRole } from '../../authorization/selectors'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  fetchGroupEventsAction,
  fetchGroupEventsStart,
  fetchGroupEventsSuccess,
  fetchGroupEventsError
} from './groupEventsActions'

export function* fetchGroupEvents({ propertyId }) {
  try {
    yield put(fetchGroupEventsStart())
    const authToken = yield select(getToken)
    const userRole = yield select(getUserRole)

    //  Do property_staff check due to core api role check limitations, double refresh otherwise
    const response = (userRole === "property_staff")
      ? []
      : yield call(
        api.getGroupMessagingGroupEvents,
        authToken,
        propertyId
      )
    yield put(fetchGroupEventsSuccess(response))
  } catch (error) {
    yield put(fetchGroupEventsError(error))
    log(`Failed to fetch group events`)
  }
}

function* watchFetchGroupEvents() {
  yield takeLatest(fetchGroupEventsAction().type, fetchGroupEvents)
}

export default [watchFetchGroupEvents()]
