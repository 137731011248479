import { connect } from 'react-redux'
import ResidentManagementSurveys from './ResidentManagementSurveys'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { fetchResidentManagementSurveysAction, fetchNextResidentManagementSurveysAction } from 'zego-shared/store/users/property/actions'
import { fetchSurveyStats } from 'zego-shared/store/surveys/actions'
import { getResidentManagementSurveysData, getResidentManagementSurveysDataTotal } from '../../../store/Property/selectors'
import { getPropertyId } from 'zego-shared/store/select/selectors'
import { getSurveyStats } from 'zego-shared/store/surveys/selectors'
import withFetchData from 'zego-shared/views/withFetchData'

const fetchingSelector = createIsFetchingSelector(['property', 'users', 'resident_management_surveys'])

export const mapStateToProps = state => ({
  isFetching: fetchingSelector(state),
  residents: getResidentManagementSurveysData(state),
  residentsTotal: getResidentManagementSurveysDataTotal(state),
  propertyId: getPropertyId(state),
  surveyStats: getSurveyStats(state)
})

export const mapDispatchToProps = (
  dispatch,
  { history, match: { url, params: { propertyId } } }
) => ({
  fetchData: () => {
    dispatch(fetchResidentManagementSurveysAction({ id: propertyId }))
    dispatch(fetchSurveyStats())
  },
  fetchResidentManagementSurveysAction: ({ propertyId, page, params }) => {
    dispatch(fetchResidentManagementSurveysAction({ id: propertyId, page, params }))
  },
  fetchNextResidentManagementSurveysAction: ({ propertyId, page, params }) => {
    dispatch(fetchNextResidentManagementSurveysAction({ id: propertyId, page, params }))
  },

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(ResidentManagementSurveys))
