// @flow

import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Link, withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import SummaryCard from '@Common/Cards/SummaryCard'
import SummaryBG from '../../../../images/summary_bg.png'
import CarouselUnit from '../CarouselUnit'
import { getSmartHeaderTabs } from '../smartUtils'
import { State, Props } from '../types'
import { FabButton } from './styles'

class UnitsSmartSummary extends React.Component<Props, State> {
  filtersToDisplay = () => [
    { name: 'Occupied', query: { filter: 'occupied' } },
    { name: 'Vacant', query: { filter: 'vacant' } },
    { name: 'Common', query: { filter: 'common' } }
  ]

  getCarouselRows = () => {
    const { unitsInfo } = this.props
    return unitsInfo.map((datum, index) => {
      return (
        <CarouselUnit
          key={index}
          lastUnit={index === unitsInfo.length - 1}
          {...datum}
        />
      )
    })
  }

  render() {
    const {
      unitsCount,
      headingText,
      filter,
      type,
      propertyId,
      scheduleEnabled,
      hasPms,
      match: { url },
      theme
    } = this.props

    return (
      <React.Fragment>
        <SummaryCard
          countText={filter}
          captionHeading="ZEGO SMART"
          unitCount={unitsCount}
          mainHeading={`${headingText} Units`}
          headerTabs={getSmartHeaderTabs(propertyId, type, scheduleEnabled)}
          items={this.getCarouselRows()}
          overflow="visible"
          imageSrc={SummaryBG}
          filterNames={this.filtersToDisplay()}
        />
        {!hasPms && (
          <Link to={`${url.split('/units')[0]}/units/create`}>
            <FabButton variant="fab" color="secondary">
              <AddIcon style={{ color: theme.palette.common.white }} />
            </FabButton>
          </Link>
        )}
      </React.Fragment>
    )
  }
}

export default withRouter(withTheme(UnitsSmartSummary))
