import { TEMPLATES } from './actions'
import withFetch from '../../helpers/reducers/withFetch'
import { createTemplateSuccess } from '../template/actions'

const templates = (state = null, action) => {
  switch (action.type) {
    case createTemplateSuccess().type:
      return [...state, action.payload]
    default:
      return state
  }
}

export default withFetch(TEMPLATES)(templates)
