// @flow

import React, { Fragment } from 'react'
import { generate as shortid } from 'shortid'
import type { Node } from 'react'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { Title, Subtitle, Bullet, ListItem, CheckItem } from './styles'

export type SetupListItemType = ?Array<string>

const createChecklistItems = (
  items: Array<string>,
  secondary: ?string
): Array<any> => items.map(item => ({ primary: item, secondary: secondary }))

const SetupList = ({ listItems }) => (
  <Fragment>
    {createChecklistItems(listItems).map(({ primary, secondary }, index) => (
      <ListItem key={shortid()} disableRipple disableGutters button>
        <ListItemText
          primary={
            <Fragment>
              <Bullet>{index + 1}.</Bullet> <CheckItem>{primary}</CheckItem>
            </Fragment>
          }
        />
        {secondary && (
          <ListItemSecondaryAction>{secondary}</ListItemSecondaryAction>
        )}
      </ListItem>
    ))}
  </Fragment>
)

type Props = {
  listItems?: SetupListItemType,
  secondaryListTitle?: string | Node,
  title?: string | Node,
  subtitle?: string | Node,
  children?: Node
}

export default class SetupStep extends React.Component<Props, {}> {
  render() {
    const {
      title,
      subtitle,
      listItems,
      secondaryListTitle,
      children
    } = this.props

    return (
      <div>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        {listItems && (
          <SetupList
            listItems={listItems}
            secondaryListTitle={secondaryListTitle}
          />
        )}
        {children}
      </div>
    )
  }
}
