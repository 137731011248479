// import Config from 'react-native-config'
import log from './logger'
import { Socket as PhoenixSocket } from './phoenix'
import { noSocketChannelFound, genericTimedOutMessage } from './messages'

class Socket {
  url(url) {
    this.url = url
  }

  init = async token => {
    log('initializing socket')
    if (!this.url) {
      throw new Error(
        `Url must be set before initializing websocket. Set url like this: Socket.url(socketUrl)`
      )
    }

    this.phoenixSocket = new PhoenixSocket(`${this.url}/device`, {
      params: { token }
    })

    this.phoenixSocket.onClose(evt => {
      log('The socket closed.', evt)
    })

    this.phoenixSocket.connect()

    // We call init ON_FIRST_RUN which isn't async so we have to make sure the socket connects before we join a channel
    this.socketOpenPromise = new Promise((resolve, reject) => {
      this.phoenixSocket.onOpen(resolve)
      this.phoenixSocket.onError(evt => {
        log('There was an error on the socket.', evt)
        reject(evt)
      })
    })
  }

  disconnect = () => {
    this.phoenixSocket.disconnect()
  }

  joinChannel = async topic => {
    // see comment above in init
    try {
      await this.socketOpenPromise
    } catch (error) {
      throw new Error(error)
    }

    const existingChannel = this._findChannel(topic)
    if (existingChannel) {
      return existingChannel
    }

    const channel = this.phoenixSocket.channel(topic)

    channel.onError(evt => log('there was an error!', evt))
    channel.onClose(evt => log('the channel has gone away gracefully', evt))

    return new Promise((resolve, reject) => {
      channel
        .join()
        .receive('ok', () => {
          log('Channel connected: ', channel.topic)
          resolve(channel)
        })
        .receive('phx_reply', msg => {
          console.log('received msg on phx_reply: ', msg)
        })
        .receive('phx_error', msg => {
          console.log('received msg on phx_error: ', msg)
        })
        .receive('error', error => {
          log('Failed to connect to channel: ', error)
          reject(error)
        })
        .receive('timeout', () => {
          log('timed out')
          reject('timed out')
        })
    })
  }

  getChannel = topic => {
    const channel = this._findChannel(topic)

    return channel ? channel : this.phoenixSocket.channel(topic)
  }

  pushMessage = (topic, payload) => {
    return new Promise((resolve, reject) => {
      log(`Push called for topic: ${topic} with payload`, payload)
      const channel = this._findChannel(topic)

      if (!channel) {
        reject(new Error(noSocketChannelFound))
      }

      channel
        .push('command', payload)
        .receive('ok', msg => {
          resolve(msg)
        })
        .receive('error', error => {
          reject(error)
        })
        .receive('timeout', () => {
          reject(new Error(genericTimedOutMessage))
        })
    })
  }

  leaveChannel = channel => {
    if (!channel) return

    return new Promise((resolve, reject) => {
      channel
        .leave()
        .receive('ok', () => {
          log('left channel: ', channel.topic)
          resolve()
        })
        .receive('error', error => {
          log('Failed to leave channel: ', error)
          reject(error)
        })
    })
  }

  _findChannel = topic => {
    return this.phoenixSocket.channels.find(x => x.topic === topic)
  }
}

export default new Socket()
