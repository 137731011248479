import styled from '@emotion/styled'

export const BodyText = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  width: 80%;
  font-weight: ${({ theme }) => theme.custom.font.weight.thin};
  color: ${({ theme }) => theme.palette.text.secondary};
  line-height: 1.5em;
`

export const BodyTitle = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.md};
  margin-bottom: 8px;
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
`
