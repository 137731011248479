import { connect } from 'react-redux'
import queryString from 'query-string'
import Residents from './Residents'
import { fetchPropertyUsersSummary } from 'zego-shared/store/users/propertyUsersSummary/actions'
import { getPropertyId } from 'zego-shared/store/select/selectors'
import { hasPms, getPmsName } from 'zego-shared/store/summary/selectors'
import { getPropertyResidentsCount } from 'zego-shared/store/users/selectors'
import { getResidentSurveysEnabled, getResidentMoveInChecklistEnabled } from 'zego-shared/store/settings/propertySettings/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { getLeaseRenewalEnabled } from 'zego-shared/store/settings/selectors'

export const mapStateToProps = (state, { location: { search } }) => {
  const filter = queryString.parse(search).filter || 'summary'

  return {
    hasPms: hasPms(state),
    pmsName: getPmsName(state),
    propertyId: getPropertyId(state),
    residentsCount: getPropertyResidentsCount(state),
    leaseRenewalEnabled: getLeaseRenewalEnabled(state),
    residentSurveysEnabled: getResidentSurveysEnabled(state),
    residentMoveInChecklistEnabled: getResidentMoveInChecklistEnabled(state),
    filter
  }
}

export const mapDispatchToProps = (
  dispatch,
  { history, match: { url, params: { propertyId } } }
) => ({
  fetchData: () => {
    dispatch(fetchPropertyUsersSummary(propertyId))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(Residents))
