//@flow
import React from 'react'
import Flex from '../../Flex'
import { StatArea, StatsOverlay, StyledCount, StyledCountBody } from './styles'
import type { FilteredUnitCountProps } from './types'

const SummaryCount = ({
  count,
  countText,
  isFetching,
  imageSrc
}: FilteredUnitCountProps) => {
  return (
    <Flex flex={1}>
      <StatArea>
        <StyledCount>{isFetching ? '' : count}</StyledCount>
        <StyledCountBody>
          {isFetching ? 'Loading...' : countText}
        </StyledCountBody>
        <StatsOverlay imageSrc={imageSrc} />
      </StatArea>
    </Flex>
  )
}

export default SummaryCount
