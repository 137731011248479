// @flow
import React from 'react'
import type { SmartSummaryContentProps } from './types'
import Flex from '../../Flex'
import Tabs from '../../Cards/Tabs'
import Carousel from './SummaryCarousel'
import SummaryCount from './SummaryCount'
import Loader from '../../../Common/Loader'

const numberOfItemsPerSlide = height => {
  if (height >= 2000) return 15
  return 6
}

const SmartSummaryContent = ({
  items,
  filterNames,
  countText,
  isFetching,
  hideSummaryCount,
  autoHeight,
  imageSrc = ''
}: SmartSummaryContentProps) => {
  return (
    <Flex
      direction="column"
      data-testid="smart-summary-content"
      fullWidth
      style={{
        marginTop: '40px'
      }}>
      <Tabs tabStyle={1} tabs={filterNames} marginRight="50px" />
      <Flex
        flex={0.7}
        style={{
          minHeight: '25em',
          height: '80%',
          marginTop: '3%'
        }}>
        {!hideSummaryCount && (
          <SummaryCount
            count={items.length}
            countText={countText}
            isFetching={isFetching}
            imageSrc={imageSrc}
          />
        )}

        <Flex flex={2} justifySpaceBetween direction="column">
          {isFetching ? (
            <Loader />
          ) : (
            <Carousel
              items={items}
              lastSlideNumber={Math.ceil(
                items.length / numberOfItemsPerSlide(window.innerHeight)
              )}
              transitionHeights={['0em', '2em']}
              numberOfItemsPerSlide={numberOfItemsPerSlide(window.innerHeight)}
              autoHeight={autoHeight}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SmartSummaryContent
