import React, { useEffect, useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import { GridItem } from '@Common/Grid'
import SummaryHeader from '@Common/Cards/SummaryCard/SummaryCardHeader'
import ReSyncPmsButton from '../UserManagement/ReSyncPmsButton'
import { withTheme } from 'emotion-theming'
import ResidentSummary from './ResidentSummary/ResidentSummaryContainer'
import ResidentManagementSurveys from './ResidentManagementSurveys/ResidentManagementSurveysContainer'
import ResidentManagementMoveInMoveOut from './ResidentManagementMoveInMoveOut/ResidentManagementMoveInMoveOutContainer'
import ResidentLeaseRenewals from './ResidentLeaseRenewal/ResidentLeaseRenewalContainer'
import Tabs from '@Common/Cards/Tabs'
import queryString from 'query-string'

import {
  StyledTitleCard,
  StyledLink,
  FabButton,
  Spacing
} from './styles'

const Residents = (props) => {
  const {
    residentsCount,
    propertyId,
    theme,
    hasPms,
    pmsName,
    dispatch,
    match,
    leaseRenewalEnabled,
    residentSurveysEnabled,
    location,
    residentMoveInChecklistEnabled
  } = props

  const integrated = hasPms && pmsName !== 'paylease'

  const [tabWiseResidentCount, setTabWiseResidentCount] = useState(residentsCount)
  const [mimoCount, setMimoCount] = useState(0)
  const [showReSyncButton, setShowReSyncButton] = useState(true)

  const tabs = [
    {
      name: 'Summary',
      query: { filter: 'summary' },
      key: 'summary',
    }
  ]
  if (residentSurveysEnabled) {
    tabs.splice(1, 0,
      {
        name: 'Surveys',
        query: { filter: 'surveys' },
        key: 'surveys'
      })
  }

  if (leaseRenewalEnabled) {
    tabs.push({
      name: 'Lease Renewal',
      query: { filter: 'lease-renewal' },
      key: 'Lease Renewal'
    })
  }

  if (residentMoveInChecklistEnabled) {
    tabs.push(
      {
        name: 'Move-in Checklist',
        query: { filter: 'Move-in Checklist' },
        key: 'Move-in Checklist'
      }
    )
  }

  const getCurrentTab = () => {
    const currentQuery = queryString.parse(location.search)
    const currentTab = tabs.find(tab => tab.query.filter === currentQuery.filter)
    return currentTab ? currentTab.name : 'Summary'
  }
  const [tab, setTab] = useState(getCurrentTab())

  useEffect(() => {
    if (tab === "Move-in Checklist") {
      setTabWiseResidentCount(mimoCount)
    } else {
      setTabWiseResidentCount(residentsCount)
    }
    // eslint-disable-next-line
  }, [mimoCount, tab, residentsCount])

  const handleStatusFilter = (e) => {
    setTab(e.target.innerText)
  }

  const renderTab = () => {
    switch (tab) {
      case 'Surveys':
        return <ResidentManagementSurveys dispatch={dispatch} match={match} />
      case 'Lease Renewal':
        return <ResidentLeaseRenewals dispatch={dispatch} match={match} />
      case 'Move-in Checklist':
        return <ResidentManagementMoveInMoveOut
          showReSyncButton={showReSyncButton}
          setShowReSyncButton={setShowReSyncButton}
          updateResidentValue={setMimoCount}
          dispatch={dispatch}
          match={match}
          residentMoveInChecklistEnabled={residentMoveInChecklistEnabled} />
      default:
        return <ResidentSummary dispatch={dispatch} match={match} residentSurveysEnabled={residentSurveysEnabled} />
    }
  }

  return (
    <>
      <GridItem columnStart={1} columnSpan={12} rowStart={1} rowSpan={2}>
        <StyledTitleCard>
          <SummaryHeader
            captionHeading={"RESIDENT MANAGEMENT"}
            mainHeading={`${tabWiseResidentCount || 0} Residents`}
          />
        </StyledTitleCard>
        <Tabs
          marginRight={'40px'}
          onTabClick={handleStatusFilter}
          selectTextColor={theme.palette.common.black}
          tabs={tabs}
          customStyle={`
            .tab-root {
            color: ${theme.palette.grey.main};
            font-weight: ${theme.custom.font.weight.light};
            margin-top: 10px;
          }
        `}
        />
      </GridItem>
      <Spacing />
      {renderTab()}
      {showReSyncButton &&
        <GridItem columnStart={12} columnSpan={1} rowStart={5} rowSpan={1}>
          <ReSyncPmsButton />
        </GridItem>}
      {
        !integrated && (
          <StyledLink to={`/properties/${propertyId}/user-management/add-user`}>
            <FabButton variant="fab">
              <AddIcon style={{ color: theme.palette.common.white }} />
            </FabButton>
          </StyledLink>
        )
      }
    </>
  )
}

export default withTheme(Residents)
