import { connect } from 'react-redux'
import { fetchAllSettings } from 'zego-shared/store/settings/actions'
import { hasSettingsData } from '../../store/Settings/selectors'
import Settings from './Settings'

const mapStateToProps = state => ({
  hasData: hasSettingsData(state)
})

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  return {
    fetchData: () => {
      dispatch(fetchAllSettings(params.propertyId))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings)
