import React from 'react'
import LoginContainer from '../../views/Login'
import UserSpoofingContainer from '../../views/Login/UserSpoofingContainer'
import Logout from '../../views/Logout'
import Signup from '../../views/Signup'
import { Route } from 'react-router-dom'

const getAuthRoutes = () => [
  <Route key="login" exact path="/login" component={LoginContainer} />,
  <Route
    key="userSpoofing"
    exact
    path="/spoof-user"
    component={UserSpoofingContainer}
  />,
  <Route key="logout" exact path="/logout" component={Logout} />,
  <Route
    key="resetPassword"
    exact
    path="/password-reset"
    render={props => <LoginContainer {...props} mode="passwordReset" />}
  />,
  <Route
    key="activateAccount"
    exact
    path="/account/activate"
    component={Signup}
  />
]

export default getAuthRoutes
