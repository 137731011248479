// @flow
import React from 'react'
import FlexDirection from '@Common/Flex/FlexDirection'
import CarouselList from '@Common/CarouselList'
import withScrollBehaviour from '@Common/CarouselList/withScrollBehaviour'
import { NoItemMessage } from './styles'

import { CarouselProps } from './types'

export const Carousel = ({
  items,
  itemHeight,
  currentSlideNumber,
  numberOfItemsPerSlide,
  noItemMessage
}: CarouselProps) => {
  const hasItem: boolean = items && items.length > 0 ? true : false
  return (
    <FlexDirection
      direction="column"
      style={{ height: window.innerHeight < 700 ? '60%' : '70%' }}>
      {hasItem && (
        <CarouselList
          numberOfItemsPerSlide={numberOfItemsPerSlide}
          itemHeight={itemHeight}
          currentSlide={currentSlideNumber}
          items={items}
        />
      )}
      {!hasItem && (
        <NoItemMessage>
          <p>{noItemMessage}</p>
        </NoItemMessage>
      )}
    </FlexDirection>
  )
}

export default withScrollBehaviour(Carousel)
