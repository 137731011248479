import { combineReducers } from 'redux'
import {
  petsReducer as pets,
  petReducer as pet,
  petsColorsReducer as petsColors,
  petsSizesReducer as petsSizes,
  petsStatusesReducer as petsStatuses,
  petsTypesReducer as petsTypes
} from './pets'
import {
  vehiclesReducer as vehicles,
  vehicleReducer as vehicle,
  vehiclesColorsReducer as vehiclesColors,
  vehiclesStatesReducer as vehiclesStates,
  vehiclesTypesReducer as vehiclesTypes,
  vehiclesMakesReducer as vehiclesMakes
} from './vehicles'
import {
  visitorReducer as visitor,
  visitorsReducer as visitors
} from './visitors'

const registration = combineReducers({
  pets,
  pet,
  petsColors,
  petsSizes,
  petsStatuses,
  petsTypes,
  vehicles,
  vehicle,
  vehiclesColors,
  vehiclesStates,
  vehiclesTypes,
  vehiclesMakes,
  visitor,
  visitors
})

export default registration
