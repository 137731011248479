// @flow

import React from 'react'
import mutableSet from 'lodash/set'
import { withTheme } from 'emotion-theming'
import Switch from '@material-ui/core/Switch'
import { GridItem } from '../../../Common/Grid'
import Flex, { FlexItem } from '@Common/Flex'
import Btn from '@Common/Cards/CardButton'
import Avatar from '@Common/Avatar'
import TextField from '../../../Common/TextField'
import Tooltip from '../../../Common/Tooltip'
import MaskPhoneNumber from '../../../Common/MaskPhoneNumber'
import Loader from '../../../Common/Loader'

import { isValidImageFile } from '../../../../utils/validationUtils'

import {
  Name,
  PropertyCode,
  PropertyCodeTitle,
  DeleteLink,
  CreateLink,
  UnitName,
  Fab,
  FabButtonWrap,
  StaffTitle,
  StaffRole,
  NotActiveMessage,
  ActivationLink
} from '../styles'
import { CommonIcon } from '@icons'
import { AvatarInput } from '../styles'
import type { Theme } from '../../../../theme'

type Profile = {
  userId: string,
  name: string,
  title: string,
  bio: string,
  role: string,
  picture: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  lockCode: string,
  unit: string,
  lastActive: string,
  accountActivatedTime: string,
  email: string,
  status: string
}

type Props = {
  isFetching: boolean,
  hasData: boolean,
  theme: Theme,
  profile: Profile,
  deleteLockCode: () => {},
  generateLockCode: () => {},
  updateUser: ({}) => mixed,
  uploadProfileImage: (image: File) => {},
  toggleStaffProfile: boolean => mixed,
  toggleResidentZegoSmartEnabled: boolean => mixed,
  showStaffProfile: boolean,
  residentZegoSmartEnabled: boolean,
  sendActivationEmail: (id: string) => void,
  isSmartEnabled: boolean
}

type State = {
  profile: Profile,
  edit: boolean,
  readOnly: boolean,
  edited: boolean
}

export class GeneralInfo extends React.Component<Props, State> {
  state = {
    profile: this.props.profile,
    edit: false,
    readOnly: true,
    edited: false
  }

  componentWillReceiveProps = (nextProps: Props) => {
    const nameChangeCheck = this.state.profile.name !== nextProps.profile.name
    const photoCheck = this.state.profile.picture !== nextProps.profile.picture
    const lockCodeCheck =
      this.state.profile.lockCode !== nextProps.profile.lockCode

    if (nameChangeCheck || lockCodeCheck || photoCheck) {
      this.setState({
        ...this.state,
        profile: nextProps.profile
      })
    }
  }

  uploadProfileImage = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const image = event.target.files[0]

    if (isValidImageFile(image)) {
      this.props.uploadProfileImage(image)
    }
  }

  createLockCode = () => {
    this.props.generateLockCode()
  }

  deleteLockCode = () => {
    this.props.deleteLockCode()
  }

  handleChange = ({
    currentTarget: { id, value }
  }: SyntheticEvent<HTMLInputElement>) => {
    const { profile } = this.state
    this.setState(oldState => {
      return {
        ...oldState,
        profile: mutableSet(profile, id, value),
        edited: true
      }
    })
  }

  toggleEdit = () => {
    const { updateUser } = this.props

    if (this.state.edited === true) {
      const { profile } = this.state
      updateUser(profile)
    }

    this.setState({
      edit: !this.state.edit,
      readOnly: !this.state.readOnly,
      edited: false
    })
  }

  toggleStaffProfile = (
    _e: SyntheticInputEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { toggleStaffProfile } = this.props
    toggleStaffProfile(checked)
  }

  toggleResidentZegoSmartEnabled = (
    _e: SyntheticInputEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { toggleResidentZegoSmartEnabled } = this.props
    toggleResidentZegoSmartEnabled(checked)
  }

  sendActivationEmail = () => {
    this.props.sendActivationEmail(this.state.profile.userId)
  }

  setButtonText = (status: string) => {
    return status === 'Pending' ? 'REMIND ME' : 'ACTIVATE'
  }

  setNotActiveMessage = (status: string) => {
    return status === 'Pending' ? (
      <NotActiveMessage>
        This user is currently pending. They have been sent an invite to setup
        their Zego account but have yet to accept.
        <ActivationLink onClick={this.sendActivationEmail}>
          {' '}
          Remind Me
        </ActivationLink>
      </NotActiveMessage>
    ) : (
      <NotActiveMessage>
        This user is currently inactive. To Activate this user, send them an
        invite to setup their Zego account.
        <ActivationLink onClick={this.sendActivationEmail}>
          {' '}
          Send Invite
        </ActivationLink>
      </NotActiveMessage>
    )
  }

  render() {
    const {
      isFetching,
      hasData,
      theme,
      showStaffProfile,
      isSmartEnabled,
      residentZegoSmartEnabled
    } = this.props
    const { profile, edit, readOnly } = this.state
    const {
      title,
      bio,
      role,
      picture,
      firstName,
      lastName,
      phoneNumber,
      lockCode,
      unit,
      lastActive,
      accountActivatedTime,
      email,
      status
    } = profile

    if (isFetching) return <Loader />
    if (!hasData) return null

    return (
      <React.Fragment>
        {role !== 'Resident' ? (
          <FabButtonWrap>
            <Fab
              onClick={this.toggleEdit}
              variant="fab"
              background="transparent"
              outlined
              component={() =>
                edit ? (
                  <CommonIcon
                    name="tick"
                    width="1em"
                    height="1em"
                    strokeWidth={2}
                    stroke={theme.palette.primary.navy.midnight}
                  />
                ) : (
                  <CommonIcon
                    name="edit"
                    width="1em"
                    height="1em"
                    fill={theme.palette.primary.navy.midnight}
                  />
                )
              }
            />
          </FabButtonWrap>
        ) : (
          ''
        )}
        <GridItem columnStart={5} columnSpan={8} rowStart={2} rowSpan={6}>
          <Flex fullWidth fullHeight>
            <FlexItem flex={2}>
              <label htmlFor="file-input">
                <Avatar
                  showOverlay={true}
                  picture={picture}
                  overlayText={'upload new +'}
                  size={'192px'}
                  overlayContent={() => (
                    <React.Fragment>
                      <span>upload new +</span>
                      <AvatarInput
                        id="file-input"
                        type="file"
                        onChange={this.uploadProfileImage}
                      />
                    </React.Fragment>
                  )}
                />
              </label>
            </FlexItem>

            <FlexItem flex={6}>
              <Flex direction="column" justifySpaceBetween fullHeight>
                {edit ? (
                  <Flex direction="column">
                    <Flex justifySpaceBetween>
                      <Flex flex={0.45} direction="column">
                        <TextField
                          id="firstName"
                          onChange={this.handleChange}
                          value={firstName}
                          error={false}
                          showPencil={edit}
                          readOnly={readOnly}
                          label={edit ? 'first name' : ''}
                          editMode={edit}
                          textAlign={'left'}
                          alwaysShowLabel={true}
                          largeFont={true}
                        />
                      </Flex>

                      <Flex flex={0.45} direction="column">
                        <TextField
                          id="lastName"
                          onChange={this.handleChange}
                          value={lastName}
                          error={false}
                          showPencil={edit}
                          readOnly={readOnly}
                          label={edit ? 'last name' : ''}
                          editMode={edit}
                          textAlign={'left'}
                          alwaysShowLabel={true}
                          largeFont={true}
                        />
                      </Flex>
                    </Flex>

                    <Flex fullWidth>
                      <TextField
                        fullWidth
                        id="title"
                        onChange={this.handleChange}
                        placeholder="Title"
                        value={title}
                        error={false}
                        showPencil={edit}
                        readOnly={readOnly}
                        label={edit ? 'title' : ''}
                        editMode={edit}
                        textAlign={'left'}
                        alwaysShowLabel={true}
                      />
                    </Flex>
                  </Flex>
                ) : (
                  <Flex direction="column">
                    <FlexItem flex={0.5}>
                      <StaffRole>{`Role/ ${role}`}</StaffRole>
                      <Flex justifySpaceBetween direction="row">
                        <Name>
                          {firstName} {lastName}
                        </Name>
                        {status !== 'Active' && role === 'Resident' ? (
                          <Btn
                            text={this.setButtonText(status)}
                            background={theme.palette.blue.main}
                            width={'8em'}
                            onClick={this.sendActivationEmail}
                          />
                        ) : (
                          ''
                        )}
                      </Flex>
                    </FlexItem>
                    {role !== 'Resident' ? (
                      <StaffTitle>{title ? title : 'Title'}</StaffTitle>
                    ) : (
                      ''
                    )}
                    {unit !== '' ? (
                      <FlexItem flex={2}>
                        <UnitName>{unit}</UnitName>
                      </FlexItem>
                    ) : (
                      ''
                    )}
                  </Flex>
                )}

                <FlexItem style={{ padding: '1.25em 0em' }}>
                  <div>
                    {status === 'Active' || role !== 'Resident' ? (
                      <TextField
                        fullWidth
                        id="bio"
                        onChange={this.handleChange}
                        value={bio}
                        multiline
                        textAlign={'left'}
                        label={edit ? 'bio' : ''}
                        placeholder="Bio"
                        disableUnderline={edit ? false : true}
                        readOnly={readOnly}
                        showPencil={edit}
                        alwaysShowLabel={true}
                        editMode={edit}
                      />
                    ) : (
                      this.setNotActiveMessage(status)
                    )}
                  </div>
                </FlexItem>
                <FlexItem>
                  <TextField
                    fullWidth
                    id="phoneNumber"
                    value={phoneNumber}
                    prefix="Cell:"
                    onChange={this.handleChange}
                    showPencil={role === 'Resident' ? false : edit}
                    readOnly={readOnly}
                    textMask={MaskPhoneNumber}
                  />
                  <TextField
                    fullWidth
                    id="email"
                    value={email}
                    prefix="Email:"
                    onChange={this.handleChange}
                    showPencil={role === 'Resident' ? false : edit}
                    readOnly={readOnly}
                  />
                  <TextField
                    fullWidth
                    id="lastActive"
                    value={lastActive}
                    prefix="Last Active:"
                    showPencil={false}
                    readOnly={true}
                  />
                  {accountActivatedTime && (
                    <TextField
                      fullWidth
                      id="accountActivatedTime"
                      value={accountActivatedTime}
                      prefix="Account Activated On:"
                      showPencil={false}
                      readOnly={true}
                    />
                  )}

                  {role !== 'Resident' && role !== 'Company Admin' && (
                    <Flex justifySpaceBetween alignCenter paddingLeft="10px">
                      <font color="#556271">Show Staff Profile:</font>
                      <Switch
                        disabled={false}
                        onChange={this.toggleStaffProfile}
                        checked={showStaffProfile}
                      />
                    </Flex>
                  )}
                  {role === 'Resident' && isSmartEnabled && (
                    <Flex justifySpaceBetween alignCenter paddingLeft="10px">
                      <font color="#556271">Zego Smart Enabled:</font>
                      <Switch
                        disabled={false}
                        onChange={this.toggleResidentZegoSmartEnabled}
                        checked={residentZegoSmartEnabled}
                      />
                    </Flex>
                  )}
                </FlexItem>
                {role !== 'Resident' && isSmartEnabled ? (
                  <FlexItem>
                    <Flex>
                      <PropertyCodeTitle>
                        {'Property Code'} <Tooltip type="PropertyCode2" />
                      </PropertyCodeTitle>
                      <FlexItem flex={11}>
                        {<PropertyCode>{lockCode}</PropertyCode>}
                      </FlexItem>
                      {lockCode ? (
                        <FlexItem flex={0.5}>
                          <DeleteLink onClick={this.deleteLockCode}>
                            <CommonIcon
                              name="close"
                              width="0.5em"
                              height="0.5em"
                              fill={theme.palette.error.main}
                              strokeWidth={4}
                              stroke={theme.palette.error.main}
                            />
                          </DeleteLink>
                        </FlexItem>
                      ) : (
                        <FlexItem flex={0.5}>
                          <CreateLink onClick={this.createLockCode}>
                            <CommonIcon
                              name="plus"
                              width="0.5em"
                              height="0.5em"
                              fill={theme.palette.primary.green.mint}
                              strokeWidth={4}
                              stroke={theme.palette.primary.green.mint}
                            />
                          </CreateLink>
                        </FlexItem>
                      )}
                    </Flex>
                  </FlexItem>
                ) : (
                  ''
                )}
              </Flex>
            </FlexItem>
          </Flex>
        </GridItem>
      </React.Fragment>
    )
  }
}

export default withTheme(GeneralInfo)
