// @flow
import compose from 'lodash/flowRight'

export const capitalize = (str: string) => str.toUpperCase()

export const convertSeparatorToSpace = (
  str: string,
  pattern: RegExp | string
) => str.replace(pattern, ' ')

export const underscoreToSpace = (str: string) =>
  convertSeparatorToSpace(str, /_/g)

export const dashToSpace = (str: string) => convertSeparatorToSpace(str, /-/g)

export const capitalizeUnderscoreToSpace = compose([
  capitalize,
  underscoreToSpace
])

export const capitalizeFirstLetter = (str: string = '') =>
  str.charAt(0).toUpperCase() + str.slice(1)
