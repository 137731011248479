// @flow
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import Typography from '@material-ui/core/Typography'
import { Props } from './types'
import FlexDirection, {
  FlexItem
} from '@Common/Flex/FlexDirection'
import { UnitContainer, CircleWrap } from './styles'
import { CommonIcon } from '@icons'

const getStatus = status =>
  !status ? 'UNKNOWN CURRENT STATUS' : status.toUpperCase()

const CarouselUnit = ({
  name,
  status,
  url,
  lastUnit,
  theme,
  selected,
  history,
  match: { params },
  showDeleteDeviceModal
}: Props) => {
  return (
    <UnitContainer lastUnit={lastUnit} selected={selected}>
      <FlexDirection className="unit-containter-flex">
        <FlexItem
          flex={2}
          style={{ cursor: 'pointer' }}
          onClick={event => history.push(url)}>
          <span> {name}</span>
        </FlexItem>
        <FlexItem
          flex={10}
          style={{
            cursor: 'pointer'
          }}
          onClick={event => history.push(url)}>
          <Typography>{getStatus(status)}</Typography>
        </FlexItem>
        <FlexItem flex={1}>
          <Link to={url}>View more</Link>
        </FlexItem>
        <FlexItem flex={0.4}>
          <CircleWrap selected={selected} onClick={showDeleteDeviceModal}>
            <CommonIcon
              name="close"
              strokeWidth={5}
              width={'0.6em'}
              height={'0.6em'}
              stroke={
                selected
                  ? theme.palette.neutrals.deepGray
                  : theme.palette.secondary.pink.coral
              }
            />
          </CircleWrap>
        </FlexItem>
      </FlexDirection>
    </UnitContainer>
  )
}

export default withRouter(withTheme(CarouselUnit))
