// @flow
import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import { withTheme } from 'emotion-theming'
import Input from '@material-ui/core/Input'
import Button from '@Common/Cards/CardButton'
import MessagesList from './MessageList'
import { CommonIcon } from '@icons'
import { ChatUserRoutes } from '../../../../routes/Communication/Chat'
import { Theme } from '../../../../theme'
import {
  Window,
  ToolBar,
  Link,
  UploadButton,
  Files,
  UploadIcon
} from './styles'
import { isValidChatFile } from '../../../../utils/validationUtils'
import { ChatIcon } from '@icons'

type Props = {
  messages: {}[],
  sendMessage: (
    message: string,
    types: Array<string>,
    file: null | Object
  ) => void,
  users: Object[],
  userId: string,
  isFetching: boolean,
  theme: Theme,
  channelType: string,
  activeChannelUrl: string,
  pinnedChannels: Array<Object>,
  pinChannel: string => mixed,
  unpinChannel: string => mixed,
  chatUser: Object
}

type State = {
  newMessage: string,
  checked: {
    chat: boolean,
    text: boolean,
    email: boolean
  },
  file: null | Object
}

class ChatWindow extends React.Component<Props, State> {
  state = {
    newMessage: '',
    checked: {
      chat: true,
      text: false,
      email: false
    },
    file: null
  }

  sendMessage = () => {
    const { sendMessage } = this.props
    const {
      newMessage,
      checked: { chat, text, email },
      file
    } = this.state

    if (newMessage === '') {
      if (file === null) return
    }

    const types = []

    if (email) {
      types.push('email')
    }

    if (chat) {
      types.push('chat')
    }

    if (text) {
      types.push('text')
    }

    sendMessage(newMessage, types, file)
    this.setState({
      newMessage: '',
      file: null
    })
  }

  handleCheck = (option: string) => {
    const checked = {
      [option]: !this.state.checked[option]
    }
    this.setState({ checked: { ...this.state.checked, ...checked } })
  }

  handleTextChange = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ newMessage: event.currentTarget.value })
  }

  handleEnterSubmit = (event: SyntheticKeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      this.sendMessage()
    }
  }

  uploadFile = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let file = event.target.files[0]

    if (file && isValidChatFile(file.name)) {
      this.setState({ file })
    }
  }

  removeFile = () => {
    this.setState({ file: null })
  }

  render() {
    const {
      messages,
      isFetching,
      theme,
      activeChannelUrl,
      pinChannel,
      unpinChannel,
      pinnedChannels,
      chatUser
    } = this.props
    const { file } = this.state
    const { email, phoneNumber } = chatUser
    return (
      <Window>
        <ChatUserRoutes />
        <MessagesList
          channelUrl={activeChannelUrl}
          isPinned={pinnedChannels.includes(activeChannelUrl)}
          pinChannel={pinChannel}
          unpinChannel={unpinChannel}
          isFetching={isFetching}
          messages={messages}
        />
        <Input
          data-testid="chat-input-area"
          className="chat-message-area"
          disableUnderline={true}
          multiline={true}
          rows={window.innerHeight > 1000 ? 4 : 10}
          name="message"
          value={this.state.newMessage}
          onChange={this.handleTextChange}
          onKeyPress={this.handleEnterSubmit}
          endAdornment={
            <Files>
              {file ? (
                <Fragment>
                  <span>{file.name}</span>
                  <CommonIcon
                    name="close"
                    width="0.5em"
                    height="0.5em"
                    fill={theme.palette.primary.green.mint}
                    strokeWidth={8}
                    stroke={theme.palette.primary.green.mint}
                    onClick={this.removeFile}
                  />
                </Fragment>
              ) : (
                ''
              )}
            </Files>
          }
        />
        <ToolBar>
          <Typography>
            <Link style={{ cursor: 'auto' }} selected={this.state.checked.chat}>
              Chat
            </Link>
            {phoneNumber && (
              <Link
                selected={this.state.checked.text}
                onClick={() => this.handleCheck('text')}>
                Text
              </Link>
            )}
            {email && (
              <Link
                selected={this.state.checked.email}
                onClick={() => this.handleCheck('email')}>
                Email
              </Link>
            )}
          </Typography>
          <div>
            <UploadButton
              id="chat-file-input"
              type="file"
              onChange={this.uploadFile}
              multiple
            />
            <UploadIcon htmlFor="chat-file-input">
              <ChatIcon name="clip" width="17px" height="17px" />
            </UploadIcon>
            <Button
              id="chat-message-send"
              data-testid="chat-message-send"
              text="Send"
              width="76px"
              height="32px"
              noBorderRadius={true}
              onClick={this.sendMessage}
              background={props => props.theme.palette.blue.main}
            />
          </div>
        </ToolBar>
      </Window>
    )
  }
}

export { ChatWindow as ChatWindowWithoutTheme }
export default withTheme(ChatWindow)
