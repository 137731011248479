// @flow
import { connect } from 'react-redux'
import Templates from './Templates'
import { fetchTemplates, searchTemplates } from 'zego-shared/store/groupMessaging/templates'
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {
  hasTemplates,
  getTemplates
} from 'zego-shared/store/groupMessaging/templates/selectors'

import { getUserRole } from 'zego-shared/store/authorization/selectors'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'

const fetchingSelector = createIsFetchingSelector(['templates'])

const mapStateToProps = (state, { match: { params: { propertyId } } }) => ({
  data: getTemplates(state),
  isFetching: fetchingSelector(state),
  hasData: hasTemplates(state),
  userRole: getUserRole(state),
  propertyId
})

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  return {
    fetchData: () => dispatch(fetchTemplates(params.propertyId)),
    searchTemplates: searchTerm => {
      if (searchTerm == null || searchTerm === '') {
        dispatch(fetchTemplates(params.propertyId))
      } else {
        dispatch(searchTemplates(params.propertyId, searchTerm))
      }
    },
    displayErrorMessage: (message) => dispatch(showSnackbar(message, 'error')),
  }
}

const TemplatesWithFetchData = withFetchData(Templates)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplatesWithFetchData)
