import React from 'react'
import Tabs from '@Common/Cards/Tabs'
import { withTheme } from 'emotion-theming'

const getTabs = () => {
  return [
    {
      name: 'Email Message',
      route: ``,
      query: { type: 'email' }
    },
    {
      name: 'Text/Chat Message',
      route: ``,
      query: { type: 'text' }
    }
  ]
}

const EmailTextTabs = () => {
  return <Tabs tabs={getTabs()} tabStyle={1} scrollable={false} />
}

export default withTheme(EmailTextTabs)
