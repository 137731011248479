import axios from 'axios'
import { createHTTPHeader } from './utils'
import { isValidToken } from '../auth'
import { getToken, getRefreshToken } from '../../store/authorization/selectors'
import { getTokenByRefresh } from '../../store/authorization/actions'

const getTokenByRefreshPromise = (refreshToken, dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(getTokenByRefresh(refreshToken, resolve, reject))
  })
}

const getAuthToken = (store, error) => {
  const refreshToken = getRefreshToken(store.getState())

  if (isValidToken(refreshToken)) {
    const authTokenRequest = getTokenByRefreshPromise(refreshToken, store.dispatch)
    authTokenRequest.then(
      () => {},
      () => {}
    )

    return authTokenRequest
  }
  return Promise.reject(error)
}

export const setupAxios = store => {
  axios.interceptors.response.use(
    response => response,
    async (error) => {
      const originalConfig = error.config
      const response = error.response

      if (response && response.status === 401 && !originalConfig._retry) {
        return getAuthToken(store, error)
          .then(() => {
            const token = getToken(store.getState())
            const headers = createHTTPHeader(token)
            Object.entries(headers).forEach(
              ([k, v]) => originalConfig.headers[k] = v
            )
            originalConfig._retry = true
            window.location.reload()

            return axios(originalConfig)
          })
      }
      return Promise.reject(error)
    }
  )
}
