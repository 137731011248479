import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect, Route } from 'react-router-dom'
import PrivateRoute from '../PrivateRoute'
import { UnitSummary, PropertySummary } from '../../views/Summary'
import { getPropertyId } from 'zego-shared/store/select/selectors'

const SummaryRoutes = ({ propertyID, location }) => [
  <Route
    exact
    key="rootSummary"
    path="/summary"
    location={location}
    component={() => <Redirect to={`/properties/${propertyID}/summary`} />}
  />,
  <PrivateRoute
    key="propertySummary"
    exact
    path="/properties/:propertyId/summary"
    location={location}
    component={PropertySummary}
  />,
  <PrivateRoute
    key="unitSummary"
    location={location}
    path="/properties/:propertyId/units/:unitId/summary"
    component={UnitSummary}
  />
]

const mapStateToProps = state => ({
  propertyID: getPropertyId(state)
})

export default withRouter(connect(mapStateToProps)(SummaryRoutes))
