// @flow

import { connect } from 'react-redux'
import queryString from 'query-string'
import Template from './Template'
import { fetchTemplatePermission } from 'zego-shared/store/groupMessaging/templatePermission'
import { searchHistory } from 'zego-shared/store/groupMessaging/history'
import { fetchReplacementOptions } from 'zego-shared/store/groupMessaging/replacementOptions'
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import { getFeatureFlag } from '../../../../integrations/LaunchDarkly/selectors'
import { ldInitRequest } from '../../../../integrations/LaunchDarkly/actions'
import {
  hasTemplate,
  getTemplate,
  canChangeTemplate
} from 'zego-shared/store/groupMessaging/template/selectors'
import {
  fetchTemplate,
  updateTemplate,
  deleteTemplate,
  createTemplate
} from 'zego-shared/store/groupMessaging/template'
import {
  hasTemplatePermission,
  getTemplatePermission
} from 'zego-shared/store/groupMessaging/templatePermission/selectors'
import {
  hasReplacementOptions,
  getReplacementOptions
} from 'zego-shared/store/groupMessaging/replacementOptions/selectors'
import {
  hasHistory,
  getHistory,
  getHistoryPaginationInfo
} from 'zego-shared/store/groupMessaging/history/selectors'

import { getUserRole } from 'zego-shared/store/authorization/selectors'

const fetchingSelector = createIsFetchingSelector([
  'template',
  'replacementOptions',
  'history'
])

const mapStateToProps = (
  state,
  { history, match: { params: { templateId, propertyId } }, location: { search } }
) => {
  const isCreate = templateId === 'create'
  return {
    data: getTemplate(state, isCreate),
    replacementOptions: getReplacementOptions(state) || [],
    isFetching: fetchingSelector(state),
    hasData:
      hasTemplate(state) &&
      hasReplacementOptions(state) &&
      hasHistory(state) &&
      hasTemplatePermission(state),
    historyData: isCreate ? [] : getHistory(state),
    totalHistoryCount: getHistoryPaginationInfo(state).total,
    canChange: canChangeTemplate(state),
    permissions: getTemplatePermission(state),
    showTextMessage: queryString.parse(search).type === 'text',
    featureFlag: getFeatureFlag(state),
    isCreate,
    userRole: getUserRole(state),
    propertyId,
    history
  }
}

const mapDispatchToProps = (dispatch, { match: { params }, history }) => {
  const isCreate = params.templateId === 'create'
  return {
    fetchData: () => {
      dispatch(fetchReplacementOptions(params.propertyId))
      if (!isCreate) {
        dispatch(fetchTemplate(params.templateId))
        dispatch(
          searchHistory(params.propertyId, 1, '', {
            template_id: params.templateId
          })
        )
      }
      dispatch(fetchTemplatePermission())
    },
    showSnackbar: message => dispatch(showSnackbar(message, 'error')),
    updateTemplate: update => {
      dispatch(updateTemplate(update))
    },
    createTemplate: (update, callback) => {
      dispatch(createTemplate(update, callback))
    },
    deleteTemplate: () => {
      dispatch(deleteTemplate(params.templateId, history))
    },
    searchHistory: searchTerm => {
      dispatch(
        searchHistory(params.propertyId, 1, searchTerm, {
          template_id: params.templateId
        })
      )
    },
    ldInitRequest
  }
}

const TemplateWithFetchData = withFetchData(Template)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateWithFetchData)
