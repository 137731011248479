// @flow

import { connect } from 'react-redux'
import Surveys from './Surveys'
import { fetchResidentSurveys } from 'zego-shared/store/surveys/actions'
import { getResidentSurveys } from 'zego-shared/store/surveys/selectors'
import { getUserProfile } from 'zego-shared/store/users/selectors'
import { getChatEnabled } from 'zego-shared/store/settings/propertySettings/selectors'
import withFetchData from 'zego-shared/views/withFetchData'

const mapStateToProps = (state, { match: { params: { userId, propertyId } } }) => ({
  surveys: getResidentSurveys(state),
  profile: getUserProfile(state),
  chatEnabled: getChatEnabled(state),
  propertyId
})

const mapDispatchToProps = (dispatch, { match: { params: { userId } } }) => ({
  fetchData: () => {
    dispatch(fetchResidentSurveys(userId))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(Surveys))
