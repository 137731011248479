import styled from '@emotion/styled'

export const ErrorMessage = styled('div')`
  color: ${({ theme }) => theme.palette.error.main};
  padding: 10px;
`

export const SuccessMessage = styled('div')`
  color: ${({ theme }) => theme.palette.primary.green.mint};
`
