export const SHOW_SNACK_BAR = 'SHOW_SNACK_BAR'
export const HIDE_SNACK_BAR = 'HIDE_SNACK_BAR'

export const showSnackbar = (message, variant) => ({
  type: SHOW_SNACK_BAR,
  message,
  variant
}) 

export const hideSnackbar = () => ({
  type: HIDE_SNACK_BAR
})
