// @flow

import React from 'react'
import { connect } from 'react-redux'
import moment from "moment";
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { createIsUpdatingSelector } from 'zego-shared/store/isUpdating/selectors'
import { getUserName } from 'zego-shared/store/authorization/selectors'

import {
  fetchAmenity,
  updateAmenityAC,
  updateAmenityPhotoPin,
  deleteAmenity,
  deleteReservation,
  fetchReservationsAC,
  getAvailabilityAC,
  createReservation,
  setAmenityViewMode,
  deleteWaiver,
  createFacilityBlock,
  deleteFacilityBlock,
} from 'zego-shared/store/amenitiesPro'

import {
  showSnackbar,
} from 'zego-shared/store/snackbar/actions'

import { fetchPropertyUsersSummary } from 'zego-shared/store/users/propertyUsersSummary/actions'

import {
  hasAmenity,
  getAmenity,
  getReservations,
  getAvailability,
  getAmenityViewMode,
} from 'zego-shared/store/amenitiesPro/selectors'
import { getAllPropertyResidents } from 'zego-shared/store/users/selectors'

import { ldInitRequest } from '../../../integrations/LaunchDarkly/actions'
import AmenityPro from './AmenityPro'

const fetchingSelector = createIsFetchingSelector(['amenity'])
const updatingSelector = createIsUpdatingSelector(['amenity'])
const mapStateToProps = (state) => {
  const {
    readMode = true,
    editMode = false,
  } = getAmenityViewMode(state)

  let amenity
  let reservations
  let residents
  let availability
  let selectedAvailabilityDate = new Date().toISOString().substr(0,10)

  amenity = getAmenity(state)

  const { facilityUuid = null } = amenity
  if (facilityUuid) {
    const reservationsState = getReservations(state)
    reservations = (reservationsState && facilityUuid in reservationsState)
      ? reservationsState[facilityUuid]
      : []

    const { date = null } = reservationsState
    if (date) {
      selectedAvailabilityDate = date
    }

    if (!amenity.placeholder) {
      availability = getAvailability(state)
    }
  }

  residents = getAllPropertyResidents(state)

  return {
    item: amenity,
    reservations,
    availability,
    isFetching: fetchingSelector(state),
    isUpdating: updatingSelector(state),
    hasData: hasAmenity(state),
    selectedAvailabilityDate,
    userName: getUserName(state),
    residents,
    readMode,
    editMode,
  }
}

const mapDispatchToProps = (dispatch, { history, match: { url, params } }) => {
  // we just use the local date to get the initial startDate
  const startDate = moment().format('yyyy-MM-DD')
  return {
    fetchData: () => {
      dispatch(fetchAmenity(params.amenityId, params.propertyId, history))
      dispatch(fetchPropertyUsersSummary(params.propertyId))
      dispatch(fetchReservationsAC(params.propertyId, params.amenityId, startDate))
      dispatch(getAvailabilityAC(params.amenityId, startDate))
    },
    fetchReservations: (selectedDate) => {
      dispatch(fetchReservationsAC(params.propertyId, params.amenityId, selectedDate))
    },
    getAvailability: (selectedDate) => {
      dispatch(getAvailabilityAC(params.amenityId, selectedDate))
    },
    createReservation: (reservation, residentName) => {
      dispatch(createReservation(reservation, residentName))
      dispatch(fetchAmenity(params.amenityId, params.propertyId, history))
    },
    deleteReservation: (reservation, message, residentName) => {
      dispatch(deleteReservation(reservation, message, residentName))
      dispatch(fetchAmenity(params.amenityId, params.propertyId, history))
    },
    deleteWaiver: (waiverUuid, amenityId) => {
      dispatch(deleteWaiver(waiverUuid, amenityId))
    },
    createFacilityBlock: (facilityUuid, body) => {
      dispatch(createFacilityBlock(facilityUuid, body))
    },
    deleteFacilityBlock: (facilityUuid, body) => {
      dispatch(deleteFacilityBlock(facilityUuid, body))
    },
    updateAmenity: (update, amenityId, date) => {
      dispatch(updateAmenityAC(update, history, url, amenityId))
      dispatch(fetchAmenity(amenityId, params.propertyId, history)) 
      dispatch(getAvailabilityAC(amenityId, date))  
    },
    updateAmenityPhotoPin: (update, amenityId) => {
      dispatch(updateAmenityPhotoPin(update, amenityId))
    },
    deleteAmenity: (id) => {
      dispatch(deleteAmenity(id, history))
    },
    ldInitRequest,
    setViewMode: ({ readMode, addMode, editMode }) => {
      dispatch(setAmenityViewMode({ readMode, addMode, editMode }))
    },
    showSnackbar: (message, mode = undefined) => {
      dispatch(showSnackbar(message, mode))
    }
  }
}

class AmenityProWrapper extends React.Component<any, any> {
  componentDidMount = () => {
    const { ldInitRequest } = this.props
    ldInitRequest()
  }

  render() {
    const {
      props,
      props: {
        isFetching,
      },
    } = this

    if (isFetching) {
      return null
    }

    return <AmenityPro {...props}/>
  }
}

const AmenityWithFetchData = withFetchData(AmenityProWrapper)

const AmenityProContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AmenityWithFetchData)

export default AmenityProContainer
