// @flow
import React from 'react'
import BaseCard from '@Common/Cards/BaseCard'
import Flex from '@Common/Flex'
import Header from '@Common/Header'
import { InfoHead } from './styles'
import type { Node } from 'react'

type Props = {
  infoHead: string,
  title: ?Node,
  headerRight?: Node,
  content: () => Node,
  noTextTransform?: boolean,
  small?: boolean,
  rowSpan?: number,
  paddingTop?: string,
  paddingBottom?: string,
  style?: Object,
  styleInfoHead?: Object,
  styleTitle?: Object,
}

const InfoCard = ({
  title,
  headerRight,
  infoHead,
  content,
  noTextTransform,
  small,
  rowSpan,
  paddingTop,
  paddingBottom,
  style,
  styleInfoHead,
  styleTitle,
}: Props) => (
  <BaseCard
    paddingTop={paddingTop}
    paddingBottom={paddingBottom}
    style={style}
    title={null}
    columnStart={1}
    columnSpan={4}
    rowStart={1}
    rowSpan={rowSpan || 7}
    content={content}
    headerRight={() => headerRight}
    header={() => (
      <Flex direction="column" fullWidth>
        <InfoHead style={styleInfoHead}>
          <Flex fullWidth justifySpaceBetween>
            {infoHead}
            {headerRight}
          </Flex>
        </InfoHead>
        <Header
          blackText
          alternateFontSize
          noTextTransform={noTextTransform}
          small={small}>
          <span style={styleTitle}>{title}</span>
        </Header>
      </Flex>
    )}
  />
)

export default InfoCard
