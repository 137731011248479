import { GROUP_MESSAGES_SENT_TODAY } from './actions'
import withFetch from '../../helpers/reducers/withFetch'

const groupMessagesSentToday = (state = null, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withFetch(GROUP_MESSAGES_SENT_TODAY)(
  groupMessagesSentToday
)
