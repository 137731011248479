import styled from '@emotion/styled'

export const UnitContainer = styled('div')`
  height: 56px;
  display: flex;
  -webkit-display: flex;
  -webkit-align-items: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: ${({ theme, lastUnit, divider }) =>
    !divider &&
    (lastUnit ? `0px` : `1px solid ${theme.palette.neutrals.deepGray}`)};
  font-size: ${({ theme }) => theme.custom.font.size.sm};

  a {
    color: ${({ theme }) => theme.palette.secondary.light1};
  }

  & > div {
    width: 100%;
  }

  ${({ divider, theme }) =>
    divider &&
    `
    & > * {
      justify-content: flex-end;
      color: ${theme.palette.neutrals.warmGray};
      align-self: flex-end;
    }
  `};

  ${({ selected, theme }) =>
    selected &&
    `
    *, a {
    color: ${theme.palette.neutrals.deepGray}
  }
  `};
`

export const HistoryContentWrap = styled('div')`
  grid-column: 5 / span 6;
  grid-row: 2 / span 10;
  margin-top: -2em;
`

const Span = styled.span`
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
`

export const Date = styled(Span)`
  line-height: 2em;
`

export const Time = styled(Span)`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};

  text-transform: lowercase;
`

export const Message = styled('span')`
  text-transform: lowercase;
`
