// @flow
import React from 'react'
import type { Node } from 'react'
import Navigation from './NavigationMenu'
import AppBarContainer from './AppBar'
import Snackbar from '../Snackbar/SnackbarContainer'
import ChatNotification from '../Communication/ChatNotification'
import { Container, Root, Content } from './styles'

type Props = {
  children: Node,
  leftImageSrc?: string,
  rightImageSrc?: string,
  companyName: string,
  isSuperAdmin: boolean,
  isUnitOrProfileView: boolean,
  selectedCompany: { id: string },
  companies: [],
  isFetching: boolean,
  hasData: boolean,
  selectProperty: number => mixed,
  getPropertyInfo: (propertyId: number, url: string, history: Object) => {},
  history: string[],
  selectedCompanyProperties: Array<number>,
  properties: [],
  selectedProperty: { id: string },
  hasSendbirdToken: boolean,
  showAmenityReservations: boolean,
  fetchCompaniesPropertiesAndSelect: (
    companyId: number,
    history: string[]
  ) => {},
  match: { params: { propertyId: number }, url: string }
}

export default class AppContainer extends React.Component<Props, {}> {
  componentDidMount() {
    const {
      selectedProperty = {},
      match: {
        params: { propertyId }
      }
    } = this.props

    const { id: selectedPropertyID } = selectedProperty
    const propertyIdUrl = parseInt(propertyId, 10)

    if (propertyIdUrl && propertyIdUrl !== selectedPropertyID) {
      this.selectProperty(propertyIdUrl)
    }
  }

  selectProperty = (propertyId: number) => {
    const {
      history,
      selectedProperty,
      selectProperty,
      getPropertyInfo,
      selectedCompanyProperties,
      isSuperAdmin,
      match: { url }
    } = this.props

    if (isSuperAdmin) {
      if (selectedCompanyProperties.includes(propertyId)) {
        if (selectedProperty.id !== propertyId) {
          let newUrl = url.split('/')
          newUrl[2] = propertyId.toString()
          newUrl = newUrl.join().replace(/,/g, '/')

          history.push(newUrl)
          selectProperty(propertyId)
        }
      } else {
        getPropertyInfo(propertyId, url, history)
      }
    } else {
      if (selectedProperty.id !== propertyId) {
        history.push(`/properties/${propertyId}/summary`)
        selectProperty(propertyId)
      }
    }
  }

  selectCompany = (companyId: number) => {
    const {
      history,
      selectedCompany,
      fetchCompaniesPropertiesAndSelect
    } = this.props

    if (selectedCompany.id !== companyId) {
      fetchCompaniesPropertiesAndSelect(companyId, history)
    }
  }

  render() {
    const {
      children,
      rightImageSrc,
      leftImageSrc,
      companyName,
      companies,
      properties,
      selectedProperty,
      selectedCompany,
      isSuperAdmin,
      hasSendbirdToken,
      isUnitOrProfileView,
      showAmenityReservations
    } = this.props

    return (
      <Root
        rightImageSrc={rightImageSrc}
        leftImageSrc={leftImageSrc}
        isUnitOrProfileView={isUnitOrProfileView}>
        <AppBarContainer
          companyName={companyName}
          companies={companies}
          menuData={properties}
          selectCompany={this.selectCompany}
          handleChange={this.selectProperty}
          selectedProperty={selectedProperty}
          selectedCompany={selectedCompany}
          isSuperAdmin={isSuperAdmin}
          showAmenityReservations={showAmenityReservations}
        />
        {selectedProperty.id != null && <Navigation />}
        {hasSendbirdToken && <ChatNotification />}
        <Content id="content">
          <Container>{children}</Container>
        </Content>
        <Snackbar />
      </Root>
    )
  }
}
