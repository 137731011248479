import withFetch from '../../../helpers/reducers/withFetch'
import {
  UPDATE_PROPERTY_SETTING_OPTIMISTIC,
  PROPERTY_SETTINGS_FETCH_SECTION
} from '../actions'

const initialState = {}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROPERTY_SETTING_OPTIMISTIC:
      return { ...state, ...action.attributes }
    default:
      return state
  }
}

export default withFetch(PROPERTY_SETTINGS_FETCH_SECTION)(reducer)
