// @flow

import reject from 'lodash/reject'
import * as roleConstants from '../constants/roleConstants'

type getAssignableRolesType = (userRole: string, hasPms: boolean) => Object[]

export const getCurrentRole = () => null

const isRoleType = roleType => (role: ?string) =>
  role ? role === roleType : getCurrentRole() === roleType

export const isCompanyAdmin = isRoleType(roleConstants.COMPANY_ADMIN)

export const isPropertyAdmin = isRoleType(roleConstants.PROPERTY_ADMIN)

export const isPropertyStaff = isRoleType(roleConstants.PROPERTY_STAFF)

export const isSetup = isRoleType(roleConstants.SETUP)

export const isResident = isRoleType(roleConstants.RESIDENT)

export const isTemporary = isRoleType(roleConstants.TEMPORARY)

export const isUnitLevelRole = (role: ?string) =>
  isResident(role) || isTemporary(role)

export const isPropertyLevelRole = (role: ?string) =>
  isPropertyAdmin(role) || isPropertyStaff(role) || isSetup(role)

const roles = [
  {
    value: roleConstants.COMPANY_ADMIN,
    label: roleConstants.COMPANY_ADMIN_DISPLAY_NAME
  },
  {
    value: roleConstants.PROPERTY_ADMIN,
    label: roleConstants.PROPERTY_ADMIN_DISPLAY_NAME
  },
  {
    value: roleConstants.PROPERTY_STAFF,
    label: roleConstants.PROPERTY_STAFF_DISPLAY_NAME
  },
  {
    value: roleConstants.SETUP,
    label: roleConstants.SETUP_DISPLAY_NAME
  },
  {
    value: roleConstants.RESIDENT,
    label: roleConstants.RESIDENT_DISPLAY_NAME
  },
  {
    value: roleConstants.TEMPORARY,
    label: roleConstants.TEMPORARY_DISPLAY_NAME
  }
]

const restrictedRoles = {
  [roleConstants.COMPANY_ADMIN]: [roleConstants.SUPER_ADMIN],
  [roleConstants.PROPERTY_ADMIN]: [
    roleConstants.SUPER_ADMIN,
    roleConstants.COMPANY_ADMIN
  ]
}

export const getAssignableRoles: getAssignableRolesType = (
  userRole: string,
  hasPms: boolean
) => {
  let rolesToRemove = restrictedRoles[userRole] || []
  if (hasPms) {
    rolesToRemove = [
      roleConstants.RESIDENT,
      roleConstants.TEMPORARY,
      ...rolesToRemove
    ]
  }

  return reject(roles, ({ value }) => rolesToRemove.includes(value))
}
