// @flow

import get from 'lodash/get'

export const getOnlinePropertyHubs = (state: Object) => {
  return get(state, ['property', 'hubs', 'online'], [])
}

export const getOfflinePropertyHubs = (state: Object) => {
  return get(state, ['property', 'hubs', 'offline'], [])
}

export const getPropertyHubsCounts = (state: Object) => {
  const hubs = get(state, ['property', 'hubs'], { offline: [], online: [] })
  const totalArray = [...hubs.offline, ...hubs.online]

  return totalArray.length
}
