import {
  createFetchActions,
  createUpdateActions
} from '../../helpers/actions/creators'

export const PETS = 'PETS'
export const PET = 'PET'
export const UPDATE_PET = 'UPDATE_PET'
export const PETCOLORS = 'PETCOLORS'
export const PETSIZES = 'PETSIZES'
export const PETSTATUSES = 'PETSTATUSES'
export const PETTYPES = 'PET_TYPES'

export const {
  fetchAction: fetchPetsAction,
  fetchStartAction: fetchPetsStart,
  fetchSuccessAction: fetchPetsSuccess,
  fetchErrorAction: fetchPetsError
} = createFetchActions(PETS)

export const fetchPets = (propertyId, page, queryParams = {}) =>
  fetchPetsAction({ propertyId, page, queryParams })

export const searchPets = (propertyId, page, searchTerm, queryParams = {}) =>
  fetchPetsAction({
    propertyId,
    searchTerm,
    queryParams: { ...queryParams, page }
  })

export const {
  fetchAction: fetchPetAction,
  fetchStartAction: fetchPetStart,
  fetchSuccessAction: fetchPetSuccess,
  fetchErrorAction: fetchPetError
} = createFetchActions(PET)

export const fetchPet = petId => fetchPetAction({ petId })

export const updatePetActions = createUpdateActions(PET, true, true)

export const updatePet = (update, history, url, isNewPet) => ({
  type: UPDATE_PET,
  update,
  history,
  url,
  isNewPet
})

export const updatePetOptimistically = update =>
  updatePetActions.updateOptimistcallyAction({
    update
  })

export const updatePetSuccess = payload =>
  updatePetActions.updateSuccessAction(payload)

export const updatePetRevert = update =>
  updatePetActions.updateRevertAction({
    update
  })

export const {
  fetchAction: fetchPetsColorsAction,
  fetchStartAction: fetchPetsColorsStart,
  fetchSuccessAction: fetchPetsColorsSuccess,
  fetchErrorAction: fetchPetsColorsError
} = createFetchActions(PETCOLORS)

export const fetchPetsColors = propertyId =>
  fetchPetsColorsAction({ propertyId })

export const {
  fetchAction: fetchPetsSizesAction,
  fetchStartAction: fetchPetsSizesStart,
  fetchSuccessAction: fetchPetsSizesSuccess,
  fetchErrorAction: fetchPetsSizesError
} = createFetchActions(PETSIZES)

export const fetchPetsSizes = propertyId => fetchPetsSizesAction({ propertyId })

export const {
  fetchAction: fetchPetsStatusesAction,
  fetchStartAction: fetchPetsStatusesStart,
  fetchSuccessAction: fetchPetsStatusesSuccess,
  fetchErrorAction: fetchPetsStatusesError
} = createFetchActions(PETSTATUSES)

export const fetchPetsStatuses = propertyId =>
  fetchPetsStatusesAction({ propertyId })

export const {
  fetchAction: fetchPetsTypesAction,
  fetchStartAction: fetchPetsTypesStart,
  fetchSuccessAction: fetchPetsTypesSuccess,
  fetchErrorAction: fetchPetsTypesError
} = createFetchActions(PETTYPES)

export const fetchPetsTypes = propertyId => fetchPetsTypesAction({ propertyId })
