import { WORK_ORDER_DETAILS, UPDATE_WORK_ORDER_END } from '../actions'
import withFetch from '../../helpers/reducers/withFetch'

const workOrderDetails = (state = [], action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withFetch(WORK_ORDER_DETAILS)(workOrderDetails)
