// @flow

import get from 'lodash/get'
import { getData } from '../../helpers/selectors/data'

export const getSelectedDeviceProfileID = (state: Object) =>
  get(state, ['addDevice', 'deviceProfiles', 'selectedDeviceProfile'])

export const getDeviceProfileData = (state: Object) =>
  getData(state, ['addDevice', 'deviceProfiles']).map(
    ({ id, friendly_name, product }) => ({
      key: id,
      name: friendly_name || product
    })
  )

export const getDeviceProfileType = (state: Object, id: string) =>
  getDeviceProfileProperty(state, id, 'product_subtype') ||
  getDeviceProfileProperty(state, id, 'product_type')

export const getDeviceSetupInstructions = (state: Object, id: string) =>
  getDeviceProfileProperty(state, id, 'setup_instructions', [])

export const getDeviceTestInstructionsBefore = (state: Object, id: string) =>
  getDeviceProfileProperty(state, id, ['test_instructions', 'before'], [])

export const getDeviceTestInstructionsAfter = (state: Object, id: string) =>
  getDeviceProfileProperty(
    state,
    id,
    ['test_instructions', 'after', '0'],
    ''
  ).toUpperCase()

export const hasConnectedDevice = (state: Object) =>
  get(state, ['addDevice', 'connected', 'id'], null) !== null

export const getConnectedDevice = (state: Object) =>
  get(state, ['addDevice', 'connected'])

export const getConnectedDeviceTestPayload = (state: Object, id: string) =>
  getDeviceProfileProperty(state, id, 'test_payload')

export const getConnectedDeviceTestWaitTimeout = (state: Object, id: string) =>
  getDeviceProfileProperty(state, id, 'test_wait_timeout') || 30000

const getDeviceProfileProperty = (state, id, path, defaultValue) =>
  get(
    getData(state, ['addDevice', 'deviceProfiles']).find(
      deviceProfile =>
        deviceProfile.id === (id || getSelectedDeviceProfileID(state))
    ),
    path,
    defaultValue
  )
