import { connect } from 'react-redux'
import Residents from './Residents'
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {
  fetchResidentUsersAction,
  fetchNextResidents
} from 'zego-shared/store/users/property/actions'
import { sendLockCode } from 'zego-shared/store/lockCodes/actions'
import {
  getResidentUsers,
  getResidentUsersCount,
  getCurrentPage,
  getSearchTerm
} from 'zego-shared/store/lockCodes/selectors'

const ResidentsWithFetchData = withFetchData(Residents)
const fetchingSelector = createIsFetchingSelector(['resident'])

const mapStateToProps = (
  state,
  {
    match: {
      params: { propertyId }
    }
  }
) => {
  return {
    propertyId,
    count: getResidentUsersCount(state),
    currentPage: getCurrentPage(state),
    residents: getResidentUsers(state),
    isFetching: fetchingSelector(state),
    searchTerm: getSearchTerm(state)
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { propertyId }
    }
  }
) => ({
  fetchData: () => {
    dispatch(fetchResidentUsersAction({ propertyId }))
  },
  sendLockCode: userId => {
    dispatch(sendLockCode(userId))
  },
  fetchNextUsers: (page, name) => {
    dispatch(fetchNextResidents(propertyId, page, name))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResidentsWithFetchData)
