import styled from '@emotion/styled'
import MaterialTextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { convertHexToRGB } from '../../utils/commonUtils'

export const Content = styled('p')`
  color: ${({ theme }) => `${theme.palette.common.white}`};
  line-height: 1.5em;
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  line-height: 26px;
  margin-top: 80px;
`

export const TableContainer = styled('div')`
  height: 84.5%;
  width: 80.5%;
  position: absolute;
  padding: 3em;
  background: ${({ theme }) => `${theme.palette.common.white}`};
  border-top-left-radius: 5px;
  right: -2em;
  bottom: -1em;
  box-shadow: ${({ theme }) =>
    `0 1px 10px 0 ${convertHexToRGB(theme.palette.neutrals.warmGray, 0.5)}`};

  th,
  td {
    padding-right: 0.5em !important;
  }

  tr {
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.neutrals.deepGray}`};
  }
`

export const Layout = styled('div')`
  width: 100%;
  &:after {
    content: ' ';
    background: ${({ theme }) => `${theme.palette.neutrals.lightGray}`};
    border-top-right-radius: 5px;
    width: 70%;
    height: calc(100% - 1em);
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: -15;
    top: 2em;
  }
`

export const Container = styled('div')`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`

export const TextField = styled(MaterialTextField)`
  width: 100%;
  height: 2em;
  margin-right: 1em;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.palette.neutrals.warmGray};
  padding: 0 15px;
  & input {
    color: ${({ theme }) => theme.palette.text.lightGrey};
    padding-top: 8px;
  }
`

export const StyledColumn = styled('div')`
  height: 4em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  outline: none;
  ${({ index, disabled }) => (index > 4 || disabled) && `pointer-events: none`};
  ${({ index }) => index === 1 && `justify-content: flex-start`};
  ${({ index }) => index === 6 && `justify-content: flex-end`};
  ${({ index }) => index < 5 && `cursor: pointer`};
  ${({ highlight, theme }) =>
    highlight &&
    `background-color: rgb(89,192,155,.15);
  font-weight: ${theme.custom.font.weight.normal};
  color: ${theme.palette.text.black};
  `};
  & .column-icon {
    opacity: ${({ disabled }) => (!disabled ? `1` : `0`)};
    transition: 0.2s opacity ease-in;
  }
`

export const StyledRow = styled('div')`
  height: ${({ firstRow }) => (firstRow ? `7em` : `4em`)};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: ${({ firstRow }) => (firstRow ? `flex-end` : `center`)};
  white-space: nowrap;
  position: relative;
  justify-content: center;
  ${({ highlight, theme }) =>
    highlight &&
    `background-color: rgb(89,192,155,.15);
  font-weight: ${theme.custom.font.weight.normal};
  color: ${theme.palette.text.black};
  `};
  ${({ index }) => index === 1 && `justify-content: flex-start`};
  ${({ index }) => index === 6 && `justify-content: flex-end`};
  ${({ firstRow }) => firstRow && `padding-bottom: 1.5em;`};
  ${({ index, theme }) =>
    (index === 1 || index === 6) &&
    `color: ${theme.palette.common.black};
    * :not(svg):not(path) {
      color: ${theme.palette.common.black};
    }`};
`

export const CustomTypography = styled(Typography, {
  shouldForwardProp: prop => !['gutter', 'small'].includes(prop)
})`
  font-size: ${({ theme, small }) =>
    small ? theme.custom.font.size.sm : theme.custom.font.size.mdlg};
  color: ${({ theme, small }) =>
    small ? theme.palette.grey.main : theme.palette.common.black};
  ${({ gutter }) =>
    gutter &&
    `
    margin-bottom: 1.5em`};
`
