// @flow
import { connect } from 'react-redux'
import UnitSummary from './UnitSummary'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { fetchUnit } from 'zego-shared/store/select/units/actions'
import { fetchUsers } from 'zego-shared/store/users/unit/actions'
import { fetchUnitResidentChatChannels } from 'zego-shared/store/chat/channels/actions'
import { fetchResidentActive } from 'zego-shared/store/analytics/residentActive/actions'
import { fetchUnitResidentEngagement } from 'zego-shared/store/analytics/residentEngagement/actions'
import { getUnitUsersCardData } from 'zego-shared/store/users/selectors'
import {
  getUnitSmartCardData,
  hasUnitSummaryData,
  getUnitSummaryCardData,
  getUnitChatUnreadData,
  getUnitChannelCounts
} from 'zego-shared/store/summary/selectors'
import { getChatEnabled } from 'zego-shared/store/settings/propertySettings/selectors'
import { getResidentActiveData } from 'zego-shared/store/analytics/residentActive/selectors'
import { getResidentEngagementData } from 'zego-shared/store/analytics/residentEngagement/selectors'
import { isSmartEnabled } from 'zego-shared/store/settings/propertySettings/selectors'

const UnitSummaryWithFetchData = withFetchData(UnitSummary)
const fetchingSelector = state =>
  getChatEnabled(state)
    ? createIsFetchingSelector([
        'residentchatchannels',
        'unit',
        'unitusers',
        'residentactive',
        'residentengagement'
      ])(state)
    : createIsFetchingSelector([
        'unit',
        'unitusers',
        'residentactive',
        'residentengagement'
      ])(state)

const mapStateToProps = (state, { match: { url } }) => ({
  smartCardData: getUnitSmartCardData(state, url),
  isFetching: fetchingSelector(state),
  usersCardData: getUnitUsersCardData(state),
  hasData: hasUnitSummaryData(state),
  summaryDetails: getUnitSummaryCardData(state),
  chatUnreadData: getUnitChatUnreadData(state),
  userActiveData: getResidentActiveData(state),
  engagementData: getResidentEngagementData(state),
  isChatEnabled: getChatEnabled(state),
  chatChannelCounts: getUnitChannelCounts(state),
  isSmartEnabled: isSmartEnabled(state),
  url
})

const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  fetchData: props => {
    dispatch(fetchUsers(params.unitId))
    dispatch(fetchUnit(params.unitId))
    dispatch(fetchResidentActive(params.unitId))
    dispatch(fetchUnitResidentEngagement(params.unitId))
    if (props.isChatEnabled) {
      dispatch(fetchUnitResidentChatChannels(params.unitId))
    }
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitSummaryWithFetchData)
