import { call, select, takeLatest, put } from 'redux-saga/effects'
import { updatePropertySetting } from '../../settings/propertySettings/actions'
import { getToken } from 'zego-shared/store/authorization/selectors'
import { getPropertyId } from 'zego-shared/store/select/selectors'
import { showSnackbar } from '../../snackbar/actions'
import api from '../../../utils/api'
import {
  fetchPackagesAction,
  fetchPackagesSuccess,
  fetchPackagesError,
  fetchPackagesStart,
  searchPackagesStart,
  searchPackagesAction,
  searchPackagesSuccess,
  searchPackagesError,
  updatePackageStatus as updatePackageStatusAction,
  UPDATE_PACKAGE_STATUS_SUCCESS,
  UPDATE_PACKAGE_STATUS_ERROR,
  updatePackageStatusOptimistically,
  updatePackageReminder as updatePackageReminderAction
} from 'zego-shared/store/packages/actions'
import {
  failedToUpdatePackage,
  updatePackageReminderSuccessful,
  failedToUpdatePackageReminder
} from '../../../utils/messages'

export function getQueryParams(status, sortBy, page) {
  const params = { sort_by: sortBy, page_size: 8, page }
  if (status && status !== 'all') {
    return {
      ...params,
      status
    }
  }
  return params
}

export function* fetchPackages({ id, filter, sortBy, page }) {
  try {
    yield put(fetchPackagesStart())
    const authToken = yield select(getToken)
    const propertyId = id ? id : yield select(getPropertyId)
    const response = yield call(
      api.getPackages,
      authToken,
      'properties',
      propertyId,
      getQueryParams(filter, sortBy, page)
    )

    yield put(fetchPackagesSuccess(response))
  } catch (error) {
    yield put(fetchPackagesError())
  }
}

export function* searchPackages({ id, term, filter, sortBy }) {
  try {
    yield put(searchPackagesStart())
    const authToken = yield select(getToken)
    const propertyId = id ? id : yield select(getPropertyId)
    const response = yield call(
      api.searchPackages,
      authToken,
      propertyId,
      term,
      getQueryParams(filter, sortBy)
    )

    yield put(searchPackagesSuccess(response))
  } catch (error) {
    yield put(searchPackagesError(error))
  }
}

export function* updatePackageStatus({ packageId, status }) {
  try {
    yield put(updatePackageStatusOptimistically(packageId, status))
    const authToken = yield select(getToken)
    yield call(api.updatePackage, authToken, packageId, {
      status
    })
    yield put({ type: UPDATE_PACKAGE_STATUS_SUCCESS, payload: { data: { packageId, status } } })
  } catch (error) {
    yield put(showSnackbar(failedToUpdatePackage, 'error'))
    yield put({ type: UPDATE_PACKAGE_STATUS_ERROR })
  }
}

export function* updatePackageReminder({ propertyId, attributes }) {
  try {
    yield put(updatePropertySetting(propertyId, attributes))

    yield put(showSnackbar(updatePackageReminderSuccessful, 'success'))
  } catch (error) {
    yield put(showSnackbar(failedToUpdatePackageReminder, 'error'))
  }
}

function* watchFetchPackages() {
  yield takeLatest(fetchPackagesAction().type, fetchPackages)
}

function* watchSearchPackages() {
  yield takeLatest(searchPackagesAction().type, searchPackages)
}

function* watchUpdatePackage() {
  yield takeLatest(updatePackageStatusAction().type, updatePackageStatus)
}

function* watchUpdatePackageReminder() {
  yield takeLatest(updatePackageReminderAction().type, updatePackageReminder)
}

export default [
  watchFetchPackages(),
  watchSearchPackages(),
  watchUpdatePackage(),
  watchUpdatePackageReminder()
]
