import api from 'zego-shared/utils/api'
import moment from 'moment-timezone'

export const isTemplate = (templates, selectedTemplateId, message) => {
  const foundTemplate = templates.find(
    template => template.id === selectedTemplateId
  )
  if (
    foundTemplate.title === message.title &&
    foundTemplate.message === message.message
  ) {
    return true
  } else {
    return false
  }
}

export const isEvent = type => type === 'event'

export const isDateTime = type => type === 'schedule'

export const isSendNow = type => type === ''

export const createCron = (
  type,
  timezone,
  momentDate,
  recurringOptionName = 'once a year'
) => {
  // change to 9am for event
  if (isEvent(type)) {
    const nineAm = moment.tz({ hour: 9, minutes: 0, seconds: 0, milliseconds: 0 }, timezone)
    .utc()
    return `${nineAm.minute()} ${nineAm.hour()} * * * *`
  }
  if (isDateTime(type)) {
    const momentUtc = momentDate.utc()
    // yearly
    if (recurringOptionName === 'once a year') {
      return `${momentUtc.minute()} ${momentUtc.hour()} ${momentUtc.date()} ${momentUtc.month() +
        1} * *`
    }
    // monthly

    if (recurringOptionName === 'once a month') {
      return `${momentUtc.minute()} ${momentUtc.hour()} ${momentUtc.date()} * * *`
    }

    // non-recurring
    return `${momentUtc.minute()} ${momentUtc.hour()} ${momentUtc.date()} ${momentUtc.month() +
      1} ${momentUtc.day()} ${momentUtc.year()}`
  }
}

export const getSelectedGroupsIds = selectedGroups =>
  selectedGroups.map(group => group.value)

export const getEventType = (events, selectedEventId) => events[selectedEventId]

export const getOffset = (offsetBeforeAfter, offsetDays) => {
  return offsetBeforeAfter === 'before' ? offsetDays : -Math.abs(offsetDays)
}

const groupsChanged = selectedGroups => selectedGroups.length > 0

export const updateGroupMessage = async (
  authToken,
  automationId,
  selectedDeliveryMethods,
  selectedDateTime,
  template,
  type,
  selectedGroups,
  event,
  timezone
) => {
  const cron = selectedDateTime
    ? createCron(
        type,
        timezone,
        selectedDateTime.moment,
        selectedDateTime.recurringOptionName
      )
    : isEvent(type)
      ? createCron(type, timezone)
      : null

  if (groupsChanged(selectedGroups)) {
    const selectedGroupIds = selectedGroups.map(group => group.value)
    return await api.updateGroupMessagEditTemplateGroup(
      authToken,
      automationId,
      selectedDeliveryMethods,
      cron,
      template,
      selectedGroupIds
    )
  } else {
    return await api.updateGroupMessagEditTemplate(
      authToken,
      automationId,
      selectedDeliveryMethods,
      cron,
      template,
      event
    )
  }
}
