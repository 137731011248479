import styled from '@emotion/styled'
import DesktopActivationBackground from '../../images/desktop_activation.jpg'
import MaterialCard from '@material-ui/core/Card'
import MaterialDivider from '@material-ui/core/Divider'
import Flex from '@Common/Flex'
import LoginBG from '../../images/login_bg.png'
import LogoLogin from '../../images/logo_login.png'
import { FlexItem } from '@Common/Flex'

export const Card = styled(MaterialCard)`
  height: 650px;
  min-height: 650px;
  width: 420px;
  min-width: 420px;
  padding: 50px;
  box-shadow: none;
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const Divider = styled(MaterialDivider)`
  margin: 10px;
`
export const Title = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.xl};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: 10px;
  text-align: center;
`

export const BtnText = styled('span')`
  padding-left: 1em;
`

export const Container = styled('div')`
  background: url("${DesktopActivationBackground}") no-repeat bottom right;
  background-size: contain;
  height: 100vh;
  width: 100vw;
  padding: 2em;
  min-height: 768px;
`

export const AppName = styled('span')`
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  font-size: ${({ theme }) => theme.custom.font.size.xxl};
  color: ${({ theme }) => theme.palette.common.black};
`

export const NotificationMessage = styled('div')`
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  font-size: ${({ theme }) => theme.custom.font.size.lg};
  color: ${({ theme }) => theme.palette.text.secondary};
  padding-top: 2em;
  text-align: center;
  margin-bottom: 3em;
`

export const MainFlex = styled(Flex)`
  background: url(${LoginBG}) left top no-repeat;
`

export const ForgotPassword = styled('div')`
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
  margin-bottom: 16px;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`

export const TitleDesc = styled(FlexItem)`
  text-align: center;
`

export const Logo = styled('div')`
  width: 266px;
  height: 144px;
  background: url(${LogoLogin});
  background-size: 266px 144px;
  margin-bottom: 70px;
  margin-top: 20px;
`

export const OktaStyle = styled('div')`
  div.okta-widget{
    display: none;
  }
`
