// @flow
import { connect } from 'react-redux'
import queryString from 'query-string'
import withFetchData from 'zego-shared/views/withFetchData'
import { fetchDeviceHistory } from 'zego-shared/store/deviceHistory/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { getPaginationData } from 'zego-shared/store/helpers/selectors/data'

import History from './History'
import { getDeviceHistory } from '../../../../store/Unit/deviceHistory/deviceHistorySelectors'

const fetchingSelector = createIsFetchingSelector(['devicehistory'])

const mapStateToProps = state => ({
  isFetching: fetchingSelector(state),
  data: getDeviceHistory(state),
  ...getPaginationData(state, 'unit.deviceHistory')
})

const mapDispatchToProps = (dispatch, props) => {
  const { deviceTypes } = queryString.parse(props.location.search)

  return {
    componentDidUpdate: previousProps => {
      if (props.location !== previousProps.location) {
        const { deviceTypes } = queryString.parse(props.location.search)
        dispatch(fetchDeviceHistory(props.match.params.unitId, deviceTypes))
      }
    },
    fetchDeviceHistory: (page: number) =>
      dispatch(fetchDeviceHistory(props.match.params.unitId, deviceTypes, page))
  }
}

const HistoryWithFetchData = withFetchData(History)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryWithFetchData)
