//@flow
import get from 'lodash/get'

export const getSurveyStats = (state: Object) => {
  return get(state, ['surveys', 'stats'])
}

export const getResidentSurveys = (state: Object) => {
  return get(state, ['surveys', 'residentSurveys'])
}
