// @flow

import React from 'react'
import ZegoInput from '../Common/Input'
import MaskPhoneNumber from '../Common/MaskPhoneNumber'
import { type SignupStepProps } from './types'

export default class InputStep extends React.Component<
  SignupStepProps,
  { value: string }
> {
  state = {
    value: this.props.value || ''
  }

  handleKeyPress = ({ key }: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      const { handleDataChange } = this.props
      handleDataChange()
    }
  }

  render() {
    const { id, value, placeholder, type, error, onChange, readOnly } = this.props
    return (
      <ZegoInput
        fullWidth
        error={error}
        placeholder={placeholder}
        dark
        value={value}
        onChange={onChange}
        onKeyPress={this.handleKeyPress}
        id={id}
        type={type}
        readOnly={readOnly}
        inputComponent={ id === 'phoneNumber' ? MaskPhoneNumber : 'input'}
      />
    )
  }
}
