// @flow
import React, { PureComponent } from 'react'
import { CommonIcon } from '@icons'
import { StyledColumn } from './styles'

import type { Order } from './types'

type Props = {
  column: string,
  isCurrent: boolean,
  index: number,
  order: Order,
  onClick: (order: Order) => mixed,
  disabled: boolean
}

type State = {
  order: Order
}

class Column extends PureComponent<Props, State> {
  state = {
    order: 'asc'
  }

  componentDidUpdate() {
    const { isCurrent, order } = this.props
    if (isCurrent) {
      this.setState({ order })
    }
  }

  handleClick = () => {
    const { order } = this.state
    const newOrder = order === 'asc' ? 'desc' : 'asc'
    this.props.onClick(newOrder || order || 'asc')
  }

  render() {
    const { column, isCurrent, index, disabled } = this.props
    const { order } = this.state
    return (
      <StyledColumn
        index={index + 1}
        onClick={this.handleClick}
        highlight={index === 4}
        tabIndex="0"
        role="presentation"
        isCurrent={isCurrent}
        disabled={disabled}>
        {column}
        {index < 4 && (
          <CommonIcon
            name={order === 'asc' ? 'arrow_up' : 'arrow_down'}
            width="0.6em"
            height="0.6em"
            style={{ marginLeft: '0.5em' }}
            className="column-icon"
          />
        )}
      </StyledColumn>
    )
  }
}

export default Column
