// @flow

import React from 'react'
import Modal from '@material-ui/core/Modal'
import { Screen, Content } from './styles'

type Props = {
  open: boolean,
  autoHideDuration?: number,
  children: any,
  type?: string,
  goTo?: Function
}

class StatusScreen extends React.Component<Props, { open: boolean }> {
  timeout: TimeoutID

  state = {
    open: this.props.open || false
  }

  hide = () => {
    this.state.open && this.setState({ open: false })
  }

  componentWillMount() {
    const { autoHideDuration, goTo, open } = this.props
    if (open && autoHideDuration) {
      this.timeout = setTimeout(() => {
        this.hide()
        goTo && goTo()
      }, autoHideDuration)
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { open, autoHideDuration, goTo } = this.props
    if (prevProps.open !== open) {
      this.setState({ open })
      if (open && autoHideDuration) {
        this.timeout = setTimeout(() => {
          this.hide()
          goTo && goTo()
        }, autoHideDuration)
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    const { children, type = 'success' } = this.props
    const { open } = this.state
    return (
      open && (
        <Modal open={true}>
          <Screen type={type}>{children}</Screen>
        </Modal>
      )
    )
  }
}

export const StatusScreenContent = ({
  type = 'success',
  heading,
  subheading
}: {
  heading: string,
  subheading: string,
  type: string
}) => (
  <Content type={type}>
    <div id="statusHeading">{heading}</div>
    <p id="statusSubheading">{subheading}</p>
  </Content>
)

export default StatusScreen
