// @flow

import React from 'react'
import CarouselList from '@Common/CarouselList'
import withScrollBehaviour from '@Common/CarouselList/withScrollBehaviour'
import FlexDirection from '@Common/Flex/FlexDirection'

const UserList = ({
  items,
  numberOfItemsPerSlide,
  currentSlideNumber,
  }) => (
  <FlexDirection direction="column">
    <CarouselList
      numberOfItemsPerSlide={numberOfItemsPerSlide}
      itemHeight={56}
      currentSlide={currentSlideNumber}
      lastSlide={Math.ceil(items.length / numberOfItemsPerSlide)}
      items={items}
    />
  </FlexDirection>
)

export default withScrollBehaviour(UserList)
