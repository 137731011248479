import { createFetchActions } from '../../helpers/actions/creators'

export const WORKORDERSUMMARYLIST = 'WORKORDERSUMMARYLIST'
export const WORK_ORDER_OPTIONS = 'WORKORDEROPTIONS'
export const SUBMIT_WORK_ORDER = 'SUBMIT_WORK_ORDER'
export const SUBMIT_WORK_ORDER_START = 'SUBMIT_WORK_ORDER_START'
export const SUBMIT_WORK_ORDER_END = 'SUBMIT_WORK_ORDER_END'
export const UPDATE_WORK_ORDER = 'UPDATE_WORK_ORDER'
export const UPDATE_WORK_ORDER_START = 'UPDATE_WORK_ORDER_START'
export const UPDATE_WORK_ORDER_END = 'UPDATE_WORK_ORDER_END'
export const WORK_ORDER_DETAILS = 'WORKORDERDETAILS'

export const {
  fetchAction: fetchWorkOrdersList,
  fetchStartAction: fetchWorkOrdersListStart,
  fetchSuccessAction: fetchWorkOrdersListSuccess,
  fetchErrorAction: fetchWorkOrdersListError
} = createFetchActions(WORKORDERSUMMARYLIST)

export const {
  fetchAction: fetchWorkOrderOptions,
  fetchStartAction: fetchWorkOrderOptionsStart,
  fetchSuccessAction: fetchWorkOrderOptionsSuccess,
  fetchErrorAction: fetchWorkOrderOptionsError
} = createFetchActions(WORK_ORDER_OPTIONS)

export const {
  fetchAction: fetchWorkOrderDetails,
  fetchStartAction: fetchWorkOrderDetailsStart,
  fetchSuccessAction: fetchWorkOrderDetailsSuccess,
  fetchErrorAction: fetchWorkOrderDetailsError
} = createFetchActions(WORK_ORDER_DETAILS)

export const fetchWorkOrderDetail = workOrderId => fetchWorkOrderDetails({ workOrderId })

export const getWorkOrdersList = (propertyId) => ({
  type: WORKORDERSUMMARYLIST,
  propertyId,
})

export const submitWorkOrder = (workOrder, history, url) => ({
  type: SUBMIT_WORK_ORDER,
  workOrder,
  history,
  url
})

export const submitWorkOrderStart = {
  type: SUBMIT_WORK_ORDER_START
}

export const submitWorkOrderEnd = {
  type: SUBMIT_WORK_ORDER_END
}

export const updateWorkOrder = (workOrder) => ({
  type: UPDATE_WORK_ORDER,
  workOrder
})

export const updateWorkOrderStart = {
  type: UPDATE_WORK_ORDER_START
}

export const updateWorkOrderEnd = (result) => ({
  type: UPDATE_WORK_ORDER_END,
  result
})

export const getWorkOrderDetails = (workOrderId) => ({
  type: WORK_ORDER_DETAILS,
  workOrderId: workOrderId,
})
