import withFetch from '../../../helpers/reducers/withFetch'
import { UPDATE_USER_OPTIMISTIC } from '../../../authorization/actions'

import {
  USERPROFILESECTION,
  GENERATE_LOCK_CODE_SUCCESS,
  DELETE_LOCK_CODE_SUCCESS,
  GET_USER_BALANCE_SUCCESS,
  UPLOAD_PROFILE_IMAGE_SUCCESS,
  GET_USER_LEASE_INTEREST_STATUS_SUCCESS,
  UPDATE_USER_LEASE_INTEREST_STATUS_SUCCESS,
  fetchUserVisitorsSuccess,
  fetchUserPetsSuccess,
  fetchUserVehiclesSuccess
} from '../actions'
const profileInitialState = {}

const userProfile = (state = profileInitialState, action) => {
  switch (action.type) {
    case GENERATE_LOCK_CODE_SUCCESS:
      return {
        ...state,
        lock_code: action.response.lock_code
      }
    case DELETE_LOCK_CODE_SUCCESS:
      return {
        ...state,
        lock_code: null
      }
    case GET_USER_BALANCE_SUCCESS:
      return {
        ...state,
        balance: action.response
      }
    case GET_USER_LEASE_INTEREST_STATUS_SUCCESS:
      return {
        ...state,
        lease_interest_status: action.response.lease_interest_status
      }
    case UPDATE_USER_LEASE_INTEREST_STATUS_SUCCESS:
      return {
        ...state,
        lease_interest_status: action.response.lease_interest_status
      }
    case UPLOAD_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        picture: action.response.picture
      }
    case UPDATE_USER_OPTIMISTIC:
      return {
        ...state,
        ...action.attributes
      }
    case fetchUserVisitorsSuccess().type:
      return {
        ...state,
        visitors: action.payload
      }
    case fetchUserPetsSuccess().type:
      return {
        ...state,
        pets: action.payload
      }
    case fetchUserVehiclesSuccess().type:
      return {
        ...state,
        vehicles: action.payload
      }
    default:
      return state
  }
}

export default withFetch(USERPROFILESECTION)(userProfile)
