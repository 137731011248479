import { createFetchActions } from '../../helpers/actions/creators'

export const FUTURE = 'FUTUREGROUPMESSAGES'
export const SEARCH_FUTURE = 'SEARCHFUTUREGROUPMESSAGES'

export const {
  fetchAction: fetchFutureAction,
  fetchStartAction: fetchFutureStart,
  fetchSuccessAction: fetchFutureSuccess,
  fetchErrorAction: fetchFutureError
} = createFetchActions(FUTURE)

export const fetchFuture = (propertyId, page) =>
  fetchFutureAction({ propertyId, page })

export const searchFuture = (
  propertyId,
  page,
  searchTerm,
  queryParams = {}
) => ({
  type: SEARCH_FUTURE,
  propertyId,
  searchTerm,
  queryParams: { ...queryParams, page }
})
