// @flow

import { connect } from 'react-redux'
import {
  updateScheduleItem,
  updateScheduleEnabled,
  updateSchedule
} from 'zego-shared/store/schedule/actions'
import {
  getSelectedUnitScheduleID,
  getSelectedUnitScheduleState,
  getScheduleInfo,
  hasSchedule
} from 'zego-shared/store/schedule/selectors'
import { getSelectedUnitType } from 'zego-shared/store/select/selectors'
import Schedule from './Schedule'

const mapStateToProps = (state, { match: { params } }) => ({
  unitId: params.unitId,
  unitType: getSelectedUnitType(state),
  scheduleId: getSelectedUnitScheduleID(state),
  scheduleInfo: getScheduleInfo(state),
  scheduleState: getSelectedUnitScheduleState(state),
  hasSchedule: hasSchedule(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateMode: (newMode, scheduleState) => {
    const update = { [`${scheduleState}_thermostat_mode`]: newMode }
    dispatch(updateScheduleItem(update))
  },
  updateSetpoints: ({ heatSetpoint, coolSetpoint }, editingScheduleState) => {
    const update = {
      [`${editingScheduleState}_cool_setpoint`]: coolSetpoint,
      [`${editingScheduleState}_heat_setpoint`]: heatSetpoint
    }
    dispatch(updateScheduleItem(update))
  },
  updateScheduleEnabled: enabled => {
    dispatch(updateScheduleEnabled(enabled))
  },
  createNewSchedule: (template, unitId) => {
    const update = {
      template,
      unit_id: unitId
    }
    dispatch(updateSchedule(update))
  },
  changeScheduleTemplate: (template, unitId, scheduleId) => {
    const update = {
      template,
      unit_id: unitId,
      id: scheduleId
    }
    dispatch(updateSchedule(update))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Schedule)
