// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import { withRouter, Link } from 'react-router-dom'
import { GridItem } from '../../Common/Grid'
import PaginatedTable from '../../Common/PaginatedTable'
import Loader from '../../Common/Loader'
import { TableContainer, StyledColumn, StyledRow } from '../styles'

type Props = {
  filter: any,
  theme: any,
  data: Object[],
  vendors: Object[],
  isFetching: boolean,
  count: number,
  propertyId: number,
  searchTerm: string,
  fetchNextVendors: (page: number, searchTerm: string) => {}
}

type State = {
  data: Object[],
  currentPage: number
}

const rowsPerPage = 8

class Vendors extends React.Component<Props, State> {
  state = {
    data: [],
    currentPage: 0
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vendors !== this.props.vendors) {
      const { vendors } = this.props
      this.setState({
        data: vendors
      })
    }

    if (
      prevProps.searchTerm !== this.props.searchTerm &&
      prevProps.vendors !== this.props.vendors
    ) {
      this.setState({
        currentPage: 0
      })
    }
  }

  createRows = () => {
    const { data } = this.state
    const { propertyId } = this.props
    let rows = []

    if (data) {
      rows = data.map((rowData, rowIndex) =>
        Object.entries(rowData).map(([key, value], index) => {
          return {
            value: (
              <StyledRow
                firstRow={rowIndex === 0}
                index={index + 1}
                lockCodesMain={index === 2}>
                {index === 0 ? (
                  <Link
                    to={`/properties/${propertyId}/lock-codes/vendors/${
                      rowData['id']
                    }/codes`}>
                    {rowData['name']}
                  </Link>
                ) : (
                  index > 0 && index < 3 && value
                )}
              </StyledRow>
            )
          }
        })
      )
    }
    return rows
  }

  changePage = page => {
    const { searchTerm } = this.props
    this.setState({ currentPage: page - 1 })
    this.props.fetchNextVendors(page, searchTerm)
  }

  render() {
    const { isFetching, count } = this.props
    const { currentPage } = this.state

    return (
      <React.Fragment>
        <GridItem columnStart={3} columnSpan={10} rowStart={3} rowSpan={10}>
          <TableContainer>
            {isFetching ? (
              <Loader />
            ) : (
              <PaginatedTable
                total={count}
                flex={[2, 1, 1]}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                columns={[
                  {
                    value: <StyledColumn index={1}>VENDOR</StyledColumn>
                  },
                  {
                    value: <StyledColumn index={2}>ACCESS TYPE</StyledColumn>
                  },
                  {
                    value: <StyledColumn index={3}>CODE</StyledColumn>
                  }
                ]}
                rows={this.createRows()}
                changePage={this.changePage}
              />
            )}
          </TableContainer>
        </GridItem>
      </React.Fragment>
    )
  }
}

export default withRouter(withTheme(Vendors))
