export const dataUrlToFile = (dataUrl, fileName, fileType) => {
  const byteString = atob(dataUrl.split(',')[1])
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: fileType})
  return new File([blob], fileName)
}
