// @flow

import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import { isValidCode } from 'zego-shared/utils/lock'
import InputAdornment from '@material-ui/core/InputAdornment'
import { formatExpiresAfter } from '../../../utils'
import Button from '@Common/Cards/CardButton'
import Flex, { FlexItem } from '@Common/Flex/FlexDirection'
import NumberInput from './NumberInput'
import {
  Detail,
  Name,
  DetailWrapper,
  TextField,
  ActionLink,
  Error,
  Caret
} from './styles'
import { CommonIcon } from '@icons'
import { EditButton, SaveButton } from './components'
import {
  TemporaryCodePanelProps as Props,
  TemporaryCodePanelState as State
} from './types'
import AlertModal from '@Common/AlertModal/AlertModal'

class PanelDetails extends React.Component<Props, State> {
  state = {
    showEditMode: false,
    valid: true,
    validTempCode: false,
    code: null,
    tempCode: '',
    showCreateMode: false,
    expiryEditingCodeID: null,
    showModal: false
  }

  openModifyExpiryMode = codeID => {
    this.setState({ expiryEditingCodeID: codeID })
  }

  closeModifyExpiryMode = () => {
    this.setState({ expiryEditingCodeID: null })
  }

  saveExpiryMode = (codeID, expiresAfterDays) => {
    const expiresAfter = expiresAfterDays * 24 * 60 * 60
    const expiration = expiresAfter + Math.floor(new Date() / 1000)
    this.props.updateExpiryMode(codeID, expiration)
  }

  openEditMode = codeID => {
    this.setState({ showEditMode: codeID })
  }

  addTempCode = () => {
    const { addLockCode, identifier, codeType, showSnackbar } = this.props
    const { tempCode, validTempCode } = this.state
    if (validTempCode) {
      addLockCode(identifier, tempCode, codeType)
      this.setState({ tempCode: '', showCreateMode: false })
    } else {
      showSnackbar('Code should be 4 to 6 characters long')
    }
  }

  closeEditMode = () => {
    this.setState({ showEditMode: false })
  }

  handleEditLockCodeChange = ({ target: { value } }) => {
    const code = parseInt(value, 10)
    if (code) {
      this.setState({ code, valid: isValidCode(code) })
    }
  }

  handleCreateTemporaryCodeChange = ({ target: { value } }) => {
    if (value && !isNaN(value)) {
      this.setState({
        tempCode: `${value}`,
        validTempCode: isValidCode(value)
      })
    }
  }

  showCreateMode = () => {
    this.setState({ showCreateMode: true })
  }

  closeCreateMode = () => {
    this.setState({ showCreateMode: false, tempCode: '' })
  }

  saveChanges = () => {
    const { editLockCode, identifier } = this.props
    const { code, valid, showEditMode: codeID } = this.state
    if (valid) {
      editLockCode(codeID, identifier, code)
      this.closeEditMode()
      this.setState({ code: null, valid: true })
    }
  }

  deleteCode = codeID => {
    const { deleteLockCode, identifier } = this.props
    deleteLockCode(identifier, codeID)
  }

  toggleShowModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  handleResyncClick = () => {
    const { resyncLockCodes } = this.props
    this.toggleShowModal()
    resyncLockCodes()
  }

  createAlertModal = () => {
    const { theme } = this.props
    return (
      <AlertModal
        title={
          'Your lock code is pending an update to the lock. This will typically take 15 to 30 secs.'
        }
        message={
          'If this code has been pending for a long time, try the Re-Sync Lock Codes button.'
        }
        buttons={[
          {
            onClick: this.toggleShowModal,
            text: 'Back'
          },
          {
            onClick: this.handleResyncClick,
            color: theme.palette.primary.navy.spaceBlue,
            text: 'Re-Sync Lock Codes'
          }
        ]}
        show={this.state.showModal}
        onClose={this.toggleShowModal}
      />
    )
  }

  render() {
    const { codes, theme, statuses, canCreate } = this.props
    const {
      showEditMode,
      valid,
      validTempCode,
      tempCode,
      showCreateMode,
      expiryEditingCodeID
    } = this.state

    const isEditModeOpen = expiryEditingCodeID != null
    const canEdit = true
    const canDelete = true
    const canExtend = true
    const showEdit = true

    return (
      <DetailWrapper>
        {codes.map(
          (
            { code, userName, codeID, expiresAfter, createdAt, verified },
            index
          ) => (
            <Detail key={index} verified={verified}>
              <div onClick={!verified ? this.toggleShowModal : undefined}>
                {statuses[codeID] ? (
                  statuses[codeID]
                ) : showEditMode === codeID ? (
                  <TextField
                    error={!valid}
                    value={this.state.code || code}
                    onChange={this.handleEditLockCodeChange}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CommonIcon
                            name="close"
                            width="0.5em"
                            height="0.5em"
                            fill={theme.palette.secondary.pink.coral}
                            strokeWidth={4}
                            stroke={theme.palette.secondary.pink.coral}
                            className="cancel-edit"
                            onClick={this.closeEditMode}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                ) : (
                  <p>{code}</p>
                )}
                {showEditMode === codeID && !valid && (
                  <Error> - Must be 4 to 6 digits</Error>
                )}
                {(valid || showEditMode !== codeID) && userName && (
                  <Name>{` - ${userName}`}</Name>
                )}
                {canExtend && expiryEditingCodeID === codeID ? (
                  <NumberInput
                    id={codeID}
                    code={code}
                    expiry={Math.round(expiresAfter / (60 * 60 * 24))}
                    saveExpiry={this.saveExpiryMode}
                    closeEditMode={this.closeModifyExpiryMode}
                  />
                ) : (
                  expiresAfter && (
                    <React.Fragment>
                      <Name>
                        {' '}
                        - {formatExpiresAfter(createdAt, expiresAfter)}
                      </Name>
                      <span>
                        <ActionLink
                          outlineColor={theme.palette.primary.navy.midnight}
                          onClick={
                            isEditModeOpen && canExtend
                              ? null
                              : this.openModifyExpiryMode.bind(this, codeID)
                          }>
                          <Caret>›</Caret>
                        </ActionLink>
                      </span>
                    </React.Fragment>
                  )
                )}
              </div>
              <div>
                {canEdit &&
                  showEdit &&
                  (showEditMode === codeID ? (
                    <SaveButton theme={theme} saveChanges={this.saveChanges} />
                  ) : (
                    <EditButton
                      editable={canEdit}
                      theme={theme}
                      openEditMode={this.openEditMode.bind(this, codeID)}
                    />
                  ))}
                {!canEdit && showEdit && (
                  <CommonIcon
                    name="edit"
                    width="0.9em"
                    height="0.9em"
                    fill={
                      canEdit
                        ? theme.palette.primary.navy.midnight
                        : theme.palette.neutrals.warmGray
                    }
                  />
                )}
                {canDelete && (
                  <ActionLink
                    outlineColor={theme.palette.secondary.pink.coral}
                    onClick={
                      showEditMode === codeID
                        ? null
                        : this.deleteCode.bind(this, codeID)
                    }>
                    <CommonIcon
                      name="close"
                      width="0.5em"
                      height="0.5em"
                      fill={theme.palette.secondary.pink.coral}
                      strokeWidth={4}
                      stroke={theme.palette.secondary.pink.coral}
                    />
                  </ActionLink>
                )}
              </div>
            </Detail>
          )
        )}
        <Flex>
          {this.props.canCreate && this.state.showCreateMode && (
            <React.Fragment>
              <FlexItem flex={2}>
                <TextField
                  width={'100%'}
                  error={!validTempCode}
                  value={tempCode}
                  onChange={this.handleCreateTemporaryCodeChange}
                  InputProps={{
                    disableUnderline: true
                  }}
                />
              </FlexItem>
              <FlexItem flex={1} displayFlex flexEnd alignItemsCenter>
                <Button
                  text={'save'}
                  width={'3em'}
                  background={theme.palette.blue.main}
                  onClick={this.addTempCode}
                />
                &nbsp;&nbsp;
                <Button
                  width={'3em'}
                  text={'cancel'}
                  background={theme.palette.blue.main}
                  onClick={this.closeCreateMode}
                />
              </FlexItem>
            </React.Fragment>
          )}
          {canCreate && !showCreateMode && codes.length < 2 && (
            <FlexItem flex={1} displayFlex flexEnd alignItemsCenter>
              <Button
                onClick={this.showCreateMode}
                outlined
                text="Add"
                height="35px"
                width="114px"
              />
            </FlexItem>
          )}
        </Flex>
        {this.createAlertModal()}
      </DetailWrapper>
    )
  }
}

export default withRouter(withTheme(PanelDetails))
