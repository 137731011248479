// @flow

import React, { Fragment } from 'react'
import ToggleItemsCard from './ToggleItemsCard'
import Tooltip from '../Common/Tooltip'

const settingsTitles = {
  smartResidentAccess: (
    <Fragment>
      Zego Smart Resident Access
      <Tooltip type="AccessSmart" />
    </Fragment>
  ),
  chatResidentAccess: (
    <Fragment>
      Chat Resident Access
      <Tooltip type="AccessChat" />
    </Fragment>
  ),
  workOrderResidentAccess: (
    <Fragment>
      Integrated Work Orders Enabled
      <Tooltip type="IntegratedWorkOrder" />
    </Fragment>
  ),
  standaloneWorkOrders: (
    <Fragment>
      Standalone Work Orders Enabled
      <Tooltip type="StandaloneWorkOrder" />
    </Fragment>
  ),
  residentSurveys: (
    <Fragment>
      Resident Surveys Enabled
      <Tooltip type="ResidentSurveys" />
    </Fragment>
  ),
  moveInChecklist: (
    <Fragment>
      Move-in Checklist
      <Tooltip type="MoveInChecklist" />
    </Fragment>
  ),
  paymentResidentAccess: (
    <Fragment>
      Payment Resident Access
      <Tooltip type="AccessPayment" />
    </Fragment>
  ),
  accessCode: (
    <Fragment>
      Building Access Code
      <Tooltip type="AccessCode" />
    </Fragment>
  ),
  communityFeed: (
    <Fragment>
      Community Feed
      <Tooltip type="CommunityFeed" />
    </Fragment>
  ),
  groupMessaging: (
    <Fragment>
      Group Messaging
      <Tooltip type="GroupMessaging" />
    </Fragment>
  ),
  packages: (
    <Fragment>
      Packages
      <Tooltip type="Packages" />
    </Fragment>
  ),
  vendorCodes: (
    <Fragment>
      Vendor Lock Codes
      <Tooltip type="VendorCodes" />
    </Fragment>
  ),
  petRegistration: (
    <Fragment>
      Pets
      <Tooltip type="PetRegistration" />
    </Fragment>
  ),
  vehicleRegistration: (
    <Fragment>
      Vehicles
      <Tooltip type="VehicleRegistration" />
    </Fragment>
  ),
  visitorRegistration: (
    <Fragment>
      Visitors
      <Tooltip type="VisitorRegistration" />
    </Fragment>
  ),
  amenities: (
    <Fragment>
      Amenities
      <Tooltip type="" />
    </Fragment>
  ),
  marketplace: (
    <Fragment>
      Marketplace
      <Tooltip type="Marketplace" />
    </Fragment>
  ),
  smartLeaseRenewal: (
    <Fragment>
      Smart Lease Renewal
      <Tooltip type = "SmartLeaseRenewal"/>
    </Fragment>
  ),
  amenityReservations: (
    <Fragment>
      Amenity Reservations
      <Tooltip type = "AmenityReservations"/>
    </Fragment>
  )
}

type Props = {
  settings: {},
  updateSetting: ({}) => mixed,
  isSmartEnabled: boolean
}

export default class Access extends React.Component<Props> {
  onClickChat = () => {
    const btn =
      document.querySelector('.zegoHelpButton .zegoHelpButtonEnabled') ||
      document.querySelector('.helpButton .helpButtonEnabled.uiButton')

    if (btn) {
      btn.click()
    }
  }

  render() {
    const { settings, updateSetting, isSmartEnabled } = this.props
    return (
      <ToggleItemsCard
        handleToggle={updateSetting}
        settings={settings}
        settingsTitles={settingsTitles}
        isSmartEnabled={isSmartEnabled}
        title="Access"
        footerNote={
          <div>
            These settings are read only. To alter any of these settings contact
            Zego via email at support@zego.io or{' '}
            <font color="#22BC9F" className="contact-zego">
              <a onClick={this.onClickChat}>Chat With Us</a>
            </font>
          </div>
        }
        disabled
      />
    )
  }
}
