import { createFetchNextActions, createFetchActions, createUpdateActions } from '../../helpers/actions/creators'
import { UPDATE_PREFIX } from '../../helpers/actions/constants'

export const CREATE_MARKETPLACE_DEAL = 'CREATE_MARKETPLACE_DEAL'
export const CREATE_MARKETPLACE_DEAL_START = 'CREATE_MARKETPLACE_DEAL_START'
export const CREATE_MARKETPLACE_DEAL_END = 'CREATE_MARKETPLACE_DEAL_END'
export const MARKETPLACEDEALLIST = 'MARKETPLACEDEALLIST'
export const DELETE_MARKETPLACE_DEAL = 'DELETEMARKETPLACEDEAL'
export const REORDER_MARKETPLACE_DEAL = 'REORDER_MARKETPLACE_DEAL'

export const MARKETPLACE_CARD = 'MARKETPLACE_CARD' 

export const {
  fetchAction: fetchMarketplaceCard,
  fetchStartAction: fetchMarketplaceCardStart,
  fetchSuccessAction: fetchMarketplaceCardSuccess,
  fetchErrorAction: fetchMarketplaceCardError
} = createFetchActions(MARKETPLACE_CARD)

export const {
  updateStartAction: updateMarketplaceCardStart,
  updateSuccessAction: updateMarketplaceCardSuccess,
  updateErrorAction: updateMarketplaceCardError
} = createUpdateActions(MARKETPLACE_CARD)

export const createMarketplaceDeal = (marketplaceDeal, image, type, history, url) => ({
  type: CREATE_MARKETPLACE_DEAL,
  marketplaceDeal,
  image,
  dealType: type,
  history,
  url
})

export const updateMarketplaceCard = (marketplaceDeal, image, type, history, url) => ({
  type: `${UPDATE_PREFIX}${MARKETPLACE_CARD}`,
  marketplaceDeal,
  image,
  dealType: type,
  history,
  url
})

export const createMarketplaceDealStart = {
  type: CREATE_MARKETPLACE_DEAL_START
}

export const createMarketplaceDealEnd = {
  type: CREATE_MARKETPLACE_DEAL_END
}

export const {
  fetchAction: fetchMarketplaceDeals,
  fetchStartAction: fetchMarketplaceDealsStart,
  fetchSuccessAction: fetchMarketplaceDealsSuccess,
  fetchErrorAction: fetchMarketplaceDealsError,
  fetchNextAction: fetchNextMarketplaceDeals,
  fetchNextStartAction: fetchNextMarketplaceDealsStart,
  fetchNextSuccessAction: fetchNextMarketplaceDealsSuccess,
  fetchNextErrorAction: fetchNextMarketplaceDealsError
} = createFetchNextActions(MARKETPLACEDEALLIST)

export const deleteMarketplaceDeal = (id) => ({
  type: DELETE_MARKETPLACE_DEAL,
  id
})

export const reorderMarketplaceDeal = (id, index) => ({
  type: REORDER_MARKETPLACE_DEAL,
  id,
  index
})
