// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import Typography from '@material-ui/core/Typography'
import { CarouselUnitProps } from './types'
import FlexDirection, {
  FlexItem
} from '@Common/Flex/FlexDirection'
import { UnitContainer, Message, Time, Date } from './styles'

const CarouselUnit = ({
  date,
  time,
  message,
  divider = false,
  theme
}: CarouselUnitProps) => {
  return (
    <UnitContainer divider={divider}>
      <FlexDirection>
        <React.Fragment>
          <FlexItem flex={2}>
            <Date>{date}</Date>
          </FlexItem>
          <FlexItem flex={2}>
            <Time>{time}</Time>
          </FlexItem>
          <FlexItem flex={6}>
            <Typography
              style={{
                lineHeight: '2em',
                color: theme.palette.neutrals.deepGray
              }}>
              <Message>{message}</Message>
            </Typography>
          </FlexItem>
        </React.Fragment>
      </FlexDirection>
    </UnitContainer>
  )
}

export default withTheme(CarouselUnit)
