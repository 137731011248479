import { call, select, takeLatest, put } from 'redux-saga/effects'
import isPlainObject from 'lodash/isPlainObject'

import { getToken } from '../../authorization/selectors'
import { showSnackbar } from '../../snackbar/actions'
import { refreshUnit } from '../../select/actions'
import { hasChanges } from '../../../utils/object'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  failedToFetchSchedule,
  failedToSwitchScheduleEnabled,
  failedToUpdateSchedule
} from '../../../utils/messages'
import {
  fetchScheduleAction,
  fetchScheduleStart,
  fetchScheduleSuccess,
  fetchScheduleError,
  updateScheduleOptimistically,
  updateScheduleRevert,
  updateScheduleSuccess,
  updateScheduleEnabledOptimistically,
  updateScheduleEnabledRevert,
  UPDATE_SCHEDULE,
  UPDATE_SCHEDULE_ENABLED
} from '../actions'
import { getFullSchedule } from '../selectors'
import { mergeScheduleItems } from '../utils'

export function* getSchedule({ id }) {
  try {
    yield put(fetchScheduleStart())
    const authToken = yield select(getToken)
    const response = yield call(api.getSchedule, id, authToken)
    yield put(fetchScheduleSuccess(response))
    return response
  } catch (error) {
    log(`Failed to fetch schedule. Error: ${error}`)
    yield put(fetchScheduleError(error))
    yield put(showSnackbar(failedToFetchSchedule, 'error'))
  }
}

export function* updateSchedule({ update: newAtts }) {
  const currentState = yield select(getFullSchedule)
  if (Object.keys(newAtts).includes('schedule_items')) {
    const { schedule_items: originalScheduleItems } = currentState
    const { schedule_items: scheduleItems } = newAtts
    newAtts = {
      ...newAtts,
      schedule_items: mergeScheduleItems(originalScheduleItems, scheduleItems)
    }
  }
  let currentAtts = {}
  let attsToUpdate = {}
  for (let key in newAtts) {
    if (
      (!isPlainObject(newAtts[key]) && newAtts[key] !== currentState[key]) ||
      (isPlainObject(newAtts[key]) &&
        hasChanges(newAtts[key], currentState[key]))
    ) {
      attsToUpdate[key] = newAtts[key]
      currentAtts[key] = currentState[key]
    }
  }
  if (Object.keys(attsToUpdate).length > 0) {
    const authToken = yield select(getToken)
    try {
      yield put(updateScheduleOptimistically(newAtts))
      const response = yield call(
        api.createUpdateSchedule,
        currentState.id,
        attsToUpdate,
        authToken
      )

      yield put(updateScheduleSuccess(response))
      const { unit_id: unitId } = newAtts
      if (unitId != null) {
        yield put(refreshUnit(unitId))
      }
    } catch (error) {
      log(`Failed to update schedule. Error: ${error}`)
      yield put(updateScheduleRevert(currentState))
      yield put(showSnackbar(failedToUpdateSchedule, 'error'))
    }
  }
}

export function* updateScheduleEnabled({ enabled }) {
  const { id: scheduleId, enabled: currentState } = yield select(
    getFullSchedule
  )
  try {
    const enabledStr = enabled ? 'enable' : 'disable'
    const authToken = yield select(getToken)
    yield put(updateScheduleEnabledOptimistically(enabled))
    const response = yield call(
      api.updateScheduleEnabled,
      scheduleId,
      enabledStr,
      authToken
    )
    return response
  } catch (error) {
    log(`Failed to update schedule. Error: ${error}`)
    yield put(updateScheduleEnabledRevert(currentState))
    yield put(showSnackbar(failedToSwitchScheduleEnabled, 'error'))
  }
}

function* watchFetchSchedule() {
  yield takeLatest(fetchScheduleAction().type, getSchedule)
}

function* watchUpdateSchedule() {
  yield takeLatest(UPDATE_SCHEDULE, updateSchedule)
}

function* watchUpdateScheduleEnabled() {
  yield takeLatest(UPDATE_SCHEDULE_ENABLED, updateScheduleEnabled)
}

export default [
  watchFetchSchedule(),
  watchUpdateSchedule(),
  watchUpdateScheduleEnabled()
]
