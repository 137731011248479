import get from 'lodash/get'
import moment from 'moment'
import { getProfileUrlOrDefault } from 'zego-shared/utils/profilePicture'
import { getPinnedChannels } from 'zego-shared/store/chat/pinnedChannels/selectors'
import {
  RESIDENT,
  PROPERTY_ADMIN,
  PROPERTY_STAFF
} from '../../constants/roleConstants'
import {
  getTimeWithAMPM,
  isToday,
  isYesterday,
  getDate
} from '../../utils/dateUtils'

const chatbotAccountUUID = window?._env_?.REACT_APP_MILLIE_CHATBOT_UUID

const getResidentMemberFromChannel = channel => {
  const prelimMatch = channel?.members.find(
    user => (user.metaData.role === RESIDENT))

  // Best heuristic for determining the resident if metadata not set
  return prelimMatch || channel?.members.find(
    user => (![PROPERTY_ADMIN, PROPERTY_STAFF].includes(user.metaData.role)
      && user.userId !== chatbotAccountUUID)
  );
}

const getChannelName = name =>
  name.substring(name.indexOf('/') + 1, name.length)

export const getResidentUserIdFromResidentChannel = channel => {
  const resident = getResidentMemberFromChannel(channel) 
  return resident ? (resident.userId || null) : null
}

const getNameFromChannelName = name => {
  return name.split('/')[1]
}

const getResidentProfileUrl = channel => {
  const resident = getResidentMemberFromChannel(channel);

  return resident
    ? getProfileUrlOrDefault(resident.profileUrl, resident.nickname)
    : getProfileUrlOrDefault('', getNameFromChannelName(channel.name))
}

const getChatChannels = state => get(state, ['chat', '']) || []

const getChatChannelsByType = state => get(state, ['chat', 'byType']) || []

const channelToChannelInfo = channel => {
  const lastUpdatedTime =
    get(channel, ['lastMessage', 'createdAt'], null) || channel.createdAt
  const time = moment(lastUpdatedTime)

  return {
    text: getChannelName(channel.name),
    date: moment().isSame(time, 'day') ? `TODAY` : time.format('M/D'),
    unitId: get(channel, ['members', '0', 'metaData', 'unit'], 'N/A'),
    userId: getResidentUserIdFromResidentChannel(channel)
  }
}

const channelToChannelListItem = channel => {
  const lastMessage = channel.lastMessage
  return {
    shouldShowUnreadIndicator: channel.unreadMessageCount > 0,
    profileUrl: getResidentProfileUrl(channel),
    url: channel.url,
    name: getChannelName(channel.name),
    userId: getResidentUserIdFromResidentChannel(channel),
    lastMessageInfoText: lastMessage
      ? `${get(lastMessage, '_sender.nickname', '(System)')} @ ${getTimeWithAMPM(
          lastMessage.createdAt
        )} ${
          isToday(lastMessage.createdAt)
            ? 'Today'
            : isYesterday(lastMessage.createdAt)
            ? 'Yesterday'
            : getDate(lastMessage.createdAt)
        }`
      : ``,
    lastMessage: lastMessage
      ? `${lastMessage.message.substring(0, 42)}${
          lastMessage.message[42] ? '...' : ''
        }`
      : ``
  }
}

export const getUnreadCount = state => {
  const chatChannels = getChatChannelsByType(state)
  return chatChannels.filter(channel => channel.unreadMessageCount !== 0).length
}
export const getActiveChannelUrl = state => {
  return get(state, ['chat', 'activeChannel', 'url'])
}

export const getChannelInfo = state => {
  return getChatChannels(state).map(channelToChannelInfo)
}

export const hasData = state => {
  return getChatChannels(state) !== null
}

export const shouldRefetchChannels = (state, userId) => {
  return (
    getChatChannelsByType(state).find(channel =>
      channel.members.find(user => user.userId === userId)
    ) === undefined
  )
}

export const getChannelList = (state, filter) => {
  const channelList = getChatChannelsByType(state).map(channelToChannelListItem)
  switch (filter) {
    case 'unread':
      return channelList.filter(
        channel => channel.shouldShowUnreadIndicator === true
      )

    case 'pinned':
      const pinnedChannels = getPinnedChannels(state)
      return channelList.filter(({ url }) => pinnedChannels.includes(url))

    default:
      return channelList
  }
}

export const getResidentUserFromChannel = state => {
  const activeChannel = get(state, ['chat', 'activeChannel'])
  const residentFromChannel =
    activeChannel &&
    getResidentMemberFromChannel(activeChannel)
  return residentFromChannel ? residentFromChannel.userId : null
}

export const hasChatSummaryData = state => {
  return getChannelInfo(state) ? true : false
}

export const getUnreadMessagesCount = state => {
  return get(state, ['chat', 'unread', 'count'])
}
