// @flow

import React from 'react'
import { generate as shortid } from 'shortid'
import { ToggleContainer, ToggleButtonGroup, ToggleButton } from './styles'

type Item = {
  id: string,
  name: string,
  disabled?: boolean
}

type Props = {
  selectedItem: string,
  items: Array<Item>,
  handleChange: (any, string) => mixed
}
const MultiToggle = ({ selectedItem, items, handleChange }: Props) => (
  <ToggleContainer>
    <ToggleButtonGroup value={selectedItem} exclusive onChange={handleChange}>
      {
        items.map(({ id, name, disabled }) => (
          <ToggleButton disabled={disabled} disableRipple value={id} key={shortid()}>
            {name}
          </ToggleButton>
        ))
      }
    </ToggleButtonGroup>
  </ToggleContainer>
)

export default MultiToggle
