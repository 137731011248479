// @flow
import { connect } from 'react-redux'
import get from 'lodash/get'
import DeleteDevice from './DeviceDelete'
import { getRemovalSteps } from 'zego-shared/store/devices/selectors'
import { deleteDevice } from 'zego-shared/store/devices/actions'
import {
  clearHubCommandExcludeStatus
} from 'zego-shared/store/devices/actions'
import { excludeDevice } from 'zego-shared/store/addDevice/connected/actions'

const mapStateToProps = (state, { match: { url, params }, history }) => {
  return {
    history,
    url,
    removalSteps: getRemovalSteps(state, params.deviceID),
    isLoading: get(state, ['unit', 'devices', 'deleteDevice', 'loading']),
    excludeLoading: get(state, [
      'unit',
      'devices',
      'hubCommand',
      'excludeLoading'
    ]),
    excludeSuccess: get(state, [
      'unit',
      'devices',
      'hubCommand',
      'excludeSuccess'
    ])
  }
}

const mapDispatchToProps = (dispatch, { match: { url, params }, history }) => {
  return {
    deleteDevice: () =>
      dispatch(deleteDevice(params.deviceID, history, url, dispatch)),
    excludeHub: () => dispatch(excludeDevice(url, history)),
    clearExclusionStatus: () => dispatch(clearHubCommandExcludeStatus())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteDevice)
