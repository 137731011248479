import { connect } from 'react-redux'
import UserManagement from './UserManagement'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { fetchPropertyUsersSummary } from 'zego-shared/store/users/propertyUsersSummary/actions'
import { ldInitRequest } from '../../integrations/LaunchDarkly/actions'
import { getFeatureFlag } from '../../integrations/LaunchDarkly/selectors'

import {
  getPropertyUsersSummary,
  getPropertyUsersCount,
  getPropertyResidentsCount,
  hasData
} from 'zego-shared/store/users/selectors'

const UserManagementWithFetchData = withFetchData(UserManagement)
const fetchingSelector = createIsFetchingSelector(['property', 'users'])

const mapStateToProps = state => ({
  featureFlag: getFeatureFlag(state),
  hasData: hasData(state),
  isFetching: fetchingSelector(state),
  propertyUsersSummary: getPropertyUsersSummary(state),
  propertyUsersCount: getPropertyUsersCount(state),
  residentsCount: getPropertyResidentsCount(state)
})

const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  fetchData: () => {
    dispatch(fetchPropertyUsersSummary(params.propertyId))
  },
  ldInitRequest: () => { 
    dispatch(ldInitRequest())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementWithFetchData)
