const SET_ACTIVE_SENDBIRD_CHANNEL = 'SET_ACTIVE_SENDBIRD_CHANNEL'

const SET_ACTIVE_SENDBIRD_CHANNEL_BY_URL = 'SET_ACTIVE_SENDBIRD_CHANNEL_BY_URL'

const SET_ACTIVE_SENDBIRD_CHANNEL_BY_USER_ID = 'SET_ACTIVE_SENDBIRD_CHANNEL_BY_USER_ID'

export const setActiveChannel = channel => ({
  type: SET_ACTIVE_SENDBIRD_CHANNEL,
  channel
})

export const setActiveChannelByUrl = url => ({
  type: SET_ACTIVE_SENDBIRD_CHANNEL_BY_URL,
  url
})

export const setActiveChannelByUserId = userId => ({
  type: SET_ACTIVE_SENDBIRD_CHANNEL_BY_USER_ID,
  userId
})
