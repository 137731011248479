import { connect } from 'react-redux'
import GroupsUsers from './GroupsUsers'
import {
  fetchGroupUsers,
  searchGroupsUsers,
  fetchSingleGroup
} from 'zego-shared/store/groupMessaging/groups'
import withFetchData from 'zego-shared/views/withFetchData'
import {
  getSelectedGroupsUsers,
  getGroupName
} from 'zego-shared/store/groupMessaging/groups/selectors'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { getUserRole } from 'zego-shared/store/authorization/selectors'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'

const fetchingSelector = createIsFetchingSelector(['groupusers'])

const mapStateToProps = (state, { history, match: { params } }) => ({
  title: getGroupName(state),
  items: getSelectedGroupsUsers(state),
  params,
  isFetching: fetchingSelector(state),
  userRole: getUserRole(state),
  propertyId: params.propertyId,
  history
})

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  return {
    fetchData: () => {
      dispatch(fetchSingleGroup(params.groupId))
      dispatch(fetchGroupUsers([params.groupId]))
    },
    searchGroupsUsers: query =>
      dispatch(searchGroupsUsers([params.groupId], query)),
    displayErrorMessage: (message) => dispatch(showSnackbar(message, 'error'))
  }
}

const GroupsUsersWithFetchData = withFetchData(GroupsUsers)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupsUsersWithFetchData)
