// @flow

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

const unitSearchCategories = categories => {
  return categories.map(category => {
    if (category === 'zego_smart') return 'Zego Smart'
    if (category === 'summary') return 'Summary'
    if (category === 'users') return 'Users'
    if (category === 'standalone_work_orders') return 'Work Orders'
    return 'Units'
  })
}

const userSearchCategories = categories => {
  const isChatEnabled = categories.find(category => category === 'chat') || ''
  return isChatEnabled ? ['User Management', 'Chat'] : ['User Management']
}

const categoryKeys = {
  'User Management': (currentPropertyId, userId) =>
    `${currentPropertyId}/user-management/profile/${userId}`,
  Chat: (currentPropertyId, userId) =>
    `${currentPropertyId}/communication/chat/live-chat/users/${userId}`,
  Summary: (currentPropertyId, unitId) =>
    `${currentPropertyId}/units/${unitId}/summary`,
  'Zego Smart': (currentPropertyId, unitId) =>
    `${currentPropertyId}/units/${unitId}/smart`,
  Users: (currentPropertyId, unitId) =>
    `${currentPropertyId}/units/${unitId}/user-management`,
  Units: (currentPropertyId, unitId) =>
    `${currentPropertyId}/units/${unitId}/summary`,
  'Work Orders': (currentPropertyId, unitId) =>
    `${currentPropertyId}/units/${unitId}/work-orders`
}

const matchedKeys = {
  email: 'email',
  last_name: 'Last name',
  first_name: 'First name'
}

export const getSearchResults = (state: Object, currentPropertyId: number) => {
  const searchResults = get(state, ['search', 'searchResult'], [])
  const results = []

  searchResults.map(result => {
    result.resource === 'user'
      ? userSearchCategories(result.nav).forEach(category => {
          const { data } = result
          const name =
            data.first_name == null && data.last_name == null
              ? data.email
              : `${data.first_name || ''} ${data.last_name || ''}`
          results.push({
            name: `${name} (${result.data.unit_name})`,
            category: category,
            matched: matchedKeys[result.matched],
            url: categoryKeys[category](currentPropertyId, result.data.user_id)
          })
        })
      : unitSearchCategories(result.nav).forEach(category => {
          if (category !== 'Units') {
            results.push({
              name: result.data.name || result.data.unit_name,
              building: result.data.building || '',
              category: category,
              matched: result.matched,
              url: categoryKeys[category](currentPropertyId, result.data.id)
            })
          }
        })
  })

  return results
}

export const getResidentSearchResults = (state: Object, residents: Object[] = []) => {
  const searchResults = get(state, ['search', 'searchResult'], [])
  const results = []
  const unitResIds = []

  const addResidentToResults = (resident) => {
    const { unitId, userId } = resident
    const key = `${unitId}-${userId}`

    if (!unitId || !userId || unitResIds.includes(key)) {
      return
    }

    results.push(resident)
    unitResIds.push(key)
  }

  searchResults.forEach(({ resource, data }) => {
    if (resource === 'user') {
      const resident = {
        userId: data.user_id || '',
        name: `${data.first_name || ''} ${data.last_name || ''}`.trim(),
        picture: '',
        firstName: data.first_name || '',
        lastName: data.last_name || '',
        email: data.email || '',
        unitId: data.unit_id || '',
        unitBuilding: data.unit_building || '',
        unitName: data.unit_name || ''
      }

      if (resident.userId) {
        const foundResident = residents.find((res) => res.userId === resident.userId)
        resident.picture = foundResident ? foundResident.picture : ''
      }

      addResidentToResults(resident)
    }

    if (resource === 'unit') {
      const unitId = data.id || ''

      if (unitId) {
        const unitResidents = residents.filter((res) => res.unitId === unitId)
        unitResidents.forEach((unitRes) => {
          addResidentToResults({
            unitId: unitId,
            unitBuilding: data.building || '',
            unitName: data.name || '',
            userId: unitRes.userId || '',
            name: unitRes.name || '',
            picture: unitRes.picture || ''
          })
        })
      }
    }
  })

  return results
}

export const hasSearchData = (state: Object) => !isEmpty(get(state, ['search']))

export const wasRecentlySearched = (state: Object, searchedUrl: string)  => {
  let recentViews = get(state, ['search', 'history'], [])
  let result = false

  if (recentViews.length)
    recentViews.forEach((view: Object) => {
      const values: Array<any> = Object.values(view)

      const value = values[0].split(',')
      const url = value[1]

      if (url === searchedUrl) result = true
    })

  return result
}
