// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import ScrollToTop from '@Common/ScrollToTop'
import { generate as shortid } from 'shortid'
import { withTheme } from 'emotion-theming'
import InputAdornment from '@material-ui/core/InputAdornment'
import Grid, { GridItem } from '@Common/Grid'
import { SearchIcon, CommonIcon } from '@icons'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import Avatar from '@Common/Avatar'
import CircleWithText from '@Common/Cards/CircleWithText'
import RecipientsTable from './RecipientsTable'
import AlertModal from '@Common/AlertModal/AlertModal'
import TabContent from './EditModeTabContent'
import MessageSendDetails from './MessageSendDetails'
import { getProfileUrlOrDefault } from 'zego-shared/utils/profilePicture'
import { Attachment } from '../CreateGroupMessage/styles'
import StatusScreen, { StatusScreenContent } from '@Common/StatusScreen'
import EditModeHead from './EditModeHead'
import MessageEditor from '../Template/MessageEditor'
import { SingleGroupMessageProps, SingleGroupMessageState } from './types'
import EmailTextTabs from '../EmailTextTabs'
import { updateGroupMessage } from 'zego-shared/store/groupMessaging/message/utils'
import Loader from '@Common/Loader'
import { dashToSpace } from '../../../../utils'
import Header from '@Common/Header'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import { FabButton } from '@Common/Button'
import { CHAT_METHOD_OPTION } from '../../constants'

import {
  HeadBackground,
  Body,
  Recipients,
  MessageDetail,
  MessageDetails,
  SenderName,
  SentDateTime,
  MessageHeader,
  DeliveryMethodBackground,
  DeliveryMethodText,
  DeliveryMethodTitle,
  ProgressCircles,
  Wrapper,
  CenteredText,
  Break,
  ButtonSet,
  TextField,
  Line,
  StyledRow
} from './styles'

class SingleGroupMessage extends React.Component<
  SingleGroupMessageProps,
  SingleGroupMessageState
> {
  state = {
    showDialog: false,
    currentTab: 0,
    selectedDeliveryMethods: null,
    selectedSendMethod: '',
    showEditMode: false,
    showMessageSendDetails: null,
    searchText: '',
    selectedTemplate: {},
    selectedGroups: [],
    offsetDays: null,
    offsetBeforeAfter: null,
    selectedEventId: null,
    selectedDateTime: null,
    sendMessageSuccess: false,
    showChatOption: false
  }

  componentDidMount = () => {
    this.props.ldInitRequest()
  }

  componentDidUpdate = () => {
    const { featureFlag } = this.props;
    const chatOptionFlag = (featureFlag && featureFlag.ld && featureFlag.ld.variation(CHAT_METHOD_OPTION, false))
    if(this.state.showChatOption !== chatOptionFlag) {
      this.setState({ showChatOption: chatOptionFlag })
    }
  }

  showMessageSendDetails = index => {
    this.setState({ showMessageSendDetails: index })
  }

  getItems = () => {
    const { recipients, propertyId, isFuture } = this.props
    const { showMessageSendDetails } = this.state
    const recipientsData = recipients.map(
      (
        { name, email, userId, method, image, groups, messageDetails },
        index
      ) => {
        return {
          image: (
            <StyledRow index={1}>
              <Avatar
                picture={getProfileUrlOrDefault(image, `${name}`)}
                size="2.5em"
              />
            </StyledRow>
          ),
          name: <StyledRow index={2}>{name}</StyledRow>,
          method: <StyledRow index={3}>{dashToSpace(method)}</StyledRow>,
          groups: <StyledRow index={4}>{groups.join(', ')}</StyledRow>,
          messageDetails: (
            <StyledRow index={5}>
              <div>
                {messageDetails && !isFuture && (
                  <a
                    onClick={() => null}
                    onMouseEnter={this.showMessageSendDetails.bind(this, index)}
                    onMouseLeave={this.showMessageSendDetails.bind(this, null)}>
                    Message Send Details
                  </a>
                )}
                {showMessageSendDetails === index &&
                  messageDetails &&
                  !isFuture && (
                    <MessageSendDetails
                      emailSentTime={messageDetails.emailSentTime}
                      emailRecipient={messageDetails.emailRecipient}
                      textSentTime={messageDetails.textSentTime}
                      textRecipient={messageDetails.textRecipient}
                      inAppSentTime={messageDetails.inAppSentTime}
                      inAppRecipient={messageDetails.inAppRecipient}
                      method={method}
                    />
                  )}
              </div>
            </StyledRow>
          ),
          userDetails: (
            <StyledRow index={6}>
              {' '}
              <Link
                to={`/properties/${propertyId}/user-management/profile/${userId}`}>
                User Details
              </Link>
            </StyledRow>
          )
        }
      }
    )

    return recipientsData.map(rowData =>
      Object.values(rowData).map((value, index) => {
        return { value: value || ' ' }
      })
    )
  }

  showFuture = () => {
    this.setState({ showFuture: true })
  }

  deleteGroupMessage = debounce(() => {
    this.closeAlertModal()
    this.props.deleteGroupMessage()
  }, 200)

  closeAlertModal = () => {
    this.setState({ showDialog: false })
  }

  openDialog = () => {
    this.setState({ showDialog: true })
  }

  editGroupMessage = () => {
    this.setState({
      showEditMode: true,
      selectedTemplate: {}
    })
  }

  getTemplateToSave = () => {
    // easier/less complex to just always update the template so we don't have to use 4 different api calls
    const { templateName, textBody, text, title, templateId } = this.props
    const { selectedTemplate } = this.state
    // template wasn't changed
    if (isEmpty(selectedTemplate)) {
      // just return the current template id
      return { id: templateId }
    }

    // new template was selected
    if (selectedTemplate.id && selectedTemplate.id !== templateId) {
      // can't edit it so just return the id
      if (!selectedTemplate.canEdit) {
        return { id: selectedTemplate.id }
      } else {
        // can edit the template so just create a new template
        // selectedTemplate values will be changed if they edit anything
        return {
          name: selectedTemplate.name,
          textBody: selectedTemplate.textMessage,
          message: selectedTemplate.message,
          title: selectedTemplate.title,
          id: selectedTemplate.id
        }
      }
    }
    // template didn't change
    return {
      name: selectedTemplate.name || templateName,
      textBody: selectedTemplate.textMessage || textBody,
      message: selectedTemplate.message || text,
      title: selectedTemplate.title || title,
      id: templateId
    }
  }

  getEventToSave = () => {
    const { groupEvents, event } = this.props
    const {
      selectedEventId,
      offsetBeforeAfter,
      offsetDays
    } = this.state

    return this.getSendMethod() === 'event'
      ? {
          name: shortid.generate(),
          type: 'event',
          event:
            groupEvents[selectedEventId] ||
            groupEvents.indexOf(event.eventType),
          operator: 'eq',
          time_offset: offsetBeforeAfter
            ? offsetBeforeAfter === 'before'
              ? offsetDays || event.offset
              : -Math.abs(offsetDays || event.offset)
            : event.offset
        }
      : null
  }

  getSendMethod = () => {
    const { isEvent } = this.props
    const { selectedSendMethod } = this.state

    return selectedSendMethod
      ? selectedSendMethod === 'event'
        ? 'event'
        : 'schedule'
      : isEvent
      ? 'event'
      : 'schedule'
  }

  saveGroupMessageChanges = async () => {
    this.closeAlertModal()
    const {
      authToken,
      groups,
      showSnackbar,
      isEvent,
      timezone,
      match: {
        params: { id }
      }
    } = this.props
    const {
      selectedDeliveryMethods,
      selectedDateTime,
      selectedSendMethod,
      selectedGroups,
      showEditMode
    } = this.state

    const template = this.getTemplateToSave()
    const messageGroups = showEditMode ? groups : selectedGroups

    // values were changed and ended up as blank strings on the template
    if (template.title === '') {
      showSnackbar('Template title cannot be blank!')
      return
    }
    if (template.message === '') {
      showSnackbar('Template message cannot be blank!')
      return
    }
    if (
      (selectedSendMethod === '' && !isEvent && messageGroups.length === 0) ||
      (selectedSendMethod === 'datetime' && messageGroups.length === 0)
    ) {
      showSnackbar('Please select a group!')
      return
    }

    const newEvent = this.getEventToSave()
    const sendMethod = this.getSendMethod()

    try {
      await updateGroupMessage(
        authToken,
        id,
        selectedDeliveryMethods,
        selectedDateTime,
        template,
        sendMethod,
        selectedGroups,
        newEvent,
        timezone
      )
    } catch (error) {
      showSnackbar('Something went wrong! Please try again!')
      return
    }
    this.setState({
      sendMessageSuccess: true
    })
  }

  goBack = () => {
    const {
      history,
      match: { url },
      isFuture,
      isEventView
    } = this.props

    this.state.showEditMode
      ? this.setState({ showEditMode: false })
      : history.push(
          `${url.split('/group-messaging')[0]}/group-messaging${
            (isFuture || isEventView) ? '?status=future' : ''
          }`
        )
  }

  handleTextFieldChange = field => {}

  handleTabChange = (event, value) => {
    this.setState({ currentTab: value })
  }

  handleDeliveryMethodChange = event => {
    const checked = event.target.checked
    const name = event.target.name
    const { sendingMethods } = this.props
    this.setState(({ selectedDeliveryMethods }) => {
      if (checked) {
        return {
          selectedDeliveryMethods: selectedDeliveryMethods
            ? [...selectedDeliveryMethods, name]
            : [...sendingMethods, name]
        }
      } else {
        return (selectedDeliveryMethods &&
          selectedDeliveryMethods.length > 1) ||
          (!selectedDeliveryMethods && sendingMethods.length > 1)
          ? {
              selectedDeliveryMethods: selectedDeliveryMethods
                ? selectedDeliveryMethods.filter(method => method !== name)
                : sendingMethods.filter(method => method !== name)
            }
          : null
      }
    })
  }

  handleSelectSendMethod = event => {
    const value = event.target.value
    if (value === 'datetime') {
      this.setState({
        selectedSendMethod: value,
        selectedGroups: []
      })
    } else {
      this.setState({
        selectedSendMethod: value
      })
    }
  }

  chooseTemplate = id => {
    const { templates } = this.props
    this.setState({
      selectedTemplate: templates.find(template => template.id === id)
    })
  }

  handleMessageChange = event => {
    const { selectedTemplate } = this.state
    this.setState({
      selectedTemplate: {
        ...selectedTemplate,
        [event.target.name]: event.target.value
      }
    })
  }

  handleEmailMessageChange = value => {
    const { selectedTemplate } = this.state
    this.setState({
      selectedTemplate: {
        ...selectedTemplate,
        message: value
      }
    })
  }

  handleGroupSelect = selections => {
    this.setState({
      selectedGroups: selections
    })
  }

  searchRecipient = debounce(() => {
    const { searchRecipient } = this.props
    const { searchText } = this.state
    searchRecipient(searchText)
  }, 400)

  handleSearchChange = ({
    currentTarget: { value }
  }: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ searchText: value })
    this.searchRecipient()
  }

  handleKeyPress = ({ key }: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      this.searchRecipient()
    }
  }

  handleSelectedDateTimeChange = (text, moment, recurringOptionName) => {
    this.setState({
      selectedDateTime: {
        moment,
        recurringOptionName
      }
    })
  }

  handleOffsetDays = ({ target: { value } }) => {
    value > -1 &&
      this.setState({
        offsetDays: value
      })
  }

  handleEmptyOffsetDays = ({ currentTarget: { value } }) => {
    this.setState({
      offsetDays: value.replace(/\D/g, '') === '' ? '0' : value
    })
  }

  handleOffsetBeforeAfter = offsetBeforeAfter => {
    this.setState({
      offsetBeforeAfter
    })
  }

  handleEventChange = event => {
    this.setState({
      selectedEventId: event
    })
  }

  canEditTemplate = () => {
    const { canEditTemplate } = this.props
    const { selectedTemplate } = this.state
    if (selectedTemplate.id) {
      return selectedTemplate.canEdit
    } else {
      return canEditTemplate
    }
  }

  render() {
    const {
      theme,
      groups,
      title,
      text,
      sendingUser,
      sentDateTime,
      attachments,
      templateName,
      cronText,
      emailCount,
      textCount,
      inAppCount,
      hasData,
      sendingMethods,
      isFetching,
      isFuture,
      isEventView,
      searchGroups,
      authToken,
      templates,
      replacementOptions,
      templateId,
      dateTimeMoment,
      groupEventsDropDownOptions,
      isEvent,
      event,
      groupEvents,
      propertyId,
      history,
      showTextMessage,
      textBody,
      currentPage,
      total,
      perPage,
      fetchPage,
      match: { params }
    } = this.props

    const {
      showEditMode,
      currentTab,
      selectedDeliveryMethods,
      selectedTemplate,
      selectedSendMethod,
      selectedDateTime,
      offsetDays,
      offsetBeforeAfter,
      selectedEventId,
      sendMessageSuccess
    } = this.state

    if (isFetching) {
      return <Loader />
    }

    if (!hasData) {
      return null
    }

    const { name: senderName, picture: senderPicture } = sendingUser
    const attachmentsCount = Object.keys(attachments).length
    const items = this.getItems()
    return (
      <React.Fragment>
        <StatusScreen
          open={sendMessageSuccess}
          autoHideDuration={2000}
          goTo={() =>
            history.push(
              `/properties/${propertyId}/communication/group-messaging?status=future`
            )
          }>
          <StatusScreenContent
            heading="SUCCESS!"
            subheading="Your group message has been updated"
          />
        </StatusScreen>
        <Wrapper>
          <FlexDirection
            direction="column"
            fullHeight
            fullWidth
            className="wrapper-flex">
            <FlexItem>
              <HeadBackground editMode={showEditMode}>
                <div>
                  {showEditMode ? (
                    <EditModeHead
                      groups={groups}
                      authToken={authToken}
                      propertyId={params.propertyId}
                      searchGroups={searchGroups}
                      theme={theme}
                      handleChange={this.handleMessageChange}
                      handleGroupSelect={this.handleGroupSelect}
                      handleTabChange={this.handleTabChange}
                      currentTab={currentTab}
                      selectedTemplate={templateName}
                      selectedSendMethod={
                        selectedSendMethod
                          ? selectedSendMethod
                          : isEvent
                          ? 'event'
                          : 'datetime'
                      }
                      isEventView={isEventView}
                      templateDisabled={!this.canEditTemplate()}
                      title={
                        selectedTemplate.name === undefined
                          ? templateName
                          : selectedTemplate.name
                      }
                    />
                  ) : (
                    <div>
                      <Recipients>
                        to:{' '}
                        {isEvent ? '@All' : (groups &&
                          groups
                            .map(group =>
                              group.label ? `${group.label}` : `@${group}`
                            )
                            .join(', '))}
                      </Recipients>
                      <br />
                      <Header midnight>
                        <span>{title}</span>
                      </Header>
                      <MessageDetails>
                        <span>
                          Delivery Method:{' '}
                          <MessageDetail id="deliveryMethod">
                            {dashToSpace(sendingMethods.join(', '))}
                          </MessageDetail>
                        </span>
                        <span>
                          Send Method: <MessageDetail id="sendMethod">{cronText}</MessageDetail> {isEvent && 'Event'}
                        </span>
                      </MessageDetails>
                    </div>
                  )}
                  <ButtonSet>
                    <FabButton
                      id="backButton"
                      color="primary"
                      background={theme.palette.secondary.yellow.solarFlare}
                      onClick={this.goBack}
                      component={() => (
                        <KeyboardBackspace
                          style={{ color: theme.palette.primary.dark }}
                          height="0.5em"
                          width="0.5em"
                        />
                      )}
                    />
                    {(isFuture || isEventView) && (
                      <React.Fragment>
                        <FabButton
                          onClick={
                            showEditMode
                              ? this.saveGroupMessageChanges
                              : this.editGroupMessage
                          }
                          variant="fab"
                          background="transparent"
                          outlined
                          component={() =>
                            showEditMode ? (
                              <CommonIcon
                                name="tick"
                                width="1em"
                                height="1em"
                                strokeWidth={2}
                                stroke={theme.palette.primary.navy.midnight}
                              />
                            ) : (
                              <CommonIcon
                                name="edit"
                                width="1em"
                                height="1em"
                                fill={theme.palette.primary.navy.midnight}
                              />
                            )
                          }
                        />
                        <FabButton
                          onClick={this.openDialog}
                          variant="fab"
                          background="transparent"
                          outlined
                          component={() => (
                            <CommonIcon
                              name="close"
                              width="0.5em"
                              height="0.5em"
                              strokeWidth={5}
                              stroke={theme.palette.primary.navy.midnight}
                            />
                          )}
                        />
                      </React.Fragment>
                    )}
                  </ButtonSet>
                </div>
              </HeadBackground>
            </FlexItem>
            <FlexItem flex={1}>
              <Body showEditMode={showEditMode}>
                <Grid>
                  <GridItem columnStart={1} columnSpan={12} rowStart={1}>
                    {!showEditMode ? (
                      <React.Fragment>
                        <Avatar
                          alt="image"
                          size="4em"
                          picture={senderPicture}
                        />
                        <SenderName id="senderName">{senderName}</SenderName>
                        <SentDateTime id="sentDateTime">{sentDateTime}</SentDateTime>
                      </React.Fragment>
                    ) : (
                      <TabContent
                        currentTab={currentTab}
                        selectedDeliveryMethods={
                          selectedDeliveryMethods || sendingMethods
                        }
                        theme={theme}
                        chooseTemplate={this.chooseTemplate}
                        selectedTemplateId={
                          selectedTemplate.id === undefined
                            ? templateId
                            : selectedTemplate.id
                        }
                        selectedSendMethod={
                          selectedSendMethod
                            ? selectedSendMethod
                            : isEvent
                            ? 'event'
                            : 'datetime'
                        }
                        handleSelectSendMethod={this.handleSelectSendMethod}
                        templates={templates}
                        groupEventsDropDownOptions={groupEventsDropDownOptions}
                        dateTimeMoment={
                          selectedDateTime ? selectedDateTime : dateTimeMoment
                        }
                        handleSelectedDateTimeChange={
                          this.handleSelectedDateTimeChange
                        }
                        handleDeliveryMethodChange={
                          this.handleDeliveryMethodChange
                        }
                        offsetDays={offsetDays || event.offset}
                        offsetBeforeAfter={
                          offsetBeforeAfter || (event.offset < '0'
                            ? 'after'
                            : 'before')
                        }
                        handleOffsetDays={this.handleOffsetDays}
                        handleEmptyOffsetDays={this.handleEmptyOffsetDays}
                        handleOffsetBeforeAfter={this.handleOffsetBeforeAfter}
                        handleEventChange={this.handleEventChange}
                        selectedEventId={
                          selectedEventId || selectedEventId === 0 ? selectedEventId :
                          groupEvents.indexOf(event.eventType)
                        }
                        isEventView={isEventView}
                      />
                    )}
                    {showEditMode ? (
                      <MessageEditor
                        message={
                          selectedTemplate.message === undefined
                            ? { message: text, textMessage: textBody }
                            : {
                                message: selectedTemplate.message,
                                textMessage: selectedTemplate.textMessage
                              }
                        }
                        subjectLineText={
                          selectedTemplate.title === undefined
                            ? title
                            : selectedTemplate.title
                        }
                        templateDisabled={!this.canEditTemplate()}
                        replacementOptions={replacementOptions}
                        attachments={[]}
                        files={[]}
                        removeFile={() => console.log('remove file')}
                        handleChange={this.handleMessageChange}
                        handleEmailMessageChange={this.handleEmailMessageChange}
                        selectedDeliveryMethods={
                          selectedDeliveryMethods || sendingMethods
                        }
                        showChatOption={this.state.showChatOption}
                        disableAttachments
                      />
                    ) : (
                      <React.Fragment>
                        <Line />
                        <EmailTextTabs />
                        <div>
                          <MessageHeader>message</MessageHeader>
                          <div
                            id="messageTxt"
                            dangerouslySetInnerHTML={{
                              __html: showTextMessage ? textBody : text
                            }}
                          />
                        </div>
                        {attachmentsCount > 0 && (
                          <Attachment>
                            <span className="count">
                              {attachmentsCount} attachments
                            </span>
                            {Object.keys(attachments).map(attachment => (
                              <span key={shortid()}>{attachment}</span>
                            ))}
                          </Attachment>
                        )}
                      </React.Fragment>
                    )}
                    <br /> <br />
                    {!showEditMode && (
                      <DeliveryMethodBackground>
                        <FlexDirection fullHeight>
                          <FlexItem
                            displayFlex
                            flex={2.7}
                            direction={'column'}
                            flexCenter>
                            <DeliveryMethodTitle>
                              Delivery Method Breakdown
                            </DeliveryMethodTitle>
                            <DeliveryMethodText id="deliveryMethodDescr">
                              This message {isFuture ? 'will be' : 'was'} sent
                              via {sendingMethods.join(', ')}. Here is the
                              breakdown of how the message{' '}
                              {isFuture ? 'will be' : 'was'} sent:
                            </DeliveryMethodText>
                          </FlexItem>
                          <FlexItem flex={1.3}>
                            <ProgressCircles>
                              <span>
                                <CircleWithText
                                  elementId="text"
                                  data={''}
                                  size={70}
                                  dataCount={textCount}
                                  backgroundColor={({ theme }) =>
                                    theme.palette.primary.green.mint
                                  }
                                  textColor={({ theme }) =>
                                    theme.palette.primary.navy.midnight
                                  }
                                />
                                <CenteredText>Text</CenteredText>
                              </span>
                              <span>
                                <CircleWithText
                                  elementId="email"
                                  data={''}
                                  size={70}
                                  dataCount={emailCount}
                                  backgroundColor={({ theme }) =>
                                    theme.palette.primary.green.mint
                                  }
                                  textColor={({ theme }) =>
                                    theme.palette.primary.navy.midnight
                                  }
                                />
                                <CenteredText>Email</CenteredText>
                              </span>
                              <span>
                                <CircleWithText
                                  elementId="inApp"
                                  data={''}
                                  size={70}
                                  dataCount={inAppCount}
                                  backgroundColor={({ theme }) =>
                                    theme.palette.primary.green.mint
                                  }
                                  textColor={({ theme }) =>
                                    theme.palette.primary.navy.midnight
                                  }
                                />
                                <CenteredText>In App</CenteredText>
                              </span>
                            </ProgressCircles>
                          </FlexItem>
                        </FlexDirection>
                      </DeliveryMethodBackground>
                    )}
                  </GridItem>
                  {!showEditMode && (
                    <GridItem columnStart={1} columnSpan={12}>
                      <br />
                      <RecipientsTable
                        total={isFuture ? items.length : total}
                        fetchPage={fetchPage}
                        searchField={() => (
                          <TextField
                            id="recipientSearch"
                            InputProps={{
                              disableUnderline: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon
                                    name="search"
                                    height="20px"
                                    width="18px"
                                    onClick={this.searchRecipient}
                                    className="searchIcon"
                                  />
                                </InputAdornment>
                              )
                            }}
                            onChange={this.handleSearchChange}
                            onKeyPress={this.handleKeyPress}
                          />
                        )}
                        items={items}
                        perPage={isFuture ? items.length : perPage}
                        currentPage={currentPage}
                      />
                    </GridItem>
                  )}
                  <Break />
                </Grid>
              </Body>
            </FlexItem>
            <Break />
          </FlexDirection>
        </Wrapper>
        <AlertModal
          title={'Are you sure you wish to delete?'}
          buttons={[
            {
              onClick: this.deleteGroupMessage,
              text: 'YES'
            },
            {
              onClick: this.closeAlertModal,
              color: theme.palette.primary.navy.spaceBlue,
              text: 'NO'
            }
          ]}
          show={this.state.showDialog}
          onClose={this.closeAlertModal}
        />
        <ScrollToTop />
      </React.Fragment>
    )
  }
}

export default withTheme(SingleGroupMessage)
