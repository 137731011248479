// @flow
import { connect } from 'react-redux'
import { fetchGroups, searchGroups } from 'zego-shared/store/groupMessaging/groups'
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {
  hasGroups,
  getGroups,
  getGroupsPaginationInfo,
  getAllGroupResidentsCount
} from 'zego-shared/store/groupMessaging/groups/selectors'
import { getUserRole } from 'zego-shared/store/authorization/selectors'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import Groups from './Groups'

const fetchingSelector = createIsFetchingSelector(['groups'])
const noAtSign = true

const mapStateToProps = (
  state,
  { history, match: { params: { propertyId } } }
) => ({
  data: getGroups(state, noAtSign),
  residentCount: getAllGroupResidentsCount(state),
  isFetching: fetchingSelector(state),
  hasData: hasGroups(state),
  ...getGroupsPaginationInfo(state),
  userRole: getUserRole(state),
  propertyId,
  history
})

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  return {
    fetchData: () => dispatch(fetchGroups(params.propertyId)),
    fetchPage: page => dispatch(fetchGroups(params.propertyId, page)),
    searchGroups: searchTerm => {
      dispatch(searchGroups(params.propertyId, 1, searchTerm))
    },
    displayErrorMessage: (message) => dispatch(showSnackbar(message, 'error')),
  }
}

const GroupsWithFetchData = withFetchData(Groups)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupsWithFetchData)
