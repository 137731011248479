// @flow

import styled from '@emotion/styled'
import MaterialIconButton from '@material-ui/core/IconButton'
import Flex from '@Common/Flex'
import Button from '@Common/Button'
import ReactSelect from 'react-select'

export const Wrapper = styled('div')`
  width: 500px;
  max-height: 750px;
  border-radius: 8px 8px 0 0;
  position: fixed;
  left: auto;
  top: 0;
  right: 20px;
  margin: 0;
  height: 95%;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  background: ${({ theme }) => theme.palette.common.white};
  z-index: 1000;
`

export const Select = styled(ReactSelect)`
  & > div {
    border-radius: 19px;
    &:hover {
      border-color: #ccc;
      box-shadow: 0 0 1px #ccc;
    }
    & > div {
      padding-left: 20px;
    }
    & > options {
      &:isfocused {
        color: red;
      }
    }
  }
`
export const Header = styled(Flex)`
  background: ${({ theme }) => theme.palette.primary.green.seeFoam};
  color: ${({ theme }) => theme.palette.common.white};
  height: 44px;
  border-radius: 8px 8px 0 0;
  padding: 5px ;
`
export const Content = styled('div')`
  padding: 10px 20px;
`

export const SubmitButton = styled(Button)`
  height: 49px;
  width: 59px;
  border-radius: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.neutrals.mediumGray};
  backgroundColor: ${({ theme }) => theme.palette.common.white}
  &:hover {
    background: ${({ home, theme }) => !home && theme.palette.neutrals.mediumGray};
    box-shadow: none;
  }
`

export const CancelButton = styled(Button)`
  height: 49px;
  width: 59px;
  border-radius: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.neutrals.mediumGray};
  backgroundColor: ${({ theme }) => theme.palette.common.white}
  &:hover {
    background: ${({ home, theme }) => !home && theme.palette.neutrals.mediumGray};
    box-shadow: none;
  }
`

export const IconButton = styled(MaterialIconButton)`
  background-color: ${({ theme }) => theme.palette.primary.green.hintMint};
`