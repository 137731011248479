import { call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken } from '../../../authorization/selectors'
import {
  fetchUsers as fetchUsersAction,
  refreshUsers as refreshUsersAction,
  fetchUsersStart,
  refreshUsersStart,
  fetchUsersSuccess,
  refreshUsersSuccess,
  fetchUsersError,
  refreshUsersError,
  fetchResidentUsersAction,
  fetchResidentUsersError,
  fetchResidentUsersStart,
  fetchResidentUsersSuccess,
  fetchNextResidentUsersAction,
  fetchNextResidentUsersStart,
  fetchNextResidentUsersError,
  fetchNextResidentUsersSuccess,
  fetchResidentSummaryAction,
  fetchResidentSummaryError,
  fetchResidentSummaryStart,
  fetchResidentSummarySuccess,
  fetchResidentManagementSurveysAction,
  fetchResidentManagementSurveysStart,
  fetchResidentManagementSurveysSuccess,
  fetchResidentManagementSurveysError,
  fetchNextResidentManagementSurveysAction,
  fetchNextResidentManagementSurveysStart,
  fetchNextResidentManagementSurveysSuccess,
  fetchNextResidentManagementSurveysError,
  setSearchTerm,
  updateUserLeaseInterestStatusAction,
  updateUserLeaseInterestStatusPropertySuccess,
  updateUserLeaseInterestStatusPropertyFailure,
  UPDATE_USER_LEASE_INTEREST_STATUS_PROPERTY_FAILURE

} from '../actions'
import api from '../../../../utils/api'
import log from '../../../../utils/logger'
import { getPropertyId } from '../../../select/selectors'

const getUsersActions = isRefreshing => ({
  start: isRefreshing ? refreshUsersStart : fetchUsersStart,
  success: isRefreshing ? refreshUsersSuccess : fetchUsersSuccess,
  error: isRefreshing ? refreshUsersError : fetchUsersError
})

export function* fetchPropertyUsers(isRefreshing, { id, name }) {
  const actions = getUsersActions(isRefreshing)
  try {
    yield put(actions.start())
    const authToken = yield select(getToken)
    const propertyID = id ? id : yield select(getPropertyId)

    if (propertyID === -1) throw new Error(noUnitSelected)

    const response = yield call(api.getPropertyUsers, propertyID, authToken, name)
    yield put(actions.success(response))
  } catch (error) {
    yield put(actions.error(error))
    log(`Failed to property users`)
  }
}

export function* fetchResidentUsers({ id, page, name, params }) {
  try {
    yield put(fetchResidentUsersStart())
    const authToken = yield select(getToken)
    const propertyID = id ? id : yield select(getPropertyId)

    if (propertyID === -1) throw new Error(noUnitSelected)
    if(name) yield put(setSearchTerm(name))

    const response = yield call(api.getPropertyResidents, propertyID, authToken, page, name, params)
    yield put(fetchResidentUsersSuccess(response))
  } catch (error) {
    yield put(fetchResidentUsersError(error))
    log(`Failed to fetch residents`)
  }
}

export function* fetchNextResidentUsers({ id, page, name, params }) {
  try {
    yield put(fetchNextResidentUsersStart())
    const authToken = yield select(getToken)
    const propertyID = id ? id : yield select(getPropertyId)

    if (propertyID === -1) throw new Error(noUnitSelected)
    if(name) yield put(setSearchTerm(name))

    const response = yield call(api.getPropertyResidents, propertyID, authToken, page, name, params)
    yield put(fetchNextResidentUsersSuccess(response))
  } catch (error) {
    yield put(fetchNextResidentUsersError(error))
    log(`Failed to fetch residents`)
  }
}

export function* fetchResidentSummary({ id }) {
  try {
    yield put(fetchResidentSummaryStart())
    const authToken = yield select(getToken)
    const propertyId = id ? id : yield select(getPropertyId)

    const response = yield call(api.getResidentSummary, propertyId, authToken)
    yield put(fetchResidentSummarySuccess(response))
  } catch (error) {
    yield put(fetchResidentSummaryError(error))
    log('Failed to fetch resident summary')
  }
}

export function* fetchResidentManagementSurveys({ id, page, params }) {
  try {
    yield put(fetchResidentManagementSurveysStart())
    const authToken = yield select(getToken)
    const propertyID = id ? id : yield select(getPropertyId)

    if (propertyID === -1) throw new Error(noUnitSelected)

    const response = yield call(api.getResidentManagementSurveys, propertyID, authToken, page, params)
    yield put(fetchResidentManagementSurveysSuccess(response))
  } catch (error) {
    yield put(fetchResidentManagementSurveysError(error))
    log(`Failed to fetch resident management surveys`)
  }
}

export function* fetchNextResidentManagementSurveys({ id, page, params }) {
  try {
    yield put(fetchNextResidentManagementSurveysStart())
    const authToken = yield select(getToken)
    const propertyID = id ? id : yield select(getPropertyId)

    if (propertyID === -1) throw new Error(noUnitSelected)

    const response = yield call(api.getResidentManagementSurveys, propertyID, authToken, page, params)
    yield put(fetchNextResidentManagementSurveysSuccess(response))
  } catch (error) {
    yield put(fetchNextResidentManagementSurveysError(error))
    log(`Failed to fetch resident management surveys`)
  }
}

function* watchFetchPropertyUsers() {
  yield takeLatest(fetchUsersAction().type, fetchPropertyUsers, false)
}

export function* watchFetchResidentUsers() {
  yield takeLatest(fetchResidentUsersAction().type, fetchResidentUsers)
}

export function* watchFetchNextResidentUsers() {
  yield takeLatest(fetchNextResidentUsersAction().type, fetchNextResidentUsers)
}

export function* watchFetchResidentSummary() {
  yield takeLatest(fetchResidentSummaryAction().type, fetchResidentSummary)
}

export function* watchFetchResidentManagementSurveys() {
  yield takeLatest(fetchResidentManagementSurveysAction().type, fetchResidentManagementSurveys)
}

export function* watchFetchNextResidentManagementSurveys() {
  yield takeLatest(fetchNextResidentManagementSurveysAction().type, fetchNextResidentManagementSurveys)
}

function* watchUpdateLeaseInterestStatus() {
  yield takeLatest(
    updateUserLeaseInterestStatusAction().type,
    updateUserLeaseInterestStatusProperty
  )
}

export function* updateUserLeaseInterestStatusProperty({ userId, status }) {
  try {
    const authToken = yield select(getToken)
    const response = yield call(
      api.updateLeaseInterestStatus,
      userId,
      status,
      authToken
    )
    yield put(updateUserLeaseInterestStatusPropertySuccess(userId, response))
  } catch (error) {
    yield put(updateUserLeaseInterestStatusPropertyFailure())
    log(`Failed to update user lease interest status`)
  }
}

export default [watchFetchPropertyUsers(), 
  watchFetchResidentUsers(), 
  watchFetchNextResidentUsers(), 
  watchFetchResidentSummary(), 
  watchFetchResidentManagementSurveys(), 
  watchFetchNextResidentManagementSurveys(),
  watchUpdateLeaseInterestStatus()
]
