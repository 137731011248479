import isPlainObject from 'lodash/isPlainObject'
import { call, select, takeLatest, put } from 'redux-saga/effects'
import { hasChanges } from '../../../utils/object'
import { getToken } from '../../authorization/selectors'
import { getPropertyId } from '../../select/selectors'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import { showSnackbar } from '../../snackbar/actions'
import { failedToUpdateVehicle } from '../../../utils/messages'
import {
  fetchVehiclesAction,
  fetchVehiclesStart,
  fetchVehiclesSuccess,
  fetchVehiclesError,
  fetchVehicleAction,
  fetchVehicleStart,
  fetchVehicleSuccess,
  fetchVehicleError,
  fetchVehiclesColorsAction,
  fetchVehiclesColorsStart,
  fetchVehiclesColorsSuccess,
  fetchVehiclesColorsError,
  fetchVehiclesStatesAction,
  fetchVehiclesStatesStart,
  fetchVehiclesStatesSuccess,
  fetchVehiclesStatesError,
  fetchVehiclesTypesAction,
  fetchVehiclesTypesStart,
  fetchVehiclesTypesSuccess,
  fetchVehiclesTypesError,
  updateVehicleOptimistically,
  updateVehicleRevert,
  updateVehicleSuccess,
  fetchVehiclesMakesStart,
  fetchVehiclesMakesAction,
  fetchVehiclesMakesSuccess,
  fetchVehiclesMakesError,
  UPDATE_VEHICLE
} from './actions'
import { getVehicle, vehicleForApi } from './selectors'

export function* fetchVehicles({ propertyId, page, searchTerm, queryParams }) {
  try {
    yield put(fetchVehiclesStart())
    const authToken = yield select(getToken)
    let response
    if (searchTerm != null) {
      response = yield call(
        api.searchRegisteredVehiclesOfProperty,
        authToken,
        propertyId,
        searchTerm,
        queryParams
      )
    } else {
      response = yield call(
        api.getRegisteredVehiclesOfProperty,
        authToken,
        propertyId,
        page,
        queryParams
      )
    }

    yield put(fetchVehiclesSuccess(response))
  } catch (error) {
    yield put(fetchVehiclesError())
    log(`Failed to fetch vehicles registration`)
  }
}

export function* fetchVehicle({ vehicleId }) {
  try {
    const propertyId = yield select(getPropertyId)
    yield put(fetchVehicleStart())
    const authToken = yield select(getToken)
    const response = yield call(api.getVehicle, authToken, propertyId, vehicleId)

    yield put(fetchVehicleSuccess(response))
  } catch (error) {
    yield put(fetchVehicleError(error))
    log(`Failed to fetch vehicle`)
  }
}

export function* fetchVehiclesColors({ propertyId }) {
  try {
    yield put(fetchVehiclesColorsStart())
    const authToken = yield select(getToken)
    const response = yield call(
      api.getVehiclesSupportedColors,
      authToken,
      propertyId
    )

    yield put(fetchVehiclesColorsSuccess(response))
  } catch (error) {
    yield put(fetchVehiclesColorsError(error))
    log(`Failed to fetch vehicles colors`)
  }
}

export function* fetchVehiclesStates({ propertyId }) {
  try {
    yield put(fetchVehiclesStatesStart())
    const authToken = yield select(getToken)
    const response = yield call(
      api.getVehiclesSupportedStates,
      authToken,
      propertyId
    )

    yield put(fetchVehiclesStatesSuccess(response))
  } catch (error) {
    yield put(fetchVehiclesStatesError(error))
    log(`Failed to fetch vehicles states`)
  }
}

export function* fetchVehiclesMakes({ propertyId }) {
  try {
    yield put(fetchVehiclesMakesStart())
    const authToken = yield select(getToken)
    const response = yield call(
      api.getVehiclesCommonMakes,
      authToken,
      propertyId
    )

    yield put(fetchVehiclesMakesSuccess(response))
  } catch (error) {
    yield put(fetchVehiclesMakesError(error))
    log(`Failed to fetch vehicles makes`)
  }
}

export function* fetchVehiclesTypes({ propertyId }) {
  try {
    yield put(fetchVehiclesTypesStart())
    const authToken = yield select(getToken)
    const response = yield call(
      api.getVehiclesSupportedTypes,
      authToken,
      propertyId
    )

    yield put(fetchVehiclesTypesSuccess(response))
  } catch (error) {
    yield put(fetchVehiclesTypesError(error))
    log(`Failed to fetch vehicles types`)
  }
}

export function* updateVehicle({ update: newAtts, history, url }) {
  const currentState = yield select(getVehicle)
  const propertyId = yield select(getPropertyId)
  let currentAtts = {}
  let attsToUpdate = {}
  for (let key in newAtts) {
    if (
      (!isPlainObject(newAtts[key]) && newAtts[key] !== currentState[key]) ||
      (isPlainObject(newAtts[key]) &&
        hasChanges(newAtts[key], currentState[key]))
    ) {
      attsToUpdate[key] = newAtts[key]
      currentAtts[key] = currentState[key]
    }
  }
  if (Object.keys(attsToUpdate).length > 0) {
    const authToken = yield select(getToken)
    try {
      yield put(updateVehicleOptimistically(vehicleForApi(newAtts)))
      let response
      if (currentState.id == null) {
        response = yield call(
          api.createVehicle,
          authToken,
          propertyId,
          vehicleForApi(attsToUpdate)
        )
      } else {
        response = yield call(
          api.updateVehicle,
          authToken,
          propertyId,
          currentState.id,
          vehicleForApi(attsToUpdate)
        )
      }

      yield put(updateVehicleSuccess(response))
      const addMode = url.indexOf('/new') > -1
      if (addMode && response.id != null) {
        const urlPrefix = url.split('/new')[0]
        history.push(`${urlPrefix}/${response.id}`)
      }
    } catch (error) {
      log(`Failed to update vehicle. Error: ${error}`)
      yield put(updateVehicleRevert(vehicleForApi(currentState)))
      yield put(showSnackbar(failedToUpdateVehicle, 'error'))
    }
  }
}

function* watchUpdateVehicle() {
  yield takeLatest(UPDATE_VEHICLE, updateVehicle)
}

function* watchFetchVehicles() {
  yield takeLatest(fetchVehiclesAction().type, fetchVehicles)
}
function* watchFetchVehicle() {
  yield takeLatest(fetchVehicleAction().type, fetchVehicle)
}
function* watchFetchVehiclesColors() {
  yield takeLatest(fetchVehiclesColorsAction().type, fetchVehiclesColors)
}
function* watchFetchVehiclesStates() {
  yield takeLatest(fetchVehiclesStatesAction().type, fetchVehiclesStates)
}
function* watchFetchVehiclesTypes() {
  yield takeLatest(fetchVehiclesTypesAction().type, fetchVehiclesTypes)
}

function* watchFetchVehiclesMakes() {
  yield takeLatest(fetchVehiclesMakesAction().type, fetchVehiclesMakes)
}

export default [
  watchFetchVehicles(),
  watchFetchVehicle(),
  watchFetchVehiclesColors(),
  watchFetchVehiclesStates(),
  watchFetchVehiclesTypes(),
  watchFetchVehiclesMakes(),
  watchUpdateVehicle()
]
