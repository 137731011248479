import { css } from 'emotion'
import styled from '@emotion/styled'
import { Typography, Card, FormControl, IconButton } from '@material-ui/core'
import Button from '@Common/Button/FabButton'
import ReactSelect from 'react-select'

export const WorkOrderCard = styled(Card)`
  padding: 75px 250px;
  margin-left: 250px;
  margin-top: 16px;

  &:before {
    content: ' ';
    background: ${({ theme }) => theme.palette.neutrals.lightGray};
    width: 100%;
    height: 350px;
    position: absolute;
    display: block;
    left: 0;
    margin-top: 33px;
    z-index: -1;
  }
`

export const SwitchControl = styled(FormControl)`
  display: flex;
  flex-direction: row;
  padding-left: 10px;

  label {
    flex: 1 0 auto;
    position: initial;
  }

  div {
    margin-right: -10px;
    margin-top: 0.6em;
  }
`

export const PageContent = styled('div')`
  padding-top: 16px;
  padding-left: 0;
  grid-column: 1 / span 12;
  grid-row: 1 / span 8;
`

export const BackButton = styled(Button)`
  min-width: 2.7em;
  min-height: 2.7em;
  width: 2.7em;
  height: 2.7em;
  margin-bottom: 7px;
  svg {
    fill: ${({ theme }) => theme.palette.primary.dark};
  }
`

export const PhotoNavigation = styled(IconButton)`
  position: absolute; 
  background-color: ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
  width: 2.7em;
  height: 2.7em;
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
 }
`

export const DropBox = styled('div')`
  width: 300px;
  height: 300px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23979797FF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='27' stroke-linecap='butt'/%3e%3c/svg%3e");
`

export const CaptionHeading = styled(Typography)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.grey.main};
`

export const StyledSelect = styled(ReactSelect)`
  margin-bottom: 1em;
`

export const DeletePhotoButton = styled(IconButton)`
  border-radius: 0;
  margin: 0; 
  background-color: ${({ theme }) => theme.palette.primary.main};
  min-width: 0px;
  width: 45px;
  height: 45px;
`
export const PhotoUploadDiv = styled('div')`
  width: 300px; 
  position: absolute; 
  top: 325px;
  left: 215px;
`

export const AlertModalClass = css`
  padding: 1em 1em 2em 1em !important;
  width: 300px !important;
  & h2 {
    font-size: medium;
  }
`
