import get from 'lodash/get'
import { getProfileUrlOrDefault } from 'zego-shared/utils/profilePicture'
import { getPaginationData } from 'zego-shared/store/helpers/selectors/data'
import { formatPhoneNumber } from 'zego-shared/utils/string'

import { getDate } from '../../../utils/dateUtils'

const getFullName = (firstName, lastName) =>
  `${firstName || ''} ${lastName || ''}`

const getDateString = date => (date ? getDate(date) : '')

const visitorToVisitorsData = visitor => {
  return {
    id: visitor.visitor_id,
    visitorName: getFullName(
      visitor.visitor_first_name,
      visitor.visitor_last_name
    ),
    visitingName: getFullName(
      visitor.resident_first_name,
      visitor.resident_last_name
    ),
    unit: visitor.unit_name,
    startDate: getDateString(visitor.start_date),
    endDate: getDateString(visitor.end_date),
    phoneNumber: formatPhoneNumber(visitor.visitor_phone || '')
  }
}

export const visitorToVisitorData = visitor => {
  const details = get(visitor, ['details'], {})
  return {
    id: visitor.visitor_id || '',
    visitorName: getFullName(
      visitor.visitor_first_name,
      visitor.visitor_last_name
    ),
    visitingName: getFullName(
      visitor.resident_first_name,
      visitor.resident_last_name
    ),
    unit: visitor.unit_name || '',
    startDate: getDateString(visitor.start_date),
    endDate: getDateString(visitor.end_date),
    phoneNumber: formatPhoneNumber(visitor.visitor_phone || ''),
    picture: getProfileUrlOrDefault(details.photo, visitor.visitor_first_name),
    description: details.description || '',
    submittedDate: getDateString(visitor.inserted_at)
  }
}

export const getVisitor = (state, addMode) => {
  const visitor = addMode ? {} : get(state, ['registration', 'visitor']) || {}
  return visitorToVisitorData(visitor)
}

export const getVisitors = state => {
  const visitors = get(state, ['registration', 'visitors', 'data'], [])
  return visitors.map(visitorToVisitorsData)
}

export const hasVisitors = state => {
  return get(state, ['registration', 'visitors']) !== null
}

export const getVisitorsPaginationInfo = state =>
  getPaginationData(state, ['registration', 'visitors'])
