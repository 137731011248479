import { SHOW_SNACK_BAR, HIDE_SNACK_BAR } from '../actions'

const InitialState = {
  message: '',
  show: false,
  variant: ''
}

const snackbar = (state = InitialState, action) => {
  switch (action.type) {
    case SHOW_SNACK_BAR:
      return {
        ...state,
        message: action.message,
        show: true,
        variant: action.variant || 'info'
      }
    case HIDE_SNACK_BAR:
      return {
        ...state,
        message: '',
        show: false
      }
    default:
      return state
  }
}

export default snackbar
