import React from 'react'
import { Route } from 'react-router-dom'
import MimoChecklistSummaryContainer from '../../views/UserManagement/UserProfile/MimoChecklistSummary/MimoChecklistSummaryContainer'
import GeneralInfoContainer from '../../views/UserManagement/UserProfile/GeneralInfo/GeneralInfoContainer'
import LeaseInfoContainer from '../../views/UserManagement/UserProfile/LeaseInfo/LeaseInfoContainer'
import SurveysContainer from '../../views/UserManagement/UserProfile/Surveys/SurveysContainer'

const ResidentProfileTabRoutes = () => (
  <React.Fragment>
    <Route
      exact
      path="/properties/:propertyId/residents/profile/:userId"
      component={GeneralInfoContainer}
    />
    <Route
      exact
      path="/properties/:propertyId/residents/profile/:userId/general"
      component={GeneralInfoContainer}
    />
    <Route
      path="/properties/:propertyId/residents/profile/:userId/lease"
      component={LeaseInfoContainer}
    />
    <Route
      path="/properties/:propertyId/residents/profile/:userId/summary"
      component={MimoChecklistSummaryContainer}
    />
    <Route
      path="/properties/:propertyId/residents/profile/:userId/survey"
      component={SurveysContainer}
    />
  </React.Fragment>
)

export default ResidentProfileTabRoutes
