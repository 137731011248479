// @flow
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'emotion-theming'
import Flex, { FlexItem } from '@Common/Flex'
import { GridItem } from '@Common/Grid'
import FabButton from '@Common/Button/FabButton'
import { CommonIcon } from '@icons'
import TextField from '@Common/TextField'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import {
  CheckListSettingContainerLeft,
  CheckListSettingContainerRight,
  InfoText,
  CustomCard,
  ButtonContainer,
  StyledSelect,
  StyledTitle,
  Underline,
  Label,
  Header,
  CustomBadge,
  UploadButtonContainer,
  UploadButton,
  FileLink,
  ErrorText,
  ClosedIcon,
  ErrorTextButton,
  Spacing,
  ErrorTextContainer,
  TermsText,
  ModalTitle,
  SelectStyle,
  StyledButton,
  StyledSaveButton
} from './styles'
import AlertModal from '@Common/AlertModal/AlertModal'
import FileUpload from '@Common/FileUpload'
import {
  fetchMimoChecklistSettingsAction,
  fetchSavedMimoChecklistSettingsAction
} from 'zego-shared/store/moveInMoveOut/actions'
import { getIsMimoChecklistSubmitting, getIsLoadingMimoChecklistSetting, getSavedMimoChecklistSettings, getIsSuccessMimoChecklistSetting } from 'zego-shared/store/moveInMoveOut/selectors'
import Loader from '@Common/Loader'
import { getPropertyId } from 'zego-shared/store/select/selectors'

export const mapStateToProps = (state: Object) => ({
  isSubmitting: getIsMimoChecklistSubmitting(state),
  isLoading: getIsLoadingMimoChecklistSetting(state),
  savedChecklist: getSavedMimoChecklistSettings(state),
  isSubmitSuccess: getIsSuccessMimoChecklistSetting(state),
  propertyId: getPropertyId(state)
})

export const mapDispatchToProps = (dispatch: Function) => ({
  fetchChecklistSettings: () => {
    dispatch(fetchSavedMimoChecklistSettingsAction())
  },
  showErrorToast: () => dispatch(showSnackbar('Please fix the fields with missing or incorrect information.', 'error')),
  submitCheckllistSettings: (settingsData: Object) => {
    dispatch(fetchMimoChecklistSettingsAction(settingsData))
  }
})

const ResidentMoveinMoveoutChecklistSettings = (props) => {
  const {
    theme,
    setShowSettings,
    showErrorToast,
    submitCheckllistSettings,
    isSubmitting,
    isLoading,
    isSubmitSuccess,
    savedChecklist,
    propertyId,
    fetchChecklistSettings
  } = props
  const [showModal, setShowModal] = useState(false)
  const DisclosureMaxLength = 250

  const isMoveOut = false
  const [moveoutDaysAfter, setMoveoutDaysAfter] = useState('')
  const [moveinDaysAfter, setMoveinDaysAfter] = useState('')
  const [termsFile, setTermsFile] = useState(null)
  const [tempTermsFile, setTempTermsFile] = useState(null)
  const [disclosure, setDisclosure] = useState('')
  const [moveOutEvent, setMoveOutEvent] = useState('')
  const [isErrors, showErrors] = useState()
  const [fileUploadError, setFileUploadError] = useState('')
  const fileSizeLimit = 10485760

  const moveOutOptions = [
    {
      label: "Move-in Date",
      value: "Move-in Date"
    },
    {
      label: "Lease Start Date",
      value: "Lease Start Date"
    }
  ]
  const [moveInEvent, setMoveInEvent] = useState('')
  const moveInOptions = [
    {
      label: "Move-in Date",
      value: "Move-in Date"
    },
    {
      label: "Lease Start Date",
      value: "Lease Start Date"
    }
  ]

  useEffect(() => {
    let selectedEvent = moveInOptions.find(x => x.value === savedChecklist.event)
    setMoveInEvent(selectedEvent)
    setMoveinDaysAfter(savedChecklist.daysAfter)
    setTermsFile({ name: savedChecklist.fileName })
    if (savedChecklist.disclosure) setDisclosure(savedChecklist.disclosure)
    // eslint-disable-next-line
  }, [savedChecklist])

  useEffect(() => {
    setMoveInEvent('')
    setMoveinDaysAfter('')
    setDisclosure('')
    fetchChecklistSettings()
    // eslint-disable-next-line
  }, [propertyId])

  const updateDisclosure = (target) => {
    if (target.value.length > DisclosureMaxLength) {
      return
    }
    setDisclosure(target.value)
  }

  const validateDaysAfter = (value) => {
    setMoveinDaysAfter(value >= 0 ? value : '')
  }

  const RenderButtons = () => {
    return (
      <ButtonContainer>
        <FabButton
          onClick={() => setShowSettings(false)}
          background={theme.palette.secondary.yellow.solarFlare}
          styles={StyledButton}
          component={() => (
            <CommonIcon
              name="close"
              width="1.2em"
              height="1.2em"
              fill="none"
              strokeWidth={4}
              stroke={theme.palette.primary.dark}
            />
          )}
          size="2.7em"
        />
        <StyledSaveButton
          id="submitButton"
          onClick={() => onSave()}
          background={theme.palette.common.white}
          outlined=""
          disabled=""
          component={() => (
            <CommonIcon
              name="tick"
              width="1.2em"
              height="1.2em"
              fill="none"
              strokeWidth={4}
              stroke={theme.palette.primary.dark}
            />
          )}
          size="2.7em"
        />
      </ButtonContainer>
    )
  }

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const onSave = async () => {
    showErrors(true)
    if (!moveInEvent || !moveinDaysAfter || !termsFile || !termsFile.name) {
      return showErrorToast()
    }
    let fileContent = ''
    if (termsFile.path) {
      fileContent = await toBase64(termsFile)
      if (fileContent) {
        fileContent = fileContent.toString().replace('data:application/pdf;base64,', '')
      }
    }
    await submitCheckllistSettings({
      id: savedChecklist && savedChecklist.id ? savedChecklist.id : '',
      event: moveInEvent.value,
      daysAfter: parseInt(moveinDaysAfter),
      disclosure,
      fileName: termsFile.name,
      fileContent: fileContent
    })
  }

  useEffect(() => {
    if(!isLoading && isSubmitSuccess){
      setShowSettings(false)
    }
    // eslint-disable-next-line
  }, [!isLoading, isSubmitSuccess])

  const LeftSide = () => {
    return (
      <CheckListSettingContainerLeft>
        <Header >Move-in</Header>
        <InfoText >Checklist Due Date:</InfoText>
        <Flex direction="row" justifySpaceBetween marginBottom="1em">
          <FlexItem style={{ width: "60%" }} >
            <Label>Event</Label>
            <StyledSelect
              id="Event"
              onChange={(e) => setMoveInEvent(e)}
              value={moveInEvent}
              placeholder="Choose Event"
              options={moveInOptions}
              styles={SelectStyle(isErrors)}
              maxMenuHeight={250}
              components={{
                IndicatorSeparator: () => null
              }}
            />
            <ErrorText>{isErrors && !moveInEvent ? 'This Field is required' : ''}</ErrorText>
          </FlexItem>
          <FlexItem style={{ width: "30%" }} >
            <Label>Days After</Label>
            <TextField
              id="moveinDaysAfter"
              onChange={(e) => validateDaysAfter(parseInt(e.target.value))}
              value={moveinDaysAfter}
              style={{
                marginTop: '6px'
              }}
              showPencil={true}
              prefix={moveinDaysAfter ? null : "#"}
              error={isErrors && !moveinDaysAfter}
              type="number"
              min="1"
              editMode={true}
              textAlign="left"
              alwaysShowLabel={false}
              disabled={false}
            />
            <ErrorTextContainer>{isErrors && !moveinDaysAfter ? 'This Field is required' : ''}</ErrorTextContainer>
          </FlexItem>
        </Flex>
        <Spacing></Spacing>
        <Label style={{ float: 'left' }}>Disclosure (Optional)</Label>
        <CustomBadge>{DisclosureMaxLength - disclosure.length}</CustomBadge>
        <TextField
          id="disclosure"
          onChange={(e) => updateDisclosure(e.target)}
          style={{
            width: '100%',
            marginBottom: "20px",
          }}
          value={disclosure}
          showPencil={true}
          placeholder="Additional disclosure, upto 250 characters."
          editMode={true}
          textAlign="left"
          isEditor={true}
          alwaysShowLabel={true}
          disabled={false}
        />
        <Spacing></Spacing>
        <Spacing></Spacing>
        <TermsText>Terms and Conditions: </TermsText>
        {termsFile && termsFile.name ?
          <UploadButtonContainer>
            <FileLink>{termsFile.name}</FileLink>
            <ClosedIcon onClick={() => setTermsFile(null)}></ClosedIcon>
          </UploadButtonContainer>
          :
          <UploadButtonContainer>
            <UploadButton onClick={() => setShowModal(true)}>Upload</UploadButton>
            {isErrors && (!termsFile || !termsFile.name) &&
              <ErrorTextButton>An upload document is required</ErrorTextButton>
            }
          </UploadButtonContainer>}
      </CheckListSettingContainerLeft>
    )
  }

  const RightSide = () => {
    return (
      <CheckListSettingContainerRight >
        <Header >Move-Out</Header>
        <InfoText>CheckList Due Date:</InfoText>
        <Label>Event</Label>
        <StyledSelect
          id="Event"
          onChange={(e) => setMoveOutEvent(e)}
          value={moveOutEvent}
          width='50%'
          placeholder="Choose Event"
          options={moveOutOptions}
          styles={SelectStyle(isErrors)}
          maxMenuHeight={250}
          components={{
            IndicatorSeparator: () => null
          }}
        />

        {isErrors && !moveOutEvent &&
          <ErrorText>This Field is required</ErrorText>
        }
        <TextField
          id="moveinDaysAfter"
          onChange={(e) => setMoveoutDaysAfter(e.target.value)}
          style={{ width: '30%' }}
          value={moveoutDaysAfter}
          showPencil={true}
          label="Days After"
          prefix={moveoutDaysAfter ? null : "#"}
          type="number"
          editMode={true}
          textAlign="left"
          alwaysShowLabel={true}
          disabled={false}
        />

      </CheckListSettingContainerRight>
    )
  }

  const FileUploadAlert = () => {
    return <AlertModal
      title={<ModalTitle>Upload Terms & Conditions</ModalTitle>}
      buttons={[
        {
          onClick: () => {
            setTempTermsFile(null)
            setShowModal(false)
            setFileUploadError('')
          },
          text: 'Cancel',
        },
        {
          onClick: () => {
            if (tempTermsFile && tempTermsFile.path && !fileUploadError) {
              setTermsFile(tempTermsFile)
            }
            setShowModal(false)
            setTempTermsFile(null)
          },
          name: 'file',
          color: theme.palette.primary.navy.spaceBlue,
          disabled: !tempTermsFile || !tempTermsFile.path || fileUploadError,
          text: 'Upload',
        },
      ]}
      messageContainerWidth="100%"
      show={showModal}
      message={
        <Flex direction="column" fullWidth>
          <FlexItem flex={1} >
            <FileUpload
              modalType={'waiver'}
              uploadedFiles={tempTermsFile}
              onFileUpload={(files) => setTempTermsFile(files[0])}
              onFileUploadError={setFileUploadError}
              onFileDelete={() => setFileUploadError('')}
              fileSizeLimit={fileSizeLimit}
              fileType={['application/pdf']}
              fileUploadError={fileUploadError}
              fileExtension={['PDF']}
              maxCount={1}
              multiple={false}
            />
          </FlexItem>
          {fileUploadError && (
            <FlexItem flex={1} style={{
              color: theme.palette.alert.error,
              fontSize: '12px'
            }}>{fileUploadError}</FlexItem>
          )
          }
        </Flex>
      }
    />
  }
  if (isSubmitting) { return <Loader /> }

  const underlineText = () => {
    return (
      <React.Fragment>
        <StyledTitle>Checklist Settings</StyledTitle>
        <Underline></Underline>
      </React.Fragment>
    )
  }

  if (isSubmitting || isLoading) { return <Loader /> }

  return (
    <>
      <GridItem columnStart={1} columnSpan={12} rowStart={5} rowSpan={15}>
        <CustomCard>
          {underlineText()}
          {LeftSide()}
          {isMoveOut && RightSide()}
          {RenderButtons()}
          {FileUploadAlert()}
        </CustomCard>
      </GridItem>
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(ResidentMoveinMoveoutChecklistSettings))
