import { createFetchActions } from '../helpers/actions/creators'

export const PROPERTY_HUBS = 'PROPERTYHUBS'

export const {
  fetchAction: fetchPropertyHubsAction,
  fetchStartAction: fetchPropertyHubsStart,
  fetchSuccessAction: fetchPropertyHubsSuccess,
  fetchErrorAction: fetchPropertyHubsError
} = createFetchActions(PROPERTY_HUBS)

export const fetchPropertyHubs = (propertyId) => fetchPropertyHubsAction({ propertyId })
