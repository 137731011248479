// @flow

import React from 'react'
import ListItemText from '@material-ui/core/ListItemText'
import InfoCard from '../../Common/InfoCard'
import { ListItem } from './styles'
import Flex from '@Common/Flex'
import QuickLinks from '../../QuickLinks'

export type Props = {
  name: string,
  residentsCount: number,
  activeResidents: number,
  currentBalance: string
}

const UnitInfoCard = ({
  name,
  residentsCount,
  activeResidents,
  currentBalance
}: Props) => (
  <InfoCard
    infoHead="USER MANAGEMENT"
    title={`UNIT ${name}`}
    headerRight={<QuickLinks selected="user management" />}
    content={() => (
      <Flex direction="column">
        <ListItem>
          <ListItemText primary={`${residentsCount} Residents`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`${activeResidents} Active`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Current Balance - $${currentBalance || '0'}`} />
        </ListItem>
      </Flex>
    )}
  />
)

export default UnitInfoCard
