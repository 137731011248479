import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReSyncPmsButton from './ReSyncPmsButton'
import { getPropertyPmsStatus } from 'zego-shared/store/properties/actions'
import { hasPms, getPmsName } from 'zego-shared/store/summary/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import { getToken } from 'zego-shared/store/authorization/selectors'
import {
  reSyncSuccess,
  genericErrorMsg,
  reSyncInProgress
} from 'zego-shared/utils/messages'
import api from 'zego-shared/utils/api'

const mapStateToProps = state => {
  return {
    hasPms: hasPms(state),
    pmsName: getPmsName(state),
    userToken: getToken(state)
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { propertyId }
    }
  }
) => {
  return {
    fetchData: () => {
      dispatch(getPropertyPmsStatus())
    },
    reSyncProperty: async token => {
      try {
        await api.reSyncProperty(propertyId, token)
        dispatch(showSnackbar(reSyncSuccess, 'success'))
      } catch (error) {
        if (error.message === 'Rate Limit Exceeded') {
          dispatch(showSnackbar(reSyncInProgress, 'error'))
        } else {
          dispatch(showSnackbar(genericErrorMsg, 'error'))
        }
      }
    }
  }
}

const ReSyncPmsButtonWithFetchData = withFetchData(ReSyncPmsButton)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReSyncPmsButtonWithFetchData)
)
