// @flow
import get from 'lodash/get'
import { getSelectedProperty, getSelectedUnit } from '../../select/selectors'

export const getFullSchedule = (state: Object) => {
  const schedule = get(state, ['schedule']) || {}
  if (schedule.id != null) {
    return schedule
  }

  return get(getSelectedUnit(state), ['schedule']) || {}
}

export const getSelectedUnitScheduleState = (state: Object) => {
  const { schedule_state: scheduleState } = getSelectedUnit(state)
  return scheduleState
}

export const hasSchedule = (state: Object) => getSelectedUnitScheduleID(state) != null

export const getSelectedUnitScheduleID = (state: Object) => {
  const { schedule } = getSelectedUnit(state)
  return schedule == null ? null : schedule.id
}

export const getSelectedPropertyVacantScheduleID = (state: Object) => {
  const { schedule: vacantSchedule } = getSelectedProperty(state)
  if (vacantSchedule == null) return null
  return vacantSchedule.id
}

export const getScheduleInfo = (state: Object) => {
  let schedule = get(state, ['schedule']) || {}
  const unitScheduleId = getSelectedUnitScheduleID(state)

  if (
    schedule.id == null ||
    schedule.id !== unitScheduleId ||
    schedule.scheduleItems == null
  ) {
    schedule = get(getSelectedUnit(state), ['schedule']) || {}
  }

  const { id, name, enabled, schedule_items: scheduleItems } = schedule
  if (id == null) {
    return {}
  }
  const { Monday: scheduleItem } = scheduleItems
  const {
    home_thermostat_mode: homeThermostatMode,
    home_heat_setpoint: homeHeatSetpoint,
    home_cool_setpoint: homeCoolSetpoint,
    away_thermostat_mode: awayThermostatMode,
    away_heat_setpoint: awayHeatSetpoint,
    away_cool_setpoint: awayCoolSetpoint
  } = scheduleItem

  return {
    id,
    name,
    enabled,
    home: {
      thermostatMode: homeThermostatMode,
      heatSetpoint: homeHeatSetpoint,
      coolSetpoint: homeCoolSetpoint
    },
    away: {
      thermostatMode: awayThermostatMode,
      heatSetpoint: awayHeatSetpoint,
      coolSetpoint: awayCoolSetpoint
    }
  }
}

export const getSelectedPropertyVacantScheduleInfo = (state: Object) => {
  const { id, enabled, schedule_items: scheduleItems } = get(
    state,
    ['schedule'],
    {}
  )

  if (id == null) {
    return null
  }

  const { Monday: scheduleItem } = scheduleItems

  const {
    away_thermostat_mode: thermostatMode,
    away_heat_setpoint: heatSetpoint,
    away_cool_setpoint: coolSetpoint
  } = scheduleItem
  return {
    id,
    enabled,
    thermostatMode,
    heatSetpoint,
    coolSetpoint
  }
}
