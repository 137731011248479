// @flow

import get from 'lodash/get'
import moment from 'moment'
import { getPaginationData } from '../../helpers/selectors/data'
// $FlowDisableNextLine
import { getDateForGroupMessaging } from '../../../../admin/assets/src/utils/dateUtils'
import { humanizeString } from '../../../utils/string'
import find from 'lodash/find'

const getGroupName = ({ groups }) =>
  groups && groups.length > 0 ? `@${groups[0].name}` : 'No Group'

const getDateOrEvent = (futureItem: Object = {}) => {
  const { groups = [], run_time } = futureItem
  const event = groups.find((group) => group.type === 'event')
  if (event) {
    return humanizeString(event.user_selectors['1']?.field)
  }
  return getDateForGroupMessaging(moment(futureItem.run_time).format())
}

const isEvent = (futureItem: Object = {}): boolean => {
  const { groups = [] } = futureItem
  const event = groups.find((group) => group.type === 'event')
  return !!event
}

const getAllGroupCount = (state) => {
  const allGroup = state.groupMessaging.groups.data.find(
    (group) => group.name === 'All'
  )
  return allGroup.user_count
}

const buildFutureItem = (futureItem, state) => {
  return {
    id: futureItem.id,
    senderPicture: futureItem?.user?.picture,
    senderName: futureItem?.user?.name || '',
    title: futureItem?.template?.title,
    group: getGroupName(futureItem),
    recipientsCount: isEvent(futureItem) ? getAllGroupCount(state) : futureItem?.count,
    frequency: futureItem?.type,
    date: getDateOrEvent(futureItem),
    runTime: futureItem?.run_time,
    isEvent: isEvent(futureItem),
  }
}

export const getFuture = (state: Object) => {
  const future = get(state, ['groupMessaging', 'future', 'data'])
  return future ? future.map(item => buildFutureItem(item, state)) : null
}

export const getFutureSearchResults = (state: Object) => {
  const future = get(state, ['groupMessaging', 'future', 'searchData'])
  return future ? future.map(item => buildFutureItem(item, state)) : getFuture(state)
}

export const hasFuture = (state: Object) => {
  return get(state, ['groupMessaging', 'future', 'data']) != null
}

export const getFuturePaginationInfo = (state: Object) =>
  getPaginationData(state, ['groupMessaging', 'future'])
