// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import { withRouter } from 'react-router-dom'
import type { Match } from 'react-router-dom'
import Flex from '@Common/Flex'
import CircleWithText from '@Common/Cards/CircleWithText'
import type { BrowserHistory } from 'history'
import type { Theme } from '../../../theme'

type Props = {
  data: Object,
  history: BrowserHistory,
  match: Match,
  theme: Theme,
}

const UnitSmartCardContent = ({
  data: { hubName, hubCount, deviceCount, deviceName },
  history,
  theme,
  match: { params }
}: Props) => {
  return (
    <Flex fullHeight fullWidth>
      <Flex fullHeight fullWidth>
        <Flex flex={1} justifyCenter alignCenter>
          <div>
            <CircleWithText
              data={hubName}
              dataCount={hubCount}
              backgroundColor={({ theme }) => theme.palette.secondary.main1}
              textColor={({ theme }) => theme.palette.background.default}
              onClick={() =>
                params.propertyId && params.unitId
                  ? history.push(
                      `/properties/${params.propertyId}/units/${
                        params.unitId
                      }/smart/hub`
                    )
                  : undefined
              }
            />
          </div>
        </Flex>
        <Flex
          alignCenter
          fullWidth
          fullHeight
          flex={2}
          justifyCenter
          style={{
            background: theme.palette.neutrals.lightGray,
            marginRight: '-1.5em',
            marginTop: '0.1em'
          }}>
          <div>
            <CircleWithText
              data={deviceName}
              dataCount={deviceCount}
              backgroundColor={({ theme }) => theme.palette.primary.dark}
              textColor={({ theme }) => theme.palette.background.default}
              onClick={() =>
                params.propertyId && params.unitId
                  ? history.push(
                      `/properties/${params.propertyId}/units/${
                        params.unitId
                      }/smart/devices`
                    )
                  : undefined
              }
            />
          </div>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default withRouter(withTheme(UnitSmartCardContent))
