import { connect } from 'react-redux'
import withFetchData from 'zego-shared/views/withFetchData'
import NavigationMenu from './NavigationMenu'
import { getPropertyId } from 'zego-shared/store/select/selectors'
import { getPropertyAccessSettings } from 'zego-shared/store/settings/propertySettings/selectors'
import { getUnreadMessagesCount } from '../../../store/Chat/channelSelectors'
import { fetchUnreadMessagesCountAction } from 'zego-shared/store/chat/messages/actions'
import { ldInitRequest } from '../../../integrations/LaunchDarkly/actions'
import { getFeatureFlag } from '../../../integrations/LaunchDarkly/selectors'

const mapStateToProps = state => ({
  propertyId: getPropertyId(state),
  propertyAccessSettings: getPropertyAccessSettings(state),
  unreadStatus: getUnreadMessagesCount(state),
  featureFlag: getFeatureFlag(state)
})

const mapDispatchToProps = dispatch => {
  return {
    fetchData: () => {
      dispatch(fetchUnreadMessagesCountAction())
    },
    ldInitRequest
  }
}
const NavigationWithFetchData = withFetchData(NavigationMenu)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationWithFetchData)
