import { connect } from 'react-redux'
import PropertyNotifications from './PropertyNotifications'
import { updatePropertySetting } from 'zego-shared/store/settings/actions'
import { getPropertyNotificationSettings } from 'zego-shared/store/settings/selectors'

const mapStateToProps = state => ({
  settings: getPropertyNotificationSettings(state)
})

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { propertyId }
    }
  }
) => ({
  updateSetting: attributes => {
    dispatch(updatePropertySetting(propertyId, attributes))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyNotifications)
