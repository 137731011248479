import { all, call, select, takeLatest } from 'redux-saga/effects'
import { getPropertyId } from '../select/selectors'
import { noPropertySelected } from '../../utils/messages'
import log from '../../utils/logger'
import { FETCH_ALL_SETTINGS } from './actions'
import propertySettings, {
  fetchPropertySettings
} from './propertySettings/sagas'
import userSettings, { fetchUserSettings } from './userSettings/sagas'

export function* fetchAllSettings({ propertyId }) {
  try {
    propertyId = propertyId ? propertyId : yield select(getPropertyId)
    if (propertyId === -1) throw new Error(noPropertySelected)
    yield all({
      property: call(fetchPropertySettings, { propertyId }),
      user: call(fetchUserSettings)
    })
  } catch (error) {
    log(`Failed to fetch settings: ${error}`)
  }
}

function* watchFetchAllSettings() {
  yield takeLatest(FETCH_ALL_SETTINGS, fetchAllSettings)
}

export default [...propertySettings, ...userSettings, watchFetchAllSettings()]
