// @flow
import { connect } from 'react-redux'
import SmartDevice from './SmartDevice'
import { getDeviceData, getDeviceType } from '../../../store/Unit/selectors'
import {
  pushLockStateCommand,
  pushThermostatCommand,
  pushSwitchStateCommand,
  updateDeviceNameCommand
} from 'zego-shared/store/devices/actions'

const mapStateToProps = (state, { match: { params }, firstDeviceID }) => {
  const id = params.deviceID ? params.deviceID : firstDeviceID
  return {
    data: getDeviceData(state, id),
    type: getDeviceType(state, id),
    viewBatteryMode: params.deviceMode === 'battery',
    firstDeviceID
  }
}

const mapDispatchToProps = (dispatch, { match: { params }, firstDeviceID }) => {
  const id = params.deviceID ? params.deviceID : firstDeviceID
  return {
    pushLockStateCommand: (id, identifier) => {
      dispatch(pushLockStateCommand(id, identifier))
    },
    pushThermostatCommand: (commandType, value, id, identifier) => {
      dispatch(pushThermostatCommand(commandType, value, id, identifier))
    },
    pushSwitchStateCommand: (id, identifier) => {
      dispatch(pushSwitchStateCommand(id, identifier))
    },
    updateSwitchName: (name, prevName) => {
      dispatch(updateDeviceNameCommand(id, name, prevName))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartDevice)
