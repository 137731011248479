// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import Flex from '@Common/Flex'
import {
  StyledLink,
  StyledRowText,
  SeparationLine,
  ResidentHappinessCard,
  CardHeader,
  ResidentHappinessContent,
  StyledGreyRow,
  StyledBorderButton,
  BoldInfo,
  RowItem
} from '../styles'

const LeaseRenewDetailsCard = (props) => {
  const { initial, propertyId } = props
  const communicationInfo = (type) => {
    switch (type) {
      case 'initial':
      case 'follow_up':
        return props[type].period
          ? `${props[type].period} days prior to lease end date`
          : 'Not Defined'
      case 'sending_methods':
        return initial.sending_methods.length
          ? initial.sending_methods.join(' | ')
          : 'Not Defined'
      default:
        return 'Not Defined'
    }
  }

  const generateEditLink = (linkType) => {
    return props[linkType].id
      ? `/properties/${propertyId}/communication/group-messaging/templates/${props[linkType].id}`
      : `/properties/${propertyId}/communication/group-messaging/create`
  }

  return (
    <ResidentHappinessCard>
      <Flex alignCenter justifySpaceBetween direction="row">
        <CardHeader blackText small>Lease Renewal Details</CardHeader>
        <StyledBorderButton to={generateEditLink('initial')}>
          Open Group Messaging
        </StyledBorderButton>
      </Flex>
      <ResidentHappinessContent>
        <Flex direction="column" width="100%">
          Notification Timing:
          <StyledGreyRow>
            <Flex
              direction="row"
              fullHeight="2em"
              paddingLeft="0.6em"
              paddingRight="0.6em"
              justifySpaceBetween>
              <RowItem>
                <StyledRowText>
                  Initial Communication:
                  <BoldInfo>{communicationInfo('initial')}</BoldInfo>
                </StyledRowText>
              </RowItem>
              <RowItem>
                <StyledLink to={generateEditLink('initial')}>Edit</StyledLink>
              </RowItem>
            </Flex>
          </StyledGreyRow>
          <Flex direction="row" paddingLeft="0.6em" paddingRight="0.6em" justifySpaceBetween>
            <RowItem>
              <StyledRowText>
                Follow up:
                <BoldInfo>{communicationInfo('follow_up')}</BoldInfo>
              </StyledRowText>
            </RowItem>
            <RowItem>
              <StyledLink to={generateEditLink('follow_up')}>Edit</StyledLink>
            </RowItem>
          </Flex>
          <SeparationLine/>
          Communication Templates:
          <StyledGreyRow>
            <Flex direction="row" fullHeight="2em" paddingLeft="0.6em" paddingRight="0.6em" justifySpaceBetween>
              <RowItem>
                <StyledRowText>
                  Templates:
                  <BoldInfo>{communicationInfo('sending_methods')}</BoldInfo>
                </StyledRowText>
              </RowItem>
              <RowItem>
                <StyledLink to={generateEditLink('initial')}>Edit</StyledLink>
              </RowItem>
            </Flex>
          </StyledGreyRow>
        </Flex>
      </ResidentHappinessContent>
    </ResidentHappinessCard>
  )
}

export default withTheme(LeaseRenewDetailsCard)
