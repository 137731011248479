// @flow

import React, { Fragment } from 'react'
import Btn from '@Common/Button'
import ZegoInput from '../Common/Input'
import { FlexItem } from '@Common/Flex'
import { Title, ForgotPassword, TitleDesc } from './styles'

import type { changeModeFn, handleChangeFn } from './types'

type Props = {
  email: string,
  changeMode: changeModeFn,
  handleChange: handleChangeFn,
  handleResetRequest: () => mixed,
  error: string,
  working: boolean
}

const PasswordRecoveryRequest = ({
  email,
  changeMode,
  handleChange,
  handleResetRequest,
  error,
  working
}: Props) => (
  <Fragment>
    <TitleDesc>
      <Title>Password Recovery</Title>
      Please enter your email address. We will send you an email to reset your
      password.
    </TitleDesc>
    <FlexItem fullWidth>
      <ZegoInput
        fullWidth
        placeholder="email"
        value={email}
        onChange={handleChange}
        onKeyPress={({ key }) => key === 'Enter' && handleResetRequest()}
        id="email"
      />
    </FlexItem>
    <FlexItem fullWidth>
      <ForgotPassword
        onClick={() => {
          changeMode('login')
        }}>
        back to login
      </ForgotPassword>
      <Btn
        fullWidth
        onClick={handleResetRequest}
        classes={{ label: 'login-label' }}
        animate={working && !error ? 'animate' : ''}
        name="Send"
        primary
      />
    </FlexItem>
  </Fragment>
)

export default PasswordRecoveryRequest
