// @flow

import React, { Fragment } from 'react'
import Flex, { FlexItem } from '@Common/Flex'
import ChangeSetPoint from '../../../Common/Devices/Thermostat/ChangeSetPoints'
import ChangeMode from '../../../Common/Devices/Thermostat/ChangeMode'
import { commands } from 'zego-shared/store/devices/sagas/utils/deviceCommands'
import { VacantSchedule, Title, Value } from './styles'

import type { ScheduleSettingsProps } from './types'

type View = 'default' | 'mode' | 'setpoint'
type State = {
  view: View
}

export default class ScheduleSettings extends React.Component<
  ScheduleSettingsProps,
  State
> {
  state = {
    view: 'default'
  }

  changeViewMode = (view: View) => {
    this.setState({ view })
  }

  getDefaultView = () => {
    const { thermostatMode, heatSetpoint, coolSetpoint } = this.props
    const showHeatSetpoint =
      thermostatMode === commands.AUTO || thermostatMode === commands.HEAT
    const showcoolSetpoint =
      thermostatMode === commands.AUTO || thermostatMode === commands.COOL
    const showOffMessage = thermostatMode === commands.OFF
    return (
      <Flex>
        <FlexItem flex={1}>
          <Title>Mode Settings</Title>
          <Value
            onClick={() => {
              this.changeViewMode('mode')
            }}>
            {thermostatMode}
          </Value>
          temperature mode
        </FlexItem>
        <FlexItem flex={1}>
          <Title>Setpoint Settings</Title>
          <Flex direction="column">
            {showHeatSetpoint && (
              <FlexItem>
                <Value
                  onClick={() => {
                    this.changeViewMode('setpoint')
                  }}>
                  {heatSetpoint}
                  &deg;
                </Value>
                heat setpoint
              </FlexItem>
            )}

            {showcoolSetpoint && (
              <FlexItem>
                <Value
                  onClick={() => {
                    this.changeViewMode('setpoint')
                  }}>
                  {coolSetpoint}
                  &deg;
                </Value>
                cool setpoint
              </FlexItem>
            )}
            {showOffMessage && (
              <FlexItem>
                Setpoints can be set when thermostat mode is not off
              </FlexItem>
            )}
          </Flex>
        </FlexItem>
      </Flex>
    )
  }

  changeSetPoint = (values: Array<number>) => {
    const {
      heatSetpoint,
      coolSetpoint,
      thermostatMode,
      updateSetpoints
    } = this.props
    switch (thermostatMode) {
      case commands.AUTO:
        updateSetpoints({
          heatSetpoint: values[0],
          coolSetpoint: values[1]
        })
        break
      case commands.HEAT:
        updateSetpoints({
          heatSetpoint: values[0],
          coolSetpoint
        })
        break
      case commands.COOL:
        updateSetpoints({
          heatSetpoint,
          coolSetpoint: values[0]
        })
        break
      default:
        break
    }
  }

  getModeView = () => {
    const {
      updateMode,
      thermostatMode,
      heatSetpoint,
      coolSetpoint
    } = this.props
    const { view } = this.state
    return (
      <Fragment>
        <Flex justifySpaceBetween>
          <FlexItem flex={4}>
            {view === 'mode' ? (
              <div>
                <Title>Temperature Mode</Title>
                <ChangeMode
                  modeToChangeTo={thermostatMode}
                  changeThermostatMode={updateMode}
                />
              </div>
            ) : (
              <div>
                <ChangeSetPoint
                  heatSetpointToChangeTo={heatSetpoint}
                  coolSetpointToChangeTo={coolSetpoint}
                  currentThermostatMode={thermostatMode}
                  changeSetPoint={this.changeSetPoint}
                  hideThermostatValues
                />
              </div>
            )}
          </FlexItem>
          <FlexItem>
            <Value
              onClick={() => {
                this.changeViewMode('default')
              }}>
              Done
            </Value>
          </FlexItem>
        </Flex>
      </Fragment>
    )
  }

  render() {
    const { view } = this.state
    return (
      <VacantSchedule>
        <Flex>
          <FlexItem flex={1}>
            <Title>Settings</Title>
            These settings will set the <br />
            temperature for any vacant unit.
          </FlexItem>

          <FlexItem flex={2}>
            {view === 'default' ? this.getDefaultView() : this.getModeView()}
          </FlexItem>
        </Flex>
      </VacantSchedule>
    )
  }
}
