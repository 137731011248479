import { call, select, takeLatest, put, all } from 'redux-saga/effects'
import { getToken } from '../../../authorization/selectors'
import {
  fetchUser as fetchUserAction,
  fetchUserStart,
  fetchUserSuccess,
  fetchUserError,
  DELETE_USER
} from '../actions'
import { getUsersResourceInfo } from '../../../users/selectors'
import { showSnackbar } from '../../../snackbar/actions'
import api from '../../../../utils/api'
import log from '../../../../utils/logger'
import {
  deleteUserConfirmation,
  genericErrorMsg
} from '../../../../utils/messages'

function* getUserBalance(userId, authToken) {
  try {
    return call(api.getPaymentBalance, id, authToken)
  } catch (error) {
    return null
  }
}

export function* fetchUser({ id }) {
  try {
    yield put(fetchUserStart())
    const authToken = yield select(getToken)

    const [user, balance] = yield all([
      call(api.getUser, id, authToken),
      call(getUserBalance, id, authToken)
    ])

    user.balance = balance

    yield put(fetchUserSuccess(user))
  } catch (error) {
    yield put(fetchUserError(error))
    log(`Failed to fetch user`)
  }
}

export function* deleteUser({ history, propertyId, userId }) {
  try {
    const authToken = yield select(getToken)
    const { resource, resourceId } = yield select(getUsersResourceInfo)

    yield call(api.deleteUser, resource, resourceId, userId, authToken)
    yield put(showSnackbar(deleteUserConfirmation, 'success'))
    history.push(`/properties/${propertyId}/user-management/`)
  } catch (error) {
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

function* watchFetchUser() {
  yield takeLatest(fetchUserAction().type, fetchUser)
}

function* watchDeleteUser() {
  yield takeLatest(DELETE_USER, deleteUser)
}

export default [watchFetchUser(), watchDeleteUser()]
