// @flow
import React, { Fragment } from 'react'
import ChatWindowContainer from './ChatWindow'
import TopNavigation from '../../Common/TopNavigation'
import ChatListDrawerContainer from './ChatListDrawer'
import { FlexItem } from '@Common/Flex/FlexDirection'
import Loader from '../../Common/Loader'
import { ChatWrapper } from './styles'

type ChatProps = {
  type: 'live-chat',
  hasData: boolean,
  isFetching: boolean,
  users: Array<any>,
  messages: Array<any>,
  match: { params: { propertyId: string, channelType: string } },
  sendMessage: (message: string) => void,
  userId: string,
  history: Object,
  propertyId: string,
  userRole: string,
  displayErrorMessage: (message: string) => void
}

const getUrl = match => `/properties/${match.params.propertyId}/communication`

const Chat = ({ isFetching, hasData, type, users, userId, match, history, propertyId, userRole, displayErrorMessage }: ChatProps) => { 
  if (userRole === "company_admin") {
    history.replace(`/properties/${propertyId}/communication`)
    setTimeout(() => {
      displayErrorMessage("Live Chat is not available for Company Admins.")
    }, 1000)

    return null
  }

  return isFetching ? (
    <Loader />
  ) : hasData ? (
    <Fragment>
      <ChatListDrawerContainer />
      <ChatWrapper>
        <FlexItem flex={1}>
          <TopNavigation scrollable={false} backArrowUrl={getUrl(match)} history={history} />
        </FlexItem>
        <FlexItem flex={15} className={'chat-window'}>
          <ChatWindowContainer userId={userId} />
        </FlexItem>
      </ChatWrapper>
    </Fragment>
  ) : null
}

export default Chat
