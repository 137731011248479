// @flow
import React from 'react'
import momentModule, { Moment } from 'moment'
import { DatetimePicker } from 'rc-datetime-picker'
import {
  getMonth,
  getDateNumber,
  getDay,
  getYear,
} from 'zego-shared/utils/date'

import {
  CalendarWrapper,
  SelectedDate,
  Date,
  Day,
  Month,
  MonthDay,
  Year,
} from './styles'

type Props = {
  width?: string,
  moment: Moment,
  flex?: number,
  handleChange: () => void,
  previousDatesEnabled?: boolean,
  isCommunityFeed?: boolean,
  noBoxShadow?: boolean,
  flexDirection?: string,
  noSidebar?: boolean,
}

class Calendar extends React.PureComponent<Props> {
  static defaultProps = {
    width: '',
    moment: '',
    flex: 0,
    previousDatesEnabled: false,
    isCommunityFeed: false,
    noBoxShadow: false,
    flexDirection: '',
    noSidebar: false,
  }
  render() {
    const {
      width,
      flex,
      moment,
      flexDirection,
      handleChange,
      previousDatesEnabled,
      noBoxShadow = false,
      isCommunityFeed = false,
      noSidebar = false,
    } = this.props

    const momentFormatted = moment.format()
    return (
      <CalendarWrapper
        flexDirection={flexDirection}
        flex={flex}
        width={width}
        noBoxShadow={noBoxShadow}>
        {isCommunityFeed ? (
          <SelectedDate isCommunityFeed={isCommunityFeed}>
            <Year>{getYear(momentFormatted)}</Year>
            <MonthDay>
              {getMonth(momentFormatted)} {getDateNumber(momentFormatted)}
            </MonthDay>
          </SelectedDate>
        ) : (
          !noSidebar && (
            <SelectedDate>
              <Day>{getDay(momentFormatted)}</Day>
              <Date>{getDateNumber(momentFormatted)}</Date>
              <Month>{getMonth(momentFormatted)}</Month>
            </SelectedDate>
          )
        )}
        <DatetimePicker
          minDate={previousDatesEnabled ? null : momentModule()}
          moment={moment}
          onChange={handleChange}
        />
      </CalendarWrapper>
    )
  }
}

export default Calendar
