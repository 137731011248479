import {
  createFetchActions,
  createRefreshActions,
  createFetchAndRefreshActions
} from '../../helpers/actions/creators'

export const SECTION = 'SUMMARY'
export const UNIT_SECTION = 'UNIT_SUMMARY'

export const {
  fetchAction: fetchSummaryAction,
  fetchStartAction: fetchSummaryStart,
  fetchSuccessAction: fetchSummarySuccess,
  fetchErrorAction: fetchSummaryError,
  refreshAction: refreshSummary,
  refreshStartAction: refreshSummaryStart,
  refreshSuccessAction: refreshSummarySuccess,
  refreshErrorAction: refreshSummaryError
} = createFetchAndRefreshActions(SECTION)

export const fetchSummary = id => fetchSummaryAction({ id })

