import { connect } from 'react-redux'
import Property from './Property'
import withFetchData from 'zego-shared/views/withFetchData'
import { fetchUsersAction } from 'zego-shared/store/users/property/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { sendLockCode } from 'zego-shared/store/lockCodes/actions'
import {
  getPropertyUsers,
  getPropertyUsersCount
} from 'zego-shared/store/lockCodes/selectors'

const PropertyWithFetchData = withFetchData(Property)
const fetchingSelector = createIsFetchingSelector(['property'])

const mapStateToProps = (
  state,
  {
    match: {
      params: { propertyId }
    }
  }
) => {
  return {
    propertyId,
    count: getPropertyUsersCount(state),
    property: getPropertyUsers(state),
    isFetching: fetchingSelector(state)
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { propertyId }
    }
  }
) => ({
  fetchData: () => {
    dispatch(fetchUsersAction({ propertyId }))
  },
  sendLockCode: userId => {
    dispatch(sendLockCode(userId))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyWithFetchData)
