import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

export const Temperature = styled(Typography)`
  &:after {
    content: '°';
  }
`

export const SliderWrap = styled('div')`
  height: 3em;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-flex: 1;
  flex: 1;
  display: flex;
  -webkit-display: flex;
  align-items: center;
  align-self: center;

  .handle-disabled {
    pointer-events: none;
  }
`
