import styled from '@emotion/styled'
import MaterialButton from '@material-ui/core/Button'

export const AddButton = styled('div')`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  display: flex;
  box-shadow: none;
`

export const ButtonSet = styled('div')`
  position: absolute;
  right: 2em;
  top: 10%;
  display: flex;
  flex-direction: column;
  display: flex;
`

export const FabButton = styled(MaterialButton)`
  width: 45px;
  height: 45px;
  z-index: 100;
  border-radius: 50%;
  box-shadow: none;
  background: ${({ theme, color }) =>
    color && color === 'primary'
      ? theme.palette.blue.main
      : theme.palette.secondary.yellow.solarFlare};

  &:hover {
    background: ${({ theme, color, outlined }) =>
      color && color === 'primary'
        ? theme.palette.primary.green.mint
        : outlined
        ? theme.palette.common.white
        : theme.palette.secondary.yellow.solarFlare};
    border: ${({ theme, outlined }) =>
      outlined && `3px solid ${theme.palette.secondary.yellow.golden}`};
  }

  ${({ theme, outlined, editdisabled}) =>
    outlined &&
    `border: 3px solid ${
      editdisabled ? theme.palette.neutrals.darkGray : theme.palette.secondary.yellow.solarFlare
    }; background: transparent`};

  svg {
    fill: ${({ theme, darkIcon }) =>
      darkIcon ? theme.palette.primary.dark : theme.palette.common.white};
  }
  margin-bottom: 0.6em;
  
  &.disabled {
    background-color: transparent;
  }
`

export const DisabledFabButton = styled(MaterialButton)`
  width: 45px;
  height: 45px;
  z-index: 100;
  border-radius: 50%;
  box-shadow: none;
  background: ${({ theme }) => theme.palette.common.white};
  &:hover {
    background: ${({ theme }) => theme.palette.common.white};
  };
  background-color: transparent;
  border: 3px solid ${({ theme }) => theme.palette.neutrals.mediumGray };

  margin-bottom: 0.6em;

  pointer-events: none;
  cursor: default;

  button {
    background-color: transparent;
  }
`
