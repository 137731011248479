import compose from 'lodash/flowRight'
import { combineReducers } from 'redux'
import withFetch from '../../../helpers/reducers/withFetch'
import { CHAT_CHANNEL_MESSAGES, addMessage, channelChanged } from '../actions'
import { fetchUnreadMessagesCountSuccess } from 'zego-shared/store/chat/messages/actions';

const initialState = []

export const unread = (state = {}, action) => {
  switch (action.type) {
    case fetchUnreadMessagesCountSuccess().type:
      return { ...state, count: action.payload }
    default:
      return state
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case addMessage().type:
      return [...state, action.message]
    default:
      return state
  }
}

export default withFetch(CHAT_CHANNEL_MESSAGES)(reducer)
