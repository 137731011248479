import React from 'react'
import { withTheme } from 'emotion-theming'
import MultiCircularProgress from '@Common/MultiCircularProgress'

import {
  ResidentHappinessCard,
  ResidentHappinessContent,
  CardHeader,
  LegendInline,
  LegendLabelInline,
  LegendInlineRow,
  SurveyChartWrapper
} from '../styles'

const getStatChartValues = stats => {
  const statChartValues = ['Promoter', 'Passive', 'Detractor', 'No Answer'].map(bucket => {
    return stats.filter(item => item.survey_result_type === bucket)[0]?.count || 0
  })
  return statChartValues
}

const surveyKeyNames = {
  "amenity": "Amenities",
  "lease_end": "Pre Lease Renewal",
  "move_in": "Post Move In",
  "work_order_completion": "Post Work Order"
}

const renderSurveyStatsCharts = (surveyStats, theme) => {
  let statValues = {}
  let surveyCharts = []
  const stats = Array.isArray(surveyStats) ? surveyStats : []

  var surveyTypes = new Set(stats.map(item => item.survey_type))

  surveyTypes.forEach(type => {
    const surveyValues = stats.filter(items => items.survey_type === type)
    statValues[type] = {}
    statValues[type].data = surveyValues
    statValues[type].total = surveyValues.reduce((acc, item) => acc + item.count, 0)
  })

  for (const [key, value] of Object.entries(statValues)) {
    const surveyChart =
      <SurveyChartWrapper>
        <MultiCircularProgress
          variant="static"
          progressCaption="Surveys"
          progressText={value.total}
          infoText={surveyKeyNames[key]}
          lgFont
          underlayColor={theme.palette.neutrals.coolGray}
          size={115}
          thickness={3}
          values={getStatChartValues(value.data)}
          stackColors={[
            theme.palette.secondary.pink.deepRed,
            theme.palette.alternates.brightBlue,
            theme.palette.secondary.main1
          ]}
        />
      </SurveyChartWrapper>

    surveyCharts.push(surveyChart)
  }
  return surveyCharts
}

const renderSurveyStatsLegend = (theme) => {
  return (
    <LegendInlineRow>
      <LegendInline>Review Score: </LegendInline>
      <LegendInline color={theme.palette.secondary.main1}><LegendLabelInline>Happy</LegendLabelInline></LegendInline>
      <LegendInline color={theme.palette.alternates.brightBlue}><LegendLabelInline>Neutral</LegendLabelInline></LegendInline>
      <LegendInline color={theme.palette.secondary.pink.deepRed}><LegendLabelInline>Unhappy</LegendLabelInline></LegendInline>
      <LegendInline color={theme.palette.neutrals.coolGray}>Have not taken a survey</LegendInline>
    </LegendInlineRow>
  )
}

const SurveyStatsInfoChart = (props) => {
  const { surveyStats, theme } = props
  return (
    <ResidentHappinessCard>
      <CardHeader>Resident Review Statistics</CardHeader>
      <ResidentHappinessContent>
        {renderSurveyStatsCharts(surveyStats, theme)}
      </ResidentHappinessContent>
      {renderSurveyStatsLegend(theme)}
    </ResidentHappinessCard>
  )
}

export default withTheme(SurveyStatsInfoChart)
