// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import { CommonIcon } from '@icons'

const ScrollDownButton = ({ scrollDown, theme }) => (
  <CommonIcon
    style={{ cursor: 'pointer' }}
    onClick={scrollDown}
    name="arrow_down"
    height="18px"
    width="18px"
    fill={theme.palette.primary.green.mint}
    stroke={theme.palette.primary.green.mint}
    strokeWidth="1"
  />
)

export default withTheme(ScrollDownButton)
