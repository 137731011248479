import { connect } from 'react-redux'
import GeneralInfo from './GeneralInfo'
import { updateUserSetting } from 'zego-shared/store/settings/actions'
import { getUserSettings } from 'zego-shared/store/settings/selectors'
import { updateUser } from 'zego-shared/store/authorization/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import {
  uploadProfileImage,
  generateLockCode,
  deleteLockCode
} from 'zego-shared/store/users/profile/actions'
import { sendActivationEmail } from 'zego-shared/store/users/unit/actions'
import {
  getUserProfile,
  hasProfileData
} from 'zego-shared/store/users/selectors'
import { isSmartEnabled } from 'zego-shared/store/settings/propertySettings/selectors'

const GeneralInfoWithFetchData = withFetchData(GeneralInfo)
const fetchingSelector = createIsFetchingSelector(['profile'])

const mapStateToProps = (state, ownProps) => {
  const { showStaffProfile, residentZegoSmartEnabled } = getUserSettings(
    state,
    ownProps.match.params.userId
  )

  return {
    hasData: hasProfileData(state),
    isFetching: fetchingSelector(state),
    profile: getUserProfile(state),
    showStaffProfile: showStaffProfile,
    residentZegoSmartEnabled:
      residentZegoSmartEnabled == null ? true : residentZegoSmartEnabled,
      isSmartEnabled: isSmartEnabled(state)
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { userId }
    }
  }
) => {
  return {
    uploadProfileImage: file => {
      dispatch(uploadProfileImage(userId, file))
    },
    sendActivationEmail: id => {
      dispatch(sendActivationEmail(id))
    },
    updateUser: attributes => {
      const {
        bio,
        userId,
        phoneNumber,
        firstName,
        lastName,
        title,
        lockCode,
        picture,
        lastActive,
        accountActivatedTime,
        role,
        status,
        ...rest
      } = attributes
      dispatch(
        updateUser({
          ...rest,
          phone_number: phoneNumber,
          first_name: firstName,
          last_name: lastName,
          user_metadata: { bio: bio, title: title }
        })
      )
    },
    generateLockCode: () => {
      dispatch(generateLockCode(userId))
    },
    deleteLockCode: () => {
      dispatch(deleteLockCode(userId))
    },
    toggleStaffProfile: showStaffProfile => {
      dispatch(updateUserSetting({ showStaffProfile, userId }))
    },
    toggleResidentZegoSmartEnabled: residentZegoSmartEnabled => {
      dispatch(updateUserSetting({ residentZegoSmartEnabled, userId }))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralInfoWithFetchData)
