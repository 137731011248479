// @flow
import React from 'react'
import CarouselList from '@Common/CarouselList'
import withScrollBehaviour from '@Common/CarouselList/withScrollBehaviour'
import type { ResultsContentProps as Props } from './types'

export const ResultsContent = ({
  currentSlideNumber = 1,
  lastSlideNumber,
  numberOfItemsPerSlide,
  items
}: Props) => (
  <CarouselList
    numberOfItemsPerSlide={numberOfItemsPerSlide}
    itemHeight={56}
    lastSlide={lastSlideNumber}
    items={items}
    currentSlide={currentSlideNumber}
  />
)

export default withScrollBehaviour(ResultsContent)
