// @flow

import React from 'react'

import type { SelectedScheduleProps } from './types'
import { Title } from './styles'

const SelectedSchedule = ({ title, description }: SelectedScheduleProps) => (
  <React.Fragment>
    <font color="#707070" size="4">
      <Title>{title}</Title>
    </font>
    <font color="#707070">{description}</font>
  </React.Fragment>
)

export default SelectedSchedule
