import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Step from '@material-ui/core/Step'
import TextField from '@material-ui/core/TextField'
import { convertHexToRGB } from '../../../../utils'
import FormHelperText from '@material-ui/core/FormHelperText'
import MaterialFormControlLabel from '@material-ui/core/FormControlLabel'
import { css } from 'emotion'

export const LinkWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  grid-column: 12;
  grid-row: 1 / span 3;
  justify-content: space-around;
`

export const CustomModal = styled(Modal)`
  &:after {
    content: ' ';
    height: 100vh;
    width: 18%;
    background-color: ${({ theme }) => theme.palette.primary.navy.twilight};
    border-top-right-radius: 4em;
    position: absolute;
    left: 0px;
  }
`

export const Wrapper = styled('div')`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 2em;
  margin: 0 auto;
  overflow-y: scroll;

  &.stepperWrapper {
    padding: 6em;
  }

  .stepperRoot {
    width: 100%;
  }

  .stepContent {
    border-right: 1px dashed ${({ theme }) => theme.palette.neutrals.warmGray};
    border-left: 0px;
    margin-top: 0px;
    margin-left: 0px;
    padding: 1em 0em;

    p {
      width: 90%;
    }

    .radioGroupRoot {
      flex-direction: row;
    }

    .checkBoxGroupFormControl {
      margin-right: 5%;
      font-size: ${({ theme }) => theme.custom.font.size.md};

      span:last-child {
        color: ${({ theme }) => theme.palette.grey.main};
      }
    }

    .checkBoxGroupFieldset {
      width: 100%;
    }

    .radioChecked {
      stroke: 1px;
    }
  }

  .stepLabelText {
    color: ${({ theme }) => theme.palette.common.black};
    display: flex;
    justify-content: space-between;
    width: 95%;
  }

  .stepLabel {
    flex-direction: row-reverse;
    width: 100%;
    & > * {
      padding: 0px;
    }

    .stepLabelIcon {
      padding: 0px !important;
      height: 1.5em;
      margin-right: -0.8em;

      * {
        padding: 0px !important;
      }
    }
  }

  .connectorLine {
    border-right: 1px dashed ${({ theme }) => theme.palette.neutrals.warmGray};
    border-left: 0px;
    min-height: 3em;
    margin-top: -0.5em;
  }

  .connectorRoot {
    flex: none;
    padding: 0px;
  }
`

export const StepIndicator = styled('div')`
  position: absolute;
  left: 6.4em;
  z-index: 100;
  color: ${({ theme }) => theme.palette.primary.green.mint};
  font-size: ${({ theme }) => theme.custom.font.size.lg};
  font-weight: ${({ theme, active }) =>
    active ? theme.custom.font.weight.bold : theme.custom.font.weight.light};
`

export const ClearFix = styled('div')`
  width: 95%;
  padding-bottom: 0.5em;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutrals.deepGray};
  ${({ selected, theme }) =>
    selected &&
    `box-shadow: inset 0 -7px 7px -7px ${convertHexToRGB(
      theme.palette.neutrals.warmGray
    )}`};
`

export const StepDescription = styled(Typography)`
  font-size: ${({ theme }) => theme.custom.font.size.md};
  color: ${({ theme }) => theme.palette.grey.main};
`

export const CustomTextField = styled(TextField, {
  shouldForwardProp: prop => !['active', 'inputProps'].includes(prop)
})`
  width: 90%;
  svg {
    cursor: pointer;
    margin-top: 0.4em;
  }
  padding-bottom: 2em;
`

export const TitleTextField = styled(TextField)`
  width: 100%;
`

export const TextFieldContainer = styled('div')`
  height: ${({ direction }) => (direction === 'column' ? 'auto' : '5em')};
  display: flex;
  margin-bottom: 16px;
  align-items: ${({ direction }) =>
    direction === 'column' ? 'flex-start' : 'center'};
  ${({ width }) => width && `width: ${width}`};
  white-space: nowrap;
  flex-direction: ${({ direction }) =>
    direction === 'column' ? 'column' : 'row'};

  .template-dropdown {
    border-radius: 3em;
    display: flex;
    border: 1px solid ${({ theme }) => theme.palette.neutrals.deepGray};
    padding: 0em 1em;

    & svg {
      position: absolute;
      right: 1em;
      top: 0.5em;
    }
  }

  .template-number {
    border-radius: 3em;
    display: flex;
    border: 1px solid ${({ theme }) => theme.palette.neutrals.deepGray};
    padding: 0em 1em;
    width: 50%;

    & svg {
      position: absolute;
      right: 1em;
      top: 0.5em;
    }
  }
`

export const Attachment = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 4em;
  color: ${({ theme }) => theme.palette.primary.green.mint};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  & > span:first-child {
    font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  }
  & span {
    margin: 0.5em;
  }

  & .count {
    color: ${({ theme }) => theme.palette.primary.navy.midnight};
  }
`

export const SchedulerWrapper = styled('div')`
  width: 90%;
  .template-dropdown {
    border-radius: 3em;
    display: flex;
    border: 1px solid ${({ theme }) => theme.palette.neutrals.deepGray};
    padding: 0em 1em;

    & svg {
      position: absolute;
      right: 1em;
      top: 0.5em;
    }
  }
`

export const Break = styled('div')`
  height: ${({ height }) => (height ? height : '2em')};
`

export const ToolBar = styled('div')`
  position: absolute;
  min-width: 3.5em;
  right: 0;
  top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  & > * {
    color: ${({ theme }) => theme.palette.grey.main};
    padding-left: 0.5em;
  }
  .chat-icon {
    transform: rotate(-45deg);
    * {
      fill: ${({ theme }) => theme.palette.grey.main};
    }
  }
`

export const NewTemplateCheckBox = styled('div')`
  padding-top: 1em;
  margin-right: 2em;
`

export const TextCount = styled('div')`
  border-radius: 2em;
  text-align: center;
  min-width: 3em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.green.mint};
`

export const CustomStep = styled(Step)`
  svg {
    cursor: pointer;
  }
`

export const CustomFormHelperText = styled(FormHelperText)`
  font-size: ${({ theme }) => theme.custom.font.size.xs};
  z-index: 1;
`

export const DaysIcon = styled('div')`
  display: flex;
  align-items: center;
  padding-left: 0.8em;
  border-left: 1px solid ${({ theme }) => theme.palette.neutrals.warmGray};
`

export const DatePickerWrapper = styled('div')`
  position: ${({ isFixed }) => (isFixed ? 'sticky' : 'absolute')};
  z-index: 150;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  margin-top: ${({ isFixed }) => (isFixed ? '1em' : '4em')};
`

export const FabButton = styled(Button)`
  position: absolute;
  left: 2em;
  color: ${({ theme }) => theme.palette.primary.dark};
  min-width: 3em;
  width: 3em;
  min-height: 3em;
  height: 3em;
  z-index: 100;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.secondary.yellow.solarFlare};

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
  }
`

export const SearchBarTextFieldClass = css`
  background-color: white;
  border: 1px solid lightgray;
  margin-top: 1em;
  width: 90%;
`

export const SearchResultsClassName = css`
  position: fixed;
  width: 25%;
  margin-left: 0.5em;
`

export const TooltipWrapper = styled('span')`
  padding: 0;
  margin: 0;
  margin-top: -0.5em;
  line-height: 0em;

  & > span {
    line-height: 0em;
  }
`

export const RecipientsList = styled('div')`
  max-height: 9.4em;
  overflow-y: scroll;
  white-space: nowrap;
  width: 93%;
  padding-right: 1.3em;
  margin-bottom: 2em;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: ${({ theme }) => theme.palette.neutrals.lightGray};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${({ theme }) => theme.palette.neutrals.deepGray};
  }

  .unit-section {
    text-align: right;
    color: ${({ theme }) => theme.palette.primary.green.mint};
  }
`

export const RecipientsFloatingDiv = styled('div')`
  position: fixed;
  min-width: 12em;
  width: 22%;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  height: 18em;
  overflow-x: hidden;
  padding: calc(0.5em + 1%);
  margin-left: 25%;
  margin-top: -0.5em;
  overflow-y: auto;
  background: ${({ theme }) => theme.palette.primary.green.iceberg};
  z-index: 9999;
  box-shadow: ${({ theme }) =>
    `0 1px 15px 0 ${convertHexToRGB(theme.palette.neutrals.deepGray, 0.5)}`};
  white-space: nowrap;
  & > div {
    height: 2.5em;
  }

  & > div > div:last-child {
    text-align: right;
    color: ${({ theme }) => theme.palette.grey.main};
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: ${({ theme }) => theme.palette.primary.hintMint};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${({ theme }) => theme.palette.primary.green.mint};
    opacity: 0.5;
    height: 1em;
  }
`

export const GroupOption = styled('div')`
  height: 2em;
  display: flex;
  padding-left: 1em;
  padding-right: 1em;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: rgba(89, 192, 155, 0.15);
  }

  background-color: ${({ theme, active }) =>
    active ? theme.palette.secondary.light5 : theme.palette.common.white};

  & > div:last-child {
    text-align: right;
    font-weight: ${({ theme }) => theme.custom.font.weight.bold};
    color: ${({ theme }) => theme.palette.primary.navy.midnight};
  }
`

export const FormControlLabel = styled(MaterialFormControlLabel)`
  text-transform: capitalize;
`
export const OffsetBeforeAfter = styled('div')`
  padding: 1.0em 0;
`
