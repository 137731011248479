// @flow

import React, { Fragment } from 'react'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import AddDevice from './AddDevice'
import AddHub from './AddHub'
import EnterMac from './EnterMac'
import ChooseDevice from './ChooseDevice'
import ConnectDevice from './ConnectDevice'
import TestDevice from './TestDevice'
import TestDeviceResult from './TestDeviceResult'
import NameDevice from './NameDevice'
import DeviceConnected from './DeviceConnected'
import SetupMessage from './SetupMessage'
import {
  DefaultBackButton,
  DefaultNextButton
} from '@Common/Button'
import Flex from '@Common/Flex'
import Grid, { GridItem } from '../Common/Grid'
import { CommonIcon } from '@icons'
import { MobileStepper, StepButton } from './styles'
import Snackbar from '../Snackbar/SnackbarContainer'
import Loader from '../Common/Loader'
import { isFunction } from '../../utils/commonUtils'

import type { Match } from 'react-router-dom'
import type { BrowserHistory } from 'history'
import type {
  DevicesType,
  DeviceProfilesType,
  SetupStepType,
  DeviceProfileType
} from './types'

export const setupSteps: Array<SetupStepType> = [
  {
    component: AddHub,
    disableBackButton: false
  },
  {
    component: EnterMac,
    disableNextButton: true
  },
  {
    component: SetupMessage,
    props: { message: 'SUCCESS !' },
    withoutGrid: true,
    displayTimer: 1200
  },
  {
    component: SetupMessage,
    props: { message: '+ add a device' },
    withoutGrid: true,
    displayTimer: 1500
  },
  {
    component: AddDevice,
    disableBackButton: false
  },
  {
    component: ChooseDevice,
    disableNextButton: true
  },
  {
    component: ConnectDevice,
    sendInclusion: true,
    disableNextButton: true,
    enableNextButtonOnProp: 'connectedDevice',
    enableNextButtonOnPropCheck: obj => !isEmpty(obj)
  },
  {
    component: TestDevice,
    disableBackButton: true,
    disableNextButton: true
  },
  {
    component: TestDeviceResult,
    disableBackButton: true,
    disableNextButton: true,
    shouldRunTest: true
  },
  {
    component: NameDevice,
    disableBackButton: true,
    disableNextButton: true
  },
  {
    component: DeviceConnected,
    backButton: '+ ADD ANOTHER',
    backButtonClickRender: ChooseDevice,
    nextButton: (
      <Fragment>
        DONE
        <CommonIcon name="arrow_right" height="12px" width="24px" />
      </Fragment>
    )
  },
  {
    component: SetupMessage,
    props: { message: 'COMPLETE !' },
    withoutGrid: true,
    displayTimer: 3000,
    setupComplete: true
  }
]

const stepsCount = setupSteps.length

type Props = {
  hubIdentifier?: string,
  unitId: number,
  devices: DevicesType,
  deviceProfiles: DeviceProfilesType,
  match: Match,
  history: BrowserHistory,
  setupInstruction: Array<string>,
  beforeTestInstruction: string,
  afterTestInstruction: string,
  testWaitTimeout: number,
  deviceProfileType: string,
  clearConnectedDevice: () => mixed,
  connectDevice: () => mixed,
  runTest: () => mixed,
  addHub: string => mixed,
  selectDeviceProfile: number => mixed,
  submitName: string => mixed,
  isFetching: boolean,
  hasConnectedDevice: boolean
}

type State = {
  currentStep: number,
  hubIdentifier: ?string,
  connectedDeviceName: ?string,
  selectedDeviceProfileID: ?number,
  selectedDeviceProfile: ?DeviceProfileType,
  forceEnableNextButton: ?boolean
}

export default class Setup extends React.Component<Props, State> {
  addDeviceIndex = setupSteps.findIndex(
    ({ component }) => component === AddDevice
  )
  state = {
    currentStep: this.props.hubIdentifier == null ? 0 : this.addDeviceIndex,
    hubIdentifier: null,
    connectedDeviceName: null,
    selectedDeviceProfileID: null,
    selectedDeviceProfile: null,
    forceEnableNextButton: null
  }

  componentDidUpdate(prevProps: Props) {
    const { hubIdentifier, devices } = this.props
    if (
      (prevProps.hubIdentifier == null && hubIdentifier != null) ||
      !isEqual(prevProps.devices, devices)
    ) {
      const { currentStep } = this.state
      if (currentStep === 0 || currentStep === this.addDeviceIndex) {
        this.setState({
          currentStep: this.addDeviceIndex
        })
      } else {
        this.handleNextStep()
      }
    }
  }

  enableNextButton = () => {
    this.setState({ forceEnableNextButton: true })
  }

  handleBackStep = () => {
    const { currentStep } = this.state
    if (
      currentStep === 0 ||
      (this.props.hubIdentifier !== null &&
        currentStep ===
          setupSteps.findIndex(({ component }) => component === AddDevice))
    ) {
      this.goBack()
    } else {
      const { backButtonClickRender } = setupSteps[currentStep]
      const backButtonClickRenderIndex = setupSteps.findIndex(
        ({ component }) => component === backButtonClickRender
      )
      const backStep =
        backButtonClickRenderIndex === -1
          ? currentStep - 1
          : backButtonClickRenderIndex
      this.setState({ currentStep: backStep, forceEnableNextButton: null })
    }
  }

  goBack = () => {
    const {
      history,
      match: { url }
    } = this.props
    history.push(`${url.split('/setup')[0]}`)
  }

  handleNextStep = () => {
    const {
      history,
      match: { params }
    } = this.props
    const { currentStep } = this.state
    const { setupComplete } = setupSteps[currentStep]

    if (setupComplete && params.propertyId && params.unitId) {
      history.push(
        `/properties/${params.propertyId}/units/${params.unitId}/smart`
      )
    } else {
      this.setState(
        { currentStep: currentStep + 1, forceEnableNextButton: null },
        () => {
          const { clearConnectedDevice, connectDevice, runTest } = this.props
          const {
            sendInclusion,
            shouldRunTest,
            displayTimer,
            onDisplayTimerEnd
          } = setupSteps[currentStep + 1]
          if (sendInclusion) {
            clearConnectedDevice()
            connectDevice()
          }
          if (shouldRunTest) {
            runTest()
          }
          if (displayTimer) {
            setTimeout(
              isFunction(onDisplayTimerEnd)
                ? onDisplayTimerEnd
                : this.handleNextStep,
              displayTimer
            )
          }
        }
      )
    }
  }

  handleDataChange = (
    changes: { selectedDeviceProfileID: ?number, hubIdentifier: ?string },
    goToNextStep: boolean = true
  ) => {
    const { selectedDeviceProfileID, hubIdentifier } = changes
    if (selectedDeviceProfileID) this.enableNextButton()
    const { addHub, selectDeviceProfile } = this.props
    if (hubIdentifier) {
      addHub(hubIdentifier)
    } else {
      if (selectedDeviceProfileID != null) {
        selectDeviceProfile(parseInt(selectedDeviceProfileID, 10))
      }
      this.setState(changes, () => {
        goToNextStep && this.handleNextStep()
      })
    }
  }

  render() {
    const {
      deviceProfiles,
      setupInstruction,
      beforeTestInstruction,
      afterTestInstruction,
      deviceProfileType,
      hasConnectedDevice,
      submitName,
      devices,
      isFetching
    } = this.props

    if (isFetching) {
      return <Loader />
    }
    const { currentStep, forceEnableNextButton, ...state } = this.state
    let {
      component: StepComponent,
      props,
      withoutGrid,
      disableBackButton,
      disableNextButton,
      enableNextButtonOnProp,
      enableNextButtonOnPropCheck,
      backButton,
      nextButton
    } = setupSteps[currentStep]

    disableNextButton =
      enableNextButtonOnProp == null
        ? disableNextButton
        : !enableNextButtonOnPropCheck(this.props[enableNextButtonOnProp])

    disableNextButton =
      forceEnableNextButton === true ? false : disableNextButton

    if (withoutGrid) {
      return <StepComponent {...props} />
    }

    return (
      <Fragment>
        <Grid>
          <GridItem columnStart={1} columnSpan={12} rowStart={1} rowSpan={2}>
            <MobileStepper
              activeStep={currentStep}
              variant="progress"
              steps={stepsCount}
              position="static"
            />
            <Flex justifySpaceBetween>
              <StepButton
                onClick={this.handleBackStep}
                disabled={disableBackButton}>
                {backButton == null ? (
                  <DefaultBackButton disabled={disableBackButton} />
                ) : (
                  backButton
                )}
              </StepButton>

              <StepButton
                onClick={this.handleNextStep}
                disabled={disableNextButton}>
                {nextButton == null ? (
                  <DefaultNextButton disabled={disableNextButton} />
                ) : (
                  nextButton
                )}
              </StepButton>
            </Flex>
          </GridItem>
          <GridItem columnStart={4} columnSpan={6} rowStart={4} rowSpan={10}>
            <StepComponent
              handleDataChange={this.handleDataChange}
              handleNextStep={this.handleNextStep}
              handleBackStep={this.handleBackStep}
              deviceProfiles={deviceProfiles}
              deviceProfileType={deviceProfileType}
              setupInstruction={setupInstruction}
              beforeTestInstruction={beforeTestInstruction}
              afterTestInstruction={afterTestInstruction}
              enableNextButton={this.enableNextButton}
              submitName={submitName}
              devices={devices}
              hasConnectedDevice={hasConnectedDevice}
              {...props}
              {...state}
            />
          </GridItem>
        </Grid>
        <Snackbar />
      </Fragment>
    )
  }
}
