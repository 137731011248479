import styled from '@emotion/styled'
import MaterialButton from '@material-ui/core/Button'

export const ScrollUpContainer = styled(MaterialButton)`
  height: 80px;
  width: 80px;
  border-radius:50%;
  background: ${({ theme, background }) =>
    background ? background : theme.palette.primary.green.hintMint};
  &:hover {
    background: ${({ theme, background }) =>
      background ? background : theme.palette.primary.green.hintMint};
  }
`
