// @flow
import React, { Component } from 'react'
import { Moment } from 'moment'
import InputAdornment from '@material-ui/core/InputAdornment'
import { CommonIcon } from '@icons'
import CustomTextField from '../../../Common/TextField'
import Calendar from '@Common/Calendar'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import { getFullDate } from '../../../../utils'
import type { Theme } from '../../../../theme'
import { DatePickerWrapper, DateWrapper, DateBlock } from './styles'
import withClickOutside from '../../../Common/withClickOutside'

type State = {
  showDatePicker: boolean
}

type Props = {
  theme: Theme,
  moment: Moment,
  startTime: string,
  endTime: string,
  setClickOutsideDomNode: (ref: any) => void,
  handleDateChange: (
    date: string,
    event: SyntheticInputEvent<HTMLInputElement>
  ) => void,
  handleCalendarChange: (moment: Moment) => void,
  handleTimeChange: (
    time: string,
    event: SyntheticInputEvent<HTMLInputElement>
  ) => void
}

class DateSection extends Component<Props, State> {
  state = {
    showDatePicker: false
  }

  handleClickOutside = () => {
    this.setState({ showDatePicker: false })
  }

  toggleDatePicker = () => {
    this.setState(state => ({ showDatePicker: !state.showDatePicker }))
  }

  render() {
    const {
      theme,
      moment,
      startTime,
      endTime,
      handleDateChange,
      handleCalendarChange,
      handleTimeChange,
      setClickOutsideDomNode
    } = this.props

    const { showDatePicker } = this.state
    return (
      <DateBlock columnStart={6} columnSpan={5} rowStart={6} rowSpan={4}>
        <DateWrapper>
          <CustomTextField
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            value={getFullDate(moment.format())}
            label="Date"
            onChange={handleDateChange.bind(this, 'date')}
            style={{
              fontSize: theme.custom.font.size.mdlg
            }}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <FlexDirection fullHeight direction="column">
                  <FlexItem
                    flex={0.9}
                    displayFlex
                    alignItemsRight
                    style={{
                      marginTop: '0.4em'
                    }}>
                    <InputAdornment position="start">
                      <CommonIcon
                        name="calendar"
                        height="16px"
                        width="12px"
                        onClick={this.toggleDatePicker}
                      />
                    </InputAdornment>
                  </FlexItem>
                </FlexDirection>
              )
            }}
          />
          <DatePickerWrapper visible={showDatePicker}>
            <Calendar
              ref={ref => setClickOutsideDomNode(ref)}
              moment={moment}
              handleChange={handleCalendarChange}
            />
          </DatePickerWrapper>
        </DateWrapper>
        <CustomTextField
          InputLabelProps={{ shrink: true }}
          fullWidth
          type="time"
          value={startTime}
          onChange={handleTimeChange.bind(this, 'startTime')}
          alwaysShowLabel
          label="start time"
          textAlign="left"
          style={{
            gridColumn: '1/span 2',
            gridRow: '2',
            fontSize: theme.custom.font.size.mdlg
          }}
        />
        <CustomTextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="time"
          value={endTime}
          onChange={handleTimeChange.bind(this, 'endTime')}
          alwaysShowLabel
          label="end time"
          textAlign="left"
          style={{
            gridColumn: '4/span 2',
            gridRow: '2/span 1',
            fontSize: theme.custom.font.size.mdlg
          }}
        />
      </DateBlock>
    )
  }
}

export default withClickOutside(DateSection)
