import React from 'react'
import Flex, { FlexItem } from '../../../Common/Flex'
import { DeleteReservationModalMessage } from '../../AmenityShared/styles'

export default function DeleteReservationMessageContent({ selectedReservation, residents }) {
  const { residentUuid } = selectedReservation || {}
  const { display, unitName } = residents.find(resident => resident.userId === residentUuid) || {}
  return (<Flex>
    <FlexItem>
      <DeleteReservationModalMessage>Send to: <span>{`${display} / ${unitName}`}</span></DeleteReservationModalMessage>
    </FlexItem>
    <FlexItem>
      <DeleteReservationModalMessage>Message: <span>{selectedReservation.message}</span></DeleteReservationModalMessage>
    </FlexItem>
  </Flex>)
}
