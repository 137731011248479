import { v4 as uuid } from 'uuid'

export const commands = {
  INCLUSION: 'inclusion',
  EXCLUSION: 'exclusion',
  UPDATE: 'update',
  RESTART: 'restart',
  MODE: 'mode',
  HEAT: 'heat',
  COOL: 'cool',
  AUTO: 'auto',
  OFF: 'off',
  ON: 'on',
  LOCK: 'lock',
  UNLOCK: 'unlock'
}

export const createTag = () => `adminWeb-${uuid()}`

const createHubCommand = action => identifier => ({
  name: 'controller',
  action: action,
  target: identifier,
  tag: createTag()
})

const createLockCommand = lock => identifier => ({
  name: 'door_lock_basic',
  action: lock ? commands.LOCK : commands.UNLOCK,
  target: identifier,
  tag: createTag()
})

const createLockStatusCommand = identifier => ({
  name: 'lock_code_status',
  target: identifier,
  tag: createTag()
})

const createLockCodeUpdateCommand = (identifier, resourceCodeId, codeValue) => ({
  name: 'door_lock_code',
  action: 'update',
  code: codeValue,
  resource_code_id: resourceCodeId,
  target: identifier,
  tag: createTag()
})

// create a new lock code
const createLockCodeCreateCommand = (identifier, codeValue, codeType, resourceType, resourceId) => ({
  name: 'door_lock_code',
  action: 'create',
  code: codeValue,
  type: codeType,
  target: identifier,
  tag: createTag(),
  ...(resourceType == null ? {} : { resource_type: resourceType }),
  ...(resourceId == null ? {} : { resource_id: resourceId })
})

const resyncLockCodesCommand = (identifier) => ({
  name: 'ensure_lock_codes',
  target: identifier,
})


// edit an existing lock code
const createLockCodeEditCommand = createLockCodeUpdateCommand

// delete an existing lock code
const createLockCodeDeleteCommand = (identifier, resourceCodeId) => ({
  name: 'door_lock_code',
  action: 'delete',
  resource_code_id: resourceCodeId,
  target: identifier,
  tag: createTag()
})

// extend a lock code
const createLockCodeExtendCommand = (
  identifier,
  resourceCodeId,
  expiration /* must be a unix timestamp */
) => ({
  name: 'door_lock_code',
  action: 'update',
  resource_code_id: resourceCodeId,
  expiration,
  target: identifier,
  tag: createTag()
})

const createThermostatCommand = (attributes, identifier) => ({
  target: identifier,
  tag: createTag(),
  ...createThermostatAttributes(attributes)
})

const createSwitchCommand = state => identifier => ({
  name: 'binary_switch',
  action: state,
  target: identifier,
  tag: createTag()
})

const hubCommands = {
  [commands.INCLUSION]: createHubCommand('add'),
  [commands.EXCLUSION]: createHubCommand('remove'),
  [commands.RESTART]: createHubCommand('restart')
}

const lockCommands = {
  [commands.LOCK]: createLockCommand(true),
  [commands.UNLOCK]: createLockCommand(false),
  getLockCodesStatus: createLockStatusCommand,
  createCode: createLockCodeCreateCommand,
  editCode: createLockCodeEditCommand,
  deleteCode: createLockCodeDeleteCommand,
  extendCode: createLockCodeExtendCommand,
  resyncCodes: resyncLockCodesCommand
}

const switchCommands = {
  [commands.ON]: createSwitchCommand('on'),
  [commands.OFF]: createSwitchCommand('off')
}

export default {
  hub: hubCommands,
  lock: lockCommands,
  switch: switchCommands,
  thermostat: {
    [commands.MODE]: createThermostatCommand,
    [commands.HEAT]: createThermostatCommand,
    [commands.COOL]: createThermostatCommand,
    [commands.AUTO]: createThermostatCommand
  }
}

export const thermostatAttributes = {
  HEAT_SETPOINT: 'heat_setpoint',
  COOL_SETPOINT: 'cool_setpoint',
  COOL_AND_HEAT_SETPOINT: 'cool_and_heat_setpoint',
  TEMPERATURES: 'thermostat_temperatures',
  SYSTEM_MODE: 'thermostat_system_mode'
}

export const createThermostatAttributes = ({ heat, cool, mode }) => ({
  ...(heat && { action: 'heat_setpoint', heat_setpoint: heat }),
  ...(cool && { action: 'cool_setpoint', cool_setpoint: cool }),
  ...(heat && cool && { action: 'cool_and_heat_setpoint' }),
  ...((heat || cool) && { name: 'thermostat_temperatures' }),
  ...(mode && { name: 'thermostat_system_mode', action: mode })
})
