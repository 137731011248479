// @flow
import React from 'react'
import ImageGallery from 'react-image-gallery'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import theme from './../../../theme'
import PhotoUpload from '@Common/PhotoUpload'
import placeholder from '../../../images/amenity_placeholder.png'
import {
    ImageGalleryDiv,
    PlaceholderText,
    PhotoUploadDiv
} from '../AmenityShared/styles'
import { CommonIcon } from '@icons'

const AmenityProDetailImageGallery = (props: Object) => {
    const {
        existingPhotos,
        pinnedPhotoUuid,
        readMode,
        onPhotoUpload,
        onPhotoPinned,
        onPhotoDelete,
        maxCount
    } = props

    const transformPhotoArray = (photos, pinnedPhotoUuid) => {
        const images = []
        if (photos && photos.length > 0) {
            let firstPinned = false

            const pinnedPhotoIndex = photos.findIndex(
                (photo) => (photo.photoUuid === pinnedPhotoUuid),
            )

            if (pinnedPhotoIndex !== -1) {
                const [photo] = photos.splice(pinnedPhotoIndex, 1)
                photos.unshift(photo)
                firstPinned = true
            }

            for (const [i, photo] of photos.entries()) {
                const pinned = firstPinned && (i === 0)
                if (photo instanceof File) {
                    const url = URL.createObjectURL(photo)
                    images.push({ original: url, thumbnail: url, showPin: pinned, name: photo.name })
                } else {
                    images.push({ original: photo.url, thumbnail: photo.url, showPin: pinned, photoUuid: photo.photoUuid })
                }
            }
        } else {
            images.push({ original: placeholder, thumbnail: placeholder, showPin: false })
        }

        return images
    }

    const pinnedItems = transformPhotoArray(existingPhotos, pinnedPhotoUuid);

    const renderDropzone = () => {
        return (
            <PhotoUploadDiv>
                <PhotoUpload
                    uploadedPhotos={existingPhotos}
                    onPhotoUpload={onPhotoUpload}
                    maxCount={maxCount}
                />
            </PhotoUploadDiv>
        )
    }

    const renderThumbInner = (item) => {
        return (
            <div className="image-gallery-thumbnail-inner">
                <img
                    className="image-gallery-thumbnail-image"
                    src={item.thumbnail}
                    alt={item.thumbnailAlt}
                    title={item.thumbnailTitle}
                />
                {readMode && existingPhotos.length > 1 && <CommonIcon onClick={() => onPhotoPinned(item.photoUuid)}
                    class="pin-image" width="16px" height="16px" name={item.showPin ? "pin-filled" : "pin-empty"} />}
                {!readMode && existingPhotos.length > 0 && 
                    <IconButton class='delete-image' onClick={() => { onPhotoDelete(item) }}>
                        <CloseIcon style={{ color: theme.palette.common.white }} />
                    </IconButton>}

                {
                    item.thumbnailLabel && (
                        <div className="image-gallery-thumbnail-label">
                            {item.thumbnailLabel}
                        </div>
                    )
                }
            </div>
        );
    }

    return (
        <ImageGalleryDiv>
            {(!readMode) && renderDropzone()}
            <ImageGallery
                items={pinnedItems}
                showPlayButton={false}
                showFullscreenButton={false}
                showNav={readMode || existingPhotos.length >= maxCount}
                rowHeight={180}
                renderItem={readMode || existingPhotos.length >= maxCount ? undefined : () => <div></div>}
                renderThumbInner={renderThumbInner}
            />
            {
                existingPhotos.length === 0 &&
                <PlaceholderText>Please edit the amenity to add photos</PlaceholderText>
            }
        </ImageGalleryDiv>
    )
}

export default AmenityProDetailImageGallery
