// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import RadioGroup from '@material-ui/core/RadioGroup'
import Flex from '@Common/Flex'
import { CustomFormControlLabel, CustomFormControl, Radio } from './styles'

const RadioButtons = ({
  selectedValue,
  defaultValue,
  name,
  handleChange,
  options,
  textSize,
  handleClick = () => {},
  width,
  prefix,
  marginLeft,
  noUnderline,
  error = false
}) => (
  <CustomFormControl
    noUnderline={noUnderline}
    marginLeft={marginLeft}
    textSize={textSize}
    width={width}
    component="fieldset"
    classNames={{ root: 'form-cont{rol-radio-button' }}>
    <Flex alignCenter justifySpaceBetween>
      {prefix}
      <RadioGroup
        name={name}
        classes={{ root: 'radio-button-group' }}
        value={selectedValue}
        defaultValue={defaultValue}
        onChange={handleChange}>
        {options.map(({ id, value, label, disabled, classLabel = '' }, index) => (
          <CustomFormControlLabel
            lastRow={index === options.length - 1}
            classes={{
              root: 'radio-button-control-label',
              label: classLabel
            }}
            key={`${index}-${value}`}
            value={value}
            control={<Radio id={id} />}
            label={label}
            disabled={disabled}
            onClick={handleClick}
            error={error}
          />
        ))}
      </RadioGroup>
    </Flex>
  </CustomFormControl>
)

export default withTheme(RadioButtons)
