// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import { Link, withRouter } from 'react-router-dom'
import type { Theme } from '../../../theme'
import BaseCard from '@Common/Cards/BaseCard'
import CardBodyWrap from '@Common/Cards/CardBodyWrap'
import FooterLink from '@Common/Cards/CardFooterLink'
import CircleWithText from '@Common/Cards/CircleWithText'
import Flex, { FlexItem } from '@Common/Flex'
import { AnnouncementText, CenteredText } from './styles'
import CommunicationCardImage from '.././../../images/communication.png'

type Props = {
  unreadCount: number,
  columnStart: number,
  columnSpan: number,
  rowStart: number,
  rowSpan: number,
  count?: number,
  text: string,
  theme: Theme,
  viewMoreUrl: string,
  baseUrl: string,
  match: { params: { propertyId: string } }
}

type State = {
  footer: {}
}

export class ChatCard extends React.Component<Props, State> {
  getContent = () => {
    const { text, count, baseUrl } = this.props
    return (
      <CardBodyWrap>
        <Flex fullWidth alignCenter justifySpaceBetween paddingRight="2em">
          <AnnouncementText>{text}</AnnouncementText>
          {count != null && !isNaN(count) && (
            <FlexItem>
              <Link to={`${baseUrl}/communication/chat/live-chat?filter=unread`}>
                <CircleWithText
                  dataCount={count}
                  backgroundColor={({ theme }) => theme.palette.primary.green.mint}
                  textColor={({ theme }) => theme.palette.background.default}
                />
                <CenteredText>UNREAD CHATS</CenteredText>
              </Link>
            </FlexItem>
          )}
        </Flex>
      </CardBodyWrap>
    )
  }

  render() {
    const { viewMoreUrl } = this.props

    return (
      <BaseCard
        title="Communications"
        columnStart={5}
        columnSpan={8}
        rowStart={9}
        rowSpan={4}
        imageSrc={CommunicationCardImage}
        backgroundsize="contain"
        content={this.getContent}
        headerRight={null}
        footer={() => (
          <div>
            <FooterLink text="View More +" to={viewMoreUrl} />
          </div>
        )}
      />
    )
  }
}

export default withRouter(withTheme(ChatCard))
