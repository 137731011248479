// @flow

import React from 'react'
import { generate as shortid } from 'shortid'
import { ResponsiveBar } from '@nivo/bar'
import BaseCard from '@Common/Cards/BaseCard'
import Flex, { FlexItem } from '@Common/Flex'
import { withTheme } from 'emotion-theming'
import type { Theme } from '../../../theme'
import { AppUsageFlex } from './styles'

type Props = {
  data: Object,
  theme: Theme
}

export class ResidentAppUsageCard extends React.Component<
  Props,
  { highlighted: number | null }
> {
  state = {
    highlighted: null
  }

  getTickValues = (maxValue: number) => {
    return [0, Math.round(0 + maxValue / 2), Math.round(maxValue)]
  }

  hightlightKey = (key: number | null) => {
    this.setState({ highlighted: key })
  }

  customBar = (
    maxValue: number,
    { index, indexValue, data: { id, value, data } }: Object
  ) => {
    const { highlighted } = this.state
    const width = (value / maxValue) * 100
    const position = 100 - width
    if (id === 'at least once')
      return (
        <React.Fragment>
          {highlighted === 1 && (
            <text fontSize="12" x={`${position - 8}%`} y={`30%`} fill="black">
              {value}
            </text>
          )}
          <rect
            onMouseEnter={this.hightlightKey.bind(this, 1)}
            onMouseLeave={this.hightlightKey.bind(this, null)}
            y={`25%`}
            x={`${position}%`}
            height="1em"
            width={`${width}%`}
            fill={data['at least onceColor']}
          />
        </React.Fragment>
      )

    if (id === 'in the last 7 days')
      return (
        <React.Fragment>
          {highlighted === 2 && (
            <text fontSize="12" y={`45%`} fill="black" x={`${position - 8}%`}>
              {value}
            </text>
          )}
          <rect
            x={`${position}%`}
            y={`40%`}
            height="1em"
            width={`${width}%`}
            fill={data['in the last 7 daysColor']}
            onMouseEnter={this.hightlightKey.bind(this, 2)}
            onMouseLeave={this.hightlightKey.bind(this, null)}
          />
        </React.Fragment>
      )

    return (
      <React.Fragment>
        {highlighted === 3 && (
          <text fontSize="12" y={`60%`} fill="black" x={`${position - 8}%`}>
            {value}
          </text>
        )}
        <rect
          x={`${position}%`}
          y={`55%`}
          height="1em"
          width={`${width}%`}
          fill={data['in the last 30 daysColor']}
          onMouseEnter={this.hightlightKey.bind(this, 3)}
          onMouseLeave={this.hightlightKey.bind(this, null)}
        />
      </React.Fragment>
    )
  }

  getGraphContent = () => {
    const {
      data: {
        rate,
        graph: { keys, maxValue, data }
      },
      theme
    } = this.props

    return (
      <Flex direction="column" fullWidth>
        <FlexItem flex={1} style={{ paddingTop: '2em' }}>
          {rate.map(({ text, percentage, color }, index) => (
            <AppUsageFlex
              paddingLeft="0.5em"
              paddingRight="0.5em"
              alignCenter
              key={shortid()}
              justifySpaceBetween
              style={{
                height: '2.2em'
              }}>
              <FlexItem>
                <font color="grey"> Opened the app </font> {text}{' '}
              </FlexItem>
              <FlexItem>
                <font color={theme.palette.primary.dark}>{percentage}%</font>
              </FlexItem>
            </AppUsageFlex>
          ))}
        </FlexItem>
        <FlexItem flex={1}>
          {maxValue === 0 ? (
            <font color="grey">No Resident Data Available</font>
          ) : (
            <ResponsiveBar
              barComponent={data => this.customBar(maxValue, data)}
              minValue={0}
              maxValue={maxValue}
              data={data}
              keys={keys}
              indexBy="type"
              margin={{
                top: 0,
                right: 5,
                bottom: 60,
                left: 10
              }}
              height={270}
              innerPadding={25}
              padding={0.2}
              groupMode="grouped"
              layout="horizontal"
              reverse={true}
              colors={[
                theme.palette.primary.main,
                theme.palette.secondary.light2,
                theme.palette.primary.green.mint
              ]}
              colorBy="id"
              defs={[]}
              fill={[]}
              borderColor="inherit:darker(1.6)"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickValues: this.getTickValues(maxValue),
                format: e => {
                  if (Math.floor(e) !== e) {
                    return
                  }
                  return e
                },
                tickSize: 6,
                tickPadding: 6,
                tickRotation: 0,
                legend: 'residents',
                legendPosition: 'end',
                legendOffset: 30
              }}
              axisLeft={null}
              enableGridY={false}
              labelSkipWidth={1200}
              labelSkipHeight={120}
              labelTextColor="inherit:darker(1.6)"
              animate={false}
              theme={{
                axis: {
                  ticks: {
                    line: {
                      stroke: '#808a95'
                    },
                    text: {
                      fill: '#808a95'
                    }
                  }
                }
              }}
              isInteractive={false}
            />
          )}
        </FlexItem>
      </Flex>
    )
  }

  render() {
    return (
      <BaseCard
        title="Resident App Usage"
        columnStart={9}
        columnSpan={4}
        rowStart={1}
        rowSpan={8}
        content={this.getGraphContent}
      />
    )
  }
}

export default withTheme(ResidentAppUsageCard)
