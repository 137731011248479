// @flow

import React from 'react'
import AddUserStep from './AddUserStep'
import { AddUserBody, Select } from './styles'

import type { AddUserStepProps, UnitType } from './types'

const getUnitOptions = (units: Array<UnitType>) =>
  units.map(({ id, name }) => ({ label: name, value: id }))

export default class ChooseUnit extends React.Component<AddUserStepProps> {
  handleChange = ({ value }: { value: string }) => {
    const { handleDataChange } = this.props
    handleDataChange({ selectedUnitId: value }, false)
  }

  render() {
    const { units, fetchUnits, isFetchingUnits } = this.props
    return (
      <AddUserStep
        title="SELECT A UNIT"
        subtitle="Please search for the unit to which you would like to add this user.">
        <AddUserBody>
          <Select
            onInputChange={fetchUnits}
            onChange={this.handleChange}
            options={getUnitOptions(units)}
            noOptionsMessage={() =>
              isFetchingUnits ? 'Searching' : 'No Options'
            }
          />
        </AddUserBody>
      </AddUserStep>
    )
  }
}
