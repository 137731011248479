import styled from '@emotion/styled'
import DropDown from '@Common/DropDown'
import TextField from '@Common/TextField'
import Flex from '@Common/Flex'

export const OffsetWrapper = styled(Flex)`
  height: fit-content;
  margin: -24px 30px 0 48px;
  width: 100px;
  & label {
    z-index: 1;
  }
`

export const OffsetLabel = styled('label')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.grey.main};
  width: 50%;
  margin-bottom: 0.5em;
`

export const BeforeAndAfterDropDown = styled(DropDown)`
  width: 100px;
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
`

export const TextFieldOffset = styled(TextField)`
  width: 100%;
  border-radius: 3em;
  display: flex;
  border: 1px solid ${({ theme }) => theme.palette.neutrals.deepGray};
  padding: 0em 1em;

  & svg {
    position: absolute;
    right: 1em;
    top: 0.5em;
  }
`
