// @flow

import React from 'react'
import Button from '@Common/Cards/CardButton'
import Flex from '@Common/Flex'
import type { Theme } from '../../../../theme'

const SelectedMethodDetails = ({
  select,
  theme,
  value,
  elementId
}: {
  select: Function,
  theme: Theme,
  value: string,
  elementId?: string
}) => (
  <React.Fragment>
    <Flex
      justifySpaceBetween
      style={{
        borderTop: `1px solid ${theme.palette.neutrals.deepGray}`,
        padding: '1em'
      }}>
      <span id={elementId}>{value}</span>
      <Button
        id="selectBtn"
        text={'SELECT'}
        width="8em"
        onClick={select}
        background={props => props.theme.palette.blue.main}
      />
    </Flex>
  </React.Fragment>
)

export default SelectedMethodDetails
