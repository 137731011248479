import { call, delay, select, takeLatest, put } from 'redux-saga/effects'
import { getToken } from '../../../authorization/selectors'
import { getPropertyId } from '../../../select/selectors'
import {
  fetchUnits as fetchUnitsAction,
  fetchUnitsStart,
  fetchUnitsError,
  fetchUnitsSuccess,
  fetchUnit as fetchUnitAction,
  fetchUnitStart,
  fetchUnitError,
  fetchUnitSuccess,
  refreshUnit as refreshUnitAction,
  refreshUnitStart,
  refreshUnitError,
  refreshUnitSuccess
} from '../actions'
import { getUnitId } from '../selectors'
import api from '../../../../utils/api'
import log from '../../../../utils/logger'
import { noUnitSelected } from '../../../../utils/messages'

const getActions = isRefreshing => ({
  start: isRefreshing ? refreshUnitStart : fetchUnitStart,
  success: isRefreshing ? refreshUnitSuccess : fetchUnitSuccess,
  error: isRefreshing ? refreshUnitError : fetchUnitError
})

export function* fetchUnits({ name }) {
  if (name === '') {
    log('name is empty')
    return
  }

  // go ahead and notify fetch start
  // so UI can show loading
  yield put(fetchUnitsStart())

  // debounce by 400ms
  yield delay(400)

  try {
    const authToken = yield select(getToken)
    // TODO get property id from somewhere else after implementing property switching
    const propertyId = yield select(getPropertyId)
    const params = { propertyId, name }
    const response = yield call(api.getUnits, authToken, params)

    yield put(fetchUnitsSuccess(response))
  } catch (error) {
    yield put(fetchUnitsError())
    log(`Failed to fetch units. Error: ${error}`)
  }
}

export function* getUnit({ id, isRefreshing }) {
  const actions = getActions(isRefreshing)
  try {
    const unitId = id ? id : yield select(getUnitId)
    const authToken = yield select(getToken)

    if (unitId === -1) throw new Error(noUnitSelected)

    yield put(actions.start())
    const response = yield call(api.getUnit, unitId, authToken)
    yield put(actions.success(response))
  } catch (error) {
    log(`Failed to refresh unit. Error: ${error}`)
    yield put(actions.error(error))
  }
}

function* watchFetchUnits() {
  yield takeLatest(fetchUnitsAction().type, fetchUnits)
}

function* watchFetchUnit() {
  yield takeLatest(fetchUnitAction().type, getUnit)
}

function* watchRefreshUnit() {
  yield takeLatest(refreshUnitAction().type, getUnit)
}

export default [watchFetchUnits(), watchFetchUnit(), watchRefreshUnit()]
