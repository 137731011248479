import { combineReducers } from 'redux'
import {
  POSTS,
  FILTER_POSTS_SUCCESS,
  SET_FILTER_VALUES_TO_STATE,
  CANCEL_FILTER_POSTS
} from './postsActions'
import withFetchNext from '../helpers/reducers/withFetchNext'

const posts = (state = null, action) => {
  switch (action.type) {
    case FILTER_POSTS_SUCCESS:
      return {
        ...state,
        data: action.posts.data,
        totalPages: action.posts.headers['total-pages']
      }
    case SET_FILTER_VALUES_TO_STATE:
      return {
        ...state,
        date: action.date,
        userId: action.userId,
        threadType: action.threadType,
        sortBy: action.sortBy
      }
    case CANCEL_FILTER_POSTS:
      return {
        ...state,
        date: null,
        userId: null,
        threadType: null,
        sortBy: null
      }
    default:
      return state
  }
}

export default withFetchNext(POSTS)(posts)
