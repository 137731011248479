import { GROUP_AUTOMATION, fetchGroupAutomationSuccess } from './actions'
import withFetch from '../../helpers/reducers/withFetch'

const groupAutomation = (state = {}, action) => {
    switch(action.type) {
        default:
            return state
    }
}

export default withFetch(GROUP_AUTOMATION)(groupAutomation)