import { connect } from 'react-redux'
import BranchIO from './BranchIO'
import { sendBranchSms } from 'zego-shared/store/branch/actions'

const mapStateToProps = (
  state,
  {
    match: {
      params: { app }
    }
  }
) => ({
  appType: app
})

const mapDispatchToProps = dispatch => ({
  sendBranchSms: (appType, phoneNumber) =>
    dispatch(sendBranchSms(appType, phoneNumber))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BranchIO)
