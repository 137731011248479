// @flow

import React from 'react'
import isEqual from 'lodash/isEqual'
import some from 'lodash/some'
import moment, { Moment } from 'moment'
import { generate as shortid } from 'shortid'
import MenuItem from '@material-ui/core/MenuItem'
import ClickOutside from 'react-click-outside'
import { withTheme } from 'emotion-theming'
import Flex, { FlexItem } from '@Common/Flex'
import { GridItem } from '../../Common/Grid'
import HeaderWithSub from '../../Common/HeaderWithSub'
import Avatar from '@Common/Avatar'
import MultiToggle from '../../Common/MultiToggle'
import Calendar from '@Common/Calendar'
import AlertModal from '@Common/AlertModal/AlertModal'
import DropDown from '@Common/DropDown'
import HorizRadioButtons from '../../Common/HorizRadioButtons'
import { Plus } from '../../Common/InputAdornments'
import Loader from '../../Common/Loader'
import CTAButtons from '../../Common/CTAButtons'
import PetColorRadioGroup from './PetColorRadioGroup'

import { getDate, selectItem } from '../../../utils'
import { isValidImageFile } from '../../../utils/validationUtils'

import {
  Container,
  Content,
  Image,
  TextField,
  Dates,
  Value,
  CalendarWrapper,
  AvatarInput
} from './styles'
import { menuItemFocus } from '@Common/DropDown/styles'

import type { Theme } from '../../../theme'
import { BrowserHistory } from 'history'
import type { Match } from 'react-router-dom'

const radioOptions = [
  {
    value: 'Yes',
    label: 'Yes'
  },
  {
    value: 'No',
    label: 'No'
  }
]

type Props = {
  hasData: boolean,
  isFetching: boolean,
  item: Object,
  petTypes: Array<Object>,
  petSizes: Array<Object>,
  petStatuses: Array<Object>,
  residents: Array<Object>,
  petColors: Array<Object>,
  addMode: boolean,
  theme: Theme,
  history: BrowserHistory,
  match: Match,
  updatePet: Object => mixed
}

type State = {
  editMode: boolean,
  addMode: boolean,
  showDialog: boolean,
  showColorList: boolean,
  item: Object,
  showBirthdayPicker: boolean,
  showLastVaccinatedPicker: boolean,
  edited: boolean,
  error: boolean
}

class Pet extends React.Component<Props, State> {
  ownerItems = null

  state = {
    showDialog: false,
    showColorList: false,
    editMode: false,
    addMode: this.props.addMode,
    item: this.props.item,
    showBirthdayPicker: false,
    showLastVaccinatedPicker: false,
    edited: false,
    error: false
  }

  vaccinationInput = React.createRef()

  componentDidMount() {
    this.ownerItems = document.getElementsByClassName('owner-item-js')
  }

  componentDidUpdate(prevProps) {
    const { item } = this.props
    if (!isEqual(prevProps.item, item)) {
      this.setState({
        item: this.props.item
      })
    }

    if (!this.ownerItems) {
      this.ownerItems = document.getElementsByClassName('owner-item-js')
    }
  }

  goBack = () => {
    const {
      history,
      match: {
        params: { propertyId }
      }
    } = this.props

    const { editMode, addMode, edited } = this.state

    if (addMode || editMode) {
      if (edited) {
        this.setState({ showDialog: true })
      } else {
        this.exitSave()
      }
    } else {
      let url = `/properties/${propertyId}/registration?filter=pets`
      history.push(url)
    }
  }

  exitSave = () => {
    const {
      history,
      match: { url }
    } = this.props

    const { editMode, addMode } = this.state

    let splitText = '/pets'
    if (editMode) {
      splitText = '/edit'
    }
    let backUrl = url.split(splitText)[0]
    if (addMode) {
      backUrl = `${backUrl}?filter=pets`
    }
    this.setState({
      showDialog: false,
      addMode: false,
      editMode: false
    })
    history.push(backUrl)
  }

  savePet = () => {
    const { item } = this.state
    const error = this.hasErrors()
    if (!error) {
      this.props.updatePet(item)
      this.setState({
        showDialog: false,
        addMode: false,
        editMode: false
      })
    }
  }

  closeAlertModal = () => {
    this.setState({ showDialog: false })
  }

  openDialog = () => {
    this.setState({ showDialog: true })
  }
  hideColorList = () => {
    this.setState({ showColorList: false })
  }

  showColorList = () => {
    this.setState({ showColorList: true })
  }

  showVaccinationInput = () => {
    if (this.vaccinationInput.current != null) {
      this.vaccinationInput.current.click()
    }
  }

  setColorList = e => {
    this.hideColorList()
  }

  changeColorList = (e: Object, d: boolean) => {
    const changedColor = e.target.name
    let { color } = this.state.item
    if (d) {
      color = [...color, changedColor]
    } else {
      color = color.filter(c => c !== changedColor)
    }
    this.updateItem({ color })
  }

  handleClickOutside = () => {
    this.setState({
      showBirthdayPicker: false,
      showLastVaccinatedPicker: false
    })
  }

  changeApprovalItem = (
    _event: SyntheticEvent<HTMLInputElement>,
    status: string
  ) => {
    this.updateItem({ status }, true)
  }

  toggleEditMode = () => {
    let { addMode, editMode, edited } = this.state
    const error = this.hasErrors()
    if (edited && (editMode || addMode)) this.savePet()
    if (!addMode && !editMode) {
      this.setState({ editMode: true })
    } else if (!error) {
      if (editMode) editMode = false
      if (addMode) addMode = false
      this.setState({ editMode, addMode })
    }
  }

  toggleDatePicker = value => {
    this.setState({
      [`show${value}Picker`]: !this.state[`show${value}Picker`],
      [`show${
        value === 'Birthday' ? 'LastVaccinated' : 'Birthday'
      }Picker`]: false
    })
  }

  setBirthday = (selectedDate: Moment) => {
    this.updateItem({
      birthday: selectedDate.toISOString()
    })
    this.handleClickOutside()
  }

  setLastVaccinated = (selectedDate: Moment) => {
    this.updateItem({
      lastVaccinated: selectedDate.toISOString()
    })
    this.handleClickOutside()
  }

  getTitle = () => {
    const { item, addMode, editMode } = this.state
    if (addMode) return 'NEW PET'
    if (editMode) return 'PET DETAILS EDIT'
    if (item.name == null) return ''
    return item.name.toUpperCase()
  }

  uploadPetImage = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const photo = event.target.files[0]

    if (isValidImageFile(photo)) {
      this.updateItem({ photo })
    }
  }
  uploadVaccinationFile = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const file = event.target.files[0]

    if (file && file.name.match(/\.(pdf)$/)) {
      this.updateItem({ vaccinationRecord: file })
    }
  }

  updateItem = (update, shouldCallAPI = false) => {
    this.setState(
      oldState => {
        return {
          ...oldState,
          item: { ...oldState.item, ...update },
          edited: true
        }
      },
      () => {
        if (shouldCallAPI) {
          this.savePet()
        }
      }
    )
  }

  handleChange = ({
    currentTarget: { id, value }
  }: SyntheticEvent<HTMLInputElement>) => {
    this.updateItem({ [id]: value })
  }

  changeOwner = e => {
    const { userId, unitId, unitName, display } = e.target.value
    this.updateItem({ ownerId: userId, unit: unitName, owner: display, unitId })
  }
  changePetType = (type, _item) => {
    this.updateItem({ type })
  }
  changePetSize = (size, _item) => {
    this.updateItem({ size })
  }

  selectHouseBroken = e => {
    this.updateItem({ houseBroken: e.currentTarget.value })
  }
  selectTherapyOrService = e => {
    this.updateItem({ serviceTherapyAnimal: e.currentTarget.value })
  }

  ownerKeyPress = ({ key }) => {
    selectItem(key, this.ownerItems)
  }

  isError = field => {
    const { edited, item } = this.state
    if (edited && [null, '', undefined].includes(item[field])) {
      return true
    }
    return false
  }
  hasErrors = () => some(['name'], this.isError)

  render() {
    const {
      addMode,
      editMode,
      item,
      showBirthdayPicker,
      showLastVaccinatedPicker,
      showDialog,
      showColorList
    } = this.state
    const {
      theme,
      petTypes,
      petSizes,
      petColors,
      petStatuses,
      residents,
      hasData,
      isFetching
    } = this.props
    const readMode = !addMode && !editMode
    if (isFetching) return <Loader />
    if (!hasData && !readMode) return null
    const picture =
      item.photo == null || typeof item.photo === 'string'
        ? item.photo
        : URL.createObjectURL(item.photo)
    
    //removing display text from default option
    petTypes[0].name = ''

    return (
      <React.Fragment>
        <GridItem columnStart={1} columnSpan={6} rowSpan={2} rowStart={1}>
          <HeaderWithSub subheader="REGISTRATION" header={this.getTitle()} />
        </GridItem>
        <GridItem
          columnStart={6}
          columnSpan={7}
          rowSpan={2}
          rowStart={1}
          alignCenter
          justifyEnd>
          <Flex paddingRight={'13em'}>
            {readMode && (
              <MultiToggle
                items={petStatuses}
                selectedItem={item.status}
                handleChange={this.changeApprovalItem}
              />
            )}
          </Flex>
        </GridItem>
        <Container>
          <Image imageSrc="/" />
          <CTAButtons
            toggleEditMode={this.toggleEditMode}
            goTo={this.goBack}
            isEditing={!readMode}
          />
          <Content>
            <Flex fullWidth>
              <FlexItem flex={0.7}>
                <label htmlFor="file-input">
                  <Avatar
                    size="192px"
                    picture={picture}
                    showOverlay={!readMode}
                    alwaysShowOverlay
                    overlayContent={() => (
                      <React.Fragment>
                        <span>upload new +</span>
                        <AvatarInput
                          id="file-input"
                          type="file"
                          onChange={this.uploadPetImage}
                        />
                      </React.Fragment>
                    )}
                  />
                </label>
              </FlexItem>
              <FlexItem flex={2}>
                <Flex>
                  <FlexItem flex={1} paddingLeft={'35px'}>
                    <TextField
                      error={this.isError('name')}
                      onChange={this.handleChange}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      alwaysShowLabel={!readMode}
                      textAlign={readMode ? 'right' : 'left'}
                      prefix={readMode ? 'Pet Name' : null}
                      label={readMode ? null : 'Pet Name'}
                      id="name"
                      value={item.name}
                      showPencil={!readMode}
                      readOnly={readMode}
                    />
                    <TextField
                      onChange={this.changeOwner}
                      fullWidth
                      InputLabelProps={{ shrink: true, margin: 'none' }}
                      alwaysShowLabel={!readMode}
                      textAlign={readMode ? 'right' : 'left'}
                      prefix={readMode ? 'Owner' : null}
                      label={readMode ? null : 'Owner'}
                      id="owner"
                      value={item.owner}
                      showPencil={false}
                      readOnly={readMode}
                      select={!readMode}
                      onKeyPress={this.ownerKeyPress}
                      SelectProps={{
                        renderValue: value => value,
                        disableUnderline: false
                      }}>
                      {residents.map(resident => (
                        <MenuItem
                          key={shortid()}
                          selected={resident.userId === item.ownerId}
                          style={{ display: 'flex', alignItems: 'center' }}
                          value={resident}
                          className={`owner-item-js ${menuItemFocus}`}>
                          {resident.display}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      onChange={this.handleChange}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      alwaysShowLabel={!readMode}
                      textAlign={readMode ? 'right' : 'left'}
                      prefix={readMode ? 'Unit' : null}
                      label={readMode ? null : 'Unit'}
                      id="unit"
                      value={item.unit}
                      showPencil={false}
                      readOnly
                    />
                    {readMode ? (
                      <TextField
                        fullWidth
                        id="type"
                        prefix="Type"
                        value={item.type}
                        showPencil={false}
                        readOnly
                      />
                    ) : (
                      <DropDown
                        handleChange={this.changePetType}
                        data={petTypes}
                        selectedItem={
                          petTypes.find(({ id }) => id === item.type) ||
                          petTypes[0]
                        }
                        alwaysShowLabel
                        label="Type"
                        id="type"
                        width="100%"
                        fontSmall
                        style={{
                          margin: `0 0 10px 10px`,
                          borderBottom: `1px solid ${theme.palette.neutrals.coolGray}`,
                          fontSize: `${theme.custom.font.size.sm}`,
                          fontWeight: `normal`,
                        }}
                      />
                    )}

                    <div>
                      <TextField
                        fullWidth
                        hideCaret
                        onClick={() => {
                          this.toggleDatePicker('Birthday')
                        }}
                        InputLabelProps={{ shrink: true }}
                        alwaysShowLabel={!readMode}
                        textAlign={readMode ? 'right' : 'left'}
                        prefix={readMode ? 'Birthday' : null}
                        label={readMode ? null : 'Birthday'}
                        id="birthday"
                        value={
                          item.birthday == null ? 'NA' : getDate(item.birthday)
                        }
                        showPencil={!readMode}
                        readOnly={readMode}
                        endAdornment={readMode ? null : 'calendar'}
                        endAdornmentOnClick={() => {
                          this.toggleDatePicker('Birthday')
                        }}
                      />
                      {showBirthdayPicker && (
                        <ClickOutside onClickOutside={this.handleClickOutside}>
                          <CalendarWrapper>
                            <Calendar
                              moment={moment(item.birthday || new Date())}
                              handleChange={this.setBirthday}
                              previousDatesEnabled
                            />
                          </CalendarWrapper>
                        </ClickOutside>
                      )}
                    </div>
                    <TextField
                      onChange={this.handleChange}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      alwaysShowLabel={!readMode}
                      textAlign={readMode ? 'right' : 'left'}
                      prefix={readMode ? 'Breed' : null}
                      label={readMode ? null : 'Breed'}
                      id="breed"
                      value={item.breed}
                      showPencil={!readMode}
                      readOnly={readMode}
                    />
                  </FlexItem>
                  <FlexItem flex={1} paddingLeft={'35px'}>
                    <TextField
                      onChange={this.handleChange}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      alwaysShowLabel={!readMode}
                      textAlign={readMode ? 'right' : 'left'}
                      prefix={readMode ? 'Weight' : null}
                      label={readMode ? null : 'Weight'}
                      id="weight"
                      type="number"
                      value={item.weight}
                      showPencil={!readMode}
                      readOnly={readMode}
                    />
                    {readMode ? (
                      <TextField
                        fullWidth
                        id="size"
                        prefix="Size"
                        value={item.size}
                        showPencil={false}
                        readOnly
                      />
                    ) : (
                      <DropDown
                        handleChange={this.changePetSize}
                        data={petSizes}
                        selectedItem={
                          petSizes.find(({ id }) => id === item.size) ||
                          petSizes[0]
                        }
                        alwaysShowLabel
                        label="Size"
                        id="size"
                        width="100%"
                        fontSmall
                        style={{
                          margin: `0 0 10px 10px`,
                          borderBottom: `1px solid ${theme.palette.neutrals.coolGray}`,
                          fontSize: `${theme.custom.font.size.sm}`,
                          fontWeight: `normal`,
                        }}
                      />
                    )}
                    <div>
                      <TextField
                        fullWidth
                        hideCaret
                        onClick={() => {
                          this.toggleDatePicker('LastVaccinated')
                        }}
                        InputLabelProps={{ shrink: true }}
                        alwaysShowLabel={!readMode}
                        textAlign={readMode ? 'right' : 'left'}
                        prefix={readMode ? 'Last Vaccination' : null}
                        label={readMode ? null : 'Last Vaccination'}
                        id="lastvaccination"
                        value={
                          item.lastVaccinated == null
                            ? 'NA'
                            : getDate(item.lastVaccinated)
                        }
                        showPencil={!readMode}
                        readOnly={readMode}
                        endAdornment={readMode ? null : 'calendar'}
                        endAdornmentOnClick={() => {
                          this.toggleDatePicker('LastVaccinated')
                        }}
                      />
                      {showLastVaccinatedPicker && (
                        <ClickOutside onClickOutside={this.handleClickOutside}>
                          <CalendarWrapper>
                            <Calendar
                              moment={moment(item.lastVaccinated || new Date())}
                              handleChange={this.setLastVaccinated}
                              previousDatesEnabled
                            />
                          </CalendarWrapper>
                        </ClickOutside>
                      )}
                    </div>
                    <TextField
                      fullWidth
                      hideCaret
                      onClick={this.showVaccinationInput}
                      InputLabelProps={{ shrink: true }}
                      alwaysShowLabel={!readMode}
                      textAlign={readMode ? 'right' : 'left'}
                      prefix={readMode ? 'Vaccinations' : null}
                      label={readMode ? null : 'Vaccinations'}
                      id="vaccination"
                      value={item.vaccinationRecord == null ? 'NA' : 'On File'}
                      showPencil={false}
                      readOnly={readMode}
                      endAdornmentNode={
                        readMode ? null : (
                          <React.Fragment>
                            <Plus onClick={this.showVaccinationInput} />
                            <input
                              type="file"
                              style={{ display: 'none' }}
                              onChange={this.uploadVaccinationFile}
                              ref={this.vaccinationInput}
                            />
                          </React.Fragment>
                        )
                      }
                    />
                    {readMode ? (
                      <TextField
                        fullWidth
                        id="housebroken"
                        prefix="HouseBroken"
                        value={item.houseBroken}
                        showPencil={!readMode}
                        readOnly={readMode}
                      />
                    ) : (
                      <HorizRadioButtons
                        textAlign={readMode ? 'right' : 'left'}
                        prefix={
                          <span style={{ fontSize: '12px', paddingRight: '10px' }}>
                            House Broken
                          </span>
                        }
                        handleChange={this.selectHouseBroken}
                        handleClick={this.selectHouseBroken}
                        selectedValue={item.houseBroken}
                        options={radioOptions}
                        name="houseBroken"
                        textSize="0.95em"
                        marginLeft="10px"
                        marginRight="10px"
                      />
                    )}
                    {readMode ? (
                      <TextField
                        fullWidth
                        id="therapy"
                        prefix="Therapy/Service Animal"
                        value={item.serviceTherapyAnimal}
                        showPencil={!readMode}
                        readOnly={readMode}
                      />
                    ) : (
                      <HorizRadioButtons
                        textAlign={readMode ? 'right' : 'left'}
                        prefix={
                          <span style={{ fontSize: '12px', paddingRight: '10px' }}>
                            Therapy/Service Animal
                          </span>
                        }
                        handleChange={this.selectTherapyOrService}
                        handleClick={this.selectTherapyOrService}
                        selectedValue={item.serviceTherapyAnimal}
                        options={radioOptions}
                        name="therapy"
                        textSize="0.95em"
                        marginLeft="10px"
                        marginRight="10px"
                      />
                    )}
                  </FlexItem>
                </Flex>
                <Flex paddingLeft={'35px'} width={'50%'}>
                  <TextField
                    fullWidth
                    hideCaret
                    onClick={this.showColorList}
                    InputLabelProps={{ shrink: true }}
                    alwaysShowLabel={!readMode}
                    textAlign={readMode ? 'right' : 'left'}
                    prefix={readMode ? 'Color' : null}
                    label={readMode ? null : 'Color'}
                    id="color"
                    value={item.color.join(', ')}
                    showPencil={false}
                    readOnly={readMode}
                    endAdornmentNode={
                      readMode ? null : <Plus onClick={this.showColorList} />
                    }
                  />
                </Flex>
              </FlexItem>
            </Flex>
          </Content>
          {readMode && (
            <Dates>
              <Flex>
                <FlexItem paddingRight={'4em'}>
                  Submitted <Value>{getDate(item.submittedDate)}</Value>
                </FlexItem>
                Last Updated{' '}
                <Value>
                  {item.resolutionDate == null
                    ? 'NA'
                    : getDate(item.resolutionDate)}
                </Value>
              </Flex>
            </Dates>
          )}
        </Container>
        <AlertModal
          title="Would you like to save before leaving?"
          message=""
          buttons={[
            {
              onClick: this.savePet,
              text: 'YES'
            },
            {
              onClick: this.exitSave,
              color: theme.palette.primary.navy.spaceBlue,
              text: 'NO'
            }
          ]}
          show={showDialog}
          onClose={this.closeAlertModal}
        />
        <AlertModal
          title="Select all colors that apply"
          message={
            <PetColorRadioGroup
              petColors={petColors}
              selectedColors={item.color}
              handleColorChange={this.changeColorList}
            />
          }
          buttons={[
            {
              onClick: this.setColorList,
              color: theme.palette.primary.navy.spaceBlue,
              text: 'DONE'
            }
          ]}
          show={showColorList}
          onClose={this.hideColorList}
        />
      </React.Fragment>
    )
  }
}

export default withTheme(Pet)
