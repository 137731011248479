// @flow
const lockType = meta_data =>
  meta_data['user_code_1'] !== undefined ? 'resident' : 'temporary'

const defaultMessage = ({ user_name, info: { message } }) =>
  user_name ? `${message} by ${user_name}` : `${message}`

const deviceDisconnected = ({ user_name }) => {
  return user_name ? `disconnected by ${user_name}` : `disconnected`
}

const codeUsed = ({ user_name, meta_data }) => {
  return user_name
    ? `${lockType(meta_data)} code used by ${user_name}`
    : `${lockType(meta_data)} code used`
}

const codeCreated = ({ user_name, meta_data }) => {
  return user_name
    ? `${lockType(meta_data)} code created by ${user_name}`
    : `${lockType(meta_data)} code created`
}

const codeRemoved = ({ user_name, meta_data }) => {
  return user_name
    ? `${lockType(meta_data)} code deleted by ${user_name}`
    : `${lockType(meta_data)} code deleted`
}

const lockedUnlocked = ({
  user_name,
  info: { message },
  meta_data: { action_type, code_index }
}) => {
  'lock button'
  let notificationText = ``
  switch (action_type) {
    case 'manual':
      notificationText = `${message} with key`
      break
    case 'keypad':
      if (user_name) {
        notificationText = `${message} by ${user_name}`
      }
      if (code_index === 'temporary') {
        notificationText = `${message} using temporary code`
      }
      if (code_index === 'resident') {
        notificationText = `${message} using resident code`
      }
      if (code_index === 'lock button') {
        notificationText = `${message} using keypad`
      }
      if (notificationText === '') {
        notificationText = `${message}`
      }
      break
    case 'auto':
      notificationText = `${message} automatically`
      break
    default:
      notificationText = user_name ? `${message} by ${user_name}` : `${message}`
      break
  }
  return notificationText
}

const systemModeChanged = ({
  user_name,
  meta_data: { system_mode },
  info: { message }
}) => {
  return user_name
    ? `${message} to ${system_mode} by ${user_name}`
    : `${message} to ${system_mode}`
}

const heatSPChanged = ({
  user_name,
  info: { message },
  meta_data: { occ_heat_sp }
}) => {
  return user_name
    ? `${message} to ${occ_heat_sp}° by ${user_name}`
    : `${message} to ${occ_heat_sp}°`
}

const coolSPChanged = ({
  user_name,
  info: { message },
  meta_data: { occ_cool_sp }
}) => {
  return user_name
    ? `${message} to ${occ_cool_sp}° by ${user_name}`
    : `${message} to ${occ_cool_sp}°`
}

const switchTurnedOn = ({ user_name, resource: { name } }) => {
  return user_name ? `${name} turned on by ${user_name}` : `${name} turned on`
}

const switchTurnedOff = ({ user_name, resource: { name } }) => {
  return user_name ? `${name} turned off by ${user_name}` : `${name} turned off`
}

const messageTypes = {
  '0': deviceDisconnected,
  '1200': codeUsed,
  '1300': codeCreated,
  '1310': codeRemoved,
  '1400': lockedUnlocked,
  '1500': lockedUnlocked,
  '2100': systemModeChanged,
  '2300': heatSPChanged,
  '2400': coolSPChanged,
  '3000': switchTurnedOn,
  '3100': switchTurnedOff
}

const getDeviceMessage = (data: any) => {
  return messageTypes[data.event_code]
    ? messageTypes[data.event_code](data)
    : defaultMessage(data)
}

export default getDeviceMessage
