// @flow

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
// $FlowDisableNextLine
import { getSelectedCompany } from 'zego-shared/store/select/selectors'
// $FlowDisableNextLine
import { getUserRole } from 'zego-shared/store/authorization/selectors'
// $FlowDisableNextLine
import { getCompanies, checkSuperAdmin } from 'zego-shared/store/select/companies/selectors'

const emptyObj = {}

export const getIsPropertyReadyToRender = (state: Object) => {
  return get(state, 'select.properties.isReady')
}

export const getSelectedProperty = (state: Object) =>
  get(state, 'select.properties.selected', emptyObj)

export const isPropertySelected = (state: Object) => !isEmpty(getSelectedProperty(state))

export const getPropertyId = (state: Object) =>
  get(state, 'select.properties.selected.id', -1)

export const getPropertyTimeZone = (state: Object) =>
  get(state, 'select.properties.selected.timezone', 'unknown property')

export const getPropertyName = (state: Object) =>
  get(state, 'select.properties.selected.name', 'unknown property')

export const getCompanyId = (state: Object) =>
  get(state, 'select.properties.selected.company_id', -1)

export const getCompanyName = (state: Object) =>
  get(state, 'select.properties.selected.company_name', 'Unnamed Company')

export const getAllProperties = (state: Object) =>
  Object.values(get(state, 'select.properties.byId'))

export const getProperties = (state: Object) => {
  const selectedCompany = getSelectedCompany(state)
  const allProperties = getAllProperties(state)
  const companies = getCompanies(state)
  const isSuperAdmin = checkSuperAdmin(getUserRole(state))

  return isSuperAdmin
    ? selectedCompany && selectedCompany.id
      ? sortBy(companies.byId[selectedCompany.id].properties, [
          company => company.name.toLowerCase()
        ])
      : []
    : sortBy(allProperties, [property => property.name.toLowerCase()])
}

export const getHasMoreProperties = (state: Object) => {
  const totalPages = get(state, 'select.properties.totalPages')
  const currentPage = get(state, 'select.properties.currentPage')
  return totalPages > currentPage
}

export const getNextPropertiesPage = (state: Object) => {
  const currentPage = get(state, 'select.properties.currentPage')
  return currentPage + 1
}

export const getPms = (state: Object) =>
  get(state, 'select.properties.selected.pms', null)
