// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import { generate as shortid } from 'shortid'
import MenuItem from '@material-ui/core/MenuItem'
import Flex, { FlexItem } from '@Common/Flex'
import Downshift from '../../Common/Downshift'
import { GridItem } from '../../Common/Grid'
import HeaderWithSub from '../../Common/HeaderWithSub'
import Avatar from '@Common/Avatar'
import DropDown from '@Common/DropDown'
import Loader from '../../Common/Loader'
import AlertModal from '@Common/AlertModal/AlertModal'
import CTAButtons from '../../Common/CTAButtons'
import { Formik } from 'formik'
import curry from 'lodash/curry'

import { getDate, selectItem } from '../../../utils'

import {
  Container,
  Content,
  Image,
  TextField,
  Dates,
  Value,
  AvatarInput,
} from './styles'
import { menuItemFocus } from '@Common/DropDown/styles'

import { BrowserHistory } from 'history'
import type { Match } from 'react-router-dom'
import type { Theme } from '../../../theme'
import schema from './schema'

type Props = {
  hasData: boolean,
  isFetching: boolean,
  item: Object,
  residents: Array<Object>,
  vehicleYears: Array<Object>,
  vehicleColors: Array<Object>,
  vehicleStates: Array<Object>,
  vehicleTypes: Array<Object>,
  vehiclesMakes: Array<Object>,
  addMode: boolean,
  theme: Theme,
  history: BrowserHistory,
  match: Match,
  updateVehicle: (Object) => mixed,
  changeRegistrationStatus: (name: string, id: number) => void,
  searchRegistration: (term: string) => void,
  uploadVehicleImage: (number, Object) => mixed,
}

type State = {
  editMode: boolean,
  addMode: boolean,
  showDialog: boolean,
  edited: boolean,
  error: boolean,
  plateNumber: string,
  photo: string | null | File,
}

class Vehicle extends React.Component<Props, State> {
  ownerItems = null
  state = {
    editMode: false,
    addMode: this.props.addMode,
    showDialog: false,
    plateNumber: this.props.item.plateNumber,
    photo: this.props.item.photo,
    edited: false,
    error: false,
  }

  componentDidMount() {
    this.ownerItems = document.getElementsByClassName('owner-item-js')
  }

  componentDidUpdate({ item: { plateNumber, photo } }) {
    const { item } = this.props

    if (item.plateNumber !== plateNumber) {
      this.setState({
        plateNumber: item.plateNumber,
      })
    }

    if (item.photo !== photo) {
      this.setState({
        photo: item.photo,
      })
    }

    if (!this.ownerItems) {
      this.ownerItems = document.getElementsByClassName('owner-item-js')
    }
  }

  goBack = () => {
    const {
      history,
      match: { url },
    } = this.props

    const { editMode, addMode, edited } = this.state

    if (addMode || editMode) {
      if (edited) {
        this.setState({ showDialog: true })
      } else {
        this.exitSave()
      }
    } else {
      let splitText = '/vehicles'
      history.push(`${url.split(splitText)[0]}?filter=vehicles`)
    }
  }

  exitSave = () => {
    const {
      history,
      match: { url },
    } = this.props

    const { editMode, addMode } = this.state

    let splitText = '/vehicles'
    if (editMode) {
      splitText = '/edit'
    }
    let backUrl = url.split(splitText)[0]
    if (addMode) {
      backUrl = `${backUrl}?filter=vehicles`
    }
    this.setState({
      showDialog: false,
      addMode: false,
      editMode: false,
    })
    history.push(backUrl)
  }

  saveVehicle = (item) => {
    const {photo} = this.state
    this.props.updateVehicle({...item, photo})
    this.setState({
      showDialog: false,
      addMode: false,
      editMode: false,
    })
  }

  // this will be updated to the submitForm function coming from formik
  triggerVehicleSave = () => {}

  // this will be updated with the isValid coming from formik
  isValid = true

  closeAlertModal = () => {
    this.setState({ showDialog: false })
  }

  openDialog = () => {
    this.setState({ showDialog: true })
  }

  toggleEditMode = async (onErrorCb?: Function) => {
    let { addMode, editMode } = this.state
    if (!addMode && !editMode) {
      return this.setState({ editMode: true })
    }

    if (addMode || editMode) {
      try {
        await this.triggerVehicleSave()
        if (this.isValid) {
          if (editMode) editMode = false
          if (addMode) addMode = false
          this.setState({ editMode, addMode })
        } else {
          throw new Error('Invalid form')
        }
      } catch (error) {
        if(typeof onErrorCb === "function")  onErrorCb()
      }
    }
  }

  uploadVehicleImage = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const image = event.target.files[0]

    if (image && image.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
      this.setState({ photo: image })
      this.setEdited()
    }
  }

  updateItem = (
    update,
    values,
    setValue,
    shouldCallValidate = false,
    shouldCallAPI = false
  ) => {
    setValue({ ...values, ...update }, shouldCallValidate)
    this.setEdited(shouldCallAPI)
  }

  setEdited = (shouldCallAPI = false) => {
    this.setState({ edited: true }, () => {
      if (shouldCallAPI) {
        this.triggerVehicleSave()
      }
    })
  }

  handleChange = curry(
    (
      setValue,
      values,
      validate,
      { currentTarget: { id, value } }: SyntheticEvent<HTMLInputElement>
    ) => {
      this.updateItem({ [id]: value }, values, setValue, validate)
    }
  )

  handleMakeSelect = curry((setValue, values, changes) => {
    if (changes.hasOwnProperty('selectedItem')) {
      this.updateItem({ make: changes.selectedItem }, values, setValue, true)
    } else if (changes.hasOwnProperty('inputValue')) {
      this.updateItem({ make: changes.inputValue }, values, setValue, true)
    }
  })

  changeOwner = curry((setValue, values, event) => {
    const { userId, unitId, unitName, display } = event.target.value
    this.updateItem(
      { ownerId: userId, unit: unitName, owner: display, unitId },
      values,
      setValue
    )
  })

  changeVehicleState = curry((setValue, values, state) => {
    this.updateItem({ state }, values, setValue)
  })

  changeVehicleYear = curry((setValue, values, year) => {
    this.updateItem({ year }, values, setValue)
  })

  changeVehicleColor = curry((setValue, values, color) => {
    this.updateItem({ color }, values, setValue)
  })

  changeVehicleType = curry((setValue, values, type) => {
    this.updateItem({ type }, values, setValue)
  })

  ownerKeyPress = ({ key }) => {
    selectItem(key, this.ownerItems)
  }

  isError = (errors, touched, field) => {
    return errors[field] && touched[field]
  }

  getTitle = () => {
    const { plateNumber, addMode, editMode } = this.state
    if (addMode) return 'NEW VEHICLE'
    if (editMode) return 'VEHICLE DETAILS EDIT'
    if (plateNumber == null) return ''
    return plateNumber.toUpperCase()
  }

  getInitialValues = () => {
    const { item } = this.props
    return {
      plateNumber: '',
      make: '',
      model: '',
      ...item,
    }
  }

  render() {
    const { addMode, editMode, showDialog, photo } = this.state
    const {
      residents,
      vehicleColors,
      vehicleStates,
      vehicleYears,
      vehicleTypes,
      hasData,
      isFetching,
      vehiclesMakes,
      theme,
    } = this.props
    const readMode = !addMode && !editMode

    if (isFetching) return <Loader />
    if (!hasData && !readMode) return null

    const picture =
      photo == null || typeof photo === 'string'
        ? photo
        : URL.createObjectURL(photo)
    return (
      <React.Fragment>
        <GridItem columnStart={1} columnSpan={6} rowSpan={2} rowStart={1}>
          <HeaderWithSub subheader="REGISTRATION" header={this.getTitle()} />
        </GridItem>
        <Container>
          <Image imageSrc="/" />
          <CTAButtons
            toggleEditMode={this.toggleEditMode}
            goTo={this.goBack}
            isEditing={!readMode}
          />
          <Content>
            <Flex fullWidth>
              <FlexItem flex={0.7}>
                <label htmlFor="file-input">
                  <Avatar
                    size="192px"
                    picture={picture}
                    showOverlay={!readMode}
                    alwaysShowOverlay
                    overlayContent={() => (
                      <React.Fragment>
                        <span>upload new +</span>
                        <AvatarInput
                          id="file-input"
                          type="file"
                          onChange={this.uploadVehicleImage}
                        />
                      </React.Fragment>
                    )}
                  />
                </label>
              </FlexItem>
              <FlexItem flex={2}>
                <Formik
                  validationSchema={schema}
                  onSubmit={this.saveVehicle}
                  initialValues={this.getInitialValues()}
                  enableReinitialize>
                  {({
                    errors,
                    touched,
                    handleBlur,
                    setValues,
                    values: item,
                    submitForm,
                    handleSubmit,
                    submitCount,
                    isValid,
                  }) => {
                    this.triggerVehicleSave = submitForm
                    this.isValid = isValid
                    return (
                      <Flex>
                        <FlexItem flex={1} paddingLeft="35px">
                          <TextField
                            error={this.isError(errors, touched, 'plateNumber')}
                            onChange={this.handleChange(setValues, item, true)}
                            onBlur={handleBlur}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            alwaysShowLabel={!readMode}
                            textAlign={readMode ? 'right' : 'left'}
                            prefix={readMode ? 'License Plate' : null}
                            label={readMode ? null : 'License Plate'}
                            id="plateNumber"
                            value={item.plateNumber}
                            showPencil={!readMode}
                            readOnly={readMode}
                          />
                          <TextField
                            onChange={this.handleChange(setValues, item, false)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            alwaysShowLabel={!readMode}
                            textAlign={readMode ? 'right' : 'left'}
                            prefix={readMode ? 'Parking Permit' : null}
                            label={readMode ? null : 'Parking Permit'}
                            id="parkingPermit"
                            value={item.parkingPermit}
                            showPencil={!readMode}
                            readOnly={readMode}
                          />
                          {readMode ? (
                            <TextField
                              fullWidth
                              id="state"
                              prefix="State"
                              value={item.state}
                              showPencil={false}
                              readOnly
                            />
                          ) : (
                            <DropDown
                              handleChange={this.changeVehicleState(
                                setValues,
                                item
                              )}
                              data={vehicleStates}
                              selectedItem={
                                vehicleStates.find(
                                  ({ id }) => id === item.state
                                ) || vehicleStates[0]
                              }
                              alwaysShowLabel
                              label="State"
                              id="state"
                              width="100%"
                              style={{
                                margin: `0 0 10px 10px`,
                                borderBottom: `1px solid ${theme.palette.neutrals.coolGray}`,
                                fontSize: `${theme.custom.font.size.sm}`,
                              }}
                            />
                          )}

                          <TextField
                            onChange={this.changeOwner(setValues, item)}
                            fullWidth
                            InputLabelProps={{ shrink: true, margin: 'none' }}
                            alwaysShowLabel={!readMode}
                            textAlign={readMode ? 'right' : 'left'}
                            prefix={readMode ? 'Owner' : null}
                            label={readMode ? null : 'Owner'}
                            id="owner"
                            value={item.owner}
                            showPencil={false}
                            readOnly={readMode}
                            select={!readMode}
                            onKeyPress={this.ownerKeyPress}
                            SelectProps={{
                              renderValue: (value) => value,
                              disableUnderline: false,
                            }}>
                            {residents.map((resident) => (
                              <MenuItem
                                className={`owner-item-js ${menuItemFocus}`}
                                key={shortid()}
                                selected={resident.userId === item.ownerId}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                                value={resident}>
                                {resident.display}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            onChange={this.handleChange(setValues, item, false)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            alwaysShowLabel={!readMode}
                            textAlign={readMode ? 'right' : 'left'}
                            prefix={readMode ? 'Unit' : null}
                            label={readMode ? null : 'Unit'}
                            id="unit"
                            value={item.unit}
                            showPencil={false}
                            readOnly
                          />
                        </FlexItem>
                        <FlexItem flex={1} paddingLeft="35px">
                          <Downshift
                            error={this.isError(errors, touched, 'make')}
                            onBlur={handleBlur}
                            suggestions={vehiclesMakes}
                            id="make-down-shift"
                            inputId="make"
                            label={readMode ? null : 'Make'}
                            handleInputChange={this.handleMakeSelect(
                              setValues,
                              item
                            )}
                            prefix={readMode ? 'Make' : null}
                            InputLabelProps={{ shrink: true }}
                            textAlign={readMode ? 'right' : 'left'}
                            selectedItem={item.make}
                            alwaysShowLabel={!readMode}
                            readOnly={readMode}
                            showPencil={!readMode}
                            classes={{ root: 'make-select' }}
                          />
                          <TextField
                            error={this.isError(errors, touched, 'model')}
                            onChange={this.handleChange(setValues, item, true)}
                            onBlur={handleBlur}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            alwaysShowLabel={!readMode}
                            textAlign={readMode ? 'right' : 'left'}
                            prefix={readMode ? 'Model' : null}
                            label={readMode ? null : 'Model'}
                            id="model"
                            value={item.model}
                            showPencil={!readMode}
                            readOnly={readMode}
                          />
                          {readMode ? (
                            <TextField
                              fullWidth
                              id="year"
                              prefix="Year"
                              value={item.year}
                              showPencil={false}
                              readOnly
                            />
                          ) : (
                            <DropDown
                              handleChange={this.changeVehicleYear(
                                setValues,
                                item
                              )}
                              data={vehicleYears}
                              selectedItem={
                                vehicleYears.find(
                                  ({ id }) => id === item.year
                                ) || vehicleYears[0]
                              }
                              alwaysShowLabel
                              label="Year"
                              id="year"
                              width="100%"
                              style={{
                                margin: `0 0 10px 10px`,
                                borderBottom: `1px solid ${theme.palette.neutrals.coolGray}`,
                                fontSize: `${theme.custom.font.size.sm}`,
                              }}
                            />
                          )}

                          {readMode ? (
                            <TextField
                              fullWidth
                              id="color"
                              prefix="Color"
                              value={item.color}
                              showPencil={false}
                              readOnly
                            />
                          ) : (
                            <DropDown
                              handleChange={this.changeVehicleColor(
                                setValues,
                                item
                              )}
                              data={vehicleColors}
                              selectedItem={
                                vehicleColors.find(
                                  ({ id }) => id === item.color
                                ) || vehicleColors[0]
                              }
                              alwaysShowLabel
                              label="Color"
                              id="color"
                              width="100%"
                              style={{
                                margin: `0 0 10px 10px`,
                                borderBottom: `1px solid ${theme.palette.neutrals.coolGray}`,
                                fontSize: `${theme.custom.font.size.sm}`,
                              }}
                            />
                          )}

                          {readMode ? (
                            <TextField
                              fullWidth
                              id="type"
                              prefix="Type"
                              value={item.type}
                              showPencil={false}
                              readOnly
                            />
                          ) : (
                            <DropDown
                              handleChange={this.changeVehicleType(
                                setValues,
                                item
                              )}
                              data={vehicleTypes}
                              selectedItem={
                                vehicleTypes.find(
                                  ({ id }) => id === item.type
                                ) || vehicleTypes[0]
                              }
                              alwaysShowLabel
                              label="Type"
                              id="type"
                              width="100%"
                              style={{
                                margin: `0 0 10px 10px`,
                                borderBottom: `1px solid ${theme.palette.neutrals.coolGray}`,
                                fontSize: `${theme.custom.font.size.sm}`,
                              }}
                            />
                          )}
                        </FlexItem>
                      </Flex>
                    )
                  }}
                </Formik>
              </FlexItem>
            </Flex>
          </Content>
          {readMode && (
            <Dates>
              <Flex>
                <FlexItem>
                  Submitted{' '}
                  <Value>{getDate(this.props.item.submittedDate)}</Value>
                </FlexItem>
              </Flex>
            </Dates>
          )}
        </Container>
        <AlertModal
          title="Would you like to save before leaving?"
          message=""
          buttons={[
            {
              onClick: () => this.toggleEditMode(this.closeAlertModal),
              text: 'YES',
            },
            {
              onClick: this.exitSave,
              color: theme.palette.primary.navy.spaceBlue,
              text: 'NO',
            },
          ]}
          show={showDialog}
          onClose={this.closeAlertModal}
        />
      </React.Fragment>
    )
  }
}

export default withTheme(Vehicle)
