import styled from '@emotion/styled'
import theme from "../../../theme";
import { DialogContentText, DialogTitle } from '@material-ui/core'

export const ContentHeader = styled('div')`
  font-size : ${theme.custom.font.size.mdlg};
  font-weight : ${theme.custom.font.weight.normal};
  line-height: 21.6px;
  margin-bottom: 10px;
  margin-left: -48px;
`
export const DialogTitleLeft = styled(DialogTitle)`
  text-align: left;
  padding-bottom : 0px;
`
export const SignatureButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`
export const ESignLabel = styled('div')`
  font-style: italic;
  font-size : ${theme.custom.font.size.mdl};
  color: ${theme.palette.text.light};
  margin-left : 5px;
  line-height: 21px;
`
export const CheckBoxContainer = styled('div')`
  color: ${theme.palette.text.light} !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
  margin-top: -20px !important;
  padding-left: 10%;
`
export const ErrorMsg = styled('p')`
  color: ${theme.palette.alert.error};
  text-align: left;
  padding-left: 13%;
`
export const DialogContentLeft = styled(DialogContentText)`
  text-align: left;
  padding-top: -20px;
  font-size: 16px;
`
export const SignatureContainer = styled('div')`
  border: 1px solid;
  width: 75%;
  margin : auto;
  flex-direction: column;
  display: inline-flex;
  margin-bottom: 12px;
  height: 200px;
`

export const MessageContainer = styled('div')`
  font-size : ${theme.custom.font.size.mdl};
  font-weight : ${theme.custom.font.weight.light};
  line-height: 19.2px;
`
export const ActionButtons = styled('div')`
  flex: 0 0 auto;
  margin: 8px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ContentData = styled('div')`
  width : 25%;
  margin : auto;
  overflow-y: auto;
  height: 180px;
`
export const LoaderContainer = styled('div')`
  position: relative;
  margin-left : -4em;
  margin-right : -4em;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 250px;
`
export const HighlightMessageContainer = styled('span')`
  font-weight : ${theme.custom.font.weight.normal};
`

export const CheckIconContainer = styled('div')`
  background: ${theme.palette.primary.green.iceberg};
  width : 80px;
  height : 80px;
  margin : auto;
  margin-top : -100px;
  border-radius : 50%;
  z-index : 9001;
  svg {
    width: 80px;
    height: 80px;
    stroke-width: 5px;
    align-self: center;
    margin-left: 10px;
    stroke :  ${theme.palette.primary.green.mint};
    margin-top : -15px;
  }
`
export const Item = styled('div')`
  font-size : ${theme.custom.font.size.mdl};
  line-height: 21px;
  font-weight : ${theme.custom.font.weight.normal};
  text-align: left;
`
export const ItemHeader = styled('div')`
  font-size : ${theme.custom.font.size.mdl};
  font-weight : ${theme.custom.font.weight.light};
  color: ${theme.palette.text.grey};
  line-height: 21px;
  display : inline-block;
  padding-right : 0.5em;
`
export const Clip = styled('div')`
  position: relative;
  margin-left : -4em;
  margin-right : -4em;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 250px;
  background: ${theme.palette.primary.green.iceberg};
  font-weight : 300;

::before {
  content: "";
  width: 840px;
  height: 46px;
  position: absolute;
  top: -26px;
  left: 0;
  background: ${theme.palette.primary.green.iceberg};
  transform: skew(0deg,-2deg);
}
`
export const styles = {
  root: {
    width: '550px',
    padding: '4em',
    borderTopRightRadius: '3em',
    borderBottomLeftRadius: '3em'
  },
  signatureCanvas: {
    width: '100%',
    height: '250px',
  },
  closeButton: {
    float: "right",
    fontSize: '1em',
    color: theme.palette.text.grey,
  },
  clearButton: {
    float: "right",
    fontSize: '1em',
    color: theme.palette.alert.error,
  },
  closeIcon: {
    marginLeft: "5px",
    color: theme.palette.text.black,
  },
  clearIcon: {
    marginRight: "5px",
    color: theme.palette.alert.error,
  },
}
