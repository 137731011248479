// @flow
import React from 'react'
import DropDown from '@Common/DropDown'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import { CommonIcon } from '@icons'
import { CustomFormHelperText, DaysIcon, OffsetBeforeAfter } from '../../CreateGroupMessage/styles'
import { EventProps } from '../../types'
import { OffsetLabel } from './styles'
import { css } from 'emotion'

const textFieldClass = css`
  width: 50%;
  margin-top: 0px;
`

const offsets = [
  { id: 'before', name: 'before' },
  { id: 'after', name: 'after' }
]

const Event = ({
  chooseEvent,
  defaultEvent,
  handleOffsetDays,
  handleEmptyOffsetDays,
  offsetDays,
  selectedEvent,
  offsetBeforeAfter,
  showOffsetBeforeAfterDropdown,
  handleOffsetBeforeAfter,
  events,
  theme,
  displayText
}: EventProps) => {
  return (
    <React.Fragment>
      <br />
      <FlexDirection fullWidth>
        <FlexItem flex={10}>
          <DropDown
            elementId="eventDropDown"
            label=" "
            width="100%"
            classes={{ root: 'template-dropdown' }}
            data={events}
            IconComponent={() => (
              <CommonIcon
                name="arrow_down"
                width="1em"
                height="1em"
                fill={theme.palette.grey.main}
              />
            )}
            DropDownLabel="recurring"
            handleChange={chooseEvent}
            selectedItem={selectedEvent}
            defaultOption={defaultEvent}
          />
          {displayText && (
            <CustomFormHelperText>
              *If you choose to use an event to automate this message,
              recipients will be automatically reset and sent to @all
            </CustomFormHelperText>
          )}
        </FlexItem>
        <FlexItem flex={0.3} />
        <FlexItem
          style={{ marginTop: '-2.5em' }}
          flex={1}
          direction={'column'}
          alignItemsCenter
          flexCenter
          displayFlex>
          <OffsetLabel>offset</OffsetLabel>
          <TextField
            id="offsetDaysInput"
            value={offsetDays}
            onChange={handleOffsetDays}
            onBlur={handleEmptyOffsetDays}
            classes={{ root: 'template-dropdown' }}
            type="number"
            className={`${textFieldClass}`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DaysIcon>days</DaysIcon>
                </InputAdornment>
              )
            }}
          />
        </FlexItem>
        <FlexItem flex={0.3} />
        <FlexItem flex={1}>
          {showOffsetBeforeAfterDropdown ? <DropDown
            label=" "
            width="100%"
            classes={{ root: 'template-dropdown' }}
            data={offsets}
            IconComponent={() => (
              <CommonIcon
                name="arrow_down"
                width="1em"
                height="1em"
                fill={theme.palette.grey.main}
              />
            )}
            handleChange={handleOffsetBeforeAfter}
            selectedItem={offsets.find(
              offset => offset.id === offsetBeforeAfter
            )}
          /> : <OffsetBeforeAfter id="offsetBeforeAfter">{offsetBeforeAfter}</OffsetBeforeAfter>}

        </FlexItem>
      </FlexDirection>
    </React.Fragment>
  )
}
export default Event
