import { createFetchActions } from '../../helpers/actions/creators'

export const LOCKCODESVENDORS = 'LOCKCODES_VENDORS'
export const FETCH_LOCKCODES_VENDORS = 'FETCH_LOCKCODES_VENDORS'
export const SEND_LOCKCODE = 'SEND_LOCKCODE'
export const SET_VENDORS_SEARCH_TERM = 'SET_VENDORS_SEARCH_TERM'
export const CLEAR_VENDORS_SEARCH_TERM = 'CLEAR_VENDORS_SEARCH_TERM'
export const VENDOR = 'VENDOR'

export const {
  fetchAction: fetchLockCodesVendorsAction,
  fetchStartAction: fetchLockCodesVendorsStart,
  fetchSuccessAction: fetchLockCodesVendorsSuccess,
  fetchErrorAction: fetchLockCodesVendorsError
} = createFetchActions(LOCKCODESVENDORS)

export const sendLockCode = userId => ({
  type: SEND_LOCKCODE,
  userId
})

export const fetchNextLockCodesVendors = (propertyId, page, name) => ({
  type: FETCH_LOCKCODES_VENDORS,
  propertyId,
  page,
  name
})

export const searchVendors = (propertyId, name) => ({
  type: FETCH_LOCKCODES_VENDORS,
  propertyId,
  name
})

export const setSearchTerm = searchTerm => ({
  type: SET_VENDORS_SEARCH_TERM,
  searchTerm
})

export const clearVendorsSearchTerm = () => ({
  type: CLEAR_VENDORS_SEARCH_TERM
})
export const VENDOR_CODES = 'VENDOR_CODES'
export const VENDOR_CODE = 'VENDOR_CODE'
export const VENDORCODEUNITS = 'VENDORCODEUNITS'

export const SEARCH_VENDOR_CODE = 'SEARCH_VENDOR_CODE'

export const RESEND_VENDOR_CODE = 'RESEND_VENDOR_CODE'
export const RESEND_VENDOR_CODE_SUCCESS = 'RESEND_VENDOR_CODE_SUCCESS'
export const RESEND_VENDOR_CODE_ERROR = 'RESEND_VENDOR_CODE_ERROR'

export const INVALIDATE_VENDOR_CODE = 'INVALIDATE_VENDOR_CODE'
export const INVALIDATE_VENDOR_CODE_SUCCESS = 'INVALIDATE_VENDOR_CODE_SUCCESS'
export const INVALIDATE_VENDOR_CODE_ERROR = 'INVALIDATE_VENDOR_CODE_ERROR'

export const {
  fetchAction: fetchVendorCodesAction,
  fetchStartAction: fetchVendorCodesStart,
  fetchSuccessAction: fetchVendorCodesSuccess,
  fetchErrorAction: fetchVendorCodesError
} = createFetchActions(VENDOR_CODES)

export const fetchVendorCodes = (vendorId, propertyId, query) =>
  fetchVendorCodesAction({ vendorId, propertyId, query })

export const searchVendorCodes = (propertyId, searchTerm) =>
  fetchVendorCodesAction({ propertyId, searchTerm })

export const {
  fetchAction: fetchVendorCodeAction,
  fetchStartAction: fetchVendorCodeStart,
  fetchSuccessAction: fetchVendorCodeSuccess,
  fetchErrorAction: fetchVendorCodeError
} = createFetchActions(VENDOR_CODE)

export const fetchVendorCode = (codeId, propertyId) =>
  fetchVendorCodeAction({ codeId, propertyId })

export const {
  fetchAction: fetchVendorCodeUnitsAction,
  fetchStartAction: fetchVendorCodeUnitsStart,
  fetchSuccessAction: fetchVendorCodeUnitsSuccess,
  fetchErrorAction: fetchVendorCodeUnitsError
} = createFetchActions(VENDORCODEUNITS)

export const fetchVendorCodeUnits = (codeId, propertyId) =>
  fetchVendorCodeUnitsAction({ codeId, propertyId })

export const {
  fetchAction: fetchVendorAction,
  fetchStartAction: fetchVendorStart,
  fetchSuccessAction: fetchVendorSuccess,
  fetchErrorAction: fetchVendorError
} = createFetchActions(VENDOR)

export const fetchVendor = (vendorId, propertyId) =>
  fetchVendorAction({ vendorId, propertyId })

export const resendCode = (codeId, propertyId) => ({
  type: RESEND_VENDOR_CODE,
  codeId,
  propertyId
})

export const resendCodeSuccess = () => ({
  type: RESEND_VENDOR_CODE_SUCCESS
})

export const resendCodeError = () => ({
  type: RESEND_VENDOR_CODE_ERROR
})

export const invalidateCode = (codeId, propertyId, vendorId) => ({
  type: INVALIDATE_VENDOR_CODE,
  codeId,
  propertyId,
  vendorId
})

export const invalidateCodeSuccess = () => ({
  type: INVALIDATE_VENDOR_CODE_SUCCESS
})

export const invalidateCodeError = () => ({
  type: INVALIDATE_VENDOR_CODE_ERROR
})
