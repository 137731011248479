import React from 'react'
import PrivateRoute from '../PrivateRoute'
import Packages from '../../views/Packages'

const getPackagesRoutes = () => [
  <PrivateRoute
    key="packages"
    path="/properties/:propertyId/packages"
    component={Packages}
  />
]

export default getPackagesRoutes
