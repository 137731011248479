// @flow

import React from 'react'
import isEqual from 'lodash/isEqual'
import { withTheme } from 'emotion-theming'
import moment, { Moment } from 'moment'
import MenuItem from '@material-ui/core/MenuItem'
import Flex, { FlexItem } from '@Common/Flex'
import { GridItem } from '../../Common/Grid'
import HeaderWithSub from '../../Common/HeaderWithSub'
import Avatar from '@Common/Avatar'
import AlertModal from '@Common/AlertModal/AlertModal'
import withClickOutside from '../../Common/withClickOutside'
import Calendar from '@Common/Calendar'
import Loader from '../../Common/Loader'
import CTAButtons from '../../Common/CTAButtons'
import { getDate } from '../../../utils/dateUtils'
import {
  validatePhoneNumber,
  validateNonEmpty,
  isValidImageFile
} from '../../../utils/validationUtils'
import { selectItem } from '../../../utils'
import {
  Container,
  Content,
  Image,
  TextField,
  Dates,
  Value,
  Label,
  AvatarInput,
  CalendarWrapper
} from './styles'
import { BrowserHistory } from 'history'
import type { Match } from 'react-router-dom'
import type { Theme } from '../../../theme'
import { menuItemFocus } from '@Common/DropDown/styles'
import MaskPhoneNumber from '../../Common/MaskPhoneNumber'

type Props = {
  item: Object,
  addMode: boolean,
  theme: Theme,
  history: BrowserHistory,
  match: Match,
  uploadVisitorImage: (number, Object) => mixed,
  setClickOutsideDomNode: (ref: Object | null) => void,
  saveVisitor: (visitor: Object, addMode: boolean, userToken: string) => mixed,
  residents: Object[],
  showSnackbar: (message: string, variant: string) => mixed,
  userToken: string,
  isFetching: boolean
}

type State = {
  editMode: boolean,
  addMode: boolean,
  showDialog: boolean,
  item: Object,
  showStartDatePicker: boolean,
  showEndDatePicker: boolean
}

const requiredFields = {
  visitorName: 'Visitor name',
  visitingName: 'Visiting',
  unit: 'Unit',
  startDate: 'Start date',
  endDate: 'End date',
  phoneNumber: 'Phone number'
}

class Visitor extends React.Component<Props, State> {
  visitingItems = null
  state = {
    editMode: this.props.addMode,
    addMode: this.props.addMode,
    showDialog: false,
    item: this.props.item,
    showStartDatePicker: false,
    showEndDatePicker: false
  }

  componentDidMount() {
    this.visitingItems = document.getElementsByClassName('visiting-item-js')
  }

  componentDidUpdate(prevProps: Props) {
    const { item, addMode } = this.props
    if (!isEqual(item, prevProps.item)) {
      this.setState({ item })
    }

    if (addMode !== prevProps.addMode) {
      this.setState({ addMode, editMode: addMode })
    }

    if (!this.visitingItems) {
      this.visitingItems = document.getElementsByClassName('visiting-item-js')
    }
  }

  handleClickOutside = () => {
    this.setState({
      showStartDatePicker: false,
      showEndDatePicker: false
    })
  }

  goBack = () => {
    const {
      history,
      match: { url },
      item
    } = this.props

    const { editMode, addMode, item: itemInState } = this.state

    if ((addMode || editMode) && !isEqual(itemInState, item)) {
      this.setState({ showDialog: true })
    } else {
      let splitText = '/visitors'
      history.push(`${url.split(splitText)[0]}?filter=visitors`)
    }
  }

  exitSave = () => {
    const {
      history,
      match: { url }
    } = this.props

    const { addMode } = this.state

    let splitText = '/visitors'
    let backUrl = url.split(splitText)[0]
    if (addMode) {
      backUrl = `${backUrl}?filter=visitors`
    }
    this.setState({
      showDialog: false,
      addMode: false,
      editMode: false
    })
    history.push(backUrl)
  }

  validateItemData = () => {
    const { item } = this.state
    let errorMessage = ''
    Object.keys(requiredFields).every(key => {
      if (!validateNonEmpty(item[key].trim())) {
        errorMessage = `${requiredFields[key]} is required`
        return false
      }
      if (['visitorName', 'visitingName'].includes(key)) {
        const value = item[key].trim()
        if (value.split(/\s+/).length !== 2) {
          errorMessage = `${
            requiredFields[key]
          } must have a first name and a last name`
          return false
        }
      }
      if (key === 'phoneNumber') {
        if (!validatePhoneNumber(item[key])) {
          errorMessage = `${requiredFields[key]} is not valid`
          return false
        }
      }
      return true
    })
    return errorMessage
  }

  saveVisitor = () => {
    const { saveVisitor, item, showSnackbar, userToken } = this.props
    const { item: itemInState, addMode } = this.state
    const validationError = this.validateItemData()
    if (!validationError) {
      if (!isEqual(itemInState, item)) {
        saveVisitor && saveVisitor(itemInState, addMode, userToken)
      }
      this.setState({
        showDialog: false,
        addMode,
        editMode: addMode
      })
    }
    validationError && showSnackbar(validationError, 'error')
  }

  closeAlertModal = () => {
    this.setState({ showDialog: false })
  }

  openDialog = () => {
    this.setState({ showDialog: true })
  }

  toggleEditMode = () => {
    const { editMode } = this.state
    if (editMode) {
      this.saveVisitor()
    } else {
      this.setState({ editMode: true })
    }
  }

  toggleDatePicker = value => {
    this.setState({
      [`show${value}Picker`]: !this.state[`show${value}Picker`],
      [`show${value === 'StartDate' ? 'EndDate' : 'StartDate'}Picker`]: false
    })
  }

  setStartDate = (selectedDate: Moment) => {
    this.setState({
      item: { ...this.state.item, startDate: getDate(selectedDate) }
    })
  }

  setEndDate = (selectedDate: Moment) => {
    this.setState({
      item: { ...this.state.item, endDate: getDate(selectedDate) }
    })
  }

  handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { value, id } = event.target
    this.setState({
      item: { ...this.state.item, [id]: value }
    })
  }

  handleVisitingNameChange = (item: Object) => {
    const { value } = item.target
    this.setState({
      item: {
        ...this.state.item,
        visitingName: value.display,
        visitingId: value.userId,
        unit: value.unitName,
        unitId: value.unitId
      }
    })
  }

  uploadVisitorImage = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const image = event.target.files[0]

    if (isValidImageFile(image)) {
      const { item } = this.state
      if (item.id) {
        const { uploadVisitorImage } = this.props
        uploadVisitorImage && uploadVisitorImage(item.id, image)
        return this.setState({
          item: { ...item, picture: URL.createObjectURL(image) }
        })
      }
      this.setState({
        item: { ...item, photo: image, picture: URL.createObjectURL(image) }
      })
    }
  }

  getTitle = () => {
    const { item, addMode, editMode } = this.state
    if (addMode) return 'NEW VISITOR'
    if (editMode) return 'VISITOR DETAILS EDIT'
    return item.visitorName && item.visitorName.toUpperCase()
  }

  visitingNameKeypress = ({ key }) => {
    selectItem(key, this.visitingItems)
  }

  render() {
    const {
      addMode,
      editMode,
      item,
      showDialog,
      showStartDatePicker,
      showEndDatePicker
    } = this.state
    const { theme, setClickOutsideDomNode, residents, isFetching } = this.props
    const readMode = !addMode && !editMode

    if (isFetching) {
      return <Loader />
    }
    return (
      <React.Fragment>
        <GridItem columnStart={1} columnSpan={6} rowSpan={2} rowStart={1}>
          <HeaderWithSub subheader="REGISTRATION" header={this.getTitle()} />
        </GridItem>
        <Container>
          <Image imageSrc="/" />
          <CTAButtons
            toggleEditMode={this.toggleEditMode}
            goTo={this.goBack}
            isEditing={!readMode}
          />
          <Content>
            <Flex fullWidth>
              <FlexItem flex={0.7}>
                <label htmlFor="file-input">
                  <Avatar
                    size="192px"
                    picture={item.picture}
                    showOverlay={!readMode}
                    alwaysShowOverlay
                    overlayContent={() => (
                      <React.Fragment>
                        <span>upload new +</span>
                        <AvatarInput
                          id="file-input"
                          type="file"
                          accept="image/png, image/jpg"
                          onChange={this.uploadVisitorImage}
                        />
                      </React.Fragment>
                    )}
                  />
                </label>
              </FlexItem>
              <FlexItem flex={2}>
                <Flex>
                  <FlexItem flex={1} paddingLeft="35px">
                    <TextField
                      fullWidth
                      id="visitorName"
                      InputLabelProps={{ shrink: true }}
                      alwaysShowLabel={!readMode}
                      textAlign={readMode ? 'right' : 'left'}
                      prefix={readMode ? 'Visitor Name' : null}
                      label={readMode ? null : 'Visitor Name'}
                      value={item.visitorName}
                      showPencil={!readMode}
                      readOnly={readMode}
                      onChange={this.handleChange}
                    />
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      alwaysShowLabel={!readMode}
                      textAlign={readMode ? 'right' : 'left'}
                      prefix={readMode ? 'Visiting' : null}
                      label={readMode ? null : 'Visiting'}
                      id="visitingName"
                      value={item.visitingName}
                      showPencil={false}
                      readOnly={!addMode}
                      onChange={this.handleVisitingNameChange}
                      select={addMode}
                      onKeyPress={this.visitingNameKeypress}
                      SelectProps={{
                        renderValue: value => value,
                        disableUnderline: false
                      }}>
                      {residents.map(resident => (
                        <MenuItem
                          style={{ display: 'flex', alignItems: 'center' }}
                          key={resident.userId}
                          value={resident}
                          className={`visiting-item-js ${menuItemFocus}`}>
                          {resident.display}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      alwaysShowLabel={!readMode}
                      textAlign={readMode ? 'right' : 'left'}
                      prefix={readMode ? 'Unit' : null}
                      label={readMode ? null : 'Unit'}
                      id="unit"
                      value={item.unit}
                      showPencil={false}
                      readOnly={true}
                    />
                  </FlexItem>
                  <FlexItem flex={1} paddingLeft="35px">
                    <TextField
                      fullWidth
                      hideCaret
                      onClick={() => {
                        this.toggleDatePicker('StartDate')
                      }}
                      InputLabelProps={{ shrink: true }}
                      alwaysShowLabel={!readMode}
                      textAlign={readMode ? 'right' : 'left'}
                      prefix={readMode ? 'Start Date' : null}
                      label={readMode ? null : 'Start Date'}
                      id="startDate"
                      value={item.startDate}
                      showPencil={!readMode}
                      readOnly={readMode}
                      endAdornment={readMode ? null : 'calendar'}
                      endAdornmentOnClick={() => {
                        this.toggleDatePicker('StartDate')
                      }}
                    />
                    {showStartDatePicker && (
                      <CalendarWrapper ref={ref => setClickOutsideDomNode(ref)}>
                        <Calendar
                          moment={moment(
                            item.startDate || new Date(),
                            'MM/DD/YYYY'
                          )}
                          handleChange={this.setStartDate}
                        />
                      </CalendarWrapper>
                    )}
                    <TextField
                      fullWidth
                      hideCaret
                      onClick={() => {
                        this.toggleDatePicker('EndDate')
                      }}
                      InputLabelProps={{ shrink: true }}
                      alwaysShowLabel={!readMode}
                      textAlign={readMode ? 'right' : 'left'}
                      prefix={readMode ? 'End Date' : null}
                      label={readMode ? null : 'End Date'}
                      id="endDate"
                      value={item.endDate}
                      showPencil={!readMode}
                      readOnly={readMode}
                      endAdornment={readMode ? null : 'calendar'}
                      endAdornmentOnClick={() => {
                        this.toggleDatePicker('EndDate')
                      }}
                    />
                    {showEndDatePicker && (
                      <CalendarWrapper ref={ref => setClickOutsideDomNode(ref)}>
                        <Calendar
                          moment={moment(
                            item.endDate || new Date(),
                            'MM/DD/YYYY'
                          )}
                          handleChange={this.setEndDate}
                        />
                      </CalendarWrapper>
                    )}
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      alwaysShowLabel={!readMode}
                      textAlign={readMode ? 'right' : 'left'}
                      prefix={readMode ? 'Phone' : null}
                      label={readMode ? null : 'Phone'}
                      id="phoneNumber"
                      value={item.phoneNumber}
                      textMask={MaskPhoneNumber}
                      showPencil={!readMode}
                      readOnly={readMode}
                      onChange={this.handleChange}
                    />
                  </FlexItem>
                </Flex>
                <Flex direction="column" paddingLeft="35px" paddingTop="35px">
                  <Label>Visitor Details</Label>
                  <TextField
                    fullWidth
                    id="description"
                    value={item.description}
                    multiline
                    textAlign="left"
                    placeholder="Visitor Details"
                    disableUnderline={readMode ? true : false}
                    alwaysShowLabel={true}
                    showPencil={!readMode}
                    readOnly={readMode}
                    onChange={this.handleChange}
                  />
                </Flex>
              </FlexItem>
            </Flex>
          </Content>
          {readMode && (
            <Dates>
              <Flex>
                <FlexItem>
                  Submitted <Value>{item.submittedDate}</Value>
                </FlexItem>
              </Flex>
            </Dates>
          )}
        </Container>
        <AlertModal
          title="Would you like to save before leaving?"
          message=""
          buttons={[
            {
              onClick: this.saveVisitor,
              text: 'YES'
            },
            {
              onClick: this.exitSave,
              color: theme.palette.primary.navy.spaceBlue,
              text: 'NO'
            }
          ]}
          show={showDialog}
          onClose={this.closeAlertModal}
        />
      </React.Fragment>
    )
  }
}

export default withTheme(withClickOutside(Visitor))
