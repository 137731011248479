// import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import ChatListDrawer from './ChatListDrawer'
import { fetchChatChannelMessagesByUrl } from 'zego-shared/store/chat/messages/actions'
import { setActiveChannelByUrl } from 'zego-shared/store/chat/activeChannel/actions'
import { getPinnedChannels } from 'zego-shared/store/chat/pinnedChannels/selectors'
import {
  pinChannel,
  unpinChannel
} from 'zego-shared/store/chat/pinnedChannels/actions'
import {
  getChannelList,
  getActiveChannelUrl,
  getUnreadCount
} from '../../../../store/Chat/channelSelectors'

const mapStateToProps = (state, { location: { search } }) => {
  const filter = queryString.parse(search).filter || ''

  return {
    activeChannelUrl: getActiveChannelUrl(state),
    unreadCount: getUnreadCount(state),
    channels: getChannelList(state, filter),
    pinnedChannels: getPinnedChannels(state),
    filter
  }
}

const mapDispatchToProps = {
  fetchChannelMessageByUrl: fetchChatChannelMessagesByUrl,
  setActiveChannelByUrl,
  pinChannel,
  unpinChannel
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChatListDrawer)
)
