import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import MobileStepper from '@material-ui/core/MobileStepper'
import FormLabel from '@material-ui/core/FormLabel'

export const Wrapper = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 2em;
  margin: 0 auto;

  .progress-stepper {
    & div[role='progressbar'] < div {
      background-color: ${({ theme }) => theme.palette.secondary.light2};
    }
  }
`

export const RadioButtonContainer = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .subheading {
    color: ${({ theme }) => theme.palette.grey.main};
    font-weight: ${({ theme }) => theme.custom.font.weight.light};
    font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  }
`

export const CustomMobileStepper = styled(MobileStepper)`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  padding: 0px;
`
export const CustomFormLabel = styled(FormLabel, {
  shouldForwardProp: isPropValid
})`
  color: ${({ theme }) => theme.palette.common.black};
  font-size: ${({ theme, headingSize }) =>
    headingSize ? headingSize : theme.custom.font.size.lg};
`

export const Heading = styled('div')`
  margin-bottom: 2.5em;
  height: 4em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 60%;
`

export const CustomInput = styled('input')`
  height: 2.1em;
  width: ${({ width }) => (width ? width : '100%')};
  border: 1px solid ${({ theme }) => theme.palette.common.black};
  color: ${({ theme }) => theme.palette.grey.main};
  border-radius: 1.2em;
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  outline: none;
  padding: 0em 1em;
`

export const BackLink = styled('div')`
  cursor: pointer;
`

export const SuccessScreen = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.palette.primary.green.mint};
  font-size: ${({ theme }) => theme.custom.font.size.xl48};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  background-color: ${({ theme }) => theme.palette.primary.navy.spaceBlue};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ErrorMessage = styled('div')`
  color: ${({ theme }) => theme.palette.error.main};
  width: 60%;
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  padding: 0.5em 0em;
`
