// @flow

import React from 'react'
import FabButton from '@Common/Button/FabButton'
import PhotoUpload from './PhotoUpload'
import { CommonIcon, ChatIcon } from '@icons'
import type { Theme } from '../../../theme'
import Header from '@Common/Header'
import Flex from '@Common/Flex'
import TextField from '@Common/TextField'
import { withTheme } from 'emotion-theming'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import { BrowserHistory } from 'history'
import type { Match } from 'react-router-dom'
import { OptionLabel, SingleValue, SelectionMenu } from './CategorySelection'
import StyledToggle from '../StyledToggle'
import AlertModal from '@Common/AlertModal/AlertModal'
import {
  CaptionHeading,
  PageContent,
  BackButton,
  WorkOrderCard,
  SwitchControl,
  StyledSelect,
  PhotoUploadDiv,
  AlertModalClass
} from './styles'
import { Switch, InputLabel } from '@material-ui/core'

type Category = {
  name: string,
  subCategories: [string]
}

type Props = {
  theme: Theme,
  history: BrowserHistory,
  match: Match,
  categories: [Category],
  units: [{ id: Number, name: string }],
  residents: { string: Object },
  assignees: [Object],
  submitWorkOrder: Object => mixed,
  updateWorkOrder: Object => mixed,
  isFetching: boolean,
  workOrderDetails: Object,
  chatEnabled: boolean
}

type State = {
  location: ?{
    value: string
  },
  resident: ?{
    value: string
  },
  assignee: ?{
    value: string
  },
  permissionToEnter: boolean,
  accessInstructions: string,
  hasPets: boolean,
  petNotes: string,
  description: string,
  category: string,
  subCategory: ?string,
  subCategorySelection: boolean,
  photos: Array<string>,
  currentStatus: string,
  chosenStatus: string,
  showDialog: boolean,
  workOrderId: ?number
}

class WorkOrderNew extends React.Component<Props, State> {
  state = {
    location: null,
    resident: null,
    assignee: null,
    permissionToEnter: true,
    accessInstructions: '',
    hasPets: false,
    petNotes: '',
    description: '',
    category: '',
    subCategory: null,
    subCategorySelection: false,
    photos: [],
    currentStatus: '',
    chosenStatus: '',
    showDialog: false,
    workOrderId: null
  }

  handlePhotos = (uploadedPhotos: Array<string>) => {
    const { workOrderDetails } = this.props

    if (workOrderDetails && workOrderDetails.photos) {
      uploadedPhotos = uploadedPhotos.filter(photo => !workOrderDetails.photos.includes(photo))
    }

    const base64photos = uploadedPhotos.map(photo => photo.split(',')[1])
    this.setState({ photos: base64photos })
  }

  goBack = () => {
    const {
      history,
      match: { url }
    } = this.props

    let splitText = '/work-orders'
    history.push(`${url.split(splitText)[0]}${splitText}/summary`)
  }

  goToChat = () => {
    const {
      history,
      workOrderDetails,
      match: { url }
    } = this.props

    history.push(`${
      url.split('/work-orders')[0]
      }/communication/chat/live-chat/users/${workOrderDetails.userId}`)
  }

  closeAlertModal = () => {
    this.setState({ showDialog: false })
  }

  submitNew = () => {
    const { submitWorkOrder } = this.props
    const {
      location,
      resident,
      assignee,
      permissionToEnter,
      accessInstructions,
      hasPets,
      petNotes,
      description,
      category,
      subCategory,
      photos
    } = this.state

    if (!this.isValid()) {
      return
    }

    submitWorkOrder({
      unitId: location && location.value,
      userId: resident && resident.value,
      assignee: assignee && assignee.value,
      permissionToEnter,
      accessInstructions,
      pets: hasPets,
      petNotes,
      category,
      subCategory,
      description,
      photos
    })
  }

  updateWorkOrder = () => {
    const { updateWorkOrder, workOrderDetails } = this.props
    const { assignee, photos, currentStatus } = this.state

    if (!this.isValid()) {
      return
    }

    const updatedWorkOrder = workOrderDetails
    updatedWorkOrder.assignee = assignee && assignee.value
    updatedWorkOrder.photos = photos

    // cannot reopen a work order
    if (updatedWorkOrder.status !== 'Closed') {
      updatedWorkOrder.status = currentStatus
    }

    updateWorkOrder(updatedWorkOrder)
  }

  isValid = () => {
    const { categories, workOrderDetails } = this.props
    const {
      location,
      permissionToEnter,
      accessInstructions,
      hasPets,
      petNotes,
      description,
      category,
      subCategory
    } = this.state

    if (workOrderDetails && workOrderDetails.workOrderId) {
      return true
    }

    const categoryObj = categories && categories.find(c => c.name === category)

    if (!description || !location || !category) {
      return false
    }

    if (!permissionToEnter && !accessInstructions) {
      return false
    }

    if (hasPets && !petNotes) {
      return false
    }

    if (categoryObj && categoryObj.subCategories.length && !subCategory) {
      return false
    }

    return true
  }

  getCategoryOptions = (categories) => {
    return categories.map((category, index) => { return { value: category.name, selectedLabel: category.name } })
  }

  handleChange = ({ currentTarget: { id, value } }) => {
    this.setState({ [id]: value })
  }

  handleToggle = ({ currentTarget: { id } }) => {
    this.setState(state => {
      return { [id]: !state[id] }
    })
  }

  handleLocation = value => {
    const resident = this.props.residents[value.value] || null
    this.setState({
      location: value,
      resident: resident && resident.length && resident[0]
    })
  }

  handleResident = value => {
    this.setState({ resident: value })
  }

  handleAssignee = value => {
    this.setState({ assignee: value })
  }

  handleCategory = ({ value }) => {
    this.setState({ category: value, subCategory: null })
  }

  handleSubcategory = ({ value }) => {
    this.setState({ subCategory: value })
  }

  handleMenuClose = () => {
    this.setState({ subCategorySelection: false })
  }

  handleChosenStatus = (event, value) => {
    if (this.state.currentStatus === 'Closed') {
      return
    }

    if (value) {
      this.setState({ chosenStatus: value, showDialog: true })
    }
  }

  handleUpdateStatus = () => {
    const { chosenStatus, currentStatus } = this.state

    if (currentStatus === 'Closed') {
      return
    }

    if (chosenStatus) {
      this.setState({ currentStatus: chosenStatus, showDialog: false }, this.updateWorkOrder)
    }
  }

  componentDidUpdate = () => {
    const { workOrderDetails, assignees } = this.props

    if (workOrderDetails && workOrderDetails.workOrderId !== this.state.workOrderId) {
      const assignee = assignees.find(a => a.value === workOrderDetails.assignee)
      this.setState({
        location: workOrderDetails.location,
        resident: workOrderDetails.submittedBy,
        assignee: assignee,
        permissionToEnter: workOrderDetails.permissionToEnter,
        accessInstructions: workOrderDetails.accessInstructions,
        hasPets: workOrderDetails.pets,
        petNotes: workOrderDetails.petNotes,
        description: workOrderDetails.description,
        category: workOrderDetails.category,
        subCategory: workOrderDetails.subCategory,
        currentStatus: workOrderDetails.status,
        workOrderId: workOrderDetails.workOrderId
      })
    }
  }

  render() {
    const { theme, units, residents, assignees, categories, workOrderDetails, isFetching, chatEnabled } = this.props
    const {
      location,
      resident,
      assignee,
      permissionToEnter,
      accessInstructions,
      hasPets,
      petNotes,
      description,
      subCategory,
      category,
      subCategorySelection,
      currentStatus,
      chosenStatus,
      showDialog
    } = this.state

    const locations = units
      .concat({ id: null, name: 'Common Space' })
      .map(u => {
        return { value: u.id, label: u.name }
      })

    const isValid = this.isValid()

    const SelectStyle = {
      control: base => ({
        ...base,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderRadius: 0,
        borderBottom: `1px solid ${theme.palette.neutrals.coolGray}`,
        marginLeft: '10px',
        boxShadow: 'none'
      })
    }

    const CategorySelectStyle = {
      control: base => ({
        ...base,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderRadius: 0,
        borderBottom: `1px solid ${theme.palette.neutrals.coolGray}`,
        marginLeft: '10px',
        boxShadow: 'none'
      }),
      menu: base => ({
        ...base,
        minWidth: '275px'
      })
    }

    return (
      <PageContent showShadow={false}>
        <Flex fullWidth justifySpaceBetween>

          <Flex direction='column' flex='1 0 auto'>
            <CaptionHeading>WORK ORDERS</CaptionHeading>
            <Header blackText variant='display1'>
              {workOrderDetails ? workOrderDetails.workOrderNumber : 'NEW WORK ORDER'}
            </Header>
          </Flex>

          {workOrderDetails &&
            <StyledToggle handleToggle={this.handleChosenStatus} startingValue={currentStatus} />
          }

          {showDialog &&
            <AlertModal
              id='UpdateStatusConfirm'
              title={
                chosenStatus === 'Closed'
                  ? 'Update work order status to Closed? You cannot reopen this work order after closing it.'
                  : `Update work order status to ${chosenStatus === 'InProgress' ? 'In Progress' : chosenStatus}?`
              }
              message=''
              buttons={[
                {
                  onClick: this.handleUpdateStatus,
                  text: 'YES'
                },
                {
                  onClick: this.closeAlertModal,
                  color: theme.palette.primary.navy.spaceBlue,
                  text: 'NO'
                }
              ]}
              show={showDialog}
              onClose={this.closeAlertModal}
              classes={{
                root: AlertModalClass
              }}
            />
          }

          <Flex direction="column" marginTop="3em">
            <BackButton
              id="backButton"
              onClick={this.goBack}
              background={theme.palette.secondary.yellow.solarFlare}
              component={() => (
                <KeyboardBackspace height="0.5em" width="0.5em" />
              )}
            />
            {chatEnabled && workOrderDetails && workOrderDetails.userId &&
              <FabButton
                id="chatButton"
                onClick={this.goToChat}
                outlined={true}
                background={theme.palette.common.white}
                style={{ marginBottom: '7px' }}
                component={() => (
                  <ChatIcon
                    name="chat"
                    width="2em"
                    height="2em"
                    fill={theme.palette.primary.dark}
                    style={{ left: '22%', position: 'absolute' }}
                  />
                )}
                size="2.7em"
              />
            }
            <FabButton
              id="submitButton"
              onClick={workOrderDetails ? this.updateWorkOrder : this.submitNew}
              background={
                isValid
                  ? theme.palette.common.white
                  : theme.palette.neutrals.deepGray
              }
              outlined={isValid}
              disabled={!isValid}
              component={() => (
                <CommonIcon
                  name="tick"
                  width="1.2em"
                  height="1.2em"
                  fill="none"
                  strokeWidth={2}
                  stroke={theme.palette.primary.dark}
                />
              )}
              size="2.7em"
            />
          </Flex>
        </Flex>
        <WorkOrderCard>
          <Flex justifySpaceBetween marginBottom="1em">
            <Flex direction="column" flex={1} paddingRight="5em">
              <StyledSelect
                id="location"
                onChange={this.handleLocation}
                value={workOrderDetails ? { label: location, value: location } : location}
                placeholder="Location"
                options={locations}
                styles={SelectStyle}
                isDisabled={workOrderDetails}
                maxMenuHeight={250}
                components={{
                  IndicatorSeparator: () => null
                }}
              />
              <StyledSelect
                id="submittedBy"
                onChange={this.handleResident}
                value={workOrderDetails ? { label: resident, value: resident } : resident}
                placeholder="Submitted By"
                options={location && residents[location.value]}
                isDisabled={!location || !residents[location.value] || workOrderDetails}
                styles={SelectStyle}
                maxMenuHeight={200}
                components={{
                  IndicatorSeparator: () => null
                }}
              />
              <SwitchControl>
                <InputLabel shrink={false}>Permission to Enter</InputLabel>
                <div>
                  <Switch
                    id="permissionToEnter"
                    onChange={!workOrderDetails && this.handleToggle}
                    checked={permissionToEnter}
                  />
                </div>
              </SwitchControl>
              {permissionToEnter ? null : (
                <TextField
                  style={{ marginTop: '0.5em' }}
                  id="accessInstructions"
                  onChange={this.handleChange}
                  value={accessInstructions || ''}
                  showPencil={true}
                  label="Access Instructions"
                  editMode={true}
                  textAlign="left"
                  alwaysShowLabel={true}
                  disabled={workOrderDetails}
                  multiline
                  rows={2}
                />
              )}
            </Flex>
            <Flex direction="column" flex={1}>
              {workOrderDetails ?
                <StyledSelect
                  id="category"
                  value={{ label: subCategory ? (category + ` - ${subCategory}`) : category, value: category }}
                  styles={SelectStyle}
                  isDisabled={true}
                  components={{
                    IndicatorSeparator: () => null
                  }}
                /> :
                categories && <StyledSelect
                  id="category"
                  placeholder="Category"
                  styles={CategorySelectStyle}
                  closeMenuOnSelect={false}
                  maxMenuHeight={250}
                  autosize={true}
                  options={this.getCategoryOptions(categories)}
                  getOptionLabel={option => <OptionLabel theme={theme} categories={categories} selectedCategory={option.value} handleToggle={this.handleToggle} />}
                  onChange={this.handleCategory}
                  onMenuClose={this.handleMenuClose}
                  components={{
                    SingleValue: (props) => <SingleValue {...props} subCategory={subCategory} />,
                    Menu: (props) =>
                      <SelectionMenu
                        {...props}
                        subCategorySelection={subCategorySelection}
                        categories={categories}
                        category={category}
                        zegoTheme={theme}
                        handleSubcategory={this.handleSubcategory}
                        handleMenuClose={this.handleMenuClose}
                      />,
                    IndicatorSeparator: null
                  }}
                />}
              <StyledSelect
                id="assignedTo"
                onChange={this.handleAssignee}
                value={assignee}
                placeholder="Assigned To"
                options={assignees}
                styles={SelectStyle}
                maxMenuHeight={200}
                isDisabled={currentStatus === 'Closed'}
                components={{
                  IndicatorSeparator: () => null
                }}
              />
              <SwitchControl>
                <InputLabel shrink={false}>Has Pets</InputLabel>
                <div>
                  <Switch
                    id="hasPets"
                    checked={hasPets}
                    onChange={!workOrderDetails && this.handleToggle}
                  />
                </div>
              </SwitchControl>
              {hasPets ? (
                <TextField
                  style={{ marginTop: '0.5em' }}
                  id="petNotes"
                  onChange={this.handleChange}
                  value={petNotes}
                  showPencil={true}
                  label="Pet Notes"
                  editMode={true}
                  textAlign="left"
                  alwaysShowLabel={true}
                  disabled={workOrderDetails}
                  multiline
                  rows={2}
                />
              ) : null}
            </Flex>
          </Flex>
          <TextField
            id="description"
            onChange={this.handleChange}
            value={description}
            showPencil={!workOrderDetails}
            label="Description"
            editMode={true}
            textAlign="left"
            alwaysShowLabel={true}
            disabled={workOrderDetails}
            fullWidth
          />
          {!isFetching &&
            <PhotoUploadDiv>
              <PhotoUpload
                uploadedPhotos={workOrderDetails ? workOrderDetails.photos : null}
                editWorkOrder={workOrderDetails}
                onPhotoUpload={this.handlePhotos}
              />
            </PhotoUploadDiv>
          }
        </WorkOrderCard>
      </PageContent >
    )
  }
}

export default withTheme(WorkOrderNew)
