// @flow
import React, { Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Layout from './GroupMessagingLayout'
import PaginatedTable from '../../Common/PaginatedTable'
import BackButton from './BackButton'
import { GroupsIcons, TemplatesIcons } from '@icons'
import drop from 'lodash/drop'
import take from 'lodash/take'
import {
  GroupMessagingRowStyled,
  GroupsButtonStyled,
  TemplatesButtonStyled,
  GroupMessagingColumnStyled as ColumnStyled,
  TableContainer
} from './styles'
import Avatar from '@Common/Avatar'
import Flex from '@Common/Flex'
import Loader from '../../Common/Loader'
import theme from '../../../theme'

const createRows = (data, { propertyId }, isFutureTab) => {
  return data.map(
    ({ id, senderPicture, sendingMethods, groups, ...rowData }) => {
      const image = <Avatar picture={senderPicture} size={'2em'} hideShadow />

      rowData = { picture: image, ...rowData }
      const keys = isFutureTab
        ? [
            { key: 'picture', classNames: 'text-center' },
            { key: 'senderName', classNames: 'text-left' },
            { key: 'title', classNames: 'text-left weight-light text-black' },
            { key: 'group', classNames: 'text-center text-grey weight-light' },
            { key: 'recipientsCount', classNames: 'highlight text-center' },
            {
              key: 'frequency',
              classNames: 'text-center text-grey weight-normal',
            },
            { key: 'date', classNames: 'text-right' },
          ]
        : [
            { key: 'picture', classNames: 'text-center' },
            { key: 'senderName', classNames: 'text-left' },
            { key: 'title', classNames: 'text-left weight-light text-black' },
            {
              key: 'inAppCount',
              classNames: 'text-center text-grey weight-light',
            },
            {
              key: 'textCount',
              classNames: 'text-center text-grey weight-light',
            },
            {
              key: 'emailCount',
              classNames: 'text-center text-grey weight-light',
            },
            { key: 'recipientsCount', classNames: 'highlight text-center' },
            { key: 'date', classNames: 'text-right' },
          ]
      return keys
        .map(({ key, classNames }, index) => {
          const isEvent = rowData.isEvent
          return {
            value: (
              <Link
                to={`/properties/${propertyId}/communication/group-messaging/${
                  isFutureTab ? isEvent ? 'event' : 'future' : 'sent'
                }/${id}${isFutureTab ? `?time=${rowData.runTime}` : ''}`}>
                <GroupMessagingRowStyled className={classNames}>
                  {rowData[key]}
                </GroupMessagingRowStyled>
              </Link>
            ),
          }
        })
    }
  )
}

const historyColumns = [
  { value: <ColumnStyled index={0} /> },
  { value: <ColumnStyled index={1} /> },
  { value: <ColumnStyled index={2} /> },
  {
    value: <ColumnStyled index={3}>in app</ColumnStyled>,
  },
  {
    value: <ColumnStyled index={4}>text</ColumnStyled>,
  },
  {
    value: <ColumnStyled index={5}>email</ColumnStyled>,
  },
  {
    value: <ColumnStyled index={6}>recipients</ColumnStyled>,
  },
  {
    value: <ColumnStyled index={7}>date</ColumnStyled>,
  },
]

const futureColumns = [
  { value: <ColumnStyled index={0} /> },
  { value: <ColumnStyled index={1} /> },
  { value: <ColumnStyled index={2} /> },
  {
    value: <ColumnStyled index={3}>group</ColumnStyled>,
  },
  {
    value: <ColumnStyled index={4}>recipients</ColumnStyled>,
  },
  {
    value: <ColumnStyled index={5}>frequency</ColumnStyled>,
  },
  {
    value: (
      <ColumnStyled index={6} className="text-right">
        date/event
      </ColumnStyled>
    ),
  },
]

const tabs = [
  {
    name: 'Sent',
    query: { status: 'sent' },
  },
  {
    name: 'Future',
    query: { status: 'future' },
  },
]

const getButtons = (propertyId, history, buttonIsDisabled) => (
  <React.Fragment>
    <Flex alignCenter justifyRight>
      <BackButton
        onClick={() => history.push(`/properties/${propertyId}/communication`)}
      />
    </Flex>
    <Flex alignCenter justifyRight>
      <TemplatesButtonStyled
        id="viewTemplatesBtn"
        variant="fab"
        style={ buttonIsDisabled ? {
          border: `3px solid ${theme.palette.neutrals.mediumGray}`
        } : null}
        disabled={buttonIsDisabled}
        onClick={() =>
          history.push(
            `/properties/${propertyId}/communication/group-messaging/templates`
          )
        }>
        <TemplatesIcons name="templates" height="1.5em" width="1.5em" />
      </TemplatesButtonStyled>
    </Flex>
    <Flex alignCenter justifyRight>
      <GroupsButtonStyled
        id="viewGroupsBtn"
        variant="fab"
        style={ buttonIsDisabled ? {
          border: `3px solid ${theme.palette.neutrals.mediumGray}`
        } : null}
        disabled={buttonIsDisabled}
        onClick={() =>
          history.push(
            `/properties/${propertyId}/communication/group-messaging/groups`
          )
        }>
        <GroupsIcons name="groups" height="1.5em" width="1.5em" />
      </GroupsButtonStyled>
    </Flex>
  </React.Fragment>
)

const getPaginateFutureData = (data, page, pageSize) => {
  if (!Array.isArray(data)) {
    return {
      total: 0,
      totalPages: 0,
      data: []
    }
  }

  const offset = (page - 1) * pageSize
  const pagedData = take(drop(data, offset), pageSize)
  return {
    total: data.length,
    data: pagedData
  }
}

const GroupMessaging = ({
  isFetching,
  hasData,
  data,
  match: { params, url },
  isFutureTab,
  history,
  currentPage,
  searchResultsFuture,
  searchFuture,
  total,
  perPage,
  fetchPage,
  searchHistory,
  userRole,
  displayErrorMessage,
  propertyId
}) => {
  const [page, setPage] = React.useState(currentPage)
  const futureData = React.useMemo(
    () => getPaginateFutureData(searchResultsFuture, page, 10),
    [searchResultsFuture, page]
    )
    const rowData = isFutureTab ? futureData.data : data
    const rowTotal = isFutureTab ? searchResultsFuture && futureData.total : total
    
    const pageChange = (page) => {
      if (isFutureTab) {
        return setPage(page)
      }
      fetchPage(page)
    }
    
    const buttonIsDisabled = (userRole === "property_staff")
    
    React.useEffect(() => {
      setPage(currentPage)
    }, [currentPage])

    if (userRole === "property_staff") {
      history.replace(`/properties/${propertyId}/communication`)
      setTimeout(() => {
        displayErrorMessage("Group Messaging is not available for Property Staff.")
      }, 1000)
  
      return null
    }

  return (
    <Layout
      header="COMMUNICATION"
      title="Group Messages"
      userRole={userRole}
      displayErrorMessage={displayErrorMessage}
      onCreateClick={() => (userRole === "property_staff")
        ? displayErrorMessage("Creating new Group Messages is not available for Property Staff.")
        : history.push(`${url}/create`)
      }
      onSearch={isFutureTab ? searchFuture : searchHistory}
      tabs={tabs}
      buttons={getButtons(params.propertyId, history, buttonIsDisabled)}
      table={
        isFetching || !hasData ? (
          <React.Fragment>
            <br />
            <br />
            <Loader />
          </React.Fragment>
        ) : rowTotal > 0 ? (
          <TableContainer>
            <PaginatedTable
              elementId="groupMessagingTable"
              total={total}
              flex={
                isFutureTab
                  ? [0.5, 2, 7, 1, 1, 1, 2]
                  : [0.5, 2, 7, 1, 1, 1, 1, 2]
              }
              columns={isFutureTab ? futureColumns : historyColumns}
              rows={createRows(rowData, params, isFutureTab)}
              rowsPerPage={perPage || 10}
              currentPage={page - 1}
              changePage={pageChange}
            />
          </TableContainer>
        ) : (
          <Fragment>
            <br />
            <br />
            <div>No {isFutureTab ? 'future' : 'history'} exists</div>
          </Fragment>
        )
      }
    />
  )
}

export default withRouter(GroupMessaging)
