// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import SummaryCard from '@Common/Cards/SummaryCard'
import CarouselUnit from './BatteryCarouselUnit'
import SummaryBG from '../../../../images/summary_bg.png'
import { getSmartHeaderTabs } from '../smartUtils'
import { State, Props } from '../types'

class SmartSummary extends React.Component<Props, State> {
  filtersToDisplay = () => {
    return [
      { name: 'Low', query: { filter: 'low' } },
      { name: 'Medium', query: { filter: 'mid' } },
      { name: 'High', query: { filter: 'high' } }
    ]
  }

  getCarouselRows = () => {
    const { batteryInfo } = this.props
    return batteryInfo.map((data, index) => (
      <CarouselUnit
        key={index}
        lastUnit={index === batteryInfo.length}
        unitId={data.unitId}
        deviceId={data.deviceId}
        deviceType={data.deviceType}
        name={data.name}
      />
    ))
  }

  render() {
    const { filter, propertyId, count, scheduleEnabled } = this.props

    return (
      <React.Fragment>
        <SummaryCard
          countText={`${filter} Level`}
          captionHeading="ZEGO SMART"
          unitCount={count}
          mainHeading="Devices on Battery"
          headerTabs={getSmartHeaderTabs(propertyId, '', scheduleEnabled)}
          items={this.getCarouselRows()}
          imageSrc={SummaryBG}
          overflow="visible"
          filterNames={this.filtersToDisplay()}
        />
      </React.Fragment>
    )
  }
}

export default withTheme(SmartSummary)
