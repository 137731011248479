import styled from '@emotion/styled'

export const Wrap = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: ${props => props.rowDirection};

  & > span {
    align-self: center;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

  & > span:first-child {
    flex: 6;
  }

  & > span:last-child {
    flex: 1;
  }
`
