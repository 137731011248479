import React from 'react'

export default {
  AccessSmart: 'This setting shows if Zego Smart is enabled',
  AccessChat: 'This setting shows if chat is enabled',
  IntegratedWorkOrder:
    'This setting shows if Integrated Work Orders are enabled. Integrated Work orders are submitted via the Resident App and sent to your property management software',
  StandaloneWorkOrder:
    'This setting shows if Standalone Work Orders are enabled. Standalone Work orders are submitted via the Resident App and managed from the Admin App',
  ResidentSurveys: 'This setting shows if Resident Surveys are enabled.',
  MoveInChecklist: 'This setting shows if Move-in Checklist is enabled.',
  AccessPayment: 'This setting shows if payments are enabled',
  AccessCode:
    'If you have an integration enabled for building access (ie. Brivo), this setting enables residents to see their code in the Resident App',
  NotificationBatteryText:
    'If enabled, sends a daily report (via text message) at 8AM of all devices with low batteries (<15%) to your cell phone number',
  NotificationBatteryEmail:
    'If enabled, sends a daily report (via email) at 8AM of all devices with low batteries (<15%) to your email address',
  NotificationWorkOrderEmail:
    'If enabled, all work orders will be sent to the property’s main email.  Zego work orders do not integrate with any other systems you may be using for maintenance requests.',
  NotificationChatEmail:
    ' if enabled, sends a daily email of unread chats to the main property email.',
  GroupMessagingTemplateColumn:
    'Type: Templates either apply to an individual property (“property”) or all properties associated with your company (”company”)',
  WordCount:
    'If your character limit exceeds 160 characters, this message will be delivered in multiple texts or chats.',
  TextEditorTextCount:
    'This shows the number of characters typed in the text editor',
  CommunityFeed:
    'This setting shows if community feed is available to this property’s residents.',
  GroupMessaging:
    'This setting  shows if group messaging is available to this property’s residents.',
  Marketplace:
    'This setting shows if marketplace is enabled for your property. Marketplace features are shown to the residents in the resident mobile app.',
  SmartLeaseRenewal:
    'This setting shows if Smart Lease Renewal is enabled.',
  AmenityReservations:
    'This setting shows if Amenity Reservations is enabled.',
  PropertyUser: (
    <div>
      Property users are staff members with access to this property in Zego.
      There are four roles a property user could have.
      <br />
      <br />
      <b>Company Admin</b>
      <br />
      These users have company level admin access to all properties within a
      company.
      <br />
      <br />
      <b>Property Admin</b>
      <br />
      These users have admin access to a single property.
      <br />
      <br />
      <b>Maintenance</b>
      <br />
      These users have access restricted to maintenance level tasks.
      <br />
      <br />
      <b>Setup</b> <br />
      These users have access restricted to the setup of Zego Smart units.
      <br />
      <br />
    </div>
  ),
  PropertyCode: (
    <div>
      The Property Code is a lock code that can be used to access all units.
      <br />
      <i>
        <b>Note:</b> Each time this code is used to enter a unit, Zego
        recognizes the code and notifies the resident that the associated user
        accessed their lock.
      </i>
    </div>
  ),
  summaryQuickLink: 'Summary',
  smartQuickLink: 'Smart View',
  userManagementQuickLink: 'Users Management',
  workOrdersQuickLink: 'Work Orders',
  upcomingEvent: 'The Next 3 Upcoming events',
  newEvent: 'Created within the last 24 hours',
  OfficeHours:
    'These hours will be displayed in the Resident App. It’s important to format it as follows: “8am-5pm M-F, 2pm-4pm Sat, Closed Sun” so it displays correctly',
  PropertyEmail:
    'Enter the primary email address for the property. This is where we will send various notifications. This email WILL NOT be displayed in the Mobile App',
  MaintenanceEmail:
    'This email is used for emailing work orders if this property does NOT have a PMS integration',
  AddressLine1:
    'This address will be displayed to residents in the Resident App',
  OfficePhone:
    'This phone number will be displayed in the Resident App as the main office number',
  EmergencyPhoneNumber:
    'This phone number will be displayed to residents as the emergency maintenance phone number',
  PropertyCode2:
    'Property Code: This is your unique code for units with smart locks. Individual unit access rights vary by user. Do not share your code with anyone.',
  TemporaryCodeLimit: 'You can create a maximum of two temporary lock codes',
  VendorCodes:
    'This setting shows if vendor lock codes are enabled. The vendor lock codes feature, allows you to manage lock code access you wish to give to your trusted 3rd party vendors',
  PetRegistration: 'This setting shows if pet registration is enabled.',
  VehicleRegistration: 'This setting shows if vehicle registration is enabled.',
  VisitorRegistration: 'This setting shows if visitor registration is enabled.',
  GlobalDeals: 'These are Zego nationwide deals for all properties and cannot be removed from the Marketplace.',
  AmenityReservationBufferTime: 'This allows for a set buffer of time after reservations that allows site staff to clean or reset an amenity.',
  OfflinePropertyHubs: 'These hubs need to plugged in by the resident or a property staff member.'
}
