import { call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken } from '../../authorization/selectors'
import { getPropertyId } from '../../select/selectors'
import { showSnackbar } from '../../snackbar/actions'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  failedToFetchMessage,
  failedToFetchMessageUserSummary,
  deleteGroupMessageSuccess,
  genericErrorMsg
} from '../../../utils/messages'
import {
  isDateTime,
  isEvent,
  isSendNow,
  isTemplate,
  getSelectedGroupsIds,
  createCron,
  getEventType,
  getOffset
} from './utils'
import {
  fetchSingleMessageAction,
  fetchSingleMessageStart,
  fetchSingleMessageSuccess,
  fetchSingleMessageError,
  fetchMessageUsersSummaryAction,
  fetchMessageUsersSummaryStart,
  fetchMessageUsersSummarySuccess,
  fetchMessageUsersSummaryError,
  deleteSingleMessageAction,
  sendGroupMessageSuccess,
  sendGroupMessage,
} from './actions'
import { capitalizeFirstLetter } from 'zego-shared/utils/string'

export function* fetchSingleMessage({ groupMessageId, status, runTime }) {
  try {
    yield put(fetchSingleMessageStart())
    const authToken = yield select(getToken)

    let response = yield call(
      api.getSingleGroupMessageHistory,
      authToken,
      groupMessageId
    )

    if (['future', 'event'].includes(status)) {
      const futureResponse = yield call(
        api.getAutomationFutureDetails,
        authToken,
        groupMessageId,
        runTime
      )

      if (status === 'event') {
        response = { ...response, ...futureResponse }
      } else {
        response = futureResponse
      }
    }
    yield put(fetchSingleMessageSuccess(response))
  } catch (error) {
    yield put(fetchSingleMessageError(error))
    yield put(showSnackbar(failedToFetchMessage, 'error'))
    log(`Failed to fetch group message`)
  }
}

export function* fetchMessageUsersSummary({
  groupMessageId,
  searchTerm,
  queryParams,
  isFuture,
  time,
  page = 1
}) {
  try {
    yield put(fetchMessageUsersSummaryStart())
    const authToken = yield select(getToken)
    let response

    if (!isFuture) {
      if (searchTerm == null || searchTerm.length <= 0) {
        response = yield call(
          api.getSingleGroupMessageHistoryUsers,
          authToken,
          groupMessageId,
          page
        )
      } else {
        response = yield call(
          api.searchSingleGroupMessageUsers,
          authToken,
          groupMessageId,
          searchTerm,
          queryParams,
          page
        )
      }
    } else {
      response = yield call(
        api.getSingleGroupMessageFutureUsers,
        authToken,
        groupMessageId,
        time,
        page
      )
    }

    yield put(fetchMessageUsersSummarySuccess(response))
  } catch (error) {
    yield put(fetchMessageUsersSummaryError(error))
    yield put(showSnackbar(failedToFetchMessageUserSummary, 'error'))
    log(`Failed to fetch group message`)
  }
}

export function* deleteSingleMessage({ groupMessageId, history }) {
  try {
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)

    yield call(api.deleteAutomation, authToken, groupMessageId)
    history.push(
      `/properties/${propertyId}/communication/group-messaging?status=future`
    )
    yield put(showSnackbar(deleteGroupMessageSuccess, 'success'))
  } catch (error) {
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

export function* createGroupMessage({
  payload: {
    type,
    templates,
    message,
    selectedTemplateId,
    selectedGroups,
    selectedDeliveryMethods,
    selectedDateTime,
    events,
    selectedEventId,
    offsetBeforeAfter,
    offsetDays,
    timezone
  }
}) {
  try {
    const authToken = yield select(getToken)

    const propertyId = yield select(getPropertyId)

    const selectedGroupIds = yield call(getSelectedGroupsIds, selectedGroups)

    const eventType = yield call(getEventType, events, selectedEventId)

    const offset = yield call(getOffset, offsetBeforeAfter, offsetDays)

    const templateUsed = isTemplate(templates, selectedTemplateId, message)

    const templateNotUsed = !templateUsed

    const isNewTemplate = message.templateName !== ''

    const callSelector = templateNotUsed ? 'NoTemplate' : 'Template'

    const createGroupMessageParameters = {
      nowNoTemplate: () => [
        message.title,
        message.message,
        message.textMessage,
        selectedTemplateId,
        selectedGroupIds,
        selectedDeliveryMethods,
        message.files
      ],
      dateTimeNoTemplate: () => [
        createCron(
          type,
          timezone,
          selectedDateTime.moment,
          selectedDateTime.recurringOptionName
        ),
        selectedGroupIds,
        selectedDeliveryMethods,
        message.message,
        message.textMessage,
        message.title,
        message.templateName,
        message.files
      ],
      eventNoTemplate: () => [
        createCron(type, timezone, selectedDateTime.moment),
        selectedGroupIds,
        selectedDeliveryMethods,
        eventType,
        offset,
        message.message,
        message.textMessage,
        message.title,
        message.templateName,
        message.files
      ],
      eventTemplate: () => [
        selectedTemplateId,
        createCron(type, timezone, selectedDateTime.moment),
        selectedGroupIds,
        selectedDeliveryMethods,
        eventType,
        offset
      ],
      nowTemplate: () => [
        selectedTemplateId,
        selectedGroupIds,
        selectedDeliveryMethods
      ],
      dateTimeTemplate: () => [
        selectedTemplateId,
        createCron(
          type,
          timezone,
          selectedDateTime.moment,
          selectedDateTime.recurringOptionName
        ),
        selectedGroupIds,
        selectedDeliveryMethods
      ]
    }

    if (
      selectedDeliveryMethods.includes('preferred') &&
      (message.message == null ||
        message.message === '' ||
        message.textMessage == null ||
        message.textMessage === '')
    ) {
      yield put(
        showSnackbar(
          'Preferred method was selected for delivery. Please provide both email and text message!',
          'error'
        )
      )
      return Promise.reject('')
    } else if (templateNotUsed && !isNewTemplate && !isSendNow(type)) {
      yield put(
        showSnackbar(
          'Messages for a future date time must be saved as a template! Please click the message section and select save as template and name the template',
          'error'
        )
      )
      return Promise.reject('')
    } else {
      if (!message.title) {
        message.title = message.templateName
      }

      if (isSendNow(type)) {
        yield call(
          api[`sendGroupMessageNow${callSelector}`],
          authToken,
          propertyId,
          ...createGroupMessageParameters[`now${callSelector}`]()
        )
      }

      if (isDateTime(type)) {
        yield call(
          api[`sendGroupMessageDateTime${capitalizeFirstLetter(callSelector)}`],
          authToken,
          propertyId,
          ...createGroupMessageParameters[`dateTime${callSelector}`]()
        )
      }

      if (isEvent(type)) {
        yield call(
          api[`sendGroupMessageEvent${capitalizeFirstLetter(callSelector)}`],
          authToken,
          propertyId,
          ...createGroupMessageParameters[`event${callSelector}`]()
        )
      }

      yield put(sendGroupMessageSuccess())
    }
  } catch (error) {
    yield put(showSnackbar('Create failed! Please try again!', 'error'))
  }
}

function* watchFetchSingleMessage() {
  yield takeLatest(fetchSingleMessageAction().type, fetchSingleMessage)
}

function* watchFetchMessageUsersSummary() {
  yield takeLatest(
    fetchMessageUsersSummaryAction().type,
    fetchMessageUsersSummary
  )
}

function* watchDeleteSingleMessage() {
  yield takeLatest(deleteSingleMessageAction().type, deleteSingleMessage)
}

function* watchSendGroupMessage() {
  yield takeLatest(sendGroupMessage().type, createGroupMessage)
}

export default [
  watchFetchSingleMessage(),
  watchFetchMessageUsersSummary(),
  watchDeleteSingleMessage(),
  watchSendGroupMessage()
]
