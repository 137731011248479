// @flow
import React from 'react'
import isEqual from 'lodash/isEqual'
import HistoryContent from './HistoryContent'
import { HistoryContentWrap } from './styles'
import { HistoryProps, HistoryState } from './types'
import CarouselUnit from './CarouselUnit'
import FilterSection from '../../../Common/FilterSection'
import Flex from '@Common/Flex'
import Loader from '../../../Common/Loader'

import type { TabsType } from '../../../Common/FilterSection/types'

const tabs: TabsType = [
  {
    name: 'All',
    query: { deviceTypes: 'all' }
  },
  {
    name: 'Thermostat',
    query: { deviceTypes: 'thermostat' }
  },
  {
    name: 'Lock',
    query: { deviceTypes: 'lock' }
  },
  {
    name: 'Lights',
    query: { deviceTypes: 'switch' }
  }
]

class History extends React.Component<HistoryProps, HistoryState> {
  state = {
    data: this.props.data,
    isFetchMore: false
  }

  componentDidUpdate(prevProps: HistoryProps) {
    const { data, isFetchMore } = this.state
    const { data: newData } = this.props

    if (!isEqual(prevProps.data, this.props.data)) {
      if (isFetchMore) {
        return this.setState({
          data: data.concat(newData),
          isFetchMore: false
        })
      }
      this.setState({
        data: newData
      })
    }
  }

  getItems = () => {
    const { data } = this.state
    return data.map(
      ({ date, time, message, divider }, index): CarouselUnit => (
        <CarouselUnit
          key={index}
          lastUnit={index === data.length - 1}
          date={date}
          time={time}
          message={message}
          divider={divider}
        />
      )
    )
  }

  getNumberOfSlide = () => {
    const { data } = this.state
    const numberOfUnitsPerSlide = 10

    return data.length < numberOfUnitsPerSlide
      ? data.length
      : numberOfUnitsPerSlide
  }

  fetchMore = () => {
    const { currentPage, totalPages, fetchDeviceHistory } = this.props
    if (currentPage < totalPages) {
      fetchDeviceHistory(currentPage + 1)
      this.setState({ isFetchMore: true })
    }
  }

  render() {
    const { isFetching } = this.props
    const { data, isFetchMore } = this.state
    const items = this.getItems()
    const numberOfUnitsPerSlide = this.getNumberOfSlide()
    return (
      <React.Fragment>
        <HistoryContentWrap>
          {isFetching && !isFetchMore ? (
            <Flex flex alignCenter justifyCenter fullHeight>
              <Loader fullScreen={false} color="#ccc" />
            </Flex>
          ) : data.length === 0 ? (
            <Flex flex alignCenter justifyCenter fullHeight>
              There are currently no device events
            </Flex>
          ) : (
            <HistoryContent
              numberOfUnitsPerSlide={numberOfUnitsPerSlide}
              lastSlideNumber={Math.ceil(data.length / numberOfUnitsPerSlide)}
              items={items}
              transitionHeights={['0em', '1em']}
              onLastSlide={this.fetchMore}
            />
          )}
        </HistoryContentWrap>
        <FilterSection tabs={tabs} />
      </React.Fragment>
    )
  }
}

export default History
