import { getInitials } from './string'

export const getProfileUrlOrDefault = (profileUrl, name) => {
  return profileUrl
    ? profileUrl
    : generateProfilePictureUrl(getInitials(name))
}

export const generateProfilePictureUrl = initials => {

  const initial = initials ? initials : 'AB'
  const url = `https://i2.wp.com/cdn.auth0.com/avatars/${initial.toLowerCase()}.png?ssl=1`

  return url
}
