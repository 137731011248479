import { createFetchActions } from '../../helpers/actions/creators'

export const GROUP_MESSAGES_SENT_TODAY = 'GROUPMESSAGESSENTTODAY'

export const {
  fetchAction: fetchGroupMessagesSentTodayAction,
  fetchStartAction: fetchGroupMessagesSentTodayStart,
  fetchSuccessAction: fetchGroupMessagesSentTodaySuccess,
  fetchErrorAction: fetchGroupMessagesSentTodayError
} = createFetchActions(GROUP_MESSAGES_SENT_TODAY)

export const fetchGroupMessagesSentToday = resource =>
  fetchGroupMessagesSentTodayAction({ resource })
