// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { CustomFormControlLabel, CustomFormControl } from './styles'
import { RadiobuttonIcon } from '@icons'

const RadioButtons = ({
  selectedValue,
  name,
  handleChange,
  options,
  textSize,
  handleClick = () => {},
  width
}) => (
  <CustomFormControl
    textSize={textSize}
    width={width}
    component="fieldset"
    classNames={{ root: 'form-cont{rol-radio-button' }}>
    <RadioGroup
      name={name}
      classes={{ root: 'radio-button-group' }}
      value={selectedValue}
      onChange={handleChange}>
      {options.map(({ value, label, disabled }, index) => (
        <CustomFormControlLabel
          lastRow={index === options.length - 1}
          classes={{ root: 'radio-button-control-label' }}
          key={`${index}-${value}`}
          value={value}
          control={
            <Radio
              checkedIcon={
                <RadiobuttonIcon
                  width={'1.5em'}
                  height={'1.1em'}
                  name="selected"
                />
              }
              icon={
                <RadiobuttonIcon
                  width={'1.5em'}
                  height={'1.1em'}
                  name="unselected"
                />
              }
            />
          }
          label={label}
          disabled={disabled}
          onClick={handleClick}
        />
      ))}
    </RadioGroup>
  </CustomFormControl>
)

export default withTheme(RadioButtons)
