// @flow
import React from 'react'
import ReactSelect from 'react-select'
import { Divider, Grid } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import CheckBox from '@Common/CheckBox'
import DropDown from '@Common/DropDown'
import ResidentSearch from '@Common/ResidentSearch'
import Flex, { FlexItem } from '@Common/Flex'
import DateInput from '@Common/DateInput'
import theme from './../../../theme'
import moment from 'moment'
import { validateReservation } from '../../../utils/amenityUtils'
import { getDatesBetween } from '../../../utils/dateUtils'

import {
  AvailabilityDayHeader,
  AmenityReservationsDiv,
  AvailabilityHoursHeader,
  NewReservationCheckBoxDiv,
  NewReservationDeleteButton,
  NewReservationDiv,
  NewReservationForm,
  NewReservationGuestDiv,
  NewReservationResidentAvatar,
  NewReservationResidentDetailsDiv,
  ReservationInvalid,
  ResidentDiv,
  ResidentMessageContainer,
  ResidentMessage,
  CheckTime
} from '../AmenityShared/styles'

type Props = {
  item: Object,
  availability: Object,
  selectedAvailabilityDate: Object,
  selectedTimeSlot: Object,
  availability: Object,
  reservations: Array<Object>,
  updateNewReservation: (Object) => void,
  newReservation: {
    resident: Object,
    groupSize: number,
    message: ?string,
    includeResource: boolean,
    checkIn: ?string,
    checkOut: ?string,
    selectedFlexStartTime: ?number,
    selectedFlexEndTime: ?number,
  },
}
export default class AmenityCreateReservation extends React.Component<Props, {}> {
  timeFromMilTime = (milTime: number) => {
    const dateString = (`${milTime}`).padStart(4, '0')
    return moment(dateString, 'Hmm')
  }
  milTimeFromTime = (time: Object) => {
    return parseInt(time.format('Hmm'), 10)
  }

  handleResultClick = (result: Object) => {
    const { updateNewReservation } = this.props
    updateNewReservation({
      resident: result,
      groupSize: 1,
      message: null,
      includeResource: false,
    })
  }

  handleDeleteButtonClick = () => {
    const { updateNewReservation } = this.props
    updateNewReservation({
      resident: null,
      groupSize: 1,
      message: null,
      includeResource: false,
    })
  }

  handleGuestsDropdown = (groupSize: number) => {
    const { updateNewReservation } = this.props
    updateNewReservation({ groupSize })
  }

  handleMessageTextfield = ({ currentTarget: { value } }: SyntheticEvent<HTMLInputElement>) => {
    const { updateNewReservation } = this.props
    if (value && value.length <= 40) {
      updateNewReservation({ message: value })
    }
  }

  handleResourceCheckbox = (_e: any, checked: boolean) => {
    const { updateNewReservation } = this.props
    updateNewReservation({ includeResource: checked })
  }

  handleCheckInTime = (date: Object) => {
    const { updateNewReservation, newReservation } = this.props
    const checkIn = moment(date).format('YYYY-MM-DD')
    if (newReservation.checkOut && newReservation.checkOut <= checkIn) {
      updateNewReservation({ checkIn, checkOut: null })
    } else {
      updateNewReservation({ checkIn })
    }
  }

  handleCheckOutTime = (date: Object) => {
    const { updateNewReservation } = this.props
    updateNewReservation({ checkOut: moment(date).format('YYYY-MM-DD') })
  }

  handleFlexStartTime = (time: number) => {
    let { updateNewReservation, item, newReservation: { selectedFlexEndTime } } = this.props
    const { minDuration, duration: maxDuration } = item
    const startTime = this.timeFromMilTime(time)
    const minEndTime = this.milTimeFromTime(startTime.add(minDuration, 'minutes'))
    const maxEndTime = this.milTimeFromTime(startTime.add(maxDuration, 'minutes'))
    if (selectedFlexEndTime) {
      if (selectedFlexEndTime < minEndTime) {
        selectedFlexEndTime = minEndTime
      } else if (selectedFlexEndTime > maxEndTime) {
        selectedFlexEndTime = maxEndTime
      }
    }
    updateNewReservation({ selectedFlexStartTime: time, selectedFlexEndTime })
  }

  handleFlexEndTime = (time: number) => {
    const { updateNewReservation } = this.props
    updateNewReservation({ selectedFlexEndTime: time })
  }

  createFlexibleTimeSlots = (startTime: Object, endTime: Object, minTime: Object, interval: number) => {
    let times: Array<Object> = []

    while (startTime <= endTime) {
      const timeMil = this.milTimeFromTime(startTime)
      const time = startTime.format('h:mm a')
      if (startTime > minTime) {
        times.push({ label: time, value: timeMil })
      }
      startTime = startTime.add(interval, 'minutes')
    }

    return times
  }

  getFlexibleStartTimeOptions = (availability: Object, minDuration: ?number, interval: number = 30) => {
    let times: Array<Object> = []
    if (!availability) {
      return times
    }
    const { timeSlots, currentFacilityDate: { time: currentTime } } = availability
    if (!timeSlots) {
      return times
    }
    const minTime = this.timeFromMilTime(currentTime).subtract(interval, 'minutes')
    const filteredTimeSlots = timeSlots.filter(({ status }) => status !== 'closed' && status !== 'full')

    for (let timeSlot of filteredTimeSlots) {
      let startTime = this.timeFromMilTime(timeSlot.startTime)
      const endTime = this.timeFromMilTime(timeSlot.endTime).subtract(minDuration, 'minutes')
      times = times.concat(this.createFlexibleTimeSlots(startTime, endTime, minTime, interval))
    }

    return times
  }

  getFlexibleEndTimeOptions = (availability: Object, selectedStartTime: ?number, minDuration: number, maxDuration: number, interval: number = 30) => {
    let times: Array<Object> = []
    if (!availability) {
      return times
    }
    const { timeSlots, currentFacilityDate: { time: currentTime } } = availability
    const minTime = this.timeFromMilTime(currentTime).subtract(interval, 'minutes')
    if (!timeSlots) {
      return times
    }
    const filteredTimeSlots = timeSlots.filter(({ status, startTime, endTime }) => {
      return status !== 'closed' && status !== 'full' &&
        ((!selectedStartTime && selectedStartTime !== 0) ||
          (startTime <= selectedStartTime && endTime >= selectedStartTime))
    })

    let maxEndTime = selectedStartTime ? this.timeFromMilTime(selectedStartTime).add(maxDuration, 'minutes') : this.timeFromMilTime(2400)

    for (let timeSlot of filteredTimeSlots) {
      const startMilTime = selectedStartTime ? selectedStartTime : timeSlot.startTime
      let startTime = this.timeFromMilTime(startMilTime).add(minDuration, 'minutes')
      let endTime = this.timeFromMilTime(timeSlot.endTime)
      if (endTime > maxEndTime) {
        endTime = maxEndTime
      }
      times = times.concat(this.createFlexibleTimeSlots(startTime, endTime, minTime, interval))
    }

    return times
  }

  getLocalDate = (date: string) => {
    const spl = date.split("-");
    return new Date(parseInt(spl[0]), parseInt(spl[1]) - 1, parseInt(spl[2]))
  }

  getMultiDayBlocks = (date: string, item: Object, availability: Object) => {
    const {
      reservationType,
      maxReservationDays,
      minMultiDayReservation,
      maxMultiDayReservation,
    } = item

    const todayDate = this.getLocalDate(moment().format('yyyy-MM-DD'))
    const lastDate = this.getLocalDate(moment().add(maxReservationDays, 'days').format('yyyy-MM-DD'))

    const checkInDate = date ? this.getLocalDate(moment(date).format('yyyy-MM-DD')) : todayDate

    if (reservationType !== 'multiDay' || !availability) {
      return {}
    }

    const { timeSlots } = availability
    const closedSlots = timeSlots.filter((t) =>
      (t.status === 'closed' || t.status === 'full')
    )
    let checkInBlocks = []
    let checkOutBlocks = []

    for(let i = 0; i < closedSlots.length; i+=1) {
      const { startDate: csStart, endDate: csEnd, status } = closedSlots[i]
      if(status === 'closed') {
        checkOutBlocks.push(csStart)
      }
      checkInBlocks.push(csStart)

      const middleBlocks = getDatesBetween(csStart,csEnd)
      checkInBlocks = checkInBlocks.concat(middleBlocks)
      checkOutBlocks = checkOutBlocks.concat(middleBlocks)

      if(status === 'closed') {
        checkInBlocks.push(csStart)
      }
      checkOutBlocks.push(csStart)
    }
    
    let checkInFilter: Array<Object> = [];
    checkInFilter.push({ before: todayDate });
    checkInFilter.push({ after: lastDate });
    if (checkInBlocks.length > 0) {
      checkInFilter = checkInFilter.concat(checkInBlocks.map((block: Object): Date => {
        return this.getLocalDate(block)
      }))
    }

    let checkOutFilter: Array<Object> = [];
    const checkOutMinimum = new Date(checkInDate)
    checkOutMinimum.setDate(checkOutMinimum.getDate() + minMultiDayReservation)

    let checkOutMaximum = new Date(checkInDate)
    checkOutMaximum.setDate(checkOutMaximum.getDate() + maxMultiDayReservation)
    if (checkOutMaximum > lastDate) {
      checkOutMaximum = lastDate
    }
    if (checkOutBlocks.length > 0) {
      const dateMinimumString = moment(checkOutMinimum).format('yyyy-MM-DD')
      const minBlockArray = checkOutBlocks.filter(block => {
        return (block > dateMinimumString)
      })
      const newMaximum = (minBlockArray.length > 0) ? this.getLocalDate(minBlockArray[0]) : null
      if (newMaximum) {
        newMaximum.setDate(newMaximum.getDate() - 1)
        checkOutMaximum = (newMaximum < checkOutMaximum) ? newMaximum : checkOutMaximum
      }
    }

    checkOutFilter.push({ before: checkOutMinimum })
    checkOutFilter.push({ after: checkOutMaximum })

    return { checkInFilter, checkOutFilter }
  }

  render() {
    const {
      item,
      selectedAvailabilityDate,
      selectedTimeSlot,
      newReservation,
      availability,
      reservations,
    } = this.props

    const {
      reservationType,
      maxPeoplePerReservation,
      minDuration,
      duration: maxDuration,
      resources: { count: resourceCount, name: resourceName } = {}
    } = item

    const { resident, groupSize, message, includeResource, checkOut, selectedFlexStartTime, selectedFlexEndTime } = newReservation

    let hours = ''
    const isMultiDay = reservationType === 'multiDay'
    const isHourly = reservationType === 'hourly'
    const isFullFacility = reservationType === 'fullFacility'

    let flexibleStartTimeOptions
    let flexibleEndTimeOptions
    let flexStartTimeIndex
    let flexEndTimeIndex
    let flexibleStartTimeProps
    let flexibleEndTimeProps

    const { message: validationMessage } = validateReservation(
      selectedTimeSlot,
      newReservation,
      availability,
      reservations,
      item
    )

    if (isFullFacility) {
      const fullFacilityInterval = 15 // minutes
      flexibleStartTimeOptions = this.getFlexibleStartTimeOptions(availability, minDuration, fullFacilityInterval)
      flexibleEndTimeOptions = this.getFlexibleEndTimeOptions(availability, selectedFlexStartTime, minDuration, maxDuration, fullFacilityInterval)
      flexStartTimeIndex = flexibleStartTimeOptions.findIndex(o => o.value === selectedFlexStartTime)
      flexEndTimeIndex = flexibleEndTimeOptions.findIndex(o => o.value === selectedFlexEndTime)

      flexibleStartTimeProps = {
        inputId: 'select-flex-start-time',
        components: { IndicatorSeparator: () => null },
        classNamePrefix: 'time',
        onChange: ({ value }) => this.handleFlexStartTime(value),
        options: flexibleStartTimeOptions,
        value: flexibleStartTimeOptions[flexStartTimeIndex] || null,
        class: 'require-error'
      }

      flexibleEndTimeProps = {
        inputId: 'select-flex-start-time',
        components: { IndicatorSeparator: () => null },
        classNamePrefix: 'time',
        onChange: ({ value }) => this.handleFlexEndTime(value),
        options: flexibleEndTimeOptions,
        value: flexibleEndTimeOptions[flexEndTimeIndex] || null,
        class: 'require-error'
      }
    }

    const remainingLength =
      message && message.length < 40 ? 40 - message.length : 0
    const residentMsgLabel = message ?
      `Additional Message to Resident (${remainingLength} characters remaining)` :
      'Additional Message to Resident'

    let { checkIn } = newReservation

    if (!checkIn) {
      checkIn = selectedAvailabilityDate
    }

    const { checkInFilter, checkOutFilter } = isMultiDay ?
      this.getMultiDayBlocks(checkIn, item, availability) : {}

    if (isHourly && selectedTimeSlot) {
      hours = `${selectedTimeSlot.startTimeShortString} - ${selectedTimeSlot.endTimeShortString}`
    } else if (isFullFacility) {
      hours = `Reservations must be between ${minDuration} and ${maxDuration} minutes long.`
    }


    const guestsOptions = []
    for (let i = 1; i <= maxPeoplePerReservation; i += 1) {
      const name = (i === 1) ? '1 (Resident Only)' : i.toString()
      guestsOptions.push({ id: i, name })
    }

    const getSelectedDateFormatted = () => {
      const d = new Date(selectedAvailabilityDate)
      return d.toLocaleString('en-us', {
        timeZone: 'UTC',
        dateStyle: 'full',
      }).slice(0, -6)
    }

    return (
      <AmenityReservationsDiv>
        <Grid container>{isHourly ?
          <Grid container>
            <Grid item xs={12}>
              <AvailabilityDayHeader id="availability-day">
                {getSelectedDateFormatted()}
              </AvailabilityDayHeader>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '12px', paddingBottom: '15px' }}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <AvailabilityHoursHeader id="availability-hours">{hours}</AvailabilityHoursHeader>
            </Grid>
          </Grid>
          : isMultiDay ? <Grid container>
            <Grid item xs={12}>
              <Flex>
                <FlexItem paddingRight="40px" flex={1}>
                  <CheckTime>Check-in date:</CheckTime>
                  <DateInput handleDayPick={this.handleCheckInTime} date={checkIn} dateFilter={checkInFilter} />
                </FlexItem>
                <FlexItem flex={1}>
                  <CheckTime>Check-out date:</CheckTime>
                  <DateInput handleDayPick={this.handleCheckOutTime} date={checkOut} dateFilter={checkOutFilter} />
                </FlexItem>
              </Flex >
            </Grid >
            <Grid item xs={12} style={{ paddingTop: '12px', paddingBottom: '15px' }}>
              <Divider />
            </Grid>
          </Grid >
            :
            <Grid container>
              <Grid item xs={12}>
                <Flex>
                  <FlexItem paddingRight="40px" flex={1}>
                    <CheckTime>Start Time:</CheckTime>
                    <ReactSelect {...flexibleStartTimeProps} />
                  </FlexItem>
                  <FlexItem flex={1}>
                    <CheckTime>End Time:</CheckTime>
                    <ReactSelect {...flexibleEndTimeProps} />
                  </FlexItem>
                </Flex>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '12px', paddingBottom: '15px' }}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <AvailabilityHoursHeader id="availability-hours">{hours}</AvailabilityHoursHeader>
              </Grid>
            </Grid>
        }
          <Grid item xs={8}>
            <NewReservationDiv>New Reservation:</NewReservationDiv>
            <ResidentDiv>Resident:</ResidentDiv>
            <ResidentSearch
              elementId="amenity-resident-search"
              handleResultClick={this.handleResultClick}
            />
          </Grid>
          {
            resident && (
              <Grid item xs={8}>
                <NewReservationForm>
                  <Grid item xs={12}>
                    <Grid container wrap="nowrap" alignItems="center">
                      <Grid item>
                        <NewReservationResidentAvatar src={resident.picture} alt="Resident Avatar" />
                      </Grid>
                      <Grid item>
                        <NewReservationResidentDetailsDiv>
                          <span id="amenity-new-reservation-resident-name">{resident.name}</span>
                          {
                            resident.unitName && (
                              <React.Fragment>
                                <span>&nbsp;/&nbsp;</span>
                                <span id="amenity-new-reservation-unit-name">
                                  {resident.unitName}
                                </span>
                              </React.Fragment>
                            )
                          }
                        </NewReservationResidentDetailsDiv>
                      </Grid>
                      <Grid item>
                        <NewReservationDeleteButton
                          id="amenity-new-reservation-delete-button"
                          onClick={this.handleDeleteButtonClick}
                          title="Remove resident from this reservation"
                        >
                          <HighlightOffIcon color="error" />
                        </NewReservationDeleteButton>
                      </Grid>
                    </Grid>
                    {
                      validationMessage &&
                      <Grid item xs={12}>
                        <ReservationInvalid>{validationMessage}</ReservationInvalid>
                      </Grid>
                    }
                    {
                      !isMultiDay && (
                        <Grid item xs={12}>
                          <NewReservationGuestDiv>
                            <DropDown
                              elementId="amenity-new-reservation-guests-dropdown"
                              label="Guests"
                              data={guestsOptions}
                              selectedItem={
                                guestsOptions.find(({ id }) => id === groupSize) || guestsOptions[0]
                              }
                              handleChange={this.handleGuestsDropdown}
                              weight="normal"
                              width="97%"
                              style={{
                                borderBottom: `1px solid ${theme.palette.neutrals.coolGray}`,
                                fontSize: `${theme.custom.font.size.md}`
                              }}
                            />
                          </NewReservationGuestDiv>
                        </Grid>
                      )
                    }
                    {
                      <Grid item xs={12}>
                        <ResidentMessageContainer>
                          <ResidentMessage
                            id="amenity-new-reservation-message"
                            label={residentMsgLabel}
                            multiline
                            rows={4}
                            variant="outlined"
                            value={message ?? ''}
                            onChange={this.handleMessageTextfield}
                            InputLabelProps={{ shrink: true }}
                          />
                        </ResidentMessageContainer>
                      </Grid>
                    }
                    {
                      resourceName && resourceCount && resourceCount > 0 && (
                        <Grid item xs={12}>
                          <NewReservationCheckBoxDiv>
                            <CheckBox
                              id="amenity-new-reservation-resource-checkbox"
                              iconName="square"
                              checkedIconName="square-checkmark"
                              checked={includeResource}
                              onChange={this.handleResourceCheckbox}
                              text={`Reserve ${resourceName}`}
                            />
                          </NewReservationCheckBoxDiv>
                        </Grid>
                      )
                    }
                  </Grid>
                </NewReservationForm>
              </Grid>
            )
          }
        </Grid >
      </AmenityReservationsDiv >
    )
  }
}
