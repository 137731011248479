import {
  createFetchActions,
  createUpdateActions
} from '../../helpers/actions/creators'

export const VISITOR = 'VISITOR'
export const VISITORS = 'VISITORS'

export const UPLOAD_VISITOR_IMAGE = 'UPLOAD_VISITOR_IMAGE'
export const UPLOAD_VISITOR_IMAGE_SUCCESS = 'UPLOAD_VISITOR_IMAGE_SUCCESS'
export const UPLOAD_VISITOR_IMAGE_ERROR = 'UPLOAD_VISITOR_IMAGE_ERROR'

export const {
  fetchAction: fetchVisitorAction,
  fetchStartAction: fetchVisitorStart,
  fetchSuccessAction: fetchVisitorSuccess,
  fetchErrorAction: fetchVisitorError
} = createFetchActions(VISITOR)

export const {
  updateAction: updateVisitorAction,
  updateStartAction: updateVisitorStart,
  updateSuccessAction: updateVisitorSuccess,
  updateErrorAction: updateVisitorError
} = createUpdateActions(VISITOR)

export const {
  fetchAction: fetchVisitorsAction,
  fetchStartAction: fetchVisitorsStart,
  fetchSuccessAction: fetchVisitorsSuccess,
  fetchErrorAction: fetchVisitorsError
} = createFetchActions(VISITORS)

export const fetchVisitors = (propertyId, page, queryParams) =>
  fetchVisitorsAction({ propertyId, page, queryParams })

export const searchVisitors = (propertyId, page, searchTerm) =>
  fetchVisitorsAction({propertyId, page, searchTerm})

export const updateVisitor = (data, id) => updateVisitorAction({ id, data })

export const uploadImage = (id, data) => ({
  type: UPLOAD_VISITOR_IMAGE,
  data,
  id
})

export const uploadImageSuccess = () => ({
  type: UPLOAD_VISITOR_IMAGE_SUCCESS
})

export const uploadImageError = () => ({
  type: UPLOAD_VISITOR_IMAGE_ERROR
})
