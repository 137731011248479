import styled from '@emotion/styled'

export const NoProperty = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  text-align: center;
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  font-size: ${({ theme }) => theme.custom.font.size.lg};
`
