import styled from '@emotion/styled'

export const ThumbTurn = styled('div')`
  height: 7.5em;
  width: 2.5em;
  transform: none;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
  cursor: pointer;
`

export const LockBase = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 8.5em;
  width: 8.5em;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.neutrals.coolGray};

  &.locked {
    background: ${({ theme }) => theme.palette.primary.green.hintMint};

    ${ThumbTurn} {
      transform: rotate(90deg);
    }
  }
`

export const LockWrap = styled('div')`
  width: 100%;

  .last-activity-time {
    padding-top: 1.5em;
    font-size: ${({ theme }) => theme.custom.font.size.mdlg};
    font-weight: ${({ theme }) => theme.custom.font.weight.light};
    span {
      margin: 0em 0.5em;
    }
  }
`

export const SwitchStatus = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  color: ${({ theme }) => theme.palette.primary.green.mint};
`
