import { call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken, getUserRole } from '../../authorization/selectors'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  fetchTemplatesAction,
  fetchTemplatesStart,
  fetchTemplatesSuccess,
  fetchTemplatesError
} from './actions'

export function* fetchTemplates({ propertyId, searchTerm }) {
  try {
    yield put(fetchTemplatesStart())
    const authToken = yield select(getToken)
    let response
    if (searchTerm == null) {
      const userRole = yield select(getUserRole)

      //  Do property_staff check due to core api role check limitations, double refresh otherwise
      response = (userRole === "property_staff")
        ? []
        : yield call(
          api.getGroupMessagingTemplates,
          authToken,
          propertyId
        )
    } else {
      response = yield call(
        api.searchGroupMessagingTemplates,
        authToken,
        propertyId,
        searchTerm
      )
    }

    yield put(fetchTemplatesSuccess(response))
  } catch (error) {
    yield put(fetchTemplatesError(error))
    log(`Failed to fetch templates`)
  }
}

function* watchFetchTemplates() {
  yield takeLatest(fetchTemplatesAction().type, fetchTemplates)
}

export default [watchFetchTemplates()]
