import React, { useRef, useState } from "react";
import { 
  ScrollButtonContainer,
  Container,
  Content,
} from './styles'
import { CommonIcon } from "../../icons";

const horizontalScroll = {
  behavior: 'smooth',
  block: "start",
  inline: 'nearest',
}

const verticalScrollProps = {
  behavior: 'smooth',
  inline: "start",
  block: 'nearest',
}

type Props = {
  children: Node[],
  numberOfItemsPerSlide: number,
  buttonSize?: number,
  orientation?: 'row' | 'column',
  buttonPlacement?: 'start' | 'end' | 'center',
  onScroll?: Function,
}

export default function ScrollingGrid ({
  children,
  numberOfItemsPerSlide,
  buttonSize = 75,
  onScroll = () => {},
  orientation = 'row',
  buttonPlacement = 'start'
} : Props) {
  const [prevScrollIndex, setPrevScrollIndex] = useState(-1);
  const [nextScrollIndex, setNextScrollIndex] = useState(
    Math.floor(numberOfItemsPerSlide)
  );
  const nextScrollTarget = useRef();
  const prevScrollTarget = useRef();

  const scrollProps =
    orientation === 'row' ? horizontalScroll : verticalScrollProps;

  const scrollToPrev = () => {
    if (prevScrollTarget?.current) {
      prevScrollTarget.current.scrollIntoView(scrollProps);
      setNextScrollIndex(nextScrollIndex - 1);
      setPrevScrollIndex(prevScrollIndex - 1);
    }
  };

  const scrollToNext = () => {
    if (nextScrollTarget?.current) {
      nextScrollTarget.current.scrollIntoView(scrollProps);
      setNextScrollIndex(nextScrollIndex + 1);
      setPrevScrollIndex(prevScrollIndex + 1);
    }
  };

  const getRef = (index) => {
    switch (index) {
      case nextScrollIndex:
        return nextScrollTarget;
      case prevScrollIndex:
        return prevScrollTarget;
      default:
        return null;
    }
  };

  const childArray = React.Children.toArray(children);
  const prevButton = React.createElement(CommonIcon, {
    style: { cursor: 'pointer' },
    name: orientation === 'row' ? 'carousel_left' : 'carousel_up',
    height: `${buttonSize}px`,
    width: `${buttonSize}px`,
  })

  const nextButton = React.createElement(CommonIcon, {
    style: { cursor: 'pointer' },
    name: orientation === 'row' ? 'carousel_right' : 'carousel_down',
    height: `${buttonSize}px`,
    width: `${buttonSize}px`,
  })
  const spacer = <div style={{ minWidth: `${buttonSize}px` }} />;
  return (
    <Container orientation={orientation}>
      <ScrollButtonContainer onClick={scrollToPrev} prev placement={buttonPlacement}>
        {prevScrollIndex >= 0 ? prevButton : spacer}
      </ScrollButtonContainer>
      <Content
        numItemsPerSlide={numberOfItemsPerSlide}
        orientation={orientation}
      >
        {childArray.map((child, index) => (
          <div ref={getRef(index)} key={index} onScroll={onScroll}>
            {child}
          </div>
        ))}
      </Content>
      <ScrollButtonContainer onClick={scrollToNext} placement={buttonPlacement}>
        {nextScrollIndex < childArray.length ? nextButton : spacer}
      </ScrollButtonContainer>
    </Container>
  );
};
