//@flow
import React from 'react'
import Flex from '../../Flex'
import CarouselList from '../../CarouselList'
import type { CarouselProps } from './types'
import withScrollBehaviour from '../../CarouselList/withScrollBehaviour'

const SummaryCarousel = ({
  currentSlideNumber,
  lastSlideNumber,
  numberOfItemsPerSlide,
  items,
  autoHeight
}: CarouselProps) => (
  <Flex flex={2} justifySpaceBetween direction="column">
    <CarouselList
      numberOfItemsPerSlide={numberOfItemsPerSlide}
      itemHeight={autoHeight ? null : 56}
      currentSlide={currentSlideNumber}
      lastSlide={lastSlideNumber}
      items={items}
      arrowsRight
    />
  </Flex>
)

export default withScrollBehaviour(SummaryCarousel)
