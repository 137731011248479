// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import { CommonIcon } from '@icons'
import { SwitchSpecs } from './SwitchSpecs'
import { SpecsType } from './types'
import { customCss } from './styles'
import { capitalizeFirstLetter } from 'zego-shared/utils/string'

const getDeviceState = (state, status, date, time) => {
  if (state === null || state === undefined) return 'Unknown Current Status'
  return `Turned ${state === 'on' ? 'On' : 'Off'} ${date} at ${time}`
}

const SwitchContent = (props: SpecsType) => {
  const {
    deviceModel,
    deviceStatus,
    deviceState,
    installationDate,
    time,
    abnormal,
    date,
    theme
  } = props
  const state = getDeviceState(deviceState, deviceStatus, date, time)
  return (
    <FlexDirection fullHeight direction="column">
      <FlexItem flex={6} className={customCss(theme)} displayFlex>
        <span>{state}</span>
        {state !== 'Unknown Current Status' && (
          <React.Fragment>
            <span>
              <CommonIcon name="bullet" width="6px" height="6px" />
            </span>
            <span>manually</span>
          </React.Fragment>
        )}
      </FlexItem>
      <FlexItem flex={1}>
        <SwitchSpecs
          deviceModel={deviceModel}
          deviceStatus={
            abnormal ? 'Abnormal' : capitalizeFirstLetter(deviceStatus)
          }
          InstallationDate={installationDate}
        />
      </FlexItem>
    </FlexDirection>
  )
}

export default withTheme(SwitchContent)
