// @flow

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TextField from '../../Common/TextField/'
import Downshift from 'downshift'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { styles } from './styles'

const renderInput = inputProps => {
  const { InputProps, classes, ...other } = inputProps

  return <TextField {...InputProps} {...other} />
}

const renderSuggestion = ({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem
}) => {
  const isHighlighted = highlightedIndex === index
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}>
      {suggestion.label}
    </MenuItem>
  )
}

const DownshiftWrapper = props => {
  let {
    id = '',
    label,
    placeholder,
    classes,
    prefix,
    readOnly,
    textAlign,
    suggestions,
    handleInputChange,
    selectedItem,
    showPencil,
    alwaysShowLabel,
    InputLabelProps,
    onBlur, 
    error,
    inputId
  } = props

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    let count = 0

    return inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
          const keep =
            count < 5 &&
            suggestion.label.slice(0, inputLength).toLowerCase() === inputValue

          if (keep) {
            count += 1
          }

          return keep
        })
  }

  return (
    <div className={classes.root}>
      <Downshift
        id={id}
        selectedItem={selectedItem}
        onStateChange={handleInputChange}>
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem
        }) => (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({ readOnly, onBlur, error, id: inputId }),
              placeholder,
              prefix,
              textAlign,
              label,
              showPencil,
              alwaysShowLabel,
              InputLabelProps
            })}
            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {getSuggestions(inputValue).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion.label }),
                      highlightedIndex,
                      selectedItem
                    })
                  )}
                </Paper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    </div>
  )
}

export default withStyles(styles)(DownshiftWrapper)
