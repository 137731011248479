import styled from '@emotion/styled'
import { convertHexToRGB } from '../../../../utils'

export const ImageBlock = styled('div')`
  grid-column: ${({ columnStart, columnSpan }) => `${columnStart} / span
  ${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span
  ${rowSpan}`};
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.neutrals.lightGray};
  ${({ image, theme }) =>
    image
      ? `
      img {
       width: 100%;
       height: 100%;
       object-fit: cover;
      }
`
      : `
border: 1px dashed ${theme.palette.neutrals.deepGray}}
`};
`

export const ImageWrapper = styled('div')`
  overflow: hidden;
  height: 100%;
  width: 100%;
`

export const TitleBlock = styled('div')`
  grid-column: ${({ columnStart, columnSpan }) => `${columnStart} / span
  ${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span
  ${rowSpan}`};
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
  padding: 2.5em;
`

export const ButtonSection = styled('div')`
  grid-column: ${({ columnStart, columnSpan }) => `${columnStart} / span
  ${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span
  ${rowSpan}`};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const MessageSection = styled('div')`
  grid-column: ${({ columnStart, columnSpan }) => `${columnStart} / span
  ${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span
  ${rowSpan}`};
  display: flex;
  justify-content: center;
`

export const Divider = styled('div')`
  grid-column: ${({ columnStart, columnSpan }) => `${columnStart} / span
  ${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span
  ${rowSpan}`};
  box-shadow: ${({ theme }) =>
    `0px 4px 5px 0px ${convertHexToRGB(theme.palette.neutrals.coolGray)}`};
`

export const Button = styled('label')`
  width: 35%;
  padding: 0px;
  height: 2em;
  line-height: 2em;
  border-radius: 0;
  background-color: ${({ theme }) => theme.palette.primary.navy.spaceBlue};
  color: ${({ theme }) => theme.palette.common.white};
  text-align: center;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.green.mint};
  }
`

export const DateBlock = styled('div')`
  grid-column: ${({ columnStart, columnSpan }) => `${columnStart} / span
${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span
${rowSpan}`};
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
  padding: 4em 2em;
`

export const DatePickerWrapper = styled('div')`
  position: absolute;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  z-index: 150;
`

export const DateWrapper = styled('div')`
  grid-column: 1 / span 5;
  grid-row: 1 / span 1;
  display: relative;
`
