// @flow

import React from 'react'
import ReactScrollToTop from 'react-scroll-up'
import { CommonIcon } from '@icons'
import { ScrollUpContainer } from './styles'

const ScrollToTop = () => (
  <ReactScrollToTop
    showUnder={50}
    style={{
      bottom: '-20px',
      right: '50%',
      left: '50%'
    }}>
    <ScrollUpContainer disableRipple disableFocusRipple>
      <CommonIcon name="arrow_up" height="12px" width="24px" fill="#fff" />
    </ScrollUpContainer>
  </ReactScrollToTop>
)

export default ScrollToTop
