import { select, takeLatest, call, put } from 'redux-saga/effects'
import { REHYDRATE } from 'redux-persist'
import SendBird from 'zego-shared/utils/sendbird'
import Socket from 'zego-shared/utils/socket'
import {
  deauth,
  updateSendBirdInfo
} from 'zego-shared/store/authorization/actions'
import {
  SELECT_PROPERTY,
  updatePropertyRenderReady
} from 'zego-shared/store/select/properties/actions'
import {
  getSendBirdInfo,
  getToken,
  getUserRole
} from 'zego-shared/store/authorization/selectors'
import api from 'zego-shared/utils/api'

const initializeExternalServices = (
  sendbirdAppID,
  sendbirdToken,
  sendbirdUserID,
  token
) => {
  // company admin and super admin don't have sendbird accounts
  if (sendbirdAppID && sendbirdUserID && sendbirdToken) {
    SendBird.init(sendbirdAppID)

    SendBird.connect(
      sendbirdUserID,
      sendbirdToken
    )
  }

  Socket.init(token)
}

export function setupServicesRehydrated(persistedState) {
  try {
    // we are logged in and have a persisted state
    if (persistedState.payload) {
      const { authorization } = persistedState.payload
      if (authorization.isAuth) {
        const {
          sendbirdAppID,
          sendbirdToken,
          sendbirdUserID,
          token
        } = authorization

        initializeExternalServices(
          sendbirdAppID,
          sendbirdToken,
          sendbirdUserID,
          token
        )
      }
    }
  } catch (error) {
    console.log(`Services rehydrated error: ${error}`)
  }
}

export function* setupPropertyServices({ id: propertyId }) {
  try {
    let sendBirdInfo = yield select(getSendBirdInfo)
    const userRole = yield select(getUserRole)
    const authToken = yield select(getToken)

    if (userRole === 'super_admin' || userRole === 'company_admin') {
      const {
        sendbird_app_id: sendbirdAppID,
        sendbird_token: sendbirdToken,
        sendbird_user_id: sendbirdUserID
      } = yield call(api.getChatTokens, authToken, propertyId)

      sendBirdInfo = {
        sendbirdAppID,
        sendbirdToken,
        sendbirdUserID
      }
    }

    yield call(
      initializeExternalServices,
      sendBirdInfo.sendbirdAppID,
      sendBirdInfo.sendbirdToken,
      sendBirdInfo.sendbirdUserID,
      authToken
    )

    yield put(updateSendBirdInfo(sendBirdInfo))
    yield put(updatePropertyRenderReady(true))
  } catch (error) {
    // an error happened when getting the sendbird info
    // so just set this to null and assume the property has chat disabled
    yield put(
      updateSendBirdInfo({
        sendbirdAppID: null,
        sendbirdToken: null,
        sendbirdUserID: null
      })
    )
    yield put(updatePropertyRenderReady(true))
  }
}

export function terminateExternalServices() {
  try {
    Socket.disconnect()
    SendBird.disconnect()
  } catch (error) {
    console.log(`Services login error: ${error}`)
  }
}

function* watchRehydrate() {
  yield takeLatest(REHYDRATE, setupServicesRehydrated)
}

function* watchSelectProperty() {
  yield takeLatest(SELECT_PROPERTY, setupPropertyServices)
}

function* watchDeauth() {
  yield takeLatest(deauth().type, terminateExternalServices)
}

export default [watchRehydrate(), watchSelectProperty(), watchDeauth()]
