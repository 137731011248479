import { call, select, put, takeLatest } from 'redux-saga/effects'
import {
  fetchPropertyHubsAction,
  fetchPropertyHubsStart,
  fetchPropertyHubsSuccess,
  fetchPropertyHubsError
} from './actions'
import { getToken } from '../authorization/selectors'
import { showSnackbar } from '../snackbar/actions'
import { fetchPropertyHubsErrorMessage } from '../../utils/messages'
import api from '../../utils/api'
import log from '../../utils/logger'

export function* fetchPropertyHubsSaga({ propertyId }) {
  try {
    yield put(fetchPropertyHubsStart())
    const authToken = yield select(getToken)
    const response = yield call(api.getPropertyHubs, authToken, propertyId)
    yield put(fetchPropertyHubsSuccess(response))
  } catch (error) {
    log(`Failed to fetch property hubs. Error: ${error}`)
    yield put(fetchPropertyHubsError(error))
    yield put(showSnackbar(fetchPropertyHubsErrorMessage, 'error'))
  }
}

function* watchFetchPropertyHubs() {
  yield takeLatest(fetchPropertyHubsAction().type, fetchPropertyHubsSaga)
}

export default [
  watchFetchPropertyHubs()
]
