// @flow

import React from 'react'
import { connect } from 'react-redux'

import { ldInitRequest } from '../../integrations/LaunchDarkly/actions'
import { getFeatureFlag } from '../../integrations/LaunchDarkly/selectors'
import { createAmenity, fetchAmenities, pinAmenity, unPinAmenity } from 'zego-shared/store/amenitiesPro'
import { getAmenities, hasAmenities } from 'zego-shared/store/amenitiesPro/selectors'
import { isProAmenity } from 'zego-shared/store/amenitiesShared/selectors'
import { createIsFetchingSelector,  } from 'zego-shared/store/isFetching/selectors'
import { createIsUpdatingSelector } from 'zego-shared/store/isUpdating/selectors'
import { getPropertyAccessSettings } from 'zego-shared/store/settings/selectors'
import withFetchData from 'zego-shared/views/withFetchData'

import AmenityDashboardPro from './AmenityDashboardPro'

const fetchingSelector = createIsFetchingSelector(['amenities'])
const updatingSelector = createIsUpdatingSelector(['amenity'])

const mapStateToProps = state => ({
  items: getAmenities(state),
  hasData: hasAmenities(state),
  isFetching: fetchingSelector(state),
  isUpdating: updatingSelector(state),
  propertySettings: getPropertyAccessSettings(state),
  featureFlag: getFeatureFlag(state),
  isProAmenity: isProAmenity(state),
  checkProAmenity: true
})

const mapDispatchToProps = (
  dispatch,
  {
    history,
    match: {
      url,
      params: { propertyId }
    }
  }
) => {
  return {
    fetchData: () => {
      dispatch(fetchAmenities(propertyId))
    },
    createAmenity: (amenityParams) => {
      dispatch(createAmenity(history, url, amenityParams))
    },
    pinAmenity: (facilityUuid) => {
      dispatch(pinAmenity(facilityUuid))
    },
    unPinAmenity: (facilityUuid) => {
      dispatch(unPinAmenity(facilityUuid))
    },
    ldInitRequest,
  }
}

class AmenityDashboardWrapper extends React.Component<any, any> {
  componentDidMount = () => {
    const { ldInitRequest } = this.props
    ldInitRequest()
  }

  render() {
    return <AmenityDashboardPro {...this.props}/>
  }
}

const AmenityDashboardWithFetchData = withFetchData(AmenityDashboardWrapper)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AmenityDashboardWithFetchData)
