import { REPLACEMENT_OPTIONS } from './actions'
import withFetch from '../../helpers/reducers/withFetch'

const replacementOptions = (state = null, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withFetch(REPLACEMENT_OPTIONS)(replacementOptions)
