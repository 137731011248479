import { createFetchActions } from '../../helpers/actions/creators'

export const RESIDENT_ACTIVE = 'RESIDENTACTIVE'

export const {
  fetchAction: fetchResidentActiveAction,
  fetchStartAction: fetchResidentActiveStart,
  fetchSuccessAction: fetchResidentActiveSuccess,
  fetchErrorAction: fetchResidentActiveError
} = createFetchActions(RESIDENT_ACTIVE)

export const fetchResidentActive = unitId =>
  fetchResidentActiveAction({ unitId })
