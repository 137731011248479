import { createFetchActions } from '../../../helpers/actions/creators'

export const SECTION = 'DEVICEPROFILE'

export const SELECT_DEVICEPROFILE = 'SELECT_DEVICEPROFILE'

export const {
  fetchAction: fetchDeviceProfiles,
  fetchStartAction: fetchDeviceProfilesStart,
  fetchSuccessAction: fetchDeviceProfilesSuccess,
  fetchErrorAction: fetchDeviceProfilesError
} = createFetchActions(SECTION)

export const selectDeviceProfile = profileId => ({
  type: SELECT_DEVICEPROFILE,
  profileId
})
