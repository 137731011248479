import { getProfile } from 'zego-shared/store/authorization/selectors'
import { getSelectedCompany, getSelectedProperty } from 'zego-shared/store/select/selectors'

export const getPendoVisitorProfile = state => {
  const {
    sub: userId,
    first_name: firstName,
    last_name: lastName,
    name: fullName,
    email
  } = getProfile(state)

  return {
    id: userId,
    email,
    first_name: firstName,
    last_name: lastName,
    full_name: fullName,
  }
}

export const getPendoAccountProfile = state => {
  const {
    id: companyId,
    name: companyName,
  } = getSelectedCompany(state)

  const {
    id: propertyId,
    name: propertyName,
    integration_info: integrationInfo
  } = getSelectedProperty(state)

  const {
    company_id: companyUuid,
    property_id: propertyUuid,
    company_pms_id: companyPmId
  } = integrationInfo || {}

  return {
    id: companyUuid,
    pm_id: companyPmId,
    company_zego_id: companyId,
    company_name: companyName,
    property_id: propertyUuid,
    property_zego_id: propertyId,
    property_name: propertyName,
    company_pm_id: companyPmId
  }
}
