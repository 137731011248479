import styled from '@emotion/styled'
import { convertHexToRGB } from 'zego-shared/utils/view'

export const OverLay = styled('div')`
  position: absolute;
  opacity: 0;
  ${({ size }) =>
    size
      ? `height: ${size}; width: ${size}; line-height: ${size}`
      : `height: 2.7em; width: 2.7em;`};
  background-color: ${({ theme }) =>
    convertHexToRGB(theme.palette.primary.green.mint, 0.9)};
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  color: white;
  z-index: 150;
`

export const Picture = styled('div')`
  background-image: ${({ src }) => src && `url(${src})`};
  border-radius: 50%;
  background-size: cover;
  box-shadow: ${({ theme, hideShadow }) =>
    !hideShadow &&
    `0 2px 2px 0 ${convertHexToRGB(theme.palette.grey.main, 0.5)}`};
  ${({ size }) =>
    size ? `height: ${size}; width: ${size};` : `height: 2.7em; width: 2.7em;`};
  ${({ showTrail }) => showTrail && `position: absolute`};
`

export const Wrapper = styled('div')`
  position: ${({ position }) => (position ? position : 'relative')};
  ${({ size }) =>
    size ? `height: ${size}; width: ${size};` : `height: 2.7em; width: 2.7em;`};

  ${({ alwaysShowOverlay }) =>
    alwaysShowOverlay && `${OverLay} { opacity: 1 }`};

  &:hover ${OverLay} {
    opacity: 1;
  }
`

export const Circle = styled('div')`
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: ${({ index }) => (index === 1 ? '-0.3em' : '-0.6em')};
  ${({ size }) =>
    size ? `height: ${size}; width: ${size};` : `height: 2.7em; width: 2.7em;`};
  background-color: ${({ index, theme }) =>
    index === 1
      ? `${theme.palette.primary.green.seeFoam}`
      : `${theme.palette.primary.green.mint}`};
`
