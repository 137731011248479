// @flow
import React from 'react'
import FlexDirection from '@Common/Flex/FlexDirection'
import { withTheme } from 'emotion-theming'
import { capitalizeFirstLetter } from 'zego-shared/utils/string'

const CurrentThermostatValue = ({ value, mode, theme }: any) => (
  <FlexDirection direction="column" style={{ textAlign: 'center' }}>
    <div
      style={{
        fontSize: theme.custom.font.size.xxl,
        color: theme.palette.primary.green.mint,
        marginTop: '-0.7em'
      }}>
      {value}°
    </div>
    <div
      style={{
        color: theme.palette.grey.main,
        fontSize: theme.custom.font.size.sm,
        marginLeft: '-3em'
      }}>
      {capitalizeFirstLetter(mode)} Setpoint
    </div>
  </FlexDirection>
)

export default withTheme(CurrentThermostatValue)
