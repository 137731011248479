import { createFetchActions } from '../../../helpers/actions/creators'

export const CHAT_CHANNELS = 'CHATCHANNELS'

const SENDBIRD_CHANNEL_CHANGED = 'SENDBIRD_CHANNEL_CHANGED'

export const CHAT_CHANNELS_BY_TYPE = 'CHATCHANNELSBYTYPE'

export const RESIDENT_CHAT_CHANNELS = 'RESIDENTCHATCHANNELS'

export const {
  fetchAction: fetchChatChannelsByTypeAction,
  fetchStartAction: fetchChatChannelsByTypeStart,
  fetchSuccessAction: fetchChatChannelsByTypeSuccess,
  fetchErrorAction: fetchChatChannelsByTypeError
} = createFetchActions(CHAT_CHANNELS_BY_TYPE)

export const fetchChatChannelsByType = (
  channelType,
  userId,
  shouldSetActiveChannel
) =>
  fetchChatChannelsByTypeAction({ channelType, userId, shouldSetActiveChannel })

export const channelChanged = channel => ({
  type: SENDBIRD_CHANNEL_CHANGED,
  channel
})

export const {
  fetchAction: fetchUnitResidentChatChannelsAction,
  fetchStartAction: fetchUnitResidentChatChannelsStart,
  fetchSuccessAction: fetchUnitResidentChatChannelsSuccess,
  fetchErrorAction: fetchUnitResidentChatChannelsError
} = createFetchActions(RESIDENT_CHAT_CHANNELS)

export const fetchUnitResidentChatChannels = id =>
  fetchUnitResidentChatChannelsAction({ id })
