import {
  normalizeArraySectionData,
  normalizePaginatedResponseWithHeader
} from '../normalizer'
import { FETCH_PREFIX, SUCCESS } from '../actions/constants'

const initialState = {
  byId: {},
  allIds: []
}

export default section => reducer => (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_PREFIX}${section}${SUCCESS}`: {
      const { data, ...paginationData } = normalizePaginatedResponseWithHeader(
        action.payload
      )
      const { byId, allIds } = normalizeArraySectionData(data, section, state)
      const prevById = state.byId || initialState.byId
      const prevAllIds = state.allIds || initialState.allIds

      return {
        byId: { ...prevById, ...byId },
        allIds: [...prevAllIds, ...allIds],
        ...paginationData
      }
    }
    default:
      return reducer(state, action)
  }
}
