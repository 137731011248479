// @flow

import get from 'lodash/get'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import { getPaginationData } from '../helpers/selectors/data'

export const getAmenities = (state: Object, filter: string = '') => {
  const amenities = get(state, ['amenitiesLegacy', 'amenities', 'data']) || []
  return amenities
    ? amenities.map(amenity => ({
      id: amenity.id,
      name: amenity.name || '',
      description: amenity.description || '',
      reservable: amenity.type === 'reservable' ? 'Yes' : 'No',
      picture: get(amenity, ['photos', 'main'])
    }))
    : []
}

export const getAmenity = (state: Object) => {
  const amenity = get(state, ['amenitiesLegacy', 'amenity']) || {}
  return {
    id: amenity.id,
    name: amenity.name || '',
    description: amenity.description || '',
    reservable: amenity.type === 'reservable',
    picture: get(amenity, ['photos', 'main'])
  }
}

export const amenityForApi = (amenity: Object) => {
  amenity = {
    id: amenity.id,
    name: amenity.name,
    description: amenity.description,
    ...(
      // $FlowDisableNextLine
      amenity.reservable == null
        ? {} : { type: amenity.reservable ? 'reservable' : 'not_reservable' }
    ),
    ...(
      amenity.files == null ? {} : { files: amenity.files }
    ),
  }
  return omitBy(amenity, isNil)
}

export const hasAmenities = (state: Object) => {
  return get(state, ['amenitiesLegacy', 'amenities', 'data']) != null
}

export const hasAmenity = (state: Object) => {
  return get(state, ['amenitiesLegacy', 'amenity']) != null
}

export const getAmenitiesPaginationInfo = (state: Object) => {
  const data = getPaginationData(state, ['amenitiesLegacy', 'amenities'])

  return {
    currentPage: data.currentPage,
    perPage: data.perPage,
    total: data.total,
    totalPages: data.totalPages
  }
}
