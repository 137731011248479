import React from 'react'
import PrivateRoute from '../PrivateRoute'
import ResidentsContainer from '../../views/Residents/ResidentsContainer'
import ResidentProfileContainer from '../../views/Residents/ResidentProfile/ResidentProfileContainer'

const getResidentRoutes = () => [
  <PrivateRoute
    exact
    path="/properties/:propertyId/residents"
    key="residents"
    component={ResidentsContainer}
  />,
  <PrivateRoute
    key="residentsManagement"
    exact
    path="/properties/:propertyId/residents/profile/:userId"
    component={ResidentProfileContainer}
  />,
  <PrivateRoute
    key="residentsManagement"
    exact
    path="/properties/:propertyId/residents/profile/:userId/:profileInfo"
    component={ResidentProfileContainer}
  />
]

export { getResidentRoutes }
