import React from 'react'
import ReactDOM from 'react-dom'

const withClickOutside = Component =>
  class withClickOutside extends React.Component {
    wrappedInstance
    domNode

    componentDidMount = () => {
      document.addEventListener('click', this.handleClickOutside, true)
    }

    componentWillUnmount = () => {
      document.removeEventListener('click', this.handleClickOutside, true)
    }

    setClickOutsideDomNode = ref => {
      this.domNode = ReactDOM.findDOMNode(ref)
    }

    handleClickOutside = e => {
      if (
        (!this.domNode || !this.domNode.contains(e.target)) &&
        this.wrappedInstance &&
        typeof this.wrappedInstance.handleClickOutside === 'function'
      ) {
        this.wrappedInstance.handleClickOutside(e)
      }
    }

    render() {
      const { wrappedRef, ...rest } = this.props

      return (
        <Component
          setClickOutsideDomNode={this.setClickOutsideDomNode}
          {...rest}
          ref={c => {
            this.wrappedInstance = c
          }}
        />
      )
    }
  }

export default withClickOutside
