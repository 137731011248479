import { connect } from 'react-redux'
import { getPropertyId } from 'zego-shared/store/select/selectors'
import {
  getCommunityThreadData,
  hasData
} from 'zego-shared/store/communityThread/selectors'
import { getToken } from 'zego-shared/store/authorization/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import CreateEditPost from './CreateEditPostEvent'
import api from 'zego-shared/utils/api'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import {
  deleteCommunityThread,
  fetchCommunityThread
} from 'zego-shared/store/communityThread/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'

const fetchingSelector = createIsFetchingSelector(['communitythread'])

const saveChanges = async (
  userToken,
  propertyId,
  image,
  title,
  message,
  type,
  startTime,
  endTime,
  action,
  threadId
) => {
  const apiFunction =
    action === 'edit' ? 'updateCommunityThread' : 'createCommunityThread'

  const { thread_id } = await api[apiFunction](
    userToken,
    propertyId,
    {
      file: image,
      title,
      message,
      type,
      event_datetime: startTime,
      event_end_datetime: endTime
    },
    threadId
  )

  return window.btoa(thread_id)
}

const mapStateToProps = (
  state,
  { title, image, message, threadId, startTime, endTime, match: { params } }
) => ({
  propertyId: getPropertyId(state),
  userToken: getToken(state),
  action: params.action,
  type: params.type,
  data: getCommunityThreadData(state, params.action, params.threadId),
  showDialog: false,
  saveChanges,
  isFetching: fetchingSelector(state),
  hasData: hasData(state),
  threadId: params.threadId
})

const mapDispatchToProps = (dispatch, { match: { params }, history }) => {
  return {
    fetchData: () => {
      params.action === 'edit' &&
        dispatch(fetchCommunityThread(params.threadId))
    },
    showError: errorMsg => dispatch(showSnackbar(errorMsg, 'error')),
    onAlertClickYes: () => {
      dispatch(deleteCommunityThread(params.threadId, params.type, history))
    }
  }
}

const CreateEditPostWithFetchData = withFetchData(CreateEditPost)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditPostWithFetchData)
