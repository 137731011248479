import styled from '@emotion/styled'
import CommonTextField from '@Common/TextField'
import {
  getLengthInMinutes,
  scheduleIncrement,
  getScheduleRowIndex,
  getScheduleStartAndEndTimes, addMinutesToMilitaryTime
} from "../../../utils/scheduleUtils";

// constants and helper functions to make quick adjustments to the entire
// set of styled components
const increment = scheduleIncrement;
const incrementHeight = `${0.15 * increment}vh`
const bigIncrementHeight = `${0.3 * increment}vh`
const linearGradient = (c1, c2) => `repeating-linear-gradient(135deg, ${c1} 0 1.2vh, ${c2} 1vh 2vh)`
const bufferBackground = ({ theme }) => linearGradient(theme.palette.neutrals.lightGray, 'transparent')
const selectedBackground = ({ theme }) => theme.palette.neutrals.mediumGray
const pastBackground = ({ theme }) => theme.palette.neutrals.lightGray
const timeLineBorder = ({ theme }) => `solid 0.5px ${theme.palette.primary.navy.midnight};`
const getScheduleRows = (startTime, endTime, offset) => `${getScheduleRowIndex(startTime, offset)} / ${getScheduleRowIndex(endTime, offset)}`

export const FadedTimeLine = styled('div')`
  grid-column: 2 / -1;
  grid-row: ${({ time, offset }) => getScheduleRows(time, time, offset)};
  border-top: ${({ theme }) => `solid 0.75px ${theme.palette.neutrals.mediumGray}`};
`

export const NowBlockDashboard = styled('div')`
  box-sizing: border-box;
  position: absolute;
  width: 80%;
  z-index: 4;
  opacity: .3;
  height: calc(${incrementHeight} * 2);
  background: ${({ theme }) => {
    const color = theme.palette.primary.green.hintMint
    return `linear-gradient(
      to right, transparent 0%, ${color} 5%, ${color} 50%, ${color} 95%, transparent 100%
    )`
  }};
  grid-row: ${({ time, offset }) => getScheduleRows(
    time, addMinutesToMilitaryTime(time, 30), offset
  )};
  ${({ dims }) => {
    if (!dims) { return '' }
    const { left } = dims
    return `
      left: calc(${left - 16}px);
      width: calc(100% - ${left}px)
    `
  }};
`

export const NowBlock = styled('div')`
  grid-column: 1 / -1;
  width: 100%
  z-index: 10;
  opacity: .4;
  height: calc(${incrementHeight} * 2);
  background: ${({ theme }) => theme.palette.primary.green.hintMint};
  grid-row: ${({ time, offset }) => getScheduleRows(
  time, addMinutesToMilitaryTime(time, 30), offset
)};
`
/*

      case 'closed':
        return `
            color: ${theme.palette.neutrals.mediumGray};
            font-style: italic;
            font-weight: 350;
          `
*/
export const TimeSlot = styled('div')`
  height: 100%;
  grid-column: 2 / -1;
  grid-row: ${({ startTime, endTime, offset }) => getScheduleRows(startTime, endTime, offset)};
  display: grid;
  grid-template-columns: auto auto; 
  border-top: ${timeLineBorder};
  justify-content: start;
  align-content: center;
  user-select: none;
  background: ${({ isSelected, buffer, isPast, status }) => {
    if (buffer && status === 'buffer') { return bufferBackground }
    if (isSelected) { return selectedBackground }
    if (isPast) { return pastBackground }
    return 'transparent'
  }};
  ${({ status }) => {
    if (status !== 'closed') { return '' }
    return `font-style: italic;
      text-transform: uppercase;
      font-weight: 350;`
  }}
  color: ${({ buffer, isPast, theme, status }) => {
    const {
      neutrals: { mediumGray },
      primary: { navy: { midnight } }
    } = theme.palette
    if (buffer && status === 'buffer') { return 'transparent' }
    if (isPast || status === 'closed') { return mediumGray }
    return midnight
  }};

  & > *:nth-child(2) {
    z-index: 8;
    font-weight: 350;
    font-style: italic;
    text-transform: uppercase;
    grid-column: 2 / 2;
    :before { 
      font-size: 12px; 
      font-style: normal; 
      content: "\\00a0|\\00a0";
    }
  }

  & > *:first-child {
    grid-column: 1 / 1;
    z-index: 8;
    ${({ buffer, isPast, status, reservationType, theme }) => {
      const {
        neutrals: { mediumGray },
        primary: { navy: { midnight } }
      } = theme.palette
      if (buffer) { return '' }
      if (isPast) { return '' }
      switch (status) {
        case 'full':
          if (reservationType !== 'fullFacility') {
            return `
                color: ${theme.palette.secondary.pink.deepRed};
                font-style: italic;
                text-transform: uppercase;
                font-weight: 350;
              `
          }
          break
        case 'closed':
          return `
              color: ${mediumGray};
              font-style: italic;
              text-transform: uppercase;
              font-weight: 350;
            `
        default:
          return `
            text-transform: capitalize;
            color: ${midnight}
            font-weight: 700;
          `
      }
    }}
   }
`

export const ScheduleContainer = styled('div')`
  display: grid;
  grid-template-columns: .1fr 1.5fr;
  ${({ timeSlots = [] }) => {
    const { startTime, endTime } = getScheduleStartAndEndTimes(timeSlots)
    const rows = Math.ceil(getLengthInMinutes({ startTime, endTime }) / increment)
    return `
      grid-template-rows: repeat(${rows}, ${incrementHeight});
    `
  }}

  & > ${TimeSlot} {
    border-top: ${timeLineBorder}; 
  }
  & > ${TimeSlot}:last-of-type {
    border-bottom: ${timeLineBorder}; 
  }
`


export const TimeLabel = styled('div')`
  font-size: 12px;
  text-align: end;
  white-space: nowrap;
  grid-column: 1 / 1;
  transform: translate(0, -25%);
  grid-row: ${({ time, offset }) => getScheduleRows(time, time, offset)};
`

export const NoRsvpBlock = styled('div')`
  font-style: bold;
  color: black;
  max-width: 150px;
  font-size: 14px;
  text-align: center;
  margin: auto;
  font-weight: 700;

  :before {
    content: 'This amenity does not require reservations. To change this please edit the details.';
  }
`

export const TextField = styled(CommonTextField)`
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  color: ${({ theme }) => theme.palette.common.black};
  width: 100%;
`

export const MultiDayReservationContainer = styled('div')`
display: grid;
height: 100%;
grid-template-columns: .1fr 1.5fr;
${({ rows }) => {
    const myRows = rows * 2 + 1
    return `grid-template-rows: repeat(${myRows}, ${bigIncrementHeight});`
  }}
`

export const MultiDayReservation = styled('div')`
  display: grid;
  height: ${incrementHeight * 2}
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
  grid-column: 2 / -1;
  border-bottom: ${({ theme }) => `solid 0.75px ${theme.palette.neutrals.mediumGray}`};
`

export const MultiDayDashboardReservation = styled('div')`
  display: grid;
  height: ${incrementHeight * 2}
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
  grid-column: 2 / -1;
`

export const MultiDayDate = styled('div')`
  font-size: 10px;
  color: ${({ theme }) => theme.palette.neutrals.darkGray};
  padding-bottom: 0px;
  padding-top: 0px;
`

export const MultiDayBold = styled('div')`
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  width: fit-content;
  padding-bottom: 0px;
  padding-top: 0px;
`

export const MultiDayHeader = styled('div')`
  color: ${({ theme }) => theme.palette.primary.navy.midnight};
  font-weight: bold;
  font-size: 20px;
`

export const MultiDayReservationDetails = styled('div')`
  color: ${({ theme }) => theme.palette.neutrals.darkGray};
  font-size: 12px;
`

export const GreenHeader = styled('div')`
  width: fit-content;
  height: 35px;
  padding: 7px;
  padding-left: 10px;
  padding-right: 7%;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  font-style: italic;
  background: linear-gradient(
    47deg,
    ${({ theme }) => theme.palette.primary.green.mint} 85%,
    rgba(255, 255, 255, 1) 85%
  );
`
