import {
  createFetchActions,
  createUpdateActions
} from '../../helpers/actions/creators'
import { generateScheduleItems } from '../utils'

export const SECTION = 'SCHEDULES'
export const SCHEDULE_ENABLED = 'SCHEDULEENABLED'
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE'
export const UPDATE_SCHEDULE_ENABLED = 'UPDATE_SCHEDULE_ENABLED'

export const {
  fetchAction: fetchScheduleAction,
  fetchStartAction: fetchScheduleStart,
  fetchSuccessAction: fetchScheduleSuccess,
  fetchErrorAction: fetchScheduleError
} = createFetchActions(SECTION)

export const fetchSchedule = id => fetchScheduleAction({ id })

export const updateScheduleActions = createUpdateActions(SECTION, true, true)

export const updateScheduleItem = scheduleItemUpdate => {
  const scheduleItems = generateScheduleItems(scheduleItemUpdate)
  return updateSchedule({ schedule_items: scheduleItems })
}
export const updateSchedule = update => ({
  type: UPDATE_SCHEDULE,
  update
})

export const updateScheduleOptimistically = update =>
  updateScheduleActions.updateOptimistcallyAction({
    update
  })

export const updateScheduleSuccess = payload =>
  updateScheduleActions.updateSuccessAction(payload)

export const updateScheduleRevert = update =>
  updateScheduleActions.updateRevertAction({
    update
  })

export const updateScheduleEnabledActions = createUpdateActions(
  SCHEDULE_ENABLED,
  true
)

export const updateScheduleEnabled = enabled => ({
  type: UPDATE_SCHEDULE_ENABLED,
  enabled
})

export const updateScheduleEnabledOptimistically = enabled =>
  updateScheduleEnabledActions.updateOptimistcallyAction({
    enabled
  })

export const updateScheduleEnabledRevert = enabled =>
  updateScheduleActions.updateRevertAction({
    enabled
  })
