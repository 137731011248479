// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import BrowserHistory from 'history'
import type { Match } from 'react-router-dom'
import { GridItem } from '../../Common/Grid'
import Grid from '../../Common/Grid'
import Flex from '@Common/Flex'
import Modal from '@material-ui/core/Modal'
import { DefaultBackButton } from '@Common/Button'
import { CarouselUnit as Unit } from '@Common/CarouselList'
import { FlexItem } from '@Common/Flex/FlexDirection'
import Loader from '../../Common/Loader'
import Button from '@Common/Cards/CardButton'
import type { Theme } from '../../../theme'
import {
  ForceText,
  Number,
  BackButton,
  TitleCustom as Title,
  Screen,
  ListWrap
} from './styles'

type Props = {
  removalSteps: string[],
  history: BrowserHistory,
  match: Match,
  isLoading: boolean,
  deleteDevice: () => void,
  excludeHub: () => void,
  theme: Theme,
  excludeLoading: boolean,
  excludeSuccess: boolean,
  clearExclusionStatus: () => void
}

class DeviceDelete extends React.Component<Props> {
  list = () => {
    const { removalSteps } = this.props
    return removalSteps.map((step, index) => (
      <ListWrap key={index}>
        <FlexItem key={index} flex={2}>
          {step}
        </FlexItem>
      </ListWrap>
    ))
  }

  handleBackStep = () => {
    const {
      history,
      match: { url }
    } = this.props
    this.props.clearExclusionStatus()
    history.push(`${url.split('/delete')[0]}`)
  }

  render() {
    const { isLoading, excludeLoading, excludeSuccess, theme } = this.props

    return (
      <Modal open={true}>
        <Screen>
          <Grid>
            <GridItem columnStart={1} columnSpan={12} rowStart={1} rowSpan={2}>
              <Flex justifySpaceBetween>
                <BackButton onClick={this.handleBackStep}>
                  <DefaultBackButton />
                </BackButton>
              </Flex>
            </GridItem>
            <GridItem columnStart={4} columnSpan={6} rowStart={4} rowSpan={10}>
              <Title>REMOVE A DEVICE</Title>

              {isLoading ? (
                <Loader />
              ) : (
                <React.Fragment>
                  <Unit height="4em">
                    <Flex alignCenter fullWidth>
                      <Number>1.</Number>
                      <ListWrap>
                        <FlexItem flex={2}>
                          Turn on the hubs exclude mode
                        </FlexItem>
                        <FlexItem flex={1} displayFlex flexEnd>
                          <Button
                            text={
                              excludeLoading === true
                                ? 'EXCLUDING...'
                                : excludeSuccess === true
                                ? 'EXCLUDED'
                                : 'EXCLUDE'
                            }
                            width="100%"
                            background={
                              excludeLoading === true || excludeSuccess === true
                                ? theme.palette.neutrals.deepGray
                                : theme.palette.blue.main
                            }
                            onClick={this.props.excludeHub}
                          />
                        </FlexItem>
                      </ListWrap>
                    </Flex>
                  </Unit>
                  {this.list().map((listItem, index) => (
                    <Unit key={index} height="4em">
                      <Flex alignCenter fullWidth>
                        <Number>{index + 2}.</Number>
                        {listItem}
                      </Flex>
                    </Unit>
                  ))}
                </React.Fragment>
              )}
            </GridItem>
            <GridItem columnStart={4} columnSpan={6} rowStart={12} rowSpan={1}>
              <Flex justifyCenter>
                <ForceText onClick={this.props.deleteDevice}>
                  Having trouble removing this device? <span>Force Delete</span>
                </ForceText>
              </Flex>
            </GridItem>
          </Grid>
        </Screen>
      </Modal>
    )
  }
}

export default withTheme(DeviceDelete)
