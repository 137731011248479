// @flow

import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

import withStyles from '@material-ui/core/styles/withStyles'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import Button from '../Cards/CardButton'
import { styles } from './styles'
import Flex, { FlexItem } from '../Flex/FlexDirection'
import { Fragment } from 'react';

import type { Theme } from '../../../theme'


type Props = {
  classes: Object,
  show: boolean,
  onClose: Function,
  title: string,
  message: string,
  messageIcon: string,
  messageContainerWidth: string,
  buttons: [
    { onClick: Function, color: string, text: string, disabled: boolean },
    { onClick: Function, color: string, text: string, disabled: boolean }
  ],
  centerTitle: boolean,
  theme: Theme,
  topContent: Boolean,
  footerText: string
}

class AlertModal extends React.Component<Props, {}> {
  render() {
    const {
      show,
      onClose,
      title,
      message = '',
      messageIcon: messageIconName = null,
      messageContainerWidth,
      buttons,
      classes,
      theme = {},
      topContent = true,
      footerText
    } = this.props

    let messageIcon = <Fragment/>
    if (messageIconName) {
      switch(messageIconName){
        case 'warning':
          messageIcon = <ReportProblemOutlinedIcon style={{color: theme.palette.secondary.yellow.golden}}/>
          break;
        default:
          break;
      }
    }

    const dialogContent = (
      <DialogContent>
        <Flex fullWidth>
          {messageIcon}
          <DialogContentText style={{ width: messageContainerWidth }} id="alert-dialog-description">
            {message}
          </DialogContentText>
        </Flex>
      </DialogContent>
    )

    const dialogActions = (
      <DialogActions style={{padding:24}}>
        <Flex fullWidth>
          {buttons.map((button, index) => (
            <React.Fragment key={`button.text${index}`}>
              <FlexItem flex={1} displayFlex flexCenter>
                <Button
                  onClick={button.onClick}
                  background={button.color}
                  outlined={buttons.length === 1 ? index === 1 : index === 0}
                  text={button.text}
                  disabled={button.disabled}
                  width="100%"
                />
              </FlexItem>
              {index !== (0 || buttons.length - 1) && (
                <FlexItem flex={0.2} />
              )}
            </React.Fragment>
          ))}
        </Flex>
      </DialogActions>
    )

    return (
      <React.Fragment>
        <Dialog
          classes={{
            paper: classes.root
          }}
          open={show}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {title}
          </DialogTitle>
          {topContent && dialogContent}
          {dialogActions}
          {!topContent && dialogContent}
          {footerText && footerText }
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(AlertModal)
