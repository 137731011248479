// import React, { Component } from 'react'
import { connect } from 'react-redux'
import withFetchData from 'zego-shared/views/withFetchData'
import ChatWindow from './ChatWindow'
import { withRouter } from 'react-router-dom'
import { fetchUser } from 'zego-shared/store/users/user/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { getMessageData } from '../../../../store/Chat/messageSelectors'
import { sendChatMessage } from 'zego-shared/store/chat/messages/actions'
import { fetchChatChannelMessages } from 'zego-shared/store/chat/messages/actions'
import { setActiveChannelByUserId } from 'zego-shared/store/chat/activeChannel/actions'
import { getPinnedChannels } from 'zego-shared/store/chat/pinnedChannels/selectors'
import { getChatUserData } from '../../../../store/Chat/userSelectors'
import {
  pinChannel,
  unpinChannel
} from 'zego-shared/store/chat/pinnedChannels/actions'
import { getActiveChannelUrl } from '../../../../store/Chat/channelSelectors'

const fetchingSelector = channelType =>
  createIsFetchingSelector(['chatchannelmessages', 'user'])

const mapStateToProps = (state, { match: { params } }) => {
  return {
    isFetching: fetchingSelector(params.channelType)(state),
    messages: getMessageData(state),
    channelType: params.channelType,
    pinnedChannels: getPinnedChannels(state),
    activeChannelUrl: getActiveChannelUrl(state),
    chatUser: getChatUserData(state)
  }
}

const mapDispatchToProps = (dispatch, { match: { params }, userId }) => {
  return {
    fetchData: () => {
      const userIdToFetch = params.userId || userId

      if (userIdToFetch) {
        dispatch(setActiveChannelByUserId(userIdToFetch))
        dispatch(fetchChatChannelMessages())
        dispatch(fetchUser(userIdToFetch))
      }
    },

    componentDidUpdate: ({ match, userId }, props) => {
      const prevUserIdToFetch = match.params.userId || userId
      const userIdToFetch = props.match.params.userId || props.userId

      if (prevUserIdToFetch !== userIdToFetch) {
        dispatch(fetchUser(userIdToFetch))
      }
    },
    refetchData: () => {
      // dont refresh data here
      // chatListDrawer will call fetch to get the new channel based on url
    },
    sendMessage: (message, types, file) =>
      dispatch(sendChatMessage(message, types, file)),
    pinChannel: channelUrl => dispatch(pinChannel(channelUrl)),
    unpinChannel: channelUrl => dispatch(unpinChannel(channelUrl))
  }
}

const ChatWindowWithFetchData = withFetchData(ChatWindow)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChatWindowWithFetchData)
)
