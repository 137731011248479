import styled from '@emotion/styled'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import isPropValid from '@emotion/is-prop-valid'

export const CustomCard = styled(Card, { shouldForwardProp: isPropValid })`
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  border-radius: 5px;
  grid-column: ${({ columnStart, columnSpan }) => `${columnStart} / span
  ${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) => `${rowStart} / span
  ${rowSpan}`};
  display: flex;
  height: ${({ isSummary }) => !isSummary && '190px'};

  ${({ theme, type }) =>
    type === 'summary'
      ? `background: ${theme.palette.text.light3};
  `
      : ''};
  ${({ showShadow }) => !showShadow && `box-shadow: none;`};
`
export const Head = styled('div')`
  display: flex;
`

export const StyledTitle = styled('div')`
  color: ${({ theme }) => theme.palette.text.black1};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
`

export const Body = styled('div')`
  position: relative;
  display: flex;
  flex: 3;
`

export const RightPanel = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  flex: 1;
  display: flex;
  justify-content: flex-end;

  & > span {
    margin: 0em 0.5em;
    align-self: center;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & > span:last-child {
    display: flex;
    justify-content: flex-end;
  }
`

export const Underline = styled('div')`
  width: ${({ length }) => (length ? length : '21px')};
  height: 10px;
  border-bottom: ${({ underlineStyle, theme }) =>
    underlineStyle
      ? underlineStyle
      : `1px solid ${theme.palette.text.tertiary};`};
`

export const Footer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`

export const CustomCardContent = styled(CardContent, {
  shouldForwardProp: prop => ['imageSrc'].indexOf(prop) === -1
})`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  padding-left: ${({ isSummary }) => isSummary && '0'};
  background-image: url(${({ imageSrc }) => imageSrc});
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  width: 100%;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  ${Footer}, ${Head} {
    z-index: 100;
  }

  ${Body} {
    z-index: 150;
  }
`

export const FooterNote = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.text.tertiary};
`

export const Title = styled('div')`
  color: ${({ theme }) => theme.palette.common.black};
`
