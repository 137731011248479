import styled from '@emotion/styled'
import TextField from '@material-ui/core/TextField'

export const CustomTextField = styled(TextField, {
  shouldForwardProp: prop => !['active', 'inputProps'].includes(prop)
})`
  width: 90%;
  svg {
    cursor: pointer;
    margin-top: 0.4em;
  }
`

export const TextCount = styled('div')`
  border-radius: 2em;
  text-align: center;
  min-width: 3em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.navy.spaceBlue};
`

export const Attachment = styled('p')`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  color: ${({ theme }) => theme.palette.primary.green.mint};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  & > span:first-child {
    font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  }
`

export const ToolBar = styled('div')`
  position: absolute;
  min-width: 5.5em;
  right: 0;
  top: -1em;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  & > * {
    color: ${({ theme }) => theme.palette.grey.main};
    font-size: ${({ theme }) => theme.custom.font.size.lg};
    margin-left: 0.5em;
  }
  .chat-icon {
    transform: rotate(-45deg);
    * {
      fill: ${({ theme }) => theme.palette.grey.main};
    }
  }
`

export const ToolTipWrapper = styled('div')`
  margin-top: -0.5em;
  margin-left: 0.3em;
  width: 100%;
  & > span {
    padding: 0;
  }
`
