import get from 'lodash/get'
import { getDeviceMakeModel, getBatteryLevelText } from './selectors'
import { getDate } from '../../utils'

export const deviceToThermostatData = device => {
  const currentThermostatMode = get(device, [
    'last_state',
    'data',
    'system_mode'
  ])

  const abnormal =
    get(device, ['last_state', 'data', 'status']) !== 'offline' &&
    get(device, ['last_state', 'data', 'status']) !== 'online'

  return {
    coolSetPoint: get(device, ['last_state', 'data', 'occ_cool_sp']),
    heatSetPoint: get(device, ['last_state', 'data', 'occ_heat_sp']),
    currentTemp: get(device, ['last_state', 'data', 'current_temp']),
    deviceStatus: get(device, ['last_state', 'data', 'status']),
    batteryPercent: device.battery,
    batteryLevelText: `Battery Level ${getBatteryLevelText(device.battery)}`,
    batteryLevelPercentText: `Battery Level ${
      device.battery ? `${device.battery}%` : 'N/A'
    }`,
    deviceModel: getDeviceMakeModel(device),
    id: device.id,
    abnormal,
    identifier: device.identifier,
    currentThermostatMode,
    installationDate: getDate(device.inserted_at)
  }
}
