// @flow

import React from 'react'
import Dropzone from 'react-dropzone'
import theme from './../../../theme'
import AlertModal from '@Common/AlertModal/AlertModal'
import { DropBox } from './styles'

const fileSizeLimit = 5242880

type Props = {
  uploadedPhotos: Array<Object>,
  onPhotoUpload: Array<Object> => void,
  maxCount: number,
}

type State = {
  photos: Array<Object>,
  selectedIndex: number,
  photoUploadError: ?string,
}

export default class PhotoUpload extends React.Component<Props, State> {
  state = {
    photos: [],
    selectedIndex: 0,
    photoUploadError: null,
  }

  fileUploadRef: Object = React.createRef()

  validatePhoto = (file: Object) => {
    if (!file) {
      return false
    }

    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
      this.setState({ photoUploadError: 'Oops! It looks like you are attempting to upload a file that is not in a compatible format. Please try uploading your file again in jpeg or png format.' })
      return false;
    } else if (file.size > fileSizeLimit) {
      this.setState({ photoUploadError: 'Oops! It looks like you are attempting to upload a file that is too large. Please try again uploading a smaller image.' })
      return false;
    } else if (this.state.photos.length >= this.props.maxCount) {
      this.setState({ photoUploadError: 'Oops! It looks like you are attempting to upload too many photos.' })
      return false;
    }

    return true;
  }

  onDrop = async (files: Array<Object>) => {
    this.handlePhoto(files)
  }

  addPhoto = async ({ target: { files } }: Object) => {
    this.handlePhoto(files)
  }

  handlePhoto = (files: Array<Object>) => {
    if (files) {
      [...files].forEach(file => {
        if (this.validatePhoto(file)) {
          const uploadedPhotos = this.props.uploadedPhotos.concat(file);
          this.props.onPhotoUpload(uploadedPhotos)
        }
      })
    }
  }

  hidePhotoUploadErrorModal = () => {
    this.setState({ photoUploadError: null })
  }

  componentDidMount = () => {
    const { uploadedPhotos } = this.props
    if (uploadedPhotos) {
      this.setState({ photos: uploadedPhotos })
    }
  }

  render() {
    const { photoUploadError } = this.state
    const { maxCount, uploadedPhotos } = this.props
    return (
      <div>
        <AlertModal
          showCloseButton
          title={photoUploadError}
          message=''
          buttons={[
            {
              onClick: this.hidePhotoUploadErrorModal,
              text: 'OK',
              color: theme.palette.primary.navy.spaceBlue
            }
          ]}
          show={photoUploadError !== null}
          onClose={this.hidePhotoUploadErrorModal}
        />
        {(uploadedPhotos.length < maxCount) && <Dropzone onDrop={this.onDrop} >
          {({ getRootProps, getInputProps }) => (
            <DropBox {...getRootProps()} >
              <input {...getInputProps()} />
              <p style={{ color: theme.palette.neutrals.deepGray, fontSize: '16px' }}>
                Drag files here or <br /> click to select files
                </p>
            </DropBox>
          )}
        </Dropzone>}
      </div>
    )
  }
}
