import { connect } from 'react-redux'
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { getCardData } from 'zego-shared/store/marketplace/selectors'
import { fetchMarketplaceCard, updateMarketplaceCard } from 'zego-shared/store/marketplace/actions'
import MarketplaceNewDeal from './MarketplaceNewDeal'

const fetchingSelector = createIsFetchingSelector(['marketplace'])

export const mapStateToProps = state => ({
  isFetching: fetchingSelector(state),
  marketplaceDeal: getCardData(state)
})

export const mapDispatchToProps = (
  dispatch,
  { history, match: { url, params: { propertyId, id } } }
) => ({
  fetchData: () => {
    dispatch(fetchMarketplaceCard({ marketplaceId: id }))
  },
  updateMarketplaceDeal: (marketplaceDeal, image, type) => dispatch(updateMarketplaceCard(
    marketplaceDeal,
    image,
    type,
    history,
    `${url.split('/marketplace')[0]}/marketplace/${id}`
  )),
})

export default connect(mapStateToProps, mapDispatchToProps)(withFetchData(MarketplaceNewDeal))
