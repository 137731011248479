import { connect } from 'react-redux'
import { updateProperty } from 'zego-shared/store/properties/actions'
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { fetchSchedule } from 'zego-shared/store/schedule/actions'
import { getGeneralInfo } from '../../store/Property/selectors'
import {
  getSelectedPropertyVacantScheduleID,
  getSelectedPropertyVacantScheduleInfo
} from 'zego-shared/store/schedule/selectors'
import { updateScheduleEnabled } from 'zego-shared/store/schedule/actions'
import { updatePropertySetting } from 'zego-shared/store/settings/actions'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import GeneralInfo from './GeneralInfo'

const fetchingSelector = createIsFetchingSelector(['schedule'])

const mapStateToProps = state => {
  const scheduleId = getSelectedPropertyVacantScheduleID(state)
  const scheduleInfo = getSelectedPropertyVacantScheduleInfo(state)
  return {
    property: getGeneralInfo(state),
    scheduleId,
    scheduleInfo,
    isFetching: fetchingSelector(state),
    hasData: scheduleId == null || scheduleInfo != null
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { propertyId }
    }
  }
) => ({
  updateProperty: attributes => {
    const {
      phoneNumber,
      emergencyPhoneNumber,
      email,
      zip,
      city,
      officeAddressLine1,
      officeAddressLine2,
      officeAddressState,
      maintenanceEmail,
      officeHours,
      amenityContact
    } = attributes
    attributes = {
      phone_number: phoneNumber,
      emergency_phone_number: emergencyPhoneNumber,
      office_address_zip: zip,
      office_address_city: city,
      office_address_line_1: officeAddressLine1,
      office_address_line_2: officeAddressLine2,
      office_address_state: officeAddressState,
      office_hours: officeHours,
      system_metadata: {
        email,
        maintenance_email: maintenanceEmail
      }
    }
    dispatch(updateProperty(propertyId, attributes))
    if (amenityContact != null) {
      dispatch(updatePropertySetting(propertyId, {
        amenityContact
      }))
    }
  },
  fetchData: scheduleId => {
    scheduleId && dispatch(fetchSchedule(scheduleId))
  },
  updateScheduleEnabled: enabled => {
    dispatch(updateScheduleEnabled(enabled))
  },
  updatePropertySettings: attributes => {
    dispatch(updatePropertySetting(propertyId, attributes))
  },
  showSnackbar: message => dispatch(showSnackbar(message, 'error'))
})

const GeneralInfoWithFetchData = withFetchData(GeneralInfo)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    fetchData() {
      dispatchProps.fetchData(stateProps.scheduleId)
    }
  })
)(GeneralInfoWithFetchData)
