import {
  createFetchActions,
  createUpdateActions
} from '../../helpers/actions/creators'

export const VEHICLES = 'VEHICLES'
export const VEHICLE = 'VEHICLE'
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE'
export const VEHICLECOLORS = 'VEHICLECOLORS'
export const VEHICLESTATES = 'VEHICLESTATES'
export const VEHICLETYPES = 'VEHICLETYPES'
export const VEHICLEMAKES = 'VEHICLEMAKES'

export const {
  fetchAction: fetchVehiclesAction,
  fetchStartAction: fetchVehiclesStart,
  fetchSuccessAction: fetchVehiclesSuccess,
  fetchErrorAction: fetchVehiclesError
} = createFetchActions(VEHICLES)

export const fetchVehicles = (propertyId, page = 1, queryParams) =>
  fetchVehiclesAction({ propertyId, page, queryParams })

export const searchVehicles = (propertyId, page, searchTerm, queryParams = {}) =>
  fetchVehiclesAction({
    propertyId,
    searchTerm,
    queryParams: { ...queryParams, page }
  })

export const {
  fetchAction: fetchVehicleAction,
  fetchStartAction: fetchVehicleStart,
  fetchSuccessAction: fetchVehicleSuccess,
  fetchErrorAction: fetchVehicleError
} = createFetchActions(VEHICLE)

export const fetchVehicle = vehicleId => fetchVehicleAction({ vehicleId })

export const updateVehicleActions = createUpdateActions(VEHICLE, true, true)

export const updateVehicle = (update, history, url) => ({
  type: UPDATE_VEHICLE,
  update,
  history,
  url
})

export const updateVehicleOptimistically = update =>
  updateVehicleActions.updateOptimistcallyAction({
    update
  })

export const updateVehicleSuccess = payload =>
  updateVehicleActions.updateSuccessAction(payload)

export const updateVehicleRevert = update =>
  updateVehicleActions.updateRevertAction({
    update
  })

export const {
  fetchAction: fetchVehiclesColorsAction,
  fetchStartAction: fetchVehiclesColorsStart,
  fetchSuccessAction: fetchVehiclesColorsSuccess,
  fetchErrorAction: fetchVehiclesColorsError
} = createFetchActions(VEHICLECOLORS)

export const fetchVehiclesColors = propertyId =>
  fetchVehiclesColorsAction({ propertyId })

export const {
  fetchAction: fetchVehiclesStatesAction,
  fetchStartAction: fetchVehiclesStatesStart,
  fetchSuccessAction: fetchVehiclesStatesSuccess,
  fetchErrorAction: fetchVehiclesStatesError
} = createFetchActions(VEHICLESTATES)

export const fetchVehiclesStates = propertyId =>
  fetchVehiclesStatesAction({ propertyId })

export const {
  fetchAction: fetchVehiclesTypesAction,
  fetchStartAction: fetchVehiclesTypesStart,
  fetchSuccessAction: fetchVehiclesTypesSuccess,
  fetchErrorAction: fetchVehiclesTypesError
} = createFetchActions(VEHICLETYPES)

export const fetchVehiclesTypes = propertyId => fetchVehiclesTypesAction({ propertyId })

export const {
  fetchAction: fetchVehiclesMakesAction,
  fetchStartAction: fetchVehiclesMakesStart,
  fetchSuccessAction: fetchVehiclesMakesSuccess,
  fetchErrorAction: fetchVehiclesMakesError
} = createFetchActions(VEHICLEMAKES)

export const fetchVehiclesMakes = propertyId => fetchVehiclesMakesAction({ propertyId })
