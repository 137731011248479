// @flow
import React from 'react'
import { withRouter } from 'react-router-dom'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import Flex from '@Common/Flex'
import { CommonIcon } from '@icons'
import { BalancePan, LeasePan, Title, MonthlyRentStyled, WideBaseCardStyled, IconTextStyled } from './styles.js'
import Header from '@Common/Header'

export type Props = {
  leaseInfo: {
    monthlyCharge: number,
    leaseEndDate: string,
    balance: number,
    moveInDate: string,
    leaseStartDate: string,
    leaseEndDate: string,
    moveOutDate: string
  }
}

const LeaseDetailsCard = ({
  leaseInfo: {
    monthlyCharge,
    leaseEndDate,
    balance,
    moveInDate,
    moveOutDate,
    leaseStartDate
  }
}: Props) => (
  <WideBaseCardStyled
    title="Lease Details"
    underlineLength={'50px'}
    underlineStyle={({ theme }) => `3px solid ${theme.palette.primary.green.mint}`}
    columnStart={1}
    columnSpan={2}
    showShadow={true}
    styles={{margin: '30px'}}
    leftContent={() => (
      <LeasePan>
        <FlexDirection justifyLeft>
          <Flex direction="column" alignCenter width={'200px'}>
            <CommonIcon name="calender" height="44px" width="44px" />
            <IconTextStyled>{`Start Date: ${leaseStartDate}`} <br/> {`Move-in Date: ${moveInDate}`}</IconTextStyled>
          </Flex>
          <Flex direction="column" alignCenter width={'200px'}>
            <CommonIcon name="calender" height="44px" width="44px" />
            <IconTextStyled>{`End Date: ${leaseEndDate}`} <br/> {`Move-out Date: ${moveOutDate}`}</IconTextStyled>
          </Flex>
        </FlexDirection>
      </LeasePan>
    )}
    rightContent={() => (
      <BalancePan>
        <FlexDirection direction="column" displayFlex>
          <MonthlyRentStyled>Monthly Rent: {monthlyCharge}</MonthlyRentStyled>
          <Title>Balance Due</Title>
          <FlexItem flex={2}>
            <Header
              blackText
              alternateFontSize
              >{balance}</Header>
          </FlexItem>
        </FlexDirection>
      </BalancePan>
    )}
  />
)

export default withRouter(LeaseDetailsCard)
