// @flow
import React from 'react'
import { css } from 'emotion'
import { withTheme } from 'emotion-theming'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import Flex from '@Common/Flex'
import { Attachment, ToolBar, TextCount, ToolTipWrapper } from './styles'
import CustomTextField from '../TextField'
import { CommonIcon, ChatIcon } from '@icons'
import { TextEditorProps, TextEditorState } from './types'
import Tooltip from '../Tooltip'

class TextEditor extends React.Component<TextEditorProps, TextEditorState> {
  state = {
    textCount: this.props.message ? this.props.message.length : 0,
    active: false
  }

  handleChange = async (event: SyntheticInputEvent<HTMLInputElement>) => {
    await this.props.handleMessageChange(event.target.value)
    this.setState({ textCount: this.props.message.length })
  }

  removeAttachment = () => {
    this.props.removeAttachment()
  }

  highlightPencil = () => {
    this.setState({ active: true })
  }

  dimPencil = () => {
    this.setState({ active: false })
  }

  componentDidUpdate(prevProps) {
    prevProps.message !== this.props.message &&
      this.setState({
        textCount: this.props.message ? this.props.message.length : 0
      })
  }

  render() {
    const {
      theme,
      message,
      label,
      width,
      addAttachment,
      file,
      alwaysShowLabel = false,
      editMode = false,
      enableAttachment = true,
      enableMergeFields = true
    } = this.props

    const attachmentCount = file ? 1 : 0
    const attachmentName = file ? file.name : ''
    const attachmentSize = file ? file.size : ''

    const { textCount, active } = this.state

    return (
      <Flex
        direction="column"
        width={width ? width : '100%'}
        className={css`
          svg {
            cursor: pointer;
          }
        `}>
        <CustomTextField
          alwaysShowLabel={alwaysShowLabel}
          editMode={editMode}
          isEditor={true}
          value={message || ''}
          label={label}
          multiline
          rows={6}
          onFocus={this.highlightPencil}
          onBlur={this.dimPencil}
          onChange={this.handleChange}
          InputProps={{
            endAdornment: (
              <FlexDirection
                direction="column"
                style={{
                  minWidth: '2em',
                  marginRight: '-1em'
                }}>
                <FlexItem flex={0.5} displayFlex alignItemsRight>
                  <ToolBar>
                    {enableMergeFields && (
                      <CommonIcon
                        name="merge_field"
                        width="0.8em"
                        height="0.8em"
                      />
                    )}
                    {enableAttachment && (
                      <label className="chat-icon" htmlFor="editor-file-attach">
                        <ChatIcon name="clip" width="0.8em" height="0.8em" />
                      </label>
                    )}
                    <input
                      id="editor-file-attach"
                      type="file"
                      onChange={addAttachment}
                      style={{ display: 'none' }}
                    />
                    <TextCount>{textCount}</TextCount>
                    <ToolTipWrapper>
                      <Tooltip type={'TextEditorTextCount'} />
                    </ToolTipWrapper>
                  </ToolBar>
                </FlexItem>
                <FlexItem flex={0.5} displayFlex alignItemsRight flexEnd>
                  <CommonIcon
                    name="edit"
                    height="14px"
                    width="12px"
                    fill={
                      active
                        ? theme.palette.primary.green.mint
                        : theme.palette.neutrals.deepGray
                    }
                  />
                </FlexItem>
              </FlexDirection>
            )
          }}
        />
        {attachmentCount > 0 && (
          <Attachment>
            <span>{attachmentCount || 0} attachment</span>
            <span>
              {attachmentName} {Math.round(parseInt(attachmentSize, 10) / 1024)}
              {' kb  '}
              <CommonIcon
                name="close"
                width="0.5em"
                height="0.5em"
                fill={theme.palette.primary.green.mint}
                strokeWidth={8}
                stroke={theme.palette.primary.green.mint}
                onClick={this.removeAttachment}
              />
            </span>
          </Attachment>
        )}
      </Flex>
    )
  }
}

export default withTheme(TextEditor)
