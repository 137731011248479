// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import { withRouter } from 'react-router-dom'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import { CommonIcon } from '@icons'
import LockSpecs from './LockSpecs'
import { LockWrap } from './styles'
import { Props } from './types'
import { capitalizeFirstLetter } from '../../../../utils'

const LockDeviceContent = ({
  deviceModel,
  deviceStatus,
  installationDate,
  lastUpdated,
  batteryPercent,
  lastStateText,
  batteryLevelText,
  toggleViewBatteryMode,
  toggleSwitch,
  viewBatteryMode,
  batteryLevelPercentText,
  theme,
  firstDeviceID,
  abnormal,
  match: { params },
  location: { pathname }
}: Props) => {
  return (
    <LockWrap>
      <FlexDirection fullWidth fullHeight>
        <FlexItem flex={3} style={{ display: 'flex', flexDirection: 'column' }}>
          <FlexItem flex={3} className="last-activity-time">
            {!viewBatteryMode ? (
              <React.Fragment>
                <span>{lastStateText}</span>
                {lastStateText !== 'Unknown Current Status' && (
                  <React.Fragment>
                    <span>
                      <CommonIcon name="bullet" width="6px" height="6px" />
                    </span>
                    <span>manually</span>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <span>{batteryLevelText}</span>
            )}
          </FlexItem>
          <FlexItem flex={1}>
            {!viewBatteryMode && (
              <LockSpecs
                deviceModel={deviceModel}
                deviceStatus={
                  abnormal ? 'Abnormal' : capitalizeFirstLetter(deviceStatus)
                }
                InstallationDate={installationDate}
                batteryLevelPercentText={batteryLevelPercentText}
                batteryPercent={batteryPercent}
                toggleViewBatteryMode={toggleViewBatteryMode}
              />
            )}
          </FlexItem>
        </FlexItem>
      </FlexDirection>
    </LockWrap>
  )
}

export default withRouter(withTheme(LockDeviceContent))
