// @flow

import React from 'react'
import BaseCard from '@Common/Cards/BaseCard'
import Flex from '@Common/Flex'
import { Content, Typography, Container, CountDescription } from './styles'
import CircleWithText from '@Common/Cards/CircleWithText'

import type { CommunicationCardProps } from './types'

const CommunicationCard = ({
  title,
  description,
  footer,
  imageSrc,
  columnStart,
  circleText,
  circleCount
}: CommunicationCardProps) => (
  <BaseCard
    columnStart={columnStart}
    columnSpan={3}
    rowStart={1}
    rowSpan={10}
    imageSrc={imageSrc}
    backgroundsize='260px'
    backgroundposition="top left"
    underlineLength="0"
    isCommunicationHome={true}
    content={() => (
      <Container>
        {circleText && (
          <Flex fullWidth justifyRight alignCenter>
            <Flex direction="column" alignCenter style={{ width: '84px' }}>
              <CircleWithText
                elementId={circleText.replace(/\s/g, '')}
                data={''}
                dataCount={circleCount}
                backgroundColor={({ theme }) => theme.palette.primary.green.mint}
                textColor={({ theme }) => theme.palette.common.white}
              />
              <CountDescription>{circleText}</CountDescription>
            </Flex>
          </Flex>
        )}

        <Content>
          <Flex direction="column">
            {title}
          </Flex>
          <Typography>{description}</Typography>
        </Content>
      </Container>
    )}
    footer={() => footer}
  />
)

export default CommunicationCard
