import styled from '@emotion/styled'
import MaterialTextField from '@material-ui/core/TextField'
import { Typography } from '@material-ui/core'
import ReactSelect from 'react-select'
import Button from '@material-ui/core/Button';
import { convertHexToRGB } from '../../../utils'
import UnitCategoryImage from '../../../images/unit_category_BG.png'
import PaginatedTable from '../../Common/PaginatedTable'

export const Content = styled('p')`
  color: ${({ theme }) => `${theme.palette.common.white}`};
  line-height: 1.5em;
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  line-height: 26px;
  margin-top: 80px;
`

export const TableContainer = styled('div')`
  height: 84.5%;
  width: ${({ fullWidth }) => fullWidth ? 'calc(100% + 4em)' : 'calc(100% - 400px)'};
  position: absolute;
  padding: 3em;
  ${({ fullWidth }) => fullWidth ? 'padding-left: 500px;' : ''}
  background: ${({ theme }) => `${theme.palette.common.white}`};
  ${({ fullWidth }) => fullWidth ? `
    background-image: url(${UnitCategoryImage});
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 25%;
  ` : ''}
  border-top-left-radius: 5px;
  box-shadow: ${({ theme }) => theme.shadows[3]};
  right: -2em;
  bottom: -0.5em;
  th, td {
    padding-right: 0.5em !important;
  }
  tr {
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.neutrals.deepGray}`};
  }
`
export const Layout = styled('div')`
  width: 100%;
  &:after {
    content: ' ';
    background: ${({ theme }) => `${theme.palette.neutrals.lightGray}`};
    border-top-right-radius: 5px;
    width: 70%;
    height: calc(100% - 2em);
    left: -2em;
    bottom: -0.5em;
    position: absolute;
    z-index: -15;
  }
`

export const Container = styled('div')`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`

export const TextField = styled(MaterialTextField)`
  width: 100%;
  height: 2em;
  margin-right: 1em;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.palette.neutrals.warmGray};
  padding: 0 15px;
  & input {
    color: ${({ theme }) => theme.palette.text.lightGrey};
    padding-top: 8px;
  }
`

export const StyledColumn = styled('div')`
  height: 4em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-start;
  ${({ index }) => index === 6 && `justify-content: flex-end`};
`

export const StyledCell = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`

export const StyledSelect = styled(ReactSelect)`
  left: 0%;
`

export const CalendarButton = styled(Button)`
  cursor: default;
  width: 200px;
  margin-top: 5px;
  color: ${ ({ theme }) => theme.palette.neutrals.darkGray};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`

export const StyledSelectCustomStyles = (theme) => {
  return {
    control: (base, state) => ({
      ...base,
      border: theme.palette.primary.green.mint,
      borderRadius: "20px",
      background: theme.palette.primary.green.mint,
      color: theme.palette.common.white,
      width: "150px"
    }),
    placeholder: base => ({
      ...base,
      color: theme.palette.primary.navy.midnight
    }),
    dropdownIndicator: base => ({
      ...base,
      color: theme.palette.primary.navy.midnight
    })
  }
};

export const FilterSelectStyle = (theme) => ({
  control: base => ({
    ...base,
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderRadius: 0,
    borderBottom: "none",
    marginLeft: "30px",
    boxShadow: "none",
    marginBottom: "30px",
    fontSize: theme.custom.font.size.sm
  }),
  menu: base => ({
    ...base,
    fontSize: theme.custom.font.size.sm
  }),
  placeholder: base => ({
    ...base,
    color: theme.palette.neutrals.darkGray
  }),
  dropdownIndicator: base => ({
    ...base,
    color: theme.palette.neutrals.darkGray
  })
});

export const CaptionHeading = styled(Typography)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.grey.main};
  flex-grow: 1;
`

export const SummaryContent = styled('div')`
  width: 100%;
`

export const SummaryListNumber = styled('span')`
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  margin-left: 0.3em;
`

export const SummaryListText = styled('span')`
  color: ${({ theme }) => theme.palette.primary.navy.spaceBlue};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  margin-left: 0.5em;
`

export const BackButton = styled('div')`
  position: absolute;
  right: 0;
`

export const CalendarWrapper = styled('div')`
  width: 470px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 1px 15px 0
    ${({ theme }) => convertHexToRGB(theme.palette.grey.main)};
  position: absolute;
  z-Index: 10;
  margin-left: -100px;
`

export const FilterDisplay = styled('div')`
  position: absolute;
  left: max(25%, 500px);
  top: 4em;
  width: calc(100% - max(25%, 500px));
`

export const SelectStyle = {
  control: base => ({
    ...base,
    border: 0,
    background: 'transparent',
    boxShadow: 'none'
  })
}

export const StyledTable = styled(PaginatedTable)`
  & tbody tr:hover {
    background-color: ${({ theme }) => theme.palette.neutrals.coolGray} !important;
    cursor: pointer;
  }
`

export const SearchField = styled(MaterialTextField)`
  margin-top: 5%;
  right: 60%;
  width: 160%;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.palette.common.black};
  padding: ${({ isGroupMsg }) => (isGroupMsg ? '0 7px' : '0 6px')};
  & input {
    color: ${({ theme }) => theme.palette.text.lightGrey};
    padding-top: ${({ isGroupMsg }) => (isGroupMsg ? '2' : '8px')};
    padding-bottom: ${({ isGroupMsg }) => (isGroupMsg ? '0' : '3px')};
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }
`


