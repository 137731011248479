import get from 'lodash/get'

const getDataWithPath = (state, path) => get(state, path)

export const getDataById = (state, id, path) => {
  return getDataWithPath(state, path).byId[id]
}

export const getData = (state, path) => {
  const data = getDataWithPath(state, path).allIds
  return data ? data.map(id => getDataById(state, id, path)) : null
}

export const getPaginationData = (state, path) => {
  const { total, totalPages, perPage, currentPage } = get(state, path, {}) || {}
  return {
    total,
    totalPages,
    perPage,
    currentPage
  }
}
