import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import MaterialListItem from '@material-ui/core/ListItem'
import MaterialListItemText from '@material-ui/core/ListItemText'
import MaterialListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { GridItem } from '../../../Common/Grid'

export const ListItem = styled(MaterialListItem, {
  shouldForwardProp: prop =>
    ['borderTop', 'selected', 'extraPadding'].indexOf(prop) === -1
})`
  font-size: ${({ theme }) => theme.custom.font.size.md};
  ${({ borderTop, theme }) =>
    borderTop && `border-top: 1px solid ${theme.palette.neutrals.coolGray}`};

  ${({ selected, theme }) =>
    selected && `color: ${theme.palette.neutrals.warmGray}`};
  ${({ extraPadding }) => extraPadding && `padding: 30px 15px `};
  height: 56px;
  &:hover {
    background: none;
  }
`

export const ListItemText = styled(MaterialListItemText)`
  padding: 0;
`

export const ListItemSecondaryAction = styled(MaterialListItemSecondaryAction)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`

export const Title = styled(Typography)`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  color: ${({ theme }) => theme.palette.common.black};
  padding: 1em 0;
`
export const Value = styled('span', {
  shouldForwardProp: prop => ['readOnly'].indexOf(prop) === -1
})`
  color: ${({ theme, readOnly }) =>
    readOnly ? theme.palette.text.light : theme.palette.primary.green.mint};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  cursor: ${({ readOnly }) => (readOnly ? 'text' : 'pointer')};
`
export const ScheduleContent = styled('div')`
  line-height: 2.5;
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const DefaultView = styled(GridItem)`
  border-color: ${({ theme }) => theme.palette.neutrals.coolGray}};
`
