// @flow
import React from 'react'
import UserProfileTable from '../UserProfileTable'
import Loader from '../../../Common/Loader'

type pets = {
  name: string,
  imageUrl: string,
  type: string,
  breed: string,
  color: string,
  weight: string,
  status: string,
  vaccinations: string
}

type Props = {
  pets: pets[],
  total: number,
  isFetching: boolean,
  match: { params: { propertyId: number } }
}

const defaultHeaders = [
  'type',
  'breed',
  'color',
  'weight',
  'vaccinations',
  'status'
]

const getHeaders = total => {
  return [`${total} Pets`, ...defaultHeaders]
}

function Pets(props: Props) {
  const { total, pets, isFetching, match: { params: { propertyId } } } = props

  if (isFetching) {
    return <Loader />
  }

  return (
    <UserProfileTable
      total={total}
      headers={getHeaders(total)}
      rowsPerPage={total}
      tableData={pets}
      keys={['name', ...defaultHeaders]}
      currentTab={'pets'}
      propertyId={propertyId}
      columnToHighlight={7}
    />
  )
}

export default Pets
