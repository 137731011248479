import React from 'react'
import PrivateRoute from '../PrivateRoute'
import { VendorLockCodes, CodeDetails, LockCodes } from '../../views/LockCodes'

const getLockCodesRoutes = () => [
  <PrivateRoute
    key="create-vendor-lock-codes"
    exact
    path="/properties/:propertyId/lock-codes/vendors/:vendorId/codes/create"
    component={CodeDetails}
  />,
  <PrivateRoute
    key="vendor-lock-codes"
    exact
    path="/properties/:propertyId/lock-codes/vendors/:vendorId/codes/:codeId"
    component={VendorLockCodes}
  />,
  <PrivateRoute
    key="vendor-lock-codes"
    exact
    path="/properties/:propertyId/lock-codes/vendors/:vendorId/codes"
    component={VendorLockCodes}
  />,
  <PrivateRoute
    key="vendor-lock-codes"
    exact
    path="/properties/:propertyId/lock-codes/vendors/:vendorId/codes/edit/:codeId"
    component={CodeDetails}
  />,
  <PrivateRoute
    exact
    key="lock-codes"
    path="/properties/:propertyId/lock-codes"
    component={LockCodes}
  />,
  <PrivateRoute
  exact
  key="lock-codes"
  path="/properties/:propertyId/lock-codes/:lockCodesInfo"
  component={LockCodes}
/>
]

export default getLockCodesRoutes
