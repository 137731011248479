// @flow

import React from 'react'
import SetupStep from './SetupStep'

import type { SetupStepProps } from './types'

type State = {
  ready: boolean
}

export default class TestDevice extends React.Component<SetupStepProps, State> {
  timer: ?TimeoutID

  state = {
    ready: false
  }

  componentDidMount() {
    const { enableNextButton, testWaitTimeout } = this.props
    this.timer = setTimeout(() => {
      enableNextButton()
      this.setState({ ready: true })
    }, testWaitTimeout)
  }

  componentWillUnmount() {
    this.timer = null
  }

  render() {
    const { ready } = this.state
    const { beforeTestInstruction } = this.props
    return (
      <SetupStep
        title={ready ? 'RUN A TEST' : 'Retrieving Data...'}
        listItems={ready ? beforeTestInstruction : null}
      />
    )
  }
}
