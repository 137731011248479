import { combineReducers } from 'redux'
import {
  AMENITY,
  AMENITIES,
  updateAmenityActions
} from './actions'
import withPaginatedFetch from '../helpers/reducers/withPaginatedFetch'
import withFetch from '../helpers/reducers/withFetch'

const reducer = (state = null, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const amenityReducer = (state = null, action) => {
  switch (action.type) {
    case updateAmenityActions.updateSuccessAction().type:
    case updateAmenityActions.updateOptimistcallyAction().type:
    case updateAmenityActions.updateRevertAction().type:
      return { ...state, ...action.update, ...action.payload }
    default:
      return state
  }
}

export const amenity = withFetch(AMENITY)(amenityReducer)

export const amenities = withPaginatedFetch(AMENITIES)(reducer)

export default combineReducers({
  amenity,
  amenities
})
