import styled from '@emotion/styled'
import MaterialListItem from '@material-ui/core/ListItem'
import MaterialTextField from '@material-ui/core/TextField'
import { FabButton } from '@Common/Button'
import { GridItem } from '../../Common/Grid'

export const Name = styled('div')`
  padding: 0px 0px 15px 10px;
  color: ${({ theme }) => theme.palette.primary.green.mint};
  font-size: ${({ theme }) => theme.custom.font.size.lg};
`

export const UnitName = styled('div')`
  padding-left: 10px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
`

export const ListItem = styled(MaterialListItem)`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  padding-left: 10px;
  .chatWithMe span {
    color: ${({ theme }) => theme.palette.primary.green.mint};
  }
`

export const ResidentName = styled('div')`
  text-transform: uppercase;
`

export const AvatarInput = styled('input')`
  display: none;
`

export const PropertyCodeTitle = styled('div')`
  color: ${({ theme }) => theme.palette.text.primary};
  padding-bottom: 1.5625em;
  padding-left: 0.625em;
`

export const PropertyCode = styled('div')`
  color: ${({ theme }) => theme.palette.text.secondary};
  padding-left: 0.625em;
`

export const PropertyCodeTextField = styled(MaterialTextField)`
  width: 85%;
  padding: 0em 0.7em;
  border: 1px solid ${({ theme }) => theme.palette.common.black};
  border-radius: 1em;
  font-size: ${({ theme }) => theme.custom.font.size.md};

  input {
    flex: 11;
  }

  .cancel-edit:hover {
    cursor: pointer;
  }
`

export const ActionLink = styled('div')`
  border: 1px solid ${({ theme }) => theme.palette.primary.green.mint};
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  text-align: center;
  line-height: 1em;
  vertical-align: top;
  transform: rotate(45deg);

  &:hover {
    cursor: pointer;
  }
`

export const CreateLink = styled('div')`
  border: 1px solid ${({ theme }) => theme.palette.primary.green.mint};
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  text-align: center;
  line-height: 1em;
  vertical-align: top;

  &:hover {
    cursor: pointer;
  }
`

export const DeleteLink = styled('div')`
  border: 1px solid ${({ theme }) => theme.palette.error.main};
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  text-align: center;
  line-height: 1em;
  vertical-align: top;

  &:hover {
    cursor: pointer;
  }
`

export const GreyText = styled('span')`
  color: ${({ theme }) => theme.palette.text.light};
  width: 100%;
  text-align: center;
`
export const Fab = styled(FabButton)`
  margin-right: 1.56em;
  position: absolute;
  margin-top: -0.948em;
  box-shadow: none;
`

export const FabButtonWrap = styled('div')`
  display: flex;
  justify-content: flex-end;
  height: 3.5em;
  position: relative;
`

export const StaffTitle = styled('div')`
  padding-left: 10px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
`

export const StaffRole = styled('div')`
  padding-left: 10px;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.primary.main};
`

export const NotActiveMessage = styled('div')`
  padding-left: 10px;
  color: ${({ theme }) => theme.palette.text.primary};
`

export const ActivationLink = styled('span')`
  color: ${({ theme }) => theme.palette.primary.green.mint};
  cursor: pointer;
`

export const UserProfileTabRoutesWrapper = styled(GridItem)`
  position: relative;
  min-height: 100px;
`
