// @flow

import get from 'lodash/get'
import every from 'lodash/every'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import sortBy from 'lodash/sortBy'
import { getPaginationData } from '../../helpers/selectors/data'
import { capitalizeFirstLetter } from '../../../utils/string'

const emptyArray: Array<any> = []

export const getVehicles = (state: Object, filter: string) => {
  let vehicles = get(state, ['registration', 'vehicles', 'data']) || []
  if (filter === 'need-approval') {
    vehicles = vehicles.filter(vehicle => vehicle.status === 'pending')
  }
  return vehicles
    ? vehicles.map(vehicle => ({
        id: vehicle.id,
        plateNumber: vehicle.plate_number,
        type: vehicle.type,
        owner: `${vehicle.resident_first_name} ${vehicle.resident_last_name}`,
        unit: vehicle.unit_name,
        make: vehicle.make,
        model: vehicle.model,
        color: vehicle.color,
        state: vehicle.state,
        year: vehicle.year
      }))
    : null
}

export const getVehicle = (state: Object) => {
  const vehicle = get(state, ['registration', 'vehicle']) || {}
  const owner = [vehicle.resident_first_name, vehicle.resident_last_name]
    .filter(x => x != null)
    .join(' ')
  return {
    id: vehicle.id,
    plateNumber: vehicle.plate_number,
    type: vehicle.type,
    owner: `${vehicle.resident_first_name} ${vehicle.resident_last_name}`,
    unit: vehicle.unit_name,
    make: vehicle.make,
    model: vehicle.model,
    color: vehicle.color,
    state: vehicle.state,
    year: vehicle.year,
    parkingPermit: vehicle.permit_number,
    submittedDate: vehicle.inserted_at,
    photo: get(vehicle, ['photos', 'main'])
  }
}

export const vehicleForApi = (vehicle: Object) => {
  vehicle = {
    id: vehicle.id,
    plate_number: vehicle.plateNumber,
    unit_id: vehicle.unitId,
    unit_name: vehicle.unit,
    resident_user_id: vehicle.ownerId,
    type: vehicle.type,
    state: vehicle.state,
    make: vehicle.make,
    model: vehicle.model,
    year: vehicle.year,
    color: vehicle.color,
    permit_number: vehicle.parkingPermit,
    ...(vehicle.photo instanceof File ? { files: { 'main': vehicle.photo }} : {})
  }
  return omitBy(vehicle, isNil)
}

const getVehicleSupported = (state: Object, key: string): Array<Object> => {
  const data = get(state, [
    'registration',
    `vehicles${capitalizeFirstLetter(key)}`,
    key
  ])
  if (data == null) return emptyArray
  return Object.entries(data).map(([id, name]) => {
    return { id, name }
  })
}

export const getVehiclesColors = (state: Object) => {
  const color = { id: 'color', name: 'COLOR' }
  const vehicleColors = getVehicleSupported(state, 'colors')

  return [color, ...vehicleColors]
}

export const getValidColors = (state: Object): Array<Object> => {
  const vehicleColors = get(state, ['registration', `vehiclesColors`, 'colors'])

  return vehicleColors ? Object.keys(vehicleColors) : emptyArray
}

export const getVehiclesSizes = (state: Object) => getVehicleSupported(state, 'sizes')

export const getVehiclesStates = (state: Object) => {
  const stateHeader = { id: 'state', name: 'STATE' }
  const states = sortBy(getVehicleSupported(state, 'states') || {}, ['id']).map(
    ({ id, name }) => ({ id, name: name.toUpperCase() })
  )

  return [stateHeader, ...states]
}

export const getValidStates = (state: Object): Array<Object> => {
  const vehicleStates = get(state, ['registration', 'vehiclesStates', 'states'])

  return vehicleStates ? Object.keys(vehicleStates) : emptyArray
}

export const getVehiclesYears = () => {
  const year = new Date().getFullYear()
  const yearsHeader = { id: 'year', name: 'YEAR' }

  const years = Array.from(new Array(60), (v, i) => {
    return {
      id: year - i,
      name: year - i
    }
  })

  return [yearsHeader, ...years]
}

export const getValidYears = (): Array<number> => {
  const year = new Date().getFullYear()

  return Array.from(new Array(60), (v, i) => {
    return year - i
  })
}

const mapForDropdown = (data: Array<any>): Array<Object> => data.map(d => ({ id: d, name: d }))

export const getVehicleYears = () => mapForDropdown(getValidYears())

export const getVehiclesTypes = (state: Object) => getVehicleSupported(state, 'types')

export const hasVehicles = (state: Object) => {
  return get(state, ['registration', 'vehicles', 'data']) != null
}

export const hasVehicle = (state: Object) => {
  return get(state, ['registration', 'vehicle']) != null
}

export const hasVehiclesSupportedData = (state: Object) => {
  return every(
    ['colors', 'states', 'types'],
    key =>
      get(state, [
        'registration',
        `vehicles${capitalizeFirstLetter(key)}`,
        key
      ]) != null
  )
}

export const getVehiclesPaginationInfo = (state: Object) =>
  getPaginationData(state, ['registration', 'vehicles'])

export const getVehiclesMakes = (state: Object) => {
  return getVehicleSupported(state, 'makes')
}

export const getVehicleMakesForCreate = (state: Object): Array<Object> => {
  const vehiclesMakes = get(state, ['registration', 'vehiclesMakes', 'makes']) || []

  return Object.values(vehiclesMakes).map(make => {
    return {label: make}
  })
}
