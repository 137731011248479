// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import { withRouter } from 'react-router-dom'
import { GridItem } from '../../Common/Grid'
import PaginatedTable from '../../Common/PaginatedTable'
import Loader from '../../Common/Loader'
import CardButton from '@Common/Cards/CardButton'
import { TableContainer, StyledColumn, StyledRow } from '../styles'

type Props = {
  theme: any,
  data: Object[],
  count: number,
  propertyId: number,
  residents: Object[],
  searchTerm: string,
  isFetching: boolean,
  sendLockCode: (id: string) => {},
  fetchNextUsers: (page: number, searchTerm: string) => {}
}

type State = {
  data: Object[],
  currentPage: number
}

const rowsPerPage = 8

class Residents extends React.Component<Props, State> {
  state = {
    data: [],
    currentPage: 0
  }

  componentDidUpdate(prevProps) {
    if (prevProps.residents !== this.props.residents) {
      const { residents } = this.props
      this.setState({
        data: residents
      })
    }

    if (
      prevProps.searchTerm !== this.props.searchTerm &&
      prevProps.residents !== this.props.residents
    ) {
      this.setState({
        currentPage: 0
      })
    }
  }

  createRows = () => {
    const { data } = this.state
    const { theme } = this.props
    let rows = []

    if (data) {
      rows = data.map((rowData, rowIndex) =>
        Object.entries(rowData).map(([key, value], index) => {
          return {
            value: (
              <StyledRow
                firstRow={rowIndex === 0}
                index={index + 1}
                lockCodesMain={index === 2}>
                {index === 2 ? (
                  <CardButton
                    text={'RESEND'}
                    width={'80px'}
                    background={theme.palette.blue.main}
                    onClick={() => this.props.sendLockCode(rowData['id'])}
                  />
                ) : (
                  index !== 2 && index < 3 && value
                )}
              </StyledRow>
            )
          }
        })
      )
    }
    return rows
  }

  changePage = page => {
    const { searchTerm } = this.props
    this.setState({ currentPage: page - 1 })
    this.props.fetchNextUsers(page, searchTerm)
  }

  render() {
    const { isFetching, count } = this.props
    const { currentPage } = this.state

    return (
      <React.Fragment>
        <GridItem columnStart={3} columnSpan={10} rowStart={3} rowSpan={10}>
          <TableContainer>
            {isFetching ? (
              <Loader />
            ) : (
              <PaginatedTable
                total={count}
                flex={[2, 1, 1]}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                columns={[
                  {
                    value: <StyledColumn index={1}>RESIDENTS</StyledColumn>
                  },
                  {
                    value: <StyledColumn index={2}>UNIT</StyledColumn>
                  },
                  {
                    value: <StyledColumn index={3}>CODE</StyledColumn>
                  }
                ]}
                rows={this.createRows()}
                changePage={this.changePage}
              />
            )}
          </TableContainer>
        </GridItem>
      </React.Fragment>
    )
  }
}

export default withRouter(withTheme(Residents))
