import styled from '@emotion/styled'

export const FooterLink = styled('span')`
  a {
    color: ${({ theme }) => theme.palette.primary.green.seeFoam};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.palette.secondary.yellow.golden};
    }
  }
  font-size: ${({ theme }) => theme.custom.font.size.sm};

  @media only screen and (min-width: 2000px) {
    font-size: ${({ theme }) => theme.custom.font.size.md};
  }
  @media only screen and (min-width: 3000px) {
    font-size: ${({ theme }) => theme.custom.font.size.lg};
  }

  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  margin-left: auto;

  &: hover {
    cursor: pointer;
  }
`
