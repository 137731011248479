// @flow

export const getReplacementOptions = (state: Object) => {
  const replacementOptions = state.groupMessaging.replacementOptions
  return replacementOptions
    ? replacementOptions.map(ro => ({
        name: ro.name,
        replaceMatch: ro.replace_match
      }))
    : null
}

export const hasReplacementOptions = (state: Object) => {
  return state.groupMessaging.replacementOptions !== null
}
