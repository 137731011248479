// @flow

import React, { Fragment } from 'react'
import Btn from '@Common/Button'
import { BtnText } from './styles'

import type { handleNextStepFunc, handleSocialSignup } from './types'

type Props = {
  handleNextStep: handleNextStepFunc,
  handleSocialSignup: handleSocialSignup
}

const InitialScreen = ({ handleNextStep, handleSocialSignup }: Props) => (
  <Fragment>
    <Btn primary onClick={handleNextStep} disableRipple fullWidth secondary>
      <BtnText>Signup with Email</BtnText>
    </Btn>
  </Fragment>
)

export default InitialScreen
