// @flow

import { connect } from 'react-redux'
import moment from 'moment'
import queryString from 'query-string'
import Packages from './Packages'
import withFetchData from 'zego-shared/views/withFetchData'
import {
  fetchPackages,
  searchPackages,
  updatePackageStatus,
  updatePackageReminder
} from 'zego-shared/store/packages/actions'
import {
  getPackageData,
  getPackageReminder,
  getPackagesPaginationInfo
} from 'zego-shared/store/packages/selectors'
import { fetchUnits } from 'zego-shared/store/select/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { fetchAllSettings } from 'zego-shared/store/settings/actions'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import { getPackagesEnabled } from 'zego-shared/store/settings/propertySettings/selectors'

const fetchingSelector = createIsFetchingSelector([
  'packages',
  'units',
  'searchpackages'
])

const mapStateToProps = (state, { location: { search } }) => {
  const filter = queryString.parse(search).filter
  return {
    isReminderPage: filter === 'reminders',
    packages: getPackageData(state),
    isFetching: fetchingSelector(state),
    filter,
    packagesReminder: getPackageReminder(state),
    isPackagesEnabled: getPackagesEnabled(state),
    ...getPackagesPaginationInfo(state)
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      url,
      params: { propertyId }
    },
    history,
    location
  }
) => {
  const query = queryString.parse(location.search)
  const filter = query.filter || 'all'
  let currentFilter = filter

  const excludedFilters = ['reminders', 'all']

  if (excludedFilters.includes(filter)) currentFilter = null

  const isReminderPage = filter === 'reminders'

  return {
    fetchData: () => {
      dispatch(fetchPackages(propertyId, currentFilter))
      dispatch(fetchUnits())
      isReminderPage && dispatch(fetchAllSettings(propertyId))
    },
    componentDidUpdate: props => {
      if (props.location !== location) {
        dispatch(fetchPackages(propertyId, currentFilter))
        dispatch(fetchUnits())
        isReminderPage && dispatch(fetchAllSettings(propertyId))
      }
    },
    updatePackageStatus: (status, packageId) => {
      dispatch(updatePackageStatus(packageId, status))
    },
    updatePackageReminder: momentDate => {
      const momentUtc = moment.utc(momentDate).local()
      dispatch(
        updatePackageReminder(propertyId, {
          packagesReminder: `${momentUtc.minute()} ${momentUtc.hour()} * * * *`
        })
      )
    },
    fetchPackages: (term, sortBy, page) => {
      if (term) {
        dispatch(searchPackages(propertyId, term, currentFilter))
      } else {
        dispatch(fetchPackages(propertyId, currentFilter, sortBy, page))
      }
    },
    showError: message => {
      dispatch(showSnackbar(message, 'error'))
    }
  }
}

const PackagesWithFetchData = withFetchData(Packages)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackagesWithFetchData)
