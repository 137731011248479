// @flow
import React, { Fragment } from 'react'
import { FabButton } from '@Common/Button'
import { CommonIcon } from '@icons'
import CustomTextField from '../../../Common/TextField'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import CloseIcon from '@material-ui/icons/Close'
import { ButtonSection, TitleBlock } from './styles'
import type { Theme } from '../../../../theme'

type Props = {
  title: string,
  theme: Theme,
  action: string,
  handleTitleChange: (
    title: string,
    event: SyntheticInputEvent<HTMLInputElement>
  ) => void,
  goBack: () => void,
  savePostOrEventChanges: () => Promise<void>,
  openDialog: () => void
}

const TitleSection = ({
  title,
  theme,
  action,
  handleTitleChange,
  goBack,
  savePostOrEventChanges,
  openDialog
}: Props) => (
  <TitleBlock columnStart={6} columnSpan={7} rowStart={1} rowSpan={6}>
    <CustomTextField
      editMode={true}
      value={title}
      alwaysShowLabel
      onChange={handleTitleChange.bind(this, 'title')}
      label="title"
      textAlign="left"
      style={{
        gridColumn: '1/span 5',
        gridRow: '2/span 2',
        fontSize: theme.custom.font.size.lg,
        width: '90%'
      }}
    />
    <ButtonSection columnStart={6} columnSpan={1} rowStart={1} rowSpan={3}>
      <Fragment>
        <FabButton
          onClick={goBack}
          component={() => (
            <KeyboardBackspace
              height="0.5em"
              width="0.5em"
              style={{ color: theme.palette.primary.dark }}
            />
          )}
          size="2.7em"
          background={theme.palette.secondary.yellow.solarFlare}
        />
        <br />
        <FabButton
          onClick={savePostOrEventChanges}
          component={() => (
            <CommonIcon
              name="tick"
              width="1em"
              height="1em"
              stroke={theme.palette.primary.navy.midnight}
              strokeWidth={2}
            />
          )}
          outlined
          size="2.7em"
          background="transparent"
        />
        <br />
        {action === 'edit' && (
          <FabButton
            onClick={openDialog}
            component={() => (
              <CloseIcon
                height="0.5em"
                width="0.5em"
                style={{ color: theme.palette.primary.navy.midnight }}
              />
            )}
            size="2.7em"
            background="transparent"
            outlined
          />
        )}
      </Fragment>
    </ButtonSection>
  </TitleBlock>
)

export default TitleSection
