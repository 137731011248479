import { normalizeArraySectionData, normalizePaginatedResponseWithHeader } from '../../../helpers/normalizer'
import {
  PROPERTIES_FETCH_SECTION,
  SELECT_PROPERTY,
  UPDATE_SELECTED_PROPERTY,
  UPDATE_PROPERTY_RENDER_READY,
  fetchPropertiesSuccess
} from '../actions'

const initialState = {
  byId: {},
  allIds: [],
  selected: undefined,
  isReady: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_PROPERTY:
      if (action.id) {
        return {
          ...state,
          selected: state.byId[action.id]
        }
      } else {
        // if there is no id, just select first one
        const firstPropertyId = state.allIds[0]
        const firstProperty = state.byId[firstPropertyId]

        return {
          ...state,
          selected: firstProperty
        }
      }
    case UPDATE_SELECTED_PROPERTY:
      const { data } = action
      const allIds = state.allIds.map(property => {
        if (property.id === data.id) {
          return data
        }
        return property
      })
      return {
        ...state,
        allIds,
        selected: data
      }
    case UPDATE_PROPERTY_RENDER_READY:
      const { isReady } = action
      return {
        ...state,
        isReady
      }
    case fetchPropertiesSuccess().type: {
        const { data, ...paginationData } = normalizePaginatedResponseWithHeader(
          action.payload
        )
        const { byId, allIds } = normalizeArraySectionData(data, PROPERTIES_FETCH_SECTION, state)
        const { byId: prevById, allIds: prevAllIds, isReady, selected } = state

        return {
          byId: { ...prevById, ...byId },
          allIds: [...prevAllIds, ...allIds],
          ...paginationData,
          isReady,
          selected
        }
      }
    default:
      return state
  }
}
