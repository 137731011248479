import React from 'react'
import queryString from 'query-string'
import { Route } from 'react-router-dom'
import AccessContainer from '../../views/Settings/AccessContainer'
import PropertyNotificationsContainer from '../../views/Settings/PropertyNotificationsContainer'
import GeneralInfoContainer from '../../views/Settings/GeneralInfoContainer'
import MyAccountContainer from '../../views/Settings/MyAccountContainer'

const SettingsSideNavRoutes = () => (
  <React.Fragment>
    <Route
      exact
      path="/properties/:propertyId/settings/access"
      component={AccessContainer}
    />
    <Route
      exact
      path="/properties/:propertyId/settings/my-account"
      render={props =>
        queryString.parse(props.location.search).type === 'notifications' ? (
          <PropertyNotificationsContainer {...props} />
        ) : (
          <MyAccountContainer {...props} />
        )
      }
    />
    <Route
      exact
      path="/properties/:propertyId/settings"
      component={GeneralInfoContainer}
    />
    <Route
      exact
      path="/properties/:propertyId/settings/general-info"
      component={GeneralInfoContainer}
    />
  </React.Fragment>
)

export default SettingsSideNavRoutes
