import styled from '@emotion/styled'
import { convertHexToRGB } from '../../../../../utils/commonUtils'

export const sliderStyle = {
  position: 'relative',
  width: '100%',
  height: '100%',
  touchAction: 'manipulation'
}

export const railStyle = {
  position: 'absolute',
  width: '100%',
  backgroundColor: 'transparent',
  height: '0.3em'
}

export const HandleText = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.primary.green.mint};
`

export const HandleThumb = styled('div')`
  position: absolute;
  margin-left: -1em;
  z-index: 2;
  cursor: pointer;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 2px 4px 0
    ${({ theme }) => convertHexToRGB(theme.palette.grey.main)};

  ${({ vertical }) =>
    vertical &&
    `-webkit-transform: rotate(90deg);
  transform: rotate(90deg);`};
`
