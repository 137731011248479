import styled from '@emotion/styled'
import MaterialTextField from '@material-ui/core/TextField'
import { convertHexToRGB } from '../../../utils/commonUtils'
import Flex from '@Common/Flex'

export const CodesDetailsEditContainer = styled('div')`
  height: 90%;
  width: ${({ isUnit }) => (isUnit ? '65%' : '87%')};
  border-top-right-radius: 2em;
  box-shadow: ${({ theme }) =>
    `0 1px 10px 0 ${convertHexToRGB(theme.palette.neutrals.warmGray)}`};
  left: 0;
  bottom: 0;
  position: absolute;
  padding: 2em;
  background: ${({ theme }) => theme.palette.common.white};
  z-index: 100;
`

export const CodeDetailsRightContainer = styled('div')`
  height: 90%;
  width: ${({ isUnit }) => (isUnit ? '37%' : '20%')};
  right: 0;
  bottom: 0;
  position: absolute;
  padding: 3em 7%;

  .unit-search {
    flex: 0.2;

    & > div {
      width: 90%;
    }

    & > div > div {
      background-color: ${({ theme }) => theme.palette.common.white};
    }
  }
`

export const Underline = styled('div')`
  width: 5em;
  height: 2em;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey.main};
  margin-left: 6em;
`

export const SearchField = styled(MaterialTextField)`
  width: 90%;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.palette.neutrals.warmGray};
  padding: 0 15px;
  margin-bottom: 1em;
  & input {
    color: ${({ theme }) => theme.palette.text.lightGrey};
    padding-top: 8px;
  }
`

export const UnitsFloatingDiv = styled('div')`
  position: absolute;
  min-width: 12em;
  color: ${({ theme }) => theme.palette.grey.main};
  width: 55%;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  height: 18em;
  overflow-x: hidden;
  padding-bottom: calc(0.8em + 1%);
  padding-top: 1em;
  margin-top: 3.5em;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: ${({ theme }) =>
    `0 1px 15px 0 ${convertHexToRGB(theme.palette.neutrals.deepGray, 0.5)}`};
  white-space: nowrap;

  & > div {
    height: 2.5em;
  }

  & > div > div:last-child {
    text-align: right;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: ${({ theme }) => theme.palette.secondary.light2};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${({ theme }) => theme.palette.secondary.light1};
    opacity: 0.5;
    height: 1em;
  }

  .hub-on {
    color: ${({ theme }) => theme.palette.primary.green.mint};
  }

  .hub-off {
    color: ${({ theme }) => theme.palette.grey.main};
  }

  & > p {
    width: 100%;
    text-align: center;
  }
`

export const CalendarWrapper = styled('div')`
  width: 250px;
  height: 358px;
  background-color: ${({ theme }) => theme.palette.common.white};
  z-index: 100;
  position: absolute;
  right: 0;
  margin-right: 23em;
`
export const NoUnitText = styled('p')`
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  line-height: 18px;
  opacity: 0.5;
  width: 241px;
  margin-top: 0;
`
export const UnitListItem = styled(Flex)`
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.green.hintMint};
  }
  cursor: pointer;
  padding: 0 calc(0.8em + 1%);
  align-items: center;
`
