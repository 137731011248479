// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import { withRouter } from 'react-router-dom'
import Tabs from '@Common/Cards/Tabs'
import { FilterContainer, TabsWrap } from './styles'
import type { FilterSectionProps } from './types'

class FilterSection extends React.Component<FilterSectionProps, {}> {
  render() {
    const { theme, tabs } = this.props
    return (
      <FilterContainer>
        <TabsWrap>
          <Tabs
            tabs={tabs}
            tabStyle={1}
            vertical
            selectColor={theme.palette.secondary.light1}
          />
        </TabsWrap>
      </FilterContainer>
    )
  }
}
export default withRouter(withTheme(FilterSection))
