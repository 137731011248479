import moment from 'moment'

export const getSubject = ({ fullName, userId }, agentsOnline = true) =>
  `${agentsOnline ? 'Chat with' : 'Offline chat request from'} ${fullName ||
    'Zego User'}${userId ? '' : ' (NOT LOGGED IN)'} on ${moment().format(
    'M/D/YYYY'
  )}`

export const waitForElement = selector => {
  return new Promise((resolve, reject) => {
    let el = document.querySelector(selector)
    if (el) {
      resolve(el)
    }

    new MutationObserver((mutationRecords, observer) => {
      Array.from(document.querySelectorAll(selector)).forEach(element => {
        resolve(element)
        observer.disconnect()
      })
    }).observe(document.documentElement, {
      childList: true,
      subtree: true
    })
  })
}

export const runOnElementChange = (selector, callback) => {
  waitForElement(selector).then(elem => {
    callback(elem)
    const el = document.querySelector(selector)
    new MutationObserver((mutationRecords, observer) => {
      callback(el)
    }).observe(el, {
      characterData: true,
      childList: true,
      subtree: false
    })
  })
}
