// @flow
import React, { Fragment } from 'react'
import type { Node } from 'react'
import { withTheme } from 'emotion-theming'

import {
  Head,
  Title,
  Underline,
  RightPanel,
  CustomCard,
  Body,
  Footer,
  FooterNote,
  CustomCardContent
} from './styles'

type Props = {
  title: string,
  underlineLength: string,
  underlineMargin: string,
  thick: boolean,
  titlesize: string,
  underlineHeight: string,
  imageSrc: string,
  backgroundposition: string,
  backgroundsize: string,
  showShadow: boolean,
  toggleFooterOnMouseOver: boolean,
  columnStart: number,
  columnSpan: number,
  rowStart: number,
  rowSpan: number,
  type: ?string,
  footerNote?: Node,
  header?: () => Node,
  headerRight?: () => Node,
  content?: () => Node,
  footer?: () => Node,
  headerMarginBottom?: number,
  isCommunicationHome?: boolean,
  overflow?: String,
  onMouseLeave: (event: SyntheticEvent<HTMLButtonElement>) => void,
  onMouseEnter: (event: SyntheticEvent<HTMLButtonElement>) => void,
  theme: any,
  transparentBackground: boolean,
  noBorderRadius?: boolean,
  maxWidth?: string,
  isLeftSideBG?: String,
  paddingTop?: string,
  paddingBottom?: string,
  style: Object
}

type State = {
  showFooter: boolean
}

class BaseCard extends React.Component<Props, State> {
  static defaultProps = {
    toggleFooterOnMouseOver: false,
    imageSrc: null,
    onMouseLeave: () => {},
    onMouseEnter: () => {},
    type: null
  }

  state = {
    showFooter: this.props.toggleFooterOnMouseOver ? false : true
  }

  showFooter = () => {
    this.setState({ showFooter: true })
  }

  hideFooter = () => {
    this.setState({ showFooter: false })
  }

  render() {
    const {
      title,
      headerRight,
      content,
      footer,
      footerNote,
      imageSrc,
      columnStart,
      columnSpan,
      rowStart,
      rowSpan,
      onMouseEnter,
      onMouseLeave,
      toggleFooterOnMouseOver,
      type,
      header,
      headerMarginBottom,
      isCommunicationHome = false,
      noBorderRadius = false,
      maxWidth,
      overflow,
      underlineLength,
      underlineHeight,
      underlineMargin,
      thick,
      theme,
      showShadow = true,
      titlesize,
      isLeftSideBG,
      paddingTop,
      paddingBottom,
      backgroundposition,
      backgroundsize,
      transparentBackground,
      style
    } = this.props

    return (
      <CustomCard
        transparentBackground={transparentBackground}
        isCommunicationHome={isCommunicationHome}
        noBorderRadius={noBorderRadius}
        maxWidth={maxWidth}
        overflow={overflow}
        showShadow={showShadow}
        type={type}
        isLeftSideBG={isLeftSideBG}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        columnStart={columnStart}
        columnSpan={columnSpan}
        rowStart={rowStart}
        rowSpan={rowSpan}
        onMouseEnter={toggleFooterOnMouseOver ? this.showFooter : onMouseEnter}
        onMouseLeave={toggleFooterOnMouseOver ? this.hideFooter : onMouseLeave}
        style={style}>
        <CustomCardContent
          imageSrc={imageSrc}
          backgroundposition={backgroundposition}
          backgroundsize={backgroundsize}>
          {
            <Fragment>
              <Head headerMarginBottom={headerMarginBottom}>
                {header ? (
                  header()
                ) : (
                  <React.Fragment>
                    <Title
                      titlesize={titlesize}
                      style={{ color: theme.palette.common.black }}>
                      {title}
                    </Title>
                    {headerRight && <RightPanel>{headerRight()}</RightPanel>}
                  </React.Fragment>
                )}
              </Head>
              {title && (
                <Underline
                  length={underlineLength}
                  height={underlineHeight}
                  marginBottom={underlineMargin}
                  thick={thick}
                />
              )}
            </Fragment>
          }
          {content && <Body>{content()}</Body>}
          {footer && (
            <Footer>
              {footerNote && <FooterNote>{footerNote}</FooterNote>}
              {this.state.showFooter && footer()}
            </Footer>
          )}
        </CustomCardContent>
      </CustomCard>
    )
  }
}

export default withTheme(BaseCard)
