import { createFetchActions } from '../../helpers/actions/creators'

export const GROUP_MESSAGE = 'GROUPMESSAGE'
export const GROUP_MESSAGE_USERS_SUMMARY = 'GROUP_MESSAGE_USERS_SUMMARY'
export const DELETE_GROUP_MESSAGE = 'DELETEGROUPMESSAGE'
export const SEND_GROUP_MESSAGE_SUCCESS = 'SENDGROUPMESSAGESUCCESS'
export const SEND_GROUP_MESSAGE = 'SENDGROUPMESSAGE'
export const CONTINUE_SEND_GROUP_MESSAGE = 'CONTINUESENDGROUPMESSAGE'
export const RESET_STATUSES = 'RESETSTATUSES'
export const SAVE_AS_TEMPLATE_FAILED = 'SAVEASTEMPLATEFAILED'

export const {
  fetchAction: fetchSingleMessageAction,
  fetchStartAction: fetchSingleMessageStart,
  fetchSuccessAction: fetchSingleMessageSuccess,
  fetchErrorAction: fetchSingleMessageError
} = createFetchActions(GROUP_MESSAGE)

export const fetchSingleMessage = (groupMessageId, status, runTime) =>
  fetchSingleMessageAction({ groupMessageId, status, runTime })

export const {
  fetchAction: fetchMessageUsersSummaryAction,
  fetchStartAction: fetchMessageUsersSummaryStart,
  fetchSuccessAction: fetchMessageUsersSummarySuccess,
  fetchErrorAction: fetchMessageUsersSummaryError
} = createFetchActions(GROUP_MESSAGE_USERS_SUMMARY)

export const fetchMessageUsersSummary = (
  groupMessageId,
  isFuture,
  time,
  page
) => fetchMessageUsersSummaryAction({ groupMessageId, isFuture, time, page })

export const searchMessageUsersSummary = (
  groupMessageId,
  searchTerm,
  queryParams = {}
) => fetchMessageUsersSummaryAction({ groupMessageId, searchTerm, queryParams })

export const deleteSingleMessageAction = (groupMessageId, history) => ({
  type: DELETE_GROUP_MESSAGE,
  groupMessageId,
  history
})

export const sendGroupMessage = payload => ({
  type: SEND_GROUP_MESSAGE,
  payload
})

export const sendGroupMessageSuccess = () => ({
  type: SEND_GROUP_MESSAGE_SUCCESS
})

export const resetStatuses = () => ({
  type: RESET_STATUSES
})

export const continueSendGroupMessage = () => ({
  type: CONTINUE_SEND_GROUP_MESSAGE
})
