import styled from '@emotion/styled'

export const CalendarWrapper = styled('div')`
  background: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : 'row'};
  z-index: 9999;
  width: ${({ width }) => (width ? width : '30em')};
  box-shadow: ${({ theme, noBoxShadow }) =>
    noBoxShadow
      ? ''
      : `0 1px 15px 0 rgba(0, 0, 0, 0.2)`};

  .datetime-picker {
    flex: ${({ flex }) => (flex ? flex : 2)};
    .calendar-days {
       {
        display: none;
      }
    }
    .time {
      display: none;
    }

    .next,
    .prev {
      background: ${({ theme }) => theme.palette.common.white};
      position: relative;
      font-size: 0px;
    }

    .next:after,
    .prev:after {
      content: ' ';
      border-radius: 90%;
      height: 0.3em;
      width: 0.3em;
      position: absolute;
      left: 48%;
      top: 50%;
      font-size: ${({ theme }) => theme.custom.font.size.sm};
      background: ${({ theme }) => theme.palette.neutrals.deepGray};
    }

    table {
      width: 100%;
      text-align: center;
    }

    th {
      font-weight: ${({ theme }) => theme.custom.font.weight.light};
      color: ${({ theme }) => theme.palette.neutrals.warmGray};
    }

    td {
      cursor: pointer;
    }

    .calendar-nav {
      display: flex;
      justify-content: space-between;

      button {
        border: none;
        outline: none;
        cursor: pointer;
        font-size: ${({ theme }) => theme.custom.font.size.mdlg};
        color: ${({ theme }) => theme.palette.neutrals.deepGray};
      }

      .next-month::before {
        content: '›';
      }

      .prev-month::before {
        content: '‹';
      }

      i {
        display: none;
      }
    }

    .selected {
      font-weight: ${({ theme }) => theme.custom.font.weight.bold};
      color: ${({ theme }) => theme.palette.secondary.main1};
    }
    td:hover:not(.disabled) {
      color: ${({ theme }) => theme.palette.secondary.main1};
    }

    td.disabled:hover {
      cursor: not-allowed;
    }
  }

  & > * {
    padding: 1em;
  }
`

export const Date = styled('div')`
  flex: 2;
  font-size: ${({ theme }) => theme.custom.font.size.xxxl};
`

export const Month = styled('div')`
  flex: 1;
`

export const Day = styled('div')`
  text-transform: lowercase;
  flex: 1;
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
`

export const SelectedDate = styled('div')`
  flex: 1;
  background: ${({ theme }) => theme.palette.secondary.main1};
  text-align: ${({ isCommunityFeed }) => (isCommunityFeed ? 'left' : 'center')};
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.common.white};

  ${Date}, ${Day}, ${Month} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const MonthDay = styled('div')`
  flex: 1;
  padding-top: 0.5em;
  padding-bottom: 1em;
`

export const Year = styled('div')`
  padding-top: 1em;
  padding-bottom: 0.3em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.primary.green.seeFoam};
`
