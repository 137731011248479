import { call, select, takeLatest, put } from 'redux-saga/effects'
import {
  fetchMimoWorkOrderSubmitAction,
  fetchMimoWorkOrderSubmitStart,
  fetchMimoWorkOrderSubmitError,
  fetchMimoWorkOrderSubmitSuccess,
  fetchIntegratedWorkOrderSubmitAction,
  fetchIntegratedWorkOrderSubmitStart,
  fetchIntegratedWorkOrderSubmitError,
  fetchIntegratedWorkOrderSubmitSuccess
} from './actions'
import { fetchMimoChecklistSummaryAction } from '../moveInMoveOut/actions'
import { getPropertyId } from '../select/selectors'
import { getToken } from '../authorization/selectors'
import { getPdfBlob } from '../../../admin/assets/src/views/UserManagement/UserProfile/MimoChecklistSummary/MimoChecklistSumaryPdf/MimoChecklistSummaryPdf'

import api from '../../utils/api'
import log from '../../utils/logger'

export function* fetchMimoWorkOrderSubmit({ checklistSummary, pmId, profileComplete, disclosure, signature }) {
  try {
    yield put(fetchMimoWorkOrderSubmitStart())
    const authToken = yield select(getToken)
    const residentCheckListId = checklistSummary[0].residentCheckListId
    if (signature) { yield call(api.submitSignature, authToken, residentCheckListId, signature, pmId) }
    const tempReq = Object.keys(checklistSummary)
      .filter(room => checklistSummary[room] && checklistSummary[room].residentRoomCheckListInfo && checklistSummary[room].residentRoomCheckListInfo.length)
      .map(room => ({
        categoryId: checklistSummary[room].categoryId,
        roomName: checklistSummary[room].roomName,
        attributeInfo: checklistSummary[room].residentRoomCheckListInfo.map(({ attributeId }) => ({ attributeId }))
      }))
    const response = yield call(api.getMimoWorkOrderSubmit, authToken, residentCheckListId, tempReq)
    const requestTemplate = {
      unitId: response.residentCheckListInfo.unitId,
      propertyId: response.residentCheckListInfo.propertyId,
      userId: response.residentCheckListInfo.residentId,
      assignee: null,
      photos: [],
      pets: (response.residentStandAloneWOQuestionsInfo && response.residentStandAloneWOQuestionsInfo.hasPets === "Yes") || false,
      accessInstructions: (response.residentStandAloneWOQuestionsInfo && response.residentStandAloneWOQuestionsInfo.accessInstructions) || '',
      permissionToEnter: (response.residentStandAloneWOQuestionsInfo && response.residentStandAloneWOQuestionsInfo.permissionsToEnter === "Yes") || false,
      petNotes: (response.residentStandAloneWOQuestionsInfo && response.residentStandAloneWOQuestionsInfo.petNotes) || ''
    }
    let workOrders = []
    for (let x = 0; x < Object.keys(checklistSummary).length; x++) {
      const room = checklistSummary[x];
      if (room && room.residentRoomCheckListInfo && room.residentRoomCheckListInfo.length) {
        let attributeInfo = room.residentRoomCheckListInfo.map(({ comments, attributeId, id }) => {
          let categoryIndex = response.categoryCheckListAttributeDetails.findIndex(cat => cat.categoryId === room.categoryId && cat.roomName == room.roomName)
          let attDets = response.categoryCheckListAttributeDetails[categoryIndex].checkListAttributeInfo.find(att => att.id === attributeId)
          return {
            category: attDets.standAloneWOCagegory,
            subCategory: attDets.standAloneWOSubCagegory,
            description: comments,
            residentRoomCheckListId: id,
            ...requestTemplate
          }
        })
        workOrders.push(...attributeInfo)
      }
    }
    let workOrderResponses = []
    for (let x = 0; x < workOrders.length; x++) {
      const workOrder = workOrders[x];
      const woResponse = yield call(api.mimoSubmitWorkOrder, workOrder, authToken)
      workOrderResponses.push({
        residentRoomCheckListId: workOrder.residentRoomCheckListId,
        workOrderNumber: woResponse.workOrderHistory[0].workOrderNumber
      })
    }
    yield call(api.mimoWorkOrderUpdate, workOrderResponses, residentCheckListId, pmId, authToken)
    const checklistResponse = yield call(api.getChecklistSummary, authToken, response.residentCheckListInfo.propertyId, requestTemplate.userId, requestTemplate.unitId)
    const pmUser = yield call(api.getUser, pmId, authToken)
    checklistResponse.residentCheckListInfo.pmUser = pmUser
    let pdf = yield getPdfBlob({ checklistSummary: checklistResponse.residentCheckListTemplateData, checklistinfo: checklistResponse.residentCheckListInfo, profileComplete, disclosure, signature })
    yield call(api.submitChecklistPdf, {
      residentChecklistId: residentCheckListId,
      file: pdf
    }, authToken)
    yield put(fetchMimoWorkOrderSubmitSuccess())
    yield put(fetchMimoChecklistSummaryAction({ residentId: requestTemplate.userId, unitId: requestTemplate.unitId }))
  } catch (error) {
    yield put(fetchMimoWorkOrderSubmitError(error))
    log(error)
  }
}

export function* fetchIntegratedWorkOrderSubmit({ checklistSummary, residentId, unitId, pmId, profileComplete, disclosure, signature }) {
  try {
    const propertyID = yield select(getPropertyId)
    yield put(fetchIntegratedWorkOrderSubmitStart())
    const authToken = yield select(getToken)
    const residentCheckListId = checklistSummary[0].residentCheckListId
    if (signature) { yield call(api.submitSignature, authToken, residentCheckListId, signature, pmId) }
    const integratedWoQuestions = yield call(api.mimoIntegratedQuestions, residentCheckListId, authToken)
    let workOrderResponses = []
    const filteredChecklistSummary = Object.values(checklistSummary).filter(room => room && room.residentRoomCheckListInfo && room.residentRoomCheckListInfo.length)
    for (let x = 0; x < filteredChecklistSummary.length; x++) {
      const room = filteredChecklistSummary[x];
      for (let y = 0; y < room.residentRoomCheckListInfo.length; y++) {
        const att = room.residentRoomCheckListInfo[y];
        let formattedWO = {
          message: att.comments,
          user_id: residentId,
          pms_options: {
            0: {
              name: 'pms_category_id',
              id: att.attributeName
            }
          }
        }
        if (integratedWoQuestions.length > 1) {
          formattedWO.pms_options[1] = {
            name: 'pms_access_id',
            id: integratedWoQuestions[1].selectedAttributeId
          }
        }
        const woRes = yield call(api.submitIntegratedWo, formattedWO, unitId, authToken)
        workOrderResponses.push({
          residentRoomCheckListId: att.id,
          workOrderNumber: woRes.id
        })
      }
    }
    yield call(api.mimoWorkOrderUpdate, workOrderResponses, residentCheckListId, pmId, authToken)
    const checklistResponse = yield call(api.getChecklistSummary, authToken, propertyID, residentId, unitId)
    const pmUser = yield call(api.getUser, pmId, authToken)
    checklistResponse.residentCheckListInfo.pmUser = pmUser
    let pdf = yield getPdfBlob({ checklistSummary: checklistResponse.residentCheckListTemplateData, checklistinfo: checklistResponse.residentCheckListInfo, profileComplete, disclosure })
    yield call(api.submitChecklistPdf, {
      residentChecklistId: residentCheckListId,
      file: pdf
    }, authToken)
    yield put(fetchMimoWorkOrderSubmitSuccess())
    yield put(fetchMimoChecklistSummaryAction({ residentId: residentId, unitId: unitId }))
  } catch (error) {
    yield put(fetchIntegratedWorkOrderSubmitError(error))
    log(error)
  }
}

function* watchfetchMimoWorkOrderSubmit() {
  yield takeLatest(fetchMimoWorkOrderSubmitAction().type, fetchMimoWorkOrderSubmit)
}
function* watchfetchIntegratedWorkOrderSubmit() {
  yield takeLatest(fetchIntegratedWorkOrderSubmitAction().type, fetchIntegratedWorkOrderSubmit)
}

export default [
  watchfetchMimoWorkOrderSubmit(),
  watchfetchIntegratedWorkOrderSubmit()
]
