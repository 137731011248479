import styled from '@emotion/styled'
import MaterialTextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import MuiSelect from '@material-ui/core/Select'

import { convertHexToRGB } from '../../../../utils'

const contentWidth = '65%'
const messageContentHeight = '7em'

export const HeadBackground = styled('div')`
  background: ${({ theme, editMode }) =>
    editMode ? theme.palette.common.white : theme.palette.neutrals.lightGray};
  height: 19em;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 71px;

  ${({ editMode, theme }) =>
    editMode &&
    `
    border-top: 0.1px solid ${theme.palette.neutrals.mediumGray};
    box-shadow: 0 1px 10px 0 ${convertHexToRGB(
      theme.palette.neutrals.warmGray
    )};
  `};

  & > div {
    display: flex;
    padding-top: 5em;
  }

  & > div > div:first-child {
    flex: 8;
    display: flex;
    flex-direction: column;

    & > *:last-child {
      flex: 2;
    }
  }
`

export const Body = styled('div')`
  margin-top: 4em;
  padding-bottom: 4em;
  }
`

export const Underline = styled('div')`
  content: ' ';
  height: 1px;
  width: 82% !important;
  position: absolute;
  left: 0;
  background-color: ${({ theme }) => theme.palette.neutrals.coolGray};
`
export const FootContainer = styled('div')`
  color: #59C09B;
  padding-bottom: 2em;
`
export const Wrapper = styled('div')`
    padding-left: 2em;
    .recipient-row {
      & > * {
        align-self:center;
      }
    }

    .row-head {
      & > *:first-child > span {
        font-size: 190%;
      }
      & > *:not(*:first-child) {
        color: ${({ theme }) => theme.palette.secondary.main1};
      }
    }

    .searchIcon {
      margin-top: 0.5em;
    }

    .wrapper-flex {
      width: 100%;
      position: absolute;
      left: 0;
      margin-bottom: 4em;
    }

    ${Body} > *, ${HeadBackground} > * {
        width: ${contentWidth};
        margin: 0 auto;
    }
`

export const Name = styled('div')`
  color: ${({ theme }) => theme.palette.secondary.light2};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`

export const Title = styled('div')`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: ${({ theme }) => theme.custom.font.size.xxl};
`

export const MessageDetails = styled('div')`
  color: ${({ theme }) => theme.palette.secondary.light2};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 1.5em;

  & > * {
    margin-left: 3em;
  }
`

export const Message = styled('div')`
  line-height: 1.5em;
`

export const TextField = styled(MaterialTextField)`
  width: 90%;
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.palette.neutrals.warmGray};
  padding: 0 15px;
  & input {
    color: ${({ theme }) => theme.palette.text.lightGrey};
    padding-top: 8px;
  }
`

export const Break = styled('div')`
  height: 3em;
`

export const FabButton = styled(Button)`
  background-color: ${({ theme, outlined }) =>
    outlined
      ? theme.palette.common.white
      : theme.palette.secondary.yellow.solarFlare};
  color: ${({ theme }) => theme.palette.primary.dark};
  min-width: 3em;
  min-height: 3em;
  width: 3em;
  height: 3em;
  z-index: 100;
  border-radius: 50%;
  border: ${({ theme, outlined }) =>
    outlined && `3px solid ${theme.palette.secondary.yellow.solarFlare}`};

  &:hover {
    background: ${({ theme, outlined }) =>
      outlined
        ? theme.palette.common.white
        : theme.palette.secondary.yellow.solarFlare};

    border: ${({ theme, outlined }) =>
      outlined && `3px solid ${theme.palette.secondary.yellow.golden}`};
  }

  svg {
    fill: ${({ theme }) => theme.palette.common.white};
  }
`

export const ButtonSet = styled('div')`
  display: flex;
  margin-right: -12em;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding-left: 8em;
  padding-bottom: 2em;
  margin-top: -1.5em;
`

export const EditModeContainer = styled('div')`
  .edit-mode-name {
    width: 100%;
    input {
      font-size: ${({ theme }) => theme.custom.font.size.xl};
    }
  }

  .radioGroupRoot {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
  }

  .edit-mode-title {
    width: 100%;
    input {
      font-size: ${({ theme }) => theme.custom.font.size.lg};
    }
  }
`

export const Content = styled('div')`
  height: ${messageContentHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.3em;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  * {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }
  background: ${({ theme }) => theme.palette.neutrals.lightGray};
  &:before {
    content: ' ';
    left: 0;
    position: absolute;
    width: 100%;
    height: ${messageContentHeight};
    background: ${({ theme }) => theme.palette.neutrals.lightGray};
    box-shadow: ${({ theme }) =>
      `inset 1px -29px 16px -33px ${convertHexToRGB(
        theme.palette.neutrals.warmGray
      )}`};
  }
  .radioGroupEditMode {
    flex-direction: row;
  }

  .radioGroupEditModeContent {
    display: block;
    height: 100%;
    position: relative;

    span {
      height: 45%;
    }
  }

  .template-dropdown {
    border-radius: 3em;
    display: flex;
    border: 1px solid ${({ theme }) => theme.palette.neutrals.deepGray};
    padding: 0em 1em;

    & svg {
      position: absolute;
      right: 1em;
      top: 0.5em;
    }
  }
`

export const MessageEditorContainer = styled('div')`
  padding-top: 1em;
  & > div {
    width: 100%;
  }
  textarea {
    margin-top: 1em;
  }
`

export const MessageBody = styled('div')`
  white-space: pre-line;
`
export const Link = styled('a')`
  color: ${({ theme }) => theme.palette.text.primary};
`

export const Select = styled(MuiSelect)`
  display: none;
`

export const MessageTypeText = styled('div')`
  padding-top: 1em;
  padding-bottom: 1em;
`

export const AttachFile = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & button {
    min-height: 30px;
    width: 310px;
    text-transform: uppercase;
  }

  & p {
    width: 327px;
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    color: ${({ theme }) => theme.palette.text.light};
    line-height: 16px;
  }

  input[type='file'] {
    position: absolute;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }
`
