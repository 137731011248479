// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import { Moment } from 'moment'
import { Theme } from '../../theme'
import { get24HrTimeAmPm } from '../../utils/dateUtils'
import CommonTextField from '../Common/TextField'
import Button from '@Common/Cards/CardButton'
import { CustomTypography as Typography } from './styles'
import Flex from '@Common/Flex'

type Props = {
  moment: Moment,
  handleReminderChange: (value: string) => void,
  theme: Theme,
  setReminder: () => void
}

export const ReminderSection = ({
  moment,
  handleReminderChange,
  setReminder,
  theme
}: Props) => (
  <React.Fragment>
    <Typography gutter>Package pickup reminders</Typography>
    <Typography small gutter>
      Reminders will be sent to the resident once daily till a package is picked
      up. Below you can set the time of day you would like all reminders to go
      out. By default the reminders will be sent at 9am. If you would like the
      reminders to stop for a specific package, go to that packages status page
      and update the status from received to on hold.
    </Typography>
    <br />
    <Flex fullWidth justifySpaceBetween alignCenter>
      <CommonTextField
        showPencil={false}
        alwaysShowLabel
        textAlign="left"
        defaultValue={get24HrTimeAmPm(moment)}
        type="time"
        InputLabelProps={{
          shrink: true
        }}
        label="time"
        onChange={handleReminderChange}
        inputProps={{
          step: 300 // 5 min
        }}
      />
      <Button
        text="SET REMINDER"
        onClick={setReminder}
        background={theme.palette.blue.main}
        width="10em"
      />
    </Flex>
  </React.Fragment>
)

export default withTheme(ReminderSection)
