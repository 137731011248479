// @flow

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import intersectionWith from 'lodash/intersectionWith'
import { channel } from 'redux-saga'
import { getPropertyName } from '../../select/properties/selectors'
import { getChatEnabled } from '../../settings/propertySettings/selectors'
import { hasResidentEngagement } from '../../analytics/residentEngagement/selectors'
import { hasResidentActive } from '../../analytics/residentActive/selectors'

export const getLowBatteryCount = (state: Object) =>
  get(state, ['property', 'summary', 'battery', 'low', 'count'], 0)

export const getSmartUnitsCount = (state: Object) => {
  const selector = state.summary ? ['summary'] : ['property', 'summary']

  return get(state, [...selector, 'smart', 'count'])
}

export const getInactiveUnitsCount = (state: Object) =>
  get(state, ['property', 'summary', 'inactive', 'count'])

export const getUnitsCount = (state: Object, type: string) =>
  get(state, ['property', 'summary', type, 'count'])

export const getSummaryData = (state: Object) => ({
  lowBatteryCount: getLowBatteryCount(state),
  smartUnitsCount: getSmartUnitsCount(state),
  summary: get(state, ['summary'], {})
})

export const hasSummaryData = (state: Object) =>
  get(state, ['property', 'summary'])

export const getAllUnitsCount = (state: Object) => {
  const allUnits = ['common', 'occupied', 'unoccupied'].map(key =>
    get(state, ['summary', key, 'count'])
  )

  return allUnits.reduce(
    (accumulator, currentValue) => accumulator + currentValue
  )
}

export const getDeviceCount = (state: Object): number => {
  const devicesCount: Object = get(state, [
    'select',
    'units',
    'selected',
    'devices_count'
  ])

  const deviceCountIsNotEmpty =
    devicesCount && Object.values(devicesCount).length > 0

  if (devicesCount && deviceCountIsNotEmpty) {
    const count: Array<any> = Object.values(devicesCount)

    return (
      count.reduce(
        (accumulator: number, currentValue: number): number =>
          accumulator + currentValue
      ) - getHubCount(state)
    )
  }

  return 0
}

export const getHubCount = (state: Object): number =>
  get(state, ['select', 'units', 'selected', 'devices_count', 'gateway']) ||
  getDeviceCount(state)

export const getUnitSmartCardData = (state: Object, path: string) => {
  path = path ? path : '/'
  const deviceCount = getDeviceCount(state)
  const hubCount = getHubCount(state)
  return {
    active: hubCount !== null && deviceCount !== null,
    hubCount,
    deviceCount,
    deviceName: `device${deviceCount === 1 ? '' : 's'}`,
    hubName: `hub${hubCount === 1 ? '' : 's'}`,
    viewMoreUrl: `${path.split('/summary')[0]}/smart/devices/${get(state, [
      'select',
      'units',
      'selected',
      'devices',
      '0'
    ])}`
  }
}

export const hasUnitSummaryData = (state: Object) => {
  if (getChatEnabled(state)) {
    return (
      get(state, ['select', 'units', 'selected']) &&
      !isEmpty(state.unit.users) &&
      hasResidentActive(state) &&
      get(state, ['chat', 'userChannels'])
    )
  }
  return (
    get(state, ['select', 'units', 'selected']) &&
    !isEmpty(state.unit.users) &&
    hasResidentActive(state)
  )
}

export const hasPropertySummaryCardData = (state: Object) =>
  get(state, ['select', 'properties', 'selected']) &&
  !isEmpty(state.property.users) &&
  get(state, ['chat', 'channels'])
    ? true
    : false

export const getPropertySmartCardData = (state: Object, path: string = '/') =>
  !isEmpty(state.property.summary)
    ? {
        allUnitsCount: getAllUnitsCount(state.property),
        smartUnitsCount: getSmartUnitsCount(state.property),
        lowBatteriesCount: getLowBatteryCount(state),
        viewMoreUrl: `${path.split('/summary')[0]}/smart`
      }
    : null

export const getUnitsInfo = (state: Object, filter: string, type: string) => {
  filter = filter === 'vacant' ? 'unoccupied' : filter

  const filteredUnits = get(state, ['property', 'summary', filter, 'data'])
  const typeUnits = get(state, ['property', 'summary', type, 'data'])

  return intersectionWith(
    filteredUnits,
    typeUnits,
    (filteredUnit, typeUnit) => filteredUnit.id === typeUnit.id
  )
}

export const getUnitChannelCounts = (state: Object) => {
  const chatChannels = get(state, ['chat', 'userChannels']) || []
  let chatChannelsWithUnreadChats = chatChannels

  if (chatChannels.length) {
    chatChannelsWithUnreadChats = chatChannels.filter(
      channel => channel.unreadMessageCount > 0
    )
  }
  return {
    unreadResidentChannels: chatChannelsWithUnreadChats.length,
    totalResidentChannels: chatChannels.length
  }
}

export const getUnitSummaryCardData = (state: Object) => {
  const name = get(state, ['select', 'units', 'selected', 'name'])
  return {
    name,
    progressData: {},
    user_management: { name },
    zego_smart: { name },
    summary: { name },
    chat: { name }
  }
}

const categoryKeys = {
  chat: 'Chat',
  summary: 'Summary',
  smart: 'Zego Smart',
  'user-management': 'User Management',
  users: 'Users'
}

export const getPropertySummaryCardData = (state: Object) => {
  const counts: Object = get(state, ['property', 'batteries', 'count'], {})

  const chatChannels = get(state, ['chat', 'channels']) || []

  const unreadChatChannels = chatChannels.filter(
    channel => channel.unreadMessageCount > 0
  )

  let totalBatteriesCount: number = 0

  const countsArray: Array<any> = Object.values(counts)
  countsArray.forEach(count => {
    totalBatteriesCount += count
  })

  let recentViews = get(state, ['search', 'history'], [])

  if (recentViews.length)
    recentViews = recentViews.map(view => {
      const category = categoryKeys[Object.keys(view)[0]]
      const values: Array<any> = Object.values(view)
      const value = values[0].split(',')
      const name = value[0]
      const url = value[1]

      return {
        category,
        name,
        url
      }
    })

  const name = get(state, ['select', 'properties', 'selected', 'name'])
  return {
    name,
    progressData: {
      chat: {
        unread: unreadChatChannels.length,
        total: chatChannels.length
      },
      battery: { total: totalBatteriesCount, low: getLowBatteryCount(state) }
    },
    history: recentViews
  }
}

export const getPropertyChatCardData = (state: Object, path: string) => {
  path = path ? path : '/'
  let channels = get(state, ['chat', 'byType']) || []

  if (channels.length) {
    channels = channels.reduce((channelWithLatestMessage, channel) => {
      if (channel.lastMessage) {
        return channel.lastMessage.createdAt >
          channelWithLatestMessage.lastMessage.createdAt
          ? channel
          : channelWithLatestMessage
      } else {
        return channelWithLatestMessage
      }
    })

    channels = {
      name: channels.name,
      sender: channels.lastMessage._sender.nickname,
      message: channels.lastMessage.message,
      viewMoreUrl: `${
        path.split('/summary')[0]
      }/communication/chat/announcements/users/${
        channels.lastMessage._sender.userId
      }`
    }
  }
  return channels
}

export const hasPropertySummaryData = (state: Object) => {
  const propertyKeys = ['summary', 'users']
  return (
    !isEmpty(get(state, ['property', 'summary'])) &&
    !isEmpty(get(state, ['property', 'users'])) &&
    hasResidentEngagement(state)
    // !isEmpty(get(state, ['property', 'batteries']))
  )
}

export const getUnitChatUnreadData = (state: Object) => {
  let userChannels = get(state, ['chat', 'userChannels'])
  if (userChannels) {
    return userChannels.reduce((result, channel) => {
      return {
        ...result,
        [channel.userId]: channel.unreadMessageCount > 0
      }
    }, {})
  }
  return {}
}

export const getPropertyOccupancyData = (state: Object) => {
  const occupiedCount = get(
    state,
    ['property', 'summary', 'occupied', 'count'],
    0
  )
  const vacantCount = get(
    state,
    ['property', 'summary', 'unoccupied', 'count'],
    0
  )
  const totalCount = occupiedCount + vacantCount

  return {
    progressText:
      totalCount === 0
        ? `100%`
        : `${Math.ceil((occupiedCount / totalCount) * 100)}%`,
    value: totalCount === 0 ? 100 : (occupiedCount / totalCount) * 100,
    totalCount,
    occupiedCount,
    vacantCount
  }
}

export const hasPms = (state: Object) => {
  return get(state, ['property', 'summary', 'hasPms'], null)
}

export const getPmsName = (state: Object) => {
  return get(state, ['property', 'summary', 'pmsName']) || ''
}

export const getTimezone = (state: Object) => {
  return get(state, ['property', 'summary', 'timezone']) || 'America/Chicago'
}
