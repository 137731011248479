// @flow

import React from 'react'
import { connect } from 'react-redux'

import { ldInitRequest } from '../../../integrations/LaunchDarkly/actions'

import {
  isProAmenitySel,
} from 'zego-shared/store/amenitiesShared'

import AmenityVersionSelector from './AmenityVersionSelector'

const mapStateToProps = state => ({
  isProAmenity: isProAmenitySel(state),
})

const mapDispatchToProps = () => ({
    ldInitRequest,
})

class AmenityVersionSelectorContainer extends React.Component<any, any> {
  componentDidMount = () => {
    const { ldInitRequest } = this.props
    ldInitRequest()
  }

  render() {
    const {
      props,
    } = this
    
    return <AmenityVersionSelector {...props}/>
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AmenityVersionSelectorContainer)
