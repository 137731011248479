import styled from '@emotion/styled'

export const ListItem = styled('div')`
  min-height: 20px;
  display: flex;
  -webkit-display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: center;
`
export const List = styled('div')`
  overflow-y: hidden;
  height: ${({ itemHeight, numberOfItemsPerSlide }) =>
    itemHeight ? `${itemHeight * numberOfItemsPerSlide}px` : 'auto'};

  ${ListItem}:first-of-type {
    margin-top: ${({ itemHeight, slideNumber, numberOfItemsPerSlide }) =>
      itemHeight && slideNumber && numberOfItemsPerSlide
        ? `-${itemHeight * numberOfItemsPerSlide * (slideNumber - 1)}px`
        : '0'};
    -webkit-transition: margin-top 1s;
    transition: margin-top 1s;
  }
`

export const ScrollButtonWrap = styled('div')`
  display: flex;
  justify-content: ${({ arrowsRight }) =>
    arrowsRight ? 'flex-end' : 'flex-start'};
  align-items: flex-end;
  height: ${({ transitionHeights, isOneSlide }) =>
    transitionHeights ? transitionHeights[1] : isOneSlide ? 0 : '3em'};

  ${({ transitionHeights, topArrow, topScrollButtonVisible }) =>
    transitionHeights &&
    `
  height: ${
    topArrow && topScrollButtonVisible
      ? transitionHeights[1]
      : transitionHeights[0]
  };
  align-items: flex-start;
  `};
  transition: 0.3s height;
`

export const UnitContainer = styled('div')`
  height: ${({ height }) => (height ? height : '56px')};
  display: flex;
  -webkit-display: flex;
  -webkit-align-items: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: ${({ theme, lastUnit }) =>
    lastUnit ? `0px` : `1px solid ${theme.palette.neutrals.coolGray}`};

  * {
    font-size: ${({ theme }) => theme.custom.font.size.md};

  @media only screen and (min-width: 3000px) {
    font-size: ${({ theme }) => theme.custom.font.size.lg};
  }
  }

  a {
    color: ${({ theme }) => theme.palette.primary.green.seeFoam};
  }
`
