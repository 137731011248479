// @flow

import React from 'react'
import Flex from '@Common/Flex'
import Switch from '@material-ui/core/Switch'

type SwitchScheduleActiveFn = (
  SyntheticInputEvent<HTMLInputElement>,
  boolean
) => mixed

type ScheduleEnableProps = {
  enabled: boolean,
  switchScheduleActive: SwitchScheduleActiveFn
}

const ScheduleEnable = ({
  switchScheduleActive,
  enabled
}: ScheduleEnableProps) => (
  <Flex justifySpaceBetween alignCenter paddingLeft="10px">
    <font color="#556271">Vacant Schedule Enabled</font>
    <Switch
      disabled={false}
      onChange={switchScheduleActive}
      checked={enabled}
    />
  </Flex>
)

export default ScheduleEnable
