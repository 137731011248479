import { createFetchActions } from '../../../helpers/actions/creators'

export const PROPERTIES_FETCH_SECTION = 'PROPERTIES'
export const SELECT_PROPERTY = 'SELECT_PROPERTY'
export const UPDATE_SELECTED_PROPERTY = 'UPDATE_SELECTED_PROPERTY'
export const UPDATE_PROPERTY_RENDER_READY = 'UPDATE_PROPERTY_RENDER_READY'
export const CHANGE_PROPERTY = 'CHANGE_PROPERTY'

export const FETCH_PROPERTY_INFO = 'FETCH_PROPERTY_INFO'

export const {
  fetchAction: fetchPropertiesAction,
  fetchSuccessAction: fetchPropertiesSuccess,
  fetchErrorAction: fetchPropertiesError,
  fetchStartAction: fetchPropertiesStart
} = createFetchActions(PROPERTIES_FETCH_SECTION)

export const fetchProperties = (page = 1) => fetchPropertiesAction({ page })

export const changeProperty = id => ({
  type: CHANGE_PROPERTY,
  id
})

export const selectProperty = id => ({
  type: SELECT_PROPERTY,
  id
})

export const updateSelectedProperty = data => ({
  type: UPDATE_SELECTED_PROPERTY,
  data
})

export const updatePropertyRenderReady = isReady => ({
  type: UPDATE_PROPERTY_RENDER_READY,
  isReady
})

export const fetchPropertyInfo = (propertyId, url, history) => ({
  type: FETCH_PROPERTY_INFO,
  id: propertyId,
  url,
  history
})
