import styled from '@emotion/styled'

export const ChatWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  grid-column: 5 / span 9;
  grid-row: 1 / span 12;
  position: relative;

  .chat-window {
    height: 100%;
    display: flex;
    flex-direction: column;


   @media only screen and (min-width: 2000px) {
      font-size: 200%;
    }
    @media only screen and (min-width: 3000px) {
      font-size: 300%;
    }
  }
`
