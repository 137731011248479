import residentMoveInMoveOut from './moveInMoveOut'
import moveInCounts from './moveInCounts'
import moveInSettings from './moveInSettings'
import moveInPdf from './moveInPdf'
import moveInOptedOut from './moveInOptedOut'
import { combineReducers } from 'redux'
import moveInResetCheckList from './moveInResetCheckList'

const moveInReducer = combineReducers({
  residentMoveInMoveOut,
  moveInCounts,
  moveInSettings,
  moveInPdf,
  moveInResetCheckList,
  moveInOptedOut
})

export default moveInReducer
