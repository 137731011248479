import React from 'react'
import PrivateRoute from '../PrivateRoute'
import { SmartContainer } from '../../views/Smart'
import SetupContainer from '../../views/Setup'
import SmartSummaryContainer from '../../views/Smart/SmartSummary'
import CreateUnitContainer from '../../views/Smart/CreateUnit'

//TODO: IMPLEMENT SETUP
const getSmartRoutes = () => [
  <PrivateRoute
    key="smartSetup"
    exact
    path="/properties/:propertyId/units/:unitId/smart/setup"
    component={SetupContainer}
    hideAppContainer
  />,
  <PrivateRoute
    exact
    key="unitSmartSummary"
    path={`/properties/:propertyId/smart/:activeTab?`}
    component={SmartSummaryContainer}
  />,
  <PrivateRoute
    key="smartContainer"
    path="/properties/:propertyId/units/:unitId/smart"
    component={SmartContainer}
  />,
  <PrivateRoute
    exact
    key="createUnit"
    path="/properties/:propertyId/smart/units/create"
    component={CreateUnitContainer}
  />
]

export default getSmartRoutes
