// @flow

import { connect } from 'react-redux'
import Pendo from './Pendo'
import {
  getPendoVisitorProfile,
  getPendoAccountProfile
} from '../../store/Integrations/selectors'

const mapStateToProps = state => ({
  account: getPendoAccountProfile(state),
  visitor: getPendoVisitorProfile(state),
})

export default connect(mapStateToProps)(Pendo)
