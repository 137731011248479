import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getPropertyAccessSettings } from 'zego-shared/store/settings/selectors'
import { getPropertyId, getUnitId } from 'zego-shared/store/select/selectors'
import QuickLinks from './QuickLinks'

const mapStateToProps = state => ({
  propertyID: getPropertyId(state),
  unitID: getUnitId(state),
  settings: getPropertyAccessSettings(state)
})

export default withRouter(connect(mapStateToProps)(QuickLinks))
