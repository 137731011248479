import withFetch from '../../helpers/reducers/withFetch'
import withPaginatedFetch from '../../helpers/reducers/withPaginatedFetch'
import isUpdate from '../../isUpdating/reducers'
import { updateVisitorSuccess, VISITOR, VISITORS } from './actions'

export const reducer = (state = null, action) => {
  return state
}

export const visitor = withFetch(VISITOR)((state = null, action) => {
  switch (action.type) {
    case updateVisitorSuccess().type:
      return action.payload
    default:
      return state
  }
})

export default withPaginatedFetch(VISITORS)(reducer)
