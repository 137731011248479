// @flow

import React, { Fragment } from 'react'
import Switch from '@material-ui/core/Switch'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import CarouselList from '@Common/CarouselList'
import withScrollBehaviour from '@Common/CarouselList/withScrollBehaviour'
import { ListItem } from './styles'

type CarouselItemProps = {
  field: string,
  title: string,
  handleToggle: (SyntheticEvent<HTMLInputElement>, boolean) => mixed,
  checked: mixed,
  lastItem: boolean,
  disabled?: boolean
}

export const CarouselItem = ({
  field,
  title,
  handleToggle,
  checked,
  lastItem,
  disabled
}: CarouselItemProps) => (
  <Fragment>
    <ListItem>
      <ListItemText primary={title} />
      <ListItemSecondaryAction>
        <Switch
          disabled={disabled}
          onChange={handleToggle}
          checked={checked}
          id={field}
        />
      </ListItemSecondaryAction>
    </ListItem>
    {!lastItem && <Divider />}
  </Fragment>
)

type CarouselProps = {
  items: Array<typeof CarouselItem>,
  currentSlideNumber: number,
  lastSlideNumber: number,
  autoHeight?: boolean
}
const Carousel = ({
  items,
  autoHeight,
  currentSlideNumber,
  lastSlideNumber
}: CarouselProps) => (
  <CarouselList
    numberOfItemsPerSlide={5}
    itemHeight={autoHeight ? null : 70}
    currentSlide={currentSlideNumber}
    lastSlide={lastSlideNumber}
    items={items}
  />
)

export default withScrollBehaviour(Carousel)
