import { HISTORY } from './actions'
import withPaginatedFetch from '../../helpers/reducers/withPaginatedFetch'

const history = (state = null, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withPaginatedFetch(HISTORY)(history)
