// @flow

import React from 'react'
import { commands } from 'zego-shared/store/devices/sagas/utils/deviceCommands'
import Flex, { FlexItem } from '@Common/Flex'
import { Selected } from './styles'
import { VacantSchedule, Title, Status, Type } from './styles'

import type { ScheduleTypeProps } from './types'

const ScheduleType = ({ thermostatMode, updateMode }: ScheduleTypeProps) => {
  const isOff = thermostatMode === commands.OFF

  return (
    <VacantSchedule>
      <Flex>
        <FlexItem flex={1}>
          <Title>Type</Title>
          Selecting the type of schedule <br />
          determines what time of day your
          <br />
          settings will run.
        </FlexItem>
        <FlexItem flex={2}>
          <Flex>
            <FlexItem flex={1}>
              <Type onClick={() => !isOff && updateMode('off')}>
                {isOff ? <font color="black">Always Off</font> : 'Always Off'}
              </Type>
              {isOff && <Selected length="25px" height="15px" />}
            </FlexItem>
            <FlexItem flex={3}>
              <Type onClick={() => isOff && updateMode('auto')}>
                {isOff ? 'Always On' : <font color="black">Always On</font>}
              </Type>
              {!isOff && <Selected length="25px" height="15px" />}
            </FlexItem>
          </Flex>
          <Status>
            {isOff
              ? 'Your schedule is set to Always off. This means the schedule will automatically set all vacant unit thermostats to off at all times.'
              : `Your schedule is set to Always On. This means the schedule will automatically set all vacant unit thermostats to ${thermostatMode} at all times.`}
          </Status>
        </FlexItem>
      </Flex>
    </VacantSchedule>
  )
}

export default ScheduleType
