import React from 'react'
import PrivateRoute from '../PrivateRoute'
import Settings from '../../views/Settings'

const getSettingsRoutes = () => [
  <PrivateRoute
    key="settings"
    path="/properties/:propertyId/settings"
    component={Settings}
  />
]

export default getSettingsRoutes
