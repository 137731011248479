// @flow
import React from 'react'
import { Provider } from 'react-redux'
import configureStore from 'redux-mock-store'
import { BrowserRouter } from 'react-router-dom'
import { mount } from 'enzyme'
import { ThemeProvider } from 'emotion-theming'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

import theme from '../theme'

const initialState = {
  authorization: {},
  snackbar: {}
}
const mockStore = configureStore([])

export const mountWithTheme = (component: Node) => {
  return mount(
    <BrowserRouter>
      <ThemeProvider theme={theme}>{component}</ThemeProvider>
    </BrowserRouter>
  )
}

export const mountWithMuiTheme = (component: Node) => {
  return mount(
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}> {component} </MuiThemeProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export const mountWithStoreAndMuiTheme = (component: Node) => {
  return mount(
    <Provider store={mockStore(initialState)}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={theme}> {component} </MuiThemeProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  )
}

export const mountWithThemeProviderOnly = (component: Node) => {
  return mount(<ThemeProvider theme={theme}>{component}</ThemeProvider>)
}

export const mountWithStore = (component: Node) => {
  return mount(
    <Provider store={mockStore(initialState)}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>{component}</ThemeProvider>
      </BrowserRouter>
    </Provider>
  )
}
