// @flow

import moment from 'moment'
import sortBy from 'lodash/sortBy'

const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]

export const daysInWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const daysInWeekShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

export const isToday = (date: string) => {
  return moment().isSame(date, 'day')
}

export const isTomorrow = (date: string) => {
  return (
    moment(date).format('M/D/YY') === moment().add(1, 'days').format('M/D/YY')
  )
}

export const isYesterday = (date: string) => {
  return (
    moment(date).format('M/D/YY') ===
    moment().subtract(1, 'days').format('M/D/YY')
  )
}

export const getDate = (date: string) => {
  return moment(date).format('M/D/YY')
}

export const getLongDate = (date: string) => {
  return moment(date).format('MMMM Do, YYYY')
}

export const getDateFullYear = (date: string) => {
  return moment(date).format('MM/DD/YYYY')
}

export const getFullDate = (date: string) => {
  return moment(date).format('dddd, MMMM Do, YYYY')
}

export const getFilteredDate = (date: string) => {
  return moment(date).format('dddd, MMMM D')
}

export const onceAYearDateFormat = (date: string) => {
  return moment(date).format('MMMM Do @ h:mma')
}

export const onceAMonthDateFormat = (date: string) => {
  return moment(date).format('Do @ h:mma')
}

export const getFullDateTime = (date: string) => {
  return moment(date).format('dddd, MMMM Do, YYYY @ h:mma')
}

export const getDateNumber = (date: string) => {
  return moment(date).format('D')
}

export const getDay = (date: string) => {
  return moment(date).format('dddd')
}

export const getYear = (date: string) => {
  return moment(date).format('YYYY')
}

export const getMonth = (date: string) => {
  return moment(date).format('MMMM')
}

export const getTime = (date: string) => {
  return moment(date).format('h:mm')
}

export const get24HrTime = (date: string) => {
  return moment(date).format('hh:mm')
}

export const get24HrTimeAmPm = (date: string) => {
  return moment(date).format('HH:mm')
}

export const isSameDay = (date1: string, date2: string) => {
  return moment(date1).format('M/D/Y') === moment(date2).format('M/D/Y')
}

export const getMeridianDesignation = (date: string) => {
  return moment(date).format('a')
}

/**
 * Returns any string date in this format 8:19 PM
 * @returns {string}
 */
export const getTimeWithAMPM = (date: string): string => {
  return moment(date).format('LT')
}

export const sortByDateDescending = (data: string) =>
  sortBy(data, (datum) => new moment(datum.date)).reverse()

export const formatExpiresAfter = (createdAt: number, expiresAfter: number) => {
  createdAt = createdAt || moment().unix()
  const expiryDuration = createdAt + expiresAfter - moment().unix()
  if (expiryDuration <= 0) {
    return 'expired'
  }

  if (expiryDuration < 60 * 60 * 23.5) {
    return 'expires in < a day'
  }

  const days = Math.ceil(expiryDuration / 86400)
  return `expires in ${days} day${days === 1 ? '' : 's'}`
}

export const getChatDateSeperator = (date: string) => {
  if (isToday(date)) {
    return 'Today'
  }
  if (isYesterday(date)) {
    return 'Yesterday'
  }
  return getFullDate(date)
}

export const getFutureDateString = (date: string) => {
  if (isToday(date)) {
    return `Today, ${getLongDate(date)}`
  }

  if (isTomorrow(date)) {
    return `Tomorrow, ${getLongDate(date)}`
  }

  return getFullDate(date)
}

export const getNextMonths = (numberOfMonths: number) => {
  const nextMonths = []
  let currentMonth = new Date().getMonth()

  for (let index = 0; index < numberOfMonths; index++) {
    nextMonths.push(months[currentMonth])
    currentMonth++
    if (currentMonth > 11) {
      currentMonth = 0
    }
  }

  return nextMonths
}

export const getUnixStartEndMonth = (month: string | number) => {
  const currentMonth = moment().month()
  const desiredMonth = typeof month === 'string' ? months.indexOf(month) : month

  const year =
    currentMonth > desiredMonth
      ? moment()
        .add(1, 'years')
        .year()
      : moment().year()

  return {
    start: moment()
      .year(year)
      .month(desiredMonth)
      .startOf('month')
      .startOf('day')
      .unix(),
    end: moment()
      .year(year)
      .month(desiredMonth)
      .endOf('month')
      .endOf('day')
      .unix(),
  }
}

export const getDateForGroupMessaging = (date: string) => {
  if (isToday(date)) return 'today'
  if (isYesterday(date)) return 'yesterday'
  if (isTomorrow(date)) return 'tomorrow'
  return moment(date).format('MM/DD/YY')
}

export const getISOString = (date: string): string => {
  const dateObj = new Date(date)
  if (String(dateObj) !== 'Invalid Date') {
    return dateObj.toISOString()
  }
  return ''
}

export const getDatesBetween = (startDate: string, endDate: string): Array<string> => {
  const result = []
  let date = new Date(startDate)
  date.setDate(date.getDate() + 1)
  let dateString = date.toISOString().substr(0,10)
  while(dateString < endDate) {
    result.push(dateString)
    date.setDate(date.getDate() + 1)
    dateString = date.toISOString().substr(0,10)
  }
  return result
}
