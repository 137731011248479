// @flow

import React from 'react'
import SetupStep from './SetupStep'
import { SetupBody, TextField, ButtonDark } from './styles'
import { ErrorMessage } from '../Common/Messages'

import type { SetupStepProps } from './types'

type State = {
  connectedDeviceName: string,
  valid: boolean
}

export default class NameDevice extends React.Component<SetupStepProps, State> {
  state = {
    connectedDeviceName: '',
    valid: true
  }

  hasInvalidDeviceName = (value: string): boolean => {
    return value.length > 0 && value.trim().length === 0
  }

  handleChange = ({
    target: { value }
  }: SyntheticInputEvent<HTMLInputElement>) => {
    let tempState = { connectedDeviceName: value, valid: true }

    if (this.hasInvalidDeviceName(value)) {
      tempState.valid = false
    }

    this.setState(tempState)
  }

  handleKeyPress = ({ key }: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    const { connectedDeviceName } = this.state
    const { submitName } = this.props

    if (connectedDeviceName.length === 0 || this.hasInvalidDeviceName(connectedDeviceName)) {
      this.setState({ valid: false })
      return
    }

    submitName(connectedDeviceName)
  }

  render() {
    const { valid } = this.state
    return (
      <SetupStep title="NAME THE DEVICE">
        <SetupBody>
          <TextField
            onKeyPress={this.handleKeyPress}
            onChange={this.handleChange}
            InputProps={{
              disableUnderline: true
            }}
          />
          <ButtonDark onClick={this.handleSubmit}>SUBMIT</ButtonDark>
        </SetupBody>
        {!valid &&
          <ErrorMessage>A device name with a minimum of 2 characters that are not spaces is required, please enter a valid device name.</ErrorMessage>}
      </SetupStep>
    )
  }
}
