import { combineReducers } from 'redux'
import withPaginatedFetch from '../../helpers/reducers/withPaginatedFetch'
import { DEVICE_HISTORY_SECTION } from '../actions'

const reducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withPaginatedFetch(DEVICE_HISTORY_SECTION)(reducer)
