// @flow
import React from 'react'
import { ReSyncIcon } from '@icons'
import { FabButton } from '@Common/Button'
import { withTheme } from 'emotion-theming'
import { ReSyncPms } from './styles'

type Props = {
  onClick: Function,
  hoverText?: string,
  FabProps?: Object,
  theme: Object
}

const ReSyncPmsButton = ({
  onClick,
  hoverText,
  FabProps = {},
  theme,
  ...props
}: Props) => {
  return (
    <ReSyncPms {...props}>
      <FabButton
        variant="fab"
        color="secondary"
        component={() => (
          <ReSyncIcon name="resync" height="23px" width="21px" />
        )}
        onClick={onClick}
        {...FabProps}
      />
      {hoverText && <p>{hoverText}</p>}
    </ReSyncPms>
  )
}

export default withTheme(ReSyncPmsButton)
