import React from 'react'
import PrivateRoute from '../PrivateRoute'
import UserManagement, {
  AddUser,
  Unit,
  UserProfileContainer
} from '../../views/UserManagement'

const getUsersRoutes = () => [
  <PrivateRoute
    exact
    path="/properties/:propertyId/user-management/add-user"
    key="usersAddUser"
    component={AddUser}
    hideAppContainer
  />,
  <PrivateRoute
    key="usersManagement"
    exact
    path="/properties/:propertyId/user-management/:userType"
    component={UserManagement}
  />,
  <PrivateRoute
    key="usersManagement"
    exact
    path="/properties/:propertyId/user-management"
    component={UserManagement}
  />,
  <PrivateRoute
    key="usersManagement"
    exact
    path="/properties/:propertyId/user-management/profile/:userId"
    component={UserProfileContainer}
  />,
  <PrivateRoute
    key="usersManagement"
    exact
    path="/properties/:propertyId/user-management/profile/:userId/:profileInfo"
    component={UserProfileContainer}
  />,
  <PrivateRoute
    key="usersUnit"
    exact
    path="/properties/:propertyId/units/:unitId/user-management"
    component={Unit}
  />,
  <PrivateRoute
    exact
    key="usersUnitAddUser"
    path="/properties/:propertyId/units/:unitId/user-management/add-user"
    component={AddUser}
    hideAppContainer
  />,
  <PrivateRoute
    key="usersUnit"
    path="/properties/:propertyId/users/:userId/user-management"
    component={Unit}
  />
]

export default getUsersRoutes
