import compose from 'lodash/flowRight'
import { USERSSECTION } from '../actions'
import withNormalizedFetch from '../../../helpers/reducers/withNormalizedFetch'
import withNormalizedRefresh from '../../../helpers/reducers/withNormalizedRefresh'
import { GET_UNIT_USERS_BALANCE } from '../actions'

const initialState = {}

const unitUsers = (state = initialState, action) => {
  switch (action.type) {
    case GET_UNIT_USERS_BALANCE:
      return {
        ...state,
        balance: action.response
      }
    default:
      return state
  }
}

const withFetchAndRefresh = compose(
  withNormalizedFetch(USERSSECTION),
  withNormalizedRefresh(USERSSECTION)
)

export default withFetchAndRefresh(unitUsers)
