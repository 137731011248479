// @flow
import { connect } from 'react-redux'
import PropertySummary from './PropertySummary'
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { fetchSummary } from 'zego-shared/store/summary/actions'
import { fetchUsers } from 'zego-shared/store/users/property/actions'
import { fetchPropertyBatteryLevels } from 'zego-shared/store/batteries/actions'
import { fetchPropertyResidentEngagement } from 'zego-shared/store/analytics/residentEngagement/actions'
import {
  getPropertySmartCardData,
  getPropertySummaryCardData,
  getPropertyOccupancyData,
  hasPropertySummaryData
} from 'zego-shared/store/summary/selectors'
import { getPropertyUsersCardData } from 'zego-shared/store/users/selectors'
import { fetchSearchHistory } from 'zego-shared/store/search/actions'
import { getIsPropertyReadyToRender } from 'zego-shared/store/select/selectors'
import { getChatEnabled } from 'zego-shared/store/settings/propertySettings/selectors'
import { getResidentEngagementData } from 'zego-shared/store/analytics/residentEngagement/selectors'
import { isSmartEnabled } from 'zego-shared/store/settings/propertySettings/selectors'

const PropertySummaryWithFetchData = withFetchData(PropertySummary)
const fetchingSelector = state =>
    createIsFetchingSelector([
        'summary',
        'propertyusers',
        'propertybatterylevels',
        'select',
        'fetchsearchhistory',
        'residentengagement'
      ])(state) || !getIsPropertyReadyToRender(state)

const fetchData = (dispatch, props, propertyId) => {
  dispatch(fetchSummary(propertyId))
  dispatch(fetchUsers(propertyId))
  dispatch(fetchPropertyBatteryLevels(propertyId))
  dispatch(fetchSearchHistory(propertyId))
  dispatch(fetchPropertyResidentEngagement(propertyId))
}

const mapStateToProps = (state, { match: { url, params: { propertyId } } }) => ({
  smartCardData: getPropertySmartCardData(state, url),
  hasData: hasPropertySummaryData(state),
  usersCardData: getPropertyUsersCardData(state),
  isFetching: propertyId > 0 && fetchingSelector(state),
  summaryDetails: getPropertySummaryCardData(state),
  occupancyData: getPropertyOccupancyData(state),
  engagementData: getResidentEngagementData(state),
  isChatEnabled: getChatEnabled(state),
  isPropertyReadyToRender: getIsPropertyReadyToRender(state),
  propertyId,
  url,
  isSmartEnabled: isSmartEnabled(state)
})

const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  fetchData: props => {
    props.isPropertyReadyToRender &&
      fetchData(dispatch, props, params.propertyId)
  },
  componentDidUpdate: (prevProps, props) => {
    if (
      // property was changed so we need to
      // fetch data after the property is ready to render
      prevProps.isPropertyReadyToRender === false &&
      props.isPropertyReadyToRender === true
    ) {
      fetchData(dispatch, props, params.propertyId)
    }
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertySummaryWithFetchData)
