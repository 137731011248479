// @flow
import React, { Fragment } from 'react'
import HubCard from './HubCard'
import HubProperties from './HubProperties'
import { GridItem } from '../../../Common/Grid'
import type { BrowserHistory } from 'history'
import type { Hub as HubType } from './types'
import { hubPropertiesTable } from './styles'

type Props = {
  data: HubType,
  match: {
    params: {
      propertyId: string,
      unitId: string,
      hubProperty: ?string
    },
    url: string
  },
  history: BrowserHistory
}

const HubTab = ({ data, history, match: { params, url } }: Props) => {
  const handleSelect = (key: string) => {
    params.unitId &&
      history.push(
        `/properties/${params.propertyId}/units/${
          params.unitId
        }/smart/hub/${key}`
      )
  }

  const findHubProperty = (): string =>
    params.hubProperty ? params.hubProperty : 'hubCommands'

  const selectedHubProperty = findHubProperty()

  return (
    <Fragment>
      <HubCard
        name={data[selectedHubProperty].name}
        value={data[selectedHubProperty].value}
        text={data[selectedHubProperty].text}
        selectedHubProperty={selectedHubProperty}
      />
      <GridItem
        columnStart={5}
        columnSpan={8}
        rowStart={6}
        rowSpan={7}
        style={hubPropertiesTable}>
        <HubProperties
          onSelect={handleSelect}
          selectedHubProperty={selectedHubProperty}
          hub={data}
        />
      </GridItem>
    </Fragment>
  )
}

export default HubTab
