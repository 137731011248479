
// @flow
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { getData } from '../../../helpers/selectors/data'
import { humanizeString } from '../../../../utils/string'

const emptyObj = {}

export const getSelectedUnit = (state: Object) =>
  get(state, 'select.units.selected', emptyObj)

export const getSelectedUnitType = (state: Object) => {
  const { type } = getSelectedUnit(state)
  return type
}

export const getUnits = (state: Object) => getData(state, ['select', 'units'], [])

export const isUnitSelected = (state: Object) => !isEmpty(getSelectedUnit(state))

export const getUnitId = (state: Object) => get(state, 'select.units.selected.id', -1)

export const getUnitName = (state: Object) => {
  const name = get(state, 'select.units.selected.name', 'unknown unit')
  return name.toUpperCase()
}

export const getUnitStatus = (state: Object) =>
  humanizeString(get(state, 'select.units.selected.status', 'unknown status'))

export const getUnitType = (state: Object) =>
  humanizeString(get(state, 'select.units.selected.type', 'unknown type'))

export const getUnitActive = (state: Object) => {
  const isUnitActive = get(state, 'select.units.selected.active', false)
  return isUnitActive ? 'Activated' : 'Not Activated'
}
