import NetworkService from 'zego-shared/utils/networkService'
import { deauth } from 'zego-shared/store/authorization/actions'
import get from 'lodash/get'
import moment from 'moment'

const handleRedirectToLogin = store => {
  NetworkService.setupResponseInterceptor(
    () => {},
    error => {
      // check for unauthorized request
      if (error.response && error.response.status === 401) {
        const state = store.getState()
        const tokenExpiration = get(state, ['authorization', 'profile', 'exp'])

        // check if we have a token expiration
        if (tokenExpiration) {
          const tokenExpirationInMilliseconds = moment
            .unix(tokenExpiration)
            .format('x')
          const currentTimeInMilliseconds = moment().valueOf()

          // check if token is expired
          if (tokenExpirationInMilliseconds < currentTimeInMilliseconds) {
            store.dispatch(deauth())
          }
        }
      }
    }
  )
}

export default {
  handleRedirectToLogin
}
