import {
  EDIT_CODE_START,
  CREATE_CODE_START,
  DELETE_CODE_START,
  CREATE_CODE_SUCCESS,
  EDIT_CODE_SUCCESS,
  DELETE_CODE_SUCCESS,
  CREATE_CODE_ERROR,
  EDIT_CODE_ERROR,
  DELETE_CODE_ERROR
} from '../../actions'

const initialState = {}

const updateStatus = (state, lockId, codeId, status) => ({
  ...state,
  [lockId]: {
    ...state[lockId],
    [codeId]: status
  }
})

// a reducer that stores update status of specified lock codes (via their id)
const statuses = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CODE_START: {
      const { id, tempCodeId } = action
      return updateStatus(state, id, tempCodeId, 'creating')
    }
    case EDIT_CODE_START: {
      const { id, codeId } = action
      return updateStatus(state, id, codeId, 'updating')
    }
    case DELETE_CODE_START: {
      const { id, codeId } = action
      return updateStatus(state, id, codeId, 'deleting')
    }
    case CREATE_CODE_ERROR:
    case CREATE_CODE_SUCCESS: {
      const { id, tempCodeId } = action
      return updateStatus(state, id, tempCodeId, '')
    }
    case EDIT_CODE_SUCCESS:
    case EDIT_CODE_ERROR:
    case DELETE_CODE_SUCCESS:
    case DELETE_CODE_ERROR: {
      const { id, codeId } = action
      return updateStatus(state, id, codeId, '')
    }
    default:
      return state
  }
}

export default statuses
