import { connect } from 'react-redux'
import { deauth } from 'zego-shared/store/authorization/actions'
import Logout from './Logout'

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(deauth())
})

export default connect(
  null,
  mapDispatchToProps
)(Logout)
