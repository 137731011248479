import { call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken } from '../../authorization/selectors'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  fetchHistoryAction,
  fetchHistoryStart,
  fetchHistorySuccess,
  fetchHistoryError
} from './actions'

export function* fetchHistory({ propertyId, page, searchTerm, queryParams }) {
  try {
    yield put(fetchHistoryStart())
    const authToken = yield select(getToken)
    let response
    if (searchTerm != null) {
      response = yield call(
        api.searchGroupMessagingHistory,
        authToken,
        propertyId,
        searchTerm,
        queryParams
      )
    } else {
      response = yield call(
        api.getGroupMessagingHistorySummary,
        authToken,
        propertyId,
        page
      )
    }

    yield put(fetchHistorySuccess(response))
  } catch (error) {
    yield put(fetchHistoryError(error))
    log(`Failed to fetch group message history`)
  }
}

function* watchFetchHistory() {
  yield takeLatest(fetchHistoryAction().type, fetchHistory)
}

export default [watchFetchHistory()]
