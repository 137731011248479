import { call, select, takeLatest, put } from 'redux-saga/effects'
import {
  fetchResidentManagementMoveInMoveOutAction,
  fetchResidentManagementMoveInMoveOutStart,
  fetchResidentManagementMoveInMoveOutSuccess,
  fetchResidentManagementMoveInMoveOutError,
  fetchNextResidentManagementMoveInMoveOutAction,
  fetchNextResidentManagementMoveInMoveOutStart,
  fetchNextResidentManagementMoveInMoveOutSuccess,
  fetchNextResidentManagementMoveInMoveOutError,
  fetchResidentFilteredCountAction,
  fetchResidentFilteredCountStart,
  fetchResidentFilteredCountSuccess,
  fetchResidentFilteredCountError,
  fetchResidentTotalCountAction,
  fetchResidentTotalCountStart,
  fetchResidentTotalCountSuccess,
  fetchResidentTotalCountError,
  fetchMimoChecklistSummaryAction,
  fetchMimoChecklistSummaryStart,
  fetchMimoChecklistSummarySuccess,
  fetchMimoChecklistSummaryError,
  fetchMimoChecklistSettingsStart,
  fetchMimoChecklistSettingsSuccess,
  fetchMimoChecklistSettingsError,
  fetchMimoChecklistSettingsAction,
  fetchSavedMimoChecklistSettingsAction,
  fetchSavedMimoChecklistSettingsStart,
  fetchSavedMimoChecklistSettingsSuccess,
  fetchSavedMimoChecklistSettingsError,
  fetchMimoChecklistPdfAction,
  fetchMimoChecklistPdfStart,
  fetchMimoChecklistPdfSuccess,
  fetchMimoChecklistPdfError,
  fetchMimoResetCheckListAction,
  fetchMimoResetCheckListStart,
  fetchMimoResetCheckListSuccess,
  fetchMimoResetCheckListError,
  fetchMimoChecklistOptedOutAction,
  fetchMimoChecklistOptedOutStart,
  fetchMimoChecklistOptedOutSuccess,
  fetchMimoChecklistOptedOutError
} from './actions'
import { getToken } from '../authorization/selectors'

import api from '../../utils/api'
import log from '../../utils/logger'
import { getPropertyId } from '../select/selectors'
import get from 'lodash/get'
import { getPdfBlob } from '../../../admin/assets/src/views/UserManagement/UserProfile/MimoChecklistSummary/MimoChecklistSumaryPdf/MimoChecklistSummaryPdf'
import { getSettingsInfo } from '../settings/propertySettings/selectors'
import moment from 'moment'
import { showSnackbar } from '../snackbar/actions'

export function* fetchResidentManagementMoveInMoveOut({ id, page, params }) {
  try {
    yield put(fetchResidentManagementMoveInMoveOutStart())
    const authToken = yield select(getToken)
    const propertyID = id ? id : yield select(getPropertyId)
    if (propertyID === -1) throw new Error(noUnitSelected)
    const response = yield call(api.getResidentMoveInMoveOut, propertyID, authToken, page, params)
    const updatedResidentResponse = yield call(getCoreResidentResponse, response, authToken)
    yield put(fetchResidentManagementMoveInMoveOutSuccess(updatedResidentResponse))
  } catch (error) {
    yield put(fetchResidentManagementMoveInMoveOutError(error))
    log(error)
  }
}

function* getCoreResidentResponse(response, authToken) {
  let dataRows = []
  const eventTypes = {
    Lease_Start_Date: 'Lease Start Date',
    Move_In_Date: 'Move-in Date'
  }
  const residentStatusTypes = {
    Awaiting_Approval: 'Awaiting Approval',
    Approved: 'Approved',
    Checklist_Completed: 'Completed'
  }
  const propertyID = get(response[0], ["propertyId"])
  let settingsInfo = yield select(getSettingsInfo)
  let isWorkOrderEnabled = false
  let checkListSettingsResponse = null
  try {
    checkListSettingsResponse = yield call(api.getChecklistSettings, propertyID, authToken)
  } catch {
  }

  const daysAfter = checkListSettingsResponse !== null ? get(checkListSettingsResponse, ["daysAfter"]) : 0;
  const event = checkListSettingsResponse !== null ? get(checkListSettingsResponse, ["event"]) : eventTypes.Move_In_Date;

  if (settingsInfo.standalone_work_orders === true || settingsInfo.work_order === true) {
    isWorkOrderEnabled = true;
  }

  for (let x = 0; x < response.length; x++) {
    let rowdata = response[x];
    const residentID = rowdata.residentId;
    let approvalStatus = rowdata.approvalStatus;
    const checklistStatus = rowdata.checklistStatus;
    let workordersStarted = rowdata.workordersStarted;
    let workordersCount = null
    let dueDateWithDaysAdded = null
    const residentResponse = yield call(api.getUser, residentID, authToken)

    if (rowdata.resetChecklistDate !== null) {
      dueDateWithDaysAdded = moment.utc(rowdata.resetChecklistDate).add(daysAfter, 'days').format('MM/DD/YYYY');
      workordersCount = workordersStarted
    }
    else {
      const moveInDate = get(residentResponse, ['move_in_date'])
      const leaseStartDate = get(residentResponse, ['lease_start_date'])
      const accountActivatedTime = get(residentResponse, ['account_activated_time'])
      const lastActiveTime = get(residentResponse, ['last_active_time'])

      if (eventTypes.Move_In_Date === event) {
        if (moveInDate !== null) {
          dueDateWithDaysAdded = moment.utc(moveInDate).add(daysAfter, 'days').format('MM/DD/YYYY');
        }
        else if (leaseStartDate !== null) {
          dueDateWithDaysAdded = moment.utc(leaseStartDate).add(daysAfter, 'days').format('MM/DD/YYYY');
        }
        else {
          dueDateWithDaysAdded = yield call(getDueDateWithDaysAdded, accountActivatedTime, lastActiveTime, daysAfter)
        }
      }
      else if (eventTypes.Lease_Start_Date === event) {
        if (leaseStartDate !== null) {
          dueDateWithDaysAdded = moment.utc(leaseStartDate).add(daysAfter, 'days').format('MM/DD/YYYY');
        }
        else if (moveInDate !== null) {
          dueDateWithDaysAdded = moment.utc(moveInDate).add(daysAfter, 'days').format('MM/DD/YYYY');
        }
        else {
          dueDateWithDaysAdded = yield call(getDueDateWithDaysAdded, accountActivatedTime, lastActiveTime, daysAfter)
        }
      }

      if ((isWorkOrderEnabled === false) || (checklistStatus === residentStatusTypes.Checklist_Completed && approvalStatus !== residentStatusTypes.Approved)) {
        workordersCount = '--'
      }
      else if (checklistStatus !== residentStatusTypes.Checklist_Completed) {
        workordersCount = ''
      }
      else if (checklistStatus === residentStatusTypes.Checklist_Completed && approvalStatus === residentStatusTypes.Approved && workordersStarted === null) {
        workordersCount = 0
      }
      else {
        workordersCount = workordersStarted
      }
    }

    const updatedData = {
      ...rowdata,
      firstName: get(residentResponse, ["first_name"]),
      lastName: get(residentResponse, ["last_name"]),
      photoUrl: get(residentResponse, ["picture"]),
      unitName: get(residentResponse, ["units", "0", "name"]),
      dueDate: dueDateWithDaysAdded,
      workordersStarted: workordersCount,
      approvalStatus: approvalStatus
    }
    dataRows.push(updatedData)
  }
  return dataRows
}

function* getDueDateWithDaysAdded(accountActivatedTime, lastActiveTime, daysAfter) {
  if (accountActivatedTime) {
    return moment.utc(accountActivatedTime).add(daysAfter, 'days').format('MM/DD/YYYY');
  }
  if (lastActiveTime) {
    return moment.utc(lastActiveTime).add(daysAfter, 'days').format('MM/DD/YYYY');
  }
  return null;
}

export function* fetchNextResidentManagementMoveInMoveOut({ id, page, params }) {
  try {
    yield put(fetchNextResidentManagementMoveInMoveOutStart())
    const authToken = yield select(getToken)
    const propertyID = id ? id : yield select(getPropertyId)
    if (propertyID === -1) throw new Error(noUnitSelected)
    const response = yield call(api.getResidentMoveInMoveOut, propertyID, authToken, page, params)
    const updatedResidentResponse = yield call(getCoreResidentResponse, response, authToken)
    yield put(fetchNextResidentManagementMoveInMoveOutSuccess(updatedResidentResponse))
  } catch (error) {
    yield put(fetchNextResidentManagementMoveInMoveOutError(error))
    log(error)
  }
}

export function* fetchResidentFilteredCount({ id, page, params }) {
  try {
    yield put(fetchResidentFilteredCountStart())
    const authToken = yield select(getToken)
    const propertyID = id ? id : yield select(getPropertyId)
    if (propertyID === -1) throw new Error(noUnitSelected)
    const response = yield call(api.getResidentMoveInMoveOutFilteredCount, propertyID, authToken, page, params)
    yield put(fetchResidentFilteredCountSuccess(response))
  } catch (error) {
    yield put(fetchResidentFilteredCountError(error))
    log(error)
  }
}

export function* fetchResidentTotalCount({ id, page, params }) {
  try {
    yield put(fetchResidentTotalCountStart())
    const authToken = yield select(getToken)
    const propertyID = id ? id : yield select(getPropertyId)
    if (propertyID === -1) throw new Error(noUnitSelected)
    const response = yield call(api.getResidentMoveInMoveOutTotalCount, propertyID, authToken, page, params)
    yield put(fetchResidentTotalCountSuccess(response))
  } catch (error) {
    yield put(fetchResidentTotalCountError(error))
    log(error)
  }
}

export function* fetchChecklistSummary({ residentId, unitId }) {
  try {
    yield put(fetchMimoChecklistSummaryStart())
    const authToken = yield select(getToken)
    const propertyID = yield select(getPropertyId)
    if (propertyID === -1) throw new Error(noUnitSelected)
    const response = yield call(api.getChecklistSummary, authToken, propertyID, residentId, unitId)

    if (response.residentCheckListInfo) {
      const { pmId, checklistStatus } = response.residentCheckListInfo
      if (checklistStatus === 'Completed' && pmId && pmId !== 'undefined') {
        const pmUser = yield call(api.getUser, response.residentCheckListInfo.pmId, authToken)
        response.residentCheckListInfo.pmUser = pmUser
      }
    }
    yield put(fetchMimoChecklistSummarySuccess(response))
  } catch (error) {
    yield put(fetchMimoChecklistSummaryError(error))
    log(error)
  }
}

export function* fetchMoveInChecklistSettings(checklistSettings) {
  try {
    yield put(fetchMimoChecklistSettingsStart())
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)
    checklistSettings.propertyId = propertyId
    const response = yield call(api.submitChecklistSettings, checklistSettings, authToken)
    yield put(fetchMimoChecklistSettingsSuccess(response))
    yield put(showSnackbar('Success! checklist setting saved.', 'success'))
    yield put(fetchSavedMimoChecklistSettingsStart())
  } catch (error) {
    yield put(fetchMimoChecklistSettingsError(error))
    yield put(showSnackbar(error, 'error'))
    log(`Failed to fetch move in checklist settings. Error: ${error}`)
  }
}

export function* fetchSavedMoveInChecklistSettings() {
  try {
    yield put(fetchSavedMimoChecklistSettingsStart())
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)
    const response = yield call(api.getSavedChecklistSettings, authToken, propertyId)
    yield put(fetchSavedMimoChecklistSettingsSuccess(response))
  } catch (error) {
    yield put(fetchSavedMimoChecklistSettingsError(error))
    log(`Failed to fetch saved move in checklist settings. Error: ${error}`)
  }
}

export function* fetchMoveInChecklistPdf(userData) {
  try {
    const { residentId, unitId, profileComplete, disclosure } = userData
    yield put(fetchMimoChecklistPdfStart())
    const authToken = yield select(getToken)
    const propertyID = yield select(getPropertyId)
    if (propertyID === -1) throw new Error(noUnitSelected)
    const response = yield call(api.getChecklistSummary, authToken, propertyID, residentId, unitId)
    const { pmId, checklistStatus } = response.residentCheckListInfo
    if (checklistStatus === 'Completed' && pmId && pmId !== 'undefined' && pmId !== null) {
      response.residentCheckListInfo.pmUser = yield call(api.getUser, response.residentCheckListInfo.pmId, authToken)
    }

    let checklistSettingResponse = null
    let pdf = null
    try{
      checklistSettingResponse = yield call(api.getSavedChecklistSettings, authToken, propertyID)
      const { fileContent } = checklistSettingResponse
      pdf = yield getPdfBlob({ checklistSummary: response.residentCheckListTemplateData, checklistinfo: response.residentCheckListInfo, profileComplete, disclosure, fileContent: fileContent })
    }
    catch{
      pdf = yield getPdfBlob({ checklistSummary: response.residentCheckListTemplateData, checklistinfo: response.residentCheckListInfo, profileComplete, disclosure })
    }

    const url = URL.createObjectURL(pdf);
    const a = document.createElement('a');
    a.href = url;
    a.download = "Checklist Summary.pdf";
    a.click();
    yield put(fetchMimoChecklistPdfSuccess(response))
  } catch (error) {
    yield put(fetchMimoChecklistPdfError(error))
    log(`Failed to fetch move in checklist Pdf. Error: ${error}`)
  }
}

export function* fetchMoveInResetCheckList({ residentId, propertyId, propertyName, unitId, unitName }) {
  try {
    const errorMessage = 'Error. Unable to reset checklist. Checklist does not exist.';
    yield put(fetchMimoResetCheckListStart())
    const authToken = yield select(getToken)
    if (residentId && propertyId && propertyName && unitId && unitName) {
      const response = yield call(api.submitResetCheckList, authToken, residentId, propertyId, propertyName, unitId, unitName)
      if (response) {
        yield put(fetchMimoResetCheckListSuccess(response))
        yield put(showSnackbar('Success! This resident’s move-in checklist has been reset.', 'success'))
      }
      else {
        yield put(showSnackbar(errorMessage, 'error'))
      }
    }
    else {
      yield put(showSnackbar(errorMessage, 'error'))
    }
    yield put(fetchMimoChecklistSummaryAction({ residentId: residentId, unitId: unitId }))
  } catch (error) {
    yield put(fetchMimoResetCheckListError(error))
    yield put(showSnackbar('Error! Unable to reset resident’s move-in checklist. Try again later.', 'error'))
    log(`Failed to reset resident’s move-in checklist. Error: ${error}`)
  }
}

export function* fetchMoveInChecklistOptedOut({ residentCheckListId, userId, unitId, pmsId }) {
  try {
    yield put(fetchMimoChecklistOptedOutStart())
    const authToken = yield select(getToken)
    yield call(api.submitChecklistOptedOut, authToken, residentCheckListId, pmsId)
    yield put(fetchMimoChecklistOptedOutSuccess())
    const { first_name } = yield call(api.getUser, userId, authToken)
    yield put(showSnackbar(`Success! ${first_name} has been successfully opted out of their move-in checklist.`, 'success'))
    yield put(fetchMimoChecklistSummaryAction({ residentId: userId, unitId: unitId }))
  } catch (error) {
    yield put(fetchMimoChecklistOptedOutError(error))
    yield put(showSnackbar('Error! Unable to opt resident out of their move-in checklist. Try again later.', 'error'))
    log(`Failed to fetch move in checklist Opted Out. Error: ${error}`)
  }
}

function* watchfetchResidentManagementMoveInMoveOut() {
  yield takeLatest(fetchResidentManagementMoveInMoveOutAction().type, fetchResidentManagementMoveInMoveOut)
}

function* watchfetchNextResidentManagementMoveInMoveOut() {
  yield takeLatest(fetchNextResidentManagementMoveInMoveOutAction().type, fetchNextResidentManagementMoveInMoveOut)
}

function* watchfetchResidentFilteredCount() {
  yield takeLatest(fetchResidentFilteredCountAction().type, fetchResidentFilteredCount)
}

function* watchfetchResidentTotalCount() {
  yield takeLatest(fetchResidentTotalCountAction().type, fetchResidentTotalCount)
}

function* watchfetchChecklistSummary() {
  yield takeLatest(fetchMimoChecklistSummaryAction().type, fetchChecklistSummary)
}

function* watchFetchMoveInChecklistSettings() {
  yield takeLatest(fetchMimoChecklistSettingsAction().type, fetchMoveInChecklistSettings)
}

function* watchFetchSavedMoveInChecklistSettings() {
  yield takeLatest(fetchSavedMimoChecklistSettingsAction().type, fetchSavedMoveInChecklistSettings)
}

function* watchFetchMoveInChecklistPdf() {
  yield takeLatest(fetchMimoChecklistPdfAction().type, fetchMoveInChecklistPdf)
}

function* watchFetchMoveInResetCheckList() {
  yield takeLatest(fetchMimoResetCheckListAction().type, fetchMoveInResetCheckList)
}

function* watchFetchMoveInChecklistOptedOut() {
  yield takeLatest(fetchMimoChecklistOptedOutAction().type, fetchMoveInChecklistOptedOut)
}

export default [
  watchfetchResidentManagementMoveInMoveOut(),
  watchfetchNextResidentManagementMoveInMoveOut(),
  watchfetchResidentFilteredCount(),
  watchfetchResidentTotalCount(),
  watchfetchChecklistSummary(),
  watchFetchMoveInChecklistSettings(),
  watchFetchSavedMoveInChecklistSettings(),
  watchFetchMoveInChecklistPdf(),
  watchFetchMoveInResetCheckList(),
  watchFetchMoveInChecklistOptedOut()
]
