import styled from '@emotion/styled'

export const HeaderStyled = styled('div')`
padding: ${({ isFirst, visitorsTable, vehiclesTable }) => (isFirst && visitorsTable ? '0.8em 14em 0.8em 0' : isFirst && vehiclesTable ? '0.8em 14em 0.8em 0' : '0.8em 0')};
  text-align: left;
  text-transform: ${({ isFirst }) => (isFirst ? 'capitalize' : 'uppercase')};
  text-align: ${({ isFirst }) => (isFirst ? 'left' : 'center')};
  font-size: ${({ isFirst, theme }) =>
    isFirst ? theme.custom.font.size.sm : theme.custom.font.size.xxs};
  line-height: ${({ isFirst }) => (isFirst ? '14px' : '12px')};
  color: ${({ isFirst, theme }) =>
    isFirst ? theme.palette.primary.green.mint : theme.palette.text.light};
  font-weight: ${({ isFirst, theme }) => (isFirst ? 600 : 'unset')};
`

export const CellStyled = styled('div')`
  padding: 1.2em 0;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  line-height: 14px;
  text-align: ${({ isFirst }) => (isFirst ? 'left' : 'center')};
  text-transform: ${({ isFirst }) => (isFirst ? 'capitalize' : 'unset')};
  color: ${({ isFirst, theme }) =>
    isFirst ? theme.palette.common.black : theme.palette.text.light};
  ${({ hasImage }) => {
    if (hasImage) {
      return `
        display:flex;
        align-items:center;
        & > div {
            margin-right: 1em
        }
   `
    }
  }}
`

export const UserProfileTableStyled = styled('div')`
  & tr:first-child > td {
    border-bottom: none;
    border-top: 1px solid rgba(224, 224, 224, 1);,
  }

  & th:last-child {
    padding: 0;
  }

  & td:last-child {
    padding: 0 0 0 2.5em;
  }

  ${({ columnToHighlight, theme }) => {
    if (columnToHighlight) {
      return `
        & ${`th:nth-child(${columnToHighlight})`},
        & ${`td:nth-child(${columnToHighlight})`} {
            background-color: ${theme.palette.primary.green.hintMint};
                & div {
                    color: ${theme.palette.primary.green.seeFoam};
                }
            }
    `
    }
  }};
`

export const ViewRegistrationLinkStyled = styled('span')`
  color: ${({ theme }) => theme.palette.primary.green.mint};
  font-size: ${({ theme }) => theme.custom.font.size.md};
  text-align: center;
`
