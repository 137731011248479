// @flow
import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import type { RouterHistory } from 'react-router-dom'
import { BackButtonStyled } from './styles'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'

const BackButton = ({
  onClick,
  history,
  theme
}: {
  onClick?: () => void,
  history: RouterHistory,
  theme: Object
}) => {
  return (
    <BackButtonStyled
      id="backBtn"
      variant="fab"
      onClick={() =>
        typeof onClick === 'function' ? onClick() : history.goBack()
      }>
      <KeyboardBackspace
        style={{ color: theme.palette.primary.dark }}
        height="1.5em"
        width="1.5em"
      />
    </BackButtonStyled>
  )
}

export default withTheme(withRouter(BackButton))
