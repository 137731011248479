import styled from '@emotion/styled'

export const Screen = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme, type }) =>
    type === 'success'
      ? theme.palette.primary.navy.spaceBlue
      : theme.palette.error.dark};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Content = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    color: ${({ theme }) => theme.palette.primary.green.mint};
    font-size: ${({ theme }) => theme.custom.font.size.xl48};
    font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  }
  p {
    font-size: ${({ theme }) => theme.custom.font.size.lg};
    font-weight: ${({ theme }) => theme.custom.font.weight.normal};
    color: ${({ theme, type }) =>
      type === 'success'
        ? theme.palette.common.white
        : theme.palette.common.white};
  }
`
