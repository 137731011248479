// @flow
import get from 'lodash/get'

export const getResidentActiveData = (state: Object) => {
  const { total, active, percent_active: percentActive = 0 } =
    get(state, ['analytics', 'residentActive']) || {}
  return {
    total,
    active,
    percentActive: Math.round(percentActive)
  }
}

export const hasResidentActive = (state: Object) => {
  return get(state, ['analytics', 'residentActive']) != null
}
