// @flow
import React from 'react'
import moment from 'moment'
import queryString from 'query-string'
import { withTheme } from 'emotion-theming'
import TopNavigation from '../../../Common/TopNavigation'
import { withRouter } from 'react-router-dom'
import { Event, DateTime } from './SendSection'
import SelectedMethodDetails from './SelectedMethodDetails'
import { SchedulerWrapper, Break } from '../CreateGroupMessage/styles'
import { SendGroupMessageSchedulerProps } from '../types'
import { getFullDateTime, capitalizeFirstLetter } from '../../../../utils'

const getSelectedDisplayValue = (
  type,
  selectedDateTime,
  offsetDays,
  offsetBeforeAfter,
  selectedEvent
) => {
  if (type === 'event') {
    return `${offsetDays} ${offsetDays === '1' ? 'Day' : 'Days'
      } ${capitalizeFirstLetter(offsetBeforeAfter)} ${selectedEvent} @ 9:00am`
  }
  if (type === 'schedule') {
    return selectedDateTime
  }
  return `Today, ${getFullDateTime(moment())}`
}

const getTopNavigations = (selectedTemplate) => {
  if (selectedTemplate.isDefault) {
    return [
      {
        name: 'Event',
        query: { type: 'event' }
      }
    ]
  } else {
    return [
      {
        name: 'Send Now',
        query: { type: '' }
      },
      {
        name: 'Event',
        query: { type: 'event' }
      },
      {
        name: 'Date & Time',
        query: { type: 'schedule' }
      }
    ]
  }
}

class SendGroupMessageScheduler extends React.Component<
  SendGroupMessageSchedulerProps,
  {}
  > {
  render() {
    const {
      theme,
      location,
      selectedDateTime,
      changeSendDetails,
      selectSendMethod,
      handleEventChange,
      selectedEvent,
      handleOffsetDays,
      handleEmptyOffsetDays,
      offsetDays,
      defaultEvent,
      offsetBeforeAfter,
      handleOffsetBeforeAfter,
      handleSelectedDateTimeChange,
      selectedTemplate,
      history
    } = this.props
    
    if (selectedTemplate.isDefault && queryString.parse(location.search).type !== 'event') {
      history.push({
        search: '?type=event'
      })
    }

    const type = queryString.parse(location.search).type
    return (
      <React.Fragment>
        <Break />
        <SchedulerWrapper>
          <TopNavigation
            scrollable={false}
            tabs={getTopNavigations(selectedTemplate)}
            rightLinks={null}
          />
          {type === 'event' && (
            <Event
              chooseEvent={handleEventChange}
              selectedEvent={selectedEvent}
              events={selectedTemplate.groupEvents}
              theme={theme}
              handleOffsetDays={handleOffsetDays}
              handleEmptyOffsetDays={handleEmptyOffsetDays}
              offsetDays={offsetDays}
              showOffsetBeforeAfterDropdown={!selectedTemplate.isDefault}
              offsetBeforeAfter={offsetBeforeAfter}
              handleOffsetBeforeAfter={handleOffsetBeforeAfter}
              displayText
              defaultEvent={defaultEvent}
            />
          )}
          {type === 'schedule' && (
            <DateTime
              theme={theme}
              changeSendDetails={changeSendDetails}
              handleSelectedDateTimeChange={handleSelectedDateTimeChange}
              isCalendarFixed
            />
          )}
          <Break />
          <SelectedMethodDetails
            elementId="gmOffsetDetails"
            value={getSelectedDisplayValue(
              type,
              selectedDateTime,
              offsetDays,
              offsetBeforeAfter,
              selectedEvent.name
            )}
            select={selectSendMethod}
            theme={theme}
          />
        </SchedulerWrapper>
        <Break />
      </React.Fragment>
    )
  }
}

export default withTheme(withRouter(SendGroupMessageScheduler))
