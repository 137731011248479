// @flow

import get from 'lodash/get'

export const getPinnedChannels = (state: Object, userId: string, type: string) => {
  type = type ? type : 'pin'
  userId = userId == null ? 'self' : userId
  return get(state, ['chat', 'pinnedChannels', userId, type], [])
}

export const hasPinnedChannels = (state: Object, userId: string, type: string) => {
  const pinnedChannels = get(state, ['chat', 'pinnedChannels', userId, type])
  return pinnedChannels != null
}
