// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import Tabs from '@Common/Cards/Tabs'
import Tooltip from '../../Common/Tooltip'
import Event from './Event'
import Flex from '@Common/Flex'
import { EventsWrapper, EmptyListStyled, EventBackdrop, Space } from './styles'
import { getNextMonths } from '../../../utils'
import { capitalizeFirstLetter } from 'zego-shared/utils/string'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../Common/Loader'

type Props = {
  events: Array<{}>,
  theme: any,
  hasData: boolean,
  isFetching: boolean,
  newOrUpcomingEvents: Array<any>,
  eventsByMonth: Array<any>,
  filter: string,
  isFetchingMonth: boolean,
  fetchNext: () => void,
  authToken: string,
  rsvpToEvent: (token: string, id: string) => void,
  shouldFetchNext: boolean,
  filtered: boolean,
  filteredEvents: Array<any>
}

const getTabs = () => {
  return [
    {
      name: (
        <Tooltip type="upcomingEvent" tooltipContent={<span>Upcoming</span>} />
      ),
      query: { filter: 'upcoming' }
    },
    {
      name: <Tooltip type="newEvent" tooltipContent={<span>New</span>} />,
      query: { filter: 'new' }
    }
  ]
}

const getMonthTabs = () => {
  return getNextMonths(12).map(month => ({
    name: capitalizeFirstLetter(month),
    query: { month: month.toLowerCase() }
  }))
}

const getColors = theme => {
  return [
    theme.palette.primary.green.mint,
    theme.palette.secondary.main1,
    theme.palette.secondary.light
  ]
}

const EventsFeed = ({
  newOrUpcomingEvents,
  eventsByMonth,
  isFetching,
  isFetchingMonth,
  fetchNext,
  authToken,
  rsvpToEvent,
  hasData,
  shouldFetchNext,
  filter,
  theme,
  filtered,
  filteredEvents
}: Props) => {
  const colors = getColors(theme)
  return isFetching ? (
    <Loader />
  ) : hasData ? (
    <div>
      {filtered ? (
        filteredEvents.length === 0 ? (
          <React.Fragment>
            <div style={{ position: 'relative' }}>
              <EventBackdrop index={1} />
            </div>
            <EmptyListStyled>
              No events matched your filter criteria
            </EmptyListStyled>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Space />
            <React.Fragment>
              <div style={{ position: 'relative' }}>
                <EventBackdrop index={1} />
              </div>
              <EventsWrapper style={{ marginBottom: '15px' }}>
                {filteredEvents.map((event, index) => (
                  <Event
                    key={index}
                    event={event}
                    color={colors[index % colors.length]}
                    rsvpToEvent={rsvpToEvent}
                    authToken={authToken}
                  />
                ))}
              </EventsWrapper>
              <div style={{ position: 'relative' }}>
                <EventBackdrop index={2} />
              </div>
            </React.Fragment>
          </React.Fragment>
        )
      ) : (
        <React.Fragment>
          <Tabs
            tabs={getTabs()}
            tabStyle={1}
          />
          {newOrUpcomingEvents.length === 0 ? (
            <React.Fragment>
              <div style={{ position: 'relative' }}>
                <EventBackdrop index={1} />
              </div>
              <EmptyListStyled>
                No {filter} events have been created
              </EmptyListStyled>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div style={{ position: 'relative' }}>
                <EventBackdrop index={1} />
              </div>
              <EventsWrapper>
                {newOrUpcomingEvents.map((event, index) => (
                  <Event
                    key={index}
                    event={event}
                    color={colors[index % colors.length]}
                    rsvpToEvent={rsvpToEvent}
                    authToken={authToken}
                  />
                ))}
              </EventsWrapper>
              <div style={{ position: 'relative' }}>
                <EventBackdrop index={2} />
              </div>
            </React.Fragment>
          )}

          <Tabs
            customStyle={'z-index: 0'}
            tabs={getMonthTabs()}
            tabStyle={1}
            numberOfUnitsPerSlide={3}
            scrollable
          />
          {isFetchingMonth ? (
            <EmptyListStyled>
              <Flex justifyCenter paddingBottom="2em">
                <Loader fullScreen={false} color="#ccc" />
              </Flex>
            </EmptyListStyled>
          ) : eventsByMonth.length === 0 ? (
            <React.Fragment>
              <div style={{ position: 'relative' }}>
                <EventBackdrop index={1} />
              </div>
              <EmptyListStyled>No events</EmptyListStyled>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <InfiniteScroll
                width="99%"
                dataLength={eventsByMonth.length}
                next={fetchNext}
                hasMore={shouldFetchNext}
                loader={
                  <Flex justifyCenter paddingBottom="2em">
                    <Loader fullScreen={false} color="#ccc" />
                  </Flex>
                }>
                <EventsWrapper>
                  {eventsByMonth.map((event, index) => (
                    <Event
                      key={index}
                      event={event}
                      color={colors[index % colors.length]}
                      rsvpToEvent={rsvpToEvent}
                      authToken={authToken}
                    />
                  ))}
                  <div />
                </EventsWrapper>
              </InfiniteScroll>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  ) : null
}

export default withTheme(EventsFeed)
