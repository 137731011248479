import { createFetchActions } from '../../helpers/actions/creators'

export const SECTION = 'SEARCH'
export const FETCH_SEARCH_HISTORY_SECTION = 'FETCHSEARCHHISTORY'
export const ADD_SEARCH_HISTORY_SECTION = 'ADDSEARCHHISTORY'

export const {
  fetchAction: searchAction,
  fetchStartAction: searchStart,
  fetchSuccessAction: searchSuccess,
  fetchErrorAction: searchError
} = createFetchActions(SECTION)

export const search = (name, id) => searchAction({ name, id })

export const {
  fetchAction: fetchSearchHistoryAction,
  fetchStartAction: fetchSearchHistoryStart,
  fetchSuccessAction: fetchSearchHistorySuccess,
  fetchErrorAction: fetchSearchHistoryError
} = createFetchActions(FETCH_SEARCH_HISTORY_SECTION)

export const fetchSearchHistory = id => fetchSearchHistoryAction({ id })

export const addSearchHistory = (id, resourceKey, resourceValue) => ({
  type: ADD_SEARCH_HISTORY_SECTION,
  id,
  resourceKey,
  resourceValue
})
