// @flow

import React from 'react'
import type { Node } from 'react'
import { HeaderStyled } from './styles'

type Props = {
  children: Node
}

const Header = ({ children, ...rest }: Props) => {
  return (
    <HeaderStyled {...rest}>
      <span>{children}</span>
    </HeaderStyled>
  )
}

export default Header
