import styled from '@emotion/styled'

export const ExpansionWrapper = styled('div')`
  .expansion-panel {
    box-shadow: none;
    border-bottom: 1px solid ${({ theme }) => theme.palette.neutrals.deepGray};
    width: 100%;
  }

  .last-panel {
    border-bottom: none;
  }

  ${({ width }) => width && `width: ${width}`};
`
