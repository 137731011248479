import { USERSSECTION } from '../actions'
import withFetch from '../../../helpers/reducers/withFetch'

const initialState = {}

const propertyUsersSummary = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withFetch(USERSSECTION)(propertyUsersSummary)
