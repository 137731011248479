import { Font } from '@react-pdf/renderer'
import theme from "../../../../../../../assets/src/theme"

Font.register({
  family: "Roboto", fonts: [
    { src: "/roboto.bold.ttf", fontWeight: 1000 },
  ]
})

const styles = ({
  page: {
    width: '80%',
    padding: 40,
    borderStyle: 'solid',
    borderWidth: '5px',
    flexGrow: 1
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 5
  },
  headerContainer: {
    width: '100%',
    flexDirection: 'row'
  },
  leftHeaderSection: {
    width: '50%',
    flexDirection: 'row'
  },
  rightHeaderSection: {
    width: '50%',
    textAlign: 'right',
    flexDirection: 'row'
  },
  header: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: theme.custom.font.size.mdlg
  },
  defaultText: {
    fontStyle: 'normal',
    fontSize: theme.custom.font.size.sm,
    color: theme.palette.text.black
  },
  signatureText: {
    fontStyle: 'normal',
    fontSize: theme.custom.font.size.sm,
    color: theme.palette.text.black,
    paddingTop:5
  },
  nameText: {
    fontStyle: 'normal',
    fontSize: theme.custom.font.size.sm,
    color: theme.palette.text.black,
    paddingTop:10
  },
  subHeader: {
    fontWeight: 800,
    fontSize: theme.custom.font.size.md,
    marginBottom: 10,
    marginTop: 5
  },
  signatureHeaderText: {
    fontWeight: 800,
    fontSize: theme.custom.font.size.md,
    marginTop: 5
  },
  innerDefaultText: {
    flexDirection: 'row',
    fontSize: theme.custom.font.size.sm,
    padding: 3,
    width: 'auto',
    color: theme.palette.text.black
  },
  innerDefaultGreyText: {
    flexDirection: 'row',
    fontSize: theme.custom.font.size.sm,
    padding: 3,
    width: 'auto',
    color: theme.palette.text.grey
  },
  innerDefaultTextRed: {
    color: theme.palette.secondary.pink.deepRed,
    flexDirection: 'row',
    fontSize: theme.custom.font.size.sm,
    textAlign: 'left',

    paddingLeft: 5,
    fontStyle:'italic'
  },
  italic: {
    fontStyle: 'italic',
    color: theme.palette.secondary.pink.deepRed,
    fontSize: theme.custom.font.size.sm,
    flexDirection: 'row',
    textAlign: 'left',
    paddingLeft: 5
  },
  innerDefaultTextRedNormal: {
    fontStyle: 'normal',
    fontSize: theme.custom.font.size.sm,
    color: theme.palette.text.deepRed
  },
  underLine: {
    width: '54px',
    height: '6px',
    borderBottom: `2px solid ${theme.palette.primary.green.mint}`,
    marginTop: 10
  },
  section: {
    width: '95%',
    flexDirection: 'row',
    marginTop: 10,
    paddingTop: '5px',
    textAlign: 'left'
  },
  disclousureSection: {
    width: '95%',
    flexDirection: 'row',
    marginTop: 10,
    paddingTop: '5px',
    textAlign: 'left'
  },
  underLineBreak: {
    width: '70%',
    height: '6px',
    borderBottom: `1px solid ${theme.palette.neutrals.warmGray}`,
    marginTop: 20,
    marginBottom: 20,
    alignSelf: 'center'
  },
  boldText: {
    fontStyle:'normal',
    fontWeight: 800,
    fontSize: theme.custom.font.size.sm,
    color: theme.palette.text.black
  },
  image: {
    width: 300,
    height: 300,
    borderStyle: 'solid',
    borderWidth: '5px'
  },
  WorkOrderNo: {
    fontStyle: 'normal',
    fontSize: theme.custom.font.size.sm,
    paddingLeft: 20,
    color: theme.palette.primary.green.mint,
    textDecoration: 'underline',
    marginBottom: 10
  },
  categoryHeader: {
    marginBottom: 10,
    marginTop: 10
  },
  attributeHeader: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  imageContainer: {
    minWidth: '29%',
    maxWidth: '29%',
    marginRight: 20,
    marginBottom: 20
  },
  pic: {
    width: '100%',
    height: 150
  },
  signatureHeader: {
    Width: '40%',
    height: 220,
    marginTop: 40,
    marginBottom: 20
  },
  signature: {
    Width: '70%',
    height: '70%',
    marginBottom: 20
  },
  workOrderHeaderText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: theme.custom.font.size.sm,
    color: theme.palette.text.black,
    paddingBottom: 10
  },
  pageNumber: {
    textAlign: 'center',
    fontSize: theme.custom.font.size.xs,
    width: '120%',
    bottom: 30,
    position: 'absolute',
    color: theme.palette.text.grey
  },
  attributeSubHeader: {
    width: '50%'
  }
})

export default styles
