import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import DesktopActivationBackground from '../../../images/desktop_activation.jpg'

export const Container = styled(Flex)`
  background: url("${DesktopActivationBackground}") no-repeat bottom right;
  background-size: contain;
`

export const AppName = styled('span')`
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  font-size: ${({ theme }) => theme.custom.font.size.xxl};
  color: ${({ theme }) => theme.palette.common.black};
`
export const DownloadMessage = styled('div')`
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  font-size: ${({ theme }) => theme.custom.font.size.lg};
  color: ${({ theme }) => theme.palette.text.secondary};
  padding-top: 2em;
`
export const Content =styled(Flex)`
  height: 80vh;
`

export const GoToLogin =styled(Flex)`
  font-size: ${({ theme }) => theme.custom.font.size.md};
  color: ${({ theme }) => theme.palette.common.black};
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding-right: 35px;
`
