import { combineReducers } from 'redux'
import flowRight from 'lodash/flowRight'
import { select } from 'zego-shared/store/select/reducers'
import addDevice from 'zego-shared/store/addDevice/reducers'
import settings from 'zego-shared/store/settings/reducers'
import isFetching from 'zego-shared/store/isFetching/reducers'
import isUpdating from 'zego-shared/store/isUpdating/reducers'
import authorization from 'zego-shared/store/authorization/reducers'
import withPersist from 'zego-shared/store/helpers/reducers/withPersist'
import withReset from 'zego-shared/store/helpers/reducers/withReset'
import { DEAUTH } from 'zego-shared/store/authorization/actions'
import appPersistConfig from './persistConfig'
import unit from './Unit/reducers'
import property from './Property/reducer'
import chat from './Chat/reducer'
import search from 'zego-shared/store/search/reducers'
import users from './Users/reducers'
import groupMessaging from 'zego-shared/store/groupMessaging/reducers'
import snackbar from 'zego-shared/store/snackbar/reducers'
import { SELECT_PROPERTY } from 'zego-shared/store/select/actions'
import schedule from 'zego-shared/store/schedule/reducers'
import packages from 'zego-shared/store/packages/reducers'
import analytics from 'zego-shared/store/analytics/reducers'
import amenitiesLegacy from 'zego-shared/store/amenitiesLegacy/reducers'
import amenitiesPro from 'zego-shared/store/amenitiesPro/reducers'
import registration from 'zego-shared/store/registration/reducers'
import { communityFeedReducer as communityFeed } from './CommunityFeed'
import { isFetchingNextReducer as isFetchingNext } from 'zego-shared/store/isFetchingNext'
import { chatNotificationData } from './Chat/Notification'
import lockCodes from 'zego-shared/store/lockCodes/reducers'
import launchDarkly from '../integrations/LaunchDarkly/reducers'
import workOrders from 'zego-shared/store/workOrders/reducers'
import surveys from 'zego-shared/store/surveys/reducers'
import moveInMoveOut from 'zego-shared/store/moveInMoveOut/reducers'
import mimoWorkOrder from 'zego-shared/store/MimoWorkorders/reducers'
import marketplace from 'zego-shared/store/marketplace/reducers'

const withResetOnDeauth = withReset(DEAUTH)
const appEnhancer = flowRight(
  withPersist(appPersistConfig),
  withResetOnDeauth
)

const resetOnSelectProperty = reducer => (state, action) => {
  if (action.type === SELECT_PROPERTY) {
    state = undefined
  }
  return reducer(state, action)
}

const rootReducer = (state, action) =>
  combineReducers({
    authorization,
    launchDarkly,
    select,
    workOrders: resetOnSelectProperty(workOrders),
    property: resetOnSelectProperty(property),
    unit: resetOnSelectProperty(unit),
    settings: resetOnSelectProperty(settings),
    isFetching: resetOnSelectProperty(isFetching),
    isFetchingNext: resetOnSelectProperty(isFetchingNext),
    isUpdating: resetOnSelectProperty(isUpdating),
    chat: resetOnSelectProperty(chat),
    search: resetOnSelectProperty(search),
    users: resetOnSelectProperty(users),
    groupMessaging: resetOnSelectProperty(groupMessaging),
    addDevice: resetOnSelectProperty(addDevice),
    snackbar: resetOnSelectProperty(snackbar),
    schedule: resetOnSelectProperty(schedule),
    communityFeed: resetOnSelectProperty(communityFeed),
    analytics: resetOnSelectProperty(analytics),
    packages: resetOnSelectProperty(packages),
    chatNotificationData: resetOnSelectProperty(chatNotificationData),
    registration: resetOnSelectProperty(registration),
    amenitiesLegacy: resetOnSelectProperty(amenitiesLegacy),
    amenitiesPro: resetOnSelectProperty(amenitiesPro),
    lockCodes: resetOnSelectProperty(lockCodes),
    surveys: resetOnSelectProperty(surveys),
    moveInMoveOut: resetOnSelectProperty(moveInMoveOut),
    mimoWorkOrder: resetOnSelectProperty(mimoWorkOrder),
    marketplace: resetOnSelectProperty(marketplace)
  })(state, action)

export default appEnhancer(rootReducer)
