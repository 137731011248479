import { createFetchActions } from '../../helpers/actions/creators'

export const GROUPS = 'GROUPS'
export const GROUPUSERS = 'GROUPUSERS'
export const SINGLEGROUP = 'SINGLEGROUP'

export const {
  fetchAction: fetchGroupsAction,
  fetchStartAction: fetchGroupsStart,
  fetchSuccessAction: fetchGroupsSuccess,
  fetchErrorAction: fetchGroupsError
} = createFetchActions(GROUPS)

export const {
  fetchAction: fetchGroupUsersAction,
  fetchStartAction: fetchGroupUsersStart,
  fetchSuccessAction: fetchGroupUsersSuccess,
  fetchErrorAction: fetchGroupUsersError
} = createFetchActions(GROUPUSERS)

export const fetchGroups = (propertyId, page) =>
  fetchGroupsAction({ propertyId, page })

export const searchGroups = (propertyId, page, searchTerm, queryParams = {}) =>
  fetchGroupsAction({
    propertyId,
    searchTerm,
    queryParams: { ...queryParams, page }
  })

export const {
  fetchAction: fetchSingleGroupAction,
  fetchStartAction: fetchSingleGroupStart,
  fetchSuccessAction: fetchSingleGroupSuccess,
  fetchErrorAction: fetchSingleGroupError
} = createFetchActions(SINGLEGROUP)

export const fetchSingleGroup = groupId => fetchSingleGroupAction({ groupId })

export const fetchGroupUsers = groupIds => fetchGroupUsersAction({ groupIds })

export const searchGroupsUsers = (groupIds, searchTerm) =>
  fetchGroupUsersAction({ groupIds, searchTerm })
