import {
  LOCKCODES,
  RESYNC_CODES_ERROR,
  RESYNC_CODES_START,
  RESYNC_CODES_SUCCESS
} from '../../actions'
import withFetch from '../../../helpers/reducers/withFetch'

const initialState = []

const lockCodes = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export const resyncingLockCodes = (state = false, action) => {
  switch (action.type) {
    case RESYNC_CODES_START:
      return true
    case RESYNC_CODES_ERROR:
    case RESYNC_CODES_SUCCESS:
      return false
    default:
      return state
  }
}

export default withFetch(LOCKCODES)(lockCodes)
