import { combineReducers } from 'redux'
import {
  EVENTSINITIAL,
  NEWEVENTS,
  UPCOMINGEVENTS,
  EVENTS,
  UPDATE_EVENT_TIMESTAMP,
  CLEAR_EVENT_TIMESTAMP,
  fetchEventsSuccess,
  fetchMonthEventsSuccess,
  fetchNextMonthEventsSuccess,
  updateEventRSVPWithUser,
  fetchFilteredEventsSuccess,
  cancelFilterEvents
} from './eventsActions'
import withFetch from '../helpers/reducers/withFetch'
import withFetchNext from '../helpers/reducers/withFetchNext'

const eventsReducer = (state = null, action) => {
  switch (action.type) {
    case fetchFilteredEventsSuccess().type: {
      return {
        ...state,
        filteredEvents: action.events,
        filtered: true
      }
    }
    case fetchEventsSuccess().type: {
      return {
        data: action.payload.data,
        pagination: {
          [action.payload.start]: {
            totalPages: parseInt(action.payload.headers['total-pages']),
            nextPage: 2
          }
        },
        filtered: false
      }
    }
    case cancelFilterEvents().type: {
      return {
        ...state,
        filtered: false
      }
    }
    case fetchMonthEventsSuccess().type: {
      return {
        data: [...state.data, ...action.payload.data],
        pagination: {
          ...state.pagination,
          [action.payload.start]: {
            totalPages: parseInt(action.payload.headers['total-pages']),
            nextPage: 2
          }
        }
      }
    }
    case fetchNextMonthEventsSuccess().type: {
      return {
        data: [...state.data, ...action.payload.data],
        pagination: {
          ...state.pagination,
          [action.payload.start]: {
            totalPages: parseInt(action.payload.headers['total-pages']),
            nextPage: parseInt(action.payload.headers['page-number']) + 1
          }
        }
      }
    }
    case updateEventRSVPWithUser().type: {
      return {
        ...state,
        data: updateEventsRSVP(state.data, action)
      }
    }
    default:
      return state
  }
}

const newEvents = (state = null, action) => {
  switch (action.type) {
    case updateEventRSVPWithUser().type: {
      return updateEventsRSVP(state, action)
    }
    default:
      return state
  }
}

const upcomingEvents = (state = null, action) => {
  switch (action.type) {
    case updateEventRSVPWithUser().type: {
      return updateEventsRSVP(state, action)
    }
    default:
      return state
  }
}

const endTimestamps = (state = [], action) => {
  switch (action.type) {
    case UPDATE_EVENT_TIMESTAMP: {
      return [...state, action.endTimestamp]
    }
    case CLEAR_EVENT_TIMESTAMP: {
      return []
    }
    default:
      return state
  }
}

const events = combineReducers({
  events: eventsReducer,
  newEvents: withFetch(NEWEVENTS)(newEvents),
  upcomingEvents: withFetch(UPCOMINGEVENTS)(upcomingEvents),
  endTimestamps
})

const updateEventsRSVP = (events, { id, user }) => {
  return events.map(event => {
    if (event.thread_id !== id) {
      return event
    }

    return {
      ...event,
      rsvps_count: event.rsvps_count + 1,
      rsvps: [...event.rsvps, user]
    }
  })
}

export default events
