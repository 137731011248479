// @flow
import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import { RadiobuttonIcon } from '@icons'
import { FormControlLabel } from './styles'
import { DeliveryMethodRadioGroupProps } from '../types'

const fields = ['text', 'in-app', 'email', 'chat', 'preferred']

const DeliveryMethodRadioGroup = ({
  selectedDeliveryMethods,
  handleDeliveryMethodChange,
  className,
  showChatOption
}: DeliveryMethodRadioGroupProps) => {

  return (
    <FormControl classes={{ root: 'checkBoxGroupFieldset' }}>
      <div>
        {fields.map((field, index) => {
          if(!showChatOption && field === 'chat') return null;
          return (<FormControlLabel
            key={index}
            classes={{ root: 'checkBoxGroupFormControl' }}
            control={
              <Checkbox
                id={field.replace(/\s|-/g, '')}
                checked={selectedDeliveryMethods.includes(field)}
                onChange={handleDeliveryMethodChange}
                name={field}
                checkedIcon={
                  <RadiobuttonIcon
                    width={'1.5em'}
                    height={'1.1em'}
                    name="selected"
                  />
                }
                icon={
                  <RadiobuttonIcon
                    width={'1.5em'}
                    height={'1.1em'}
                    name="unselected"
                  />
                }
              />
            }
            label={field === 'in-app' ? 'in app' : field}
          />
        )})}
      </div>
    </FormControl>
  )
}

export default DeliveryMethodRadioGroup
