// @flow
import React, { Component } from 'react'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import MergeFields from './MergeFields'
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const htmlToDraftMessage = (message = '') => {
  const { contentBlocks, entityMap } = htmlToDraft(message)
  return ContentState.createFromBlockArray(contentBlocks, entityMap)
}

type State = {
  editorState: any
}

type Props = {
  message: string,
  onBlur: (value: string) => void,
  onChange: (editorState: any, value: string) => void
}

class WYSIWYGEditor extends Component<Props, State> {
  state = {
    editorState: EditorState.createWithContent(
      htmlToDraftMessage(this.props.message)
    )
  }

  static defaultProps = {
    onBlur: () => {},
    onChange: () => {}
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.message !== this.props.message) {
      this.setState({
        editorState: EditorState.createWithContent(
          htmlToDraftMessage(this.props.message)
        )
      })
    }
  }

  onBlur = (e: any, editorState: any) => {
    const { onBlur } = this.props

    onBlur(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  onChange = (editorState: any) => {
    const { onChange } = this.props

    this.setState(
      {
        editorState
      },
      // output state and html on change
      onChange(
        editorState,
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      )
    )
  }

  render() {
    const { editorState } = this.state

    return (
      <Editor
        editorState={editorState}
        onEditorStateChange={this.onChange}
        onBlur={this.onBlur}
        toolbarCustomButtons={[<MergeFields />]}
      />
    )
  }
}

export default WYSIWYGEditor
