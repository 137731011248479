import {
  createFetchActions,
  createRefreshActions,
  createFetchNextActions,
  createFetchAndRefreshActions
} from '../../../helpers/actions/creators'

export const USERSSECTION = 'PROPERTY_USERS'
export const FETCH_RESIDENT_USERS = 'FETCH_RESIDENT_USERS'
export const FETCH_RESIDENT_SUMMARY = 'FETCH_RESIDENT_SUMMARY'
export const FETCH_RESIDENT_MANAGEMENT_SURVEYS = 'FETCH_RESIDENT_MANAGEMENT_SURVEYS'
export const FETCH_PROPERTY_USERS = 'FETCH_PROPERTY_USERS'
export const RESIDENTSECTION = 'RESIDENT_USERS'
export const RESIDENTSUMMARYSECTION = 'RESIDENT_SUMMARY'
export const RESIDENTMANAGEMENTSURVEYSSECTION = 'RESIDENT_MANAGEMENT_SURVEYS'
export const RESIDENT_MOVEINMOVEOUT = 'RESIDENT_MOVEINMOVEOUT'
export const FILTER_PROPERTY_USERS = 'FILTER_PROPERTY_USERS'
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'
export const CLEAR_SEARCH_TERM = 'CLEAR_SEARCH_TERM'
export const UPDATE_USER_LEASE_INTEREST_PROPERTY_STATUS =
  'UPDATE_USER_LEASE_INTEREST_PROPERTY_STATUS'
export const UPDATE_USER_LEASE_INTEREST_STATUS_PROPERTY_SUCCESS =
  'UPDATE_USER_LEASE_INTEREST_STATUS_PROPERTY_SUCCESS'
export const UPDATE_USER_LEASE_INTEREST_STATUS_PROPERTY_FAILURE =
  'UPDATE_USER_LEASE_INTEREST_STATUS_PROPERTY_FAILURE'
export const {
  fetchAction: fetchUsersAction,
  fetchStartAction: fetchUsersStart,
  fetchSuccessAction: fetchUsersSuccess,
  fetchErrorAction: fetchUsersError,
  refreshAction: refreshUsers,
  refreshStartAction: refreshUsersStart,
  refreshSuccessAction: refreshUsersSuccess,
  refreshErrorAction: refreshUsersError
} = createFetchAndRefreshActions(USERSSECTION)

export const fetchUsers = id => fetchUsersAction({ id })

export const {
  fetchAction: fetchResidentUsersAction,
  fetchStartAction: fetchResidentUsersStart,
  fetchSuccessAction: fetchResidentUsersSuccess,
  fetchErrorAction: fetchResidentUsersError,
  fetchNextAction: fetchNextResidentUsersAction,
  fetchNextStartAction: fetchNextResidentUsersStart,
  fetchNextSuccessAction: fetchNextResidentUsersSuccess,
  fetchNextErrorAction: fetchNextResidentUsersError
} = createFetchNextActions(RESIDENTSECTION)

export const fetchNextResidents = (id, page, name) => ({
  type: FETCH_RESIDENT_USERS,
  id,
  page,
  name
})

export const {
  fetchAction: fetchResidentSummaryAction,
  fetchStartAction: fetchResidentSummaryStart,
  fetchSuccessAction: fetchResidentSummarySuccess,
  fetchErrorAction: fetchResidentSummaryError
} = createFetchActions(RESIDENTSUMMARYSECTION)

export const fetchResidentSummary = (id) => ({
  type: FETCH_RESIDENT_SUMMARY,
  id
})

export const {
  fetchAction: fetchResidentManagementSurveysAction,
  fetchStartAction: fetchResidentManagementSurveysStart,
  fetchSuccessAction: fetchResidentManagementSurveysSuccess,
  fetchErrorAction: fetchResidentManagementSurveysError,
  fetchNextAction: fetchNextResidentManagementSurveysAction,
  fetchNextStartAction: fetchNextResidentManagementSurveysStart,
  fetchNextSuccessAction: fetchNextResidentManagementSurveysSuccess,
  fetchNextErrorAction: fetchNextResidentManagementSurveysError
} = createFetchNextActions(RESIDENTMANAGEMENTSURVEYSSECTION)

export const fetchNextResidentManagementSurveys = (id, page, params) => ({
  type: FETCH_RESIDENT_MANAGEMENT_SURVEYS,
  id,
  page,
  params
})

export const searchPropertyUsers = (id, searchTerm) => ({
  type: FETCH_PROPERTY_USERS,
  id,
  name: searchTerm
})

export const searchResidents = (id, searchTerm) => ({
  type: FETCH_RESIDENT_USERS,
  id,
  name: searchTerm
})

export const setSearchTerm = (searchTerm) => ({
  type: SET_SEARCH_TERM,
  searchTerm
})

export const clearSearchTerm = () => ({
  type: CLEAR_SEARCH_TERM
})

export const updateUserLeaseInterestStatusAction = (userId, status) => ({
  type: UPDATE_USER_LEASE_INTEREST_PROPERTY_STATUS,
  userId,
  status
})

export const updateUserLeaseInterestStatusPropertySuccess = (userId, response) => ({
  type: UPDATE_USER_LEASE_INTEREST_STATUS_PROPERTY_SUCCESS,
  userId,
  response
})

export const updateUserLeaseInterestStatusPropertyFailure = () => ({
  type: UPDATE_USER_LEASE_INTEREST_STATUS_PROPERTY_FAILURE
})
