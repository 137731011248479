// @flow

import React from 'react'
import { generate as shortid } from 'shortid'
import BaseCard from '@Common/Cards/BaseCard'
import Flex from '@Common/Flex'
import Header from '@Common/Header'
import Grid from '../Common/Grid'
import { Description } from './styles'
import CommunicationCard from './CommunicationCard'
import getCommunicationCardData from './data'
import Loader from '../Common/Loader'
import { UnderlayBG } from './styles'
import type { CommunicationProps } from './types'

export default class Communication extends React.Component<CommunicationProps> {
  render() {
    const {
      selectedPropertyID,
      groupMessageTodayCount,
      communityFeedEnabled,
      groupMessagingEnabled,
      upcomingEventsCount,
      chatEnabled,
      isFetching,
      userRole,
    } = this.props

    const hasGroupMessagingPermission = (userRole !== "property_staff")
    const hasLiveChatPermission = (userRole !== "company_admin")

    const cardData = getCommunicationCardData(
      selectedPropertyID,
      groupMessageTodayCount,
      communityFeedEnabled,
      groupMessagingEnabled,
      hasGroupMessagingPermission,
      chatEnabled,
      hasLiveChatPermission,
      upcomingEventsCount
    )

    return isFetching ? (
      <Loader />
    ) : (
      <BaseCard
        header={() => <Header blackText> communication</Header>}
        headerMarginBottom={123}
        columnStart={1}
        columnSpan={12}
        rowStart={1}
        rowSpan={12}
        showShadow={false}
        overflow="visible"
        content={() => (
          <Grid>
            <UnderlayBG />
            <BaseCard
              columnStart={1}
              columnSpan={3}
              rowStart={1}
              rowSpan={12}
              noBorderRadius={true}
              maxWidth="250px"
              showShadow={false}
              underlineLength="0"
              content={() => (
                <Flex fullWidth marginBottom="85px">
                  <Description>
                    <b>Communication is key!</b>
                    <br />
                    Which is why we’ve built
                    <br />
                    our communication <br />
                    platform to help you
                    <br />
                    engage with your
                    <br />
                    community and stay in <br />
                    contact with residents.
                  </Description>
                </Flex>
              )}
            />
            {Object.values(cardData).map(props => (
              <CommunicationCard key={shortid()} {...props} />
            ))}
          </Grid>
        )}
      />
    )
  }
}
