// @flow
import get from 'lodash/get'
import every from 'lodash/every'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import { getPaginationData } from '../../helpers/selectors/data'
import { capitalizeFirstLetter } from '../../../utils/string'

export const getPets = (state: Object, filter: string) => {
  let pets = get(state, ['registration', 'pets', 'data']) || []
  if (filter === 'need-approval') {
    pets = pets.filter(pet => pet.status === 'pending')
  }

  return pets
    ? pets.map(pet => ({
        id: pet.id,
        name: pet.name,
        type: pet.type,
        owner: `${pet.resident_first_name} ${pet.resident_last_name}`,
        unit: pet.unit_name,
        lastVaccinated: pet.last_vaccination_date,
        status: pet.status
      }))
    : null
}

export const getPet = (state: Object) => {
  const pet = get(state, ['registration', 'pet']) || {}
  const owner = [pet.resident_first_name, pet.resident_last_name]
    .filter(x => x != null)
    .join(' ')
  return {
    id: pet.id,
    name: pet.name,
    type: pet.type,
    owner,
    ownerId: pet.resident_user_id,
    unit: pet.unit_name,
    lastVaccinated: pet.last_vaccination_date,
    status: pet.status,
    photo: pet.photo_url,
    birthday: pet.birthday,
    breed: pet.breed,
    color: pet.color || [],
    weight: pet.weight,
    size: pet.size,
    vaccinationRecord: pet.vaccination_record,
    houseBroken: pet.housebroken ? 'Yes' : 'No',
    serviceTherapyAnimal: pet.therapy ? 'Yes' : 'No',
    resolutionDate: pet.resolution_date,
    submittedDate: pet.inserted_at
  }
}

export const petForApi = (pet: Object) => {
  pet = {
    id: pet.id,
    name: pet.name,
    type: pet.type,
    unit_id: pet.unitId,
    unit_name: pet.unit,
    resident_user_id: pet.ownerId,
    last_vaccination_date: pet.lastVaccinated,
    status: pet.status,
    photo: pet.photo,
    birthday: pet.birthday,
    breed: pet.breed,
    color: pet.color,
    weight: pet.weight,
    size: pet.size,
    vaccination_record: pet.vaccinationRecord,
    housebroken: pet.houseBroken == null ? null : pet.houseBroken === 'Yes',
    therapy:
      pet.serviceTherapyAnimal == null
        ? null
        : pet.serviceTherapyAnimal === 'Yes'
  }
  return omitBy(pet, isNil)
}

const getPetSupported = (state: Object, key: string): Array<Object> => {
  const emptyArray: Array<any> = []

  const data = get(state, [
    'registration',
    `pets${capitalizeFirstLetter(key)}`,
    key
  ])

  if (data == null) return emptyArray
  return Object.entries(data).map(([id, name]) => {
    return { id, name }
  })
}

export const getPetsColors = (state: Object) => getPetSupported(state, 'colors')

export const getPetsSizes = (state: Object) => getPetSupported(state, 'sizes')

export const getPetsStatuses = (state: Object) => {
  const status = { id: 'status', name: 'STATUS', disabled: true }
  const statuses = (getPetSupported(state, 'statuses') || {}).filter(
    ({ id }) => id !== 'is_deleted'
  )

  return [status, ...statuses]
}

export const getPetsTypes = (state: Object) => {
  const petTypes = { id: 'type', name: 'TYPE' }
  const types = getPetSupported(state, 'types')

  return [petTypes, ...types]
}

export const hasPets = (state: Object) => {
  return get(state, ['registration', 'pets', 'data']) != null
}

export const hasPet = (state: Object) => {
  return get(state, ['registration', 'pet']) != null
}

export const hasPetsSupportedData = (state: Object) => {
  return every(
    ['colors', 'sizes', 'statuses', 'types'],
    key =>
      get(state, ['registration', `pets${capitalizeFirstLetter(key)}`, key]) !=
      null
  )
}

export const getPetsPaginationInfo = (state: Object, filter: string) => {
  return filter !== 'need-approval'
    ? getPaginationData(state, ['registration', 'pets'])
    : []
}

export const visitorsEndDateDropDownOptions = [
  { id: 'default', name: 'END DATE' },
  { id: 'end_date', name: 'End Date/asc' },
  { id: 'desc', name: 'End Date/desc' }
]

export const visitorsStartDateDropDownOptions = [
  { id: 'default', name: 'START DATE' },
  { id: 'start_date', name: 'START Date/asc' },
  { id: 'desc', name: 'START Date/desc' }
]

export const getValidPetTypes = (state: Object) => {
  const types = get(state, ['registration', `petsTypes`, 'types'])

  return types ? Object.values(types) : {}
}

export const getValidPetStatuses = (state: Object) => {
  const statuses = get(state, ['registration', 'petsStatuses', 'statuses'])

  return statuses ? Object.values(statuses) : {}
}
