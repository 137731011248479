import React from 'react'
import { Route } from 'react-router-dom'
import GeneralInfoContainer from '../../views/UserManagement/UserProfile/GeneralInfo/GeneralInfoContainer'
import LeaseInfoContainer from '../../views/UserManagement/UserProfile/LeaseInfo/LeaseInfoContainer'
import Pets from '../../views/UserManagement/UserProfile/Pets'
import VisitorsContainer from '../../views/UserManagement/UserProfile/Visitors/VisitorsContainer'
import VehiclesContainer from '../../views/UserManagement/UserProfile/Vehicles/VehiclesContainer'
import SurveysContainer from '../../views/UserManagement/UserProfile/Surveys/SurveysContainer'

const UserProfileTabRoutes = () => (
  <React.Fragment>
    <Route
      exact
      path="/properties/:propertyId/user-management/profile/:userId"
      component={GeneralInfoContainer}
    />
    <Route
      exact
      path="/properties/:propertyId/user-management/profile/:userId/general"
      component={GeneralInfoContainer}
    />
    <Route
      path="/properties/:propertyId/user-management/profile/:userId/lease"
      component={LeaseInfoContainer}
    />
    <Route
      path="/properties/:propertyId/user-management/profile/:userId/pets"
      component={Pets}
    />
    <Route
      path="/properties/:propertyId/user-management/profile/:userId/vehicles"
      component={VehiclesContainer}
    />
    <Route
      path="/properties/:propertyId/user-management/profile/:userId/visitors"
      component={VisitorsContainer}
    />
    <Route
      path="/properties/:propertyId/user-management/profile/:userId/survey"
      component={SurveysContainer}
    />
  </React.Fragment>
)

export default UserProfileTabRoutes
