import fromPairs from 'lodash/fromPairs'

const scheduleDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

export const generateScheduleItems = scheduleItemContent =>
  fromPairs(scheduleDays.map(day => [day, scheduleItemContent]))

export const mergeScheduleItems = (
  originalScheduleItems,
  scheduleItemUpdates
) =>
  fromPairs(
    Object.entries(originalScheduleItems).map(([k, v]) => [
      k,
      { ...v, ...scheduleItemUpdates[k] }
    ])
  )
