import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuList from '@material-ui/core/MenuList'
import Typography from '@material-ui/core/Typography'

export const UnreadPointer = styled('div')`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: ${({ theme, show }) =>
    show ? theme.palette.alert.error : 'transparent'};
  margin-top: 30%;
  margin-right: 7px;
`

export const ChatMenuItem = styled(
  ({ color, isChatHeader, height, isChatListItem, ...other }) => (
    <MenuItem {...other} classes={{ selected: 'selected' }} />
  )
)`
  height: ${props => (!props.isChatHeader ? '4em' : '5em')};
  display: flex;
  margin-right: 20px;
  justify-content: space-between;
  padding-left: 2em;
  padding-right: 0;
  padding-bottom: 5px;
  padding-top: 5px;
  &&.selected {
    background-color: ${({ isChatHeader }) =>
      !isChatHeader && 'rgb(89, 192, 155, .15);'};
  }
  &:hover {
    background: ${({ isChatHeader, theme }) =>
      !isChatHeader ? theme.palette.neutrals.mediumGray : 'none'};
    box-shadow: none;
    cursor: ${({ isChatListItem }) => (isChatListItem ? 'pointer' : 'default')};
  }
  & > div {
    align-self: center;
  }
  @media only screen and (max-width: 1024px) {
    padding: 0em 1em;
  }
  ${({ height }) =>
    height &&
    `
height: ${height};
`};
`

export const ChatMenuList = styled(MenuList)`
  height: 100%;
  .scroll-down-behaviour,
    .scroll-up-behaviour {
      padding: 0 0 1em 2em;
  }
  }
`

export const ChatMenuText = styled(ListItemText)`
  span {
    color: ${({ theme }) => theme.palette.common.white};
    display: flex;
    justify-content: flex-end;
  }
`

export const ChatMenuLink = styled(Link)`
  text-decoration: none;
`

export const ChatMenu = styled(Drawer)`
  overflow: hidden;

  && .drawerpaper {
    z-index: 160;
    position: absolute;
    white-space: nowrap;
    height: 90%;
    transition-duration: 0s;
    transition: all 0.3s;
    left: 5.5em;
    width: 28%;
    min-width: 18em;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    top: 100px;
  }

  && .drawerpaperclose {
    z-index: 160;
    white-space: nowrap;
    transition-duration: 0s;
    position: absolute;
    transition: all 0.3s;
    height: 65px;
    left: 5.5em;
    width: 28%;
    min-width: 18em;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  }
  [role='tab'].unread {
    padding-left: 1em;
  }
  [role='tab'].unread:before {
    content: ' ';
    position: absolute;
    left: 0;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.alert.error};
  }
`

export const IconSection = styled('div')`
  background: ${({ theme }) => theme.palette.primary.dark};
  position: absolute;
  width: 60px;
  height: 100%;
  right: 0;
`

export const MainHeading = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
`

export const Underline = styled('div')`
  width: 75px;
  height: 2px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutrals.mediumGray};
`

export const Conversations = styled('span')`
  color: ${({ theme }) => theme.palette.text.main};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  font-size: ${({ theme }) => theme.custom.font.size.xl36};
`

export const FilterChatItem = styled(ChatMenuItem)`
  padding: 0px 1em;
  padding-left: 30px;
`

export const TextPreview = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  display: flex;
  width: 100%;
  @media only screen and (min-width: 2000px) {
    font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  }
  @media only screen and (min-width: 3000px) {
    font-size: ${({ theme }) => theme.custom.font.size.lg};
  }
  margin-left: 3.6em;
`

export const TimeAndSenderName = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  overflow: hidden;
  min-height: 28px;

  & > div:first-child {
    margin-right: 0.5em;
  }

  display: flex;
  width: 90%;
  align-items: center;

  svg {
    margin: 0em 0.4em;
  }

  @media only screen and (min-width: 2000px) {
    font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  }
  @media only screen and (min-width: 3000px) {
    font-size: ${({ theme }) => theme.custom.font.size.lg};
  }
  @media only screen and (max-width: 1200px) {
    max-width: 85%;
    overflow: hidden;
  }
`

export const Sender = styled('span')`
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
`
export const Pin = styled('div')`
  position: absolute;
  right: 0;
`
export const NoItemMessage = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  text-transform: lowercase;
  & > p:first-letter {
    text-transform: uppercase;
  }
  font-size: ${({ theme }) => theme.custom.font.size.md};
  color: ${({ theme }) => theme.palette.common.black};
`
