import { combineReducers } from 'redux'
import { templateReducer as template } from './template'
import { templatePermissionReducer as templatePermission } from './templatePermission'
import { templatesReducer as templates } from './templates'
import { historyReducer as history } from './history'
import { futureReducer as future } from './future'
import { groupsReducer as groups } from './groups'
import { messageReducer as message } from './message'
import { groupEventsReducer as groupEvents } from './groupEvents'
import { replacementOptionsReducer as replacementOptions } from './replacementOptions'
import { messagesSentTodayReducer as messagesSentTodayCount } from './messagesSentTodayCount'
import { groupAutomationReducer as groupAutomation } from './groupAutomation'

const groupMessaging = combineReducers({
  template,
  templatePermission,
  templates,
  future,
  history,
  groups,
  message,
  groupEvents,
  replacementOptions,
  messagesSentTodayCount,
  groupAutomation
})

export default groupMessaging
