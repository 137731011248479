import React from 'react'
import { HandleText, HandleThumb } from './styles'
import { HandleType } from './types'

const getClassName = disabled => `${disabled ? `handle-disabled` : ''}`

const Handle = ({
  handle: { id, value, percent },
  domain: [min, max],
  getHandleProps,
  onMouseDown,
  disabled,
  theme,
  percentValue,
  vertical
}: HandleType) => {
  return (
    <HandleThumb
      onMouseDown={args => {
        typeof onMouseDown === 'function'
          ? onMouseDown()
          : getHandleProps(id).onMouseDown(args)
      }}
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      vertical={vertical}
      className={getClassName(disabled)}
      style={{
        padding: '10px',
        left: `${percentValue ? percentValue : percent}%`
      }}>
      <HandleText>{value}</HandleText>
    </HandleThumb>
  )
}

export default Handle
