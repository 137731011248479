import { connect } from 'react-redux'
import Vendors from './Vendors'
import withFetchData from 'zego-shared/views/withFetchData'
import {
  fetchLockCodesVendorsAction,
  fetchNextLockCodesVendors
} from 'zego-shared/store/lockCodes/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {
  getLockCodesVendors,
  getVendorsCount,
  getVendorsSearchTerm
} from 'zego-shared/store/lockCodes/selectors'

const VendorsWithFetchData = withFetchData(Vendors)
const fetchingSelector = createIsFetchingSelector(['lockcodes'])

const mapStateToProps = (
  state,
  {
    match: {
      params: { propertyId }
    }
  }
) => {
  return {
    vendors: getLockCodesVendors(state),
    count: getVendorsCount(state),
    propertyId,
    isFetching: fetchingSelector(state),
    searchTerm: getVendorsSearchTerm(state)
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { propertyId }
    }
  }
) => ({
  fetchData: () => {
    dispatch(fetchLockCodesVendorsAction({ propertyId }))
  },
  fetchNextVendors: (page, name) => {
    dispatch(fetchNextLockCodesVendors(propertyId, page, name))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorsWithFetchData)
