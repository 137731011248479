import styled from '@emotion/styled'
import Button from '@material-ui/core/Button'

export const FabButton = styled(Button)`
  color: ${({ theme }) => theme.palette.common.white};
  width: 14px;
  height: 14px;
  min-height: 14px;
`

export const FabButtonWrap = styled('div')`
  display: flex;
  justify-content: flex-end;
`
