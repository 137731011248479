// @flow
import React, { Fragment } from 'react'
import type { Theme } from '../../../../theme'
import { ImageBlock, ImageWrapper } from './styles'

type Props = {
  image?: Object | null,
  theme: Theme
}

const ImageSection = ({ image }: Props) => (
  <ImageBlock
    columnStart={1}
    columnSpan={5}
    rowStart={1}
    rowSpan={9}
    image={image}>
    <Fragment>
      <ImageWrapper>
        <img src={image ? image.url : null} alt={image ? image.url : null} />
      </ImageWrapper>
    </Fragment>
  </ImageBlock>
)

export default ImageSection
