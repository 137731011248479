// @flow

import React, { useState } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import { CommonIcon, NavIcon, RadiobuttonIcon, SearchIcon } from '@icons'
import Button from '@Common/Cards/CardButton'
import { CarouselUnit } from '@Common/CarouselList'
import Flex from '@Common/Flex'
import { FlexItem } from '@Common/Flex/FlexDirection'
import { convertHexToRGB } from '../../../utils/commonUtils'
import { Theme } from '../../../theme'
import { UnitListItem } from './styles'
import { FabButton } from '@Common/Button'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import {
  CodeDetailsRightContainer,
  SearchField,
  UnitsFloatingDiv,
  NoUnitText
} from './styles'
import { RowsWrap, Header, ButtonSet } from '../VendorLockCodes/styles'

type Props = {
  units: Object[],
  unitList: Object[],
  theme: Theme,
  searchTerm: string,
  removeMode: boolean,
  addMode: boolean,
  removeMode: boolean,
  isUnitType: boolean,
  isFetchUnits: boolean,
  unitIsSelected: (value: number) => boolean,
  handleSearchChange: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  handleKeyPress: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  unselectUnit: (value: number) => void,
  selectUnit: (value: number) => void,
  openAddMode: () => void,
  openRemoveMode: () => void,
  closeAddMode: () => void,
  closeRemoveMode: () => void,
  goBack: () => void,
  saveDetails: () => mixed,
  searchUnits: () => void,
  handleListItemClick: (unit: Object, index?: number) => mixed
}

const RightSection = ({
  units,
  unitList,
  addMode,
  searchTerm,
  theme,
  removeMode,
  unitIsSelected,
  handleSearchChange,
  handleKeyPress,
  unselectUnit,
  selectUnit,
  openAddMode,
  openRemoveMode,
  closeAddMode,
  closeRemoveMode,
  goBack,
  saveDetails,
  searchUnits,
  handleListItemClick,
  isUnitType,
  isFetchUnits
}: Props) => {
  const hasUnits = units.length > 0
  const [saveDetailsDisabled, toggleSaveDetailsDisabled] = useState(false)

  const handleSaveDetails = async () => {
    toggleSaveDetailsDisabled(true)
    try {
      await saveDetails()
    } catch(err) {
      toggleSaveDetailsDisabled(false)
    } finally {
      toggleSaveDetailsDisabled(false)
    }
  }
  
  return (
    <CodeDetailsRightContainer isUnit={isUnitType}>
      <Flex fullWidth fullHeight>
        {isUnitType && (
          <FlexItem flex={1} displayFlex direction="column">
            <Header big>
              <div>
                {hasUnits && units.length} UNIT{hasUnits ? 'S' : ''}
              </div>
            </Header>
            <Flex direction="column" paddingTop="24%" fullHeight fullWidth>
              <NoUnitText isShow={!hasUnits && !addMode}>
                0 units are currently attached to this code. The access type you
                have chosen will require you to attach at least one unit to
                finish setting up this lock code.
              </NoUnitText>
              {addMode && (
                <React.Fragment>
                  <SearchField
                    value={searchTerm}
                    type="search"
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon
                            name="search"
                            height="20px"
                            width="18px"
                            onClick={searchUnits}
                            className="searchIcon"
                          />
                        </InputAdornment>
                      )
                    }}
                    onChange={handleSearchChange}
                    onKeyPress={handleKeyPress}
                  />
                  {unitList && searchTerm && (
                    <UnitsFloatingDiv>
                      {!isFetchUnits &&
                        unitList.map((unit, index) => {
                          const { name, hubStatus } = unit
                          return (
                            <UnitListItem
                              key={index}
                              onClick={() => handleListItemClick(unit, index)}>
                              <FlexItem flex={3}>{name}</FlexItem>
                              <FlexItem flex={1} className={`hub-${hubStatus}`}>
                                <NavIcon
                                  name="zego smart"
                                  width="1em"
                                  height="1em"
                                  fill={
                                    theme.palette[
                                      hubStatus === 'on' ? 'secondary' : 'grey'
                                    ].main
                                  }
                                />
                                &nbsp;HUB {hubStatus.toUpperCase()}
                              </FlexItem>
                            </UnitListItem>
                          )
                        })}
                      {isFetchUnits && <p>Loading...</p>}
                    </UnitsFloatingDiv>
                  )}
                </React.Fragment>
              )}
              <RowsWrap editPage>
                {units.map(({ name, hubStatus, id }, index) => {
                  const isSelected = unitIsSelected(id)
                  return (
                    <CarouselUnit key={index}>
                      <Flex fullWidth>
                        <FlexItem
                          flex={1.5}
                          displayFlex
                          alignItemsCenter
                          flexEnd
                          style={{ paddingRight: '1em' }}>
                          {removeMode && (
                            <RadiobuttonIcon
                              onClick={() =>
                                isSelected ? unselectUnit(id) : selectUnit(id)
                              }
                              name={isSelected ? 'selected' : 'unselected'}
                              width="2em"
                              height="2em"
                              strokeWidth={2}
                            />
                          )}
                        </FlexItem>
                        <FlexItem flex={1} displayFlex alignItemsCenter>
                          {name}
                        </FlexItem>
                        <FlexItem
                          alignItemsCenter
                          flex={2}
                          displayFlex
                          flexEnd
                          className={`hub-${hubStatus}`}>
                          <NavIcon
                            name="zego smart"
                            width="1em"
                            height="1em"
                            fill={
                              theme.palette[
                                hubStatus === 'on' ? 'secondary' : 'grey'
                              ].main
                            }
                          />
                          &nbsp;HUB {hubStatus.toUpperCase()}
                        </FlexItem>
                      </Flex>
                    </CarouselUnit>
                  )
                })}
              </RowsWrap>
              <FlexItem
                flex={1}
                style={{ paddingBottom: '2em' }}
                displayFlex
                direction="column"
                flexEnd>
                {(removeMode || addMode) && (
                  <Button
                    text={removeMode ? 'REMOVE' : 'DONE'}
                    background={theme.palette.blue.main}
                    onClick={removeMode ? closeRemoveMode : closeAddMode}
                    width="75%"
                    height="2.5em"
                  />
                )}
                {!removeMode && !addMode && (
                  <React.Fragment>
                    {hasUnits && (
                      <Button
                        text="REMOVE A UNIT"
                        background={convertHexToRGB(
                          theme.palette.blue.main,
                          0.8
                        )}
                        onClick={openRemoveMode}
                        width="75%"
                        height="2.5em"
                      />
                    )}
                    <br />
                    <Button
                      text="ADD A UNIT"
                      background={theme.palette.blue.main}
                      width="75%"
                      height="2.5em"
                      onClick={openAddMode}
                      style={{ marginTop: '21px' }}
                    />
                  </React.Fragment>
                )}
              </FlexItem>
            </Flex>
          </FlexItem>
        )}
        <ButtonSet>
          <FabButton
            color="primary"
            background={theme.palette.secondary.yellow.solarFlare}
            onClick={goBack}
            component={() => (
              <KeyboardBackspace
                style={{ color: theme.palette.primary.dark }}
                height="0.5em"
                width="0.5em"
              />
            )}
          />
          <FabButton
            disabled={saveDetailsDisabled}
            color="primary"
            background="transparent"
            outlined
            onClick={handleSaveDetails}
            component={() => (
              <CommonIcon
                name="tick"
                width="1.3em"
                height="1.3em"
                stroke={theme.palette.primary.navy.midnight}
                strokeWidth={2}
              />
            )}
          />
        </ButtonSet>
      </Flex>
    </CodeDetailsRightContainer>
  )
}

export default RightSection
