import styled from '@emotion/styled'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

export const TableRowStyled = styled(TableRow, {
  shouldForwardProp: (prop) => !['columnLength', 'noData'].includes(prop),
})`
  height: 4.5vh;
  border-bottom: 1px solid
    ${({ theme }) => `${theme.palette.neutrals.coolGray} !important;`};

  ${({ noData, noBorder }) =>
    (noData || noBorder) && `border-bottom: none !important;`};

  ${({ headerBorderTop, theme }) =>
    headerBorderTop &&
    `
    border-bottom: none !important;
    border-top: 1px solid ${theme.palette.neutrals.warmGray};
  `}
`

export const TableCellStyled = styled(TableCell, {
  shouldForwardProp: (prop) => !['columnLength', 'noData'].includes(prop),
})`
  padding-right: 0.5em;
  padding-left: 0.6em;
  padding-top: 0em;
  padding-bottom: 0em;
  ${({ flex, columnLength }) =>
    flex && `min-width: ${(100 / columnLength) * flex}%`};

  ${({ noData, noBorder }) => (noData || noBorder) && `border: none;`};

  ${({ noData }) =>
    noData &&
    `padding: 2em;
  text-align: center;`};
`

export const TableBodyStyled = styled(TableBody)`
  & > tr:last-child {
    & > td {
      border-bottom: none;
    }
  }

  ${({ alternateColor, theme }) =>
    alternateColor &&
    `
    border-bottom: none !important;
    tr:nth-child(even) {
      background: ${theme.palette.neutrals.lightGray};
    }
  `};
`

export const TableHeadStyled = styled(TableHead)`
  & > tr {
    & > th {
      border-bottom: none;
    }
  }
`

export const DotWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${({ numberOfPages }) => `width: ${numberOfPages * 2.5}%`};
`

export const DotStyled = styled('div')`
  height: 0.5em;
  width: 0.5em;
  background-color: ${({ theme, isActive }) =>
    isActive
      ? `${theme.palette.primary.green.mint}`
      : `${theme.palette.neutrals.deepGray}`};
  border-radius: 50%;
  cursor: pointer;
`

export const KeyboardArrowLeftStyled = styled(KeyboardArrowLeft)`
  color: ${({ theme }) => theme.palette.neutrals.deepGray};
`

export const KeyboardArrowRightStyled = styled(KeyboardArrowRight)`
  color: ${({ theme }) => theme.palette.neutrals.deepGray};
`
