import { call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken, getUnitId } from '../../authorization/selectors'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  fetchGroupMessagesSentTodayAction,
  fetchGroupMessagesSentTodayStart,
  fetchGroupMessagesSentTodayError,
  fetchGroupMessagesSentTodaySuccess
} from './actions'
import { getCompanyId, getPropertyId } from '../../select/properties/selectors'

export function* fetchGroupMessagesSentTodayCount({ resource }) {
  try {
    yield put(fetchGroupMessagesSentTodayStart())
    const authToken = yield select(getToken)
    const resources = {
      units: yield select(getUnitId),
      properties: yield select(getPropertyId),
      companies: yield select(getCompanyId)
    }
    const response = yield call(
      api.getGroupMessagesSentTodayCount,
      authToken,
      resource,
      resources[resource]
    )
    yield put(fetchGroupMessagesSentTodaySuccess(response))
  } catch (error) {
    yield put(fetchGroupMessagesSentTodayError(error))
  }
}

function* watchFetchGroupMessagesSentToday() {
  yield takeLatest(
    fetchGroupMessagesSentTodayAction().type,
    fetchGroupMessagesSentTodayCount
  )
}

export default [watchFetchGroupMessagesSentToday()]
