// @flow
import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { RadiobuttonIcon } from '@icons'
import { CommonIcon } from '@icons'
import { CustomTextField } from '../CreateGroupMessage/styles'
import { MessageDetails, EditModeContainer } from './styles'
import { EditModeHeadProps } from './types'
import { capitalizeFirstLetter } from '../../../../utils'

const EditModeHead = ({
  name,
  theme,
  active,
  handleChange,
  currentTab,
  resourceType,
  permissions,
  isCreate,
  handlePermissionChange,
  isDefault
}: EditModeHeadProps) => {
  permissions = permissions ? permissions : []
  return (
    <EditModeContainer>
      <CustomTextField
        className="edit-mode-name"
        value={name}
        name="name"
        label="template name"
        onChange={isDefault? null : handleChange}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={isDefault? {} : {
          disableUnderline: false,
          endAdornment: (
            <InputAdornment position="end">
              <CommonIcon
                name="edit"
                height="14px"
                width="12px"
                fill={
                  active === 'name'
                    ? theme.palette.secondary.main1
                    : theme.palette.grey.main
                }
              />
            </InputAdornment>
          )
        }}
      />
      <MessageDetails
        style={{ color: theme.palette.common.black, paddingBottom: '0.5em' }}>
        {!isCreate ? (
          permissions && permissions.length > 1 ? (
            <RadioGroup
              classes={{ root: `radioGroupRoot` }}
              aria-label="Delivery Method"
              name="deliveryMethod"
              value={resourceType}
              onChange={handlePermissionChange}>
              {permissions.map((field, index) => (
                <FormControlLabel
                  key={index}
                  classes={{ root: 'checkBoxGroupFormControl' }}
                  value={field}
                  control={
                    <Radio
                      checkedIcon={
                        <RadiobuttonIcon
                          width={'1.5em'}
                          height={'1.1em'}
                          name="selected"
                        />
                      }
                      icon={
                        <RadiobuttonIcon
                          width={'1.5em'}
                          height={'1.1em'}
                          name="unselected"
                        />
                      }
                    />
                  }
                  label={capitalizeFirstLetter(field)}
                />
              ))}
            </RadioGroup>
          ) : (
            <span>{resourceType} template</span>
          )
        ) : (
          <RadioGroup
            classes={{ root: `radioGroupRoot` }}
            aria-label="Delivery Method"
            name="deliveryMethod"
            value={resourceType}
            onChange={handlePermissionChange}>
            {permissions.map((field, index) => (
              <FormControlLabel
                key={index}
                classes={{ root: 'checkBoxGroupFormControl' }}
                value={field}
                control={
                  <Radio
                    checkedIcon={
                      <RadiobuttonIcon
                        width={'1.5em'}
                        height={'1.1em'}
                        name="selected"
                      />
                    }
                    icon={
                      <RadiobuttonIcon
                        width={'1.5em'}
                        height={'1.1em'}
                        name="unselected"
                      />
                    }
                  />
                }
                label={capitalizeFirstLetter(field)}
              />
            ))}
          </RadioGroup>
        )}
      </MessageDetails>
    </EditModeContainer>
  )
}

export default EditModeHead
