import styled from '@emotion/styled'
import { IconButton, ListItemText, ListItem, Button } from '@material-ui/core'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import Flex from '@Common/Flex'
import { CaptionHeading } from '../styles'

export const SelectionMenuFlex = styled(Flex)`
  font-size: 15px;
  padding-left: 10px;
  padding-top: 15px;
`

export const SelectionMenuBackButtonBG = styled(IconButton)`
  background-color: ${({ theme }) => theme.palette.primary.green.mint};
  width: 1.7em;
  height: 1.7em;
`
export const SubcategoryHeading = styled(CaptionHeading)`
  font-size 15px;
  font-weight: bold;
  padding-left: 10px;
  padding-top: 2px;
`

export const SubcategoryButtonText = styled(CaptionHeading)`
  color: ${({ theme }) => theme.palette.neutrals.deepGray};
`

export const SubcategoryListItemText = styled(ListItemText)`
  padding-left: 200px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutrals.coolGray};
`
export const SubcategoryListItem = styled(ListItem)`
  left: 2.5%;
  width: 95%;
`

export const SelectionMenuBack = styled(NavigateBeforeIcon)`
  color: ${({ theme }) => theme.palette.common.white};
`

export const OptionLabelFlex = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutrals.coolGray};
`

export const OptionLabelText = styled('span')`
  padding-bottom: 5px; 
  padding-left: 5PX;
`

export const SubcategoryButton = styled(Button)`
  padding-bottom: 0px; 
  padding-top: 0px;
`
