import styled from '@emotion/styled'
import MaterialListItem from '@material-ui/core/ListItem'
import MaterialButton from '@material-ui/core/Button'
import Btn from '@Common/Button'

export const EmptySpace = styled('div')`
  height: 40px;
`

export const FabButton = styled(MaterialButton)`
  position: absolute;
  right: 1.5em;
  bottom: 3.5em;
  z-index: 20;
  background-color: ${({ theme }) => theme.palette.primary.dark};
`

export const Title = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.lg};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  padding-bottom: 60px;
`

export const Content = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  padding: 0 0 50px 0;
  line-height: 24px;
  ${({ theme }) => {
    return `
    ${Image} {
      width: 70%;
      padding-top: 35%;
      background: ${theme.palette.neutrals.mediumGray};
      top: 25%;
      right:0;
      clip-path: circle(30% at 80%);
    `
  }};
`
export const Button = styled(Btn)`
  margin-top: 20%;
`
export const Image = styled('span')`
  position: absolute;
  background-size: cover;
  background-image: url(${({ imageSrc }) => imageSrc});
`

export const ListItem = styled(MaterialListItem)`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  padding-left: 10px;
`

export const Specs = styled('div')`
  display: flex;
  white-space: nowrap;
  & > * {
    margin-right: 2em;
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    color: ${({ theme }) => theme.palette.grey.main};
  }

  .battery-bar {
    background: ${({ theme }) => theme.palette.primary.green.mint};
  }

  .battery-root {
    width: 100%;
    height: 50%;
    border-radius: 1em;
    box-shadow: ${({ theme }) =>
      `0 0 0 2px ${theme.palette.primary.green.mint}`};
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`

export const BatteryPercentWrap = styled('div')`
  width: 3em;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const BatteryLevelText = styled('div')`
  margin-left: -1em;
  color: ${({ theme }) => theme.palette.primary.green.mint};
  cursor: pointer;
`

export const PercentageText = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.xxxl};
  color: ${({ theme }) => theme.palette.primary.green.mint};
  display: flex;
  align-items: center;
`

export const Superscript = styled('span')`
  font-size: 60%;
  margin-top: -30%;
  color: ${({ theme }) => theme.palette.primary.green.mint};
`

export const Screen = styled('div')`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.common.white};
  padding: 1.5em;
  display: flex;
  flex-direction: column;
`

export const BackButton = styled('div')`
  width: 4em;
  color: ${({ theme }) => theme.palette.primary.green.mint};

  &:hover {
    cursor: pointer;
  }
`

export const TitleCustom = styled('div')`
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  font-size: ${({ theme }) => theme.custom.font.size.xl};
  margin-bottom: 1em;
`

export const Number = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.lg};
  color: ${({ theme }) => theme.palette.primary.green.mint};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  margin-right: 1em;
`

export const ForceText = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.grey.main};
  &:hover {
    cursor: pointer;
  }

  span {
    color: ${({ theme }) => theme.palette.primary.green.mint};
  }
`

export const ListWrap = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  * {
    font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  }
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  display: flex;
  width: 100%;
`
