// @flow
import React from 'react'
import type { Match, RouterHistory } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import MoreMenu from '@Common/MoreMenu'
import DropDown from '@Common/DropDown'
import SalesForceModal from '@Common/SalesForceModal'
import Search from '../../Common/Search'
import { Separator, StyledAppBar, Typography } from './styles'
import type { MenuItemType } from '@Common/DropDown'

const categoryKeys = {
  Chat: 'chat',
  Summary: 'summary',
  'Zego Smart': 'smart',
  'User Management': 'user-management',
  Users: 'users'
}

type Props = {
  companyName: string,
  menuData: Array<MenuItemType>,
  companies: { byId: [], data: [] },
  isSuperAdmin: boolean,
  handleSearchEnter: (searchText: ?string) => {},
  match: Match,
  history: RouterHistory,
  selectedProperty: number,
  selectCompany: () => void,
  selectedCompany: { id: number },
  handleChange: () => void,
  wasRecentlySearched: (url: string) => boolean,
  addToSearchHistory: (category: string, url: string) => void,
  hasMoreCompanies: boolean,
  nextCompanies: number,
  fetchCompanies: number => void,
  hasMoreProperties: boolean,
  nextProperties: number,
  fetchProperties: number => void,
  isFetching: boolean,
  showAmenityReservations: boolean,
  showSnackbar: string => void,
  paymentEnabled: boolean
}

type State = {
  searchText: ?string,
  showSalesForceModal: boolean
}

type Result = {
  name: string,
  category: string,
  url: string
}

export class AppBar extends React.Component<Props, State> {
  state = {
    searchText: '',
    showSalesForceModal: false
  }

  onResultClick = ({ name, category, url }: Result) => {
    const { wasRecentlySearched, addToSearchHistory, history } = this.props

    if (!wasRecentlySearched(url)) {
      addToSearchHistory(categoryKeys[category], [name, url].toString())
    }

    history.push(`/properties/${url}`)
  }

  loadMoreCompanies = () => {
    const { nextCompanies, fetchCompanies } = this.props
    fetchCompanies(nextCompanies)
  }

  loadMoreProperties = () => {
    const { nextProperties, fetchProperties } = this.props
    fetchProperties(nextProperties)
  }

  onSalesForceSelect = () => {
    this.setState({
      showSalesForceModal: true
    })
  }

  closeSalesForceModal = (showSuccess: boolean = false) => {
    const {
      showSnackbar
    } = this.props
    
    this.setState({
      showSalesForceModal: false
    })

    if(showSuccess) {
      showSnackbar('Your case has been submitted.')
    }
  }

  setSelectDropdownColor = (showAmenityReservations: boolean) => {
    const {
      match: { url }
    } = this.props
    const [, , , mainPath, subPath] = url.split('/')
    const validPages = ['registration', 'packages', 'lock-codes', 'work-orders']
    if (!showAmenityReservations) validPages.push('amenities')

    const validSubPaths = {
      'lock-codes': ['vendors', 'property', 'residents'],
      'work-orders': ['summary']
    }
    const subPaths = validSubPaths[mainPath] || []

    return (
      validPages.includes(mainPath) && (!subPath || subPaths.includes(subPath))
    )
  }

  render() {
    const {
      companyName,
      menuData,
      companies,
      isSuperAdmin,
      selectedProperty,
      selectedCompany,
      handleChange,
      selectCompany,
      hasMoreCompanies,
      hasMoreProperties,
      isFetching,
      showAmenityReservations
    } = this.props

    return (
      <StyledAppBar position='static'>
        <Toolbar>
          <Typography component='div'>
            {isSuperAdmin ? (
              <DropDown
                classes={
                  this.setSelectDropdownColor(showAmenityReservations) && {
                    root: 'green-dropdown',
                    icon: 'green-dropdown'
                  }
                }
                data={companies.data}
                handleChange={selectCompany}
                selectedItem={selectedCompany}
                hasMore={hasMoreCompanies}
                loadMore={this.loadMoreCompanies}
                isFetching={isFetching}
              />
            ) : (
              companyName
            )}{' '}
                <Separator>/</Separator>{' '}
            <span className='right-part'>
              <DropDown
                classes={
                  this.setSelectDropdownColor(showAmenityReservations) && {
                    root: 'green-dropdown',
                    icon: 'green-dropdown'
                  }
                }
                data={menuData}
                handleChange={handleChange}
                selectedItem={selectedProperty}
                hasMore={hasMoreProperties}
                loadMore={this.loadMoreProperties}
              />
            </span>
          </Typography>
          <Search onResultClick={this.onResultClick} />
          <MoreMenu paymentEnabled={this.props.paymentEnabled} onSalesForceSelect={this.onSalesForceSelect} />
          <SalesForceModal showModal={this.state.showSalesForceModal} onClose={this.closeSalesForceModal} />
        </Toolbar>
      </StyledAppBar>
    )
  }
}

export default withRouter(AppBar)
