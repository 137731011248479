import { call, select, takeLatest, put } from 'redux-saga/effects'
import isPlainObject from 'lodash/isPlainObject'
import { getToken, getUserId, getUserRole } from '../../authorization/selectors'
import { showSnackbar } from '../../snackbar/actions'
import { hasChanges } from '../../../utils/object'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  failedToFetchGroupMessageTemplatePermission,
} from '../../../utils/messages'
import {
  fetchTemplatePermissionAction,
  fetchTemplatePermissionStart,
  fetchTemplatePermissionSuccess,
  fetchTemplatePermissionError
} from './actions'

export function* fetchTemplatePermission() {
  try {
    yield put(fetchTemplatePermissionStart())
    const authToken = yield select(getToken)
    const userId = yield select(getUserId)
    const userRole = yield select(getUserRole)

    //  Do property_staff check due to core api role check limitations, double refresh and error message otherwise
    const { template_resources: templateResources = []} = (userRole === "property_staff")
      ? {}
      : yield call(
        api.getGroupMessagingTemplatePermission,
        authToken,
        userId
      )
    yield put(fetchTemplatePermissionSuccess(templateResources))
  } catch (error) {
    yield put(fetchTemplatePermissionError(error))
    yield put(showSnackbar(failedToFetchGroupMessageTemplatePermission, 'error'))
    log(`Failed to fetch templatePermission`)
  }
}

function* watchFetchTemplatePermission() {
  yield takeLatest(fetchTemplatePermissionAction().type, fetchTemplatePermission)
}

export default [watchFetchTemplatePermission()]
