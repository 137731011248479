import React, { useState, useEffect } from 'react'
import moment from 'moment'
import ScrollDownButton from '../../Common/ScrollDownButton'
import Loader from '../../Common/Loader'
import { GridItem } from '../../Common/Grid'
import Flex, { FlexItem } from '@Common/Flex'
import Avatar from '@Common/Avatar'
import { getDateFullYear } from 'zego-shared/utils/date'
import SurveyStatsInfoCard from './SurveyStatsInfoCard'
import { withTheme } from 'emotion-theming'
import { PropagateLoader } from 'react-spinners'
import { getProfileUrlOrDefault } from 'zego-shared/utils/profilePicture'

import {
  StyledTable,
  StyledColumn,
  StyledCell,
  StyledLink,
  StyledChip,
  StyledFilterText,
  StyledClearText,
  Loading,
  ShowMoreBtn,
  StyledRowText,
  StyledNoSurveysText,
  SurveysAvailableIndicator,
  StyledFinalCell,
  StyledFinalColumn,
  FilterChipsWrapper,
  AfterTableSpacing
} from '../styles'

const ResidentManagementSurveys = (props) => {
  const {
    residents,
    residentsTotal,
    propertyId,
    theme,
    isFetching,
    fetchResidentManagementSurveysAction,
    fetchNextResidentManagementSurveysAction,
    surveyStats
  } = props

  const [currentPage, setCurrentPage] = useState(1)
  const [selectedFilters, setFilters] = useState({ 'average_score': [], 'surveys_taken': [] })
  const [showLoader, setShowLoader] = useState(false)

  const filters = { 'average_score': ['happy', 'neutral', 'unhappy'], 'surveys_taken': ['completed surveys', 'no completed surveys'] }

  useEffect(() => {
    if (currentPage > 1) {
      fetchNextResidentManagementSurveysAction({ propertyId, page: currentPage, params: selectedFilters })
    }
    // eslint-disable-next-line
  }, [currentPage])

  useEffect(() => {
    setShowLoader(false)
  }, [residents])

  if (isFetching) {
    return <Loader />
  }

  const clearFilters = () => {
    setFilters({ 'average_score': [], 'surveys_taken': [] })
    handlePageChange(1)
    fetchResidentManagementSurveysAction({ propertyId })
  }

  const getNPS = (averageScore) => {
    averageScore = averageScore && Math.round(averageScore)
    switch (true) {
      case (averageScore && averageScore <= 6):
        return `${averageScore} - Unhappy`
      case (averageScore === 7 || averageScore === 8):
        return `${averageScore} - Neutral`
      case (averageScore === 9 || averageScore === 10):
        return `${averageScore} - Happy`
      default:
        return '--'
    }
  }

  const createRows = () => {
    const formattedRows = residents.map((rowData, rowIndex) => {
      const photo = getProfileUrlOrDefault(rowData.picture, rowData.name)
      const surveysLastViewed = localStorage.getItem(`surveyLastViewed_${rowData.user_id}`)
      const viewedSurveys = surveysLastViewed >= rowData.recent_survey_date

      return {
        ID: (
          <FlexItem flex={0.8}>
            <StyledLink to={`/properties/${propertyId}/residents/profile/${rowData.user_id}`}>
              <Avatar size="2.2em" picture={photo} />
            </StyledLink>
          </FlexItem>
        ),
        Resident: <StyledLink to={`/properties/${propertyId}/residents/profile/${rowData.user_id}`}> {rowData.name !== " " ? rowData.name : rowData.email} </StyledLink>,
        Unit: rowData.unit ? <StyledLink to={`/properties/${propertyId}/units/${rowData.unit}/user-management`}>Unit {rowData.unit_name} </StyledLink> : '',
        Status: <StyledRowText>{rowData.account_status}</StyledRowText>,
        AverageReviewScore: <StyledRowText>{getNPS(rowData.average_score)}</StyledRowText>,
        MostRecentSurvey:
          <Flex>
            {rowData.surveys_taken !== 0 && (!surveysLastViewed || !viewedSurveys) && <SurveysAvailableIndicator />}
            <StyledRowText>{rowData.surveys_taken !== 0 ? getDateFullYear(rowData.recent_survey_date) : '--'}</StyledRowText>
          </Flex>,
        NumberOfSurveysTaken: <StyledRowText>{rowData.surveys_taken}</StyledRowText>,
        CompletedSurveys: rowData.surveys_taken !== 0 ?
          <StyledLink
            to={`/properties/${propertyId}/residents/profile/${rowData.user_id}/survey`}
            onClick={() => localStorage.setItem(`surveyLastViewed_${rowData.user_id}`, moment(new Date()).format('YYYY-MM-DD'))}
          >
            View Surveys
          </StyledLink>
          : <StyledNoSurveysText> View Surveys </StyledNoSurveysText>
      }
    })

    const styledRows = formattedRows.map((rowData, rowIndex) => {
      return Object.entries(rowData).map(([key, value], index) => {
        if (index === 7) {
          return { key: key, value: (<StyledFinalCell index={index + 1}>{value}</StyledFinalCell>) }
        }
        return {
          key: key, value: (<StyledCell index={index + 1}>{value}</StyledCell >)
        }
      })
    })

    return styledRows
  }

  const columns = [
    {
      value: <StyledColumn index={1}> Resident </StyledColumn>
    },
    {
      value: <StyledColumn index={2}> </StyledColumn>
    },
    {
      value: <StyledColumn index={3}> Unit </StyledColumn>
    },
    {
      value: <StyledColumn index={4}> Status </StyledColumn>
    },
    {
      value: <StyledColumn index={5}> Average Review Score </StyledColumn>
    },
    {
      value: <StyledColumn index={6}> Most Recent Survey </StyledColumn>
    },
    {
      value: <StyledColumn index={7}> Number Of Surveys Taken </StyledColumn>
    },
    {
      value: <StyledFinalColumn index={8}> Completed Surveys </StyledFinalColumn>
    }
  ]

  const handleChipClick = (filterType, filterValue) => {
    const otherFilters = Object.assign({}, selectedFilters);
    delete otherFilters[filterType]

    let selectedValues = selectedFilters[filterType]

    // toggle filters
    if (selectedValues?.includes(filterValue)) {
      selectedValues = selectedValues.filter(filter => filter !== filterValue)
    } else {
      selectedValues.push(filterValue)
    }

    let filters = {}
    filters[filterType] = selectedValues
    const newFilter = { ...filters, ...otherFilters }
    setFilters(Object.assign({}, newFilter))
    handlePageChange(1)
    fetchResidentManagementSurveysAction({ propertyId, page: 1, params: newFilter })
  }

  const filterChipGroup = (filters) => {
    // map label to status
    const chips = []

    for (const [filterType, filterValues] of Object.entries(filters)) {
      const selectedValues = selectedFilters?.[filterType] || []

      if (Array.isArray(filterValues)) {
        for (const index in filterValues) {
          const value = filterValues[index]
          let color = "primary"

          if (selectedValues.includes(value)) {
            color = "secondary"
          }

          chips.push(
            <StyledChip
              theme={theme}
              variant="outlined"
              color={color}
              label={value}
              onClick={() => { handleChipClick(filterType, value) }} />
          )

        }
      } else {

        let color = "primary"
        if (filterValues === selectedValues) {
          color = "secondary"
        }

        chips.push(
          <StyledChip
            theme={theme}
            variant="outlined"
            color={color}
            label={filterValues}
            onClick={() => { handleChipClick(filterType, filterValues) }} />
        )
      }
    }

    return <FilterChipsWrapper>{chips}</FilterChipsWrapper>
  }

  const showMore = residents.length < residentsTotal

  const handlePageChange = page => {
    setCurrentPage(page)
    setShowLoader(true)
  }

  return (
    <>
      <GridItem columnStart={1} columnSpan={12} rowStart={1} rowSpan={2}>
        <SurveyStatsInfoCard surveyStats={surveyStats} />
      </GridItem>
      <GridItem columnStart={1} columnSpan={10} rowStart={5} rowSpan={1}>
        <div>
          <StyledFilterText>{residentsTotal} Filtered Residents </StyledFilterText>
          <StyledClearText onClick={clearFilters}> Clear Filters</StyledClearText>
        </div>
        {filterChipGroup(filters)}
      </GridItem>
      <GridItem columnStart={1} columnSpan={12} rowStart={6} rowSpan={10}>
        <StyledTable
          noDataText="No Residents"
          flex={[0.01, 0.1, 1, 0.1, 0.1, 0.1, 0.1, 0.1]}
          columns={columns}
          rows={createRows()}
        />
        {showLoader &&
          <Loading>
            <PropagateLoader color={theme.palette.secondary.main1} />
          </Loading>
        }
        {showMore &&
          <ShowMoreBtn
            id="showMoreBtn"
            disableRipple
            onClick={() => handlePageChange(currentPage + 1)}>
            Show More  <ScrollDownButton />
          </ShowMoreBtn>
        }
        <AfterTableSpacing />
      </GridItem>
    </>
  )
}

export default withTheme(ResidentManagementSurveys)
