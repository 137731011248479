import {
  createFetchActions,
  createUpdateActions,
  createViewModeAction,
} from '../helpers/actions/creators'

export const AMENITIES = 'AMENITIES_PRO'
export const AMENITY = 'AMENITY_PRO'
export const CLEAR_AMENITY = 'CLEAR_AMENITY_PRO'
export const CREATE_AMENITY = 'CREATE_AMENITY_PRO'
export const UPDATE_AMENITY = 'UPDATE_AMENITY_PRO_START'
export const DELETE_AMENITY = 'DELETE_AMENITY_PRO'
export const UPDATE_AMENITY_PHOTO_PIN = 'UPDATE_AMENITY_PHOTO_PIN'

export const RESERVATIONS = 'RESERVATIONS_PRO'

export const CREATE_RESERVATION = 'UPDATE_AMENITY_CREATE_RESERVATION_PRO_START'
export const CREATE_RESERVATION_START = 'CREATE_RESERVATION_PRO_START'
export const CREATE_RESERVATION_SUCCESS = 'UPDATE_AMENITY_CREATE_RESERVATION_PRO_SUCCESS'
export const CREATE_RESERVATION_ERROR = 'UPDATE_AMENITY_CREATE_RESERVATION_PRO_ERROR'
export const UPDATE_AMENITY_PHOTO_PIN_SUCCESS = 'UPDATE_AMENITY_PHOTO_PIN_PRO_SUCCESS'
export const UPDATE_AMENITY_PIN_PHOTO_ERROR = 'UPDATE_AMENITY_PHOTO_PIN_PRO_ERROR'

export const DELETE_RESERVATION = 'UPDATE_AMENITY_DELETE_RESERVATION_PRO_START'
export const DELETE_RESERVATION_START = 'DELETE_RESERVATION_PRO_START'
export const DELETE_RESERVATION_SUCCESS = 'UPDATE_AMENITY_DELETE_RESERVATION_PRO_SUCCESS'
export const DELETE_RESERVATION_ERROR = 'UPDATE_AMENITY_DELETE_RESERVATION_PRO_ERROR'

export const DELETE_WAIVER_START = 'DELETE_WAIVER_START'
export const DELETE_WAIVER = 'UPDATE_AMENITY_DELETE_WAIVER_START'
export const DELETE_WAIVER_SUCCESS = 'UPDATE_AMENITY_DELETE_WAIVER_SUCCESS'
export const DELETE_WAIVER_ERROR = 'UPDATE_AMENITY_DELETE_WAIVER_ERROR'

export const GET_ADMIN = 'GET_ADMIN'
export const GET_ADMIN_START = 'GET_ADMIN_START'
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS'
export const GET_ADMIN_ERROR = 'GET_ADMIN_ERROR'

export const GET_AVAILABILITY = 'GET_AVAILABILITY'
export const GET_AVAILABILITY_START = 'GET_AVAILABILITY_START'
export const GET_AVAILABILITY_SUCCESS = 'GET_AVAILABILITY_SUCCESS'
export const GET_AVAILABILITY_FOR_AMENITIES_SUCCESS = 'GET_AVAILABILITY_FOR_AMENITIES_SUCCESS'
export const GET_AVAILABILITY_ERROR = 'GET_AVAILABILITY_ERROR'

export const PIN_AMENITY = 'UPDATE_AMENITY_PIN_AMENITY_START'
export const PIN_AMENITY_START = 'PIN_AMENITY_START'
export const PIN_AMENITY_SUCCESS = 'UPDATE_AMENITY_PIN_AMENITY_SUCCESS'
export const PIN_AMENITY_ERROR = 'UPDATE_AMENITY_PIN_AMENITY_ERROR'

export const UNPIN_AMENITY = 'UPDATE_AMENITY_UNPIN_AMENITY_START'
export const UNPIN_AMENITY_START = 'UNPIN_AMENITY_START'
export const UNPIN_AMENITY_SUCCESS = 'UPDATE_AMENITY_UNPIN_AMENITY_SUCCESS'
export const UNPIN_AMENITY_ERROR = 'UPDATE_AMENITY_UNPIN_AMENITY_ERROR'

export const CREATE_FACILITY_BLOCK = 'CREATE_FACILITY_BLOCK'
export const CREATE_FACILITY_BLOCK_SUCCESS = 'CREATE_FACILITY_BLOCK_SUCCESS'
export const CREATE_FACILITY_BLOCK_ERROR = 'CREATE_FACILITY_BLOCK_ERROR'
export const CREATE_FACILITY_BLOCK_START = 'CREATE_FACILITY_BLOCK_START'

export const DELETE_FACILITY_BLOCK = 'DELETE_FACILITY_BLOCK'
export const DELETE_FACILITY_BLOCK_SUCCESS = 'DELETE_FACILITY_BLOCK_SUCCESS'
export const DELETE_FACILITY_BLOCK_ERROR = 'DELETE_FACILITY_BLOCK_ERROR'
export const DELETE_FACILITY_BLOCK_START = 'DELETE_FACILITY_BLOCK_START'

export const createAmenityAction = ({
  history = null,
  url = null,
  amenityParams = null
} = {}) => ({
  type: CREATE_AMENITY,
  history,
  url,
  amenityParams
})

export const createAmenity = (history, url, amenityParams) => createAmenityAction({ history, url, amenityParams })

export const clearAmenityAction = () => ({
  type: CLEAR_AMENITY,
})

export const clearAmenity = () => clearAmenityAction()

export const {
  fetchAction: fetchAmenitiesAction,
  fetchStartAction: fetchAmenitiesStart,
  fetchSuccessAction: fetchAmenitiesSuccess,
  fetchErrorAction: fetchAmenitiesError,
} = createFetchActions(AMENITIES)

export const fetchAmenities = (propertyId, page) =>
  fetchAmenitiesAction({ propertyId, page })

export const {
  fetchAction: fetchAmenityAction,
  fetchStartAction: fetchAmenityStart,
  fetchSuccessAction: fetchAmenitySuccess,
  fetchErrorAction: fetchAmenityError,
} = createFetchActions(AMENITY)

export const fetchAmenity = (amenityId, propertyId, history) =>
  fetchAmenityAction({ amenityId, propertyId, history })

export const updateAmenityActions = createUpdateActions(AMENITY, true, true)

export const updateAmenityAC = ( // Action Creator
  update,
  history,
  url,
  amenityId,
) => ({
  type: UPDATE_AMENITY,
  update,
  history,
  url,
  amenityId,
})

export const updateAmenityPhotoPin = (
  update,
  amenityId,
) => ({
  type: UPDATE_AMENITY_PHOTO_PIN,
  update,
  amenityId,
})

export const updateAmenityOptimistically = (update) =>
  updateAmenityActions.updateOptimistcallyAction({
    update,
  })

export const updateAmenitySuccess = (payload) =>
  updateAmenityActions.updateSuccessAction(payload)

export const updateAmenityRevert = (update) =>
  updateAmenityActions.updateRevertAction({
    update,
  })

export const deleteAmenity = (id, history) => ({
  type: DELETE_AMENITY,
  id,
  history,
})

export const getAdminStart = () => ({ type: GET_ADMIN_START })
export const getAdmin = () => ({ type: GET_ADMIN })
export const getAdminSuccess = (payload) => ({ type: GET_ADMIN_SUCCESS, payload })
export const getAdminError = () => ({ type: GET_ADMIN_ERROR })

export const getAvailabilityStart = () => ({ type: GET_AVAILABILITY_START })
export const getAvailabilityAC = (facilityUuid, date, endDate) => ({ type: GET_AVAILABILITY, facilityUuid, date, endDate })
export const getAvailabilityForAmenitiesSuccess = () => ({ type: GET_AVAILABILITY_FOR_AMENITIES_SUCCESS })
export const getAvailabilitySuccess = (payload) => ({ type: GET_AVAILABILITY_SUCCESS, payload })
export const getAvailabilityError = () => ({ type: GET_AVAILABILITY_ERROR })

export const pinAmenityStart = () => ({ type: PIN_AMENITY_START })
export const pinAmenity = (facilityUuid) => ({ type: PIN_AMENITY, facilityUuid })
export const pinAmenitySuccess = () => ({ type: PIN_AMENITY_SUCCESS })
export const pinAmenityError = () => ({ type: PIN_AMENITY_ERROR })

export const unPinAmenityStart = () => ({ type: UNPIN_AMENITY_START })
export const unPinAmenity = (facilityUuid) => ({ type: UNPIN_AMENITY, facilityUuid })
export const unPinAmenitySuccess = () => ({ type: UNPIN_AMENITY_SUCCESS })
export const unPinAmenityError = () => ({ type: UNPIN_AMENITY_ERROR })

export const deleteWaiverStart = () => ({ type: DELETE_WAIVER_START })
export const deleteWaiver = (waiverUuid, amenityId) =>
  ({ type: DELETE_WAIVER, waiverUuid, amenityId })
export const deleteWaiverSuccess = () => ({ type: DELETE_WAIVER_SUCCESS })
export const deleteWaiverError = () => ({ type: DELETE_WAIVER_ERROR })

export const {
  fetchAction: fetchReservationsAction,
  fetchStartAction: fetchReservationsStart,
  fetchSuccessAction: fetchReservationsSuccess,
  fetchErrorAction: fetchReservationsError,
} = createFetchActions(RESERVATIONS)

export const fetchReservationsAC = (propertyId, facilityUuid, startDate) =>
  fetchReservationsAction({ propertyId, facilityUuid, startDate })

export const createReservationStart = () => ({ type: CREATE_RESERVATION_START })
export const createReservation = (reservation, residentName) =>
  ({ type: CREATE_RESERVATION, reservation, residentName })
export const createReservationSuccess = () => ({ type: CREATE_RESERVATION_SUCCESS })
export const createReservationError = () => ({ type: CREATE_RESERVATION_ERROR })

export const deleteReservationStart = () => ({ type: DELETE_RESERVATION_START })
export const deleteReservation = (reservation, message, residentName) =>
  ({ type: DELETE_RESERVATION, reservation, message, residentName })
export const deleteReservationSuccess = () => ({ type: DELETE_RESERVATION_SUCCESS })
export const deleteReservationError = () => ({ type: DELETE_RESERVATION_ERROR })

export const updateAmenityPhotoPinSuccess = () => ({ type: UPDATE_AMENITY_PHOTO_PIN_SUCCESS })
export const updateAmenityPhotoPinError = () => ({ type: UPDATE_AMENITY_PIN_PHOTO_ERROR })

export const setAmenityViewModeAction = createViewModeAction(AMENITY)

export const setAmenityViewMode = ({ readMode, editMode }) =>
  setAmenityViewModeAction({ readMode, editMode })

export const createFacilityBlockStart = () => ({ type: CREATE_FACILITY_BLOCK_START })
export const createFacilityBlock = (facilityUuid, body) =>
  ({ type: CREATE_FACILITY_BLOCK, facilityUuid, body })
export const createFacilityBlockSuccess = () => ({ type: CREATE_FACILITY_BLOCK_SUCCESS })
export const createFacilityBlockError = () => ({ type: CREATE_FACILITY_BLOCK_ERROR })

export const deleteFacilityBlockStart = () => ({ type: DELETE_FACILITY_BLOCK_START })
export const deleteFacilityBlock = (facilityUuid, body) =>
  ({ type: DELETE_FACILITY_BLOCK, facilityUuid, body })
export const deleteFacilityBlockSuccess = () => ({ type: DELETE_FACILITY_BLOCK_SUCCESS })
export const deleteFacilityBlockError = () => ({ type: DELETE_FACILITY_BLOCK_ERROR })
