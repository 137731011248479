import styled from '@emotion/styled'

export const HeaderRightLink = styled('a')`
  font-size: ${({ theme }) => theme.custom.font.size.md};
  color: ${({ theme }) => theme.palette.primary.green.mint};
`

export const Specs = styled('div')`
  display: flex;
  & > * {
    margin-right: 2em;
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    color: ${({ theme }) => theme.palette.grey.main};
  }

  .battery-bar {
    background: ${({ theme }) => theme.palette.primary.green.mint};
  }

  .battery-root {
    width: 100%;
    height: 50%;
    border-radius: 1em;
    box-shadow: ${({ theme }) => `0 0 0 2px ${theme.palette.primary.green.mint}`};
    background-color: ${({ theme }) => theme.palette.common.white}
`
