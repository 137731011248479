// @flow
import React from 'react'
import ReactSelect from 'react-select'
import { Grid } from '@material-ui/core'
import moment from 'moment'

import { daysInWeek } from '../../../utils'

import CheckBox from '@Common/CheckBox'
import Flex, { FlexItem } from "@Common/Flex";
import HorizRadioButtons from '@Common/HorizRadioButtons'
import PaginatedTable from '@Common/PaginatedTable'
import Tooltip from '@Common/Tooltip'

import { TextField } from './style'
import { EditField } from "./AmenityProDetails";
import {
  AvailabilityFormCheckBoxDiv,
  AvailabilityFormDiv,
  AvailabilityFormExtrasDiv,
  AvailabilityFormMaxPeopleField,
  AvailabilityFormScheduleDiv,
  AvailabilityFormScheduleSelectDiv,
  AvailabilityFormScheduleTableHeader,
  BackgroundDiv,
  MinMaxDaysTextField,
} from '../AmenityShared/styles'


type Props = {
  item: Object,
  editMode: boolean,
  isError: (string) => boolean,
  handleSchedule: (string, string, number) => typeof undefined,
  handleScheduleDayClose: (string, boolean) => typeof undefined,
  handleCheckInAndOutSchedule: (time: string, value: number) => typeof undefined,
  handleChange: (Object) => typeof undefined,
  updateItem: (Object) => any,
  registerFormField: (string) => any,
}

const hourlyDurationOptions = [
  {
    id: 'radio-30-mins-slot-length',
    label: '30 Minutes',
    value: 30,
    classLabel: 'radio-button-amenity-availability-label',
  },
  {
    id: 'radio-1-hr-slot-length',
    label: '1 Hour',
    value: 60,
    classLabel: 'radio-button-amenity-availability-label',
  },
]
const maxReservationsOptions = [
  {
    id: 'radio-1-slot',
    label: '1 Slot',
    value: 1,
    classLabel: 'radio-button-amenity-availability-label',
  },
  {
    id: 'radio-2-slots',
    label: '2 Slots',
    value: 2,
    classLabel: 'radio-button-amenity-availability-label',
  },
  {
    id: 'radio-unlimited-slots',
    label: 'Unlimited',
    value: 'unlimited',
    classLabel: 'radio-button-amenity-availability-label',
  },
]
const { createElement } = React
const flexPadding = '32px'
const wrapViewModeField = (cmp) => <Flex paddingBottom={flexPadding} children={cmp} />

function transformMaxReservationsPerDay(count: string) {
  const parsed = parseInt(count);
  return isNaN(parsed) ? count : parsed
}

const getDurationParts = (item = {}) => {
  const { minDuration = 0, duration = 0 } = item

  const minMinutes = minDuration % 60
  const minHours = (minDuration - (minDuration % 60)) / 60
  const maxMinutes = duration % 60
  const maxHours = (duration - (duration % 60)) / 60

  const minDurationFriendlyValue = `${minHours ? minHours + 'h' : ''}`
    + `${minMinutes ? minMinutes + 'm' : ''}`

  const maxDurationFriendlyValue = `${maxHours ? maxHours + 'h' : ''}`
    + `${maxMinutes ? maxMinutes + 'm' : ''}`
  return {
    minMinutes,
    minHours,
    maxMinutes,
    maxHours,
    minDurationFriendlyValue,
    maxDurationFriendlyValue,
  }
}

export default class AmenityAvailabilityForm extends React.Component<Props> {
  getDayTimeOptions = (startTime: number, endTime: number, optionsType: string, interval: number = 30) => {
    const periodsInADay = moment.duration(1, 'day').as('minutes')
    const times = []
    const startTimeMoment = moment('00:00', 'hh:mm')

    let isOptionDisabled = false;
    for (let i = 0; i <= periodsInADay; i += interval) {
      startTimeMoment.add(i === 0 ? 0 : interval, 'minutes')
      const time = startTimeMoment.format('h:mm a')
      let timeMil = parseInt(startTimeMoment.format('Hmm'), 10)
      timeMil = i === periodsInADay ? 2400 : timeMil
      isOptionDisabled = optionsType === "startTime" ? (timeMil >= endTime && endTime !== -1) : timeMil <= startTime;
      if (!isOptionDisabled)
        times.push({ label: time, value: timeMil })
    }
    return times
  }

  getHourDropdownOptions = (): Array<Object> => {
    return [...Array(25).keys()].map(x => ({ label: `0${x} H`.slice(-4), value: x }))
  }

  getHourDropdownOptionsForFullFacility = (fieldName: string, minHours: number, maxHours: number): Array<Object> => {
    const mapCallbackFn = x => ({ label: `0${x} H`.slice(-4), value: x })

    if (fieldName === 'minDuration') {
      return [...Array(25).keys()]
        .filter(f => f <= maxHours)
        .map(mapCallbackFn)
    } else if (fieldName === 'duration') {
      return [...Array(25).keys()]
        .filter(f => f >= minHours)
        .map(mapCallbackFn)
    }

    return this.getHourDropdownOptions()
  }

  selectHourDropdownOptions = (hours: number): ?number => {
    const options = this.getHourDropdownOptions()

    return options.find(f => f.value === hours) ?? (options.length > 0 ? options[0] : null)

  }

  getMinuteDropdownOptions = (): Array<Object> => {
    return [...Array(4).keys()].map(x => ({ label: `0${x * 15} M`.slice(-4), value: x * 15 }))
  }

  getMinuteDropdownOptionsForFullFacility = (fieldName: string,
    minHours: number,
    minMinutes: number,
    maxHours: number,
    maxMinutes: number): Array<Object> => {
    const rawValue = [...Array(4).keys()].map((x) => x * 15)
    const mapCallbackFn = x => ({ label: `0${x * 1} M`.slice(-4), value: x * 1 })

    if (fieldName === 'minDuration') {
      return rawValue
        .filter((f) => {
          if (minHours === 0) { return f >= 30 }
          if (minHours === 24) { return f <= 0 }
          if (minHours === maxHours) { return f <= maxMinutes }
          return true
        })
        .map(mapCallbackFn)
    } else if (fieldName === 'duration') {
      return rawValue
        .filter((f) => {
          if (maxHours === 0) { return f >= 30 }
          if (maxHours === 24) { return f <= 0 }
          if (minHours === maxHours) { return f >= minMinutes }
          return true
        })
        .map(mapCallbackFn)
    }

    return this.getMinuteDropdownOptions()
  }

  selectMinuteDropdownOptions = (minutes: number): ?number => {
    const options = this.getMinuteDropdownOptions()

    return options.find(f => f.value === minutes) ?? (options.length > 0 ? options[0] : null)
  }

  /**
   * Handle duration for all types
   * updates on field at a time
   * use 'duration' argument to set duration for hourly
   * use min/max minutes/hours argument to set for full-facility
   * no need to use extra state vars
   */
  handleDuration = ({
    duration,
    minHours,
    minMinutes,
    maxMinutes,
    maxHours,
  }: {
    duration?: number,
    minHours?: number,
    minMinutes?: number,
    maxMinutes?: number,
    maxHours?: number,
  }) => {
    const { item, updateItem } = this.props

    if (duration || duration === 0) {
      // Used by hourly.  Duration are set as is in UI.
      updateItem({ duration: duration * 1 })
      return
    }

    if (minMinutes || minMinutes === 0) {
      const { minHours: oldMinHours } = getDurationParts(item)
      updateItem({ minDuration: oldMinHours * 60 + minMinutes })
      return
    }
    if (minHours || minHours === 0) {
      const { minMinutes: oldMinMinutes } = getDurationParts(item)
      updateItem({ minDuration: oldMinMinutes + minHours * 60 })
      return
    }
    if (maxMinutes || maxMinutes === 0) {
      const { maxHours: oldMaxHours } = getDurationParts(item)
      updateItem({ duration: oldMaxHours * 60 + maxMinutes })
      return
    }
    if (maxHours || maxHours === 0) {
      const { maxMinutes: oldMaxMinutes } = getDurationParts(item)
      updateItem({ duration: oldMaxMinutes + maxHours * 60 })
    }
  }

  handleEnableBuffer = (e: Object) => {
    const { updateItem } = this.props
    updateItem({ padding: e.target.checked ? 30 : 0 })
  }

  handleMaxReservationsPerDay = (e: Object) => {
    const { updateItem } = this.props
    updateItem({ maxReservationsPerDay: transformMaxReservationsPerDay(e.currentTarget.value) })
  }

  handleGuestsAllowed = (e: Object) => {
    const { updateItem } = this.props
    updateItem({ guestsAllowed: e.target.checked })
  }

  renderScheduleHeader = () => {
    const {
      editMode,
      item: {
        reservationType,
      } = { reservationType: '' }
    } = this.props

    const makeLabel = (text) => <AvailabilityFormScheduleTableHeader reservationType={reservationType} children={text}/>


    const {
      item: {
        checkInTime,
        checkOutTime,
      },
    } = this.props

    if (reservationType !== 'multiDay') {
      return [<></>, makeLabel('Day Start Time'), makeLabel('Day End Time'), <></>]
        .map < Object > ((value, key) => ({ value, key, index: key - 1 }))
    }

    // multi-day edit mode
    if (!editMode) {
      return [<></>, makeLabel('Check In Time'), makeLabel('Check Out Time'), <></>]
          .map<Object>((value, key) => ({ value, key, index: key - 1 }))
    }

    const checkInTimeOptions = this.getDayTimeOptions(30, 2330, '')
    const checkOutTimeOptions = this.getDayTimeOptions(0, 2300, '')
    const checkInTimeIndex = checkInTimeOptions.findIndex(o => o.value === checkInTime)
    const checkOutTimeIndex = checkOutTimeOptions.findIndex(o => o.value === checkOutTime)

    // Check-In + Check out time (multi day) headers
    // (when in edit mode we render a dropdown, controlled by these
    // (props passed to renderDropDown() function)
    const checkInSelectProps = {
      inputId: 'select-check-in-time',
      components: { IndicatorSeparator: () => null },
      classNamePrefix: 'time',
      onChange: ({ value }) => this.props.handleCheckInAndOutSchedule('checkInTime', value),
      options: checkInTimeOptions,
      value: checkInTimeOptions[checkInTimeIndex] || null,
      class: 'require-error'
    }

    const checkOutSelectProps = {
      inputId: 'select-check-out-time',
      components: { IndicatorSeparator: () => null },
      classNamePrefix: "time",
      onChange: ({ value }) => this.props.handleCheckInAndOutSchedule('checkOutTime', value),
      options: checkOutTimeOptions,
      value: checkOutTimeOptions[checkOutTimeIndex] || null,
      class: "require-error",
    }

    const renderDropDown = (selectProps: Object, labelText) => (
      <Flex flex={1}>
        <FlexItem>
          <AvailabilityFormScheduleTableHeader>
            <Grid item xs> {labelText} </Grid>
          </AvailabilityFormScheduleTableHeader>
          <AvailabilityFormScheduleSelectDiv>
            <ReactSelect {...selectProps} />
          </AvailabilityFormScheduleSelectDiv>
        </FlexItem>
      </Flex>
    )

    // The headers passed into the PaginatedTable's 'columns' prop
    return [
      null,
      renderDropDown(checkInSelectProps, 'Check in time:'),
      renderDropDown(checkOutSelectProps, 'Check out time:'),
      null
    ].map < Object > ((value, key) => ({ value, key, index: key - 1 }))
  }

  // mapped result is passed into the PaginatedTable component
  // in the render() function as it's 'rows' prop
  renderScheduleDayRow = (day: string) => {
    const {
      item,
      isError,
      handleSchedule,
    } = this.props

    const checkInTimeOptions = this.getDayTimeOptions(30, 2330, '')
    const checkOutTimeOptions = this.getDayTimeOptions(0, 2300, '')

    const dayKey = day.toLowerCase().substr(0, 3)
    const daySchedule = item.schedule && dayKey in item.schedule
      ? item.schedule[dayKey] : {}
    const { startTime, endTime, checkInTime, checkOutTime } = daySchedule

    const isClosed = startTime === 0 && endTime === 0
    const startTimeOptions = this.getDayTimeOptions(startTime, endTime, "startTime")
    const endTimeOptions = this.getDayTimeOptions(startTime, endTime, "endTime")
    const startTimeIndex = isClosed ? -1 : startTimeOptions.findIndex(o => o.value === startTime)
    const endTimeIndex = isClosed ? -1 : endTimeOptions.findIndex(o => o.value === endTime)

    let checkInTimeLabel = '-'
    let checkOutTimeLabel = '-'
    if (item.reservationType === 'multiDay') {
      const checkInTimeIndex = checkInTime ? checkInTimeOptions.findIndex(o => o.value === checkInTime) : -1
      const checkOutTimeIndex = checkOutTime ? checkOutTimeOptions.findIndex(o => o.value === checkOutTime) : -1

      if (checkInTimeIndex !== -1) checkInTimeLabel = checkInTimeOptions[checkInTimeIndex]?.label || '-'
      if (checkOutTimeIndex !== -1) checkOutTimeLabel = checkOutTimeOptions[checkOutTimeIndex]?.label || '-'
    }

    const getDropdownStyleForErrors = (key) => {
      if (!isError(key)) {
        return {}
      }
      return {
        styles: {
          control: (provided) => ({
            ...provided,
            borderColor: 'red'
          })
        }
      }
    }
    const reactSelectProps = {
      isDisabled: isClosed,
      components: { IndicatorSeparator: () => null },
    }
    const startTimeSelectProps = {
      ...reactSelectProps,
      inputId: `select-start-time-${dayKey}`,
      classNamePrefix: 'time',
      onChange: ({ value }) => handleSchedule(dayKey, 'startTime', value),
      options: startTimeOptions,
      value: startTimeOptions[startTimeIndex] || null,
      class: 'require-error',
      ...getDropdownStyleForErrors('schedule-' + dayKey + '-startTime'),
    }
    const endTimeSelectProps = {
      ...reactSelectProps,
      inputId: `select-end-time-${dayKey}`,
      classNamePrefix: 'time',
      onChange: ({ value }) => handleSchedule(dayKey, 'endTime', value),
      options: endTimeOptions,
      value: endTimeOptions[endTimeIndex] || null,
      ...getDropdownStyleForErrors('schedule-' + dayKey + '-endTime'),
    }

    // if in edit mode, show a textbox with the option to close that day
    // define the props here for more readability in the JSX
    const checkBoxProps = {
      id: `checkbox-close-day-${dayKey}`,
      iconName: 'square',
      checkedIconName: 'square-checkmark',
      checked: isClosed,
      onChange: (e: Object) => this.props.handleScheduleDayClose(dayKey, e.target.checked),
      text: 'Closed All Day'
    }

    // conditionally render the dropdown if we are in edit mode
    // else render a simple text field bound to the same value prop (from selectProps)
    const renderDropdownIfInEditMode = (selectProps: Object, value) => {
      const { editMode } = this.props;
      const textFieldProps = {
        value: value || selectProps?.value?.label,
        showPencil: false,
        textAlign: 'center',
        hideCaret: true,
        disableUnderline: true,
      }

      return <AvailabilityFormScheduleSelectDiv>
        {editMode ? <ReactSelect {...selectProps} /> : < TextField {...textFieldProps} />}
      </AvailabilityFormScheduleSelectDiv>
    }

    // else just show a label, which is empty if not closed
    const closedAllDayLabel = isClosed ? checkBoxProps.text : ''

    return [
      <AvailabilityFormScheduleSelectDiv children={day} />,
      // if res type is not multiDay, spread in the 'startTime/endTime' dropdowns
      ...item.reservationType !== 'multiDay' ? [
        renderDropdownIfInEditMode(startTimeSelectProps),
        renderDropdownIfInEditMode(endTimeSelectProps)
      ] : [
        // and if it is multiDay, just show some text
        <AvailabilityFormScheduleSelectDiv children={checkInTimeLabel} />,
        <AvailabilityFormScheduleSelectDiv children={checkOutTimeLabel} />
      ],
      this.props.editMode
        ? <AvailabilityFormCheckBoxDiv children={<CheckBox {...checkBoxProps} />} />
        : <AvailabilityFormCheckBoxDiv children={closedAllDayLabel} />
      // map components to objects with { value } property (the component) for Table component
    ].map<Object>((component, index) => ({ value: component, index: index - 1, key: index }))
  }

  /* region Render Footer Fields and Helpers */

  renderDurationRadioButtons = () => {
    const {
      item: { duration } = { duration: 0 },
      registerFormField,
      isError,
    } = this.props

    const labelText = 'Length of time slot:'
    let value
    switch (duration) {
      case 30:
        value = '30 minutes'
        break
      case 60:
        value = '1 hour'
        break
      default:
        break
    }

    return (
      <EditField
        label={labelText}
        value={value}
        editMode={this.props.editMode}
      >
        <FlexItem>
          <div>Length of time slot:</div>
          <div>
            <HorizRadioButtons
              handleChange={this.handleDuration}
              name="duration"
              selectedValue={duration}
              validationCall={registerFormField('duration')}
              error={isError('duration')}
              options={hourlyDurationOptions}
              textSize="16px"
              marginLeft="8px"
              noUnderline
            />
          </div>
        </FlexItem>
      </EditField>
    )
  }

  renderBufferTimeCheckbox = () => {
    const {
      item: { padding } = { padding: 0 },
      editMode,
    } = this.props

    const labelText = '30 minute buffer'
    const value = padding ? 'Yes' : 'No'

    return (
      <EditField
        editMode={editMode}
        label={labelText + ':'}
        value={value}
      >
        <AvailabilityFormCheckBoxDiv>
          <CheckBox id="checkbox-enable-buffer"
            iconName="square"
            checkedIconName="square-checkmark"
            checked={padding > 0}
            onChange={this.handleEnableBuffer}
            text={labelText}
          />
          <Tooltip type="AmenityReservationBufferTime" />
        </AvailabilityFormCheckBoxDiv>
      </EditField>
    )
  }

  renderGuestsOptions = () => {
    const {
      registerFormField,
      handleChange,
      isError,
      editMode,
      item: {
        guestsAllowed,
        maxPeoplePerReservation
      } = { guestsAllowed: false, maxPeoplePerReservation: 0 }
    } = this.props

    const numPeoplePluralized = maxPeoplePerReservation === 1
      ? '1 person'
      : `${maxPeoplePerReservation} people`

    const labelText = guestsAllowed
      ? `Up to ${numPeoplePluralized} per reservation`
      : 'No guests allowed'

    return (
      <EditField label={labelText} editMode={editMode} >
        <AvailabilityFormCheckBoxDiv>
          <CheckBox
            id="checkbox-guests-allowed"
            iconName="square"
            checkedIconName="square-checkmark"
            checked={guestsAllowed}
            onChange={this.handleGuestsAllowed}
            text="Guests allowed"
          />
        </AvailabilityFormCheckBoxDiv>
        {guestsAllowed &&
          <AvailabilityFormMaxPeopleField>
            <TextField
              error={isError('maxPeoplePerReservation')}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              alwaysShowLabel={true}
              textAlign="left"
              prefix={null}
              label="Max # per reservation:"
              id="maxPeoplePerReservation"
              value={maxPeoplePerReservation}
              style={{ width: 350 }}
              showPencil
              paddingLeft={0}
              validationCall={registerFormField('maxPeoplePerReservation')}
            />
          </AvailabilityFormMaxPeopleField>
        }
      </EditField>
    )
  }

  renderHourlyFooter = () => {
    const {
      editMode,
      isError,
      registerFormField,
      item: {
        maxReservationsPerDay,
        duration,
      } = {}
    } = this.props

    const maxSlotsLabel = 'Max # of slots resident can reserve per day:'
    const durationLabel = 'Length of time slot:'
    let durationFriendlyValue
    switch (duration) {
      case 30:
        durationFriendlyValue = '30 minutes'
        break
      case 60:
        durationFriendlyValue = '1 hour'
        break
      default:
        break
    }

    return (
      <>
        <Flex>
          <FlexItem flex={1}>
            <EditField
              label={durationLabel}
              value={durationFriendlyValue}
              editMode={this.props.editMode}
              viewModeWrapper={wrapViewModeField}
            >
              <div>{durationLabel}</div>
              <div>
                <HorizRadioButtons
                  handleChange={(e) => {
                    this.handleDuration({ duration: parseInt(e.target.value) })
                  }}
                  name="duration"
                  selectedValue={duration}
                  validationCall={registerFormField('duration')}
                  error={isError('duration')}
                  options={hourlyDurationOptions}
                  textSize="16px"
                  marginLeft="8px"
                  noUnderline
                />
              </div>
            </EditField>
          </FlexItem>
          <FlexItem flex={1}>
            <EditField
              label={maxSlotsLabel}
              value={maxReservationsPerDay}
              editMode={editMode}
              viewModeWrapper={wrapViewModeField}
            >
              <div>{maxSlotsLabel}</div>
              <div>
                <HorizRadioButtons
                  handleChange={this.handleMaxReservationsPerDay}
                  name="maxReservationsPerDay"
                  selectedValue={maxReservationsPerDay}
                  validationCall={registerFormField('maxReservationsPerDay')}
                  error={isError('maxReservationsPerDay')}
                  options={maxReservationsOptions}
                  textSize="16px"
                  marginLeft="8px"
                  noUnderline
                />
              </div>
            </EditField>
          </FlexItem>
        </Flex>
        <Flex>
          <FlexItem flex={1}> {this.renderBufferTimeCheckbox()} </FlexItem>
          <FlexItem flex={1}> {this.renderGuestsOptions()} </FlexItem>
        </Flex>
      </>
    )
  }

  renderFullFacilityFooter = () => {
    const {
      isError,
      editMode,
    } = this.props

    const {
      minHours,
      minMinutes,
      maxHours,
      maxMinutes,
      minDurationFriendlyValue,
      maxDurationFriendlyValue,
    } = getDurationParts(this.props.item)

    const getDropDownStyleForErrors = (fieldName) => {
      if (isError(fieldName)) {
        return { styles: { control: provided => ({ ...provided, borderColor: "red" }) } }
      }
      return {}
    }

    const defaultSelectProps = {
      components: { IndicatorSeparator: () => null },
      classNamePrefix: "time",
      class: "require-error",
    }
    const minDurationHoursProps = {
      ...defaultSelectProps,
      inputId: 'minDurationHours',
      options: this.getHourDropdownOptionsForFullFacility('minDuration', minHours, maxHours),
      value: this.selectHourDropdownOptions(minHours),
      ...getDropDownStyleForErrors('minDuration'),
      onChange: ({ value }) => {
        this.handleDuration({ minHours: parseInt(value) })
      },
    }
    const minDurationMinutesProps = {
      ...defaultSelectProps,
      inputId: 'minDurationMinutes',
      options: this.getMinuteDropdownOptionsForFullFacility('minDuration', minHours, minMinutes, maxHours, maxMinutes),
      value: this.selectMinuteDropdownOptions(minMinutes),
      ...getDropDownStyleForErrors('minDuration'),
      onChange: ({ value }) => {
        this.handleDuration({ minMinutes: parseInt(value) })
      },
    }
    const maxDurationHoursProps = {
      ...defaultSelectProps,
      inputId: 'maxDurationHours',
      options: this.getHourDropdownOptionsForFullFacility('duration', minHours, maxHours),
      value: this.selectHourDropdownOptions(maxHours),
      ...getDropDownStyleForErrors('duration'),
      onChange: ({ value }) => {
        this.handleDuration({ maxHours: parseInt(value) })
      },
    }
    const maxDurationMinutesProps = {
      ...defaultSelectProps,
      inputId: 'maxDurationMinutes',
      options: this.getMinuteDropdownOptionsForFullFacility('duration', minHours, minMinutes, maxHours, maxMinutes),
      value: this.selectMinuteDropdownOptions(maxMinutes),
      ...getDropDownStyleForErrors('duration'),
      onChange: ({ value }) => {
        this.handleDuration({ maxMinutes: parseInt(value) })
      },
    }

    const renderTimeDropdown = (selectProps) => createElement(
      AvailabilityFormScheduleSelectDiv, {}, createElement(ReactSelect, selectProps)
    )

    const minHoursLabel = 'Min # of hours:'
    const maxHoursLabel = 'Max # of hours:'

    return (
      <>
        <Flex paddingBottom={flexPadding}>
          <FlexItem flex={2}>
            {/* MIN DURATION */}
            <EditField
              editMode={editMode}
              label={minHoursLabel}
              value={minDurationFriendlyValue}
            >
              <FlexItem>
                {minHoursLabel}
              </FlexItem>
              <Flex>
                <FlexItem> {renderTimeDropdown(minDurationHoursProps)} </FlexItem>
                <FlexItem> {renderTimeDropdown(minDurationMinutesProps)} </FlexItem>
              </Flex>
            </EditField>

          </FlexItem>
          <FlexItem flex={2}>
            {/* MAX DURATION */}
            <EditField
              editMode={editMode}
              label={maxHoursLabel}
              value={maxDurationFriendlyValue}
            >
              <FlexItem> {maxHoursLabel} </FlexItem>
              <Flex>
                <FlexItem> {renderTimeDropdown(maxDurationHoursProps)} </FlexItem>
                <FlexItem> {renderTimeDropdown(maxDurationMinutesProps)} </FlexItem>
              </Flex>
            </EditField>
            {/* GUEST OPTIONS */}
          </FlexItem>
        </Flex>
        <Flex>
          <FlexItem flex={1}>
            {/* BUFFERS */}
            {this.renderBufferTimeCheckbox()}
          </FlexItem>
          <FlexItem flex={1}>
            {/* BUFFERS */}
            {this.renderGuestsOptions()}
          </FlexItem>
        </Flex>
      </>
    )
  }

  renderMultiDayFooter = () => {
    const {
      item: {
        minMultiDayReservation = 0,
        maxMultiDayReservation = 0
      } = {}
    } = this.props

    const renderTextField = (textFieldProps) => {
      const withDefaults = {
        onChange: this.props.handleChange,
        InputLabelProps: { shrink: true },
        alwaysShowLabel: true,
        addLabelPadding: true,
        padding: null,
        textAlign: 'left',
        prefix: null,
        showPencil: true,
        ...textFieldProps,
      }
      return (
        <MinMaxDaysTextField>
          <TextField {...withDefaults} />
        </MinMaxDaysTextField>
      )
    }

    const minDaysLabel = 'Min # of days:'
    const maxDaysLabel = 'Max # of days:'

    const renderMinDaysEditField = () => renderTextField({
      id: "minMultiDayReservation",
      error: this.props.isError('minMultiDayReservation'),
      label: minDaysLabel,
      value: minMultiDayReservation,
      validationCall: this.props.registerFormField('minMultiDayReservation')
    })
    const renderMaxDaysEditField = () => renderTextField({
      id: "maxMultiDayReservation",
      error: this.props.isError('maxMultiDayReservation'),
      label: maxDaysLabel,
      value: maxMultiDayReservation,
      validationCall: this.props.registerFormField('maxMultiDayReservation')
    })

    return <>
      <Flex paddingBottom={flexPadding}>
        <FlexItem flex={1}>
          <EditField
            editMode={this.props.editMode}
            label={minDaysLabel}
            value={`${minMultiDayReservation} ${minMultiDayReservation === 1 ? 'day' : 'days'}`}
            children={renderMinDaysEditField()}
          >
          </EditField>
        </FlexItem>
        <FlexItem flex={1}>
          <EditField
            editMode={this.props.editMode}
            label={maxDaysLabel}
            value={`${maxMultiDayReservation} ${maxMultiDayReservation === 1 ? 'day' : 'days'}`}
            children={renderMaxDaysEditField()}
          >
          </EditField>
        </FlexItem>
      </Flex>
      <Flex>
        <FlexItem> {" "} </FlexItem>
        <FlexItem> {this.renderGuestsOptions()} </FlexItem>
      </Flex>
    </>
  }

  // renders the footer section based on reservation type
  // field components that are re-used are defined by functions above
  renderFooterContent = () => {
    const {
      item: {
        reservationType,
        rsvpRequired,
      } = {}
    } = this.props

    if (!rsvpRequired) { return null }

    switch (reservationType) {
      case 'fullFacility':
        return this.renderFullFacilityFooter()
      case 'multiDay':
        return this.renderMultiDayFooter()
      default:
        return this.renderHourlyFooter()
    }
  }

  /* endregion */

  render() {
    return (
      <AvailabilityFormDiv>
        <AvailabilityFormScheduleDiv>
          <PaginatedTable
            headerBorderTop
            alternateColor
            noBorder
            total={9}
            flex={[0, 0, 0, 1]}
            currentPage={0}
            rowsPerPage={9}
            columns={this.renderScheduleHeader()}
            rows={daysInWeek.map(this.renderScheduleDayRow)}
          />
        </AvailabilityFormScheduleDiv>
        <BackgroundDiv>
          <AvailabilityFormExtrasDiv>
            {this.renderFooterContent()}
          </AvailabilityFormExtrasDiv>
        </BackgroundDiv>
      </AvailabilityFormDiv>
    )
  }
}
