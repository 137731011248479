import React, { useEffect, useRef, useState, Fragment } from 'react'
import OktaSignIn from '@okta/okta-signin-widget'
import { OktaStyle } from './styles'
import LoginSection from './LoginSection'

const OktaSignInWidget = ({
  onSuccess,
  onError,
  isUserSpoofing,
  changeMode
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const config = {
    baseUrl: window?._env_?.REACT_APP_OKTA_BASE_URL || '',
    redirectUri: window?._env_?.REACT_APP_OKTA_REDIRECT_URI || '',
    clientId: window?._env_?.REACT_APP_OKTA_CLIENT_ID || '',
    authParams: {
      issuer: window?._env_?.REACT_APP_OKTA_ISSUER || '',
    },
    features: {
      rememberMe: false,
    },
    brandName: "Zego",
    language: 'en',
    i18n: {
      en: {
        'primaryauth.title': 'Sign in to Zego',
      }
    },
  };
  const widgetRef = useRef();
  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    const widget = new OktaSignIn(config);
    widget.showSignInToGetTokens({
      el: widgetRef.current,
    }).then(onSuccess).catch(onError);

    return () => widget.remove();
  }, [config, onSuccess, onError]);

  const handleLogin = () => {
    const emailField = document.getElementById("okta-signin-username")
    setValueWithChange(emailField, email)
    const passwordField = document.getElementById("okta-signin-password")
    setValueWithChange(passwordField, password)
    document.getElementById("okta-signin-submit").click()
  }

  const handleChange = ({ currentTarget: { id, value } }) => {
    if (id === 'email') setEmail(value)
    if (id === 'password') setPassword(value)
  }

  const setValueWithChange = (field, value) => {
    field.focus()
    field.value = value
    field.dispatchEvent(new Event('input', { bubbles: true }))
  }

  return (
    <Fragment>
      <LoginSection
        changeMode={changeMode}
        disableSocialLogin={true}
        email={email}
        password={password}
        handleLogin={handleLogin}
        handleChange={handleChange}
      />
      <OktaStyle>
        <div class="okta-widget" ref={widgetRef} />
      </OktaStyle>
    </Fragment>
  );
};
export default OktaSignInWidget;