// @flow

import React from 'react'
import Button from '@Common/Button/FabButton'
import Switch from '@material-ui/core/Switch'
import mutableSet from 'lodash/set'

import BaseSettingCard from './BaseSettingCard'
import Flex, { FlexItem } from '@Common/Flex'
import TextField from '../Common/TextField'
import MaskPhoneNumber from '../Common/MaskPhoneNumber'
import Tooltip from '../Common/Tooltip'
import Avatar from '@Common/Avatar'
import { CommonIcon } from '@icons'
import Loader from '../Common/Loader'
import { isValidImageFile } from '../../utils/validationUtils'
import {
  PropertyCodeTitle,
  PropertyCode,
  DeleteLink,
  CreateLink,
  Name,
  AvatarInput,
  StaffTitle,
  StaffRole,
  Bio
} from './styles'
import Tabs from '@Common/Cards/Tabs'
import { withTheme } from 'emotion-theming'
import type { Theme } from '../../theme'

type User = {
  userId: string,
  email: string,
  role: string,
  title: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  picture: string,
  bio: string,
  lockCode: number
}

type Props = {
  user: User,
  updateSetting: ({}) => mixed,
  updateUser: ({}) => mixed,
  deleteLockCode: (id: string) => {},
  generateLockCode: (id: string) => {},
  uploadProfileImage: (id: string, image: File) => {},
  hasData: boolean,
  toggleStaffProfile: boolean => mixed,
  showStaffProfile: boolean,
  theme: Theme,
  match: { params: Object },
  isSmartEnabled: boolean
}

type State = {
  user: User,
  edit: boolean,
  readOnly: boolean,
  edited: boolean
}

class MyAccount extends React.Component<Props, State> {
  state = {
    user: this.props.user,
    edit: false,
    readOnly: true,
    edited: false
  }

  componentWillReceiveProps = (nextProps: Props) => {
    const { picture, lockCode } = this.state.user

    const lockCodeCheck = lockCode !== nextProps.user.lockCode
    const photoCheck = picture !== nextProps.user.picture

    if (lockCodeCheck || photoCheck) {
      this.setState({
        ...this.state,
        user: nextProps.user
      })
    }
  }

  handleChange = (
    { currentTarget: { id, value } }: SyntheticEvent<HTMLInputElement>,
    checked?: boolean
  ) => {
    const { updateSetting } = this.props
    const { user } = this.state
    if (typeof checked === 'boolean') {
      updateSetting({ [id]: checked })
    } else {
      this.setState(oldState => {
        return {
          ...oldState,
          user: mutableSet(user, id, value),
          edited: true
        }
      })
    }
  }

  uploadProfileImage = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const image = event.target.files[0]

    if (isValidImageFile(image)) {
      this.props.uploadProfileImage(this.state.user.userId, image)
    }
  }

  createLockCode = () => {
    this.props.generateLockCode(this.state.user.userId)
  }

  deleteLockCode = () => {
    this.props.deleteLockCode(this.state.user.userId)
  }

  toggleEdit = () => {
    const { updateUser } = this.props

    if (this.state.edited === true) {
      const { user } = this.state
      updateUser(user)
    }

    this.setState({
      edit: !this.state.edit,
      readOnly: !this.state.readOnly,
      edited: false
    })
  }

  toggleStaffProfile = (
    _e: SyntheticInputEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const { toggleStaffProfile } = this.props
    toggleStaffProfile(checked)
  }

  render() {
    const { user, edit, readOnly } = this.state
    const {
      email,
      phoneNumber,
      picture,
      lockCode,
      bio,
      firstName,
      lastName,
      title,
      role
    } = user
    const {
      hasData,
      showStaffProfile,
      match: {
        params: { propertyId }
      },
      theme,
      isSmartEnabled
    } = this.props
    if (!hasData) return <Loader />

    return (
      <BaseSettingCard
        subheading="my account"
        headerRight={
          <React.Fragment>
            <Tabs
              customStyle={{
                position: 'absolute',
                top: 0,
                right: 0,
                fontSize: theme.custom.font.size.sm
              }}
              tabStyle={2}
              tabs={[
                {
                  name: 'My Info',
                  route: `/properties/${propertyId}/settings/my-account`,
                  query: { type: '' }
                },
                {
                  name: 'My Notifications',
                  route: `/properties/${propertyId}/settings/my-account`,
                  query: { type: 'notifications' }
                }
              ]}
            />
            <div style={{ marginTop: '3em' }}>
              <Button
                onClick={this.toggleEdit}
                background={theme.palette.common.white}
                outlined
                component={() =>
                  edit ? (
                    <CommonIcon
                      name="tick"
                      width="1.2em"
                      height="1.2em"
                      fill={'none'}
                      strokeWidth={2}
                      stroke={theme.palette.primary.dark}
                    />
                  ) : (
                    <CommonIcon
                      name="edit"
                      width="1.2em"
                      height="1.2em"
                      fill={theme.palette.primary.dark}
                      strokeWidth={2}
                    />
                  )
                }
                size="2.7em"
              />
            </div>
          </React.Fragment>
        }
        content={
          <Flex fullWidth marginTop="40px">
            <FlexItem flex={1}>
              <label htmlFor="file-input">
                <Avatar
                  showOverlay={true}
                  picture={picture}
                  overlayText={'upload new +'}
                  size={'192px'}
                  overlayContent={() => (
                    <React.Fragment>
                      <span>upload new +</span>
                      <AvatarInput
                        id="file-input"
                        type="file"
                        onChange={this.uploadProfileImage}
                      />
                    </React.Fragment>
                  )}
                />
              </label>
            </FlexItem>
            <FlexItem flex={3}>
              <Flex direction="column" justifySpaceBetween>
                {edit ? (
                  <Flex direction="column">
                    <Flex justifySpaceBetween>
                      <Flex flex={0.45} direction="column">
                        <TextField
                          id="firstName"
                          onChange={this.handleChange}
                          value={firstName}
                          error={false}
                          showPencil={edit}
                          readOnly={readOnly}
                          label={'first name'}
                          editMode={edit}
                          textAlign={'left'}
                          alwaysShowLabel={true}
                          largeFont={true}
                        />
                      </Flex>

                      <Flex flex={0.45} direction="column">
                        <TextField
                          id="lastName"
                          onChange={this.handleChange}
                          value={lastName}
                          error={false}
                          showPencil={edit}
                          readOnly={readOnly}
                          label={'last name'}
                          editMode={edit}
                          textAlign={'left'}
                          alwaysShowLabel={true}
                          largeFont={true}
                        />
                      </Flex>
                    </Flex>

                    <Flex fullWidth>
                      <TextField
                        fullWidth
                        id="title"
                        onChange={this.handleChange}
                        value={title}
                        error={false}
                        placeholder="Title:"
                        showPencil={edit}
                        readOnly={readOnly}
                        label={'title'}
                        editMode={edit}
                        textAlign={'left'}
                        alwaysShowLabel={true}
                      />
                    </Flex>
                  </Flex>
                ) : (
                  <Flex direction="column">
                    <StaffRole>
                      {`Role/`}
                      {role}
                    </StaffRole>
                    <Name>
                      {firstName} {lastName}{' '}
                    </Name>
                    <StaffTitle>{title ? title : 'Title'}</StaffTitle>
                  </Flex>
                )}
                <Bio>
                  <TextField
                    fullWidth
                    id="bio"
                    onChange={this.handleChange}
                    value={bio || ''}
                    placeholder="Bio"
                    multiline
                    textAlign={'left'}
                    label={edit ? 'bio' : ''}
                    disableUnderline={edit ? false : true}
                    readOnly={readOnly}
                    showPencil={edit}
                    editMode={edit}
                    alwaysShowLabel={true}
                  />
                </Bio>
                <FlexItem>
                  <TextField
                    fullWidth
                    id="phoneNumber"
                    onChange={this.handleChange}
                    value={phoneNumber || ''}
                    prefix="Cell:"
                    textMask={MaskPhoneNumber}
                    readOnly={readOnly}
                    showPencil={edit}
                    editMode={edit}
                  />
                  <TextField
                    fullWidth
                    id="email"
                    onChange={this.handleChange}
                    value={email}
                    prefix="Email:"
                    readOnly={readOnly}
                    showPencil={edit}
                    editMode={edit}
                  />

                  <Flex justifySpaceBetween alignCenter paddingLeft="10px">
                    <font color="#556271">Show Staff Profile</font>
                    <Switch
                      disabled={false}
                      onChange={this.toggleStaffProfile}
                      checked={showStaffProfile}
                    />
                  </Flex>
                </FlexItem>
                {isSmartEnabled && !edit && (
                  <React.Fragment>
                    <FlexItem>
                      <PropertyCodeTitle>
                        {'Property Code'} <Tooltip type="PropertyCode2" />
                      </PropertyCodeTitle>
                    </FlexItem>
                    <Flex>
                      <FlexItem flex={11}>
                        {<PropertyCode>{lockCode}</PropertyCode>}
                      </FlexItem>
                      {lockCode ? (
                        <FlexItem flex={0.5}>
                          <DeleteLink onClick={this.deleteLockCode}>
                            <CommonIcon
                              name="close"
                              width="0.5em"
                              height="0.5em"
                              fill={theme.palette.error.main}
                              strokeWidth={4}
                              stroke={theme.palette.error.main}
                            />
                          </DeleteLink>
                        </FlexItem>
                      ) : (
                        <FlexItem flex={0.5}>
                          <CreateLink onClick={this.createLockCode}>
                            <CommonIcon
                              name="plus"
                              width="0.5em"
                              height="0.5em"
                              fill={theme.palette.primary.green.mint}
                              strokeWidth={4}
                              stroke={theme.palette.primary.green.mint}
                            />
                          </CreateLink>
                        </FlexItem>
                      )}
                    </Flex>
                  </React.Fragment>
                )}
              </Flex>
            </FlexItem>
          </Flex>
        }
      />
    )
  }
}

export default withTheme(MyAccount)
