import get from 'lodash/get'
import { FETCH_PREFIX, SUCCESS } from '../../../helpers/actions/constants'

import {
  UPDATE_USER_SETTING_OPTIMISTIC,
  USER_SETTINGS_FETCH_SECTION,
  fetchUserSettingsSuccess
} from '../actions'

const initialState = {}

const reducer = (state = initialState, action) => {
  const { userId = 'self', ...payload } = action.payload || {}
  const { user_id = 'self', ...attributes } = action.attributes || {}
  switch (action.type) {
    case `${FETCH_PREFIX}${USER_SETTINGS_FETCH_SECTION}${SUCCESS}`:
      return { ...state, [userId]: payload }
    case UPDATE_USER_SETTING_OPTIMISTIC:
      return { ...state, [user_id]: { ...get(state, user_id), ...attributes } }
    default:
      return state
  }
}

export default reducer
