import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import withFetchData from 'zego-shared/views/withFetchData'
import LockCodes from './LockCodes'
import {
  getGroupedLockCodesForLock,
  getLockCodeIdentifier,
  getLockCodeStatusesForLock,
  getLockCodeResyncingStatus
} from 'zego-shared/store/devices/selectors/lockCodes'
import {
  createCode,
  deleteCode,
  editCode,
  extendCode,
  resyncLockCodes
} from 'zego-shared/store/devices/actions'
import {
  fetchLockCodes,
  fetchPermissions
} from 'zego-shared/store/devices/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'

const fetchingSelector = createIsFetchingSelector(['lock-codes'])

const mapStateToProps = (state, { match: { params } }) => {
  const deviceID = parseInt(params.deviceID, 10)
  return {
    isFetching: fetchingSelector(state),
    lockCodes: getGroupedLockCodesForLock(state, deviceID),
    identifier: getLockCodeIdentifier(state, deviceID),
    statuses: getLockCodeStatusesForLock(state, deviceID),
    isResyncing: getLockCodeResyncingStatus(state)
  }
}

const mapDispatchToProps = (dispatch, { match: { params } }) => {
  return {
    fetchData: () => {
      dispatch(fetchPermissions())
      dispatch(fetchLockCodes(params.deviceID))
    },
    editLockCode: (codeID, identifier, value) => {
      dispatch(editCode(params.deviceID, identifier, codeID, value))
    },
    updateExpiryMode: (codeID, identifier, value) => {
      dispatch(extendCode(params.deviceID, identifier, codeID, value))
    },
    addLockCode: (identifier, value, codeType) => {
      dispatch(createCode(params.deviceID, identifier, value, codeType))
    },
    deleteLockCode: (identifier, codeID) => {
      dispatch(deleteCode(params.deviceID, identifier, codeID))
    },
    resyncLockCodes: () => {
      dispatch(resyncLockCodes(params.deviceID))
    },
    showSnackbar: message => {
      dispatch(showSnackbar(message, 'error'))
    }
  }
}

const LockCodesWithFetchData = withFetchData(LockCodes)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LockCodesWithFetchData)
)
