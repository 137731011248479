// @flow
import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import FlexDirection, {
  FlexItem
} from '@Common/Flex/FlexDirection'
import { CarouselUnitProps as Props } from './types'
import {
  UnitContainer,
  StyledTypography,
  UnitName,
  StyledLink,
  ViewLinkItem
} from './styles'
import { CommonIcon } from '@icons'

const getName = name => {
  return name ? name.toUpperCase() : 'Unnamed'
}

//TODO: ISSUE #829
const CarouselUnit = ({
  name,
  id,
  status,
  lastUnit,
  theme,
  device_count,
  match: { params }
}: Props) => {
  return (
    <UnitContainer lastUnit={lastUnit}>
      <FlexDirection fullWidth>
        <FlexItem flex={device_count ? 1 : 9} style={{ whiteSpace: 'nowrap' }}>
          <UnitName>{getName(name)}</UnitName>
        </FlexItem>
        {/* <FlexItem flex={9} style={{ whiteSpace: 'nowrap' }}>
          {name.toUpperCase()}
        </FlexItem> */}
        {device_count ? (
          <React.Fragment>
            <FlexItem
              flex={0.5}
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0px 8px'
              }}>
              <CommonIcon
                name="bullet"
                width="4px"
                height="4px"
                fill={theme.palette.secondary.main3}
                style={{ alignSelf: 'center' }}
              />
            </FlexItem>
            <FlexItem flex={11}>
              <StyledTypography>{`${device_count} device${
                device_count !== 1 ? 's' : ''
              }`}</StyledTypography>
            </FlexItem>
          </React.Fragment>
        ) : null}
        <ViewLinkItem flex={1}>
          <StyledLink to={`/properties/${params.propertyId}/units/${id}/smart`}>
            View
          </StyledLink>
        </ViewLinkItem>
      </FlexDirection>
    </UnitContainer>
  )
}

export default withRouter(withTheme(CarouselUnit))
