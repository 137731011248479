import styled from '@emotion/styled'

export const ActivationText = styled('div')`
  margin-top: 20px;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`

export const ActivationLink = styled('span')`
  color: ${({ theme }) => theme.palette.primary.green.mint};
`
