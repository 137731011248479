import { connect } from 'react-redux'
import WorkOrderNew from './WorkOrderNew'
import { submitWorkOrder, fetchWorkOrderOptions } from 'zego-shared/store/workOrders/actions'
import { fetchUnits } from 'zego-shared/store/select/units/actions'
import { fetchPropertyUsersSummary } from 'zego-shared/store/users/propertyUsersSummary/actions'
import { getUnits } from 'zego-shared/store/select/units/selectors'
import { getCategories, getWorkOrderAssignees } from 'zego-shared/store/workOrders/selectors'
import { getUnitResidents } from 'zego-shared/store/users/selectors'
import withFetchData from 'zego-shared/views/withFetchData'

export const mapStateToProps = state => ({
  units: getUnits(state),
  categories: getCategories(state),
  residents: getUnitResidents(state),
  assignees: getWorkOrderAssignees(state)
})

export const mapDispatchToProps = (
  dispatch,
  { history, match: { url, params: { propertyId } } }
) => ({
  fetchData: _ => {
    dispatch(fetchWorkOrderOptions())
    dispatch(fetchUnits())
    dispatch(fetchPropertyUsersSummary())
  },
  submitWorkOrder: (workOrder) => dispatch(submitWorkOrder(
    workOrder,
    history,
    `${url.split('/work-orders')[0]}/work-orders/summary`
  ))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(WorkOrderNew))
