// @flow
import React from 'react'
import moment, { Moment } from 'moment'
import isEqual from 'lodash/isEqual'

import { BrowserHistory } from 'history'
import { withRouter } from 'react-router-dom'
import type { Match } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import MenuItem from '@material-ui/core/MenuItem'

import RightSection from './RightSection'

import Grid, { GridItem } from '../../Common/Grid'
import Calendar from '@Common/Calendar'
import TextField from '../../Common/TextField'
import AlertModal from '@Common/AlertModal/AlertModal'
import SuccessScreen, { StatusScreenContent } from '../../Common/StatusScreen'
import Flex from '@Common/Flex'
import withClickOutside from '../../Common/withClickOutside'
import {
  validateEmail,
  validatePhoneNumber
} from '../../../utils/validationUtils'

import { Theme } from '../../../theme'

import { InputWrapper } from '../styles'

import { getDate } from '../../../utils/dateUtils'

import { CodesDetailsEditContainer, Underline, CalendarWrapper } from './styles'

import { Header } from '../VendorLockCodes/styles'

type Props = {
  items: Object[],
  codeDetails: Object,
  codeUnits: Object[],
  unitList: Object[],
  theme: Theme,
  moment: Moment,
  isReminderPage: boolean,
  changePackageStatus: (name: string, id: number) => void,
  vendorCodes: Object[],
  history: BrowserHistory,
  match: Match,
  setClickOutsideDomNode: (ref: Object | null) => void,
  hasData: boolean,
  searchUnits: (name: string) => mixed,
  createMode: boolean,
  isFetchUnits: boolean,
  userToken: string,
  saveVendorCode: (vendor: Object, token: string) => string,
  displayErrorMessage: (message: string) => mixed,
  isVendorNameEditable: boolean,
  userRole: string,
  displayErrorMessage: (message: string) => void,
  history: Object,
  propertyId: string,
}

type State = {
  searchTerm: string,
  saveDetailsSuccess: boolean,
  removeMode: boolean,
  selectedUnits: number[],
  addMode: boolean,
  removeMode: boolean,
  showEndDatePicker: boolean,
  showStartDatePicker: boolean,
  codeDetails: Object,
  codeUnits: Object[],
  hasData: boolean,
  showDialog: boolean,
  backUrl: string
}

const accessTypes = [
  { id: 'property_vacant', name: 'Vacant' },
  { id: 'property', name: 'Property' },
  { id: 'unit', name: 'Unit' }
]

class CodeDetailsEdit extends React.PureComponent<Props, State> {
  state = {
    removeMode: false,
    addMode: false,
    selectedUnits: [],
    searchTerm: '',
    saveDetailsSuccess: false,
    showEndDatePicker: false,
    showStartDatePicker: false,
    codeDetails: this.props.codeDetails,
    codeUnits: this.props.codeUnits,
    hasData: this.props.hasData,
    showDialog: false,
    backUrl: ''
  }

  componentDidUpdate(prevProps: Props) {
    const {
      codeDetails,
      codeUnits,
      hasData,
      createMode,
      isVendorNameEditable
    } = this.props
    if (createMode && !isVendorNameEditable) return
    if (!isEqual(codeDetails, prevProps.codeDetails)) {
      this.setState({ codeDetails })
    }

    if (!isEqual(codeUnits, prevProps.codeUnits)) {
      this.setState({ codeUnits })
    }

    if (hasData !== prevProps.hasData) {
      this.setState({ hasData })
    }
  }

  validateCodeDetails = () => {
    const { codeDetails } = this.state
    let error = ''
    Object.keys(codeDetails).every((key: string) => {
      const value = codeDetails[key]
      if (!value) {
        error = `${key} field is required`
        return false
      }

      if (key === 'Email' && !validateEmail(value)) {
        error = 'Email is invalid'
        return false
      }
      if (key === 'Phone' && !validatePhoneNumber(value)) {
        error = 'Phone number is invalid'
        return false
      }

      if (key === 'End Date' && !moment(value).isAfter(moment())) {
        error = 'End Date must be greater than today'
        return false
      }
      return true
    })
    return error
  }

  checkForUnsavedChanges = () => {
    const { codeUnits, codeDetails } = this.state
    return (
      !isEqual(codeDetails, this.props.codeDetails) ||
      !isEqual(codeUnits, this.props.codeUnits)
    )
  }

  isUnitAccessType = () => {
    return this.state.codeDetails['Access Type'].name === 'Unit'
  }

  goBack = () => {
    const {
      history,
      match: {
        url,
        params: { codeId, vendorId }
      },
      createMode
    } = this.props

    let { backUrl, saveDetailsSuccess } = this.state

    if (vendorId === 'new' && !saveDetailsSuccess) {
      backUrl = `${url.split('/vendor')[0]}`
    }

    if (!backUrl) {
      const splitter = createMode ? '/create' : '/edit'
      backUrl = `${url.split(splitter)[0]}/${codeId || ''}`
    }

    history.push(backUrl)
  }

  getUnitIds = (): number[] | void => {
    const { codeUnits = [] } = this.state
    if (Array.isArray(codeUnits) && codeUnits.length) {
      return codeUnits.map(unit => unit.id)
    }
  }

  saveDetails = async () => {
    const {
      userToken,
      saveVendorCode,
      displayErrorMessage,
      createMode
    } = this.props
    const { codeDetails } = this.state
    const hasUnSavedData = this.checkForUnsavedChanges()
    if (hasUnSavedData || createMode) {
      const error = this.validateCodeDetails()
      if (!error) {
        const backUrl: string = await saveVendorCode(
          { ...codeDetails, units: this.getUnitIds() },
          userToken
        )
        if (backUrl) {
          this.setState({ saveDetailsSuccess: true, backUrl })
        }
      } else {
        displayErrorMessage(error)
      }
    } else {
      this.goBack()
    }
  }

  handleBackButtonClick = () => {
    const hasUnSavedData = this.checkForUnsavedChanges()
    if (!hasUnSavedData) {
      return this.goBack()
    }
    this.setState({ showDialog: true })
  }

  closeDialog = () => {
    this.setState({ showDialog: false })
  }

  handleClickOutside = () => {
    this.setState({
      showEndDatePicker: false,
      showStartDatePicker: false
    })
  }

  openRemoveMode = () => {
    this.setState({ removeMode: true })
  }

  closeRemoveMode = () => {
    const { codeUnits, selectedUnits } = this.state
    const filteredCodeUnits = codeUnits.filter(({ id }) => {
      if (selectedUnits.includes(id)) {
        return false
      }
      return true
    })
    this.setState({
      removeMode: false,
      codeUnits: filteredCodeUnits,
      selectedUnits: []
    })
  }

  openAddMode = () => {
    this.setState({ addMode: true })
  }

  closeAddMode = () => {
    this.setState({ addMode: false })
  }

  handleSearchChange = ({ target: { value } }) => {
    this.setState({ searchTerm: value })
    this.props.searchUnits(value)
  }

  handleUnitListItemClick = (unit: Object) => {
    const { codeUnits } = this.state
    const { id } = unit
    const hasUnit = codeUnits.find(codeUnit => codeUnit.id === id)
    if (!hasUnit) {
      this.setState({
        codeUnits: [...codeUnits, unit],
        searchTerm: ''
      })
    }
    this.setState({ addMode: false })
  }

  selectUnit = id => {
    const { selectedUnits } = this.state
    this.setState({ selectedUnits: [...selectedUnits, id] })
  }

  unselectUnit = value => {
    const { selectedUnits } = this.state
    const index = selectedUnits.indexOf(value)
    const newUnits = [...selectedUnits]
    newUnits.splice(index, 1)
    this.setState({ selectedUnits: newUnits })
  }

  unitIsSelected = value => {
    const { selectedUnits } = this.state
    return selectedUnits.indexOf(value) !== -1 ? true : false
  }

  handleKeyPress = () => {}

  searchUnits = () => {
    this.props.searchUnits(this.state.searchTerm)
  }

  handleChange = key => ({ target: { value } }) => {
    this.setState({ codeDetails: { ...this.state.codeDetails, [key]: value } })
  }

  handleDateChange = key => (date: Moment) => {
    this.setState({
      codeDetails: { ...this.state.codeDetails, [key]: getDate(date) }
    })
  }

  handleDropDownChange = (event: Object) => {
    const { value } = event.target
    const selectedType = accessTypes.filter(accessType => {
      return value === accessType.id
    })[0]

    this.setState({
      codeDetails: {
        ...this.state.codeDetails,
        'Access Type': selectedType
      }
    })
  }

  toggleDatePicker = value => {
    this.setState({
      [`show${value}DatePicker`]: !this.state[`show${value}DatePicker`],
      [`show${value === 'End' ? 'Start' : 'End'}DatePicker`]: false
    })
  }

  createSelect = (codeDetails, field, key) => {
    return (
      <InputWrapper key={key}>
        <TextField
          fullWidth
          onChange={this.handleDropDownChange}
          textAlign="left"
          value={codeDetails[field].name}
          InputLabelProps={{ shrink: true }}
          alwaysShowLabel
          label={field}
          select
          showPencil={false}
          SelectProps={{
            renderValue: value => value,
            disableUnderline: false
          }}>
          {accessTypes.map(({ id, name }) => (
            <MenuItem
              style={{ display: 'flex', alignItems: 'center' }}
              key={id}
              value={id}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      </InputWrapper>
    )
  }

  render() {
    const {
      theme,
      setClickOutsideDomNode,
      unitList,
      createMode,
      isFetchUnits,
      isVendorNameEditable,
      userRole,
      displayErrorMessage,
      history,
      propertyId,
    } = this.props
    const {
      saveDetailsSuccess,
      removeMode,
      addMode,
      searchTerm,
      codeDetails,
      codeUnits,
      hasData,
      showDialog
    } = this.state

    if (userRole === "property_staff") {
      history.replace(`/properties/${propertyId}/lock-codes`)
      setTimeout(() => {
        displayErrorMessage("Creating/Editing Vendor Codes is not available for Property Staff.")
      }, 1000)

      return null
    }

    if (!hasData && !createMode) return null

    const isUnit = this.isUnitAccessType()

    return (
      <React.Fragment>
        <SuccessScreen
          open={saveDetailsSuccess}
          autoHideDuration={2000}
          goTo={this.goBack}>
          <StatusScreenContent
            heading="SUCCESS!"
            subheading={`Code details have been saved`}
          />
        </SuccessScreen>
        <GridItem
          columnStart={1}
          columnSpan={isUnit ? 8 : 10}
          rowStart={1}
          rowSpan={12}>
          <CodesDetailsEditContainer isUnit={isUnit}>
            <Grid>
              <GridItem
                style={{ position: 'relative' }}
                columnStart={1}
                columnSpan={12}
                rowStart={1}
                rowSpan={12}>
                <Header>
                  <div />
                  <div>
                    {createMode ? 'NEW VENDOR CODE' : 'CODE DETAILS EDIT'}
                  </div>
                </Header>
                <Underline />
                <Flex
                  className="container"
                  direction="column"
                  paddingTop="4em"
                  paddingLeft={isUnit ? '9em' : '20em'}
                  paddingRight={isUnit ? '9em' : '20em'}
                  fullWidth
                  style={{ height: '88%' }}>
                  {Object.keys(codeDetails).map((field, index) => {
                    const isDatePicker = ['Start Date', 'End Date'].includes(
                      field
                    )
                    const currentDatePicker = field.split(' ')[0]
                    const disable =
                      isVendorNameEditable && field === 'Vendor Name'
                    return field === 'Access Type' ? (
                      this.createSelect(codeDetails, field, index)
                    ) : (
                      <InputWrapper key={index}>
                        <TextField
                          fullWidth
                          onChange={this.handleChange(field)}
                          textAlign="left"
                          value={codeDetails[field]}
                          InputLabelProps={{ shrink: true }}
                          alwaysShowLabel
                          label={field}
                          readOnly={disable}
                          showPencil={!disable}
                          endAdornment={isDatePicker ? 'calendar' : null}
                          endAdornmentOnClick={
                            isDatePicker
                              ? this.toggleDatePicker.bind(
                                  this,
                                  currentDatePicker
                                )
                              : null
                          }
                        />
                        {isDatePicker &&
                          this.state[`show${currentDatePicker}DatePicker`] && (
                            <CalendarWrapper
                              ref={ref => setClickOutsideDomNode(ref)}>
                              <Calendar
                                moment={moment(codeDetails[field])}
                                handleChange={this.handleDateChange(field)}
                              />
                            </CalendarWrapper>
                          )}
                      </InputWrapper>
                    )
                  })}
                </Flex>
              </GridItem>
            </Grid>
          </CodesDetailsEditContainer>
        </GridItem>
        <GridItem
          columnStart={isUnit ? 8 : 10}
          columnSpan={isUnit ? 5 : 8}
          rowStart={1}
          rowSpan={12}>
          <RightSection
            units={codeUnits}
            unitList={unitList}
            addMode={addMode}
            searchTerm={searchTerm}
            theme={theme}
            removeMode={removeMode}
            unitIsSelected={this.unitIsSelected}
            handleSearchChange={this.handleSearchChange}
            handleKeyPress={this.handleKeyPress}
            unselectUnit={this.unselectUnit}
            selectUnit={this.selectUnit}
            openAddMode={this.openAddMode}
            openRemoveMode={this.openRemoveMode}
            closeAddMode={this.closeAddMode}
            closeRemoveMode={this.closeRemoveMode}
            goBack={this.handleBackButtonClick}
            saveDetails={this.saveDetails}
            searchUnits={this.searchUnits}
            handleListItemClick={this.handleUnitListItemClick}
            isUnitType={this.isUnitAccessType()}
            isFetchUnits={isFetchUnits}
          />
        </GridItem>
        <AlertModal
          title="Would you like to save before leaving?"
          message=""
          buttons={[
            {
              onClick: this.saveDetails,
              text: 'YES'
            },
            {
              onClick: this.goBack,
              color: theme.palette.primary.navy.spaceBlue,
              text: 'NO'
            }
          ]}
          show={showDialog}
          onClose={this.closeDialog}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(withTheme(withClickOutside(CodeDetailsEdit)))
