// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import Flex from '@Common/Flex'
import Avatar from '@Common/Avatar'
import Linkify from '@Common/Linkify'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../Common/Loader'
import {
  PostDateText,
  PostsWrapper,
  ReadMoreStyled,
  PostStyled,
  PostImageStyled,
  FavoriteIconStyled,
  ChatBubbleIconStyled,
  PostBodyStyled,
  PostTitleStyled,
  PostInfoWrapper,
  PostUsernameStyled,
  PostNumberOfLikesStyled,
  PostNumberOfCommentsStyled,
  EmptyListStyled,
  Backdrop,
  WhiteTop,
} from './styles'

type Props = {
  posts: Array<{
    postImage: string,
    numberOfLikes: number,
    numberOfComments: number,
    title: string,
    userPicture: string,
    userName: string,
    dateText: string,
    body: string,
    showReadMore: boolean,
    url: string,
  }>,
  hasData: boolean,
  fetchNext: () => void,
  shouldFetchNext: boolean,
  isFetching: boolean,
}

class PostsFeed extends React.Component<Props, {}> {
  render() {
    const {
      posts,
      hasData,
      fetchNext,
      shouldFetchNext,
      isFetching,
    } = this.props
    return isFetching ? (
      <Loader />
    ) : hasData ? (
      posts.length === 0 ? (
        <EmptyListStyled>No posts have been created</EmptyListStyled>
      ) : (
        <React.Fragment>
          <div
            style={{
              position: 'relative',
              width: '130%',
              marginTop: '2em',
            }}>
            <WhiteTop />
          </div>
          <InfiniteScroll
            dataLength={posts.length}
            next={fetchNext}
            hasMore={shouldFetchNext}
            loader={
              <Flex justifyCenter paddingBottom="2em">
                <Loader fullScreen={false} color="#ccc" />
              </Flex>
            }>
            <Backdrop />
            {posts.map((post, index) => (
              <Link key={index} to={post.url} style={{ width: '100%' }}>
                <PostsWrapper>
                  <PostImageStyled src={post.postImage} alt="" />
                  <PostInfoWrapper>
                    <Flex>
                      <FavoriteIconStyled />
                      <PostNumberOfLikesStyled>
                        {post.numberOfLikes} LIKES
                      </PostNumberOfLikesStyled>
                      <ChatBubbleIconStyled />
                      <PostNumberOfCommentsStyled>
                        {post.numberOfComments}{' '}
                        {post.numberOfComments === 1 ? 'COMMENT' : 'COMMENTS'}
                      </PostNumberOfCommentsStyled>
                    </Flex>
                    <PostTitleStyled>{post.title}</PostTitleStyled>
                    <PostStyled>
                      <Avatar
                        position={'static'}
                        picture={post.userPicture}
                        size="3em"
                      />
                    </PostStyled>
                    <PostUsernameStyled>{post.userName}</PostUsernameStyled>
                    <PostDateText>{post.dateText}</PostDateText>
                    <PostBodyStyled>
                      <Linkify tagName="span" content={post.body} />
                      {post.showReadMore && (
                        <ReadMoreStyled> Read More</ReadMoreStyled>
                      )}
                    </PostBodyStyled>
                  </PostInfoWrapper>
                </PostsWrapper>
              </Link>
            ))}
          </InfiniteScroll>
        </React.Fragment>
      )
    ) : null
  }
}

export default withTheme(PostsFeed)
