import styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'

export const PhotoNavigation = styled(IconButton)`
  position: absolute;
  background-color: ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
  width: 2.7em;
  height: 2.7em;
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.yellow.solarFlare};
 }
`

export const DropBox = styled('div')`
  height: 200px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23979797FF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='27' stroke-linecap='butt'/%3e%3c/svg%3e");
`

export const DeletePhotoButton = styled(IconButton)`
  border-radius: 0;
  margin: 0;
  background-color: ${({ theme }) => theme.palette.primary.main};
  min-width: 0px;
  width: 45px;
  height: 45px;
`
