// @flow

import React, { memo } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import { Grid } from '@material-ui/core'
import { find, findIndex } from 'lodash'
import queryString from 'query-string'
import Header from '@Common/Header'
import Tabs from '@Common/Cards/Tabs'
import ScrollingGrid from '@Common/ScrollingGrid'
import SizeObservingContainer from "../Common/SizeObservingContainer";
import { CommonIcon } from '@icons'
import placeholder from '../../images/amenity_placeholder.png'
import AmenityAvailabilitySchedule from './AmenityPro/AmenityProAvailabilitySchedule.js'
import { getDefaultAmenityProperties } from '../../utils/scheduleUtils'

import {
  DashBoardScheduleContainer,
  MultiDayCard,
  PageContainer,
  PageContent,
  PageHeader,
  StyledCard,
  StyledLink,
  TileAmenityName,
  TileAmenityOccupancy,
  TileAmenityPin,
  TileAmenityRow,
  TileContents,
  TileDraftMode,
  TileFooter,
} from './styles'

type AmenityPhoto = {
  photoUuid: string,
  url: string,
}

type AmenityItem = {
  admin: string,
  padding: number,
  facilityUuid: number,
  name: string,
  totalOccupancy: number,
  reservationLimit: number,
  photos: Array<AmenityPhoto>,
  pinnedPhotoUuid: ?string,
  pinned: boolean,
  published: boolean,
  rsvpRequired: boolean,
  reserveAheadRequired: boolean,
  extraAreaQuantity: number,
  extraAreaName: string,
  reservationType: string,
  amenityType: string,
  minMultiDayReservation: number,
  maxMultiDayReservation: number,
  upcomingAvailability: Object,
}

type Props = {
  items: Array<AmenityItem>,
  hasData: boolean,
  isFetching: boolean,
  isUpdating: boolean,
  createAmenity: (Object) => null,
  pinAmenity: (number) => null,
  unPinAmenity: (number) => null,
  getAvailability: (Object) => null,
  match: Object,
  theme: Object,
  location: Object
}

type State = {
  dashboardBoundingRect: DOMRect,
  tab: string
}

const tabs = [
  {
    name: 'Spaces',
    query: { filter: 'space' },
    key: 'space',
  },
  {
    name: 'Items',
    query: { filter: 'item' },
    key: 'item',
  }
]

class Amenities extends React.Component<Props, State> {
  itemWidth: number
  numberOfItemsPerSlide: number

  constructor(props: Props) {
    super(props)

    this.state = {
      tab: this.getInitialTab(),
      dashboardBoundingRect: DOMRect.fromRect({x: 0, y: 0, width: 0, height: 0}),
    }

    this.getItemsForCurrentTab = this.getItemsForCurrentTab.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.handlePin = this.handlePin.bind(this)
    this.createSpace = this.createSpace.bind(this)
    this.createItem = this.createItem.bind(this)
  }

  createItem = () => this.props.createAmenity(getDefaultAmenityProperties({ amenityType: 'item', reservationType: 'hourly' }))
  createSpace = () => this.props.createAmenity(getDefaultAmenityProperties({ amenityType: 'space', reservationType: 'hourly' }))

  getItemsForCurrentTab = () => {
    const {
      state: { tab: key = 'space' } = {},
      props: { items = [] } = {},
    } = this
    return items.filter(item => item.amenityType === key)
  }

  handleTabChange = (e) => {
    const tab = find(tabs, t => t.name === e.target.innerText)
    this.setState({ tab: tab.key })
  }

  handlePin = (e, pinned, facilityUuid) => {
    e.preventDefault()
    pinned
      ? this.props.unPinAmenity(facilityUuid)
      : this.props.pinAmenity(facilityUuid)
  }

  getInitialTab() {
    const { items, location: { search } } = this.props
    const filter = queryString.parse(search).filter

    if (filter && ['space', 'item'].indexOf(filter) > -1) return filter

    const [itemAmenities, spaceAmenities] = [[], []]
    items.forEach(item => {
      const { amenityType } = item
      switch (amenityType) {
        case 'item':
          itemAmenities.push(item)
          break
        case 'space':
        default:
          spaceAmenities.push(item)
          break
      }
    })

    return !!itemAmenities.length === !!spaceAmenities.length
      ? 'space'
      : !!itemAmenities.length
        ? 'item'
        : 'space'
  }

  onDashboardResize = (dashboardBoundingRect) => {
    this.setState((oldState) => ({
      ...oldState,
      dashboardBoundingRect,
    }))
  }

  getWindowStart(availability) {
    const {currentFacilityDate: { time } = { time: 0 } } = availability
    const minutes = time % 100
    const hours = time - minutes
    const roundedMinutes = minutes >= 30 ? 30 : 0
    const roundedTime = (hours + roundedMinutes)
    return Math.min(roundedTime)
  }

  generateMultidayCard(upcomingAvailability) {
    // TODO: This is a placeholder until UX team delivers the definitve design
    return (
      <MultiDayCard>
        {upcomingAvailability.timeSlots.length ?
          'Multiday: The facility is booked.'
          :
          'The Facility is available.'
        }
      </MultiDayCard>
    )
  }

  getTotalOccupancyString = ({ amenityType, reservationLimit, totalOccupancy }) => {
    if (amenityType === 'item' && reservationLimit) {
      return `Total: ${reservationLimit}`
    } else if (amenityType === 'space' && totalOccupancy) {
      return `Occupancy: ${totalOccupancy}`
    }
    return null
  }

  renderCard = memo(({ amenity, propertyId, index, containerDimensions }: Object) => {
    const {
      upcomingAvailability,
      facilityUuid,
      name,
      padding,
      photos,
      pinnedPhotoUuid,
      pinned,
      published,
      rsvpRequired,
      extraAreaQuantity,
      extraAreaName,
      reservationType,
    } = amenity
    const pinnedPhoto = photos.find(p => p.photoUuid === pinnedPhotoUuid)
    const image = pinnedPhoto?.url || photos[0]?.url || placeholder
    const pinIcon = pinned ? 'pin' : 'pin-empty'

    return (
      <div>
        <StyledCard id={`card-${facilityUuid}`} backgroundimage={image} raised>
          <Link
            to={`/properties/${propertyId || ''}/amenities/${facilityUuid}`}>
            <TileContents>
              {!published && <TileDraftMode>Draft Mode</TileDraftMode>}
              <TileFooter>
                <TileAmenityName id={`name-${facilityUuid}`} name={name}/>
                <TileAmenityRow>
                  <TileAmenityOccupancy
                    id={`reservationLimit-${facilityUuid}`}
                    text={this.getTotalOccupancyString(amenity)}/>
                  <TileAmenityPin
                    id={`pin-${facilityUuid}`}
                    onClick={(e) => this.handlePin(e, pinned, facilityUuid)}>
                    <CommonIcon width="14px" height="14px" name={pinIcon}/>
                  </TileAmenityPin>
                </TileAmenityRow>
              </TileFooter>
            </TileContents>
          </Link>
        </StyledCard>
        <DashBoardScheduleContainer>
          <AmenityAvailabilitySchedule
            startTime={this.getWindowStart(upcomingAvailability)}
            windowDuration={240}
            availability={upcomingAvailability}
            padding={padding}
            reservationType={reservationType}
            rsvpRequired={rsvpRequired}
            extraAreaQuantity={extraAreaQuantity}
            extraAreaName={extraAreaName}
            showNowBlock={index === 0}
            containerDimensions={containerDimensions}
            forDashboard
          />
        </DashBoardScheduleContainer>
      </div>
    )
  })

  render() {
    const { theme } = this.props
    const { tab } = this.state
    const {
      match: {
        params: { propertyId },
      },
    } = this.props

    let tabIndex = findIndex(tabs, t => t.key === tab)

    const formattedDateString = new Date()
      .toLocaleDateString('en-us', { dateStyle: 'full' })
    const typeLabel = tab?.charAt(0).toUpperCase() + tab?.slice(1)
    const RenderCard = this.renderCard
    return (
      <PageContainer columnStart={1} columnSpan={12} rowStart={1} rowSpan={12}>
        <PageHeader>
          <Header alternateFontSize>Amenities</Header>
          <div>
            Create amenity options your residents can view within their resident
            app. Allow them to reserve an amenity or view its details.
          </div>
        </PageHeader>
        <PageContent raised>
          <Grid container>
            <Grid item xs={12} style={{ paddingLeft: 30, paddingRight: 30 }}>
              <Grid container wrap="nowrap" alignItems="center">
                <Grid item xs={12}>
                  <Tabs
                    selected={tabIndex}
                    onTabClick={this.handleTabChange}
                    selectTextColor={theme.palette.common.black}
                    tabs={tabs}
                    customStyle={`
                      .tab-root {
                      color: ${theme.palette.grey.main};
                      font-weight: ${theme.custom.font.weight.light};
                      margin-top: 10px;
                    }`}
                  />
                </Grid>
                <Grid item>
                  <StyledLink onClick={this.createItem} id="addItemLink">
                    Add new Rentable Item
                  </StyledLink>
                </Grid>
                <Grid item style={{ minWidth: 30 }} />
                <Grid item>
                  <StyledLink onClick={this.createSpace} id="addSpaceLink">
                    Add new Reservable Space
                  </StyledLink>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: 30, paddingBottom: 0 }}>
              <h1 id="amenitySchedLabel">{`Amenity ${typeLabel} Schedule`}</h1>
            </Grid>
            <Grid item xs={12} style={{ padding: 30, paddingTop: 0} }>
              <h2 id="formattedDateStr">{formattedDateString}</h2>
            </Grid>
          </Grid>
          <SizeObservingContainer style={{ maxWidth: '1750px' }} onSizeChanged={this.onDashboardResize}>
            <ScrollingGrid numberOfItemsPerSlide={4.7}>
              {this.getItemsForCurrentTab().map((item, index) => <RenderCard
                containerDimensions={this.state.dashboardBoundingRect}
                amenity={item}
                propertyId={propertyId}
                index={index}
              />)}
            </ScrollingGrid>
          </SizeObservingContainer>
        </PageContent>
      </PageContainer>
    )
  }
}

export default withRouter(withTheme(Amenities))
