import styled from '@emotion/styled'
import MaterialTypography from '@material-ui/core/Typography'
import MaterialListItem from '@material-ui/core/ListItem'
import { Head } from '@Common/Cards/BaseCard/styles'

export const InfoHead = styled(Head)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.text.light};
  padding-top: 25px;
`
export const Body = styled('div')`
  position: relative;
  padding: 60px 0 25px 0;
`

export const Typography = styled(MaterialTypography)`
  font-size: ${({ theme }) => theme.custom.font.size.xl};
  color: ${({ theme }) => theme.palette.common.black};
`

export const ListItem = styled(MaterialListItem)`
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  padding-left: 10px;
`

export const StyledLink = styled('a')`
  color: ${({ theme }) => theme.palette.primary.green.mint};
`
