import React from 'react'
import { fetchUserSettings } from 'zego-shared/store/settings/actions'
import { connect } from 'react-redux'
import ResidentProfile from './ResidentProfile'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { fetchUserProfile } from 'zego-shared/store/users/profile/actions'
import { deleteUser } from 'zego-shared/store/users/user/actions'
import { getResidentSurveysEnabled } from 'zego-shared/store/settings/propertySettings/selectors'

import {
  getUserProfile,
  hasProfileData
} from 'zego-shared/store/users/selectors'

const ResidentProfileWithFetchData = withFetchData(ResidentProfile)
const fetchingSelector = createIsFetchingSelector(['users', 'profile', 'usersettings'])

const ResidentProfileContainer = props => (
  <ResidentProfileWithFetchData
    {...props}
    alertTitle="Delete user"
    alertMessage="Do you really want to delete this user ?"
    alertYesButtonText="YES"
    alertNoButtonText="NO"
  />
)

const mapStateToProps = (state, { match: { params: { profileInfo } } }) => {
  return {
    hasData: hasProfileData(state),
    isFetching: fetchingSelector(state),
    profile: getUserProfile(state),
    currentTab: profileInfo,
    residentSurveysEnabled: getResidentSurveysEnabled(state)
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { userId, propertyId }
    },
    history
  }
) => {
  return {
    fetchData: () => {
      dispatch(fetchUserProfile(userId))
      dispatch(fetchUserSettings(userId))
    },
    onAlertClickYes: () => {
      dispatch(deleteUser(history, propertyId, userId))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResidentProfileContainer)
