import { call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken, getUserRole } from '../../authorization/selectors'
import { showSnackbar } from '../../snackbar/actions'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  failedToFetchReplacementOptions
} from '../../../utils/messages'
import {
  fetchReplacementOptionsAction,
  fetchReplacementOptionsStart,
  fetchReplacementOptionsSuccess,
  fetchReplacementOptionsError
} from './actions'

export function* fetchReplacementOptions({ propertyId }) {
  try {
    yield put(fetchReplacementOptionsStart())
    const authToken = yield select(getToken)
    const userRole = yield select(getUserRole)

    //  Do property_staff check due to core api role check limitations, double refresh otherwise
    const response = (userRole === "property_staff")
      ? []
      : yield call(
        api.getGroupMessagingReplacementOptions,
        authToken,
        propertyId
      )
    yield put(fetchReplacementOptionsSuccess(response))
  } catch (error) {
    yield put(fetchReplacementOptionsError(error))
    yield put(showSnackbar(failedToFetchReplacementOptions, 'error'))
    log(`Failed to fetch replacementOptions`)
  }
}

function* watchFetchReplacementOptions() {
  yield takeLatest(fetchReplacementOptionsAction().type, fetchReplacementOptions)
}

export default [watchFetchReplacementOptions()]
