import styled from '@emotion/styled'
import theme from "../../../theme";

export const ImageStyle = styled.img`
  height: 100%;
  object-fit: cover;
`

export const MainContainer = styled('div')`
  width: 100%;
  height: 400px;
`

export const LeftContainer = styled('div')`
  width: 70%;
  height: 360px;
  object-fit: contain;
  float: left;
  overflow:hidden;
`

export const RightContainer = styled('div')`
  width: 30%;
  height: 380px;
  float: right;
  object-fit: contain;
  overflow-y: auto;
`

export const ImageContainer = styled('div')`
  width: 100%;
  height: 100px;
  object-fit: contain;
  alignItems: center;
  padding-bottom: 20px;
  object-fit: stretch;
`

export const styles = {
  root: {
    width: '550px',
    padding: '2em',
    borderTopRightRadius: '3em',
    borderBottomLeftRadius: '3em'
  },
  closeButton: {
    float: "right",
    fontSize: '1em',
    color: theme.palette.text.grey,
    width: '20%'
  },
  closeIcon: {
    marginLeft: '5px',
    color: theme.palette.text.black
  },
  title: {
    textAlign: 'Left',
    fontSize: '24px',
    width: '75%',
    color: theme.palette.text.black
  },

  titleDesc: {
    textAlign: 'Left',
    marginTop: '-20px',
    width: '75%',
    paddingLeft: '-10%',
    fontSize: '12px',
    color: theme.palette.text.grey
  },

  activeImg: {
    height: '380px'
  },

  twoImage: {
    paddingTop: '85px'
  },

  oneImage: {
    width: '100%'
  },

  imgContainer: {
    marginBottom: '20px'
  },

  activeImgCss: {
    borderWidth: 'thin',
    borderColor: theme.palette.primary.green.iceberg
  }
}
