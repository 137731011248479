import { select, put, call, takeLatest, takeEvery } from 'redux-saga/effects'
import api from '../../../../utils/api'
import log from '../../../../utils/logger'
import isEmpty from 'lodash/isEmpty'

import { getToken } from '../../../authorization/selectors'
import {
  fetchUserSettingsStart,
  fetchUserSettingsSuccess,
  fetchUserSettingsError,
  fetchUserSettings as fetchUserSettingsAction,
  updateUserSettingOptimistic,
  updateUserSettingSuccess,
  updateUserSettingError,
  UPDATE_USER_SETTING
} from '../actions'
import { getUserSettings } from '../selectors'

export function* fetchUserSettings(action) {
  let userId
  if (action != null) userId = action.userId
  try {
    const authToken = yield select(getToken)
    yield put(fetchUserSettingsStart())
    let response = yield call(api.getUserSettings, authToken, userId)
    if (isEmpty(response)) {
      response = {
        show_staff_profile: false,
        resident_zego_smart_enabled: true
      }
    }
    if (userId != null) {
      response = { ...response, userId }
    }
    yield put(fetchUserSettingsSuccess(response))
    return response
  } catch (error) {
    yield put(fetchUserSettingsError(error))
    log(`Failed to fetch properties. Error: ${error}`)
  }
}

export function* updateUserSetting(action: Object) {
  const settings = yield select(getUserSettings, action.attributes.user_id)
  const { attributes: newAtts } = action

  let currentAtts = {}
  let attsToUpdate = {}

  // loop through each attribute
  // if attribute is different from what we have then add it to update list
  // also save it to current attributes so we can revert later if an error occurs
  for (let key in newAtts) {
    if (newAtts[key] !== settings[key]) {
      attsToUpdate[key] = newAtts[key]
      currentAtts[key] = settings[key]
    }
  }

  if (Object.keys(attsToUpdate).length > 0) {
    const authToken = yield select(getToken)

    try {
      yield put(updateUserSettingOptimistic(attsToUpdate))
      const response = yield call(
        api.updateUserSettings,
        authToken,
        attsToUpdate
      )
      let serverAtts = {}

      for (let key in attsToUpdate) {
        if (response[key]) {
          serverAtts[key] = response[key]
        }
      }

      yield put(updateUserSettingSuccess(serverAtts))
    } catch (err) {
      yield put(updateUserSettingError(err.message, currentAtts))
    }
  }
}

function* watchFetchUserSetting() {
  yield takeEvery(fetchUserSettingsAction().type, fetchUserSettings)
}

function* watchUpdateUserSetting() {
  yield takeLatest(UPDATE_USER_SETTING, updateUserSetting)
}

export default [watchFetchUserSetting(), watchUpdateUserSetting()]
