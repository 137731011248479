// @flow

import React from 'react'

type Visitor = {
  id: number
}
type Account = {}

type Props = {
  visitor: Visitor,
  account: Account
}

type PendoInstance = {
  isReady?: Function,
  get_visitor_id?: Function,
  initialize?: Function
}

export default class Pendo extends React.Component<Props> {
  componentDidMount() {
    this.initPendo()
  }

  componentDidUpdate() {
    this.initPendo()
  }

  initPendo = () => {
    const { visitor, account } = this.props
    const pendo: PendoInstance = window.pendo

    if (pendo && visitor.id) {
      const { isReady, get_visitor_id: getVisitorId, initialize } = pendo
      if (
        (isReady && !isReady()) ||
        (getVisitorId && getVisitorId() !== visitor.id)
      ) {
        initialize &&
          initialize({
            visitor,
            account
          })
      }
    }
  }

  render() {
    return null
  }
}
