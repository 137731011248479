import styled from '@emotion/styled'

export const MainWrap = styled('div')`
  display: flex;
  -webkit-display: flex;
  width: ${({ vertical }) => !vertical && '100%'};
  height: ${({ vertical }) => (vertical ? '100%' : '3em')};
  align-content: center;
  padding-top: 2em;
`

export const SliderWrap = styled('div')`
  position: relative;
  width: 100%;
  flex: 5;
  -webkit-flex: 5;
  display: flex;
  -webkit-display: flex;
  align-items: center;
`
