// @flow
import React from 'react'
import Button from '@Common/Button/FabButton'
import mutableSet from 'lodash/set'
import _every from 'lodash/every'
import {
  validateEmail,
  validatePhoneNumber,
  validateNumber,
  validateAlpha
} from '../../utils/validationUtils'
import BaseSettingCard from './BaseSettingCard'
import ScheduleEnable from './ScheduleEnable'
import Flex from '@Common/Flex'
import TextField from '../Common/TextField'
import MaskPhoneNumber from '../Common/MaskPhoneNumber'
import { CommonIcon } from '@icons'
import { Email, PropertyName } from './styles'
import { withTheme } from 'emotion-theming'
import type { Theme } from '../../theme'
import Loader from '../Common/Loader'

type Property = {
  name: string,
  address: string,
  phoneNumber: string,
  emergencyPhoneNumber: string,
  zip: number,
  city: string,
  officeAddressLine1: string,
  officeAddressLine2: string,
  officeAddressState: string,
  officeHours: string,
  amenityContact: string
}

type Props = {
  property: Property,
  updateProperty: (property: Property) => {},
  scheduleInfo?: null | { enabled: boolean },
  updateScheduleEnabled: boolean => mixed,
  isFetching: boolean,
  hasData: boolean,
  theme: Theme,
  showSnackbar: string => mixed
}

type State = {
  property: Property,
  valid: {
    phoneNumber: boolean,
    emergencyPhoneNumber: boolean,
    zip: boolean,
    city: boolean,
    officeAddressLine1: boolean,
    officeAddressLine2: boolean,
    officeAddressState: boolean,
    officeHours: boolean,
    amenityContact: boolean
  },
  edit: boolean,
  readOnly: boolean,
  edited: boolean
}

type SwitchScheduleActiveFn = (
  SyntheticInputEvent<HTMLInputElement>,
  boolean
) => mixed

class GeneralInfo extends React.Component<Props, State> {
  state = {
    property: this.props.property,
    valid: {
      phoneNumber: true,
      emergencyPhoneNumber: true,
      zip: true,
      officeAddressState: true,
      city: true,
      officeAddressLine1: true,
      officeAddressLine2: true,
      officeHours: true,
      amenityContact: true
    },
    edit: false,
    readOnly: true,
    edited: false
  }

  handleChange = ({
    currentTarget: { id, value }
  }: SyntheticEvent<HTMLInputElement>) => {
    const { property } = this.state
    this.setState(oldState => {
      const { valid } = oldState

      return {
        ...oldState,
        valid: { ...valid, [id]: this.isValidFieldValue(id, value) },
        property: mutableSet(property, id, value),
        edited: true
      }
    })
  }

  switchScheduleActive: SwitchScheduleActiveFn = (_e, checked) => {
    const { updateScheduleEnabled } = this.props
    updateScheduleEnabled(checked)
  }

  isValidFieldValue = (field: string, value: string) => {
    switch (field) {
      case 'amenityContact':
        return validateEmail(value)
      case 'phoneNumber':
      case 'emergencyPhoneNumber':
        return validatePhoneNumber(value)

      case 'zip':
        return validateNumber(value)

      case 'officeAddressState':
      case 'city':
        return validateAlpha(value)

      case 'officeAddressLine1':
      case 'officeAddressLine2':
      case 'officeHours':
        return true

      default:
        return false
    }
  }

  toggleEdit = () => {
    const { property, valid } = this.state

    if (this.state.edited === true) {
      if(_every(Object.values(valid), Boolean)) {
        const { updateProperty } = this.props
        updateProperty(property)
      } else {
        this.props.showSnackbar('Something went wrong! Please try again!')
      }
    }

    this.setState({
      edit: !this.state.edit,
      readOnly: !this.state.readOnly,
      edited: false
    })
  }

  render() {
    const { property, valid, edit, readOnly } = this.state
    const {
      name,
      phoneNumber,
      emergencyPhoneNumber,
      city,
      officeAddressLine1,
      officeAddressLine2,
      officeAddressState,
      officeHours,
      zip,
      amenityContact
    } = property

    const { scheduleInfo, isFetching, hasData, theme } = this.props
    if (isFetching || !hasData) {
      return <Loader />
    }

    return (
      <BaseSettingCard
        subheading="general info"
        headerRight={
          <div style={{ marginTop: '3em' }}>
            <Button
              onClick={this.toggleEdit}
              background={theme.palette.common.white}
              outlined
              component={() =>
                edit ? (
                  <CommonIcon
                    name="tick"
                    width="1.2em"
                    height="1.2em"
                    fill={'none'}
                    strokeWidth={2}
                    stroke={theme.palette.primary.dark}
                  />
                ) : (
                  <CommonIcon
                    name="edit"
                    width="1.2em"
                    height="1.2em"
                    fill={theme.palette.primary.dark}
                    strokeWidth={2}
                  />
                )
              }
              size="2.7em"
            />
          </div>
        }
        content={
          <Flex
            fullWidth
            direction="column"
            marginTop="40px"
            marginBottom="5px">
            <PropertyName>{name}</PropertyName>
            <Flex justifySpaceBetween>
              <Flex direction="column" flex={1} paddingRight="5em">
                <TextField
                  id="officeAddressLine1"
                  onChange={this.handleChange}
                  value={officeAddressLine1}
                  prefix="Address Line 1:"
                  error={!valid.officeAddressLine1}
                  toolTipText="AddressLine1"
                  showPencil={edit}
                  readOnly={readOnly}
                  label={edit ? 'Address Line 1' : ''}
                  editMode={edit}
                />
                <TextField
                  id="officeAddressLine2"
                  onChange={this.handleChange}
                  value={officeAddressLine2}
                  prefix="Address Line 2:"
                  error={!valid.officeAddressLine2}
                  showPencil={edit}
                  readOnly={readOnly}
                  label={edit ? 'Address Line 2' : ''}
                  editMode={edit}
                />
                <TextField
                  id="city"
                  onChange={this.handleChange}
                  value={city}
                  prefix="City:"
                  error={!valid.city}
                  showPencil={edit}
                  readOnly={readOnly}
                  label={edit ? 'City' : ''}
                  editMode={edit}
                />
                <Flex justifySpaceBetween wrap>
                  <TextField
                    id="officeAddressState"
                    onChange={this.handleChange}
                    value={officeAddressState}
                    prefix="State:"
                    error={!valid.officeAddressState}
                    showPencil={edit}
                    readOnly={readOnly}
                    label={edit ? 'State' : ''}
                    editMode={edit}
                  />
                  <TextField
                    id="zip"
                    onChange={this.handleChange}
                    value={zip}
                    prefix="Zip:"
                    error={!valid.zip}
                    showPencil={edit}
                    readOnly={readOnly}
                    label={edit ? 'Zip' : ''}
                    editMode={edit}
                  />
                </Flex>
              </Flex>
              <Flex flex={1} direction="column">
                <TextField
                  id="phoneNumber"
                  onChange={this.handleChange}
                  value={phoneNumber}
                  prefix="Office:"
                  error={!valid.phoneNumber}
                  textMask={MaskPhoneNumber}
                  toolTipText="OfficePhone"
                  showPencil={edit}
                  readOnly={readOnly}
                  label={edit ? 'Office' : ''}
                  editMode={edit}
                />
                <TextField
                  id="emergencyPhoneNumber"
                  onChange={this.handleChange}
                  value={emergencyPhoneNumber}
                  prefix="Emergency Phone Number:"
                  error={!valid.emergencyPhoneNumber}
                  textMask={MaskPhoneNumber}
                  toolTipText="EmergencyPhoneNumber"
                  showPencil={edit}
                  readOnly={readOnly}
                  label={edit ? 'Emergency Phone Number' : ''}
                  editMode={edit}
                />
                <TextField
                  id="amenityContact"
                  onChange={this.handleChange}
                  value={amenityContact}
                  prefix="Amenity Contact:"
                  error={!valid.amenityContact}
                  showPencil={edit}
                  readOnly={readOnly}
                  label={edit ? 'Amenity Contact' : ''}
                  editMode={edit}
                />
              </Flex>
            </Flex>
            <Email>
              <TextField
                id="officeHours"
                onChange={this.handleChange}
                fullWidth
                value={officeHours}
                prefix="Office Hours:"
                toolTipText="OfficeHours"
                error={!valid.officeHours}
                showPencil={edit}
                readOnly={readOnly}
                label={edit ? 'Office Hours' : ''}
                editMode={edit}
              />
            </Email>
            {scheduleInfo && (
              <ScheduleEnable
                enabled={scheduleInfo.enabled}
                switchScheduleActive={this.switchScheduleActive}
              />
            )}
          </Flex>
        }
      />
    )
  }
}

export default withTheme(GeneralInfo)
