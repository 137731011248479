// @flow
import React from 'react'
import { generate as shortid } from 'shortid'
import filesize from 'filesize'
import { withTheme } from 'emotion-theming'
import InputAdornment from '@material-ui/core/InputAdornment'
import MergeOptions from './MergeOptions'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import { CommonIcon } from '@icons'
import { MessageEditorContainer, FootContainer, MessageTypeText, AttachFile } from './styles'
import { MessageEditorProps } from './types'
import Tooltip from '../../../Common/Tooltip'
import { WYSIWYGEditor } from '../../../Common/WYSIWYG'
import Button from '@Common/Button'
import {
  CustomTextField,
  Attachment,
  TextCount,
  ToolBar,
  TooltipWrapper,
  StepDescription,
  TitleTextField,
  TextFieldContainer
} from '../CreateGroupMessage/styles'
import { Line } from '../SingleGroupMessage/styles'

class MessageEditor extends React.Component<MessageEditorProps, {}> {
  emailTextArea = React.createRef()
  textTextArea = React.createRef()

  handleMergeSelect = (mergeValue: string, name: string) => {
    const currentTextArea =
      name === 'textMessage'
        ? this.textTextArea.current
        : this.emailTextArea.current

    if (currentTextArea != null) {
      const { selectionStart, selectionEnd } = currentTextArea
      const { message, handleMergeFieldChange } = this.props
      const currentMessage = message[name]
      const firstPart = currentMessage.substring(0, selectionStart)
      const secondPart = currentMessage.substring(selectionEnd)
      handleMergeFieldChange(name, `${firstPart}${mergeValue}${secondPart}`)
    }
  }

  shouldShowEmail = () => {
    const { selectedDeliveryMethods } = this.props
    // return true is delivery methods aren't passed
    return selectedDeliveryMethods
      ? selectedDeliveryMethods.some(
          method => method === 'preferred' || method === 'email'
        )
      : true
  }

  shouldShowText = () => {
    const { selectedDeliveryMethods } = this.props
    // return true is delivery methods aren't passed
    return selectedDeliveryMethods
      ? selectedDeliveryMethods.some(
          method =>
            ['preferred', 'text', 'in-app', 'chat'].includes(method)
        )
      : true
  }

  handleFileChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { files } = event.target || {}
    if (files && files.length) {
      this.props.addAttachment(files[0])
    }
  }

  render() {
    const {
      message,
      handleChange,
      theme,
      removeAttachment,
      removeFile,
      replacementOptions,
      attachments,
      files,
      className,
      disableAttachments,
      templateDisabled,
      subjectLineText,
      handleEmailMessageChange,
      showChatOption,
      isDefault,
      footNote
    } = this.props

    const attachmentsCount =
      Object.keys(attachments).length + (files || []).length

    return (
      <MessageEditorContainer className={className}>
        {this.shouldShowEmail() && (
          <React.Fragment>
            <MessageTypeText>Email Message</MessageTypeText>
            <StepDescription style={{ paddingBottom: '2em' }}>
              *Email messages do not have a character limit, but texts{showChatOption ? ', chat messages' : ''} and in
              app notifications have a limit of 160 characters. For this reason
              we recommend that you please write a version of your message for
              email and a version of your message for text{showChatOption ? ', chat' : ''} and in app
              notification. This way no matter what the delivery method is, your
              message will always be received in its best possible format.
            </StepDescription>
            <TextFieldContainer>
              <TitleTextField
                value={subjectLineText}
                label="subject line"
                name="title"
                onChange={handleChange}
                InputProps={{
                  disableUnderline: false,
                  endAdornment: (
                    <InputAdornment position="end">
                      <CommonIcon name="edit" height="14px" width="12px" />
                    </InputAdornment>
                  )
                }}
              />
            </TextFieldContainer>
            <WYSIWYGEditor
              message={message.message}
              onBlur={handleEmailMessageChange}
            />
          </React.Fragment>
        )}
        {this.shouldShowEmail() && this.shouldShowText() && <Line />}
        {this.shouldShowText() && (
          <React.Fragment>
            <MessageTypeText>Text{showChatOption ? '/Chat' : ''} Message</MessageTypeText>
            <StepDescription style={{ paddingBottom: '2em' }}>
              *Email messages do not have a character limit, but texts{showChatOption ? ', chat' : ''} messages and in
              app notifications have a limit of 160 characters. For this reason
              we recommend that you please write a version of your message for
              text{showChatOption ? ', chat' : ''} and in app notification. This way no matter what the delivery
              method is, your message will always be received in its best
              possible format.
            </StepDescription>
            <CustomTextField
              inputRef={this.textTextArea}
              value={message.textMessage}
              label="message"
              name="textMessage"
              multiline
              disabled={templateDisabled}
              rows={10}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: (
                  <FlexDirection direction="column">
                    <FlexItem flex={0.5} displayFlex alignItemsRight>
                      <ToolBar>
                        <div>
                          <MergeOptions
                            name="textMessage"
                            handleSelect={this.handleMergeSelect}
                            mergeOptions={replacementOptions}
                          />
                        </div>
                        <FlexDirection>
                          <TextCount>
                            {message.textMessage
                              ? message.textMessage.length
                              : 0}
                          </TextCount>
                          <TooltipWrapper>
                            <Tooltip type="TextEditorTextCount" />
                          </TooltipWrapper>
                        </FlexDirection>
                      </ToolBar>
                    </FlexItem>
                    <FlexItem flex={0.5} displayFlex alignItemsRight>
                      <InputAdornment position="end">
                        <CommonIcon name="edit" height="14px" width="12px" />
                      </InputAdornment>
                    </FlexItem>
                  </FlexDirection>
                )
              }}
            />
          </React.Fragment>
        )}
        {isDefault && (
          <React.Fragment>
            <StepDescription style={{ paddingBottom: '2em' }}>
              <span>*At the end of each message, the following link will be appended to capture residents interest:</span>
            </StepDescription>
            <FootContainer>
              <u dangerouslySetInnerHTML={{ __html: footNote }}></u>
            </FootContainer>
          </React.Fragment>
        )}
        {!disableAttachments && (
          <AttachFile>
            <p>
              Attach a file or image to your message. This file will be attached
              to all message types
            </p>
            <Button secondary>
              attach a file
              <input id="fileInput" type="file" onChange={this.handleFileChange} />
            </Button>
          </AttachFile>
        )}
        {disableAttachments ? null : (
          <Attachment>
            <span>{attachmentsCount} attachment</span>
            {attachments &&
              Object.keys(attachments).map(attachment => (
                <span key={shortid()}>
                  {attachment}
                  <CommonIcon
                    name="close"
                    width="0.5em"
                    height="0.5em"
                    fill={theme.palette.primary.green.mint}
                    strokeWidth={8}
                    stroke={theme.palette.primary.green.mint}
                    onClick={() => {
                      removeAttachment(attachment)
                    }}
                  />
                </span>
              ))}
            {files &&
              files.map(file => (
                <span key={shortid()}>
                  {file.name} {filesize(file.size)} {'  '}
                  <CommonIcon
                    name="close"
                    width="0.5em"
                    height="0.5em"
                    fill={theme.palette.primary.green.mint}
                    strokeWidth={8}
                    stroke={theme.palette.primary.green.mint}
                    onClick={() => {
                      removeFile(file)
                    }}
                  />
                </span>
              ))}
          </Attachment>
        )}
      </MessageEditorContainer>
    )
  }
}

export default withTheme(MessageEditor)
