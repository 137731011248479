import { GROUPEVENTS } from './groupEventsActions'
import withFetch from '../../helpers/reducers/withFetch'

const groups = (state = null, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withFetch(GROUPEVENTS)(groups)
