// @flow

import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import TooltipButton from '@Common/Tooltip'
import Loader from '@Common/Loader'
import ScrollDownButton from '@Common/ScrollDownButton'
import Header from '@Common/Header'
import AlertModal from '@Common/AlertModal/AlertModal'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Card from './Card'
import {
  BackButton,
  PageContainer,
  PageHeader,
  PageContent,
  Deals,
  ShowMoreBtn,
  StyledLink
} from './styles'

import type { Match } from 'react-router-dom'
import type { Theme } from '../../theme'
import { BrowserHistory } from 'history'

type Props = {
  theme: Theme,
  history: BrowserHistory,
  match: Match,
  deals: Object,
  isFetching: Boolean,
  propertyId: number,
  fetchNextMarketplaceDeals: Function,
  deleteMarketplaceDeal: Function,
  reorderMarketplaceDeal: Function
}

const Marketplace = (props: Props) => {
  const { theme, isFetching, match: { url } } = props

  const [showDialog, setShowDialog] = useState(false)
  const [deleteDealId, setDeleteDealId] = useState(null)
  const [deals, setDeals] = useState(props.deals)

  useEffect(() => {
    setDeals(props.deals)
  }, [props])

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    const { property: list } = deals
    
    const [dragCard] = list.splice(dragIndex, 1)
    list.splice(hoverIndex, 0, dragCard)
    
    setDeals({ ...deals, property: list })
  }, [deals])

  const goBack = () => {
    const { history } = props
    history.goBack()
  }

  const goToDeal = id => {
    const { history, propertyId } = props
    history.push(`/properties/${propertyId}/marketplace/${id}`)
  }

  const handlePageChange = page => {
    const { fetchNextMarketplaceDeals } = props
    fetchNextMarketplaceDeals({ page })
  }

  const handleReorder = (id, index) => {
    const { reorderMarketplaceDeal } = props
    reorderMarketplaceDeal(id, index)
  }

  const handleDelete = () => {
    const { deleteMarketplaceDeal } = props

    deleteMarketplaceDeal(deleteDealId)
    closeDeleteModal()
  }

  const showDeleteModal = id => {
    setShowDialog(true)
    setDeleteDealId(id)
  }

  const closeDeleteModal = () => {
    setShowDialog(false)
  }

  if (isFetching) {
    return <Loader />
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <PageContainer columnStart={1} columnSpan={12} rowStart={1} rowSpan={12}>
        {showDialog &&
          <AlertModal
            id='DeleteConfirm'
            title=''
            message='Are you sure you want to delete this deal for your residents?'
            buttons={[
              {
                onClick: handleDelete,
                text: 'YES'
              },
              {
                onClick: closeDeleteModal,
                color: theme.palette.primary.navy.spaceBlue,
                text: 'NO'
              }
            ]}
            show={showDialog}
            onClose={closeDeleteModal}
          />
        }
        <PageHeader>
          <BackButton
            id="backButton"
            onClick={goBack}
            background={theme.palette.secondary.yellow.solarFlare}
            component={() => (
              <KeyboardBackspace height="0.5em" width="0.5em" />
            )}
          />
          <Header alternateFontSize>Marketplace</Header>
          <div>Create “deals” for your residents that display exclusive discounts they receive at area businesses just for being a resident at your property.</div>
        </PageHeader>
        <PageContent>
          <h1>Services</h1>
          <h2 className={deals.global.length === 0 && "globalDealsHeader"}>
            Global deals
            <TooltipButton type="GlobalDeals" />
          </h2>
          <StyledLink to={`${url.split('/marketplace')[0]}/marketplace/new`}> Add new discount </StyledLink>
          {deals.global.length !== 0 && (
            <Deals className="global">
              {deals.global.map(deal => <Card deal={deal} key={deal.id} />)}
            </Deals>
          )}
          <Deals>
            {deals.property.map((deal, index) =>
              <Card
                deal={deal}
                key={deal.id}
                onClick={goToDeal}
                onDelete={showDeleteModal}
                moveCard={moveCard}
                commitMove={handleReorder}
                index={index}
              />)}
          </Deals>
          {deals.nextPage <= deals.totalPages && (
            <ShowMoreBtn
              id="showMoreBtn"
              onClick={() => handlePageChange(deals.nextPage)}>
              Show More <ScrollDownButton />
            </ShowMoreBtn>
          )}
        </PageContent>
      </PageContainer>
    </DndProvider>
  )
}

export default withRouter(withTheme(Marketplace))
