import { connect } from 'react-redux'
import { deauth } from 'zego-shared/store/authorization/actions'
import { getPms } from 'zego-shared/store/select/selectors'
import MoreMenu from './MoreMenu'
import { getSupportTicketEnabled } from 'zego-shared/store/settings/propertySettings/selectors'

const pLSupportLink = window?._env_?.REACT_APP_PAYLEASE_SUPPORT_LINK
const nonPLSupportLink = window?._env_?.REACT_APP_NON_PAYLEASE_SUPPORT_LINK

const mapStateToProps = state => ({
  supportUrl: getPms(state) === 'paylease'
      ? pLSupportLink
      : nonPLSupportLink,
      supportTicketEnabled: getSupportTicketEnabled(state)
})

const mapDispatchToProps = {
  logout: deauth
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoreMenu)
