import React from 'react'
import PrivateRoute from '../../PrivateRoute'
import {
  CommunityFeedContainer,
  CreateEditPostEventContainer,
  ViewPostEventContainer
} from '../../../views/Communication/CommunityFeed'

const getCommunityFeedRoutes = () => [
  <PrivateRoute
    exact
    key="chatChannels"
    path="/properties/:propertyId/communication/community-feed/:type(posts|events)?/"
    component={CommunityFeedContainer}
  />,
  <PrivateRoute
    exact
    key="createGroupMessage"
    path="/properties/:propertyId/communication/community-feed/:type(posts|events)/:threadId/:action(edit)/"
    component={CreateEditPostEventContainer}
  />,
  <PrivateRoute
    exact
    key="chatChannels"
    path="/properties/:propertyId/communication/community-feed/:type(posts|events)/:action(create)/"
    component={CreateEditPostEventContainer}
  />,
  <PrivateRoute
    exact
    key="createGroupMessage"
    path="/properties/:propertyId/communication/community-feed/:type(posts|events)/:threadId/"
    component={ViewPostEventContainer}
  />
]

export default getCommunityFeedRoutes
