// @flow
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { search } from 'zego-shared/store/search/actions'
import { getResidentSearchResults, hasSearchData } from 'zego-shared/store/search/selectors'
import { getPropertyResidents } from 'zego-shared/store/users/selectors'

import ResidentSearch from './ResidentSearch'

const mapStateToProps = (state, _props) => {
  const fetchingSelector = createIsFetchingSelector(['search'])
  const residents = getPropertyResidents(state, 'active')

  return {
    isFetching: fetchingSelector(state),
    results: getResidentSearchResults(state, residents || []),
    hasResults: hasSearchData(state),
  }
}

const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  dispatchSearch: name => dispatch(search(name, params.propertyId)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResidentSearch))
