import { call, select, put, takeLatest} from 'redux-saga/effects'
import api from '../../../utils/api'
import { getToken } from '../../authorization/selectors'
import log from '../../../utils/logger'
import {
  UPLOAD_VISITOR_IMAGE,
  uploadImageError,
  uploadImage as uploadImageAction,
  uploadImageSuccess,
  fetchVisitorStart,
  fetchVisitorAction,
  fetchVisitorError,
  fetchVisitorSuccess,
  updateVisitorAction,
  updateVisitorSuccess,
  updateVisitorError,
  fetchVisitorsAction,
  fetchVisitorsStart,
  fetchVisitorsError,
  fetchVisitorsSuccess
} from './actions'

export function* updateVisitor({ id, data }) {
  try {
    const authToken = yield select(getToken)
    const visitor = yield call(api.updateVisitor, authToken, data, id)
    yield put(updateVisitorSuccess(visitor))
  } catch (error) {
    yield put(updateVisitorError(error))
    log(`Failed to update visitor. Error: ${error}`)
  }
}

export function* uploadImage({ id, data }) {
  try {
    const authToken = yield select(getToken)
    const image = yield call(api.uploadVisitorImage, authToken, id, data)
    yield put(uploadImageSuccess())
  } catch (error) {
    yield put(uploadImageError(error))
    log(`Failed to upload visitor image. Error: ${error}`)
  }
}

export function* fetchVisitor({ id }) {
  try {
    yield put(fetchVisitorStart())
    const authToken = yield select(getToken)
    const visitor = yield call(api.getVisitor, authToken, id)
    yield put(fetchVisitorSuccess(visitor))
  } catch (error) {
    yield put(fetchVisitorError(error))
    log(`Failed to fetch visitor. Error: ${error}`)
  }
}

export function* fetchVisitors({
  propertyId,
  page = 1,
  searchTerm,
  queryParams
}) {
  try {
    yield put(fetchVisitorsStart())
    const authToken = yield select(getToken)
    let visitors = {}
    if (searchTerm) {
      visitors = yield call(
        api.searchVisitorsOnProperty,
        authToken,
        propertyId,
        searchTerm,
        { page, page_size: 8 }
      )
    } else {
      visitors = yield call(
        api.getVisitorsForProperty,
        authToken,
        propertyId,
        page,
        queryParams
      )
    }

    yield put(fetchVisitorsSuccess(visitors))
  } catch (error) {
    yield put(fetchVisitorsError())
    log(`Failed to fetch visitors. Error: ${error}`)
  }
}

function* watchUpdateVisitor() {
  yield takeLatest(updateVisitorAction().type, updateVisitor)
}

function* watchFetchVisitor() {
  yield takeLatest(fetchVisitorAction().type, fetchVisitor)
}

function* watchFetchVisitors() {
  yield takeLatest(fetchVisitorsAction().type, fetchVisitors)
}

function* watchUploadVisitorImage() {
  yield takeLatest(UPLOAD_VISITOR_IMAGE, uploadImage)
}

export default [
  watchUploadVisitorImage(),
  watchFetchVisitor(),
  watchUpdateVisitor(),
  watchFetchVisitors()
]
