import has from 'lodash/has'
import get from 'lodash/get'

export const getDeviceChannel = identifier => `device:${identifier}`

export const getHubEventChannel = identifier =>
  `event:device:gateway:${identifier}`

export const isAnEventUpdate = payload => {
  return has(payload, 'event_code')
  || has(payload, ['response', 'tag'])
  || get(payload, ['response', 'original_command', 'name']) === 'lock_code_status'
}

export * from './lockCodes'
