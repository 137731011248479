import {
  fetchMimoResetCheckListStart,
  fetchMimoResetCheckListSuccess,
  fetchMimoResetCheckListError
} from '../actions'

const initialState = {
  resetCheckList: []
}

const submitMimoResetCheckList = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case fetchMimoResetCheckListStart().type:
      return {
        ...state,
        resetCheckList: [],
      }
    case fetchMimoResetCheckListSuccess().type: {
      return {
        ...state,
        resetCheckList: payload
      }
    }
    case fetchMimoResetCheckListError().type:
      return {
        ...state,
        resetCheckList: [],
      }
    default:
      return state
  }
}
export default submitMimoResetCheckList
