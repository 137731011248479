import { connect } from 'react-redux'
import MyAccount from './MyAccount'
import { fetchUserSettings, updateUserSetting } from 'zego-shared/store/settings/actions'
import { getUserSettings } from 'zego-shared/store/settings/selectors'
import { updateUser } from 'zego-shared/store/authorization/actions'
import withFetchData from 'zego-shared/views/withFetchData'
import {
  fetchUserProfile,
  uploadProfileImage,
  generateLockCode,
  deleteLockCode
} from 'zego-shared/store/users/profile/actions'
import {
  getUserId,
  getUserRole,
  getUserEmail,
  getUserPhoneNumber
} from 'zego-shared/store/authorization/selectors'
import {
  getUserlockCode,
  getUserProfilePicture,
  hasProfileData,
  getUserName,
  getUserFirstName,
  getUserLastName,
  getUserBio,
  getUserTitle,
  userRoles,
} from 'zego-shared/store/users/selectors'
import { isSmartEnabled } from 'zego-shared/store/settings/propertySettings/selectors'

const MyAccountWithFetchData = withFetchData(MyAccount)

const mapStateToProps = state => ({
  hasData: hasProfileData(state),
  user: {
    userId: getUserId(state),
    name: getUserName(state),
    firstName: getUserFirstName(state),
    lastName: getUserLastName(state),
    role: userRoles[getUserRole(state)],
    email: getUserEmail(state),
    phoneNumber: getUserPhoneNumber(state),
    picture: getUserProfilePicture(state),
    lockCode: getUserlockCode(state),
    bio: getUserBio(state),
    title: getUserTitle(state)
  },
  showStaffProfile: getUserSettings(state).showStaffProfile,
  isSmartEnabled: isSmartEnabled(state)
})

const mapDispatchToProps = dispatch => {
  return {
    fetchData: () => {
      dispatch(fetchUserProfile())
      dispatch(fetchUserSettings())
    },
    updateUser: attributes => {
      const {
        bio,
        userId,
        phoneNumber,
        firstName,
        lastName,
        title,
        lockCode,
        picture,
        role,
        ...rest
      } = attributes
      dispatch(
        updateUser({
          ...rest,
          phone_number: phoneNumber,
          first_name: firstName,
          last_name: lastName,
          user_metadata: { bio: bio, title: title }
        })
      )
    },
    uploadProfileImage: (userId, file) => {
      dispatch(uploadProfileImage(userId, file))
    },
    generateLockCode: userId => {
      dispatch(generateLockCode(userId))
    },
    deleteLockCode: userId => {
      dispatch(deleteLockCode(userId))
    },
    toggleStaffProfile: showStaffProfile => {
      dispatch(updateUserSetting({ showStaffProfile }))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccountWithFetchData)
