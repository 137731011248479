import { setChatNotificationData } from './actions'

const initialState = {}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setChatNotificationData().type:
      return action.data
    default:
      return state
  }
}

export default reducer
