// @flow

export type Colors = {|
  common: {|
    black: string,
    white: string,
    main: string
  |},

  primary: {|
    navy: {| midnight: string, spaceBlue: string, twilight: string |},
    green: {|
      seeFoam: string,
      mint: string,
      hintMint: string,
      iceberg: string
    |},
    dark: string,
    main: string,
    light1: string
  |},
  neutrals: {
    deepGray: string,
    darkGray: string,
    mediumGray: string,
    warmGray: string,
    coolGray: string,
    lightGray: string,
    underlineGray: string
  },
  secondary: {
    yellow: {
      golden: string,
      solarFlare: string
    },
    pink: { deepRed: string, coral: string },
    main1: string,
    main3: string,
    main4: string
  },

  blue: {
    main: string
  },

  alternates: {
    brightBlue: string,
    brightPurple: string
  },

  grey: {
    main: string,
    light7: string
  },

  text: {
    black: string,
    grey: string,
    white: string,
    black1: string,
    light: string
  },

  alerts: {
    success: string,
    warning: string,
    error: string,
    errorDark: string
  }
|}

const colors: Colors = {
  common: {
    black: '#000',
    white: '#FFFFFF',
    main: '#0E1427'
  },
  primary: {
    navy: { midnight: '#0E1427', spaceBlue: '#0C173C', twilight: '#213061' },
    green: {
      seeFoam: '#06846E',
      mint: '#59C09B',
      hintMint: '#ACE0CD',
      iceberg: '#E6F6F0'
    },
    dark: '#0B173C',
    main: '#223460',
    light1: '#394971'
  },
  secondary: {
    yellow: { golden: '#FF9F00', solarFlare: '#FDB627' },
    pink: {
      deepRed: '#C51F47',
      coral: '#EE305A'
    },
    main1: '#4dcea3',
    main3: '#2CAC82',
    main4: '#079466'
  },
  blue: {
    main: '#0B183D'
  },
  neutrals: {
    deepGray: '#5C6274',
    darkGray: '#8A8D9E',
    mediumGray: '#BABABA',
    warmGray: '#D4D4D4',
    coolGray: '#E7E7E7',
    lightGray: '#EDEDED',
    underlineGray: '#9B9B9B'
  },
  grey: {
    main: '#0E1427',
    light7: '#E3E5E7'
  },
  text: {
    black: '#0E1427',
    black1: '#040E26',
    grey: '#5C6274',
    white: '#FFFFFF',
    light: '#707070'
  },
  alerts: {
    success: '#06846E',
    warning: '#FDB627',
    error: '#EE305A',
    errorDark: '#C51F47'
  },
  alternates: {
    brightBlue: '#029EC6',
    brightPurple: '#3B3E98'
  }
}

export default colors
