import { call, all, select, takeLatest, put } from 'redux-saga/effects'
import { getToken, getPropertyId } from '../authorization/selectors'
import api from '../../utils/api'
import log from '../../utils/logger'
import {
  fetchEventsInitialAction,
  fetchEventsInitialStart,
  fetchEventsInitialSuccess,
  fetchUpcomingEventsSuccess,
  fetchEventsSuccess,
  updateEventTimestamp,
  fetchMonthEventsStart,
  fetchMonthEventsSuccess,
  fetchMonthEventsAction,
  fetchNextMonthEventsStart,
  fetchNextMonthEventsSuccess,
  fetchNextMonthEventsAction,
  fetchNewEventsSuccess,
  updateEventRSVPWithUser,
  UPDATE_EVENT_RSVP,
  fetchUpcomingEventsStart,
  fetchUpcomingEventsAction,
  fetchFilteredEventsSuccess,
  FILTER_EVENTS
} from './eventsActions'
import { getPage, getRSVPUser } from './eventsSelectors'

export function* fetchEventsInitial({ propertyId, start, end }) {
  try {
    yield put(fetchEventsInitialStart())
    const authToken = yield select(getToken)

    const [upcomingEvents, newEvents, events] = yield all([
      call(api.getUpcomingEvents, authToken, propertyId),
      call(api.getNewEvents, authToken, propertyId),
      call(api.getEvents, authToken, propertyId, start, end)
    ])

    // need this to filter the pagination in the reducer
    events.start = start

    yield put(updateEventTimestamp(start))
    yield put(fetchUpcomingEventsSuccess(upcomingEvents))
    yield put(fetchNewEventsSuccess(newEvents))
    yield put(fetchEventsSuccess(events))
    yield put(fetchEventsInitialSuccess())
  } catch (error) {
    log(error)
  }
}

export function* fetchUpcomingEvents() {
  try {
    yield put(fetchUpcomingEventsStart())
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)
    const response = yield call(api.getUpcomingEvents, authToken, propertyId)
    yield put(fetchUpcomingEventsSuccess(response))
  } catch (error) {
    log(error)
  }
}

export function* fetchMonthEvents({ propertyId, start, end }) {
  try {
    yield put(fetchMonthEventsStart())
    const authToken = yield select(getToken)

    const response = yield call(
      api.getEvents,
      authToken,
      propertyId,
      start,
      end
    )

    // need this to filter the pagination in the reducer
    response.start = start

    yield put(updateEventTimestamp(start))
    yield put(fetchMonthEventsSuccess(response))
  } catch (error) {
    log(error)
  }
}

export function* fetchNextMonthEvents({ propertyId, start, end }) {
  try {
    yield put(fetchNextMonthEventsStart())
    const authToken = yield select(getToken)
    const page = yield select(getPage, start)

    const response = yield call(
      api.getEvents,
      authToken,
      propertyId,
      start,
      end,
      page
    )

    // need this to filter the pagination in the reducer
    response.start = start

    yield put(updateEventTimestamp(start))
    yield put(fetchNextMonthEventsSuccess(response))
  } catch (error) {
    log(error)
  }
}

export function* updateEventRSVP({ id }) {
  try {
    const rsvpUser = yield select(getRSVPUser)

    yield put(updateEventRSVPWithUser(id, rsvpUser))
  } catch (error) {
    log(error)
  }
}

export function* getFilteredEvents({ date, userId, threadType, sortBy, propertyId }) {
  try {
    const authToken = yield select(getToken)

    const events = yield call(
      api.getFilteredThread,
      authToken,
      propertyId,
      date,
      userId,
      threadType,
      sortBy
    )

    yield put(fetchFilteredEventsSuccess(events))
  } catch (error) {
    log(error)
  }
}

function* watchGetFilteredEvents() {
  yield takeLatest(FILTER_EVENTS, getFilteredEvents)
}

function* watchFetchEventsInitial() {
  yield takeLatest(fetchEventsInitialAction().type, fetchEventsInitial)
}

function* watchFetchMonthEventsInitial() {
  yield takeLatest(fetchMonthEventsAction().type, fetchMonthEvents)
}

function* watchFetchNextMonthEventsInitial() {
  yield takeLatest(fetchNextMonthEventsAction().type, fetchNextMonthEvents)
}

function* watchUpdateEventRSVP() {
  yield takeLatest(UPDATE_EVENT_RSVP, updateEventRSVP)
}

function* watchFetchUpcomingEvents() {
  yield takeLatest(fetchUpcomingEventsAction().type, fetchUpcomingEvents)
}

export default [
  watchFetchEventsInitial(),
  watchFetchMonthEventsInitial(),
  watchFetchNextMonthEventsInitial(),
  watchUpdateEventRSVP(),
  watchFetchUpcomingEvents(),
  watchGetFilteredEvents()
]
