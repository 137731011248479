// @flow

import { connect } from 'react-redux'
import queryString from 'query-string'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { fetchGroupEvents } from 'zego-shared/store/groupMessaging/groupEvents'
import withFetchData from 'zego-shared/views/withFetchData'
import { fetchTemplates } from 'zego-shared/store/groupMessaging/templates'
import SingleGroupMessage from './SingleGroupMessage'
import api from 'zego-shared/utils/api'
import { getToken } from 'zego-shared/store/authorization/selectors'
import { fetchReplacementOptions } from 'zego-shared/store/groupMessaging/replacementOptions'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import { fetchTemplatePermission } from 'zego-shared/store/groupMessaging/templatePermission'
import { hasTemplatePermission } from 'zego-shared/store/groupMessaging/templatePermission/selectors'
import { getFeatureFlag } from '../../../../integrations/LaunchDarkly/selectors'
import { ldInitRequest } from '../../../../integrations/LaunchDarkly/actions'
import {
  fetchSingleMessage,
  fetchMessageUsersSummary,
  searchMessageUsersSummary,
  deleteSingleMessageAction
} from 'zego-shared/store/groupMessaging/message'
import {
  getMessage,
  hasMessage,
  isMessageOfEventType
} from 'zego-shared/store/groupMessaging/message/selectors'
import {
  hasTemplates,
  getTemplatesDropdownData
} from 'zego-shared/store/groupMessaging/templates/selectors'
import {
  hasReplacementOptions,
  getReplacementOptions
} from 'zego-shared/store/groupMessaging/replacementOptions/selectors'
import {
  hasGroupEvents,
  getGroupEvents,
  getGroupEventsDropDownData
} from 'zego-shared/store/groupMessaging/groupEvents'
import { getPropertyTimezone } from 'zego-shared/store/properties/actions'
import { getTimezone } from 'zego-shared/store/summary/selectors'

const fetchingSelector = createIsFetchingSelector([
  'groupmessage',
  'groupevents',
  'replacement',
  'template',
  'templates',
  'group'
])

const searchGroups = async (authToken, propertyId, term) => {
  const groups = await api.searchGroupMessagingGroups(
    authToken,
    propertyId,
    term
  )
  return groups.data.map(group => ({
    value: group.id,
    label: `@${group.name}`
  }))
}

const isEdit = true

const mapStateToProps = (
  state,
  { match: { params }, location: { search } }
) => {
  const queryParams = queryString.parse(search)
  return {
    isFetching: fetchingSelector(state),
    hasData:
      hasMessage(state) &&
      hasTemplates(state) &&
      hasReplacementOptions(state) &&
      hasGroupEvents(state) &&
      hasTemplatePermission(state),
    isFuture: params.status === 'future',
    isEventView: params.status === 'event',
    propertyId: params.propertyId,
    groupEventsDropDownOptions: getGroupEventsDropDownData(state),
    authToken: getToken(state),
    templates: getTemplatesDropdownData(state, isEdit),
    replacementOptions: getReplacementOptions(state),
    groupEvents: getGroupEvents(state), 
    showTextMessage: queryParams.type === 'text',
    featureFlag: getFeatureFlag(state),
    searchGroups,
    ...getMessage(state, params.status, queryParams.time),
    timezone: getTimezone(state),    
    isEvent: isMessageOfEventType(state)
  }
}
const mapDispatchToProps = (
  dispatch,
  { match: { params }, location: { search }, history }
) => {
  const time = queryString.parse(search).time
  const isFuture = params.status === 'future'

  return {
    fetchData: () => {
      dispatch(fetchSingleMessage(params.id, params.status, time))
      dispatch(fetchMessageUsersSummary(params.id, isFuture, time))
      dispatch(fetchTemplates(params.propertyId))
      dispatch(fetchReplacementOptions(params.propertyId))
      dispatch(fetchGroupEvents(params.propertyId))
      dispatch(fetchTemplatePermission()) 
      dispatch(getPropertyTimezone())
    },
    searchRecipient: searchTerm => {
      dispatch(searchMessageUsersSummary(params.id, searchTerm))
    },
    deleteGroupMessage: () => {
      dispatch(deleteSingleMessageAction(params.id, history))
    },
    fetchPage: page =>
      dispatch(fetchMessageUsersSummary(params.id, isFuture, time, page)),
    showSnackbar: message => dispatch(showSnackbar(message, 'error')),
    ldInitRequest
  }
}

const SingleGroupMessageWithFetchData = withFetchData(SingleGroupMessage)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleGroupMessageWithFetchData)
