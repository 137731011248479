// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import SummaryCard from '@Common/Cards/SummaryCard'
import SummaryBG from '../../../images/summary_bg.png'
import CarouselUnit from './CarouselUnit'
import { Props } from './types'
import Loader from '../../Common/Loader'
import ReSyncPmsButton from '../ReSyncPmsButton'

export class PropertyUsers extends React.Component<Props, {}> {
  getFilters = () => {
    return [
      { name: 'Company Admin', query: { role: 'companyAdmin' } },
      { name: 'Property Admin', query: { role: 'propertyAdmin' } },
      { name: 'Property Staff', query: { role: 'propertyStaff' } },
      { name: 'Setup', query: { role: 'setup' } }
    ]
  }

  getCarouselRows = () => {
    const data = this.props.propertyUsers

    return data.map((datum, index) => (
      <CarouselUnit
        key={index}
        lastUnit={index === data.length - 1}
        {...datum}
      />
    ))
  }

  render() {
    const { propertyUsersCount, isFetching, countText, hasData } = this.props

    if (isFetching) return <Loader />
    if (!hasData) return null

    return (
      <React.Fragment>
        <SummaryCard
          unitCount={propertyUsersCount}
          countText={countText}
          imageSrc={SummaryBG}
          overflow="visible"
          captionHeading={'USER MANAGEMENT'}
          mainHeading="Property Users"
          filterNames={this.getFilters()}
          items={this.getCarouselRows()}
          SummaryHeaderRightPanelChildren={<ReSyncPmsButton />}
        />
      </React.Fragment>
    )
  }
}

export default withTheme(PropertyUsers)
