// @flow

import React from 'react'
import { generate as shortid } from 'shortid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { MergeOptionsProps, MergeOptionsState } from './types'

export default class MergeOptions extends React.Component<
  MergeOptionsProps,
  MergeOptionsState
> {
  state = {
    anchorEl: null
  }

  handleClick = ({ currentTarget }: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ anchorEl: currentTarget })
  }

  handleClose = (replaceMatch: string) => {
    const { handleSelect, name } = this.props
    if (typeof replaceMatch === 'string') {
      handleSelect(replaceMatch, name)
    }
    this.setState({ anchorEl: null })
  }

  render() {
    const { mergeOptions } = this.props
    const { anchorEl } = this.state

    return (
      <div>
        <span
          onClick={this.handleClick}
          aria-owns={anchorEl && 'merge-options-menu'}
          aria-haspopup="true">
          [...]
        </span>
        <Menu
          id="merge-options-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}>
          {mergeOptions.map(({ name, replaceMatch }) => (
            <MenuItem
              key={shortid()}
              onClick={() => this.handleClose(replaceMatch)}>
              {name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  }
}
