import { createFetchActions } from '../../helpers/actions/creators'

export const LOCKCODES = 'LOCKCODES'
export const PERMISSIONS_SECTION = 'LOCKCODEPERMISSIONS'

export const CREATE_CODE = 'CREATE_CODE'
export const CREATE_CODE_START = 'CREATE_CODE_START'
export const CREATE_CODE_ERROR = 'CREATE_CODE_ERROR'
export const CREATE_CODE_SUCCESS = 'CREATE_CODE_SUCCESS'

export const EDIT_CODE = 'EDIT_CODE'
export const EDIT_CODE_START = 'EDIT_CODE_START'
export const EDIT_CODE_ERROR = 'EDIT_CODE_ERROR'
export const EDIT_CODE_SUCCESS = 'EDIT_CODE_SUCCESS'

export const EXTEND_CODE = 'EXTEND_CODE'
export const EXTEND_CODE_START = 'EXTEND_CODE_START'
export const EXTEND_CODE_ERROR = 'EXTEND_CODE_ERROR'
export const EXTEND_CODE_SUCCESS = 'EXTEND_CODE_SUCCESS'

export const DELETE_CODE = 'DELETE_CODE'
export const DELETE_CODE_START = 'DELETE_CODE_START'
export const DELETE_CODE_ERROR = 'DELETE_CODE_ERROR'
export const DELETE_CODE_SUCCESS = 'DELETE_CODE_SUCCESS'

export const RESYNC_CODES = 'RESYNC_CODES'
export const RESYNC_CODES_START = 'RESYNC_CODES_START'
export const RESYNC_CODES_ERROR = 'RESYNC_CODES_ERROR'
export const RESYNC_CODES_SUCCESS = 'RESYNC_CODES_SUCCESS'

export const {
  fetchAction: fetchLockCodesAction,
  fetchStartAction: fetchLockCodesStart,
  fetchSuccessAction: fetchLockCodesSuccess,
  fetchErrorAction: fetchLockCodesError
} = createFetchActions(LOCKCODES)

export const fetchLockCodes = id => fetchLockCodesAction({ id })

export const {
  fetchAction: fetchPermissions,
  fetchStartAction: fetchPermissionsStart,
  fetchSuccessAction: fetchPermissionsSuccess,
  fetchErrorAction: fetchPermissionsError
} = createFetchActions(PERMISSIONS_SECTION)

export const createCode = (id, identifier, codeValue, codeType) => ({
  type: CREATE_CODE,
  id,
  identifier,
  codeValue,
  codeType
})

export const createCodeStart = (id, tempCodeId, codeType) => ({
  type: CREATE_CODE_START,
  id,
  tempCodeId,
  codeType
})

export const createCodeError = (id, tempCodeId) => ({
  type: CREATE_CODE_ERROR,
  id,
  tempCodeId
})

export const createCodeSuccess = (id, tempCodeId, codeInfo) => ({
  type: CREATE_CODE_SUCCESS,
  id,
  tempCodeId,
  codeInfo
})

export const editCode = (id, identifier, codeId, codeValue) => ({
  type: EDIT_CODE,
  id,
  identifier,
  codeId,
  codeValue
})

export const editCodeStart = (id, codeId) => ({
  type: EDIT_CODE_START,
  id,
  codeId
})

export const editCodeError = (id, codeId) => ({
  type: EDIT_CODE_ERROR,
  id,
  codeId
})

export const editCodeSuccess = (id, codeId, codeInfo) => ({
  type: EDIT_CODE_SUCCESS,
  id,
  codeId,
  codeInfo
})

export const extendCode = (id, identifier, codeId, codeValue) => ({
  type: EXTEND_CODE,
  id,
  identifier,
  codeId,
  codeValue
})

export const extendCodeStart = (id, codeId) => ({
  type: EXTEND_CODE_START,
  id,
  codeId
})

export const extendCodeError = (id, codeId) => ({
  type: EXTEND_CODE_ERROR,
  id,
  codeId
})

export const extendCodeSuccess = (id, codeId, codeInfo) => ({
  type: EXTEND_CODE_SUCCESS,
  id,
  codeId,
  codeInfo
})

export const deleteCode = (id, identifier, codeId) => ({
  type: DELETE_CODE,
  id,
  identifier,
  codeId
})

export const deleteCodeStart = (id, codeId) => ({
  type: DELETE_CODE_START,
  id,
  codeId
})

export const deleteCodeError = (id, codeId) => ({
  type: DELETE_CODE_ERROR,
  id,
  codeId
})

export const deleteCodeSuccess = (id, codeId) => ({
  type: DELETE_CODE_SUCCESS,
  id,
  codeId
})

export const resyncLockCodes = id => ({
  type: RESYNC_CODES,
  id
})

export const resyncLockCodesStart = () => ({
  type: RESYNC_CODES_START
})

export const resyncLockCodesError = () => ({
  type: RESYNC_CODES_ERROR
})

export const resyncLockCodesSuccess = () => ({
  type: RESYNC_CODES_SUCCESS
})
