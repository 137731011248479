// @flow
import React from 'react'
// components
import SmartCard from './SmartCard'
import ChatCard from './ChatCard'
import UserActivationCard from './UserActivationCard'
import ResidentAppUsageCard from './ResidentAppUsageCard'
import { withTheme } from 'emotion-theming'
import SummaryCard, { UnitSummaryCard } from './SummaryCard'
import { UnitSmartCardContent } from './SmartCard'
import Loader from '../Common/Loader'
import { OccupancyWrapper } from './OccupancyCard/styles'

type Props = {
  smartCardData: {
    hubCount: number,
    hubName: string,
    deviceName: string,
    deviceCount: number,
    viewMoreUrl: string,
    active: boolean
  },
  usersCardData: {
    count: number,
    users: Object[]
  },
  isFetching: boolean,
  hasData: boolean,
  summaryDetails: Object,
  url: string,
  isChatEnabled: boolean,
  chatUnreadData: Object,
  chatChannelCounts: {
    unreadResidentChannels: number,
    totalResidentChannels: number
  },
  userActiveData: Object,
  engagementData: Object,
  isSmartEnabled: boolean,
  theme: Object
}

const UnitSummary = ({
  smartCardData,
  usersCardData,
  isFetching,
  hasData,
  summaryDetails,
  url,
  userActiveData,
  engagementData,
  chatChannelCounts,
  chatUnreadData,
  isChatEnabled,
  isSmartEnabled
}: Props) => {
  if (isFetching) return <Loader />
  if (!hasData) return null
  const { count: usersCount } = usersCardData
  const { unreadResidentChannels } = chatChannelCounts

  return (
    <React.Fragment>
      <SummaryCard
        filters={[{ name: `${usersCount} Residents`, route: '' }]}
        unitName={summaryDetails.name}
        batteryData={summaryDetails.progressData.battery}
        content={() => (
          <UnitSummaryCard
            data={usersCardData}
            chatUnreadData={chatUnreadData}
          />
        )}
        rightContent={() => (
          <OccupancyWrapper>
            <UserActivationCard {...userActiveData} />
          </OccupancyWrapper>
        )}
      />
      {isSmartEnabled && (
        <SmartCard
          viewMoreUrl={smartCardData.viewMoreUrl}
          active={smartCardData.active}
          countText={'units'}
          content={() => <UnitSmartCardContent data={smartCardData} />}
        />
      )}
      <ResidentAppUsageCard data={engagementData} />
      {isChatEnabled && (
        <ChatCard
          count={unreadResidentChannels}
          viewMoreUrl={`${url.split('/unit')[0]}/communication`}
          baseUrl={url.split('/unit')[0]}
          text={
            <div>
              <font color="#000" size="3">
                Engage this units residents.
              </font>
              &nbsp;Write bulk messages to this unit for
              <br />
              everyday updates and notices or chat one on one with a resident in
              <br />
              app.
            </div>
          }
        />
      )}
    </React.Fragment>
  )
}

export default withTheme(UnitSummary)
