import React from 'react'
import PrivateRoute from '../PrivateRoute'
import AmenityDashboard from '../../views/Amenities'
import Amenity from '../../views/Amenities/AmenityShared'

const getAmenitiesRoutes = (showAmenityReservation) => [
  <PrivateRoute
    key="amenity"
    path="/properties/:propertyId/amenities/:amenityId"
    component={Amenity}
    exact
  />,
  <PrivateRoute
    key="amenity"
    path="/properties/:propertyId/amenities/:amenityId/:tab"
    component={Amenity}
    exact
  />,
  <PrivateRoute
    key="registration"
    path="/properties/:propertyId/amenities"
    reservationLdFlag={showAmenityReservation}
    component={AmenityDashboard}
    eaxct
  />,
]

export default getAmenitiesRoutes
