import React, { useRef, useEffect, useCallback } from 'react'

type Props = {
  element: ?string,
  onSizeChanged: (boundingRect: DOMRect) => {},
  style: ?Object,
  children: ?node,
}

/**
 * Container that 'reports' its dimensions via a callback function, passed in as a prop
 * @param {String} element - the html tag to render for the container, default is 'div'
 * @param {Function} onSizeChanged - a callback function that takes DOMRect parameter (i.e. container's bounds)
 * @param {Object} style - style passed on to the root container (element)
 * @param {Node} children - the container's children (you can use standard JSX nesting)
 * @see DOMRect
 */
export default function SizeObservingContainer({
  element = 'div',
  onSizeChanged,
  style,
  children,
}: Props) {
  const ref = useRef({ current: null })

  const doCallback = useCallback(() => {
    if (ref.current) {
      onSizeChanged(ref.current.getBoundingClientRect())
    }
  }, [ref, onSizeChanged])
  useEffect(() => {
   doCallback()
  }, [ref, doCallback])

  return React.createElement(element, { children, style, ref, onresize: doCallback })
}

