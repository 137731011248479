// @flow
import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'

import {
  styles,
  ImageStyle,
  MainContainer,
  LeftContainer,
  RightContainer,
  ImageContainer
} from './styles'

type Props = {
  classes: Object,
  show: boolean,
  onClose: Function,
  title: string,
  subTitle: string,
  titleDesc: string,
  images: Object,
  photoIndex: number,
  message: string,
  data: Object,
  emptyContentText: string,
  maxWidth: string,
}

const AlertModalPhoto = ({
  show,
  onClose,
  title,
  subTitle,
  titleDesc,
  images,
  photoIndex,
  maxWidth,
  classes
}: Props) => {
  const [activeImg, setActiveImg] = useState(photoIndex)
  const handleImgClick = (i) => {
    setActiveImg(i)
  }

  let photoCount = 0
  photoCount += images.length

  return (
    <React.Fragment>
      <Dialog
        classes={{
          paper: classes.root
        }}
        PaperProps={{ className: { dialogPaper: { overflow: 'hidden' } } }}
        maxWidth={maxWidth}
        open={show}
        onClose={onClose}
        aria-describedby="alert-dialog-description">

        <DialogContent>
          <Button onClick={onClose} style={styles.closeButton} >
            <CloseIcon style={styles.closeIcon} />
          </Button>
          <DialogContentText style={styles.title}>
            <b>{title}</b> - {subTitle}
          </DialogContentText>
          <DialogContentText style={styles.titleDesc}>
            {titleDesc}
          </DialogContentText>
        </DialogContent>
        <MainContainer>
          <LeftContainer style={photoCount === 1 ? styles.oneImage : null}>
            <ImageStyle src={images[activeImg]['imageUrl']} key={activeImg} />
          </LeftContainer>
          {photoCount > 1 &&
            <RightContainer style={photoCount === 2 ? styles.twoImage : null}>
              {images && images.map((object, i) => images[i]['imageUrl'] &&
                <ImageContainer style={styles.imgContainer} resizeMode='contain'>
                  <ImageStyle src={images[i]['imageUrl']} key={i} onClick={() => handleImgClick(i)} />
                </ImageContainer>)}
            </RightContainer>
          }
        </MainContainer>
      </Dialog>
    </React.Fragment >
  )
}

export default withStyles(styles)(AlertModalPhoto)
