import { createFetchActions } from '../../helpers/actions/creators'

export const TEMPLATES = 'TEMPLATES'

export const {
  fetchAction: fetchTemplatesAction,
  fetchStartAction: fetchTemplatesStart,
  fetchSuccessAction: fetchTemplatesSuccess,
  fetchErrorAction: fetchTemplatesError
} = createFetchActions(TEMPLATES)

export const fetchTemplates = propertyId => fetchTemplatesAction({ propertyId })

export const searchTemplates = (propertyId, searchTerm, page, queryParams = {}) => fetchTemplatesAction({ propertyId, searchTerm, queryParams: { ...queryParams, page } })
