// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import some from 'lodash/some'
import isEqual from 'lodash/isEqual'
import Switch from '@material-ui/core/Switch'
import AlertModal from '@Common/AlertModal/AlertModal'
import Linkify from '@Common/Linkify'
import { GridItem } from '../../Common/Grid'
import Flex, { FlexItem } from '@Common/Flex'
import CTAButtons from '../../Common/CTAButtons'
import HeaderWithSub from '../../Common/HeaderWithSub'
import Loader from '../../Common/Loader'
import AmenityLegacyImage from './AmenityLegacyImage'
import {
  ImageWrapper,
  Container,
  Image,
  Content,
  Label,
  TextField,
} from '../AmenityShared/styles'

import { BrowserHistory } from 'history'
import type { Match } from 'react-router-dom'
import type { Theme } from '../../../theme'

type Props = {
  item: Object,
  addMode: boolean,
  theme: Theme,
  history: BrowserHistory,
  match: Match,
  updateAmenity: (Object, number) => mixed,
  deleteAmenity: (number) => mixed,
  isFetching: boolean,
  hasData: boolean,
  hasAmenityContactInfo: boolean,
}

type State = {
  editMode: boolean,
  addMode: boolean,
  showDialog: boolean,
  item: Object,
  edited: boolean,
  error: boolean,
  croppedImage: ?Object,
  showCropper: boolean,
  dialogMode: string,
}

class AmenityLegacy extends React.Component<Props, State> {
  state = {
    editMode: false,
    addMode: this.props.addMode,
    showDialog: false,
    item: this.props.item,
    edited: false,
    error: false,
    croppedImage: null,
    showCropper: false,
    dialogMode: 'edit',
  }

  componentDidUpdate(prevProps) {
    const { item } = this.props
    if (!isEqual(prevProps.item, item)) {
      this.setState({
        item: this.props.item,
      })
    }
  }

  goBack = () => {
    const {
      history,
      match: { url },
    } = this.props

    const { editMode, addMode, edited } = this.state

    if (addMode || editMode) {
      if (edited) {
        this.setState({ showDialog: true, dialogMode: 'edit' })
      } else {
        this.exitSave()
      }
    } else {
      let splitText = '/amenities'
      history.push(`${url.split(splitText)[0]}${splitText}`)
    }
  }

  exitSave = () => {
    const {
      history,
      match: { url },
    } = this.props

    const { editMode } = this.state

    const splitText = '/amenities'
    const backUrl = `${url.split(splitText)[0]}${splitText}`
    this.setState({
      showDialog: false,
      addMode: false,
      editMode: false,
    })
    if (!editMode) history.push(backUrl)
  }

  saveAmenity = () => {
    let {
      item: { picture, croppedImage, ...item },
    } = this.state
    const error = this.hasErrors()
    if (!error) {
      if (picture instanceof File) {
        item.files = { main: picture }
      }
      if (croppedImage instanceof File) {
        item.files = { main: croppedImage }
      }
      this.props.updateAmenity(item, item.id)
      this.setState({
        showDialog: false,
        addMode: false,
        editMode: false,
      })
    }
  }

  deleteAmenity = () => {
    const { deleteAmenity } = this.props
    const { id } = this.state.item
    deleteAmenity(id)
  }

  closeAlertModal = () => {
    this.setState({ showDialog: false })
  }

  openDeleteDialog = () => {
    this.setState({ showDialog: true, dialogMode: 'delete' })
  }

  openDialog = () => {
    this.setState({ showDialog: true })
  }

  toggleEditMode = () => {
    let { addMode, editMode, edited } = this.state
    const error = this.hasErrors()
    if (edited && (editMode || addMode)) this.saveAmenity()
    if (!addMode && !editMode) {
      this.setState({ editMode: true })
    } else if (!error) {
      if (editMode) editMode = false
      if (addMode) addMode = false
      this.setState({ editMode, addMode })
    }
  }

  updateItem = (update, shouldCallAPI = false) => {
    this.setState(
      (oldState) => {
        return {
          ...oldState,
          item: { ...oldState.item, ...update },
          edited: true,
        }
      },
      () => {
        if (shouldCallAPI) {
          this.saveAmenity()
        }
      }
    )
  }

  deleteImage = () => {
    this.updateItem({ croppedImage: null, picture: null })
  }

  uploadCroppedImage = (file: any) => {
    file.url = URL.createObjectURL(file)

    this.updateItem({ croppedImage: file })
    this.setState({ showCropper: false })
  }

  uploadImage = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let picture = event.target.files[0]
    // $FlowDisableNextLine
    picture.url = URL.createObjectURL(picture)
    if (picture) {
      this.updateItem({ picture })
      this.setState({ showCropper: true })
    }
  }

  handleChange = ({
    currentTarget: { id, value },
  }: SyntheticEvent<HTMLInputElement>) => {
    this.updateItem({ [id]: value })
  }

  switchReservation = (_e, checked) => {
    const { addMode, editMode } = this.state
    if (addMode || editMode) {
      this.updateItem({ reservable: checked })
    }
  }

  isError = (field) => {
    const { edited, item } = this.state
    if (edited && !item[field]) {
      return true
    }
    return false
  }

  hasErrors = () => some(['name', 'description'], this.isError)

  render() {
    const {
      addMode,
      editMode,
      item,
      showDialog,
      dialogMode,
      showCropper,
    } = this.state
    const { theme, hasData, isFetching, hasAmenityContactInfo } = this.props
    const readMode = !addMode && !editMode

    if (isFetching) return <Loader />
    if (!hasData && !readMode) return null

    return (
      <React.Fragment>
        <ImageWrapper>
          <AmenityLegacyImage
            readMode={readMode}
            showCropper={showCropper}
            theme={theme}
            image={item.picture}
            croppedImage={item.croppedImage}
            deleteImage={this.deleteImage}
            uploadImage={this.uploadImage}
            uploadCroppedImage={this.uploadCroppedImage}
          />
        </ImageWrapper>
        <GridItem columnStart={9} columnSpan={4} rowSpan={2} rowStart={2}>
          <Flex direction="column" paddingTop="0.5em">
            {readMode ? (
              <HeaderWithSub
                subheader="AMENITIES"
                header={item.name.toUpperCase()}
                noUnderline
                paddingLeft="10px"
              />
            ) : (
              <TextField
                error={this.isError('name')}
                onChange={this.handleChange}
                InputLabelProps={{ shrink: true }}
                alwaysShowLabel={!readMode}
                textAlign={readMode ? 'right' : 'left'}
                prefix={null}
                label="Name"
                id="name"
                value={item.name}
                style={{ width: 250 }}
                showPencil
              />
            )}
            {hasAmenityContactInfo && (
              <FlexItem paddingTop="2em">
                <Switch
                  onChange={this.switchReservation}
                  checked={item.reservable}
                  id="reservable"
                />
                <Label>Reservable</Label>
              </FlexItem>
            )}
          </Flex>
        </GridItem>
        <GridItem columnStart={1} columnSpan={12} rowSpan={8} rowStart={5}>
          <Container>
            <Image imageSrc="/" />
            <Content>
              {!readMode && (
                <TextField
                  error={this.isError('description')}
                  onChange={this.handleChange}
                  fullWidth
                  id="description"
                  alwaysShowLabel
                  label="Description"
                  value={item.description}
                  multiline
                  textAlign="left"
                  placeholder="Amenity Description"
                  rows="5"
                  showPencil={true}
                />
              )}
              {readMode && (
                <Linkify tagName="span" content={item.description} />
              )}
            </Content>
          </Container>
        </GridItem>

        <CTAButtons
          toggleEditMode={this.toggleEditMode}
          goTo={this.goBack}
          isEditing={!readMode}
          showDelete={item.id != null}
          onDelete={this.openDeleteDialog}
        />
        <AlertModal
          title={
            dialogMode === 'delete'
              ? `Are you sure you want to delete ${item.name}?`
              : 'Would you like to save before leaving?'
          }
          message=""
          buttons={[
            {
              onClick:
                dialogMode === 'delete' ? this.deleteAmenity : this.saveAmenity,
              text: 'YES',
            },
            {
              onClick:
                dialogMode === 'delete' ? this.closeAlertModal : this.exitSave,
              color: theme.palette.primary.navy.spaceBlue,
              text: 'NO',
            },
          ]}
          show={showDialog}
          onClose={this.closeAlertModal}
        />
      </React.Fragment>
    )
  }
}

export default withTheme(AmenityLegacy)
