import Marketplace from './Marketplace'
import { connect } from 'react-redux'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { createIsFetchingNextSelector } from 'zego-shared/store/isFetchingNext/isFetchingNextSelectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { getMarketplaceDeals } from 'zego-shared/store/marketplace/selectors'
import {
  fetchMarketplaceDeals,
  fetchNextMarketplaceDeals,
  deleteMarketplaceDeal,
  reorderMarketplaceDeal
} from 'zego-shared/store/marketplace/actions'

const fetchingSelector = createIsFetchingSelector(['marketplacedeallist'])
const fetchingNextSelector = createIsFetchingNextSelector(['marketplacedeallist'])

export const mapStateToProps = (
  state,
  { match: { params: { propertyId } } }
) => ({
  deals: getMarketplaceDeals(state),
  isFetching: fetchingSelector(state),
  isFetchingNext: fetchingNextSelector(state),
  propertyId
})
export const mapDispatchToProps = (dispatch) => ({
  fetchData: () => {
    dispatch(fetchMarketplaceDeals())
  },
  fetchNextMarketplaceDeals: ({ page }) => {
    dispatch(fetchNextMarketplaceDeals({ page }))
  },
  deleteMarketplaceDeal: (id) => {
    dispatch(deleteMarketplaceDeal(id))
  },
  reorderMarketplaceDeal: (id, index) => {
    dispatch(reorderMarketplaceDeal(id, index))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(Marketplace))
