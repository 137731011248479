// @flow
import React from 'react'
import queryString from 'query-string'
import { withTheme } from 'emotion-theming'
import { withRouter, Link } from 'react-router-dom'
import { Tab, Tabs as MaterialTabs } from '@material-ui/core'
import { CommonIcon } from '../../../icons'
import { ContentWrap, customCss } from './styles'
import Flex from '../../Flex/FlexDirection'

import type { BrowserLocation } from 'history'

type Props = {
  selected: number,
  handleChange: (value: number) => void,
  selectColor?: string | ((theme: any) => string),
  tabs: any,
  customStyle: string,
  numberOfUnitsPerSlide?: number,
  tabStyle: number,
  scrollable?: boolean,
  theme: any,
  marginRight?: string,
  vertical?: boolean,
  selectTextColor: string,
  location: BrowserLocation,
  component?: string,
  onTabChange?: Function,
  onTabClick?: Function,
}

type State = {
  slideNumber: number,
}

class Tabs extends React.Component<Props, State> {
  static defaultProps = {
    tabStyle: 1,
    customStyle: '',
  }

  state = {
    slideNumber: 0,
  }

  getQuery(queryFromTab) {
    if (!queryFromTab) {
      return ''
    }
    const { location } = this.props
    const currentQuery = queryString.parse(location.search)
    const newQuery = { ...currentQuery, ...queryFromTab }
    return queryString.stringify(newQuery)
  }

  adjustScrollableIndex(index: number) {
    const { scrollable, numberOfUnitsPerSlide } = this.props
    const { slideNumber } = this.state

    if (scrollable && numberOfUnitsPerSlide) {
      return Math.floor(index / numberOfUnitsPerSlide) === slideNumber
        ? Math.abs(slideNumber * numberOfUnitsPerSlide - index)
        : false
    }

    return index
  }

  getCurrentTabIndex() {
    const { location, tabs } = this.props
    const queryParameters = queryString.parse(location.search)
    let index

    // check for query string parameters
    index = tabs.findIndex((tab) =>
      tab.query
        ? Object.keys(queryParameters).some(
            (key) => tab.query[key] === queryParameters[key]
          )
        : false
    )
    if (index !== -1) {
      return this.adjustScrollableIndex(index)
    }

    //try to find exact route first
    index = tabs.findIndex((tab) => location.pathname === tab.route)
    if (index !== -1) {
      return this.adjustScrollableIndex(index)
    }

    // didn't find exact match so check for nested route
    index = tabs.findIndex((tab) => location.pathname.includes(tab.route))
    if (index !== -1) {
      return this.adjustScrollableIndex(index)
    }

    return 0
  }

  scrollRight = () => {
    this.setState((state) => ({ slideNumber: state.slideNumber + 1 }))
  }

  scrollLeft = () => {
    this.setState((state) => ({ slideNumber: state.slideNumber - 1 }))
  }

  isLastSlide = () => {
    const { tabs, numberOfUnitsPerSlide, scrollable } = this.props
    const { slideNumber } = this.state
    return scrollable && numberOfUnitsPerSlide
      ? slideNumber * numberOfUnitsPerSlide + numberOfUnitsPerSlide >
          tabs.length - 1
      : false
  }

  isFirstSlide = () => {
    const { slideNumber } = this.state
    return slideNumber === 0
  }

  getTabs = () => {
    const { numberOfUnitsPerSlide, scrollable, tabs } = this.props
    const { slideNumber } = this.state
    if (scrollable && numberOfUnitsPerSlide) {
      return tabs.slice(
        slideNumber * numberOfUnitsPerSlide,
        slideNumber * numberOfUnitsPerSlide + numberOfUnitsPerSlide
      )
    }
    return tabs
  }

  render() {
    const {
      tabStyle,
      component = 'link',
      selected,
      tabs,
      marginRight,
      selectColor,
      customStyle,
      scrollable,
      numberOfUnitsPerSlide,
      onTabChange,
      onTabClick,
      theme,
      vertical,
      selectTextColor,
    } = this.props
    const currentTabIndex = this.getCurrentTabIndex()

    return (
      <Flex className={customCss(scrollable)}>
        <div>
          {scrollable && tabs.length > 1 && !this.isFirstSlide() && (
            <a onClick={this.scrollLeft} className="scroll-button">
              <CommonIcon
                name="arrow_left"
                height="12px"
                width="12px"
                fill={theme.palette.neutrals.warmGray}
              />
            </a>
          )}
        </div>
        <ContentWrap
          className={`main-tab-root${tabStyle}`}
          vertical={vertical}
          customStyle={customStyle}
          selectColor={selectColor}
          selectTextColor={selectTextColor}
          numberOfUnitsPerSlide={numberOfUnitsPerSlide}
          marginRight={marginRight}
          slideNumber={this.state.slideNumber}>
          <MaterialTabs
            value={component === 'link' && selected ? selected  : currentTabIndex}
            onChange={component === 'link' ? null : onTabChange}
            classes={{
              flexContainer: 'container',
              root: `tabs-root`,
              indicator: 'tabs-indicator',
            }}>
            {this.getTabs().map(
              ({ id, name, route = '', query, className }, index) =>
                component === 'link' ? (
                  <Tab
                    id={id || name}
                    disableRipple
                    data-testid={`${name}-${index}`}
                    className={className}
                    key={`${name}-${index}`}
                    classes={{
                      root: 'tab-root',
                      selected: 'tab-selected',
                    }}
                    label={name}
                    component={Link}
                    to={{
                      pathname: route,
                      search: this.getQuery(query),
                    }}
                    onClick={onTabClick}
                  />
                ) : (
                  <Tab
                    id={id || name}
                    disableRipple
                    data-testid={`${name}-${index}`}
                    className={className}
                    key={`${name}-${index}`}
                    classes={{
                      root: 'tab-root',
                      selected: 'tab-selected',
                    }}
                    label={name}
                    onClick={onTabClick}
                  />
                )
            )}
          </MaterialTabs>
        </ContentWrap>
        <div>
          {scrollable && tabs.length > 1 && !this.isLastSlide() && (
            <a onClick={this.scrollRight} className="scroll-button">
              <CommonIcon
                name="arrow_right"
                height="12px"
                width="12px"
                fill={theme.palette.neutrals.warmGray}
              />
            </a>
          )}
        </div>
      </Flex>
    )
  }
}

export default withRouter(withTheme(Tabs))
