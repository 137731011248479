// @flow

import React from 'react'
import BaseCard from '@Common/Cards/BaseCard'
import { InfoHead } from '../Common/InfoCard/styles'
import Flex from '@Common/Flex'
import Header from '@Common/Header'

import type { Node } from 'react'

type Props = {
  subheading?: string,
  content: Node,
  footerNote?: Node,
  headerRight?: Node
}

const BaseSettingCard = ({
  footerNote,
  subheading = '',
  content,
  headerRight
}: Props) => (
  <BaseCard
    title={null}
    footerNote={footerNote}
    footer={() => null}
    columnStart={1}
    columnSpan={10}
    rowStart={1}
    rowSpan={11}
    content={() => content}
    headerRight={() => headerRight}
    header={() => (
      <Flex fullWidth justifySpaceBetween>
        <Flex direction="column">
          <InfoHead>SETTINGS</InfoHead>
          <Header blackText variant="subheading">
            {subheading}
          </Header>
        </Flex>
        {headerRight}
      </Flex>
    )}
  />
)

export default BaseSettingCard
