import { call, select, put, takeLatest } from 'redux-saga/effects'
import { getToken, getUserRole } from '../../../authorization/selectors'
import {
  selectCompany,
  FETCH_COMPANY_PROPERTIES_AND_SELECT,
  fetchCompaniesAction,
  fetchCompaniesSuccess,
  fetchCompaniesError,
  fetchCompaniesStart
} from '../actions'
import {
  selectProperty,
  updatePropertyRenderReady
} from '../../properties/actions'
import { fetchPropertiesSuccess } from '../../properties/actions'
import { getSelectedProperty } from '../../properties/selectors'
import { fetchAllSettings } from '../../../settings/actions'

import api from '../../../../utils/api'
import log from '../../../../utils/logger'

export function* fetchCompaniesProperties(companyId) {
  try {
    const authToken = yield select(getToken)
    const response = yield call(
      api.getCompaniesProperties,
      authToken,
      companyId
    )

    yield put(fetchPropertiesSuccess(response))
    return response
  } catch (error) {
    log(`Failed to fetch company properties. Error: ${error}`)
  }
}

export function* fetchCompaniesPropertiesAndSelect({
  companyId,
  history,
  propertyId,
  url
}) {
  try {
    yield put(updatePropertyRenderReady(false))
    yield put(selectCompany(companyId))

    const properties = yield call(fetchCompaniesProperties, companyId)
    const firstPropertyId = propertyId ? propertyId : properties[0].id

    history.push(`/properties/${firstPropertyId}/summary`)
    yield put(selectProperty(firstPropertyId))
    history.push(url)
  } catch (error) {
    log(`Failed to fetch property. Error: ${error}`)
  }
}
export function* fetchCompanies({ page }) {
  try {
    yield put(fetchCompaniesStart())
    const authToken = yield select(getToken)
    const role = yield select(getUserRole)

    if (role === 'super_admin') {
      const companies = yield call(api.getCompanies, authToken, page)
      yield put(fetchCompaniesSuccess(companies))
    } else {
      throw new Error('Unable to fetch companies')
    }
  } catch (error) {
    yield put(fetchCompaniesError(error))
    log(`Failed to fetch companies. Error: ${error}`)
  }
}

export function* watchFetchCompaniesPropertiesAndSelect() {
  yield takeLatest(
    FETCH_COMPANY_PROPERTIES_AND_SELECT,
    fetchCompaniesPropertiesAndSelect
  )
}

export function* watchFetchCompanies() {
  yield takeLatest(fetchCompaniesAction().type, fetchCompanies)
}

export default [watchFetchCompaniesPropertiesAndSelect(), watchFetchCompanies()]
