// @flow

import get from 'lodash/get'
import { getTemplatePermission } from '../templatePermission/selectors'

export const getTemplate = (state: Object, isCreate: boolean) => {
  const template = get(state, ['groupMessaging', 'template'])
  if (template == null) {
    return template
  }
  const {
    id,
    title,
    resource_type: resourceType,
    name,
    body,
    text_body,
    attachments,
    is_default,
    foot_note
  } = template
  return isCreate
    ? {
        id: '',
        title: '',
        resourceType: '',
        name: '',
        message: '',
        textMessage: '',
        attachments: [],
        isDefault: false,
        footNote: ''
      }
    : {
        id,
        title,
        resourceType,
        name,
        message: body || '',
        textMessage: text_body || '',
        attachments,
        footNote : foot_note || '',
        isDefault : is_default || false
      }
}

export const canChangeTemplate = (state: Object) => {
  const template = getTemplate(state, false)
  if (template == null) return false

  const { resourceType } = template
  const templatePermission = getTemplatePermission(state) || []
  return templatePermission.includes(resourceType)
}

export const hasTemplate = (state: Object) => {
  return get(state, ['groupMessaging', 'template']) !== null
}

export const getFullTemplate = (state: Object) => state.groupMessaging.template
