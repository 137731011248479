// @flow
import styled from '@emotion/styled'
import MaterialTextField from '@material-ui/core/TextField'
import { convertHexToRGB } from '../../../utils'

export const TextField = styled(MaterialTextField)`
  width: 100%;
  border-radius: 50px;
  border: none;
  padding: ${({ isGroupMsg }) => (isGroupMsg ? '0 7px' : '0 6px')};
  background-color: ${({ theme }) => theme.palette.neutrals.lightGray};
  & input {
    color: ${({ theme }) => theme.palette.text.lightGrey};
    padding-top: ${({ isGroupMsg }) => (isGroupMsg ? '2' : '8px')};
    padding-bottom: ${({ isGroupMsg }) => (isGroupMsg ? '0' : '3px')};
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }
`

export const SearchWrapper = styled('div')`
  position: relative;
  flex: 1.5;

  .searchSubmitIcon:hover {
    cursor: pointer;
  }
`

export const SearchResultsWrapper = styled('div')`
  position: absolute;
  width: 100%;
  padding: 0em 2em;
  height: auto;
  box-shadow: ${({ theme }) =>
    `0 2px 4px 0 ${convertHexToRGB(theme.palette.grey.main, 0.25)}`};
  background-color: ${({ theme }) => theme.palette.common.white};
`

export const UnitContainer = styled('div')`
  height: 56px;
  display: flex;
  -webkit-display: flex;
  -webkit-align-items: center;
  color: ${({ theme }) => theme.palette.common.black};
  align-items: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: ${({ theme, lastUnit }) =>
    lastUnit ? `0px` : `1px solid ${theme.palette.neutrals.deepGray}`};

  * {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }

  a {
    color: ${({ theme }) => theme.palette.primary.green.mint};
  }
`

export const Padder = styled('div')`
  padding: 1em 0em;
`
export const SearchResultItem = styled('div')`
  padding-left: 1em;
`

export const SearchResultIcon = styled('div')`
  padding-left: 1em;
`
