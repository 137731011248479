// TODO token and app type should only be needed once
export function createHTTPHeader(authToken, appType) {
  const token = authToken == null ? 'test' : authToken
  const clientType = appType == null ? 'unknown' : appType
  return {
    Authorization: `Bearer ${token}`,
    'X-Api-Version': 2
    // 'X-Client-Type': clientType
  }
}

export const buildAPIUrl = (base_url, params) => {
  let finalParams = ''
  if (typeof params === 'object' && Object.keys(params).length > 0) {
    const parts = []
    for (var key in params) {
      const item = params[key]

      if ((item && !Array.isArray(item)) || (Array.isArray(item) && item.length > 0)) {
        parts.push(`${key}=${item}`)
      }
    }
    finalParams = `?${parts.join('&')}`
  } else {
    finalParams = `/${params}`
  }

  if (finalParams === '') {
    return base_url
  }

  return `${base_url}${finalParams}`
}
