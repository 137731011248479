// @flow

import get from 'lodash/get'
import flatten from 'lodash/flatten'

const getLocation = (units, residents, unitId, userId) => {
  const unitName = (units[unitId] && units[unitId].name) || 'Common Space'
  const resident = residents.find(r => r.user_id === userId)
  const residentName = (resident && resident.display) || 'PM'

  return `${unitName} / ${residentName}`
}

export const getWorkOrders = (state: Object, unitId: Number) => {
  const workOrders = get(state, ['workOrders', 'summary'], [])
  const units = get(state, ['select', 'units', 'byId'], {})
  const residents = flatten(
    ['active', 'inactive'].map(status =>
      get(state, ['users', 'summary', 'resident', status, 'data'], [])
    )
  )

  return (unitId ? workOrders.filter(wo => wo.unitId == unitId) : workOrders)
    .map(wo => ({ ...wo, location: getLocation(units, residents, wo.unitId, wo.userId) }))
}

export const getWorkOrderDetails = (state: Object) => {
  const workOrders = get(state, ['workOrders', 'details'], [])
  const units = get(state, ['select', 'units', 'byId'], {})
  const residents = flatten(
    ['active', 'inactive'].map(status =>
      get(state, ['users', 'summary', 'resident', status, 'data'], [])
    )
  )

  workOrders.location = getLocation(units, residents, workOrders.unitId, workOrders.userId)

  return workOrders
}

export const getWorkOrderCounts = (state: Object, unitId: Number) => {
  const workOrders = get(state, ['workOrders', 'summary'], [])

  return workOrders
    .filter(wo => wo.unitId == unitId)
    .reduce((counts, { status }) => {
      counts[status] = (counts[status] || 0) + 1
      return counts
    }, {})
}

export const getCategories = (state: Object) => get(state, ['workOrders', 'categories', 'categories'])

export const getWorkOrderAssignees = (state: Object): Array<{ value: ?string, label: string }> => {
  const assignees = flatten(['property_admin', 'property_staff', 'company_admin']
    .map(role => get(state, ['users', 'summary', role, 'data'], [])))
    .filter(assignee => assignee.display)
    .map(assignee => ({
      value: assignee.user_id,
      label: assignee.display + (assignee.title ? ` • ${assignee.title}` : '')
    }))

  return [{ value: null, label: "Unassigned" }]
    .concat(assignees)
}
