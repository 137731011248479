import axios from 'axios'

const setupResponseInterceptor = (onSuccess, onError) => {
  axios.interceptors.response.use(
    response => {
      onSuccess(response)
      return response
    },
    error => {
      return new Promise((resolve, reject) => {
        onError(error)
        return reject(error)
      })
    }
  )
}

export default {
  setupResponseInterceptor
}
