// @flow
import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import FlexDirection, { FlexItem } from '@Common/Flex/FlexDirection'
import { CommonIcon } from '@icons'
import { CarouselUnitProps as Props } from '../types'
import {
  UnitContainer,
  StyledTypography,
  UnitName,
  StyledLink,
  ViewLinkItem
} from '../styles'

const CarouselUnit = ({
  name,
  unitId,
  deviceId,
  deviceType,
  lastUnit,
  theme,
  match: { params }
}: Props) => {
  return (
    <UnitContainer lastUnit={lastUnit}>
      <FlexDirection fullWidth>
        <FlexItem
          flex={1}
          style={{ whiteSpace: 'nowrap', textTransform: 'uppercase' }}>
          <UnitName>{name}</UnitName>
        </FlexItem>
        <FlexItem
          flex={0.5}
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
          <CommonIcon
            name="bullet"
            width="4px"
            height="4px"
            fill={theme.palette.secondary.main3}
            style={{ alignSelf: 'center' }}
          />
        </FlexItem>
        <FlexItem flex={11}>
          <StyledTypography>{deviceType}</StyledTypography>
        </FlexItem>
        <ViewLinkItem flex={1}>
          <StyledLink
            to={`/properties/${
              params.propertyId
            }/units/${unitId}/smart/devices/${deviceId}/battery`}>
            View
          </StyledLink>
        </ViewLinkItem>
      </FlexDirection>
    </UnitContainer>
  )
}

export default withRouter(withTheme(CarouselUnit))
