// @flow
import React from 'react'
import { OptionLabelFlex, SubcategoryButton, OptionLabelText, SubcategoryButtonText } from './styles'

type Category = {
  name: string,
  subCategories: [string]
}

type Props = {
  handleToggle: (Object) => any,
  categories: [Category],
  selectedCategory: string
}

export class OptionLabel extends React.Component<Props, {}> {
  render() {
    const { selectedCategory, categories, handleToggle } = this.props
    const categoryObj = categories.find(c => c.name === selectedCategory)

    return (
      <OptionLabelFlex direction="row" fullWidth justifySpaceBetween>
        <OptionLabelText>{selectedCategory.toUpperCase()}</OptionLabelText>
        {categoryObj && categoryObj.subCategories.length ?
          <SubcategoryButton onClick={() => handleToggle({ currentTarget: { id: 'subCategorySelection' } })}>
            <SubcategoryButtonText>Sub Categories</SubcategoryButtonText>
          </SubcategoryButton>
          : null
        }
      </OptionLabelFlex>
    )
  }
}

export default OptionLabel
