import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'

export const Heading = styled('div', {
  shouldForwardProp: prop =>
    !['paddingTop', 'paddingBottom', 'paddingLeft', 'paddingRight'].includes(
      prop
    ) && isPropValid(prop)
})`
  div:first-child {
    font-size: ${({ theme, big }) =>
      big ? theme.custom.font.size.mdlg : theme.custom.font.size.sm};
    color: ${({ theme }) => theme.palette.grey.main};
    margin-bottom: 0.5em;
  }
  div:nth-child(2) {
    font-size: calc(${({ theme }) => theme.custom.font.size.xl} + 0.5em);
    color: ${({ theme }) => theme.palette.common.black};
  }
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};

  ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}`};

  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`};

  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
`
