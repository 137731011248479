export const userSettingsKeys = {
  receivePropertyTexts: 'receive_property_texts',
  receivePropertyEmails: 'receive_property_emails',
  receiveMaintenanceEmails: 'receive_maintenance_emails',
  showStaffProfile: 'show_staff_profile',
  residentZegoSmartEnabled: 'resident_zego_smart_enabled',
  userId: 'user_id'
}

export const getUserSettingValue = key => userSettingsKeys[key]
