import { call, select, takeLatest, put } from 'redux-saga/effects'
import isPlainObject from 'lodash/isPlainObject'
import { getToken, getUserRole } from '../../authorization/selectors'
import { showSnackbar } from '../../snackbar/actions'
import { hasChanges } from '../../../utils/object'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  failedToFetchGroupMessageTemplate,
  failedToUpdateGroupMessageTemplate,
  deleteGroupMessageTemplateSuccess,
  failedToDeleteGroupMessageTemplate,
  failedToCreateGroupMessageTemplate,
  createGroupMessageTemplateSuccess
} from '../../../utils/messages'
import {
  fetchTemplateAction,
  fetchTemplateStart,
  fetchTemplateSuccess,
  fetchTemplateError,
  updateTemplateOptimistically,
  updateTemplateRevert,
  updateTemplateSuccess,
  UPDATE_TEMPLATE,
  deleteTemplate as deleteTemplateAction,
  CREATE_TEMPLATE,
  createTemplateSuccess
} from './actions'
import { getFullTemplate } from './selectors'
import { getCompanyId, getPropertyId } from '../../select/properties/selectors'

export function* fetchTemplate({ templateId }) {
  try {
    yield put(fetchTemplateStart())
    const authToken = yield select(getToken)
    const userRole = yield select(getUserRole)

    //  Do property_staff check due to core api role check limitations, double refresh otherwise
    const response = (userRole === "property_staff")
      ? null
      : yield call(
        api.getGroupMessagingTemplate,
        authToken,
        templateId
      )
    yield put(fetchTemplateSuccess(response))
  } catch (error) {
    yield put(fetchTemplateError(error))
    yield put(showSnackbar(failedToFetchGroupMessageTemplate, 'error'))
    log(`Failed to fetch template`)
  }
}

export function* createTemplate({ payload, callback }) {
  try {
    const authToken = yield select(getToken)
    const resourceId =
      payload.resourceType === 'property'
        ? yield select(getPropertyId)
        : yield select(getCompanyId)

    const formData = new FormData()
    Object.entries(payload).forEach(([k, v]) => {
      if (k !== 'resourceType') {
        if (k === 'files') {
          v.forEach(file => formData.append('files[]', file))
        } else if (k === 'attachments') {
          Object.keys(v).forEach(attachment => {
            const keyName = [k, '[', attachment, ']'].join('')
            formData.append(keyName, v[attachment])
          })
        } else {
          formData.append(k, v)
        }
      }
    })

    const resource =
      payload.resourceType === 'company' ? 'companies' : 'properties'

    const response = yield call(
      api.createGroupMessagingTemplate,
      authToken,
      resourceId,
      resource,
      formData
    )
    typeof callback === 'function' && callback()
    yield put(createTemplateSuccess(response))
    yield put(showSnackbar(createGroupMessageTemplateSuccess, 'success'))
  } catch (error) {
    log(`Failed to create template. Error: ${error}`)
    yield put(showSnackbar(failedToCreateGroupMessageTemplate(error), 'error'))
  }
}

export function* updateTemplate({ update: newAtts }) {
  const currentState = yield select(getFullTemplate)
  let currentAtts = {}
  let attsToUpdate = {}
  for (let key in newAtts) {
    if (
      (!isPlainObject(newAtts[key]) && newAtts[key] !== currentState[key]) ||
      (isPlainObject(newAtts[key]) &&
        hasChanges(newAtts[key], currentState[key]))
    ) {
      attsToUpdate[key] = newAtts[key]
      currentAtts[key] = currentState[key]
    }
  }
  if (Object.keys(attsToUpdate).length > 0) {
    const authToken = yield select(getToken)
    try {
      yield put(updateTemplateOptimistically(newAtts))
      const { resourceType: updateResource, ...remAtts } = newAtts
      const formData = new FormData()
      Object.entries(remAtts).forEach(([k, v]) => {
        if (k === 'files') {
          v.forEach(file => formData.append('files[]', file))
        } else if (k === 'attachments') {
          Object.keys(v).forEach(attachment => {
            const keyName = [k, '[', attachment, ']'].join('')
            formData.append(keyName, v[attachment])
          })
        } else {
          formData.append(k, v)
        }
      })

      let resourceResponse
      if (updateResource != null) {
        const companyId = yield select(getCompanyId)
        const propertyId = yield select(getPropertyId)
        const updateResourceId =
          updateResource === 'company' ? companyId : propertyId
        resourceResponse = yield call(
          api.updateGroupMessagingTemplateResource,
          authToken,
          currentState.id,
          updateResource,
          updateResourceId
        )
      }

      const response = yield call(
        api.updateGroupMessagingTemplate,
        authToken,
        currentState.id,
        formData
      )

      yield put(updateTemplateSuccess(response))
    } catch (error) {
      log(`Failed to update template. Error: ${error}`)
      yield put(updateTemplateRevert(currentState))
      yield put(showSnackbar(failedToUpdateGroupMessageTemplate, 'error'))
    }
  }
}

export function* deleteTemplate({ templateId, history }) {
  try {
    const authToken = yield select(getToken)
    const propertyId = yield select(getPropertyId)

    yield call(api.deleteGroupMessageTemplate, authToken, templateId)
    yield put(showSnackbar(deleteGroupMessageTemplateSuccess, 'success'))

    history.push(
      `/properties/${propertyId}/communication/group-messaging/templates`
    )
  } catch (error) {
    log(`Failed to delete template. Error: ${error}`)
    yield put(showSnackbar(failedToDeleteGroupMessageTemplate, 'error'))
  }
}

function* watchFetchTemplate() {
  yield takeLatest(fetchTemplateAction().type, fetchTemplate)
}

function* watchUpdateTemplate() {
  yield takeLatest(UPDATE_TEMPLATE, updateTemplate)
}

function* watchCreateTemplate() {
  yield takeLatest(CREATE_TEMPLATE, createTemplate)
}

function* watchDeleteTemplate() {
  yield takeLatest(deleteTemplateAction().type, deleteTemplate)
}

export default [
  watchFetchTemplate(),
  watchUpdateTemplate(),
  watchDeleteTemplate(),
  watchCreateTemplate()
]
