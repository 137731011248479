import { connect } from 'react-redux'
import { createMarketplaceDeal } from 'zego-shared/store/marketplace/actions'
import MarketplaceNewDeal from './MarketplaceNewDeal'

export const mapStateToProps = state => ({})

export const mapDispatchToProps = (
  dispatch,
  { history, match: { url, params: { propertyId } } }
) => ({
  createMarketplaceDeal: (marketplaceDeal, image, type) => dispatch(createMarketplaceDeal(
    marketplaceDeal,
    image,
    type,
    history,
    `${url.split('/marketplace')[0]}/marketplace/overview`
  ))
})

export default connect(mapStateToProps, mapDispatchToProps)(MarketplaceNewDeal)
