import moment from 'moment'

import { getFutureDateString, getUnixStartEndMonth } from '../../utils'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

const eventToEventData = currentUserId => event => {
  if (event) {
    const eventTime = moment.unix(event.event_datetime)
    return {
      id: event.thread_id,
      day: eventTime.date(),
      month: eventTime.format('MMMM'),
      attendeesCount: event.rsvps_count,
      attendessImage:
        event.rsvps.length > 0
          ? event.rsvps[event.rsvps.length - 1].picture
          : null,
      title: event.title,
      isAttending:
        event.rsvps &&
        event.rsvps.find(({ user_id }) => user_id === currentUserId)
          ? true
          : false,
      dateText: getFutureDateString(eventTime)
    }
  }
  return null
}

export const getNewOrUpcomingEvents = (state, type = 'upcoming') => {
  const events = state.communityFeed.events[`${type}Events`]
  const currentUserId = state.authorization.id
  return events ? events.map(eventToEventData(currentUserId)) : null
}

export const getNewOrUpcomingEventsCount = (state, type = 'upcoming') => {
  const events = getNewOrUpcomingEvents(state, (type = 'upcoming'))
  return events ? events.length : []
}

export const getEventsByMonth = (state, month = moment().month()) => {
  const { start, end } = getUnixStartEndMonth(month)
  const currentUserId = state.authorization.id

  const events = get(
    state,
    ['communityFeed', 'events', 'events', 'data'],
    undefined
  )
  return events
    ? events
        .filter(
          event => event.event_datetime > start && event.event_datetime < end
        )
        .map(eventToEventData(currentUserId))
    : []
}

export const getFilteredEvents = state => {
  const currentUserId = state.authorization.id

  const events = get(
    state,
    ['communityFeed', 'events', 'events', 'filteredEvents', 'data'],
    undefined
  )
  return events ? events.map(eventToEventData(currentUserId)) : []
}

export const getFilteredStatus = state => {
  const status = get(
    state,
    ['communityFeed', 'events', 'events', 'filtered'],
    undefined
  )

  return status
}

export const getThreadType = type => {
  return type ? (type === 'posts' ? 'feed' : 'event') : 'feed'
}

export const hasEventData = (state, type = 'upcoming') => {
  return state.communityFeed.events[`${type}Events`]
}

export const getTimestamps = state => {
  return state.communityFeed.events.endTimestamps
}

export const getShouldFetchNextEvents = (state, start) => {
  const totalPages = get(
    state,
    ['communityFeed', 'events', 'events', 'pagination', start, 'totalPages'],
    0
  )
  const nextPage = get(
    state,
    ['communityFeed', 'events', 'events', 'pagination', start, 'nextPage'],
    0
  )
  return totalPages >= nextPage
}

export const staffToStaffData = adminStaff => {
  return adminStaff.data.map(staff => {
    return {
      userId: staff.user_id,
      userName: staff.display,
      userPicture: staff.picture
    }
  })
}

const getStaffData = propertyStaff => {
  const admins = ['property_admin', 'company_admin']
  let result = []

  admins.forEach(adminType => {
    result = [
      ...result,
      ...propertyStaff[adminType].data.map(staff => {
        return {
          userId: staff.user_id,
          userName: staff.display,
          userPicture: staff.picture
        }
      })
    ]
  })
  return result
}

const postToPostData = (post, url) => {
  if (post && url) {
    return {
      userId: post.user.user_id,
      url: `${url.split('/posts')[0]}/posts/${window.btoa(post.thread_id)}`,
      numberOfLikes: post.likes,
      numberOfComments: post.reply_count,
      title: post.title,
      userName: post.user.name,
      userPicture: post.user.picture,
      postImage: post.metadata.images && post.metadata.images[0],
      dateText: getFutureDateString(moment.unix(post.created_at)),
      body: post.body
        .substr(0, 100)
        .concat(post.body.length > 100 ? '...' : ''),
      showReadMore: post.body.length > 100
    }
  }
  return null
}

export const hasPostsData = state => {
  return state.communityFeed.posts
}

export const getPosts = (state, url) => {
  const posts = get(state, ['communityFeed', 'posts', 'data'], undefined)
  return posts ? posts.map(post => postToPostData(post, url)) : null
}

export const getPostAuthors = state => {
  const propertyStaff = get(state, ['users', 'summary'], {})

  return isEmpty(propertyStaff) ? '' : getStaffData(propertyStaff)
}

export const getShouldFetchNextPosts = state => {
  const totalPages = get(state, ['communityFeed', 'posts', 'totalPages'], 0)
  const nextPage = get(state, ['communityFeed', 'posts', 'nextPage'], 0)
  return totalPages >= nextPage
}
