// @flow
import React from 'react'
import type { Node } from 'react'

import { Container, Item } from './styles'

type FlexProps = {
  children: Node,
  direction?: string,
  fullWidth?: boolean,
  fullHeight?: boolean
}

type FlexItemProps = {
  children?: Node,
  flex?: number
}

export const Flex = (props: FlexProps) => {
  const { children } = props
  return <Container {...props}>{children}</Container>
}

export const FlexItem = (props: FlexItemProps) => {
  const { flex, children } = props
  return (
    <Item {...props} flex={flex}>
      {children}
    </Item>
  )
}

export default Flex
