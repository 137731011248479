import {
  createFetchNextActions,
  createFetchActions
} from '../helpers/actions/creators'

export const POSTS = 'POSTS'
export const FILTER_POSTS = 'FILTER_POSTS'
export const FILTER_POSTS_SUCCESS = 'FILTER_POSTS_SUCCESS'
export const SET_FILTER_VALUES_TO_STATE = 'SET_FILTER_VALUES_TO_STATE'
export const CANCEL_FILTER_POSTS = 'CANCEL_FILTER_POSTS'

export const {
  fetchAction: fetchPostsAction,
  fetchStartAction: fetchPostsStart,
  fetchSuccessAction: fetchPostsSuccess,
  fetchNextAction: fetchPostsNextAction,
  fetchNextStartAction: fetchPostsNextStart,
  fetchNextSuccessAction: fetchPostsNextSuccess,
  fetchNextErrorAction: fetchPostsNextError
} = createFetchNextActions(POSTS)

export const fetchPosts = propertyId => fetchPostsAction({ propertyId })

export const fetchNextPosts = propertyId => fetchPostsNextAction({ propertyId })

export const filterPosts = (date, userId, threadType, sortBy, propertyId) => ({
  type: FILTER_POSTS,
  date,
  userId,
  threadType,
  sortBy,
  propertyId
})

export const fetchFilteredPostsSuccess = posts => ({
  type: FILTER_POSTS_SUCCESS,
  posts
})

export const setFilterValuesToState = (date, userId, threadType,sortBy) => ({
  type: SET_FILTER_VALUES_TO_STATE,
  date,
  userId,
  threadType,
  sortBy
})
