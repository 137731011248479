// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import Masonry from 'react-masonry-component'
import { CommonIcon, ChatIcon } from '@icons'
import moment from 'moment'
import { ContactButton, ContactButtons, Content, Footer, Header, NewIndicator, NewTag, NoSurveys, SeeAllButton, SurveyCard, ViewMoreButton } from './styles'
import SurveyList from './SurveyList'

import type { Theme } from 'emotion-theming'

type Props = {
  theme: Theme,
  profile: Object,
  chatEnabled: boolean,
  surveys: Array<Object>,
  propertyId: number
}

type State = {
  surveysByType: Object,
  showModal: boolean,
  modalType: ?string,
  lastVisit: ?number,
}

const surveyKeyNames = {
  "work_order_completion": "Post Work Order",
  "lease_end" : "Pre Lease Renewal",
  "move_in": "Post Move In",
  "amenity": "Post Amenity Use" 
}

class Surveys extends React.Component<Props, State> {
  state = {
    surveysByType: {},
    showModal: false,
    modalType: null,
    lastVisit: null
  }

  componentDidMount = () => {
    const lastVisit = document.cookie.split('; ').find(v => v.startsWith('surveys_last_read'))
    if (lastVisit) {
      this.setState({ lastVisit: parseInt(lastVisit.split('=')[1]) })
    }

    document.cookie = `surveys_last_read=${moment().startOf('day').valueOf()}`
  }

  componentDidUpdate = (prevProps: Props) => {
    const { surveys } = this.props

    if (surveys !== prevProps.surveys) {
      const surveysByType = surveys.reduce((acc, survey) => {
        if (!acc[survey.type.name]) {
          acc[survey.type.name] = { latest: survey, top: survey, bottom: survey, total: survey.survey_result, count: 1, expand: false }
        } else {
          acc[survey.type.name].total += survey.survey_result
          acc[survey.type.name].count++

          if (Date.parse(acc[survey.type.name].latest.completed_on) < Date.parse(survey.completed_on)) {
            acc[survey.type.name].latest = survey
          }
          if (acc[survey.type.name].top.survey_result < survey.survey_result) {
            acc[survey.type.name].top = survey
          }
          if (acc[survey.type.name].bottom.survey_result > survey.survey_result) {
            acc[survey.type.name].bottom = survey
          }
        }

        return acc
      }, {})

      this.setState({ surveysByType })
    }
  }

  toggleExpand = type => {
    this.setState(({ surveysByType }) => {
      surveysByType[type].expand = !surveysByType[type].expand

      return { surveysByType }
    })
  }

  showModal = type => {
    this.setState({ showModal: true, modalType: type })
  }

  hideModal = () => {
    this.setState({ showModal: false })
  }

  renderEmptyCard = (type) => (
    <SurveyCard key={type}>
      <Header>
        <h1>{surveyKeyNames[type]}</h1>
        <NoSurveys>No Surveys Taken</NoSurveys>
      </Header>
      <Content>
        <div>
          <div className="left">Date:</div>
          <div className="right">
            Not Taken
          </div>
        </div>
        <div>
          <div className="left">Rating:</div>
          <div className="right">
            {[...Array(10)].map((_, index) => (
              <CommonIcon
                key={`${type}-${index}`}
                name="star"
                width="20px"
                height="20px"
              />
            ))}
          </div>
        </div>
        <div>
          <div className="left">Comments:</div>
          <div className="right">
            --
          </div>
        </div>
      </Content>
    </SurveyCard>
  )

  renderSurveyContent = (survey) => (
    <Content>
      <div>
        <div className="left">Date:</div>
        <div className="right">
          {moment(survey.completed_on).format("LL")}
          {this.state.lastVisit && moment(survey.completed_on).valueOf() >= this.state.lastVisit && (<NewTag>(NEW)</NewTag>)}
        </div>
      </div>
      <div>
        <div className="left">Rating:</div>
        <div className="right">
          {[...Array(10)].map((_, index) => (
            <CommonIcon
              key={`${survey.type.name}-${index}`}
              name={index >= survey.survey_result ? "star" : "star-on"}
              width="20px"
              height="20px"
            />
          ))}
        </div>
      </div>
      <div>
        <div className="left">Comments:</div>
        <div className="right">
          {survey.feedback}
        </div>
      </div>
    </Content>
  )

  renderSurveyCard = (type) => {
    const { surveysByType } = this.state
    const { theme, profile, chatEnabled, propertyId } = this.props

    if (!surveysByType[type]) {
      return this.renderEmptyCard(type)
    }

    return (
      <SurveyCard key={type}>
        <Header>
          {this.state.lastVisit && moment(surveysByType[type].latest.completed_on).valueOf() >= this.state.lastVisit && (<NewIndicator />)}
          <h1>{surveyKeyNames[type]}</h1>
          <div>Average: <span>{Math.round(surveysByType[type].total / surveysByType[type].count)}/10</span></div>
        </Header>
        {this.renderSurveyContent(surveysByType[type].latest)}
        {surveysByType[type].expand && surveysByType[type].top !== surveysByType[type].latest && this.renderSurveyContent(surveysByType[type].top)}
        {surveysByType[type].expand && surveysByType[type].bottom !== surveysByType[type].latest && surveysByType[type].bottom !== surveysByType[type].top && this.renderSurveyContent(surveysByType[type].bottom)}
        {surveysByType[type].expand && (<SeeAllButton onClick={() => this.showModal(type)}>See all reviews</SeeAllButton>)}
        {surveysByType[type].count > 1 ? (
          <Footer>
            <ViewMoreButton onClick={() => this.toggleExpand(type)}>
              <CommonIcon
                name={surveysByType[type].expand ? "arrow_up" : "arrow_down"}
                height="10px"
                width="10px"
                stroke={theme.palette.primary.green.seeFoam}
                strokeWidth="2"
              />
              <span>View {surveysByType[type].expand ? "Less" : "More"}</span>
            </ViewMoreButton>
            {surveysByType[type].expand && (
              <ContactButtons>
                {profile.email && (
                  <ContactButton href={`mailto:${profile.email}`}>
                    <CommonIcon
                      name="envelope_email"
                      height="18px"
                      width="16px"
                    />
                  </ContactButton>
                )}
                {chatEnabled && (
                  <ContactButton chat href={`/properties/${propertyId}/communication/chat/live-chat/users/${profile.userId}`}>
                    <ChatIcon
                      name="chat"
                      height="20px"
                      width="20px"
                    />
                  </ContactButton>
                )}
              </ContactButtons>
            )}
          </Footer>
        ) : null}
      </SurveyCard>
    )
  }

  render() {
    const { showModal, modalType, lastVisit } = this.state

    return showModal ?
      <SurveyList {...this.props} onClose={this.hideModal} type={modalType} lastVisit={lastVisit} />
      :
      <Masonry options={{ gutter: 40 }}>
        {Object.keys(surveyKeyNames).map(this.renderSurveyCard)}
      </Masonry>
  }
}

export default withTheme(Surveys)
