import styled from '@emotion/styled'
import MaterialMobileStepper from '@material-ui/core/MobileStepper'
import MaterialDivider from '@material-ui/core/Divider'
import MaterialCard from '@material-ui/core/Card'
import MaterialButton from '@material-ui/core/Button'
import Grid from '../Common/Grid'
import { ErrorMessage as CommonErrorMessage } from '../Common/Messages'

export const Divider = styled(MaterialDivider)`
  margin: 30px;
  width: 90%;
`

export const BtnText = styled('span')`
  padding-left: 1em;
`

export const Card = styled(MaterialCard)`
  box-shadow: none;
  width: 420px;
  padding: 0 2px;
`

export const WelcomeMsgGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.primary.green.mint};
  color: ${({ theme }) => theme.palette.common.white};
  font-size: ${({ theme }) => theme.custom.font.size.xxl};
`

export const MobileStepper = styled(MaterialMobileStepper)`
  align-items: flex-start;
  padding: 0 0px;
`

export const StepButton = styled(MaterialButton)`
  color: ${({ theme }) => theme.palette.primary.green.mint};
  font-size: ${({ theme }) => theme.custom.font.size.mdlg};
  &:hover {
    background: none;
  }
`

export const ErrorMessage = styled(CommonErrorMessage)`
  position: absolute;
`
