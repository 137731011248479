// @flow

import { combineReducers } from 'redux'
import withFetch from '../../helpers/reducers/withFetch'
import withFetchNext from "../../helpers/reducers/withFetchNext"
import { MARKETPLACE_CARD, REORDER_MARKETPLACE_DEAL } from '../actions'
import {
  fetchMarketplaceDealsSuccess,
  fetchNextMarketplaceDealsSuccess,
  DELETE_MARKETPLACE_DEAL,
  MARKETPLACEDEALLIST,
  updateMarketplaceCard
} from "../actions"

const marketplaceReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_MARKETPLACE_DEAL:
      const { id } = action
      return {
        ...state,
        data: state.data.filter(deal => deal.id !== id)
      }
    case REORDER_MARKETPLACE_DEAL:
      const { id: dealId, index } = action

      const fromIndex = state.data.findIndex(deal => deal.id === dealId)
      const toIndex = index + state.data.filter(deal => deal.global).length
      const [deal] = state.data.splice(fromIndex, 1)
      state.data.splice(toIndex, 0, deal)

      return {...state}
    default:
      return state
  }
}

const updateReducer = (state = {}, action) => {
  switch(action.type) {
    case updateMarketplaceCard().type:
      return { 
        ...state, 
        data: {...state.data, ...action.marketplaceDeal}
      }
    default: 
      return state
  }
}

export default combineReducers({
  list: withFetchNext(MARKETPLACEDEALLIST)(marketplaceReducer),
  selected: withFetch(MARKETPLACE_CARD)(updateReducer)
})
