import { css } from 'emotion'
import styled from '@emotion/styled'
import { Typography, Card } from '@material-ui/core'
import Button from '@Common/Button/FabButton'

export const StyledCard = styled(Card)`
  padding: 75px 22px;
  height: 475px;
  margin-top: 16px;

  &:before {
    content: ' ';
    background: ${({ theme }) => theme.palette.primary.green.iceberg};
    width: 100%;
    height: 230px;
    position: absolute;
    display: block;
    left: 0;
    margin-top: 325px;
    z-index: -1;
  }
`

export const DropBox = styled('div')`
  width: 308px;
  height: 200px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  outline: none;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23979797FF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='27' stroke-linecap='butt'/%3e%3c/svg%3e");
`

export const CardHeader = styled(Typography)`
  top: -50px;
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: ${({ theme }) => theme.custom.font.size.xl36};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};;
  position: relative;

  &::before {
    height: 2px;
    width: 70px;
    left: -7px;
    bottom: -9px;
    position: absolute;
    background-color: ${ ({ theme }) => theme.palette.secondary.main1};
    transform: translateX(10px);
    content: '';
}
`

export const DropboxCaption = styled('p')`
  color: ${({ theme }) => theme.palette.neutrals.deepGray};
  font-size: ${({ theme }) => theme.custom.font.size.mdl};
  width: 75%;
  text-align: center;
`

export const UploadedPhoto = styled('div')`
  width: 306px;
  height: 198px;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ photoUrl }) => `url(${photoUrl})`}
`

export const BackButton = styled(Button)`
  min-width: 2.7em;
  min-height: 2.7em;
  width: 2.7em;
  height: 2.7em;
  margin-bottom: 7px;
  svg {
    fill: ${({ theme }) => theme.palette.primary.dark};
  }
`

export const AlertModalClass = css`
  display: flex;
  justify-content: center;
  width: 540px !important;
  text-align: center;

  & p {
    margin-bottom: 20px;
    width: 95%;
  }

  & h2 {
    width: 85%;
    padding-left: 10%
  }
`

export const DropzoneClass = css`
  outline: none;
`
