// @flow
import React from 'react'
import { PropagateLoader } from 'react-spinners'
import { Loading } from './styles'

type Props = {
  color?: string,
  fullScreen?: boolean
}

type State = {
  isPastDelay: boolean
}

class Loader extends React.Component<Props, State> {
  state = {
    isPastDelay: false
  }

  timer: TimeoutID

  componentDidMount = () => {
    this.timer = setTimeout(() => {
      this.setState({
        isPastDelay: true
      })
    }, 200)
  }

  componentWillUnmount = () => {
    this.timer && clearTimeout(this.timer)
  }

  render() {
    const { color = '#029385', fullScreen = true } = this.props
    const { isPastDelay } = this.state

    if (isPastDelay) {
      if (fullScreen) {
        return (
          <Loading>
            <PropagateLoader color={color} />
          </Loading>
        )
      }
      return <PropagateLoader color={color} />
    } else {
      return null
    }
  }
}

export default Loader
