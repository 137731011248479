import { createFetchActions } from '../../helpers/actions/creators'

export const GROUP_AUTOMATION = 'GROUP_AUTOMATION'

export const {
  fetchAction: fetchGroupAutomationAction,
  fetchStartAction: fetchGroupAutomationStart,
  fetchSuccessAction: fetchGroupAutomationSuccess,
  fetchErrorAction: fetchGroupAutomationError
} = createFetchActions(GROUP_AUTOMATION)

export const fetchGroupAutomation = propertyId => fetchGroupAutomationAction({ propertyId })