// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import {CommonIcon } from '@icons'
import { AddButton, ButtonSet, FabButton, DisabledFabButton } from './styles'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import { withTheme } from 'emotion-theming'

import type {Theme} from '../../../theme'

type Props = {
  theme: Theme,
  toggleEditMode: () => mixed,
  goTo: () => mixed,
  showEdit?: boolean,
  isEditing: boolean,
  editDisabled: boolean,
  showDelete?: boolean,
  onDelete?: () => mixed,
  showAdd?: boolean,
  showAddDisabled: boolean,
  onAdd?: () => mixed,
}

type AddProps = {
  to: string,
  color: string
}

export const CTAButtonAdd = ({to, color}: AddProps) => (
  <AddButton>
    <Link to={to}>
      <FabButton variant="fab" color="primary">
        <CommonIcon
          name="plus"
          width="1em"
          height="1em"
          fill={color}
          strokeWidth={2}
          stroke={color}
        />
      </FabButton>
    </Link>
  </AddButton>
)

type AddOnClickProps = {
  onClick: () => mixed,
  color: string
}

export const CTAButtonAddWithOnClick = ({onClick, color}: AddOnClickProps) => (
  <AddButton>
    <FabButton
      variant="fab"
      onClick={onClick}
      color="primary"
    >
      <CommonIcon
        name="plus"
        width="1em"
        height="1em"
        fill={color}
        strokeWidth={2}
        stroke={color}
      />
    </FabButton>
  </AddButton>
)

const CTAButtons = ({
  theme,
  toggleEditMode,
  goTo,
  isEditing,
  showDelete,
  showEdit = true,
  onDelete,
  showAdd,
  showAddDisabled,
  onAdd,
  editDisabled,
}: Props) => (
  <ButtonSet>
    <FabButton variant="fab" onClick={goTo} darkIcon>
      <KeyboardBackspace height="0.5em" width="0.5em"/>
    </FabButton>
    {showAdd && (
      <FabButton
        variant="fab"
        color="secondary"
        outlined="true"
        onClick={onAdd}
      >
        <CommonIcon
          name="plus"
          width="1.3em"
          height="1.3em"
          strokeWidth={3}
          stroke={theme.palette.primary.dark}
        />
      </FabButton>
    )}
    {showAddDisabled && (
      <DisabledFabButton
        variant="fab"
        disableRipple={true}
        disableTouchRipple={true}
      >
        <CommonIcon
          name="plus"
          width="1.3em"
          height="1.3em"
          strokeWidth={3}
          stroke={theme.palette.neutrals.mediumGray}
        />
      </DisabledFabButton>
    )}
    {!isEditing && showEdit && (
      <FabButton
        variant="fab"
        color="secondary"
        outlined
        darkIcon
        disabled={editDisabled}
        editdisabled={editDisabled ? 1 : 0}
        classes={{disabled: 'disabled'}}
        onClick={toggleEditMode}>
        <CommonIcon
          name={'edit'}
          width={'1em'}
          height={'1em'}
          strokeWidth={5}
          stroke={editDisabled ? theme.palette.neutrals.darkGray : theme.palette.primary.dark}
        />
      </FabButton>
    )}
    {isEditing && (
      <FabButton
        variant="fab"
        color="secondary"
        outlined
        darkIcon
        disabled={editDisabled}
        editdisabled={editDisabled ? 1 : 0}
        classes={{disabled: 'disabled'}}
        onClick={toggleEditMode}>
        <CommonIcon
          name={'tick'}
          width={'1.5em'}
          height={'1.5em'}
          strokeWidth={2}
          stroke={theme.palette.primary.dark}
        />
      </FabButton>
    )}
    {showDelete && (
      <FabButton
        variant="fab"
        color="secondary"
        outlined
        darkIcon
        onClick={onDelete}>
        <CommonIcon
          name="close"
          width="1em"
          height="1em"
          fill={theme.palette.primary.dark}
          strokeWidth={2}
          stroke={theme.palette.primary.dark}
        />
      </FabButton>
    )}
  </ButtonSet>
)

export default withTheme(CTAButtons)
