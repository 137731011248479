import styled from '@emotion/styled'
import FormControl from '@material-ui/core/FormControl'
import isPropValid from '@emotion/is-prop-valid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MaterialRadio from '@material-ui/core/Radio'

export const CustomFormControl = styled(FormControl, {
  shouldForwardProp: isPropValid,
})`
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}`};
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};

  width: ${({ width }) => (width ? width : '100%')};
  border-bottom: ${({ theme, noUnderline }) =>
    noUnderline ? 'none' : `1px solid ${theme.palette.neutrals.coolGray}`};

  .radio-button-group {
    display: flex;
    flex-direction: row;
  }

  .radio-button-control-label {
    flex: 1;
  }
  
  .radio-button-amenity-availability-label {
    color: ${({ theme }) => theme.palette.neutrals.deepGray};
    white-space: nowrap;
  }

  ${({ textSize }) =>
    textSize &&
    ` * {
        font-size: ${textSize}
    }
    `};
`

export const CustomFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'lastRow',
})`
  min-width: 110px;
  
  ${({ error }) =>
    error &&
    ` svg {
        color: red;
      }
    `};
`

export const Radio = styled(MaterialRadio)`
  width: 28px;
  height: 28px;
`
