import {
  GROUPS,
  fetchGroupUsersSuccess,
  fetchSingleGroupSuccess
} from './actions'
import withPaginatedFetch from '../../helpers/reducers/withPaginatedFetch'

const groups = (state = null, action) => {
  switch (action.type) {
    case fetchGroupUsersSuccess().type:
      return { ...state, selectedGroupsUsers: action.payload }
    case fetchSingleGroupSuccess().type:
      return { ...state, selectedGroup: action.payload }
    default:
      return state
  }
}

export default withPaginatedFetch(GROUPS)(groups)
