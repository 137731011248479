// @flow

import { connect } from 'react-redux'
import withFetchData from 'zego-shared/views/withFetchData'
import { getPropertyId } from 'zego-shared/store/select/selectors'
import { fetchHistory } from 'zego-shared/store/groupMessaging/history'
import { fetchUpcomingEvents } from 'zego-shared/store/events/eventsActions'
import { fetchGroupMessagesSentToday } from 'zego-shared/store/groupMessaging/messagesSentTodayCount'
import { getNewOrUpcomingEventsCount } from '../../store/CommunityFeed/communityFeedSelectors.js'
import { getGroupMessageTodayCount } from 'zego-shared/store/groupMessaging/messagesSentTodayCount/selectors'
import {
  getCommunityFeedEnabled,
  getGroupMessagingEnabled,
  getChatEnabled
} from 'zego-shared/store/settings/selectors'
import { getUserRole } from 'zego-shared/store/authorization/selectors'
import Communication from './Communication'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import type { CommunicationProps } from './types'

const fetchingSelector = createIsFetchingSelector(['groupmessagessenttoday'])

const mapStateToProps = (state): CommunicationProps => ({
  userRole: getUserRole(state),
  selectedPropertyID: getPropertyId(state),
  groupMessageTodayCount: getGroupMessageTodayCount(state),
  upcomingEventsCount: getNewOrUpcomingEventsCount(state, 'upcoming'),
  communityFeedEnabled: getCommunityFeedEnabled(state),
  groupMessagingEnabled: getGroupMessagingEnabled(state),
  chatEnabled: getChatEnabled(state),
  isFetching: fetchingSelector(state)
})

const mapDispatchToProps = (dispatch, { match: { params } }) => ({
  fetchData: () => {
    dispatch(fetchGroupMessagesSentToday('properties'))
    dispatch(fetchHistory(params.propertyId))
    dispatch(fetchUpcomingEvents())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(Communication))
