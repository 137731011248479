import get from 'lodash/get'
import isPlainObject from 'lodash/isPlainObject'
import { getPropertySettingValue } from '../utils'

export const getPropertySettings = (state: Object) =>
  get(state, ['settings', 'propertySettings'], {})

const getSettingValue = (settings, key, defaultValue = false) =>
  get(settings, [getPropertySettingValue(key)], defaultValue)

const getSettings = (settings, keys) =>
  keys.reduce(
    (result, key) => ({
      ...result,
      [key]: getSettingValue(settings, key)
    }),
    {}
  )

export const getPropertyAccessSettings = (state: Object) => {
  const settings = getPropertySettings(state)
  return getSettings(settings, [
    'smartResidentAccess',
    'chatResidentAccess',
    'workOrderResidentAccess',
    'standaloneWorkOrders',
    'paymentResidentAccess',
    'accessCode',
    'communityFeed',
    'groupMessaging',
    'packages',
    'petRegistration',
    'visitorRegistration',
    'vehicleRegistration',
    'amenities',
    'vendorCodes',
    'residentSurveys',
    'moveInChecklist',
    'smartLeaseRenewal',
    'marketplace',
    'amenityReservations',
    'supportTicketAccess'
  ])
}

export const getPropertyNotificationSettings = (state: Object) => {
  const settings = getPropertySettings(state)
  return getSettings(settings, ['batteryDailyText', 'batteryDailyEmail'])
}

export const getCommunityFeedEnabled = state => {
  const settings = getPropertySettings(state)
  return getSettingValue(settings, 'communityFeed')
}

export const getGroupMessagingEnabled = state => {
  const settings = getPropertySettings(state)
  return getSettingValue(settings, 'groupMessaging')
}

export const getPaymentEnabled = state => {
  const settings = getPropertySettings(state)
  return getSettingValue(settings, 'payment')
}

export const getChatEnabled = state => {
  const settings = getPropertySettings(state)
  return getSettingValue(settings, 'chatResidentAccess')
}

export const getPackagesEnabled = state => {
  const settings = getPropertySettings(state)
  return getSettingValue(settings, 'packages')
}

export const getAmenityContact = state => {
  const settings = getPropertySettings(state)
  return getSettingValue(settings, 'amenityContact')
}

export const getLeaseRenewalEnabled = state => {
  const settings = getPropertySettings(state)
  return getSettingValue(settings, 'smartLeaseRenewal')
}

export const getSupportTicketEnabled = state => {
  const settings = getPropertySettings(state)
  return getSettingValue(settings, 'supportTicketAccess')
}

export const hasPropertySettings = ({ settings: { propertySettings } }) =>
  isPlainObject(propertySettings)

export const getRegistrationSettings = state => {
  const settings = getPropertySettings(state)
  return getSettings(settings, [
    'petRegistration',
    'visitorRegistration',
    'vehicleRegistration'
  ])
}

export const isSmartEnabled = (state: Object) => {
  const settings = getPropertySettings(state)

  return settings.zego_smart_allowed
}

export const getMoveInChecklistSetting = (state: Object) => {
  const settings = getPropertySettings(state)
  return get(settings, 'move_in_checklist', false)
}

export const getResidentSurveysEnabled = (state: Object) => {
  const settings = getPropertySettings(state)

  return getSettingValue(settings, 'residentSurveys')
}

export const getResidentMoveInChecklistEnabled = (state: Object) => {
  const settings = getPropertySettings(state)

  return getSettingValue(settings, 'moveInChecklist')
}

export const getResidentStandaloneWorkOrderEnabled = (state: Object) => {
  const settings = getPropertySettings(state)

  return getSettingValue(settings, 'standaloneWorkOrders')
}

export const getResidentIntegratedWorkOrderEnabled = (state: Object) => {
  const settings = getPropertySettings(state)

  return getSettingValue(settings, 'workOrderResidentAccess')
}

export const getSettingsInfo = (state: Object) => {
  const settings = getPropertySettings(state)

  return settings
}
