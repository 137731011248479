import moment from 'moment'
import {
  createFetchActions,
  createRefreshActions,
  createUpdateActions
} from '../../helpers/actions/creators'
import {
  thermostatAttributes,
  createThermostatAttributes,
  commands
} from '../sagas/utils/deviceCommands'

export const SECTION = 'DEVICES'

export const INCLUSION = 'HUBINCLUSION'
export const EXCLUSION = 'HUBEXCLUSION'
export const UPDATE = 'HUBUPDATE'
export const RESTART = 'HUBRESTART'

export const DELETE_DEVICE = 'DELETE_DEVICE'
export const DELETE_DEVICE_START = 'DELETE_DEVICE_START'
export const DELETE_DEVICE_FINISH = 'DELETE_DEVICE_FINISH'

export const PUSH_HUB_COMMAND = 'PUSH_HUB_COMMAND'

export const UPGRADE_FIRMWARE = 'UPGRADE_FIRMWARE'

export const THERMOSTAT_COMMAND = 'THERMOSTAT_COMMAND'

export const PUSH_THERMOSTAT_COMMAND = 'PUSH_THERMOSTAT_COMMAND'

export const LOCK_COMMAND = 'LOCK_COMMAND'

export const PUSH_LOCK_STATE_COMMAND = 'PUSH_LOCK_STATE_COMMAND'

export const SWITCH_COMMAND = 'SWITCH_COMMAND'

export const PUSH_SWITCH_STATE_COMMAND = 'PUSH_SWITCH_STATE_COMMAND'

export const DEVICE_NAME = 'DEVICE_NAME'

export const CLEAR_EXCLUDE_STATUS = 'CLEAR_EXCLUDE_STATUS'

export const CLEAR_DEVICES = 'CLEAR_DEVICES'

export const PUSH_DEVICE_NAME_COMMAND = 'PUSH_DEVICE_NAME_COMMAND'

export const deviceCommands = commands

export const {
  fetchAction: fetchDevicesAction,
  fetchStartAction: fetchDevicesStart,
  fetchSuccessAction: fetchDevicesSuccess,
  fetchErrorAction: fetchDevicesError
} = createFetchActions(SECTION)

export const fetchDevices = id => fetchDevicesAction({ id })

export const {
  refreshAction: refreshDevices,
  refreshStartAction: refreshDevicesStart,
  refreshSuccessAction: refreshDevicesSuccess,
  refreshErrorAction: refreshDevicesError
} = createRefreshActions(SECTION)

export const refreshDevicesList = id => refreshDevices({ id })

const {
  updateStartAction: loadInclusionStart,
  updateSuccessAction: loadInclusionSuccess,
  updateErrorAction: loadInclusionError
} = createUpdateActions(INCLUSION)

const {
  updateStartAction: loadExclusionStart,
  updateSuccessAction: loadExclusionSuccess,
  updateErrorAction: loadExclusionError
} = createUpdateActions(EXCLUSION)

const {
  updateStartAction: loadUpdateStart,
  updateSuccessAction: loadUpdateSuccess,
  updateErrorAction: loadUpdateError
} = createUpdateActions(UPDATE)

const {
  updateStartAction: loadRestartStart,
  updateSuccessAction: loadRestartSuccess,
  updateErrorAction: loadRestartError
} = createUpdateActions(RESTART)

export const hubCommandActions = {
  [commands.INCLUSION]: {
    start: loadInclusionStart,
    success: loadInclusionSuccess,
    error: loadInclusionError
  },
  [commands.EXCLUSION]: {
    start: loadExclusionStart,
    success: loadExclusionSuccess,
    error: loadExclusionError
  },
  [commands.UPDATE]: {
    start: loadUpdateStart,
    success: loadUpdateSuccess,
    error: loadUpdateError
  },
  [commands.RESTART]: {
    start: loadRestartStart,
    success: loadRestartSuccess,
    error: loadRestartError
  }
}

export const updateThermostatActions = createUpdateActions(
  THERMOSTAT_COMMAND,
  true
)

export const updateThermostatOptimistically = (id, value) => {
  const { action: deviceAction, name, heat_setpoint, cool_setpoint } = createThermostatAttributes(value)
  const attributes = name === thermostatAttributes.SYSTEM_MODE ?
    { system_mode: deviceAction } :
    {
      ...(heat_setpoint == null ? {} : { occ_heat_sp: heat_setpoint }),
      ...(cool_setpoint == null ? {} : { occ_cool_sp: cool_setpoint }),
    }
  return updateThermostatActions.updateOptimistcallyAction({
    id,
    attributes
  })
}

export const pushThermostatCommand = (commandType, value, id, identifier) => ({
  type: PUSH_THERMOSTAT_COMMAND,
  commandType,
  value,
  id,
  identifier
})

export const updateLockActions = createUpdateActions(LOCK_COMMAND, true)

export const updateLockOptimistically = (id, lock) =>
  updateLockActions.updateOptimistcallyAction({
    id,
    attributes: {
      state: lock ? 'locked' : 'unlocked'
    }
  })

export const pushLockStateCommand = (id, identifier) => ({
  type: PUSH_LOCK_STATE_COMMAND,
  id,
  identifier
})

export const pushHubCommand = commandType => ({
  type: PUSH_HUB_COMMAND,
  commandType
})

export const updateFirmware = id => ({
  type: UPGRADE_FIRMWARE,
  id
})

export const updateDeviceNameActions = createUpdateActions(DEVICE_NAME, true)

export const updateDeviceNameOptimistically = (id, name) =>
  updateDeviceNameActions.updateOptimistcallyAction({ id, name })

export const updateDeviceNameRevert = (id, name) =>
  updateDeviceNameActions.updateRevertAction({ id, name })

export const updateDeviceName = (id, name, previousName) =>
  updateDeviceNameActions.updateOptimistcallyAction({ id, name, previousName })

export const updateSwitchActions = createUpdateActions(SWITCH_COMMAND, true)

export const updateSwitchOptimistically = (id, switchState) =>
  updateSwitchActions.updateOptimistcallyAction({
    id,
    attributes: {
      state: switchState,
      device_timestamp: moment.utc().format()
    }
  })

export const updateSwitchRevert = (id, previousState) =>
  updateSwitchActions.updateRevertAction({
    id,
    attributes: {
      state: previousState
    }
  })

export const pushSwitchStateCommand = (id, identifier) => ({
  type: PUSH_SWITCH_STATE_COMMAND,
  id,
  identifier
})

export const updateDeviceNameCommand = (id, name, prevName) => ({
  type: PUSH_DEVICE_NAME_COMMAND,
  id,
  name,
  prevName
})

export const clearHubCommandExcludeStatus = () => ({
  type: CLEAR_EXCLUDE_STATUS
})

export const deleteDevice = (id, history, url, dispatch) => ({
  type: DELETE_DEVICE,
  id,
  history,
  url,
  dispatch
})

export const clearDevices = () => ({
  type: CLEAR_DEVICES
})

export * from './lockCodes'
