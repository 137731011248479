// @flow
import React, { useState, useEffect } from 'react'
import { PropagateLoader } from 'react-spinners'
import curry from 'lodash/curry'
import find from 'lodash/find'
import AddIcon from '@material-ui/icons/Add'
import LeaseRenewDetailsCard from './LeaseRenewDetailsCard'
import { FlexItem } from '@Common/Flex'
import { GridItem } from '@Common/Grid'
import ScrollDownButton from '@Common/ScrollDownButton'
import Loader from '@Common/Loader'
import Flex from '@Common/Flex'
import Avatar from '@Common/Avatar'
import DropDown from '@Common/DropDown'
import { withTheme } from 'emotion-theming'
import { getProfileUrlOrDefault } from 'zego-shared/utils/profilePicture'
import { CommonIcon } from '@icons'
import {
  StyledTable,
  StyledRenewalColumn,
  StyledColumn,
  StyledCell,
  StyledLink,
  StyledChip,
  StyledFilterText,
  StyledClearText,
  Loading,
  ShowMoreBtn,
  FabButton,
  StyledRowText,
  StyledEmailLink,
  AfterTableSpacing
} from '../styles'

const ResidentLeaseRenewals = (props) => {
  const {
    residents,
    residentsFliteredTotal,
    propertyId,
    theme,
    hasPms,
    pmsName,
    isFetching,
    chatEnabled,
    fetchResidentUsersAction,
    fetchNextResidentUsersAction,
    statuses,
    updateLeaseInterestStatus,
    templates
  } = props

  const [currentPage, setCurrentPage] = useState(1)
  const [selectedFilters, setFilters] = useState({
    lease_interest_status: [],
    sort_by: 'lease_end_date,asc',
  })
  const [showLoader, setShowLoader] = useState(false)


  const integrated = hasPms && pmsName !== 'paylease'

  const filters = {
    lease_interest_status: statuses.map((status) => status.name),
  }

  useEffect(() => {
    setShowLoader(false)
  }, [residents])

  useEffect(() => {
    if (currentPage > 1) {
      fetchNextResidentUsersAction({
        propertyId,
        page: currentPage,
        params: addDefaultStatusWhenUndefiend(
          selectedFilters,
          'lease_interest_status'
        ),
      })
    }
    // eslint-disable-next-line
  }, [currentPage])



  if (isFetching) {
    return <Loader />
  }

  const extractPriorPeriod = (groups) => {
    let periodValue
    groups.forEach((group) => {
      Object.values(group.user_selectors).forEach((selector) => {
        if(selector != null
            && typeof selector == 'object') {
          if (selector.field === 'lease_end_date') {
            periodValue = selector.value
          }
        }
      })
    })
    return periodValue
  }

  const getRenewDetails = (template_name) => {
    const empty = {id: null, sending_methods: [], period: null}
    if(templates.length){
     return templates.reduce(
        (
          acc,
          { name, groups, sending_methods, id, is_default}
        ) => {
          if (name === template_name && is_default && !!groups) {
            const period = extractPriorPeriod(groups)
            return {
              id,
              period,
              sending_methods
            }
          }
          return acc
      }, empty)
    }
     return empty
  }

  const clearFilters = () => {
    const filters = Object.assign({}, selectedFilters)
    filters['lease_interest_status'] = []

    setFilters(filters)
    setCurrentPage(1)
    setShowLoader(true)

    fetchResidentUsersAction({
      propertyId,
      params: addDefaultStatusWhenUndefiend(filters, 'lease_interest_status'),
    })
  }

  const validLeaseInterestStatus = statuses && statuses.map((status) => status.id)

  const addDefaultStatusWhenUndefiend = (filters, filterType) => {
    const newFilters = Object.assign({}, filters)

    if (((newFilters[filterType]) === "undefined") || (newFilters[filterType].length === 0)) {
      newFilters[filterType] = validLeaseInterestStatus
    } else {
      newFilters[filterType] = newFilters[filterType].map(
        (name) => statuses.find((status) => status.name === name).id
      )
    }
    return newFilters
  }

  const leaseInterestStatus = (resident) => {
    const status = resident.lease_interest_status || 'not_interested'
    return find(statuses, (o) => o.id === status)
  }

  const leaseInterestStatusChange = curry((resident, selectedValue) => {
    updateLeaseInterestStatus(resident.user_id, selectedValue)
  })

  const createRows = () => {
    const formattedRows = residents.filter((resident) => validLeaseInterestStatus.includes(resident.lease_interest_status)).map((rowData) => {
      const photo = getProfileUrlOrDefault(rowData.picture, rowData.name)
      const unit = rowData.units[0]
      return {
        ID: (
          <FlexItem flex={0.8}>
            <StyledLink
              to={`/properties/${propertyId}/user-management/profile/${rowData.user_id}`}>
              <Avatar size="2.2em" picture={photo} />
            </StyledLink>
          </FlexItem>
        ),
        Resident: <StyledLink to={`/properties/${propertyId}/user-management/profile/${rowData.user_id}`}> {rowData.name || rowData.email} </StyledLink>,
        Unit: unit ? <StyledLink to={`/properties/${propertyId}/units/${unit.id}/user-management`} className="text-left"> {unit.name}</StyledLink> : '',
        EmptyColumn: "",
        LeaseRenewalStatus:
          <FlexItem>
            <DropDown
              handleChange={leaseInterestStatusChange(rowData)}
              selectedItem={leaseInterestStatus(rowData)}
              data={statuses}
              weight={({theme}) => theme.custom.font.weight.light}
              width={"115px"}
            />
          </FlexItem>
        ,
        MoveOutDate: <StyledRowText>{rowData.move_out_date}</StyledRowText>,
        LeaseEndDate: <StyledRowText>{rowData.lease_end_date}</StyledRowText>,
        ContactResident: renderContactLinks(rowData),
      }
    })

    const styledRows = formattedRows.map((rowData) => {
      return Object.entries(rowData).map(([key, value], index) => {
        if (index === 1) {
          return {
            key: key,
            value: <StyledCell index={index + 1}>{value}</StyledCell>,
          }
        }
        return {
          key: key, value: (<StyledRenewalColumn index={index + 1}>{value}</StyledRenewalColumn >)
        }
      })
    })

    return styledRows
  }

  const columns = [
    {
      value: <StyledColumn index={1}> Resident </StyledColumn>
    },
    {
      value: <StyledRenewalColumn index={2}> </StyledRenewalColumn>
    },
    {
      value: <StyledRenewalColumn index={3} className="content-left"> Unit </StyledRenewalColumn>
    },
    {
      value: <StyledRenewalColumn index={4}></StyledRenewalColumn>
    },
    {
      value: <StyledRenewalColumn index={5}> Lease Renewal Status </StyledRenewalColumn>
    },
    {
      value: <StyledRenewalColumn onClick={() => { handleColumnClick('move_out_date') }} index={6}> Move Out Date
        <CommonIcon
          name={selectedFilters['sort_by'] === 'move_out_date,asc' ? 'arrow_up' : 'arrow_down'}
          width="0.9em"
          height="0.9em"
          style={{ marginLeft: '0.5em' }}
          className="column-icon"
        />
      </StyledRenewalColumn>
    },
    {
      value: <StyledRenewalColumn onClick={() => { handleColumnClick('lease_end_date') }} index={7}> Lease End Date
        <CommonIcon
          name={selectedFilters['sort_by'] === 'lease_end_date,asc' ? 'arrow_up' : 'arrow_down'}
          width="0.9em"
          height="0.9em"
          style={{ marginLeft: '0.5em' }}
          className="column-icon"
        />
      </StyledRenewalColumn>
    },
    {
      value: <StyledRenewalColumn index={6} className="content-right"> Contact Resident </StyledRenewalColumn>
    }
  ]

  const renderContactLinks = (resident) => {
    const chatLink = <StyledLink to={`/properties/${propertyId}/communication/chat/live-chat/users/${resident?.user_id}`}> Chat </StyledLink>
    const emailLink = resident?.email ? <StyledEmailLink href={`mailto:${resident?.email}`}> Email </StyledEmailLink> : ''
    const hasBoth = emailLink && chatEnabled
    return (
      <Flex justifyRight fullWidth>
        {emailLink} {chatEnabled && (<span> {hasBoth && '|'} {chatLink}</span>)}
      </Flex>
    )
  }

  const handleChipClick = (filterType, filterValue) => {
    const otherFilters = Object.assign({}, selectedFilters)
    delete otherFilters[filterType]

    let selectedValues = selectedFilters[filterType]

    // toggle filters
    if (selectedValues?.includes(filterValue)) {
      selectedValues = selectedValues.filter((filter) => filter !== filterValue)
    } else {
      selectedValues.push(filterValue)
    }

    let filters = {}
    filters[filterType] = selectedValues
    setFilters({ ...filters, ...otherFilters })
    setCurrentPage(1)
    setShowLoader(true)

    filters = addDefaultStatusWhenUndefiend(
      { ...filters, ...otherFilters },
      filterType
    )
    fetchResidentUsersAction({ propertyId, page: 1, params: filters })
  }

  const filterChipGroup = (filters : Object) => {
    const chips = []

    for (let [filterType, filterValues] of Object.entries(filters)) {
      const selectedValues = selectedFilters?.[filterType] || []

      if (Array.isArray(filterValues)) {
        filterValues.forEach((value) => {
          let color = "primary"
          if (selectedValues.includes(value)) {
            color = 'secondary'
          }
          chips.push(
            <StyledChip
              theme={theme}
              variant="outlined"
              color={color}
              label={value}
              onClick={() => {
                handleChipClick(filterType, value)
              }}
            />
          )
        })
      } else {
        let color = 'primary'
        if (filterValues === selectedValues) {
          color = 'secondary'
        }

        chips.push(
          <StyledChip
            theme={theme}
            variant="outlined"
            color={color}
            label={filterValues}
            onClick={() => {
              handleChipClick(filterType, filterValues)
            }}
          />
        )
      }
    }
    return chips
  }

  const handleColumnClick = (column: string) => {
    const orderBy = { 'sort_by': (selectedFilters['sort_by'] && selectedFilters['sort_by'].includes('asc') && selectedFilters['sort_by'].includes(column)) ? `${column},desc` : `${column},asc` }
    let filters = Object.assign({}, selectedFilters);
    delete filters['sort_by']
    setFilters({ ...orderBy, ...filters })
    setShowLoader(true)
    filters = addDefaultStatusWhenUndefiend(filters, 'lease_interest_status')

    fetchResidentUsersAction({ propertyId, params: { ...orderBy, ...filters } })
  }

  const showMore = residents.length < residentsFliteredTotal
  const handleShowMore = () => {
    setCurrentPage(currentPage + 1)
    setShowLoader(true)
  }

  return (
    <>
      <GridItem columnStart={1} columnSpan={12} rowStart={1} rowSpan={2}>
        <LeaseRenewDetailsCard
          initial={getRenewDetails('Smart Lease Renewal')}
          follow_up={getRenewDetails('Smart Lease Renewal')}
          propertyId={propertyId}
          />
      </GridItem>
      <GridItem columnStart={1} columnSpan={10} rowStart={5} rowSpan={1}>
        <div>
          <StyledFilterText>
            {residentsFliteredTotal} Residents eligible for renewal{' '}
          </StyledFilterText>
          <StyledClearText onClick={clearFilters}>
            Clear Filters
          </StyledClearText>
        </div>
        {filterChipGroup(filters)}
      </GridItem>
      <GridItem columnStart={1} columnSpan={12} rowStart={6} rowSpan={10}>
        <StyledTable
          noDataText="No Residents"
          flex={[0.01, 0.1, 0.1, 1, 0.1, 0.1, 0.1, 0.1]}
          columns={columns}
          rows={createRows()}
        />
        {showLoader && (
          <Loading>
            <PropagateLoader color={theme.palette.secondary.main1} />
          </Loading>
        )}
        {showMore && (
          <ShowMoreBtn id="showMoreBtn" disableRipple onClick={handleShowMore}>
            Show More <ScrollDownButton />
          </ShowMoreBtn>
        )}
        <AfterTableSpacing/>
      </GridItem>
      {!integrated && (
        <StyledLink to={`/properties/${propertyId}/user-management/add-user`}>
          <FabButton variant="fab">
            <AddIcon style={{ color: theme.palette.common.white }} />
          </FabButton>
        </StyledLink>
      )}
    </>
  )
}
export default withTheme(ResidentLeaseRenewals)
