import {
  FETCH_NEXT_PREFIX,
  START,
  SUCCESS,
  ERROR
} from '../helpers/actions/constants'
import { endsWithAny } from '../../utils/string'

const SUPPORTED_PREFIX = FETCH_NEXT_PREFIX
const SUPPORTED_POSTFIXES = [START, SUCCESS, ERROR]

// handles all actions with type format: PREFIX_SOMEWORD_SUFFIX
const isFetchingNext = (state = {}, action) => {
  const { type } = action

  if (
    !type ||
    !type.startsWith(SUPPORTED_PREFIX) ||
    !endsWithAny(SUPPORTED_POSTFIXES, type)
  ) {
    return state
  }

  // if its not start just set isFetchingNext to false
  const isFetchingNext = type.includes(START)
  const requestName = type.split('_')[2]

  return {
    ...state,
    [requestName.toLowerCase()]: isFetchingNext
  }
}

export default isFetchingNext
