// @flow

import get from 'lodash/get'
import { getPaginationData } from '../helpers/selectors/data'

const mapAmenityData = (amenity) => {
  const { extraReservableArea, resources } = amenity

  const { name: extraAreaName, count: extraAreaQuantity } = resources || {}

  return {
    amenityType: amenity.amenityType,
    reservationType: amenity.reservationType,
    availability: amenity.availability ?? [],
    checkInTime : amenity.checkInTime,
    checkOutTime : amenity.checkOutTime,
    currentWaiverUuid: amenity.currentWaiverUuid ?? '',
    description: amenity.description,
    duration: amenity.duration ?? 60,
    minDuration: amenity.minDuration,
    extraAreaName,
    extraAreaQuantity,
    extraReservableArea: !!(
      extraReservableArea ??
      extraAreaName ??
      extraAreaQuantity
    ),
    facilityUuid: amenity.facilityUuid,
    guestsAllowed: amenity.guestsAllowed,
    maxReservationDays: amenity.maxReservationDays ?? 90,
    maxPeoplePerReservation: amenity.maxPeoplePerReservation,
    maxReservationsPerDay: amenity.maxReservationsPerDay,
    minMultiDayReservation: amenity.minMultiDayReservation,
    maxMultiDayReservation: amenity.maxMultiDayReservation,
    name: amenity.name,
    padding: amenity.padding,
    photos: amenity.photos ?? [],
    pinnedPhotoUuid: amenity.pinnedPhotoUuid ?? '',
    deletedPhotos: [],
    placeholder: amenity.placeholder ?? false,
    propertyId: amenity.propertyId,
    published: amenity.published,
    pinned: amenity.pinned,
    resources: amenity.resources ?? { name: '', count: 0 },
    reservationLimit: amenity.reservationLimit,
    rsvpRequired: amenity.rsvpRequired,
    reserveAheadRequired: amenity.reserveAheadRequired,
    schedule: amenity.schedule ?? {},
    timeZone: amenity.timeZone ?? '',
    totalOccupancy: amenity.totalOccupancy,
    waiverDocs: amenity.waiverDocs,
    waiverRequired: amenity.waiverRequired,
    upcomingAvailability: amenity.upcomingAvailability,
  }
}

export const sortAmenities = (state: Object, amenities: Object[]) => {
  let pinned: Object[] = []
  let notPinned: Object[] = []

  const pinnedFacilityUuids = getPinnedFacilities(state)

  amenities.forEach((amenity) =>
    pinnedFacilityUuids.includes(amenity.facilityUuid)
      ? pinned.push(amenity)
      : notPinned.push(amenity)
  )
  pinned.sort((a, b) => {
    return a.name && b.name ? a.name.localeCompare(b.name) : 0
  })
  pinned.forEach((amenity) => amenity.pinned = true)
  notPinned.sort((a, b) => {
    return a.name && b.name ? a.name.localeCompare(b.name) : 0
  })
  notPinned.forEach((amenity) => amenity.pinned = false)

  return [...pinned, ...notPinned]
}

export const getAmenities = (state: Object) => {
  let amenities = get(state, ['amenitiesPro', 'amenities', 'data']) || []

  return sortAmenities(
    state,
    amenities ? amenities.map((amenity) => mapAmenityData(amenity)) : []
  )
}

export const getAmenityViewMode = (state: Object) => {
  const { readMode, editMode } =
    get(state, ['amenitiesPro', 'amenityView']) || {}

  return {
    readMode,
    editMode,
  }
}

export const getAmenity = (state: Object) => {
  const availability = get(state, ['amenitiesPro', 'availability', 'timeSlots'] ?? [])
  let amenity = get(state, ['amenitiesPro', 'amenity']) || {}
  amenity = { ...amenity, availability}

  return mapAmenityData(amenity)
}

export const getReservations = (state: Object): Object => {
  return get(state, ['amenitiesPro', 'reservations']) ?? {}
}

export const getAvailability = (state: Object): Object => {
  return get(state, ['amenitiesPro', 'availability'] ?? [])
}

export const getWaiver = (state: Object): Object => {
  return get(state, ['amenitiesPro', 'waiver'] || {})
}

export const amenityForApi = (data: Object) => {
  const {
    amenityType,
    reservationType,
    facilityUuid,
    name,
    description,
    placeholder,
    published,
    rsvpRequired,
    reserveAheadRequired,
    reservationLimit,
    totalOccupancy,
    extraReservableArea,
    extraAreaName,
    extraAreaQuantity,
    duration,
    minDuration,
    maxReservationsPerDay,
    padding,
    guestsAllowed,
    maxPeoplePerReservation,
    maxReservationDays,
    checkInTime,
    checkOutTime,
    schedule,
    minMultiDayReservation,
    maxMultiDayReservation,
  } = data

  const amenity = {
    amenityType,
    reservationType,
    facilityUuid,
    name,
    description,
    placeholder,
    published,
    rsvpRequired,
    reserveAheadRequired,
    reservationLimit,
    totalOccupancy,
    ...(extraReservableArea
      ? {
        resources: {
          name: extraAreaName,
          count: extraAreaQuantity,
        },
      }
      : {
        resources: null,
      }),
    duration,
    minDuration,
    maxReservationsPerDay: maxReservationsPerDay,
    padding,
    guestsAllowed,
    maxPeoplePerReservation,
    maxReservationDays,
    checkInTime,
    checkOutTime,
    schedule,
    minMultiDayReservation,
    maxMultiDayReservation,
  }

  // don't omit nulls or we can't clear fields intentionally
  return amenity
}

export const hasAmenities = (state: Object) => {
  return get(state, ['amenitiesPro', 'amenities', 'data']) != null
}

export const hasAmenity = (state: Object) => {
  return get(state, ['amenitiesPro', 'amenity']) != null
}

export const getPinnedFacilities = (state: Object) => {
  return get(state, ['amenitiesPro', 'admin', 'pinnedFacilityUuids']) || []
}

export const getAmenitiesPaginationInfo = (state: Object) => {
  const data = getPaginationData(state, ['amenitiesPro', 'amenities'])

  return {
    currentPage: data.currentPage,
    perPage: data.perPage,
    total: data.total,
    totalPages: data.totalPages,
  }
}
