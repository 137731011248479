import { REFRESH_PREFIX, SUCCESS } from '../actions/constants'

// use this reducer to handle a refresh for a single object
export default (section, key) => reducer => (state, action) => {
  switch (action.type) {
    case `${REFRESH_PREFIX}${section}${SUCCESS}`: {
      return {
        ...state,
        [key]: action.payload
      }
    }
    default:
      return reducer(state, action)
  }
}
