import { CLEAR_DEVICES } from '../actions'
const allIds = (state = [], action) => {
  switch (action.type) {
    case CLEAR_DEVICES:
      return []
    default:
      return state
  }
}

export default allIds
