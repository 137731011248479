import styled from '@emotion/styled'

export const FilterContainer = styled('div')`
  grid-column: 11 / span 3;
  grid-row: 2 / span 10;

  background: ${({ theme }) => theme.palette.secondary.light5};
`

export const TabsWrap = styled('div')`
  width: 100%;
  padding: 2em 1em;
`
