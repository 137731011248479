import { call, select, takeLatest, put } from 'redux-saga/effects'
import { getToken } from '../../authorization/selectors'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  fetchGroupAutomationAction,
  fetchGroupAutomationStart,
  fetchGroupAutomationSuccess,
  fetchGroupAutomationError
} from './actions'

export function* fetchGroupAutomation({ propertyId }) {
  try {
    yield put(fetchGroupAutomationStart())
    const authToken = yield select(getToken)
    const response = yield call(
        api.getGroupMessagingAutomation,
        authToken,
        propertyId
      );

    yield put(fetchGroupAutomationSuccess(response))
  } catch (error) {
    yield put(fetchGroupAutomationError(error))
    log(`Failed to fetch group automations`)
  }
}

function* watchFetchGroupAutomation() {
  yield takeLatest(fetchGroupAutomationAction().type, fetchGroupAutomation)
}

export default [watchFetchGroupAutomation()]