export const propertySettingsKeys = {
  smartResidentAccess: 'zego_smart_allowed',
  chatResidentAccess: 'chat',
  workOrderResidentAccess: 'work_order',
  standaloneWorkOrders: 'standalone_work_orders',
  paymentResidentAccess: 'payment',
  batteryDailyText: 'text_notifications',
  batteryDailyEmail: 'email_notifications',
  accessCode: 'access_code',
  communityFeed: 'community_feed',
  groupMessaging: 'group_messaging',
  packagesReminder: 'package_reminder_time',
  packages: 'packages',
  amenities: 'amenities',
  payment: 'payment',
  petRegistration: 'pet_registration',
  vehicleRegistration: 'vehicle_registration',
  visitorRegistration: 'visitor_registration',
  vendorCodes: 'vendor_code_management',
  amenityContact: 'amenity_contact',
  residentSurveys: 'resident_surveys',
  moveInChecklist: 'move_in_checklist',
  smartLeaseRenewal: 'smart_lease_renewal',
  marketplace: 'service_marketplace',
  amenityReservations: 'amenity_reservations',
  supportTicketAccess: 'support_ticket',
}

export const getPropertySettingValue = key => propertySettingsKeys[key]
