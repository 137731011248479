import React from 'react'
import { Route } from 'react-router-dom'
import ChannelDetailsContainer from '../../../views/Communication/Chat/ChatWindow/ChannelDetailsContainer'

const ChatUserRoutes = () => (
  <React.Fragment>
    <Route
      exact
      path="/properties/:propertyId/communication/chat/:channelType"
      render={() => <ChannelDetailsContainer />}
    />
    <Route
      exact
      path="/properties/:propertyId/communication/chat/:channelType/users/:userId"
      render={() => <ChannelDetailsContainer />}
    />
  </React.Fragment>
)

export default ChatUserRoutes
