// @flow
import React, { PureComponent } from 'react'
import Notification from 'react-web-notification'
import { withRouter } from 'react-router-dom'

type State = {
  ignore: boolean
}

type Props = {
  tag: string,
  body: string,
  icon: string,
  title: string,
  channelId: string,
  clearNotificationData: Function,
  match: { params: { propertyId: string } }
}

class ChatNotification extends PureComponent<Props, State> {
  state = {
    ignore: false
  }

  updateIgnore = (ignore: boolean) => () => this.setState({ ignore })

  handClick = (event: Object) => {
    const {
      channelId,
      match: {
        params: { propertyId }
      }
    } = this.props
    event.preventDefault()
    window.open(
      `/properties/${propertyId}/communication/chat/live-chat/users/${channelId}`
    )
  }

  getOptions(): Object {
    return {
      tag: this.props.tag,
      body: this.props.body,
      icon: this.props.icon,
      lang: 'en',
      dir: 'ltr'
    }
  }

  render() {
    const { title = '', clearNotificationData } = this.props
    return (
      <Notification
        ignore={this.state.ignore}
        onPermissionGranted={this.updateIgnore(false)}
        onPermissionDenied={this.updateIgnore(true)}
        notSupported={this.updateIgnore(true)}
        onClose={clearNotificationData}
        onClick={this.handClick}
        timeout={5000}
        title={title}
        options={this.getOptions()}
      />
    )
  }
}

export default withRouter(ChatNotification)
